import {useCountries} from 'lib/event-api/countries/use-countries'
import DefaultSelect, {SelectProps} from 'lib/ui/Select'
import DefaultOption, {OptionProps} from 'lib/ui/Select/Option'
import React from 'react'

export default function CountrySelect(
  props: SelectProps & {
    Select?: React.FC<SelectProps>
    Option?: React.FC<OptionProps>
    defaultLabel?: string
  },
) {
  const {
    Select = DefaultSelect,
    Option = DefaultOption,
    defaultLabel,
    ...selectProps
  } = props

  const {data: countries = []} = useCountries()

  return (
    <Select {...selectProps} aria-label="country" {...selectProps}>
      <Option value={0} disabled>
        {defaultLabel ?? 'Country'}
      </Option>

      {countries.map((c) => (
        <Option value={c.id} key={c.id}>
          {c.name}
        </Option>
      ))}
    </Select>
  )
}

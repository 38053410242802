import React from 'react'
import {TicketRibbon} from 'Event/template/Lefty/Dashboard/Sidebar/SidebarItem/TicketRibbonList/Ribbon'
import {Controller, UseFormMethods} from 'react-hook-form'
import ColorPicker from 'lib/ui/ColorPicker'

export default function Styling(props: {
  control: UseFormMethods['control']
  ticketRibbon: TicketRibbon
}) {
  const {control, ticketRibbon} = props

  return (
    <>
      <Controller
        name="color"
        control={control}
        defaultValue={ticketRibbon.color}
        render={({value, onChange}) => (
          <ColorPicker
            label="Ribbon Text Color"
            color={value}
            onPick={onChange}
          />
        )}
      />
    </>
  )
}

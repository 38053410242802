import {client} from 'lib/ui/api-client'
import {api} from 'lib/url'
import {useQuery} from 'react-query'

export interface Country {
  id: number
  name: string
  code: string
}

export function useCountries() {
  return useQuery({
    queryKey: ['countries'],
    queryFn: () => client.get<Country[]>(api(`/countries`)),
  })
}

import React from 'react'
import styled from 'styled-components'
import {Controller, UseFormMethods} from 'react-hook-form'
import {NumberField} from 'lib/ui/TextField'
import {Label} from 'lib/ui/typography'

export default function MarginPaddingInputs(props: {
  control: UseFormMethods['control']
  namePrefix: string
  values?: {
    top?: number
    left?: number
    right?: number
    bottom?: number
  }
  settings?: {
    min?: number
    max?: number
    step?: number
  }
}) {
  const {control, namePrefix, settings = {}, values = {}} = props

  const {min = 0, max = 100, step = 1} = settings
  const {top = 20, left = 20, right = 20, bottom = 20} = values

  return (
    <FlexContainer>
      <FlexBox>
        <StyledLabel>Top</StyledLabel>
        <Controller
          name={`${namePrefix}.top`}
          control={control}
          defaultValue={top}
          render={({value, onChange}) => (
            <NumberField
              value={value}
              onChange={onChange}
              inputProps={{
                min: min,
                max: max,
                step: step,
                'aria-label': 'margin-padding-top',
              }}
            />
          )}
        />
      </FlexBox>
      <FlexBox>
        <StyledLabel>Left</StyledLabel>
        <Controller
          name={`${namePrefix}.left`}
          control={control}
          defaultValue={left}
          render={({value, onChange}) => (
            <NumberField
              value={value}
              onChange={onChange}
              inputProps={{
                min: min,
                max: max,
                step: step,
                'aria-label': 'margin-padding-left',
              }}
            />
          )}
        />
      </FlexBox>
      <FlexBox>
        <StyledLabel>Right</StyledLabel>
        <Controller
          name={`${namePrefix}.right`}
          control={control}
          defaultValue={right}
          render={({value, onChange}) => (
            <NumberField
              value={value}
              onChange={onChange}
              inputProps={{
                min: min,
                max: max,
                step: step,
                'aria-label': 'margin-padding-right',
              }}
            />
          )}
        />
      </FlexBox>
      <FlexBox>
        <StyledLabel>Bottom</StyledLabel>
        <Controller
          name={`${namePrefix}.bottom`}
          control={control}
          defaultValue={bottom}
          render={({value, onChange}) => (
            <NumberField
              value={value}
              onChange={onChange}
              inputProps={{
                min: min,
                max: max,
                step: step,
                'aria-label': 'margin-padding-bottom',
              }}
            />
          )}
        />
      </FlexBox>
    </FlexContainer>
  )
}

const FlexContainer = styled.div`
  display: flex;
`

const FlexBox = styled.div`
  flex: 1;
  margin-right: ${(props) => props.theme.spacing[2]};
`

const StyledLabel = styled(Label)`
  margin-bottom: ${(props) => props.theme.spacing[2]} !important;
`

import {useGuestVariables} from 'Event'
import {useCardsTemplate} from 'Event/template/Cards'
import TitleConfig from 'Event/template/Cards/ForgotPassword/Title/TitleConfig'
import {TitleBase} from 'Event/template/Cards/Login/Title'
import Configurable from 'organization/Event/Configurable'
import React from 'react'

export default function Title() {
  const {
    login: {
      passwordReset: {forgotPasswordTitle},
    },
  } = useCardsTemplate()
  const v = useGuestVariables()

  return (
    <Configurable>
      <TitleConfig />
      <TitleBase>{v(forgotPasswordTitle)}</TitleBase>
    </Configurable>
  )
}

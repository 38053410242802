import {useCardsTemplate, useCardsUpdate} from 'Event/template/Cards'
import ColorPicker from 'lib/ui/ColorPicker'
import Divider from 'lib/ui/ConfigPanel/Divider'
import Title from 'lib/ui/ConfigPanel/Title'
import TextField from 'lib/ui/TextField'
import {useConfig} from 'organization/Event/Configurable'
import Config from 'organization/Event/Configurable/Config'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import StylingPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/StylingPanel'
import React from 'react'
import {Controller} from 'react-hook-form'

export default function StepLabelConfig(props: {step: 1 | 2 | 3}) {
  const {step} = props
  const save = useCardsUpdate()
  const template = useCardsTemplate()
  const {
    form: {control, register},
  } = useConfig()

  return (
    <Config title="Check In" onSave={save}>
      <SettingsPanel>
        <Title>Title</Title>
        <TextField
          name="checkIn.title"
          label="Label"
          defaultValue={template.checkIn.title}
          inputProps={{
            'aria-label': 'check in title',
            ref: register,
          }}
          fullWidth
        />
        <Divider />
        <Title>Step {step}</Title>
        <TextField
          name={`checkIn.step${step}Label`}
          label="Label"
          defaultValue={template.checkIn[`step${step}Label`]}
          inputProps={{
            'aria-label': 'step label',
            ref: register,
          }}
          fullWidth
        />
      </SettingsPanel>
      <StylingPanel>
        <Title>Title</Title>
        <Controller
          name="checkIn.titleColor"
          defaultValue={template.checkIn.titleColor}
          control={control}
          render={({value, onChange}) => (
            <ColorPicker
              label="Color"
              color={value}
              onPick={onChange}
              aria-label="check in title color"
            />
          )}
        />
        <Title>Step</Title>
        <Controller
          name="checkIn.stepLabelColor"
          defaultValue={template.checkIn.stepLabelColor}
          control={control}
          render={({value, onChange}) => (
            <ColorPicker
              label="Color"
              color={value}
              onPick={onChange}
              aria-label="step label color"
            />
          )}
        />
      </StylingPanel>
    </Config>
  )
}

import {IconBlock} from 'Event/Marketplace/Icon'

export const createIconBlock = (): IconBlock => ({
  type: 'Icon',
  visibility: 'all',
  background: {
    image: '',
    color: '',
    opacity: 0,
  },
  padding: {
    top: 10,
    right: 10,
    bottom: 10,
    left: 10,
  },
  alignment: {
    vertical: 'center',
    horizontal: 'center',
  },
  border: {
    width: 0,
    color: '#FFFFFF',
    radius: 0,
  },
  size: 60,
  color: '#3490DC',
  opacity: 100,
  icon: null,
})

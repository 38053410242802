import {CURRENCY} from 'Event/Question/Currency'
import {CurrencyConfig} from 'Event/Question/Currency/CurrencyConfig'
import {LONG_ANSWER_TEXT} from 'Event/Question/LongAnswerText'
import {LongAnswerTextConfig} from 'Event/Question/LongAnswerText/LongAnswerTextConfig'
import {RADIO} from 'Event/Question/Radio'
import {RadioConfig} from 'Event/Question/Radio/RadioConfig'
import {SELECT} from 'Event/Question/Select'
import {SelectConfig} from 'Event/Question/Select/SelectConfig'
import {SELECT_PRIORITY} from 'Event/Question/SelectPriority'
import SelectPriorityConfig from 'Event/Question/SelectPriority/SelectPriorityConfig'
import {SHORT_ANSWER_TEXT} from 'Event/Question/ShortAnswerText'
import {ShortAnswerTextConfig} from 'Event/Question/ShortAnswerText/ShortAnswerTextConfig'
import {Option, Question} from 'organization/Event/QuestionsProvider'
import React from 'react'
import {DeepMap, FieldError, UseFormMethods} from 'react-hook-form'

export type QuestionTypeConfigProps = {
  questionType: Question['type']
  question?: Question
  register: UseFormMethods['register']
  control: UseFormMethods['control']
  errors: DeepMap<
    {
      options: Option[]
      type: string
    },
    FieldError
  >
}

/**
 * Config fields specific to the selected question type.
 *
 * @param props
 * @returns
 */
export default function QuestionTypeConfig(props: QuestionTypeConfigProps) {
  const {questionType} = props

  switch (questionType) {
    case SELECT:
      return <SelectConfig {...props} />
    case RADIO:
      return <RadioConfig {...props} />
    case SELECT_PRIORITY:
      return <SelectPriorityConfig {...props} />
    case SHORT_ANSWER_TEXT:
      return <ShortAnswerTextConfig {...props} />
    case LONG_ANSWER_TEXT:
      return <LongAnswerTextConfig {...props} />
    case CURRENCY:
      return <CurrencyConfig {...props} />
    default:
      // No type specific configs / fields required.
      return null
  }
}

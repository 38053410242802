import {Groups} from 'Event/attendee'

import {
  GroupRule,
  IS_BLANK,
  IS_FILLED,
  IS_NOT,
} from 'Event/attendee-rules/RuleConfig/RuleList/SingleRule/GroupRule'

export function matchesGroupRule(groups: Groups, rule: GroupRule) {
  const lowedGroups = lowerCaseGroups(groups)
  const lowedKey = rule.key.toLowerCase()
  const group = lowedGroups[lowedKey]
  const groupRule = rule.target?.toLowerCase()

  if (rule.type === IS_NOT) {
    return group !== groupRule
  }

  if (rule.type === IS_FILLED) {
    return Boolean(group)
  }

  if (rule.type === IS_BLANK) {
    return !group
  }

  return group === groupRule
}

function lowerCaseGroups(groups: Groups) {
  return Object.fromEntries(
    Object.entries(groups).map(([k, v]) => [
      k.toLowerCase(),
      typeof v === 'string' ? v.toLowerCase() : v,
    ]),
  )
}

import {useTemplate} from 'Event/TemplateProvider'
import {useSaveTemplate} from 'Event/TemplateUpdateProvider'
import TextField from 'lib/ui/TextField'
import {useConfig} from 'organization/Event/Configurable'
import Config from 'organization/Event/Configurable/Config'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import StylingPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/StylingPanel'
import React from 'react'
import {StyleFields} from 'Event/auth/Login/SubmitButton/SubmitButtonConfig'

export default function SubmitButtonConfig() {
  const save = useSaveTemplate()
  const {signUp} = useTemplate()

  const {
    form: {register},
  } = useConfig()

  return (
    <Config title="Submit Button" onSave={save}>
      <SettingsPanel>
        <TextField
          label="Submit Label"
          name="signUp.buttonText"
          defaultValue={signUp.buttonText}
          inputProps={{
            'aria-label': 'submit button label',
            ref: register,
          }}
        />
      </SettingsPanel>
      <StylingPanel>
        <StyleFields />
      </StylingPanel>
    </Config>
  )
}

import {useTrackEventPage} from 'analytics'
import {User} from 'auth/user'
import {useAttendee} from 'Event/auth'
import {NavButtonProps} from 'Event/Dashboard/components/NavButton'
import PagePoints, {
  SPONSORS as POINTS_SPONSORS,
} from 'Event/PointsProvider/PagePoints'
import {components} from 'Event/template'
import {useTemplate} from 'Event/TemplateProvider'
import {HashMap, Ordered} from 'lib/list'
import React from 'react'
import {HasRules} from 'Event/attendee-rules'
import {useEditMode} from 'Event/EditModeProvider'
import VisibleOnMatch from 'Event/attendee-rules/VisibleOnMatch'
import Scripts, {SPONSORS as SCRIPTS_SPONSORS} from 'Event/Scripts'

export interface Sponsor extends Ordered, HasRules {
  name: string
  description: string
  formId: number | null
  buttons: HashMap<NavButtonProps>
  image: string | null
  isActive?: boolean
}

export type SponsorButtons = NonNullable<NonNullable<Sponsor['buttons']>>

export default function Sponsors() {
  useTrackEventPage({
    page: 'Visited Sponsors',
  })

  const attendee = useAttendee()

  return (
    <PagePoints page={POINTS_SPONSORS}>
      <>
        <Scripts page={SCRIPTS_SPONSORS} />
        <TemplateSponsors user={attendee} />
      </>
    </PagePoints>
  )
}

export function TemplateSponsors(props: {user: User}) {
  const template = useTemplate()
  const Component = components[template.name].Sponsors.index
  return <Component user={props.user} />
}

export function VisibleSponsor(props: {
  children: JSX.Element
  sponsor: Sponsor
}) {
  const {sponsor, children} = props

  // Always render Sponsor in edit mode...
  const isEditMode = useEditMode()
  if (isEditMode) {
    return children
  }

  if (sponsor.isActive === false) {
    return null
  }

  return (
    <VisibleOnMatch rules={props.sponsor.rules}>
      {props.children}
    </VisibleOnMatch>
  )
}

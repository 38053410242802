import React, {useCallback} from 'react'
import {useAttendee, useIsAttendee} from 'Event/auth'
import {useTemplate} from 'Event/TemplateProvider'
import {Attendee} from 'Event/attendee'
import {useTrackEventPage} from 'analytics'
import {useEvent} from 'Event/EventProvider'
import {api} from 'lib/url'
import {useAsync} from 'lib/async'
import {components} from 'Event/template'
import Scripts, {LEADERBOARD} from 'Event/Scripts'

export interface Entry {
  attendee: Pick<Attendee, 'id' | 'first_name' | 'last_name' | 'email'>
  score: number
}

export default function Leaderboard() {
  const {name} = useTemplate()
  const user = useAttendee()

  useTrackEventPage({
    page: 'Visited Leaderboard',
  })

  const Component = components[name].Leaderboard.index

  return (
    <>
      <Scripts page={LEADERBOARD} />
      <Component user={user} />
    </>
  )
}

export function useEntries() {
  const {client, event} = useEvent()
  const url = api(`/events/${event.id}/leaderboard`)

  const isAttendee = useIsAttendee()

  // If we're configuring the dashboard, we don't want to actually make a
  // request, so we'll just stub out empty entries.
  const emptyRequest = useCallback(() => Promise.resolve<Entry[]>([]), [])
  const liveRequest = useCallback(() => client.get<Entry[]>(url), [client, url])

  const request = isAttendee ? liveRequest : emptyRequest

  const {data, loading} = useAsync(request)

  return {
    entries: data || [],
    loading,
  }
}

import React from 'react'
import {RelativeLink} from 'lib/ui/link/RelativeLink'
import PageHeader from 'lib/ui/PageHeader'
import NewItemButton from 'lib/ui/PageHeader/NewItemButton'
import Title from 'lib/ui/PageHeader/Title'
import Page from 'organization/Event/Page'
import TicketFilter from 'organization/Marketplace/Tickets/TicketFilter'
import TicketListing from 'organization/Marketplace/Tickets/TicketListing'
import {useEventRoutes} from 'organization/Event/EventRoutes'
import PageBreadcrumbs from 'organization/Event/Page/PageBreadcrumbs'
import {Redirect} from 'react-router-dom'
import {useOrganization} from 'organization/OrganizationProvider'
import {useMarketplaceAccount} from 'lib/marketplace-api/accounts/use-marketplace-account'

export default function Tickets() {
  const eventRoutes = useEventRoutes()
  const {data: account} = useMarketplaceAccount()
  const {routes} = useOrganization()

  if (!account) {
    return null
  }

  if (!account.completed_setup) {
    return <Redirect to={routes.settings} />
  }

  return (
    <PageBreadcrumbs page="Tickets">
      <Page>
        <PageHeader>
          <Title text="Marketplace Tickets" />
          <RelativeLink disableStyles to={eventRoutes.marketplace.tickets.add}>
            <NewItemButton
              aria-label="new ticket"
              text="New Ticket"
              color="primary"
            />
          </RelativeLink>
        </PageHeader>
        <TicketFilter />
        <TicketListing />
      </Page>
    </PageBreadcrumbs>
  )
}

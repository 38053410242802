import React from 'react'
import 'react-grid-layout/css/styles.css'
import 'react-resizable/css/styles.css'
import ConfigurableSection from 'organization/Marketplace/PurchasePageConfig/ConfigurableSection'
import Configurable from 'organization/Event/Configurable'
import SectionConfig from 'organization/Marketplace/PurchasePageConfig/SectionConfig'
import {createPositions, orderedIdsByPosition} from 'lib/list'
import {useUpdateTemplate} from 'organization/Marketplace/PurchasePageConfig/TemplateUpdateProvider'
import {uuid} from 'lib/uuid'
import {PurchasePageTemplate} from 'Event/Marketplace/purchase-page'

interface MoveableSectionProps {
  sectionId: string
  canMoveUp: boolean
  canMoveDown: boolean
  template: PurchasePageTemplate
}

export default function MoveableSection(props: MoveableSectionProps) {
  const {sectionId, canMoveDown, canMoveUp, template} = props
  const updateTemplate = useUpdateTemplate()

  const {moveUp, moveDown} = useMoveSection(sectionId, template)
  const {sections} = template

  const copy = () => {
    updateTemplate({
      sections: {
        [uuid()]: sections[sectionId],
      },
    })
  }

  return (
    <Configurable
      editableProps={{
        moveDown: canMoveDown ? moveDown : () => {},
        moveUp: canMoveUp ? moveUp : () => {},
        onCopy: copy,
        copyRightSpacing: 27,
      }}
      aria-label="section config"
    >
      <SectionConfig id={sectionId} section={sections[sectionId]} />
      <ConfigurableSection
        section={sections[sectionId]}
        id={sectionId}
        key={sectionId}
        template={template}
      />
    </Configurable>
  )
}

function useMoveSection(sectionId: string, template: PurchasePageTemplate) {
  const {sections} = template
  const updateTemplate = useUpdateTemplate()

  const ids = orderedIdsByPosition(sections)
  const index = ids.indexOf(sectionId)
  const moved = Array.from(ids)

  const insertAt = (newIndex: number) => {
    const [removed] = moved.splice(index, 1)
    moved.splice(newIndex, 0, removed)
    updateTemplate({
      sections: createPositions(moved),
    })
  }

  const moveUp = () => insertAt(index - 1)
  const moveDown = () => insertAt(index + 1)

  return {
    moveUp,
    moveDown,
  }
}

import {useToggleArray} from 'lib/toggle'
import Button from 'lib/ui/Button'
import NewScriptDialog from 'organization/Event/Scripts/AddScriptButton/NewScriptDialog'
import React from 'react'

export default function AddEmbedScriptButton() {
  const [showingDialog, toggleDialog] = useToggleArray()

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        onClick={toggleDialog}
        aria-label="add script"
      >
        Add
      </Button>
      <NewScriptDialog showing={showingDialog} onClose={toggleDialog} />
    </>
  )
}

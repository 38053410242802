import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import WidgetResize from '@ckeditor/ckeditor5-widget/src/widgetresize';
import CustomButtonResizeCommand from './custombuttonresizecommand';

export default class CustomButtonResize extends Plugin {
	/**
      * @inheritDoc
      */
	static get requires() {
		return [ WidgetResize ];
	}

	/**
      * @inheritDoc
      */
	static get pluginName() {
		return 'customButtonResize';
	}

	/**
      * @inheritDoc
      */
	init() {
		const editor = this.editor;
		const command = new CustomButtonResizeCommand( editor );

		this._registerSchema();
		this._registerConverters();

		editor.commands.add( 'customButtonResize', command );

		editor.editing.downcastDispatcher.on( 'insert:customButton', ( evt, data, conversionApi ) => {
			const widget = conversionApi.mapper.toViewElement( data.item );

			const resizer = editor.plugins
				.get( WidgetResize )
				.attachTo( {
					unit: editor.config.get( 'customButton.resizeUnit' ) || 'px',

					modelElement: data.item,
					viewElement: widget,
					editor,

					getHandleHost( domWidgetElement ) {
						return domWidgetElement;
					},
					getResizeHost( domWidgetElement ) {
						return domWidgetElement;
					},
					// TODO consider other positions.
					isCentered() {
						const customButtonStyle = data.item.getAttribute( 'customButtonStyle' );

						return !customButtonStyle || customButtonStyle == 'full' || customButtonStyle == 'alignCenter';
					},

					onCommit( newValue ) {
						editor.execute( 'customButtonResize', { width: newValue } );
					}
				} );

			resizer.on( 'updateSize', () => {
				if ( !widget.hasClass( 'customButton_resized' ) ) {
					editor.editing.view.change( writer => {
						writer.addClass( 'customButton_resized', widget );
					} );
				}
			} );

			resizer.bind( 'isEnabled' ).to( command );
		}, { priority: 'low' } );
	}

	/**
      * @private
      */
	_registerSchema() {
		this.editor.model.schema.extend( 'customButton', {
			allowAttributes: 'width'
		} );
	}

	/**
      * Registers customButton resize converters.
      *
      * @private
      */
	_registerConverters() {
		const editor = this.editor;

		editor.conversion.for( 'downcast' ).add( dispatcher =>
			dispatcher.on( 'attribute:width:customButton', ( evt, data, conversionApi ) => {
				if ( !conversionApi.consumable.consume( data.item, evt.name ) ) {
					return;
				}

				const viewWriter = conversionApi.writer;
				const figure = conversionApi.mapper.toViewElement( data.item );

				if ( data.attributeNewValue !== null ) {
					viewWriter.setStyle( 'width', data.attributeNewValue, figure );
					viewWriter.addClass( 'customButton_resized', figure );
				} else {
					viewWriter.removeStyle( 'width', figure );
					viewWriter.removeClass( 'customButton_resized', figure );
				}
			} )
		);

		editor.conversion.for( 'upcast' )
			.attributeToAttribute( {
				view: {
					name: 'a',
					styles: {
						width: /.+/
					}
				},
				model: {
					key: 'width',
					value: viewElement => viewElement.getStyle( 'width' )
				}
			} );
	}
}

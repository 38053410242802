import {useBreadcrumbs} from 'lib/ui/BreadcrumbProvider'
import {RelativeLink} from 'lib/ui/link/RelativeLink'
import React from 'react'
import styled from 'styled-components'
import MuiBreadcrumbs from '@material-ui/core/Breadcrumbs'
import chevronRight from 'lib/ui/AppBar/icons/chevron-right.svg'
import {isLast} from 'lib/array'

export default function Breadcrumbs(props: {className?: string}) {
  const {breadcrumbs} = useBreadcrumbs()

  return (
    <StyledBreadcrumbs
      aria-label="breadcrumb"
      className={props.className}
      separator={<img src={chevronRight} alt="arrow right" />}
    >
      {breadcrumbs.map((breadcrumb, index) => (
        <Breadcrumb
          key={index}
          to={breadcrumb.url}
          isActive={isLast(index, breadcrumbs)}
          aria-label={index === 0 ? 'home link' : ''}
        >
          {breadcrumb.title}
        </Breadcrumb>
      ))}
    </StyledBreadcrumbs>
  )
}

const StyledBreadcrumbs = styled(MuiBreadcrumbs)`
  li {
    color: #ffffff;
  }
`

function Breadcrumb(props: {
  to?: string
  isActive: boolean
  'aria-label'?: string
  children: string
}) {
  if (props.to) {
    return <BreadcrumbLink {...props} to={props.to} />
  }

  return <StaticText {...props} />
}

const BreadcrumbLink = styled(RelativeLink)<{
  isActive: boolean
}>`
  color: ${(props) =>
    props.isActive
      ? props.theme.colors.text.primary
      : props.theme.colors.text.muted};
`

const StaticText = styled.span<{
  isActive: boolean
}>`
  color: ${(props) =>
    props.isActive
      ? props.theme.colors.text.primary
      : props.theme.colors.text.muted};
  cursor: default;
`

import React from 'react'
import {Answer} from 'Event/SubmissionsProvider'
import {ValidationError} from 'lib/ui/api-client'
import {Form} from 'organization/Event/FormsProvider'
import Question from 'Event/Question'
import {UseFormMethods} from 'react-hook-form'
import {Question as QuestionProps} from 'organization/Event/QuestionsProvider'

// TODO: Remove temporary event code for 'cap' June, 2022.

// Hardcoded question ids to check
const ARE_YOU_A_CERTIFIED_LIFE_COACH_ID = '6329'
const BY_WHOM_WERE_YOU_CERTIFIED_ID = '6330'
const DO_YOU_HAVE_NICHE_ID = '6345'
const WHAT_IS_YOUR_NICHE_ID = '6346'
const ARE_YOU_COACHING_ID = '6347'
const HOW_MANY_CLIENTS_COACHING_ID = '6348'
const BIGGEST_CHALLENGE_ID = '6350'
const OTHER_CHALLENGE_ID = '6351'

export default function CapWaiverForm(props: {
  form: Form
  formErrors: UseFormMethods['errors']
  control: UseFormMethods['control']
  register: UseFormMethods['register']
  responseError: ValidationError<any>
  setValue: UseFormMethods['setValue']
  answers: Answer[]
  watch: UseFormMethods['watch']
}) {
  const {
    form,
    formErrors,
    control,
    register,
    responseError,
    setValue,
    answers,
    watch,
  } = props

  const currentAnswers: {question_id: string; value: string}[] =
    watch('answers') || []

  const answer = (questionId: string) =>
    currentAnswers.find((a) => a.question_id === questionId)?.value

  const isQuestion = (questionId: string, q: QuestionProps) =>
    `${q.id}` === questionId

  const questions = form.questions.filter((q) => {
    if (isQuestion(BY_WHOM_WERE_YOU_CERTIFIED_ID, q)) {
      return answer(ARE_YOU_A_CERTIFIED_LIFE_COACH_ID) === 'Yes'
    }

    if (isQuestion(WHAT_IS_YOUR_NICHE_ID, q)) {
      return answer(DO_YOU_HAVE_NICHE_ID) === 'Yes'
    }

    if (isQuestion(HOW_MANY_CLIENTS_COACHING_ID, q)) {
      return answer(ARE_YOU_COACHING_ID) === 'Yes'
    }

    if (isQuestion(OTHER_CHALLENGE_ID, q)) {
      return answer(BIGGEST_CHALLENGE_ID)?.includes('Other')
    }

    return true
  })

  return (
    <>
      {questions.map((question, index) => (
        <Question
          formErrors={formErrors}
          key={index}
          index={index}
          control={control}
          question={question}
          answers={answers}
          register={register}
          responseError={responseError}
          setValue={setValue}
          watch={watch}
        />
      ))}
    </>
  )
}

import React from 'react'
import ReactDOM from 'react-dom'
import 'normalize.css'
import App from 'App'
import StoreProvider from 'store/StoreProvider'
import Providers from 'Providers'
import * as Sentry from '@sentry/react'

import 'lib/fonts/arial/font.css'
import 'lib/fonts/courier-new/font.css'
import 'lib/fonts/montserrat/font.css'
import 'lib/fonts/open-sans/font.css'
import 'lib/fonts/oswald/font.css'
import 'lib/fonts/pacifico/font.css'
import 'lib/fonts/permanent-marker/font.css'
import 'lib/fonts/poppins/font.css'
import 'lib/fonts/roboto/font.css'
import 'lib/fonts/shadows-into-light/font.css'
import 'lib/fonts/times-new-roman/font.css'
import 'lib/fonts/ubuntu/font.css'
import 'lib/fonts/verdana/font.css'
import 'lib/fonts/water-brush/font.css'
import {isProduction} from 'env'

const release = process.env.REACT_APP_RELEASE_VERSION || 'latest'

if (isProduction) {
  Sentry.init({
    dsn:
      'https://39c143b873174b08bfc9a2487d3a3140@o4505051487469568.ingest.sentry.io/4505051488321536',
    integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
    tracesSampleRate: 1.0,
    replaysOnErrorSampleRate: 1.0,
    release,
    environment: 'production',
    ignoreErrors: [
      // Ignore stripe errors that happen intermittently when a user navigates away
      // Reference: https://github.com/stripe/stripe-js/issues/26#issuecomment-791468166
      /failed to load stripe/i,
      /live stripe\.js integrations must use https/i,
      'Network Error',
    ],
    allowUrls: ['obv.io'],
  })
}

ReactDOM.render(
  <Providers storeProvider={StoreProvider}>
    <App />
  </Providers>,
  document.getElementById('root'),
)

import {BlockComponentProps} from 'Event/Marketplace/Block'
import {PurchaseFormBlock} from 'Event/Marketplace/Block/PurchaseForm'
import React from 'react'

interface NextStepButtonProps extends PurchaseFormBlock, BlockComponentProps {
  disabled?: boolean
  onClick: () => void
  ButtonComponent: React.FC<any>
}

export default function NextStepButton(props: NextStepButtonProps) {
  const {onClick, disabled, ButtonComponent, ...config} = props

  return (
    <ButtonComponent
      config={config}
      text={config.nextStepButton.text}
      disabled={disabled}
      onClick={onClick}
      button={config.nextStepButton}
      aria-label="next step button"
      type="button"
    />
  )
}

import CustomButtonAlignmentCommand from './custombuttonalignmentcommand';
import Plugin from '@ckeditor/ckeditor5-core/src/plugin';

export default class CustomButtonAlignmentEditing extends Plugin {
	static get pluginName() {
		return 'CustomButtonAlignmentEditing';
	}

	init() {
		this.editor.commands.add( 'customButtonAlignment', new CustomButtonAlignmentCommand( this.editor ) );
	}
}


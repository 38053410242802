import {useGuestVariables} from 'Event'
import TextField from 'Event/auth/ChangePassword/TextField'
import {useTemplate} from 'Event/TemplateProvider'
import Configurable from 'organization/Event/Configurable'
import React from 'react'
import NewPasswordFieldConfig from 'Event/auth/ChangePassword/NewPasswordField/NewPasswordFieldConfig'
import {ChangePasswordRequest} from 'auth/password'

export default function NewPasswordField(props: ChangePasswordRequest) {
  const v = useGuestVariables()
  const template = useTemplate()

  const newPasswordLabel = v(template.changePassword.newPasswordLabel)

  return (
    <Configurable>
      <NewPasswordFieldConfig />
      <TextField
        label={newPasswordLabel}
        type="password"
        fullWidth
        variant="outlined"
        name="password"
        disabled={props.submitting}
        inputProps={{
          ref: props.register({
            required: `${newPasswordLabel} is required`,
          }),
          'aria-label': 'new password',
        }}
        error={!!props.errors.password}
        helperText={props.errors.password}
      />
    </Configurable>
  )
}

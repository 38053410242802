import {LoginProps} from 'Event/auth/Login'
import styled from 'styled-components'
import React from 'react'
import {ForgotPasswordLinkBase} from 'Event/auth/Login/ForgotPasswordLink'
import LeftPanel from 'Event/template/NiftyFifty/Login/LeftPanel/index'
import RightPanel from 'Event/template/NiftyFifty/Login/RightPanel'
import MobilePanel from 'Event/template/NiftyFifty/Login/MobilePanel'
import Page from 'Event/template/NiftyFifty/Page'

export default function Login(props: LoginProps) {
  return (
    <Page
      Left={<LeftPanel />}
      Right={<RightPanel {...props} />}
      Mobile={<MobilePanel {...props} />}
    />
  )
}

export const StyledRelativeLink = styled(ForgotPasswordLinkBase)<{
  color: string
}>`
  margin-top: ${(props) => props.theme.spacing[10]};
`

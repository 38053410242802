import {useConfig} from 'organization/Event/Configurable'
import Config from 'organization/Event/Configurable/Config'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import {useUpdateTemplate} from 'organization/Marketplace/PurchasePageConfig/TemplateUpdateProvider'
import React, {useState} from 'react'
import {Controller} from 'react-hook-form'
import ColorPicker from 'lib/ui/ColorPicker'
import CollapsibleSection from 'lib/ui/ConfigPanel/CollapsibleSection'
import Slider from 'lib/ui/Slider'
import StylingPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/StylingPanel'
import Select from 'lib/ui/Select'
import Option from 'lib/ui/Select/Option'
import {onUnknownChangeHandler} from 'lib/dom'
import {REMOVE} from 'Event/TemplateUpdateProvider'
import VisibilitySetting from 'organization/Marketplace/PurchasePageConfig/BlockConfig/VisibilitySettings'
import {SeparatorBlock} from 'Event/Marketplace/Block/Separator'

interface SeparatorConfigProps extends SeparatorBlock {
  id: string
  sectionId: string
}

export default function SeparatorConfig(props: SeparatorConfigProps) {
  const {
    padding,
    alignment,
    id,
    sectionId,
    separatorStyle,
    color,
    height,
    opacity,
    width,
    radius,
  } = props
  const updateTemplate = useUpdateTemplate()
  const {
    form: {control},
  } = useConfig()
  const [visibility, setVisibility] = useState(props.visibility)

  const handleRemove = () => {
    updateTemplate({
      sections: {
        [sectionId]: {
          blocks: {
            [id]: REMOVE,
          },
        },
      },
    })
  }

  const save = (data: any) => {
    data.sections[sectionId].blocks[id].visibility = visibility
    updateTemplate(data)
  }

  return (
    <Config title="Separator Block" onSave={save} onRemove={handleRemove}>
      <VisibilitySetting
        visibility={visibility}
        setVisibility={setVisibility}
      />
      <SettingsPanel>
        <Controller
          name={`sections.${sectionId}.blocks.${id}.separatorStyle`}
          defaultValue={separatorStyle}
          control={control}
          render={({value, onChange}) => (
            <Select
              fullWidth
              label="Separator Style"
              value={value}
              onChange={onUnknownChangeHandler(onChange)}
            >
              <Option value="solid">Solid</Option>
              <Option value="dotted">Dotted</Option>
              <Option value="dashed">Dashed</Option>
            </Select>
          )}
        />
      </SettingsPanel>
      <StylingPanel>
        <CollapsibleSection
          label="Separator"
          storageKey={`blocks-${id}-styling`}
        >
          <Controller
            name={`sections.${sectionId}.blocks.${id}.color`}
            defaultValue={color}
            control={control}
            render={({value, onChange}) => (
              <ColorPicker
                label="Color"
                color={value}
                onPick={onChange}
                aria-label="separator color"
              />
            )}
          />
          <Controller
            name={`sections.${sectionId}.blocks.${id}.opacity`}
            defaultValue={opacity}
            control={control}
            render={({value, onChange}) => (
              <Slider
                label="Opacity"
                unit="%"
                valueLabelDisplay="auto"
                aria-label="separator opacity"
                value={value}
                onChange={onChange}
                step={1}
                min={0}
                max={100}
              />
            )}
          />
          <Controller
            name={`sections.${sectionId}.blocks.${id}.height`}
            defaultValue={height}
            control={control}
            render={({value, onChange}) => (
              <Slider
                label="Height"
                unit="px"
                valueLabelDisplay="auto"
                aria-label="separator height"
                value={value}
                onChange={onChange}
                step={1}
                min={1}
                max={100}
              />
            )}
          />
          <Controller
            name={`sections.${sectionId}.blocks.${id}.width`}
            defaultValue={width}
            control={control}
            render={({value, onChange}) => (
              <Slider
                label="Width"
                unit="%"
                valueLabelDisplay="auto"
                aria-label="separator width"
                value={value}
                onChange={onChange}
                step={1}
                min={1}
                max={100}
              />
            )}
          />
          <Controller
            name={`sections.${sectionId}.blocks.${id}.radius`}
            defaultValue={radius}
            control={control}
            render={({value, onChange}) => (
              <Slider
                label="Border Radius"
                unit="%"
                valueLabelDisplay="auto"
                aria-label="separator border radius"
                value={value}
                onChange={onChange}
                step={1}
                min={1}
                max={100}
              />
            )}
          />
        </CollapsibleSection>
        <CollapsibleSection
          label="Alignment"
          storageKey={`blocks-${id}-alignment`}
        >
          <Controller
            name={`sections.${sectionId}.blocks.${id}.alignment.vertical`}
            defaultValue={alignment.vertical}
            control={control}
            render={({value, onChange}) => (
              <Select
                fullWidth
                label="Vertical"
                value={value}
                onChange={onUnknownChangeHandler(onChange)}
              >
                <Option value="start">Top</Option>
                <Option value="center">Center</Option>
                <Option value="end">Bottom</Option>
              </Select>
            )}
          />
          <Controller
            name={`sections.${sectionId}.blocks.${id}.alignment.horizontal`}
            defaultValue={alignment.horizontal}
            control={control}
            render={({value, onChange}) => (
              <Select
                fullWidth
                label="Horizontal"
                value={value}
                onChange={onUnknownChangeHandler(onChange)}
              >
                <Option value="left">Left</Option>
                <Option value="center">Center</Option>
                <Option value="right">Right</Option>
              </Select>
            )}
          />
        </CollapsibleSection>
        <CollapsibleSection
          label="Advanced Spacing"
          storageKey={`blocks-${id}-spacing`}
          open={false}
        >
          <Controller
            name={`sections.${sectionId}.blocks.${id}.padding.top`}
            defaultValue={padding.top}
            control={control}
            render={({value, onChange}) => (
              <Slider
                label="Top"
                value={value}
                onChange={onChange}
                step={1}
                min={0}
                max={100}
                aria-label="padding top"
              />
            )}
          />

          <Controller
            name={`sections.${sectionId}.blocks.${id}.padding.right`}
            defaultValue={padding.right}
            control={control}
            render={({value, onChange}) => (
              <Slider
                label="Right"
                value={value}
                onChange={onChange}
                step={1}
                min={0}
                max={100}
                aria-label="padding right"
              />
            )}
          />
          <Controller
            name={`sections.${sectionId}.blocks.${id}.padding.bottom`}
            defaultValue={padding.bottom}
            control={control}
            render={({value, onChange}) => (
              <Slider
                label="Bottom"
                value={value}
                onChange={onChange}
                step={1}
                min={0}
                max={100}
                aria-label="padding bottom"
              />
            )}
          />
          <Controller
            name={`sections.${sectionId}.blocks.${id}.padding.left`}
            defaultValue={padding.left}
            control={control}
            render={({value, onChange}) => (
              <Slider
                label="Left"
                value={value}
                onChange={onChange}
                step={1}
                min={0}
                max={100}
                aria-label="padding left"
              />
            )}
          />
        </CollapsibleSection>
      </StylingPanel>
    </Config>
  )
}

import {alpha, hex, rgba} from 'lib/color'
import ColorPicker from 'lib/ui/ColorPicker'
import {BackgroundPickerProps} from 'lib/ui/form/BackgroundPicker'
import Slider from 'lib/ui/Slider'
import React, {useEffect, useState} from 'react'
import {
  isGradient,
  parseValues as parseGradientValues,
} from 'lib/ui/form/BackgroundPicker/GradientColorPicker'

export default function SolidColorPicker(props: BackgroundPickerProps) {
  const {background, onChange} = props

  const [color, setColor] = useState(parseHex(background))

  const currentOpacityPercent = alpha(background) * 100
  const [opacityPercent, setOpacityPercent] = useState(currentOpacityPercent)

  useEffect(() => {
    if (!color) {
      onChange('')
      return
    }
    const opacity = opacityPercent / 100
    const value = opacity === 1 ? color : rgba(color, opacity)

    const updated = value !== background

    if (!updated) {
      return
    }

    onChange(value)
  }, [opacityPercent, color, background, onChange])

  return (
    <>
      <ColorPicker
        label="Color"
        disabled={props.disabled}
        color={color}
        onPick={setColor}
        aria-label="solid background"
      />
      <Slider
        min={0}
        max={100}
        step={10}
        onChange={setOpacityPercent}
        valueLabelDisplay="off"
        label="Opacity"
        unit="%"
        value={opacityPercent}
        aria-label="background opacity"
      />
    </>
  )
}

function parseHex(color?: string) {
  if (isGradient(color)) {
    const {color1} = parseGradientValues(color)
    return color1
  }

  return hex(color)
}

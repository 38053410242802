import React from 'react'
import styled from 'styled-components'
import {createPositions, orderedIdsByPosition} from 'lib/list'
import {
  DragDropContext,
  Droppable,
  DroppableProvidedProps,
  DropResult,
} from 'react-beautiful-dnd'
import Grid from '@material-ui/core/Grid'
import CustomButton from 'Event/Step3/TechCheck/Buttons/CustomButtons/CustomButton'
import NewCustomButton from 'Event/Step3/TechCheck/Buttons/CustomButtons/NewCustomButton'
import {useEditMode} from 'Event/EditModeProvider'
import {useTemplate} from 'Event/TemplateProvider'
import {useSaveTemplate} from 'Event/TemplateUpdateProvider'

export default function CustomButtons() {
  const isEditMode = useEditMode()
  const {
    techCheck: {customButtons},
  } = useTemplate()

  const sortedIds = orderedIdsByPosition(customButtons)

  const buttons = sortedIds.map((id, index) => (
    <CustomButton id={id} index={index} key={id} button={customButtons[id]} />
  ))

  if (isEditMode) {
    return <Draggable>{buttons}</Draggable>
  }

  return <Container>{buttons}</Container>
}

function Draggable(props: {children: JSX.Element[]}) {
  const handleDrag = useHandleDrag()

  return (
    <>
      <DragDropContext onDragEnd={handleDrag}>
        <Droppable droppableId="tech_check_buttons">
          {(provided) => (
            <Container ref={provided.innerRef} {...provided.droppableProps}>
              <>
                {props.children}
                {provided.placeholder}
              </>
            </Container>
          )}
        </Droppable>
      </DragDropContext>
      <NewCustomButton />
    </>
  )
}

const Container = React.forwardRef<
  HTMLDivElement,
  {
    className?: string
    children: React.ReactElement | React.ReactElement[]
  } & Partial<DroppableProvidedProps>
>((props, ref) => (
  <Box className={props.className} ref={ref} {...props}>
    <Grid container justify="center" spacing={2}>
      {props.children}
    </Grid>
  </Box>
))

function useHandleDrag() {
  const {
    techCheck: {customButtons},
  } = useTemplate()
  const save = useSaveTemplate()

  return (result: DropResult) => {
    const {destination, source} = result

    if (!destination) {
      return
    }

    const ids = orderedIdsByPosition(customButtons)
    const moved = Array.from(ids)

    const [removed] = moved.splice(source.index, 1)
    moved.splice(destination.index, 0, removed)

    save({
      techCheck: {
        customButtons: createPositions(moved),
      },
    })
  }
}

const Box = styled.div`
  margin-bottom: ${(props) => props.theme.spacing[7]};
  margin-top: ${(props) => props.theme.spacing[7]};
  width: 100%;
`

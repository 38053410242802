import Header from 'lib/ui/layout/Header'
import {Title} from 'lib/ui/typography'
import AddBroadcastButton from 'organization/Event/Broadcasts/AddBroadcastButton'
import BroadcastsTable from 'organization/Event/Broadcasts/BroadcastsTable'
import {useEventRoutes} from 'organization/Event/EventRoutes'
import Page from 'organization/Event/Page'
import {CommunicationBreadcrumbs} from 'organization/Event/Page/PageBreadcrumbs'
import {
  SCHEDULE_BROADCASTS,
  usePermissions,
} from 'organization/PermissionsProvider'
import React from 'react'
import {Redirect} from 'react-router'

export default function Broadcasts() {
  const {can} = usePermissions()
  const routes = useEventRoutes()

  if (!can(SCHEDULE_BROADCASTS)) {
    return <Redirect to={routes.root} />
  }

  return (
    <CommunicationBreadcrumbs page="Broadcasts">
      <Page>
        <Header>
          <Title>Broadcasts</Title>
          <AddBroadcastButton />
        </Header>
        <BroadcastsTable />
      </Page>
    </CommunicationBreadcrumbs>
  )
}

import React from 'react'
import {useTemplate} from 'Event/TemplateProvider'
import {components} from 'Event/template'
import Scripts, {EVENT_OFFLINE_PAGE} from 'Event/Scripts'

export default function EventOfflinePage(props: {isPreview?: boolean}) {
  const template = useTemplate()
  const {isPreview} = props
  const {name, offlinePage: settings} = template

  if (settings?.shouldRedirect && settings?.redirectUrl && !isPreview) {
    window.location.href = settings.redirectUrl
    return null
  }

  const Component = components[name].EventOfflinePage.index

  return (
    <>
      <Scripts page={EVENT_OFFLINE_PAGE} />
      <Component
        isPreview={isPreview}
        title={settings.title}
        description={settings.description}
      />
    </>
  )
}

import {PurchaseFormData} from 'Event/Marketplace/Block/PurchaseForm/PurchaseFormProvider'
import {MarketplaceTicket} from 'lib/marketplace-api/tickets'
import {useEventTickets} from 'lib/marketplace-api/tickets/use-event-tickets'
import {useQueryParams} from 'lib/url'
import React, {useEffect, useMemo, useState} from 'react'

export interface PurchaseData {
  ticket_id: number
  first_name: string
  last_name: string
  email: string
  phone_number: string
  billing_address: {
    address_1: string
    address_2?: string
    country_id: string
    state_id: string
    city: string
    zip_postal: string
  }
  shipping_address: {
    address_1: string
    address_2?: string
    country_id: string
    state_id: string
    city: string
    zip_postal: string
  }
  payment_method_id: string | null
}

interface PurchaseProviderProps {
  children: JSX.Element
  onSubmit: (data: PurchaseData) => void
}

interface PurchaseContextProps {
  selectedTicket: MarketplaceTicket | null
  setSelectedTicket: (ticket: MarketplaceTicket | null) => void
  tickets: MarketplaceTicket[]
  submit: (data: PurchaseFormData) => void
}

const PurchaseFormContext = React.createContext<
  PurchaseContextProps | undefined
>(undefined)

export default function PurchaseProvider(props: PurchaseProviderProps) {
  const {children, onSubmit} = props
  const [
    selectedTicket,
    setSelectedTicket,
  ] = useState<MarketplaceTicket | null>(null)
  const {ticket: urlTicketSlug} = useQueryParams()

  const {data: tickets} = useEventTickets()

  const selectedTicketSlug = selectedTicket?.slug

  useEffect(() => {
    if (!urlTicketSlug) {
      return
    }

    const urlTicket = tickets?.find((ticket) => ticket.slug === urlTicketSlug)
    if (!urlTicket) {
      return
    }

    if (urlTicket.slug === selectedTicketSlug) {
      return
    }

    setSelectedTicket(urlTicket)
  }, [urlTicketSlug, selectedTicketSlug, tickets, setSelectedTicket])

  const submit = (data: PurchaseFormData) => {
    if (!selectedTicket) {
      return
    }

    onSubmit({
      ticket_id: selectedTicket.id,
      ...data,
    })
  }

  const visibleTickets = useMemo(() => {
    if (!tickets) {
      return []
    }

    return tickets.filter((ticket) => {
      const showing = ticket.active && ticket.is_available && !ticket.hidden

      if (urlTicketSlug) {
        return ticket.slug === urlTicketSlug && showing
      }

      return showing
    })
  }, [tickets, urlTicketSlug])

  // Auto-select a ticket if its the only one.
  useEffect(() => {
    if (!tickets) {
      return
    }

    if (selectedTicket) {
      return
    }

    if (visibleTickets.length === 1) {
      setSelectedTicket(tickets[0])
    }
  }, [tickets, selectedTicket, visibleTickets])

  return (
    <PurchaseFormContext.Provider
      value={{
        selectedTicket,
        setSelectedTicket,
        tickets: visibleTickets,
        submit,
      }}
    >
      {children}
    </PurchaseFormContext.Provider>
  )
}

export function usePurchase() {
  const context = React.useContext(PurchaseFormContext)
  if (context === undefined) {
    throw new Error('usePurchase must be used within a PurchaseProvider')
  }

  return context
}

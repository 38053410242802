import React from 'react'
import {ErrorMessage} from 'Event/template/NiftyFifty/Login/Page'
import Button from 'Event/auth/Login/SubmitButton/Button'
import Page from 'Event/template/NiftyFifty/Page'
import Text from 'Event/auth/Login/Description/Text'
import {ChangePasswordRequest} from 'auth/password/change'
import {useHistory} from 'react-router-dom'
import {eventRoutes} from 'Event/Routes'
import Container from '@material-ui/core/Container'
import LeftPanel from 'Event/template/NiftyFifty/Login/LeftPanel/index'
import styled from 'styled-components'
import {useNiftyFiftyTemplate} from 'Event/template/NiftyFifty'
import {rgba} from 'lib/color'
import MobilePanel from 'Event/template/NiftyFifty/ChangePassword/MobilePanel'
import Description from 'Event/auth/ChangePassword/Description'
import NewPasswordField from 'Event/auth/ChangePassword/NewPasswordField'
import ConfirmNewPasswordField from 'Event/auth/ChangePassword/ConfirmNewPasswordField'
import SubmitButton from 'Event/auth/ChangePassword/SubmitButton'
import {User} from 'auth/user'

export default function ChangePassword(props: {
  changePassword: ChangePasswordRequest
  user: User
}) {
  return (
    <Page
      Left={<LeftPanel />}
      Right={<RightPanel {...props} />}
      Mobile={<MobilePanel {...props} />}
    />
  )
}

export function RightPanel(props: {changePassword: ChangePasswordRequest}) {
  const template = useNiftyFiftyTemplate()
  const {login} = template

  return (
    <RightContainer
      backgroundColor={rgba(
        login.loginRightPanel.backgroundColor,
        login.loginRightPanel.backgroundOpacity,
      )}
    >
      <FormContainer>
        <Content {...props} />
      </FormContainer>
    </RightContainer>
  )
}

export function Content(props: {changePassword: ChangePasswordRequest}) {
  const {changePassword} = props
  const {responseError} = changePassword
  const history = useHistory()
  const goBackToDashboard = () => {
    history.push(eventRoutes.root)
  }

  if (changePassword.wasSuccessful) {
    return (
      <>
        <Text>Password Updated!</Text>
        <Button aria-label="back to dashboard" onClick={goBackToDashboard}>
          Go To Dashboard
        </Button>
      </>
    )
  }

  return (
    <>
      <Container maxWidth="md">
        <Description />
        <form onSubmit={changePassword.onSubmit}>
          <NewPasswordField {...changePassword} />
          <ConfirmNewPasswordField {...changePassword} />
          <ErrorMessage>{responseError?.message}</ErrorMessage>
          <SubmitButton {...changePassword} />
        </form>
      </Container>
    </>
  )
}

const RightContainer = styled.div<{
  backgroundColor: string
}>`
  width: 100%;
  height: 100%;
  margin: 0 auto;

  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  background: ${(props) => props.backgroundColor};
`

const FormContainer = styled.div`
  width: 80%;
`

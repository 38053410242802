import {useTemplate} from 'Event/TemplateProvider'
import React from 'react'
import BaseTextField from 'Event/auth/TextField'
import {TextFieldProps} from '@material-ui/core/TextField'

export default function TextField(props: TextFieldProps) {
  const {login} = useTemplate()

  return <BaseTextField {...props} {...login.input} />
}

import Container from '@material-ui/core/Container'
import {User} from 'auth/user'
import SimpleBlogPage from 'Event/template/SimpleBlog/Page'
import React from 'react'
import ProgressBar from 'Event/template/SimpleBlog/check-in/ProgressBar'
import Waivers from 'Event/Step2/Waivers'
import {useSimpleBlogTemplate} from 'Event/template/SimpleBlog'
import {DarkBackgroundTheme} from 'lib/ui/theme/ThemeProvider'

export default function Step2(props: {user: User}) {
  const template = useSimpleBlogTemplate()
  const {dashboardBackground} = template
  const {color, opacity} = dashboardBackground

  return (
    <SimpleBlogPage user={props.user}>
      <Container maxWidth="md">
        <DarkBackgroundTheme color={color} opacity={opacity}>
          <ProgressBar textKey="step2Text" percentKey="step2Percent" />
          <Waivers user={props.user} />
        </DarkBackgroundTheme>
      </Container>
    </SimpleBlogPage>
  )
}

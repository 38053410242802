import ColorPicker from 'lib/ui/ColorPicker'
import {useTownhallTemplate} from 'Event/template/Townhall'
import React from 'react'
import {Controller} from 'react-hook-form'
import CollapsibleSection from 'lib/ui/ConfigPanel/CollapsibleSection'
import Slider from 'lib/ui/Slider'
import MarginPaddingInputs from 'lib/ui/MarginPaddingInputs'
import Select from 'lib/ui/Select'
import Option from 'lib/ui/Select/Option'
import {onUnknownChangeHandler} from 'lib/dom'
import {useConfig} from 'organization/Event/Configurable'
import {DEFAULT_BUTTON_HEIGHT} from 'Event/Dashboard/components/NavButton'
import Label from 'lib/ui/TextField/Label'

export default function Styling() {
  const template = useTownhallTemplate()
  const {
    form: {control},
  } = useConfig()

  return (
    <>
      <CollapsibleSection
        label="Pane"
        storageKey="dashboard.header.left.pane.styling"
      >
        <Label>Padding</Label>
        <MarginPaddingInputs
          control={control}
          namePrefix="actionNav.padding"
          values={template.actionNav.padding}
          settings={{max: 300}}
        />
        <Controller
          name="actionNav.backgroundColor"
          defaultValue={template.actionNav.backgroundColor}
          control={control}
          render={({value, onChange}) => (
            <ColorPicker
              label="Background Color"
              color={value}
              onPick={onChange}
              aria-label="pane background color"
            />
          )}
        />
        <Controller
          name="actionNav.backgroundOpacity"
          defaultValue={template.actionNav.backgroundOpacity || 0}
          control={control}
          render={({value, onChange}) => (
            <Slider
              label="Background Opacity"
              min={0}
              max={100}
              step={1}
              onChange={onChange}
              valueLabelDisplay="auto"
              value={value}
              aria-label="pane background opacity"
              unit="%"
            />
          )}
        />
        <Controller
          name="actionNav.width"
          defaultValue={template.actionNav.width || 0}
          control={control}
          render={({value, onChange}) => (
            <Slider
              label="Width"
              min={0}
              max={100}
              step={1}
              onChange={onChange}
              valueLabelDisplay="auto"
              value={value}
              aria-label="paper width"
              unit="%"
            />
          )}
        />
        <Controller
          name="actionNav.align"
          defaultValue={template.actionNav.align}
          control={control}
          render={({value, onChange}) => (
            <Select
              fullWidth
              label="Alignment"
              value={value}
              onChange={onUnknownChangeHandler(onChange)}
            >
              <Option value="flex-start">Left</Option>
              <Option value="center">Center</Option>
              <Option value="flex-end">Right</Option>
            </Select>
          )}
        />
      </CollapsibleSection>
      <CollapsibleSection
        label="Separator"
        storageKey="dashboard.header.left.pane.separator.styling"
      >
        <Controller
          name="actionNav.separator.color"
          defaultValue={template.actionNav.separator.color}
          control={control}
          render={({value, onChange}) => (
            <ColorPicker
              label="Color"
              color={value}
              onPick={onChange}
              aria-label="pane separator color"
            />
          )}
        />
        <Controller
          name="actionNav.separator.width"
          defaultValue={template.actionNav.separator.width || 0}
          control={control}
          render={({value, onChange}) => (
            <Slider
              label="Thickness"
              min={0}
              max={10}
              step={1}
              onChange={onChange}
              valueLabelDisplay="auto"
              value={value}
              aria-label="pane separator width"
              unit="px"
            />
          )}
        />
      </CollapsibleSection>
      <Controller
        name="actionNav.buttonHeight"
        defaultValue={template.actionNav.buttonHeight || DEFAULT_BUTTON_HEIGHT}
        control={control}
        render={({value, onChange}) => (
          <Slider
            min={1}
            max={400}
            onChange={onChange}
            valueLabelDisplay="off"
            value={value}
            label="Button Height"
          />
        )}
      />
      <CollapsibleSection
        label="Border"
        storageKey="dashboard.header.left.pane.border.styling"
      >
        <Controller
          name="actionNav.border.color"
          control={control}
          defaultValue={template.actionNav.border.color || ''}
          render={({value, onChange}) => (
            <ColorPicker label="Color" color={value} onPick={onChange} />
          )}
        />
        <Controller
          name="actionNav.border.width"
          defaultValue={template.actionNav.border.width || 0}
          control={control}
          render={({value, onChange}) => (
            <Slider
              min={0}
              max={100}
              onChange={onChange}
              valueLabelDisplay="off"
              value={value}
              label="Width"
            />
          )}
        />
        <Controller
          name="actionNav.border.radius"
          defaultValue={template.actionNav.border.radius || 0}
          control={control}
          render={({value, onChange}) => (
            <Slider
              min={0}
              max={100}
              onChange={onChange}
              valueLabelDisplay="off"
              value={value}
              label="Radius"
            />
          )}
        />
      </CollapsibleSection>

      <Controller
        name="actionNav.vertical_alignment"
        defaultValue={template.actionNav.vertical_alignment}
        control={control}
        render={({value, onChange}) => (
          <Select
            fullWidth
            label="Vertical Alignment"
            value={value}
            onChange={onUnknownChangeHandler(onChange)}
          >
            <Option value="start">Top</Option>
            <Option value="center">Middle</Option>
            <Option value="end">Bottom</Option>
          </Select>
        )}
      />
    </>
  )
}

import React from 'react'
import styled from 'styled-components'
import {useOrganization} from 'organization/OrganizationProvider'
import {useEvent} from 'Event/EventProvider'
import {onUnknownChangeHandler} from 'lib/dom'
import {useToggle} from 'lib/toggle'
import CreateFieldInput from 'organization/Event/Services/Apps/Mailchimp/Config/LoginUrlFieldSelect/CreateFieldInput'
import Box from '@material-ui/core/Box'
import {
  MailchimpField,
  useMailchimpFields,
} from 'lib/event-api/integrations/mailchimp/fields/list'
import Select from 'lib/ui/Select'
import Option from 'lib/ui/Select/Option'
import Button, {ButtonProps} from 'lib/ui/Button'

export default function LoginUrlFieldSelect(props: {
  value?: string
  onChange: (loginFieldId: MailchimpField['id']) => void
}) {
  const {onChange, value = ''} = props

  const {event} = useEvent()
  const {client} = useOrganization()

  const {data: fields = [], isLoading} = useMailchimpFields({event, client})

  const {flag: showingNewFieldInput, toggle: toggleNewFieldInput} = useToggle()

  const handleSelectedField = (id: MailchimpField['id']) => {
    if (isLoading) {
      return
    }

    onChange(id)
  }

  return (
    <Box mb={4}>
      <ExistingFieldSelect showing={!showingNewFieldInput}>
        <Select
          disabled={isLoading}
          value={value}
          onChange={onUnknownChangeHandler(handleSelectedField)}
          fullWidth
          aria-label="pick login field"
          variant="outlined"
          label={'Login URL Field'}
        >
          {fields.map((field) => (
            <Option
              key={field.id}
              value={field.id}
              aria-label={`pick ${field.name}`}
            >
              {field.name}
            </Option>
          ))}
        </Select>
      </ExistingFieldSelect>
      <CreateFieldInput
        showing={showingNewFieldInput}
        onClose={toggleNewFieldInput}
        onAdd={(field) => handleSelectedField(field.id)}
      />
      <CreateFieldButton
        showing={!showingNewFieldInput}
        onClick={toggleNewFieldInput}
        aria-label="create new field"
        color="primary"
        variant="outlined"
        disabled={isLoading}
      >
        Create New Field
      </CreateFieldButton>
    </Box>
  )
}

const CreateFieldButton = styled((props: {showing: boolean} & ButtonProps) => {
  const {showing: _, ...otherProps} = props
  return <Button {...otherProps} />
})`
  display: ${(props) => (props.showing ? 'inline-flex' : 'none')};
`

const ExistingFieldSelect = styled.div<{showing: boolean}>`
  display: ${(props) => (props.showing ? 'block' : 'none')};
`

import CircularProgress from '@material-ui/core/CircularProgress'
import {colors} from 'lib/ui/theme'
import React from 'react'
import styled, {useTheme} from 'styled-components'

type ButtonStyles =
  | 'primary'
  | 'danger'
  | 'success'
  | 'info'
  | 'warning'
  | 'secondary'
  | 'light'
  | 'dark'
  | 'accent'
  | 'grey'
  | 'default'

const DEFAULT_FONT_SIZE = 14

export type ButtonProps = {
  children: React.ReactNode | React.ReactNode[] | string
  type?: 'button' | 'submit'
  variant?: 'text' | 'contained' | 'outlined'
  color?: ButtonStyles
  hoverBackgroundColor?: ButtonStyles
  className?: string
  autoFocus?: boolean
  fullWidth?: boolean
  disabled?: boolean
  width?: number
  fontSize?: number
  borderWidth?: number
  disableBorderRadius?: boolean
  disablePadding?: boolean
  startIcon?: React.ReactNode
  endIcon?: React.ReactNode
  onClick?: React.MouseEventHandler<HTMLButtonElement>
  dataTestId?: string
  'aria-label'?: string
  hidden?: boolean
  size?: 'small' | 'large' | 'medium'
  hasRipple?: boolean
  formId?: string
  isLoading?: boolean
}

export default function Button(props: ButtonProps) {
  const filled: ButtonProps = {
    color: 'default',
    variant: 'text',
    type: 'button',
    ...props,
  }

  return (
    <StyledButton
      className={filled.className}
      type={filled.type}
      backgroundColor={useBackgroundColor(filled)}
      hoverBackgroundColor={useHoverBackgroundColor(filled)}
      padding={usePadding(filled)}
      color={useTextColor(filled)}
      onClick={filled.onClick}
      borderColor={useBorderColor(filled)}
      autoFocus={filled.autoFocus}
      aria-label={filled['aria-label']}
      disabled={filled.disabled}
      fullWidth={filled.fullWidth}
      width={filled.width ? `${filled.width}%` : 'unset'}
      borderWidth={getBorderWidth(filled)}
      fontSize={fontSize(filled)}
      disableBorderRadius={Boolean(filled.disableBorderRadius)}
      hasBorder={hasBorder(filled)}
      display={display(filled)}
      hasRipple={Boolean(filled.hasRipple)}
      form={filled.formId}
    >
      <LeftIcon isLoading={filled.isLoading} startIcon={filled.startIcon} />
      {filled.children}
      <Icon paddingLeft>{filled.endIcon}</Icon>
    </StyledButton>
  )
}

function LeftIcon(props: {isLoading?: boolean; startIcon?: React.ReactNode}) {
  const {isLoading, startIcon} = props

  if (isLoading) {
    return (
      <Icon paddingRight>
        <CircularProgress color="inherit" size={12} />
      </Icon>
    )
  }

  return <Icon paddingRight>{startIcon}</Icon>
}

function useTextColor(props: ButtonProps) {
  const theme = useTheme()
  const outlinedColor = getVariantColor(props) || theme.colors.text.primary

  if (props.variant === 'outlined') {
    return outlinedColor
  }

  if (props.variant === 'text') {
    return theme.colors.text.primary
  }

  if (props.color === 'light') {
    return '#000000'
  }

  if (props.color === 'default') {
    return theme.colors.text.primary
  }

  return '#ffffff'
}

function useBackgroundColor(props: ButtonProps) {
  const theme = useTheme()
  const color = getVariantColor(props)

  if (props.variant === 'outlined') {
    return 'transparent'
  }

  if (props.variant === 'text') {
    return 'transparent'
  }

  return color ? color : theme.colors.button.background
}

function useBorderColor(props: ButtonProps) {
  const textColor = useTextColor(props)
  const backgroundColor = useBackgroundColor(props)

  if (props.variant === 'text') {
    return 'transparent'
  }
  if (props.variant === 'contained') {
    return backgroundColor
  }

  return textColor
}

function useHoverBackgroundColor(props: ButtonProps) {
  const theme = useTheme()
  const backgroundColor = useBackgroundColor(props)

  if (props.hasRipple) {
    const rippleHover = getHoverBackgroundColor(props, backgroundColor)
    return rippleHover
  }

  if (props.variant === 'outlined' || props.variant === 'text') {
    return theme.colors.button.hoverBackground
  }

  if (props.color === 'danger') {
    return colors.button.danger.hover
  }

  if (props.color === 'warning') {
    return colors.button.warning.hover
  }

  return backgroundColor
}

function getHoverBackgroundColor(props: ButtonProps, backgroundColor: string) {
  if (props.hoverBackgroundColor === 'secondary') {
    return colors.secondary
  }

  return backgroundColor
}

function getVariantColor(props: ButtonProps) {
  if (props.color === 'danger') {
    return colors.button.danger.default
  }

  if (props.color === 'warning') {
    return colors.button.warning.default
  }

  if (props.color === 'primary') {
    return colors.primary
  }
  if (props.color === 'success') {
    return colors.success
  }
  if (props.color === 'info') {
    return colors.info
  }
  if (props.color === 'accent') {
    return colors.accent
  }

  if (props.color === 'secondary') {
    return colors.secondary
  }
  if (props.color === 'light') {
    return '#FFFFFF'
  }
  if (props.color === 'dark') {
    return '#000000'
  }
  if (props.color === 'grey') {
    return colors.grey500
  }

  return null
}

function hasBorder(props: ButtonProps) {
  if (props.variant === 'contained' || props.variant === 'text') {
    return false
  }
  return true
}

function display(props: ButtonProps) {
  if (props.hidden) {
    return 'none'
  }

  return 'flex'
}

function getBorderWidth(props: ButtonProps) {
  if (hasBorder(props) === false) {
    return 0
  }
  return props.borderWidth || 1
}

function fontSize(props: ButtonProps) {
  if (props.fontSize) {
    return props.fontSize
  }

  if (props.size === 'small') {
    return 12
  }

  return DEFAULT_FONT_SIZE
}

function usePadding(props: ButtonProps) {
  const theme = useTheme()

  if (props.disablePadding) {
    return '0'
  }

  if (props.size === 'small') {
    return `${theme.spacing[1]} ${theme.spacing[3]}`
  }

  return `${theme.spacing[2]} ${theme.spacing[8]}`
}

type StyleProps = {
  display: string
  color: string
  backgroundColor: string
  hoverBackgroundColor: string
  borderColor: string
  borderWidth: number
  fullWidth?: boolean
  width: string
  fontSize: number
  disableBorderRadius: boolean
  disablePadding?: boolean
  hasBorder: boolean
  padding: string
  hasRipple?: boolean
}

const StyledButton = styled.button<StyleProps>`
  display: inline-flex;
  align-items: center;
  line-height: 122%;
  padding: ${(props) =>
    props.disablePadding
      ? 0
      : `${props.theme.spacing[2]} ${props.theme.spacing[3]}`};
  color: ${(props) => props.color};
  border: ${(props) => `${props.borderWidth}px solid ${props.borderColor}`};
  border-radius: 3px;
  background-color: ${(props) => props.backgroundColor};
  width: ${(props) => (props.fullWidth ? '100%' : props.width)};
  border-radius: ${(props) => (props.disableBorderRadius ? '0px' : '4px')};
  font-size: ${(props) => props.fontSize}px;
  justify-content: center;
  cursor: pointer;
  letter-spacing: 0.5px;
  font-family: 'Rubik', '-apple-system', 'BlinkMacSystemFont', '"Segoe UI"',
    'Roboto', '"Helvetica Neue"', 'Arial', 'sans-serif', '"Apple Color Emoji"',
    '"Segoe UI Emoji"', '"Segoe UI Symbol"';
  background-position: center;
  transition-property: ${(props) => (props.hasRipple ? 'background' : 'none')};
  transition-duration: ${(props) => (props.hasRipple ? '0.8s' : '0s')};

  &:hover {
    background: ${(props) => props.hoverBackgroundColor}
      radial-gradient(
        circle,
        transparent 1%,
        ${(props) => props.hoverBackgroundColor} 1%
      )
      center/15000%;
  }

  &:disabled {
    background-color: ${(props) =>
      props.backgroundColor === 'transparent'
        ? 'transparent'
        : '#e7e7e7'}!important;
    border-color: #e7e7e7;
    color: #666666;
    filter: brightness(1);
    cursor: default;
  }

  &:active:not(:disabled) {
    background-color: ${(props) => props.backgroundColor};
    background-size: 100%;
    transition: background 0s;
  }
`

function Icon(props: {
  children?: React.ReactNode
  paddingRight?: boolean
  paddingLeft?: boolean
}) {
  if (!props.children) {
    return null
  }

  return <IconBox {...props} />
}

export const IconBox = styled.div<{
  paddingRight?: boolean
  paddingLeft?: boolean
}>`
  margin-left: ${(props) => (props.paddingLeft ? props.theme.spacing[2] : 0)};
  margin-right: ${(props) => (props.paddingRight ? props.theme.spacing[2] : 0)};
  display: flex;
  align-items: center;
  justif-content: center;
`

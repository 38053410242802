import {onChangeCheckedHandler} from 'lib/dom'
import Switch from 'lib/ui/form/Switch'
import React from 'react'
import {Controller, UseFormMethods} from 'react-hook-form'
import Slider from 'lib/ui/Slider'
import {useCardsTemplate} from 'Event/template/Cards'
import Title from 'lib/ui/ConfigPanel/Title'
import Divider from 'lib/ui/ConfigPanel/Divider'
import ImageAssetUploader from 'lib/asset/ImageAssetUploader'

const MIN_LOGO_SIZE = 1
const MAX_LOGO_SIZE = 100

export default function Settings(props: {control: UseFormMethods['control']}) {
  const {control} = props
  const {header, logo} = useCardsTemplate()

  return (
    <>
      <Controller
        name="header.isEnabled"
        defaultValue={header.isEnabled}
        control={control}
        render={({value, onChange}) => (
          <Switch
            checked={value}
            onChange={onChangeCheckedHandler(onChange)}
            arial-label="config header background image visible switch"
            labelPlacement="start"
            label={value ? 'Header Enabled' : 'Header Disabled'}
          />
        )}
      />
      <Divider />
      <Title>Logo</Title>
      <Controller
        name="logo"
        control={control}
        defaultValue={logo}
        render={({onChange, value}) => (
          <ImageAssetUploader
            onChange={onChange}
            value={value}
            uploadLabel="Upload"
            canResize
            uploadInputProps={{
              'aria-label': 'logo upload',
            }}
            removeButtonProps={{
              'aria-label': 'remove logo',
            }}
            disableAutoRemove
          />
        )}
      />
      <Controller
        name="header.logoSize"
        defaultValue={header.logoSize}
        control={control}
        render={({value, onChange}) => (
          <Slider
            min={MIN_LOGO_SIZE}
            max={MAX_LOGO_SIZE}
            step={1}
            onChange={onChange}
            valueLabelDisplay="auto"
            value={value}
            aria-label="header logo size"
            label="Size"
          />
        )}
      />
    </>
  )
}

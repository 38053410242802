import {useEvent} from 'Event/EventProvider'
import {Room} from 'Event/room'
import {useAsync} from 'lib/async'
import FullPageLoader from 'lib/ui/layout/FullPageLoader'
import {api} from 'lib/url'
import {useArea} from 'organization/Event/Area/AreaProvider'
import {useEventSocket} from 'organization/Event/EventSocketProvider'
import {useOrganization} from 'organization/OrganizationProvider'
import React, {useCallback, useEffect, useState} from 'react'

// Socket events
export const UPDATED_ROOM = '.room.updated'

type RoomsContextProps = {
  rooms: Room[]
  add: (room: Room) => void
  remove: (room: Room) => void
  update: (room: Room) => void
  totalAttendees: number
}

const RoomsContext = React.createContext<RoomsContextProps | undefined>(
  undefined,
)

export default function RoomsProvider(props: {children: React.ReactElement}) {
  const [rooms, setRooms] = useState<Room[]>([])
  /**
   * Need to handle explicit loading state, otherwise async request
   * would resolve before the 'setRooms'
   */
  const [loading, setLoading] = useState(true)
  const {data: saved} = useSavedRooms()
  const {channel} = useEventSocket()

  useEffect(() => {
    if (!saved) {
      return
    }

    setRooms(saved)
    setLoading(false)
  }, [saved])

  // Listen for socket updates
  useEffect(() => {
    channel.listen(UPDATED_ROOM, (updated: Room) => {
      setRooms(rooms.map((r) => (r.id === updated.id ? updated : r)))
    })

    return () => {
      channel.stopListening(UPDATED_ROOM)
    }
  }, [channel, rooms])

  const add = (room: Room) => {
    setRooms((current) => [...current, room])
  }

  const remove = (room: Room) => {
    const removed = rooms.filter((a) => a.id !== room.id)
    setRooms(removed)
  }

  const update = useCallback(
    (room: Room) => {
      const updated = rooms.map((r) => {
        const isTarget = r.id === room.id
        if (!isTarget) {
          return r
        }

        return room
      })

      setRooms(updated)
    },
    [rooms],
  )

  if (loading) {
    return <FullPageLoader />
  }

  return (
    <RoomsContext.Provider
      value={{
        rooms,
        add,
        remove,
        update,
        totalAttendees: totalAttendees(rooms),
      }}
    >
      {props.children}
    </RoomsContext.Provider>
  )
}

export function totalAttendees(rooms: Room[]) {
  return rooms.reduce((prev, current) => prev + current.attendee_count, 0)
}

export function useRooms() {
  const context = React.useContext(RoomsContext)
  if (context === undefined) {
    throw new Error('useRooms must be used within a RoomsProvider')
  }

  return context
}

function useSavedRooms() {
  const {client} = useOrganization()
  const {
    event: {id: eventId},
  } = useEvent()
  const {area} = useArea()
  const {id} = area

  const request = useCallback(() => {
    const url = api(`/events/${eventId}/areas/${id}/rooms`)
    return client.get<Room[]>(url)
  }, [client, eventId, id])

  return useAsync(request)
}

import React from 'react'
import styled from 'styled-components'
import Grid from '@material-ui/core/Grid'
import {Speaker} from 'Event/Speakers'
import {Draggable} from 'react-beautiful-dnd'
import {usePanelsTemplate} from 'Event/template/Panels'
import {useAttendeeVariables} from 'Event'
import {useEditMode} from 'Event/EditModeProvider'
import Image from 'Event/Speakers/Image'
import Content from 'lib/ui/form/TextEditor/Content'
import SpeakerConfig, {SpeakerConfigurable} from 'Event/Speakers/SpeakerConfig'

type SpeakerProps = {
  id: string
  index: number
  speaker: Speaker
}

export default function Card(props: SpeakerProps) {
  const isEditMode = useEditMode()

  if (!isEditMode) {
    return <SpeakerContent {...props} />
  }
  return (
    <Draggable draggableId={String(props.id)} index={props.index}>
      {(provided) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <SpeakerContent {...props} />
        </div>
      )}
    </Draggable>
  )
}

function SpeakerContent(props: SpeakerProps) {
  const {speaker} = props
  const template = usePanelsTemplate()
  const v = useAttendeeVariables()

  const imageSize = template.speakers.imageSize
  const isFirst = props.index === 0

  return (
    <Box aria-label="speaker" isFirst={isFirst}>
      <SpeakerConfigurable>
        <SpeakerConfig speaker={props.speaker} id={props.id} />
        <div>
          <Left item xs={imageSize}>
            <StyledImage speaker={speaker} />
            <SpeakerName isFirst={isFirst}>{v(speaker.name)}</SpeakerName>
          </Left>
          <BodyBox>
            <Content>{v(speaker.text)}</Content>
          </BodyBox>
        </div>
      </SpeakerConfigurable>
      <ClearContent />
    </Box>
  )
}

const BodyBox = styled.div`
  width: 100%;
  margin-left: ${(props) => props.theme.spacing[5]};
`

const Box = styled.div<{
  isFirst: boolean
}>`
  position: relative;
  border-top: ${(props) => (props.isFirst ? 'none' : '1px solid #e5e5e5')};
  padding: ${(props) => (props.isFirst ? '0 0 30px' : '30px 0px')};
  display: flex;
`

const Left = styled(Grid)`
  float: left;
  margin-right: ${(props) => props.theme.spacing[5]};
`

const SpeakerName = styled.h4<{
  isFirst: boolean
}>`
  margin-top: ${(props) => (props.isFirst ? '0' : '16px')}; 
  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    font-size: 14px;
    line-height: 17px;
`

const StyledImage = styled(Image)`
  margin-bottom: ${(props) => props.theme.spacing[4]};
  img {
    width: 100%;
    border-radius: 10px;
  }
`

const ClearContent = styled.div`
  clear: both;
`

import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import TagsInput, {Tag} from 'lib/ui/form/TagsInput'
import HelperText from 'lib/ui/TextField/HelperText'
import {NewGroup} from 'organization/Event/AttendeeManagement/attendee-dialog/Form'
import NewGroupInput from 'organization/Event/AttendeeManagement/attendee-dialog/Form/NewGroupInput'
import AdvancedAttendeeFilter from 'organization/Event/ZoomAttendance/AdvancedAttendeeFilter'
import {FormProps} from 'organization/Event/ZoomAttendance/AttendeeActionForm'
import ButtonRow from 'organization/Event/ZoomAttendance/ButtonRow'
import {
  useZoomAttendanceAction,
  useZoomAttendance,
} from 'organization/Event/ZoomAttendance/ZoomAttendanceProvider'

export default function ActionFormTagGroup(props: FormProps) {
  const {action, onClose, onSuccessMessage} = props
  const [error, setError] = useState<string>('')
  const [tags, setTags] = useState<Tag[]>([])
  const [groupName, setGroupName] = useState<string>('')
  const [groupValue, setGroupValue] = useState<string>('')
  const submitAction = useZoomAttendanceAction()
  const {
    area,
    attendeeFilterData,
    endDateTime,
    setScheduledActionData,
    startDateTime,
  } = useZoomAttendance()

  const validation = () => {
    setError('')

    if (!tags.length && !groupName && !groupValue) {
      setError('You must provide at least one Tag or Group/Value pair')
      return false
    }

    if ((groupName && !groupValue) || (!groupName && groupValue)) {
      setError('You must provide both the Group Name and Value')
      return false
    }

    return true
  }

  const onClick = () => {
    if (validation() === false) {
      return
    }

    submitAction(action, startDateTime, endDateTime, area, {
      tags: tags,
      groupName: groupName,
      groupValue: groupValue,
      filter: attendeeFilterData,
    })
      .then((response) => {
        onSuccessMessage(response.message ?? null)
        onClose()
      })
      .catch((e) => {
        setError(e.message)
        return
      })
  }

  useEffect(() => {
    setScheduledActionData({
      tags: tags,
      groupName: groupName,
      groupValue: groupValue,
    })
  }, [tags, groupName, groupValue, setScheduledActionData])

  const handleUpdate = (update: NewGroup) => {
    setGroupName(update.key)
    setGroupValue(update.value)
  }

  return (
    <>
      <AdvancedAttendeeFilter />

      <TagsInput
        value={tags}
        onChange={setTags}
        name="tags"
        aria-label="tags"
        label="Tags"
      />

      <NewGroupInput
        group={{key: groupName, value: groupValue}}
        onChange={handleUpdate}
      />

      <StyledHelperText error={Boolean(error)}>{error}</StyledHelperText>

      <ButtonRow
        action={action}
        onClick={onClick}
        onClose={onClose}
        validation={validation}
      />
    </>
  )
}

const StyledHelperText = styled(HelperText)`
  margin-bottom: ${(props) => props.theme.spacing[6]};
`

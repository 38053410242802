import React from 'react'
import {ForgotPasswordProps} from 'auth/password'
import LeftPanel from 'Event/template/NiftyFifty/Login/LeftPanel'
import Page from 'Event/template/NiftyFifty/Page'
import RightPanel from 'Event/template/NiftyFifty/ForgotPassword/RightPanel'
import MobilePanel from 'Event/template/NiftyFifty/ForgotPassword/MobilePanel'

export default function ForgotPassword(props: ForgotPasswordProps) {
  return (
    <Page
      Left={<LeftPanel />}
      Right={<RightPanel {...props} />}
      Mobile={<MobilePanel {...props} />}
    />
  )
}

import grey from '@material-ui/core/colors/grey'
import IconButton from 'lib/ui/IconButton'
import styled from 'styled-components'
import Tooltip from '@material-ui/core/Tooltip'
import React from 'react'
import {DownloadIcon} from 'lib/ui/Icon'

export default function DownloadWaiverButton(props: {
  onClick: () => void
  className?: string
}) {
  const {onClick, className} = props

  return (
    <Tooltip title="Download waiver" placement="bottom" className={className}>
      <span>
        <StyledIconButton aria-label="download waiver" onClick={onClick}>
          <DownloadIcon />
        </StyledIconButton>
      </span>
    </Tooltip>
  )
}

const StyledIconButton = styled(IconButton)`
  color: ${grey[500]};
`

import {useEvent, useHasTechCheck} from 'Event/EventProvider'
import React, {useState} from 'react'
import {api} from 'lib/url'
import {Attendee} from 'Event/attendee'
import {ValidationError} from 'lib/ui/api-client'
import {setUser} from 'auth/actions'
import {useDispatch} from 'react-redux'
import {useTemplate} from 'Event/TemplateProvider'
import {useAttendee} from 'Event/auth'
import {usePoints} from 'Event/PointsProvider'
import {usePlatformActions} from 'Event/ActionsProvider/platform-actions'
import {User} from 'auth/user'
import {components} from 'Event/template'
import {useHasWaiver} from 'Event/Step2/Waivers'

interface SetPasswordData {
  password: string
  password_confirmation: string
}

export interface SetPasswordFormProps {
  submit: (data: SetPasswordData) => void
  submitting: boolean
  responseError: ValidationError<SetPasswordData>
  progress: number
  user: User
}

export default function SetPasswordForm() {
  const [submitting, setSubmitting] = useState(false)
  const [responseError, setResponseError] = useState<
    SetPasswordFormProps['responseError']
  >(null)
  const setPassword = useSetPassword()
  const dispatch = useDispatch()
  const {submit: submitAction} = usePoints()
  const {createPassword} = usePlatformActions()
  const progress = useStep1Progress()
  const user = useAttendee()

  const submit = (data: SetPasswordData) => {
    setSubmitting(true)
    setPassword(data)
      .then((attendee) => {
        dispatch(setUser(attendee))
        if (createPassword?.key) {
          submitAction(createPassword.key)
        }
      })
      .catch((e) => {
        setResponseError(e)
        setSubmitting(false)
      })
  }

  return (
    <TemplateSetPasswordForm
      user={user}
      submit={submit}
      submitting={submitting}
      responseError={responseError}
      progress={progress}
    />
  )
}

function useStep1Progress() {
  const hasTechCheck = useHasTechCheck()

  const hasWaiver = useHasWaiver()

  if (hasTechCheck && hasWaiver) {
    return 25
  }

  if (!hasTechCheck && !hasWaiver) {
    return 50
  }

  return 33
}

export function TemplateSetPasswordForm(props: SetPasswordFormProps) {
  const template = useTemplate()
  const Component = components[template.name].Step1.SetPasswordForm.index
  return <Component {...props} />
}

function useSetPassword() {
  const {event, client} = useEvent()
  const url = api(`/events/${event.id}/password`)

  return (data: SetPasswordData) => client.post<Attendee>(url, data)
}

export function useRequiresPassword() {
  const {login} = useTemplate()
  return login.requiresPassword
}

import React from 'react'
import styled from 'styled-components'
import TextField, {TextFieldProps} from 'lib/ui/TextField'
import {BlockComponentProps} from 'Event/Marketplace/Block'
import {
  PlaceholderType,
  PurchaseFormBlock,
} from 'Event/Marketplace/Block/PurchaseForm'

export interface PurhaseFormTextFieldProps extends TextFieldProps {
  config: PurchaseFormBlock & BlockComponentProps
  placeholder: PlaceholderType
}

export default function PurhaseFormTextField(
  props: TextFieldProps & {
    config: PurchaseFormBlock
    placeholder: PlaceholderType
  },
) {
  const {config, placeholder} = props

  return (
    <Box config={config}>
      <StyledTextField
        {...props}
        placeholder={config.input.placeholder[placeholder]}
      />
    </Box>
  )
}

const Box = styled.div<{config: PurchaseFormBlock}>`
  margin-bottom: ${(props) => props.config.input.spacing}px !important;
`

const StyledTextField = styled(TextField)<{config: PurchaseFormBlock}>`
  margin-bottom: 0;
  input {
    margin-bottom: unset;
    background-color: ${(props) => props.config.input.backgroundColor};
    border: ${(props) => props.config.input.border.width}px solid;
    border-color: ${(props) => props.config.input.border.color};
    border-radius: ${(props) => props.config.input.border.radius}px;
    padding-left: ${(props) => props.config.input.padding.left}px;
    padding-right: ${(props) => props.config.input.padding.right}px;
    padding-top: ${(props) => props.config.input.padding.top}px;
    padding-bottom: ${(props) => props.config.input.padding.bottom}px;
    font-size: ${(props) => props.config.input.fontSize}px;
    width: 100%;
    height: auto !important;
  }
`

import {LoginProps} from 'Event/auth/Login'
import styled from 'styled-components'
import React from 'react'
import Page, {ErrorMessage} from 'Event/template/SimpleBlog/Login/Page'
import EmailField from 'Event/auth/Login/EmailField'
import PasswordField from 'Event/auth/Login/PasswordField'
import SubmitButton from 'Event/auth/Login/SubmitButton'
import Description from 'Event/auth/Login/Description'
import ForgotPasswordLink, {
  ForgotPasswordLinkBase,
} from 'Event/auth/Login/ForgotPasswordLink'
import SignUpLink, {SignUpLinkBase} from 'Event/auth/Login/SignUpLink'

export default function Login(props: LoginProps) {
  return (
    <Page isPreview={props.isPreview}>
      <>
        <Description />
        <StyledForm onSubmit={props.onSubmit}>
          <EmailField {...props} />
          <PasswordField {...props} />
          <ErrorMessage>{props.error}</ErrorMessage>
          <SubmitButton {...props} />
        </StyledForm>
        <ForgotPasswordLink Component={StyledForgotPasswordLink} />
        <SignUpLink Component={StyledSignUpLink} />
      </>
    </Page>
  )
}

export const StyledForgotPasswordLink = styled(ForgotPasswordLinkBase)<{
  color: string
}>`
  margin-top: ${(props) => props.theme.spacing[10]};
`

export const StyledSignUpLink = styled(SignUpLinkBase)<{
  color: string
}>`
  margin-top: ${(props) => props.theme.spacing[10]};
`

const StyledForm = styled.form`
  width: 100%;
`

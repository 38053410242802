import TextField from '@material-ui/core/TextField'
import ComponentConfig, {
  SaveButton,
  Footer,
} from 'organization/Event/DashboardConfig/ComponentConfigPanel'
import StylingPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/StylingPanel'
import ColorPicker from 'lib/ui/ColorPicker'
import React from 'react'
import Slider from 'lib/ui/Slider'
import {useToggle} from 'lib/toggle'
import Button from '@material-ui/core/Button'
import styled from 'styled-components'
import {Controller, UseFormMethods, useForm} from 'react-hook-form'
import {Template} from 'Event/template'
import {useSaveTemplate} from 'Event/TemplateUpdateProvider'
import {Label} from 'lib/ui/typography'
import Title from 'lib/ui/ConfigPanel/Title'
import Divider from 'lib/ui/ConfigPanel/Divider'
import FontSelect from 'lib/FontSelect'
import MarginPaddingInputs from 'lib/ui/MarginPaddingInputs'
import {useLeftyTemplate} from 'Event/template/Lefty'

const MAX_SPACING = 250
const MIN_SPACING = 1
const MIN_BORDER_WIDTH = 0
const MAX_BORDER_WIDTH = 50
const MIN_BORDER_RADIUS = 0
const MAX_BORDER_RADIUS = 50

export default function PostStylesConfig() {
  const {flag: showingConfig, toggle: toggleConfig} = useToggle()
  const {
    register,
    control,
    handleSubmit,
    formState: {isDirty},
  } = useForm()
  const update = useSaveTemplate()

  const save = (data: Template['postStyles']) => {
    update({
      postStyles: data,
    })

    toggleConfig()
  }

  return (
    <>
      <StyledEditPostStylesButton onClick={toggleConfig} />
      <ComponentConfig
        showing={showingConfig}
        onClose={toggleConfig}
        title="Post Styles"
        onSubmit={handleSubmit(save)}
        hasChanges={isDirty}
      >
        <StylingPanel>
          <Styling control={control} register={register} />
        </StylingPanel>
        <Footer>
          <SaveButton type="submit" />
        </Footer>
      </ComponentConfig>
    </>
  )
}

function Styling(props: {
  control: UseFormMethods['control']
  register: UseFormMethods['register']
}) {
  const {control, register} = props
  const {postStyles} = useLeftyTemplate()

  return (
    <>
      <Title>Blog Title</Title>
      <Label>Font</Label>
      <Controller
        control={control}
        name="titleFont"
        defaultValue={postStyles.titleFont}
        render={({value, onChange}) => (
          <FontSelect value={value} onChange={onChange} />
        )}
      />
      <TextField
        name="titleFontSize"
        defaultValue={postStyles.titleFontSize}
        label="Font Size"
        type="number"
        fullWidth
        inputProps={{
          min: 0,
          ref: register,
          'aria-label': 'title font size',
        }}
      />
      <Controller
        name="titleTextColor"
        defaultValue={postStyles.titleTextColor}
        control={control}
        render={({value, onChange}) => (
          <ColorPicker
            label="Text Color"
            color={value}
            onPick={onChange}
            aria-label="title text color"
          />
        )}
      />
      <Divider />
      <Title>Blog Date</Title>
      <TextField
        name="dateFontSize"
        defaultValue={postStyles.dateFontSize}
        label="Font Size"
        type="number"
        fullWidth
        inputProps={{
          min: 0,
          ref: register,
          'aria-label': 'date font size',
        }}
      />
      <Controller
        name="dateTextColor"
        defaultValue={postStyles.dateTextColor}
        control={control}
        render={({value, onChange}) => (
          <ColorPicker
            label="Text Color"
            color={value}
            onPick={onChange}
            aria-label="date text color"
          />
        )}
      />
      <Divider />
      <Title>Blog Content</Title>
      <TextField
        name="contentFontSize"
        defaultValue={postStyles.contentFontSize}
        label="Font Size"
        type="number"
        fullWidth
        inputProps={{
          min: 0,
          ref: register,
          'aria-label': 'content font size',
        }}
      />
      <Controller
        name="contentTextColor"
        defaultValue={postStyles.contentTextColor}
        control={control}
        render={({value, onChange}) => (
          <ColorPicker
            label="Text Color"
            color={value}
            onPick={onChange}
            aria-label="content text color"
          />
        )}
      />
      <Divider />
      <Controller
        name="spacing"
        defaultValue={postStyles.spacing}
        control={control}
        render={({value, onChange}) => (
          <Slider
            min={MIN_SPACING}
            max={MAX_SPACING}
            step={1}
            onChange={onChange}
            valueLabelDisplay="auto"
            value={value}
            label="Space Between Posts"
          />
        )}
      />
      <Divider />
      <Title>Blog Container</Title>
      <Controller
        name="containerBackgroundColor"
        defaultValue={postStyles.containerBackgroundColor}
        control={control}
        render={({value, onChange}) => (
          <ColorPicker
            label="Background Color"
            color={value}
            onPick={onChange}
            aria-label="container background color"
          />
        )}
      />
      <Controller
        name="containerBackgroundOpacity"
        defaultValue={postStyles.containerBackgroundOpacity}
        control={control}
        render={({value, onChange}) => (
          <Slider
            min={0}
            max={100}
            step={1}
            onChange={onChange}
            value={value}
            hideUnits
            label="Background Opacity"
            unit="%"
          />
        )}
      />
      <Label>Padding</Label>
      <MarginPaddingInputs
        control={control}
        namePrefix="containerPadding"
        values={postStyles.containerPadding}
        settings={{max: 300}}
      />
      <Controller
        name="containerBorderWidth"
        defaultValue={postStyles.containerBorderWidth}
        control={control}
        render={({value, onChange}) => (
          <Slider
            min={MIN_BORDER_WIDTH}
            max={MAX_BORDER_WIDTH}
            step={1}
            onChange={onChange}
            value={value}
            label="Border Width"
          />
        )}
      />
      <Controller
        name="containerBorderRadius"
        defaultValue={postStyles.containerBorderRadius}
        control={control}
        render={({value, onChange}) => (
          <Slider
            min={MIN_BORDER_RADIUS}
            max={MAX_BORDER_RADIUS}
            step={1}
            onChange={onChange}
            value={value}
            label="Border Radius"
          />
        )}
      />
      <Controller
        name="containerBorderColor"
        defaultValue={postStyles.containerBorderColor}
        control={control}
        render={({value, onChange}) => (
          <ColorPicker label="Border Color" color={value} onPick={onChange} />
        )}
      />
      <Divider />
    </>
  )
}

function EditPostStylesButton(props: {
  onClick: () => void
  className?: string
}) {
  return (
    <Button
      className={props.className}
      fullWidth
      size="large"
      variant="contained"
      color="primary"
      aria-label="style posts"
      onClick={props.onClick}
    >
      Edit Post Styles
    </Button>
  )
}

const StyledEditPostStylesButton = styled(EditPostStylesButton)`
  margin-bottom: ${(props) => props.theme.spacing[3]}!important;
`

import {usePanelsTemplate, usePanelsUpdate} from 'Event/template/Panels'
import {TicketRibbon} from 'Event/template/Panels/Dashboard/TicketRibbonList/Ribbon'
import ComponentConfig, {
  ComponentConfigProps,
  SaveButton,
  RemoveButton,
  Footer,
} from 'organization/Event/DashboardConfig/ComponentConfigPanel'
import {useDeleteCustomRibbon} from 'organization/Event/DashboardConfig/TicketRibbonUpload/UploadedTicketRibbon'
import React, {useEffect, useState} from 'react'
import {useForm} from 'react-hook-form'
import {DeepRequired} from 'lib/type-utils'
import Settings from 'Event/template/Panels/Dashboard/TicketRibbonList/TicketRibbonConfig/Settings'
import Styling from 'Event/template/Panels/Dashboard/TicketRibbonList/TicketRibbonConfig/Styling'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import RulesPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/RulesPanel'
import StylingPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/StylingPanel'
import {usePruneCustomRibbon} from 'organization/Event/DashboardConfig/TicketRibbonUpload'

export default function TicketRibbonConfig(
  props: ComponentConfigProps & {
    ticketRibbon: DeepRequired<TicketRibbon>
    index?: number
  },
) {
  const {index, showing: isVisible, onClose, ticketRibbon} = props
  const [processing, setProcessing] = useState(false)
  const deleteCustomRibbon = useDeleteCustomRibbon()
  const {control, handleSubmit, register, errors, formState} = useForm()
  const saveTemplate = usePanelsUpdate()
  const {ticketRibbons} = usePanelsTemplate()

  const pruneCustomRibbon = usePruneCustomRibbon()

  const [rules, setRules] = useState(ticketRibbon.rules)
  const [letterUpload, setLetterUpload] = useState(ticketRibbon.letterUpload)
  const [hoverUpload, setHoverUpload] = useState(ticketRibbon.hoverUpload)
  const [hoverTextFontStyles, setHoverTextFontStyles] = useState(
    ticketRibbon.hoverTextFontStyles,
  )

  const showingRemoveButton = index !== undefined

  /**
   * Set defaults for anything that can't be set via defaultValue
   */
  useEffect(() => {
    if (isVisible) {
      return
    }

    setRules(ticketRibbon.rules)
    setLetterUpload(ticketRibbon.letterUpload)
    setHoverUpload(ticketRibbon.hoverUpload)

    setHoverTextFontStyles(ticketRibbon.hoverTextFontStyles)
  }, [isVisible, ticketRibbon])

  const update = (index: number, updated: TicketRibbon) => {
    saveTemplate({
      ticketRibbons: ticketRibbons.map((tr, i) => {
        const isTarget = i === index
        if (isTarget) {
          return updated
        }

        return tr
      }),
    })

    pruneCustomRibbon({
      current: updated.letterUpload,
      saved: ticketRibbon.letterUpload,
    })

    pruneCustomRibbon({
      current: updated.hoverUpload,
      saved: ticketRibbon.hoverUpload,
    })
  }

  const insert = (newRibbon: TicketRibbon) => {
    saveTemplate({
      ticketRibbons: [...ticketRibbons, newRibbon],
    })
  }

  const remove = async () => {
    if (index === undefined) {
      throw new Error('Missing index for ticket ribbon')
    }

    try {
      if (ticketRibbon.letterUpload) {
        await deleteCustomRibbon(ticketRibbon.letterUpload)
      }

      if (ticketRibbon.hoverUpload) {
        await deleteCustomRibbon(ticketRibbon.hoverUpload)
      }
    } catch {
      // Ignore failing to delete images
    }

    const removed = ticketRibbons.filter((_, i) => i !== index)
    onClose()
    saveTemplate({
      ticketRibbons: removed,
    })
  }

  const save = (formData: any) => {
    const ribbon: TicketRibbon = {
      ...formData,
      rules,
      letterUpload,
      hoverUpload,
      hoverTextFontStyles,
    }

    if (index === undefined) {
      insert(ribbon)
    } else {
      update(index, ribbon)
    }

    onClose()
  }

  return (
    <ComponentConfig
      title="Ticket Ribbon"
      onClose={onClose}
      showing={isVisible}
      onSubmit={handleSubmit(save)}
      hasChanges={formState.isDirty}
    >
      <SettingsPanel>
        <Settings
          register={register}
          ticketRibbon={ticketRibbon}
          letterUpload={letterUpload}
          setLetterUpload={setLetterUpload}
          hoverUpload={hoverUpload}
          setHoverUpload={setHoverUpload}
          processing={processing}
          setProcessing={setProcessing}
          hoverTextError={Boolean(errors.hoverText)}
          hoverTextErrorMessage={errors.hoverText?.message || ''}
        />
      </SettingsPanel>
      <StylingPanel>
        <Styling
          control={control}
          ticketRibbon={ticketRibbon}
          hoverTextFontStyles={hoverTextFontStyles}
          setHoverTextFontStyles={setHoverTextFontStyles}
        />
      </StylingPanel>
      <RulesPanel rules={rules} setRules={setRules} />
      <Footer>
        <SaveButton type="submit" />
        <RemoveButton
          fullWidth
          variant="outlined"
          aria-label="remove ticket ribbon"
          onClick={remove}
          showing={showingRemoveButton}
        >
          REMOVE TICKET RIBBON
        </RemoveButton>
      </Footer>
    </ComponentConfig>
  )
}

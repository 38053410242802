import React from 'react'
import styled, {useTheme} from 'styled-components'
import {ControlLabel, Header, SubHead} from 'lib/ui/typography'
import {UserFilledIcon} from 'lib/ui/Icon'
import {Row} from 'Event/Statistics/Summary'
import {OpenStatisticWindowButton, StatisticType} from 'Event/Statistics'

export default function CheckInStep(props: {
  title: string
  completed: number
  total: number
  statisticType: StatisticType
  className?: string
}) {
  const {title, completed, total, statisticType, className} = props

  return (
    <div className={className}>
      <DetailCard>
        <DetailHeader>
          <OpenStatisticWindowButton statisticType={statisticType} />
        </DetailHeader>
        <SubHead color="white">{title}</SubHead>
        <Row>
          <UserFilledIcon color="white" />
          <Header color="white">{completed}</Header>
        </Row>
      </DetailCard>
      <ProgressBar value={completed} total={total} />
    </div>
  )
}

function ProgressBar(props: {value: number; total: number}) {
  const {value, total} = props

  const percentMath = Math.round((value / total) * 100)
  const percent = isNaN(percentMath) ? 0 : percentMath
  const theme = useTheme()

  const color = percent === 100 ? 'white' : theme.colors.primary

  return (
    <ProgressContainer>
      <ProgressContent width={percent} />
      <ProgressLabel color={color}>{`${percent}%`}</ProgressLabel>
    </ProgressContainer>
  )
}

const DetailHeader = styled.div`
  position: absolute;
  top: ${(props) => props.theme.spacing[2]};
  right: ${(props) => props.theme.spacing[2]};
`

const DetailCard = styled.div`
  position: relative;
  background: #1f4687;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing[2]};
  height: 100%;
  padding: ${(props) => props.theme.spacing[9]} 0;
  border-top-left-radius: ${(props) => props.theme.spacing[1]};
  border-top-right-radius: ${(props) => props.theme.spacing[1]};
`

const ProgressContainer = styled.div`
  width: 100%;
  height: ${(props) => props.theme.spacing[6]};
  background-color: #f2f5f9;
  border-bottom-left-radius: ${(props) => props.theme.spacing[1]};
  border-bottom-right-radius: ${(props) => props.theme.spacing[1]};
  position: relative;
`

type ProgressContentProps = {
  width: number
}

const ProgressContent = styled.div<ProgressContentProps>`
  width: ${(props) => `${props.width}%`};
  height: 100%;
  background-color: #3490dc;
  border-bottom-left-radius: ${(props) => props.theme.spacing[1]};
  border-bottom-right-radius: ${(props) =>
    props.width === 100 ? props.theme.spacing[1] : 0};
`

type ProgressLabelProps = {
  color: string
}

const ProgressLabel = styled(ControlLabel)<ProgressLabelProps>`
  color: ${(props) => props.color};
  position: absolute;
  right: ${(props) => props.theme.spacing[2]};
  top: 2px;
`

import React from 'react'
import Grid from '@material-ui/core/Grid'
import TemplateCard from 'organization/Marketplace/PurchasePageConfig/Content/TemplateSelector/TemplateCard'
import {ObvioEvent} from 'Event'

interface ObvioEventsGridProps {
  events: ObvioEvent[]
}

export default function ObvioEventsGrid(props: ObvioEventsGridProps) {
  const {events} = props

  return (
    <>
      {events.map((event) => (
        <Grid item xs={12} md={4} key={event.id}>
          <TemplateCard event={event} />
        </Grid>
      ))}
    </>
  )
}

import {useTemplate} from 'Event/TemplateProvider'
import {useSaveTemplate} from 'Event/TemplateUpdateProvider'
import TextField from 'lib/ui/TextField'
import {useConfig} from 'organization/Event/Configurable'
import Config from 'organization/Event/Configurable/Config'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import React from 'react'

export default function TitleConfig() {
  const save = useSaveTemplate()

  const template = useTemplate()
  const {faq} = template
  const {
    form: {register},
  } = useConfig()

  return (
    <Config title="Title" onSave={save}>
      <SettingsPanel>
        <TextField
          name="faq.title"
          defaultValue={faq.title}
          label="Title"
          fullWidth
          inputProps={{
            'aria-label': 'faq page title',
            ref: register,
          }}
        />
      </SettingsPanel>
    </Config>
  )
}

import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import {useAttendeeVariables} from 'Event'
import {Attendee} from 'Event/attendee'
import {useAttendee, useSetGroup} from 'Event/auth'
import Day from 'Event/business-mastery/TeamCompetition/Day'
import DetailsForm, {
  useTeamDetails,
} from 'Event/business-mastery/TeamCompetition/DetailsForm'
import {useEvent} from 'Event/EventProvider'
import {eventRoutes} from 'Event/Routes'
import CardsPage from 'Event/template/Cards/Page'
import {useAsync} from 'lib/async'
import {useInterval} from 'lib/interval'
import QRCode from 'lib/QRCode'
import {useToggleArray} from 'lib/toggle'
import {PaginatedCollection} from 'lib/ui/api-client'
import FullPageLoader from 'lib/ui/layout/FullPageLoader'
import {api, useQueryParams} from 'lib/url'
import React, {useCallback, useEffect, useState} from 'react'
import {Redirect} from 'react-router'
import styled from 'styled-components'

export const TEAM_ID_GROUP_KEY = `TeamId`
export const IN_PERSON_QUERY = 'in_person'

type TeamCompetitionContextProps = {
  teamId: string
  isInPerson: boolean
}

type DayStatus = {
  is_locked: boolean
  is_visible: boolean
}

const TeamCompetitionContext = React.createContext<
  undefined | TeamCompetitionContextProps
>(undefined)

export default function TeamCompetition() {
  const {
    team_id: teamId,
    [IN_PERSON_QUERY]: inPerson = false,
  } = useQueryParams()

  if (!teamId) {
    return <Redirect to={eventRoutes.root} />
  }

  return (
    <TeamCompetitionContext.Provider
      value={{teamId, isInPerson: inPerson === 'true'}}
    >
      <Content />
    </TeamCompetitionContext.Provider>
  )
}

const RULES_PDF_IN_PERSON_DEFAULT =
  'https://s3-staging.obv.io/event/note_attachments/O9GY8fVp6NPPW0dQZOfpBEhT7E4wNKoykhtUkPu8.pdf'

const RULES_PDF_VIRTUAL_DEFAULT =
  'https://s3-staging.obv.io/event/note_attachments/0u6jfysBneKrWEgaqi8D8XRuVu543N1nSQRAOHYY.pdf'

function Content() {
  const attendee = useAttendee()
  const teamDetails = useTeamDetails()
  const v = useAttendeeVariables()
  const {isInPerson} = useTeamCompetition()

  const {client} = useEvent()

  useSetTeamId()
  const {
    day_one = '2024-01-10',
    day_two = '2024-01-11',
    day_three = '2024-01-12',
    day_four = '2024-01-13',
    day_five = '2024-01-14',
  } = useQueryParams()

  const fetchDays = useCallback(
    () =>
      client.get<{
        '1': DayStatus
        '2': DayStatus
        '3': DayStatus
        '4': DayStatus
        '5': DayStatus
      }>(
        api(
          `/business_mastery/team_competition/days?day_one=${day_one}&day_two=${day_two}&day_three=${day_three}&day_four=${day_four}&day_five=${day_five}`,
        ),
      ),
    [day_one, day_two, day_three, day_four, day_five, client],
  )

  const {data: dayStatuses} = useAsync(fetchDays)

  // The rules pdf needs to be different for In-Person, and Virtual attendees.
  // They also need to be different for certain languages, so we'll make
  // them variables.
  const rulesPdfKey = isInPerson
    ? '{{ rules_pdf_in_person }}'
    : '{{ rules_pdf_virtual }}'

  const fallbackPdf = isInPerson
    ? RULES_PDF_IN_PERSON_DEFAULT
    : RULES_PDF_VIRTUAL_DEFAULT

  const rulesPdfLink = v(rulesPdfKey, fallbackPdf)

  if (teamDetails.loading || !dayStatuses) {
    return <FullPageLoader />
  }

  const activeDayStatus = Object.entries(dayStatuses).find(
    ([_day, status]) =>
      status.is_locked === false && status.is_visible === true,
  )

  const activeDay = activeDayStatus ? parseInt(activeDayStatus[0]) : null

  return (
    <CardsPage user={attendee}>
      <Box my={5}>
        <Top>
          <Grid item xs={12} md={7}>
            <DetailsForm details={teamDetails} />
          </Grid>
          <Grid item xs={12} md={5}>
            <TeamMembers />
            <ZoomSection />
            <JoinQRCode />
            <Box mt={3}>
              <Typography>
                <span>
                  <a
                    href={rulesPdfLink}
                    rel="noopenner noreferrer"
                    style={{
                      textDecoration: 'none',
                    }}
                    download
                  >
                    <Button variant="outlined" color="primary">
                      {v(
                        '{{team_download_rules}}',
                        ' Download The Rules of the Game ',
                      )}
                    </Button>
                  </a>
                </span>
              </Typography>
            </Box>
          </Grid>
        </Top>
      </Box>
      <Day
        day={1}
        isShowing={dayStatuses['1'].is_visible}
        isLocked={dayStatuses['1'].is_locked}
        activeDay={activeDay}
        description="Did you choose a project? What is the project? Why did you choose this project? How will you measure your success? "
      />
      <Day
        day={2}
        isShowing={dayStatuses['2'].is_visible}
        isLocked={dayStatuses['2'].is_locked}
        activeDay={activeDay}
        description="What is your project update and/or progress? What Business Mastery principles are you implementing? What are your challenges and victories? And how are you measuring that? "
      />
      <Day
        day={3}
        isShowing={dayStatuses['3'].is_visible}
        isLocked={dayStatuses['3'].is_locked}
        activeDay={activeDay}
        description="What is your project update and/or progress? What Business Mastery principles are you implementing? What are your challenges and victories? And how are you measuring that? "
      />
      <Day
        day={4}
        isShowing={dayStatuses['4'].is_visible}
        isLocked={dayStatuses['4'].is_locked}
        activeDay={activeDay}
        description="What is your project update and/or progress? What Business Mastery principles are you implementing? What are your challenges and victories? And how are you measuring that? "
      />
      <Day
        day={5}
        isShowing={dayStatuses['5'].is_visible}
        isLocked={dayStatuses['5'].is_locked}
        activeDay={activeDay}
        description="What are the results of your project? What did you learn? (What went well? What could you do even better?) How can your team apply this to your businesses?"
      />
    </CardsPage>
  )
}

function useSetTeamId() {
  const {teamId} = useTeamCompetition()
  const user = useAttendee()
  const [processing, toggleProcessing] = useToggleArray()
  const setGroup = useSetGroup()
  const {join_team = true} = useQueryParams()

  // Allow users to visit with `?join_team=false` to avoid having
  // their group set. Useful for crew members.
  const canSwitch = join_team === true

  const isCurrent = user.groups[TEAM_ID_GROUP_KEY] === teamId

  useEffect(() => {
    if (!canSwitch) {
      return
    }

    if (processing) {
      return
    }

    toggleProcessing()

    if (isCurrent) {
      return
    }

    setGroup(TEAM_ID_GROUP_KEY, teamId).then(toggleProcessing)
  }, [isCurrent, processing, toggleProcessing, setGroup, teamId, canSwitch])
}

function ZoomSection() {
  const {teamId, isInPerson} = useTeamCompetition()
  const joinLink = `https://obv-io.zoom.us/j/${teamId}`
  const v = useAttendeeVariables()

  if (isInPerson) {
    return null
  }

  return (
    <div>
      <Typography variant="h6">
        {v('{{team_zoom_meeting_id}}', 'Zoom Meeting ID text')}
      </Typography>
      <Typography>
        <a href={joinLink} target="_blank" rel="noopener noreferrer">
          {teamId}
        </a>
        <br></br>
        <a href={joinLink} target="_blank" rel="noopener noreferrer">
          <Button variant="contained" color="primary">
            {v('{{team_meet_with_my_team_button}}', 'MEET WITH MY TEAM')}
          </Button>
        </a>
      </Typography>
    </div>
  )
}

function JoinQRCode() {
  const v = useAttendeeVariables()
  const {isInPerson, teamId} = useTeamCompetition()

  const url = window.location.href
  if (!isInPerson) {
    return null
  }

  return (
    <div>
      <Typography variant="h6">
        {v('{{team_scan_qr_code}}', 'Team QR Code')}{' '}
        <Typography variant="body1">(Short Code: {teamId})</Typography>
      </Typography>
      <QRCode>{url}</QRCode>
    </div>
  )
}

function TeamMembers() {
  const v = useAttendeeVariables()

  const members = useFetchTeamMembers()

  return (
    <div>
      <Typography variant="h6">
        {v('{{team_members}}', 'Team Members')}
      </Typography>
      <ul>
        {members.map((member) => (
          <li key={member.id}>
            <Typography>
              {member.first_name} {member.last_name} -{' '}
              <a href={`mailto:${member.email}`}>{member.email}</a>
            </Typography>
          </li>
        ))}
      </ul>
    </div>
  )
}

/**
 * How often to fetch team members
 */
const POLL_MEMBERS_INTERVAL_SECS = 10

function useFetchTeamMembers() {
  const {teamId} = useTeamCompetition()
  const findAttendeesWithTeamId = useAttendeesWithTeamId()
  const [members, setMembers] = useState<Attendee[]>([])

  const request = useCallback(() => {
    findAttendeesWithTeamId(teamId).then((paginated) =>
      setMembers(paginated.data),
    )
  }, [findAttendeesWithTeamId, teamId])

  useInterval(request, POLL_MEMBERS_INTERVAL_SECS * 1000)
  useEffect(request, [request]) // fetch on load

  return members
}

export function useAttendeesWithTeamId() {
  const {
    client,
    event: {id: eventId},
  } = useEvent()

  return useCallback(
    (teamId: string) => {
      let url = api(
        `/events/${eventId}/attendees?group_is=${TEAM_ID_GROUP_KEY}:${teamId}`,
      )
      return client.get<PaginatedCollection<Attendee>>(url)
    },
    [client, eventId],
  )
}

export function useTeamCompetition() {
  const context = React.useContext(TeamCompetitionContext)
  if (context === undefined) {
    throw new Error('useTeamCompetition must be used within TeamCompetition')
  }

  return context
}

const Top = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;

  @media (min-width: ${(props) => props.theme.breakpoints.sm}) {
    flex-direction: row;
    gap: 20px;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    gap: 40px;
  }
`

import React, {useState} from 'react'
import styled from 'styled-components'
import EmailsTable from 'organization/Settings/CommunicationSettings/EmailsTable'
import AddEmailForm from 'organization/Settings/CommunicationSettings/AddEmailForm'
import InfoAlert from 'lib/ui/alerts/InfoAlert'

export interface AddEmailData {
  name: string
  email: string
}

export default function CommunicationSettings() {
  const [showingCheckEmailAlert, setShowingCheckEmailAlert] = useState(false)

  return (
    <Box>
      <AddEmailForm onSuccess={() => setShowingCheckEmailAlert(true)} />
      <InfoAlert
        showing={showingCheckEmailAlert}
        onClose={() => {
          setShowingCheckEmailAlert(false)
        }}
      >
        Please click the link in the email to verify this email address.
      </InfoAlert>
      <EmailsTable />
    </Box>
  )
}

const Box = styled.div`
  max-width: 740px;
`

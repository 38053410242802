import React, {useState} from 'react'
import styled, {useTheme} from 'styled-components'
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardMedia from '@material-ui/core/CardMedia'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import {details, Template} from 'Event/template'
import {Description, SubHead} from 'lib/ui/typography'
import Button from 'lib/ui/Button'
import Icon from 'lib/ui/Icon'
import TemplatePreviewImages from 'Event/template/TemplatePreviewImages'
import {useToggleArray} from 'lib/toggle'
import {getThumbnail} from 'Event/template/screenshots'

export const CREATE_SAMPLE = 'create_sample'
export const CREATE_BLANK = 'create_blank'
export const CONVERT = 'convert'

export type Action = typeof CREATE_SAMPLE | typeof CREATE_BLANK | typeof CONVERT

export type TemplateCardContextProps = {
  action: Action
  setAction: (action: Action) => void
  template: Template['name']
}

const TemplateCardContext = React.createContext<
  TemplateCardContextProps | undefined
>(undefined)

export default function TemplateCard(props: {
  template: Template['name']
  disabled: boolean
  defaultAction: Action
  options: JSX.Element
  selectButton: JSX.Element
}) {
  const {template, selectButton} = props
  const [showingPreview, togglePreview] = useToggleArray()
  const [action, setAction] = useState<Action>(props.defaultAction)
  const {previewUrl, label} = details(template)

  const onViewSampleDashboard = () => {
    window.open(previewUrl, '_blank')
  }

  const thumbnail = getThumbnail(template)

  return (
    <TemplateCardContext.Provider
      value={{
        action,
        setAction,
        template,
      }}
    >
      <StyledCard>
        <StyledCardActionArea>
          <StyledCardMedia
            image={thumbnail}
            title="Template Preview"
            onClick={togglePreview}
          />
        </StyledCardActionArea>
        <Container>
          <StyledCardContent>
            <SubHead>{label}</SubHead>
            <OptionGroupContainer>{props.options}</OptionGroupContainer>
          </StyledCardContent>
          <StyledCardActions>
            <ActionsContainer>
              {selectButton}
              <Button
                variant="text"
                color="primary"
                fullWidth
                aria-label="view sample dashboard"
                startIcon={<Icon className="fas fa-external-link" />}
                onClick={onViewSampleDashboard}
              >
                View Sample Dashboard
              </Button>
            </ActionsContainer>
          </StyledCardActions>
        </Container>
        <TemplatePreviewImages
          open={showingPreview}
          template={template}
          onClose={togglePreview}
        />
      </StyledCard>
    </TemplateCardContext.Provider>
  )
}

export function SelectButtonBase(props: {
  disabled: boolean
  template: Template['name']
  onClick: () => void
}) {
  return (
    <Button variant="outlined" color="primary" fullWidth {...props}>
      Select
    </Button>
  )
}

export function BlankOption() {
  const {template} = useTemplateCard()

  return (
    <StartOption
      label="Start with an Empty Template"
      action={CREATE_BLANK}
      aria-label={`select blank ${template}`}
    />
  )
}

export function SampleOption() {
  const {template} = useTemplateCard()

  return (
    <StartOption
      label="Start with Demo Sample Data"
      action={CREATE_SAMPLE}
      aria-label={`select sample ${template}`}
    />
  )
}

export function ConvertOption() {
  const {template} = useTemplateCard()

  return (
    <StartOption
      label="Copy from Current Template"
      action={CONVERT}
      aria-label={`select copy ${template}`}
    />
  )
}

function StartOption(props: {
  label: string
  action: Action
  'aria-label': string
}) {
  const {action: selected, setAction} = useTemplateCard()

  const {label, action} = props

  const checked = action === selected

  const theme = useTheme()
  const color = checked ? theme.colors.success : theme.colors.grey500

  return (
    <OptionContent
      onClick={() => setAction(action)}
      aria-label={props['aria-label']}
    >
      <Icon className="fas fa-check-circle" color={color} />
      <Description>{label}</Description>
    </OptionContent>
  )
}

export function useTemplateCard() {
  const context = React.useContext(TemplateCardContext)

  if (context === undefined) {
    throw new Error('useTemplateCard must be used within TemplateCard')
  }

  return context
}

const StyledCard = styled(Card)`
  padding: ${(props) => props.theme.spacing[6]};
  display: flex;
  flex-direction: column;
  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    flex-direction: row;
    grid-gap: ${(props) => props.theme.spacing[6]};
  }
  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    flex-direction: column;
  }
`

const StyledCardActionArea = styled(CardActionArea)`
  width: 100%;
  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    width: 65%;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    width: 100%;
  }
`

const Container = styled.div``

const StyledCardMedia = styled(CardMedia)`
  height: 156px;
  cursor: pointer;
`

const StyledCardContent = styled(CardContent)`
  padding: ${(props) => props.theme.spacing[6]} 0;
`

const StyledCardActions = styled(CardActions)`
  display: block !important;
  padding: 0;
`

const ActionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: ${(props) => props.theme.spacing[2]};
`

const OptionGroupContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  grid-gap: ${(props) => props.theme.spacing[3]};
  margin-top: ${(props) => props.theme.spacing[3]};
`

const OptionContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  grid-gap: ${(props) => props.theme.spacing[2]};
`

import Config from 'organization/Event/Configurable/Config'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import React from 'react'
import Settings from 'Event/template/Townhall/Dashboard/NavBar/LinkTabs/FeaturePageMenuItemConfig/Settings'
import {useTownhallUpdate} from 'Event/template/Townhall'
import {REMOVE} from 'Event/TemplateUpdateProvider'

export default function FeaturePageMenuItemConfig(props: {id: string}) {
  const {id} = props

  const update = useTownhallUpdate()

  const onRemove = () => {
    update({
      navBar: {
        menuItems: {
          [id]: REMOVE,
        },
      },
    })
  }

  return (
    <Config title="Edit Feature Page" onSave={update} onRemove={onRemove}>
      <SettingsPanel>
        <Settings id={id} />
      </SettingsPanel>
    </Config>
  )
}

import React from 'react'
import {ErrorMessage} from 'Event/template/SimpleBlog/Login/Page'
import Container from '@material-ui/core/Container'
import Page from 'Event/template/SimpleBlog/Page'
import {ChangePasswordRequest} from 'auth/password/change'
import {useHistory} from 'react-router-dom'
import {eventRoutes} from 'Event/Routes'
import Button from 'Event/auth/Login/SubmitButton/Button'
import Text from 'Event/auth/Login/Description/Text'
import Description from 'Event/auth/ChangePassword/Description'
import {User} from 'auth/user'
import NewPasswordField from 'Event/auth/ChangePassword/NewPasswordField'
import ConfirmNewPasswordField from 'Event/auth/ChangePassword/ConfirmNewPasswordField'
import SubmitButton from 'Event/auth/ChangePassword/SubmitButton'

export default function ChangePassword(props: {
  changePassword: ChangePasswordRequest
  user: User
}) {
  return (
    <Page user={props.user}>
      <Content {...props} />
    </Page>
  )
}

function Content(props: {changePassword: ChangePasswordRequest}) {
  const {changePassword} = props
  const {responseError} = changePassword
  const history = useHistory()
  const goBackToDashboard = () => {
    history.push(eventRoutes.root)
  }

  if (changePassword.wasSuccessful) {
    return (
      <>
        <Text>Password Updated!</Text>
        <Button aria-label="back to dashboard" onClick={goBackToDashboard}>
          Go To Dashboard
        </Button>
      </>
    )
  }

  return (
    <>
      <Container maxWidth="md">
        <Description />
        <form onSubmit={changePassword.onSubmit}>
          <NewPasswordField {...props.changePassword} />
          <ConfirmNewPasswordField {...props.changePassword} />
          <ErrorMessage>{responseError?.message}</ErrorMessage>
          <SubmitButton {...props.changePassword} />
        </form>
      </Container>
    </>
  )
}

import React from 'react'
import styled from 'styled-components'
import {DateTimePicker, DateTimePickerProps} from '@material-ui/pickers'
import {useLocalization} from 'lib/LocalizationProvider'
import {BaseTextField} from 'lib/ui/TextField'
import InputLabel from 'lib/ui/TextField/InputLabel'
import IconButton from '@material-ui/core/IconButton'
import {TimesSolid} from 'lib/ui/Icon'
import moment from 'moment'

type LocalizedDateTimePickerProps = DateTimePickerProps & {
  onClear?: () => void
}

export default function LocalizedDateTimePicker(
  props: LocalizedDateTimePickerProps & {
    endAdornment?: JSX.Element
  },
) {
  const {has12HourTime} = useLocalization()

  return (
    <>
      <InputLabel>{props.label}</InputLabel>
      <StyledDateTimePicker
        ampm={has12HourTime}
        TextFieldComponent={({
          variant: _variant,
          value,
          label,
          defaultValue: _defaultValue,
          helperText,
          type: _type,
          ...otherProps
        }) => {
          return (
            <BaseTextField
              {...otherProps}
              value={value as string}
              variant="outlined"
              label={label as string}
              helperText={helperText as JSX.Element | undefined}
              endAdornment={<EndAdornment {...props} />}
              onChange={(e) => {
                // In case we're testing, and manually calling onChange,
                // we'll manually call onChange here with a date
                props.onChange(moment(e.currentTarget.value))
              }}
            />
          )
        }}
        {...props}
      />
    </>
  )
}

function EndAdornment(props: LocalizedDateTimePickerProps) {
  if (!props.onClear) {
    return null
  }

  return <ClearButton onClick={props.onClear} showing={!!props.value} />
}

function ClearButton(props: {onClick: () => void; showing?: boolean}) {
  if (!props.showing) {
    return null
  }

  return (
    <StyledIconButton onClick={props.onClick} aria-label="clear date">
      <TimesSolid />
    </StyledIconButton>
  )
}

const StyledDateTimePicker = styled(DateTimePicker)`
  position: relative;
`

const StyledIconButton = styled(IconButton)`
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  right: 8px;
  padding: 3px 6px;
  border-radius: 4px;
  color: ${(props) => props.theme.colors.text.primary};

  i {
    font-size: 16px;
  }
`

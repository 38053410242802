import TextField from 'lib/ui/TextField'
import {Label} from 'lib/ui/typography'
import React from 'react'
import Slider from 'lib/ui/Slider'
import {DEFAULT_FOOTER_IMAGE_SIZE} from 'Event/template/Cards/Dashboard/Footer/FooterConfig'
import {Controller, UseFormMethods} from 'react-hook-form'
import {useCardsTemplate} from 'Event/template/Cards'
import Title from 'lib/ui/ConfigPanel/Title'
import Divider from 'lib/ui/ConfigPanel/Divider'
import ImageAssetUploader from 'lib/asset/ImageAssetUploader'

const MIN_FOOTER_IMAGE_SIZE = 1
const MAX_FOOTER_IMAGE_SIZE = 550

export default function Settings(props: {control: UseFormMethods['control']}) {
  const {footer} = useCardsTemplate()
  const {control} = props
  const {imageSize, termsLink, privacyLink, copyrightText, image} = footer

  return (
    <>
      <Title>Image</Title>
      <Controller
        name="image"
        control={control}
        defaultValue={image}
        render={({onChange, value}) => (
          <ImageAssetUploader
            onChange={onChange}
            value={value}
            uploadLabel="Upload"
            canResize
            uploadInputProps={{
              'aria-label': 'footer image upload',
            }}
            disableAutoRemove
          />
        )}
      />
      <Controller
        control={control}
        name="imageSize"
        defaultValue={imageSize || DEFAULT_FOOTER_IMAGE_SIZE}
        render={({value, onChange}) => (
          <Slider
            min={MIN_FOOTER_IMAGE_SIZE}
            max={MAX_FOOTER_IMAGE_SIZE}
            step={1}
            onChange={onChange}
            valueLabelDisplay="auto"
            value={value}
            aria-label="footer image size"
            label="Size"
          />
        )}
      />
      <Divider />
      <Title>Link</Title>
      <Label>Terms</Label>
      <Controller
        name="termsLink"
        control={control}
        defaultValue={termsLink}
        render={({value, onChange}) => (
          <TextField
            value={value}
            aria-label="set footer terms link"
            fullWidth
            onChange={onChange}
          />
        )}
      />
      <Label>Privacy</Label>
      <Controller
        name="privacyLink"
        control={control}
        defaultValue={privacyLink}
        render={({value, onChange}) => (
          <TextField
            value={value}
            aria-label="set privacy terms link"
            fullWidth
            onChange={onChange}
          />
        )}
      />
      <Divider />
      <Title>Copyright Text</Title>
      <Controller
        name="copyrightText"
        control={control}
        defaultValue={copyrightText}
        render={({value, onChange}) => (
          <TextField
            value={value}
            aria-label="set copyright text"
            fullWidth
            onChange={onChange}
          />
        )}
      />
    </>
  )
}

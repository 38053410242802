import styled from 'styled-components'
import React, {useState} from 'react'
import {Organization} from 'organization'
import {createRoutesFor} from 'organization/OrganizationProvider'
import logoImgVertical from 'assets/images/logo_vertical.png'
import {RelativeLink} from 'lib/ui/link/RelativeLink'
import {MoreActionsMenu} from 'lib/ui/Menu'
import MenuItem from 'lib/ui/Menu/MenuItem'
import DeleteOrganizationDialog from 'obvio/Organizations/Collection/DeleteOrganizationDialog'
import {DeleteOrganizationButton} from 'obvio/Organizations/Collection/Cards/GridCard'

export default function ListCard(props: {
  organization: Organization
  index: number
}) {
  const {organization, index} = props
  const routes = createRoutesFor(organization)
  const url = routes.root
  const label = `view ${organization.name}`
  const logo = organization.logo?.url || logoImgVertical
  const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false)

  return (
    <Container index={index} aria-label="list card">
      <RelativeLink to={url} disableStyles aria-label={label}>
        <Content>
          <LogoImage src={logo} alt="organization logo" />
          <span>{props.organization.name}</span>
        </Content>
      </RelativeLink>
      <MoreActionsMenu>
        <RelativeLink to={url} disableStyles aria-label="view organization">
          <MenuItem>View</MenuItem>
        </RelativeLink>
        <DeleteOrganizationButton
          organization={organization}
          setShowDeleteDialog={setShowDeleteDialog}
        />
      </MoreActionsMenu>
      <DeleteOrganizationDialog
        organization={organization}
        visible={showDeleteDialog}
        onClose={() => {
          setShowDeleteDialog(false)
        }}
      />
    </Container>
  )
}

const Container = styled.div<{
  index: number
}>`
  ${(props) =>
    props.index % 2 === 0
      ? `background-color: ${props.theme.colors.white}`
      : `background-color: ${props.theme.colors.gray}`};

  border-radius: 5px;
  height: ${(props) => props.theme.spacing[35]};
  display: flex;
  align-items: center;
  padding: ${(props) => props.theme.spacing[5]}
    ${(props) => props.theme.spacing[3]};

  a {
    width: 100%;
  }

  @media all and (min-width: ${(props) => props.theme.breakpoints.sm}) {
    height: auto;
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  &:hover img,
  &:hover span {
    opacity: 0.5;
  }

  span {
    margin-right: ${(props) => props.theme.spacing[4]};
  }
`

const LogoImage = styled.img`
  max-height: 50px;
  max-width: 50px;
`

import React from 'react'
import {Cards, useCardsUpdate} from 'Event/template/Cards'
import ComponentConfig, {
  ComponentConfigProps,
  SaveButton,
  Footer,
} from 'organization/Event/DashboardConfig/ComponentConfigPanel'
import {useForm} from 'react-hook-form'
import Settings from 'Event/template/Cards/Dashboard/Header/HeaderConfig/Settings'
import Styling from 'Event/template/Cards/Dashboard/Header/HeaderConfig/Styling'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import StylingPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/StylingPanel'
import {
  useAutoUpdate,
  useTemplateEditor,
} from 'organization/Event/TemplateEditor'
import {usePruneAssets} from 'lib/asset'

type ConfigFormData = Pick<
  Cards,
  'backgroundPosition' | 'menu' | 'header' | 'logo'
>

export default function HeaderConfig(props: ComponentConfigProps) {
  const {showing, onClose} = props
  const update = useCardsUpdate()
  const {handleSubmit, control, watch, formState} = useForm()
  const pruneAssets = usePruneAssets()
  const {saved} = useTemplateEditor<Cards>()

  const save = (data: ConfigFormData) => {
    pruneAssets({logo: data.logo}, saved)
    update(data)
    onClose()
  }

  useAutoUpdate({values: {header: watch()}, when: showing})

  return (
    <ComponentConfig
      showing={showing}
      onClose={onClose}
      title="Header"
      onSubmit={handleSubmit(save)}
      hasChanges={formState.isDirty}
    >
      <SettingsPanel>
        <Settings control={control} />
      </SettingsPanel>
      <StylingPanel>
        <Styling control={control} />
      </StylingPanel>
      <Footer>
        <SaveButton type="submit" />
      </Footer>
    </ComponentConfig>
  )
}

import {FaqItem, FaqListBlock} from 'Event/Marketplace/Block/FaqList'

export const createFaqListBlock = (): FaqListBlock => ({
  type: 'FaqList',
  visibility: 'all',
  background: {
    image: '',
    color: '',
    opacity: 0,
  },
  padding: {
    top: 10,
    right: 10,
    bottom: 10,
    left: 10,
  },
  alignment: {
    vertical: 'start',
    horizontal: 'left',
  },
  border: {
    width: 0,
    color: '#FFFFFF',
    radius: 0,
  },
  icon: 'fa-solid fa-angle-down',
  font: null,
  fontSize: 24,
  color: '#3490DC',
  iconColor: '#3490DC',
  iconSize: 24,
  faqSpace: 10,
  textSpace: 10,
  items: {},
})

export const createFaqItem = (): FaqItem => ({
  showAnswerOnLoad: false,
  question: '<p>Question</p>',
  answer: '',
  visibility: 'all',
  border: {
    width: 0,
    color: '#FFFFFF',
    radius: 0,
  },
  background: {
    image: '',
    color: '',
    opacity: 0,
  },
})

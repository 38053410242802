import Checkbox from '@material-ui/core/Checkbox'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import TextField from 'lib/ui/TextField'
import {onChangeCheckedHandler, onUnknownChangeHandler} from 'lib/dom'
import React from 'react'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'
import {EventPages, EVENT_PAGES} from 'Event/Routes'
import {TargetInputProps} from 'Event/Dashboard/components/NavButton/NavButtonConfig/TargetInput'
import {useTemplate} from 'Event/TemplateProvider'
import {Controller} from 'react-hook-form'
import {useIsExternalLinkUrl} from 'Event/Dashboard/components/NavButton'

type LinkConfigProps = TargetInputProps & {
  pages?: EventPages
}

export default function LinkConfig(props: LinkConfigProps) {
  const {control, watch, button} = props

  const isAreaButton = watch('isAreaButton', button.isAreaButton)
  const isImageUpload = watch('isImageUpload', button.isImageUpload)
  const isForm = watch('isFormButton', button.isFormButton)
  const isLinkButton = !isAreaButton && !isImageUpload && !isForm

  const hasActionId = watch('actionId', button.actionId)
  const hasInfusionsoftTag = watch('infusionsoftTag', button.infusionsoftTag)
  const hasZapierTag = watch('zapierTag', button.zapierTag)
  const hasMailchimpTag = watch('mailchimpTag', button.mailchimpTag)
  const hasHubspotTag = watch('hubspotTag', button.hubspotTag)
  const hasActiveCampaignTag = watch(
    'activeCampaignTag',
    button.activeCampaignTag,
  )
  const checkWebhook = watch('webhook', button.webhook)
  const hasWebhook = checkWebhook?.id
  const buttonLink = watch('link', button.link)
  const isExternalLinkUrl = useIsExternalLinkUrl(buttonLink)

  if (!isLinkButton) {
    return null
  }

  // Whether a button triggers some other request, such as action, applying tag, webhook.
  const hasTrigger =
    hasActionId ||
    hasInfusionsoftTag ||
    hasZapierTag ||
    hasMailchimpTag ||
    hasHubspotTag ||
    hasActiveCampaignTag ||
    hasWebhook

  // If the host of the link is NOT the event's host AND... any of the "click
  // actions" are present, we need to disable the "new tab" checkbox, while also
  // checking it.
  const requiresNewTab = hasTrigger && isExternalLinkUrl

  const titleText = requiresNewTab
    ? 'New Tab is required for external links which also have one of Action for Points, Keap, Zapier, Mailchimp or Webhooks enabled'
    : ''

  return (
    <>
      <PageSelect {...props} />
      <LinkInput {...props} />
      <Controller
        name="newTab"
        control={control}
        defaultValue={button.newTab || false}
        render={({value, onChange}) => (
          <FormControl>
            <FormControlLabel
              label="New Tab"
              title={titleText}
              control={
                <Checkbox
                  checked={requiresNewTab || value}
                  disabled={requiresNewTab}
                  onChange={onChangeCheckedHandler(onChange)}
                  title={titleText}
                  inputProps={{
                    'aria-label': 'optional new tab',
                  }}
                />
              }
            />
          </FormControl>
        )}
      />
    </>
  )
}

function PageSelect(props: LinkConfigProps) {
  const pages = props.pages || EVENT_PAGES
  const {button, control} = props

  const isVisible = useIsVisiblePage()
  const enabledPages = Object.entries(pages).filter(([_, title]) =>
    isVisible(title),
  )

  if (props.disablePageSelect) {
    return null
  }

  return (
    <FormControl fullWidth>
      <InputLabel>Page</InputLabel>
      <Controller
        name="page"
        defaultValue={button.page || ''}
        control={control}
        render={({value, onChange}) => (
          <Select
            value={value}
            fullWidth
            onChange={onUnknownChangeHandler(onChange)}
            label="Link"
            inputProps={{
              'aria-label': 'pick page',
            }}
          >
            {enabledPages.map(([link, label]) => (
              <MenuItem value={link} aria-label={`${label} page`} key={link}>
                {label}
              </MenuItem>
            ))}
            <MenuItem value={0} aria-label="set other link">
              Other
            </MenuItem>
          </Select>
        )}
      />
    </FormControl>
  )
}

function useIsVisiblePage() {
  const {
    speakers,
    sponsors,
    faq,
    leaderboard,
    zoomBackgrounds,
    imageWaterfall,
  } = useTemplate()

  // Map of event page titles as defined in EVENT_PAGES, and their
  // respective config values for whether to show the page.
  const VISIBLE: Record<string, boolean> = {
    Speakers: speakers.isEnabled,
    Sponsors: sponsors.isEnabled,
    FAQ: faq.isEnabled,
    Leaderboard: leaderboard.isVisible,
    Backgrounds: zoomBackgrounds.isEnabled,
    'Image Waterfall': imageWaterfall.isVisible,
  }

  return (title: string) => {
    const isConfigurable = Object.prototype.hasOwnProperty.call(VISIBLE, title)
    if (!isConfigurable) {
      return true
    }

    return VISIBLE[title]
  }
}

function LinkInput(props: LinkConfigProps) {
  const {button, register, watch} = props

  if (watch('page', button.page)) {
    return null
  }

  return (
    <TextField
      label="URL"
      name="link"
      defaultValue={button.link || ''}
      inputProps={{
        'aria-label': 'button link input',
        ref: register,
      }}
      fullWidth
      helperText="Starting with https:// or http://"
    />
  )
}

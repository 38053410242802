import React from 'react'
import FormSelect from 'organization/Event/FormsProvider/FormSelect'
import {TargetInputProps} from 'Event/Dashboard/components/NavButton/NavButtonConfig/TargetInput'
import {Controller} from 'react-hook-form'

export default function FormConfig(props: TargetInputProps) {
  const {control, watch, button} = props

  if (!watch('isFormButton', button.isFormButton)) {
    return null
  }

  return (
    <Controller
      name="formId"
      defaultValue={button.formId}
      control={control}
      render={({value, onChange}) => (
        <FormSelect value={value} onChange={onChange} />
      )}
    />
  )
}

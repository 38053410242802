import {OfflinePageProps} from 'Event/JoinArea/OfflinePage'
import React from 'react'
import Page, {DescriptionText} from 'Event/template/SimpleBlog/Login/Page'
import {useSimpleBlogTemplate} from 'Event/template/SimpleBlog'
import {useGuestVariables} from 'Event'
import Configurable from 'organization/Event/Configurable'
import OfflineContentConfig from 'Event/EventOfflinePage/OfflineContentConfig'

export default function OfflinePage(props: OfflinePageProps) {
  const v = useGuestVariables()

  return (
    <Page isPreview={props.isPreview}>
      <Configurable
        aria-label="event offline content config"
        dataTestId="event offline config"
      >
        <OfflineContentConfig />
        <>
          <Text disableMargin fontSize={24}>
            {v(props.title)}
          </Text>
          <Text>
            <div dangerouslySetInnerHTML={{__html: v(props.description)}} />
          </Text>
        </>
      </Configurable>
    </Page>
  )
}

function Text(props: {
  children: string | React.ReactElement
  'aria-label'?: string
  disableMargin?: boolean
  fontSize?: number
}) {
  const template = useSimpleBlogTemplate()
  const {login, linkColor, linkUnderline} = template
  const color = login.description.color
  const fontSize = props.fontSize
    ? props.fontSize
    : template.login.description.fontSize
  if (!props.children) {
    return null
  }

  return (
    <DescriptionText
      textColor={color}
      fontSize={fontSize}
      disableMargin={props.disableMargin}
      aria-label={props['aria-label']}
      linkColor={linkColor}
      linkUnderline={linkUnderline}
    >
      {props.children}
    </DescriptionText>
  )
}

import Select from 'lib/ui/Select'
import FormControl from '@material-ui/core/FormControl/FormControl'
import Option from 'lib/ui/Select/Option'
import TextField from 'lib/ui/TextField'
import {RuleConfigProps} from 'Event/attendee-rules/RuleConfig/RuleList/RuleForm/SourceConfig'
import {onUnknownChangeHandler} from 'lib/dom'
import React, {useEffect, useState} from 'react'
import {Rule} from 'Event/attendee-rules'
import {
  createTagsRule,
  DOES_NOT_INCLUDE,
  INCLUDE,
  TAGS,
  TagsRule,
} from 'Event/attendee-rules/RuleConfig/RuleList/SingleRule/TagsRule'

export default function TagsRuleConfig(props: RuleConfigProps) {
  const [type, setType] = useState<TagsRule['type']>(initialType(props.rule))
  const [target, setTarget] = useState(initialTarget(props.rule))
  const {onSet} = props

  useEffect(() => {
    const isValid = type && Boolean(target)
    const rule = isValid ? createTagsRule(type, target) : null
    onSet(rule)
  }, [type, target, onSet])

  return (
    <>
      <FormControl fullWidth>
        <Select
          value={type}
          fullWidth
          onChange={onUnknownChangeHandler(setType)}
          aria-label="pick tags rule type"
        >
          <Option value={INCLUDE}>{INCLUDE}</Option>
          <Option value={DOES_NOT_INCLUDE}>{DOES_NOT_INCLUDE}</Option>
        </Select>
      </FormControl>
      <TextField
        fullWidth
        variant="outlined"
        placeholder="Value"
        aria-label="new tag target"
        value={target}
        onChange={setTarget}
      />
    </>
  )
}

function initialType(rule: Rule | null) {
  if (!rule || rule.source !== TAGS) {
    return INCLUDE
  }

  return rule.type
}

function initialTarget(rule: Rule | null) {
  if (!rule || rule.source !== TAGS) {
    return ''
  }

  return rule.target
}

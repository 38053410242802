import {onUnknownChangeHandler} from 'lib/dom'
import ColorPicker from 'lib/ui/ColorPicker'
import React from 'react'
import {Controller, UseFormMethods} from 'react-hook-form'
import FormControl from '@material-ui/core/FormControl'
import Select from 'lib/ui/Select'
import Option from 'lib/ui/Select/Option'
import {useLeftyTemplate} from 'Event/template/Lefty'
import Slider from 'lib/ui/Slider'
import CollapsibleSection from 'lib/ui/ConfigPanel/CollapsibleSection'

const MIN_SIDEBAR_PADDING_TOP = 0
const MAX_SIDEBAR_PADDING_TOP = 720

const MIN_SIDEBAR_BORDER_WIDTH = 0
const MAX_SIDEBAR_BORDER_WIDTH = 50

const MIN_SIDEBAR_BORDER_RADIUS = 0
const MAX_SIDEBAR_BORDER_RADIUS = 25

const MIN_SIDEBAR_ITEM_SPACE = 0
const MAX_SIDEBAR_ITEM_SPACE = 200

export default function Styling(props: {control: UseFormMethods['control']}) {
  const {sidebar, textColor} = useLeftyTemplate()
  const {control} = props
  const sidebarTextColor = sidebar.textColor ? sidebar.textColor : textColor

  return (
    <>
      <CollapsibleSection
        label="Spacing"
        storageKey="sidebar.config.styling.spacing"
      >
        <Controller
          name="paddingTop"
          defaultValue={sidebar.paddingTop}
          control={control}
          render={({value, onChange}) => (
            <Slider
              min={MIN_SIDEBAR_PADDING_TOP}
              max={MAX_SIDEBAR_PADDING_TOP}
              step={4}
              onChange={onChange}
              valueLabelDisplay="auto"
              value={value}
              aria-label="padding top"
              label="Top Padding"
            />
          )}
        />

        <Controller
          name="itemSpacing"
          defaultValue={sidebar.itemSpacing}
          control={control}
          render={({value, onChange}) => (
            <Slider
              min={MIN_SIDEBAR_ITEM_SPACE}
              max={MAX_SIDEBAR_ITEM_SPACE}
              step={1}
              onChange={onChange}
              valueLabelDisplay="auto"
              value={value}
              aria-label="item spacing"
              label="Space Between Items"
            />
          )}
        />
      </CollapsibleSection>
      <CollapsibleSection
        label="Separator"
        storageKey="sidebar.config.styling.separator"
      >
        <Controller
          name="separatorThickness"
          defaultValue={sidebar.separatorThickness}
          control={control}
          render={({value, onChange}) => (
            <Slider
              min={1}
              max={10}
              step={1}
              onChange={onChange}
              valueLabelDisplay="auto"
              value={value}
              aria-label="separator width"
              label="Separator Thickness"
            />
          )}
        />
        <Controller
          name="separatorColor"
          defaultValue={sidebar.separatorColor}
          control={control}
          render={({value, onChange}) => (
            <ColorPicker
              label="Separator Color"
              color={value}
              onPick={onChange}
              aria-label="separator color"
            />
          )}
        />
        <FormControl fullWidth>
          <Controller
            name="separatorStyle"
            defaultValue={sidebar.separatorStyle}
            control={control}
            render={({value, onChange}) => (
              <Select
                value={value}
                onChange={onUnknownChangeHandler(onChange)}
                label="Separator Style"
              >
                <Option dark value="solid">
                  Solid
                </Option>
                <Option dark value="dashed">
                  Dashed
                </Option>
                <Option dark value="dotted">
                  Dotted
                </Option>
                <Option dark value="none">
                  None
                </Option>
              </Select>
            )}
          />
        </FormControl>
      </CollapsibleSection>

      <CollapsibleSection
        label="Colors"
        storageKey="sidebar.config.styling.colors"
      >
        <Controller
          name="background"
          defaultValue={sidebar.background}
          control={control}
          render={({value, onChange}) => (
            <ColorPicker
              label="Background Color"
              color={value}
              onPick={onChange}
              aria-label="background color"
            />
          )}
        />
        <Controller
          name="textColor"
          defaultValue={sidebarTextColor}
          control={control}
          render={({value, onChange}) => (
            <ColorPicker
              label="Text Color"
              color={value}
              onPick={onChange}
              aria-label="color"
            />
          )}
        />
      </CollapsibleSection>
      <CollapsibleSection
        label="Border"
        storageKey="sidebar.config.styling.border"
      >
        <Controller
          name="borderWidth"
          defaultValue={sidebar.borderWidth}
          control={control}
          render={({value, onChange}) => (
            <Slider
              min={MIN_SIDEBAR_BORDER_WIDTH}
              max={MAX_SIDEBAR_BORDER_WIDTH}
              step={1}
              onChange={onChange}
              valueLabelDisplay="auto"
              value={value}
              aria-label="border thickness"
              label="Thickness"
            />
          )}
        />
        <Controller
          name="borderColor"
          defaultValue={sidebar.borderColor}
          control={control}
          render={({value, onChange}) => (
            <ColorPicker label="Color" color={value} onPick={onChange} />
          )}
        />
        <Controller
          name="borderRadius"
          defaultValue={sidebar.borderRadius}
          control={control}
          render={({value, onChange}) => (
            <Slider
              min={MIN_SIDEBAR_BORDER_RADIUS}
              max={MAX_SIDEBAR_BORDER_RADIUS}
              step={1}
              onChange={onChange}
              valueLabelDisplay="auto"
              value={value}
              aria-label="border radius"
              label="Radius"
            />
          )}
        />
      </CollapsibleSection>

      <CollapsibleSection
        label="Icon on Mobile"
        storageKey="sidebar.config.styling.icon"
      >
        <Controller
          name="mobile.icon.color"
          defaultValue={sidebar.mobile.icon.color}
          control={control}
          render={({value, onChange}) => (
            <ColorPicker
              label="Color"
              color={value}
              onPick={onChange}
              aria-label="icon color on mobile"
            />
          )}
        />
        <Controller
          name="mobile.icon.background"
          defaultValue={sidebar.mobile.icon.background}
          control={control}
          render={({value, onChange}) => (
            <ColorPicker
              label="Background Color"
              color={value}
              onPick={onChange}
              aria-label="icon background on mobile"
            />
          )}
        />
      </CollapsibleSection>
    </>
  )
}

import {useEvent} from 'Event/EventProvider'
import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'

import {Label} from 'lib/ui/typography'
import styled from 'styled-components'
import Button from 'lib/ui/Button'
import {useToggle} from 'lib/toggle'
import {OntraportTag} from 'organization/Event/Services/Apps/Ontraport/Config/Tags'
import AutocompleteTags, {
  CreateNewTagDialog,
} from 'organization/Event/Services/Apps/Ontraport/Config/Tags/AutocompleteTags'

export default function OntraportTagInput(props: {
  onChange: (tag: OntraportTag | null) => void
  value?: OntraportTag | null
  disabled?: boolean
}) {
  const {value, onChange} = props

  const {event} = useEvent()
  const {flag: showCreateForm, toggle: toggleShowCreateForm} = useToggle()
  if (!event.has_ontraport) {
    return null
  }

  const handleOnCreate = (newTag: OntraportTag) => {
    toggleShowCreateForm()
    onChange(newTag)
  }

  return (
    <div>
      <StyledLabel>Ontraport Tag</StyledLabel>
      <Box>
        <AutocompleteTags
          inputLabel="Tag"
          value={value}
          onChange={onChange}
          inputVariant="outlined"
          disabled={props.disabled ?? false}
          endAdornment={(loading, arrow) =>
            loading ? (
              <>
                <CircularProgress color="inherit" size={20} />
              </>
            ) : (
              <>
                {arrow}
                <Button
                  color="primary"
                  size="large"
                  aria-label="create tag"
                  onClick={toggleShowCreateForm}
                >
                  Create new tag
                </Button>
              </>
            )
          }
        />
      </Box>
      <CreateNewTagDialog
        visible={showCreateForm}
        onClose={toggleShowCreateForm}
        onCreate={handleOnCreate}
      />
    </div>
  )
}

const StyledLabel = styled(Label)`
  margin-bottom: ${(props) => props.theme.spacing[4]} !important;
`

const Box = styled.div`
  display: flex;
  gap: ${(props) => props.theme.spacing[1]};

  > div {
    flex: 1;
  }
`

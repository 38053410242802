import Plugin from "@ckeditor/ckeditor5-core/src/plugin";

import Value from "./Value";
import EditorComponents from "./EditorComponents";

export default class ObvioVariableSelector extends Plugin {
	static get requires() {
		return [Value, EditorComponents];
	}
}

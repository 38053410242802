import React from 'react'
import {Controller, UseFormMethods} from 'react-hook-form'
import {NavButtonWithSize} from 'Event/Dashboard/components/NavButton'
import InfusionsoftTagInput from 'organization/Event/DashboardConfig/InfusionsoftTagInput'
import MailchimpTagInput from 'organization/Event/DashboardConfig/MailchimpTagInput'
import ZapierTagInput from 'organization/Event/DashboardConfig/ZapierTagInput'
import {CardsNavButtonProps} from 'Event/template/Cards/Dashboard/CardsNavButton'
import HubspotTagInput from 'organization/Event/DashboardConfig/HubspotTagInput'
import ActiveCampaignTagInput from 'organization/Event/DashboardConfig/ActiveCampaignTagInput'
import HighLevelTagInput from 'organization/Event/DashboardConfig/HighLevelTagInput'
import ConvertKitTagInput from 'organization/Event/DashboardConfig/ConvertKitTagInput'
import OntraportTagInput from 'organization/Event/DashboardConfig/OntraportTagInput'

export default function TagFields(props: {
  button: NavButtonWithSize | CardsNavButtonProps
  control: UseFormMethods['control']
}) {
  const {control, button} = props

  return (
    <>
      <Controller
        name="infusionsoftTag"
        control={control}
        defaultValue={button.infusionsoftTag || ''}
        render={({value, onChange}) => (
          <InfusionsoftTagInput value={value} onChange={onChange} />
        )}
      />
      <Controller
        name="mailchimpTag"
        control={control}
        defaultValue={button.mailchimpTag || ''}
        render={({value, onChange}) => (
          <MailchimpTagInput
            value={{name: value}}
            onChange={(tag) => onChange(tag?.name ?? null)}
          />
        )}
      />
      <Controller
        name="zapierTag"
        control={control}
        defaultValue={button.zapierTag || ''}
        render={({value, onChange}) => (
          <ZapierTagInput
            value={{name: value}}
            onChange={(tag) => onChange(tag?.name ?? null)}
          />
        )}
      />

      <Controller
        name="highLevelTag"
        control={control}
        defaultValue={button.highLevelTag || ''}
        render={({value, onChange}) => (
          <HighLevelTagInput
            value={{name: value}}
            onChange={(tag) => onChange(tag?.name ?? null)}
          />
        )}
      />
      <Controller
        name="hubspotTag"
        control={control}
        defaultValue={button.hubspotTag || ''}
        render={({value, onChange}) => (
          <HubspotTagInput value={value} onChange={onChange} />
        )}
      />
      <Controller
        name="activeCampaignTag"
        control={control}
        defaultValue={button.activeCampaignTag || ''}
        render={({value, onChange}) => (
          <ActiveCampaignTagInput value={value} onChange={onChange} />
        )}
      />
      <Controller
        name="convertKitTag"
        control={control}
        defaultValue={button.convertKitTag || ''}
        render={({value, onChange}) => (
          <ConvertKitTagInput value={value} onChange={onChange} />
        )}
      />
      <Controller
        name="ontraportTag"
        control={control}
        defaultValue={button.ontraportTag || ''}
        render={({value, onChange}) => (
          <OntraportTagInput value={value} onChange={onChange} />
        )}
      />
    </>
  )
}

import TextField from 'lib/ui/TextField'
import {
  ATTENDEE_CHECKED_IN,
  ATTENDEE_SIGNED_WAIVER,
  FORM_SUBMITTED,
  Tag,
} from 'organization/Event/Services/Apps/Mailchimp'
import React from 'react'

/**
 * How many MS to wait after typing to search to see
 * if the tag exists.
 */
export const TAG_SEARCH_DEBOUNCE_MS = 250

export const NEW_TAG_ERROR =
  'Tag does not currently exist. To use it anyway, click SAVE and Obvio will create the tag for you.'

export default function TagInput(props: {
  tag: Tag
  onChange: (tag: Tag) => void
}) {
  const {tag, onChange} = props

  const setName = (tagName: string | null) => {
    const updatedTag: Tag = {
      ...tag,
      name: tagName,
    }
    onChange(updatedTag)
  }

  return (
    <TextField
      value={tag.name || ''}
      onChange={setName}
      variant="outlined"
      label={typeLabel(tag)}
      fullWidth
      inputProps={{
        'aria-label': 'tag name',
        maxLength: 100, // mailchimp limit
      }}
    />
  )
}

function typeLabel(tag: Tag) {
  const labels: Record<string, string> = {
    [ATTENDEE_SIGNED_WAIVER]: 'Attendee Signed Waiver',
    [ATTENDEE_CHECKED_IN]: 'Attendee Checked In',
    [FORM_SUBMITTED]: 'Form submitted',
  }

  const label = labels[tag.type]
  if (!label) {
    throw new Error(`Label not defined for tag type: ${tag.type}`)
  }

  return label
}

import {useEditMode} from 'Event/EditModeProvider'
import EditIconButton from 'lib/ui/IconButton/EditIconButton'
import CopyIconButton from 'lib/ui/IconButton/DuplicateIconButton'
import React from 'react'
import styled from 'styled-components'
import MoveIconButton from 'lib/ui/IconButton/MoveIconButton'

export const EDIT_COMPONENT_CLASS = 'edit-component'
export const EDIT_COMPONENT_BUTTON_CLASS = 'edit-component-button'

export const DUPLICATE_COMPONENT_CLASS = 'duplicate-component'
export const DUPLICATE_COMPONENT_BUTTON_CLASS = 'duplicate-component-button'

export const MOVE_UP_COMPONENT_BUTTON_CLASS = 'move-up-component-button'

export const MOVE_DOWN_COMPONENT_BUTTON_CLASS = 'move-down-component-button'

export type EditableProps = {
  children: React.ReactElement
  onEdit: () => void
  onCopy?: () => void
  moveUp?: () => void
  moveDown?: () => void
  className?: string
  dataTestId?: string
  ['aria-label']?: string
  copyRightSpacing?: number
}

/**
 * A more generic version of EditComponent where handling the edit/config
 * dialog rendering is handled by the parent.
 *
 * @param props
 * @returns
 */
export function Editable(props: EditableProps) {
  const isEditMode = useEditMode()
  if (!isEditMode) {
    return props.children
  }

  return (
    <EditComponentOverlay
      onClick={props.onEdit}
      onCopy={props.onCopy}
      onMoveDown={props.moveDown}
      onMoveUp={props.moveUp}
      className={props.className}
      aria-label={props['aria-label']}
      dataTestId={props.dataTestId}
      copyRightSpacing={props.copyRightSpacing}
    >
      {props.children}
    </EditComponentOverlay>
  )
}

export function EditComponentOverlay(props: {
  onClick: () => void
  onCopy?: () => void
  onMoveUp?: () => void
  onMoveDown?: () => void
  children: React.ReactElement
  disableChildInteraction?: boolean
  className?: string
  ['aria-label']?: string
  dataTestId?: string
  copyRightSpacing?: number
}) {
  const className = `${EDIT_COMPONENT_CLASS} ${props.className}`
  const label = props['aria-label'] ?? 'edit component'

  return (
    <Box className={className} data-testid={props.dataTestId}>
      <InteractionOverlay disable={props.disableChildInteraction} />
      <StyledCopyIconButton
        onClick={props.onCopy}
        className={DUPLICATE_COMPONENT_BUTTON_CLASS}
        showing={Boolean(props.onCopy)}
        type="button"
        aria-label="duplicate component"
        rightSpacing={props.copyRightSpacing}
      />
      <StyledEditIconButton
        onClick={props.onClick}
        className={EDIT_COMPONENT_BUTTON_CLASS}
        type="button"
        aria-label={label}
      />
      <StyledMoveIconButton
        onClick={props.onMoveUp}
        className={MOVE_UP_COMPONENT_BUTTON_CLASS}
        showing={Boolean(props.onMoveUp)}
        type="button"
        aria-label="move component up"
        direction="up"
        rightSpacing={10}
      />
      <StyledMoveIconButton
        onClick={props.onMoveDown}
        className={MOVE_DOWN_COMPONENT_BUTTON_CLASS}
        showing={Boolean(props.onMoveDown)}
        type="button"
        aria-label="move component down"
        direction="down"
        rightSpacing={Boolean(props.onMoveUp) ? 18 : 10}
      />
      {props.children}
    </Box>
  )
}

const InteractionOverlay = styled.div<{disable?: boolean}>`
  display: ${(props) => (props.disable ? 'block' : 'none')};
  position: ${(props) => (props.disable ? 'absolute' : 'relative')};
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
`

const StyledEditIconButton = styled(EditIconButton)`
  position: absolute;
  z-index: 3;
  right: ${(props) => props.theme.spacing[1]};
  top: ${(props) => props.theme.spacing[1]};
  display: none;

  &:hover {
    opacity: 0.8;
  }
`

const StyledCopyIconButton = styled(CopyIconButton)<{rightSpacing?: number}>`
  position: absolute;
  z-index: 4;
  right: ${(props) =>
    props.rightSpacing
      ? props.theme.spacing[props.rightSpacing]
      : props.theme.spacing[19]};
  top: ${(props) => props.theme.spacing[1]};
  display: none;

  &:hover {
    opacity: 0.8;
  }
`

const StyledMoveIconButton = styled(MoveIconButton)<{rightSpacing: number}>`
  position: absolute;
  z-index: 4;
  right: ${(props) => props.theme.spacing[props.rightSpacing]};
  top: ${(props) => props.theme.spacing[1]};
  display: none;

  &:hover {
    opacity: 0.8;
  }
`

const Box = styled.div`
  position: relative;
  width: 100%;

  &:hover > ${StyledEditIconButton} {
    display: inline-flex;
  }
  &:hover > ${StyledCopyIconButton} {
    display: inline-flex;
  }
  &:hover > ${StyledMoveIconButton} {
    display: inline-flex;
  }
`

import React from 'react'
import Grid from '@material-ui/core/Grid'
import FormButtons from 'Event/Marketplace/Block/PurchaseForm/FormButtons'
import BackButtonButton from 'Event/Marketplace/Block/PurchaseForm/BackButton'
import NextStepButton from 'Event/Marketplace/Block/PurchaseForm/NextStepButton'
import Checkbox from 'lib/ui/Checkbox'
import styled from 'styled-components'
import {usePurchaseForm} from 'Event/Marketplace/Block/PurchaseForm/PurchaseFormProvider'
import {BlockComponentProps} from 'Event/Marketplace/Block'
import CountrySelect from 'lib/CountrySelect'
import StateSelect from 'lib/StateSelect'
import {Controller} from 'react-hook-form'
import {PurchaseFormBlock} from 'Event/Marketplace/Block/PurchaseForm'

interface BillingAddressStepProps extends PurchaseFormBlock {
  onBack: () => void
  onContinue: () => void
  TextFieldComponent: React.FC<any>
  ButtonComponent: React.FC<any>
}

export default function BillingAddressStep(
  props: BillingAddressStepProps & BlockComponentProps,
) {
  const {
    onBack,
    onContinue,
    TextFieldComponent,
    ButtonComponent,
    ...config
  } = props

  const {
    useSameAddressForShipping,
    setUseSameAddressForShipping,
    form: {trigger, register, errors, control, watch},
  } = usePurchaseForm()

  const countryId = watch(`billing_address.country_id`)

  const handleContinue = async () => {
    trigger([
      `billing_address.address_1`,
      `billing_address.country_id`,
      `billing_address.state_id`,
      `billing_address.city`,
      `billing_address.zip_postal`,
    ]).then((valid) => {
      if (valid) {
        onContinue()
      }
    })
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextFieldComponent
          aria-label={`billing_address address 1`}
          config={config}
          placeholder={'billingAddress1'}
          name={`billing_address.address_1`}
          inputProps={{
            ref: register({
              required: true,
            }),
          }}
          error={Boolean(errors['billing_address']?.address_1)}
          helperText={
            Boolean(errors['billing_address']?.address_1)
              ? config.errors.requiredField
              : ''
          }
        />
      </Grid>
      <Grid item xs={12}>
        <TextFieldComponent
          aria-label={`billing_address address 2`}
          config={config}
          placeholder={'billingAddress2'}
          name={`billing_address.address_2`}
          inputProps={{
            ref: register,
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Controller
          control={control}
          name={`billing_address.country_id`}
          rules={{
            validate: (v) => (v && v !== 0) || config.errors.requiredField,
          }}
          render={({value, onChange}) => (
            <StyledCountrySelect
              config={config}
              value={value}
              onChange={onChange}
              fullWidth
              variant="outlined"
              defaultLabel={config.input.placeholder.country}
              required
              error={Boolean(errors['billing_address']?.country_id)}
              helperText={errors['billing_address']?.country_id?.message}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Controller
          control={control}
          name={`billing_address.state_id`}
          rules={{
            validate: (v) => (v && v !== 0) || config.errors.requiredField,
          }}
          render={({value, onChange}) => (
            <StyledStateSelect
              config={config}
              value={value}
              onChange={onChange}
              countryId={countryId}
              fullWidth
              variant="outlined"
              defaultLabel={config.input.placeholder.state}
              required
              error={Boolean(errors['billing_address']?.state_id)}
              helperText={errors['billing_address']?.state_id?.message}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextFieldComponent
          aria-label={`billing_address city`}
          config={config}
          placeholder={'city'}
          name={`billing_address.city`}
          inputProps={{
            ref: register({
              required: true,
            }),
          }}
          error={Boolean(errors['billing_address']?.city)}
          helperText={
            Boolean(errors['billing_address']?.city)
              ? config.errors.requiredField
              : ''
          }
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextFieldComponent
          aria-label={`billing_address postal`}
          config={config}
          placeholder={'zipcode'}
          name={`billing_address.zip_postal`}
          inputProps={{
            ref: register({
              required: true,
            }),
          }}
          error={Boolean(errors['billing_address']?.zip_postal)}
          helperText={
            Boolean(errors['billing_address']?.zip_postal)
              ? config.errors.requiredField
              : ''
          }
        />
      </Grid>

      <Grid item>
        <StyledCheckbox
          aria-label="shipping same as billing"
          config={config}
          onChange={() =>
            setUseSameAddressForShipping(!useSameAddressForShipping)
          }
          checked={useSameAddressForShipping}
          label={props.questions.sameShippingAddress}
        />
      </Grid>
      <Grid item xs={12}>
        <FormButtons>
          <BackButtonButton {...config} onClick={onBack} />
          <NextStepButton
            ButtonComponent={ButtonComponent}
            {...config}
            onClick={handleContinue}
          />
        </FormButtons>
      </Grid>
    </Grid>
  )
}

const StyledCheckbox = styled(Checkbox)<{
  config: PurchaseFormBlock
}>`
  color: ${(props) => props.config.input.textColor};
`

const StyledStateSelect = styled(StateSelect)<{
  config: PurchaseFormBlock
}>`
  margin-bottom: 0px;

  & > div > div {
    background-color: ${(props) => props.config.input.backgroundColor};
    border: ${(props) => props.config.input.border.width}px solid;
    border-color: ${(props) => props.config.input.border.color};
    border-radius: ${(props) => props.config.input.border.radius}px !important;
    padding-left: ${(props) => props.config.input.padding.left}px;
    padding-right: ${(props) => props.config.input.padding.right}px !important;
    padding-top: ${(props) => props.config.input.padding.top}px;
    padding-bottom: ${(props) => props.config.input.padding.bottom}px;
    font-size: ${(props) => props.config.input.fontSize}px;
    line-height: normal;
  }

  & > div > div:focus {
    background-color: ${(props) => props.config.input.backgroundColor};
  }
`

const StyledCountrySelect = styled(CountrySelect)<{
  config: PurchaseFormBlock
}>`
  margin-bottom: 0px;

  & > div > div {
    background-color: ${(props) => props.config.input.backgroundColor};
    border: ${(props) => props.config.input.border.width}px solid;
    border-color: ${(props) => props.config.input.border.color};
    border-radius: ${(props) => props.config.input.border.radius}px !important;
    padding-left: ${(props) => props.config.input.padding.left}px;
    padding-right: ${(props) => props.config.input.padding.right}px !important;
    padding-top: ${(props) => props.config.input.padding.top}px;
    padding-bottom: ${(props) => props.config.input.padding.bottom}px;
    font-size: ${(props) => props.config.input.fontSize}px;
    line-height: normal;
  }

  & > div > div:focus {
    background-color: ${(props) => props.config.input.backgroundColor};
  }
`

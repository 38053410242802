import React from 'react'
import {
  ATTENDEE_CHECKED_IN,
  ATTENDEE_CREATED,
  ATTENDEE_SIGNED_WAIVER,
  HubspotPlatformTag,
} from 'organization/Event/Services/Apps/Hubspot/index'
import TagInput, {
  TagInputProps,
} from 'organization/Event/Services/Apps/Hubspot/Config/TagInput'

export default function PlatformTagInput(
  props: Omit<TagInputProps, 'targetTypes' | 'onChange' | 'value'> & {
    value: HubspotPlatformTag
    onChange: (tag: HubspotPlatformTag) => void
    errorText?: string
  },
) {
  const {value} = props

  return (
    <TagInput
      targetTypes={['select', 'text', 'checkbox', 'booleancheckbox', 'radio']}
      label={label(value)}
      {...props}
      onChange={(tag) =>
        props.onChange({
          ...value,
          ...tag,
        })
      }
    />
  )
}

function label(tag: HubspotPlatformTag) {
  const labels: Record<string, string> = {
    [ATTENDEE_CREATED]: 'Attendee Created',
    [ATTENDEE_SIGNED_WAIVER]: 'Attendee Signed Waiver',
    [ATTENDEE_CHECKED_IN]: 'Attendee Checked In',
  }

  const label = labels[tag.type]
  if (!label) {
    throw new Error(`Label not defined for tag type: ${tag.type}`)
  }

  return label
}

import Slider from 'lib/ui/Slider'
import ColorPicker from 'lib/ui/ColorPicker'
import {useLeftyTemplate} from 'Event/template/Lefty'
import React from 'react'
import {Controller, UseFormMethods} from 'react-hook-form'

const MIN_BAR_HEIGHT = 60
const MAX_BAR_HEIGHT = 120
const MIN_LOGO_HEIGHT = 30

export default function Styling(props: {
  control: UseFormMethods['control']
  watch: UseFormMethods['watch']
}) {
  const {control} = props
  const template = useLeftyTemplate()

  return (
    <>
      <Controller
        name="featurePageHeader.logoHeight"
        defaultValue={template.featurePageHeader.logoHeight}
        control={control}
        render={({value, onChange}) => (
          <Slider
            min={MIN_LOGO_HEIGHT}
            max={template.featurePageHeader.menuBarHeight}
            step={1}
            onChange={onChange}
            valueLabelDisplay="auto"
            value={value}
            aria-label="feature page logo height"
            label="Logo Height"
          />
        )}
      />
      <Controller
        name="featurePageHeader.menuBarHeight"
        defaultValue={template.featurePageHeader.menuBarHeight}
        control={control}
        render={({value, onChange}) => (
          <Slider
            min={MIN_BAR_HEIGHT}
            max={MAX_BAR_HEIGHT}
            step={1}
            onChange={onChange}
            valueLabelDisplay="auto"
            value={value}
            aria-label="feature page menu bar height"
            label="Menu Bar Height"
          />
        )}
      />
      <Controller
        name="featurePageHeader.menuBarBackgroundColor"
        defaultValue={template.featurePageHeader.menuBarBackgroundColor}
        control={control}
        render={({value, onChange}) => (
          <ColorPicker
            label="Menu Bar Background Color"
            color={value}
            onPick={onChange}
            aria-label="feature page menu bar background color"
          />
        )}
      />
      <Controller
        name="featurePageHeader.menuBarBackgroundOpacity"
        defaultValue={template.featurePageHeader.menuBarBackgroundOpacity || 0}
        control={control}
        render={({value, onChange}) => (
          <Slider
            min={0}
            max={100}
            step={1}
            onChange={onChange}
            valueLabelDisplay="auto"
            value={value}
            aria-label="feature page background opacity"
            label="Header Background Opacity"
            unit="%"
          />
        )}
      />
      <Controller
        name="featurePageHeader.backgroundBarHeight"
        defaultValue={template.featurePageHeader.backgroundBarHeight}
        control={control}
        render={({value, onChange}) => (
          <Slider
            min={0}
            max={MAX_BAR_HEIGHT}
            step={1}
            onChange={onChange}
            valueLabelDisplay="auto"
            value={value}
            aria-label="feature page background bar height"
            label="Background Bar Height"
          />
        )}
      />
      <Controller
        name="featurePageHeader.menuIconColor"
        defaultValue={
          template.featurePageHeader.menuIconColor ?? template.menu.iconColor
        }
        control={control}
        render={({value, onChange}) => (
          <ColorPicker
            label="Menu Icon Color"
            color={value}
            onPick={onChange}
            aria-label="feature page menu icon color"
          />
        )}
      />
    </>
  )
}

import Page from 'lib/ui/layout/Page'
import React, {useState} from 'react'
import TeamMembersTable from 'organization/Team/TeamMembersTable'
import AddTeamMemberForm from 'organization/Team/AddTeamMemberForm'
import TeamProvider from 'organization/Team/TeamProvider'
import Tabs from '@material-ui/core/Tabs'
import MuiTab, {TabProps} from '@material-ui/core/Tab'
import Roles from 'organization/Team/Roles'
import RolesProvider from 'organization/Team/Roles/RolesProvider'
import Layout from 'organization/user/Layout'
import TabPanel from 'lib/ui/tabs/TabPanel'
import {useBreadcrumbs} from 'lib/ui/BreadcrumbProvider'
import {useOrganization} from 'organization/OrganizationProvider'
import TeamInvitationsProvider from 'organization/Team/TeamInvitationsProvider'
import {useIsOwner} from 'organization/OwnerProvider'
import {useObvioUser} from 'obvio/auth'

export default function Team() {
  const [tabIndex, setTabIndex] = useState(0)
  const {routes} = useOrganization()

  useBreadcrumbs([
    {
      title: 'Team',
      url: routes.team,
    },
  ])

  return (
    <Layout>
      <Page>
        <TeamProvider>
          <TeamInvitationsProvider>
            <RolesProvider>
              <Tabs
                onChange={(_, newTabIndex: number) => setTabIndex(newTabIndex)}
                value={tabIndex}
              >
                <Tab label="Members" />
                <Tab label="Roles" />
              </Tabs>
              <TabPanel index={0} currentIndex={tabIndex}>
                <AddTeamMemberForm />
                <TeamMembersTable />
              </TabPanel>
              <TabPanel index={1} currentIndex={tabIndex}>
                <Roles />
              </TabPanel>
            </RolesProvider>
          </TeamInvitationsProvider>
        </TeamProvider>
      </Page>
    </Layout>
  )
}

function Tab(props: {label: 'Roles' | 'Members'} & TabProps) {
  const isOwner = useIsOwner()
  const user = useObvioUser()

  if (props.label === 'Members') {
    return (
      <MuiTab
        {...props}
        label={props.label}
        value={0}
        aria-label="members tab"
      />
    )
  }
  if (isOwner || user.is_admin) {
    return (
      <MuiTab {...props} label={props.label} value={1} aria-label="roles tab" />
    )
  }
  return null
}

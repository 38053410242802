import MenuItem, {MenuItemProps} from '@material-ui/core/MenuItem'
import {obvioRoutes} from 'obvio/Routes'
import React from 'react'
import Divider from '@material-ui/core/Divider'
import {useHistory} from 'react-router-dom'

/**
 * MUI forwards refs to MenuItem(s) so we have to forward
 * them here. The actual type is crazy long, since it's
 * in MUI domain we'll just 'any' type it.
 */
const BillingMenuItem = React.forwardRef<MenuItemProps, any>((props, ref) => {
  const history = useHistory()

  const goToBilling = () => {
    history.push(obvioRoutes.billing.root)
  }

  return (
    <>
      <MenuItem
        aria-label="billing settings"
        ref={ref}
        onClick={goToBilling}
        {...props}
      >
        Billing
      </MenuItem>
      <Divider />
    </>
  )
})

export default BillingMenuItem

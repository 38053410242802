import {useEvent} from 'Event/EventProvider'
import {api} from 'lib/url'

export interface Tag {
  id: number
  tag_id: number | null
  name: string | null
  type: TagType
}

export const ATTENDEE_CREATED = 'attendee_created'
export const ATTENDEE_SIGNED_WAIVER = 'attendee_signed_waiver'
export const ATTENDEE_CHECKED_IN = 'attendee_checked_in'
export const IMPORT_TAG = 'import_tag'
export const UPDATE_TAG = 'update_tag'
export const FORM_SUBMITTED = 'form_submitted'

export type TagType =
  | typeof ATTENDEE_CREATED
  | typeof ATTENDEE_CHECKED_IN
  | typeof ATTENDEE_SIGNED_WAIVER
  | typeof IMPORT_TAG
  | typeof UPDATE_TAG
  | typeof FORM_SUBMITTED

export interface ConvertKitTag {
  name: string
  id: number
}

export function useAddTag() {
  const {client, event} = useEvent()

  const url = api(`/events/${event.id}/integrations/convert_kit/add_tag`)
  return (tag: ConvertKitTag) => {
    try {
      client.post(url, {
        id: tag.id,
      })
    } catch {
      // Ignore errors, failing to add a tag should
      // not break the app.
    }
  }
}

import React from 'react'
import {ChangePasswordRequest} from 'auth/password/change'
import Logo from 'Event/auth/Login/Logo'
import {
  Box,
  StyledBackground,
  LeftContainer,
} from 'Event/template/NiftyFifty/Login/MobilePanel'
import {RightPanel} from 'Event/template/NiftyFifty/ChangePassword'

export default function MobilePanel(props: {
  changePassword: ChangePasswordRequest
}) {
  return (
    <Box>
      <LeftContainer>
        <StyledBackground>
          <Logo />
        </StyledBackground>
      </LeftContainer>
      <RightPanel {...props} />
    </Box>
  )
}

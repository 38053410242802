import FormControlLabel from '@material-ui/core/FormControlLabel'
import styled from 'styled-components'
import Switch from '@material-ui/core/Switch'
import React from 'react'
import grey from '@material-ui/core/colors/grey'
import {useEditMode, useSetEditMode} from 'Event/EditModeProvider'

export default function ConfigBar(props: {
  children?: JSX.Element | JSX.Element[]
}) {
  const isEditMode = useEditMode()

  const setIsEditMode = useSetEditMode()

  const toggle = () => setIsEditMode(!isEditMode)

  return (
    <Box>
      <Left>{props.children}</Left>
      <FormControlLabel
        control={
          <Switch checked={!isEditMode} onChange={toggle} color="primary" />
        }
        label="Preview"
      />
    </Box>
  )
}

const Box = styled.div`
  flex: 0 0 50px;
  height: 50px;
  border-bottom: 1px solid ${grey[300]};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 ${(props) => props.theme.spacing[4]};
  z-index: 2;
`

const Left = styled.div`
  display: flex;
  align-items: center;
`

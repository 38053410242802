import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import {useLocalTime} from 'lib/date-time'
import {MoreActionsMenu} from 'lib/ui/Menu'
import {colors} from 'lib/ui/theme'
import {TableHeader} from 'lib/ui/typography'
import {
  Broadcast,
  Segment,
  useBroadcasts,
} from 'organization/Event/Broadcasts/BroadcastsProvider'
import DeleteMenuItem from 'organization/Event/Broadcasts/BroadcastsTable/DeleteMenuItem'
import EditMenuItem from 'organization/Event/Broadcasts/BroadcastsTable/EditMenuItem'
import React, {useState} from 'react'
import styled from 'styled-components'
import DuplicateMenuItem from 'organization/Event/Broadcasts/BroadcastsTable/DuplicateMenuItem'
import TestMenuItem from 'organization/Event/Broadcasts/BroadcastsTable/TestMenuItem'
import SuccessAlert from 'lib/ui/alerts/SuccessAlert'

export default function BroadcastsTable() {
  const {broadcasts} = useBroadcasts()
  const [successMessage, setSuccessMessage] = useState<string | null>(null)

  const localTime = useLocalTime()

  const hasBroadcasts = broadcasts.length > 0
  if (!hasBroadcasts) {
    return <p>No broadcasts have been added.</p>
  }

  return (
    <>
      <SuccessAlert onClose={() => setSuccessMessage(null)}>
        {successMessage}
      </SuccessAlert>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <TableHeader>Date</TableHeader>
            </TableCell>
            <TableCell>
              <TableHeader>Name</TableHeader>
            </TableCell>
            <TableCell>
              <TableHeader>Channel</TableHeader>
            </TableCell>
            <TableCell>
              <TableHeader>Segments</TableHeader>
            </TableCell>
            <CenteredTableCell>
              <TableHeader>Send Status</TableHeader>
            </CenteredTableCell>
            <CenteredTableCell>
              <TableHeader>Published</TableHeader>
            </CenteredTableCell>
            <TableCell>{/* Action Column */}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {broadcasts.map((b) => (
            <TableRow key={b.id}>
              <TableCell>{localTime(b.send_at)}</TableCell>
              <TableCell>{b.name}</TableCell>
              <TableCell>{b.channel.name}</TableCell>
              <TableCell>
                <Segments broadcast={b} />
              </TableCell>
              <CenteredTableCell>
                <SendingStatus broadcast={b} />
              </CenteredTableCell>
              <CenteredTableCell>
                <PublishedStatus broadcast={b} />
              </CenteredTableCell>
              <TableCell>
                <BroadcastActions broadcast={b} onSuccess={setSuccessMessage} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  )
}

const CenteredTableCell = styled(TableCell)`
  text-align: center;
`

function Segments(props: {broadcast: Broadcast}) {
  const {broadcast} = props
  const segments = [broadcast.attendee_segment] as Segment[]

  return (
    <>
      {segments.map((s) => (
        <div key={s}>{segmentLabel(s)}</div>
      ))}
    </>
  )
}

function segmentLabel(segment: Segment) {
  switch (segment) {
    case 'all':
      return 'All Attendees'
    case 'checked_in':
      return 'Checked In'
    case 'not_checked_in':
      return 'Not Checked In'
    case 'filtered':
      return 'Filtered'
    default:
      throw new Error(`Missing segment label for segment: ${segment}`)
  }
}

function BroadcastActions(props: {
  broadcast: Broadcast
  onSuccess: (message: string) => void
}) {
  const {broadcast, onSuccess} = props

  return (
    <MoreActionsMenu>
      <TestMenuItem broadcast={broadcast} onSuccess={onSuccess} />
      <EditMenuItem broadcast={broadcast} />
      <DuplicateMenuItem broadcast={broadcast} />
      <DeleteMenuItem broadcast={broadcast} />
    </MoreActionsMenu>
  )
}

function SendingStatus(props: {broadcast: Broadcast}) {
  const {broadcast} = props
  const iconClass = broadcast.sent ? 'fas' : 'fal'
  const title = broadcast.sent ? 'has completed' : 'is pending'

  return (
    <StyledSendingIcon
      aria-label={`broadcast send status ${
        broadcast.sent ? 'sent' : 'pending'
      }`}
      className={`${iconClass} fa-circle`}
      title={`Broadcast send ${title}`}
    />
  )
}

function PublishedStatus(props: {broadcast: Broadcast}) {
  const {broadcast} = props
  const iconClass = broadcast.is_draft ? 'fal' : 'fas'
  const title = broadcast.is_draft ? 'draft' : 'published'

  return (
    <StyledSendingIcon
      aria-label={`broadcast status ${
        broadcast.is_draft ? 'draft' : 'published'
      }`}
      className={`${iconClass} fa-circle`}
      title={`Broadcast is ${title}`}
    />
  )
}

const StyledSendingIcon = styled.i`
  color: ${colors.primary};
`

import {useOrganization} from 'organization/OrganizationProvider'
import {useEvent} from 'Event/EventProvider'
import {useAsync} from 'lib/async'
import {api} from 'lib/url'
import React, {useCallback} from 'react'

export type Property = {
  name: string
  label: string
  type:
    | 'select'
    | 'text'
    | 'number'
    | 'date'
    | 'checkbox'
    | 'booleancheckbox'
    | 'radio'
    | 'phonenumber'
  read_only: boolean
  options: PropertyOption[]
}

export type PropertyOption = {
  label: string
  value: string
  description: string
}

interface HubspotPropertiesProviderContextProps {
  properties: Property[]
  query: (params: {
    types?: Property['type'][]
    readOnly?: boolean
  }) => Property[]
  findProperty: (name: string) => Property | undefined
}

const HubspotPropertiesProviderContext = React.createContext<
  undefined | HubspotPropertiesProviderContextProps
>(undefined)

export default function HubspotPropertiesProvider(props: {
  children: React.ReactElement
}) {
  const {event} = useEvent()
  const {data, loading} = useFetchProperties(event.has_hubspot)

  const properties = data || []

  const query: HubspotPropertiesProviderContextProps['query'] = (params) =>
    properties.filter((p) => {
      const matchesType = params.types ? params.types.includes(p.type) : true
      const matchesAccess = params.readOnly ? p.read_only : true

      return matchesType && matchesAccess
    })

  const findProperty: HubspotPropertiesProviderContextProps['findProperty'] = (
    name,
  ) => properties.find((p) => p.name === name)

  if (loading) {
    return null
  }

  return (
    <HubspotPropertiesProviderContext.Provider
      value={{
        properties,
        query: query,
        findProperty,
      }}
    >
      {props.children}
    </HubspotPropertiesProviderContext.Provider>
  )
}

export function useHubspotProperties() {
  const context = React.useContext(HubspotPropertiesProviderContext)

  if (context === undefined) {
    throw new Error(
      'useHubspotProperties must be used within a HubspotPropertiesProvider',
    )
  }

  return context
}

function useFetchProperties(shouldFetch: boolean) {
  const {client} = useOrganization()
  const {
    event: {id: eventId},
  } = useEvent()
  const url = api(`/events/${eventId}/integrations/hubspot/properties`)
  const request = useCallback(
    () => (shouldFetch ? client.get<Property[]>(url) : Promise.resolve([])),
    [url, client, shouldFetch],
  )

  return useAsync(request)
}

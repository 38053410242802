import {ObvioEvent} from 'Event'
import {useEvent} from 'Event/EventProvider'
import {FileLocation} from 'lib/http-client'
import {api, createFileLocation} from 'lib/url'
import {useOrganization} from 'organization/OrganizationProvider'
import {useCallback} from 'react'

export interface Asset {
  id: number
  file: FileLocation
}

export function useUploadAsset() {
  const {event} = useEvent()
  const {client} = useOrganization()

  const url = api(`/events/${event.id}/assets`)

  return useCallback(
    (file: File) => {
      const data = new FormData()
      data.set('file', file)

      return client.post<Asset>(url, data)
    },
    [client, url],
  )
}

export function useRemoveAsset() {
  const {client} = useOrganization()

  return useCallback(
    (file: FileLocation) => {
      const url = api(`/assets/${file.name}`)
      return client.delete<void>(url)
    },
    [client],
  )
}

/**
 * Helper to delete multiple assets that have since been
 * removed from the object. Useful for components
 * where we trigger a remove on save.
 *
 * @param saved
 * @returns
 */
export function usePruneAssets() {
  const remove = useRemoveAsset()

  return useCallback(
    async (
      values: Record<string, string | null | undefined>,
      saved?: Record<string, any>,
    ) => {
      const currentValues = saved || {}

      for (const key in values) {
        const current = currentValues[key]
        const updated = values[key]

        // If an asset didn't exist, there's nothing to remove
        if (!current) {
          continue
        }

        // Was the asset removed?
        const currentUrl = createFileLocation(current)
        if (!updated && currentUrl) {
          await remove(currentUrl)
        }
      }
    },
    [remove],
  )
}

/**
 * Copies an asset to a new event.
 *
 * @returns
 */
export function useCopyToEvent(targetEvent: ObvioEvent) {
  const {client} = useOrganization()
  const {id: eventId} = targetEvent

  return useCallback(
    (fileUrl: string) => {
      const matches = fileUrl.match(/^https?:\/\/.+\/event\/assets\/(.+)$/)
      const fileName = matches ? matches[1] : null
      if (!fileName) {
        throw new Error(`Invalid asset url: ${fileUrl}`)
      }

      const url = api(`/assets/${fileName}/copy`)
      return client.post<{file: FileLocation}>(url, {
        event_id: eventId,
      })
    },
    [client, eventId],
  )
}

export const isAssetUrl = (url: string) =>
  /^http(s?):\/\/.*(obv|localhost).+\/event\/assets\/.+$/.test(url)

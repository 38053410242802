import Grid from '@material-ui/core/Grid'
import {StripeElementStyle} from '@stripe/stripe-js'
import BackButtonButton from 'Event/Marketplace/Block/PurchaseForm/BackButton'
import PurchaseButton from 'Event/Marketplace/Block/PurchaseForm/PurchaseButton'
import FormButtons from 'Event/Marketplace/Block/PurchaseForm/FormButtons'
import {formatPrice} from 'lib/currency'
import CardFields from 'lib/stripe/CreditCardForm/CardFields'
import {colors} from 'lib/ui/theme'
import {SubHead} from 'lib/ui/typography'
import React, {useMemo} from 'react'
import styled from 'styled-components'
import useStateName from 'lib/use-state-name'
import useCountryName from 'lib/use-country-name'
import {usePurchaseForm} from 'Event/Marketplace/Block/PurchaseForm/PurchaseFormProvider'
import {usePurchase} from 'Event/Marketplace/PurchaseProvider'
import {PurchaseFormBlock} from 'Event/Marketplace/Block/PurchaseForm'

interface OrderConfirmationStepProps extends PurchaseFormBlock {
  onBack: () => void
  ButtonComponent: React.FC<any>
}

export default function OrderConfirmationStep(
  props: OrderConfirmationStepProps,
) {
  const {onBack, ButtonComponent, ...config} = props

  const {
    input: {fontSize: inputFontSize},
    summary,
  } = config

  const {selectedTicket} = usePurchase()

  const {
    form: {watch},
    useSameAddressForShipping,
    setCardElement,
  } = usePurchaseForm()

  const data = watch([
    'first_name',
    'last_name',
    'email',
    'phone_number',
    'billing_address.address_1',
    'billing_address.address_2',
    'billing_address.city',
    'billing_address.state_id',
    'billing_address.zip_postal',
    'billing_address.country_id',
    'shipping_address.address_1',
    'shipping_address.address_2',
    'shipping_address.city',
    'shipping_address.state_id',
    'shipping_address.zip_postal',
    'shipping_address.country_id',
  ])

  const billingCountry = useCountryName(data['billing_address.country_id'])
  const billingState = useStateName(
    data['billing_address.country_id'],
    data['billing_address.state_id'],
  )

  const shippingCountry = useCountryName(data['shipping_address.country_id'])
  const shippingState = useStateName(
    data['shipping_address.country_id'],
    data['shipping_address.state_id'],
  )

  const requiresCardInput = selectedTicket ? selectedTicket.price !== 0 : false

  const billing = {
    address_1: data['billing_address.address_1'],
    address_2: data['billing_address.address_2'],
    city: data['billing_address.city'],
    state_id: data['billing_address.state_id'],
    zip_postal: data['billing_address.zip_postal'],
    country_id: data['billing_address.country_id'],
  }

  const shipping = useSameAddressForShipping
    ? billing
    : {
        address_1: data['shipping_address.address_1'],
        address_2: data['shipping_address.address_2'],
        city: data['shipping_address.city'],
        state_id: data['shipping_address.state_id'],
        zip_postal: data['shipping_address.zip_postal'],
        country_id: data['shipping_address.country_id'],
      }

  const style: StripeElementStyle = useMemo(
    () => ({
      base: {
        fontFamily: 'Rubik, sans-serif',
        fontSize: `${inputFontSize}px`,
        ':disabled': {
          color: 'rgba(0, 0, 0, 0.36)',
        },
        '::placeholder': {
          color: 'rgba(0, 0, 0, 0.36)',
        },
      },
      invalid: {
        color: colors.error,
      },
    }),
    [inputFontSize],
  )

  return (
    <Summary {...summary.text} container spacing={2}>
      <Grid item xs={12}>
        <Heading {...summary.label}>
          {summary.label.attendeeDetailsLabel}
        </Heading>
        <div>
          {data.first_name} {data.last_name}
        </div>
        <div>{data.email}</div>
        <div>{data.phone_number}</div>
      </Grid>
      {config.requireAddresses && (
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12} sm={6}>
              <div>
                <Heading {...summary.label}>
                  {summary.label.billingAddressLabel}
                </Heading>
                <div>{billing.address_1}</div>
                <div>{billing.address_2}</div>
                <div>
                  {billing.city}, {billingState}, {billing.zip_postal}
                </div>
                <div>{billingCountry}</div>
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div>
                <Heading {...summary.label}>
                  {summary.label.shippingAddressLabel}
                </Heading>
                <div>{shipping.address_1}</div>
                <div>{shipping.address_2}</div>
                <div>
                  {shipping.city}, {shippingState}, {shipping.zip_postal}
                </div>
                <div>{shippingCountry}</div>
              </div>
            </Grid>
          </Grid>
        </Grid>
      )}
      <Grid item xs={12}>
        <StyledGrid container alignItems="center">
          <Grid item xs={12} sm={3}>
            <Heading {...summary.label}>{summary.label.purchaseLabel}</Heading>
          </Grid>
          <Grid item xs={12} sm={9}>
            {selectedTicket?.name}
          </Grid>
        </StyledGrid>
        <Grid container alignItems="center">
          <Grid item xs={12} sm={3}>
            <Heading {...summary.label}>{summary.label.totalLabel}</Heading>
          </Grid>
          <Grid item xs={12} sm={9}>
            ${selectedTicket ? formatPrice(selectedTicket.price) : '-'}
          </Grid>
        </Grid>
      </Grid>
      {requiresCardInput && (
        <Grid item xs={12}>
          <CardContainer config={config}>
            <CardFields style={style} onLoad={setCardElement} />
          </CardContainer>
        </Grid>
      )}
      <Grid item xs={12}>
        <FormButtons>
          <BackButtonButton {...config} onClick={onBack} />
          <PurchaseButton ButtonComponent={ButtonComponent} {...config} />
        </FormButtons>
      </Grid>
    </Summary>
  )
}

const CardContainer = styled.div<{
  config: PurchaseFormBlock
}>`
  .StripeElement {
    background-color: ${(props) =>
      props.config.input.backgroundColor} !important;
    border: ${(props) => props.config.input.border.width}px solid !important;
    border-color: ${(props) => props.config.input.border.color} !important;
    border-radius: ${(props) => props.config.input.border.radius}px !important;
    padding-left: ${(props) => props.config.input.padding.left}px !important;
    padding-right: ${(props) => props.config.input.padding.right}px !important;
    padding-top: ${(props) =>
      props.config.input.padding.top * 1.5}px !important;
    padding-bottom: ${(props) =>
      props.config.input.padding.bottom * 1.5}px !important;
  }
`

const StyledGrid = styled(Grid)`
  margin-bottom: ${(props) => props.theme.spacing[2]};
`
const Summary = styled(Grid)<{
  color: string
  fontSize: number
}>`
  font-size: ${(props) => props.fontSize}px;
  color: ${(props) => props.color};
`

const Heading = styled(SubHead)<{
  color: string
  fontSize: number
}>`
  font-weight: 600;
  font-size: ${(props) => props.fontSize}px;
  color: ${(props) => props.color};
`

import React from 'react'
import styled from 'styled-components'
import logo from 'assets/images/logo.png'
import {RelativeLink} from 'lib/ui/link/RelativeLink'

export default function Logo(props: {className?: string; homeLink: string}) {
  return (
    <div className={props.className}>
      <RelativeLink to={props.homeLink}>
        <Image src={logo} alt="Obv.io" />
      </RelativeLink>
    </div>
  )
}

const Image = styled.img`
  max-width: 150px;
  max-height: 100%;
`

import React from 'react'
import Configurable from 'organization/Event/Configurable'
import PurchaseFormTextFieldConfig from 'organization/Marketplace/PurchasePageConfig/BlockConfig/PurchaseFormTextFieldConfig'
import PurhaseFormTextField, {
  PurhaseFormTextFieldProps,
} from 'Event/Marketplace/Block/PurchaseForm/PurchaseFormTextField'

export default function ConfigurablePurchaseFormTextField(
  props: PurhaseFormTextFieldProps,
) {
  return (
    <Configurable aria-label={`edit ${props['aria-label']}`}>
      <PurchaseFormTextFieldConfig {...props} />
      <PurhaseFormTextField {...props} />
    </Configurable>
  )
}

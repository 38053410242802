import {useEditMode} from 'Event/EditModeProvider'
import styled from 'styled-components'
import {Editable} from 'Event/Dashboard/editor/views/EditComponent'
import {useNiftyFiftyTemplate} from 'Event/template/NiftyFifty'
import BlogPosts from 'Event/template/NiftyFifty/Dashboard/Home/BlogPosts'
import WelcomeTextConfig from 'Event/template/NiftyFifty/Dashboard/Home/WelcomeTextConfig'
import {useToggle} from 'lib/toggle'
import React from 'react'
import BodyHTMLEmbed from 'Event/Dashboard/components/BodyHtmlEmbed'
import {useAttendeeVariables} from 'Event'
import EditModeOnly from 'Event/Dashboard/editor/views/EditModeOnly'
import TeamAccessControls from 'Event/Dashboard/TeamAccessControls'
import CountDownTimers from 'Event/template/NiftyFifty/Dashboard/CountDownTimers'
import {Font, useLoadFont} from 'lib/FontSelect'
import {BOLD, ITALIC, CAPS} from 'lib/ui/typography/FontStyleInput'

export default function Home() {
  const {flag: welcomeConfigVisible, toggle: toggleWelcomeConfig} = useToggle()

  return (
    <>
      <EditModeOnly>
        <WelcomeTextConfig
          showing={welcomeConfigVisible}
          onClose={toggleWelcomeConfig}
        />
      </EditModeOnly>
      <Editable onEdit={toggleWelcomeConfig}>
        <WelcomeText />
      </Editable>
      <TeamAccessControls />
      <DesktopCountDownTimers />
      <BlogPosts />
      <BodyHTMLEmbed />
    </>
  )
}

function WelcomeText() {
  const template = useNiftyFiftyTemplate()
  const isEditMode = useEditMode()
  const {
    welcomeText,
    welcomeTextFont,
    welcomeTextColor,
    welcomeTextFontSize,
    welcomeTextJustify,
    welcomeTextWidth,
    textColor,
  } = template
  useLoadFont(welcomeTextFont)
  const v = useAttendeeVariables()

  if (!welcomeText && !isEditMode) {
    return null
  }

  return (
    <FlexContainer justify={welcomeTextJustify}>
      <TextContainer width={welcomeTextWidth}>
        <Text
          aria-label="welcome"
          hasMinHeight={isEditMode}
          font={welcomeTextFont}
          color={textColor}
          welcomeTextColor={welcomeTextColor}
          fontSize={welcomeTextFontSize}
          justify={welcomeTextJustify}
        >
          {v(welcomeText)}
        </Text>
      </TextContainer>
    </FlexContainer>
  )
}

const FlexContainer = styled.div<{
  justify: string
}>`
  display: flex;
  justify-content: ${(props) =>
    props.justify === 'center'
      ? 'center;'
      : props.justify === 'right'
      ? 'flex-end;'
      : 'flex-start;'};
`

const TextContainer = styled.div<{
  width: number
}>`
  width: ${(props) => props.width ?? '100'}%;
`

const Text = styled.h2<{
  hasMinHeight: boolean
  font: Font | null
  color: string
  welcomeTextColor: string | null
  fontSize: number
  justify: string
}>`
  font-weight: ${(props) =>
    props.font?.styles?.includes(BOLD) ? 'bold' : 'normal'};
  font-family: ${(props) => (props.font ? props.font.family : 'inherit')};
  font-style: ${(props) =>
    props.font?.styles?.includes(ITALIC) ? 'italic' : 'normal'};
  text-transform: ${(props) =>
    props.font && !props.font.styles?.includes(CAPS) ? 'none' : 'uppercase'};
  color: ${(props) =>
    props.welcomeTextColor ? props.welcomeTextColor : props.color} !important;
  font-size: ${(props) => props.fontSize}px;
  line-height: 1.5;
  text-align: ${(props) => props.justify ?? 'left'};
  margin: 0 0 30px;

  ${(props) => (props.hasMinHeight ? 'min-height: 42px;' : '')}
`

const DesktopCountDownTimers = styled(CountDownTimers)`
  display: none;

  @media all and (min-width: ${(props) => props.theme.breakpoints.md}) {
    display: block;
  }
`

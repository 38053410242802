import {usePreviewAttendeeProfile} from 'Event/attendee-rules/AttendeeProfileProvider/PreviewAttendeeProfileProvider'
import styled, {useTheme} from 'styled-components'
import {useStaticDate} from 'lib/date-time/StaticDateProvider'
import React from 'react'
import EditIconButton from 'lib/ui/IconButton/EditIconButton'
import {colors} from 'lib/ui/theme'

export default function TogglePreviewConfigButton(props: {
  onClick: () => void
}) {
  const {tags, groups} = usePreviewAttendeeProfile()
  const {date} = useStaticDate()

  const hasTags = tags.length > 0
  const hasGroup = Object.keys(groups).length > 0
  const hasDate = Boolean(date)
  const theme = useTheme()

  const hasPreviewVariables = hasTags || hasGroup || hasDate

  const color = hasPreviewVariables ? theme.colors.primary : colors.text.muted

  return (
    <StyledEditIconButton
      {...props}
      aria-label="configure preview rules"
      color={color}
    />
  )
}

const StyledEditIconButton = styled(EditIconButton)`
  margin-left: ${(props) => props.theme.spacing[2]};
`

import ColorPicker from 'lib/ui/ColorPicker'
import React from 'react'
import {Controller} from 'react-hook-form'
import {useTownhallTemplate} from 'Event/template/Townhall'
import {useConfig} from 'organization/Event/Configurable'

export default function Styling() {
  const {
    form: {control},
  } = useConfig()

  const {footer, textColor} = useTownhallTemplate()
  const footerColor = footer.textColor ? footer.textColor : textColor

  return (
    <>
      <Controller
        name="footer.background"
        control={control}
        defaultValue={footer.background}
        render={({value, onChange}) => (
          <ColorPicker
            label="Background Color"
            color={value}
            onPick={onChange}
          />
        )}
      />
      <Controller
        name="footer.textColor"
        control={control}
        defaultValue={footerColor}
        render={({value, onChange}) => (
          <ColorPicker label="Text Color" color={value} onPick={onChange} />
        )}
      />
    </>
  )
}

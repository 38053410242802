import {
  useNiftyFiftyTemplate,
  useNiftyFiftyUpdate,
} from 'Event/template/NiftyFifty'
import ColorPicker from 'lib/ui/ColorPicker'
import Title from 'lib/ui/ConfigPanel/Title'
import Slider from 'lib/ui/Slider'
import {useConfig} from 'organization/Event/Configurable'
import Config from 'organization/Event/Configurable/Config'
import StylingPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/StylingPanel'
import React from 'react'
import {Controller} from 'react-hook-form'

export default function RightPanelConfig() {
  const save = useNiftyFiftyUpdate()

  return (
    <Config title="Login Right Panel" onSave={save}>
      <StylingPanel>
        <StylingInputs />
      </StylingPanel>
    </Config>
  )
}

export function StylingInputs() {
  const template = useNiftyFiftyTemplate()
  const {
    form: {control},
  } = useConfig()

  return (
    <>
      <Title>Background</Title>
      <Controller
        name="login.loginRightPanel.backgroundColor"
        defaultValue={template.login.loginRightPanel.backgroundColor}
        control={control}
        render={({value, onChange}) => (
          <ColorPicker
            label="Color"
            color={value}
            onPick={onChange}
            aria-label="check in right panel background color"
          />
        )}
      />
      <Controller
        name="login.loginRightPanel.backgroundOpacity"
        defaultValue={template.login.loginRightPanel.backgroundOpacity}
        control={control}
        render={({value, onChange}) => (
          <Slider
            label="Opacity"
            min={0}
            max={100}
            step={1}
            onChange={onChange}
            value={value}
            unit="%"
          />
        )}
      />
    </>
  )
}

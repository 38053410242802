import Button, {ButtonProps} from '@material-ui/core/Button'
import {
  EVENT_SETTINGS,
  UPDATE_ATTENDEES,
  usePermissions,
} from 'organization/PermissionsProvider'
import React from 'react'

export default function AddAttendeeButton(props: ButtonProps) {
  const {can} = usePermissions()
  if (!can(UPDATE_ATTENDEES) && !can(EVENT_SETTINGS)) {
    return null
  }

  return (
    <Button
      {...props}
      variant="contained"
      color="primary"
      aria-label="add-attendee"
    >
      Add Attendee
    </Button>
  )
}

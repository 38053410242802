import React from 'react'
import {UseFormMethods} from 'react-hook-form'
import {NavButtonWithSize} from 'Event/Dashboard/components/NavButton'
import Typography from 'Event/Dashboard/components/BlogPosts/Buttons/BlogPostButtonConfig/Styling/Typography'
import Background from 'Event/Dashboard/components/BlogPosts/Buttons/BlogPostButtonConfig/Styling/Background'
import Size from 'Event/Dashboard/components/BlogPosts/Buttons/BlogPostButtonConfig/Styling/Size'
import Border from 'Event/Dashboard/components/BlogPosts/Buttons/BlogPostButtonConfig/Styling/Border'

export default function BaseStyling(props: {
  control: UseFormMethods['control']
  watch: UseFormMethods['watch']
  button: NavButtonWithSize
}) {
  const {control, button, watch} = props

  return (
    <>
      <Typography button={button} control={control} />
      <Background button={button} control={control} watch={watch} />
      <Size button={button} control={control} />
      <Border button={button} control={control} />
    </>
  )
}

import {ResourceListProps} from 'Event/template/SimpleBlog/Dashboard/Sidebar/SidebarItem/ResourceList'
import {onChangeCheckedHandler} from 'lib/dom'
import {EnabledSwitch} from 'lib/ui/form/Switch'
import TextField from 'lib/ui/TextField'
import {Label} from 'lib/ui/typography'
import React from 'react'
import {Controller, UseFormMethods} from 'react-hook-form'

export default function Settings(props: {
  control: UseFormMethods['control']
  register: UseFormMethods['register']
  list: ResourceListProps
}) {
  const {control, register, list} = props

  return (
    <>
      <Controller
        name="isEnabled"
        defaultValue={list.isEnabled}
        control={control}
        render={({value, onChange}) => (
          <EnabledSwitch
            checked={value}
            onChange={onChangeCheckedHandler(onChange)}
            arial-label="config enabled switch"
          />
        )}
      />
      <Label>Title</Label>
      <TextField
        name="title"
        defaultValue={list.title}
        aria-label="update resources title"
        fullWidth
        inputProps={{
          ref: register,
        }}
      />
      <Label>Description</Label>
      <TextField
        name="description"
        defaultValue={list.description}
        aria-label="update resources description"
        fullWidth
        inputProps={{
          ref: register,
        }}
      />
    </>
  )
}

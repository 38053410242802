import React from 'react'
import Divider from 'lib/ui/layout/Divider'
import Subheading from 'lib/ui/typography/Subheading'
import {useObvioUser} from 'obvio/auth'
import DefaultCardManagement from 'obvio/Billing/CreditCardSection/DefaultCardManagement'
import {useDefaultPaymentMethod} from 'obvio/Billing/DefaultPaymentMethodProvider'
import OtherCardsTable from 'obvio/Billing/CreditCardSection/OtherCardsTable'
import {Section} from 'obvio/Billing'

export default function CreditCardSection() {
  const {paymentMethod} = useDefaultPaymentMethod()

  const user = useObvioUser()

  /**
   * If the user doesn't have an active subscription, we'll want them to select a plan
   * first before adding a card. If they DO have a subscription we'll always show
   * this section so they can add a card before the subscription lapses.
   */
  const showingCardSection =
    Boolean(paymentMethod) || user.has_active_subscription

  if (!showingCardSection) {
    return null
  }

  return (
    <>
      <Section>
        <Subheading>Default Credit Card</Subheading>
        <DefaultCardManagement />
      </Section>
      <Section>
        <Subheading>Other Credit Cards</Subheading>
        <OtherCardsTable />
      </Section>
      <Divider />
    </>
  )
}

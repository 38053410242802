import {CountDownTimerSettings} from 'Event/Dashboard/components/CountDownTimer'
import {onChangeCheckedHandler} from 'lib/dom'
import LocalizedDateTimePicker from 'lib/LocalizedDateTimePicker'
import {EnabledSwitch} from 'lib/ui/form/Switch'
import TextField from 'lib/ui/TextField'
import {Label} from 'lib/ui/typography'
import {useConfig} from 'organization/Event/Configurable'
import React from 'react'
import {Controller} from 'react-hook-form'

export default function Settings(props: {
  countDownTimer: CountDownTimerSettings
  id: string
  sectionId: string
}) {
  const {countDownTimer, id, sectionId} = props

  const {
    form: {control},
  } = useConfig()
  return (
    <>
      <Controller
        name={`dashboardSections.${sectionId}.items.${id}.enabled`}
        control={control}
        defaultValue={countDownTimer.enabled}
        render={({value, onChange}) => (
          <EnabledSwitch
            checked={value}
            onChange={onChangeCheckedHandler(onChange)}
            arial-label="toggle countdown timer"
          />
        )}
      />
      <Controller
        name={`dashboardSections.${sectionId}.items.${id}.end`}
        control={control}
        defaultValue={countDownTimer.end}
        render={({onChange, value}) => (
          <div>
            <Label>End</Label>
            <LocalizedDateTimePicker
              value={value}
              onChange={(date) => onChange(date?.toISOString())}
              fullWidth
              inputProps={{
                'aria-label': 'start time',
                onChange,
              }}
            />
          </div>
        )}
      />
      <Controller
        name={`dashboardSections.${sectionId}.items.${id}.labels.days`}
        control={control}
        defaultValue={countDownTimer.labels.days}
        render={({value, onChange}) => (
          <TextField
            aria-label="days label input"
            fullWidth
            onChange={onChange}
            value={value}
            label="Days Label"
          />
        )}
      />
      <Controller
        name={`dashboardSections.${sectionId}.items.${id}.labels.hours`}
        control={control}
        defaultValue={countDownTimer.labels.hours}
        render={({value, onChange}) => (
          <TextField
            aria-label="hours label input"
            fullWidth
            onChange={onChange}
            value={value}
            label="Hours Label"
          />
        )}
      />
      <Controller
        name={`dashboardSections.${sectionId}.items.${id}.labels.minutes`}
        control={control}
        defaultValue={countDownTimer.labels.minutes}
        render={({value, onChange}) => (
          <TextField
            aria-label="minutes label input"
            fullWidth
            onChange={onChange}
            value={value}
            label="Minutes Label"
          />
        )}
      />
      <Controller
        name={`dashboardSections.${sectionId}.items.${id}.labels.seconds`}
        control={control}
        defaultValue={countDownTimer.labels.seconds}
        render={({value, onChange}) => (
          <TextField
            aria-label="seconds label input"
            fullWidth
            onChange={onChange}
            value={value}
            label="Seconds Label"
          />
        )}
      />
      <Label>Description</Label>
      <Controller
        name={`dashboardSections.${sectionId}.items.${id}.description`}
        control={control}
        defaultValue={countDownTimer.description}
        render={({value, onChange}) => (
          <TextField
            aria-label="description input"
            fullWidth
            onChange={onChange}
            value={value}
          />
        )}
      />
      <Label>Separator</Label>
      <Controller
        name={`dashboardSections.${sectionId}.items.${id}.separator`}
        control={control}
        defaultValue={countDownTimer.separator}
        render={({value, onChange}) => (
          <TextField
            aria-label="separator input"
            fullWidth
            onChange={onChange}
            value={value}
          />
        )}
      />
    </>
  )
}

import styled from 'styled-components'
import React, {useState} from 'react'
import {RelativeLink} from 'lib/ui/link/RelativeLink'
import {ObvioEvent} from 'Event'
import {useEventRoutes} from 'organization/Event/EventRoutes'
import {MoreActionsMenu} from 'lib/ui/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import {Label, Description} from 'lib/ui/typography'
import defaultLogo from 'assets/images/logo_vertical.png'
import MoveEventDialog from 'organization/EventList/MoveEventDialog'
import {
  MoveToOrganizationButton,
  LiveIndicator,
  DeleteEventButton,
} from 'organization/EventList/Cards/GridCard'
import {ListEventData} from 'organization/EventList'
import {usePermissions, VIEW_STATISTICS} from 'organization/PermissionsProvider'
import DeleteEventDialog from 'organization/EventList/DeleteEventDialog'
import {SYSTEM_DOMAINS} from 'lib/url'

export default function ListCard(props: {event: ListEventData; index: number}) {
  const {event} = props
  const label = `view ${event.name}`
  const routes = useEventRoutes(event)
  const logo = event.logo?.url || defaultLogo
  const {can} = usePermissions()
  const [showMoveDialog, setShowMoveDialog] = useState<boolean>(false)
  const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false)
  const defaultRoute = can(VIEW_STATISTICS) ? routes.statistics : routes.root

  return (
    <>
      <DesktopContainer index={props.index} aria-label="list card">
        <StyledRelativeLink to={defaultRoute} disableStyles aria-label={label}>
          <Content>
            <Box>
              <Image src={logo} alt={event.name} aria-label="event logo" />
              <Name>{props.event.name}</Name>
              <LiveIndicator
                isLive={props.event.is_live}
                Container={LiveIndicatorContainer}
              />
            </Box>
            <Box>
              <URL event={event} />
            </Box>
          </Content>
        </StyledRelativeLink>
        <Actions
          event={event}
          setShowMoveDialog={setShowMoveDialog}
          setShowDeleteDialog={setShowDeleteDialog}
        />
      </DesktopContainer>
      <MobileContainer index={props.index}>
        <StyledRelativeLink to={routes.root} disableStyles aria-label={label}>
          <Name>{props.event.name}</Name>
          <LiveIndicator
            isLive={props.event.is_live}
            Container={LiveIndicatorContainer}
          />
          <URL event={event} />
        </StyledRelativeLink>
        <Actions
          event={event}
          setShowMoveDialog={setShowMoveDialog}
          setShowDeleteDialog={setShowDeleteDialog}
        />
      </MobileContainer>

      <MoveEventDialog
        event={event}
        isVisible={showMoveDialog}
        onClose={() => {
          setShowMoveDialog(false)
        }}
      />

      <DeleteEventDialog
        event={event}
        visible={showDeleteDialog}
        onClose={() => {
          setShowDeleteDialog(false)
        }}
      />
    </>
  )
}

function URL(props: {event: Pick<ObvioEvent, 'slug' | 'system_domain_id'>}) {
  const {event} = props

  const systemDomain = SYSTEM_DOMAINS[event.system_domain_id ?? 1]

  return (
    <URLText>
      {props.event.slug}.{systemDomain}
    </URLText>
  )
}

function Actions(props: {
  event: ListEventData
  setShowMoveDialog: (show: boolean) => void
  setShowDeleteDialog: (show: boolean) => void
}) {
  const {event, setShowMoveDialog, setShowDeleteDialog} = props
  const routes = useEventRoutes(event)

  return (
    <MoreActionsMenu>
      <RelativeLink
        to={routes.duplicate}
        disableStyles
        aria-label="duplicate event"
      >
        <MenuItem>Duplicate</MenuItem>
      </RelativeLink>
      <MoveToOrganizationButton setShowMoveDialog={setShowMoveDialog} />
      <DeleteEventButton setShowDeleteDialog={setShowDeleteDialog} />
    </MoreActionsMenu>
  )
}

const DesktopContainer = styled.div<{
  index: number
}>`
  ${(props) =>
    props.index % 2 === 0
      ? 'background-color: white'
      : `background-color: ${props.theme.colors.gray}`};
  display: none;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;

  &:hover img,
  &:hover span,
  &:hover p {
    opacity: 0.5;
  }

  button {
    height: 20%;
    margin-right: ${(props) => props.theme.spacing[4]};
  }

  @media all and (min-width: ${(props) => props.theme.breakpoints.sm}) {
    display: flex;
  }
`

const MobileContainer = styled.div<{
  index: number
}>`
  ${(props) =>
    props.index % 2 === 0
      ? 'background-color: white'
      : `background-color: ${props.theme.colors.gray}`};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
  height: ${(props) => props.theme.spacing[35]};

  button {
    height: 20%;
    margin-right: ${(props) => props.theme.spacing[4]};
  }

  @media all and (min-width: ${(props) => props.theme.breakpoints.sm}) {
    display: none;
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const Box = styled.div`
  padding: ${(props) => props.theme.spacing[3]};
  display: flex;
  align-items: center;
`

const StyledRelativeLink = styled(RelativeLink)`
  width: inherit;

  @media all and (min-width: ${(props) => props.theme.breakpoints.sm}) {
    width: 90%;
  }
`

const Name = styled(Label)`
  margin-left: ${(props) => props.theme.spacing[4]};
  margin-top: ${(props) => props.theme.spacing[6]};
  font-weight: 400;
  padding-bottom: ${(props) => props.theme.spacing[6]};
  display: inline-block;

  @media all and (min-width: ${(props) => props.theme.breakpoints.sm}) {
    margin-left: ${(props) => props.theme.spacing[12]};
    padding-bottom: none;
  }
`

const URLText = styled(Description)`
  background-color: ${(props) => props.theme.colors.primary};
  color: #ffffff;
  padding: ${(props) => props.theme.spacing[1]}
    ${(props) => props.theme.spacing[6]};
  border-radius: 25px;
  margin-left: ${(props) => props.theme.spacing[4]};
  margin-bottom: ${(props) => props.theme.spacing[6]};

  @media all and (min-width: ${(props) => props.theme.breakpoints.sm}) {
    margin-left: 0;
    margin-bottom: 0;
  }
`

const LiveIndicatorContainer = styled.div`
  margin-left: ${(props) => props.theme.spacing[2]};
  display: inline-flex;
  align-items: center;

  span {
    margin-right: ${(props) => props.theme.spacing[1]};
  }
`

const Image = styled.img`
  max-height: 50px;
  max-width: 50px;
`

import {useInterval} from 'lib/interval'
import {Client} from 'lib/ui/api-client'
import {api} from 'lib/url'
import {useCallback, useEffect, useState} from 'react'

export type Meeting = {
  start_url: string
  id?: string
  password?: string
  zak?: string
  host_email?: string
}

export function useFetchMeeting() {
  return useCallback((params: {client: Client; room: {id: number}}) => {
    const {client, room} = params
    const url = api(`/rooms/${room.id}/meeting`)
    return client.get<Meeting>(url)
  }, [])
}

export function useHostJoinUrl(params: {
  client: Client
  room: {id: number}
  useStartUrl: boolean
}) {
  const {
    client,
    room: {id: roomId},
    useStartUrl,
  } = params

  const [url, setUrl] = useState<string | null>(null)
  const fetchMeeting = useFetchMeeting()

  const endpoint = api(`/rooms/${roomId}/host/join`)

  const fetchUrl = useCallback(() => {
    if (url) {
      return
    }

    if (useStartUrl) {
      fetchMeeting({client, room: {id: roomId}}).then((meeting) => {
        setUrl(meeting.start_url)
      })
      return
    }

    client
      .get<{join_url: string}>(endpoint)
      .then((res) => setUrl(res.join_url))
      .catch(() => null) // ignore errors
  }, [client, endpoint, url, useStartUrl, roomId, fetchMeeting])

  // Kick off initial fetch without waiting for interval
  useEffect(fetchUrl, [fetchUrl])

  // Keep retrying until we have a url
  useInterval(fetchUrl, 1000, Boolean(url))

  return url
}

import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import {useEvent} from 'Event/EventProvider'
import TextField from 'lib/ui/TextField'
import {api} from 'lib/url'
import Page from 'organization/Event/Page'
import {ActiveCampaignIntegration} from 'organization/Event/Services/Apps/ActiveCampaign'
import {useServices} from 'organization/Event/Services/ServicesProvider'
import {useOrganization} from 'organization/OrganizationProvider'
import React, {useState} from 'react'
import {useForm} from 'react-hook-form'
import ErrorAlert from 'lib/ui/alerts/ErrorAlert'
import styled from 'styled-components'

export interface ActiveCampaignAuth {
  api_url: string
  api_key: string
}

export default function LinkPage() {
  const {handleSubmit, register} = useForm()
  const auth = useAuthActiveCampaign()
  const [processing, setProcessing] = useState(false)
  const {add: addIntegration} = useServices()
  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  const submit = (data: ActiveCampaignAuth) => {
    if (processing) {
      return
    }

    setErrorMessage(null)
    setProcessing(true)

    auth(data)
      .then(addIntegration)
      .catch((e) => {
        setErrorMessage(e.message)
      })
      .finally(() => setProcessing(false))
  }

  return (
    <Page>
      <Typography variant="h4">Active Campaign</Typography>
      <p>
        To obtain your ActiveCampaign API URL and Key, follow these steps in
        your ActiveCampaign account:
      </p>

      <p>
        1. <b>Click "Settings"</b> (gear icon) on the left menu.
      </p>
      <p>
        2. The Account Settings menu will appear.{' '}
        <b>Click the "Developer" option.</b>
      </p>
      <p>
        3. <b>Copy your API URL and Key</b> and{' '}
        <b>paste it into the fields below.</b>
      </p>
      <br />
      <form onSubmit={handleSubmit(submit)}>
        <TextField
          label="API Url"
          name="api_url"
          fullWidth
          variant="outlined"
          inputProps={{ref: register, 'aria-label': 'api url'}}
          disabled={processing}
        />
        <TextField
          label="API Key"
          name="api_key"
          fullWidth
          variant="outlined"
          inputProps={{ref: register, 'aria-label': 'api key'}}
          disabled={processing}
        />
        <Button
          color="primary"
          variant="contained"
          aria-label="authorize"
          type="submit"
          disabled={processing}
        >
          Authorize
        </Button>
        <StyledErrorAlert>{errorMessage}</StyledErrorAlert>
      </form>
    </Page>
  )
}

const StyledErrorAlert = styled(ErrorAlert)`
  width: 100%;
  margin-top: ${(props) => props.theme.spacing[4]};
`

function useAuthActiveCampaign() {
  const {client} = useOrganization()
  const {event} = useEvent()

  return (authData: ActiveCampaignAuth) => {
    return client.post<ActiveCampaignIntegration>(
      api(`/events/${event.id}/integrations/active_campaign/link`),
      {...authData},
    )
  }
}

import React from 'react'
import {useForm} from 'react-hook-form'
import ComponentConfig, {
  ComponentConfigProps,
  SaveButton,
  Footer,
} from 'organization/Event/DashboardConfig/ComponentConfigPanel'
import {NiftyFifty, useNiftyFiftyUpdate} from 'Event/template/NiftyFifty'
import Styling from 'Event/template/NiftyFifty/Dashboard/RightPanel/RightPanelConfig/Styling'
import StylingPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/StylingPanel'

export default function RightPanelConfig(props: ComponentConfigProps) {
  const {showing: isVisible, onClose} = props

  const update = useNiftyFiftyUpdate()
  const {control, handleSubmit, register, formState} = useForm()

  const submit = (data: NiftyFifty) => {
    update({...data})

    onClose()
  }

  return (
    <ComponentConfig
      showing={isVisible}
      onClose={onClose}
      title="Right Panel Config"
      onSubmit={handleSubmit(submit)}
      hasChanges={formState.isDirty}
    >
      <StylingPanel>
        <Styling control={control} register={register} />
      </StylingPanel>
      <Footer>
        <SaveButton type="submit" />
      </Footer>
    </ComponentConfig>
  )
}

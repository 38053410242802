import {useEvent} from 'Event/EventProvider'
import {useAsync} from 'lib/async'
import FullPageLoader from 'lib/ui/layout/FullPageLoader'
import {api} from 'lib/url'
import {useOrganization} from 'organization/OrganizationProvider'
import React, {useEffect, useState, useCallback} from 'react'

export interface GroupContextProps {
  groups: string[]
  loading: boolean
  error: string | null
  clearError: () => void
  setError: (error: string) => void
}

export const GroupsContext = React.createContext<GroupContextProps | undefined>(
  undefined,
)

export default function GroupsProvider(props: {
  children: React.ReactElement
  disableLoader?: Boolean
}) {
  const {groups: saved, loading} = useSavedGroups()
  const [groups, setGroups] = useState<string[]>([])
  const [error, setError] = useState<string | null>(null)
  const clearError = () => setError(null)

  useEffect(() => {
    if (!saved) {
      return
    }
    setGroups(saved)
  }, [saved])

  if (loading && !props.disableLoader) {
    return <FullPageLoader />
  }

  return (
    <GroupsContext.Provider
      value={{
        groups,
        loading,
        error,
        clearError,
        setError,
      }}
    >
      {props.children}
    </GroupsContext.Provider>
  )
}

export function useGroups() {
  const context = React.useContext(GroupsContext)
  if (context === undefined) {
    throw new Error('useGroups must be used within a GroupsProvider')
  }

  return context
}

export function useSavedGroups() {
  const {client} = useOrganization()
  const {
    event: {id: eventId},
  } = useEvent()

  const request = useCallback(() => {
    const url = api(`/events/${eventId}/groups`)
    return client.get<string[]>(url)
  }, [client, eventId])

  const {data, loading} = useAsync(request)

  return {
    groups: data,
    loading,
  }
}

import styled from 'styled-components'
import React from 'react'
import {useNiftyFiftyTemplate} from 'Event/template/NiftyFifty'
import {rgba} from 'lib/color'
import {LoginProps} from 'Event/auth/Login'
import ForgotPasswordLink, {
  ForgotPasswordLinkBase,
} from 'Event/auth/Login/ForgotPasswordLink'
import EmailField from 'Event/auth/Login/EmailField'
import PasswordField from 'Event/auth/Login/PasswordField'
import SubmitButton from 'Event/auth/Login/SubmitButton'
import {ErrorMessage} from 'Event/template/NiftyFifty/Login/Page'
import Logo from 'Event/auth/Login/Logo'
import Background from 'Event/auth/Login/Background'
import Description from 'Event/auth/Login/Description'
import SignUpLink, {SignUpLinkBase} from 'Event/auth/Login/SignUpLink'

export default function MobilePanel(props: LoginProps) {
  const template = useNiftyFiftyTemplate()
  const {login} = template

  return (
    <Box>
      <LeftContainer>
        <StyledBackground>
          <Logo />
        </StyledBackground>
      </LeftContainer>
      <RightContainer
        backgroundColor={rgba(
          login.loginRightPanel.backgroundColor,
          login.loginRightPanel.backgroundOpacity,
        )}
      >
        <FormContainer>
          <Description />
          <form onSubmit={props.onSubmit}>
            <EmailField {...props} />
            <PasswordField {...props} />
            <ErrorMessage>{props.error}</ErrorMessage>
            <SubmitButton {...props} />
          </form>
        </FormContainer>
        <ForgotPasswordLink Component={StyledRelativeLink} />
        <SignUpLink Component={StyledRelativeSignUpLink} />
      </RightContainer>
    </Box>
  )
}

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  overflow: auto;
`

export const LeftContainer = styled.div`
  text-align: center;
  display: flex;
  align-items: center;
  width: 100%;
`

export const RightContainer = styled.div<{
  backgroundColor: string
}>`
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding-top: ${(props) => props.theme.spacing[16]};
  padding-bottom: ${(props) => props.theme.spacing[8]};
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  background: ${(props) => props.backgroundColor};
`

export const FormContainer = styled.div`
  width: 80%;
`

export const StyledRelativeLink = styled(ForgotPasswordLinkBase)<{
  color: string
}>`
  margin-top: ${(props) => props.theme.spacing[10]};
`

export const StyledRelativeSignUpLink = styled(SignUpLinkBase)<{
  color: string
}>`
  margin-top: ${(props) => props.theme.spacing[10]};
`

export const StyledBackground = styled(Background)`
  position: inherit !important;
  > * {
    padding: ${(props) => props.theme.spacing[12]} 0;
  }
`

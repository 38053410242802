import React, {useState} from 'react'
import styled from 'styled-components'
import Button from 'lib/ui/Button'
import TextField from '@material-ui/core/TextField'
import {useValidatedForm} from 'lib/form'
import {Header, Typography} from 'lib/ui/typography'
import ErrorDisplay from 'lib/ui/ErrorDisplay'
import {useAddMarketplaceAccount} from 'lib/marketplace-api/accounts/use-add-marketplace-account'
import ConfirmConnectDialog from 'organization/Settings/MarketplaceConnectForm/ConfirmConnectDialog'

interface ConnectFormData {
  email: string
}

export default function ConnectAccountForm() {
  const [stripeUrl, setStripeUrl] = useState<string | null>(null)
  const [showingForm, setShowingForm] = useState(false)

  const {mutate, isLoading} = useAddMarketplaceAccount()

  const {
    errors,
    handleSubmit,
    register,
    responseError,
    clearErrors,
    setResponseError,
  } = useValidatedForm<ConnectFormData>()

  const submit = (data: ConnectFormData) => {
    mutate(
      {email: data.email},
      {
        onError: (error: any) => {
          setResponseError(error)
        },
        onSuccess: (response) => {
          setStripeUrl(response.url)
        },
      },
    )
    clearErrors()
  }

  // Showing the "connect" alone initially. Once user clicks it, the email form
  // is rendered which is the only first step from the Obvio side for connecting
  // a Stripe account.
  if (!showingForm) {
    return <ConnectButton onClick={() => setShowingForm(true)} />
  }

  return (
    <>
      {stripeUrl && (
        <ConfirmConnectDialog
          onClose={() => setStripeUrl(null)}
          url={stripeUrl}
        />
      )}

      <Header mb={2}>Let's get started</Header>

      <Typography mb={4}>
        Before your Marketplace can operate, we need to link your Organization
        to a Stripe account. If you already have a Stripe account that you would
        like to use, provide the email below.
      </Typography>

      <Typography mb={4}>
        {' '}
        If you do not already have a Stripe account, one will be created for you
        during this onboard process.
      </Typography>

      <form onSubmit={handleSubmit(submit)}>
        <ErrorDisplay>{responseError?.message}</ErrorDisplay>

        <TextField
          disabled={isLoading}
          error={!!errors.email}
          fullWidth
          helperText={
            errors.email ||
            'This is the email address you will use to log in to your Stripe account'
          }
          inputProps={{
            ref: register({
              required: 'Email is required',
            }),
            'aria-label': 'stripe connect email',
          }}
          label="Email"
          name="email"
          required
          variant="outlined"
        />

        <ButtonContainer>
          <Button
            aria-label="submit"
            color="primary"
            disabled={isLoading}
            fullWidth
            size="large"
            type="submit"
            variant="contained"
          >
            Next
          </Button>
        </ButtonContainer>
      </form>
    </>
  )
}

function ConnectButton(props: {onClick: () => void}) {
  return (
    <Button
      aria-label="stripe connect button"
      color="primary"
      fullWidth
      variant="outlined"
      onClick={props.onClick}
    >
      Stripe Connect
    </Button>
  )
}

const ButtonContainer = styled.div`
  display: flex;
  margin-top: ${(props) => props.theme.spacing[8]};
`

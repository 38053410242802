import {useEvent} from 'Event/EventProvider'
import {useAsync} from 'lib/async'
import {api} from 'lib/url'
import {useOrganization} from 'organization/OrganizationProvider'
import React, {useCallback, useEffect, useState} from 'react'
import {usePlatformActions} from 'Event/ActionsProvider/platform-actions'
import {usePoints} from 'Event/PointsProvider'
import FullPageLoader from 'lib/ui/layout/FullPageLoader'

export interface Action {
  id: number
  key: string
  description: string
  points: number
  max_per_day: number | null
  max_per_event: number | null
  is_active: boolean
  has_random_points: boolean
  random_min_points: number | null
  random_max_points: number | null
  min_interval_minutes: number | null
}

export type ActionsContextProps = ReturnType<typeof useActionsList>

export const ActionsContext = React.createContext<
  ActionsContextProps | undefined
>(undefined)

export default function ActionsProvider(props: {
  children: React.ReactNode
  loader?: React.ReactElement
}) {
  const fetch = useFetch()
  const list = useActionsList(fetch)

  const loading = list.loading
  if (loading) {
    return props.loader || <FullPageLoader />
  }

  return (
    <ActionsContext.Provider value={list}>
      {props.children}
    </ActionsContext.Provider>
  )
}

export function useActions() {
  const context = React.useContext(ActionsContext)
  if (context === undefined) {
    throw new Error(`useActions must be used within a ActionsProvider`)
  }

  return context
}

function useFetch() {
  const {event} = useEvent()
  const {client} = useOrganization()
  const url = api(`/events/${event.id}/actions`)

  return useCallback(
    () => client.get<Action[]>(url, {noCache: true}),
    [client, url],
  )
}

export function useActionsList(request: () => Promise<Action[]>) {
  const {data: saved, loading: fetching, error} = useAsync(request)
  const [actions, setActions] = useState<Action[]>([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (fetching) {
      return
    }

    if (!saved) {
      setLoading(false)
      return
    }

    setActions(saved)
    setLoading(false)
  }, [saved, fetching])

  const update = (target: Action) => {
    const updated = actions.map((a) => {
      const isTarget = a.key === target.key
      if (isTarget) {
        return target
      }

      return a
    })

    setActions(updated)
  }

  const add = (action: Action) => {
    const appended = [...actions, action]
    setActions(appended)
  }

  const remove = (target: Action) => {
    const removed = actions.filter((a) => a.key !== target.key)
    setActions(removed)
  }

  return {actions, update, loading, error, add, remove, setActions}
}

/**
 * Sends a points action for a given Resource.
 *
 * @param resource
 * @returns
 */
export function useSubmitResourceAction(resource: {actionId?: string | null}) {
  const {submit} = usePoints()
  const {downloadResource} = usePlatformActions()

  return () => {
    if (resource.actionId) {
      submit(resource.actionId)
    }

    if (downloadResource) {
      submit(downloadResource.key)
    }
  }
}

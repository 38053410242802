import React from 'react'
import styled, {useTheme} from 'styled-components'
import {onChangeStringHandler} from 'lib/dom'
import {BaseProps} from 'lib/ui'
import {TextFieldProps} from 'lib/ui/TextField'

export type TextAreaProps = Omit<
  TextFieldProps,
  'onFocus' | 'onClick' | 'inputProps'
> & {
  rows: number
  inputProps?: BaseProps<HTMLTextAreaElement> &
    React.HTMLAttributes<HTMLTextAreaElement>
  defaultValue?: string
}

export default function TextArea(props: TextAreaProps) {
  const backgroundColor = useBackgroundColor(props)
  const {
    className,
    variant,
    inputProps,
    value,
    rows,
    onChange,
    ...forwardProps
  } = props
  const hasBorder = variant === 'outlined'

  const textColor = useTextColor()

  const baseProps = {
    ...forwardProps,
    backgroundColor,
    color: textColor,
    hasBorder,
    error: Boolean(props.error),
    rows,
  }

  const propsWithValue =
    props.onChange === undefined
      ? baseProps
      : {
          ...baseProps,
          value: value || '',
          onChange: onChange && onChangeStringHandler(onChange),
        }

  return (
    <Box fullWidth={props.fullWidth} className={className}>
      <Content>
        <StyledTextArea {...propsWithValue} {...inputProps} name={props.name} />
      </Content>
    </Box>
  )
}

function useTextColor() {
  const theme = useTheme()
  if (theme.name === 'dark') {
    return '#FFFFFF'
  }

  return '#000000'
}

function useBackgroundColor(props: TextAreaProps) {
  const theme = useTheme()

  if (theme.name === 'dark') {
    return theme.colors.input.dark
  }

  if (props.variant === 'filled') {
    return theme.colors.input.filled
  }

  return 'transparent'
}

type StyleProps = {
  backgroundColor: string
  color: string
  rounded?: boolean
  hasBorder?: boolean
  error?: boolean
  disabled?: boolean
}

const StyledTextArea = styled.textarea<StyleProps>`
  box-sizing: border-box;
  padding: ${(props) => `${props.theme.spacing[3]} ${props.theme.spacing[3]}`};
  color: ${(props) =>
    props.disabled ? props.theme.colors.disabled : props.color};
  background-color: ${(props) => props.backgroundColor};
  border-radius: ${(props) => `${props.rounded ? '24px' : '3px'}`};
  border-width: ${(props) => `${props.hasBorder || props.error ? '1px' : 0}`};
  border-color: ${(props) =>
    `${props.error ? props.theme.colors.error : props.theme.colors.gray300}`};
  border-style: solid;
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
  &:focus {
    outline: none !important;
    border-color: ${(props) =>
      `${
        props.error ? props.theme.colors.error : props.theme.colors.input.focus
      }`};
  }

  &::placeholder {
    ${(props) =>
      props.disabled ? `color: ${props.theme.colors.disabled};` : ''}
  }
`

const Box = styled.div<{
  fullWidth?: boolean
}>`
  display: flex;
  width: ${(props) => (props.fullWidth ? '100%' : 'auto')};
  flex-direction: column;

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    margin-bottom: ${(props) => props.theme.spacing[6]};
  }
`

const Content = styled.div`
  display: flex;

  ${StyledTextArea} {
    flex: 1;
  }
`

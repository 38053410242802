import React from 'react'
import {eventRoutes} from 'Event/Routes'
import {useHistory} from 'react-router-dom'
import {useResetPassword} from 'auth/password'
import {useEvent} from 'Event/EventProvider'
import {api} from 'lib/url'
import {useTemplate} from 'Event/TemplateProvider'
import {components} from 'Event/template'

export default function ResetPassword() {
  const {name} = useTemplate()
  const Component = components[name].ResetPassword
  const history = useHistory()

  const goBacktoLogin = () => {
    history.push(eventRoutes.login)
  }

  const {event} = useEvent()
  const reset = useResetPassword(api(`/events/${event.id}/reset_password`))

  return <Component goBacktoLogin={goBacktoLogin} {...reset} />
}

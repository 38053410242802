import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import {useAttendeeVariables} from 'Event'
import {useEntries} from 'Event/Leaderboard'
import {useNiftyFiftyTemplate} from 'Event/template/NiftyFifty'
import {PageTitle} from 'Event/template/NiftyFifty/Page'
import Content from 'lib/ui/form/TextEditor/Content'
import React from 'react'
import {useToggle} from 'lib/toggle'
import {Editable} from 'Event/Dashboard/editor/views/EditComponent'
import EditModeOnly from 'Event/Dashboard/editor/views/EditModeOnly'
import LeaderboardConfig from 'Event/template/NiftyFifty/Leaderboard/LeaderboardConfig'
import styled from 'styled-components'

import {PageDescription} from 'Event/template/NiftyFifty/Page'

export default function Leaderboard() {
  const v = useAttendeeVariables()
  const template = useNiftyFiftyTemplate()
  const {leaderboard} = template
  const {entries} = useEntries()
  const {flag: configVisible, toggle: toggleConfig} = useToggle()

  return (
    <>
      <EditModeOnly>
        <LeaderboardConfig showing={configVisible} onClose={toggleConfig} />
      </EditModeOnly>
      <Editable onEdit={toggleConfig}>
        <PageTitle
          textColor={template.textColor}
          pageTitleColor={template.pageTitleColor}
          aria-label="config points tab page config"
        >
          {v(leaderboard.title)}
        </PageTitle>
      </Editable>
      <PageDescription
        color={template.textColor}
        aria-label="leaderboard description"
      >
        <Content>{v(leaderboard.description)}</Content>
      </PageDescription>
      <Table color={template.textColor}>
        <TableHead>
          <TableRow>
            <StyledTableCell color={template.textColor}>#</StyledTableCell>
            <StyledTableCell color={template.textColor} align="left">
              Name
            </StyledTableCell>
            <StyledTableCell
              color={template.textColor}
              align="right"
              size="small"
            >
              Points
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {entries.map((entry, index) => (
            <TableRow key={index} aria-label="entry">
              <StyledTableCell
                color={template.textColor}
                component="th"
                scope="row"
              >
                {index + 1}
              </StyledTableCell>
              <StyledTableCell color={template.textColor} align="left">
                {entry.attendee.first_name} {entry.attendee.last_name}
              </StyledTableCell>
              <StyledTableCell color={template.textColor} align="right">
                {entry.score}
              </StyledTableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  )
}

const StyledTableCell = styled(TableCell)<{color: string}>`
  color: ${(props) => props.color};
`

import Typography from '@material-ui/core/Typography'
import Page from 'organization/Event/Page'
import React, {useState} from 'react'
import Box from '@material-ui/core/Box'
import {Grid, withStyles} from '@material-ui/core'
import {
  useHighLevel,
  useServices,
} from 'organization/Event/Services/ServicesProvider'
import {spacing} from 'lib/ui/theme'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import {
  ATTENDEE_CHECKED_IN,
  ATTENDEE_CREATED,
  ATTENDEE_SIGNED_WAIVER,
  Group,
  HighLevelField,
  HighLevelIntegration,
  Tag,
} from 'organization/Event/Services/Apps/HighLevel'
import TagIdInput from 'organization/Event/Services/Apps/HighLevel/Config/TagIdInput'
import FieldsAutocomplete from 'organization/Event/Services/Apps/HighLevel/Config/FieldsAutocomplete'
import GroupsConfig from 'organization/Event/Services/Apps/HighLevel/Config/GroupsConfig'
import SyncSwitch from 'organization/Event/Services/Apps/HighLevel/Config/SyncSwitch'
import {useOrganization} from 'organization/OrganizationProvider'
import {useEvent} from 'Event/EventProvider'
import {api} from 'lib/url'
import ErrorAlert from 'lib/ui/alerts/ErrorAlert'
import InputAdornment from '@material-ui/core/InputAdornment'
import CopyIconButton from 'lib/ui/IconButton/CopyIconButton'

export interface UpdateData {
  tags: Tag[]
  groups: Group[]
  login_field: HighLevelField | null
}

export default function Config() {
  const highlevel = useHighLevel()
  const {save, processing, error} = useSave()
  const [tags, setTags] = useState<Tag[]>(highlevel.tags)
  const [groups, setGroups] = useState<Group[]>(highlevel.groups)
  const [loginField, setLoginField] = useState<HighLevelField | null>({
    field_key: highlevel.login_field_key,
    field_id: highlevel.login_field_id,
    field_name: highlevel.login_field_name,
    field_data_type: highlevel.login_field_type || 'TEXT',
  })
  const updateTag = (tag: Tag) => {
    const updated = tags.map((t) => (t.id === tag.id ? tag : t))
    setTags(updated)
  }

  const tagForType = (type: Tag['type']) => {
    const target = tags.find((t) => t.type === type)
    if (!target) {
      throw new Error(`Unknown tag type: ${type}`)
    }

    return target
  }

  const handleSave = () => {
    const data: UpdateData = {
      tags,
      groups,
      login_field: loginField,
    }

    save(data)
  }

  const copyTag = () => {
    navigator.clipboard.writeText(highlevel.import_tag || '')
  }

  return (
    <Page>
      <Box mb={3}>
        <Grid container spacing={2}>
          <Grid item md={6}>
            <Typography variant="h4">Go High Level</Typography>
          </Grid>
          <StyledGrid item md={6}>
            <SyncSwitch />
          </StyledGrid>
        </Grid>
      </Box>

      <Box mb={3}>
        <Box mb={2}>
          <Typography variant="h5">
            Attendee Import or Delete via Tag
          </Typography>
          <em>Add this tag to attendees you want to import.</em>
        </Box>

        <TextField
          label="Import tag"
          value={highlevel.import_tag}
          fullWidth
          variant="outlined"
          disabled={true}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <CopyIconButton
                  aria-label="copy go high level tag"
                  onClick={copyTag}
                />
              </InputAdornment>
            ),
          }}
          inputProps={{'aria-label': 'import tag'}}
        />
        <em>
          If an attendee with the given email already exists, obv.io will update
          the other attributes.
        </em>
      </Box>

      <Box mb={2}>
        <Typography variant="h5">Login URL Field</Typography>
        <em>
          Select the Custom Field in Go High Level to save your attendee's Login
          URL.
        </em>
      </Box>

      <FieldsAutocomplete
        inputVariant={'outlined'}
        types={['TEXT', 'LARGE_TEXT']}
        inputLabel={loginField?.field_name}
        value={loginField}
        onChange={setLoginField}
      />

      <Box mb={3}>
        <Box mb={2}>
          <Typography variant="h5">Action Tags</Typography>
          <em>
            The following tags can be applied in Go High Level when an attendee
            takes the specific action. (optional)
          </em>
        </Box>

        <TagIdInput tag={tagForType(ATTENDEE_CREATED)} onChange={updateTag} />
        <TagIdInput
          tag={tagForType(ATTENDEE_CHECKED_IN)}
          onChange={updateTag}
        />
        <TagIdInput
          tag={tagForType(ATTENDEE_SIGNED_WAIVER)}
          onChange={updateTag}
        />
      </Box>

      <Box mb={2}>
        <Typography variant="h5">Attendee Groups Properties</Typography>
        <em>
          Obvio can import Contact Properties from Go High Level as groups
          within Obvio. You can import multiple properties by adding multiple
          groups. This is optional.
        </em>
      </Box>
      <GroupsConfig onChange={setGroups} groups={groups} />
      <ErrorAlert>{error}</ErrorAlert>
      <SaveButton
        type="submit"
        variant="contained"
        color="primary"
        size="large"
        aria-label="save"
        onClick={handleSave}
        disabled={processing}
      >
        Save
      </SaveButton>
    </Page>
  )
}

function useSave() {
  const {client} = useOrganization()
  const {event} = useEvent()

  const [processing, setProcessing] = useState<boolean>(false)
  const {update: updateIntegration} = useServices()
  const [error, setError] = useState<string | null>(null)

  const save = (data: UpdateData) => {
    setProcessing(true)
    setError('')

    client
      .put<HighLevelIntegration>(
        api(`/events/${event.id}/integrations/high_level`),
        data,
      )
      .then(updateIntegration)
      .catch((e) => setError(e.message))
      .finally(() => {
        setProcessing(false)
      })
  }

  return {
    processing,
    error,
    save,
  }
}

const StyledGrid = withStyles({
  root: {
    display: 'flex',
    justifyContent: 'end',
  },
})(Grid)

const SaveButton = withStyles({
  root: {
    marginBottom: spacing[5],
  },
})(Button)

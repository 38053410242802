import React from 'react'
import {storage} from 'lib/url'
import ConfirmRemoveButton from 'lib/ui/Button/ConfirmRemoveButton'
import {AbsoluteLink} from 'lib/ui/link/AbsoluteLink'
import Button from '@material-ui/core/Button'
import {DOCUMENTS, MEDIA} from 'lib/ui/form/UploadDropzone'

/**
 * File/mime types that are accepted as resources.
 * For example: '.pdf', '.mp4'
 */
export const RESOURCE_FILE_TYPES = [...DOCUMENTS, ...MEDIA]

export default function UploadedResource(props: {
  filePath: string
  onRemove: () => void
  'aria-label'?: string
}) {
  if (!props.filePath) {
    return null
  }

  const path = storage(`/event/resources/${props.filePath}`)

  return (
    <>
      <AbsoluteLink to={path} disableStyles newTab>
        <Button variant="outlined" color="primary">
          View Uploaded File
        </Button>
      </AbsoluteLink>
      <ConfirmRemoveButton
        onClick={props.onRemove}
        variant="outlined"
        aria-label={props['aria-label'] || 'remove file'}
      >
        Remove File
      </ConfirmRemoveButton>
    </>
  )
}

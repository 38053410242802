import React from 'react'
import Button from '@material-ui/core/Button'
import {useForm} from 'react-hook-form'
import Box from '@material-ui/core/Box'
import {useAttendeeVariables} from 'Event'
import {useToggleArray} from 'lib/toggle'
import {useTeamCompetition} from 'Event/business-mastery/TeamCompetition'
import {useNote} from 'lib/note'
import TextField from 'Event/business-mastery/TextField'

export default function DetailsForm(props: {
  details: ReturnType<typeof useTeamDetails>
}) {
  const {details} = props
  const {handleSubmit, register, watch} = useForm()
  const v = useAttendeeVariables()

  const [processing, toggleProcessing] = useToggleArray()

  const submit = ({
    name,
    leader,
    slogan,
    project_name,
    project_url,
    team_communication_link,
  }: any) => {
    if (processing) {
      return
    }

    toggleProcessing()

    Promise.all([
      details.name.save(name),
      details.leader.save(leader),
      details.slogan.save(slogan),
      details.projectName.save(project_name),
      details.projectUrl.save(project_url),
      details.teamCommunicationLink.save(team_communication_link),
    ]).finally(toggleProcessing)
  }

  if (details.loading) {
    return null
  }

  return (
    <form onSubmit={handleSubmit(submit)}>
      <Box display="flex" flexDirection="column">
        <Box mb={1}>
          <TextField
            name="name"
            label={v('{{team_name}}', 'Team Name')}
            fullWidth
            defaultValue={details.name.note?.body || null}
            inputProps={{
              ref: register,
              'aria-label': 'name',
            }}
            required
            disabled={processing}
          />
        </Box>

        <Box mb={1}>
          <TextField
            name="leader"
            label={v('{{team_leader}}', 'Team Leader')}
            fullWidth
            defaultValue={details.leader.note?.body || null}
            inputProps={{
              ref: register,
              'aria-label': 'leader',
            }}
            disabled={processing}
          />
        </Box>
        <Box>
          <TextField
            name="slogan"
            label={v('{{team_slogan}}', 'Team Slogan')}
            fullWidth
            defaultValue={details.slogan.note?.body || null}
            multiline
            rows={3}
            inputProps={{
              ref: register,
              'aria-label': 'slogan',
            }}
            disabled={processing}
            InputLabelProps={{
              shrink:
                Boolean(details.slogan.note?.body) || Boolean(watch('slogan')),
            }}
          />
        </Box>
        <Box>
          <TextField
            name="project_name"
            label={v('{{project_name}}', 'Project Name')}
            fullWidth
            defaultValue={details.projectName.note?.body || null}
            inputProps={{
              ref: register,
              'aria-label': 'project name',
            }}
            required
            disabled={processing}
          />
        </Box>
        <Box>
          <TextField
            name="project_url"
            label={v('{{project_url}}', 'Project URL')}
            fullWidth
            defaultValue={details.projectUrl.note?.body || null}
            inputProps={{
              ref: register,
              'aria-label': 'project url',
            }}
            disabled={processing}
          />
        </Box>
        <Box>
          <TextField
            name="team_communication_link"
            label={v('{{team_communication_link}}', 'Team Communication Link')}
            fullWidth
            defaultValue={details.teamCommunicationLink.note?.body || null}
            inputProps={{
              ref: register,
              'aria-label': 'team communication link',
            }}
            disabled={processing}
          />
        </Box>
        <Box mt={2} display="flex" justifyContent="flex-end">
          <Button variant="outlined" type="submit" disabled={processing}>
            {v('{{team_update_details_button}}', 'Update')}
          </Button>
        </Box>
      </Box>
    </form>
  )
}

export function useTeamDetails() {
  const {teamId} = useTeamCompetition()

  const notes = {
    name: useNote(`team:${teamId}:details:name`),
    leader: useNote(`team:${teamId}:details:leader`),
    slogan: useNote(`team:${teamId}:details:slogan`),
    projectName: useNote(`team:${teamId}:details.project_name`),
    projectUrl: useNote(`team:${teamId}:details.project_url`),
    teamCommunicationLink: useNote(
      `team:${teamId}:details.team_communication_link`,
    ),
  }

  const loading = Object.values(notes).filter((n) => n.loading).length > 0

  return {
    ...notes,
    loading,
  }
}

import React from 'react'
import {rgba, isValidColor} from 'lib/color'
import {backgroundRepeat, backgroundSize} from './lib/background'
import {PurchasePageTemplate} from 'Event/Marketplace/purchase-page'
import {getFontStyles} from 'Event/Marketplace/lib/font'

interface PurchasePageContainerProps {
  template: PurchasePageTemplate
  children: React.ReactNode
}

export default function Container(props: PurchasePageContainerProps) {
  const {template, children} = props
  const styles = getStyles(template)
  return <div style={styles}>{children}</div>
}

function getStyles(template: PurchasePageTemplate): React.CSSProperties {
  return {
    backgroundImage: template.background.image
      ? `url(${template.background.image})`
      : undefined,
    backgroundColor: isValidColor(template.background.color)
      ? rgba(template.background.color, template.background.opacity / 100)
      : undefined,
    backgroundBlendMode: 'multiply',
    backgroundAttachment:
      template.background.repeat === 'full-center-parallax'
        ? 'fixed'
        : undefined,
    backgroundPosition:
      template.background.repeat === 'full-center-parallax'
        ? 'center'
        : template.background.position,
    backgroundSize: backgroundSize(template.background.repeat),
    backgroundRepeat: backgroundRepeat(template.background.repeat),
    paddingTop: `${template.padding.top}px`,
    paddingRight: `${template.padding.right}px`,
    paddingBottom: `${template.padding.bottom}px`,
    paddingLeft: `${template.padding.left}px`,
    width: '100%',
    height: '100%',
    color: template.typography.textColor,
    ...getFontStyles(template.typography.font),
  }
}

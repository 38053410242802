import {useTownhallTemplate} from 'Event/template/Townhall'
import React from 'react'
import {Controller} from 'react-hook-form'
import ImageAssetUploader from 'lib/asset/ImageAssetUploader'
import Title from 'lib/ui/ConfigPanel/Title'
import {useConfig} from 'organization/Event/Configurable'
import BackgroundPositionSelector from 'lib/ui/BackgroundPositionSelector'
import {useToggleArray} from 'lib/toggle'
import PositionSelectorButton from 'lib/ui/BackgroundPositionSelector/BackgroundPositionSelectorButton'

export default function Settings() {
  const {
    form: {control},
  } = useConfig()
  const template = useTownhallTemplate()

  const [showingPositionSelector, togglePositionSelector] = useToggleArray()

  return (
    <>
      <Title>Header Background</Title>
      <Controller
        name="header.backgroundImage"
        control={control}
        defaultValue={template.header.backgroundImage}
        render={({onChange, value}) => (
          <ImageAssetUploader
            onChange={onChange}
            value={value}
            uploadLabel="Upload"
            canResize
            uploadInputProps={{
              'aria-label': 'header background upload',
            }}
            disableAutoRemove
            additionalActions={
              <PositionSelectorButton onClick={togglePositionSelector} />
            }
          />
        )}
      />

      <Controller
        name="header.backgroundPosition"
        control={control}
        defaultValue={template.header.backgroundPosition}
        render={({onChange, value}) => (
          <BackgroundPositionSelector
            showing={showingPositionSelector}
            onChange={onChange}
            value={value}
            label="Background Position"
          />
        )}
      />

      <Title>Page Background</Title>
      <Controller
        name="pageBackground.image"
        control={control}
        defaultValue={template.pageBackground.image}
        render={({onChange, value}) => (
          <ImageAssetUploader
            onChange={onChange}
            value={value}
            uploadLabel="Upload"
            canResize
            uploadInputProps={{
              'aria-label': 'page background upload',
            }}
            disableAutoRemove
          />
        )}
      />
    </>
  )
}

import React from 'react'
import {User} from 'auth/user'
import SpeakerHeader from 'Event/template/Townhall/Speakers/SpeakersHeader'
import FeaturedSpeakersList from 'Event/template/Townhall/Speakers/FeaturedSpeakersList'
import SpeakersList from 'Event/template/Townhall/Speakers/SpeakersList'
import {TownhallBreadcrumbs} from 'Event/template/Townhall/Page'
import {Speaker} from 'Event/Speakers'
import {NavButtonProps} from 'Event/Dashboard/components/NavButton'
import FeaturePage from 'Event/template/Townhall/Page'
import {
  FeaturePageTitle,
  StyledConfigurable,
} from 'Event/template/Townhall/Page'
import {useTownhallTemplate} from 'Event/template/Townhall'
import {useAttendeeVariables} from 'Event'
import SpeakersHeaderConfig from 'Event/template/Townhall/Speakers/SpeakersHeaderConfig'

export type TownhallSpeaker = Speaker & {
  button?: NavButtonProps
}

export default function SpeakerPage(props: {user: User}) {
  const template = useTownhallTemplate()
  const {speakers} = template
  const v = useAttendeeVariables()

  return (
    <FeaturePage user={props.user}>
      <StyledConfigurable>
        <SpeakersHeaderConfig />
        <>
          <FeaturePageTitle aria-label="speakers title">
            {v(speakers.title)}
          </FeaturePageTitle>
          <TownhallBreadcrumbs page={v(speakers.title)} />
          <SpeakerHeader />
        </>
      </StyledConfigurable>
      <FeaturedSpeakersList />
      <SpeakersList />
    </FeaturePage>
  )
}

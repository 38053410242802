import {TextEditorProps} from 'lib/ui/form/TextEditor'
import HtmlNotificationTextEditor from 'lib/ui/form/TextEditor/HtmllNotificationTextEditor'
import React, {useCallback, useEffect} from 'react'

export default function PlainTextNotificationEditor(props: TextEditorProps) {
  const {data, onChange} = props
  const stripTags = (text: string) => text.replace(/(<([^>]+)>)/gi, '')

  // CKEditor will wrap everything in a <p> tag, this will remove all
  // html tags.
  const onChangeHtml = useCallback(
    (html: string) => onChange(stripTags(html)),
    [onChange],
  )

  const isHtml = useCallback((text: string) => /(<([^>]+)>)/.test(text), [])

  // If initial data contains html we'll strip it to start.
  useEffect(() => {
    if (!isHtml(data)) {
      return
    }

    onChangeHtml(data)
  }, [data, onChangeHtml, isHtml])

  // CKEditor expects html. If we're passing in a plain string as a value, the alignment
  // gets messed up, and becomes right-to-left.
  const html = isHtml(data) ? data : `<p>${data || ''}</p>`

  return (
    <HtmlNotificationTextEditor
      toolbar={[
        'obvioVariable', // Registered as a custom CK Editor Plugin
      ]}
      {...props}
      data={html}
      onChange={onChangeHtml}
    />
  )
}

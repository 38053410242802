import React from 'react'
import Button from 'lib/ui/Button'
import {Title} from 'lib/ui/typography'
import HeaderBase from 'lib/ui/layout/Header'

export type HeaderProps = {
  disabled?: boolean
}

export default function Header(props: HeaderProps) {
  return (
    <HeaderBase>
      <Title>Create Your Event</Title>
      <Button
        type="submit"
        variant="contained"
        color="success"
        aria-label="submit"
        disabled={props.disabled}
      >
        Create
      </Button>
    </HeaderBase>
  )
}

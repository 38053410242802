import React from 'react'
import {Link} from 'react-router-dom'
import Page from 'Event/template/SimpleBlog/Page'
import styled from 'styled-components'
import {Attendee} from 'Event/attendee'
import {useAttendeeVariables} from 'Event'
import {useSimpleBlogTemplate} from 'Event/template/SimpleBlog'
import Content from 'lib/ui/form/TextEditor/Content'
import {PageTitle, PageDescription} from 'Event/template/SimpleBlog/Page'
import Configurable from 'organization/Event/Configurable'
import HeaderConfig from 'Event/Backgrounds/HeaderConfig'
import BackgroundLayoutConfig from 'Event/Backgrounds/BackgroundLayoutConfig'
import DashboardLinkConfig from 'Event/template/SimpleBlog/Backgrounds/DashboardLinkConfig'
import BackgroundList from 'Event/Backgrounds/BackgroundList'
import UploadBackgroundButton from 'Event/Backgrounds/UploadBackgroundButton'

export default function SimpleBlogBackgrounds(props: {user: Attendee}) {
  const v = useAttendeeVariables()
  const template = useSimpleBlogTemplate()
  const {zoomBackgrounds: settings} = template

  return (
    <Page user={props.user}>
      <Configurable>
        <HeaderConfig />
        <PageTitle aria-label="backgrounds title">
          {v(settings.title)}
        </PageTitle>
      </Configurable>
      <PageDescription>
        <Content aria-label="backgrounds description">
          {v(settings?.description)}
        </Content>
      </PageDescription>
      <Configurable>
        <DashboardLinkConfig />
        <BackToDashboard color={settings.backToDashboardTextColor}>
          <Link to="/">{v(settings.backToDashboardText)}</Link>
        </BackToDashboard>
      </Configurable>
      <UploadBackgroundButton />
      <Configurable aria-label="background list config">
        <BackgroundLayoutConfig />
        <BackgroundList />
      </Configurable>
    </Page>
  )
}

const BackToDashboard = styled.div`
  text-align: center;
  margin-bottom: 20px;
  a {
    line-height: 1.5;
    color: ${(props) => props.color};
  }
`

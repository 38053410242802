import Switch from '@material-ui/core/Switch'
import {onChangeCheckedHandler} from 'lib/dom'
import React from 'react'
import {useArea} from 'organization/Event/Area/AreaProvider'

export default function RegistrationSwitch() {
  const {area, processing, setRegistration: toggleRegistration} = useArea()

  return (
    <Switch
      checked={area.has_registration}
      disabled={processing}
      onChange={onChangeCheckedHandler(toggleRegistration)}
      color="primary"
      inputProps={{
        'aria-label': 'toggle registration',
      }}
    />
  )
}

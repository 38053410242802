import {useTemplate} from 'Event/TemplateProvider'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import RulesPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/RulesPanel'
import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import {Background} from 'organization/Event/BackgroundsConfig'
import {useAttendeeVariables} from 'Event'
import BackgroundImage from 'Event/Backgrounds/BackgroundImage'
import {REMOVE, useSaveTemplate} from 'Event/TemplateUpdateProvider'
import Config from 'organization/Event/Configurable/Config'
import {useRemoveAsset} from 'lib/asset'
import {createFileLocation} from 'lib/url'

export default function BackgroundImageConfig(props: {
  background: Background
  id: string
}) {
  const {background, id} = props
  const updateTemplate = useSaveTemplate()
  const removeAsset = useRemoveAsset()

  const {zoomBackgrounds: settings} = useTemplate()
  const [rules, setRules] = useState(background.rules || [])
  const v = useAttendeeVariables()

  useEffect(() => {
    setRules(background.rules || [])
  }, [background])

  const save = () => {
    updateTemplate({
      zoomBackgrounds: {
        items: {
          [id]: {
            rules,
          },
        },
      },
    })
  }

  const removeFromTemplate = () => {
    updateTemplate({
      zoomBackgrounds: {
        items: {
          [id]: REMOVE,
        },
      },
    })
  }

  const remove = () => {
    const fileLocation = createFileLocation(background.image)
    if (fileLocation) {
      removeAsset(fileLocation)
        .then(removeFromTemplate)
        .catch((e) => {
          // If the background asset is missing we'll remove it anyway.
          if (e && e.status === 404) {
            removeFromTemplate()
          }
        })
    }
  }

  return (
    <Config title="Background Image" onSave={save} onRemove={remove}>
      <SettingsPanel>
        <ImageContainer>
          <BackgroundImage
            alt="background"
            borderRadius={settings?.borderRadius}
            borderThickness={settings?.borderThickness}
            borderColor={settings?.borderColor}
            clickable={false}
            src={v(background.image)}
            width="100%"
          />
        </ImageContainer>
      </SettingsPanel>
      <RulesPanel rules={rules} setRules={setRules} />
    </Config>
  )
}

const ImageContainer = styled.div`
  margin-top: ${(props) => props.theme.spacing[6]}!important;
  margin-bottom: ${(props) => props.theme.spacing[6]}!important;
`

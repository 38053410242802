import ErrorAlert from 'lib/ui/alerts/ErrorAlert'
import SaveButton from 'organization/Event/Area/Rules/SaveButton'
import {useRules} from 'organization/Event/Area/Rules/RulesProvider'
import RulesTable from 'organization/Event/Area/Rules/RulesTable'
import AddRuleButton from 'organization/Event/Area/Rules/RulesTable/AddRuleButton'
import Page from 'organization/Event/Page'
import React from 'react'
import styled from 'styled-components'
import Alert from '@material-ui/lab/Alert'
import CustomButton from 'lib/ui/Button/CustomButton'
import {ClearRoomAssignments} from 'organization/Event/Area/ClearRoomAssignmentsButton'
import {AreaBreadcrumbs} from 'organization/Event/Area'

export default function Rules() {
  const {error, clearError, setError} = useRules()

  return (
    <AreaBreadcrumbs page="Rules">
      <Page>
        <Actions>
          <AddRuleButton />
          <SaveButton />
        </Actions>
        <StyledErrorAlert onClose={clearError}>{error}</StyledErrorAlert>
        <StyledAlert severity="info">
          Any rule changes will not affect previously assigned attendees. Click{' '}
          <ClearRoomAssignments onError={setError} clearError={clearError}>
            {(confirm, processing) => (
              <StyledButton
                variant="text"
                onClick={confirm}
                disabled={processing}
              >
                here
              </StyledButton>
            )}
          </ClearRoomAssignments>{' '}
          to clear all room assignments.
        </StyledAlert>
        <RulesTable />
      </Page>
    </AreaBreadcrumbs>
  )
}

const Actions = styled.div`
  display: flex;
  justify-content: space-between;
`

const StyledErrorAlert = styled(ErrorAlert)`
  width: 100%;
  margin-top: ${(props) => props.theme.spacing[4]};
`

const StyledAlert = styled(Alert)`
  margin-top: ${(props) => props.theme.spacing[4]};
`

const StyledButton = styled(CustomButton)`
  font-weight: bold;
`

import {
  EMAIL,
  FIRST_NAME,
  LAST_NAME,
  LOGIN_URL,
} from 'lib/ui/form/TextEditor/variables'
import EmailConfig, {WELCOME_EMAIL} from 'organization/Event/EmailConfig'
import React from 'react'

export default function WelcomeEmailConfig() {
  return (
    <EmailConfig
      type={WELCOME_EMAIL}
      title="Welcome Email"
      label="welcome email"
      bodyVariables={[LOGIN_URL, FIRST_NAME, LAST_NAME, EMAIL]}
    />
  )
}

import {useEvent} from 'Event/EventProvider'
import {MarketplaceTicket} from 'lib/marketplace-api/tickets'
import {marketplaceApi} from 'lib/marketplace-api/url'
import {useEventMarketplaceClient} from 'lib/marketplace-api/use-event-marketplace-client'
import {useMutation, useQueryClient} from 'react-query'

interface UpdateTicketParams {
  ticket: MarketplaceTicket
}

export interface UpdateTicketData {
  account_id: number
  name: string
  slug: string
  description: string
  tags?: string[]
  groups?: string[]
  price: number
  available_from?: string
  available_to?: string
  hidden?: boolean
  active?: boolean
}

export function useUpdateTicket(params: UpdateTicketParams) {
  const {ticket} = params
  const {client} = useEventMarketplaceClient()
  const qc = useQueryClient()
  const {event} = useEvent()

  return useMutation({
    mutationFn: (data: UpdateTicketData) => {
      const url = marketplaceApi(`/tickets/${ticket.id}`)
      return client!.put<MarketplaceTicket>(url, data)
    },
    onSuccess: (updated) => {
      qc.setQueryData<MarketplaceTicket[]>(
        ['events', event.id, 'tickets'],
        (tickets) => {
          if (!tickets) {
            return [updated]
          }

          return tickets.map((ticket) =>
            ticket.id === updated.id ? updated : ticket,
          )
        },
      )

      qc.setQueryData<MarketplaceTicket>(['tickets', ticket.id], () => updated)
    },
  })
}

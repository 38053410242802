import {useEvent} from 'Event/EventProvider'
import React from 'react'
import PropertySelector from 'organization/Event/Services/Apps/Hubspot/Config/PropertySelector'
import PropertyValueField from 'organization/Event/Services/Apps/Hubspot/Config/PropertyValueField'
import {
  Property,
  useHubspotProperties,
} from 'organization/Event/Services/Apps/Hubspot/Config/HubspotPropertiesProvider'
import {HubspotTag} from 'Event/hubspot'
import {Label} from 'lib/ui/typography'
import styled from 'styled-components'
import {TargetProperty} from 'organization/Event/Services/Apps/Hubspot/Config'

export type TagInputProps = {
  onChange: (tag: HubspotTag | null) => void
  value?: HubspotTag | null
  targetTypes?: Property['type'][]
  disabled?: boolean
  label?: string
}

export default function TagInput(props: TagInputProps) {
  const {onChange, disabled, value, targetTypes, label} = props
  const {event} = useEvent()

  const {findProperty: findByName} = useHubspotProperties()

  const property = value?.property_name
    ? findByName(value?.property_name)
    : null

  if (!event.has_hubspot || disabled) {
    return null
  }

  const setProperty = (selected: TargetProperty | null) => {
    onChange({
      property_value: value?.property_value ?? '',
      property_label: selected?.label ?? null,
      property_name: selected?.name ?? null,
      property_type: selected?.type ?? null,
    })
  }

  const setValue = (propertyValue: string) => {
    if (!value) {
      return
    }

    onChange({
      ...value,
      property_value: propertyValue,
    })
  }

  return (
    <div>
      <StyledLabel>Hubspot tag</StyledLabel>
      <Box>
        <PropertySelector
          targetTypes={targetTypes}
          onChange={setProperty}
          value={property}
          label={label}
        />
        <ValueField
          propertyName={value?.property_name}
          onChange={setValue}
          propertyValue={value?.property_value}
          propertyType={value?.property_type}
        />
      </Box>
    </div>
  )
}

function ValueField(props: {
  propertyName?: string | null
  propertyValue?: string | null
  propertyType?: Property['type'] | null
  onChange: (value: string) => void
}) {
  const {propertyName, onChange, propertyType, propertyValue} = props
  if (!propertyName || !propertyType) {
    return null
  }

  return (
    <PropertyValueField
      onChange={onChange}
      propertyName={propertyName}
      propertyType={propertyType}
      value={propertyValue || ''}
    />
  )
}

const StyledLabel = styled(Label)`
  margin-bottom: ${(props) => props.theme.spacing[4]} !important;
`

const Box = styled.div`
  display: flex;
  gap: ${(props) => props.theme.spacing[1]};

  > div {
    flex: 1;
  }
`

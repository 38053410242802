import {ObvioEvent} from 'Event'
import {Client} from 'lib/ui/api-client'
import {api} from 'lib/url'
import {useQuery} from 'react-query'

export type MailchimpAudience = {
  id: string
  name: string
}

export function useMailchimpAudiences(params: {
  client: Client
  event: ObvioEvent
}) {
  const {client, event} = params

  return useQuery({
    queryKey: ['events', event.id, 'integrations', 'mailchimp', 'audiences'],
    queryFn: () =>
      client.get<Array<MailchimpAudience>>(
        api(`/events/${event.id}/integrations/mailchimp/audiences`),
      ),
  })
}

import React from 'react'
import styled from 'styled-components'
import {AttendeesBreadcrumbs} from 'organization/Event/Page/PageBreadcrumbs'
import Page from 'organization/Event/Page'
import TextField, {NumberField} from 'lib/ui/TextField'
import Box from '@material-ui/core/Box'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import TableBody from '@material-ui/core/TableBody'
import Typography from '@material-ui/core/Typography'
import {useLeaderboardEntries} from 'organization/Event/Leaderboard/LeaderboardProvider'
import {useDebouncedHandler} from 'lib/debounce'
import FullPageLoader from 'lib/ui/layout/FullPageLoader'

export default function Leaderboard() {
  const {search, searchTerm, limit, setLimit} = useLeaderboardEntries()
  const [term, setTerm] = useDebouncedHandler(searchTerm, search)
  const [searchLimit, setSearchLimit] = useDebouncedHandler(limit, setLimit)

  return (
    <AttendeesBreadcrumbs page="Leaderboard">
      <Page>
        <PageHeader>
          <SearchBox>
            <StyledTextField
              fullWidth
              variant="outlined"
              label="Search"
              inputProps={{
                'aria-label': 'search for entries',
              }}
              value={term}
              onChange={setTerm}
              helperText="Searches within retrieved entries determined by the limit count."
            />
          </SearchBox>
          <NumberField
            variant="outlined"
            label="Limit"
            type="number"
            onChange={setSearchLimit}
            value={searchLimit}
            inputProps={{
              'aria-label': 'limit for entries',
            }}
          />
        </PageHeader>
        <EntryTable />
      </Page>
    </AttendeesBreadcrumbs>
  )
}

function EntryTable() {
  const {entries, loading} = useLeaderboardEntries()
  const hasEntries = entries.length > 0

  if (loading) {
    return (
      <Box justifyContent="center" display="flex" paddingY={4}>
        <FullPageLoader />
      </Box>
    )
  }

  if (!hasEntries) {
    return (
      <Box paddingY={4}>
        <Typography align="center">No entries found</Typography>
      </Box>
    )
  }

  return (
    <TableBox>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>#</TableCell>
            <TableCell align="left">Name</TableCell>
            <TableCell align="right" size="small">
              Points
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {entries.map((entry, index) => (
            <TableRow key={index} aria-label="entry">
              <TableCell component="th" scope="row">
                {index + 1}
              </TableCell>
              <TableCell align="left">
                {entry.attendee.first_name} {entry.attendee.last_name}
              </TableCell>
              <TableCell align="right">{entry.score}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableBox>
  )
}

const SearchBox = styled.div`
  flex: 1;
  margin-right: ${(props) => props.theme.spacing[2]};
  margin-bottom: ${(props) => props.theme.spacing[2]};
`

const StyledTextField = styled(TextField)`
  margin-bottom: 0;
`

const TableBox = styled.div`
  overflow: auto;
`

const PageHeader = styled.div`
  display: flex;
  align-items: flex-start;
`

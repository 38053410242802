import {useRemoveAsset, useUploadAsset} from 'lib/asset'
import UploadButton from 'lib/ui/form/ImageUpload/UploadButton'
import styled from 'styled-components'
import React, {useEffect, useState} from 'react'
import {FileSelect, useFileSelect} from 'lib/ui/form/file'
import {createFileLocation} from 'lib/url'
import {useCallback} from 'react'
import Box from '@material-ui/core/Box'
import ImageUpload from 'lib/ui/form/ImageUpload'
import InputLabel from '@material-ui/core/InputLabel'
import RemoveImageButton from 'lib/ui/form/ImageUpload/RemoveButton'
import Cropper from 'lib/ui/form/ImageUpload/Cropper'
import Image from 'lib/ui/form/ImageUpload/Image'
import Actions from 'lib/ui/form/ImageUpload/Actions'

export type ImageSelectorProps = {
  label?: string | null
  value: string | File | null
  onChange: (file: File | null) => void
  alt?: string
  uploadInputProps?: {
    'aria-label'?: string
  }
  removeButtonProps?: {
    'aria-label'?: string
  }
  canResize?: boolean
  width?: number
  height?: number
  uploadLabel?: string
  disabled?: boolean
  autoUpload?: boolean
  additionalActions?: JSX.Element
}

export type ImageAssetUploaderProps = Omit<
  ImageSelectorProps,
  'onChange' | 'disabled' | 'autoUpload' | 'value'
> & {
  value: string | null
  onChange: (file: string | null) => void

  /**
   * Disable automatically sending a DELETE request when the image is removed. Allows the parent
   * to handle the remove at a different time, such as when the form is submitted, or the
   * dialog is closed.
   */
  disableAutoRemove?: boolean
  togglePosition?: () => void
}

/**
 * Generic Event Asset uploader/picker for images.
 *
 * @param {boolean} canResize Enable or disable support for resizing the viewport area.
 * @param {number} width Determine the width. If undefined, It will be calculated using the viewport
 * @param {number} height Determine the height. If undefined, It will be calculated using the viewport
 *
 * {width, height} an object defining the width and height. If only one dimension is specified, the other will be calculated using the viewport aspect ratio.
 *
 * Refernece: https://foliotek.github.io/Croppie/
 */
export default function ImageAssetUploader(props: ImageAssetUploaderProps) {
  const {onChange, disableAutoRemove: disableDelete, ...selectProps} = props

  const {value} = selectProps
  const [processing, setProcessing] = useState(false)

  const fileUrl = typeof value === 'string' ? value : null
  const current = createFileLocation(fileUrl)

  const fileSelect = useFileSelect(current)
  const {selected, wasRemoved, remove: removeFile} = fileSelect

  const upload = useUploadAsset()
  const remove = useRemoveAsset()

  const handleChange = useCallback(
    (file: File | null) => {
      // If there's NO current file, and NO new file, then we're
      // probably here because we've toggled `processing` state,
      // so no-op required here.
      if (!current && !file) {
        return
      }

      if (processing) {
        return
      }

      setProcessing(true)

      const wasRemoved = current && !file
      if (wasRemoved && disableDelete) {
        onChange('')
        setProcessing(false)
        return
      }

      if (wasRemoved) {
        remove(current)
          .catch(() => {
            // Ignore errors
          })
          .finally(() => {
            // Even if the asset is missing, we'll still clear the value, otherwise
            // we'd be stuck with a missing asset.
            onChange('')
            setProcessing(false)
          })
        return
      }

      if (file) {
        upload(file)
          .then((asset) => {
            onChange(asset.file.url)
          })
          // On error let's remove the selected file to
          // let the user retry
          .catch(removeFile)
          .finally(() => {
            setProcessing(false)
          })
      }
    },
    [
      processing,
      setProcessing,
      current,
      upload,
      remove,
      onChange,
      disableDelete,
      removeFile,
    ],
  )

  useEffect(() => {
    if (selected || wasRemoved) {
      handleChange(selected)
    }
  }, [selected, wasRemoved, handleChange])

  return (
    <ImageSelector
      {...selectProps}
      autoUpload
      disabled={processing}
      onChange={handleChange}
      file={fileSelect}
    />
  )
}

function ImageSelector(
  props: ImageSelectorProps & {
    file: FileSelect
  },
) {
  const {uploadLabel = 'Image', file, additionalActions} = props

  return (
    <Box mb={3}>
      <Label>{props.label}</Label>
      <ImageUpload
        file={file}
        disabled={props.disabled}
        autoUpload={props.autoUpload}
      >
        <Cropper
          width={props.width}
          height={props.height}
          canResize={props.canResize}
        />
        <Image alt={props?.alt || 'uploaded image'} />
        <UploadButton
          inputProps={{
            'aria-label':
              props.uploadInputProps?.['aria-label'] || 'upload image',
          }}
          size="small"
        >
          {uploadLabel}
        </UploadButton>

        <Actions>
          <RemoveImageButton
            aria-label={
              props.removeButtonProps?.['aria-label'] || 'remove image'
            }
            size="small"
          />
          {additionalActions}
        </Actions>
      </ImageUpload>
    </Box>
  )
}

function Label(props: {children?: ImageSelectorProps['label']}) {
  if (!props.children) {
    return null
  }
  return <StyledInputLabel>{props.children}</StyledInputLabel>
}

const StyledInputLabel = styled(InputLabel)`
  margin-bottom: ${(props) => props.theme.spacing[2]};
`

import styled from 'styled-components'
import Page from 'organization/Event/Page'
import CreateQuestionDialog from 'organization/Event/Form/dialog/CreateQuestionDialog'
import FormActions from 'organization/Event/Form/FormActions'
import QuestionsProvider, {
  Question,
  useQuestions,
} from 'organization/Event/QuestionsProvider'

import React, {useEffect, useRef, useState} from 'react'
import QuestionEditDialog from 'organization/Event/Form/dialog/EditQuestionDialog'
import QuestionsList from 'organization/Event/Form/QuestionsList'
import {useForm as useEventForm} from 'organization/Event/Form/FormProvider'
import {Form as FormData} from 'organization/Event/FormsProvider'
import {Controller, useForm as useHookForm} from 'react-hook-form'
import Box from '@material-ui/core/Box'
import ActionSelect from 'Event/ActionsProvider/ActionSelect'
import ActionsProvider from 'Event/ActionsProvider'
import {InfusionsoftTag} from 'Event/infusionsoft'
import InfusionsoftTagInput from 'organization/Event/DashboardConfig/InfusionsoftTagInput'
import {fieldError} from 'lib/form'
import ErrorAlert from 'lib/ui/alerts/ErrorAlert'
import PageHeader from 'lib/ui/PageHeader'
import Title from 'lib/ui/PageHeader/Title'
import PageHeaderButton from 'lib/ui/PageHeader/Button'
import Checkbox from 'lib/ui/Checkbox'
import Button from 'lib/ui/Button'
import InputLabel from 'lib/ui/TextField/InputLabel'
import TextField from 'lib/ui/TextField'
import {useEventRoutes} from 'organization/Event/EventRoutes'
import PageBreadcrumbs from 'organization/Event/Page/PageBreadcrumbs'
import {
  ACTIVE_CAMPAIGN,
  CONVERT_KIT,
  HIGHLEVEL,
  HUBSPOT,
  INFUSIONSOFT,
  ONTRAPORT,
} from 'organization/Event/Services/ServicesProvider'
import {ZAPIER} from 'organization/Event/Services/ServicesProvider'
import {ZapierTag} from 'Event/zapier'
import {MAILCHIMP} from 'organization/Event/Services/ServicesProvider'
import {MailchimpTag} from 'Event/mailchimp'
import {ActiveCampaignTag} from 'Event/activeCampaign'
import {HubspotTag} from 'Event/hubspot'
import HubspotTagInput from 'organization/Event/DashboardConfig/HubspotTagInput'
import ZapierTagInput from 'organization/Event/DashboardConfig/ZapierTagInput'
import MailchimpTagInput from 'organization/Event/DashboardConfig/MailchimpTagInput'
import ActiveCampaignTagInput from 'organization/Event/DashboardConfig/ActiveCampaignTagInput'
import ServiceComponent from 'organization/Event/Services/ServiceComponent'
import {Label} from 'lib/ui/typography'
import HighLevelTagInput from 'organization/Event/DashboardConfig/HighLevelTagInput'
import {HighLevelTag} from 'Event/highlevel'
import FullPageLoader from 'lib/ui/layout/FullPageLoader'
import ConvertKitTagInput from 'organization/Event/DashboardConfig/ConvertKitTagInput'
import {ConvertKitTag} from 'organization/Event/Services/Apps/ConvertKit/Config/Tags'
import OntraportTagInput from 'organization/Event/DashboardConfig/OntraportTagInput'
import {OntraportTag} from 'organization/Event/Services/Apps/Ontraport/Config/Tags'

export const DEFAULT_RESUBMIT_LABEL = 'Edit your answer'
export const DEFAULT_SUBMIT_LABEL = 'Submit'
export const DEFAULT_SUBMITTED_MESSAGE = 'Thank you'

export default function Form() {
  const {form, processing, responseError} = useEventForm()
  const [editing, setEditing] = useState<Question | null>(null)
  const [addQuestionDialogVisible, setAddQuestionDialogVisible] = useState(
    false,
  )
  const formRef = useRef<HTMLFormElement>(null)
  const toggleAddQuestionDialog = () =>
    setAddQuestionDialogVisible(!addQuestionDialogVisible)

  const stopEditing = () => setEditing(null)

  /**
   * Save the form config. We want the question list OUTSIDE of the
   * html form to avoid triggering validation, so this is the
   * workaround to allow that.
   */
  const save = () => {
    if (!formRef.current) {
      return
    }

    formRef.current.dispatchEvent(new Event('submit'))
  }

  return (
    <ActionsProvider
      loader={
        <Content>
          <FullPageLoader />
        </Content>
      }
    >
      <QuestionsProvider form={form}>
        <Content>
          <>
            <CreateQuestionDialog
              isVisible={addQuestionDialogVisible}
              onClose={toggleAddQuestionDialog}
            />
            <QuestionEditDialog question={editing} onClose={stopEditing} />
            <PageHeader>
              <Title text="Edit Form" />
              <PageHeaderButton
                text="Save Changes"
                textCollapsed="Save"
                aria-label="save form"
                disabled={processing}
                onClick={save}
              />
            </PageHeader>
            <ErrorAlert>{responseError?.message}</ErrorAlert>
            <Box mb={2}>
              <FormConfig ref={formRef} setEditing={setEditing} />
            </Box>
            <QuestionsBox>
              <Box mb={2}>
                <Button
                  variant="contained"
                  color="primary"
                  aria-label="add question"
                  onClick={toggleAddQuestionDialog}
                  disabled={processing}
                >
                  Add Question
                </Button>
              </Box>
              <QuestionsList onSelect={setEditing} />
            </QuestionsBox>
            <StyledFormActions />
          </>
        </Content>
      </QuestionsProvider>
    </ActionsProvider>
  )
}

const FormConfig = React.forwardRef<
  HTMLFormElement,
  {
    setEditing: (question: Question | null) => void
  }
>((_props, ref) => {
  const {
    handleSubmit,
    control,
    errors: formErrors,
    register,
    watch,
  } = useHookForm()
  const {form, update, processing, responseError} = useEventForm()
  const {questions} = useQuestions()

  const {
    infusionsoftTag,
    setInfusionsoftTag,
    hubspotTag,
    setHubspotTag,
    zapierTag,
    setZapierTag,
    highLevelTag,
    setHighLevelTag,
    mailchimpTag,
    setMailchimpTag,
    activeCampaignTag,
    setActiveCampaignTag,
    convertKitTag,
    setConvertKitTag,
    ontraportTag,
    setOntraportTag,
  } = useFormTags(form)

  const submit = (data: Partial<FormData>) => {
    const withAttributes: Partial<FormData> = {
      ...data,
      questions: [...questions],
      tags: createTags({
        infusionsoftTag,
        highLevelTag,
        activeCampaignTag,
        mailchimpTag,
        zapierTag,
        hubspotTag,
        convertKitTag,
        ontraportTag,
      }),
    }

    update(withAttributes)
  }

  const error = (key: keyof FormData) =>
    fieldError(key, {
      response: responseError,
      form: formErrors,
    })

  const nameError = error('name')
  const onSubmitRedirectUrlError = error('on_submit_redirect_url')
  const submissionWebhookUrlError = error('submission_webhook_url')
  const submitLabelError = error('submit_label')
  const submittedMessageError = error('submitted_message')
  const resubmitButtonLabelError = error('resubmit_button_label')

  const canResubmit = watch('can_resubmit', form.can_resubmit)
  const allowsMultipleSubmissions = watch(
    'allows_multiple_submissions',
    form.allows_multiple_submissions,
  )

  return (
    <>
      <form onSubmit={handleSubmit(submit)} ref={ref}>
        <InputLabel>Form Name</InputLabel>
        <TextField
          aria-label="form name"
          name="name"
          defaultValue={form.name}
          inputProps={{
            ref: register,
          }}
          variant="outlined"
          required
          placeholder="Type form name..."
          fullWidth
          disabled={processing}
          helperText={nameError}
          error={Boolean(nameError)}
        />
        <StyledBox>
          <Controller
            control={control}
            name="can_resubmit"
            defaultValue={form.can_resubmit || false}
            render={({onChange, value}) => (
              <Checkbox
                checked={!!value}
                label="Can Edit Answer?"
                aria-label="toggle can re-submit"
                disabled={processing || allowsMultipleSubmissions}
                onChange={onChange}
              />
            )}
          />
        </StyledBox>
        <StyledBox>
          <Controller
            control={control}
            name="allows_multiple_submissions"
            defaultValue={form.allows_multiple_submissions}
            render={({onChange, value}) => (
              <Checkbox
                checked={!!value}
                label="Allow Multiple Submissions?"
                aria-label="toggle allow multiple submissions"
                disabled={processing || canResubmit}
                onChange={onChange}
              />
            )}
          />
        </StyledBox>

        <InputLabel disabled={allowsMultipleSubmissions || !canResubmit}>
          Resubmit Button Label
        </InputLabel>
        <TextField
          variant="outlined"
          aria-label="resubmit button label"
          name="resubmit_button_label"
          inputProps={{
            ref: register,
          }}
          defaultValue={form.resubmit_button_label}
          placeholder="Type resubmit button label..."
          fullWidth
          disabled={processing || allowsMultipleSubmissions || !canResubmit}
          helperText={resubmitButtonLabelError}
          error={Boolean(resubmitButtonLabelError)}
        />

        <InputLabel>Submit Label</InputLabel>
        <TextField
          variant="outlined"
          aria-label="form submit label"
          name="submit_label"
          defaultValue={form.submit_label}
          inputProps={{
            ref: register,
          }}
          placeholder="Type submit label..."
          fullWidth
          disabled={processing}
          helperText={submitLabelError}
          error={Boolean(submitLabelError)}
        />

        <InputLabel disabled={canResubmit}>Submitted Message</InputLabel>
        <TextField
          aria-label="form submitted message"
          name="submitted_message"
          defaultValue={form.submitted_message}
          variant="outlined"
          placeholder="Type submitted message..."
          fullWidth
          disabled={processing || canResubmit}
          inputProps={{
            ref: register,
          }}
          helperText={
            submittedMessageError ||
            'Message displayed when form has been submitted'
          }
          error={Boolean(submittedMessageError)}
        />

        <InputLabel>Redirect URL (optional)</InputLabel>
        <TextField
          variant="outlined"
          aria-label="redirect url after submit"
          name="on_submit_redirect_url"
          defaultValue={form.on_submit_redirect_url || ''}
          placeholder="Type redirect url after submit..."
          fullWidth
          inputProps={{
            ref: register,
          }}
          disabled={processing}
          helperText={
            onSubmitRedirectUrlError ||
            'URL to redirect to after completing form. Starting with https:// or http://.'
          }
          error={Boolean(onSubmitRedirectUrlError)}
        />

        <InputLabel>Submission Webhook URL (optional)</InputLabel>
        <TextField
          variant="outlined"
          aria-label="submission webhook url"
          name="submission_webhook_url"
          defaultValue={form.submission_webhook_url || ''}
          inputProps={{
            ref: register,
          }}
          placeholder="Type submission webhook URL..."
          fullWidth
          disabled={processing}
          helperText={
            submissionWebhookUrlError ||
            'Webhook URL to send submissions. Starting with https:// or http://.'
          }
          error={Boolean(submissionWebhookUrlError)}
        />

        <StyledBox>
          <Controller
            control={control}
            name="action_id"
            defaultValue={form.action?.id || ''}
            render={({onChange, value}) => (
              <ActionSelect
                value={value}
                onChange={onChange}
                useId
                disabled={processing}
              />
            )}
          />
        </StyledBox>
      </form>
      <ServiceComponent service={INFUSIONSOFT}>
        <div>
          <StyledLabel>Infusionsoft tag</StyledLabel>
          <InfusionsoftTagInput
            value={infusionsoftTag}
            onChange={setInfusionsoftTag}
            disabled={processing}
            autoSet
          />
        </div>
      </ServiceComponent>
      <ServiceComponent service={HUBSPOT}>
        <div>
          <StyledLabel>Hubspot tag</StyledLabel>
          <HubspotTagInput
            value={hubspotTag}
            onChange={setHubspotTag}
            disabled={processing}
          />
        </div>
      </ServiceComponent>
      <ServiceComponent service={ZAPIER}>
        <div>
          <StyledLabel>Zapier tag</StyledLabel>
          <ZapierTagInput
            value={zapierTag}
            onChange={setZapierTag}
            disabled={processing}
            variant="outlined"
            helperText=""
          />
        </div>
      </ServiceComponent>

      <ServiceComponent service={HIGHLEVEL}>
        <div>
          <StyledLabel>High level tag</StyledLabel>
          <HighLevelTagInput
            value={highLevelTag}
            onChange={setHighLevelTag}
            disabled={processing}
            variant="outlined"
            helperText=""
          />
        </div>
      </ServiceComponent>

      <ServiceComponent service={ACTIVE_CAMPAIGN}>
        <div>
          <StyledLabel>Active Campaign tag</StyledLabel>
          <ActiveCampaignTagInput
            value={activeCampaignTag}
            onChange={setActiveCampaignTag}
            disabled={processing}
          />
        </div>
      </ServiceComponent>
      <ServiceComponent service={CONVERT_KIT}>
        <ConvertKitTagInput
          value={convertKitTag}
          onChange={setConvertKitTag}
          disabled={processing}
        />
      </ServiceComponent>
      <ServiceComponent service={MAILCHIMP}>
        <div>
          <StyledLabel>Mailchimp tag</StyledLabel>
          <MailchimpTagInput
            value={mailchimpTag}
            onChange={setMailchimpTag}
            disabled={processing}
            variant="outlined"
          />
        </div>
      </ServiceComponent>
      <ServiceComponent service={ONTRAPORT}>
        <OntraportTagInput
          value={ontraportTag}
          onChange={setOntraportTag}
          disabled={processing}
        />
      </ServiceComponent>
    </>
  )
})

function Content(props: {children: JSX.Element}) {
  const routes = useEventRoutes()
  const {form} = useEventForm()

  return (
    <PageBreadcrumbs
      parent={{
        title: 'Forms',
        url: routes.forms.root,
      }}
      page={form.name}
    >
      <Page>{props.children}</Page>
    </PageBreadcrumbs>
  )
}

function useFormTags(form: FormData) {
  const [
    activeCampaignTag,
    setActiveCampaignTag,
  ] = useState<ActiveCampaignTag | null>(null)
  const [
    infusionsoftTag,
    setInfusionsoftTag,
  ] = useState<InfusionsoftTag | null>(null)
  const [zapierTag, setZapierTag] = useState<ZapierTag | null>(null)
  const [mailchimpTag, setMailchimpTag] = useState<MailchimpTag | null>(null)
  const [hubspotTag, setHubspotTag] = useState<HubspotTag | null>(null)
  const [highLevelTag, setHighLevelTag] = useState<HighLevelTag | null>(null)
  const [convertKitTag, setConvertKitTag] = useState<ConvertKitTag | null>(null)
  const [ontraportTag, setOntraportTag] = useState<OntraportTag | null>(null)

  useEffect(() => {
    const {tags} = form

    setInfusionsoftTag(null)

    if (tags?.infusionsoft?.infusionsoft_id && tags.infusionsoft.name) {
      setInfusionsoftTag({
        id: tags.infusionsoft.infusionsoft_id,
        name: tags.infusionsoft.name,
      })
    }

    if (tags?.zapier && tags.zapier.name) {
      setZapierTag({
        name: tags.zapier.name,
      })
    }

    if (tags?.highlevel && tags.highlevel.name) {
      setHighLevelTag({
        name: tags.highlevel.name,
      })
    }

    if (tags?.mailchimp?.name) {
      setMailchimpTag({
        name: tags.mailchimp.name,
      })
    }

    if (tags?.activecampaign?.active_campaign_id && tags.activecampaign.name) {
      setActiveCampaignTag({
        id: tags.activecampaign.active_campaign_id,
        tag: tags.activecampaign.name,
      })
    }

    if (tags?.hubspot) {
      setHubspotTag(tags.hubspot)
    }

    if (tags?.convertkit?.id && tags.convertkit.name) {
      setConvertKitTag({
        id: tags.convertkit.id,
        name: tags.convertkit.name,
      })
    }

    if (tags?.ontraport && tags.ontraport.tag_id && tags.ontraport.tag_name) {
      setOntraportTag(tags.ontraport)
    }
  }, [form])

  return {
    infusionsoftTag,
    setInfusionsoftTag,
    activeCampaignTag,
    setActiveCampaignTag,
    zapierTag,
    setZapierTag,
    highLevelTag,
    setHighLevelTag,
    mailchimpTag,
    setMailchimpTag,
    hubspotTag,
    setHubspotTag,
    convertKitTag,
    setConvertKitTag,
    ontraportTag,
    setOntraportTag,
  }
}

function createTags(props: {
  zapierTag: ZapierTag | null
  highLevelTag: HighLevelTag | null
  infusionsoftTag: InfusionsoftTag | null
  mailchimpTag: MailchimpTag | null
  hubspotTag: HubspotTag | null
  activeCampaignTag: ActiveCampaignTag | null
  convertKitTag: ConvertKitTag | null
  ontraportTag: OntraportTag | null
}): FormData['tags'] {
  const result: FormData['tags'] = {}

  const {
    zapierTag,
    highLevelTag,
    infusionsoftTag,
    mailchimpTag,
    hubspotTag,
    activeCampaignTag,
    convertKitTag,
    ontraportTag,
  } = props

  if (zapierTag) {
    result['zapier'] = {
      name: zapierTag.name,
    }
  }

  if (highLevelTag) {
    result['highlevel'] = {
      name: highLevelTag.name,
    }
  }

  if (infusionsoftTag) {
    result['infusionsoft'] = {
      infusionsoft_id: infusionsoftTag.id,
      name: infusionsoftTag.name,
    }
  }

  if (infusionsoftTag) {
    result['infusionsoft'] = {
      infusionsoft_id: infusionsoftTag.id,
      name: infusionsoftTag.name,
    }
  }

  if (mailchimpTag) {
    result['mailchimp'] = {
      name: mailchimpTag.name,
    }
  }

  if (hubspotTag) {
    result['hubspot'] = {
      property_label: hubspotTag.property_label,
      property_name: hubspotTag.property_name,
      property_type: hubspotTag.property_type,
      property_value: hubspotTag.property_value,
    }
  }

  if (activeCampaignTag) {
    result['activecampaign'] = {
      active_campaign_id: activeCampaignTag.id,
      name: activeCampaignTag.tag,
    }
  }

  if (convertKitTag) {
    result['convertkit'] = {
      id: convertKitTag.id,
      name: convertKitTag.name,
    }
  }

  if (ontraportTag) {
    result['ontraport'] = ontraportTag
  }

  if (Object.keys(result).length === 0) {
    return null
  }

  return result
}

const StyledFormActions = styled(FormActions)`
  margin-bottom: ${(props) => props.theme.spacing[4]};
`

const StyledBox = styled.div`
  margin-bottom: ${(props) => props.theme.spacing[6]};
  width: 300px;
`

const QuestionsBox = styled.div`
  margin-bottom: ${(props) => props.theme.spacing[8]};
  max-width: 640px;
`

const StyledLabel = styled(Label)`
  margin-bottom: ${(props) => props.theme.spacing[4]} !important;
`

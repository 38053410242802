import TextField from '@material-ui/core/TextField'
import {QuestionTypeConfigProps} from 'organization/Event/Form/dialog/Form/QuestionTypeConfig'
import {isTextQuestion} from 'organization/Event/QuestionsProvider'
import React from 'react'

export default function CharacterLimitInputs(props: QuestionTypeConfigProps) {
  const {question, register} = props

  return (
    <>
      <TextField
        label="Character Limit"
        fullWidth
        variant="outlined"
        name="character_limit"
        type="number"
        defaultValue={isTextQuestion(question) ? question.character_limit : ''}
        inputProps={{
          ref: register,
          'aria-label': 'character limit',
        }}
      />
      <TextField
        label="Limit Error Message"
        fullWidth
        variant="outlined"
        name="character_limit_error_message"
        defaultValue={
          isTextQuestion(question) ? question.character_limit_error_message : ''
        }
        inputProps={{
          ref: register,
          'aria-label': 'limit error message',
        }}
      />
    </>
  )
}

import React, {useState} from 'react'
import HubspotPropertiesProvider from 'organization/Event/Services/Apps/Hubspot/Config/HubspotPropertiesProvider'
import Page from 'organization/Event/Page'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import {
  useHubspot,
  useServices,
} from 'organization/Event/Services/ServicesProvider'
import {
  ATTENDEE_CHECKED_IN,
  ATTENDEE_CREATED,
  ATTENDEE_SIGNED_WAIVER,
  HubspotGroup,
  HubspotIntegration,
  HubspotPlatformTag,
} from 'organization/Event/Services/Apps/Hubspot/index'
import GroupsConfig from 'organization/Event/Services/Apps/Hubspot/Config/GroupsConfig'
import {Property} from 'organization/Event/Services/Apps/Hubspot/Config/HubspotPropertiesProvider'
import withStyles from '@material-ui/core/styles/withStyles'
import Button from 'lib/ui/Button'
import {useOrganization} from 'organization/OrganizationProvider'
import {useEvent} from 'Event/EventProvider'
import {api} from 'lib/url'
import PlatformTagInput from 'organization/Event/Services/Apps/Hubspot/Config/PlatformTagInput'
import FieldInput from 'organization/Event/Services/Apps/Hubspot/Config/FieldInput.tsx'
import styled from 'styled-components'
import InfoAlert from 'lib/ui/alerts/InfoAlert'
import Grid from '@material-ui/core/Grid'
import SyncSwitch from 'organization/Event/Services/Apps/Hubspot/Config/SyncSwitch'
import PropertySelector from 'organization/Event/Services/Apps/Hubspot/Config/PropertySelector'
import ListSelector from 'organization/Event/Services/Apps/Hubspot/Config/ListSelector'
import HubspotListsProvider, {
  HubspotList,
} from 'organization/Event/Services/Apps/Hubspot/Config/HubspotListsProvider'

export interface Data {
  tags: HubspotPlatformTag[]
  groups: HubspotGroup[]
  login_property: {
    label: string
    name: string
    type: string
  }
  tags_property: {
    label: string
    name: string
    type: string
  }
  phone_property: {
    label: string
    name: string
    type: string
  }
  import_list: {
    listId: number
    name: string
  }
}

export type TargetProperty = Pick<Property, 'name' | 'label' | 'type'>
export type ImportProperty = TargetProperty & {value: string | null}

export default function Config() {
  return (
    <HubspotPropertiesProvider>
      <HubspotListsProvider>
        <Content />
      </HubspotListsProvider>
    </HubspotPropertiesProvider>
  )
}

function Content() {
  const hubspot = useHubspot()
  const {save, processing} = useSave()
  const [successMessage, setSuccessMessage] = useState<null | string>(null)
  const clearSuccessMessage = () => setSuccessMessage(null)

  const [loginProperty, setLoginProperty] = useState<TargetProperty | null>({
    name: hubspot.login_property_name,
    label: hubspot.login_property_label,
    type: hubspot.login_property_type || 'text',
  })

  const [importList, setImportList] = useState<HubspotList | null>({
    name: hubspot.import_list_name,
    listId: hubspot.import_list_id,
  })

  const [tagsProperty, setTagsProperty] = useState<TargetProperty | null>({
    name: hubspot.tags_property_name,
    label: hubspot.tags_property_label,
    type: hubspot.tags_property_type || 'text',
  })

  const [phoneProperty, setPhoneProperty] = useState<TargetProperty | null>({
    name: hubspot.phone_property_name || 'phone',
    label: hubspot.phone_property_label || 'Phone Number',
    type: hubspot.phone_property_type || 'phonenumber',
  })

  const [groups, setGroups] = useState<HubspotGroup[]>(hubspot.groups)
  const [tags, setTags] = useState<HubspotPlatformTag[]>(hubspot.tags)
  const [errors, setErrors] = useState<Array<string>>([])

  const getTag = (type: HubspotPlatformTag['type']) => {
    const target = tags.find((t) => t.type === type)
    if (!target) {
      throw new Error(`Unknown tag type: ${type}`)
    }

    return target
  }

  const filledGroups = groups.filter((g) => g.key && g.property_name)

  const handleSave = () => {
    let errorsCheck: Array<string> = []

    if (!loginProperty?.name) {
      errorsCheck.push('loginProperty')
    }

    if (!importList?.name) {
      errorsCheck.push('importList')
    }

    setErrors(errorsCheck)

    if (errorsCheck.length > 0) {
      return
    }

    const data: Data = {
      tags,
      groups: filledGroups,
      tags_property: {
        label: tagsProperty?.label || '',
        name: tagsProperty?.name || '',
        type: tagsProperty?.type || '',
      },
      phone_property: {
        label: phoneProperty?.label || '',
        name: phoneProperty?.name || '',
        type: phoneProperty?.type || '',
      },
      login_property: {
        label: loginProperty?.label || '',
        name: loginProperty?.name || '',
        type: loginProperty?.type || '',
      },
      import_list: {
        listId: importList?.listId || 0,
        name: importList?.name || '',
      },
    }

    save(data)
  }

  const updateTag = (tag: HubspotPlatformTag) => {
    const updated = tags.map((t) => (t.id === tag.id ? tag : t))
    setTags(updated)
  }

  return (
    <Page>
      <StyledSuccessAlert onClose={clearSuccessMessage}>
        {successMessage}
      </StyledSuccessAlert>

      <Box mb={3}>
        <Grid container spacing={2}>
          <Grid item md={6}>
            <Typography variant="h4">Hubspot</Typography>
          </Grid>
          <StyledGrid item md={6}>
            <SyncSwitch />
          </StyledGrid>
        </Grid>
      </Box>

      <Box mb={2}>
        <Typography>Hub ID: {hubspot.portal_id}</Typography>
      </Box>
      <Box mb={2}>
        <Typography variant="h5">Login URL Property</Typography>
        <em>
          Select the Contact Property in Hubspot to save your attendee's Login
          URLs.
        </em>
      </Box>
      <PropertySelector
        label={loginProperty?.label}
        value={loginProperty}
        targetTypes={['text']}
        onChange={setLoginProperty}
        errorText={
          errors.includes('loginProperty') ? 'Login Property is required' : ''
        }
      />
      <Box mb={2}>
        <Typography variant="h5">Attendee Import List</Typography>
        <em>Select the List In Hubspot that contains your attendees.</em>
      </Box>
      <ListSelector
        value={importList}
        onChange={setImportList}
        errorText={
          errors.includes('importList') ? 'Import List is required' : ''
        }
      />

      <Box mb={2}>
        <Typography variant="h5">Action tags</Typography>
        <em>
          Obvio can apply a specific contact property for the following 3
          actions that take place within Obvio. These action tags are optional.
        </em>
      </Box>
      <PlatformTagInput value={getTag(ATTENDEE_CREATED)} onChange={updateTag} />
      <PlatformTagInput
        value={getTag(ATTENDEE_CHECKED_IN)}
        onChange={updateTag}
      />
      <PlatformTagInput
        value={getTag(ATTENDEE_SIGNED_WAIVER)}
        onChange={updateTag}
      />
      <Box mb={2}>
        <Typography variant="h5">Attendee Tags Property</Typography>
        <em>
          Obvio can import one Contact Property from Hubspot that is a multiple
          choice property as tags within Obvio. This is optional.
        </em>
      </Box>
      <FieldInput tagsProperty={tagsProperty} onChange={setTagsProperty} />

      <Box mb={2}>
        <Typography variant="h5">Attendee Phone Property</Typography>
        <em>
          Obvio can import one Contact Property from Hubspot that is a multiple
          choice property as tags within Obvio. This is optional.
        </em>
      </Box>
      <FieldInput tagsProperty={phoneProperty} onChange={setPhoneProperty} />

      <Box mb={2}>
        <Typography variant="h5">Attendee Groups Properties</Typography>
        <em>
          Obvio can import Contact Properties from Hubspot as groups within
          Obvio. You can import multiple properties by adding multiple groups.
          This is optional.
        </em>
      </Box>
      <GroupsConfig onChange={setGroups} groups={groups} />

      <SaveButton
        type="submit"
        variant="contained"
        color="primary"
        size="large"
        aria-label="save"
        onClick={handleSave}
        disabled={processing}
        fullWidth
      >
        Save
      </SaveButton>
    </Page>
  )
}

function useSave() {
  const {client} = useOrganization()
  const {event} = useEvent()

  const [processing, setProcessing] = useState<boolean>(false)
  const {update: updateIntegration} = useServices()
  const [error, setError] = useState<string | null>(null)

  const save = (data: Data) => {
    setProcessing(true)
    client
      .put<HubspotIntegration>(
        api(`/events/${event.id}/integrations/hubspot`),
        data,
      )
      .then(updateIntegration)
      .catch((e) => setError(e.message))
      .finally(() => {
        setProcessing(false)
      })
  }

  return {
    processing,
    error,
    save,
  }
}

const StyledGrid = withStyles({
  root: {
    display: 'flex',
    justifyContent: 'end',
  },
})(Grid)

const StyledSuccessAlert = styled(InfoAlert)`
  margin-bottom: ${(props) => props.theme.spacing[5]};
`

const SaveButton = styled(Button)`
    marginTop: ${(props) => props.theme.spacing[5]},
    marginBottom: ${(props) => props.theme.spacing[5]},
`

import React from 'react'
import Settings from 'Event/template/Townhall/Dashboard/ActionNav/ActionNavConfig/Settings'
import Styling from 'Event/template/Townhall/Dashboard/ActionNav/ActionNavConfig/Styling'

import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import StylingPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/StylingPanel'
import {useSaveTemplate} from 'Event/TemplateUpdateProvider'
import Config from 'organization/Event/Configurable/Config'

export default function ActionNavConfig() {
  const save = useSaveTemplate()

  return (
    <Config title="Action Nav" onSave={save}>
      <SettingsPanel>
        <Settings />
      </SettingsPanel>
      <StylingPanel>
        <Styling />
      </StylingPanel>
    </Config>
  )
}

import React from 'react'
import styled from 'styled-components'
import Container from '@material-ui/core/Container'
import {User} from 'auth/user'
import Footer from 'Event/template/Cards/Dashboard/Footer'
import {useCardsTemplate} from 'Event/template/Cards'
import {rgba} from 'lib/color'
import LanguageSelector from 'Event/LanguageSelector'
import {muiTheme} from 'lib/ui/theme'
import {ThemeProvider} from '@material-ui/core/styles'
import {withStyles} from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import {Logo, useRandomBackground} from 'Event/template/Cards/Login/Page'
import {TopBox} from 'Event/template/Cards/Dashboard/Page'
import {useLoadFont} from 'lib/FontSelect'
import Configurable from 'organization/Event/Configurable'
import ContentPanelConfig from 'Event/template/Cards/check-in/ContentPanelConfig'

export default function CheckInPage(props: {
  user: User
  children: React.ReactElement | React.ReactElement[]
}) {
  const template = useCardsTemplate()

  const {background} = template

  const dashboardBackground = useRandomBackground()

  const backgroundImage = dashboardBackground ? dashboardBackground.image : null
  const backgroundPosition = dashboardBackground
    ? dashboardBackground.position
    : null

  const backgroundRGBColor = rgba(background.color, background.opacity / 100)

  const color = template.textColor
  const linkUnderline = template.linkUnderline
  const linkColor = template.linkColor
  const font = template.font

  const contentBackgroundColor = rgba(
    template.checkIn.contentPanel.backgroundColor,
    template.checkIn.contentPanel.backgroundOpacity / 100,
  )
  const contentTextcolor = template.checkIn.contentPanel.textColor

  useLoadFont(font)

  return (
    <ThemeProvider theme={muiTheme}>
      <TopBox
        backgroundImage={backgroundImage}
        backgroundPosition={backgroundPosition}
        color={color}
        linkColor={linkColor}
        linkUnderline={linkUnderline}
        position={template.backgroundPosition}
        font={font}
        fullHeight
      >
        <ColorOverlay color={backgroundRGBColor}>
          <Content>
            <Logo />
            <StyledContainer maxWidth="lg">
              <Configurable>
                <ContentPanelConfig />
                <StyledPaper
                  color={contentTextcolor}
                  backgroundColor={contentBackgroundColor}
                >
                  {props.children}
                </StyledPaper>
              </Configurable>
            </StyledContainer>
          </Content>
          <LanguageSelector />
          <Footer />
        </ColorOverlay>
      </TopBox>
    </ThemeProvider>
  )
}

const Content = styled.div`
  flex: 1;
  width: 100%;
  justify-content: center;
  display: flex;
  flex-direction: column;
  @media (min-width: ${(props) => props.theme.breakpoints.lg}) {
    display: block;
  }
`
const ColorOverlay = styled.div<{
  color: string
}>`
  background-color: ${(props) => props.color};
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
`

const StyledContainer = withStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingBottom: '50px',
  },
})(Container)

const StyledPaper = styled(
  ({backgroundColor: _backgroundColor, color: _color, ...otherProps}) => (
    <Paper {...otherProps} />
  ),
)<{
  backgroundColor: string
  color: string
}>`
  padding: ${(props) => props.theme.spacing[10]};
  background-color: ${(props) => props.backgroundColor};
  color: ${(props) => (props.color ? props.color : 'inherit')} !important;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  width: 100%;
`

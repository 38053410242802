import {Button, NavButtonProps} from 'Event/Dashboard/components/NavButton'
import ConfirmDialog from 'Event/Dashboard/components/NavButton/ConfirmedButton/ConfirmDialog'
import {useEditMode} from 'Event/EditModeProvider'
import React from 'react'

type ConfirmButtonContextProps = {
  key: string // template key
  button: NavButtonProps
}

export const ConfirmedButtonContext = React.createContext<
  ConfirmButtonContextProps | undefined
>(undefined)

export default function ConfirmedButton(
  props: NavButtonProps & {onConfirm: () => void},
) {
  const {onConfirm, ...buttonProps} = props
  const isEditMode = useEditMode()

  return (
    <ConfirmDialog
      onConfirm={() => {
        // do NOT want to trigger an action while in edit mode
        if (isEditMode) {
          return
        }

        onConfirm()
      }}
      {...buttonProps}
    >
      {(showConfirmation) => (
        <Button
          {...buttonProps}
          onClick={() => {
            showConfirmation()
          }}
        />
      )}
    </ConfirmDialog>
  )
}

export function useConfirmedButton() {
  const context = React.useContext(ConfirmedButtonContext)
  if (context === undefined) {
    throw new Error(
      'useConfirmedButton should be used within a ConfirmedButtonContext',
    )
  }

  return context
}

import React, {useEffect, useState} from 'react'
import ComponentConfig, {
  ComponentConfigProps,
  SaveButton,
  RemoveButton,
  Footer,
} from 'organization/Event/DashboardConfig/ComponentConfigPanel'
import {useForm} from 'react-hook-form'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import StylingPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/StylingPanel'
import RulesPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/RulesPanel'
import Settings from 'Event/Sponsors/Buttons/SponsorButtonConfig/Settings'
import Styling from 'Event/Sponsors/Buttons/SponsorButtonConfig/Styling'
import ScheduleInput from 'organization/Event/DashboardConfig/ComponentConfigPanel/ScheduleInput'
import {NavButtonProps} from 'Event/Dashboard/components/NavButton'

export default function SponsorButtonConfig(
  props: ComponentConfigProps & {
    button: NavButtonProps
    onChange: (button: NavButtonProps) => void
    onRemove: () => void
    id?: string
  },
) {
  const {showing: visible, onClose, button, onChange, onRemove} = props

  const {
    control,
    handleSubmit,
    watch,
    register,
    setValue,
    formState,
  } = useForm()

  const [rules, setRules] = useState(button?.rules)

  useEffect(() => {
    setRules(button.rules)
  }, [button])

  const submit = (form: NavButtonProps) => {
    const data: NavButtonProps = {
      ...form,
      rules,
    }

    onChange(data)
    onClose()
  }

  return (
    <ComponentConfig
      showing={visible}
      onClose={onClose}
      title="Sponsor Button"
      onSubmit={handleSubmit(submit)}
      hasChanges={formState.isDirty}
    >
      <SettingsPanel>
        <Settings
          control={control}
          register={register}
          watch={watch}
          button={button}
          setValue={setValue}
        />
      </SettingsPanel>
      <StylingPanel>
        <Styling control={control} watch={watch} button={button} />
      </StylingPanel>
      <RulesPanel rules={rules} setRules={setRules}>
        <ScheduleInput control={control} setValue={setValue} values={button} />
      </RulesPanel>
      <Footer>
        <SaveButton type="submit" />
        <RemoveButton
          aria-label="remove button"
          onClick={onRemove}
          showing={Boolean(props.id)}
        />
      </Footer>
    </ComponentConfig>
  )
}

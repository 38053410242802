import {useRoom} from 'organization/Event/Room/RoomProvider'
import React from 'react'
import {useArea} from 'organization/Event/Area/AreaProvider'
import styled from 'styled-components'
import Button from 'lib/ui/Button'

export default function PauseButton(props: {
  processing: boolean
  numAttendees?: number
}) {
  const {room, setPaused, processing} = useRoom()
  const {area} = useArea()
  const showing =
    props.numAttendees !== 0 && area.is_tech_check && room.is_online

  if (!showing) {
    return null
  }

  if (room.is_paused) {
    return (
      <StyledUnPauseButton
        variant="contained"
        color="warning"
        aria-label="unpause room"
        disabled={processing}
        onClick={() => {
          setPaused(false)
        }}
      >
        Unpause Room
      </StyledUnPauseButton>
    )
  }

  return (
    <StyledPauseButton
      variant="outlined"
      color="warning"
      aria-label="pause room"
      disabled={processing}
      onClick={() => {
        setPaused(true)
      }}
    >
      Pause Room
    </StyledPauseButton>
  )
}

const StyledPauseButton = styled(Button)`
  margin-left: ${(props) => props.theme.spacing[3]};
`

const StyledUnPauseButton = styled(Button)`
  margin-left: ${(props) => props.theme.spacing[3]};
`

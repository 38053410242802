import {
  useSimpleBlogTemplate,
  useSimpleBlogUpdate,
} from 'Event/template/SimpleBlog'
import ColorPicker from 'lib/ui/ColorPicker'
import TextField from 'lib/ui/TextField'
import {useConfig} from 'organization/Event/Configurable'
import Config from 'organization/Event/Configurable/Config'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import StylingPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/StylingPanel'
import React from 'react'
import {Controller} from 'react-hook-form'

export default function DashboardLinkConfig() {
  const save = useSimpleBlogUpdate()
  const {speakers} = useSimpleBlogTemplate()

  const {
    form: {control, register},
  } = useConfig()

  return (
    <Config title="Dashboard Link" onSave={save}>
      <SettingsPanel>
        <TextField
          defaultValue={speakers.backToDashboardText}
          name="speakers.backToDashboardText"
          label="Back to Dashboard Text"
          fullWidth
          inputProps={{
            ref: register,
            'aria-label': 'back to dashboard text',
          }}
        />
      </SettingsPanel>
      <StylingPanel>
        <Controller
          name="speakers.backToDashboardTextColor"
          defaultValue={speakers.backToDashboardTextColor}
          control={control}
          render={({onChange, value}) => (
            <ColorPicker
              label="Back to Dashboard Text Color"
              color={value}
              onPick={onChange}
              aria-label="text color"
            />
          )}
        />
      </StylingPanel>
    </Config>
  )
}

import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import ButtonView from '@ckeditor/ckeditor5-ui/src/button/buttonview';
import clickOutsideHandler from '@ckeditor/ckeditor5-ui/src/bindings/clickoutsidehandler';
import CustomButtonLinkFormView from './custombuttonlinkformview';
import ContextualBalloon from '@ckeditor/ckeditor5-ui/src/panel/balloon/contextualballoon';
import textAlternativeIcon from '../theme/link.svg';
import { repositionContextualBalloon, getBalloonPositionData, getSelectedCustomButtonWidget } from '../utils';

export default class CustomButtonLinkUI extends Plugin {
	static get requires() {
		return [ ContextualBalloon ];
	}

	static get pluginName() {
		return 'CustomButtonLinkUI';
	}

	init() {
		this._createButton();
		this.formView = this._createForm();
	}

	destroy() {
		super.destroy();
		this.formView.destroy();
	}

	_createButton() {
		const editor = this.editor;
		const t = editor.t;

		editor.ui.componentFactory.add( 'customButtonLink', locale => {
			const view = new ButtonView( locale );

			view.set( {
				label: t( 'Button Link' ),
				icon: textAlternativeIcon,
				tooltip: true
			} );

			this.listenTo( view, 'execute', () => {
				this._showForm();
			} );

			return view;
		} );
	}

	_createForm() {
		const editor = this.editor;
		const view = editor.editing.view;
		const viewDocument = view.document;

		this._balloon = this.editor.plugins.get( 'ContextualBalloon' );

		const formView = new CustomButtonLinkFormView( editor.locale );

		// this._form.render();

		this.listenTo( formView, 'submit', () => {
			editor.execute( 'customButtonLink', {
				newValue: formView.labeledInput.fieldView.element.value
			} );

			this._hideForm( true );
		} );

		this.listenTo( formView, 'cancel', () => {
			this._hideForm( true );
		} );

		// Close the form on Esc key press.
		formView.keystrokes.set( 'Esc', ( data, cancel ) => {
			this._hideForm( true );
			cancel();
		} );

		// Reposition the balloon or hide the form if an image widget is no longer selected.
		this.listenTo( editor.ui, 'update', () => {
			if ( !getSelectedCustomButtonWidget( viewDocument.selection ) ) {
				this._hideForm( true );
			} else if ( this._isVisible ) {
				repositionContextualBalloon( editor );
			}
		} );

		// Close on click outside of balloon panel element.
		clickOutsideHandler( {
			emitter: formView,
			activator: () => this._isVisible,
			contextElements: [ this._balloon.view.element ],
			callback: () => this._hideForm()
		} );

		return formView;
	}

	/**
      * Shows the {@link #_form} in the {@link #_balloon}.
      *
      * @private
      */
	_showForm() {
		if ( this._isVisible ) {
			return;
		}

		const editor = this.editor;
		const command = editor.commands.get( 'customButtonLink' );
		const labeledInput = this.formView.labeledInput;

		if ( !this._isInBalloon ) {
			this._balloon.add( {
				view: this.formView,
				position: getBalloonPositionData( editor )
			} );
		}

		// Make sure that each time the panel shows up, the field remains in sync with the value of
		// the command. If the user typed in the input, then canceled the balloon (`labeledInput#value`
		// stays unaltered) and re-opened it without changing the value of the command, they would see the
		// old value instead of the actual value of the command.
		// https://github.com/ckeditor/ckeditor5-image/issues/114
		labeledInput.fieldView.value = labeledInput.fieldView.element.value = command.value || 'https://';

		this.formView.labeledInput.fieldView.select();
	}

	_hideForm( focusEditable ) {
		if ( !this._isInBalloon ) {
			return;
		}

		// Blur the input element before removing it from DOM to prevent issues in some browsers.
		// See https://github.com/ckeditor/ckeditor5/issues/1501.
		if ( this.formView.focusTracker.isFocused ) {
			this.formView.saveButtonView.focus();
		}

		this._balloon.remove( this.formView );

		if ( focusEditable ) {
			this.editor.editing.view.focus();
		}
	}

	get _isVisible() {
		return this._balloon.visibleView === this.formView;
	}

	get _isInBalloon() {
		return this._balloon.hasView( this.formView );
	}
}


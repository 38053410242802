import Container from 'Event/Marketplace/Block/Container'

import React from 'react'
import styled from 'styled-components'
import {orderedIdsByPosition} from 'lib/list'
import Content from 'lib/ui/form/TextEditor/Content'
import {Font, fontStyles, useLoadFont} from 'lib/FontSelect'
import {rgba} from 'lib/color'
import {BlockComponentProps} from 'Event/Marketplace/Block'
import {HashMap, Ordered} from '../../lib/list'
import {BlockBase} from '../base'

export type NumberItem = Ordered & {
  visibility: 'all' | 'mobile_only' | 'desktop_only'
  text: string
}

export interface NumberedListBlock extends BlockBase {
  type: 'NumberedList'
  font: Font | null
  fontSize: number
  color: string
  numberSpace: number
  textSpace: number
  items: HashMap<NumberItem>
  counter: {
    style: 'numeric' | 'alphabetical' | 'roman'
    outline: 'none' | 'circle' | 'square'
    font: Font | null
    fontSize: number
    color: string
    background: {
      color: string
      opacity: number
    }
  }
}

export default function NumberedList(
  props: NumberedListBlock & BlockComponentProps,
) {
  return (
    <NumberedListContainer {...props}>
      <ListItems {...props} />
    </NumberedListContainer>
  )
}

export function NumberedListContainer(
  props: NumberedListBlock &
    BlockComponentProps & {
      children: React.ReactNode
    },
) {
  const {children} = props
  useLoadFont(props.font)

  return (
    <Container {...props}>
      <NumberedListBox>{children}</NumberedListBox>
    </Container>
  )
}

function ListItems(props: NumberedListBlock) {
  const {items} = props

  if (!items) {
    return null
  }

  const sortedIds = orderedIdsByPosition(items)

  return (
    <>
      {sortedIds.map((id, index) => (
        <ListItem key={id} number={index} {...props} item={items[id]} />
      ))}
    </>
  )
}

export function ListItem(
  props: NumberedListBlock & {
    item: NumberItem
    number: number
  },
) {
  const {item} = props

  const backgroundColor = props.counter.background.color
    ? rgba(
        props.counter.background.color,
        props.counter.background.opacity / 100,
      )
    : ''

  const counterNumber = convertCounterNumber(
    props.number + 1,
    props.counter.style,
  )

  return (
    <ListItemBox {...props} aria-label="number list">
      <NumberedItem {...props} backgroundColor={backgroundColor}>
        {' '}
        <CounterNumberHolder>{counterNumber}</CounterNumberHolder>{' '}
      </NumberedItem>
      <StyledContent
        fontSize={props.fontSize}
        counterSize={props.counter.fontSize}
      >
        {item.text}
      </StyledContent>
    </ListItemBox>
  )
}

function convertCounterNumber(
  number: number,
  outline: NumberedListBlock['counter']['style'],
) {
  if (outline === 'numeric') {
    return number
  }

  if (outline === 'alphabetical') {
    return convertToAlphabetical(number)
  }

  if (outline === 'roman') {
    return convertToRoman(number)
  }
}

const NumberedItem = (
  props: NumberedListBlock & {
    backgroundColor: string
    children: React.ReactNode
  },
) => {
  const {counter} = props

  if (counter.outline === 'none') {
    return <NoneOutline {...props} />
  }

  if (counter.outline === 'circle') {
    return <CircleOutline {...props} />
  }

  if (counter.outline === 'square') {
    return <SquareOutline {...props} />
  }

  return null
}

export const createNumberItem = (): NumberItem => ({
  text: '<p>New Item</p>',
  visibility: 'all',
})

const CounterNumberHolder = styled.span`
  display: inline-block;
  padding-top: 50%;
  padding-bottom: 50%;
`

const NoneOutline = styled.div<
  NumberedListBlock & {
    backgroundColor: string
  }
>`
  ${(props) => fontStyles(props.counter.font)}
  font-size: ${(props) => props.counter.fontSize}px;
  color: ${(props) => props.counter.color};
  margin-right: ${(props) => props.textSpace}px;
  text-align: center;
  line-height: 0px;
  min-width: calc(${(props) => props.counter.fontSize}px * 2);
  min-height: calc(${(props) => props.counter.fontSize}px * 2);
`

const CircleOutline = styled.div<
  NumberedListBlock & {
    backgroundColor: string
  }
>`
  background: ${(props) => props.backgroundColor};
  border-radius: 100%;
  ${(props) => fontStyles(props.counter.font)}
  font-size: ${(props) => props.counter.fontSize}px;
  color: ${(props) => props.counter.color};
  margin-right: ${(props) => props.textSpace}px;
  text-align: center;
  line-height: 0px;
  min-width: calc(${(props) => props.counter.fontSize}px * 1.5);
  min-height: calc(${(props) => props.counter.fontSize}px * 1.5);
`

const SquareOutline = styled.div<
  NumberedListBlock & {
    backgroundColor: string
  }
>`
  background: ${(props) => props.backgroundColor};
  ${(props) => fontStyles(props.counter.font)}
  font-size: ${(props) => props.counter.fontSize}px;
  color: ${(props) => props.counter.color};
  margin-right: ${(props) => props.textSpace}px;
  text-align: center;
  line-height: 0px;
  min-width: calc(${(props) => props.counter.fontSize}px * 1.5);
  min-height: calc(${(props) => props.counter.fontSize}px * 1.5);
`

const ListItemBox = styled.div<{
  numberSpace: number
  fontSize: number
  color: string
  font: Font | null
}>`
  margin-top: ${(props) => props.numberSpace}px;
  display: flex;
  align-items: flex-start;

  ${(props) => fontStyles(props.font)}
  font-size: ${(props) => props.fontSize}px;
  color: ${(props) => props.color};

  &:last-child {
    margin-bottom: ${(props) => props.numberSpace}px;
  }
`

const NumberedListBox = styled.div`
  flex-direction: column;

  & > div > div {
    left: auto !important;
    top: auto !important;
  }
`

const StyledContent = styled(Content)<{
  fontSize: number
  counterSize: number
}>`
  margin-top: ${(props) =>
    (props.counterSize * 1.5) / 2 - (props.fontSize * 1.4) / 2}px;
  & > p {
    margin-bottom: 0 !important;
    padding-top: 0 !important;
  }
`

function convertToRoman(number: number) {
  const romanChars: any = {
    M: 1000,
    CM: 900,
    D: 500,
    CD: 400,
    C: 100,
    XC: 90,
    L: 50,
    XL: 40,
    X: 10,
    IX: 9,
    V: 5,
    IV: 4,
    I: 1,
  }
  var result = ''

  for (var key of Object.keys(romanChars)) {
    var q = Math.floor(number / romanChars[key])
    number -= q * romanChars[key]
    result += key.repeat(q)
  }

  return result
}

function convertToAlphabetical(number: number) {
  const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
  let result = ''

  var charIndex = number % alphabet.length
  var quotient = number / alphabet.length
  if (charIndex - 1 === -1) {
    charIndex = alphabet.length
    quotient--
  }
  result = alphabet.charAt(charIndex - 1) + result
  if (quotient >= 1) {
    result = convertToAlphabetical(quotient) + result
  }

  return result
}

import styled from 'styled-components'
import React from 'react'
import {useTeam} from 'organization/Team/TeamProvider'
import {
  TeamInvitation,
  useTeamInvitations,
} from 'organization/Team/TeamInvitationsProvider'
import {api} from 'lib/url'
import {useToggle} from 'lib/toggle'
import {useOrganization} from 'organization/OrganizationProvider'
import {useState} from 'react'
import ErrorAlert from 'lib/ui/alerts/ErrorAlert'
import {useOwner} from 'organization/OwnerProvider'
import Box from '@material-ui/core/Box'
import TeamMemberItem from './TeamMemberItem'
import MuiTable from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import MuiTableCell from '@material-ui/core/TableCell'
import MuiTableRow from '@material-ui/core/TableRow'

export default function TeamMemberList() {
  const {
    members,
    remove: removeTeamMember,
    processing: processingTeamMember,
  } = useTeam()
  const {
    invitations: memberInvites,
    remove: removeInvite,
    processing: processingInvites,
  } = useTeamInvitations()

  const {
    resend: resendInvitation,
    processing: processingResend,
    error: resendError,
    clearError: clearResendError,
  } = useResendInvitation()

  const {owner} = useOwner()

  const processing =
    processingTeamMember || processingInvites || processingResend

  return (
    <Box>
      <ErrorAlert onClose={clearResendError}>{resendError}</ErrorAlert>
      <MuiTable>
        <TableHead>
          <StyledTableRow>
            <MuiTableCell>Name</MuiTableCell>
            <MuiTableCell>Email</MuiTableCell>
            <MuiTableCell>Role</MuiTableCell>
            <MuiTableCell />
          </StyledTableRow>
        </TableHead>
        <TableBody>
          <TeamMemberItem member={owner} isOwner />
          {memberInvites.map((invite, index) => (
            <TeamMemberItem
              key={index}
              aria-label="team invitation"
              member={invite}
              background={index % 2 ? 'dark' : 'light'}
              isInvitation
              processing={processing}
              onRemove={() => removeInvite(invite)}
              onResend={() => resendInvitation(invite)}
            />
          ))}
          {members.map((member, index) => (
            <TeamMemberItem
              key={member.id}
              aria-label="team member"
              member={member}
              background={(index + memberInvites.length) % 2 ? 'dark' : 'light'}
              onRemove={() => removeTeamMember(member)}
              processing={processing}
            />
          ))}
        </TableBody>
      </MuiTable>
    </Box>
  )
}

function useResendInvitation() {
  const {flag: processing, toggle: toggleProcessing} = useToggle()
  const {client} = useOrganization()

  const [error, setError] = useState<string | null>(null)
  const clearError = () => setError(null)

  const resend = (invitation: TeamInvitation) => {
    if (processing) {
      return
    }

    clearError()

    toggleProcessing()

    const url = api(`/team_invitations/${invitation.id}`)
    return client
      .post(url)
      .catch((error) => setError(error.message))
      .finally(toggleProcessing)
  }

  return {
    resend,
    processing,
    error,
    clearError,
  }
}

const StyledTableRow = styled((props) => {
  const {background: _1, ...otherProps} = props

  return <MuiTableRow {...otherProps} />
})`
  background-color: #f2f5f9;
`

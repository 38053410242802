import styled from 'styled-components'
import React from 'react'
import {useCardsTemplate} from 'Event/template/Cards'
import {eventRoutes} from 'Event/Routes'
import {useEditMode} from 'Event/EditModeProvider'
import {RelativeLink} from 'lib/ui/link/RelativeLink'
import {useAttendeeVariables} from 'Event'
import Configurable from 'organization/Event/Configurable'
import PageLinkConfig from 'Event/template/Cards/Dashboard/PageLinks/PageLinkConfig'

export default function PageLinks() {
  const template = useCardsTemplate()
  const isEditMode = useEditMode()

  const {
    homeMenuTitle: homeTitle,
    speakers: {menuTitle: speakerTitle, isEnabled: showingSpeakers},
    sponsors: {menuTitle: sponsorsTitle, isEnabled: showingSponsors},
    zoomBackgrounds: {
      menuTitle: zoomBackgroundsMenuTitle,
      isEnabled: showingZoomBackgrounds,
    },
    leaderboard: {menuTitle: pointsTitle, isVisible: showingPoints},
    faq: {menuTitle: faqsTitle, isEnabled: showingFaqs},
    pageLinks: {textColor, dividerColor: tabSeparatorColor},
    imageWaterfall: {
      menuTitle: imageWaterfallTitle,
      isVisible: showingImageWaterfall,
    },
  } = template

  const hasLinks =
    showingSpeakers ||
    showingSponsors ||
    showingZoomBackgrounds ||
    showingPoints ||
    showingFaqs ||
    showingImageWaterfall

  if (!isEditMode && !hasLinks) {
    return null
  }

  return (
    <Box borderBottomColor={tabSeparatorColor} aria-label="page links">
      <Link
        name="homeMenuTitle"
        showing
        label={homeTitle}
        color={textColor}
        to={eventRoutes.root}
        aria-label="home link"
      />
      <Link
        name="speakers.menuTitle"
        showing={showingSpeakers}
        label={speakerTitle}
        color={textColor}
        to={eventRoutes.speakers}
        aria-label="speakers link"
      />
      <Link
        name="sponsors.menuTitle"
        color={textColor}
        showing={showingSponsors}
        label={sponsorsTitle}
        to={eventRoutes.sponsors}
        aria-label="sponsors link"
      />
      <Link
        name="zoomBackgrounds.menuTitle"
        color={textColor}
        showing={showingZoomBackgrounds}
        label={zoomBackgroundsMenuTitle}
        to={eventRoutes.backgrounds}
        aria-label="zoom backgrounds link"
      />
      <Link
        name="leaderboard.menuTitle"
        color={textColor}
        showing={showingPoints}
        label={pointsTitle}
        to={eventRoutes.leaderboard}
        aria-label="leaderboard link"
      />
      <Link
        name="faq.menuTitle"
        color={textColor}
        showing={showingFaqs}
        label={faqsTitle}
        to={eventRoutes.faq}
        aria-label="faq link"
      />
      <Link
        name="imageWaterfall.menuTitle"
        color={textColor}
        showing={showingImageWaterfall}
        label={imageWaterfallTitle}
        to={eventRoutes.image_waterfall}
        aria-label="faq link"
      />
    </Box>
  )
}

function Link(props: {
  name: string
  label: string
  showing: boolean
  color: string
  to: string
  'aria-label': string
}) {
  const {label, color, showing, name} = props
  const v = useAttendeeVariables()
  const isEditMode = useEditMode()
  if (!isEditMode && !showing) {
    return null
  }

  return (
    <InlineConfigurable>
      <PageLinkConfig value={label} name={name} />
      <StyledRelativeLink
        to={props.to}
        aria-label={props['aria-label']}
        color={color}
      >
        {v(label)}
      </StyledRelativeLink>
    </InlineConfigurable>
  )
}

const DIVIDER_SPACE_PX = 8

const Box = styled.div<{borderBottomColor: string}>`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  border-bottom: 2px solid ${(props) => props.borderBottomColor};

  padding-bottom: ${DIVIDER_SPACE_PX}px;

  width: 100%;

  @media (min-width: ${(props) => props.theme.breakpoints.xl}) {
    width: 80%;
  }
`
const StyledRelativeLink = styled(RelativeLink)<{color: string}>`
  color: ${(props) => props.color};
  display: inline-block;
  margin: ${DIVIDER_SPACE_PX}px 25px;
  font-weight: bold;
  font-size: 20px;
`

const InlineConfigurable = styled(Configurable)`
  width: auto;
`

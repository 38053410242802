import {useAttendeeVariables} from 'Event'
import styled from 'styled-components'
import {useTemplate} from 'Event/TemplateProvider'
import Content from 'lib/ui/form/TextEditor/Content'
import Configurable from 'organization/Event/Configurable'
import React from 'react'
import BodyConfig from 'Event/Step3/TechCheck/Body/BodyConfig'

export default function Body() {
  const v = useAttendeeVariables()

  const {techCheck} = useTemplate()

  return (
    <Configurable>
      <BodyConfig />
      <StyledContent>{v(techCheck.body)}</StyledContent>
    </Configurable>
  )
}

const StyledContent = styled(Content)`
  max-height: 100%;
  overflow-y: auto;
  margin-bottom: 16px;
  font-size: 16px;
  line-height: 24px;

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    font-size: 20px;
    line-height: 32px;
    margin-bottom: 32px;
  }
`

import {Tags} from 'Event/attendee'
import React, {useMemo, useState} from 'react'
import AttendeeProfileProvider from 'Event/attendee-rules/AttendeeProfileProvider'
import {useEvent} from 'Event/EventProvider'

export type Group = {
  key: string
  value: string
}

interface PreviewAttendeeProfileContextProps {
  groups: Group[]
  tags: Tags
  setGroups: (groups: Group[]) => void
  setTags: (tags: Tags) => void
  save: (groups: Group[], tags: Tags) => void
}

const PreviewAttendeeProfileContext = React.createContext(
  (undefined as unknown) as PreviewAttendeeProfileContextProps,
)

export default function PreviewAttendeeProfileProvider(props: {
  children: React.ReactNode
}) {
  const {groups: savedGroups, tags: savedTags, save} = useSavedProfile()

  const [groups, setGroups] = useState<Group[]>(savedGroups)
  const [tags, setTags] = useState<Tags>(savedTags)

  const attendeeGroups = useMemo(
    () =>
      groups.reduce((acc, group) => {
        acc[group.key] = group.value
        return acc
      }, {} as any),
    [groups],
  )

  return (
    <PreviewAttendeeProfileContext.Provider
      value={{
        groups,
        tags,
        setGroups,
        setTags,
        save,
      }}
    >
      <AttendeeProfileProvider groups={attendeeGroups} tags={tags}>
        {props.children}
      </AttendeeProfileProvider>
    </PreviewAttendeeProfileContext.Provider>
  )
}

export function usePreviewAttendeeProfile() {
  const context = React.useContext(PreviewAttendeeProfileContext)
  if (context === undefined) {
    throw new Error(
      'usePreviewAttendeeProfile must be used within a PreviewAttendeeProfileProvider',
    )
  }

  return context
}

function useSavedProfile() {
  const {event} = useEvent()
  const tagsKey = `__obvio_${event.id}_preview_tags__`
  const groupsKey = `__obvio_${event.id}_preview_groups__`

  const savedGroups = localStorage.getItem(groupsKey)
  const savedTags = localStorage.getItem(tagsKey)

  const save = (groups: Group[], tags: Tags) => {
    localStorage.setItem(groupsKey, JSON.stringify(groups))
    localStorage.setItem(tagsKey, JSON.stringify(tags))
  }

  return {
    groups: savedGroups ? JSON.parse(savedGroups) : [],
    tags: savedTags ? JSON.parse(savedTags) : [],
    save,
  }
}

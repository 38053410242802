export const localStorageEnabled = () => {
  try {
    if (!window || !window.localStorage) {
      return false
    }
    return true
  } catch (error) {
    return false
  }
}

export const getLocalStorageItem = (key: string) => {
  if (!window || !window.localStorage) {
    return null
  }
  return window.localStorage.getItem(key)
}

import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import MuiTextField, {TextFieldProps} from '@material-ui/core/TextField'
import PasswordField from 'lib/ui/TextField/PasswordField'

export type InputStyleProps = {
  labelColor: string
  textColor: string
  borderColor: string
  borderWidth: number
  backgroundColor: string
  borderRadius: number
}

export default function TextField(props: TextFieldProps & InputStyleProps) {
  const {
    borderRadius,
    textColor,
    labelColor,
    borderColor,
    borderWidth,
    backgroundColor,
    ...otherProps
  } = props

  const useStyles = makeStyles({
    root: {
      backgroundColor: `${backgroundColor} !important`,
      borderRadius: `${borderRadius}px !important`,
      '& .MuiFilledInput-input': {
        borderRadius: `${borderRadius}px !important`,
      },
      '& .MuiInputLabel-outlined': {
        color: `${labelColor} !important`,
      },
      '& i': {
        color: `${labelColor}`,
      },
      '&::before': {
        content: 'unset',
      },
      '&::after': {
        content: 'unset',
      },
    },
    input: {
      padding: '22px 14px 16px',
      color: `${textColor} !important`,
    },
    inputLabelShrunk: {
      transform: 'translate(14px, 10px) scale(0.75)!important',
    },
    notch: {
      borderWidth: `${borderWidth}px !important`,
      borderColor: `${borderColor} !important`,
      '& legend': {
        maxWidth: '0.01px', // Hide white box over border
      },
    },
    label: {
      color: `${labelColor} !important`,
    },
  })

  const classes = useStyles()

  const Field = props.type === 'password' ? PasswordField : MuiTextField

  return (
    <Field
      {...otherProps}
      variant="outlined"
      className={classes.label}
      InputLabelProps={{
        classes: {
          shrink: classes.inputLabelShrunk,
          outlined: classes.label,
        },
      }}
      InputProps={{
        classes: {
          root: classes.root,
          input: classes.input,
          notchedOutline: classes.notch,
          colorSecondary: classes.label,
        },
      }}
    />
  )
}

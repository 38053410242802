import React from 'react'
import Container from '@material-ui/core/Container'
import Text from 'Event/auth/Login/Description/Text'
import styled from 'styled-components'
import Page, {ErrorMessage} from 'Event/template/Panels/Login/Page'
import {ChangePasswordRequest} from 'auth/password'
import Button from 'Event/auth/Login/SubmitButton/Button'
import {eventRoutes} from 'Event/Routes'
import {useHistory} from 'react-router-dom'
import Description from 'Event/auth/ChangePassword/Description'
import NewPasswordField from 'Event/auth/ChangePassword/NewPasswordField'
import ConfirmNewPasswordField from 'Event/auth/ChangePassword/ConfirmNewPasswordField'
import SubmitButton from 'Event/auth/ChangePassword/SubmitButton'
import {User} from 'auth/user'

export default function ChangePassword(props: {
  changePassword: ChangePasswordRequest
  user: User
}) {
  return (
    <Page isPreview={false}>
      <Content {...props} />
    </Page>
  )
}

export function Content(props: {changePassword: ChangePasswordRequest}) {
  const {changePassword} = props
  const history = useHistory()
  const {responseError} = changePassword
  const goBackToDashboard = () => {
    history.push(eventRoutes.root)
  }

  if (changePassword.wasSuccessful) {
    return (
      <>
        <Text>Password Updated!</Text>
        <Button aria-label="back to dashboard" onClick={goBackToDashboard}>
          Go To Dashboard
        </Button>
      </>
    )
  }

  return (
    <>
      <Container maxWidth="md">
        <Description />
        <StyledForm onSubmit={changePassword.onSubmit}>
          <NewPasswordField {...changePassword} />
          <ConfirmNewPasswordField {...changePassword} />
          <ErrorMessage>{responseError?.message}</ErrorMessage>
          <SubmitButton {...changePassword} />
        </StyledForm>
      </Container>
    </>
  )
}

const StyledForm = styled.form`
  width: 100%;
`

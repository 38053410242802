import ErrorAlert from 'lib/ui/alerts/ErrorAlert'
import styled from 'styled-components'
import React, {ErrorInfo} from 'react'

type Props = {
  children: JSX.Element
  showError: boolean
}

type State = {
  hasError: boolean
}

class ErrorBoundary extends React.Component<Props, State> {
  state: State = {
    hasError: false,
  }

  static getDerivedStateFromError(_: Error) {
    // Update state so the next render will show the fallback UI.
    return {hasError: true}
  }

  componentDidCatch(error: Error, _errorInfo: ErrorInfo) {
    console.warn('Invalid HTML embedded:', error)
  }

  render() {
    if (this.state.hasError && this.props.showError) {
      return <StyledAlert>Invalid HTML Embed</StyledAlert>
    }

    if (this.state.hasError) {
      return null
    }

    return this.props.children
  }
}

export default ErrorBoundary

const StyledAlert = styled(ErrorAlert)`
  margin: ${(props) => props.theme.spacing[2]} 0 0 0;
`

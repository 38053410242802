import React from 'react'
import {useEvent} from 'Event/EventProvider'
import styled from 'styled-components'
import Hidden from '@material-ui/core/Hidden'
import {usePanelsTemplate} from 'Event/template/Panels'

export default function Logo(props: {className?: string}) {
  return (
    <>
      <Hidden mdUp>
        <MobileLogo className={props.className} />
      </Hidden>
      <Hidden smDown>
        <DesktopLogo className={props.className} />
      </Hidden>
    </>
  )
}

function MobileLogo(props: {className?: string}) {
  const {event} = useEvent()
  const {mobileLogo} = usePanelsTemplate()

  if (!mobileLogo) {
    return <DesktopLogo className={props.className} />
  }

  return (
    <LogoImage src={mobileLogo} alt={event.name} className={props.className} />
  )
}

function DesktopLogo(props: {className?: string}) {
  const {event} = useEvent()
  const {logo} = usePanelsTemplate()

  if (!logo) {
    return null
  }

  return <LogoImage src={logo} alt={event.name} className={props.className} />
}

const LogoImage = styled.img`
  max-width: 100%;
`

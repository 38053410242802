import {useNiftyFiftyTemplate} from 'Event/template/NiftyFifty'
import {FullHeightConfigurable} from 'Event/template/NiftyFifty/check-in/LeftPanel'
import RightPanelConfig from 'Event/template/NiftyFifty/check-in/RightPanel/RightPanelConfig'
import {rgba} from 'lib/color'
import React from 'react'
import styled from 'styled-components'

export type Step = 1 | 2 | 3

export default function RightPanel(props: {
  children: React.ReactElement | React.ReactElement[]
}) {
  const template = useNiftyFiftyTemplate()
  const {checkInRightPanel, textColor} = template

  return (
    <FullHeightConfigurable aria-label="right panel config">
      <RightPanelConfig />
      <Box
        backgroundColor={rgba(
          checkInRightPanel.backgroundColor,
          checkInRightPanel.backgroundOpacity / 100,
        )}
        rightPanelColor={checkInRightPanel.textColor}
        textColor={textColor}
      >
        <FlexContainer contentAlign={checkInRightPanel.contentAlign}>
          <div>{props.children}</div>
        </FlexContainer>
      </Box>
    </FullHeightConfigurable>
  )
}

const Box = styled.div<{
  backgroundColor: string
  textColor: string
  rightPanelColor: string | null
}>`
  background: ${(props) => props.backgroundColor};
  width: 100%;
  height: 100%;
  overflow: auto;
  padding: ${(props) => props.theme.spacing[10]};
  > * {
    color: ${(props) =>
      props.rightPanelColor ? props.rightPanelColor : props.textColor};
  }
`

const FlexContainer = styled.div<{
  contentAlign: string
}>`
  display: flex;
  align-items: ${(props) => props.contentAlign ?? 'flex-start'};
  height: 100%;
  justify-content: center;
`

import React from 'react'
import styled from 'styled-components'
import FormControlLabel, {
  FormControlLabelProps,
} from '@material-ui/core/FormControlLabel'
import MuiSwitch, {
  SwitchProps as MuiSwitchProps,
} from '@material-ui/core/Switch'
import InputLabel from 'lib/ui/InputLabel'

type SwitchProps = {
  name?: string
  onChange: MuiSwitchProps['onChange']
  label?: string | ''
  checked: MuiSwitchProps['checked']
  labelPlacement: FormControlLabelProps['labelPlacement']
  'aria-label'?: string
  disabled?: boolean
  className?: string
}

export default function Switch(props: SwitchProps) {
  return (
    <Box className={props.className}>
      <FormControlLabel
        control={
          <MuiSwitch
            disabled={props.disabled}
            checked={props.checked}
            onChange={props.onChange}
            name={props.name}
            inputProps={{'aria-label': props['aria-label']}}
            color="primary"
          />
        }
        label={<InputLabel>{props.label}</InputLabel>}
        labelPlacement={props.labelPlacement}
      />
    </Box>
  )
}

/**
 * Convenient Switch component for 'enabled'/'disabled' states.
 *
 * @param props
 * @returns
 */
export function EnabledSwitch(
  props: Omit<SwitchProps, 'label' | 'labelPlacement'>,
) {
  const label = props.checked ? 'Enabled' : 'Disabled'

  const ariaLabel = props['aria-label'] || 'toggle enabled'

  return (
    <Switch
      {...props}
      labelPlacement="end"
      label={label}
      aria-label={ariaLabel}
    />
  )
}

const Box = styled.div`
  padding-left: 10px;
  margin-bottom: ${(props) => props.theme.spacing[4]};
`

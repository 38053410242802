import React, {useState} from 'react'
import Button from '@material-ui/core/Button'
import {now} from 'lib/date-time'
import BlogPostConfig from 'Event/template/Townhall/Dashboard/Main/BlogSection/BlogPostConfig'
import {
  BlogSectionProps,
  TownhallBlogPost,
} from 'Event/template/Townhall/Dashboard/Main/BlogSection'

const newPost = (): TownhallBlogPost => ({
  title: 'My Post',
  postedAt: now(),
  publishAt: now(),
  showingUntil: null,
  content: '',
  isVisible: true,
  hideDate: true,
  buttons: {},
  rules: [],
  formId: null,
  isModalForm: false,
  modalButtonText: 'Submit',
  buttonsPosition: 'center',
  attachment: 'form',
  buttonsWidth: 100,
  isWelcomePost: false,
  image: null,
  level: 1,
})

export default function AddBlogPostButton(
  props: BlogSectionProps & {
    sectionId: string
    className?: string
  },
) {
  const {className} = props

  const [post, setPost] = useState<TownhallBlogPost | null>(null)

  const clearPost = () => setPost(null)

  return (
    <>
      <NewPostConfig {...props} post={post} onClose={clearPost} />
      <Button
        className={className}
        fullWidth
        size="large"
        variant="outlined"
        color="primary"
        aria-label="add blog post"
        onClick={() => setPost(newPost())}
      >
        Add Blog post
      </Button>
    </>
  )
}

function NewPostConfig(
  props: BlogSectionProps & {
    sectionId: string
    post: TownhallBlogPost | null
    onClose: () => void
  },
) {
  const {post, onClose} = props
  if (!post) {
    return null
  }

  return <BlogPostConfig {...props} post={post} onClose={onClose} isVisible />
}

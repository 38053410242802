import ContentInput from 'Event/Dashboard/components/NavButton/NavButtonConfig/ContentInput'
import {Template} from 'Event/template'
import React from 'react'
import {UseFormMethods} from 'react-hook-form'

export default function Settings(props: {
  register: UseFormMethods['register']
  control: UseFormMethods['control']
  watch: UseFormMethods['watch']
  button: Template['techCheck']['startButton']
}) {
  const {register, button, control, watch} = props

  return (
    <>
      <ContentInput
        button={button}
        control={control}
        register={register}
        watch={watch}
      />
    </>
  )
}

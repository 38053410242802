import React, {useState} from 'react'
import styled from 'styled-components'
import {useAttendeeVariables} from 'Event'
import {Waiver as WaiverProps} from 'Event/template'
import Form from 'Event/Step2/Waivers/Waiver/Form'
import SubmitButton from 'Event/Step2/Waivers/Waiver/SubmitButton'
import {useEvent} from 'Event/EventProvider'
import {Attendee} from 'Event/attendee'
import {api} from 'lib/url'
import {useDispatch} from 'react-redux'
import {setUser} from 'auth/actions'
import {Answer, useSubmissions} from 'Event/SubmissionsProvider'
import {useToggleArray} from 'lib/toggle'
import Checkbox from 'Event/Step2/Waivers/Waiver/Checkbox'
import Body from 'Event/Step2/Waivers/Waiver/Body'
import SignaturePrompt from 'Event/Step2/Waivers/Waiver/SignaturePrompt'
import SignatureBox from 'Event/Step2/Waivers/Waiver/SignatureBox'
import EditModeOnly from 'Event/Dashboard/editor/views/EditModeOnly'
import {useTargetWaiverId} from 'Event/Step2/Waivers'
import {Form as FormProps} from 'organization/Event/FormsProvider'
import FirstName from 'Event/Step2/Waivers/Waiver/Input/FirstName'
import LastName from 'Event/Step2/Waivers/Waiver/Input/LastName'
import {User} from 'auth/user'
import {useValidatedForm, UseValidatedFormMethods} from 'lib/form'
import {useTemplate} from 'Event/TemplateProvider'
import {useEditMode} from 'Event/EditModeProvider'
import SignatureRequiredSwitch from 'Event/Step2/Waivers/Waiver/SignatureRequiredSwitch'
import EnableFormSwitch from 'Event/Step2/Waivers/Waiver/EnableFormSwitch'
import TitleField from 'Event/Step2/Waivers/Waiver/TitleField'

type WaiverContextProps = {
  id: string
  waiver: WaiverProps
  agree: boolean
  canSubmit: boolean
  setAgree: (agree: boolean) => void
  signature: string | null
  setSignature: (signature: string | null) => void
  submit: (data: any) => Promise<void> | undefined
  form: UseValidatedFormMethods
}

const WaiverContext = React.createContext<WaiverContextProps | undefined>(
  undefined,
)

export default function Waiver(props: {
  waiver: WaiverProps
  id: string
  className?: string
  user: User
}) {
  const v = useAttendeeVariables()
  const {waiver, id} = props
  const [agree, setAgree] = useState(false)
  const [signature, setSignature] = useState<string | null>(null)
  const form = useValidatedForm()
  const {
    waiver: {formEnabled},
  } = useTemplate()

  const [submitting, toggleSubmitting] = useToggleArray()
  const {
    event: {forms},
  } = useEvent()
  const {waiver: settings} = useTemplate()

  const isEditMode = useEditMode()
  const targetId = useTargetWaiverId()
  const {clearErrors} = useValidatedForm()

  const isTarget = id === targetId
  const showing = isTarget || isEditMode

  const addSubmissionsAndSignWaiver = useAddSubmissionsAndSignWaiver()

  const sign = useSignWaiver()

  const {submit: submitAnswers} = useSubmissions()

  const canSubmit = () => {
    if (settings.signatureRequired) {
      return !submitting && Boolean(signature) && agree
    }

    return !submitting
  }

  const waiverForm = waiver.formId
    ? forms.find((f) => {
        const isTarget = f.id === waiver.formId

        // We want to prevent submitting a form without questions, or it would
        // crash the app. We'll only return a form if it does have questions
        //here.
        const hasQuestions = f.questions.length > 0

        return isTarget && hasQuestions
      })
    : null

  if (!showing) {
    return null
  }

  /**
   * Submitting dynamic user defined form, no way of
   * knowing the data type, so we'll have to
   * use 'any' data here.
   */
  const submit = (formData: any) => {
    if (submitting || isEditMode) {
      return
    }

    clearErrors()
    toggleSubmitting()

    const data = {
      ...formData,
      body: v(waiver.body),
      signature,
      title: waiver.title,
    }

    // Signature + form
    if (waiverForm && formEnabled && settings.signatureRequired) {
      return addSubmissionsAndSignWaiver(waiverForm, data).catch((e) => {
        form.setResponseError(e)
        toggleSubmitting()
      })
    }

    // Form only
    if (waiverForm && formEnabled) {
      return submitAnswers(waiverForm, formData).catch((e) => {
        form.setResponseError(e)
        toggleSubmitting()
      })
    }

    // Signature only
    return sign(data).catch(toggleSubmitting)
  }

  return (
    <WaiverContext.Provider
      value={{
        waiver,
        id,
        agree,
        setAgree,
        signature,
        setSignature,
        canSubmit: canSubmit(),
        submit,
        form,
      }}
    >
      <Content className={props.className} user={props.user} />
    </WaiverContext.Provider>
  )
}

function Content(props: {className?: string; user: User}) {
  const {className = '', user} = props
  const {
    submit,
    form: {handleSubmit},
  } = useWaiver()

  return (
    <form
      className={className}
      onSubmit={handleSubmit(submit)}
      aria-label="waiver form"
    >
      <FormContent user={user} />
    </form>
  )
}

function FormContent(props: {user: User}) {
  const {user} = props

  const {waiver} = useTemplate()

  if (waiver.formEnabled && waiver.signatureRequired) {
    return (
      <>
        <EditModeOnly>
          <TitleField />
          <EnableFormSwitch />
        </EditModeOnly>
        <Form />
        <EditModeOnly>
          <SignatureRequiredSwitch />
        </EditModeOnly>
        <Body />
        <Checkbox />
        <SignaturePrompt />
        <SignatureBox user={user} />
        <Container>
          <NameContent>
            <FirstName user={user} />
            <LastName user={user} />
          </NameContent>
        </Container>
        <SubmitButton />
      </>
    )
  }

  if (waiver.signatureRequired) {
    return (
      <>
        <EditModeOnly>
          <TitleField />
          <EnableFormSwitch />
        </EditModeOnly>
        <EditModeOnly>
          <SignatureRequiredSwitch />
        </EditModeOnly>
        <Body />
        <Checkbox />
        <SignaturePrompt />
        <SignatureBox user={user} />
        <Container>
          <NameContent>
            <FirstName user={user} />
            <LastName user={user} />
          </NameContent>
        </Container>
        <SubmitButton />
      </>
    )
  }

  if (waiver.formEnabled) {
    return (
      <>
        <EditModeOnly>
          <TitleField />
          <EnableFormSwitch />
        </EditModeOnly>
        <Form />
        <EditModeOnly>
          <SignatureRequiredSwitch />
        </EditModeOnly>
        <SubmitButton />
      </>
    )
  }

  return (
    <>
      <EditModeOnly>
        <TitleField />
        <EnableFormSwitch />
        <SignatureRequiredSwitch />
      </EditModeOnly>
    </>
  )
}

export function useWaiver() {
  const context = React.useContext(WaiverContext)

  if (context === undefined) {
    throw new Error('useWaiver must be used within a <Waiver/>')
  }

  return context
}

type SignWaiverProps = {
  body: string
  signature: string | null
  first_name?: string
  last_name?: string
} & Pick<WaiverProps, 'title'>

function useSignWaiver() {
  const {event, client} = useEvent()
  const dispatch = useDispatch()

  const url = api(`/events/${event.id}/waiver/sign`)

  return (data: SignWaiverProps) =>
    client.post<Attendee>(url, data).then((attendee) => {
      dispatch(setUser(attendee))
    })
}

type AddSubmissionsAndSignWaiverResponse = {
  attendee: Attendee
  submissions: Answer[]
}

function useAddSubmissionsAndSignWaiver() {
  const {client} = useEvent()
  const dispatch = useDispatch()
  const {answers, setAnswers} = useSubmissions()

  return (
    form: FormProps,
    data: {
      answers: Answer[]
      body: string
      signature: string | null
      first_name?: string
      last_name?: string
    } & Pick<WaiverProps, 'title'>,
  ) => {
    const url = api(`/forms/${form.id}/waiver_form`)

    return client
      .post<AddSubmissionsAndSignWaiverResponse>(url, data)
      .then(({attendee, submissions}) => {
        dispatch(setUser(attendee))
        setAnswers([...answers, ...submissions])
      })
  }
}

const Container = styled.div`
  display: flex;
  justify-content: center;
`

const NameContent = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 8px;
  width: 300px;
  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    width: 500px;
    flex-direction: row;
  }
`

import DialogContent from '@material-ui/core/DialogContent'
import Button from '@material-ui/core/Button'
import DialogTitle from '@material-ui/core/DialogTitle'
import Question from 'Event/Question'
import Dialog from 'lib/ui/Dialog'
import {Form} from 'organization/Event/FormsProvider'
import React, {useState} from 'react'
import {useForm} from 'react-hook-form'
import Box from '@material-ui/core/Box'
import {hasSubmission, useSubmissions} from 'Event/SubmissionsProvider'
import {useAttendeeVariables} from 'Event'
import {
  DEFAULT_RESUBMIT_LABEL,
  DEFAULT_SUBMITTED_MESSAGE,
  DEFAULT_SUBMIT_LABEL,
} from 'organization/Event/Form'
import {useEvent} from 'Event/EventProvider'
import {useEditMode} from 'Event/EditModeProvider'
import {Sponsor} from 'Event/Sponsors'

export default function SponsorForm(props: {
  sponsor: Sponsor
  showing: boolean
  onClose: () => void
}) {
  const {
    sponsor: {formId},
  } = props

  const {event} = useEvent()

  const isEditMode = useEditMode()
  if (isEditMode) {
    return null
  }

  const form = event.forms.filter((form) => form.id === formId)[0]
  if (!form) {
    return null
  }

  return <Content form={form} showing={props.showing} onClose={props.onClose} />
}

function Content(props: {form: Form; showing: boolean; onClose: () => void}) {
  const {form, onClose, showing} = props

  return (
    <Dialog open={showing} onClose={onClose}>
      <DialogTitle>{form.name}</DialogTitle>
      <DialogContent>
        <Box pb={2}>
          <Body form={form} onSubmitted={onClose} />
        </Box>
      </DialogContent>
    </Dialog>
  )
}

function Body(props: {form: Form; onSubmitted: () => void}) {
  const {form} = props
  const {submit: submitAnswers, responseError, answers} = useSubmissions()
  const [submitting, setSubmitting] = useState(false)
  const v = useAttendeeVariables()
  const [isResubmitting, setIsResubmitting] = useState(false)

  const toggleResubmit = () => setIsResubmitting(!isResubmitting)

  const {
    register,
    handleSubmit,
    errors: formErrors,
    control,
    setValue,
    watch,
  } = useForm()

  const submit = (data: any) => {
    if (submitting) {
      return
    }
    setSubmitting(true)

    submitAnswers(form, data)
      .then(() => {
        if (form.on_submit_redirect_url) {
          window.location.href = v(form.on_submit_redirect_url)
        }

        props.onSubmitted()
      })
      .finally(() => {
        setSubmitting(false)
      })
  }

  if (hasSubmission(answers, form) && !isResubmitting) {
    return <SubmittedMessage form={form} onResubmit={toggleResubmit} />
  }

  return (
    <form onSubmit={handleSubmit(submit)}>
      {form.questions.map((question, index) => (
        <Question
          formErrors={formErrors}
          key={question.id}
          index={index}
          control={control}
          question={question}
          answers={answers}
          register={register}
          responseError={responseError}
          setValue={setValue}
          disabled={submitting}
          watch={watch}
        />
      ))}
      <Button type="submit" variant="outlined" disabled={submitting} fullWidth>
        {v(form.submit_label || DEFAULT_SUBMIT_LABEL)}
      </Button>
    </form>
  )
}

function SubmittedMessage(props: {form: Form; onResubmit: () => void}) {
  const v = useAttendeeVariables()
  const {form, onResubmit} = props

  if (!form.can_resubmit) {
    return (
      <div>
        <p>{v(props.form.submitted_message || DEFAULT_SUBMITTED_MESSAGE)}</p>
      </div>
    )
  }

  return (
    <div>
      <p>{v(props.form.submitted_message || DEFAULT_SUBMITTED_MESSAGE)}</p>
      <Button variant="text" onClick={onResubmit}>
        {v(props.form.resubmit_button_label || DEFAULT_RESUBMIT_LABEL)}
      </Button>
    </div>
  )
}

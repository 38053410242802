import React from 'react'
import {useObvioAuth} from 'obvio/auth'
import {createRoutes} from 'lib/url'
import GuestRoutes from 'obvio/Routes/GuestRoutes'
import UserRoutes from 'obvio/Routes/UserRoutes'
import FullPageLoader from 'lib/ui/layout/FullPageLoader'
import WithLiveChatSupport from 'lib/WithLiveChatSupport'
import TeamMemberLiveUpdates from 'auth/TeamMemberLiveUpdates'
import AutoLogout from 'auth/AutoLogout'

export const obvioRoutes = createRoutes({
  login: '/login',
  registration: '/register',
  accept_invitation: '/accept_invitation',
  forgot_password: '/forgot_password',
  reset_password: '/reset_password',
  account: '/account',
  organizations: {
    create: '/create',
  },
  billing: {
    purchase_add_ons: '/purchase_add_ons',
    change_plan: '/change_plan',
    cancel_plan: '/cancel_plan',
    resume_plan: '/resume_plan',
  },
  mailchimp: {
    auth: '/auth',
  },
  highlevel: {
    auth: '/auth',
  },
  hubspot: {
    auth: '/auth',
  },
  purchase: '/purchase',
  promoter: '/promoter',
})

export default function ObvioRoutes() {
  const {user, loading, logout} = useObvioAuth()

  if (loading) {
    return <FullPageLoader />
  }

  if (user) {
    return (
      <AutoLogout user={user} logout={logout}>
        <TeamMemberLiveUpdates>
          <WithLiveChatSupport user={user}>
            <UserRoutes />
          </WithLiveChatSupport>
        </TeamMemberLiveUpdates>
      </AutoLogout>
    )
  }

  return (
    <WithLiveChatSupport>
      <GuestRoutes />
    </WithLiveChatSupport>
  )
}

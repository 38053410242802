import ColorPicker from 'lib/ui/ColorPicker'
import {useTownhallTemplate} from 'Event/template/Townhall'
import React from 'react'
import {Controller} from 'react-hook-form'
import CollapsibleSection from 'lib/ui/ConfigPanel/CollapsibleSection'
import Slider from 'lib/ui/Slider'
import MarginPaddingInputs from 'lib/ui/MarginPaddingInputs'
import {Label} from 'lib/ui/typography'
import FontSelect from 'lib/FontSelect'
import {useConfig} from 'organization/Event/Configurable'

const DEFAULT_FONT_SIZE = 42
const MAX_FONT_SIZE = 50
const MIN_FONT_SIZE = 5

export default function Styling() {
  const {
    form: {control, watch},
  } = useConfig()
  const template = useTownhallTemplate()
  const topBarColor = template.topBar.color
    ? template.topBar.color
    : template.textColor

  const backgroundOpacity = watch('topbar.backgroundOpacity')

  return (
    <>
      <CollapsibleSection
        label="Logo"
        storageKey="dashboard.topbar.logo.styling"
      >
        <Controller
          name="logoSize"
          defaultValue={template.logoSize}
          control={control}
          render={({value, onChange}) => (
            <Slider
              label="Size"
              min={0}
              max={500}
              step={1}
              onChange={onChange}
              valueLabelDisplay="auto"
              value={value}
              aria-label="logo size"
              unit="px"
            />
          )}
        />
        <Label>Padding</Label>
        <MarginPaddingInputs
          control={control}
          namePrefix="topBar.padding"
          values={template.topBar.padding}
          settings={{max: 300}}
        />
      </CollapsibleSection>

      <CollapsibleSection
        label="Background"
        storageKey="dashboard.topbar.background.styling"
      >
        <Controller
          name="topBar.backgroundColor"
          defaultValue={template.topBar.backgroundColor}
          control={control}
          render={({value, onChange}) => (
            <ColorPicker
              label="Background Color"
              color={value}
              onPick={onChange}
              aria-label="topbar background color"
            />
          )}
        />
        <Controller
          name="topBar.backgroundOpacity"
          defaultValue={template.topBar.backgroundOpacity || 0}
          control={control}
          render={({value, onChange}) => (
            <Slider
              label="Background Color Opacity"
              min={0}
              max={100}
              step={1}
              onChange={onChange}
              valueLabelDisplay="auto"
              valueLabelFormat={() => <div>{backgroundOpacity * 100 || 0}</div>}
              value={value}
              aria-label="topbar background opacity"
              unit="%"
            />
          )}
        />
      </CollapsibleSection>
      <CollapsibleSection
        label="Typography"
        storageKey="dashboard.topbar.text.styling"
      >
        <Label>Font</Label>
        <Controller
          control={control}
          name="topBar.font"
          defaultValue={template.topBar.font || null}
          render={({value, onChange}) => (
            <FontSelect value={value} onChange={onChange} />
          )}
        />
        <Controller
          name="topBar.color"
          control={control}
          defaultValue={topBarColor}
          render={({value, onChange}) => (
            <ColorPicker
              label="Font Color"
              aria-label="topbar text font color"
              color={value}
              onPick={onChange}
            />
          )}
        />
        <Controller
          name="topBar.fontSize"
          defaultValue={template.topBar.fontSize || DEFAULT_FONT_SIZE}
          control={control}
          render={({value, onChange}) => (
            <Slider
              aria-label="topbar text font size"
              min={MIN_FONT_SIZE}
              max={MAX_FONT_SIZE}
              onChange={onChange}
              valueLabelDisplay="off"
              value={value}
              label="Font Size"
            />
          )}
        />
      </CollapsibleSection>
    </>
  )
}

import ColorPicker from 'lib/ui/ColorPicker'
import React from 'react'
import {Controller} from 'react-hook-form'
import {useConfig} from 'organization/Event/Configurable'
import {BlogSectionProps} from 'Event/template/Townhall/Dashboard/Main/BlogSection'
import CollapsibleSection from 'lib/ui/ConfigPanel/CollapsibleSection'
import FontSelect from 'lib/FontSelect'
import InputLabel from 'lib/ui/TextField/InputLabel'
import BackgroundPicker from 'lib/ui/form/BackgroundPicker'
import {useTownhallTemplate} from 'Event/template/Townhall'

export default function Styling(props: {
  blogSection: BlogSectionProps
  sectionId: string
}) {
  const {blogSection, sectionId} = props
  const {
    form: {control},
  } = useConfig()
  const template = useTownhallTemplate()
  const blogSectionDescriptionColor = blogSection.color
    ? blogSection.color
    : template.textColor

  return (
    <>
      <CollapsibleSection
        label="Background"
        storageKey="dashboard.blogSection.styling"
      >
        <Controller
          name={`dashboardSections.${sectionId}.background`}
          control={control}
          defaultValue={blogSection.background}
          render={({onChange, value}) => (
            <BackgroundPicker
              hasFreeSize
              label="Background Color"
              background={value}
              onChange={onChange}
            />
          )}
        />
      </CollapsibleSection>
      <CollapsibleSection
        label="Typography"
        storageKey="dashboard.blogSection.text.styling"
      >
        <InputLabel>Font</InputLabel>
        <Controller
          control={control}
          name={`dashboardSections.${sectionId}.font`}
          defaultValue={blogSection.font || null}
          render={({value, onChange}) => (
            <FontSelect value={value} onChange={onChange} />
          )}
        />
        <Controller
          name={`dashboardSections.${sectionId}.color`}
          control={control}
          defaultValue={blogSectionDescriptionColor}
          render={({value, onChange}) => (
            <ColorPicker
              label="Font Color"
              aria-label="font color"
              color={value}
              onPick={onChange}
            />
          )}
        />
      </CollapsibleSection>
    </>
  )
}

import React from 'react'
import styled from 'styled-components'
import {HeroConfig} from 'Event/template/Townhall/Dashboard/Hero/HeroConfig'
import {useAttendeeVariables} from 'Event'
import {useTownhallTemplate} from 'Event/template/Townhall'
import {Font, fontStyles, useLoadFont} from 'lib/FontSelect'
import TextContent from 'lib/ui/form/TextEditor/Content'
import Configurable from 'organization/Event/Configurable'

export default function Hero() {
  return (
    <Configurable aria-label="edit hero">
      <HeroConfig />
      <>
        <Headline />
        <Body />
      </>
    </Configurable>
  )
}

function Headline() {
  const template = useTownhallTemplate()
  const {hero, textColor} = template
  useLoadFont(hero.font)
  const v = useAttendeeVariables()

  if (!hero.headline) {
    return null
  }

  return (
    <Text
      font={hero.font}
      textColor={textColor}
      heroColor={hero.color}
      fontSize={hero.fontSize}
      alignment={hero.alignment}
    >
      {v(hero.headline)}
    </Text>
  )
}

function Body() {
  const v = useAttendeeVariables()
  const {
    hero: {body: description, font, color},
    textColor,
  } = useTownhallTemplate()

  return (
    <StyledContent
      fontFamily={font?.family}
      textColor={textColor}
      heroColor={color}
    >
      {v(description)}
    </StyledContent>
  )
}

const Text = styled.div<{
  font: Font | null
  textColor: string
  heroColor: string | null
  fontSize: number
  alignment: 'left' | 'right' | 'center'
}>`
  ${(props) => fontStyles(props.font)}
  color: ${(props) => (props.heroColor ? props.heroColor : props.textColor)};
  font-size: ${(props) => props.fontSize}px;
  line-height: 1;
  text-align: ${(props) => props.alignment};
  &:empty {
    height: ${(props) => props.theme.spacing[5]};
    width: 100%;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    line-height: 1.5;
  }
`

const StyledContent = styled(TextContent)<{
  fontFamily?: string
  textColor: string
  heroColor: string | null
}>`
  font-family: ${(props) => props.fontFamily};
  color: ${(props) => (props.heroColor ? props.heroColor : props.textColor)};
`

import React from 'react'
import {Lefty, useLeftyUpdate} from 'Event/template/Lefty'
import {useForm} from 'react-hook-form'
import Settings from 'Event/template/Lefty/Dashboard/Sidebar/SidebarContainer/SidebarContainerConfig/Settings'
import Styling from 'Event/template/Lefty/Dashboard/Sidebar/SidebarContainer/SidebarContainerConfig/Styling'
import ComponentConfig, {
  ComponentConfigProps,
  SaveButton,
  Footer,
} from 'organization/Event/DashboardConfig/ComponentConfigPanel'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import StylingPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/StylingPanel'
import {
  useAutoUpdate,
  useTemplateEditor,
} from 'organization/Event/TemplateEditor'
import {usePruneAssets} from 'lib/asset'

export function SidebarContainerConfig(props: ComponentConfigProps) {
  const {showing, onClose} = props
  const update = useLeftyUpdate()

  const {control, watch, handleSubmit, formState} = useForm()
  const pruneAssets = usePruneAssets()
  const {saved} = useTemplateEditor<Lefty>()

  const save = (data: Lefty['sidebar']) => {
    const backgroundImage = data.backgroundImage
    pruneAssets({backgroundImage}, saved.sidebar)

    update({
      sidebar: data,
    })
    onClose()
  }

  useAutoUpdate({values: {sidebar: watch()}, when: showing})

  return (
    <ComponentConfig
      showing={showing}
      onClose={onClose}
      title="Sidebar"
      onSubmit={handleSubmit(save)}
      hasChanges={formState.isDirty}
    >
      <SettingsPanel>
        <Settings control={control} />
      </SettingsPanel>
      <StylingPanel>
        <Styling control={control} />
      </StylingPanel>
      <Footer>
        <SaveButton type="submit" />
      </Footer>
    </ComponentConfig>
  )
}

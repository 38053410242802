import React from 'react'
import {Controller, UseFormMethods} from 'react-hook-form'
import Slider from 'lib/ui/Slider'
import {Grid, Box} from '@material-ui/core'
import {onChangeCheckedHandler} from 'lib/dom'
import ColorPicker from 'lib/ui/ColorPicker'
import {
  useNiftyFiftyTemplate,
  MIN_LOGO_SIZE_PERCENT,
  MAX_LOGO_SIZE_PERCENT,
} from 'Event/template/NiftyFifty'
import ImageAssetUploader from 'lib/asset/ImageAssetUploader'
import Switch from 'lib/ui/form/Switch'

export default function Styling(props: {control: UseFormMethods['control']}) {
  const {control} = props
  const template = useNiftyFiftyTemplate()
  const {leftPanel, menu, mainNav} = template

  return (
    <>
      <Grid container justify="center" spacing={2}>
        <Grid item xs={12} md={6}>
          <Box mb={2}>
            <Controller
              name="dashboardLogo.image"
              defaultValue={template.dashboardLogo.image}
              control={control}
              render={({value, onChange}) => (
                <ImageAssetUploader
                  label="Logo"
                  onChange={onChange}
                  value={value}
                  canResize
                  width={1200}
                  height={1920}
                  uploadInputProps={{
                    'aria-label': 'dashboard logo image',
                  }}
                  disableAutoRemove
                />
              )}
            />
          </Box>
          <Box mb={2}>
            <Controller
              name="dashboardLogo.enabled"
              defaultValue={template.dashboardLogo.enabled}
              control={control}
              render={({value, onChange}) => (
                <Switch
                  checked={value}
                  onChange={onChangeCheckedHandler(onChange)}
                  arial-label="set logo mode"
                  labelPlacement="end"
                  label={value ? 'Logo Enabled' : 'Logo Disabled'}
                />
              )}
            />
          </Box>
          <Box display="flex" flexDirection="column" flex="1" mb={2}>
            <Controller
              name="dashboardLogo.size"
              defaultValue={template.dashboardLogo.size}
              control={control}
              render={({value, onChange}) => (
                <Slider
                  valueLabelDisplay="auto"
                  aria-label="logo weight"
                  value={value}
                  onChange={onChange}
                  step={1}
                  min={MIN_LOGO_SIZE_PERCENT}
                  max={MAX_LOGO_SIZE_PERCENT}
                  label="Image Size"
                />
              )}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box mb={2}>
            <Controller
              name="dashboardBackground.image"
              defaultValue={template.dashboardBackground.image}
              control={control}
              render={({value, onChange}) => (
                <ImageAssetUploader
                  label="Background Image"
                  onChange={onChange}
                  value={value}
                  canResize
                  width={1200}
                  height={1920}
                  disableAutoRemove
                />
              )}
            />
          </Box>
          <Box mb={2}>
            <Controller
              name="dashboardBackground.enabled"
              defaultValue={template.dashboardBackground.enabled}
              control={control}
              render={({value, onChange}) => (
                <Switch
                  checked={value}
                  onChange={onChangeCheckedHandler(onChange)}
                  arial-label="set background mode"
                  labelPlacement="end"
                  label={
                    value
                      ? 'Background Image Enabled'
                      : 'Background Image Disabled'
                  }
                />
              )}
            />
          </Box>
        </Grid>
      </Grid>
      <Controller
        name="menu.iconColor"
        defaultValue={menu.iconColor}
        control={control}
        render={({value, onChange}) => (
          <ColorPicker
            label="Menu Icon Color"
            color={value}
            onPick={onChange}
            aria-label="left panel menu icon color"
          />
        )}
      />
      <Controller
        name="menu.textColor"
        defaultValue={menu.textColor}
        control={control}
        render={({value, onChange}) => (
          <ColorPicker
            label="Menu Text Color"
            color={value}
            onPick={onChange}
            aria-label="left panel menu text color"
          />
        )}
      />
      <Controller
        name="menu.backgroundColor"
        defaultValue={menu.backgroundColor}
        control={control}
        render={({value, onChange}) => (
          <ColorPicker
            label="Menu Background Color"
            color={value}
            onPick={onChange}
            aria-label="left panel bar background color"
          />
        )}
      />
      <Controller
        name="menu.backgroundOpacity"
        defaultValue={menu.backgroundOpacity}
        control={control}
        render={({value, onChange}) => (
          <Slider
            min={0}
            max={100}
            step={1}
            onChange={onChange}
            valueLabelDisplay="auto"
            value={value}
            label="Menu Background Opacity"
            unit="%"
          />
        )}
      />
      <Controller
        name="mainNav.spacing"
        defaultValue={mainNav.spacing}
        control={control}
        render={({value, onChange}) => (
          <Slider
            min={8}
            max={30}
            step={1}
            onChange={onChange}
            valueLabelDisplay="auto"
            value={value}
            label="Space Between Buttons"
          />
        )}
      />
      <Controller
        name="leftPanel.arrowColor"
        defaultValue={leftPanel.arrowColor}
        control={control}
        render={({value, onChange}) => (
          <ColorPicker
            label="Page Arrow Color"
            color={value}
            onPick={onChange}
            aria-label="page arrow color"
          />
        )}
      />
      <Controller
        name="leftPanel.backgroundColor"
        defaultValue={leftPanel.backgroundColor}
        control={control}
        render={({value, onChange}) => (
          <ColorPicker
            label="Background Color"
            color={value}
            onPick={onChange}
            aria-label="left panel background color"
          />
        )}
      />
      <Controller
        name="leftPanel.backgroundOpacity"
        defaultValue={leftPanel.backgroundOpacity}
        control={control}
        render={({value, onChange}) => (
          <Slider
            min={0}
            max={100}
            step={1}
            onChange={onChange}
            valueLabelDisplay="auto"
            value={value}
            label="Background Opacity"
            unit="%"
          />
        )}
      />
    </>
  )
}

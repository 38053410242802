import React, {useState} from 'react'
import Grid from '@material-ui/core/Grid'
import {User} from 'auth/user'
import BlogPosts from 'Event/template/Lefty/Dashboard/BlogPosts'
import Sidebar from 'Event/template/Lefty/Dashboard/Sidebar'
import DashboardPage, {Top, Bottom} from 'Event/template/Lefty/Dashboard/Page'
import BodyHTMLEmbed from 'Event/Dashboard/components/BodyHtmlEmbed'
import withStyles from '@material-ui/core/styles/withStyles'
import TeamAccessControls from 'Event/Dashboard/TeamAccessControls'
import MobileSidebar from 'Event/template/Lefty/Dashboard/Sidebar/Mobile'
import Visible from 'lib/ui/layout/Visible'
import SidebarContainer from 'Event/template/Lefty/Dashboard/Sidebar/SidebarContainer'

export default function LeftyDashboard(props: {user: User}) {
  return (
    <DashboardPage
      Mobile={<MobileView user={props.user} />}
      Desktop={<DesktopView user={props.user} />}
    />
  )
}

function MobileView(props: {user: User}) {
  const [hideDashboard, setHideDashboard] = useState(false)

  return (
    <>
      <Visible when={!hideDashboard}>
        <Top user={props.user}></Top>
      </Visible>
      <MobileSidebar visible={hideDashboard} setVisible={setHideDashboard} />
      <Visible when={!hideDashboard}>
        <Bottom>
          <FullHeightGrid container spacing={4}>
            <Grid item xs={12} md={12}>
              <TeamAccessControls />
              <BlogPosts />
            </Grid>
          </FullHeightGrid>
          <BodyHTMLEmbed />
        </Bottom>
      </Visible>
    </>
  )
}

function DesktopView(props: {user: User}) {
  return (
    <>
      <Top user={props.user}></Top>
      <Bottom>
        <FullHeightGrid container spacing={4}>
          <Grid item xs={12} md={4}>
            <SidebarContainer>
              <Sidebar />
            </SidebarContainer>
          </Grid>
          <Grid item xs={12} md={8}>
            <TeamAccessControls />
            <BlogPosts />
          </Grid>
        </FullHeightGrid>
        <BodyHTMLEmbed />
      </Bottom>
    </>
  )
}

const FullHeightGrid = withStyles({
  root: {
    flex: 1,
  },
})(Grid)

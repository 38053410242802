import Config from 'organization/Event/Configurable/Config'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import {useUpdateTemplate} from 'organization/Marketplace/PurchasePageConfig/TemplateUpdateProvider'
import React, {useState} from 'react'
import {REMOVE} from 'Event/TemplateUpdateProvider'
import VisibilitySetting from 'organization/Marketplace/PurchasePageConfig/BlockConfig/VisibilitySettings'
import {SpacerBlock} from 'Event/Marketplace/Block/Spacer'

interface SpacerConfigProps extends SpacerBlock {
  id: string
  sectionId: string
}

export default function SpacerConfig(props: SpacerConfigProps) {
  const {id, sectionId} = props
  const updateTemplate = useUpdateTemplate()
  const [visibility, setVisibility] = useState(props.visibility)

  const handleRemove = () => {
    updateTemplate({
      sections: {
        [sectionId]: {
          blocks: {
            [id]: REMOVE,
          },
        },
      },
    })
  }

  const save = () => {
    updateTemplate({
      sections: {
        [sectionId]: {
          blocks: {
            [id]: {visibility},
          },
        },
      },
    })
  }

  return (
    <Config title="Spacer Block" onSave={save} onRemove={handleRemove}>
      <SettingsPanel>
        <VisibilitySetting
          visibility={visibility}
          setVisibility={setVisibility}
        />
      </SettingsPanel>
    </Config>
  )
}

import {PurchasePageSection} from './purchase-page'

export const createSection = (): PurchasePageSection => ({
  background: {
    image: '',
    color: '',
    opacity: 0,
    position: 'center center',
    repeat: 'none',
  },
  padding: {
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
  margin: {
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
  border: {
    width: 0,
    color: '#FFFFFF',
    radius: 0,
  },
  minHeight: 0,
  blockSpacing: 10,
  layouts: {},
  blocks: {},
  content: {
    background: {
      image: '',
      color: '',
      opacity: 0,
      position: 'center center',
    },
    border: {
      width: 0,
      color: '#FFFFFF',
      radius: 0,
    },
  },
  numColumns: 24,
  visibility: 'all',
})

import React from 'react'
import {FeaturesBreadcrumbs} from 'organization/Event/Page/PageBreadcrumbs'
import Page from 'organization/Event/TemplateEditor/Page'
import ToggleVisibleSwitch from 'organization/Event/FAQsConfig/ToggleVisibleSwitch'
import {useObvioUser} from 'obvio/auth'
import {TemplateFAQs} from 'Event/FAQs'
import {useSaveTemplate} from 'Event/TemplateUpdateProvider'
import {useTemplate} from 'Event/TemplateProvider'
import {DropResult} from 'react-beautiful-dnd'
import {createPositions, orderedIdsByPosition} from 'lib/list'

export default function FAQsConfig() {
  const user = useObvioUser()

  return (
    <FeaturesBreadcrumbs page="FAQ">
      <Page toolbarItems={<ToggleVisibleSwitch />}>
        <TemplateFAQs user={user} />
      </Page>
    </FeaturesBreadcrumbs>
  )
}

export function useHandleDragFaqs() {
  const update = useSaveTemplate()

  const {
    faq: {items},
  } = useTemplate()

  return (result: DropResult) => {
    const {destination, source} = result

    if (!destination) {
      return
    }

    const ids = orderedIdsByPosition(items)
    const [removed] = ids.splice(source.index, 1)
    ids.splice(destination.index, 0, removed)
    update({
      faq: {
        items: createPositions(ids),
      },
    })
  }
}

import React from 'react'
import Page from 'organization/Event/TemplateEditor/Page'
import SelectTemplagePage from 'organization/Event/SelectTemplatePage'
import {useEvent} from 'Event/EventProvider'
import {FeaturesBreadcrumbs} from 'organization/Event/Page/PageBreadcrumbs'
import {TemplateBackgrounds} from 'Event/Backgrounds'
import {useObvioUser} from 'obvio/auth'
import {Ordered} from 'lib/list'
import {HasRules} from 'Event/attendee-rules'
import {Rule} from 'Event/attendee-rules'
import ToggleBackgroundsSwitch from 'organization/Event/BackgroundsConfig/ToggleBackgroundsSwitch'

export interface Background extends Ordered, HasRules {
  image: string
  rules?: Rule[]
}

export default function BackgroundsConfig() {
  const {event} = useEvent()
  const user = useObvioUser()

  if (!event.template) {
    return <SelectTemplagePage />
  }

  return (
    <FeaturesBreadcrumbs page="Zoom Backgrounds">
      <Page
        toolbarItems={
          <>
            <ToggleBackgroundsSwitch />
          </>
        }
      >
        <TemplateBackgrounds user={user} />
      </Page>
    </FeaturesBreadcrumbs>
  )
}

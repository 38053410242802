import {PurchasePageTemplate} from '../purchase-page'

export type BackgroundPosition =
  | 'left top'
  | 'center top'
  | 'right top'
  | 'left center'
  | 'center center'
  | 'right center'
  | 'left bottom'
  | 'center bottom'
  | 'right bottom'

export type BackgroundRepeatSetting =
  | 'none'
  | 'full'
  | 'full-center-parallax'
  | 'fill-width'
  | 'fill-width-height'
  | 'no-repeat'
  | 'repeat'
  | 'repeat-x'
  | 'repeat-y'

export function backgroundSize(repeat: BackgroundRepeatSetting) {
  switch (repeat) {
    case 'full':
    case 'full-center-parallax':
      return 'cover'
    case 'fill-width':
      return '100% auto'
    case 'fill-width-height':
      return '100% 100%'
  }
}

export function backgroundRepeat(repeat: BackgroundRepeatSetting) {
  switch (repeat) {
    case 'repeat':
      return 'repeat'
    case 'repeat-x':
      return 'repeat-x'
    case 'repeat-y':
    case 'fill-width':
      return 'repeat-y'
    default:
      return 'no-repeat'
  }
}

export function backgroundPosition(template: PurchasePageTemplate) {
  const {background} = template

  if (background.repeat === 'full-center-parallax') {
    return 'center'
  }

  return background.position
}

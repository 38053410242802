import React, {useState} from 'react'
import {useTemplate} from 'Event/TemplateProvider'
import {useSaveTemplate} from 'Event/TemplateUpdateProvider'
import Config from 'organization/Event/Configurable/Config'
import RulesPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/RulesPanel'

export default function RulesConfig() {
  const {login} = useTemplate()
  const saveTemplate = useSaveTemplate()

  const [rules, setRules] = useState(login.skipSetPasswordRules)

  const save = () => {
    saveTemplate({
      login: {
        skipSetPasswordRules: rules,
      },
    })
  }

  return (
    <Config title="Skip set password" onSave={save}>
      <RulesPanel
        rules={rules}
        setRules={setRules}
        configProps={{
          description: 'Set password will be skipped when',
        }}
      />
    </Config>
  )
}

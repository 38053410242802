import React from 'react'
import Settings from 'Event/template/Townhall/Dashboard/Footer/FooterConfig/Settings'
import Styling from 'Event/template/Townhall/Dashboard/Footer/FooterConfig/Styling'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import StylingPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/StylingPanel'
import Config from 'organization/Event/Configurable/Config'
import {useSaveTemplate} from 'Event/TemplateUpdateProvider'

export const DEFAULT_FOOTER_IMAGE_SIZE = 100

export function FooterConfig() {
  const save = useSaveTemplate()

  return (
    <Config title="Footer" onSave={save}>
      <SettingsPanel>
        <Settings />
      </SettingsPanel>
      <StylingPanel>
        <Styling />
      </StylingPanel>
    </Config>
  )
}

import React from 'react'
import styled from 'styled-components'
import TextField from 'lib/ui/TextField'
import Select from 'lib/ui/Select'
import Option from 'lib/ui/Select/Option'
import {Controller, UseFormMethods} from 'react-hook-form'
import {
  Box,
  TopDescription,
  RightDescription,
  FieldTitle,
} from 'lib/ui/TextField/DescribedTextField'
import {useSystemDomains} from 'lib/domains'

type UrlFieldProps = {
  className?: string
  title?: string
  description?: string
  textField?: JSX.Element
  control: UseFormMethods['control']
  register: UseFormMethods['register']
  errors: UseFormMethods['errors']
  submitting: boolean
  fullWidth?: boolean
}

export default function UrlField(props: UrlFieldProps) {
  const {
    className,
    title,
    description,
    control,
    register,
    errors,
    submitting,
    fullWidth,
  } = props

  return (
    <>
      <FieldTitle>{title}</FieldTitle>
      <Box fullWidth={fullWidth} className={className}>
        <TopDescription>{description}</TopDescription>
        <Text register={register} errors={errors} submitting={submitting} />
        <UrlInput control={control} />
        <RightDescription>{description}</RightDescription>
      </Box>
    </>
  )
}

function Text(props: {
  register: UseFormMethods['register']
  errors: UseFormMethods['errors']
  submitting: boolean
}) {
  const {register, errors, submitting} = props

  return (
    <StyledTextField
      name="slug"
      variant="outlined"
      required
      defaultValue=""
      fullWidth
      inputProps={{
        // Fix Webkit bug where 'align-items: center' cannot be correctly
        // calculated without a value.
        // Reference: https://github.com/philipwalton/flexbugs/issues/270#issuecomment-854631729
        placeholder: ' ',

        ref: register({
          required: 'Slug is required',
        }),
        'aria-label': 'domain slug',
      }}
      error={!!errors.slug}
      disabled={submitting}
    />
  )
}

function UrlInput(props: {control: UseFormMethods['control']}) {
  const systemDomains = useSystemDomains()
  return (
    <Controller
      name="system_domain_id"
      control={props.control}
      defaultValue={1}
      render={({onChange, value}) => (
        <StyledSelect
          fullWidth
          aria-label="domain"
          value={value}
          onChange={onChange}
        >
          {Object.entries(systemDomains).map(([id, name]) => (
            <Option key={id} value={id}>
              {name}
            </Option>
          ))}
        </StyledSelect>
      )}
    />
  )
}

const StyledTextField = styled(TextField)`
  max-width: 640px;
  margin-right: ${(props) => props.theme.spacing[5]};
`

const StyledSelect = styled(Select)`
  max-width: 640px;
  margin-top: ${(props) => props.theme.spacing[5]};

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    margin-top: 0;
  }
`

import React from 'react'
import styled from 'styled-components'
import {useEditMode} from 'Event/EditModeProvider'
import AddResourceButton from 'Event/template/Townhall/Dashboard/Main/ResourceSection/AddResourceButton'
import ResourceItem, {
  Resource,
} from 'Event/template/Townhall/Dashboard/Main/ResourceSection/ResourceItem'
import Grid from '@material-ui/core/Grid'
import {ResourceListConfig} from 'Event/template/Townhall/Dashboard/Main/ResourceSection/ResourceListConfig'
import {useHasVisibleItems} from 'Event/attendee-rules/matcher'
import {useTownhallUpdate} from 'Event/template/Townhall'
import {
  DragDropContext,
  Droppable,
  DroppableProvidedProps,
  DropResult,
} from 'react-beautiful-dnd'
import {createPositions, HashMap, orderedIdsByPosition} from 'lib/list'
import {HasRules} from 'Event/attendee-rules'
import {HasSchedule} from 'lib/ui/layout/Scheduled'
import Configurable from 'organization/Event/Configurable'
import SectionHeading from 'Event/template/Townhall/Dashboard/Main/SectionHeading'
import SectionDescription from 'Event/template/Townhall/Dashboard/Main/SectionDescription'
import {BaseSectionProps} from 'Event/template/Townhall/Dashboard/Main/section'

export const RESOURCE_SECTION = 'resource'
export type ResourceSectionProps = BaseSectionProps &
  HasRules &
  HasSchedule & {
    name: typeof RESOURCE_SECTION
    items: HashMap<Resource>
  }

export const createResourceSection = (): ResourceSectionProps => ({
  name: RESOURCE_SECTION,
  title: 'Resources',
  description: '',
  items: {},
  isEnabled: true,
  background: '#FFFFFF',
  color: null,
  font: null,
})

export const RESOURCE_ICON = {
  pdf: 'picture_as_pdf',
  attachment: 'attachment',
  people: 'people',
  image: 'image',
  photoLibrary: 'photo_library',
}

export default function ResourceSection(
  props: ResourceSectionProps & {sectionId: string},
) {
  const {items: resources} = props
  const isEditMode = useEditMode()
  const hasVisibleItems = useHasVisibleItems(Object.values(resources))

  if (isEditMode) {
    return <EditableList {...props} />
  }

  const showing = hasVisibleItems && props.isEnabled

  if (!showing) {
    return null
  }

  return (
    <>
      <SectionHeading color={props.color} aria-label="resources title">
        {props.title}
      </SectionHeading>
      <SectionDescription
        color={props.color}
        aria-label="resources description"
        description={props.description}
      />
      <Container>
        <ResourceItemList {...props} />
      </Container>
    </>
  )
}

function EditableList(props: ResourceSectionProps & {sectionId: string}) {
  return (
    <Configurable aria-label="resource section config">
      <ResourceListConfig section={props} id={props.sectionId} />
      <>
        <SectionHeading color={props.color} aria-label="resource title">
          {props.title}
        </SectionHeading>
        <SectionDescription
          color={props.color}
          aria-label="resource description"
          description={props.description}
        />
        <Container>
          <DroppableList {...props} />
        </Container>
        <StyledAddResourceButton sectionId={props.sectionId} />
      </>
    </Configurable>
  )
}

function DroppableList(props: ResourceSectionProps & {sectionId: string}) {
  const handleDrag = useHandleDrag({...props})

  return (
    <DragDropContext onDragEnd={handleDrag}>
      <Droppable droppableId="drag-and-drop-resources">
        {(provided) => (
          <Container ref={provided.innerRef} {...provided.droppableProps}>
            <>
              <ResourceItemList {...props} />
              {provided.placeholder}
            </>
          </Container>
        )}
      </Droppable>
    </DragDropContext>
  )
}

function ResourceItemList(props: ResourceSectionProps & {sectionId: string}) {
  const {items: resources, sectionId} = props

  if (!resources) {
    return null
  }

  const ids = orderedIdsByPosition(resources)

  return (
    <>
      {ids.map((id: string, index: number) => {
        const resource = resources[id]
        return (
          <ResourceItem
            key={index}
            id={id}
            sectionId={sectionId}
            resource={resource}
            index={index}
            draggableId={`resource-item-${index}`}
          />
        )
      })}
    </>
  )
}

function useHandleDrag(props: ResourceSectionProps & {sectionId: string}) {
  const updateTemplate = useTownhallUpdate()
  const {items: resources, sectionId} = props

  return (result: DropResult) => {
    const {destination, source} = result
    if (!destination) {
      return
    }

    const ids = orderedIdsByPosition(resources)
    const moved = Array.from(ids)

    const [removed] = moved.splice(source.index, 1)
    moved.splice(destination.index, 0, removed)

    updateTemplate({
      dashboardSections: {
        [sectionId]: {
          items: createPositions(moved),
        },
      },
    })
  }
}

const Container = React.forwardRef<
  HTMLDivElement,
  {
    className?: string
    children: React.ReactElement | React.ReactElement[]
  } & Partial<DroppableProvidedProps>
>((props, ref) => (
  <Box className={props.className} ref={ref} {...props}>
    <StyledGrid container justify="flex-start" spacing={1}>
      {props.children}
    </StyledGrid>
  </Box>
))

const Box = styled.div`
  margin-bottom: 30px;
  width: 100%;
`

const StyledAddResourceButton = styled(AddResourceButton)`
  margin-bottom: ${(props) => props.theme.spacing[6]}!important;
  margin-top: ${(props) => props.theme.spacing[5]}!important;
`
const StyledGrid = styled(Grid)`
  width: 100%;
  margin: 0;
`

import React from 'react'
import {ComponentConfigProps} from 'organization/Event/DashboardConfig/ConfigDialog'
import {Controller} from 'react-hook-form'
import InputLabel from 'lib/ui/InputLabel'
import Select from 'lib/ui/Select'
import MenuItem from '@material-ui/core/MenuItem'
import {onUnknownChangeHandler} from 'lib/dom'
import FormControl from 'lib/ui/FormControl'
import {useEditMode} from 'Event/EditModeProvider'
import {
  BlogSectionProps,
  TownhallBlogPost,
} from 'Event/template/Townhall/Dashboard/Main/BlogSection'
import {REMOVE, useSaveTemplate} from 'Event/TemplateUpdateProvider'
import DefaultBlogPostConfig from 'Event/Dashboard/components/BlogPosts/BlogPostConfig'
import {DeepRequired} from 'lib/type-utils'
import {uuid} from 'lib/uuid'
import ImageAssetUploader from 'lib/asset/ImageAssetUploader'

export const DEFAULT_MODAL_BUTTON_TEXT = 'Submit'

export function EditPost(
  props: BlogSectionProps & {
    id: string | null
    sectionId: string
    onClose: () => void
  },
) {
  const {id, sectionId, onClose} = props
  const isEditMode = useEditMode()

  if (!id || !isEditMode) {
    return null
  }

  const post = props.items[id] as DeepRequired<TownhallBlogPost>
  if (!post) {
    return null
  }

  return (
    <BlogPostConfig
      {...props}
      id={id}
      sectionId={sectionId}
      isVisible
      post={post}
      onClose={onClose}
    />
  )
}

export default function BlogPostConfig(
  props: ComponentConfigProps &
    BlogSectionProps & {
      post: TownhallBlogPost
      sectionId: string
      id?: string
    },
) {
  const {isVisible, onClose, id, sectionId, post} = props

  const save = useSaveTemplate()

  const update = (id: string, data: Partial<TownhallBlogPost>) => {
    save({
      dashboardSections: {
        [sectionId]: {
          items: {
            [id]: {
              ...post,
              ...data,
            },
          },
        },
      },
    })
  }

  const insert = (data: TownhallBlogPost) => {
    const id = uuid()

    save({
      dashboardSections: {
        [sectionId]: {
          items: {
            [id]: data,
          },
        },
      },
    })
  }

  const remove = (id: string) => {
    save({
      dashboardSections: {
        [sectionId]: {
          items: {
            [id]: REMOVE,
          },
        },
      },
    })
  }

  return (
    <DefaultBlogPostConfig
      showing={isVisible}
      onClose={onClose}
      onUpdate={update}
      onInsert={insert}
      onRemove={remove}
      post={post}
      id={id}
      additionalInputs={({control}) => (
        <>
          <InputLabel>Image</InputLabel>
          <Controller
            name="image"
            control={control}
            defaultValue={post.image}
            render={({onChange, value}) => (
              <ImageAssetUploader
                onChange={onChange}
                value={value}
                uploadLabel="Upload"
                canResize
                uploadInputProps={{
                  'aria-label': 'blog image',
                }}
                disableAutoRemove
              />
            )}
          />
          <InputLabel>Select Level</InputLabel>
          <Controller
            name="level"
            control={control}
            defaultValue={post.level}
            render={({onChange, value}) => (
              <FormControl fullWidth>
                <Select
                  value={value}
                  fullWidth
                  aria-label="select blog post level"
                  onChange={onUnknownChangeHandler(onChange)}
                >
                  <MenuItem value={1}>One Per Row</MenuItem>
                  <MenuItem value={2}>Two Per Row</MenuItem>
                  <MenuItem value={3}>Three Per Row</MenuItem>
                  <MenuItem value={4}>Four Per Row</MenuItem>
                </Select>
              </FormControl>
            )}
          />
        </>
      )}
    />
  )
}

import axios from 'axios'
import {useOrgMarketplaceToken} from 'lib/event-api/organization/use-org-marketplace-token'
import {Client} from 'lib/ui/api-client'
import {useMarketplaceClient} from 'lib/marketplace-api/use-marketplace-client'
import {useOrganization} from 'organization/OrganizationProvider'
import {useCallback} from 'react'
import {useQueryClient} from 'react-query'

export function useOrgMarketplaceClient():
  | {isLoading: true; client: null}
  | {isLoading: false; client: Client} {
  const {client: orgClient, organization} = useOrganization()
  const {id: orgId} = organization
  const qc = useQueryClient()

  const {data: token, isFetching, isLoading} = useOrgMarketplaceToken({
    organization,
    client: orgClient,
  })

  const handleError = useCallback(
    (error: unknown) => {
      if (axios.isAxiosError(error)) {
        if (axios.isAxiosError(error) && error.response?.status === 401) {
          // Force re-fetch token
          qc.invalidateQueries(['organizations', orgId, 'marketplace', 'token'])
        }
      }
    },
    [qc, orgId],
  )

  const client = useMarketplaceClient({token, handleError})

  if (!client || isLoading || isFetching) {
    return {
      isLoading: true,
      client: null,
    }
  }

  return {
    isLoading: false,
    client,
  }
}

import {BlockComponentProps} from 'Event/Marketplace/Block'
import Container from 'Event/Marketplace/Block/Container'
import {VideoBlock} from 'Event/Marketplace/Block/Video'
import React, {useMemo, useState} from 'react'
import styled from 'styled-components'

interface VideoSize {
  width: number
  height: number
}

interface VideoContainerProps extends VideoBlock {
  children: (size: VideoSize) => JSX.Element
}

export default function VideoContainer(
  props: VideoContainerProps & BlockComponentProps,
) {
  const {children, ...videoBlockProps} = props
  const [container, setContainer] = useState<HTMLDivElement | null>(null)
  const {
    aspectRatio: {value: aspectRatio, customHeightRatio, customWidthRatio},
    padding,
  } = videoBlockProps

  const width = container?.clientWidth ?? 0
  const height = container?.clientHeight ?? 0

  const size = useMemo(() => {
    if (!aspectRatio) {
      return {
        width: 100,
        height: 100,
      }
    }

    if (aspectRatio === '1:1') {
      return resize({
        width,
        height,
        widthRatio: 1,
        heightRatio: 1,
        padding,
      })
    }

    if (aspectRatio === '16:9') {
      return resize({
        width,
        height,
        widthRatio: 16,
        heightRatio: 9,
        padding,
      })
    }

    if (aspectRatio === '9:16') {
      return resize({
        width,
        height,
        widthRatio: 9,
        heightRatio: 16,
        padding,
      })
    }

    return resize({
      width,
      height,
      widthRatio: customWidthRatio,
      heightRatio: customHeightRatio,
      padding,
    })
  }, [aspectRatio, customHeightRatio, customWidthRatio, padding, width, height])

  return (
    <StyledBlockContainer {...videoBlockProps} ref={setContainer}>
      {children(size)}
    </StyledBlockContainer>
  )
}

function resize(params: {
  widthRatio: number
  heightRatio: number
  height: number
  width: number
  padding: {
    top: number
    right: number
    bottom: number
    left: number
  }
}) {
  const {height, heightRatio, width, widthRatio, padding} = params
  const unitHeight = (height - padding.top - padding.bottom) / heightRatio
  const unitWidth = (width - padding.left - padding.right) / widthRatio

  const unitPx = Math.min(unitHeight, unitWidth)
  return {
    width: unitPx * widthRatio,
    height: unitPx * heightRatio,
  }
}

const StyledBlockContainer = styled(Container)<VideoBlock>`
  min-height: 100px;
`

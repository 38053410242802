import React, {useRef, useState} from 'react'
import styled, {useTheme} from 'styled-components'
import CircularSlider from '@fseehawer/react-circular-slider'
import {onUnknownChangeHandler} from 'lib/dom'
import {ReactComponent as DarkIcon} from './icons/circle-dark.svg'
import {ReactComponent as LightIcon} from './icons/circle-light.svg'
import InputLabelBase from 'lib/ui/InputLabel'

type RotationInputProps = {
  value?: number
  onChange: (value: number) => void
  inputProps?: {
    'aria-label'?: string
  }
  disabled?: boolean
  label?: string
}

export default function RotationInput(props: RotationInputProps) {
  const {onChange, disabled} = props
  const theme = useTheme()

  const [focused, setFocused] = useState(false)
  const toggleFocused = () => setFocused(!focused)

  const inputRef = useRef<HTMLInputElement>(null)

  const value = props.value || 0

  const handleClickInput = () => {
    if (!inputRef.current) {
      return
    }

    inputRef.current.focus()
  }

  const inputBorderColor = useInputBorderColor(focused)

  return (
    <Box>
      <InputLabel>{props.label}</InputLabel>
      <Container>
        <CircleBox>
          <CirclePositionAdjustment>
            <CircularSlider
              width={20}
              progressSize={2}
              trackSize={2}
              knobSize={8}
              knobColor={theme.colors.text.primary}
              trackColor="transparent"
              progressColorFrom="transparent"
              progressColorTo="transparent"
              hideLabelValue
              onChange={onChange}
              dataIndex={value}
              knobDraggable={!props.disabled}
            >
              <CircleIcon />
            </CircularSlider>
          </CirclePositionAdjustment>
        </CircleBox>
        <InputBox onClick={handleClickInput} borderColor={inputBorderColor}>
          <Input
            type="number"
            value={value}
            ref={inputRef}
            min={0}
            max={360}
            onChange={onUnknownChangeHandler(onChange)}
            onFocus={toggleFocused}
            onBlur={toggleFocused}
            disabled={disabled}
            aria-label={props.inputProps?.['aria-label'] || ''}
          />
          <Label>DEG</Label>
        </InputBox>
      </Container>
    </Box>
  )
}

function InputLabel(props: {children?: string}) {
  if (!props.children) {
    return null
  }

  return <StyledInputLabel>{props.children}</StyledInputLabel>
}

function CircleIcon() {
  const theme = useTheme()

  if (theme.name === 'dark') {
    return <LightIcon />
  }

  return <DarkIcon />
}

function useInputBorderColor(focused: boolean) {
  const theme = useTheme()

  if (focused) {
    return theme.colors.primary
  }

  if (theme.name === 'dark') {
    return 'transparent'
  }

  return theme.colors.gray
}

const Box = styled.div`
  margin-bottom: ${(props) => props.theme.spacing[5]};
`

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
`

const CircleBox = styled.div`
  width: 34px;
  height: 34px;
  background-color: ${(props) => props.theme.colors.input.background};
  border-radius: 34px;
  border: 1px solid
    ${(props) =>
      props.theme.name === 'dark' ? 'transparent' : props.theme.colors.gray};
  display: flex;
  align-items: center;
  justify-content: center;
`

const CirclePositionAdjustment = styled.div`
  margin-top: -14px;
`

const InputBox = styled.div<{
  borderColor: string
}>`
  height: 34px;
  background-color: ${(props) => props.theme.colors.input.background};
  padding: 11px 8px;
  border-radius: ${(props) => props.theme.spacing[1]};
  border: 1px solid ${(props) => props.borderColor};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 0;
`

const Input = styled.input`
  color: ${(props) => props.theme.colors.text.primary};
  background-color: ${(props) => props.theme.colors.input.background};
  padding: 0;
  outline: none;
  font-size: 14px;
  line-height: 16px;
  font-weight: 900;
  border: none;
  &:focus {
    outline: none;
  }
  -moz-appearance: textfield;
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
    width: 0;
  }
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    width: 0;
  }
`

const Label = styled.span`
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
  color: ${(props) => props.theme.colors.text.primary};
  display: block;
`

const StyledInputLabel = styled(InputLabelBase)`
  display: block;
  margin-bottom: ${(props) => props.theme.spacing[3]};
`

import React from 'react'
import {useBroadcastForm} from 'organization/Event/Broadcasts/BroadcastForm'
import MenuItem from 'lib/ui/Menu/MenuItem'
import MenuButton from 'lib/ui/MenuButton'
import {usePaymentMethods} from 'lib/event-api/organization/payment_methods/list'
import {useDefaultPaymentMethod} from 'obvio/Billing/DefaultPaymentMethodProvider'

export default function SaveSmsButton(props: {
  readOnly?: boolean
  disabled?: boolean
}) {
  const {disabled} = props
  const {
    hasAgreedToSmsCharges,
    toggleCreditCardForm,
    paymentMethodId,
    setPaymentMethodId,
  } = useBroadcastForm()
  const {paymentMethod: defaultPaymentMethod} = useDefaultPaymentMethod()

  const {data: paymentMethods, isFetching} = usePaymentMethods()

  const isPayingWithDefault = paymentMethodId === null
  const activePaymentMethod = isPayingWithDefault
    ? defaultPaymentMethod
    : paymentMethods?.find((pm) => pm.id === paymentMethodId)

  const canSubmit =
    !isFetching && !disabled && hasAgreedToSmsCharges && !!activePaymentMethod

  const additionalPaymentMethods =
    paymentMethods?.filter((pm) => pm.id !== defaultPaymentMethod?.id) ?? []

  const label = () => {
    if (isFetching) {
      return '...'
    }

    return isPayingWithDefault
      ? `Schedule to pay with ${activePaymentMethod?.card?.brand} card on file ending in ${activePaymentMethod?.card?.last4}`
      : `Schedule to pay with ${activePaymentMethod?.card?.brand} card ending in ${activePaymentMethod?.card?.last4}`
  }

  return (
    <MenuButton
      label={label()}
      color="success"
      variant="contained"
      fullWidth
      disabled={canSubmit === false}
      type="submit"
      MenuProps={{
        transformOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      }}
      ArrowButtonProps={{
        'aria-label': 'use other card',
      }}
    >
      {[
        <MenuItem key="use_default" onClick={() => setPaymentMethodId(null)}>
          Default Card
        </MenuItem>,
        ...additionalPaymentMethods.map((pm) => (
          <MenuItem key={pm.id} onClick={() => setPaymentMethodId(pm.id)}>
            {`${pm.card?.brand ?? ''} ending in ${pm.card?.last4 ?? ''}`}
          </MenuItem>
        )),
        <MenuItem key="add_another_card" onClick={toggleCreditCardForm}>
          Charge a Different Card
        </MenuItem>,
      ]}
    </MenuButton>
  )
}

import React, {useEffect, useState, useCallback} from 'react'
import {NavButtonWithSize} from 'Event/Dashboard/components/NavButton'
import {ComponentConfigProps} from 'organization/Event/DashboardConfig/ComponentConfigPanel'
import {useForm} from 'react-hook-form'
import {v4 as uuid} from 'uuid'
import {REMOVE, useRemoveIfEmpty} from 'Event/TemplateUpdateProvider'
import {useEditSidebarItem} from 'Event/template/SimpleBlog/Dashboard/Sidebar/SidebarItem'
import SidebarNavButtonConfigBase from 'Event/Dashboard/components/Sidebar/SidebarNavButtonConfig'
import {usePruneAssets} from 'lib/asset'
import {SidebarNavProps} from 'Event/template'

export function SidebarNavButtonConfig(
  props: ComponentConfigProps & {
    button: NavButtonWithSize
    id?: string
    nav: SidebarNavProps
  },
) {
  const {button, id, showing, onClose} = props

  const [rules, setRules] = useState(button.rules)
  const {update: updateItem} = useEditSidebarItem()
  const pruneAssets = usePruneAssets()

  useEffect(() => {
    if (showing) {
      return
    }

    setRules(button.rules)
  }, [button, props.id, showing])

  const {
    control,
    register,
    handleSubmit,
    setValue,
    watch,
    formState,
  } = useForm()

  const update = (id: string, updated: NavButtonWithSize) => {
    const image = updated.image

    pruneAssets({image}, button)

    updateItem({
      buttons: {
        [id]: updated,
      },
    })
  }

  const removeButton = useCallback(() => {
    if (!id) {
      throw new Error('Missing id')
    }

    onClose()

    updateItem({
      buttons: {
        [id]: REMOVE,
      },
    })
  }, [id, onClose, updateItem])

  useRemoveIfEmpty(removeButton, button, {shouldSkip: !id})

  const insert = (button: NavButtonWithSize) => {
    const id = uuid()

    updateItem({
      buttons: {
        [id]: button,
      },
    })
  }

  const save = (formData: any) => {
    const button: NavButtonWithSize = {
      ...formData,
      rules,
    }

    if (id) {
      update(id, button)
      onClose()
      return
    }

    insert(button)
    onClose()
  }

  return (
    <SidebarNavButtonConfigBase
      button={button}
      id={id}
      watch={watch}
      control={control}
      register={register}
      setValue={setValue}
      rules={rules}
      setRules={setRules}
      removeButton={removeButton}
      handleSubmit={handleSubmit}
      save={save}
      showing={showing}
      onClose={onClose}
      formState={formState}
    />
  )
}

import React from 'react'
import styled from 'styled-components'
import {Label as Typography} from 'lib/ui/typography'

export type CheckboxProps = {
  className?: string
  label?: string
  checked?: boolean
  disabled?: boolean
  'aria-label'?: string
  onChange?: (v: boolean) => void
}

export default function Checkbox(props: CheckboxProps) {
  const {label, checked, disabled, onChange} = props

  const toggle = () => {
    if (!onChange || disabled) {
      return
    }

    onChange(!checked)
  }

  return (
    <Label className={props.className} onClick={toggle} disabled={disabled}>
      {label}
      <Input
        type="checkbox"
        aria-label={props['aria-label']}
        checked={checked}
        readOnly
        disabled={disabled}
      />
      <Span disabled={disabled} />
    </Label>
  )
}

const Label = styled(Typography)<{
  disabled?: boolean
}>`
  display: block;
  position: relative;
  padding-left: 32px;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  user-select: none;

  color: ${(props) =>
    props.disabled
      ? props.theme.colors.disabled
      : props.theme.colors.text.primary};

  &::hover {
    + span {
      background-color: #ccc;
    }
  }
  input:checked {
    + span {
      background-color: ${(props) => props.theme.colors.primary};
      &::after {
        display: block;
      }
    }
  }
`

const Input = styled.input`
  position: absolute;
  opacity: 0;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  height: 0;
  width: 0;
`

const Span = styled.span<{
  disabled?: boolean
}>`
  position: absolute;
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  border: 1px solid
    ${(props) =>
      props.disabled
        ? props.theme.colors.disabled
        : props.theme.colors.primary};
  border-radius: 4px;
  &:after {
    content: '';
    position: absolute;
    display: none;
    left: 5px;
    top: 1px;
    width: 4px;
    height: 8px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`

import {useEvent} from 'Event/EventProvider'
import {useIsMounted} from 'lib/dom'
import {AbsoluteLink} from 'lib/ui/link/AbsoluteLink'
import {api} from 'lib/url'
import {useEventRoutes} from 'organization/Event/EventRoutes'
import Page from 'organization/Event/Page'
import {useServices} from 'organization/Event/Services/ServicesProvider'
import {useOrganization} from 'organization/OrganizationProvider'
import React, {useEffect, useState} from 'react'
import {HubspotIntegration} from 'organization/Event/Services/Apps/Hubspot/index'
import {useRedirectUrl} from 'organization/Event/Services/Apps/Hubspot/LinkPage'
import {ObvioEvent} from 'Event'

export type AuthCallback = {
  integration: HubspotIntegration
  event: ObvioEvent
}

export default function AuthPage(props: {authCode: string}) {
  const [hasSubmitted, setHasSubmitted] = useState(false)
  const [failed, setFailed] = useState(false)
  const completeAuth = useCompleteAuth()
  const {update: updateIntegration} = useServices()
  const {authCode} = props
  const isMounted = useIsMounted()
  const {set: setEvent, event} = useEvent()
  const routes = useEventRoutes()

  useEffect(() => {
    if (hasSubmitted) {
      return
    }

    setHasSubmitted(true)

    completeAuth(authCode)
      .then(({event, integration}) => {
        updateIntegration(integration)
        setEvent(event)
      })
      .catch((e) => {
        console.error(e)

        if (!isMounted.current) {
          return
        }

        setFailed(true)
      })
  }, [
    authCode,
    completeAuth,
    hasSubmitted,
    updateIntegration,
    isMounted,
    setEvent,
    event,
  ])

  if (failed) {
    return (
      <Page>
        <p>Error: could not authenticate with Hubspot</p>
        <p>
          <AbsoluteLink to={routes.services.hubspot}>Click here</AbsoluteLink>{' '}
          to go back.
        </p>
      </Page>
    )
  }

  return (
    <Page>
      <div>processing...</div>
    </Page>
  )
}

function useCompleteAuth() {
  const {event} = useEvent()
  const {client} = useOrganization()
  const url = api(`/events/${event.id}/integrations/hubspot/auth`)
  const redirectUrl = useRedirectUrl()

  return (authCode: string) =>
    client.post<AuthCallback>(url, {
      auth_code: authCode,
      redirect_url: redirectUrl,
    })
}

import {SYSTEM_DOMAINS} from 'lib/url'
import {useHasOrganizationFeatureToggle} from 'organization/OrganizationFeatureToggle'

export function useSystemDomains() {
  const hasSagehub = useHasOrganizationFeatureToggle('sagehub')

  if (hasSagehub) {
    return SYSTEM_DOMAINS
  }

  const {5: _, ...publicDomains} = SYSTEM_DOMAINS
  return publicDomains
}

import React from 'react'
import Slider from 'lib/ui/Slider'
import {Controller, UseFormMethods} from 'react-hook-form'
import {
  NavButtonWithSize,
  DEFAULT_BUTTON_HEIGHT,
} from 'Event/Dashboard/components/NavButton'
import Checkbox from 'lib/ui/Checkbox'
import CollapsibleSection from 'lib/ui/ConfigPanel/CollapsibleSection'
import styled from 'styled-components'

type SizeSectionProps = {
  control: UseFormMethods['control']
  button: Pick<NavButtonWithSize, 'height' | 'width' | 'size' | 'newLine'>
  storageKey: string
  disableNewLine?: boolean
  sizeUnit?: 'grid' | 'percent'
  namePrefix?: string
}

export default function SizeSection(props: SizeSectionProps) {
  const {button, control, namePrefix} = props

  return (
    <CollapsibleSection label="Size" storageKey={props.storageKey}>
      <Content>
        <Controller
          name={namePrefix ? `${namePrefix}.height` : 'height'}
          defaultValue={button.height || DEFAULT_BUTTON_HEIGHT}
          control={control}
          render={({value, onChange}) => (
            <Slider
              min={1}
              max={400}
              onChange={onChange}
              valueLabelDisplay="off"
              value={value}
              label="Height"
            />
          )}
        />
        <SizeConfig {...props} />
        <NewLineConfig {...props} />
      </Content>
    </CollapsibleSection>
  )
}

function NewLineConfig(props: {
  control: UseFormMethods['control']
  button: Pick<NavButtonWithSize, 'newLine'>
  disableNewLine?: boolean
  namePrefix?: string
}) {
  const {button, control, disableNewLine, namePrefix} = props

  if (disableNewLine) {
    return null
  }

  return (
    <Controller
      name={namePrefix ? `${namePrefix}.newLine` : 'newLine'}
      defaultValue={button.newLine || false}
      control={control}
      render={({value, onChange}) => (
        <Checkbox
          label="New Line"
          checked={value}
          aria-label="toggle new line"
          onChange={onChange}
        />
      )}
    />
  )
}

function SizeConfig(props: {
  control: UseFormMethods['control']
  button: Pick<NavButtonWithSize, 'width' | 'size'>
  sizeUnit?: SizeSectionProps['sizeUnit']
  namePrefix?: string
}) {
  const {button, control, sizeUnit = 'grid', namePrefix} = props

  return (
    <>
      <SizeSlider
        showing={sizeUnit === 'percent'}
        name={namePrefix ? `${namePrefix}.width` : 'width'}
        control={control}
        defaultValue={button.width || 100}
        unit="%"
        max={100}
      />
      <SizeSlider
        showing={sizeUnit === 'grid'}
        name={namePrefix ? `${namePrefix}.size` : 'size'}
        control={control}
        defaultValue={button.size || 12}
        max={12}
      />
    </>
  )
}

function SizeSlider(props: {
  control: UseFormMethods['control']
  sizeUnit?: SizeSectionProps['sizeUnit']
  showing: boolean
  defaultValue: number
  unit?: string
  name: string
  max: number
}) {
  const {showing, control, defaultValue, unit, max, name} = props
  if (!showing) {
    return null
  }

  return (
    <>
      <Controller
        name={name}
        defaultValue={defaultValue || 0}
        control={control}
        render={({value, onChange}) => (
          <Slider
            min={1}
            max={max}
            onChange={onChange}
            valueLabelDisplay="off"
            value={value}
            unit={unit}
            hideUnits={Boolean(unit)}
            label="Width"
          />
        )}
      />
    </>
  )
}

const Content = styled.div`
  margin-bottom: ${(props) => props.theme.spacing[4]};
`

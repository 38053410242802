import React from 'react'
import {Redirect} from 'react-router'
import {useOrganization} from 'organization/OrganizationProvider'
import PurchasePageConfigLoader from 'organization/Marketplace/PurchasePageConfig/PurchasePageConfigLoader'
import useAutoCreatePurchasePage from 'organization/Marketplace/PurchasePageConfig/use-auto-create-purchase-page'
import TemplateUpdateProvider from 'organization/Marketplace/PurchasePageConfig/TemplateUpdateProvider'
import TemplateEditor from 'organization/Event/TemplateEditor'
import Content from 'organization/Marketplace/PurchasePageConfig/Content'
import {useMarketplaceAccount} from 'lib/marketplace-api/accounts/use-marketplace-account'
import {useEventMarketplaceClient} from 'lib/marketplace-api/use-event-marketplace-client'
import PurchaseProvider from 'Event/Marketplace/PurchaseProvider'
import PurchasePageConfigProvider from 'organization/Marketplace/PurchasePageConfig/PurchasePageConfigProvider'
import {fillTemplate} from 'Event/Marketplace/lib/fill-template'

export default function PurchasePageConfig() {
  const {data: account} = useMarketplaceAccount()
  const {purchasePage} = useAutoCreatePurchasePage()

  const {routes} = useOrganization()

  const {client: marketplaceClient} = useEventMarketplaceClient()

  if (account === null) {
    return <Redirect to={routes.settings} />
  }

  if (!account || !purchasePage || !marketplaceClient) {
    return <PurchasePageConfigLoader />
  }

  return (
    <PurchaseProvider onSubmit={() => {}}>
      <PurchasePageConfigProvider>
        <TemplateUpdateProvider
          purchasePage={purchasePage}
          client={marketplaceClient}
        >
          {(template) => (
            <TemplateEditor template={template}>
              {(template) => <Content template={fillTemplate(template)} />}
            </TemplateEditor>
          )}
        </TemplateUpdateProvider>
      </PurchasePageConfigProvider>
    </PurchaseProvider>
  )
}

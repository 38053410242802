import React from 'react'
import Dialog from 'lib/ui/Dialog'
import {useToggleArray} from 'lib/toggle'
import {PlanAddOnDetails} from 'obvio/Billing/plans'
import {OtherCardForm} from 'organization/AddOns/PurchaseAddOnPage/ConfirmDialog/OtherCardForm'
import {SavedCardConfirmation} from 'organization/AddOns/PurchaseAddOnPage/ConfirmDialog/SavedCardConfirmation'

export type ConfirmationProps = {
  quantity: number
  duration: number
  addOnDetail: PlanAddOnDetails
  addOnKey: string
  blockLabel: string
  onClose: () => void
  price: number
  onSuccess: () => void
}

export default function ConfirmDialog(
  props: ConfirmationProps & {
    showing: boolean
    onClose: () => void
  },
) {
  const {showing: _, ...confirmationProps} = props

  return (
    <Dialog open={props.showing} onClose={props.onClose}>
      <Content {...confirmationProps} />
    </Dialog>
  )
}

function Content(props: ConfirmationProps) {
  const [showingOtherCardForm, toggleOtherCardForm] = useToggleArray()

  if (showingOtherCardForm) {
    return <OtherCardForm {...props} onCancel={toggleOtherCardForm} />
  }

  return (
    <SavedCardConfirmation {...props} onUseOtherCard={toggleOtherCardForm} />
  )
}

import React from 'react'
import {ObvioEvent} from 'Event'
import ActionsProvider from 'Event/ActionsProvider'
import Page from 'organization/Event/TemplateEditor/Page'
import {TemplateTechCheck} from 'Event/Step3/TechCheck'
import {useObvioUser} from 'obvio/auth'
import {ToggleTechCheckSwitch} from 'organization/Event/TechCheckConfig/ConfigFields/ToggleTechCheckSwitch'
import RulesConfig from 'organization/Event/TechCheckConfig/ConfigFields/RulesConfig'
import {ConfigButton} from 'organization/Event/Configurable'
import Button from 'lib/ui/Button'
import {useHasTechCheck} from 'Event/EventProvider'
import {CheckInBreadcrumbs} from 'organization/Event/Page/PageBreadcrumbs'
import FullPageLoader from 'lib/ui/layout/FullPageLoader'

export interface TechCheckData {
  body: string
  start: string
  is_enabled: boolean
  template: ObvioEvent['template']
}

export default function TechCheckConfig() {
  const user = useObvioUser()

  return (
    <CheckInBreadcrumbs page="Tech Check">
      <Page
        toolbarItems={
          <>
            <ToggleTechCheckSwitch />
            <SkipTechCheckRulesButton />
          </>
        }
      >
        <ActionsProvider loader={<FullPageLoader />}>
          <TemplateTechCheck user={user} />
        </ActionsProvider>
      </Page>
    </CheckInBreadcrumbs>
  )
}

function SkipTechCheckRulesButton() {
  const hasTechCheck = useHasTechCheck()

  return (
    <ConfigButton config={<RulesConfig />}>
      {(onToggle) => (
        <Button
          variant="text"
          size="small"
          type="button"
          onClick={onToggle}
          disabled={!hasTechCheck}
        >
          Skip Tech Check Rules
        </Button>
      )}
    </ConfigButton>
  )
}

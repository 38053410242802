import React from 'react'
import {User} from 'auth/user'
import {useEditMode} from 'Event/EditModeProvider'
import Configurable from 'organization/Event/Configurable'
import TextField from 'Event/auth/TextField'
import InputFieldConfig from 'Event/Step2/Waivers/Waiver/Input/InputFieldConfig'
import {useAttendeeVariables} from 'Event'
import {useTemplate} from 'Event/TemplateProvider'
import {useWaiver} from 'Event/Step2/Waivers/Waiver'

export default function FirstName(props: {user: User}) {
  const {user} = props
  const {
    form: {register, errors},
  } = useWaiver()

  const isEditMode = useEditMode()
  const v = useAttendeeVariables()
  const {waiver} = useTemplate()

  const hasName = user.first_name && user.last_name
  if (!isEditMode && hasName) {
    return null
  }

  return (
    <Configurable>
      <InputFieldConfig />
      <TextField
        variant="outlined"
        fullWidth
        placeholder={waiver.firstNameInputLabel}
        label={v(waiver.firstNameInputLabel)}
        name="first_name"
        inputProps={{
          ref: register({required: 'First name is required'}),
          'aria-label': 'first name',
        }}
        defaultValue={user.first_name}
        error={Boolean(errors.first_name)}
        helperText={errors.first_name}
        {...waiver.input}
      />
    </Configurable>
  )
}

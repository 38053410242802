import {marketplaceApi} from 'lib/marketplace-api/url'
import {useOrgMarketplaceClient} from 'lib/marketplace-api/use-org-marketplace-client'
import {useOrganization} from 'organization/OrganizationProvider'
import {useMutation, useQueryClient} from 'react-query'

export interface AddMarketplaceAccountData {
  email: string
}

interface AddMarketplaceAccountResponse {
  account_id: number
  created: number
  expires_at: number
  object: string
  url: string
}

export function useAddMarketplaceAccount() {
  const {client} = useOrgMarketplaceClient()
  const qc = useQueryClient()
  const {organization} = useOrganization()

  return useMutation({
    mutationFn: (data: AddMarketplaceAccountData) => {
      const url = marketplaceApi('/accounts')
      return client!.post<AddMarketplaceAccountResponse>(url, {
        email: data.email,
        organization_id: organization.id,
      })
    },
    onSuccess: (account) => {
      qc.setQueryData(['marketplace', 'account'], () => account)
    },
  })
}

import ColorPicker from 'lib/ui/ColorPicker'
import {useTownhallTemplate} from 'Event/template/Townhall'
import React from 'react'
import {Controller} from 'react-hook-form'
import CollapsibleSection from 'lib/ui/ConfigPanel/CollapsibleSection'
import Slider from 'lib/ui/Slider'
import {useConfig} from 'organization/Event/Configurable'

export default function Styling() {
  const {
    form: {control, watch},
  } = useConfig()
  const template = useTownhallTemplate()

  const headerMinHeight = watch('header.minHeight')

  return (
    <>
      <CollapsibleSection label="Header" storageKey="dashboard.header.styling">
        <Controller
          name="header.backgroundColor"
          defaultValue={template.header.backgroundColor}
          control={control}
          render={({value, onChange}) => (
            <ColorPicker
              label="Color"
              color={value}
              onPick={onChange}
              aria-label="header background color"
            />
          )}
        />
        <Controller
          name="header.backgroundOpacity"
          defaultValue={template.header.backgroundOpacity || 0}
          control={control}
          render={({value, onChange}) => (
            <Slider
              label="Opacity"
              min={0}
              max={100}
              step={1}
              onChange={onChange}
              valueLabelDisplay="auto"
              value={value}
              aria-label="background opacity"
              unit="%"
            />
          )}
        />
        <Controller
          name="header.minHeight"
          defaultValue={template.header.minHeight}
          control={control}
          render={({value, onChange}) => (
            <Slider
              label="Minimum height"
              min={0}
              max={1000}
              step={1}
              onChange={onChange}
              valueLabelDisplay="auto"
              valueLabelFormat={() => <div>{headerMinHeight}</div>}
              value={value}
              aria-label="minimum height"
              unit="px"
            />
          )}
        />
      </CollapsibleSection>
      <CollapsibleSection label="Page" storageKey="dashboard.page.styling">
        <Controller
          name="pageBackground.color"
          defaultValue={template.pageBackground?.color}
          control={control}
          render={({value, onChange}) => (
            <ColorPicker
              label="Color"
              color={value}
              onPick={onChange}
              aria-label="page background color"
            />
          )}
        />
        <Controller
          name="pageBackground.opacity"
          defaultValue={template.pageBackground?.opacity || 0}
          control={control}
          render={({value, onChange}) => (
            <Slider
              label="Opacity"
              min={0}
              max={100}
              step={1}
              onChange={onChange}
              valueLabelDisplay="auto"
              value={value}
              aria-label="page background color opacity"
              unit="%"
            />
          )}
        />
      </CollapsibleSection>
    </>
  )
}

import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import CustomButtonTextEditing from './custombuttontextediting';
import CustomButtonTextUI from './custombuttontextui';

export default class CustomButtonText extends Plugin {
	static get requires() {
		return [ CustomButtonTextEditing, CustomButtonTextUI ];
	}

	static get pluginName() {
		return 'CustomButtonText';
	}
}

import React from 'react'
import {useTemplate} from 'Event/TemplateProvider'
import Configurable from 'organization/Event/Configurable'
import SubmitButtonConfig from 'Event/Step1/SubmitButton/SubmitButtonConfig'
import {Button} from 'Event/Dashboard/components/NavButton'
import styled from 'styled-components'

export default function SubmitButton() {
  const {setPasswordForm} = useTemplate()

  return (
    <Configurable>
      <SubmitButtonConfig />
      <Box>
        <Button {...setPasswordForm.button} type="submit" />
      </Box>
    </Configurable>
  )
}

const Box = styled.div`
  display: flex;
  justify-content: center;
`

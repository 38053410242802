import {useAttendeeVariables} from 'Event'
import AdditionalContentConfig from 'Event/Step3/TechCheck/AdditionalContent/AdditionalContentConfig'
import {useTemplate} from 'Event/TemplateProvider'
import Content from 'lib/ui/form/TextEditor/Content'
import Configurable from 'organization/Event/Configurable'
import React from 'react'
import styled from 'styled-components'

export default function AdditionalContent() {
  const v = useAttendeeVariables()
  const {
    techCheck: {additionalContent},
  } = useTemplate()

  return (
    <Configurable>
      <AdditionalContentConfig />
      <StyledContent>{v(additionalContent)}</StyledContent>
    </Configurable>
  )
}

const StyledContent = styled(Content)`
  max-height: 100%;
  margin-bottom: ${(props) => props.theme.spacing[4]};
  margin-top: ${(props) => props.theme.spacing[8]};
`

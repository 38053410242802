import styled from 'styled-components'
import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import {spacing} from 'lib/ui/theme'
import {useBreadcrumbs} from 'lib/ui/BreadcrumbProvider'
import {EventRoutePaths, useEventRoutes} from 'organization/Event/EventRoutes'
import {useEvent} from 'Event/EventProvider'
import {ObvioEvent} from 'Event'
import Typography from '@material-ui/core/Typography'

export default function EventPage(props: {
  children: React.ReactElement | React.ReactElement[]
  disablePadding?: boolean
  className?: string
}) {
  const {disablePadding, children} = props
  const eventRoutes = useEventRoutes()
  const {event} = useEvent()

  // Default Breadcrumb
  useBreadcrumbs(rootEventBreadcrumbs(eventRoutes, event, ''))

  return (
    <Box disablePadding={disablePadding}>
      <Content>{children}</Content>
    </Box>
  )
}

export function rootEventBreadcrumbs(
  eventRoutes: EventRoutePaths,
  event: ObvioEvent,
  page: string,
) {
  return [
    {
      title: event.name,
      url: eventRoutes.root,
    },
    {
      title: page,
    },
  ]
}

export function SectionTitle(props: {children: string}) {
  return <TitleText variant="h4">{props.children}</TitleText>
}

const TitleText = withStyles({
  root: {
    marginTop: spacing[4],
    marginBottom: spacing[4],
  },
})(Typography)

export const PreviewBox = styled.div`
  padding: ${(props) => props.theme.spacing[2]};
  border: 1px solid ${(props) => props.theme.colors.border};
  border-radius: 4px;
  height: 70vh;
`

export const Box = styled.div<{disablePadding?: boolean}>`
  ${(props) => (props.disablePadding ? 'padding: 0!important;' : '')}

  padding: ${(props) => `${props.theme.spacing[8]} ${props.theme.spacing[5]}`};

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    padding: ${(props) => props.theme.spacing[15]};
  }
`

const Content = styled.div`
  padding-bottom: ${(props) => props.theme.spacing[5]};
`

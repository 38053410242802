import React from 'react'
import {EnabledSwitch} from 'lib/ui/form/Switch'
import TextField from 'lib/ui/TextField'
import {Label} from 'lib/ui/typography'
import FormControl from '@material-ui/core/FormControl'
import ToggleButton from '@material-ui/lab/ToggleButton'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'
import {Controller, UseFormMethods} from 'react-hook-form'
import {onChangeCheckedHandler} from 'lib/dom'
import {Resource} from 'Event/template/Panels/Dashboard/Resources/ResourceList'
import ResourceUpload from 'Event/template/Panels/Dashboard/Resources/ResourceList/ResourceUpload'
import ActionSelect from 'Event/ActionsProvider/ActionSelect'

export default function Settings(props: {
  control: UseFormMethods['control']
  register: UseFormMethods['register']
  resource: Resource
  isUrl?: boolean
  setIsUrl: (flag: boolean) => void
  setActionId: (actionId: string | null) => void
  actionId: string | null
  filePath: string
  setFilePath: (newPath: string) => void
}) {
  const {
    control,
    register,
    resource,
    setIsUrl,
    isUrl,
    filePath,
    setFilePath,
    setActionId,
    actionId,
  } = props

  return (
    <>
      <Controller
        name="isVisible"
        control={control}
        defaultValue={resource.isVisible || false}
        render={({value, onChange}) => (
          <EnabledSwitch
            checked={value}
            onChange={onChangeCheckedHandler(onChange)}
            arial-label="toggle reseource"
          />
        )}
      />
      <Label>Name</Label>
      <TextField
        name="name"
        aria-label="resource name"
        fullWidth
        defaultValue={resource.name}
        inputProps={{
          ref: register,
        }}
      />
      <FormControl>
        <ToggleButtonGroup value={isUrl ? 'true' : 'false'} exclusive>
          <ToggleButton value="false" onClick={() => setIsUrl(false)}>
            File
          </ToggleButton>
          <ToggleButton
            value="true"
            aria-label="set url resource"
            onClick={() => setIsUrl(true)}
          >
            Link
          </ToggleButton>
        </ToggleButtonGroup>
      </FormControl>
      <ResourceUpload
        isUrl={isUrl}
        filePath={filePath}
        onChange={setFilePath}
      />
      <UrlField
        defaultValue={resource.url || ''}
        control={control}
        register={register}
        isUrl={isUrl}
      />
      <Label>Description</Label>
      <TextField
        name="description"
        aria-label="resource description"
        fullWidth
        defaultValue={resource.description || ''}
        inputProps={{
          ref: register,
        }}
      />
      <Label>Link Text</Label>
      <TextField
        name="linkText"
        aria-label="resource link text"
        fullWidth
        defaultValue={resource.linkText}
        inputProps={{
          ref: register,
        }}
      />
      <Controller
        name="actionId"
        defaultValue={actionId}
        control={control}
        onChange={setActionId}
        render={({value, onChange}) => (
          <ActionSelect noneLabel="default" value={value} onChange={onChange} />
        )}
      />
    </>
  )
}

function UrlField(props: {
  isUrl?: boolean
  control: UseFormMethods['control']
  register: UseFormMethods['register']
  defaultValue: string
}) {
  if (!props.isUrl) {
    return null
  }

  return (
    <>
      <Label>URL starting with http:// or https://</Label>
      <TextField
        name="url"
        aria-label="resource external file url"
        fullWidth
        defaultValue={props.defaultValue}
        inputProps={{
          ref: props.register,
        }}
      />
    </>
  )
}

import {useConfig} from 'organization/Event/Configurable'
import Config from 'organization/Event/Configurable/Config'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import {Settings} from 'Event/Sponsors/HeaderConfig'
import React from 'react'
import {Controller} from 'react-hook-form'
import StylingPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/StylingPanel'
import Slider from 'lib/ui/Slider'
import {
  useNiftyFiftyTemplate,
  useNiftyFiftyUpdate,
} from 'Event/template/NiftyFifty'

export default function HeaderConfig() {
  const save = useNiftyFiftyUpdate()

  return (
    <Config title="Header" onSave={save}>
      <SettingsPanel>
        <Settings />
      </SettingsPanel>
      <StylingPanel>
        <Styling />
      </StylingPanel>
    </Config>
  )
}

function Styling() {
  const {sponsors} = useNiftyFiftyTemplate()

  const {
    form: {control},
  } = useConfig()

  return (
    <>
      <Controller
        name="sponsors.spacing"
        defaultValue={sponsors.spacing}
        control={control}
        render={({onChange, value}) => (
          <Slider
            min={0}
            max={10}
            step={1}
            onChange={onChange}
            valueLabelDisplay="auto"
            value={value}
            hideUnits
            aria-label="sponsor spacing"
            label="Space Between Sponsors"
          />
        )}
      />
    </>
  )
}

import React from 'react'
import styled from 'styled-components'
import {rgba} from 'lib/color'
import {BlockComponentProps} from 'Event/Marketplace/Block'
import {BlockBase} from './base'

interface ContainerProps extends BlockBase, BlockComponentProps {
  children: React.ReactNode
  className?: string
}

const Container = React.forwardRef<HTMLDivElement, ContainerProps>(
  (props, ref) => {
    const backgroundColor = rgba(
      props.background.color || '#FFFFFF',
      props.background.opacity / 100,
    )

    return <Box {...props} ref={ref} backgroundColor={backgroundColor} />
  },
)

export default Container

const Box = styled.div<
  BlockBase & {
    backgroundColor: string
  }
>`
  ${(props) =>
    props.background.image
      ? `background-image: url(${props.background.image});`
      : null}
  background-color: ${(props) => props.backgroundColor};
  background-blend-mode: multiply;
  background-position: center;
  background-size: cover !important;
  background-repeat: no-repeat;
  border: ${(props) => props.border.width}px solid;
  border-color: ${(props) => props.border.color};
  border-radius: ${(props) => props.border.radius}px;
  padding-top: ${(props) => props.padding.top}px;
  padding-right: ${(props) => props.padding.right}px;
  padding-bottom: ${(props) => props.padding.bottom}px;
  padding-left: ${(props) => props.padding.left}px;
  display: flex;
  justify-content: ${(props) => props.alignment.horizontal};
  align-items: ${(props) => props.alignment.vertical};
  width: 100%;
  height: 100%;
`

import React from 'react'
import RuleConfig, {RuleConfigProps} from 'Event/attendee-rules/RuleConfig'
import {Rule} from 'Event/attendee-rules'
import {Panel} from 'organization/Event/DashboardConfig/ComponentConfigPanel/Panel'
import {Controller, UseFormMethods} from 'react-hook-form'

export default function RulesPanel(props: {
  rules?: Rule[]
  setRules?: (rules: Rule[]) => void
  configProps?: Partial<RuleConfigProps>
  children?: JSX.Element
  name?: string
  control?: UseFormMethods['control']
}) {
  const {rules, setRules, control, name, configProps = {}} = props

  if (control) {
    return (
      <Panel>
        <Controller
          name={name ?? ''}
          control={control}
          defaultValue={rules ?? []}
          render={({value, onChange}) => (
            <RuleConfig
              visible
              rules={value}
              onChange={onChange}
              {...configProps}
            />
          )}
        />
        {props.children}
      </Panel>
    )
  }

  if (!setRules) {
    return <Panel>{props.children}</Panel>
  }
  return (
    <Panel>
      <RuleConfig visible rules={rules} onChange={setRules} {...configProps} />
      {props.children}
    </Panel>
  )
}

import {useTemplate} from 'Event/TemplateProvider'
import {useSaveTemplate} from 'Event/TemplateUpdateProvider'
import ColorPicker from 'lib/ui/ColorPicker'
import Divider from 'lib/ui/ConfigPanel/Divider'
import Title from 'lib/ui/ConfigPanel/Title'
import IconPicker from 'lib/ui/form/IconPicker'
import TextField from 'lib/ui/TextField'
import {useConfig} from 'organization/Event/Configurable'
import Config from 'organization/Event/Configurable/Config'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import StylingPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/StylingPanel'
import React from 'react'
import {Controller} from 'react-hook-form'

export default function ForgotPasswordLinkConfig() {
  const {login} = useTemplate()
  const save = useSaveTemplate()
  const {
    form: {register, control},
  } = useConfig()

  return (
    <Config title="Forgot Password Link" onSave={save}>
      <SettingsPanel>
        <TextField
          name="login.passwordReset.linkLabel"
          defaultValue={login.passwordReset.linkLabel}
          label="Text"
          inputProps={{
            'aria-label': 'password reset link',
            ref: register,
          }}
        />
      </SettingsPanel>
      <StylingPanel>
        <Controller
          name="login.description.color"
          defaultValue={login.description.color}
          control={control}
          render={({value, onChange}) => (
            <ColorPicker
              label="Description Text Color"
              color={value}
              onPick={onChange}
              aria-label="description text color"
            />
          )}
        />
        <Divider />
        <Title>Icon</Title>
        <Controller
          name="login.passwordReset.iconName"
          defaultValue={login.passwordReset.iconName}
          control={control}
          render={({value, onChange}) => (
            <IconPicker value={value} onChange={onChange} />
          )}
        />

        <Controller
          name="login.passwordReset.iconColor"
          defaultValue={login.passwordReset.iconColor}
          control={control}
          render={({value, onChange}) => (
            <ColorPicker
              label="Color"
              color={value}
              onPick={onChange}
              aria-label="icon color"
            />
          )}
        />
      </StylingPanel>
    </Config>
  )
}

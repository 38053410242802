import {Client} from 'lib/ui/api-client'
import {api} from 'lib/url'
import {Organization} from 'organization'
import {useQuery} from 'react-query'

export function useOrgMarketplaceToken(params: {
  client: Client
  organization: Organization
}) {
  const {client, organization} = params

  return useQuery({
    queryKey: ['organizations', organization.id, 'marketplace', 'token'],
    queryFn: () =>
      client
        .get<{token: string}>(
          api(`/organizations/${organization.id}/marketplace/token`),
        )
        .then(({token}) => token),
  })
}

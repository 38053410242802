import React from 'react'
import {Controller} from 'react-hook-form'
import Slider from 'lib/ui/Slider'
import ColorPicker from 'lib/ui/ColorPicker'
import {InputStyleProps} from 'Event/auth/TextField'
import {useConfig} from 'organization/Event/Configurable'
import Title from 'lib/ui/ConfigPanel/Title'
import InfoTooltip from 'lib/ui/ConfigPanel/InfoTooltip'

export default function StyleFields(props: {
  templateKey: string
  values: InputStyleProps
}) {
  const {templateKey: template, values} = props
  const {
    form: {control},
  } = useConfig()

  return (
    <>
      <Title>
        Input{' '}
        <InfoTooltip>
          Will update Login, and Forgot Password inputs.
        </InfoTooltip>
      </Title>
      <Controller
        name={`${template}.borderRadius`}
        defaultValue={values['borderRadius']}
        control={control}
        render={({value, onChange}) => (
          <Slider
            valueLabelDisplay="auto"
            aria-label="input border radius"
            value={value}
            onChange={onChange}
            step={1}
            min={0}
            max={60}
            label="Border Radius"
          />
        )}
      />
      <Controller
        name={`${template}.labelColor`}
        defaultValue={values['labelColor']}
        control={control}
        render={({value, onChange}) => (
          <ColorPicker
            label="Label Color"
            color={value}
            onPick={onChange}
            aria-label="input label color"
          />
        )}
      />
      <Controller
        name={`${template}.borderColor`}
        defaultValue={values['borderColor']}
        control={control}
        render={({value, onChange}) => (
          <ColorPicker
            label="Border Color"
            color={value}
            onPick={onChange}
            aria-label="input box border color"
          />
        )}
      />
      <Controller
        name={`${template}.borderWidth`}
        defaultValue={values['borderWidth']}
        control={control}
        render={({value, onChange}) => (
          <Slider
            valueLabelDisplay="auto"
            aria-label="input border width"
            value={value}
            onChange={onChange}
            step={1}
            min={0}
            max={20}
            label="Border Width"
          />
        )}
      />
      <Controller
        name={`${template}.backgroundColor`}
        defaultValue={values['backgroundColor']}
        control={control}
        render={({value, onChange}) => (
          <ColorPicker
            label="Background Color"
            color={value}
            onPick={onChange}
            aria-label="input box background color"
          />
        )}
      />
      <Controller
        name={`${template}.textColor`}
        defaultValue={values['textColor']}
        control={control}
        render={({value, onChange}) => (
          <ColorPicker
            label="Text Color"
            color={value}
            onPick={onChange}
            aria-label="input box text color"
          />
        )}
      />
    </>
  )
}

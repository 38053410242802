import React from 'react'
import moment from 'moment'
import styled from 'styled-components'
import Button from '@material-ui/core/Button'
import {
  ZoomAttendance,
  useZoomAttendance,
} from 'organization/Event/ZoomAttendance/ZoomAttendanceProvider'
import {ClockIcon} from 'lib/ui/Icon'
import {formatDate} from 'organization/EventList/CreateEventForm/Form'

export interface ButtonRowProps {
  action: string
  onClick: () => void
  onClose: () => void
  validation: () => boolean
}

export default function ButtonRow(props: ButtonRowProps) {
  const {action, onClick, onClose, validation} = props
  const {
    endDateTime,
    scheduledActionFormToggle,
    startDateTime,
    zoomAttendance,
  } = useZoomAttendance()

  if (!zoomAttendance) {
    return null
  }

  const onScheduleClick = () => {
    if (!validation()) {
      return
    }

    scheduledActionFormToggle()
  }

  const scheduledDate = formatDate(moment(endDateTime))

  return (
    <>
      <ButtonRowContent
        action={action}
        endDateTime={endDateTime}
        startDateTime={startDateTime}
        zoomAttendance={zoomAttendance}
      />
      <ButtonContainer>
        <ConfirmButton color="primary" onClick={onClick} variant="contained">
          Confirm
        </ConfirmButton>
        <ScheduledButton
          aria-label="schedule action"
          color="primary"
          onClick={onScheduleClick}
          title={`Schedule Attendee Action to run at ${scheduledDate}`}
          variant="contained"
        >
          <ClockIcon />
        </ScheduledButton>

        <Button color="primary" onClick={onClose} variant="outlined">
          Cancel
        </Button>
      </ButtonContainer>

      <GreyContainer>
        <Superscript>**</Superscript>{' '}
        <small>
          Discrepancies in the count above will occur if you have attendees that
          have been deleted from this event. Deleted attendees will not be
          included in this action.
        </small>
      </GreyContainer>
    </>
  )
}

function ButtonRowContent(props: {
  action: string
  zoomAttendance: ZoomAttendance
  startDateTime: string | null
  endDateTime: string | null
}) {
  const {action, zoomAttendance, startDateTime, endDateTime} = props

  const grammarAttendee = zoomAttendance.unique_attendees === 1 ? '' : 's'
  const grammarWasWere = zoomAttendance.unique_attendees === 1 ? 'was' : 'were'
  const displayStartDateTime = moment(startDateTime).format('MMMM Do Y hh:mm a')
  const displayEndDateTime = moment(endDateTime).format('MMMM Do Y hh:mm a')

  return (
    <div>
      You are about to <strong>{action}</strong> for{' '}
      {zoomAttendance.unique_attendees} attendee{grammarAttendee} who{' '}
      {grammarWasWere} online in Zoom between {displayStartDateTime} and{' '}
      {displayEndDateTime}. Please confirm to continue.
    </div>
  )
}

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: ${(props) => props.theme.spacing[4]} 0;

  & > button:last-child {
    margin-left: ${(props) => props.theme.spacing[4]};
  }
`

const ConfirmButton = styled(Button)`
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  padding-right: ${(props) => props.theme.spacing[3]};
`

const ScheduledButton = styled(Button)`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left: solid 1px ${(props) => props.theme.colors.text.info};
  min-width: 0;
  padding-left: ${(props) => props.theme.spacing[3]};
  padding-right: ${(props) => props.theme.spacing[4]};
`

const Superscript = styled.div`
  display: inline-block;
  font-size: 10px;
  position: relative;
  top: -6px;
`

const GreyContainer = styled.div`
  color: ${(props) => props.theme.colors.gray100};
`

import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import {Controller, UseFormMethods} from 'react-hook-form'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import {onUnknownChangeHandler} from 'lib/dom'
import {
  ALPHA_NUMERIC,
  EMAIL,
  NUMERIC,
  PHONE_NUMBER,
  Question,
} from 'organization/Event/QuestionsProvider'

const RULE_SELECT_ID = 'question-rule-select'

export default function ValidationSelect(props: {
  type: string
  question?: Question
  control: UseFormMethods['control']
}) {
  const {question, control} = props

  return (
    <FormControl fullWidth>
      <StyledInputLabel htmlFor={RULE_SELECT_ID} variant="outlined" filled>
        Validation
      </StyledInputLabel>
      <Controller
        name="validation_rule"
        defaultValue={question?.validation_rule || null}
        control={control}
        render={({onChange, value}) => (
          <Select
            id={RULE_SELECT_ID}
            value={value || 0}
            fullWidth
            onChange={onUnknownChangeHandler((val) => onChange(val || null))}
            variant="outlined"
            inputProps={{
              'aria-label': 'validation rule',
            }}
          >
            <MenuItem value={0}>None</MenuItem>
            <MenuItem value={EMAIL} aria-label="email">
              Email
            </MenuItem>
            <MenuItem value={PHONE_NUMBER} aria-label="phone number">
              Phone Number
            </MenuItem>
            <MenuItem value={ALPHA_NUMERIC} aria-label="alpha numeric">
              Alphanumeric
            </MenuItem>
            <MenuItem value={NUMERIC} aria-label="number">
              Number
            </MenuItem>
          </Select>
        )}
      />
    </FormControl>
  )
}

const StyledInputLabel = withStyles({
  root: {
    background: '#FFFFFF',
  },
})(InputLabel)

import {LoginProps} from 'Event/auth/Login'
import React from 'react'
import Page, {
  ErrorMessage,
  StyledPaper,
  StyledFormContainer,
  DescriptionText,
} from 'Event/template/Cards/Login/Page'
import EmailField from 'Event/auth/Login/EmailField'
import PasswordField from 'Event/auth/Login/PasswordField'
import SubmitButton from 'Event/auth/Login/SubmitButton'
import Description from 'Event/auth/Login/Description'
import ForgotPasswordLink from 'Event/auth/Login/ForgotPasswordLink'
import Title from 'Event/template/Cards/Login/Title'
import AdditionalDescription from 'Event/template/Cards/Login/AdditionalDescription'
import SignUpLink from 'Event/auth/Login/SignUpLink'

export default function Login(props: LoginProps) {
  return (
    <Page isPreview={props.isPreview}>
      <StyledPaper>
        <StyledFormContainer>
          <Title />
          <Description Content={DescriptionText} />
          <form onSubmit={props.onSubmit}>
            <EmailField {...props} />
            <PasswordField {...props} />
            <ErrorMessage>{props.error}</ErrorMessage>
            <SubmitButton {...props} />
          </form>
          <AdditionalDescription />
          <ForgotPasswordLink />
          <SignUpLink />
        </StyledFormContainer>
      </StyledPaper>
    </Page>
  )
}

import styled from 'styled-components'
import {useForms} from 'organization/Event/FormsProvider'
import React from 'react'
import {Label} from 'lib/ui/typography'
import FormItem, {Container} from 'organization/Event/FormsConfig/FormItem'

export default function Forms() {
  const {forms} = useForms()

  const hasForms = forms.length > 0
  if (!hasForms) {
    return <div>No forms have been added</div>
  }

  return (
    <FormsList>
      <Container>
        <HeaderLabel>Name</HeaderLabel>
      </Container>
      {forms.map((form) => (
        <FormItem form={form} key={form.id} />
      ))}
    </FormsList>
  )
}

const FormsList = styled.div`
  max-width: 660px;
  border: 1px solid ${(props) => props.theme.colors.gray300} !important;
  border-bottom: none !important;
`

const HeaderLabel = styled(Label)`
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 19px !important;
`

import {useObvioUser} from 'obvio/auth'
import Page from 'organization/Event/TemplateEditor/Page'
import React from 'react'
import {FeaturesBreadcrumbs} from 'organization/Event/Page/PageBreadcrumbs'
import {TemplateSponsors} from 'Event/Sponsors'
import {REMOVE, useSaveTemplate} from 'Event/TemplateUpdateProvider'
import {useTemplate} from 'Event/TemplateProvider'
import {DropResult} from 'react-beautiful-dnd'
import {orderedIdsByPosition, createPositions} from 'lib/list'
import {uuid} from 'lib/uuid'
import {NavButtonProps} from 'Event/Dashboard/components/NavButton'
import ToggleSponsorsSwitch from 'organization/Event/SponsorsConfig/ToggleSponsorsSwitch'

export default function SponsorsConfig() {
  const user = useObvioUser()

  return (
    <FeaturesBreadcrumbs page="Sponsors">
      <Page
        toolbarItems={
          <>
            <ToggleSponsorsSwitch />
          </>
        }
      >
        <TemplateSponsors user={user} />
      </Page>
    </FeaturesBreadcrumbs>
  )
}

export function useHandleDragSponsors() {
  const update = useSaveTemplate()
  const {
    sponsors: {items},
  } = useTemplate()

  return (result: DropResult) => {
    const {destination, source} = result
    if (!destination) {
      return
    }
    const ids = orderedIdsByPosition(items)

    const [removed] = ids.splice(source.index, 1)
    ids.splice(destination.index, 0, removed)

    update({
      sponsors: {
        items: createPositions(ids),
      },
    })
  }
}

export function useButtons(sponsorId: string) {
  const {
    sponsors: {items},
  } = useTemplate()

  const buttons = items[sponsorId].buttons || {}
  const updateTemplate = useSaveTemplate()

  const add = (button: NavButtonProps) => {
    const id = uuid()
    updateSponsorButtons(id, button)
  }

  const duplicate = (id: string) => {
    const button = buttons[id]
    add(button)
  }

  const remove = (id: string) => {
    updateTemplate({
      sponsors: {
        items: {
          [sponsorId]: {
            buttons: {
              [id]: REMOVE,
            },
          },
        },
      },
    })
  }

  const update = (id: string) => (button: NavButtonProps) => {
    updateSponsorButtons(id, button)
  }

  const handleDrag = (result: DropResult) => {
    const {destination, source} = result

    if (!destination) {
      return
    }

    const ids = orderedIdsByPosition(buttons)
    const [removed] = ids.splice(source.index, 1)
    ids.splice(destination.index, 0, removed)

    const positions = createPositions(ids)

    updateTemplate({
      sponsors: {
        items: {
          [sponsorId]: {
            buttons: positions,
          },
        },
      },
    })
  }

  const updateSponsorButtons = (id: string, updated: NavButtonProps) => {
    updateTemplate({
      sponsors: {
        items: {
          [sponsorId]: {
            buttons: {
              [id]: updated,
            },
          },
        },
      },
    })
  }

  return {
    add,
    remove,
    update,
    duplicate,
    handleDrag,
  }
}

import FeatureToggle, {
  FeatureToggleProps,
  hasFeatureToggle,
} from 'lib/FeatureToggle'
import {useObvioUser} from 'obvio/auth'
import React from 'react'

/**
 * Renders only if the authenticated user has the required flags. We are
 * using a forwardRef in case it's being used to wrap a component that
 * expects a ref.
 *
 * @param props
 * @returns
 */
export default React.forwardRef<any, FeatureToggleProps>((props, _ref) => {
  const user = useObvioUser()

  return <FeatureToggle user={user} {...props} />
})

/**
 * Checks if the currently authenticated user has the given flags.
 *
 * @param flags
 * @returns
 */
export function useHasFeature(flags: FeatureToggleProps['flags']) {
  const user = useObvioUser()

  return hasFeatureToggle(user, flags)
}

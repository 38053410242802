import TextField from '@material-ui/core/TextField'
import withStyles from '@material-ui/core/styles/withStyles'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import {onUnknownChangeHandler} from 'lib/dom'
import {fieldError} from 'lib/form'
import ValidationSelect from 'organization/Event/Form/dialog/Form/ValidationSelect'
import {
  isQuestionWithOptions as hasOptions,
  Question,
} from 'organization/Event/QuestionsProvider'
import React, {useEffect} from 'react'
import {Controller, useForm} from 'react-hook-form'
import {v4 as uuid} from 'uuid'
import {SHORT_ANSWER_TEXT} from 'Event/Question/ShortAnswerText'
import {LONG_ANSWER_TEXT} from 'Event/Question/LongAnswerText'
import {RADIO} from 'Event/Question/Radio'
import {SELECT} from 'Event/Question/Select'
import {CHECKBOX} from 'Event/Question/Checkbox'
import {CURRENCY} from 'Event/Question/Currency'
import {SELECT_PRIORITY} from 'Event/Question/SelectPriority'
import QuestionTypeConfig from 'organization/Event/Form/dialog/Form/QuestionTypeConfig'
import OptionsInput from 'organization/Event/Form/dialog/Form/OptionsInput'
import Switch from 'lib/ui/form/Switch'

const TYPE_SELECT_ID = 'question-type-select'

export default function Form(props: {
  onComplete: (data: Question) => void
  onClose: () => void
  question?: Question
  footer?: React.ReactElement
}) {
  const {question} = props
  const {register, handleSubmit, errors, control, watch, setValue} = useForm({
    defaultValues: {
      options: hasOptions(question) ? question.options : [],
      type: question?.type || '',
    },
  })

  const selectedType = watch('type') as Question['type']

  useEffect(() => {
    if (!question) {
      return
    }

    setValue('type', question.type)
    setValue('is_required', question.is_required)
  }, [question, setValue])

  const submit = (data: Question) => {
    props.onComplete(fill(data, question))
    props.onClose()
  }

  const error = (field: keyof Question) => {
    const value = fieldError(field, {
      form: errors,
      response: null,
    })

    return {
      exists: Boolean(value),
      value,
    }
  }

  return (
    <Box pb={3}>
      <form onSubmit={handleSubmit(submit)}>
        <TextField
          label="Label"
          fullWidth
          variant="outlined"
          required
          name="label"
          defaultValue={question?.label || ''}
          inputProps={{
            ref: register({required: 'Label is required'}),
            'aria-label': 'question label',
          }}
          error={error('label').exists}
          helperText={error('label').value}
        />
        <FormControl fullWidth>
          <StyledInputLabel
            htmlFor={TYPE_SELECT_ID}
            required
            variant="outlined"
            filled
          >
            Type
          </StyledInputLabel>
          <Controller
            name="type"
            defaultValue={question?.type || ''}
            control={control}
            render={({onChange, value}) => (
              <Select
                id={TYPE_SELECT_ID}
                value={value}
                fullWidth
                onChange={onUnknownChangeHandler(onChange)}
                variant="outlined"
                required
                inputProps={{
                  'aria-label': 'question type',
                }}
              >
                <MenuItem value={SHORT_ANSWER_TEXT}>Short Answer Text</MenuItem>
                <MenuItem value={LONG_ANSWER_TEXT}>Long Answer Text</MenuItem>
                <MenuItem value={RADIO}>Radio</MenuItem>
                <MenuItem value={SELECT}>Select</MenuItem>
                <MenuItem value={CHECKBOX}>Checkbox</MenuItem>
                <MenuItem value={CURRENCY}>Currency</MenuItem>
                <MenuItem value={SELECT_PRIORITY}>Select Priority</MenuItem>
              </Select>
            )}
          />
        </FormControl>
        <QuestionTypeConfig
          control={control}
          questionType={selectedType}
          register={register}
          question={question}
          errors={errors}
        />
        <OptionsInput
          control={control}
          questionType={selectedType}
          register={register}
          question={question}
          errors={errors}
        />
        <TextField
          label="Helper Text"
          fullWidth
          variant="outlined"
          name="helper_text"
          defaultValue={question?.helper_text || ''}
          inputProps={{
            ref: register,
            'aria-label': 'helper text',
          }}
          multiline
          rows={4}
          error={error('helper_text').exists}
          helperText={error('helper_text').value}
        />
        <ValidationSelect
          type={selectedType}
          control={control}
          question={props.question}
        />
        <Controller
          name="is_required"
          defaultValue={question?.is_required || true}
          control={control}
          render={({onChange, value}) => (
            <Switch
              checked={!value}
              onChange={(e) => onChange(!e.target.checked)}
              labelPlacement="end"
              aria-label="is optional"
              label={value ? 'Question is Required' : 'Question is Optional'}
            />
          )}
        />
        <Button
          variant="contained"
          color="primary"
          fullWidth
          type="submit"
          aria-label="save question"
        >
          Save
        </Button>
      </form>
      {props.footer || null}
    </Box>
  )
}

function fill<T extends Question>(question: T, saved?: Question) {
  const result: T = {
    ...question,
    // If we're adding a new question, let's give it a temp uuid
    id: saved ? saved.id : uuid(),
  }

  // A question may have changed from a type that did not have
  // options to one that did. If it had, we should send
  // over an empty array if it hasn't been set.
  if (hasOptions(result)) {
    result.options = result.options || []
  }

  return result
}

export function ruleLabel(name: string) {
  const labels: Record<string, string> = {
    email: 'Email',
    phone_number: 'Phone Number',
    alpha_numeric: 'Alphanumeric',
    numeric: 'Number',
  }

  const label = labels[name]
  if (!label) {
    throw new Error(`Label not defined for rule: ${name}`)
  }

  return label
}

const StyledInputLabel = withStyles({
  root: {
    background: '#FFFFFF',
  },
})(InputLabel)

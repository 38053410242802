import React from 'react'
import Grid from '@material-ui/core/Grid'
import FormButtons from 'Event/Marketplace/Block/PurchaseForm/FormButtons'
import BackButtonButton from 'Event/Marketplace/Block/PurchaseForm/BackButton'
import NextStepButton from 'Event/Marketplace/Block/PurchaseForm/NextStepButton'
import {usePurchaseForm} from 'Event/Marketplace/Block/PurchaseForm/PurchaseFormProvider'
import {BlockComponentProps} from 'Event/Marketplace/Block'
import CountrySelect from 'lib/CountrySelect'
import StateSelect from 'lib/StateSelect'
import {Controller} from 'react-hook-form'
import styled from 'styled-components'
import {PurchaseFormBlock} from 'Event/Marketplace/Block/PurchaseForm'

interface ShippingAddressStepProps extends PurchaseFormBlock {
  sectionId?: string
  onBack: () => void
  onContinue: () => void
  TextFieldComponent: React.FC<any>
  ButtonComponent: React.FC<any>
}

export default function ShippingAddressStep(
  props: ShippingAddressStepProps & BlockComponentProps,
) {
  const {
    onBack,
    onContinue,
    TextFieldComponent,
    ButtonComponent,
    ...config
  } = props

  const {
    form: {trigger, register, control, watch, errors},
  } = usePurchaseForm()

  const countryId = watch(`shipping_address.country_id`)

  const handleContinue = async () => {
    trigger([
      `shipping_address.address_1`,
      `shipping_address.country_id`,
      `shipping_address.state_id`,
      `shipping_address.city`,
      `shipping_address.zip_postal`,
    ]).then((valid) => {
      if (valid) {
        onContinue()
      }
    })
  }

  return (
    <Grid container spacing={2}>
      <>
        <Grid item xs={12}>
          <TextFieldComponent
            aria-label={`shipping_address address 1`}
            config={config}
            placeholder={'shippingAddress1'}
            name={`shipping_address.address_1`}
            inputProps={{
              ref: register({
                required: true,
              }),
            }}
            error={Boolean(errors['shipping_address']?.address_1)}
            helperText={
              Boolean(errors['shipping_address']?.address_1)
                ? config.errors.requiredField
                : ''
            }
          />
        </Grid>
        <Grid item xs={12}>
          <TextFieldComponent
            aria-label={`shipping_address address 2`}
            config={config}
            placeholder={'shippingAddress2'}
            name={`shipping_address.address_2`}
            inputProps={{
              ref: register,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            control={control}
            name={`shipping_address.country_id`}
            rules={{
              validate: (v) => (v && v !== 0) || config.errors.requiredField,
            }}
            render={({value, onChange}) => (
              <StyledCountrySelect
                config={config}
                value={value}
                onChange={onChange}
                fullWidth
                variant="outlined"
                defaultLabel={config.input.placeholder.shippingcountry}
                required
                error={Boolean(errors['shipping_address']?.country_id)}
                helperText={errors['shipping_address']?.country_id?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            control={control}
            name={`shipping_address.state_id`}
            rules={{
              validate: (v) => (v && v !== 0) || config.errors.requiredField,
            }}
            render={({value, onChange}) => (
              <StyledStateSelect
                config={config}
                value={value}
                onChange={onChange}
                countryId={countryId}
                fullWidth
                variant="outlined"
                defaultLabel={config.input.placeholder.shippingstate}
                required
                error={Boolean(errors['shipping_address']?.state_id)}
                helperText={errors['shipping_address']?.state_id?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextFieldComponent
            aria-label={`shipping_address city`}
            config={config}
            placeholder={'shippingcity'}
            name={`shipping_address.city`}
            inputProps={{
              ref: register({
                required: true,
              }),
            }}
            error={Boolean(errors['shipping_address']?.city)}
            helperText={
              Boolean(errors['shipping_address']?.city)
                ? config.errors.requiredField
                : ''
            }
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextFieldComponent
            aria-label={`shipping_address postal`}
            config={config}
            placeholder={'shippingzipcode'}
            name={`shipping_address.zip_postal`}
            inputProps={{
              ref: register({
                required: true,
              }),
            }}
            error={Boolean(errors['shipping_address']?.zip_postal)}
            helperText={
              Boolean(errors['shipping_address']?.zip_postal)
                ? config.errors.requiredField
                : ''
            }
          />
        </Grid>
      </>

      <Grid item xs={12}>
        <FormButtons>
          <BackButtonButton {...config} onClick={onBack} />
          <NextStepButton
            ButtonComponent={ButtonComponent}
            {...config}
            onClick={handleContinue}
          />
        </FormButtons>
      </Grid>
    </Grid>
  )
}

const StyledStateSelect = styled(StateSelect)<{
  config: PurchaseFormBlock
}>`
  margin-bottom: 0px;

  & > div > div {
    background-color: ${(props) => props.config.input.backgroundColor};
    border: ${(props) => props.config.input.border.width}px solid;
    border-color: ${(props) => props.config.input.border.color};
    border-radius: ${(props) => props.config.input.border.radius}px !important;
    padding-left: ${(props) => props.config.input.padding.left}px;
    padding-right: ${(props) => props.config.input.padding.right}px !important;
    padding-top: ${(props) => props.config.input.padding.top}px;
    padding-bottom: ${(props) => props.config.input.padding.bottom}px;
    font-size: ${(props) => props.config.input.fontSize}px;
    line-height: normal;
  }

  & > div > div:focus {
    background-color: ${(props) => props.config.input.backgroundColor};
  }
`

const StyledCountrySelect = styled(CountrySelect)<{
  config: PurchaseFormBlock
}>`
  margin-bottom: 0px;

  & > div > div {
    background-color: ${(props) => props.config.input.backgroundColor};
    border: ${(props) => props.config.input.border.width}px solid;
    border-color: ${(props) => props.config.input.border.color};
    border-radius: ${(props) => props.config.input.border.radius}px !important;
    padding-left: ${(props) => props.config.input.padding.left}px;
    padding-right: ${(props) => props.config.input.padding.right}px !important;
    padding-top: ${(props) => props.config.input.padding.top}px;
    padding-bottom: ${(props) => props.config.input.padding.bottom}px;
    font-size: ${(props) => props.config.input.fontSize}px;
    line-height: normal;
  }

  & > div > div:focus {
    background-color: ${(props) => props.config.input.backgroundColor};
  }
`

import React from 'react'
import styled from 'styled-components'
import ToggleButton from '@material-ui/lab/ToggleButton'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'

export default function SelectorButtons(props: {
  onChange: (value: any) => void
  options: {
    label: any
    value: any
    disabled?: boolean
    'aria-label'?: string
  }[]
  value: any
  disabled?: boolean
}) {
  const {disabled, onChange, options, value} = props

  return (
    <SelectorButtonGroup exclusive value={value}>
      {options.map((option, key) => (
        <SelectorButton
          key={key}
          value={option.value}
          disableRipple
          aria-label={option['aria-label'] || undefined}
          disabled={option.disabled || disabled}
          onClick={() => onChange(option.value)}
        >
          {option.label}
        </SelectorButton>
      ))}
    </SelectorButtonGroup>
  )
}

const SelectorButtonGroup = styled(ToggleButtonGroup)`
  background-color: ${(props) => props.theme.colors.input.dark};
  flex-wrap: wrap;
  gap: ${(props) => props.theme.spacing[4]};
  justify-content: space-around;
  margin-bottom: 12px;
  padding: ${(props) => `2px ${props.theme.spacing[4]}`};
  width: 100%;
`

const SelectorButton = styled(ToggleButton)`
  background-color: ${(props) => props.theme.colors.input.dark};
  border: none !important;
  border-radius: 4px !important;
  flex: 1;
  font-size: 12px;
  padding: ${(props) => `${props.theme.spacing[2]} ${props.theme.spacing[5]}`};

  &.Mui-selected {
    background-color: ${(props) =>
      props.theme.colors.panel.background} !important;
  }

  &::hover {
    background: none !important;
  }
`

import {useSaveTemplate} from 'Event/TemplateUpdateProvider'
import TextField from 'lib/ui/TextField'
import {useConfig} from 'organization/Event/Configurable'
import Config from 'organization/Event/Configurable/Config'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import React from 'react'
import {useConfirmedButton} from 'Event/Dashboard/components/NavButton/ConfirmedButton'
import StylingPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/StylingPanel'
import {Controller} from 'react-hook-form'
import Slider from 'lib/ui/Slider'
import BackgroundPicker from 'lib/ui/form/BackgroundPicker'
import ColorPicker from 'lib/ui/ColorPicker'
import Title from 'lib/ui/ConfigPanel/Title'
import {
  DEFAULT_BUTTON_BACKGROUND_COLOR,
  DEFAULT_BUTTON_TEXT_COLOR,
} from 'lib/ui/Button/CustomButton'

export const DEFAULT_FONT_SIZE = 16
export const DEFAULT_LABEL = 'Yes'

export default function ConfirmButtonConfig() {
  const save = useSaveTemplate()
  const {key, button} = useConfirmedButton()

  const {
    form: {register, control},
  } = useConfig()

  return (
    <Config title="Confirm Button" onSave={save}>
      <SettingsPanel>
        <TextField
          label="Confirm Label"
          name={`${key}.confirmButtonLabel`}
          defaultValue={button.confirmButtonLabel}
          inputProps={{
            'aria-label': 'confirm button label',
            ref: register,
          }}
        />
      </SettingsPanel>
      <StylingPanel>
        <Title>Font</Title>
        <Controller
          name={`${key}.confirmButtonFontSize`}
          defaultValue={button.confirmButtonFontSize || DEFAULT_FONT_SIZE}
          control={control}
          render={({value, onChange}) => (
            <Slider
              min={1}
              max={100}
              onChange={onChange}
              valueLabelDisplay="off"
              value={value}
              label="Size"
            />
          )}
        />
        <Controller
          name={`${key}.confirmButtonTextColor`}
          control={control}
          defaultValue={
            button.confirmButtonTextColor || DEFAULT_BUTTON_TEXT_COLOR
          }
          render={({value, onChange}) => (
            <ColorPicker label="Text Color" color={value} onPick={onChange} />
          )}
        />
        <Title>Background</Title>
        <Controller
          name={`${key}.confirmButtonBackgroundColor`}
          control={control}
          defaultValue={
            button.confirmButtonBackgroundColor ||
            DEFAULT_BUTTON_BACKGROUND_COLOR
          }
          render={({value, onChange}) => (
            <BackgroundPicker
              label="Background"
              background={value}
              onChange={onChange}
            />
          )}
        />
      </StylingPanel>
    </Config>
  )
}

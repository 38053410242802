import React from 'react'
import styled from 'styled-components'

export default function Label(props: {children?: string}) {
  if (!props.children) {
    return null
  }

  return <LabelText>{props.children}</LabelText>
}

const LabelText = styled.span`
  display: block;
  margin-bottom: ${(props) => props.theme.spacing[3]};
  color: ${(props) => props.theme.colors.input.label};
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
`

import React, {useRef} from 'react'
import {useAttendeeVariables} from 'Event'
import styled from 'styled-components'
import SpeakerConfig from 'Event/template/Lefty/Speakers/SpeakerConfig'
import {Speaker, VisibleSpeaker} from 'Event/Speakers'
import {Title, Label} from 'lib/ui/typography'
import Button from 'lib/ui/Button'
import {Icon} from 'lib/fontawesome/Icon'
import SpeakerModal from 'Event/template/Lefty/Speakers/SpeakerModal'
import {useToggle} from 'lib/toggle'
import {SPEAKER_ALIGN, useLeftyTemplate} from 'Event/template/Lefty'
import {SpeakerConfigurable} from 'Event/Speakers/SpeakerConfig'
import AddSpeakerButton from 'Event/Speakers/AddSpeakerButton'
import {orderedIdsByPosition} from 'lib/list'
import {useEditMode} from 'Event/EditModeProvider'
import {breakpoints} from 'lib/ui/theme'
import Slider, {Settings} from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import {Draggable} from 'react-beautiful-dnd'
import {DragDropContext, Droppable} from 'react-beautiful-dnd'
import {useHandleDragSpeakers} from 'organization/Event/SpeakersConfig'
import SpeakersListConfig from 'Event/template/Lefty/Speakers/SpeakersList/SpeakersListConfig'
import {Editable} from 'Event/Dashboard/editor/views/EditComponent'
import {
  SPEAKER_ALIGN_LEFT,
  SPEAKER_ALIGN_RIGHT,
} from 'Event/template/Lefty/Speakers/SpeakersList/SpeakersListConfig/Styling'

export default function SpeakersList() {
  const {speakers} = useLeftyTemplate()
  const ids = orderedIdsByPosition(speakers.items)
  const hasSpeakers = ids.length > 0
  const sliderRef = useRef<Slider>(null)
  const isEditMode = useEditMode()
  const {flag: configVisible, toggle: toggleConfig} = useToggle()
  const v = useAttendeeVariables()

  const sliderSettings: Settings = {
    infinite: false,
    slidesToScroll: 1,
    dots: false,
    slidesToShow: 3,
    draggable: false,
    nextArrow: <NextArrow color={speakers.carouselArrowColor} />,
    prevArrow: <PrevArrow color={speakers.carouselArrowColor} />,
    responsive: [
      {
        breakpoint: parseInt(breakpoints.md),
        settings: {
          dots: false,
          slidesToShow: 2,
          draggable: false,
          nextArrow: <NextArrow color={speakers.carouselArrowColor} />,
          prevArrow: <PrevArrow color={speakers.carouselArrowColor} />,
        },
      },
      {
        breakpoint: parseInt(breakpoints.sm),
        settings: {
          slidesToShow: 1,
          dots: true,
          arrows: false,
        },
      },
    ],
  }

  const slideToSpeaker = (speaker: Speaker) => {
    if (sliderRef.current) {
      sliderRef.current.slickGoTo(speaker.position || 0)
    }
  }

  if (!hasSpeakers) {
    return <AddSpeakerButton />
  }

  if (isEditMode) {
    return (
      <>
        <SpeakersListConfig showing={configVisible} onClose={toggleConfig} />
        <Editable onEdit={toggleConfig}>
          <Box>
            <ListTitle>{v(speakers.otherSpeakersTitle)}</ListTitle>
            <DroppableList>
              {ids.map((id, index) => (
                <SpeakerCard
                  key={id}
                  id={id}
                  speaker={speakers.items[id]}
                  index={index}
                />
              ))}
            </DroppableList>
            <AddSpeakerButton onAdd={slideToSpeaker} />
          </Box>
        </Editable>
      </>
    )
  }

  return (
    <Box>
      <ListTitle>{v(speakers.otherSpeakersTitle)}</ListTitle>
      <StyledSlider {...sliderSettings} ref={sliderRef} align={speakers.align}>
        {ids.map((id, index) => (
          <VisibleSpeaker key={id} speaker={speakers.items[id]}>
            <SpeakerCard id={id} speaker={speakers.items[id]} index={index} />
          </VisibleSpeaker>
        ))}
      </StyledSlider>
      <AddSpeakerButton onAdd={slideToSpeaker} />
    </Box>
  )
}

function DroppableList(props: {children: React.ReactNode[]}) {
  const handleDrag = useHandleDragSpeakers()

  return (
    <DragDropContext onDragEnd={handleDrag}>
      <Droppable droppableId="drag-and-drop-speaker" direction="horizontal">
        {(provided) => (
          <EditGrid ref={provided.innerRef} {...provided.droppableProps}>
            <>
              {props.children}
              {provided.placeholder}
            </>
          </EditGrid>
        )}
      </Droppable>
    </DragDropContext>
  )
}

function SpeakerCard(props: {id: string; speaker: Speaker; index: number}) {
  const isEditMode = useEditMode()

  if (!isEditMode) {
    return <SpeakerCardContent {...props} />
  }
  return (
    <Draggable draggableId={String(props.id)} index={props.index}>
      {(provided) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <SpeakerCardContent {...props} />
        </div>
      )}
    </Draggable>
  )
}

function SpeakerCardContent(props: {id: string; speaker: Speaker}) {
  const {id, speaker} = props
  const v = useAttendeeVariables()
  const {flag: showing, toggle} = useToggle()
  const isEditMode = useEditMode()

  return (
    <>
      <SpeakerModal showing={showing} speaker={speaker} onClose={toggle} />
      <SpeakerCardBox isEditMode={isEditMode}>
        <SpeakerConfigurable>
          <SpeakerConfig speaker={speaker} id={id} />
          <SpeakerCardContainer onClick={toggle}>
            <SpeakerImage image={speaker.image} />
            <SpeakerCardFooter>
              <SpeakerLabel aria-label="speaker">
                {v(speaker.name)}
              </SpeakerLabel>
            </SpeakerCardFooter>
          </SpeakerCardContainer>
        </SpeakerConfigurable>
      </SpeakerCardBox>
    </>
  )
}

function NextArrow(props: any) {
  return (
    <ArrowButtonBox
      className={props.className}
      variant="contained"
      backgroundColor={props.color}
      aria-label="next speaker"
      onClick={props.onClick}
    >
      <ArrowIcon iconClass={`fa-solid fa-chevron-right`} color="white" />
    </ArrowButtonBox>
  )
}

function PrevArrow(props: any) {
  return (
    <ArrowButtonBox
      className={props.className}
      variant="contained"
      backgroundColor={props.color}
      aria-label="prev speaker"
      onClick={props.onClick}
    >
      <ArrowIcon iconClass={`fa-solid fa-chevron-left`} color="white" />
    </ArrowButtonBox>
  )
}

function SpeakerImage(props: {image: string | null}) {
  if (!props.image) {
    return <EmptyImage></EmptyImage>
  }

  return <StyledImage src={props.image} />
}

const EditGrid = styled.div`
  display: flex;
  overflow-x: auto;
`

const EmptyImage = styled.div`
  width: 100%;
  height: 240px;
  background: #f7f9fc;
`

const StyledImage = styled.img`
  width: 100%;
  height: 240px;
  object-fit: cover;
`

const SpeakerCardFooter = styled.div`
  padding: ${(props) => `${props.theme.spacing[4]} ${props.theme.spacing[6]}`};
`

const ListTitle = styled(Title)`
  font-style: normal;
  font-weight: 500;
  font-size: 34px;
  line-height: 40px;
  color: inherit;
  margin-bottom: ${(props) => props.theme.spacing[9]};
`

const SpeakerLabel = styled(Label)`
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  color: inherit;
`

const Box = styled.div`
  width: 100%;
  margin-bottom: ${(props) => props.theme.spacing[4]};
`

const SpeakerCardBox = styled.div<{
  isEditMode?: boolean
}>`
  width: ${(props) => (props.isEditMode ? '320px' : '100%')};
  flex: 0 0 auto;
  padding: ${(props) => `0 ${props.theme.spacing[2]}`};
  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    width: ${(props) => (props.isEditMode ? '240px' : '100%')};
  }
`

const StyledSlider = styled(Slider)<{
  align: SPEAKER_ALIGN
}>`
  .slick-track {
    ${(props) =>
      props.align === SPEAKER_ALIGN_LEFT
        ? 'margin-left: 0;'
        : props.align === SPEAKER_ALIGN_RIGHT
        ? 'margin-right: 0;'
        : ''}
  }
`

const SpeakerCardContainer = styled.div`
  border: 1px solid #edf1f7;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
`

const ArrowButtonBox = styled(Button)<{
  backgroundColor: string
}>`
  width: 24px;
  height: 24px;
  border-radius: 4px;
  background-color: ${(props) => props.backgroundColor};
  &::before {
    display: none;
  }
  &:hover {
    background-color: ${(props) => props.backgroundColor};
  }
`

const ArrowIcon = styled(Icon)<{
  color: string
}>`
  color: ${(props) => props.color};
  font-size: 18px;
  margin: 0 2px;
`

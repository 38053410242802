import React from 'react'
import {FAQ} from 'Event/FAQs'
import Card from 'lib/ui/Card'
import Box from '@material-ui/core/Box'
import CardHeader from 'lib/ui/Card/CardHeader'
import CardContent from 'lib/ui/Card/CardContent'
import Collapse from '@material-ui/core/Collapse'
import {useAttendeeVariables} from 'Event'
import Content from 'lib/ui/form/TextEditor/Content'
import FaqConfig from 'Event/FAQs/FAQConfig'
import Configurable from 'organization/Event/Configurable'

type BodyProps = {
  id: string
  faq: FAQ
  className?: string
  answerVisible?: boolean
}

export default function Body(props: BodyProps) {
  return (
    <Configurable>
      <FaqConfig faq={props.faq} id={props.id} />
      <FaqCard {...props} />
    </Configurable>
  )
}

function FaqCard(props: {faq: FAQ; answerVisible?: boolean}) {
  const {faq, answerVisible} = props

  const startExpanded =
    Boolean(props.answerVisible) || Boolean(faq.showAnswerOnLoad)

  const [expanded, setExpanded] = React.useState(startExpanded)
  const v = useAttendeeVariables()

  const handleExpandClick = () => {
    if (answerVisible) {
      return
    }

    setExpanded(!expanded)
  }

  return (
    <Box
      onClick={() => {
        handleExpandClick()
      }}
    >
      <Card>
        <CardHeader title={v(faq.question)} />
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            <Content>{v(faq.answer)}</Content>
          </CardContent>
        </Collapse>
      </Card>
    </Box>
  )
}

import {useTemplate} from 'Event/TemplateProvider'
import {useSaveTemplate} from 'Event/TemplateUpdateProvider'
import TextField from 'lib/ui/TextField'
import {useConfig} from 'organization/Event/Configurable'
import Config from 'organization/Event/Configurable/Config'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import StylingPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/StylingPanel'
import React from 'react'
import StyleFields from 'Event/auth/TextField/StyleFields'
import Title from 'lib/ui/ConfigPanel/Title'

export default function FirstNameFieldConfig() {
  const save = useSaveTemplate()
  const {signUp, login} = useTemplate()

  const {
    form: {register},
  } = useConfig()

  return (
    <Config title="First Name Field" onSave={save}>
      <SettingsPanel>
        <Title>First Name</Title>
        <TextField
          name="signUp.firstNameLabel"
          defaultValue={signUp.firstNameLabel}
          label="Label"
          inputProps={{
            'aria-label': 'first name label',
            ref: register,
          }}
        />
      </SettingsPanel>
      <StylingPanel>
        <StyleFields templateKey="login.input" values={login.input} />
      </StylingPanel>
    </Config>
  )
}

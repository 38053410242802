import React from 'react'
import Page from 'Event/template/Panels/Login/Page'
import {usePanelsTemplate} from 'Event/template/Panels'
import {useGuestVariables} from 'Event'
import DescriptionText from 'Event/auth/Login/Description/Text'
import styled from 'styled-components'
import Configurable from 'organization/Event/Configurable'
import OfflineContentConfig from 'Event/EventOfflinePage/OfflineContentConfig'
import {OfflinePageProps} from 'Event/JoinArea/OfflinePage'

export default function EventOfflinePage(props: OfflinePageProps) {
  const v = useGuestVariables()

  return (
    <Page isPreview={props.isPreview || false}>
      <Configurable aria-label="event offline content config">
        <OfflineContentConfig />
        <>
          <Content fontSize={24}>{v(props.title)}</Content>
          <Content>
            <div dangerouslySetInnerHTML={{__html: v(props.description)}} />
          </Content>
        </>
      </Configurable>
    </Page>
  )
}

function Content(props: {
  children: string | React.ReactElement
  'aria-label'?: string
  fontSize?: number
}) {
  const template = usePanelsTemplate()
  const {login, linkColor, linkUnderline} = template
  const color = login.description.color
  const fontSize = props.fontSize
    ? props.fontSize
    : template.login.description.fontSize
  if (!props.children) {
    return null
  }

  return (
    <StyledText
      textColor={color}
      linkColor={linkColor}
      linkUnderline={linkUnderline}
      fontSize={fontSize}
      aria-label={props['aria-label']}
    >
      {props.children}
    </StyledText>
  )
}

const StyledText = styled(DescriptionText)<{
  textColor: string | null
  fontSize: number
  linkColor: string
  linkUnderline: boolean
}>`
  color: ${(props) => props.textColor};
  font-size: ${(props) => props.fontSize}px;

  a {
    color: ${(props) => props.linkColor};
    text-decoration: none;

    &:hover {
      text-decoration: ${(props) =>
        props.linkUnderline ? 'underline' : 'none'};
    }
  }
`

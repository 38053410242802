import LinkPage from 'organization/Event/Services/Apps/HighLevel/LinkPage'
import {
  BaseIntegration,
  HIGHLEVEL,
  useServices,
} from 'organization/Event/Services/ServicesProvider'
import React from 'react'
import {useQueryParams} from 'lib/url'
import AuthPage from 'organization/Event/Services/Apps/HighLevel/AuthPage'
import Config from 'organization/Event/Services/Apps/HighLevel/Config'

export type HighLevelIntegration = BaseIntegration & {
  service: typeof HIGHLEVEL
  import_tag: string | null
  tags: Tag[]
  groups: Group[]
  login_field_key: string
  login_field_id: string
  login_field_name: string
  login_field_type: string
  sync_required: boolean
}

export interface Tag {
  id: number
  name: string | null
  type: TagType
}

export interface HighLevelField {
  field_key: string
  field_id: string
  field_name: string
  field_data_type: string
}

export type Group = HighLevelField & {
  key: string | null
}

export const ATTENDEE_CREATED = 'attendee_created'
export const ATTENDEE_SIGNED_WAIVER = 'attendee_signed_waiver'
export const ATTENDEE_CHECKED_IN = 'attendee_checked_in'
export const FORM_SUBMITTED = 'form_submitted'

export type TagType =
  | typeof ATTENDEE_CREATED
  | typeof ATTENDEE_CHECKED_IN
  | typeof ATTENDEE_SIGNED_WAIVER
  | typeof FORM_SUBMITTED

export default function Highlevel() {
  const {code} = useQueryParams()
  const {isLinked} = useServices()
  const showConfig = isLinked(HIGHLEVEL)

  if (showConfig) {
    return <Config />
  }

  if (code) {
    return <AuthPage authCode={code} />
  }

  return <LinkPage />
}

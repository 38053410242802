import React, {useEffect, useState} from 'react'
import {NavButtonWithSize} from 'Event/Dashboard/components/NavButton'
import {REMOVE, useSaveTemplate} from 'Event/TemplateUpdateProvider'
import {useConfig} from 'organization/Event/Configurable'
import Config from 'organization/Event/Configurable/Config'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import StylingPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/StylingPanel'
import Settings from 'Event/Step3/TechCheck/Buttons/CustomButtons/CustomButtonConfig/Settings'
import Styling from 'Event/Step3/TechCheck/Buttons/CustomButtons/CustomButtonConfig/Styling'

export const DEFAULT_BUTTON_WIDTH_PERCENT = 100

export default function ButtonConfig(props: {
  button: NavButtonWithSize
  id: string
}) {
  const {button, id} = props

  const saveTemplate = useSaveTemplate()
  const [formReady, setFormReady] = useState(false)

  const {
    toggle: toggleConfig,
    form: {register, watch, control, setValue, getValues},
  } = useConfig()

  // Must manually register dynamic form fields for 'watch' to be triggered
  // when called via setValue.
  //
  // Reference: https://github.com/react-hook-form/react-hook-form/discussions/3160#discussioncomment-95785
  useEffect(() => {
    if (formReady) {
      return
    }

    register(`page`)
    register(`link`)
    register(`newTab`)

    for (const [key, value] of Object.entries(button)) {
      setValue(key, value)
    }

    setFormReady(true)
  }, [id, register, button, setValue, formReady])

  const remove = () => {
    saveTemplate({
      techCheck: {
        customButtons: {
          [id]: REMOVE,
        },
      },
    })
    toggleConfig()
  }

  const onSave = () => {
    const data = getValues()

    saveTemplate({
      techCheck: {
        customButtons: {
          [id]: data,
        },
      },
    })
  }

  // Need to tell Config we're updating custom button explicitly, because
  // the input names are just `text`, `link`. Without specifing the values here,
  // useAutoUpdate() would mess up previews.
  const current = {
    techCheck: {
      customButtons: {
        [id]: watch(),
      },
    },
  }

  return (
    <Config
      title="Custom Button"
      onSave={onSave}
      onRemove={remove}
      preview={current}
    >
      <SettingsPanel>
        <Settings
          button={button}
          register={register}
          setValue={setValue}
          watch={watch}
          control={control}
        />
      </SettingsPanel>
      <StylingPanel>
        <Styling button={button} watch={watch} control={control} />
      </StylingPanel>
    </Config>
  )
}

import Container from '@material-ui/core/Container'
import AppBar from 'obvio/user/Layout/AppBar'
import React from 'react'

export default function Layout(props: {
  children: React.ReactElement | React.ReactElement[]
}) {
  return (
    <>
      <AppBar />
      <Container maxWidth="lg">{props.children}</Container>
    </>
  )
}

import React from 'react'
import Page from 'organization/Event/Page'
import styled from 'styled-components'
import {RelativeLink} from 'lib/ui/link/RelativeLink'
import {useEventRoutes} from 'organization/Event/EventRoutes'
import {Redirect} from 'react-router'
import AddButton from 'organization/Event/WaiverConfig/AdditionalWaivers/AddButton'
import WaiversTable from 'organization/Event/WaiverConfig/AdditionalWaivers/WaiversTable/WaiversTable'
import {useHasWaiver} from 'Event/Step2/Waivers'
import Button from 'lib/ui/Button'
import PageBreadcrumbs from 'organization/Event/Page/PageBreadcrumbs'

export default function AdditionalWaivers() {
  const hasWaiver = useHasWaiver()
  const routes = useEventRoutes()

  // If a user hasn't set a default waiver, we'll redirect them back as
  // we require the default to be set.
  if (!hasWaiver) {
    return <Redirect to={routes.waiver.root} />
  }

  return (
    <PageBreadcrumbs
      parent={{
        title: 'Waivers',
        url: routes.waiver.root,
      }}
      page="Additional Waivers"
    >
      <Page>
        <Content />
      </Page>
    </PageBreadcrumbs>
  )
}

function Content() {
  const routes = useEventRoutes()

  return (
    <>
      <Header>
        <RelativeLink disableStyles to={routes.waiver.root}>
          <Button>Back to default waiver</Button>
        </RelativeLink>
        <AddButton />
      </Header>
      <WaiversTable />
    </>
  )
}

const Header = styled.div`
  margin-bottom: ${(props) => props.theme.spacing[5]};
  display: flex;
  justify-content: space-between;
`

import React from 'react'
import styled from 'styled-components'
import Grid from '@material-ui/core/Grid'
import LeftPanel from 'Event/template/NiftyFifty/EventOfflinePage/LeftPanel/index'
import RightPanel from 'Event/template/NiftyFifty/EventOfflinePage/RightPanel'
import {OfflinePageProps} from 'Event/JoinArea/OfflinePage'

export default function EventOfflinePage(props: OfflinePageProps) {
  return (
    <PanelsContainer container>
      <SidePanel item md={6} xs={12}>
        <LeftPanel />
      </SidePanel>
      <SidePanel item md={6} xs={12}>
        <RightPanel {...props} />
      </SidePanel>
    </PanelsContainer>
  )
}

const FullHeightGrid = styled(Grid)`
  height: 100vh;
`

export const SidePanel = styled(FullHeightGrid)`
  display: flex;
  overflow: visible;
  flex-direction: column;
  align-items: center;
`

export const PanelsContainer = styled(Grid)`
  display: flex;
  justify-content: flex-start;

  @media all and (min-width: ${(props) => props.theme.breakpoints.md}) {
    flex-direction: row;
  }
`

import React from 'react'
import styled from 'styled-components'
import Container from '@material-ui/core/Container'
import {MenuIconButton} from 'lib/ui/IconButton/MenuIconButton'
import {rgba} from 'lib/color'
import {Lefty, useLeftyTemplate} from 'Event/template/Lefty'
import {useToggle} from 'lib/toggle'
import {Editable} from 'Event/Dashboard/editor/views/EditComponent'
import HeaderConfig from 'Event/template/Lefty/Dashboard/Header/HeaderConfig'
import {useEditMode} from 'Event/EditModeProvider'
import MainNav from 'Event/template/Lefty/Dashboard/MainNav'
import Hero from 'Event/template/Lefty/Dashboard/Hero'
import {Grid, GridSize} from '@material-ui/core'

export default function Header(props: {
  toggleMenu: () => void
  menuVisible: boolean
  'aria-label'?: string
}) {
  const {flag: configVisible, toggle: toggleConfig} = useToggle()
  const template = useLeftyTemplate()
  const {menu} = template
  const {iconColor} = menu
  const isEditMode = useEditMode()

  const leftGridSize = (): GridSize => {
    if (template.logoIsCenter) {
      return 12
    }
    return 4
  }

  const rightGridSize = (): GridSize => {
    if (template.logoIsCenter) {
      return 12
    }
    return 8
  }

  const content = (
    <Background>
      <HeaderColorOverlay>
        <StyledContainer maxWidth="lg">
          <Grid container spacing={4}>
            <Grid item md={leftGridSize()}>
              <StyledMenuIconButton
                active={props.menuVisible}
                onClick={props.toggleMenu}
                aria-label="show side menu"
                iconColor={iconColor}
              />
              <LogoContainer padding={template.logoPadding}>
                <DashboardLogo />
              </LogoContainer>
            </Grid>
            <Grid item md={rightGridSize()}>
              <Hero />
              <MainNav />
            </Grid>
          </Grid>
        </StyledContainer>
      </HeaderColorOverlay>
    </Background>
  )

  if (!isEditMode) {
    return content
  }

  return (
    <>
      <HeaderConfig showing={configVisible} onClose={toggleConfig} />
      <Editable onEdit={toggleConfig}>{content}</Editable>
    </>
  )
}

function Background(props: {children: React.ReactElement}) {
  const template = useLeftyTemplate()
  const {header} = template
  const backgroundImage = header.backgroundImage

  return (
    <Box
      backgroundImage={backgroundImage}
      disableShadow={header.disableShadow || header.isCollapsed}
      isCollapsed={header.isCollapsed}
    >
      {props.children}
    </Box>
  )
}

function DashboardLogo() {
  const template = useLeftyTemplate()
  const {title} = template
  const logo = template.logo ?? ''

  return (
    <Logo
      src={logo}
      alt={title}
      size={template.logoSize}
      position={template.logoPosition}
      aria-label="logo"
    />
  )
}

function HeaderColorOverlay(props: {children: React.ReactElement}) {
  const template = useLeftyTemplate()
  const {header, contentOverlap} = template
  const backgroundColorRgb = rgba(
    header.backgroundColor,
    header.backgroundOpacity / 100,
  )

  return (
    <ColorOverlay
      color={backgroundColorRgb}
      overlap={contentOverlap}
      aria-label="header"
    >
      {props.children}
    </ColorOverlay>
  )
}

const Box = styled.div<{
  backgroundImage: string | null
  disableShadow?: boolean
  isCollapsed?: boolean
}>`
  ${(props) =>
    props.backgroundImage
      ? `background-image: url(${props.backgroundImage});`
      : null}

  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`

const ColorOverlay = styled.div<{
  color: string
  overlap: number
}>`
  background-color: ${(props) => props.color};
  padding-top: 64px;
  padding-bottom: ${(props) => props.overlap + 64}px;
`

const Logo = styled.img<{
  size: number
  position: 'Top' | 'Center' | 'Bottom'
}>`
  max-width: 100%;
  width: ${(props) => props.size || 100}%;
  object-fit: contain;
  display: block;
  margin: auto;
  margin-top: ${(props) => (props.position === 'Top' ? '0' : 'auto')};
  margin-bottom: ${(props) => (props.position === 'Bottom' ? '0' : 'auto')};
`

const StyledMenuIconButton = styled(MenuIconButton)`
  top: 0;
`

const StyledContainer = styled(Container)`
  display: flex;
  width: 100%;
  margin: 0 auto;
  flex-direction: column;

  @media (min-width: 600px) {
    width: 80%;
  }
`

const LogoContainer = styled.div<{
  padding: Lefty['logoPadding']
}>`
  height: 100%;
  display: flex;
  padding: 0;

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    padding: ${(props) => props.padding.top}px
      ${(props) => props.padding.right}px ${(props) => props.padding.bottom}px
      ${(props) => props.padding.left}px;
  }
`

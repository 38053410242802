import React from 'react'
import styled from 'styled-components'
import Drawer from '@material-ui/core/Drawer'
import IconButton from 'lib/ui/IconButton'
import {ReactComponent as CloseIcon} from 'lib/ui/ConfigDrawer/close-icon.svg'
import {APPBAR_HEIGHT} from 'lib/ui/AppBar'

export type ConfigDrawerProps = {
  children?: JSX.Element | JSX.Element[]
  open: boolean
  onClose: () => void
}

const CONTAINER_CLASSNAME = 'config-drawer-paper'

export default function ConfigDrawer(props: ConfigDrawerProps) {
  const {open, children, onClose} = props
  return (
    <StyledDrawer
      anchor="right"
      open={open}
      PaperProps={{
        className: CONTAINER_CLASSNAME,
      }}
      ModalProps={{
        onBackdropClick: onClose,
      }}
      disableEnforceFocus
      BackdropProps={{
        style: {
          backgroundColor: 'transparent',
        },
      }}
    >
      <CloseButtonBox>
        <IconButton onClick={onClose} aria-label="close config">
          <CloseIcon />
        </IconButton>
      </CloseButtonBox>
      {children}
    </StyledDrawer>
  )
}

const CloseButtonBox = styled.div`
  margin-left: -${(props) => props.theme.spacing[4]};
  margin-top: ${(props) => props.theme.spacing[3]};
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-left: 0;
  padding-right: ${(props) => props.theme.spacing[2]};
  position: absolute;
  width: 100%;
  background-color: transparent;

  @media (min-width: ${(props) => props.theme.breakpoints.sm}) {
    position: fixed;
    width: auto;
    justify-content: center;
  }

  &:hover {
    cursor: pointer;
  }
`

const StyledDrawer = styled(Drawer)`
  .${CONTAINER_CLASSNAME} {
    background-color: ${(props) => props.theme.colors.panel.background};
    color: #ffffff;
    width: 90%;
    right: 5%;
    top: 3%;
    height: 94%;

    @media (min-width: ${(props) => props.theme.breakpoints.sm}) {
      right: 0;
      top: ${APPBAR_HEIGHT}px;
      width: 480px;
      height: calc(100% - ${APPBAR_HEIGHT}px);
      border-radius: 4px 0px 0px 4px;
    }
  }
`

export const Title = styled.h3`
  font-size: 24px;
  line-height: 28px;
  font-weight: normal;
  color: ${(props) => props.theme.colors.input.filled};
  margin: ${(props) => props.theme.spacing[6]};
`

import React from 'react'
import PostForm from 'Event/Dashboard/components/BlogPosts/PostForm'
import {BlogPostList, BlogPostProps} from 'Event/Dashboard/components/BlogPosts'
import Buttons from 'Event/Dashboard/components/BlogPosts/Buttons'
import Title from 'Event/Dashboard/components/BlogPosts/Title'
import Content from 'Event/Dashboard/components/BlogPosts/Content'
import Date from 'Event/Dashboard/components/BlogPosts/Date'
import Container from 'Event/Dashboard/components/BlogPosts/Container'
import WelcomePostDialog from 'Event/Dashboard/components/BlogPosts/WelcomePostDialog'
import {useSimpleBlogTemplate} from 'Event/template/SimpleBlog'

export default function BlogPosts() {
  return <BlogPostList>{(props) => <BlogPost {...props} />}</BlogPostList>
}

function BlogPost(props: BlogPostProps) {
  const {post, postId} = props
  const template = useSimpleBlogTemplate()
  const {textColor} = template

  return (
    <>
      <WelcomePostDialog post={post} postId={postId} />
      <Container
        disableSpacing={props.isLast}
        postId={postId}
        textColor={textColor}
      >
        <Title post={post} />
        <Date post={post} />
        <Content post={post} />
        <PostForm post={post} />
        <Buttons post={post} postId={postId} />
      </Container>
    </>
  )
}

import {useCardsTemplate, useCardsUpdate} from 'Event/template/Cards'
import FontSelect from 'lib/FontSelect'
import ColorPicker from 'lib/ui/ColorPicker'
import Title from 'lib/ui/ConfigPanel/Title'
import Slider from 'lib/ui/Slider'
import TextField from 'lib/ui/TextField'
import InputLabel from 'lib/ui/TextField/InputLabel'
import {useConfig} from 'organization/Event/Configurable'
import Config from 'organization/Event/Configurable/Config'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import StylingPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/StylingPanel'
import React from 'react'
import {Controller} from 'react-hook-form'

export default function TitleConfig() {
  const save = useCardsUpdate()
  const {login} = useCardsTemplate()

  const {
    form: {register},
  } = useConfig()

  return (
    <Config title="Title" onSave={save}>
      <SettingsPanel>
        <Title>Title</Title>
        <TextField
          name="login.welcome.text"
          defaultValue={login.welcome.text}
          label="Text"
          fullWidth
          inputProps={{
            'aria-label': 'welcome text',
            ref: register,
          }}
        />
      </SettingsPanel>
      <StylingPanel>
        <StyleFields />
      </StylingPanel>
    </Config>
  )
}

export function StyleFields() {
  const {login, textColor} = useCardsTemplate()
  const welcomeTextColor = login.welcome.color ? login.welcome.color : textColor

  const {
    form: {control},
  } = useConfig()

  return (
    <>
      <Controller
        name="login.welcome.color"
        defaultValue={welcomeTextColor}
        control={control}
        render={({value, onChange}) => (
          <ColorPicker
            label="Color"
            color={value}
            onPick={onChange}
            aria-label="welcome text color"
          />
        )}
      />
      <InputLabel>Font</InputLabel>
      <Controller
        control={control}
        name="login.welcome.font"
        defaultValue={login.welcome.font || null}
        render={({value, onChange}) => (
          <FontSelect value={value} onChange={onChange} />
        )}
      />
      <Controller
        name="login.welcome.fontSize"
        defaultValue={login.welcome.fontSize}
        control={control}
        render={({value, onChange}) => (
          <Slider
            valueLabelDisplay="auto"
            aria-label="welcome font size"
            value={value}
            onChange={onChange}
            step={1}
            min={5}
            max={50}
            label="Font Size"
          />
        )}
      />
    </>
  )
}

import React, {useCallback, useRef, useState} from 'react'
import {useForm} from 'react-hook-form'
import styled from 'styled-components'
import Button from '@material-ui/core/Button'
import {
  createRoutesFor,
  useOrganization,
} from 'organization/OrganizationProvider'
import MenuItem from '@material-ui/core/MenuItem'
import {api} from 'lib/url'
import {ObvioEvent} from 'Event'
import ConfirmDialog from 'lib/ui/ConfirmDialog'
import {Organization} from 'organization/index'
import {onUnknownChangeHandler} from 'lib/dom'
import ErrorAlert from 'lib/ui/alerts/ErrorAlert'
import {teamMemberClient} from 'obvio/obvio-client'
import {useAsync} from 'lib/async'
import Select from 'lib/ui/Select'
import {useHistory} from 'react-router-dom'

export default function Form(props: {
  event: Pick<ObvioEvent, 'id' | 'name'>
  onClose: () => void
}) {
  const {handleSubmit} = useForm()
  const [submitting, setSubmitting] = useState(false)
  const {client, organization: currentOrganization} = useOrganization()
  const [error, setError] = useState<string | null>(null)
  const [newOrganization, setNewOrganization] = useState<Organization | null>(
    null,
  )
  const {organizations, loading} = useOwnedOrganizations()
  const formRef = useRef<HTMLFormElement>(null)
  const history = useHistory()

  const canSubmit = !submitting && Boolean(newOrganization)

  const submit = () => {
    if (!newOrganization) {
      setError('New Organization is required.')
      return
    }

    setError('')
    setSubmitting(true)

    const data = {organization_id: newOrganization.id}

    const url = api(`/events/${props.event.id}/move_organization`)
    client
      .post<ObvioEvent>(url, data)
      .then(() => {
        const routes = createRoutesFor(newOrganization)
        history.push(routes.events.root)
      })
      .catch((e) => {
        setError(e.message)
        setSubmitting(false)
      })
  }

  const placeholder = loading ? 'Loading ...' : 'Select organization'

  const handleSelectedOrganization = (organizationId: number) => {
    const organization = organizations.find((o) => o.id === organizationId)
    if (!organization) {
      setNewOrganization(null)
      return
    }

    setNewOrganization(organization)
  }

  const selectValue = newOrganization ? newOrganization.id : 0 // 0 for placeholder

  return (
    <form ref={formRef} onSubmit={handleSubmit(submit)}>
      <Select
        fullWidth
        variant="outlined"
        value={selectValue}
        onChange={onUnknownChangeHandler(handleSelectedOrganization)}
        aria-label="select organization"
        disabled={submitting}
      >
        <MenuItem value={0}>{placeholder}</MenuItem>
        {organizations
          .filter((o) => o.id !== currentOrganization.id)
          .map((org) => {
            return (
              <MenuItem
                key={org.id}
                value={org.id}
                aria-label={`pick ${org.name}`}
              >
                {org.name}
              </MenuItem>
            )
          })}
      </Select>
      <div>
        <ErrorAlert>{error}</ErrorAlert>
        <ConfirmDialog
          onConfirm={() => {
            // Programmatically submit form as we're currently within the dialog.
            if (formRef.current) {
              formRef.current.dispatchEvent(
                new Event('submit', {cancelable: true}),
              )
            }
          }}
          aria-label="move event confirm"
          description={`Taking this action will move the ${props.event.name} event from the ${currentOrganization.name} organization to the ${newOrganization?.name} organization.`}
        >
          {(confirm) => (
            <SaveButton
              variant="contained"
              color="primary"
              fullWidth
              disabled={!canSubmit}
              onClick={confirm}
              aria-label="move action"
            >
              Move
            </SaveButton>
          )}
        </ConfirmDialog>
      </div>
    </form>
  )
}

function useOwnedOrganizations() {
  const request = useCallback(() => {
    const url = api('/organizations?ownership=own')
    return teamMemberClient.get<Organization[]>(url)
  }, [])

  const {data, loading} = useAsync(request)

  return {
    organizations: data || [],
    loading,
  }
}

const SaveButton = styled(Button)`
  margin-bottom: ${(props) => props.theme.spacing[4]}!important;
`

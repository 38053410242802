import {useTownhallTemplate} from 'Event/template/Townhall'
import {useSaveTemplate} from 'Event/TemplateUpdateProvider'
import TextEditor, {TextEditorContainer} from 'lib/ui/form/TextEditor'
import Title from 'lib/ui/ConfigPanel/Title'
import TextField from 'lib/ui/TextField'
import {useConfig} from 'organization/Event/Configurable'
import Config from 'organization/Event/Configurable/Config'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import React from 'react'
import {Controller} from 'react-hook-form'

export default function FaqsHeaderConfig() {
  const save = useSaveTemplate()
  const {faq} = useTownhallTemplate()

  const {
    form: {register, control},
  } = useConfig()

  return (
    <Config title="Header" onSave={save}>
      <SettingsPanel>
        <TextField
          name="faq.title"
          defaultValue={faq.title}
          label="Title"
          fullWidth
          inputProps={{
            'aria-label': 'faq page title',
            ref: register,
          }}
        />
        <TextField
          name="faq.secondaryTitle"
          defaultValue={faq.secondaryTitle}
          label="Secondary Title"
          fullWidth
          inputProps={{
            'aria-label': 'faq secondary title',
            ref: register,
          }}
        />
        <Title>Description</Title>
        <Controller
          name="faq.description"
          defaultValue={faq.description}
          control={control}
          render={({onChange, value}) => (
            <TextEditorContainer>
              <TextEditor data={value} onChange={onChange} />
            </TextEditorContainer>
          )}
        />
      </SettingsPanel>
    </Config>
  )
}

import React from 'react'
import {Controller, UseFormMethods} from 'react-hook-form'
import Slider from 'lib/ui/Slider'
import ColorPicker from 'lib/ui/ColorPicker'
import {Label} from 'lib/ui/typography'
import {usePanelsTemplate} from 'Event/template/Panels'
import ImageAssetUploader from 'lib/asset/ImageAssetUploader'
import {Grid} from '@material-ui/core'
import styled from 'styled-components'

export default function Styling(props: {control: UseFormMethods['control']}) {
  const {control} = props
  const template = usePanelsTemplate()
  const {leftPanel} = template

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <StyledLabel>Logo</StyledLabel>
          <Controller
            name="logo"
            control={control}
            defaultValue={template.logo}
            render={({onChange, value}) => (
              <ImageAssetUploader
                onChange={onChange}
                value={value}
                uploadLabel="Upload"
                canResize
                uploadInputProps={{
                  'aria-label': 'logo upload',
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <StyledLabel>Mobile Logo</StyledLabel>
          <Controller
            name="mobileLogo"
            control={control}
            defaultValue={template.mobileLogo}
            render={({onChange, value}) => (
              <ImageAssetUploader
                onChange={onChange}
                value={value}
                uploadLabel="Upload"
                canResize
                uploadInputProps={{
                  'aria-label': 'mobilelogo upload',
                }}
              />
            )}
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} md={6}>
          <StyledLabel>Dashboard Background</StyledLabel>
          <Controller
            name="dashboardBackground.image"
            control={control}
            defaultValue={template.dashboardBackground.image}
            render={({onChange, value}) => (
              <ImageAssetUploader
                onChange={onChange}
                value={value}
                uploadLabel="Upload"
                canResize
                uploadInputProps={{
                  'aria-label': 'dashboard background upload',
                }}
              />
            )}
          />
        </Grid>
      </Grid>
      <Controller
        name="leftPanel.barBackgroundColor"
        defaultValue={leftPanel.barBackgroundColor}
        control={control}
        render={({value, onChange}) => (
          <ColorPicker
            label="Bar Background Color"
            color={value}
            onPick={onChange}
            aria-label="left panel bar background color"
          />
        )}
      />
      <Controller
        name="leftPanel.barTextColor"
        defaultValue={leftPanel.barTextColor}
        control={control}
        render={({value, onChange}) => (
          <ColorPicker
            label="Bar Text Color"
            color={value}
            onPick={onChange}
            aria-label="left panel bar text color"
          />
        )}
      />
      <Controller
        name="leftPanel.menuTextColor"
        defaultValue={leftPanel.menuTextColor}
        control={control}
        render={({value, onChange}) => (
          <ColorPicker
            label="Menu Text Color"
            color={value}
            onPick={onChange}
            aria-label="left panel menu text color"
          />
        )}
      />
      <Controller
        name="leftPanel.arrowColor"
        defaultValue={leftPanel.arrowColor}
        control={control}
        render={({value, onChange}) => (
          <ColorPicker
            label="Page Arrow Color"
            color={value}
            onPick={onChange}
            aria-label="page arrow color"
          />
        )}
      />
      <Controller
        name="leftPanel.backgroundColor"
        defaultValue={leftPanel.backgroundColor}
        control={control}
        render={({value, onChange}) => (
          <ColorPicker
            label="Panel Background Color"
            color={value}
            onPick={onChange}
            aria-label="left panel background color"
          />
        )}
      />
      <Controller
        name="leftPanel.backgroundOpacity"
        defaultValue={leftPanel.backgroundOpacity}
        control={control}
        render={({value, onChange}) => (
          <Slider
            min={0}
            max={100}
            step={1}
            onChange={onChange}
            valueLabelDisplay="auto"
            value={value}
            label="Background Opacity"
            unit="%"
          />
        )}
      />
    </>
  )
}

const StyledLabel = styled(Label)`
  margin-bottom: 12px;
`

import {useEvent} from 'Event/EventProvider'
import Section from 'Event/Marketplace/Section'
import {StaticPointsProvider} from 'Event/PointsProvider'
import {eventRoutes} from 'Event/Routes'
import {usePurchaseTicket} from 'lib/event-api/event/use-purchase-ticket'
import {orderedIdsByPosition} from 'lib/list'
import {usePurchasePage} from 'lib/marketplace-api/purchase-page/use-purchase-page'
import React, {useEffect} from 'react'
import {Redirect} from 'react-router'
import PurchaseProvider from 'Event/Marketplace/PurchaseProvider'
import {useLoadFont} from 'lib/FontSelect'
import Block from 'Event/Marketplace/Block'
import {getBlocks} from 'Event/Marketplace/Block/get-blocks'
import {fillTemplate} from 'Event/Marketplace/lib/fill-template'
import {PurchasePageTemplate} from 'Event/Marketplace/purchase-page'
import Container from 'Event/Marketplace/Container'

export default function PurchasePage() {
  const {event} = useEvent()
  const {data: purchasePage, isLoading} = usePurchasePage({event})

  if (!isLoading && !purchasePage) {
    return <Redirect to={eventRoutes.login} />
  }

  if (!purchasePage) {
    return null
  }

  const filledTemplate = fillTemplate(purchasePage.template)

  return <Content template={filledTemplate} />
}

function Content(props: {template: PurchasePageTemplate}) {
  const {template} = props
  const {mutate: purchase} = usePurchaseTicket()
  const {sections, scripts, typography} = template
  const sectionIds = orderedIdsByPosition(sections)

  useLoadFont(typography.font)

  useEffect(() => {
    if (!scripts || scripts === '') {
      return
    }
    const scriptToAppend = document.createElement('script')
    scriptToAppend.src = scripts
    document.body.appendChild(scriptToAppend)

    return () => {
      document.body.removeChild(scriptToAppend)
    }
  }, [scripts])

  return (
    <PurchaseProvider onSubmit={purchase}>
      <StaticPointsProvider>
        <Container template={template}>
          {sectionIds.map((id) => (
            <Section section={sections[id]} key={id} template={template}>
              {Object.entries(getBlocks(sections[id])).map(([id, block]) => (
                <Block block={block} key={id} id={id} template={template} />
              ))}
            </Section>
          ))}
        </Container>
      </StaticPointsProvider>
    </PurchaseProvider>
  )
}


import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import WidgetToolbarRepository from '@ckeditor/ckeditor5-widget/src/widgettoolbarrepository';
import { getSelectedCustomButtonWidget } from './utils';

export default class CustomButtonToolbar extends Plugin {
	/**
      * @inheritDoc
      */
	static get requires() {
		return [ WidgetToolbarRepository ];
	}

	/**
      * @inheritDoc
      */
	static get pluginName() {
		return 'CustomButtonToolbar';
	}

	/**
      * @inheritDoc
      */
	afterInit() {
		const editor = this.editor;
		const t = editor.t;
		const widgetToolbarRepository = editor.plugins.get( WidgetToolbarRepository );

		widgetToolbarRepository.register( 'customButton', {
			ariaLabel: t( 'Button toolbar' ),
			items: [
				'customButtonText',
				'customButtonLink',
				'customButtonAlignment',
				'customButtonFontSize',
				'FontBackgroundColor',
				'FontColor'
			],
			getRelatedElement: getSelectedCustomButtonWidget
		} );
	}
}

import {LoginProps} from 'Event/auth/Login'
import styled from 'styled-components'
import React from 'react'
import Typography from '@material-ui/core/Typography'
import Background from 'Event/auth/Login/Background'
import Logo from 'Event/auth/Login/Logo'
import {useSimpleBlogTemplate} from 'Event/template/SimpleBlog'

export default function Page(props: {
  isPreview?: LoginProps['isPreview']
  children: React.ReactElement | React.ReactElement[]
}) {
  const template = useSimpleBlogTemplate()
  const {textColor, login} = template

  return (
    <StyledBackground
      isPreview={props.isPreview}
      textColor={textColor}
      loginTextColor={login.description.color}
    >
      <Logo />
      <>{props.children}</>
    </StyledBackground>
  )
}

export function ErrorMessage(props: {children?: string}) {
  if (!props.children) {
    return null
  }

  return <ErrorText color="error">{props.children}</ErrorText>
}

const StyledBackground = styled(Background)<{
  textColor: string
  loginTextColor: string | null
}>`
  color: ${(props) =>
    props.loginTextColor ? props.loginTextColor : props.textColor} !important;
`

export const DescriptionText = styled.div<{
  textColor?: string | null
  fontSize: number
  disableMargin?: boolean
  linkColor?: string
  linkUnderline?: boolean
}>`
  color: ${(props) => props.textColor};
  font-size: ${(props) => props.fontSize}px;
  font-weight: 500;
  margin-bottom: ${(props) =>
    props.disableMargin ? 0 : props.theme.spacing[8]};
  text-align: center;

  a {
    color: ${(props) => props.linkColor};
    text-decoration: none;

    &:hover {
      text-decoration: ${(props) =>
        props.linkUnderline ? 'underline' : 'none'};
    }
  }
`

export const ErrorText = styled(Typography)`
  margin-bottom: ${(props) => props.theme.spacing[3]}!important;
  text-align: center;
`

import {useTemplate} from 'Event/TemplateProvider'
import {useSaveTemplate} from 'Event/TemplateUpdateProvider'
import styled from 'styled-components'
import {onChangeCheckedHandler} from 'lib/dom'
import React from 'react'
import {EnabledSwitch} from 'lib/ui/form/Switch'

export default function ToggleSpeakerSwitch() {
  const {speakers} = useTemplate()
  const save = useSaveTemplate()
  const toggle = (isEnabled: boolean) => {
    save({
      speakers: {
        isEnabled,
      },
    })
  }

  return (
    <StyledEnabledSwitch
      checked={speakers.isEnabled}
      onChange={onChangeCheckedHandler(toggle)}
      aria-label="toggle speakers"
    />
  )
}

const StyledEnabledSwitch = styled(EnabledSwitch)`
  margin-bottom: 0;
`

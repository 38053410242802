import {isPast, isFuture} from 'lib/date-time'
import {useEditMode} from 'Event/EditModeProvider'
import {useInterval} from 'lib/interval'
import {useCallback, useEffect, useState} from 'react'
import {useNow} from 'lib/date-time/DateProvider'

export const SHOWING_FROM = 'showingFrom'
export const SHOWING_UNTIL = 'showingUntil'

export interface HasSchedule {
  showingFrom?: string | null
  showingUntil?: string | null
}

export default function Scheduled(props: {
  component: HasSchedule
  children: JSX.Element
}) {
  const isEditMode = useEditMode()
  const {showingFrom, showingUntil} = props.component
  const hasSchedule = showingFrom || showingUntil
  const now = useNow()

  // Whether to show on first render
  const showingOnStart = () => {
    if (isEditMode) {
      return true
    }

    if (!hasSchedule) {
      return true
    }

    return false
  }

  const [showing, setShowing] = useState(showingOnStart())

  const checkShowing = useCallback(() => {
    // Always show when editing
    if (isEditMode && !showing) {
      setShowing(true)
      return
    }

    if (!hasSchedule && !showing) {
      setShowing(true)
      return
    }

    const isInFuture = showingFrom && !isPast(showingFrom, now)
    if (!isEditMode && showing && isInFuture) {
      setShowing(false)
      return
    }

    const isAlreadyPast = showingUntil && !isFuture(showingUntil, now)
    if (!isEditMode && showing && isAlreadyPast) {
      setShowing(false)
      return
    }

    if (showing) {
      return
    }

    if (isInFuture || isAlreadyPast) {
      return
    }

    setShowing(true)
  }, [isEditMode, showingFrom, showing, showingUntil, hasSchedule, now])

  useInterval(checkShowing, 1000) // Check every second

  // Immediately check whether to show the component without
  // waiting for the interval tick.
  useEffect(checkShowing, [checkShowing])

  if (!showing) {
    return null
  }

  return props.children
}

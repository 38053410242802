import React from 'react'

/**
 * Functional react util to check if a given React child is a functional
 * component definition. Useful for HOCs.
 *
 * @param fn
 * @param component
 * @returns
 */
export function is<T>(
  fn: React.FC<T>,
  component: JSX.Element,
): fn is React.FC<T> {
  return fn === component.type
}

/**
 * Whether an element has further elements as children.
 *
 * @param component
 * @returns
 */
export function hasChildren(component: JSX.Element) {
  return typeof component.props.children === 'object'
}

/**
 * Assigns provided props to all children.
 *
 * @param props
 * @param children
 * @returns
 */
export function assignProps(props: any, children: JSX.Element | JSX.Element[]) {
  if (Array.isArray(children)) {
    // If a 'key' was provided, it would be overwritten. In case one wasn't
    // provided, we'll just set an index which is required.
    return children.map((c, index) => addProps({key: index, ...props}, c))
  }

  return addProps(props, children)
}

function addProps(props: any, component: JSX.Element) {
  const assigned = {...component.props, ...props}
  return React.cloneElement(component, assigned)
}

import MuiTextField from '@material-ui/core/TextField'
import styled from 'styled-components'

/**
 * Custom TextField only for Business Mastery eveents
 */
const TextField = styled(MuiTextField)`
  /* Always want all text to be black, including placeholders */
  label {
    color: #000000;
  }

  * {
    color: #000000 !important;
  }

  .Mui-disabled {
    cursor: not-allowed;
  }
`

export default TextField

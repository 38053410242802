import {useAttendee} from 'Event/auth'
import {eventRoutes} from 'Event/Routes'
import React from 'react'
import {Redirect} from 'react-router-dom'
import {useTemplate} from 'Event/TemplateProvider'
import {
  useHasWaiver,
  useHasWaiverFormAnswers,
  useWaiverForm,
} from 'Event/Step2/Waivers'
import {useTrackEventPage} from 'analytics'
import {components} from 'Event/template'
import {User} from 'auth/user'
import {hasMatch} from 'Event/attendee-rules/matcher'
import {useSubmissions} from 'Event/SubmissionsProvider'
import {useEvent} from 'Event/EventProvider'
import Scripts, {CHECK_IN_WAIVER} from 'Event/Scripts'

export type Step2Props = {
  user: User
}

export default function Step2() {
  const attendee = useAttendee()

  useTrackEventPage({
    page: 'Visited Step 2',
  })

  const needsToCompleteStep2 = useNeedsToCompleteStep2()
  if (!needsToCompleteStep2) {
    return <Redirect to={eventRoutes.step3} />
  }

  return (
    <>
      <Scripts page={CHECK_IN_WAIVER} />
      <TemplateStep2 user={attendee} />
    </>
  )
}

export function TemplateStep2(props: Step2Props) {
  const template = useTemplate()
  const Component = components[template.name].Step2
  return <Component {...props} />
}

export function useMatchesRulesToSkipWaiver() {
  const template = useTemplate()
  const attendee = useAttendee()
  const {answers} = useSubmissions()
  const {event} = useEvent()
  const {forms} = event

  return hasMatch(
    {groups: attendee.groups, tags: attendee.tags, answers, forms},
    template.waiver.skipRules,
  )
}

export function useNeedsToCompleteStep2() {
  const {
    waiver: {signatureRequired, formEnabled},
  } = useTemplate()

  const matchesRulesToSkip = useMatchesRulesToSkipWaiver()
  const attendee = useAttendee()
  const hasAnswers = useHasWaiverFormAnswers()
  const waiverForm = useWaiverForm()
  const hasWaiver = useHasWaiver()

  const hasWaiverFormQuestions =
    formEnabled && waiverForm && waiverForm.questions.length > 0

  if (!hasWaiver) {
    return false
  }

  if (matchesRulesToSkip) {
    return false
  }

  if (signatureRequired && !formEnabled) {
    return !attendee.waiver
  }

  if (signatureRequired && hasWaiverFormQuestions && hasAnswers) {
    return !attendee.waiver
  }

  if (hasWaiverFormQuestions) {
    return !hasAnswers
  }

  if (signatureRequired) {
    return !attendee.waiver
  }

  // No form, or signature. There's nothing to complete here.
  return false
}

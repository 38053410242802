import React from 'react'

import {handleAutocomplete, onChangeStringHandler} from 'lib/dom'

import {Autocomplete} from '@material-ui/lab'
import TextField from '@material-ui/core/TextField'
import {
  Property,
  PropertyOption,
  useHubspotProperties,
} from 'organization/Event/Services/Apps/Hubspot/Config/HubspotPropertiesProvider'
import styled from 'styled-components'

/**
 * Property types that have list values
 */
const LIST_TYPES: Property['type'][] = [
  'checkbox',
  'select',
  'radio',
  'booleancheckbox',
]

export default function PropertyValueField(props: {
  value: string | null
  propertyName: string
  propertyType: Property['type']
  onChange: (selected: string) => void
  errorText?: string
  textLabel?: string
  checkboxLabel?: string
  disabled?: boolean
}) {
  const {propertyType} = props
  if (propertyType === 'text') {
    return <TextInput {...props} />
  }

  const hasOptions = LIST_TYPES.includes(propertyType)
  if (hasOptions) {
    return <ListInput {...props} />
  }

  throw new Error(`Property type not specified.`)
}

function TextInput(props: {
  onChange: (selected: string) => void
  value: string | null
  errorText?: string
  textLabel?: string
  disabled?: boolean
}) {
  const {errorText, textLabel, disabled} = props

  return (
    <Box>
      <TextField
        value={props.value || ''}
        onChange={onChangeStringHandler(props.onChange)}
        label={textLabel ?? 'Fill Property With Text'}
        variant="outlined"
        fullWidth
        error={Boolean(errorText)}
        helperText={errorText || ''}
        inputProps={{
          'aria-label': 'field value equal to',
          maxLength: 100, // mailchimp limit
        }}
        disabled={disabled}
      />
    </Box>
  )
}

function ListInput(props: {
  propertyName: string
  onChange: (selected: string) => void
  value: string | null
  errorText?: string
  checkboxLabel?: string
  disabled?: boolean
}) {
  const {errorText, checkboxLabel, propertyName, disabled} = props
  const {findProperty: findByName} = useHubspotProperties()

  const property = findByName(propertyName)
  const options = property?.options || []

  const handleSelected = (option: PropertyOption | null) => {
    props.onChange(option?.value ?? '')
  }

  const optionLabel = (option: PropertyOption | null) => option?.label || ''

  const getSelectedOption = (name: string | null) =>
    options.find((o) => o.value === name)

  const noOptionsText = () => {
    return `Could not find a field containing search word`
  }

  const labelText = () => {
    return checkboxLabel ?? 'Fill Property With'
  }

  return (
    <Box>
      <Autocomplete
        value={getSelectedOption(props.value) || null}
        disablePortal
        options={options}
        aria-label={'field autocomplete'}
        onChange={handleAutocomplete(handleSelected)}
        getOptionLabel={optionLabel}
        closeIcon=""
        noOptionsText={noOptionsText()}
        getOptionSelected={(option, value) => option?.value === value?.value}
        disabled={disabled}
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              variant="outlined"
              label={labelText()}
              fullWidth
              error={Boolean(errorText)}
              helperText={errorText || ''}
              inputProps={{
                ...params.inputProps,
                'aria-label': 'field include value',
              }}
              InputProps={{
                ...params.InputProps,
              }}
            />
          )
        }}
      />
    </Box>
  )
}

const Box = styled.div`
  flex: 1;
`

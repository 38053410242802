import React from 'react'
import {useTownhallTemplate} from 'Event/template/Townhall'
import {useAttendeeVariables} from 'Event'

export default function Copyright() {
  const {footer} = useTownhallTemplate()
  const v = useAttendeeVariables()

  if (!footer.copyrightText) {
    return null
  }

  return <p aria-label="copyright">{v(footer.copyrightText)}</p>
}

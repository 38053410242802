import Container from 'Event/Marketplace/Block/Container'
import React from 'react'
import styled from 'styled-components'
import ListItem from 'Event/Marketplace/Block/FaqList/ListItem'
import {BlockComponentProps} from 'Event/Marketplace/Block'
import {HashMap, Ordered, orderedIdsByPosition} from '../../lib/list'
import {BlockBase} from 'Event/Marketplace/Block/base'
import {Font} from 'Event/Marketplace/lib/font'
import {useLoadFont} from 'lib/FontSelect'

export type FaqItem = Ordered & {
  visibility: 'all' | 'mobile_only' | 'desktop_only'
  question: string
  answer: string
  showAnswerOnLoad: boolean
  background: {
    image: string
    color: string
    opacity: number
  }
  border: {
    width: number
    color: string
    radius: number
  }
}

export interface FaqListBlock extends BlockBase {
  type: 'FaqList'
  icon: string | null
  font: Font | null
  fontSize: number
  color: string
  iconColor: string
  iconSize: number
  faqSpace: number
  textSpace: number
  items: HashMap<FaqItem>
}

export default function FaqList(props: FaqListBlock & BlockComponentProps) {
  useLoadFont(props.font)

  return (
    <FaqListContainer {...props}>
      <ListItems {...props} />
    </FaqListContainer>
  )
}

export function FaqListContainer(
  props: FaqListBlock &
    BlockComponentProps & {
      children: React.ReactNode
    },
) {
  const {children} = props

  return (
    <Container {...props}>
      <FaqListBox>{children}</FaqListBox>
    </Container>
  )
}

function ListItems(props: FaqListBlock) {
  const {items} = props

  if (!items) {
    return null
  }

  const sortedIds = orderedIdsByPosition(items)

  return (
    <>
      {sortedIds.map((id) => (
        <ListItem key={id} {...props} item={items[id]} />
      ))}
    </>
  )
}

const FaqListBox = styled.div`
  flex-direction: column;

  & > div > div {
    left: auto !important;
    top: auto !important;
  }
`

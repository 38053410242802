import {useHasAny} from 'organization/HasPermission'
import {Permission} from 'organization/PermissionsProvider'
import React from 'react'

export default function AuthorizedPage(props: {
  permission: Permission | Permission[]
  children: React.ReactElement
}) {
  const {children, permission} = props
  const hasAny = useHasAny()

  if (!hasAny(permission)) {
    return <Forbidden />
  }

  return children
}

function Forbidden() {
  return <div>You do not have permission to view this page.</div>
}

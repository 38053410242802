import React from 'react'
import FormControl from '@material-ui/core/FormControl'
import Select, {SelectProps} from 'lib/ui/Select'
import Option from 'lib/ui/Select/Option'
import {onUnknownChangeHandler} from 'lib/dom'
import {eventRoutes} from 'Event/Routes'

export type FeaturePage =
  | 'speakers'
  | 'sponsors'
  | 'faq'
  | 'image_waterfall'
  | 'zoom_backgrounds'
  | 'leaderboard'

interface FeaturePageOption {
  key: FeaturePage
  label: string
  link: string
}

export const featurePages: readonly FeaturePageOption[] = [
  {
    key: 'speakers',
    label: 'Speakers',
    link: eventRoutes.speakers,
  },
  {
    key: 'sponsors',
    label: 'Sponsors',
    link: eventRoutes.sponsors,
  },
  {
    key: 'faq',
    label: 'FAQ',
    link: eventRoutes.faq,
  },
  {
    key: 'image_waterfall',
    label: 'Image Waterfall',
    link: eventRoutes.image_waterfall,
  },
  {
    key: 'zoom_backgrounds',
    label: 'Zoom Backgrounds',
    link: eventRoutes.backgrounds,
  },
  {
    key: 'leaderboard',
    label: 'Leaderboard',
    link: eventRoutes.leaderboard,
  },
]

interface FeaturePageSelectProps
  extends Omit<SelectProps, 'value' | 'onChange'> {
  dark?: boolean
  value: FeaturePage
  onChange: (page: FeaturePage) => void
}

export default function FeaturePageSelect(props: FeaturePageSelectProps) {
  const {dark, value, onChange} = props

  return (
    <FormControl fullWidth>
      <Select
        label="Select page"
        aria-label="select page"
        value={value}
        fullWidth
        onChange={onUnknownChangeHandler(onChange)}
      >
        {featurePages.map(({key, label}) => (
          <Option value={key} dark={dark} key={key}>
            {label}
          </Option>
        ))}
      </Select>
    </FormControl>
  )
}

import {BlockComponentProps} from 'Event/Marketplace/Block'
import Container from 'Event/Marketplace/Block/Container'
import {BlockBase} from '../base'
import React from 'react'
import styled from 'styled-components'

export interface ImageBlock extends BlockBase {
  type: 'Image'
  image: null | string
  size: number
  sizeMobile: number
  borderWidth: number
  borderRadius: number
  borderColor: string
}

export default function Image(props: ImageBlock & BlockComponentProps) {
  const {image} = props
  if (!image) {
    return (
      <Container {...props}>
        <></>
      </Container>
    )
  }

  return (
    <Container {...props}>
      <StyledImage {...props} src={image} alt="block" />
    </Container>
  )
}

const StyledImage = styled.img<ImageBlock>`
  width: ${(props) => props.sizeMobile}%;
  border: ${(props) => props.borderWidth}px solid
    ${(props) => props.borderColor};
  border-radius: ${(props) => props.borderWidth}%;

  @media (min-width: ${(props) => props.theme.breakpoints.sm}) {
    width: ${(props) => props.size}%;
  }
`

import React, {useState} from 'react'
import {useEditMode} from 'Event/EditModeProvider'
import UploadDialog from 'Event/Dashboard/components/NavButton/ImageEntryUpload/UploadDialog'
import {NavButtonProps, Button} from 'Event/Dashboard/components/NavButton'
import {useTemplate} from 'Event/TemplateProvider'

export default function ImageEntryUpload(props: NavButtonProps) {
  const [visibleUploadForm, setVisibleUploadForm] = useState(false)
  const isEditMode = useEditMode()
  const template = useTemplate()
  const {imageWaterfall} = template

  const toggleVisibleUploadForm = () => setVisibleUploadForm(!visibleUploadForm)

  if (!imageWaterfall.isVisible) {
    return null
  }

  /**
   * Don't show uploader in edit mode or the user might think it works
   */
  if (isEditMode) {
    return <Button {...props} />
  }

  return (
    <>
      <UploadDialog
        onClose={toggleVisibleUploadForm}
        visible={visibleUploadForm}
      />

      <Button {...props} onClick={toggleVisibleUploadForm} />
    </>
  )
}

import React from 'react'
import styled from 'styled-components'
import Container from '@material-ui/core/Container'
import {withStyles} from '@material-ui/core/styles'
import {MenuIconButton} from 'lib/ui/IconButton/MenuIconButton'
import {useLeftyTemplate} from 'Event/template/Lefty'
import {useToggle} from 'lib/toggle'
import {Editable} from 'Event/Dashboard/editor/views/EditComponent'
import HeaderConfig from 'Event/template/Lefty/FeaturePageHeader/HeaderConfig'
import {useEditMode} from 'Event/EditModeProvider'
import {rgba} from 'lib/color'

export default function FeaturePageHeader(props: {
  toggleMenu: () => void
  menuVisible: boolean
  'aria-label'?: string
}) {
  const {flag: configVisible, toggle: toggleConfig} = useToggle()
  const template = useLeftyTemplate()
  const {menu, featurePageHeader, header} = template
  const {
    menuBarHeight,
    menuBarBackgroundColor,
    menuBarBackgroundOpacity,
    backgroundBarHeight,
  } = featurePageHeader
  const isEditMode = useEditMode()
  const backgroundImage =
    featurePageHeader.backgroundImage ?? header.backgroundImage
  const menuBackgroundColor = rgba(
    menuBarBackgroundColor,
    menuBarBackgroundOpacity,
  )
  const iconColor = featurePageHeader.menuIconColor ?? menu.iconColor

  const content = (
    <>
      <MenuBar height={menuBarHeight} backgroundColor={menuBackgroundColor}>
        <StyledContainer maxWidth="lg">
          <StyledMenuIconButton
            active={props.menuVisible}
            onClick={props.toggleMenu}
            aria-label="show feature side menu"
            iconColor={iconColor}
          />
          <HeaderLogo />
        </StyledContainer>
      </MenuBar>
      <BackgroundBar
        backgroundImage={backgroundImage}
        disableShadow={header.disableShadow || header.isCollapsed}
        height={backgroundBarHeight}
      />
    </>
  )

  if (!isEditMode) {
    return content
  }

  return (
    <>
      <HeaderConfig showing={configVisible} onClose={toggleConfig} />
      <Editable onEdit={toggleConfig}>{content}</Editable>
    </>
  )
}

function HeaderLogo() {
  const template = useLeftyTemplate()
  const {title, featurePageHeader} = template
  const logo = featurePageHeader.logo ?? template.logo ?? ''

  return (
    <Logo
      src={logo}
      alt={title}
      height={featurePageHeader.logoHeight}
      max={featurePageHeader.menuBarHeight}
      aria-label="logo"
    />
  )
}

const MenuBar = styled.div<{
  height: number
  backgroundColor: string
}>`
  display: flex;
  align-items: center;
  height: ${(props) => props.height}px;
  background-color: ${(props) => props.backgroundColor};
`

const BackgroundBar = styled.div<{
  backgroundImage: string | null
  disableShadow?: boolean
  height: number
}>`
  ${(props) =>
    props.backgroundImage
      ? `background-image: url(${props.backgroundImage});`
      : null}

  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: ${(props) => props.height}px;
`

const StyledMenuIconButton = styled(MenuIconButton)`
  top: 0;
  transform: translateY(0);
  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    margin-left: 100px;
  }
`

const Logo = styled.img<{
  height: number
  max: number
}>`
  object-fit: contain;
  display: block;
  margin-left: ${(props) => props.theme.spacing[8]};
  width: ${(props) => props.height}px;
  height: ${(props) => props.height}px;
  max-width: ${(props) => props.max}px;
  max-height: ${(props) => props.max}px;
`

const StyledContainer = withStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
})(Container)

import Drawer from '@material-ui/core/Drawer'
import styled from 'styled-components'
import {useLayout} from 'organization/Event/Layout'
import Sidebar from 'organization/Event/Layout/Sidebar'
import React, {useCallback, useEffect, useLayoutEffect, useState} from 'react'
import {useOnResize} from 'lib/resize'

export default function MobileSidebar() {
  const {
    showingMobileSidebar,
    toggleMobileSidebar,
    sidebarExpanded,
    toggleSidebar,
  } = useLayout()

  const [isMobile, setIsMobile] = useState(false)

  const setScreenSize = useCallback(() => {
    const {innerWidth: width} = window
    // Ideally we reference the theme breakpoint, but since that'd involve
    // some extra work which could result in a janky UI, we'll just
    // hardcode the breakpoint width here.
    const isMobile = width <= 960

    setIsMobile(isMobile)
  }, [setIsMobile])

  const handleResize = useCallback(() => {
    setScreenSize()
  }, [setScreenSize])

  // Auto open/hide on window resize
  useOnResize(handleResize)
  useLayoutEffect(setScreenSize, [setScreenSize]) // check on load

  // Auto-hide when expanding to desktop
  useEffect(() => {
    if (!isMobile && showingMobileSidebar) {
      toggleMobileSidebar()
    }
  }, [isMobile, showingMobileSidebar, toggleMobileSidebar])

  // Always show expanded sidebar in mobile mode
  useEffect(() => {
    if (!showingMobileSidebar) {
      return
    }

    if (!sidebarExpanded) {
      toggleSidebar()
    }
  }, [showingMobileSidebar, sidebarExpanded, toggleSidebar])

  return (
    <Drawer
      variant="temporary"
      open={showingMobileSidebar}
      onClose={toggleMobileSidebar}
    >
      <StyledSidebar />
    </Drawer>
  )
}

// Only show on mobile
const StyledSidebar = styled(Sidebar)`
  display: flex;

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    display: none;
  }
`

import React from 'react'
import DefaultButtons from 'Event/Dashboard/components/BlogPosts/Buttons'
import {useEditMode} from 'Event/EditModeProvider'
import {TownhallBlogPost} from 'Event/template/Townhall/Dashboard/Main/BlogSection'
import {NavButtonWithSize} from 'Event/Dashboard/components/NavButton'
import {REMOVE, useSaveTemplate} from 'Event/TemplateUpdateProvider'
import {uuid} from 'lib/uuid'

export type BlogPostProps = {
  post: TownhallBlogPost
  postId: string
  sectionId: string
}

export default function Buttons(props: BlogPostProps) {
  const {post, postId} = props
  const isEditMode = useEditMode()

  if (isEditMode) {
    return <EditableButtons {...props} />
  }

  return <DefaultButtons post={post} postId={postId} />
}

function EditableButtons(props: BlogPostProps) {
  const {post, postId, sectionId} = props

  const save = useSaveTemplate()

  const addPostButton = (button: NavButtonWithSize) => {
    const id = uuid()
    save({
      dashboardSections: {
        [sectionId]: {
          items: {
            [postId]: {
              buttons: {
                [id]: button,
              },
            },
          },
        },
      },
    })
  }

  const updatePostButton = (
    id: string,
    button: NavButtonWithSize | typeof REMOVE,
  ) => {
    save({
      dashboardSections: {
        [sectionId]: {
          items: {
            [postId]: {
              buttons: {
                [id]: button,
              },
            },
          },
        },
      },
    })
  }

  return (
    <DefaultButtons
      post={post}
      postId={postId}
      onAdd={addPostButton}
      onUpdate={updatePostButton}
      sectionId={sectionId}
    />
  )
}

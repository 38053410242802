import React, {useState} from 'react'
import Container from 'Event/Marketplace/Block/Container'
import CustomerDetailStep from 'Event/Marketplace/Block/PurchaseForm/CustomerDetailStep'
import Step from 'Event/Marketplace/Block/PurchaseForm/Step'
import BillingAddressStep from 'Event/Marketplace/Block/PurchaseForm/BillingAddressStep'
import ShippingAddressStep from 'Event/Marketplace/Block/PurchaseForm/ShippingAddressStep'
import OrderConfirmationStep from 'Event/Marketplace/Block/PurchaseForm/OrderConfirmationStep'
import PurchaseFormProvider, {
  usePurchaseForm,
} from 'Event/Marketplace/Block/PurchaseForm/PurchaseFormProvider'
import PurchaseFormStripe from 'Event/Marketplace/PurchaseFormStripe'
import {useStripeAccountId} from 'lib/marketplace-api/purchase-setup/use-stripe-account-id'
import {useEvent} from 'Event/EventProvider'
import styled from 'styled-components'
import PurchaseFormTextField from 'Event/Marketplace/Block/PurchaseForm/PurchaseFormTextField'
import PurchaseFormButton from 'Event/Marketplace/Block/PurchaseForm/PurchaseFormButton'
import {BlockComponentProps} from 'Event/Marketplace/Block'
import {BlockBase} from 'Event/Marketplace/Block/base'
import {Column} from 'Event/Marketplace/lib/layout'
import {Font} from 'Event/Marketplace/lib/font'

export const ADDRESS_TYPE_BILLING = 'billing'
export const ADDRESS_TYPE_SHIPPING = 'shipping'
export type AddressType =
  | typeof ADDRESS_TYPE_BILLING
  | typeof ADDRESS_TYPE_SHIPPING

export interface AttendeeCollectionProps {
  active: boolean
  backClick?: () => void
  config: PurchaseFormBlock
  continueClick: () => void
  disabled?: boolean
}

export type PurchaseStep =
  | 'customer_details'
  | 'address_billing'
  | 'address_shipping'
  | 'order_confirmation'

interface PurchaseFormProps extends PurchaseFormBlock {
  TextFieldComponent?: React.FC<any>
  ButtonComponent?: React.FC<any>
}

export type PlaceholderType =
  | 'firstName'
  | 'lastName'
  | 'email'
  | 'phone'
  | 'billingAddress1'
  | 'billingAddress2'
  | 'city'
  | 'state'
  | 'zipcode'
  | 'country'

export interface PurchaseFormBlock extends BlockBase {
  type: 'PurchaseForm'
  isHidden: boolean
  requireAddresses: boolean
  errorMessage: string
  input: {
    textColor: string
    fontSize: number
    backgroundColor: string
    spacing: number
    border: {
      width: number
      radius: number
      color: string
    }
    padding: {
      left: number
      right: number
      top: number
      bottom: number
    }
    placeholder: {
      firstName: string
      lastName: string
      email: string
      phone: string
      billingAddress1: string
      billingAddress2: string
      city: string
      state: string
      zipcode: string
      country: string
      shippingAddress1: string
      shippingAddress2: string
      shippingcity: string
      shippingstate: string
      shippingzipcode: string
      shippingcountry: string
    }
  }
  nextStepButton: {
    text: string
    icon?: string | null
    iconAlign?: 'left' | 'right'
    image?: string
  }
  purchaseButton: {
    text: string
    icon?: string | null
    iconAlign?: 'left' | 'right'
    image?: string
  }
  buttonStyle: {
    size: Column
    font: Font | null
    fontSize?: number
    backgroundColor?: string
    hoverBackgroundColor?: string
    textColor?: string
    hoverTextColor?: string
    borderRadius?: number
    borderWidth?: number
    borderColor?: string
    hoverBorderColor?: string
  }
  phoneNumber: {
    enabled: boolean
    required: boolean
  }
  summary: {
    label: {
      attendeeDetailsLabel: string
      billingAddressLabel: string
      shippingAddressLabel: string
      purchaseLabel: string
      totalLabel: string
      color: string
      fontSize: number
    }
    text: {
      color: string
      fontSize: number
    }
  }
  errors: {
    requiredField: string
  }
  questions: {
    sameShippingAddress: string
  }
}

export default function PurchaseForm(
  props: PurchaseFormProps & BlockComponentProps,
) {
  const {event} = useEvent()
  const {data: stripeAccountId} = useStripeAccountId({event})

  if (!stripeAccountId) {
    return null
  }

  return (
    <PurchaseFormStripe stripeAccountId={stripeAccountId}>
      <PurchaseFormProvider>
        <Content {...props} />
      </PurchaseFormProvider>
    </PurchaseFormStripe>
  )
}

function Content(props: PurchaseFormProps & BlockComponentProps) {
  const {
    requireAddresses,
    TextFieldComponent = PurchaseFormTextField,
    ButtonComponent = PurchaseFormButton,
  } = props
  const [step, setStep] = useState<PurchaseStep>('customer_details')

  const {
    useSameAddressForShipping,
    form: {clearErrors},
  } = usePurchaseForm()

  const changeStep = (step: PurchaseStep) => {
    clearErrors() // clear errors on every page change
    setStep(step)
  }

  return (
    <Container {...props}>
      <ContentBox>
        <Step showing={step === 'customer_details'}>
          <CustomerDetailStep
            TextFieldComponent={TextFieldComponent}
            ButtonComponent={ButtonComponent}
            {...props}
            onContinue={() =>
              changeStep(
                requireAddresses ? 'address_billing' : 'order_confirmation',
              )
            }
          />
        </Step>
        {requireAddresses && (
          <>
            <Step showing={step === 'address_billing'}>
              <BillingAddressStep
                TextFieldComponent={TextFieldComponent}
                ButtonComponent={ButtonComponent}
                onBack={() => changeStep('customer_details')}
                {...props}
                onContinue={() =>
                  changeStep(
                    useSameAddressForShipping
                      ? 'order_confirmation'
                      : 'address_shipping',
                  )
                }
              />
            </Step>

            <Step showing={step === 'address_shipping'}>
              <ShippingAddressStep
                {...props}
                TextFieldComponent={TextFieldComponent}
                ButtonComponent={ButtonComponent}
                onBack={() => changeStep('address_billing')}
                onContinue={() => changeStep('order_confirmation')}
              />
            </Step>
          </>
        )}

        <Step showing={step === 'order_confirmation'}>
          <OrderConfirmationStep
            {...props}
            ButtonComponent={ButtonComponent}
            onBack={() =>
              requireAddresses
                ? changeStep('address_billing')
                : changeStep('customer_details')
            }
          />
        </Step>
      </ContentBox>
    </Container>
  )
}

const ContentBox = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`

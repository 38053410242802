import styled from 'styled-components'
import React from 'react'
import Button from 'lib/ui/Button'
import Typography from '@material-ui/core/Typography'
import {useBroadcastForm} from 'organization/Event/Broadcasts/BroadcastForm'
import SaveSmsButton from 'organization/Event/Broadcasts/BroadcastForm/SaveButton/SaveSmsButton'

export default function SaveButton(props: {
  readOnly?: boolean
  disabled?: boolean
}) {
  const {readOnly, disabled} = props
  const {channel, hasAgreedToSmsCharges} = useBroadcastForm()

  const canSubmit = () => {
    if (disabled) {
      return false
    }

    if (channel === 'sms') {
      return hasAgreedToSmsCharges
    }

    return true
  }

  if (readOnly) {
    return (
      <Typography color="error" align="center">
        Sent broadcasts can't be edited
      </Typography>
    )
  }

  if (channel === 'sms') {
    return (
      <Box>
        <SaveSmsButton disabled={disabled} />
      </Box>
    )
  }

  return (
    <Box>
      <Button
        variant="contained"
        color="success"
        fullWidth
        disabled={canSubmit() === false}
        type="submit"
      >
        Save
      </Button>
    </Box>
  )
}

const Box = styled.div`
  margin: ${(props) => props.theme.spacing[4]} 0;
`

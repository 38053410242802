import {useEditMode} from 'Event/EditModeProvider'
import React from 'react'

export default function Visible(props: {
  when: boolean
  showInEditmode?: boolean
  children: React.ReactElement | JSX.Element[]
}) {
  const isEditMode = useEditMode()

  /**
   * Always show component in edit mode, otherwise it would be pretty
   * difficult to configure.
   */
  if (isEditMode && props.showInEditmode) {
    return <>{props.children}</>
  }

  if (!props.when) {
    return null
  }

  return <>{props.children}</>
}

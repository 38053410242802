import React from 'react'
import {Controller} from 'react-hook-form'
import Checkbox from 'lib/ui/Checkbox'
import BackgroundPicker from 'lib/ui/form/BackgroundPicker'
import CollapsibleSection from 'lib/ui/ConfigPanel/CollapsibleSection'
import styled from 'styled-components'
import Visible from 'lib/ui/layout/Visible'
import {DEFAULT_BUTTON_BACKGROUND_COLOR} from 'lib/ui/Button/CustomButton'
import {Resource} from 'Event/template/Townhall/Dashboard/Main/ResourceSection/ResourceItem'
import {useConfig} from 'organization/Event/Configurable'

export default function BackgroundSection(props: {
  storageKey: string
  resource: Resource
  sectionId: string
  id: string
}) {
  const {resource, id, sectionId} = props

  const {
    form: {control, watch},
  } = useConfig()

  const showingHoverBackgroundInput = !watch(
    'disableHover',
    resource.disableHover,
  )

  return (
    <CollapsibleSection label="Background" storageKey={props.storageKey}>
      <Controller
        name={`dashboardSections.${sectionId}.items.${id}.backgroundColor`}
        control={control}
        defaultValue={
          resource.backgroundColor || DEFAULT_BUTTON_BACKGROUND_COLOR
        }
        render={({value, onChange}) => (
          <BackgroundPicker
            label="Background"
            background={value}
            onChange={onChange}
          />
        )}
      />
      <Controller
        name={`dashboardSections.${sectionId}.items.${id}.disableHover`}
        control={control}
        defaultValue={resource.disableHover || false}
        render={({value, onChange}) => (
          <StyledCheckBox
            label="Customize Hover Background"
            // Because we're toggling 'enable' here, but the prop is a 'disable', we need
            // to invert the values here.
            checked={!value}
            onChange={(val) => onChange(!val)}
            aria-label="toggle custom hover background"
          />
        )}
      />
      <Visible when={showingHoverBackgroundInput}>
        <Controller
          name={`dashboardSections.${sectionId}.items.${id}.hoverBackgroundColor`}
          control={control}
          defaultValue={
            resource.hoverBackgroundColor || DEFAULT_BUTTON_BACKGROUND_COLOR
          }
          render={({value, onChange}) => (
            <BackgroundPicker
              label="Hover Background"
              background={value}
              onChange={onChange}
            />
          )}
        />
      </Visible>
    </CollapsibleSection>
  )
}

const StyledCheckBox = styled(Checkbox)`
  margin-bottom: ${(props) => props.theme.spacing[4]};
`

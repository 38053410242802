import Input from '@material-ui/core/Input'
import FontStyleInput from 'lib/ui/typography/FontStyleInput'
import InputAdornment from '@material-ui/core/InputAdornment'
import {onChangeCheckedHandler} from 'lib/dom'
import Switch, {EnabledSwitch} from 'lib/ui/form/Switch'
import TextField from 'lib/ui/TextField'
import React from 'react'
import {Controller} from 'react-hook-form'
import {useConfig} from 'organization/Event/Configurable'
import {BlogSectionProps} from 'Event/template/Townhall/Dashboard/Main/BlogSection'
import {useTownhallTemplate} from 'Event/template/Townhall'
import InputLabel from 'lib/ui/TextField/InputLabel'

export default function Settings(props: {
  blogSection: BlogSectionProps
  sectionId: string
}) {
  const {blogSection, sectionId} = props
  const {
    form: {control, register},
  } = useConfig()
  const {
    navBar: {menuItems},
  } = useTownhallTemplate()

  return (
    <>
      <Controller
        name={`dashboardSections.${sectionId}.isEnabled`}
        control={control}
        defaultValue={blogSection.isEnabled}
        render={({value, onChange}) => (
          <EnabledSwitch
            checked={value}
            onChange={onChangeCheckedHandler(onChange)}
            arial-label="config enabled switch"
          />
        )}
      />
      <Controller
        name={`navBar.menuItems.${sectionId}.isEnabled`}
        defaultValue={menuItems[sectionId]?.isEnabled ?? true}
        control={control}
        render={({value, onChange}) => (
          <Switch
            checked={value}
            onChange={onChangeCheckedHandler(onChange)}
            labelPlacement="end"
            label={value ? 'Show NavBar Item' : 'Hide NavBar Item'}
            aria-label="toggle hide navbar item"
          />
        )}
      />
      <InputLabel>Menu Title</InputLabel>
      <TextField
        name={`navBar.menuItems.${sectionId}.title`}
        defaultValue={menuItems[sectionId]?.title ?? blogSection.title}
        aria-label="update blogs menu title"
        fullWidth
        inputProps={{
          ref: register,
        }}
      />
      <InputLabel>Title</InputLabel>
      <TextField
        name={`dashboardSections.${sectionId}.title`}
        defaultValue={blogSection.title}
        aria-label="update blogs title"
        fullWidth
        inputProps={{
          ref: register,
        }}
      />
      <InputLabel>Description</InputLabel>
      <Controller
        name={`dashboardSections.${sectionId}.description`}
        control={control}
        defaultValue={blogSection.description || ''}
        render={({value, onChange}) => (
          <Input
            id="agenda-adornment-description"
            type="text"
            value={value}
            onChange={onChange}
            aria-label="update blogs description"
            fullWidth
            endAdornment={
              <InputAdornment position="end">
                <Controller
                  name={`dashboardSections.${sectionId}.descriptionFontStyles`}
                  control={control}
                  defaultValue={blogSection.descriptionFontStyles || ''}
                  render={({value, onChange}) => (
                    <FontStyleInput onChange={onChange} value={value} />
                  )}
                />
              </InputAdornment>
            }
          />
        )}
      />
    </>
  )
}

import React from 'react'
import {Controller, UseFormMethods} from 'react-hook-form'
import {EnabledSwitch} from 'lib/ui/form/Switch'
import TextField from 'lib/ui/TextField'
import {Label} from 'lib/ui/typography'
import TextEditor, {TextEditorContainer} from 'lib/ui/form/TextEditor'
import Slider from 'lib/ui/Slider'
import ActionSelect from 'Event/ActionsProvider/ActionSelect'
import {useNiftyFiftyTemplate} from 'Event/template/NiftyFifty'
import {onChangeCheckedHandler} from 'lib/dom'

export default function Settings(props: {
  control: UseFormMethods['control']
  register: UseFormMethods['register']
}) {
  const {control, register} = props
  const template = useNiftyFiftyTemplate()
  const {imageWaterfall: current} = template

  return (
    <>
      <Controller
        name="isVisible"
        control={control}
        defaultValue={current.isVisible}
        render={({value, onChange}) => (
          <EnabledSwitch
            checked={value}
            onChange={onChangeCheckedHandler(onChange)}
            arial-label="toggle image water fall visiblity"
          />
        )}
      />
      <Label>Title</Label>
      <TextField
        name="title"
        aria-label="title"
        fullWidth
        defaultValue={current.title}
        inputProps={{
          ref: register,
        }}
      />
      <Label>Menu Title</Label>
      <TextField
        name="menuTitle"
        aria-label="edit image water fall page menu title"
        fullWidth
        defaultValue={current.menuTitle}
        inputProps={{
          ref: register,
        }}
      />
      <Label>Description</Label>
      <Controller
        name="description"
        defaultValue={current.description}
        control={control}
        render={({onChange, value}) => (
          <TextEditorContainer>
            <TextEditor data={value} onChange={onChange} />
          </TextEditorContainer>
        )}
      />
      <Label>Upload Form Title</Label>
      <TextField
        name="uploadFormTitle"
        aria-label="uploadFormTitle"
        fullWidth
        defaultValue={current.uploadFormTitle}
        inputProps={{
          ref: register,
        }}
      />
      <Label>Upload Form Description</Label>
      <Controller
        name="uploadFormDescription"
        control={control}
        defaultValue={current.uploadFormDescription}
        render={({onChange, value}) => (
          <TextEditorContainer>
            <TextEditor data={value} onChange={onChange} />
          </TextEditorContainer>
        )}
      />
      <Label>Upload Button Text</Label>
      <TextField
        name="uploadButtonText"
        aria-label="uploadButtonText"
        fullWidth
        defaultValue={current.uploadButtonText}
        inputProps={{
          ref: register,
        }}
      />
      <Controller
        name="uploadButtonFontSize"
        control={control}
        defaultValue={current.uploadButtonFontSize}
        render={({value, onChange}) => (
          <Slider
            valueLabelDisplay="auto"
            aria-label="uploadButtonFontSize"
            value={value ? value : 20}
            onChange={onChange}
            step={1}
            min={5}
            max={50}
            label="Upload Button Font Size"
          />
        )}
      />
      <Controller
        name="actionId"
        defaultValue={current.actionId}
        control={control}
        render={({value, onChange}) => (
          <ActionSelect
            value={value}
            onChange={onChange}
            label="Approved Action"
          />
        )}
      />
    </>
  )
}

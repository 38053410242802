import styled from 'styled-components'
import React, {useEffect} from 'react'
import {rgba} from 'lib/color'
import {useTemplate} from 'Event/TemplateProvider'
import {useLayout} from 'organization/Event/Layout'

export default function Page(props: {
  children: React.ReactElement | React.ReactElement[]
}) {
  const template = useTemplate()
  const {
    imageWaterfall: {page},
  } = template
  const {showFullScreen, setFullScreen} = useLayout()

  const background = page.backgroundImage

  const backgroundRGBColor = rgba(
    page.backgroundColor || '#FFFFFF',
    page.backgroundOpacity / 100 || 0,
  )

  useEffect(() => {
    setFullScreen(true)
    return () => {
      setFullScreen(false)
    }
  }, [showFullScreen, setFullScreen])

  return (
    <Background
      background={background}
      aria-label="image waterfall page background"
      isEnabled={page.backgroundEnabled}
    >
      <Container color={backgroundRGBColor}>{props.children}</Container>
    </Background>
  )
}

const Background = styled.div<{
  background: string | null
  isEnabled?: boolean
}>`
  ${(props) => (props.isEnabled ? `background: url(${props.background});` : '')}
  display: flex;
  background-size: cover;
  background-position: center;
  position: absolute;
  width: 100%;
  min-height: 100vh;
  top: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;
  overflow-y: auto;
`

const Container = styled.div<{
  color: string
}>`
  background-color: ${(props) => props.color};
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  position: absolute;
`

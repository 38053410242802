import React, {useState} from 'react'
import styled from 'styled-components'
import {User} from 'auth/user'
import Page from 'Event/template/NiftyFifty/Page'
import LeftPanel from 'Event/template/NiftyFifty/Dashboard/LeftPanel'
import RightPanel from 'Event/template/NiftyFifty/Dashboard/RightPanel'
import MobilePanel from 'Event/template/NiftyFifty/Dashboard/MobilePanel'
import SpeakerPage from 'Event/template/NiftyFifty/Dashboard/Speakers'
import TabPanel from 'lib/ui/tabs/TabPanel'
import Home from 'Event/template/NiftyFifty/Dashboard/Home'
import Leaderboard from 'Event/template/NiftyFifty/Dashboard/Leaderboard'
import Resources from 'Event/template/NiftyFifty/Dashboard/Resources'
import {useNiftyFiftyTemplate} from 'Event/template/NiftyFifty'
import SponsorPage from 'Event/template/NiftyFifty/Dashboard/Sponsors'
import ImageWaterfall from 'Event/template/NiftyFifty/Dashboard/ImageWaterfall'
import FaqPage from 'Event/template/NiftyFifty/Dashboard/Faqs'

export default function NiftyFiftyDashboard(props: {user: User}) {
  const [tabIndex, setTabIndex] = useState(0)

  return (
    <Page
      Left={<LeftPanel onChangeTab={setTabIndex} user={props.user} />}
      Right={
        <RightPanel currentTab={tabIndex} onChangeTab={setTabIndex}>
          <Content currentTab={tabIndex} />
        </RightPanel>
      }
      Mobile={
        <MobilePanel onChangeTab={setTabIndex} user={props.user}>
          <Content currentTab={tabIndex} />
        </MobilePanel>
      }
    />
  )
}

function Content(props: {currentTab: number}) {
  const {currentTab} = props

  return (
    <>
      <ContentPanel index={0} currentIndex={currentTab} extraPadding>
        <Home />
      </ContentPanel>
      <ContentPanel index={1} currentIndex={currentTab}>
        <SpeakerPage />
      </ContentPanel>
      <ContentPanel index={2} currentIndex={currentTab}>
        <SponsorPage />
      </ContentPanel>
      <ContentPanel index={3} currentIndex={currentTab}>
        <Resources />
      </ContentPanel>
      <ContentPanel index={4} currentIndex={currentTab} extraPadding>
        <Leaderboard />
      </ContentPanel>
      <ContentPanel index={5} currentIndex={currentTab} extraPadding>
        <ImageWaterfall />
      </ContentPanel>
      <ContentPanel index={6} currentIndex={currentTab}>
        <FaqPage />
      </ContentPanel>
    </>
  )
}

function ContentPanel(props: {
  index: number
  currentIndex: number
  children: React.ReactElement
  extraPadding?: boolean
}) {
  const {textColor} = useNiftyFiftyTemplate()

  return (
    <StyledTabPanel
      textColor={textColor}
      index={props.index}
      currentIndex={props.currentIndex}
      extraPadding={props.extraPadding}
    >
      {props.children}
    </StyledTabPanel>
  )
}

const StyledTabPanel = styled(TabPanel)<{
  textColor: string
  extraPadding?: boolean
}>`
  /*
    Explicitly set color to avoid dark mode's white color from
    rendering text invisible on white background.
  */
  color: ${(props) => props.textColor}!important;
  padding: 20px ${(props) => (props.extraPadding ? 35 : 0)}px !important;
`

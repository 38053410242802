import styled from 'styled-components'
import React from 'react'
import AddButton from 'Event/Sponsors/Buttons/AddButton'
import {
  DragDropContext,
  Droppable,
  DroppableProvidedProps,
  DropResult,
} from 'react-beautiful-dnd'
import SponsorButton from 'Event/Sponsors/Buttons/Button'
import {useTemplate} from 'Event/TemplateProvider'
import {orderedIdsByPosition} from 'lib/list'
import {useEditMode} from 'Event/EditModeProvider'
import {useButtons} from 'organization/Event/SponsorsConfig'
import {SponsorButtons} from 'Event/Sponsors'

export default function Buttons(props: {sponsorId: string}) {
  const {
    sponsors: {items},
  } = useTemplate()

  const sponsor = items[props.sponsorId]
  const isEditMode = useEditMode()

  const buttons = sponsor.buttons || {}

  const sortedIds = orderedIdsByPosition(buttons)

  const visibleButtons = sortedIds.filter((id) => buttons[id].isVisible)

  if (!isEditMode) {
    return (
      <>
        {visibleButtons.map((id, index) => (
          <ButtonBox key={id}>
            <SponsorButton
              button={buttons[id]}
              sponsorId={props.sponsorId}
              id={id}
              index={index}
            />
          </ButtonBox>
        ))}
      </>
    )
  }

  return <EditableButtons sponsorId={props.sponsorId} />
}

export function EditableButtons(props: {sponsorId: string}) {
  const {handleDrag, add} = useButtons(props.sponsorId)
  const {
    sponsors: {items},
  } = useTemplate()
  const buttons = items[props.sponsorId].buttons || {}

  const sortedIds = orderedIdsByPosition(buttons)

  return (
    <>
      <DraggableList buttons={buttons} onHandleDrag={handleDrag}>
        <>
          {sortedIds.map((id, index) => (
            <SponsorButton
              button={buttons[id]}
              sponsorId={props.sponsorId}
              id={id}
              index={index}
              key={index}
              isEditMode
            />
          ))}
        </>
      </DraggableList>
      <AddButton onAdd={add} />
    </>
  )
}

function DraggableList(props: {
  buttons: SponsorButtons
  className?: string
  children: JSX.Element
  onHandleDrag: (result: DropResult) => void
}) {
  const droppableId = `sponsor-button`

  return (
    <DragDropContext onDragEnd={props.onHandleDrag}>
      <Droppable droppableId={droppableId} direction="vertical">
        {(provided) => (
          <Container
            className={props.className}
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            <>
              {props.children}
              {provided.placeholder}
            </>
          </Container>
        )}
      </Droppable>
    </DragDropContext>
  )
}

const Container = React.forwardRef<
  HTMLDivElement,
  {
    className?: string
    children: React.ReactElement | React.ReactElement[]
  } & Partial<DroppableProvidedProps>
>((props, ref) => (
  <div className={props.className} ref={ref} {...props}>
    {props.children}
  </div>
))

const ButtonBox = styled.div`
  &:not(:last-child) {
    margin-bottom: ${(props) => props.theme.spacing[1]};
  }
`

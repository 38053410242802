import React, {useState} from 'react'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import TextField from 'lib/ui/TextField'
import InputLabel from 'lib/ui/InputLabel'
import TextEditor, {TextEditorContainer} from 'lib/ui/form/TextEditor'
import {useRoom} from 'organization/Event/Room/RoomProvider'
import {Controller, useForm, UseFormMethods} from 'react-hook-form'
import {Room} from 'Event/room'
import ComponentConfig, {
  CancelButton,
  Footer,
  SaveButton,
} from 'organization/Event/DashboardConfig/ComponentConfigPanel'
import Switch from 'lib/ui/form/Switch'
import {onChangeCheckedHandler} from 'lib/dom'

export interface RegistrationTemplate {
  description?: string
  firstNameLabel?: string
  lastNameLabel?: string
  emailLabel?: string
  joinButtonText?: string
}

export default function RoomRegistrationConfig(props: {
  showing: boolean
  onClose: () => void
}) {
  const {update, room, processing} = useRoom()
  const {showing, onClose} = props

  const registerExistingAttendeesOnly = useRegisterExistingAttendeesOnly()

  const {control, handleSubmit, register, watch, formState} = useForm()

  const save = (data: Room['template']) => {
    if (processing) {
      return
    }

    registerExistingAttendeesOnly.save()
    update({
      ...room,
      template: data,
    }).then(onClose)
  }

  const hasChanges = formState.isDirty

  return (
    <ComponentConfig
      title="Registration"
      onSubmit={handleSubmit(save)}
      hasChanges={hasChanges}
      showing={showing}
      onClose={onClose}
    >
      <SettingsPanel>
        <Switch
          checked={registerExistingAttendeesOnly.value}
          disabled={processing}
          onChange={onChangeCheckedHandler(registerExistingAttendeesOnly.set)}
          aria-label="toggle register existing attendees only"
          labelPlacement="end"
          label={
            registerExistingAttendeesOnly.value
              ? 'Registration is Limited to Existing Attendees'
              : 'Registration is Open to All'
          }
        />
        <InputLabel>Description</InputLabel>
        <TextEditorContainer>
          <Controller
            name="description"
            defaultValue={room.template?.description || ''}
            control={control}
            render={({value, onChange}) => (
              <TextEditor
                data={value}
                onChange={onChange}
                disabled={processing}
              />
            )}
          />
        </TextEditorContainer>
        <NameTextField
          name="firstNameLabel"
          defaultValue={room.template?.firstNameLabel}
          register={register}
          disabled={processing}
          label="First Name Label"
          aria-label="registration first name label"
          hidden={registerExistingAttendeesOnly.value}
        />

        <NameTextField
          name="lastNameLabel"
          defaultValue={room.template?.lastNameLabel}
          register={register}
          disabled={processing}
          label="Last Name Label"
          aria-label="registration last name label"
          hidden={registerExistingAttendeesOnly.value}
        />

        <TextField
          name="emailLabel"
          defaultValue={room.template?.emailLabel || ''}
          label="Email Label"
          aria-label="registration email label"
          inputProps={{
            ref: register,
          }}
          fullWidth
          disabled={processing}
        />
        <AttendeeNotFoundMessageTextField
          register={register}
          disabled={processing}
          hidden={!registerExistingAttendeesOnly.value}
        />
        <TextField
          name="joinButtonText"
          defaultValue={room.template?.joinButtonText || ''}
          label="Join Button Text"
          aria-label="registration join button label"
          inputProps={{
            ref: register,
          }}
          fullWidth
          disabled={processing}
        />
        <CollectPhoneNumber
          register={register}
          control={control}
          watch={watch}
          disabled={processing}
        />
      </SettingsPanel>
      <Footer>
        <SaveButton disabled={processing} />
        <CancelButton onClick={onClose} />
      </Footer>
    </ComponentConfig>
  )
}

function useRegisterExistingAttendeesOnly() {
  const {setRegisterExistingAttendeesOnly: save, room} = useRoom()
  const [value, setValue] = useState(room.register_existing_attendees_only)

  const handleSave = () => {
    if (value === room.register_existing_attendees_only) {
      // No change
      return
    }

    save(value)
  }

  return {value, set: setValue, save: handleSave}
}

function NameTextField(props: {
  register: UseFormMethods['register']
  disabled: boolean
  label: string
  'aria-label': string
  name: string
  defaultValue?: string
  hidden: boolean
}) {
  const {register, disabled, label, name, defaultValue, hidden} = props

  if (hidden) {
    return null
  }

  return (
    <TextField
      name={name}
      defaultValue={defaultValue}
      label={label}
      aria-label={props['aria-label']}
      inputProps={{
        ref: register,
      }}
      fullWidth
      disabled={disabled}
    />
  )
}

function AttendeeNotFoundMessageTextField(props: {
  register: UseFormMethods['register']
  disabled: boolean
  hidden: boolean
}) {
  const {register, disabled, hidden} = props

  const {room} = useRoom()

  if (hidden) {
    return null
  }

  return (
    <TextField
      name="missingRegistrationAttendeeMessage"
      defaultValue={room.template?.missingRegistrationAttendeeMessage}
      label="Attendee Not Found Message"
      aria-label="attendee not found message"
      inputProps={{
        ref: register,
      }}
      fullWidth
      disabled={disabled}
    />
  )
}

function CollectPhoneNumber(props: {
  disabled: boolean
  register: UseFormMethods['register']
  control: UseFormMethods['control']
  watch: UseFormMethods['watch']
}) {
  const {disabled, register, control, watch} = props

  const {room} = useRoom()
  const hasPhoneNumberField = watch(
    'hasPhoneNumberField',
    room.template?.hasPhoneNumberField,
  )

  return (
    <>
      <Controller
        name="hasPhoneNumberField"
        defaultValue={room.template?.hasPhoneNumberField ?? false}
        control={control}
        render={({onChange, value}) => (
          <Switch
            checked={value}
            disabled={disabled}
            onChange={onChangeCheckedHandler(onChange)}
            aria-label="toggle collect phone number"
            labelPlacement="end"
            label="Collect Phone number"
          />
        )}
      />
      <PhoneNumberField
        register={register}
        control={control}
        disabled={disabled}
        hidden={!hasPhoneNumberField}
      />
    </>
  )
}

function PhoneNumberField(props: {
  register: UseFormMethods['register']
  control: UseFormMethods['control']
  disabled: boolean
  hidden: boolean
}) {
  const {register, control, disabled, hidden} = props

  const {room} = useRoom()

  if (hidden) {
    return null
  }

  return (
    <>
      <TextField
        name="phoneNumberLabel"
        defaultValue={room.template?.phoneNumberLabel}
        label="Phone number Label"
        aria-label="phone number label"
        inputProps={{
          ref: register,
        }}
        fullWidth
        disabled={disabled}
      />
      <Controller
        name="phoneNumberRequired"
        control={control}
        defaultValue={room.template?.phoneNumberRequired ?? false}
        render={({value, onChange}) => (
          <Switch
            checked={value}
            disabled={disabled}
            onChange={onChangeCheckedHandler(onChange)}
            aria-label="toggle phone number required"
            labelPlacement="end"
            label="Phone number required"
          />
        )}
      />
    </>
  )
}

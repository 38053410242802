import React, {Dispatch, SetStateAction, useEffect, useState} from 'react'
import styled from 'styled-components'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Slider from '@material-ui/core/Slider'
import {getDiffDatetime} from 'lib/date-time'
import {handleChangeSlider, onUnknownChangeHandler} from 'lib/dom'
import Select from 'lib/ui/Select'
import Option from 'lib/ui/Select/Option'
import {Label, SubHead} from 'lib/ui/typography'
import {
  OPTION_MIN,
  OPTION_MAX,
  OPTION_MINUTES,
  OPTION_PERCENTAGE,
  SLIDER_LABEL_MIN_MINUTES,
  SLIDER_LABEL_MAX_MINUTES,
  SLIDER_LABEL_MIN_PERCENTAGE,
  SLIDER_LABEL_MAX_PERCENTAGE,
  SLIDER_LABEL_DEFAULT,
  useZoomAttendance,
} from 'organization/Event/ZoomAttendance/ZoomAttendanceProvider'

export default function AdvancedAttendeeFilter() {
  const [filterOpen, setFilterOpen] = useState<boolean>(false)

  const handleClick = () => {
    setFilterOpen((current) => !current)
  }

  return (
    <Box>
      <RightGrid item>
        <FilterButton open={filterOpen} onClick={handleClick} />
      </RightGrid>

      <Content open={filterOpen} setFilterOpen={setFilterOpen} />
    </Box>
  )
}

const Content = (props: {
  open: boolean
  setFilterOpen: Dispatch<SetStateAction<boolean>>
}) => {
  const [minMax, setMinMax] = useState<string>(OPTION_MIN)
  const [minutesPercentage, setMinutesPercentage] = useState<string>(
    OPTION_PERCENTAGE,
  )
  const [filterValue, setFilterValue] = useState<number>(100)
  const [sliderMax, setSliderMax] = useState<number>(100)
  const [sliderLabel, setSliderLabel] = useState<string>(SLIDER_LABEL_DEFAULT)
  const [sliderLabelValue, setSliderLabelValue] = useState<string>('100%')
  const {
    convertMinToDurationString,
    endDateTime,
    startDateTime,
    setAttendeeFilterData,
  } = useZoomAttendance()

  const adjustLabels = (
    incomingMinMax: string,
    incomingMinutesPercentage: string,
    incomingFilterValue: number,
  ) => {
    switch (`${incomingMinMax}.${incomingMinutesPercentage}`) {
      case `${OPTION_MIN}.${OPTION_MINUTES}`:
        setSliderLabel(SLIDER_LABEL_MIN_MINUTES)
        break
      case `${OPTION_MAX}.${OPTION_MINUTES}`:
        setSliderLabel(SLIDER_LABEL_MAX_MINUTES)
        break
      case `${OPTION_MIN}.${OPTION_PERCENTAGE}`:
        setSliderLabel(SLIDER_LABEL_MIN_PERCENTAGE)
        break
      case `${OPTION_MAX}.${OPTION_PERCENTAGE}`:
        setSliderLabel(SLIDER_LABEL_MAX_PERCENTAGE)
        break
    }

    if (incomingMinutesPercentage === OPTION_MINUTES) {
      setSliderLabelValue(convertMinToDurationString(incomingFilterValue))

      return
    }

    setSliderLabelValue(`${incomingFilterValue}%`)
  }

  // When the "metric" is changed from percentage to minutes (or verse vice-a),
  // we want to adjust the current filter value to reflect the same position, but
  // in the other metric. For example, if user slides to 50%, then changes to
  // "minutes", we'd like the slider to remain at the "50%" mark in minutes.
  const convertFilterValue = (newMinutesPercentage: string) => {
    const maxMinutes = getDiffDatetime(
      String(endDateTime),
      String(startDateTime),
      'm',
    )

    if (newMinutesPercentage === OPTION_MINUTES) {
      const newFilterValue = Math.floor((maxMinutes * filterValue) / 100)

      setSliderMax(maxMinutes)
      setFilterValue(newFilterValue)
      adjustLabels(minMax, newMinutesPercentage, newFilterValue)

      return
    }

    const newFilterValue = Math.floor((filterValue / maxMinutes) * 100)

    setSliderMax(100)
    setFilterValue(newFilterValue)
    adjustLabels(minMax, newMinutesPercentage, newFilterValue)
  }

  // Whenever the Condition (minMax), Metric (minutesPercentage) or the slider
  // value change, we want to send the data back to the provider so it's available
  // at "action submit" time.
  useEffect(() => {
    setAttendeeFilterData(props.open, minMax, minutesPercentage, filterValue)
  }, [
    props.open,
    minMax,
    minutesPercentage,
    filterValue,
    setAttendeeFilterData,
  ])

  const handleMinMaxChange = (newMinMax: string) => {
    adjustLabels(newMinMax, minutesPercentage, filterValue)
    setMinMax(newMinMax)
  }

  const handleMinutePercentageChange = (newMinutesPercentage: string) => {
    // convertFilterValue() in turn calls adjustLabels(), due to max value changing
    // based on minutes vs. percentage. As well as the value, to keep it in the
    // same spot before changing the metric.
    convertFilterValue(newMinutesPercentage)
    setMinutesPercentage(newMinutesPercentage)
  }

  const handleFilterValueChange = (newFilterValue: number) => {
    adjustLabels(minMax, minutesPercentage, newFilterValue)
    setFilterValue(newFilterValue)
  }

  if (!props.open) {
    return null
  }

  return (
    <FilterContainer>
      <StyledGrid container spacing={3}>
        <Grid item xs={12}>
          <SubHead>Advanced Attendee Filter</SubHead>
        </Grid>
        <Grid item xs={12} sm={6}>
          <StyledSelect
            value={minMax}
            label="Condition"
            fullWidth
            onChange={onUnknownChangeHandler(handleMinMaxChange)}
            aria-label="pick condition type"
          >
            <Option value={OPTION_MIN}>Minimum</Option>
            <Option value={OPTION_MAX}>Maximum</Option>
          </StyledSelect>
        </Grid>
        <Grid item xs={12} sm={6}>
          <StyledSelect
            value={minutesPercentage}
            fullWidth
            label="Metric"
            onChange={onUnknownChangeHandler(handleMinutePercentageChange)}
            aria-label="pick metric type"
          >
            <Option value={OPTION_MINUTES}>Minutes</Option>
            <Option value={OPTION_PERCENTAGE}>Percent</Option>
          </StyledSelect>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={0}>
            <Grid item xs={8}>
              <Label>{sliderLabel}</Label>
            </Grid>
            <Grid item xs={4}>
              <RightLabel aria-label="slider value">
                {sliderLabelValue}
              </RightLabel>
            </Grid>
          </Grid>
          <Slider
            valueLabelDisplay="off"
            aria-label="slider filter value"
            value={filterValue}
            onChange={handleChangeSlider(handleFilterValueChange)}
            step={1}
            min={1}
            max={sliderMax}
          />
        </Grid>
      </StyledGrid>
    </FilterContainer>
  )
}

const FilterButton = (props: {open: boolean; onClick: () => void}) => {
  if (props.open) {
    return null
  }

  return (
    <Button color="primary" onClick={props.onClick} variant="outlined">
      Advanced Filter
    </Button>
  )
}

const Box = styled.div`
  margin-bottom: ${(props) => props.theme.spacing[6]};
`

const FilterContainer = styled.div`
  margin-bottom: ${(props) => props.theme.spacing[6]};
  padding: ${(props) => `${props.theme.spacing[4]} ${props.theme.spacing[3]}`};
`

const StyledGrid = styled(Grid)`
  border: 1px solid ${(props) => props.theme.colors.gray700};
  border-radius: 4px;
  margin-bottom: ${(props) => props.theme.spacing[4]};
`

const StyledSelect = styled(Select)`
  margin-bottom: 0;
`

const RightLabel = styled(Label)`
  text-align: right;
`

const RightGrid = styled(Grid)`
  text-align: right;
`

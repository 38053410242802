import {useEvent} from 'Event/EventProvider'
import {api} from 'lib/url'
import {useOrganization} from 'organization/OrganizationProvider'
import React from 'react'
import Button from '@material-ui/core/Button'
import {
  useConvertKit,
  useServices,
} from 'organization/Event/Services/ServicesProvider'
import {useToggle} from 'lib/toggle'
import {getDiffDatetime, useCurrentTime} from 'lib/date-time'
import {ConvertKitIntegration} from 'organization/Event/Services/Apps/ConvertKit'

/**
 * How long to wait between each import. This should be the same value
 * as the API.
 */
const IMPORT_ATTENDEES_MIN_INTERVAL_MINS = 15

export default function ImportAttendeesButton(props: {
  onSuccess: () => void
  onError: (error: string) => void
}) {
  const {update: updateIntegration} = useServices()
  const importAttendees = useImportUsers()
  const convertKit = useConvertKit()
  const {flag: processing, toggle: toggleProcessing} = useToggle()

  const handleImport = () => {
    if (processing) {
      return
    }

    toggleProcessing()

    importAttendees()
      .then((integration) => {
        updateIntegration(integration)
        props.onSuccess()
      })
      .catch((e) => {
        props.onError(e.message)
      })
      .finally(() => {
        toggleProcessing()
      })
  }

  const withinInterval = useWithinInterval()

  const canImport =
    convertKit.has_set_import_tag && !processing && !withinInterval

  return (
    <Button
      variant="outlined"
      color="primary"
      aria-label="import attendees"
      onClick={handleImport}
      disabled={!canImport}
    >
      Import Attendees
    </Button>
  )
}

function useWithinInterval() {
  const {imported_at} = useConvertKit()
  const time = useCurrentTime()

  if (!imported_at) {
    return false
  }

  const elapsed = getDiffDatetime(time, imported_at)
  return elapsed < IMPORT_ATTENDEES_MIN_INTERVAL_MINS * 60
}

function useImportUsers() {
  const {client} = useOrganization()
  const {event} = useEvent()
  const url = api(
    `/events/${event.id}/integrations/convert_kit/import_attendees`,
  )

  return () => client.post<ConvertKitIntegration>(url)
}

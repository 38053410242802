import React from 'react'
import Page from 'organization/Event/TemplateEditor/Page'
import {DesignBreadcrumbs} from 'organization/Event/Page/PageBreadcrumbs'
import {useObvioUser} from 'obvio/auth'
import {TemplateChangePassword} from 'Event/auth/ChangePassword/'

export default function ChangePasswordConfig() {
  const user = useObvioUser()

  return (
    <DesignBreadcrumbs page="Change Password">
      <Page>
        <TemplateChangePassword user={user} />
      </Page>
    </DesignBreadcrumbs>
  )
}

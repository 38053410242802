import React from 'react'
import Grid from '@material-ui/core/Grid'
import styled from 'styled-components'
import {Draggable, DraggableProvidedDraggableProps} from 'react-beautiful-dnd'
import BaseCountDownTimer, {
  CountDownTimerSettings as CountDownTimerConfig,
} from 'Event/Dashboard/components/CountDownTimer'
import {useEditMode} from 'Event/EditModeProvider'
import {DraggableOverlay, DragHandle} from 'lib/ui/drag-and-drop'
import TimerConfig from 'Event/template/Townhall/Dashboard/Main/CountdownTimerSection/CountdownTimers/CountdownTimer/TimerConfig'
import Configurable from 'organization/Event/Configurable'

type CountDownTimerProps = {
  id: string
  countDownTimer: CountDownTimerConfig
  index: number
}

export default React.memo(
  (props: CountDownTimerProps & {sectionId: string}) => {
    const isEditMode = useEditMode()

    if (!isEditMode) {
      return (
        <CountDownTimerContainer>
          <CountDownTimer {...props} />
        </CountDownTimerContainer>
      )
    }

    return (
      <Draggable draggableId={props.id} index={props.index}>
        {(provided) => (
          <CountDownTimerContainer
            ref={provided.innerRef}
            draggableProps={provided.draggableProps}
          >
            <DraggableOverlay>
              <>
                <DragHandle handleProps={provided.dragHandleProps} />
                <CountDownTimer {...props} />
              </>
            </DraggableOverlay>
          </CountDownTimerContainer>
        )}
      </Draggable>
    )
  },
)

function CountDownTimer(props: CountDownTimerProps & {sectionId: string}) {
  return (
    <Configurable aria-label="countdown timer config">
      <TimerConfig
        id={props.id}
        countDownTimer={props.countDownTimer}
        sectionId={props.sectionId}
      />
      <BaseCountDownTimer {...props.countDownTimer} id={props.id} />
    </Configurable>
  )
}

const CountDownTimerContainer = React.forwardRef<
  HTMLDivElement,
  {
    children: React.ReactElement
    draggableProps?: DraggableProvidedDraggableProps
  }
>((props, ref) => {
  return (
    <>
      <StyledGrid item xs={12} ref={ref} {...props.draggableProps}>
        {props.children}
      </StyledGrid>
    </>
  )
})

const StyledGrid = styled(Grid)`
  display: flex;
  align-items: center;
  justify-content: center;
`

import {useStates} from 'lib/event-api/countries/use-states'

export default function useStateName(
  countryId: number | undefined,
  stateId: number | undefined,
) {
  const {data: states = []} = useStates(countryId ?? null)

  return states.find((s) => s.id === stateId)?.name
}

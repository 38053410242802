import React, {useState} from 'react'
import Page from 'organization/Event/Page'
import Box from '@material-ui/core/Box'
import {
  MailchimpIntegration,
  useMailchimp,
} from 'organization/Event/Services/Apps/Mailchimp'
import LoginUrlFieldSelect from 'organization/Event/Services/Apps/Mailchimp/Config/LoginUrlFieldSelect/LoginUrlFieldSelect'
import Typography from '@material-ui/core/Typography'
import AccessTokenAutoSelect from 'organization/Event/Services/Apps/Mailchimp/Config/AccessTokenAutoSelect'
import {ServicesBreadcrumbs} from 'organization/Event/Page/PageBreadcrumbs'
import FullConfig from 'organization/Event/Services/Apps/Mailchimp/Config/FullConfig'
import {useToggleArray} from 'lib/toggle'
import {useOrganization} from 'organization/OrganizationProvider'
import {useEvent} from 'Event/EventProvider'
import {useServices} from 'organization/Event/Services/ServicesProvider'
import {api} from 'lib/url'
import ErrorAlert from 'lib/ui/alerts/ErrorAlert'
import AudienceSelect from 'organization/Event/Services/Apps/Mailchimp/Config/AudienceSelect'

export default function Config() {
  return (
    <ServicesBreadcrumbs page="Mailchimp">
      <Page>
        <Box mb={3}>
          <Typography variant="h4">Mailchimp</Typography>
        </Box>
        <Box mb={2}>
          <Content />
        </Box>
      </Page>
    </ServicesBreadcrumbs>
  )
}

/**
 * Config should 'guide' user through each step required to
 * complete config. On completion it should show all the
 * individual options.
 *
 * @returns
 */
function Content() {
  const mailchimp = useMailchimp()
  const setLoginField = useSetLoginField()
  const [processing, toggleProcessing] = useToggleArray()
  const [error, setError] = useState('')

  const handleSelectLoginField = (fieldId: string) => {
    if (processing) {
      return
    }

    setError('')
    toggleProcessing()
    setLoginField(fieldId).catch((error) => {
      setError(error.message)
      toggleProcessing()
    })
  }

  if (!mailchimp.audience_id) {
    return <AudienceSelect />
  }

  if (!mailchimp.access_token_id) {
    return <AccessTokenAutoSelect />
  }

  if (!mailchimp.login_url_field_id) {
    return (
      <>
        <ErrorAlert>{error}</ErrorAlert>
        <LoginUrlFieldSelect onChange={handleSelectLoginField} />
      </>
    )
  }

  return <FullConfig />
}

export function useSetLoginField() {
  const {client} = useOrganization()
  const {event} = useEvent()
  const {update} = useServices()

  const url = api(`/events/${event.id}/integrations/mailchimp/login_field`)
  return (id: string) =>
    client
      .put<MailchimpIntegration>(url, {id})
      .then(update)
}

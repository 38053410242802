import styled from 'styled-components'
import React, {useEffect, useState} from 'react'
import TextField from '@material-ui/core/TextField'
import {handleAutocomplete, onChangeStringHandler} from 'lib/dom'
import {Autocomplete} from '@material-ui/lab'
import {useEvent} from 'Event/EventProvider'
import {api} from 'lib/url'
import {ajax, useObserve} from 'lib/rx'
import {debounceTime, filter, map, switchMap, tap} from 'rxjs/operators'
import {useAuthToken} from 'obvio/auth'
import Button from '@material-ui/core/Button'
import {useToggle} from 'lib/toggle'
import Dialog from 'lib/ui/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import {OntraportField} from 'organization/Event/Services/Apps/Ontraport/Config/Fields'
import CreateFieldForm from 'organization/Event/Services/Apps/Ontraport/Config/Fields/CreateFieldForm'

/**
 * Minimum amount of chars required to perform a search.
 */
const MIN_SEARCH_CHARS = 3

export default function AutocompleteFields(props: {
  value?: OntraportField | null
  onChange: (ontraportField: OntraportField | null) => void
  inputLabel?: string
  inputVariant?: 'filled' | 'standard' | 'outlined' | undefined
  saveButtonText?: string
  disabled?: boolean
  errorText?: string
}) {
  const {value, onChange} = props
  const [name, setName] = useState('')
  const {value$, onReady} = useObserve(name)
  const [options, setOptions] = React.useState<OntraportField[]>([])
  const [loading, setLoading] = React.useState(false)
  const {flag: showCreateForm, toggle: toggleShowCreateForm} = useToggle()

  const {
    event: {slug},
  } = useEvent()
  const token = useAuthToken()

  const handleOnCreate = (newField: OntraportField) => {
    toggleShowCreateForm()
    onChange(newField)
  }

  useEffect(() => {
    if (!onReady) {
      return
    }

    value$
      .pipe(
        filter((value) => {
          return value.length >= MIN_SEARCH_CHARS
        }),
        tap(() => {
          setLoading(true)
        }),
        debounceTime(500),
        switchMap((name) => {
          const url = api(
            `/events/${slug}/integrations/ontraport/fields?search=${name}`,
          )

          return ajax.get(url, {
            accessToken: token,
          })
        }),
        map((res) => res.response),
        tap(() => {
          setLoading(false)
        }),
      )
      .subscribe({
        next: (options) => {
          setOptions(options)
        },
      })

    onReady()
  }, [value$, token, slug, onReady])

  return (
    <Box>
      <Autocomplete
        disabled={props.disabled}
        disablePortal
        options={options}
        value={value || null}
        onChange={handleAutocomplete(onChange)}
        getOptionLabel={(option) => (option ? option.field_name : '')}
        loading={loading}
        closeIcon=""
        aria-label={'field id holder'}
        noOptionsText={'Type to search. Minimum length: 3 characters.'}
        getOptionSelected={(option, value) =>
          option.field_id === value.field_id
        }
        renderInput={(params) => {
          return (
            <>
              <TextField
                {...params}
                onChange={onChangeStringHandler(setName)}
                variant={props.inputVariant ? props.inputVariant : 'standard'}
                fullWidth
                helperText={props.errorText ? props.errorText : ''}
                error={Boolean(props.errorText)}
                label={props.inputLabel ? props.inputLabel : 'Ontraport Field'}
                inputProps={{
                  ...params.inputProps,
                  'aria-label': 'field id',
                }}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <Button
                      disabled={props.disabled}
                      type="submit"
                      color="primary"
                      size="large"
                      aria-label="create field"
                      onClick={toggleShowCreateForm}
                    >
                      Create new field
                    </Button>
                  ),
                }}
              />
            </>
          )
        }}
      />
      <CreateNewFieldDialog
        visible={showCreateForm}
        onClose={toggleShowCreateForm}
        onCreate={handleOnCreate}
      />
    </Box>
  )
}

const CreateNewFieldDialog = (props: {
  visible: boolean
  onClose: () => void
  onCreate: (newTag: OntraportField) => void
}) => {
  const {visible, onCreate, onClose} = props

  return (
    <Dialog open={visible} onClose={onClose}>
      <DialogTitle>Create new field</DialogTitle>
      <DialogContent>
        <CreateFieldForm onCreate={onCreate} />
      </DialogContent>
    </Dialog>
  )
}

const Box = styled.div`
  position: relative;
  .MuiAutocomplete-popper {
    margin-top: -20px !important;
  }
`

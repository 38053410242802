import Card from 'Event/template/NiftyFifty/Dashboard/Speakers/SpeakerList/Card'
import React from 'react'
import Typography from '@material-ui/core/Typography'
import {DragDropContext, Droppable} from 'react-beautiful-dnd'
import {useNiftyFiftyTemplate} from 'Event/template/NiftyFifty'
import {orderedIdsByPosition} from 'lib/list'
import {useEditMode} from 'Event/EditModeProvider'
import {useHandleDragSpeakers} from 'organization/Event/SpeakersConfig'
import {VisibleSpeaker} from 'Event/Speakers'

export default function SpeakerList() {
  const {
    speakers: {items},
  } = useNiftyFiftyTemplate()
  const ids = orderedIdsByPosition(items)
  const isEditMode = useEditMode()

  const isEmpty = ids.length === 0
  if (isEmpty) {
    return <Typography align="center">No speakers have been added</Typography>
  }

  const cards = ids.map((id: string, index: number) => (
    <VisibleSpeaker key={id} speaker={items[id]}>
      <Card speaker={items[id]} index={index} key={id} id={id} />
    </VisibleSpeaker>
  ))

  if (!isEditMode) {
    return <div>{cards}</div>
  }

  return <DraggableList>{cards}</DraggableList>
}

function DraggableList(props: {children: React.ReactElement[]}) {
  const handleDrag = useHandleDragSpeakers()

  return (
    <DragDropContext onDragEnd={handleDrag}>
      <Droppable droppableId="drag-and-drop-speaker">
        {(provided) => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            <>
              {props.children}
              {provided.placeholder}
            </>
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )
}

import {MarketplaceAccount} from 'lib/marketplace-api/accounts'
import {marketplaceApi} from 'lib/marketplace-api/url'
import {useOrgMarketplaceClient} from 'lib/marketplace-api/use-org-marketplace-client'
import {useMutation, useQueryClient} from 'react-query'

export interface setMarketplaceAccountConnectedData {
  stripeAccountId: string
  account: MarketplaceAccount
}

export function useSetMarketplaceAccountConnected() {
  const {client} = useOrgMarketplaceClient()
  const qc = useQueryClient()

  return useMutation({
    mutationFn: (data: setMarketplaceAccountConnectedData) => {
      const url = marketplaceApi(
        `/accounts/${data.account.id}/stripe/${data.stripeAccountId}/connected`,
      )
      return client!.post<MarketplaceAccount>(url)
    },
    onSuccess: (account) => {
      qc.setQueryData(['marketplace', 'account'], () => account)
    },
  })
}

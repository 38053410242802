import React from 'react'
import styled from 'styled-components'
import Typography from '@material-ui/core/Typography'
import TextContent from 'lib/ui/form/TextEditor/Content'
import {useAttendeeVariables} from 'Event'
import QuestionIcon from 'Event/Sponsors/QuestionIcon'
import {Sponsor} from 'Event/Sponsors'

type BodyProps = {
  sponsor: Sponsor
  toggleForm: () => void
  textColor?: string
  id: string
}

export default function Content(props: BodyProps) {
  const {sponsor, id} = props
  const v = useAttendeeVariables()

  return (
    <Box color={props.textColor}>
      <SponsorHeader>
        <Typography variant="h5">{v(sponsor.name)}</Typography>
        <StyledQuestionIcon
          sponsor={sponsor}
          onClick={props.toggleForm}
          id={id}
        />
      </SponsorHeader>
      <StyledTextContent>{v(sponsor.description)}</StyledTextContent>
    </Box>
  )
}

const Box = styled.div`
  color: ${(props) => props.color};
  width: 100%;
`

const StyledQuestionIcon = styled(QuestionIcon)`
  margin-right: 10px;
`

const SponsorHeader = styled.div`
  display: flex;
  justify-content: space-between;
`

const StyledTextContent = styled(TextContent)`
  display: none;

  @media (min-width: ${(props) => props.theme.breakpoints.sm}) {
    display: initial;
  }
`

import styled from 'styled-components'
import {onChangeCheckedHandler} from 'lib/dom'
import {EnabledSwitch} from 'lib/ui/form/Switch'
import React from 'react'
import {useEvent, useUpdate} from 'Event/EventProvider'

export default function SignUpEnabledSwitch() {
  const {event} = useEvent()
  const update = useUpdate()

  const toggleSignUpEnabled = (signUpEnabled: boolean) => {
    update({
      sign_up_enabled: signUpEnabled,
    })
  }

  return (
    <StyledEnabledSwitch
      checked={event.sign_up_enabled}
      onChange={onChangeCheckedHandler(toggleSignUpEnabled)}
      aria-label="toggle has public sign up"
    />
  )
}

const StyledEnabledSwitch = styled(EnabledSwitch)`
  margin-bottom: 0;
`

import React, {useState} from 'react'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import LockOpenIcon from '@material-ui/icons/LockOpen'
import {Attendee} from 'Event/attendee'
import ResetPasswordDialog from 'organization/Event/AttendeeManagement/ResetPasswordButton/ResetPasswordDialog'
import {api} from 'lib/url'
import {useToggle} from 'lib/toggle'
import {eventRoutes} from 'Event/Routes'
import {client, ValidationError} from 'lib/ui/api-client'
import {useEvent} from 'Event/EventProvider'

export default function ResetPasswordButton(props: {attendee: Attendee}) {
  const [responseError, setResponseError] = useState<
    ValidationError<{email: string}>
  >(null)
  const [success, setSuccess] = useState(false)

  const {flag: openDialog, toggle: toggleOpenDialog} = useToggle()
  const {flag: loading, toggle: toggleLoading} = useToggle()
  const {url: eventUrl, event} = useEvent()

  const onClick = () => {
    const url = api(`/events/${event.id}/forgot_password`)
    const data = {
      email: props.attendee.email,
      form_url: `${eventUrl}${eventRoutes.resetPassword}`,
    }

    setSuccess(false)
    setResponseError(null)
    toggleOpenDialog()
    toggleLoading()
    client
      .post(url, data)
      .then(() => setSuccess(true))
      .catch(setResponseError)
      .finally(toggleLoading)
  }

  return (
    <>
      <ResetPasswordDialog
        open={openDialog}
        loading={loading}
        success={success}
        error={responseError?.message}
        onClose={toggleOpenDialog}
      />
      <Tooltip title="Send Password Reset">
        <IconButton
          color="primary"
          aria-label="send password reset"
          onClick={onClick}
        >
          <LockOpenIcon />
        </IconButton>
      </Tooltip>
    </>
  )
}

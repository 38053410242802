import {ObvioEvent} from 'Event'
import {marketplaceApi} from 'lib/marketplace-api/url'
import {client} from 'lib/ui/api-client'
import {useQuery} from 'react-query'

interface StripeAccountIdResponse {
  stripe_account_id: string | null
}

export function useStripeAccountId(params: {event: ObvioEvent}) {
  const {event} = params

  return useQuery({
    queryKey: ['events', event.id, 'stripe_account_id'],
    queryFn: () =>
      client
        .get<StripeAccountIdResponse>(
          marketplaceApi(`/events/${event.id}/stripe_account_id`),
        )
        .then(({stripe_account_id}) => stripe_account_id),
  })
}

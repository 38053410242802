import InputAdornment from '@material-ui/core/InputAdornment'
import {FieldProps, useSavedValue, FormTextField} from 'Event/Question'
import React from 'react'
import {useAttendeeVariables} from 'Event'
import {Question, QuestionBase} from 'organization/Event/QuestionsProvider'
import styled from 'styled-components'

export const CURRENCY = 'currency'

export type CurrencyQuestion = QuestionBase & {
  type: typeof CURRENCY
  start_adornment: string | null
}

type CurrencyProps = FieldProps & {
  question: CurrencyQuestion
}

export default function Currency(props: CurrencyProps) {
  useSavedValue(props)

  const v = useAttendeeVariables()
  const helperText = v(props.error || props.question.helper_text || '')
  const label = v(props.question.label)
  const defaultValue = v(props.answer || '')

  return (
    <FormTextField
      label={label}
      inputProps={{
        'aria-label': props.question.label,
        ref: props.register,
      }}
      defaultValue={defaultValue}
      name={props.name}
      fullWidth
      helperText={helperText}
      required={props.question.is_required}
      error={Boolean(props.error)}
      disabled={props.disabled}
      styles={props.inputStyles}
      startAdornment={
        <StyledInputAdornment position="start">
          {props.question.start_adornment || ''}
        </StyledInputAdornment>
      }
    />
  )
}

export function isCurrencyQuestion(
  question?: Question,
): question is CurrencyQuestion {
  if (!question) {
    return false
  }

  return question.type === CURRENCY
}

const StyledInputAdornment = styled(InputAdornment)`
  & > * {
    font-family: inherit;
  }
`

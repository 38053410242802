import Signature from 'lib/ui/form/Signature'
import {useTemplate} from 'Event/TemplateProvider'
import {useWaiver} from 'Event/Step2/Waivers/Waiver'
import React from 'react'
import Configurable from 'organization/Event/Configurable'
import SignatureConfig from 'Event/Step2/Waivers/Waiver/SignatureBox/SignatureConfig'
import {User} from 'auth/user'

export default function SignatureBox(props: {user: User; className?: string}) {
  const {className, user} = props
  const {
    waiver: {
      signature: {typeButtonColor, typeButtonText, typeInputLabel},
    },
  } = useTemplate()
  const {signature, setSignature} = useWaiver()

  const fullName = `${user.first_name} ${user.last_name}`

  return (
    <Configurable>
      <SignatureConfig />
      <Signature
        className={className}
        value={signature}
        onUpdate={setSignature}
        typeButtonColor={typeButtonColor}
        typeButtonText={typeButtonText}
        typeInputLabel={typeInputLabel}
        name={fullName}
      />
    </Configurable>
  )
}

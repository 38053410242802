import Typography from '@material-ui/core/Typography'
import styled from 'styled-components'
import React from 'react'
import {DragDropContext, Droppable} from 'react-beautiful-dnd'
import {useSimpleBlogTemplate} from 'Event/template/SimpleBlog'
import {orderedIdsByPosition} from 'lib/list'
import {useEditMode} from 'Event/EditModeProvider'
import {useHandleDragSponsors} from 'organization/Event/SponsorsConfig'
import Card from 'Event/template/SimpleBlog/Sponsors/SponsorList/Card'
import {VisibleSponsor} from 'Event/Sponsors'

export default function SponsorList() {
  const template = useSimpleBlogTemplate()
  const {sponsors: settings} = template

  const isEditMode = useEditMode()
  const ids = orderedIdsByPosition(settings.items)

  const isEmpty = ids.length === 0
  if (isEmpty) {
    return <Typography align="center">No sponsor have been added</Typography>
  }

  const sponsors = ids.map((id, index) => (
    <VisibleSponsor key={id} sponsor={settings.items[id]}>
      <Card
        key={id}
        index={index}
        id={id}
        sponsor={settings.items[id]}
        isLast={index === ids.length - 1}
      />
    </VisibleSponsor>
  ))

  if (!isEditMode) {
    return <Box>{sponsors}</Box>
  }

  return <DraggableList>{sponsors}</DraggableList>
}

function DraggableList(props: {children: React.ReactElement[]}) {
  const handleDrag = useHandleDragSponsors()

  return (
    <DragDropContext onDragEnd={handleDrag}>
      <Droppable droppableId="drag-and-drop-sponsors">
        {(provided) => (
          <Box ref={provided.innerRef} {...provided.droppableProps}>
            <>
              {props.children}
              {provided.placeholder}
            </>
          </Box>
        )}
      </Droppable>
    </DragDropContext>
  )
}

const Box = styled.div`
  width: 100%;
`

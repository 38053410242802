import {
  EMAIL,
  FIRST_NAME,
  LAST_NAME,
  LOGIN_URL,
  RESET_PASSWORD_URL,
} from 'lib/ui/form/TextEditor/variables'
import EmailConfig, {
  FORGOT_PASSWORD_EMAIL,
} from 'organization/Event/EmailConfig'
import React from 'react'

export default function ForgotPasswordEmailConfig() {
  return (
    <EmailConfig
      type={FORGOT_PASSWORD_EMAIL}
      title="Forgot Password Email"
      label="forgot password email"
      bodyVariables={[
        LOGIN_URL,
        FIRST_NAME,
        LAST_NAME,
        EMAIL,
        RESET_PASSWORD_URL,
      ]}
    />
  )
}

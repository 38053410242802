import React, {useEffect, useRef, useState} from 'react'
import {useForm, UseFormMethods} from 'react-hook-form'
import {useEventAuth} from 'Event/auth'
import {useQueryParams} from 'lib/url'
import {useTemplate} from 'Event/TemplateProvider'
import FullPageLoader from 'lib/ui/layout/FullPageLoader'
import {components} from 'Event/template'
import {useDeepLink} from 'DeepLinkProvider'
import Scripts, {LOGIN} from 'Event/Scripts'

export interface LoginProps {
  onSubmit: () => void
  register: UseFormMethods['register']
  errors: UseFormMethods['errors']
  error: string
  submitting: boolean
  isPreview?: boolean
}

export default function Login(props: {isPreview?: boolean}) {
  const {token} = useQueryParams()
  const {register, handleSubmit, errors} = useForm()
  const [error, setError] = useState('')
  const [submitting, setSubmitting] = useState(false)
  const {login} = useEventAuth()
  const hasAttemptedTokenLogin = useRef(false)
  const {isPreview} = props
  const {clearDeepLink, deepLinkLocation} = useDeepLink()

  const submit = (data: {email: string; password: string}) => {
    if (isPreview) {
      return
    }

    setSubmitting(true)
    login({
      email: data.email,
      password: data.password,
    })
      .catch((e) => {
        setError(e.message)
        setSubmitting(false)
      })
      .then((res) => {
        if (!res) {
          return
        }
        // Only checking for a Deep Link location when the user is actually
        // submitting the login form. The auth-tokens (below) don't qualify for a
        // deep link redirect.
        const redirectLocation = deepLinkLocation()

        if (redirectLocation) {
          clearDeepLink()
          window.location.href = redirectLocation
        }
      })
  }

  const onSubmit = handleSubmit(submit)

  useEffect(() => {
    if (!token || hasAttemptedTokenLogin.current || isPreview) {
      return
    }

    hasAttemptedTokenLogin.current = true
    setSubmitting(true)

    login({
      login_token: token,
    }).catch((e) => {
      setError(e.message)
      setSubmitting(false)
    })
  }, [token, login, isPreview])

  const isProcessingToken =
    (token && !hasAttemptedTokenLogin.current) || submitting
  if (isProcessingToken) {
    return <FullPageLoader />
  }

  return (
    <>
      <Scripts page={LOGIN} />
      <TemplateLogin
        onSubmit={onSubmit}
        register={register}
        errors={errors}
        error={error}
        submitting={submitting}
        isPreview={props.isPreview}
      />
    </>
  )
}

function TemplateLogin(props: LoginProps) {
  const template = useTemplate()
  const Component = components[template.name].Login.index

  return <Component {...props} />
}

import {isStaging, isTestEnv} from 'env'
import {getSubdomain} from 'lib/url'
import {useParams} from 'react-router-dom'

export function domainEventSlug() {
  const fullDomain = window.location.host
  const subdomain = getSubdomain(window.location.host)

  // If we're developing locally, the domain will always be localhost, so
  // just send over the slug (subdomain) only.
  if (fullDomain.includes('localhost')) {
    return subdomain
  }

  if (isStaging) {
    return fullDomain.replace('.staging', '')
  }

  if (isTestEnv) {
    return fullDomain.replace('.test', '')
  }

  // If we're on a live domain, the slug should include the
  // domain name since a slug could be re-used per domain:
  // myevent.obv.io
  // myevent.zoomiverse.com
  // myevent.bootcamp.live
  return fullDomain
}

export function useParamEventId() {
  const {event} = useParams<{event: string}>()
  return event
}

import Box from '@material-ui/core/Box'
import {Cards, useCardsTemplate, useCardsUpdate} from 'Event/template/Cards'
import ColorPicker from 'lib/ui/ColorPicker'
import Switch from 'lib/ui/form/Switch'
import {FontFamilySelect} from 'lib/FontSelect'
import {SectionTitle} from 'organization/Event/Page'
import React from 'react'
import Grid from '@material-ui/core/Grid'
import InputLabel from 'lib/ui/TextField/InputLabel'
import Slider from 'lib/ui/Slider'
import {onChangeCheckedHandler} from 'lib/dom'
import BackgroundImageUploader from 'Event/template/Cards/GlobalStylesConfig/BackgroundImageUploader'
import Page from 'organization/Event/Page'
import {SaveButton} from 'organization/Event/DashboardConfig/ConfigDialog'
import {Controller, useForm} from 'react-hook-form'
import FormControl from 'lib/ui/FormControl'
import {DesignBreadcrumbs} from 'organization/Event/Page/PageBreadcrumbs'
import {useToggleArray} from 'lib/toggle'
import PositionSelectorButton from 'lib/ui/BackgroundPositionSelector/BackgroundPositionSelectorButton'
import BackgroundPositionSelector from 'lib/ui/BackgroundPositionSelector'

type GlobalStylesFormData = Pick<
  Cards,
  'textColor' | 'linkColor' | 'linkUnderline'
> &
  Cards['background']

export default function GlobalStylesConfig() {
  const template = useCardsTemplate()
  const updateBackground = useCardsUpdate()
  const {handleSubmit, control} = useForm()

  const [showingImage1Options, toggleImage1Options] = useToggleArray()
  const [showingImage2Options, toggleImage2Options] = useToggleArray()
  const [showingImage3Options, toggleImage3Options] = useToggleArray()
  const [showingImage4Options, toggleImage4Options] = useToggleArray()
  const [showingImage5Options, toggleImage5Options] = useToggleArray()

  const save = (data: GlobalStylesFormData) => {
    updateBackground({
      ...data,
    })
  }
  return (
    <DesignBreadcrumbs page="Global Styles">
      <Page>
        <SectionTitle>Global Styles</SectionTitle>
        <form onSubmit={handleSubmit(save)}>
          <Grid container justify="center" spacing={10}>
            <Grid item xs={4} md={4}>
              <Box mb={2}>
                <BackgroundImageUploader
                  label="Background 1"
                  property="backgroundImage1"
                  control={control}
                  additionalActions={
                    <PositionSelectorButton onClick={toggleImage1Options} />
                  }
                />
                <Controller
                  name="backgroundImage1Position"
                  control={control}
                  defaultValue={template.backgroundImage1Position}
                  render={({onChange, value}) => (
                    <BackgroundPositionSelector
                      showing={showingImage1Options}
                      onChange={onChange}
                      value={value}
                      label="Background 1 Position"
                    />
                  )}
                />
              </Box>
            </Grid>
            <Grid item xs={4} md={4}>
              <Box mb={2}>
                <BackgroundImageUploader
                  label="Background 2"
                  property="backgroundImage2"
                  control={control}
                  additionalActions={
                    <PositionSelectorButton onClick={toggleImage2Options} />
                  }
                />
                <Controller
                  name="backgroundImage2Position"
                  control={control}
                  defaultValue={template.backgroundImage2Position}
                  render={({onChange, value}) => (
                    <BackgroundPositionSelector
                      showing={showingImage2Options}
                      onChange={onChange}
                      value={value}
                      label="Background 2 Position"
                    />
                  )}
                />
              </Box>
            </Grid>
            <Grid item xs={4} md={4}>
              <Box mb={2}>
                <BackgroundImageUploader
                  label="Background 3"
                  property="backgroundImage3"
                  control={control}
                  additionalActions={
                    <PositionSelectorButton onClick={toggleImage3Options} />
                  }
                />
                <Controller
                  name="backgroundImage3Position"
                  control={control}
                  defaultValue={template.backgroundImage3Position}
                  render={({onChange, value}) => (
                    <BackgroundPositionSelector
                      showing={showingImage3Options}
                      onChange={onChange}
                      value={value}
                      label="Background 3 Position"
                    />
                  )}
                />
              </Box>
            </Grid>
            <Grid item xs={4} md={4}>
              <Box mb={2}>
                <BackgroundImageUploader
                  label="Background 4"
                  property="backgroundImage4"
                  control={control}
                  additionalActions={
                    <PositionSelectorButton onClick={toggleImage4Options} />
                  }
                />
                <Controller
                  name="backgroundImage4Position"
                  control={control}
                  defaultValue={template.backgroundImage4Position}
                  render={({onChange, value}) => (
                    <BackgroundPositionSelector
                      showing={showingImage4Options}
                      onChange={onChange}
                      value={value}
                      label="Background 4 Position"
                    />
                  )}
                />
              </Box>
            </Grid>
            <Grid item xs={4} md={4}>
              <Box mb={2}>
                <BackgroundImageUploader
                  label="Background 5"
                  property="backgroundImage5"
                  control={control}
                  additionalActions={
                    <PositionSelectorButton onClick={toggleImage5Options} />
                  }
                />
                <Controller
                  name="backgroundImage5Position"
                  control={control}
                  defaultValue={template.backgroundImage5Position}
                  render={({onChange, value}) => (
                    <BackgroundPositionSelector
                      showing={showingImage5Options}
                      onChange={onChange}
                      value={value}
                      label="Background 5 Position"
                    />
                  )}
                />
              </Box>
            </Grid>
          </Grid>
          <Box mb={2}>
            <Controller
              name="background.color"
              defaultValue={template.background.color}
              control={control}
              render={({value, onChange}) => (
                <ColorPicker
                  label="Background Color"
                  color={value}
                  onPick={onChange}
                  aria-label="dashboard background color"
                />
              )}
            />
          </Box>
          <Controller
            name="background.opacity"
            defaultValue={template.background.opacity}
            control={control}
            render={({value, onChange}) => (
              <Slider
                min={0}
                max={100}
                step={1}
                onChange={onChange}
                valueLabelDisplay="auto"
                value={value}
                valueLabelFormat={() => <div>{value}</div>}
                aria-label="background color opacity"
                label="Background Color Opacity"
                unit="%"
              />
            )}
          />
          <FormControl>
            <InputLabel>Font Family</InputLabel>
            <Controller
              control={control}
              name="font"
              defaultValue={template.font || null}
              render={({value, onChange}) => (
                <FormControl fullWidth>
                  <FontFamilySelect value={value} onChange={onChange} />
                </FormControl>
              )}
            />
          </FormControl>

          <Box mb={2}>
            <Controller
              name="textColor"
              defaultValue={template.textColor}
              control={control}
              render={({value, onChange}) => (
                <ColorPicker
                  label="Text Color"
                  color={value}
                  onPick={onChange}
                  aria-label="text color"
                />
              )}
            />
          </Box>
          <Box mb={2}>
            <Controller
              name="linkColor"
              defaultValue={template.linkColor}
              control={control}
              render={({value, onChange}) => (
                <ColorPicker
                  label="Link Color"
                  color={value}
                  onPick={onChange}
                  aria-label="link color"
                />
              )}
            />
          </Box>
          <Box mb={2}>
            <Controller
              name="linkUnderline"
              defaultValue={template.linkUnderline}
              control={control}
              render={({value, onChange}) => (
                <Switch
                  label={
                    value ? 'Link Underline Enabled' : 'Link Underline Disabled'
                  }
                  checked={value}
                  onChange={onChangeCheckedHandler(onChange)}
                  labelPlacement="end"
                />
              )}
            />
          </Box>
          <SaveButton />
        </form>
      </Page>
    </DesignBreadcrumbs>
  )
}

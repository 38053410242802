import styled from 'styled-components'
import React, {ChangeEvent} from 'react'
import InputLabel from 'lib/ui/InputLabel'
import {Grid, Radio} from '@material-ui/core'

export type BackgroundPosition =
  | 'left top'
  | 'center top'
  | 'right top'
  | 'left center'
  | 'center center'
  | 'right center'
  | 'left bottom'
  | 'center bottom'
  | 'right bottom'

export type BackgroundPositionSelectorProps = {
  showing?: boolean
  label: string
  onChange: (position: BackgroundPosition) => void
  value: BackgroundPosition
}

export default function BackgroundPositionSelector(
  props: BackgroundPositionSelectorProps,
) {
  const {showing = true, label, onChange, value} = props

  if (!showing) {
    return null
  }

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value as BackgroundPosition)
  }

  return (
    <Box>
      <StyledInputLabel>{label}</StyledInputLabel>
      <Right>
        <StyledPositionHolder>
          <Grid container>
            <StyledGrid item xs={4}>
              <StyledRadio
                inputProps={{
                  'aria-label': 'select position left top',
                }}
                checked={value === 'left top'}
                onChange={handleChange}
                value="left top"
                name="position"
              />
            </StyledGrid>
            <StyledGrid item xs={4}>
              <StyledRadio
                checked={value === 'center top'}
                onChange={handleChange}
                value="center top"
                name="position"
              />
            </StyledGrid>
            <StyledGrid item xs={4}>
              <StyledRadio
                checked={value === 'right top'}
                onChange={handleChange}
                value="right top"
                name="position"
              />
            </StyledGrid>
            <StyledGrid item xs={4}>
              <StyledRadio
                checked={value === 'left center'}
                onChange={handleChange}
                value="left center"
                name="position"
              />
            </StyledGrid>
            <StyledGrid item xs={4}>
              <StyledRadio
                checked={value === 'center center'}
                onChange={handleChange}
                value="center center"
                name="position"
              />
            </StyledGrid>
            <StyledGrid item xs={4}>
              <StyledRadio
                checked={value === 'right center'}
                onChange={handleChange}
                value="right center"
                name="position"
              />
            </StyledGrid>
            <StyledGrid item xs={4}>
              <StyledRadio
                checked={value === 'left bottom'}
                onChange={handleChange}
                value="left bottom"
                name="position"
              />
            </StyledGrid>
            <StyledGrid item xs={4}>
              <StyledRadio
                checked={value === 'center bottom'}
                onChange={handleChange}
                value="center bottom"
                name="position"
              />
            </StyledGrid>
            <StyledGrid item xs={4}>
              <StyledRadio
                checked={value === 'right bottom'}
                onChange={handleChange}
                value="right bottom"
                name="position"
              />
            </StyledGrid>
          </Grid>
        </StyledPositionHolder>
      </Right>
    </Box>
  )
}

const Box = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${(props) => props.theme.spacing[6]};
`

const StyledInputLabel = styled(InputLabel)`
  flex: 10;
`

const Right = styled.div`
  display: flex;
  flex: 160px;
`

const StyledPositionHolder = styled.div`
  background: #353535;
  border-radius: 4px;
`

const StyledRadio = styled(Radio)`
  color: #a0a0a0;

  &.Mui-checked {
    color: #ffffff;
  }
`

const StyledGrid = styled(Grid)`
  justify-content: center;
  align-items: center;
  display: inline-flex;
`

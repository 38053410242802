import React, {useState, useEffect, useCallback} from 'react'
import Page from 'organization/Event/ImageWaterfall/Page'
import {
  ImageEntry,
  useFetchEntriesRequest,
} from 'organization/Event/ImageEntriesProvider'
import {useInterval} from 'lib/interval'
import AnimationWall from 'organization/Event/ImageWaterfall/AnimationWall'
import FullPageLoader from 'lib/ui/layout/FullPageLoader'

/**
 * How often to fetch new entries.
 */
const POLL_INTERVAL_MS = 1000

export default function ImageWaterfall() {
  const [entries, setEntries] = useState<ImageEntry[]>([])
  const [loading, setLoading] = useState(true)

  const request = useFetchEntriesRequest({
    status: 'approved',
    startId: '',
    limit: 100,
  })

  const fetch = useCallback(() => {
    request().then((data) => {
      setEntries(data.items)
      setLoading(false)
    })
  }, [request])

  useEffect(fetch, [fetch]) // Initial fetch
  useInterval(fetch, POLL_INTERVAL_MS) // Fetch more entries...

  if (loading) {
    return (
      <Page>
        <FullPageLoader />
      </Page>
    )
  }

  if (entries.length === 0) {
    return (
      <Page>
        <div>No Entries Available</div>
      </Page>
    )
  }

  return (
    <Page>
      <AnimationWall entries={entries} />
    </Page>
  )
}

import React from 'react'
import {useGuestVariables} from 'Event'
import TextField from 'Event/auth/Login/TextField'
import {useTemplate} from 'Event/TemplateProvider'
import {UseFormMethods} from 'react-hook-form'
import Configurable from 'organization/Event/Configurable'
import LastNameFieldConfig from './LastNameFieldConfig'
import {useQueryParams} from 'lib/url'

export default function LastNameField(props: {
  submitting: boolean
  errors: Record<string, any>
  register: UseFormMethods['register']
}) {
  const {signUp} = useTemplate()
  const v = useGuestVariables()
  const lastNameLabel = v(signUp.lastNameLabel)
  const {last_name} = useQueryParams()

  return (
    <Configurable>
      <LastNameFieldConfig />
      <TextField
        label={lastNameLabel}
        type="text"
        fullWidth
        variant="outlined"
        name="last_name"
        disabled={props.submitting}
        defaultValue={last_name}
        inputProps={{
          ref: props.register({
            required: `Last Name is required`,
          }),
          'aria-label': 'last name',
        }}
        error={!!props.errors.last_name}
        helperText={props.errors.last_name}
      />
    </Configurable>
  )
}

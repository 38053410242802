import {BlogPost} from 'Event/Dashboard/components/BlogPosts'
import styled from 'styled-components'
import React from 'react'
import {useTemplate} from 'Event/TemplateProvider'
import {useLocalTime} from 'lib/date-time'

export default function Date(props: {
  post: BlogPost
  className?: string
  TextComponent?: React.FC
}) {
  const {post, TextComponent = Text} = props
  const {postStyles} = useTemplate()
  const date = post.publishAt || post.postedAt
  const localTime = useLocalTime()

  if (post.hideDate) {
    return null
  }

  return (
    <TextComponent
      textColor={postStyles.dateTextColor}
      className={props.className}
      fontSize={postStyles.dateFontSize}
      aria-label="blog post publish date"
    >
      {localTime(date)}
    </TextComponent>
  )
}

const Text = styled.span<{
  textColor: string
  fontSize?: number
}>`
  font-size: ${(props) => (props.fontSize ? props.fontSize : 14)}px;
  color: ${(props) =>
    props.textColor ? props.textColor : 'inherit'} !important;
  display: block;
  margin-bottom: ${(props) => props.theme.spacing[4]};
`

import {PaginatedCollection, client} from 'lib/ui/api-client'
import {communicationsApi} from 'lib/url'
import {Broadcast} from 'organization/Event/Broadcasts/BroadcastsProvider'
import {useQuery} from 'react-query'

export function useBroadcasts({
  paymentMethodId,
  status,
  enabled,
}: {
  paymentMethodId?: string
  status?: 'in_future'
  enabled?: boolean
} = {}) {
  const params = new URLSearchParams()

  if (paymentMethodId) {
    params.set('payment_method_id', paymentMethodId)
  }

  if (status) {
    params.set('status', status)
  }

  const search = params.toString()
  const baseUrl = `/broadcasts`

  const url = search ? `${baseUrl}?${search}` : baseUrl

  return useQuery({
    queryKey: [
      'broadcasts',
      {
        status,
        paymentMethodId,
      },
    ],
    queryFn: () =>
      client.get<PaginatedCollection<Broadcast>>(communicationsApi(url)),
    enabled,
  })
}

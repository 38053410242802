import React from 'react'
import PageBreadcrumbs from 'organization/Event/Page/PageBreadcrumbs'
import Header from 'lib/ui/layout/Header'
import {Title} from 'lib/ui/typography'
import Page from 'organization/Event/Page'
import AddScriptButton from 'organization/Event/Scripts/AddScriptButton'
import ScriptsTable from 'organization/Event/Scripts/ScriptsTable'

export default function Scripts() {
  return (
    <PageBreadcrumbs page="Scripts">
      <Page>
        <Header>
          <Title>Scripts</Title>
          <AddScriptButton />
        </Header>
        <ScriptsTable />
      </Page>
    </PageBreadcrumbs>
  )
}

import Divider from '@material-ui/core/Divider'
import styled from 'styled-components'
import MenuItem from '@material-ui/core/MenuItem'
import {obvioRoutes} from 'obvio/Routes'
import React from 'react'
import logo from 'assets/images/logo-white@0.125x.png'
import {RelativeLink} from 'lib/ui/link/RelativeLink'

export default React.forwardRef(() => {
  return (
    <>
      <RelativeLink to={obvioRoutes.promoter} disableStyles>
        <StyledMenuItem>
          Share <Logo src={logo} alt="obvio" />
        </StyledMenuItem>
      </RelativeLink>
      <Divider />
    </>
  )
})

const StyledMenuItem = styled(MenuItem)`
  background: ${(props) => props.theme.colors.sidebar.background}!important;
  color: #ffffff !important;

  &:hover {
    background: ${(props) => props.theme.colors.primary}!important;
  }
`

const Logo = styled.img`
  width: 64px;
  margin-left: 8px;
`

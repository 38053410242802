import React from 'react'
import {DragDropContext, Droppable, DropResult} from 'react-beautiful-dnd'
import {useEditMode} from 'Event/EditModeProvider'
import {
  useNiftyFiftyTemplate,
  useNiftyFiftyUpdate,
} from 'Event/template/NiftyFifty'
import NewMainNavButton from 'Event/template/NiftyFifty/Dashboard/MainNav/MainNavButton/NewMainNavButton'
import MainNavButton from 'Event/template/NiftyFifty/Dashboard/MainNav/MainNavButton'
import {Container} from 'Event/template/NiftyFifty/Dashboard/MainNav/MainNavDesktop'
import {createPositions, orderedIdsByPosition} from 'lib/list'
import Scheduled from 'lib/ui/layout/Scheduled'

export default function BodyEditable(props: {className?: string}) {
  const handleDrag = useHandleDrag()
  const template = useNiftyFiftyTemplate()
  const {mainNav} = template

  const ids = orderedIdsByPosition(mainNav.buttons)

  return (
    <>
      <DragDropContext onDragEnd={handleDrag}>
        <Droppable droppableId="main_nav_buttons">
          {(provided) => (
            <Container
              ref={provided.innerRef}
              {...provided.droppableProps}
              canScroll
              className={props.className}
            >
              <>
                {ids.map((id, index) => (
                  <Scheduled key={id} component={mainNav.buttons[id]}>
                    <MainNavButton
                      id={id}
                      index={index}
                      button={mainNav.buttons[id]}
                      spacing={mainNav.spacing}
                    />
                  </Scheduled>
                ))}
                {provided.placeholder}
                <NewButton />
              </>
            </Container>
          )}
        </Droppable>
      </DragDropContext>
    </>
  )
}

function useHandleDrag() {
  const template = useNiftyFiftyTemplate()
  const updateTemplate = useNiftyFiftyUpdate()
  const {mainNav} = template

  return (result: DropResult) => {
    const {destination, source} = result

    if (!destination) {
      return
    }

    const ids = orderedIdsByPosition(mainNav.buttons)
    const [removed] = ids.splice(source.index, 1)
    ids.splice(destination.index, 0, removed)

    updateTemplate({
      mainNav: {
        buttons: createPositions(ids),
      },
    })
  }
}

function NewButton() {
  const isEditMode = useEditMode()

  if (!isEditMode) {
    return null
  }

  return <NewMainNavButton />
}

import React from 'react'
import {useEvent} from 'Event/EventProvider'
import {
  ACTIVE_CAMPAIGN,
  CONVERT_KIT,
  HIGHLEVEL,
  HUBSPOT,
  INFUSIONSOFT,
  MAILCHIMP,
  Service,
  ZAPIER,
  ONTRAPORT,
} from 'organization/Event/Services/ServicesProvider'

export default function ServiceComponent(props: {
  children: JSX.Element | JSX.Element[]
  service: Service
}) {
  const hasService = useHasService(props.service)
  if (hasService === false) {
    return null
  }

  return <>{props.children}</>
}

function useHasService(service: Service) {
  const {event} = useEvent()
  switch (service) {
    case INFUSIONSOFT:
      return event.has_infusionsoft
    case ACTIVE_CAMPAIGN:
      return event.has_active_campaign
    case HUBSPOT:
      return event.has_hubspot
    case MAILCHIMP:
      return event.has_mailchimp
    case ZAPIER:
      return event.has_zapier
    case HIGHLEVEL:
      return event.has_highlevel
    case CONVERT_KIT:
      return event.has_convert_kit
    case ONTRAPORT:
      return event.has_ontraport
    default:
      throw new Error(`Unhandled service: ${service}`)
  }
}

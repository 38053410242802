import React from 'react'
import FeaturePage from 'Event/template/Townhall/Page'
import FaqList from 'Event/template/Townhall/FAQs/FaqList'
import {User} from 'auth/user'
import {
  FeaturePageTitle,
  PageTexts,
  StyledConfigurable,
} from 'Event/template/Townhall/Page'
import {useAttendeeVariables} from 'Event'
import {useTownhallTemplate} from 'Event/template/Townhall'
import AddFaqButton from 'Event/FAQs/AddFaqButton'
import {TownhallBreadcrumbs} from 'Event/template/Townhall/Page'
import FaqsHeaderConfig from 'Event/template/Townhall/FAQs/FaqsHeaderConfig'

export default function Faqs(props: {user: User}) {
  const template = useTownhallTemplate()
  const {faq: pageSettings} = template
  const v = useAttendeeVariables()

  return (
    <FeaturePage user={props.user}>
      <StyledConfigurable>
        <FaqsHeaderConfig />
        <>
          <FeaturePageTitle aria-label="faqs title">
            {v(pageSettings?.title)}
          </FeaturePageTitle>
          <TownhallBreadcrumbs page={v(pageSettings.title)} />
          <PageTexts
            title={pageSettings.secondaryTitle}
            description={pageSettings.description}
          />
        </>
      </StyledConfigurable>
      <AddFaqButton />
      <FaqList />
    </FeaturePage>
  )
}

import {AND, BaseRule} from 'Event/attendee-rules'
import {formatDateInLocalTimezone} from 'lib/date-time'
import React from 'react'

export const TIME = 'Time'

export type TimeRule = BaseRule & {
  source: typeof TIME
  from: string | null
  to: string | null
}

export default function TimeRuleLabel(props: {rule: TimeRule}) {
  const from = props.rule.from
    ? formatDateInLocalTimezone(props.rule.from, 'MM-DD-YYYY HH:mm')
    : null
  const to = props.rule.to
    ? formatDateInLocalTimezone(props.rule.to, 'MM-DD-YYYY HH:mm')
    : null

  let message = `show from ${from} until ${to}`

  if (!from) {
    message = `show until ${to}`
  }

  if (!to) {
    message = `show from ${from} `
  }

  return <div>{message}</div>
}

export const createTimeRule = (
  from: TimeRule['from'],
  to: TimeRule['to'],
): TimeRule => ({
  condition: AND,
  source: TIME,
  from,
  to,
})

import {useTemplate} from 'Event/TemplateProvider'
import {useSaveTemplate} from 'Event/TemplateUpdateProvider'
import styled from 'styled-components'
import {onChangeCheckedHandler} from 'lib/dom'
import React from 'react'
import {EnabledSwitch} from 'lib/ui/form/Switch'

export default function ToggleWaiverSwitch() {
  const {waiver} = useTemplate()
  const save = useSaveTemplate()
  const handleToggleWaiver = (isEnabled: boolean) => {
    save({
      waiver: {
        isEnabled,
      },
    })
  }

  return (
    <StyledEnabledSwitch
      checked={waiver.isEnabled}
      onChange={onChangeCheckedHandler(handleToggleWaiver)}
      aria-label="toggle waiver"
    />
  )
}

const StyledEnabledSwitch = styled(EnabledSwitch)`
  margin-bottom: 0;
`

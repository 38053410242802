import React from 'react'
import {Cards, useCardsUpdate} from 'Event/template/Cards'
import ComponentConfig, {
  ComponentConfigProps,
  SaveButton,
  Footer,
} from 'organization/Event/DashboardConfig/ComponentConfigPanel'
import {useForm} from 'react-hook-form'

import Settings from 'Event/template/Cards/Dashboard/Footer/FooterConfig/Settings'
import Styling from 'Event/template/Cards/Dashboard/Footer/FooterConfig/Styling'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import StylingPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/StylingPanel'
import {
  useAutoUpdate,
  useTemplateEditor,
} from 'organization/Event/TemplateEditor'
import {usePruneAssets} from 'lib/asset'

export const DEFAULT_FOOTER_IMAGE_SIZE = 100

export function FooterConfig(props: ComponentConfigProps) {
  const {showing, onClose} = props
  const update = useCardsUpdate()
  const {handleSubmit, control, watch, formState} = useForm()
  const pruneAssets = usePruneAssets()
  const {saved} = useTemplateEditor<Cards>()

  const save = (data: Cards['footer']) => {
    const image = data.image
    pruneAssets({image}, saved.footer)

    update({
      footer: data,
    })

    onClose()
  }

  useAutoUpdate({values: {footer: watch()}, when: showing})

  return (
    <ComponentConfig
      title="Footer"
      showing={showing}
      onClose={onClose}
      onSubmit={handleSubmit(save)}
      hasChanges={formState.isDirty}
    >
      <SettingsPanel>
        <Settings control={control} />
      </SettingsPanel>
      <StylingPanel>
        <Styling control={control} />
      </StylingPanel>
      <Footer>
        <SaveButton type="submit" />
      </Footer>
    </ComponentConfig>
  )
}

import {usePanelsTemplate} from 'Event/template/Panels'
import {FullHeightConfigurable} from 'Event/template/Panels/check-in/LeftPanel'
import RightPanelConfig from 'Event/template/Panels/check-in/RightPanel/RightPanelConfig'
import {rgba} from 'lib/color'
import React from 'react'
import styled from 'styled-components'

export default function RightPanel(props: {
  children: React.ReactElement
  center?: boolean
}) {
  const template = usePanelsTemplate()
  const {checkInRightPanel, textColor} = template

  return (
    <FullHeightConfigurable aria-label="right panel config">
      <RightPanelConfig />
      <Box
        data-testid="right panel"
        backgroundColor={rgba(
          checkInRightPanel.backgroundColor,
          checkInRightPanel.backgroundOpacity / 100,
        )}
        rightPanelColor={checkInRightPanel.textColor}
        textColor={textColor}
        center={props.center}
      >
        {props.children}
      </Box>
    </FullHeightConfigurable>
  )
}

const Box = styled.div<{
  backgroundColor: string
  textColor: string
  rightPanelColor: string | null
  center?: boolean
}>`
  padding: 100px 35px;
  margin: 24px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: ${(props) => (props.center ? 'center' : 'flex-start')};
  background: ${(props) => props.backgroundColor};
  overflow: auto;

  > * {
    color: ${(props) =>
      props.rightPanelColor
        ? props.rightPanelColor
        : props.textColor} !important;
  }
`

import React from 'react'
import {useAttendeeVariables} from 'Event'
import FeaturePage from 'Event/template/Lefty/Page'
import {
  FeaturePageTitle,
  PageTexts,
  StyledConfigurable,
} from 'Event/template/Lefty/Page'
import {useLeftyTemplate} from 'Event/template/Lefty'
import {User} from 'auth/user'
import Configurable from 'organization/Event/Configurable'
import BackgroundLayoutConfig from 'Event/Backgrounds/BackgroundLayoutConfig'
import UploadBackgroundButton from 'Event/Backgrounds/UploadBackgroundButton'
import BackgroundList from 'Event/Backgrounds/BackgroundList'
import {LeftyBreadcrumbs} from 'Event/template/Lefty/Page'
import BackgroundsHeaderConfig from 'Event/template/Lefty/Backgrounds/BackgroundsHeaderConfig'

export default function Backgrounds(props: {user: User}) {
  const {zoomBackgrounds: settings} = useLeftyTemplate()
  const v = useAttendeeVariables()

  return (
    <FeaturePage user={props.user}>
      <StyledConfigurable>
        <BackgroundsHeaderConfig />
        <>
          <FeaturePageTitle aria-label="backgrounds title">
            {v(settings.title)}
          </FeaturePageTitle>
          <LeftyBreadcrumbs page={v(settings.title)} />
          <PageTexts
            title={settings.secondaryTitle}
            description={settings.description}
          />
        </>
      </StyledConfigurable>
      <UploadBackgroundButton />
      <Configurable>
        <BackgroundLayoutConfig />
        <BackgroundList />
      </Configurable>
    </FeaturePage>
  )
}

import {useTownhallTemplate, useTownhallUpdate} from 'Event/template/Townhall'
import {StyleFields} from 'Event/template/Townhall/Login/Title/TitleConfig'
import Title from 'lib/ui/ConfigPanel/Title'
import TextField from 'lib/ui/TextField'
import {useConfig} from 'organization/Event/Configurable'
import Config from 'organization/Event/Configurable/Config'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import StylingPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/StylingPanel'
import React from 'react'

export default function TitleConfig() {
  const save = useTownhallUpdate()
  const {login} = useTownhallTemplate()

  const {
    form: {register},
  } = useConfig()

  return (
    <Config title="Title" onSave={save}>
      <SettingsPanel>
        <Title>Title</Title>
        <TextField
          name="login.passwordReset.forgotPasswordTitle"
          defaultValue={login.passwordReset.forgotPasswordTitle}
          label="Text"
          fullWidth
          inputProps={{
            'aria-label': 'text',
            ref: register,
          }}
        />
      </SettingsPanel>
      <StylingPanel>
        <StyleFields />
      </StylingPanel>
    </Config>
  )
}

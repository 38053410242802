import CustomButtonFontSizeCommand from './custombuttonfontsizecommand';
import Plugin from '@ckeditor/ckeditor5-core/src/plugin';

export default class CustomButtonFontSizeEditing extends Plugin {
	/**
      * @inheritDoc
      */
	static get pluginName() {
		return 'CustomButtonFontSizeEditing';
	}

	/**
      * @inheritDoc
      */
	init() {
		this.editor.commands.add( 'customButtonFontSize', new CustomButtonFontSizeCommand( this.editor ) );
	}
}


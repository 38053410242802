import React from 'react'
import styled from 'styled-components'
import {useEditMode} from 'Event/EditModeProvider'
import {useTemplate} from 'Event/TemplateProvider'
import {Sponsor} from 'Event/Sponsors'

export const SPONSOR_QUESTION_ICON_PLACEHOLDER = 'http://placehold.jp/50x50.png'

export default function QuestionIcon(props: {
  sponsor: Sponsor
  id: string
  onClick: () => void
  className?: string
}) {
  const template = useTemplate()
  const {sponsors} = template
  const isEditMode = useEditMode()

  if (!props.sponsor.formId && !isEditMode) {
    return null
  }

  const src = sponsors.questionIcon || SPONSOR_QUESTION_ICON_PLACEHOLDER

  return (
    <Box
      onClick={props.onClick}
      aria-label="sponsor questions"
      className={props.className}
    >
      <img src={src} alt="show sponsor questions" />
    </Box>
  )
}

const Box = styled.div`
  cursor: pointer;
  width: 40px;

  img {
    width: 100%;
    max-height: 100%;
  }
`

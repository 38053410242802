import React, {useState} from 'react'
import Header from 'Event/template/Lefty/Dashboard/Header'
import Menu from 'Event/template/Lefty/Menu'
import {User} from 'auth/user'
import Footer from 'Event/template/Lefty/Dashboard/Footer'
import {useLeftyTemplate} from 'Event/template/Lefty'
import {rgba} from 'lib/color'
import LanguageSelector from 'Event/LanguageSelector'
import {muiTheme} from 'lib/ui/theme'
import {ThemeProvider} from '@material-ui/core/styles'
import {useLoadFont} from 'lib/FontSelect'
import {Box, Content, ColorOverlay} from 'Event/template/Lefty/Page'
import Hidden from '@material-ui/core/Hidden'
import styled from 'styled-components'
import Container from '@material-ui/core/Container'
import {withStyles} from '@material-ui/core/styles'

export default function DashboardPage(props: {
  Mobile: React.ReactElement
  Desktop: React.ReactElement
}) {
  return (
    <ThemeProvider theme={muiTheme}>
      <Hidden mdUp>{props.Mobile}</Hidden>
      <Hidden smDown>{props.Desktop}</Hidden>
    </ThemeProvider>
  )
}

export function Top(props: {user: User}) {
  const [menuVisible, setMenuVisible] = useState(false)
  const toggleMenu = () => setMenuVisible(!menuVisible)

  return (
    <>
      <Menu visible={menuVisible} toggle={toggleMenu} user={props.user} />
      <Header
        menuVisible={menuVisible}
        toggleMenu={toggleMenu}
        aria-label="header"
      />
    </>
  )
}

export function Bottom(props: {
  children: React.ReactElement | React.ReactElement[]
}) {
  const template = useLeftyTemplate()
  const {
    pageBackgroundPosition,
    pageBackground: page,
    contentOverlap,
  } = template
  const pageBackground = page.image ? `url(${page.image})` : 'transparent'

  const backgroundRGBColor = page
    ? rgba(page.color || '#FFFFFF', page.opacity / 100 || 0)
    : '#FFFFFF'

  const color = template.textColor
  const linkUnderline = template.linkUnderline
  const linkColor = template.linkColor
  const font = template.font

  useLoadFont(font)

  return (
    <>
      <Box
        background={pageBackground}
        position={pageBackgroundPosition}
        color={color}
        linkColor={linkColor}
        linkUnderline={linkUnderline}
        font={font}
      >
        <ColorOverlay color={backgroundRGBColor}>
          <Content>
            <StyledContainer maxWidth="lg" overlap={contentOverlap}>
              {props.children}
            </StyledContainer>
          </Content>
        </ColorOverlay>
      </Box>
      <LanguageSelector />
      <Footer />
    </>
  )
}

export const StyledContainer = styled(
  withStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
      '@media (min-width: 600px)': {
        width: '80%',
      },
    },
  })(Container),
)<{overlap: number}>`
  margin-top: ${(props) => -(props.overlap * 3 - 32)}px;
`

import React from 'react'
import {Draggable} from 'react-beautiful-dnd'
import {DraggableOverlay} from 'lib/ui/drag-and-drop'
import styled from 'styled-components'
import {useAttendeeVariables} from 'Event'

type PriorityItemProps = {
  isSelected?: boolean
  index: number
  onClick: () => void
  children: string
  backgroundColor: string
  textColor: string
  borderRadius: number
}

export default function PriorityItem(props: PriorityItemProps) {
  const v = useAttendeeVariables()
  const {children} = props

  const ariaLabel = props.isSelected
    ? `selected ${props.children}`
    : `select ${props.children}`

  return (
    <Draggable draggableId={children} index={props.index}>
      {(provided) => (
        <Box ref={provided.innerRef} {...provided.draggableProps}>
          <DraggableOverlay>
            <Item
              aria-label={ariaLabel}
              backgroundColor={props.backgroundColor}
              textColor={props.textColor}
              borderRadius={props.borderRadius}
              onClick={props.onClick}
              {...provided.dragHandleProps}
            >
              {v(props.children)}
            </Item>
          </DraggableOverlay>
        </Box>
      )}
    </Draggable>
  )
}

const Box = styled.div`
  padding: 8px 0;
`

const Item = styled.div<{
  backgroundColor: string
  textColor: string
  borderRadius: number
}>`
  padding: 8px 16px;
  background-color: ${(props) => props.backgroundColor};
  color: ${(props) => props.textColor};
  border-radius: ${(props) => props.borderRadius}px;
  cursor: pointer;
`

import React from 'react'
import MenuItem from '@material-ui/core/MenuItem'
import Select, {SelectProps} from 'lib/ui/Select'
import {useEmails} from 'organization/Settings/CommunicationSettings/EmailsProvider'
import {Controller, UseFormMethods} from 'react-hook-form'

/**
 * String we'll use to split an email, and name apart, and join
 * together again so that they appear as a single field.
 */
export const NAME_EMAIL_DELIMITER = '|'

export default function VerifiedEmailField(props: {
  ControllerProps: {
    name: string
    control: UseFormMethods['control']
  }
  defaultFromEmail?: string
  defaultFromName?: string
  SelectProps?: Partial<SelectProps>
  disabled?: boolean
}) {
  const {
    defaultFromEmail: fromEmail,
    defaultFromName: fromName,
    SelectProps,
    ControllerProps,
    disabled,
  } = props
  const {verifiedEmails} = useEmails()

  const joined =
    fromEmail && fromName
      ? `${fromName}${NAME_EMAIL_DELIMITER}${fromEmail}`
      : null

  const exists = verifiedEmails.find(
    (e) => e.name === fromName && e.email === fromEmail,
  )

  const value = joined && exists ? joined : null

  return (
    <Controller
      {...ControllerProps}
      defaultValue={value}
      render={({onChange, value}) => {
        return (
          <VerifiedEmailSelect
            disabled={disabled}
            value={value}
            onChange={onChange}
            variant="outlined"
            fullWidth
            {...SelectProps}
          />
        )
      }}
    />
  )
}

function VerifiedEmailSelect(props: SelectProps) {
  const {value = ''} = props
  const {verifiedEmails} = useEmails()

  // Select requires a value, so we'll need to use 0 here to indicate
  // one has NOT been selected.
  const selected = value || 0

  return (
    <Select
      displayEmpty
      variant="outlined"
      fullWidth
      {...props}
      value={selected}
    >
      <MenuItem value={0} disabled>
        Pick One
      </MenuItem>
      {verifiedEmails.map((email, index) => (
        <MenuItem
          key={index}
          value={`${email.name}${NAME_EMAIL_DELIMITER}${email.email}`}
          aria-label={`pick ${email.id}`}
        >
          {email.name} - {email.email}
        </MenuItem>
      ))}
    </Select>
  )
}

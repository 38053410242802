import React from 'react'
import styled from 'styled-components'
import {Agenda} from 'Event/template/Townhall/Dashboard/Main/AgendaSection'
import {Speaker} from 'Event/Speakers'
import {useTownhallTemplate} from 'Event/template/Townhall'

export default function SpeakerImages(props: {agenda: Agenda}) {
  const {agenda} = props

  const {speakers} = useTownhallTemplate()

  return (
    <SpeakerImagesContainer>
      {agenda.speakers.map((id) => (
        <SpeakerImage key={id} speaker={speakers.items[id]} />
      ))}
    </SpeakerImagesContainer>
  )
}

function SpeakerImage(props: {speaker: Speaker | undefined}) {
  const {speaker} = props

  if (!speaker || !speaker.image) {
    return null
  }

  return <SpeakerAvatar src={speaker.image} />
}

const SpeakerImagesContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    margin-top: ${(props) => props.theme.spacing[1]};
    justify-content: flex-start;
  }
`

const SpeakerAvatar = styled.img`
  width: 35px;
  height: 35px;
  border-radius: 50%;
  margin-left: -9px;
`

import requireContext from 'require-context.macro'
import setAtPath from 'lodash/set'
import {Template} from 'Event/template'

export const screenshots = importScreenshots(
  // Need to use babel's requireContext macro because jest runs in node (not weback)
  // and does not have in-built require.context.
  requireContext('./', true, /^.\/.*\/screenshots\/.*.[png|jpg|jpeg]$/),
)

function importScreenshots(r: __WebpackModuleApi.RequireContext) {
  const result = r.keys().reduce((modules, i) => {
    const paths = i.split('/')
    paths.shift() // remove leading '.' path

    // Create a keypath, and remove extension to avoid the extension
    // being set as the final key.
    const keyPath = paths
      .join('.')
      .replace('.screenshots', '')
      .replace(/.jpg$/, '')
      .replace(/.jpeg$/, '')
      .replace(/.png$/, '')

    const module = r(i)

    // Use lodash's mutating _.set to merge/set.
    setAtPath(modules, keyPath, module)

    return modules
  }, {} as Record<string, any>)

  return result
}

export function getPreviews(template: Template['name']): string[] {
  try {
    return screenshots[template].previews
  } catch {
    throw new Error(
      `Missing previews for ${template}. They should be stored with src/Event/template/${template}/screenshots/previews/*`,
    )
  }
}

export function getThumbnail(template: Template['name']): string {
  try {
    return screenshots[template].thumbnail
  } catch {
    throw new Error(
      `Missing thumbnail for ${template}. It should be stored at src/Event/template/${template}/screenshots/thumbnail.png (or other image extension).`,
    )
  }
}

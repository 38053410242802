import React from 'react'
import Configurable from 'organization/Event/Configurable'
import PurchaseFormButtonConfig from 'organization/Marketplace/PurchasePageConfig/BlockConfig/PurchaseFormButtonConfig'
import PurchaseFormButton, {
  PurchaseFormButtonProps,
} from 'Event/Marketplace/Block/PurchaseForm/PurchaseFormButton'

export default function ConfigurablePurchaseFormButton(
  props: PurchaseFormButtonProps,
) {
  return (
    <Configurable aria-label={`edit ${props['aria-label']}`}>
      <PurchaseFormButtonConfig {...props} />
      <PurchaseFormButton {...props} />
    </Configurable>
  )
}

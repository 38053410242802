import ColorPicker from 'lib/ui/ColorPicker'
import CollapsibleSection from 'lib/ui/ConfigPanel/CollapsibleSection'
import MarginPaddingInputs from 'lib/ui/MarginPaddingInputs'
import Slider from 'lib/ui/Slider'
import TextField from 'lib/ui/TextField'
import ImageAssetUploader from 'lib/asset/ImageAssetUploader'
import {useConfig} from 'organization/Event/Configurable'
import Config from 'organization/Event/Configurable/Config'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import StylingPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/StylingPanel'
import {useUpdateTemplate} from 'organization/Marketplace/PurchasePageConfig/TemplateUpdateProvider'
import React, {useState} from 'react'
import {Controller} from 'react-hook-form'
import Select from 'lib/ui/Select'
import Option from 'lib/ui/Select/Option'
import {Label} from 'lib/ui/typography'
import {onUnknownChangeHandler} from 'lib/dom'
import {REMOVE} from 'Event/TemplateUpdateProvider'
import MultilineTextField from 'lib/ui/TextField/TextArea'
import RadioButtons from 'lib/ui/RadioButtons'
import Visible from 'lib/ui/layout/Visible'
import Tooltip from '@material-ui/core/Tooltip'
import {InfoRegularIcon} from 'lib/ui/Icon'
import InputLabel from 'lib/ui/TextField/InputLabel'
import VisibilitySetting from 'organization/Marketplace/PurchasePageConfig/BlockConfig/VisibilitySettings'
import {VideoBlock} from 'Event/Marketplace/Block/Video'

interface VideoConfigProps extends VideoBlock {
  id: string
  sectionId: string
}

const aspectRatios = [
  {
    label: '16 X 9',
    value: '16:9',
  },
  {
    label: '9 X 16',
    value: '9:16',
  },
  {
    label: '1 X 1',
    value: '1:1',
  },
  {
    label: 'Custom',
    value: 'custom',
  },
]

export default function VideoConfig(props: VideoConfigProps) {
  const {
    url,
    html: embedCode,
    background,
    padding,
    borderWidth,
    borderRadius,
    borderColor,
    alignment,
    id,
    sectionId,
    aspectRatio,
  } = props
  const updateTemplate = useUpdateTemplate()
  const {
    form: {register, control, watch},
  } = useConfig()
  const [visibility, setVisibility] = useState(props.visibility)

  const showingCustomRatioInput =
    watch(
      `sections.${sectionId}.blocks.${id}.aspectRatio.value`,
      aspectRatio.value || '',
    ) === 'custom'

  const handleRemove = () => {
    updateTemplate({
      sections: {
        [sectionId]: {
          blocks: {
            [id]: REMOVE,
          },
        },
      },
    })
  }

  const save = (data: any) => {
    data.sections[sectionId].blocks[id].visibility = visibility
    updateTemplate(data)
  }

  return (
    <Config title="Video" onSave={save} onRemove={handleRemove}>
      <SettingsPanel>
        <InputLabel>
          Video URL
          <Tooltip
            title="Will work with YouTube, Vimeo, Searchie or Wistia video URLs."
            arrow
            placement="bottom"
          >
            <span>
              <InfoRegularIcon iconSize={16} />
            </span>
          </Tooltip>
        </InputLabel>
        <TextField
          name={`sections.${sectionId}.blocks.${id}.url`}
          defaultValue={url}
          inputProps={{
            'aria-label': 'url',
            ref: register,
          }}
        />
        <InputLabel>Video Embed Code</InputLabel>
        <MultilineTextField
          name={`sections.${sectionId}.blocks.${id}.embedCode`}
          defaultValue={embedCode || ''}
          label="Embed Code"
          rows={10}
          fullWidth
          inputProps={{
            'aria-label': 'embed code',
            ref: register,
          }}
        />
        <VisibilitySetting
          visibility={visibility}
          setVisibility={setVisibility}
        />
      </SettingsPanel>
      <StylingPanel>
        <CollapsibleSection
          label="Background"
          storageKey={`blocks-${id}-background`}
        >
          <Controller
            name={`sections.${sectionId}.blocks.${id}.background.image`}
            control={control}
            defaultValue={background.image}
            render={({onChange, value}) => (
              <ImageAssetUploader
                onChange={onChange}
                value={value}
                uploadLabel="Upload"
                uploadInputProps={{
                  'aria-label': 'block background image',
                }}
                width={1920}
                height={1200}
                canResize
                disableAutoRemove
              />
            )}
          />
          <Controller
            name={`sections.${sectionId}.blocks.${id}.background.color`}
            defaultValue={background.color}
            control={control}
            render={({value, onChange}) => (
              <ColorPicker
                label="Color"
                color={value}
                onPick={onChange}
                aria-label="block background color"
              />
            )}
          />
          <Controller
            name={`sections.${sectionId}.blocks.${id}.background.opacity`}
            defaultValue={background.opacity}
            control={control}
            render={({value, onChange}) => (
              <Slider
                label="Opacity"
                unit="%"
                valueLabelDisplay="auto"
                aria-label="block background opacity"
                value={value}
                onChange={onChange}
                step={1}
                min={0}
                max={100}
              />
            )}
          />
        </CollapsibleSection>

        <CollapsibleSection
          label="Aspect Ratio"
          storageKey={`blocks-${id}-border`}
        >
          <Controller
            name={`sections.${sectionId}.blocks.${id}.aspectRatio.value`}
            defaultValue={aspectRatio?.value}
            control={control}
            render={({value, onChange}) => (
              <RadioButtons
                options={aspectRatios}
                name="video-ratio-options"
                onChange={onChange}
                value={value}
              />
            )}
          />
          <Visible when={showingCustomRatioInput}>
            <Controller
              name={`sections.${sectionId}.blocks.${id}.aspectRatio.customWidthRatio`}
              defaultValue={aspectRatio?.customWidthRatio || 16}
              control={control}
              render={({value, onChange}) => (
                <Slider
                  min={1}
                  max={16}
                  step={1}
                  onChange={onChange}
                  value={value}
                  label="Width"
                  hideUnits
                />
              )}
            />
            <Controller
              name={`sections.${sectionId}.blocks.${id}.aspectRatio.customHeightRatio`}
              defaultValue={aspectRatio?.customHeightRatio || 9}
              control={control}
              render={({value, onChange}) => (
                <Slider
                  min={1}
                  max={16}
                  step={1}
                  onChange={onChange}
                  value={value}
                  label="Height"
                  hideUnits
                />
              )}
            />
          </Visible>
        </CollapsibleSection>

        <CollapsibleSection
          label="Advanced Spacing"
          storageKey={`blocks-${id}-spacing`}
        >
          <Label>Padding</Label>
          <MarginPaddingInputs
            control={control}
            namePrefix={`sections.${sectionId}.blocks.${id}.padding`}
            values={padding}
          />
        </CollapsibleSection>
        <CollapsibleSection label="Border" storageKey={`blocks-${id}-border`}>
          <Controller
            name={`sections.${sectionId}.blocks.${id}.borderWidth`}
            defaultValue={borderWidth}
            control={control}
            render={({value, onChange}) => (
              <Slider
                min={0}
                max={50}
                step={1}
                onChange={onChange}
                value={value}
                label="Thickness"
              />
            )}
          />
          <Controller
            name={`sections.${sectionId}.blocks.${id}.borderRadius`}
            defaultValue={borderRadius}
            control={control}
            render={({value, onChange}) => (
              <Slider
                min={0}
                max={50}
                step={1}
                onChange={onChange}
                value={value}
                label="Radius"
              />
            )}
          />
          <Controller
            name={`sections.${sectionId}.blocks.${id}.borderColor`}
            defaultValue={borderColor}
            control={control}
            render={({value, onChange}) => (
              <ColorPicker label="Color" color={value} onPick={onChange} />
            )}
          />
        </CollapsibleSection>
        <CollapsibleSection
          label="Alignment"
          storageKey={`blocks-${id}-alignment`}
        >
          <Controller
            name={`sections.${sectionId}.blocks.${id}.alignment.vertical`}
            defaultValue={alignment.vertical}
            control={control}
            render={({value, onChange}) => (
              <Select
                fullWidth
                label="Vertical"
                value={value}
                onChange={onUnknownChangeHandler(onChange)}
              >
                <Option value="start">Top</Option>
                <Option value="center">Center</Option>
                <Option value="end">Bottom</Option>
              </Select>
            )}
          />
          <Controller
            name={`sections.${sectionId}.blocks.${id}.alignment.horizontal`}
            defaultValue={alignment.horizontal}
            control={control}
            render={({value, onChange}) => (
              <Select
                fullWidth
                label="Horizontal"
                value={value}
                onChange={onUnknownChangeHandler(onChange)}
              >
                <Option value="left">Left</Option>
                <Option value="center">Center</Option>
                <Option value="right">Right</Option>
              </Select>
            )}
          />
        </CollapsibleSection>
      </StylingPanel>
    </Config>
  )
}

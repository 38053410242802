import {useObvioAuth, useObvioUser} from 'obvio/auth'
import React, {useCallback, useEffect} from 'react'
import Layout from 'obvio/user/Layout'
import Page from 'lib/ui/layout/Page'
import FullPageLoader from 'lib/ui/layout/FullPageLoader'
import {useToggleArray} from 'lib/toggle'
import {teamMemberClient} from 'obvio/obvio-client'
import {api} from 'lib/url'
import {TeamMember} from 'auth/user'

const OBVIO_USERS_CAMPAIGN_ID = 15313

export default function PromoterDashboard() {
  return (
    <Layout>
      <Page>
        <Content />
      </Page>
    </Layout>
  )
}

function Content() {
  const user = useObvioUser()

  useAutoSignUpAsPromoter()

  if (!user.is_promoter) {
    return <FullPageLoader />
  }

  return (
    <iframe
      title="First Promoter Dashboard"
      height="850px"
      width="100%"
      frameBorder="0"
      src={`https://partner.obv.io//iframe?at=${user.promoter_auth_token}&camp_id=${OBVIO_USERS_CAMPAIGN_ID}`}
    />
  )
}

function useAutoSignUpAsPromoter() {
  const [processing, toggleProcessing] = useToggleArray()
  const {setUser} = useObvioAuth()
  const user = useObvioUser()

  const signUp = useSignUp()

  useEffect(() => {
    if (user.is_promoter) {
      return
    }

    if (processing) {
      return
    }

    toggleProcessing()

    signUp().then(setUser)
  }, [user, processing, toggleProcessing, signUp, setUser])
}

function useSignUp() {
  const url = api('/promoters/sign_up')
  return useCallback(() => teamMemberClient.post<TeamMember>(url), [url])
}

import {useLayout} from 'organization/Event/Layout'
import styled from 'styled-components'
import React from 'react'
import {CollapseIcon, ExpandIcon} from 'lib/ui/Icon'

export default function CollapseButton() {
  const {sidebarExpanded, toggleSidebar} = useLayout()

  return (
    <Button
      aria-label="toggle sidebar"
      onClick={toggleSidebar}
      expanded={sidebarExpanded}
    >
      {sidebarExpanded ? <CollapseIcon /> : <ExpandIcon />}
      <span>Collapse</span>
    </Button>
  )
}

export const Button = styled.button<{expanded?: boolean}>`
  background: ${(props) => props.theme.colors.input.filled};
  color: #000000;
  border: 0;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  display: none;

  > * {
    flex: 0;
  }

  // Only show on desktop slide-out sidebars
  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    display: flex;
  }

  i {
    margin-right: ${(props) => (props.expanded ? props.theme.spacing[3] : 0)};
  }

  padding: ${(props) =>
    props.expanded
      ? `${props.theme.spacing[2]} ${props.theme.spacing[10]}`
      : `${props.theme.spacing[2]} 0`};

  ${(props) => (props.expanded ? '' : 'border-top: 1px solid #ffffff;')}

  span {
    font-size: 14px;
    ${(props) => (props.expanded ? '' : 'display: none;')}
  }
`

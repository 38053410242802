import {User} from 'auth/user'
import axios from 'axios'
import {useEffect} from 'react'

/**
 * Logs out an attendee whenever an unauthenticated response
 * is received.
 *
 * @param props
 * @returns
 */
export default function AutoLogout(props: {
  children: JSX.Element
  user: User
  logout: () => void
}) {
  const {user, logout} = props

  useEffect(() => {
    const interceptor = axios.interceptors.response.use(
      (response) => response,
      (error) => {
        const isUnauthenticatedError =
          error.response && error.response.status === 401
        if (isUnauthenticatedError) {
          logout()
          return
        }

        // Regular error
        return Promise.reject(error)
      },
    )

    return () => {
      axios.interceptors.response.eject(interceptor)
    }
  }, [user, logout])

  return props.children
}

import React, {useCallback} from 'react'
import Layout from 'organization/user/Layout'
import Page from 'lib/ui/layout/Page'
import styled, {useTheme} from 'styled-components'
import {OrganizationEmail} from 'organization/Settings/CommunicationSettings/EmailsProvider'
import FullPageLoader from 'lib/ui/layout/FullPageLoader'
import {useOrganization} from 'organization/OrganizationProvider'
import {useParams} from 'react-router-dom'
import {useAsync} from 'lib/async'
import {api} from 'lib/url'
import Button from 'lib/ui/Button'
import {RelativeLink} from 'lib/ui/link/RelativeLink'
import Center from 'lib/ui/layout/Center'
import {CheckIcon, TimesCircle} from 'lib/ui/Icon'

export default function VerifyEmailPage() {
  const {routes: organizationRoutes} = useOrganization()
  const {token} = useParams<{token: string}>()
  const verification = useVerifyEmail(token)

  if (verification.loading) {
    return <FullPageLoader />
  }

  return (
    <Layout>
      <Page>
        <Center>
          <Content>
            <Status
              organizationEmail={verification.email}
              error={verification.error}
            />
            <SettingsButtonBox>
              <RelativeLink to={organizationRoutes.settings} disableStyles>
                <Button variant="contained" color="primary">
                  Back to Organizations Settings
                </Button>
              </RelativeLink>
            </SettingsButtonBox>
          </Content>
        </Center>
      </Page>
    </Layout>
  )
}

function Status(props: {
  organizationEmail: OrganizationEmail | null
  error?: any
}) {
  const {error, organizationEmail} = props

  const theme = useTheme()

  if (error) {
    return (
      <>
        <IconBox>
          <TimesCircle iconSize={38} color={theme.colors.error} />
        </IconBox>
        <Description>
          Could not verify email, please request another verification.
        </Description>
      </>
    )
  }

  if (!organizationEmail) {
    return null
  }

  return (
    <>
      <IconBox>
        <CheckIcon iconSize={38} color={theme.colors.success} />
      </IconBox>
      <Description>
        Thanks for confirming your name & email address, <br />
        {organizationEmail.name} & {organizationEmail.email}
      </Description>
    </>
  )
}

function useVerifyEmail(token: string) {
  const {client} = useOrganization()

  const request = useCallback(() => {
    const url = api(`/organization_emails/verify/${token}`)
    return client.post<OrganizationEmail>(url)
  }, [client, token])

  const {data, loading, error} = useAsync(request)

  return {email: data || null, loading, error}
}

const IconBox = styled.div`
  margin-bottom: ${(props) => props.theme.spacing[8]};
  text-align: center;
`

const Description = styled.p`
  font-weight: bold;
  text-align: center;
  font-size: 20px;
  line-height: 28px;
  margin: 0 0 ${(props) => props.theme.spacing[9]};
`

const Content = styled.div`
  padding: 0 ${(props) => props.theme.spacing[5]};
`

const SettingsButtonBox = styled.div`
  text-align: center;
`

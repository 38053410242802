import Slider from 'lib/ui/Slider'
import {TextProps} from 'Event/template/SimpleBlog/Dashboard/Sidebar/SidebarItem/Text'
import React from 'react'
import {Controller, UseFormMethods} from 'react-hook-form'
import OffsetInputs from 'Event/template/SimpleBlog/Dashboard/Sidebar/SidebarItem/OffsetInputs'

const MAX_PADDING = 16
const MIN_PADDING = 0

export default function Styling(props: {
  control: UseFormMethods['control']
  text: TextProps
}) {
  const {control, text} = props

  return (
    <>
      <Controller
        name="padding"
        defaultValue={text.padding}
        control={control}
        render={({value, onChange}) => (
          <Slider
            min={MIN_PADDING}
            max={MAX_PADDING}
            step={1}
            onChange={onChange}
            valueLabelDisplay="auto"
            value={value}
            label="Padding"
          />
        )}
      />
      <OffsetInputs
        control={control}
        offsetTop={text.offsetTop}
        offsetBottom={text.offsetBottom}
      />
    </>
  )
}

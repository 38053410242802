import React from 'react'
import styled from 'styled-components'
import Dialog from 'lib/ui/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Box from 'lib/ui/Box'
import Button from 'lib/ui/Button'
import TextField from 'lib/ui/TextField'
import {useValidatedForm} from 'lib/form'
import {useToggleArray} from 'lib/toggle'
import {communicationsApi} from 'lib/url'
import {client} from 'lib/ui/api-client'
import {Controller} from 'react-hook-form'
import {Typography} from 'lib/ui/typography'
import {useObvioUser} from 'obvio/auth'
import {EmailType} from 'organization/Event/EmailConfig'

type EmailTestData = {
  name: string
  email: string
}

export default function SendTestDialog(props: {
  isOpen: boolean
  onClose: () => void
  id: number
  title: string
  type: EmailType
}) {
  const {isOpen, onClose, id, title, type} = props
  const [submitting, toggleSubmitting] = useToggleArray()
  const {
    register,
    handleSubmit,
    control,
    errors,
    clearErrors,
    setResponseError,
  } = useValidatedForm<EmailTestData>()
  const user = useObvioUser()

  const submit = (data: EmailTestData) => {
    toggleSubmitting()
    clearErrors()

    const payload: EmailTestData = {
      name: `${user.first_name} ${user.last_name}`,
      email: data.email,
    }

    const url = communicationsApi(`/${type}s/${id}/test`)
    client
      .post<void>(url, payload)
      .catch(setResponseError)
      .finally(() => {
        toggleSubmitting()
        onClose()
      })
  }

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>Send Test {title}</DialogTitle>
      <DialogContent>
        <Box pb={2}>
          <StyledTypography>
            Generated data will be used to populate any{' '}
            <code>{'{{ variables }}'}</code> used within the body of the {title}{' '}
            Email.
          </StyledTypography>

          <form onSubmit={handleSubmit(submit)}>
            <Controller
              control={control}
              defaultValue=""
              name="email"
              render={({onChange, value}) => {
                return (
                  <TextField
                    defaultValue={value}
                    disabled={submitting}
                    error={!!errors.email}
                    fullWidth
                    helperText={errors.email}
                    inputProps={{
                      ref: register(),
                      'aria-label': 'email',
                    }}
                    label="Email to receive test"
                    name="email"
                    onChange={onChange}
                    required
                    variant="outlined"
                  />
                )
              }}
            />

            <StyledButton
              aria-label="send test"
              color="primary"
              disabled={submitting}
              type="submit"
              variant="contained"
            >
              Send Test
            </StyledButton>
          </form>
        </Box>
      </DialogContent>
    </Dialog>
  )
}

const StyledTypography = styled(Typography)`
  margin-bottom: ${(props) => props.theme.spacing[4]};
`

const StyledButton = styled(Button)`
  margin-top: ${(props) => props.theme.spacing[4]};

  @media (min-width: ${(props) => props.theme.breakpoints.sm}) {
    margin-top: initial;
  }
`

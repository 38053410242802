import ForgotPasswordLinkConfig from 'Event/auth/Login/ForgotPasswordLink/ForgotPasswordLinkConfig'
import styled from 'styled-components'
import Configurable from 'organization/Event/Configurable'
import React from 'react'
import {RelativeLink} from 'lib/ui/link/RelativeLink'
import {Icon} from 'lib/fontawesome/Icon'
import {useTemplate} from 'Event/TemplateProvider'
import {eventRoutes} from 'Event/Routes'
import {useGuestVariables} from 'Event'
import {useEditMode} from 'Event/EditModeProvider'

export default function ForgotPasswordLink(props: {
  Component?: React.FC<{
    to: any
    'aria-label'?: string
    textColor: string | null
  }>
}) {
  const {
    login: {
      description: {color},
      passwordReset: {iconColor, iconName, linkLabel},
      requiresPassword,
    },
  } = useTemplate()
  const v = useGuestVariables()
  const isEditMode = useEditMode()

  const Component = props.Component || ForgotPasswordLinkBase

  if (!requiresPassword && !isEditMode) {
    return null
  }

  return (
    <Configurable>
      <ForgotPasswordLinkConfig />
      <Component
        to={eventRoutes.forgotPassword}
        aria-label="forgot password"
        textColor={color}
      >
        <StyledIcon iconClass={iconName} color={iconColor} />
        {v(linkLabel)}
      </Component>
    </Configurable>
  )
}

export const ForgotPasswordLinkBase = styled(RelativeLink)<{
  textColor: string | null
}>`
  color: ${(props) => props.textColor};
  text-align: center;
  display: block;
`

const StyledIcon = styled(Icon)`
  margin-right: ${(props) => props.theme.spacing[2]};
`

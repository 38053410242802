import React from 'react'
import Slider from 'lib/ui/Slider'
import {Controller, UseFormMethods} from 'react-hook-form'
import {NavButtonWithSize} from 'Event/Dashboard/components/NavButton'
import ColorPicker from 'lib/ui/ColorPicker'
import CollapsibleSection from 'lib/ui/ConfigPanel/CollapsibleSection'
import Visible from 'lib/ui/layout/Visible'

export default function BorderSection(props: {
  control: UseFormMethods['control']
  button: Pick<
    NavButtonWithSize,
    'borderColor' | 'hoverBorderColor' | 'borderWidth' | 'borderRadius'
  >
  storageKey: string
  disableBorderRadius?: boolean
  namePrefix?: string
}) {
  const {control, button, namePrefix} = props

  return (
    <CollapsibleSection label="Border" storageKey={props.storageKey}>
      <Controller
        name={namePrefix ? `${namePrefix}.borderColor` : 'borderColor'}
        control={control}
        defaultValue={button.borderColor || ''}
        render={({value, onChange}) => (
          <ColorPicker label="Color" color={value} onPick={onChange} />
        )}
      />
      <Controller
        name={
          namePrefix ? `${namePrefix}.hoverBorderColor` : 'hoverBorderColor'
        }
        control={control}
        defaultValue={button.hoverBorderColor || ''}
        render={({value, onChange}) => (
          <ColorPicker label="Hover Color" color={value} onPick={onChange} />
        )}
      />
      <Controller
        name={namePrefix ? `${namePrefix}.borderWidth` : 'borderWidth'}
        defaultValue={button.borderWidth || 0}
        control={control}
        render={({value, onChange}) => (
          <Slider
            min={0}
            max={100}
            onChange={onChange}
            valueLabelDisplay="off"
            value={value}
            label="Width"
          />
        )}
      />
      <Visible when={!props.disableBorderRadius}>
        <>
          <Controller
            name={namePrefix ? `${namePrefix}.borderRadius` : 'borderRadius'}
            defaultValue={button.borderRadius || 0}
            control={control}
            render={({value, onChange}) => (
              <Slider
                min={0}
                max={100}
                onChange={onChange}
                valueLabelDisplay="off"
                value={value}
                label="Radius"
              />
            )}
          />
        </>
      </Visible>
    </CollapsibleSection>
  )
}

import {useBroadcastForm} from 'organization/Event/Broadcasts/BroadcastForm'
import BodyInput from 'organization/Event/Broadcasts/BroadcastForm/ChannelInputs/EmailInputs/BodyInput'
import SubjectInput from 'organization/Event/Broadcasts/BroadcastForm/ChannelInputs/EmailInputs/SubjectInput'
import {isEmailBroadcast} from 'organization/Event/Broadcasts/BroadcastsProvider'
import VerifiedEmailField from 'organization/Settings/CommunicationSettings/VerifiedEmailField'
import React from 'react'

export default function EmailInputs() {
  const {control, broadcast, isDisabled} = useBroadcastForm()

  if (broadcast && !isEmailBroadcast(broadcast)) {
    throw new Error(
      `Expected email broadcast, but received: ${broadcast.channel.name}`,
    )
  }

  return (
    <>
      <VerifiedEmailField
        disabled={isDisabled}
        ControllerProps={{
          name: 'channel.from_email',
          control,
        }}
        SelectProps={{
          label: 'From Email',
          'aria-label': 'from email',
        }}
        defaultFromEmail={broadcast?.channel.from_email}
        defaultFromName={broadcast?.channel.from_name}
      />
      <SubjectInput disabled={isDisabled} />
      <BodyInput disabled={isDisabled} />
    </>
  )
}

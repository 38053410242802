import React, {useEffect} from 'react'
import {useEvent} from 'Event/EventProvider'
import {api} from 'lib/url'
import {useTemplate} from 'Event/TemplateProvider'
import {components} from 'Event/template'
import {FieldErrors, useValidatedForm} from 'lib/form'
import {useState} from 'react'
import {UseFormMethods} from 'react-hook-form'
import {client, ValidationError} from 'lib/ui/api-client'
import {Attendee} from 'Event/attendee'
import {Redirect} from 'react-router'
import {eventRoutes} from 'Event/Routes'
import {useIsAttendee} from 'Event/auth'
import Scripts, {SIGN_UP} from 'Event/Scripts'

export interface SignUpData {
  email: string
  first_name: string
  last_name: string
}

export interface SignUpProps {
  onSubmit: () => void
  register: UseFormMethods['register']
  submitting: boolean
  errors: FieldErrors<SignUpData>
  responseError: ValidationError<SignUpData>
}

export default function SignUp() {
  const {event} = useEvent()
  const [attendee, setAttendee] = useState<Attendee | null>(null)

  const isAttendee = useIsAttendee()

  // Auto-login once an attendee has been created.
  useEffect(() => {
    if (!attendee) {
      return
    }

    window.location.href = attendee.login_url
  }, [attendee])

  const form = useSignUpForm(setAttendee)

  const {name} = useTemplate()
  const Component = components[name].SignUp.index

  const shouldRedirectToLogin = isAttendee && !event.sign_up_enabled
  if (shouldRedirectToLogin) {
    return <Redirect to={eventRoutes.login} />
  }

  return (
    <>
      <Scripts page={SIGN_UP} />
      <Component {...form} />
    </>
  )
}

export function useSignUpForm(
  setAttendee: (attendee: Attendee) => void,
): SignUpProps {
  const {event} = useEvent()
  const url = api(`/events/${event.id}/attendees/sign_up`)

  const {
    register,
    handleSubmit,
    errors,
    setResponseError,
    clearErrors,
    responseError,
  } = useValidatedForm<SignUpData>()

  const [submitting, setSubmitting] = useState(false)

  const signUp = (body: {
    email: string
    first_name: string
    last_name: string
  }) => client.post<Attendee>(url, body)

  const submit = (data: {
    email: string
    first_name: string
    last_name: string
  }) => {
    clearErrors()
    setSubmitting(true)
    signUp(data)
      .then(setAttendee)
      .catch(setResponseError)
      .finally(() => setSubmitting(false))
  }

  return {
    submitting,
    register,
    onSubmit: handleSubmit(submit),
    errors,
    responseError,
  }
}

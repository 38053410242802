import Typography from '@material-ui/core/Typography'
import styled from 'styled-components'
import React from 'react'
import {DragDropContext, Droppable} from 'react-beautiful-dnd'
import {useTownhallTemplate} from 'Event/template/Townhall'
import {HashMap, orderedIdsByPosition} from 'lib/list'
import {useEditMode} from 'Event/EditModeProvider'
import {useHandleDragSponsors} from 'organization/Event/SponsorsConfig'
import Card from 'Event/template/Townhall/Sponsors/SponsorList/Card'
import {VisibleSponsor} from 'Event/Sponsors'
import {Sponsor as BaseSponsor} from 'Event/Sponsors'
import Grid, {GridProps} from '@material-ui/core/Grid'

export type TownhallSponsor = BaseSponsor & {
  level?: number // Ability to have the different levels of sponsorships.  Different payment for different types of sponsors.
  logoSize?: number
  padding?: {
    top: number
    right: number
    bottom: number
    left: number
  }
}

export default function SponsorList() {
  const template = useTownhallTemplate()
  const {sponsors: settings} = template

  const isEditMode = useEditMode()
  const ids = orderedIdsByPosition(settings.items)

  const isEmpty = ids.length === 0
  if (isEmpty) {
    return <Typography align="center">No sponsor have been added</Typography>
  }

  if (!isEditMode) {
    return (
      <Box>
        <SponsorsPerRow level={1} md={12} sm={12} xs={12} />
        <SponsorsPerRow level={2} md={6} sm={6} xs={12} />
        <SponsorsPerRow level={3} md={4} sm={6} xs={12} />
        <SponsorsPerRow level={4} md={3} sm={6} xs={12} />
      </Box>
    )
  }

  return <DraggableList />
}

function DraggableList() {
  const template = useTownhallTemplate()
  const {sponsors: settings} = template

  const ids = orderedIdsByPosition(settings.items)

  const handleDrag = useHandleDragSponsors()

  const sponsors = ids.map((id, index) => (
    <Card index={index} key={id} id={id} sponsor={settings.items[id]} />
  ))

  return (
    <DragDropContext onDragEnd={handleDrag}>
      <Droppable droppableId="drag-and-drop-sponsors">
        {(provided) => (
          <Box ref={provided.innerRef} {...provided.droppableProps}>
            <>
              {sponsors}
              {provided.placeholder}
            </>
          </Box>
        )}
      </Droppable>
    </DragDropContext>
  )
}

function SponsorsPerRow(props: GridProps & {level: number}) {
  const template = useTownhallTemplate()
  const {sponsors: settings} = template

  const ids = orderedByLevel(settings.items).filter(
    (id) => settings.items[id].level === props.level,
  )

  const sponsors = ids.map((id, index) => (
    <VisibleSponsor key={id} sponsor={settings.items[id]}>
      <Grid item {...props}>
        <Card index={index} id={id} sponsor={settings.items[id]} />
      </Grid>
    </VisibleSponsor>
  ))

  return (
    <GridBoxContainer>
      <Grid container spacing={3}>
        {sponsors}
      </Grid>
    </GridBoxContainer>
  )
}

/**
 * Sort the sponsors by level, position.
 * @param items
 * @returns
 */
function orderedByLevel(items: HashMap<TownhallSponsor>) {
  return Object.entries(items)
    .sort(
      (
        [_aId, {position: aPosition, level: aLevel}],
        [_bId, {position: bPosition, level: bLevel}],
      ) => {
        if (!aLevel) {
          return 1
        }
        if (!bLevel) {
          return -1
        }

        if (aLevel === bLevel) {
          if (!aPosition) {
            return 1
          }

          if (!bPosition) {
            return -1
          }

          return aPosition - bPosition
        }

        return aLevel - bLevel
      },
    )
    .map(([id]) => id)
}

const Box = styled.div`
  width: 100%;
`
const GridBoxContainer = styled.div`
  margin-top: ${(props) => props.theme.spacing[3]};
`

import {useTemplate} from 'Event/TemplateProvider'
import {useSaveTemplate} from 'Event/TemplateUpdateProvider'
import TextField from 'lib/ui/TextField'
import {useConfig} from 'organization/Event/Configurable'
import Config from 'organization/Event/Configurable/Config'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import React from 'react'

export default function SignUpLinkConfig() {
  const {signUp} = useTemplate()
  const save = useSaveTemplate()
  const {
    form: {register},
  } = useConfig()

  return (
    <Config title="Sign up Link" onSave={save}>
      <SettingsPanel>
        <TextField
          name="signUp.linkLabel"
          defaultValue={signUp.linkLabel}
          label="Text"
          inputProps={{
            'aria-label': 'sign up link',
            ref: register,
          }}
        />
      </SettingsPanel>
    </Config>
  )
}

import {useUpdateTemplate} from 'organization/Marketplace/PurchasePageConfig/TemplateUpdateProvider'
import {useConfig} from 'organization/Event/Configurable'
import Config from 'organization/Event/Configurable/Config'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import StylingPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/StylingPanel'
import React, {useState} from 'react'
import {Controller} from 'react-hook-form'
import {onChangeCheckedHandler} from 'lib/dom'
import CollapsibleSection from 'lib/ui/ConfigPanel/CollapsibleSection'
import Switch from 'lib/ui/form/Switch'
import Slider from 'lib/ui/Slider'
import {Label} from 'lib/ui/typography'
import ColorPicker from 'lib/ui/ColorPicker'
import ImageAssetUploader from 'lib/asset/ImageAssetUploader'
import MarginPaddingInputs from 'lib/ui/MarginPaddingInputs'
import Select from 'lib/ui/Select'
import Option from 'lib/ui/Select/Option'
import {onUnknownChangeHandler} from 'lib/dom'
import {REMOVE} from 'Event/TemplateUpdateProvider'
import Visible from 'lib/ui/layout/Visible'
import TextField from 'lib/ui/TextField'
import VisibilitySetting from 'organization/Marketplace/PurchasePageConfig/BlockConfig/VisibilitySettings'
import {PurchaseFormBlock} from 'Event/Marketplace/Block/PurchaseForm'

interface PurchaseFormConfigProps extends PurchaseFormBlock {
  id: string
  sectionId: string
}

export default function PurchaseFormConfig(props: PurchaseFormConfigProps) {
  const {id, sectionId} = props
  const updateTemplate = useUpdateTemplate()
  const [visibility, setVisibility] = useState(props.visibility)
  const {
    form: {control, watch, register},
  } = useConfig()

  const phoneNumberEnabled = watch(
    `sections.${sectionId}.blocks.${id}.phoneNumber.enabled`,
    props.phoneNumber.enabled,
  )

  const handleRemove = () => {
    updateTemplate({
      sections: {
        [sectionId]: {
          blocks: {
            [id]: REMOVE,
          },
        },
      },
    })
  }

  const save = (data: any) => {
    data.sections[sectionId].blocks[id].visibility = visibility
    updateTemplate(data)
  }

  return (
    <Config title="Purchase Form Block" onSave={save} onRemove={handleRemove}>
      <SettingsPanel>
        <CollapsibleSection
          label="Data Collection"
          storageKey={`blocks-${id}-settings.dataCollection`}
        >
          <Controller
            name={`sections.${sectionId}.blocks.${id}.requireAddresses`}
            control={control}
            defaultValue={props.requireAddresses}
            render={({value, onChange}) => (
              <Switch
                checked={value}
                onChange={onChangeCheckedHandler(onChange)}
                arial-label="toggle requires addresses"
                labelPlacement="end"
                label="Collect Attendee addresses on purchase"
              />
            )}
          />
          <Controller
            name={`sections.${sectionId}.blocks.${id}.phoneNumber.enabled`}
            defaultValue={props.phoneNumber.enabled}
            control={control}
            render={({value, onChange}) => (
              <Switch
                checked={value}
                label="Phone number Field"
                labelPlacement="end"
                onChange={onChangeCheckedHandler(onChange)}
              />
            )}
          />
          <Visible when={phoneNumberEnabled}>
            <Controller
              name={`sections.${sectionId}.blocks.${id}.phoneNumber.required`}
              defaultValue={props.phoneNumber.required}
              control={control}
              render={({value, onChange}) => (
                <Switch
                  checked={value}
                  label="Phone number Required"
                  labelPlacement="end"
                  onChange={onChangeCheckedHandler(onChange)}
                />
              )}
            />
          </Visible>
          <TextField
            name={`sections.${sectionId}.blocks.${id}.errorMessage`}
            defaultValue={props.errorMessage}
            label="Error Message"
            inputProps={{
              ref: register,
              'aria-label': 'purchase form error message',
            }}
          />
        </CollapsibleSection>

        <CollapsibleSection
          label="Summary Placeholder Text"
          noDivider
          storageKey={`blocks-${id}-styling.summary.placeholderText`}
        >
          <TextField
            name={`sections.${sectionId}.blocks.${id}.summary.label.attendeeDetailsLabel`}
            defaultValue={props.summary.label.attendeeDetailsLabel}
            label="Attendee Header"
            inputProps={{
              'aria-label': 'attendeeHeader',
              ref: register,
            }}
          />

          <TextField
            name={`sections.${sectionId}.blocks.${id}.summary.label.billingAddressLabel`}
            defaultValue={props.summary.label.billingAddressLabel}
            label="Billing Address Header"
            inputProps={{
              'aria-label': 'billingAddressHeader',
              ref: register,
            }}
          />

          <TextField
            name={`sections.${sectionId}.blocks.${id}.summary.label.shippingAddressLabel`}
            defaultValue={props.summary.label.shippingAddressLabel}
            label="Shipping Address Header"
            inputProps={{
              'aria-label': 'shippingAddressHeader',
              ref: register,
            }}
          />

          <TextField
            name={`sections.${sectionId}.blocks.${id}.summary.label.purchaseLabel`}
            defaultValue={props.summary.label.purchaseLabel}
            label="Purchase Ticket Header"
            inputProps={{
              'aria-label': 'purchaseTicketHeader',
              ref: register,
            }}
          />

          <TextField
            name={`sections.${sectionId}.blocks.${id}.summary.label.totalLabel`}
            defaultValue={props.summary.label.totalLabel}
            label="Purchase Total Header"
            inputProps={{
              'aria-label': 'purchaseTotalHeader',
              ref: register,
            }}
          />
        </CollapsibleSection>

        <VisibilitySetting
          visibility={visibility}
          setVisibility={setVisibility}
        />
      </SettingsPanel>
      <StylingPanel>
        <CollapsibleSection
          label="Summary Headers Typography"
          storageKey={`blocks-${id}-summary-headers-typography`}
        >
          <Label>Font Size</Label>
          <Controller
            name={`sections.${sectionId}.blocks.${id}.summary.label.fontSize`}
            defaultValue={props.summary.label.fontSize}
            control={control}
            render={({value, onChange}) => (
              <Slider
                min={1}
                max={100}
                onChange={onChange}
                valueLabelDisplay="off"
                value={value}
              />
            )}
          />
          <Controller
            name={`sections.${sectionId}.blocks.${id}.summary.label.color`}
            defaultValue={props.summary.label.color}
            control={control}
            render={({value, onChange}) => (
              <ColorPicker label="Color" color={value} onPick={onChange} />
            )}
          />
        </CollapsibleSection>

        <CollapsibleSection
          label="Summary Text Typography"
          storageKey={`blocks-${id}-summary-text-typography`}
        >
          <Label>Font Size</Label>
          <Controller
            name={`sections.${sectionId}.blocks.${id}.summary.text.fontSize`}
            defaultValue={props.summary.text.fontSize}
            control={control}
            render={({value, onChange}) => (
              <Slider
                min={1}
                max={100}
                onChange={onChange}
                valueLabelDisplay="off"
                value={value}
              />
            )}
          />
          <Controller
            name={`sections.${sectionId}.blocks.${id}.summary.text.color`}
            defaultValue={props.summary.text.color}
            control={control}
            render={({value, onChange}) => (
              <ColorPicker label="Color" color={value} onPick={onChange} />
            )}
          />
        </CollapsibleSection>

        <CollapsibleSection
          label="Border"
          storageKey={`blocks-${id}-styling.border`}
        >
          <Controller
            name={`sections.${sectionId}.blocks.${id}.border.color`}
            defaultValue={props.border.color}
            control={control}
            render={({value, onChange}) => (
              <ColorPicker label="Color" color={value} onPick={onChange} />
            )}
          />
          <Controller
            name={`sections.${sectionId}.blocks.${id}.border.width`}
            defaultValue={props.border.width}
            control={control}
            render={({value, onChange}) => (
              <Slider
                min={0}
                max={50}
                step={1}
                onChange={onChange}
                value={value}
                label="Width"
              />
            )}
          />
          <Controller
            name={`sections.${sectionId}.blocks.${id}.border.radius`}
            defaultValue={props.border.radius}
            control={control}
            render={({value, onChange}) => (
              <Slider
                min={0}
                max={50}
                step={1}
                onChange={onChange}
                value={value}
                label="Radius"
              />
            )}
          />
        </CollapsibleSection>
        <CollapsibleSection
          label="Background"
          storageKey={`blocks-${id}-styling.background`}
        >
          <Controller
            name={`sections.${sectionId}.blocks.${id}.background.image`}
            control={control}
            defaultValue={props.background.image}
            render={({onChange, value}) => (
              <ImageAssetUploader
                onChange={onChange}
                value={value}
                uploadLabel="Upload"
                uploadInputProps={{
                  'aria-label': 'block background image',
                }}
                width={1920}
                height={1200}
                canResize
                disableAutoRemove
              />
            )}
          />
          <Controller
            name={`sections.${sectionId}.blocks.${id}.background.color`}
            defaultValue={props.background.color}
            control={control}
            render={({value, onChange}) => (
              <ColorPicker
                label="Color"
                color={value}
                onPick={onChange}
                aria-label="block background color"
              />
            )}
          />
          <Controller
            name={`sections.${sectionId}.blocks.${id}.background.opacity`}
            defaultValue={props.background.opacity}
            control={control}
            render={({value, onChange}) => (
              <Slider
                label="Opacity"
                unit="%"
                valueLabelDisplay="auto"
                aria-label="block background opacity"
                value={value}
                onChange={onChange}
                step={1}
                min={0}
                max={100}
              />
            )}
          />
        </CollapsibleSection>
        <CollapsibleSection
          label="Alignment"
          storageKey={`blocks-${id}-alignment`}
        >
          <Controller
            name={`sections.${sectionId}.blocks.${id}.alignment.vertical`}
            defaultValue={props.alignment.vertical}
            control={control}
            render={({value, onChange}) => (
              <Select
                fullWidth
                label="Vertical"
                value={value}
                onChange={onUnknownChangeHandler(onChange)}
              >
                <Option value="start">Top</Option>
                <Option value="center">Center</Option>
                <Option value="end">Bottom</Option>
              </Select>
            )}
          />
          <Controller
            name={`sections.${sectionId}.blocks.${id}.alignment.horizontal`}
            defaultValue={props.alignment.horizontal}
            control={control}
            render={({value, onChange}) => (
              <Select
                fullWidth
                label="Horizontal"
                value={value}
                onChange={onUnknownChangeHandler(onChange)}
              >
                <Option value="left">Left</Option>
                <Option value="center">Center</Option>
                <Option value="right">Right</Option>
              </Select>
            )}
          />
        </CollapsibleSection>

        <CollapsibleSection
          label="Advanced Spacing"
          storageKey={`blocks-${id}-styling.paddingControls`}
          open={false}
        >
          <Label>Padding</Label>
          <MarginPaddingInputs
            control={control}
            namePrefix={`sections.${sectionId}.blocks.${id}.padding`}
            values={props.padding}
          />
        </CollapsibleSection>
      </StylingPanel>
    </Config>
  )
}

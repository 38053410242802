import {useQueryParams} from 'lib/url'
import {useObvioUser} from 'obvio/auth'

export const INTERVAL_YEAR = 'year'
export const INTERVAL_MONTH = 'month'

export type BillingInterval = typeof INTERVAL_MONTH | typeof INTERVAL_YEAR

export const BASIC = 'basic'
export const PROFESSIONAL = 'professional'
export const PROFESSIONAL_EVENT_DISCOUNT = 'professional_event_discount'
export const ENTERPRISE = 'enterprise'
export const FOUNDER = 'founder'
export const LEAP = 'leap'

export const ADDON_KEY_ATTENDEES = 'attendees'
export const ADDON_KEY_ZOOM_MEETINGS = 'zoom_meetings'

export type PlanName =
  | typeof PROFESSIONAL
  | typeof PROFESSIONAL_EVENT_DISCOUNT
  | typeof BASIC
  | typeof ENTERPRISE
  | typeof FOUNDER
  | typeof LEAP

// Plan model as defined in API
export type Plan = {
  name: PlanName
  interval: BillingInterval
}

export interface DetailsData {
  displayName: string
  description: string
  transaction_description: string
}

export interface PlanAddOnDetails {
  block: number
  cost: number
  details: DetailsData
  duration: number | null
}

export type AddOn = typeof ADDON_KEY_ATTENDEES | typeof ADDON_KEY_ZOOM_MEETINGS

export type PlanAddOns = Record<AddOn, PlanAddOnDetails>

// Plan info used in the front-end only
export interface PlanInfo {
  name: PlanName
  label: string
  description: string
  priceYearly: number
  priceMonthly: number
  features: Feature[]
  addOns: PlanAddOns
}

export interface Feature {
  details: string
  isGood: boolean
  isActive: boolean
}

export interface AvailableAddOns {
  [ADDON_KEY_ATTENDEES]: DetailsData
  [ADDON_KEY_ZOOM_MEETINGS]: DetailsData
}

export const addOnDetails: AvailableAddOns = {
  [ADDON_KEY_ATTENDEES]: {
    displayName: 'Attendees',
    description:
      "Extra blocks of Attendees can be purchased to be used on a per-event basis to extend past your current plan's limit. Once a block of Attendees is assigned to an event, it can not be transferred to a different event.",
    transaction_description:
      '{{quantity}} block{{quantity_grammar}} of {{block_num}} Attendees at {{cost}} each',
  },
  [ADDON_KEY_ZOOM_MEETINGS]: {
    displayName: 'Extra Zoom Meetings (Open Rooms)',
    description:
      'A Zoom Meeting allows you to have an open room within your event. Any purchased Zoom Meetings can be used for rooms of any event within your organization, for the duration of the purchase.',
    transaction_description:
      '{{quantity}} extra Zoom Meeting{{quantity_grammar}} for {{duration}} day{{duration_grammar}} at {{cost}} each',
  },
}

export const BASIC_PLAN: PlanInfo = {
  name: BASIC,
  label: 'Obvio',
  description: '1 Organization, 3 Zoom Meetings per Event',
  priceYearly: 997,
  priceMonthly: 97,
  addOns: {
    [ADDON_KEY_ATTENDEES]: {
      block: 250,
      cost: 100,
      details: addOnDetails[ADDON_KEY_ATTENDEES],
      duration: null,
    },
    [ADDON_KEY_ZOOM_MEETINGS]: {
      block: 1,
      cost: 100,
      details: addOnDetails[ADDON_KEY_ZOOM_MEETINGS],
      duration: 30,
    },
  },
  features: [
    {
      details: 'Unlimited Events',
      isGood: true,
      isActive: true,
    },
    {
      details: 'Event Durations up to 4 Days',
      isGood: true,
      isActive: true,
    },
    {
      details: '1 Zoom Meeting per Event *',
      isGood: true,
      isActive: true,
    },
    {
      details: 'Cloud Based Event Recording',
      isGood: true,
      isActive: true,
    },
    {
      details: 'Unlimited Team Members',
      isGood: true,
      isActive: true,
    },
    {
      details: 'Includes 1 Organization',
      isGood: true,
      isActive: true,
    },
  ],
}

export const PROFESSIONAL_PLAN: PlanInfo = {
  name: PROFESSIONAL,
  label: 'Obvio Pro',
  description:
    'Unlimited events of up to 500 attendees, with no event duration limit.',
  priceYearly: 1997,
  priceMonthly: 197,
  addOns: {
    [ADDON_KEY_ATTENDEES]: {
      block: 250,
      cost: 100,
      details: addOnDetails[ADDON_KEY_ATTENDEES],
      duration: null,
    },
    [ADDON_KEY_ZOOM_MEETINGS]: {
      block: 1,
      cost: 100,
      details: addOnDetails[ADDON_KEY_ZOOM_MEETINGS],
      duration: 30,
    },
  },
  features: [
    {
      details: 'Includes All Obvio Features, Plus',
      isGood: true,
      isActive: true,
    },
    {
      details: 'Unlimited Event Duration',
      isGood: true,
      isActive: true,
    },
    {
      details: '5 Zoom Meetings per Event **',
      isGood: true,
      isActive: true,
    },
    {
      details: 'Includes 3 Organizations',
      isGood: true,
      isActive: true,
    },
  ],
}

export const PROFESSIONAL_EVENT_DISCOUNT_PLAN: PlanInfo = {
  ...PROFESSIONAL_PLAN,
  priceYearly: 997,
  priceMonthly: 83,
  name: PROFESSIONAL_EVENT_DISCOUNT,
  label: 'Obvio Pro (Event Discount)',
}

export const LEAP_PLAN: PlanInfo = {
  name: LEAP,
  label: 'Obvio LEAP',
  description: '3 Organizations, 10 Zoom Meetings per Event',
  priceYearly: 0,
  priceMonthly: 0,
  addOns: {
    [ADDON_KEY_ATTENDEES]: {
      block: 250,
      cost: 100,
      details: addOnDetails[ADDON_KEY_ATTENDEES],
      duration: null,
    },
    [ADDON_KEY_ZOOM_MEETINGS]: {
      block: 1,
      cost: 100,
      details: addOnDetails[ADDON_KEY_ZOOM_MEETINGS],
      duration: 30,
    },
  },
  features: [
    {
      details: 'Includes All Obvio Features, Plus',
      isGood: true,
      isActive: true,
    },
    {
      details: '10 Zoom Meetings per Event **',
      isGood: true,
      isActive: true,
    },
    {
      details: 'Includes 3 Organizations',
      isGood: true,
      isActive: true,
    },
  ],
}

export const ENTERPRISE_PLAN: PlanInfo = {
  name: ENTERPRISE,
  label: 'Obvio Enterprise',
  description: 'Unlimited Organizations, 25 Zoom Meetings per Event',
  priceYearly: 4997,
  priceMonthly: 497,
  addOns: {
    [ADDON_KEY_ATTENDEES]: {
      block: 250,
      cost: 50,
      details: addOnDetails[ADDON_KEY_ATTENDEES],
      duration: null,
    },
    [ADDON_KEY_ZOOM_MEETINGS]: {
      block: 1,
      cost: 50,
      details: addOnDetails[ADDON_KEY_ZOOM_MEETINGS],
      duration: 30,
    },
  },
  features: [
    {
      details: 'Includes All Obvio Pro Features Plus',
      isGood: true,
      isActive: true,
    },
    {
      details: '25 Zoom Meetings per Event *',
      isGood: true,
      isActive: true,
    },
    {
      details: 'Unlimited Organizations',
      isGood: true,
      isActive: true,
    },
  ],
}

export const FOUNDER_PLAN: PlanInfo = {
  name: FOUNDER,
  label: 'Obvio Founders',
  description: 'Unlimited Organizations, 3 Zoom Meetings per Event',
  priceYearly: 997,
  priceMonthly: 83,
  addOns: {
    [ADDON_KEY_ATTENDEES]: {
      block: 250,
      cost: 100,
      details: addOnDetails[ADDON_KEY_ATTENDEES],
      duration: null,
    },
    [ADDON_KEY_ZOOM_MEETINGS]: {
      block: 1,
      cost: 100,
      details: addOnDetails[ADDON_KEY_ZOOM_MEETINGS],
      duration: 30,
    },
  },
  features: [
    {
      details: 'Includes All Features of Obvio Plus',
      isGood: true,
      isActive: true,
    },
    {
      details: '3 Zoom Meetings per Event *',
      isGood: true,
      isActive: true,
    },
    {
      details: 'Unlimited Organizations',
      isGood: true,
      isActive: true,
    },
  ],
}

/**
 * Available plans. Order here matters to determine the plan
 * tier, and prevent downgrades. Cheapest first.
 */
export const PLANS = [
  BASIC_PLAN,
  FOUNDER_PLAN,
  PROFESSIONAL_EVENT_DISCOUNT_PLAN,
  PROFESSIONAL_PLAN,
  LEAP_PLAN,
  ENTERPRISE_PLAN,
]

export const isPlan = (name?: string): name is PlanName => {
  switch (name) {
    case BASIC:
    case PROFESSIONAL:
    case PROFESSIONAL_EVENT_DISCOUNT:
    case LEAP:
    case ENTERPRISE:
    case FOUNDER:
      return true
    default:
      return false
  }
}

export const isInterval = (interval?: string): interval is BillingInterval => {
  return interval === INTERVAL_MONTH || interval === INTERVAL_YEAR
}

export function useAvailablePlans() {
  const user = useObvioUser()

  // Iterate the array of PLANS to determine if they are "visible" to the current
  // user, based on business logic.
  return PLANS.filter((plan) => {
    // If the current plan is the FOUNDER_PLAN, we only want to show it to users
    // who are marked as founders, so the truthiness of the flag will determine
    // if it's visible.
    if (plan === FOUNDER_PLAN) {
      return user?.plan?.name === FOUNDER
    }

    // For the LEAP_PLAN, because it's an integration plan, it should ONLY be
    // visible to users who have been subscribed to it (via the integration). No
    // one else should be able to see it or subscribe to it. So if the user's
    // current plan is LEAP_PLAN, we can show it.
    if (plan === LEAP_PLAN) {
      return user?.plan?.name === LEAP
    }

    // Only want to show the Professional (Event Discount) plan if they're currently
    // subscribed to it.
    if (plan === PROFESSIONAL_EVENT_DISCOUNT_PLAN) {
      return user?.plan?.name === PROFESSIONAL_EVENT_DISCOUNT
    }

    // Only want to show the Professional plan as long as the current plan is
    // NOT the Event Discount version of it.
    if (plan === PROFESSIONAL_PLAN) {
      return user?.plan?.name !== PROFESSIONAL_EVENT_DISCOUNT
    }

    return true
  })
}

export const getPlan = (plan: PlanName) => {
  const target = PLANS.find((p) => p.name === plan)
  if (!target) {
    throw new Error(`Missing plan info for plan: ${plan}`)
  }

  return target
}

export const useGetPlanFromQueryParams = () => {
  const {plan} = useQueryParams()

  if (!plan) {
    return null
  }

  const planName = plan as PlanName

  return getPlan(planName) as PlanInfo
}

/**
 * Get the plan tier, an incrementing number indicating
 * the plan level. With 1 being the lowest plan.
 * @param plan
 * @returns
 */
export const tier = (plan: PlanName) => {
  const index = PLANS.findIndex((p) => p.name === plan)
  if (index === -1) {
    throw new Error(`Missing plan: ${plan}`)
  }

  return index + 1
}

export function useCanCreateOrganization() {
  const user = useObvioUser()

  return user.has_active_subscription
}

export function getIntervalDetails(
  plan: PlanInfo,
  interval?: string,
): [number, string] {
  if (interval && interval === INTERVAL_YEAR) {
    return [plan.priceYearly, 'annually']
  }

  return [plan.priceMonthly, 'monthly']
}

/**
 * Checks if a given string is a valid plan add-on key, such as 'attendees', or 'zoom_meetings'.
 *
 * @param key
 * @returns boolean
 */
export const isAddOn = (key: string): key is AddOn =>
  Object.prototype.hasOwnProperty.call(BASIC_PLAN.addOns, key)

import React from 'react'

import {QueryClient, QueryClientProvider} from 'react-query'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // Set stale time to forever to prevent re-fetching on re-render,
      // and auto-refresh in background. This is consistent with our
      // previous manual (Provider) approach where we have to
      // explicity re-fetch data.
      staleTime: Infinity,
    },
  },
})

export default function QueryProvider(props: {
  children: React.ReactNode
  queryClient?: QueryClient
}) {
  return (
    <QueryClientProvider client={props.queryClient ?? queryClient}>
      {props.children}
    </QueryClientProvider>
  )
}

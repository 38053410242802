import {useConfig} from 'organization/Event/Configurable'
import Config from 'organization/Event/Configurable/Config'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import {useUpdateTemplate} from 'organization/Marketplace/PurchasePageConfig/TemplateUpdateProvider'
import React, {useState} from 'react'
import {Controller} from 'react-hook-form'
import ColorPicker from 'lib/ui/ColorPicker'
import CollapsibleSection from 'lib/ui/ConfigPanel/CollapsibleSection'
import Slider from 'lib/ui/Slider'
import StylingPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/StylingPanel'
import Select from 'lib/ui/Select'
import Option from 'lib/ui/Select/Option'
import {onUnknownChangeHandler} from 'lib/dom'
import {REMOVE} from 'Event/TemplateUpdateProvider'
import VisibilitySetting from 'organization/Marketplace/PurchasePageConfig/BlockConfig/VisibilitySettings'
import LocalizedDateTimePicker from 'lib/LocalizedDateTimePicker'
import TextField from 'lib/ui/TextField'
import {Label} from 'lib/ui/typography'
import {NumberField} from 'lib/ui/TextField'
import {CountdownTimerBlock} from 'Event/Marketplace/Block/CountdownTimer'

interface CountdownTimerConfigProps extends CountdownTimerBlock {
  id: string
  sectionId: string
}

export default function CountdownTimerConfig(props: CountdownTimerConfigProps) {
  const {padding, alignment, id, sectionId, countdownTimer, background} = props
  const updateTemplate = useUpdateTemplate()
  const {
    form: {control},
  } = useConfig()
  const [visibility, setVisibility] = useState(props.visibility)

  const handleRemove = () => {
    updateTemplate({
      sections: {
        [sectionId]: {
          blocks: {
            [id]: REMOVE,
          },
        },
      },
    })
  }

  const save = (data: any) => {
    data.sections[sectionId].blocks[id].visibility = visibility
    updateTemplate(data)
  }

  return (
    <Config title="Countdown Timer Block" onSave={save} onRemove={handleRemove}>
      <SettingsPanel>
        <Controller
          name={`sections.${sectionId}.blocks.${id}.countdownTimer.end`}
          control={control}
          defaultValue={countdownTimer.end}
          render={({onChange, value}) => (
            <div>
              <Label>End</Label>
              <LocalizedDateTimePicker
                value={value}
                onChange={(date) => onChange(date?.toISOString())}
                fullWidth
                inputProps={{
                  'aria-label': 'end time',
                  onChange,
                }}
              />
            </div>
          )}
        />
        <Label>Description</Label>
        <Controller
          name={`sections.${sectionId}.blocks.${id}.countdownTimer.description`}
          control={control}
          defaultValue={countdownTimer.description}
          render={({value, onChange}) => (
            <TextField
              aria-label="description input"
              fullWidth
              onChange={onChange}
              value={value}
            />
          )}
        />
        <Label>Separator</Label>
        <Controller
          name={`sections.${sectionId}.blocks.${id}.countdownTimer.separator`}
          control={control}
          defaultValue={countdownTimer.separator}
          render={({value, onChange}) => (
            <TextField
              aria-label="separator input"
              fullWidth
              onChange={onChange}
              value={value}
            />
          )}
        />
        <Controller
          name={`sections.${sectionId}.blocks.${id}.countdownTimer.labels.days`}
          control={control}
          defaultValue={countdownTimer.labels.days}
          render={({value, onChange}) => (
            <TextField
              aria-label="days label input"
              fullWidth
              onChange={onChange}
              value={value}
              label="Days Label"
            />
          )}
        />
        <Controller
          name={`sections.${sectionId}.blocks.${id}.countdownTimer.labels.hours`}
          control={control}
          defaultValue={countdownTimer.labels.hours}
          render={({value, onChange}) => (
            <TextField
              aria-label="hours label input"
              fullWidth
              onChange={onChange}
              value={value}
              label="Hours Label"
            />
          )}
        />
        <Controller
          name={`sections.${sectionId}.blocks.${id}.countdownTimer.labels.minutes`}
          control={control}
          defaultValue={countdownTimer.labels.minutes}
          render={({value, onChange}) => (
            <TextField
              aria-label="minutes label input"
              fullWidth
              onChange={onChange}
              value={value}
              label="Minutes Label"
            />
          )}
        />
        <Controller
          name={`sections.${sectionId}.blocks.${id}.countdownTimer.labels.seconds`}
          control={control}
          defaultValue={countdownTimer.labels.seconds}
          render={({value, onChange}) => (
            <TextField
              aria-label="seconds label input"
              fullWidth
              onChange={onChange}
              value={value}
              label="Seconds Label"
            />
          )}
        />
        <VisibilitySetting
          visibility={visibility}
          setVisibility={setVisibility}
        />
      </SettingsPanel>
      <StylingPanel>
        <CollapsibleSection
          label="Block Background"
          storageKey={`blocks-${id}-background`}
        >
          <Controller
            name={`sections.${sectionId}.blocks.${id}.background.color`}
            control={control}
            defaultValue={background.color}
            render={({value, onChange}) => (
              <ColorPicker label="Color" color={value} onPick={onChange} />
            )}
          />
          <Controller
            name={`sections.${sectionId}.blocks.${id}.background.opacity`}
            defaultValue={background.opacity}
            control={control}
            render={({value, onChange}) => (
              <Slider
                label="Opacity"
                unit="%"
                valueLabelDisplay="auto"
                aria-label="block background opacity"
                value={value}
                onChange={onChange}
                step={1}
                min={0}
                max={100}
              />
            )}
          />
        </CollapsibleSection>

        <CollapsibleSection
          label="Timer Background"
          storageKey={`blocks-${id}-background`}
        >
          <Controller
            name={`sections.${sectionId}.blocks.${id}.countdownTimer.backgroundColor`}
            control={control}
            defaultValue={countdownTimer.backgroundColor}
            render={({value, onChange}) => (
              <ColorPicker label="Color" color={value} onPick={onChange} />
            )}
          />
          <Controller
            name={`sections.${sectionId}.blocks.${id}.countdownTimer.backgroundOpacity`}
            defaultValue={countdownTimer.backgroundOpacity}
            control={control}
            render={({value, onChange}) => (
              <Slider
                label="Opacity"
                unit="%"
                valueLabelDisplay="auto"
                aria-label="block background opacity"
                value={value}
                onChange={onChange}
                step={1}
                min={0}
                max={100}
              />
            )}
          />
        </CollapsibleSection>
        <CollapsibleSection
          label="Number Block"
          storageKey={`blocks-${id}-number-block`}
        >
          <Controller
            name={`sections.${sectionId}.blocks.${id}.countdownTimer.numberColor`}
            control={control}
            defaultValue={countdownTimer.numberColor}
            render={({value, onChange}) => (
              <ColorPicker label="Color" color={value} onPick={onChange} />
            )}
          />
          <Controller
            name={`sections.${sectionId}.blocks.${id}.countdownTimer.numberBackgroundColor`}
            control={control}
            defaultValue={countdownTimer.numberBackgroundColor}
            render={({value, onChange}) => (
              <ColorPicker
                label="Background Color"
                color={value}
                onPick={onChange}
              />
            )}
          />
          <Label>Background Opacity</Label>
          <Controller
            name={`sections.${sectionId}.blocks.${id}.countdownTimer.numberBackgroundOpacity`}
            defaultValue={countdownTimer.numberBackgroundOpacity}
            control={control}
            render={({value, onChange}) => (
              <Slider
                min={0}
                max={1}
                step={0.1}
                onChange={onChange}
                valueLabelDisplay="auto"
                value={value}
              />
            )}
          />
          <Label>Background Radius</Label>
          <Controller
            name={`sections.${sectionId}.blocks.${id}.countdownTimer.numberBackgroundRadius`}
            defaultValue={countdownTimer.numberBackgroundRadius}
            control={control}
            render={({value, onChange}) => (
              <Slider
                min={0}
                max={1}
                step={0.1}
                onChange={onChange}
                valueLabelDisplay="auto"
                value={value}
              />
            )}
          />
          <Controller
            name={`sections.${sectionId}.blocks.${id}.countdownTimer.numberBackgroundWidth`}
            control={control}
            defaultValue={countdownTimer.numberBackgroundWidth}
            render={({value, onChange}) => (
              <NumberField
                aria-label="number block width"
                fullWidth
                onChange={onChange}
                value={value}
                label="Width"
              />
            )}
          />
          <Controller
            name={`sections.${sectionId}.blocks.${id}.countdownTimer.numberBackgroundHeight`}
            control={control}
            defaultValue={countdownTimer.numberBackgroundHeight}
            render={({value, onChange}) => (
              <NumberField
                aria-label="number block height"
                fullWidth
                onChange={onChange}
                value={value}
                label="Height"
              />
            )}
          />
        </CollapsibleSection>
        <CollapsibleSection
          label="Description & Separator Text"
          storageKey={`blocks-${id}-description`}
        >
          <Controller
            name={`sections.${sectionId}.blocks.${id}.countdownTimer.textColor`}
            control={control}
            defaultValue={countdownTimer.textColor}
            render={({value, onChange}) => (
              <ColorPicker label="Color" color={value} onPick={onChange} />
            )}
          />
        </CollapsibleSection>
        <CollapsibleSection
          label="Alignment"
          storageKey={`blocks-${id}-alignment`}
        >
          <Controller
            name={`sections.${sectionId}.blocks.${id}.alignment.vertical`}
            defaultValue={alignment.vertical}
            control={control}
            render={({value, onChange}) => (
              <Select
                fullWidth
                label="Vertical"
                value={value}
                onChange={onUnknownChangeHandler(onChange)}
              >
                <Option value="start">Top</Option>
                <Option value="center">Center</Option>
                <Option value="end">Bottom</Option>
              </Select>
            )}
          />
          <Controller
            name={`sections.${sectionId}.blocks.${id}.alignment.horizontal`}
            defaultValue={alignment.horizontal}
            control={control}
            render={({value, onChange}) => (
              <Select
                fullWidth
                label="Horizontal"
                value={value}
                onChange={onUnknownChangeHandler(onChange)}
              >
                <Option value="left">Left</Option>
                <Option value="center">Center</Option>
                <Option value="right">Right</Option>
              </Select>
            )}
          />
        </CollapsibleSection>
        <CollapsibleSection
          label="Advanced Spacing"
          storageKey={`blocks-${id}-spacing`}
          open={false}
        >
          <Controller
            name={`sections.${sectionId}.blocks.${id}.padding.top`}
            defaultValue={padding.top}
            control={control}
            render={({value, onChange}) => (
              <Slider
                label="Top"
                value={value}
                onChange={onChange}
                step={1}
                min={0}
                max={100}
                aria-label="padding top"
              />
            )}
          />

          <Controller
            name={`sections.${sectionId}.blocks.${id}.padding.right`}
            defaultValue={padding.right}
            control={control}
            render={({value, onChange}) => (
              <Slider
                label="Right"
                value={value}
                onChange={onChange}
                step={1}
                min={0}
                max={100}
                aria-label="padding right"
              />
            )}
          />
          <Controller
            name={`sections.${sectionId}.blocks.${id}.padding.bottom`}
            defaultValue={padding.bottom}
            control={control}
            render={({value, onChange}) => (
              <Slider
                label="Bottom"
                value={value}
                onChange={onChange}
                step={1}
                min={0}
                max={100}
                aria-label="padding bottom"
              />
            )}
          />
          <Controller
            name={`sections.${sectionId}.blocks.${id}.padding.left`}
            defaultValue={padding.left}
            control={control}
            render={({value, onChange}) => (
              <Slider
                label="Left"
                value={value}
                onChange={onChange}
                step={1}
                min={0}
                max={100}
                aria-label="padding left"
              />
            )}
          />
        </CollapsibleSection>
      </StylingPanel>
    </Config>
  )
}

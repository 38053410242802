import React from 'react'
import {useTemplate} from 'Event/TemplateProvider'
import {useSaveTemplate} from 'Event/TemplateUpdateProvider'
import TextField from 'lib/ui/TextField'
import {useConfig} from 'organization/Event/Configurable'
import Config from 'organization/Event/Configurable/Config'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'

export default function ErrorMessageConfig() {
  const save = useSaveTemplate()
  const {signUp} = useTemplate()

  const {
    form: {register},
  } = useConfig()

  return (
    <Config title="Error Message" onSave={save}>
      <SettingsPanel>
        <TextField
          name="signUp.error.emailAlreadyTakenMessage"
          defaultValue={signUp.error.emailAlreadyTakenMessage}
          label="Email already taken error"
          inputProps={{
            'aria-label': 'email already taken error message',
            ref: register,
          }}
        />
      </SettingsPanel>
    </Config>
  )
}

import React from 'react'
import {useAttendee} from 'Event/auth'
import {useTrackEventPage} from 'analytics'
import {useTemplate} from 'Event/TemplateProvider'
import {User} from 'auth/user'
import {components} from 'Event/template'
import Scripts, {ZOOM_BACKGROUNDS} from 'Event/Scripts'

export default function Backgrounds() {
  const attendee = useAttendee()

  useTrackEventPage({
    page: 'Visited Zoom Backgrounds',
  })

  return (
    <>
      <Scripts page={ZOOM_BACKGROUNDS} />
      <TemplateBackgrounds user={attendee} />
    </>
  )
}

export function TemplateBackgrounds(props: {user: User}) {
  const template = useTemplate()
  const Component = components[template.name].Backgrounds.index

  return <Component user={props.user} />
}

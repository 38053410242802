import React from 'react'
import {Cards, useCardsTemplate, useCardsUpdate} from 'Event/template/Cards'
import ComponentConfig, {
  ComponentConfigProps,
  SaveButton,
  Footer,
} from 'organization/Event/DashboardConfig/ComponentConfigPanel'
import {useForm} from 'react-hook-form'
import Settings from 'Event/template/Cards/Dashboard/Sidebar/SidebarContainer/Settings'
import Styling from 'Event/template/Cards/Dashboard/Sidebar/SidebarContainer/Styling'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import StylingPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/StylingPanel'
import {
  useAutoUpdate,
  useTemplateEditor,
} from 'organization/Event/TemplateEditor'
import {usePruneAssets} from 'lib/asset'

export function SidebarContainerConfig(props: ComponentConfigProps) {
  const {showing, onClose} = props

  const {sidebar, textColor} = useCardsTemplate()
  const update = useCardsUpdate()
  const pruneAssets = usePruneAssets()
  const {saved} = useTemplateEditor<Cards>()

  const {control, handleSubmit, watch, formState} = useForm()

  const save = (data: Cards['sidebar']) => {
    const backgroundImage = data.backgroundImage
    pruneAssets({backgroundImage}, saved.sidebar)

    update({
      sidebar: data,
    })
    onClose()
  }
  useAutoUpdate({values: {sidebar: watch()}, when: showing})

  return (
    <ComponentConfig
      showing={showing}
      onClose={onClose}
      title="Sidebar"
      onSubmit={handleSubmit(save)}
      hasChanges={formState.isDirty}
    >
      <SettingsPanel>
        <Settings control={control} sidebar={sidebar} />
      </SettingsPanel>
      <StylingPanel>
        <Styling control={control} sidebar={sidebar} textColor={textColor} />
      </StylingPanel>
      <Footer>
        <SaveButton type="submit" />
      </Footer>
    </ComponentConfig>
  )
}

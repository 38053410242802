import React from 'react'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import RedirectAddForm from 'organization/Event/Redirects/AddDialog/RedirectAddForm'
import {RedirectLink} from 'Event/Redirects/CustomRedirect'
import Dialog from 'lib/ui/Dialog'

export default function RedirectsAddDialog(props: {
  isOpen: boolean
  onClose: () => void
  onAdd: (redirect: RedirectLink) => void
  description?: string
  destination?: string
  copyToClipboard?: boolean
}) {
  const visible = Boolean(props.isOpen)
  return (
    <Dialog open={visible} onClose={props.onClose} fullWidth>
      <DialogTitle>Add Redirect</DialogTitle>
      <DialogContent>
        <RedirectAddForm {...props} />
      </DialogContent>
    </Dialog>
  )
}

import React from 'react'
import Page from 'Event/template/Panels/Page'
import LeftPanel from 'Event/template/Panels/check-in/LeftPanel'
import RightPanel from 'Event/template/Panels/check-in/RightPanel'
import MobilePanel from 'Event/template/Panels/check-in/MobilePanel'
import Body from 'Event/Step3/TechCheck/Body'
import Buttons from 'Event/Step3/TechCheck/Buttons'
import AdditionalContent from 'Event/Step3/TechCheck/AdditionalContent'
import ConfigFields from 'organization/Event/TechCheckConfig/ConfigFields'
import {usePanelsTemplate} from 'Event/template/Panels'
import {DarkBackgroundTheme} from 'lib/ui/theme/ThemeProvider'

export default function TechCheck() {
  return (
    <Page
      Left={<LeftPanel step={3} />}
      Right={
        <RightPanel>
          <Content />
        </RightPanel>
      }
      Mobile={
        <MobilePanel step={3}>
          <Content />
        </MobilePanel>
      }
    />
  )
}

function Content() {
  const template = usePanelsTemplate()
  const {checkInRightPanel} = template
  const {backgroundColor, backgroundOpacity} = checkInRightPanel

  return (
    <DarkBackgroundTheme color={backgroundColor} opacity={backgroundOpacity}>
      <div>
        <ConfigFields />
        <Body />
        <Buttons />
        <AdditionalContent />
      </div>
    </DarkBackgroundTheme>
  )
}

import {useAttendeeVariables} from 'Event'
import {useEditMode} from 'Event/EditModeProvider'
import {NavMenuItemProps, useTownhallTemplate} from 'Event/template/Townhall'
import {RelativeLink} from 'lib/ui/link/RelativeLink'
import React from 'react'
import Tab from 'Event/template/Townhall/Dashboard/NavBar/LinkTabs/Tab'
import {BlogSectionConfig} from 'Event/template/Townhall/Dashboard/Main/BlogSection/BlogSectionConfig'
import {AgendaSectionConfig} from 'Event/template/Townhall/Dashboard/Main/AgendaSection/AgendaSectionConfig'
import {ResourceListConfig} from 'Event/template/Townhall/Dashboard/Main/ResourceSection/ResourceListConfig'
import Configurable from 'organization/Event/Configurable'
import FeaturePageMenuItemConfig from 'Event/template/Townhall/Dashboard/NavBar/LinkTabs/FeaturePageMenuItemConfig'
import {CountdownTimerSectionConfig} from 'Event/template/Townhall/Dashboard/Main/CountdownTimerSection/CountdownTimerSectionConfig'
import {COUNTDOWN_TIMER} from 'Event/template/Townhall/Dashboard/Main/CountdownTimerSection'

interface TabMenuItemProps {
  menuItem: NavMenuItemProps
  id: string
  index: number
  onClick: (id: string) => void
  active?: boolean
}

export default function TabMenuItem(props: TabMenuItemProps) {
  const {menuItem, id, index, onClick, active} = props
  const v = useAttendeeVariables()
  const isEditMode = useEditMode()

  if (menuItem.link && !isEditMode) {
    return (
      <RelativeLink to={menuItem.link} disableStyles key={id}>
        <Tab label={v(menuItem.title)} value={id} />
      </RelativeLink>
    )
  }

  return (
    <Configurable key={id}>
      <Config sectionId={id} />
      <Tab
        index={index}
        label={v(menuItem.title)}
        value={id}
        active={active}
        onClick={() => onClick(id)}
      />
    </Configurable>
  )
}

function Config(props: {sectionId: string}) {
  const {sectionId} = props
  const {
    dashboardSections,
    navBar: {menuItems},
  } = useTownhallTemplate()

  const menuItem = menuItems[sectionId]
  if (menuItem.link) {
    return <FeaturePageMenuItemConfig id={sectionId} />
  }

  const section = dashboardSections[sectionId]
  const sectionName = section?.name

  switch (sectionName) {
    case 'blog':
      return <BlogSectionConfig blogSection={section} sectionId={sectionId} />
    case 'agenda':
      return (
        <AgendaSectionConfig agendaSection={section} sectionId={sectionId} />
      )
    case 'resource':
      return <ResourceListConfig section={section} id={sectionId} />
    case COUNTDOWN_TIMER:
      return <CountdownTimerSectionConfig section={section} id={sectionId} />
    default:
      return null
  }
}

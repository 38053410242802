import {useTownhallTemplate} from 'Event/template/Townhall'
import React from 'react'
import {Controller} from 'react-hook-form'
import Switch from 'lib/ui/form/Switch'
import {onChangeCheckedHandler} from 'lib/dom'
import {useConfig} from 'organization/Event/Configurable'
import Select from 'lib/ui/Select'
import Option from 'lib/ui/Select/Option'
import {onUnknownChangeHandler} from 'lib/dom'

export default function Settings() {
  const template = useTownhallTemplate()

  const {
    form: {control},
  } = useConfig()
  return (
    <>
      <Controller
        name="actionNav.enabled"
        control={control}
        defaultValue={template.actionNav.enabled}
        render={({value, onChange}) => (
          <Switch
            checked={value}
            onChange={onChangeCheckedHandler(onChange)}
            arial-label="config dashboard pane placement"
            label="Background Visible"
            labelPlacement="end"
          />
        )}
      />
      <Controller
        name="actionNav.position"
        defaultValue={template.actionNav.position}
        control={control}
        render={({value, onChange}) => (
          <Select
            fullWidth
            label="Position"
            value={value}
            onChange={onUnknownChangeHandler(onChange)}
          >
            <Option value="left">Left</Option>
            <Option value="right">Right</Option>
          </Select>
        )}
      />
    </>
  )
}

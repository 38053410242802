import {useStates} from 'lib/event-api/countries/use-states'
import {usePrevious} from 'lib/state'
import DefaultSelect, {SelectProps} from 'lib/ui/Select'
import DefaultOption, {OptionProps} from 'lib/ui/Select/Option'
import React, {useEffect} from 'react'

export default function StateSelect(
  props: SelectProps & {
    countryId: number | null
    Select?: React.FC<SelectProps>
    Option?: React.FC<OptionProps>
    defaultLabel?: string
  },
) {
  const {
    Select = DefaultSelect,
    Option = DefaultOption,
    countryId,
    defaultLabel,
    ...selectProps
  } = props

  const {value, onChange} = selectProps

  const {data, isLoading: loading} = useStates(countryId)

  const prevCountryId = usePrevious(countryId)
  const didChangeCountry = countryId !== prevCountryId

  const states = data || []

  // Reset State whenever the country changes
  useEffect(() => {
    if (!didChangeCountry) {
      return
    }

    if (!value) {
      return
    }

    onChange(0)
  }, [didChangeCountry, onChange, value])

  return (
    <Select
      aria-label="state"
      {...selectProps}
      disabled={loading || props.disabled}
    >
      <Option value={0} disabled>
        {defaultLabel ?? 'State'}
      </Option>

      {states.map((s) => (
        <Option value={s.id} key={s.id}>
          {s.name}
        </Option>
      ))}
    </Select>
  )
}

import styled from 'styled-components'
import Page from 'organization/Event/Page'
import React, {useEffect} from 'react'
import Forms from 'organization/Event/FormsConfig/Forms'
import CreateFormDialog from 'organization/Event/FormsConfig/CreateFormDialog'
import {useToggle} from 'lib/toggle'
import PageHeader from 'lib/ui/PageHeader'
import Title from 'lib/ui/PageHeader/Title'
import NewItemButton from 'lib/ui/PageHeader/NewItemButton'
import PageBreadcrumbs from 'organization/Event/Page/PageBreadcrumbs'
import Button from 'lib/ui/Button'
import {api} from 'lib/url'
import {useOrganization} from 'organization/OrganizationProvider'
import {useGet} from 'lib/requests'
import {useEvent} from 'Event/EventProvider'
import ContextAlert, {
  createAlertContext,
  useAlertContext,
} from 'lib/ui/alerts/ContextAlert'

export const FormsAlert = createAlertContext()

export default function FormsConfig() {
  const {flag: showingAddDialog, toggle: toggleAddDialog} = useToggle()
  const alertContext = useAlertContext()

  return (
    <FormsAlert.Provider value={alertContext}>
      <PageBreadcrumbs page="Forms">
        <Page>
          <ContextAlert context={alertContext} canClose />
          <PageHeader>
            <Title text="Forms" />
            <ButtonContainer>
              <ExportAllSubmissions />
              <NewItemButton
                text="New Form"
                aria-label="add form"
                onClick={toggleAddDialog}
              />
            </ButtonContainer>
          </PageHeader>
          <CreateFormDialog
            isVisible={showingAddDialog}
            onClose={toggleAddDialog}
          />
          <Forms />
        </Page>
      </PageBreadcrumbs>
    </FormsAlert.Provider>
  )
}

function ExportAllSubmissions() {
  const {event} = useEvent()
  const {
    exportSubmissions,
    errorMessage,
    successMessage,
    processing,
  } = useAllSubmissionsExport(`/events/${event.id}/all_form_submissions/export`)

  const {setAlert} = FormsAlert.useAlert()

  useEffect(() => {
    if (processing) {
      return
    }

    if (successMessage) {
      setAlert({message: successMessage, severity: 'info'})
    }

    if (errorMessage) {
      setAlert({message: errorMessage, severity: 'error'})
    }
  }, [processing, errorMessage, successMessage, setAlert])

  return (
    <ExportBox>
      <Button
        onClick={exportSubmissions}
        variant="outlined"
        color="primary"
        aria-label="export all submissions"
        disabled={processing}
      >
        Download All Submissions
      </Button>
    </ExportBox>
  )
}

function useAllSubmissionsExport(endpoint: string) {
  const url = api(endpoint)
  const {client} = useOrganization()

  const {
    send: exportSubmissions,
    processing,
    errorMessage,
    successMessage,
  } = useGet(client, url)

  return {
    exportSubmissions,
    errorMessage,
    successMessage,
    processing,
  }
}

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${(props) => props.theme.spacing[2]};
`

const ExportBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing[1]};
`

import {defaults, Template} from 'Event/template'
import {withDefaults} from 'lib/object'
import {DeepRequired} from 'lib/type-utils'
import React, {useMemo} from 'react'
import {fillCountDownTimerDefaults} from 'Event/Dashboard/components/CountDownTimer'
import {fillBlogPostDefaults} from 'Event/Dashboard/components/BlogPosts'

const TemplateContext = React.createContext<DeepRequired<Template> | undefined>(
  undefined,
)

export default function TemplateProvider(props: {
  children: React.ReactNode
  template: Template
}) {
  const {template} = props

  /**
   * Fill out the template with any missing defaults
   */
  const filled = useMemo(() => {
    const updated = withDefaults(defaults(template.name), template)

    // Fill out any defaults for countdown timers
    updated.countDownTimers = fillCountDownTimerDefaults(
      updated.countDownTimers,
    )

    updated.blogPosts = fillBlogPostDefaults(updated.blogPosts, template.name)

    return updated
  }, [template])

  return (
    <TemplateContext.Provider value={filled}>
      {props.children}
    </TemplateContext.Provider>
  )
}

export function useTemplate() {
  const context = React.useContext(TemplateContext)
  if (context === undefined) {
    throw new Error('useTemplate must be used within a TemplateProvider')
  }

  return context
}

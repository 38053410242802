import {TitleBlock} from 'Event/Marketplace/Block/Title'

export const createTitleBlock = (): TitleBlock => ({
  type: 'Title',
  visibility: 'all',
  background: {
    image: '',
    color: '',
    opacity: 0,
  },
  padding: {
    top: 10,
    right: 10,
    bottom: 10,
    left: 10,
  },
  alignment: {
    vertical: 'center',
    horizontal: 'center',
  },
  border: {
    width: 0,
    color: '#FFFFFF',
    radius: 0,
  },
  text: 'Title',
  font: null,
  fontSize: 60,
  color: '#3490DC',
  opacity: 100,
  lineHeight: 110,
  letterSpacing: 0,
})

import React from 'react'
import Box from '@material-ui/core/Box'
import DangerButton from 'lib/ui/Button/DangerButton'
import ConfirmDialog from 'lib/ui/ConfirmDialog'
import {useArea} from 'organization/Event/Area/AreaProvider'
import {EVENT_SETTINGS, usePermissions} from 'organization/PermissionsProvider'
import {useHistory} from 'react-router'
import {useEventRoutes} from 'organization/Event/EventRoutes'

export default function DeleteAreaButton() {
  const {processing} = useArea()
  const deleteArea = useDeleteArea()

  const {can} = usePermissions()
  if (!can(EVENT_SETTINGS)) {
    return null
  }

  return (
    <Box mb={2}>
      <ConfirmDialog
        onConfirm={deleteArea}
        description="Deleting this Area will not only delete the Area, but also any Rooms and statistics associated with the Area and Room(s). This can NOT be undone. Are you sure you wish to delete it?"
      >
        {(confirm) => (
          <Box mb={2}>
            <DangerButton
              variant="outlined"
              disabled={processing}
              onClick={confirm}
              aria-label="delete area"
              size="small"
            >
              Delete
            </DangerButton>
          </Box>
        )}
      </ConfirmDialog>
    </Box>
  )
}

function useDeleteArea() {
  const {deleteArea} = useArea()
  const history = useHistory()
  const eventRoutes = useEventRoutes()

  const goBackToAreas = () => {
    history.push(eventRoutes.areas.root)
  }

  return () => {
    deleteArea().then(() => {
      goBackToAreas()
    })
  }
}

import {useAsync} from 'lib/async'
import {api} from 'lib/url'
import {teamMemberClient} from 'obvio/obvio-client'
import {Organization} from 'organization'
import React, {useEffect, useState, useCallback} from 'react'
import {sendRequest} from 'obvio/Organizations/CreateOrganizationForm'
import {useObvioUser} from 'obvio/auth'

interface UserOrganizationsContextProps {
  organizations: Organization[]
  loading: boolean
}

const UserOrganizationsContext = React.createContext<
  UserOrganizationsContextProps | undefined
>(undefined)

export default function UserOrganizationsProvider(props: {
  children: React.ReactNode
}) {
  const fetched = useFetchOrganizations()
  const [organizations, setOrganizations] = useState<Organization[] | null>(
    null,
  )

  const {
    has_active_subscription: isSubscribed,
    first_name,
    last_name,
  } = useObvioUser()

  const newOrganizationName = `${first_name} ${last_name}'s Organization`

  const createOrganization = useCallback(() => {
    const orgData = {
      name: newOrganizationName,
      logo: null,
    }

    sendRequest(orgData)
      .then((data) => {
        setOrganizations([data])
      })
      .catch((error) => {
        console.error(error)
      })
  }, [newOrganizationName])

  // Auto-create an organization if a user is subscribed, and they
  // haven't created on already.
  useEffect(() => {
    if (fetched === null) {
      return
    }

    const shouldAutoCreateOrganization = isSubscribed && fetched.length === 0
    if (shouldAutoCreateOrganization) {
      createOrganization()
      return
    }

    setOrganizations(fetched)
  }, [fetched, createOrganization, isSubscribed])

  return (
    <UserOrganizationsContext.Provider
      value={{
        loading: !organizations,
        organizations: organizations || [],
      }}
    >
      {props.children}
    </UserOrganizationsContext.Provider>
  )
}

export function useUserOrganizations() {
  const context = React.useContext(UserOrganizationsContext)
  if (context === undefined) {
    throw new Error(
      `useUserOrganizations must be used within an UserOrganizationsProvier`,
    )
  }

  return context
}

function useFetchOrganizations() {
  const request = useCallback(() => {
    const url = api('/organizations')
    return teamMemberClient.get<Organization[]>(url)
  }, [])

  return useAsync(request).data
}

import {useSaveTemplate} from 'Event/TemplateUpdateProvider'
import {useConfig} from 'organization/Event/Configurable'
import Config from 'organization/Event/Configurable/Config'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import React from 'react'
import {useConfirmedButton} from 'Event/Dashboard/components/NavButton/ConfirmedButton'
import {Controller} from 'react-hook-form'
import Title from 'lib/ui/ConfigPanel/Title'
import TextEditor, {TextEditorContainer} from 'lib/ui/form/TextEditor'

export const DEFAULT_DESCRIPTION =
  '<p style="text-align: center;">Are you sure?</p>'

export default function DescriptionConfig() {
  const save = useSaveTemplate()
  const {key, button} = useConfirmedButton()

  const {
    form: {control},
  } = useConfig()

  return (
    <Config title="Description" onSave={save}>
      <SettingsPanel>
        <Title>Description</Title>
        <Controller
          name={`${key}.confirmDescription`}
          defaultValue={button.confirmDescription || DEFAULT_DESCRIPTION}
          control={control}
          render={({onChange, value}) => (
            <TextEditorContainer>
              <TextEditor data={value} onChange={onChange} />
            </TextEditorContainer>
          )}
        />
      </SettingsPanel>
    </Config>
  )
}

import React from 'react'
import Bar from 'lib/ui/ProgressBar'
import Configurable from 'organization/Event/Configurable'
import {SimpleBlog, useSimpleBlogTemplate} from 'Event/template/SimpleBlog'
import ProgressBarConfig from 'Event/template/SimpleBlog/check-in/ProgressBar/ProgressBarConfig'

export type ProgressBarProps = {
  textKey: keyof SimpleBlog['progressBar'] &
    ('step1Text' | 'step2Text' | 'step3Text')
  percentKey: keyof SimpleBlog['progressBar'] &
    ('step1Percent' | 'step2Percent' | 'step3Percent')
}

export default function ProgressBar(props: ProgressBarProps) {
  const {textKey, percentKey} = props
  const {progressBar} = useSimpleBlogTemplate()

  return (
    <Configurable>
      <ProgressBarConfig {...props} />
      <Bar
        aria-label="check in progress"
        showing={progressBar.showing}
        text={progressBar[textKey]}
        value={progressBar[percentKey]}
        barColor={progressBar.barColor}
        backgroundColor={progressBar.backgroundColor}
        textColor={progressBar.textColor}
        borderRadius={progressBar.borderRadius}
        thickness={progressBar.thickness}
      />
    </Configurable>
  )
}

import {useAsync} from 'lib/async'
import FullPageLoader from 'lib/ui/layout/FullPageLoader'
import React, {useCallback, useEffect, useState} from 'react'
import {useOrganization} from 'organization/OrganizationProvider'
import {api} from 'lib/url'

export interface OrganizationEmail {
  name: string
  email: string
  verified: boolean
  id: number
}

export interface EmailContextProps {
  add: (email: OrganizationEmail) => void
  remove: (email: OrganizationEmail) => void
  emails: OrganizationEmail[]
  verifiedEmails: OrganizationEmail[]
}

export const EmailContext = React.createContext<EmailContextProps | undefined>(
  undefined,
)

export default function EmailsProvider(props: {children: React.ReactElement}) {
  const fetch = useFetchEmails()
  const {data: saved, loading} = useAsync(fetch)

  const [emails, setEmails] = useState<OrganizationEmail[]>([])
  const [verifiedEmails, setVerifiedEmails] = useState<OrganizationEmail[]>([])

  useEffect(() => {
    if (!saved) {
      return
    }

    massageEmails(saved)
  }, [saved])

  const massageEmails = (emails: OrganizationEmail[]) => {
    setEmails(emails)
    setVerifiedEmails(emails.filter((e) => e.verified))
  }

  const add = (email: OrganizationEmail) => {
    const appended = [...emails, email]
    massageEmails(appended)
  }

  const remove = useCallback(
    (email: OrganizationEmail) => {
      const removed = emails.filter((a) => a.id !== email.id)
      massageEmails(removed)
    },
    [emails],
  )

  if (loading || !emails) {
    return <FullPageLoader />
  }

  return (
    <EmailContext.Provider
      value={{
        add,
        remove,
        emails,
        verifiedEmails,
      }}
    >
      {props.children}
    </EmailContext.Provider>
  )
}

export function useFetchEmails() {
  const {client, organization} = useOrganization()

  return useCallback(() => {
    const url = api(`/organizations/${organization.id}/emails`)
    return client.get<OrganizationEmail[]>(url)
  }, [client, organization])
}

export function useEmails() {
  const context = React.useContext(EmailContext)
  if (context === undefined) {
    throw new Error('useEmails must be used within an EmailsProvider')
  }

  return context
}

import React from 'react'
import {useDropzone, DropzoneOptions} from 'react-dropzone'
import styled from 'styled-components'
import Paper from '@material-ui/core/Paper'

/**
 * List of Document file types.
 */
export const DOCUMENTS = ['image/*', '.pdf', '.xlsx']

/**
 * List of Media file types.
 */
export const MEDIA = ['.mp3', '.mp4', '.mov']

const DEFAULT_MAX_FILE_SIZE_BYTES = 150000000 // bytes

export default function UploadDropzone(props: {
  options?: Omit<DropzoneOptions, 'onDrop'>
  onDrop: (acceptedFile: File) => void
  'aria-label'?: string
}) {
  const handleDrop = (files: File[]) => {
    props.onDrop(files[0]) // Should only receive one file
  }

  const {getRootProps, getInputProps} = useDropzone({
    onDrop: handleDrop,
    accept: DOCUMENTS,
    maxSize: DEFAULT_MAX_FILE_SIZE_BYTES,
    maxFiles: 1,
    multiple: false,
    ...props.options,
  })
  const {ref: _, ...rootProps} = getRootProps()

  return (
    <PaperDropzone {...rootProps}>
      <input
        {...getInputProps()}
        aria-label={props['aria-label'] || 'upload single file'}
      />
      <p>Drop a file here or click to upload</p>
    </PaperDropzone>
  )
}

const PaperDropzone = styled(Paper)`
  cursor: pointer;
  margin-bottom: ${(props) => props.theme.spacing[6]};
  padding: ${(props) => props.theme.spacing[6]};
  display: flex;
  align-items: center;
  justify-content: center;

  > p {
    margin: 0;
  }
`

import styled from 'styled-components'
import {ToggleButton, ToggleButtonGroup} from '@material-ui/lab'
import {onChangeCheckedHandler, onChangeDate} from 'lib/dom'
import {FieldErrors, useValidatedForm} from 'lib/form'
import LocalizedDateTimePicker from 'lib/LocalizedDateTimePicker'
import {useToggleArray} from 'lib/toggle'
import ErrorAlert from 'lib/ui/alerts/ErrorAlert'
import Checkbox from 'lib/ui/Checkbox'
import InfoTooltip from 'lib/ui/ConfigPanel/InfoTooltip'
import Visible from 'lib/ui/layout/Visible'
import Select from 'lib/ui/Select'
import Option from 'lib/ui/Select/Option'
import TextField, {NumberField} from 'lib/ui/TextField'
import React, {useEffect, useState} from 'react'
import {Controller, UseFormMethods} from 'react-hook-form'
import Button from 'lib/ui/Button'
import InputLabel from 'lib/ui/TextField/InputLabel'
import ChannelInputs from 'organization/Event/Broadcasts/BroadcastForm/ChannelInputs'
import {
  Broadcast,
  BroadcastFiltered,
  ChannelName,
} from 'organization/Event/Broadcasts/BroadcastsProvider'
import FormControl from 'lib/ui/FormControl'
import {inFiveMinutes} from 'lib/date-time'
import {useHasOrganizationFeatureToggle} from 'organization/OrganizationFeatureToggle'
import {SMS} from 'auth/user/flags'
import Box from '@material-ui/core/Box'
import Switch from 'lib/ui/form/Switch'
import {NAME_EMAIL_DELIMITER} from 'organization/Settings/CommunicationSettings/VerifiedEmailField'
import AttendeeFilterInput from 'organization/Event/Broadcasts/BroadcastForm/AttendeeFilterInput'
import {ValidationError} from 'lib/ui/api-client'
import NumAttendeesSummary from 'organization/Event/Broadcasts/BroadcastForm/NumAttendeesSummary'
import SaveButton from 'organization/Event/Broadcasts/BroadcastForm/SaveButton'
import AgreeToSmsChargesCheckbox from 'organization/Event/Broadcasts/BroadcastForm/AgreeToSmsChargesCheckbox'
import SaveCreditCardForm from 'lib/stripe/SaveCreditCardForm'
import {useAddPaymentMethod} from 'lib/event-api/organization/payment_methods/add'
import ComponentConfig from 'organization/Event/DashboardConfig/ConfigDialog'
import ConfirmUnsavedChangesDialog from 'organization/Event/Configurable/ConfirmUnsavedChangesDialog'

export type Payload = Omit<Broadcast, 'id'>

type BroadcastFormValues = Omit<Broadcast, 'channel'> & {
  channel: Broadcast['channel']
  filter_query?: BroadcastFiltered['filter_query']
}

type BroadcastFormContextProps = {
  register: UseFormMethods['register']
  control: UseFormMethods['control']
  watch: UseFormMethods<BroadcastFormValues>['watch']
  channel: ChannelName
  broadcast?: Broadcast
  isDisabled: boolean
  errors: FieldErrors<BroadcastFormValues>
  readOnly: boolean
  shouldDistribute: boolean
  toggleDistributionInput: () => void
  responseError?: ValidationError<BroadcastFormValues>
  setResponseError: (error: any) => void
  attendeeQuery: string
  hasAgreedToSmsCharges: boolean
  setHasAgreedToSmsCharges: (agree: boolean) => void
  paymentMethodId: string | null
  setPaymentMethodId: (id: string | null) => void
  showingCreditCardForm: boolean
  toggleCreditCardForm: () => void
  handleSubmit: any
}

const BroadcastFormContext = React.createContext<
  undefined | BroadcastFormContextProps
>(undefined)

export default function BroadcastForm(props: {
  broadcast?: Broadcast
  readOnly?: boolean
  title: string
  showing: boolean
  onSubmit: (data: Payload) => Promise<unknown>
  onClose: () => void
}) {
  const {showing, title, broadcast, readOnly = false, onSubmit} = props
  const [submitting, toggleSubmitting] = useToggleArray()
  const shouldDistributeInit = Boolean(broadcast?.distribution_minutes)
  const [shouldDistribute, toggleDistributionInput] = useToggleArray(
    shouldDistributeInit,
  )
  const [paymentMethodId, setPaymentMethodId] = useState<string | null>(null)
  const [showingCreditCardForm, toggleCreditCardForm] = useToggleArray()
  const [hasAgreedToSmsCharges, setHasAgreedToSmsCharges] = useState(false)
  const [
    showingUnsavedChangesDialog,
    setShowingUnsavedChangesDialog,
  ] = useState(false)

  const {
    handleSubmit,
    errors,
    register,
    control,
    responseError,
    setResponseError,
    watch,
    formState: {isDirty},
  } = useValidatedForm<BroadcastFormValues>({
    defaultValues: {
      name: broadcast?.name,
      send_at: broadcast?.send_at || inFiveMinutes(),
      distribution_minutes: broadcast?.distribution_minutes,
      attendee_segment: broadcast?.attendee_segment ?? 'all',
      channel: {
        name: broadcast?.channel.name || 'email',
      },
    },
  })

  const {mutate: addPaymentMethod} = useAddPaymentMethod()

  const createPayload = (form: Payload): Payload => {
    const base: Payload = {
      ...form,
      payment_method_id: paymentMethodId,
      distribution_minutes: shouldDistribute ? form.distribution_minutes : 0,
    }

    // If we've selected a Verified Email, the value from the picker
    // will be a concatenated string: 'mike|mike@obv.io', so we'll
    // need to split the values here.
    if (base.channel.name === 'email') {
      const selectedEmail = base.channel.from_email || ''
      const [from_name, from_email] = selectedEmail.split(NAME_EMAIL_DELIMITER)

      return {
        ...base,
        channel: {
          ...base.channel,
          from_name,
          from_email,
        },
      }
    }

    return base
  }

  const submit = (form: Payload) => {
    if (submitting) {
      return
    }

    setShowingUnsavedChangesDialog(false)
    toggleSubmitting()

    const data = createPayload(form)

    onSubmit(data).catch((e) => {
      setResponseError(e)
      toggleSubmitting()
    })
  }

  const isDisabled = submitting || readOnly

  const channel = watch('channel.name') ?? broadcast?.channel.name ?? 'email'
  const validChannel: ChannelName =
    channel === 'email' || channel === 'sms' ? channel : 'email'

  const attendeeQuery = () => {
    const segment = watch('attendee_segment')
    const filter_query = watch('filter_query')

    if (segment === 'checked_in') {
      return `?checked_in_status=checked_in`
    }

    if (segment === 'not_checked_in') {
      return `?checked_in_status=not_checked_in`
    }

    if (segment === 'all') {
      return ''
    }

    if (filter_query) {
      return `?${filter_query}`
    }

    return ''
  }

  const onClose = () => {
    if (isDirty) {
      setShowingUnsavedChangesDialog(true)
    } else {
      props.onClose()
    }
  }

  useEffect(() => {
    if (!showing) {
      setShowingUnsavedChangesDialog(false)
    }
  }, [showing])

  return (
    <BroadcastFormContext.Provider
      value={{
        control,
        register,
        channel: validChannel,
        watch,
        broadcast,
        isDisabled,
        errors,
        readOnly,
        shouldDistribute,
        toggleDistributionInput,
        responseError,
        setResponseError,
        attendeeQuery: attendeeQuery(),
        hasAgreedToSmsCharges,
        setHasAgreedToSmsCharges,
        paymentMethodId,
        setPaymentMethodId,
        showingCreditCardForm,
        toggleCreditCardForm,
        handleSubmit,
      }}
    >
      <ComponentConfig isVisible={showing} onClose={onClose} title={title}>
        <ConfirmUnsavedChangesDialog
          onClose={props.onClose}
          open={showingUnsavedChangesDialog}
          onSubmit={handleSubmit(submit)}
        />
        <>
          {showingCreditCardForm && (
            <StyledSaveCreditCardForm
              onSuccess={(paymentMethodId: string) => {
                addPaymentMethod(
                  {payment_method_id: paymentMethodId},
                  {
                    onSuccess: () => {
                      toggleCreditCardForm()
                      setPaymentMethodId(paymentMethodId)
                    },
                    onError: (e: any) => {
                      setResponseError(e)
                      toggleCreditCardForm()
                    },
                  },
                )
              }}
              submitLabel={'Save'}
            />
          )}
          <form onSubmit={handleSubmit(submit)}>
            <Content />
          </form>
        </>
      </ComponentConfig>
    </BroadcastFormContext.Provider>
  )
}

function Content() {
  const [showingFilterInputs, setShowingFilterInputs] = useState(false)
  const toggleFilterInputs = () => setShowingFilterInputs((showing) => !showing)

  const {
    control,
    watch,
    broadcast,
    errors,
    isDisabled,
    register,
    readOnly,
    shouldDistribute,
    toggleDistributionInput,
    responseError,
    showingCreditCardForm,
  } = useBroadcastForm()

  const hasSmsChannelOptions = useHasOrganizationFeatureToggle(SMS)

  const isDraft = watch('is_draft', broadcast?.is_draft || true)

  const attendeeSegment = watch('attendee_segment')
  const isFiltered = attendeeSegment === 'filtered'

  useEffect(() => {
    if (!isFiltered) {
      setShowingFilterInputs(false)
    }
  }, [isFiltered])

  return (
    <>
      <BroadcastFormContent showing={showingCreditCardForm === false}>
        <Box mb={2}>
          <Controller
            name="is_draft"
            control={control}
            defaultValue={isDraft}
            render={({value, onChange}) => (
              <Switch
                checked={!value} // reverse to show Published in right
                onChange={onChangeCheckedHandler((val) => {
                  onChange(!val) // reverse to show Published in right
                })}
                disabled={isDisabled}
                aria-label="toggle draft status"
                labelPlacement="end"
                label={isDraft ? 'Draft' : 'Publish'}
              />
            )}
          />
        </Box>

        <TextField
          label="Name"
          name="name"
          defaultValue={broadcast?.name}
          fullWidth
          variant="outlined"
          disabled={isDisabled}
          inputProps={{
            ref: register,
            'aria-label': 'name',
          }}
          error={!!errors.name}
          helperText={errors.name}
        />
        <Controller
          name="send_at"
          control={control}
          defaultValue={broadcast?.send_at || inFiveMinutes()}
          render={({onChange, value}) => (
            <LocalizedDateTimePicker
              label="Send at"
              value={value}
              onChange={onChangeDate(onChange)}
              fullWidth
              disabled={isDisabled}
              error={!!errors.send_at}
              helperText={errors.send_at}
              inputVariant={'outlined'}
              inputProps={{
                'aria-label': 'send at time',
              }}
            />
          )}
        />
        <DistributionToggle>
          <Checkbox
            label="Distribute Over Time"
            disabled={isDisabled}
            onChange={toggleDistributionInput}
            checked={shouldDistribute}
          />
          <InfoTooltip>
            Distributing your broadcast over time will split the broadcast into
            equal parts. As an example, if you enter 60 minutes, part 1 will be
            sent at the time the broadcast is set to send, part 2 will be sent
            20 minutes later, and part 3 will be sent 20 minutes after that.
          </InfoTooltip>
        </DistributionToggle>
        <Visible when={shouldDistribute}>
          <NumberField
            name="distribution_minutes"
            label="Distribution Minutes"
            defaultValue={broadcast?.distribution_minutes}
            error={!!errors.distribution_minutes}
            helperText={errors.distribution_minutes}
            variant="outlined"
            disabled={isDisabled}
            inputProps={{
              'aria-label': 'distribution minutes',
              ref: register,
            }}
          />
        </Visible>
        <SegmentInput>
          <InputLabel>Send to</InputLabel>
          <Controller
            name="attendee_segment"
            control={control}
            render={({value, onChange}) => (
              <FormControl>
                <ToggleButtonGroup value={value}>
                  <ToggleButton value="all" onClick={() => onChange('all')}>
                    All
                  </ToggleButton>
                  <ToggleButton
                    value="checked_in"
                    onClick={() => onChange('checked_in')}
                  >
                    Checked-in Users
                  </ToggleButton>
                  <ToggleButton
                    value="not_checked_in"
                    onClick={() => onChange('not_checked_in')}
                  >
                    Not Checked-in Users
                  </ToggleButton>
                  <ToggleButton
                    value="filtered"
                    onClick={() => onChange('filtered')}
                  >
                    Filtered
                  </ToggleButton>
                </ToggleButtonGroup>
              </FormControl>
            )}
          />
        </SegmentInput>

        <EditFilterButton
          variant="outlined"
          onClick={toggleFilterInputs}
          showing={isFiltered}
        >
          {showingFilterInputs ? 'Hide filters' : 'Edit filters'}
        </EditFilterButton>

        <AttendeeFilterInput
          showing={showingFilterInputs}
          onDone={toggleFilterInputs}
        />
        <Controller
          name="channel.name"
          control={control}
          defaultValue={broadcast?.channel.name || 'email'}
          render={({onChange, value}) => (
            <div>
              <Select
                onChange={onChange}
                value={value}
                disabled={isDisabled || !!broadcast}
                fullWidth
                label="Channel"
                aria-label="channel select"
              >
                <Option value="email">Email</Option>
                {hasSmsChannelOptions && <Option value="sms">SMS</Option>}
              </Select>
            </div>
          )}
        />
        <ChannelInputs />
        <NumAttendeesSummary />
        <AgreeToSmsChargesCheckbox />
        <ErrorAlert>{responseError?.message}</ErrorAlert>
        <SaveButton readOnly={readOnly} disabled={isDisabled} />
      </BroadcastFormContent>
    </>
  )
}

export function useBroadcastForm() {
  const context = React.useContext(BroadcastFormContext)
  if (context === undefined) {
    throw new Error('useBroadcastForm must be used within a BroadcastForm')
  }

  return context
}

const DistributionToggle = styled.div`
  display: flex;
  margin-bottom: ${(props) => props.theme.spacing[3]};
`

const SegmentInput = styled.div`
  margin-bottom: ${(props) => props.theme.spacing[6]};
`

const EditFilterButton = styled(Button)<{
  showing?: boolean
}>`
  display: ${(props) => (props.showing ? 'block' : 'none')};
  margin-bottom: ${(props) => props.theme.spacing[6]};
`

const StyledSaveCreditCardForm = styled(SaveCreditCardForm)`
  display: flex;
  justify-content: center;
  margin-bottom: ${(props) => props.theme.spacing[6]};

  > form {
    width: 100%;
  }
`

const BroadcastFormContent = styled.div<{showing: boolean}>`
  display: ${(props) => (props.showing ? 'block' : 'none')};
`

import React from 'react'
import styled from 'styled-components'
import Typography from '@material-ui/core/Typography'
import {Speaker} from 'Event/Speakers'
import {useAttendeeVariables} from 'Event'
import Content from 'lib/ui/form/TextEditor/Content'
import Configurable from 'organization/Event/Configurable'
import SpeakerNameConfig from 'Event/Speakers/SpeakerConfig'
import SpeakerConfig from 'Event/Speakers/SpeakerConfig'

export default function Body(props: {speaker: Speaker; id: string}) {
  const {speaker} = props
  const v = useAttendeeVariables()

  return (
    <div>
      <Box>
        <Configurable>
          <SpeakerNameConfig speaker={props.speaker} id={props.id} />
          <Typography variant="h5">{v(speaker.name)}</Typography>
        </Configurable>
      </Box>
      <Configurable>
        <SpeakerConfig speaker={props.speaker} id={props.id} />
        <Content>{v(speaker.text)}</Content>
      </Configurable>
    </div>
  )
}

const Box = styled.div`
  display: flex;
  justify-content: space-between;
`

import React from 'react'
import styled from 'styled-components'
import Slide from '@material-ui/core/Slide'
import Sidebar from 'Event/template/Lefty/Dashboard/Sidebar'
import IconButton from 'lib/ui/IconButton'
import {Icon} from 'lib/fontawesome/Icon'
import SidebarContainer from 'Event/template/Lefty/Dashboard/Sidebar/SidebarContainer'
import {useLeftyTemplate} from '../..'

export default function MobileSidebar(props: {
  visible: boolean
  setVisible: (flag: boolean) => void
}) {
  const {visible, setVisible} = props
  const toggleMenu = () => setVisible(!visible)

  return (
    <StyledDiv>
      <MobileSidebarShowButton onClick={toggleMenu} visible={!visible} />
      <Slide direction="left" in={visible} mountOnEnter unmountOnExit>
        <div>
          <SidebarContainer
            backOnMobile={<MobileSidebarHideButton onClick={toggleMenu} />}
          >
            <Sidebar />
          </SidebarContainer>
        </div>
      </Slide>
    </StyledDiv>
  )
}

function MobileSidebarShowButton(props: {
  onClick: () => void
  visible: boolean
}) {
  const {
    sidebar: {mobile},
  } = useLeftyTemplate()

  if (!props.visible) {
    return null
  }

  return (
    <ButtonBox backgroundColor={mobile.icon.background}>
      <IconButton onClick={props.onClick}>
        <ArrowIcon
          iconClass={`fa-solid fa-arrow-right`}
          color={mobile.icon.color}
        />
      </IconButton>
    </ButtonBox>
  )
}

function MobileSidebarHideButton(props: {onClick: () => void}) {
  const {
    sidebar: {mobile},
  } = useLeftyTemplate()

  return (
    <ButtonBox backgroundColor={mobile.icon.background}>
      <IconButton onClick={props.onClick}>
        <ArrowIcon
          iconClass={`fa-solid fa-arrow-left`}
          color={mobile.icon.color}
        />
      </IconButton>
    </ButtonBox>
  )
}

const ArrowIcon = styled(Icon)<{
  color: string
}>`
  color: ${(props) => props.color};
  font-size: 18px;
  margin: 0 2px;
`

const ButtonBox = styled.div<{backgroundColor: string}>`
  border-radius: ${(props) => props.theme.spacing[1]};
  box-shadow: 1px 1px 1px #ddd;
  padding: ${(props) => props.theme.spacing[3]}
    ${(props) => props.theme.spacing[4]};
  position: fixed;
  display: inline-flex;
  background-color: ${(props) => props.backgroundColor || '#FFFFFF'};
  bottom: 23px;
  z-index: 2;
`

const StyledDiv = styled.div`
  position: absolute;
`

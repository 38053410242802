import Typography from '@material-ui/core/Typography'
import styled from 'styled-components'
import Card from 'Event/template/Cards/Sponsors/SponsorList/Card'
import React from 'react'
import {DragDropContext, Droppable} from 'react-beautiful-dnd'
import {useCardsTemplate} from 'Event/template/Cards'
import {orderedIdsByPosition} from 'lib/list'
import {useEditMode} from 'Event/EditModeProvider'
import {useHandleDragSponsors} from 'organization/Event/SponsorsConfig'
import {VisibleSponsor} from 'Event/Sponsors'

export default function SponsorList() {
  const {sponsors: settings} = useCardsTemplate()
  const isEditMode = useEditMode()

  const ids = orderedIdsByPosition(settings.items)
  const isEmpty = ids.length === 0
  if (isEmpty) {
    return <Typography align="center">No sponsor have been added</Typography>
  }

  const sponsors = ids.map((id, index) => (
    <VisibleSponsor key={id} sponsor={settings.items[id]}>
      <Card
        key={id}
        index={index}
        id={id}
        sponsor={settings.items[id]}
        isLast={index === ids.length - 1}
      />
    </VisibleSponsor>
  ))

  if (!isEditMode) {
    return <Box>{sponsors}</Box>
  }

  return <DraggableList>{sponsors}</DraggableList>
}

function DraggableList(props: {
  className?: string
  children: React.ReactNode[]
}) {
  const handleDrag = useHandleDragSponsors()

  return (
    <DragDropContext onDragEnd={handleDrag}>
      <Droppable droppableId="drag-and-drop-sponsors">
        {(provided) => (
          <Box
            ref={provided.innerRef}
            {...provided.droppableProps}
            className={props.className}
          >
            <>
              {props.children}
              {provided.placeholder}
            </>
          </Box>
        )}
      </Droppable>
    </DragDropContext>
  )
}
const Box = styled.div`
  width: 100%;
`

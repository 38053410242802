import React from 'react'
import styled from 'styled-components'
import {Controller, UseFormMethods} from 'react-hook-form'
import {NavButtonWithSize} from 'Event/Dashboard/components/NavButton'
import BackgroundPicker from 'lib/ui/form/BackgroundPicker'
import CollapsibleSection from 'lib/ui/ConfigPanel/CollapsibleSection'
import Visible from 'lib/ui/layout/Visible'
import Checkbox from 'lib/ui/Checkbox'

export default function Background(props: {
  control: UseFormMethods['control']
  watch: UseFormMethods['watch']
  button: NavButtonWithSize
}) {
  const {control, button, watch} = props

  const showingHoverBackgroundInput = !watch(
    'disableHover',
    button.disableHover,
  )

  return (
    <CollapsibleSection
      label="Background"
      storageKey="blogPostButton.config.styling.typography"
    >
      <Controller
        name="backgroundColor"
        defaultValue={button.backgroundColor || ''}
        control={control}
        render={({value, onChange}) => (
          <BackgroundPicker
            label="Background"
            background={value}
            onChange={onChange}
          />
        )}
      />
      <Controller
        name="disableHover"
        control={control}
        defaultValue={button.disableHover || false}
        render={({value, onChange}) => (
          <StyledCheckBox
            label="Customize Hover Background"
            // Because we're toggling 'enable' here, but the prop is a 'disable', we need
            // to invert the values here.
            checked={!value}
            onChange={(val) => onChange(!val)}
            aria-label="toggle custom hover background"
          />
        )}
      />
      <Visible when={showingHoverBackgroundInput}>
        <Controller
          name="hoverBackgroundColor"
          control={control}
          defaultValue={button.hoverBackgroundColor || ''}
          render={({value, onChange}) => (
            <BackgroundPicker
              label="Hover Background"
              background={value}
              onChange={onChange}
            />
          )}
        />
      </Visible>
    </CollapsibleSection>
  )
}

const StyledCheckBox = styled(Checkbox)`
  margin-bottom: ${(props) => props.theme.spacing[4]};
`

import {assignProps} from 'lib/react-utils'
import {Permission, usePermissions} from 'organization/PermissionsProvider'
import React from 'react'

export default function HasPermission(props: {
  required: Permission | Permission[]
  children: JSX.Element | JSX.Element[]
}) {
  const {children, required, ...forwardedProps} = props

  const hasAny = useHasAny()

  // In case this is used in a HOC, let's forward props along to all
  // children
  const content = assignProps(forwardedProps, children)

  // Check if we have any of the required ones.
  if (hasAny(required)) {
    return <>{content}</>
  }

  // Missing required permission(s), return nothing.
  return null
}

export function useHasAny() {
  const {can} = usePermissions()
  return (permissions: Permission | Permission[]) => {
    const targets = Array.isArray(permissions) ? permissions : [permissions]
    return targets.some(can)
  }
}

import {api} from 'lib/url'
import {Organization} from 'organization'

const baseUrl = process.env.REACT_APP_MARKETPLACE_API_URL

/**
 * Create the full URL for a Marketplace API request. Marketplace requests are currently
 * proxied via the Event API for authorization.
 *
 * @param organization
 * @param endpoint
 * @returns string
 */
export const marketplaceProxy = (
  organization: Organization,
  endpoint: string,
) => api(`/organizations/${organization.id}/marketplace${endpoint}`)

export const marketplaceApi = (endpoint: string) => `${baseUrl}${endpoint}`

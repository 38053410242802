import React from 'react'
import Button, {ButtonProps} from 'lib/ui/Button'
import styled from 'styled-components'
import {HashMap, createPositions, orderedIdsByPosition} from 'lib/list'
import {useUpdateTemplate} from 'organization/Marketplace/PurchasePageConfig/TemplateUpdateProvider'
import {uuid} from 'lib/uuid'
import {
  DragDropContext,
  Draggable,
  DropResult,
  Droppable,
} from 'react-beautiful-dnd'
import {DragHandle, DraggableOverlay} from 'lib/ui/drag-and-drop'
import {Editable} from 'Event/Dashboard/editor/views/EditComponent'
import {useToggle} from 'lib/toggle'
import {ConfigContext} from 'organization/Event/Configurable'
import {useForm} from 'react-hook-form'
import {Box} from '@material-ui/core'
import {
  ListItem,
  NumberItem,
  NumberedListBlock,
  NumberedListContainer,
  createNumberItem,
} from 'Event/Marketplace/Block/NumberedList'
import NumberedListItemConfig from 'organization/Marketplace/PurchasePageConfig/BlockConfig/NumberedListItemConfig'
import {BlockComponentProps} from 'Event/Marketplace/Block'
import {useConfigurableSection} from 'organization/Marketplace/PurchasePageConfig/ConfigurableSection'

interface AddNumberButtonProps extends Omit<ButtonProps, 'children'> {}

interface ConfigurableNumberedListProps
  extends NumberedListBlock,
    BlockComponentProps {}

export default function ConfigurableNumberedList(
  props: ConfigurableNumberedListProps,
) {
  const update = useUpdateTemplate()
  const {id: blockId} = props
  const {sectionId} = useConfigurableSection()

  const addNewNumber = () => {
    if (!blockId || !sectionId) {
      return null
    }

    const newNumber = createNumberItem()
    const newId = uuid()

    update({
      sections: {
        [sectionId]: {
          blocks: {
            [blockId]: {
              items: {
                [newId]: newNumber,
              },
            },
          },
        },
      },
    })
  }

  return (
    <NumberedListContainer {...props}>
      <NumberItems sectionId={sectionId} blockId={blockId} {...props} />
      <AddNumberButton onClick={addNewNumber} />
    </NumberedListContainer>
  )
}

function useHandleDrag(props: {
  items: HashMap<NumberItem>
  blockId?: string
  sectionId?: string
}) {
  const {items, blockId, sectionId} = props
  const update = useUpdateTemplate()

  return (result: DropResult) => {
    const {destination, source} = result

    if (!blockId || !sectionId || !destination) {
      return
    }

    const ids = orderedIdsByPosition(items)
    const [removed] = ids.splice(source.index, 1)
    ids.splice(destination.index, 0, removed)

    update({
      sections: {
        [sectionId]: {
          blocks: {
            [blockId]: {
              items: createPositions(ids),
            },
          },
        },
      },
    })
  }
}

function NumberItems(
  props: NumberedListBlock & {
    blockId?: string
    sectionId?: string
  },
) {
  const {items} = props
  const handleDrag = useHandleDrag(props)

  if (!items) {
    return null
  }

  const sortedIds = orderedIdsByPosition(items)

  return (
    <>
      <DragDropContext onDragEnd={handleDrag}>
        <Droppable droppableId="drag-and-drop-number-list">
          {(provided) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              <>
                {sortedIds.map((id, index: number) => {
                  return (
                    <DraggableListItem
                      item={items[id]}
                      key={id}
                      itemId={id}
                      index={index}
                      {...props}
                    />
                  )
                })}
                {provided.placeholder}
              </>
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </>
  )
}

function DraggableListItem(
  props: NumberedListBlock & {
    item: NumberItem
    index: number
    itemId: string
    sectionId?: string
    blockId?: string
  },
) {
  const {index, itemId, item, sectionId, blockId} = props
  const {flag: showingConfig, toggle: toggleConfig} = useToggle()
  const form = useForm()

  if (!sectionId || !blockId) {
    return null
  }

  return (
    <>
      <ConfigContext.Provider
        value={{showing: showingConfig, toggle: toggleConfig, form}}
      >
        <NumberedListItemConfig
          isVisible={showingConfig}
          onClose={toggleConfig}
          list={item}
          itemId={itemId}
          sectionId={sectionId}
          blockId={blockId}
        />
        <Draggable draggableId={itemId} index={index}>
          {(provided) => (
            <div ref={provided.innerRef} {...provided.draggableProps}>
              <DraggableOverlay>
                <Editable
                  aria-label="edit Numbered list item"
                  onEdit={toggleConfig}
                  key={index}
                >
                  <>
                    <DragHandle handleProps={provided.dragHandleProps} />
                    <ListItem number={index} {...props} item={item} />
                  </>
                </Editable>
              </DraggableOverlay>
            </div>
          )}
        </Draggable>
      </ConfigContext.Provider>
    </>
  )
}

function AddNumberButton(props: AddNumberButtonProps) {
  return (
    <Box>
      <StyledAddButton
        size="small"
        variant="outlined"
        color="primary"
        fullWidth
        onClick={props.onClick}
      >
        Add Item
      </StyledAddButton>
    </Box>
  )
}

const StyledAddButton = styled(Button)`
  background: ${(props) => props.theme.colors.gray};
`

import EmailConfig, {
  PURCHASE_CONFIRMATION,
} from 'organization/Event/EmailConfig'
import React from 'react'

export default function PurchaseConfirmationConfig() {
  return (
    <EmailConfig
      type={PURCHASE_CONFIRMATION}
      title="Purchase Confirmation Email"
      label="purchase confirmation email"
      customLinks={['Login']}
    />
  )
}

import {useLeftyTemplate} from 'Event/template/Lefty'
import styled from 'styled-components'
import React from 'react'
import {useGuestVariables} from 'Event'
import Configurable from 'organization/Event/Configurable'
import TitleConfig from 'Event/template/Lefty/Login/Title/TitleConfig'

export default function Title() {
  const {
    login: {
      welcome: {text},
    },
  } = useLeftyTemplate()
  const v = useGuestVariables()

  return (
    <Configurable>
      <TitleConfig />
      <TitleBase aria-label="event login title">{v(text)}</TitleBase>
    </Configurable>
  )
}

export function TitleBase(props: {
  children: string | JSX.Element
  'aria-label'?: string
  className?: string
}) {
  const {
    login: {
      welcome: {color, fontSize},
    },
    textColor,
  } = useLeftyTemplate()

  if (!props.children) {
    return null
  }

  return (
    <TitleText
      textColor={textColor}
      titleTextColor={color}
      fontSize={fontSize}
      aria-label={props['aria-label']}
      className={props.className}
    >
      {props.children}
    </TitleText>
  )
}

export const TitleText = styled.div<{
  textColor: string
  titleTextColor?: string | null
  fontSize: number
}>`
  color: ${(props) =>
    props.titleTextColor ? props.titleTextColor : props.textColor} !important;
  font-size: ${(props) => props.fontSize}px;
  font-weight: 500;
  margin-bottom: ${(props) => props.theme.spacing[4]};
  text-align: center;
`

import React from 'react'
import styled from 'styled-components'

export default function Actions(props) {
  if (!props.isVisible) {
    return null
  }

  return <Box>{props.children}</Box>
}

const Box = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

import {GroupRule} from 'Event/attendee-rules/RuleConfig/RuleList/SingleRule/GroupRule'
import {NestedRule} from 'Event/attendee-rules/RuleConfig/RuleList/SingleRule/NestedRule'
import {TagsRule} from 'Event/attendee-rules/RuleConfig/RuleList/SingleRule/TagsRule'
import {FormResponseRule} from 'Event/attendee-rules/RuleConfig/RuleList/SingleRule/FormResponseRule'
import {TimeRule} from 'Event/attendee-rules/RuleConfig/RuleList/SingleRule/TimeRule'

export type Rule =
  | TagsRule
  | GroupRule
  | NestedRule
  | FormResponseRule
  | TimeRule

export type Source = Rule['source']

export interface HasRules {
  rules?: Rule[]
}

export const AND = 'And'
export const OR = 'Or'
export type Condition = typeof AND | typeof OR

export type BaseRule = {
  condition: Condition
}

import React from 'react'
import {Lefty, useLeftyUpdate} from 'Event/template/Lefty'
import {useForm} from 'react-hook-form'
import ComponentConfig, {
  ComponentConfigProps,
  SaveButton,
  Footer,
} from 'organization/Event/DashboardConfig/ComponentConfigPanel'
import {DeepPartial} from 'lib/type-utils'
import Settings from 'Event/template/Lefty/FeaturePageHeader/HeaderConfig/Settings'
import Styling from 'Event/template/Lefty/FeaturePageHeader/HeaderConfig/Styling'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import StylingPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/StylingPanel'
import {useAutoUpdate} from 'organization/Event/TemplateEditor'

export default function HeaderConfig(props: ComponentConfigProps) {
  const {showing, onClose} = props
  const update = useLeftyUpdate()
  const {control, watch, handleSubmit, formState} = useForm()

  const save = (data: DeepPartial<Lefty>) => {
    update(data)
    onClose()
  }

  useAutoUpdate({values: watch(), when: showing})

  return (
    <ComponentConfig
      showing={showing}
      onClose={onClose}
      title="Lefty"
      onSubmit={handleSubmit(save)}
      hasChanges={formState.isDirty}
    >
      <SettingsPanel>
        <Settings control={control} />
      </SettingsPanel>
      <StylingPanel>
        <Styling control={control} watch={watch} />
      </StylingPanel>
      <Footer>
        <SaveButton type="submit" />
      </Footer>
    </ComponentConfig>
  )
}

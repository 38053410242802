/* eslint-disable no-undef */
import ButtonView from '@ckeditor/ckeditor5-ui/src/button/buttonview';
import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import LinkUI from '@ckeditor/ckeditor5-link/src/linkui';
import '../theme/style.css';

export default class LeaderboardLink extends Plugin {
	init() {
		const editor = this.editor;
		const linkUI = editor.plugins.get( LinkUI );
		this.linkFormView = linkUI.formView;
		const rexlinkConfig = editor.config.get( 'links.rexlink' );

		if ( rexlinkConfig.length === 1 ) {
			this.button = this._createButton( rexlinkConfig[ 0 ], this.getValue( rexlinkConfig[ 0 ] ) );

			this.linkFormView.once( 'render', () => {
				// Render button's tamplate.
				this.button.render();

				// Register the button under the link form view, it will handle its destruction.
				this.linkFormView.registerChild( this.button );

				// Inject the element into DOM.
				this.linkFormView.element.insertBefore( this.button.element, this.linkFormView.saveButtonView.element );
			} );
		}
	}

	_createButton( label, value ) {
		const editor = this.editor;
		const button = new ButtonView( this.locale );
		const linkCommand = editor.commands.get( 'link' );
		const that = this;
		button.set( {
			label,
			withText: true,
			tooltip: true
		} );

		button.extendTemplate( {
			attributes: {
				class: [ 'obvio-leaderboard-link' ]
			}
		} );

		// Probably this button should be also disabled when the link command is disabled.
		// Try setting editor.isReadOnly = true to see it in action.
		button.bind( 'isEnabled' ).to( linkCommand );

		button.on( 'execute', () => {
			// Do something (like open the popup), then update the link URL field's value.
			// The line below will be probably executed inside some callback.
			const linkUrl = value;
			that.linkFormView.urlInputView.fieldView.element.value = linkUrl;
		} );

		return button;
	}

	getValue( label ) {
		if ( label == 'Leaderboard' ) {
			return '/leaderboard';
		}

		if ( label == 'Login' ) {
			return '{{login_url}}';
		}

		return '';
	}
}

import {useGuestVariables} from 'Event'
import SubmitButtonConfig from 'Event/auth/SignUp/SubmitButton/SubmitButtonConfig'
import Button from 'Event/auth/Login/SubmitButton/Button'
import {useTemplate} from 'Event/TemplateProvider'
import Configurable from 'organization/Event/Configurable'
import React from 'react'

export default function SubmitButton(props: {submitting: boolean}) {
  const v = useGuestVariables()
  const {signUp} = useTemplate()

  return (
    <Configurable>
      <SubmitButtonConfig />
      <Button disabled={props.submitting} aria-label="submit sign up form">
        {v(signUp.buttonText)}
      </Button>
    </Configurable>
  )
}

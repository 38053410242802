import Layout from 'obvio/user/Layout'
import {useBreadcrumbs} from 'lib/ui/BreadcrumbProvider'
import Page from 'lib/ui/layout/Page'
import React, {useState} from 'react'
import {obvioRoutes} from 'obvio/Routes'
import {
  BillingInterval,
  getIntervalDetails,
  isPlan,
  PlanInfo,
  useGetPlanFromQueryParams,
} from 'obvio/Billing/plans'
import {useQueryParams} from 'lib/url'
import {useGetSubscription} from 'obvio/Billing/subscribe'
import {Redirect} from 'react-router-dom'
import Typography from '@material-ui/core/Typography'
import {formatDate, LONG_DATE_FORMAT} from 'lib/date-time'
import Box from '@material-ui/core/Box'
import ErrorAlert from 'lib/ui/alerts/ErrorAlert'
import ResumeStep from 'obvio/Billing/ResumePlanPage/ResumeStep'
import {formatPrice} from 'lib/currency'

export default function ResumePlanPage() {
  const params = useQueryParams()
  const plan = useGetPlanFromQueryParams()
  const interval: BillingInterval = params?.interval as BillingInterval

  useBreadcrumbs([
    {
      title: 'Billing',
      url: obvioRoutes.billing.root,
    },
    {
      title: 'Resume Plan',
      url: `${obvioRoutes.billing.resume_plan}?plan=${plan?.name}`,
    },
  ])

  if (!plan || !isPlan(plan.name)) {
    return <Redirect to={obvioRoutes.billing.root} />
  }

  return <Content interval={interval} plan={plan} />
}

function Content(props: {interval?: BillingInterval; plan: PlanInfo}) {
  const {interval, plan} = props
  const subscription = useGetSubscription()
  const endsAt = formatDate(
    subscription?.renews_at || subscription?.ends_at || '',
    LONG_DATE_FORMAT,
  )

  const [error, setError] = useState<string | null>(null)
  const [planPrice, billingInterval] = getIntervalDetails(plan, interval)
  const price = formatPrice(planPrice)

  return (
    <>
      <Layout>
        <Page>
          <Box textAlign="center">
            <ErrorAlert>{error}</ErrorAlert>
            <Box mb={4}>
              <Typography>
                You are resuming the <strong>{plan.label}</strong> plan at the.
              </Typography>
              <Typography>
                You will be billed on <strong>{endsAt}</strong> for{' '}
                <strong>${price}</strong>, recurring{' '}
                <strong>{billingInterval}</strong> until cancelled.
              </Typography>
            </Box>
            <ResumeStep onError={setError} {...props} />
          </Box>
        </Page>
      </Layout>
    </>
  )
}

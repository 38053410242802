import React from 'react'
import styled from 'styled-components'
import {
  EmojiImageProps,
  emojiWithName,
  useSendEmoji,
} from 'Event/Dashboard/components/EmojiList/emoji'
import {useTownhallTemplate} from 'Event/template/Townhall'

export default function Emojis() {
  const template = useTownhallTemplate()
  const {emojiList: list} = template

  return (
    <>
      <Box aria-label="emoji list">
        {list.emojis.map((name, index) => (
          <Container key={index} width={list.emojiWidth}>
            <EmojiImage name={name} src={emojiWithName(name).image} />
          </Container>
        ))}
      </Box>
    </>
  )
}

const EmojiImage = (props: EmojiImageProps) => {
  const {send, sending} = useSendEmoji()
  const {name, src} = props

  return (
    <Image
      aria-label="event emoji"
      src={src}
      alt={name}
      onClick={send(name)}
      style={{opacity: sending ? 0.5 : 1}}
    />
  )
}

const Box = styled.div`
  height: 100%;
  padding: 0;
  margin-bottom: ${(props) => props.theme.spacing[2]};
  display: flex;
  justify-content: flex-end;
`

/**
 * Adjust the container according to the following requirements:
 *
 * - If no size is set, the emoji should expand until all available width, shared evenly.
 * - If a size is set, show set size if possible.
 * - If showing set size will stretch container, then render in available width.
 */
const Container = styled((props: any) => {
  const {width: _, ...otherProps} = props
  return <div {...otherProps} />
})`
  display: flex;
  align-items: center;
  margin: 0 ${(props) => props.theme.spacing[3]};
  flex: ${(props) => (props.width ? `0 1 ${props.width}px` : '1')};
  text-align: center;
`

const Image = styled.img`
  max-width: 100%;
  max-height: 100%;
  cursor: pointer;
`

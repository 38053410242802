import Plugin from "@ckeditor/ckeditor5-core/src/plugin";
import {
	toWidget,
	viewToModelPositionOutsideModelElement,
} from "@ckeditor/ckeditor5-widget/src/utils";
import Widget from "@ckeditor/ckeditor5-widget/src/widget";
import InsertVariableCommand from "./InsertVariableCommand";

export const ELEMENT_NAME = "obvioVariable";
export const INSERT_COMMAND = "insertObvioVariable";

export default class Value extends Plugin {
	static get requires() {
		return [Widget];
	}

	init() {
		this._defineSchema();
		this._defineConverters();

		this.editor.commands.add(
			INSERT_COMMAND,
			new InsertVariableCommand(this.editor)
		);

		this.editor.editing.mapper.on(
			"viewToModelPosition",
			viewToModelPositionOutsideModelElement(
				this.editor.model,
				(viewElement) => viewElement.hasClass(ELEMENT_NAME)
			)
		);
		this.editor.config.define("obvioVariables", {
			items: [],
		});
	}

	_defineSchema() {
		const schema = this.editor.model.schema;

		schema.register(ELEMENT_NAME, {
			// Allow wherever text is allowed:
			allowWhere: "$text",

			// The selected variable will act as an inline node:
			isInline: true,

			// The inline widget is self-contained so it cannot be split by the caret and it can be selected:
			isObject: true,

			// The inline widget can have the same attributes as text (for example linkHref, bold).
			allowAttributesOf: "$text",

			// Element attributes
			allowAttributes: ["name"],
		});
	}

	_defineConverters() {
		const conversion = this.editor.conversion;

		conversion.for("upcast").elementToElement({
			view: {
				name: "span",
				classes: [ELEMENT_NAME],
			},
			model: (viewElement, { writer: modelWriter }) => {
				const name = viewElement.getChild(0).data;
				return modelWriter.createElement(ELEMENT_NAME, { name });
			},
		});

		conversion.for("editingDowncast").elementToElement({
			model: ELEMENT_NAME,
			view: (modelItem, { writer: viewWriter }) => {
				const widgetElement = createView(modelItem, viewWriter);

				// Enable widget handling on an element inside the editing view.
				return toWidget(widgetElement, viewWriter);
			},
		});

		conversion.for("dataDowncast").elementToElement({
			model: ELEMENT_NAME,
			view: (modelItem, { writer: viewWriter }) =>
				createView(modelItem, viewWriter),
		});

		// Helper method for both downcast converters.
		function createView(modelItem, viewWriter) {
			const name = modelItem.getAttribute("name");

			const view = viewWriter.createContainerElement("span", {
				class: ELEMENT_NAME,
			});

			// Insert the variable name (as a text).
			const innerText = viewWriter.createText(name);
			viewWriter.insert(viewWriter.createPositionAt(view, 0), innerText);

			return view;
		}
	}
}

import React from 'react'
import Switch from 'lib/ui/form/Switch'
import {useTemplate} from 'Event/TemplateProvider'
import {useSaveTemplate} from 'Event/TemplateUpdateProvider'
import {onChangeCheckedHandler} from 'lib/dom'

export default function FormEnabledSwitch() {
  const {waiver} = useTemplate()
  const save = useSaveTemplate()
  const handleToggle = (formEnabled: boolean) => {
    save({
      waiver: {
        formEnabled,
      },
    })
  }

  return (
    <Switch
      checked={waiver.formEnabled}
      onChange={onChangeCheckedHandler(handleToggle)}
      aria-label="toggle check in survey"
      label="Form enabled"
      labelPlacement="end"
    />
  )
}

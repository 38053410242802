import {v4 as uuid} from 'uuid'
import React from 'react'
import Button from '@material-ui/core/Button'
import {useSaveTemplate} from 'Event/TemplateUpdateProvider'
import {NavButtonWithSize} from 'Event/Dashboard/components/NavButton'

export default function AddBlogPostButtonButton(props: {
  postId: string
  onAdd?: (button: NavButtonWithSize) => void
}) {
  const {postId, onAdd} = props
  const update = useSaveTemplate()

  const add = () => {
    const newButton: NavButtonWithSize = {
      text: 'Button',
      link: '',
      size: 12,
      rules: [],
      isAreaButton: false,
      isImageUpload: false,
      isFormButton: false,
      areaId: null,
      formId: null,
      actionId: null,
      isVisible: true,
      infusionsoftTag: null,
      mailchimpTag: null,
      hubspotTag: null,
      activeCampaignTag: null,
      convertKitTag: null,
      ontraportTag: null,
      fontSize: 16,
      padding: 8,
      height: 34,
      zapierTag: null,
      highLevelTag: null,
      image: '',
      backgroundColor: '#000000',
      textColor: '#FFFFFF',
      hoverTextColor: '#FFFFFF',
      disableHover: true,
      webhook: null,
    }

    if (onAdd) {
      onAdd(newButton)
      return
    }

    const id = uuid()

    update({
      blogPosts: {
        [postId]: {
          buttons: {
            [id]: newButton,
          },
        },
      },
    })
  }

  return (
    <Button
      color="primary"
      aria-label="add new button"
      onClick={add}
      variant="outlined"
      fullWidth
    >
      Add Button
    </Button>
  )
}

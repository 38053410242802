import FeatureToggle, {
  FeatureToggleProps,
  hasFeatureToggle,
} from 'lib/FeatureToggle'
import {useOwner} from 'organization/OwnerProvider'
import React from 'react'

/**
 * Renders only if the Organization Owner has the required flags.
 *
 * @param props
 * @returns
 */
export default function OrganizationFeatureToggle(props: FeatureToggleProps) {
  const {owner} = useOwner()

  return <FeatureToggle user={owner} {...props} />
}

/**
 * Checks if the Organization Owner has the given flags.
 *
 * @param flags
 * @returns
 */
export function useHasOrganizationFeatureToggle(
  flags: FeatureToggleProps['flags'],
) {
  const {owner} = useOwner()

  return hasFeatureToggle(owner, flags)
}

import {
  useNiftyFiftyTemplate,
  useNiftyFiftyUpdate,
} from 'Event/template/NiftyFifty'
import ColorPicker from 'lib/ui/ColorPicker'
import Divider from 'lib/ui/ConfigPanel/Divider'
import Title from 'lib/ui/ConfigPanel/Title'
import Slider from 'lib/ui/Slider'
import {useConfig} from 'organization/Event/Configurable'
import Config from 'organization/Event/Configurable/Config'
import StylingPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/StylingPanel'
import React from 'react'
import {Controller} from 'react-hook-form'
import SelectorButtons from 'lib/ui/SelectorButtons'

const CONTENT_ALIGN = [
  {label: 'Top', value: 'flex-start'},
  {label: 'Center', value: 'center'},
  {label: 'Bottom', value: 'flex-end'},
]

export default function RightPanelConfig() {
  const save = useNiftyFiftyUpdate()
  const {
    form: {control},
  } = useConfig()

  const template = useNiftyFiftyTemplate()
  const checkInTextColor = template.checkInRightPanel.textColor
    ? template.checkInRightPanel.textColor
    : template.textColor

  return (
    <Config title="Check In Right Panel" onSave={save}>
      <StylingPanel>
        <Title>Background</Title>
        <Controller
          name="checkInRightPanel.backgroundColor"
          defaultValue={template.checkInRightPanel.backgroundColor}
          control={control}
          render={({value, onChange}) => (
            <ColorPicker
              label="Color"
              color={value}
              onPick={onChange}
              aria-label="check in right panel background color"
            />
          )}
        />
        <Controller
          name="checkInRightPanel.backgroundOpacity"
          defaultValue={template.checkInRightPanel.backgroundOpacity}
          control={control}
          render={({value, onChange}) => (
            <Slider
              label="Opacity"
              min={0}
              max={100}
              step={1}
              onChange={onChange}
              value={value}
              unit="%"
            />
          )}
        />
        <Divider />
        <Title>Text</Title>
        <Controller
          name="checkInRightPanel.textColor"
          defaultValue={checkInTextColor}
          control={control}
          render={({value, onChange}) => (
            <ColorPicker
              label="Color"
              color={value}
              onPick={onChange}
              aria-label="check in right panel text color"
            />
          )}
        />
        <Divider />
        <Title>Content</Title>
        <Controller
          name="checkInRightPanel.contentAlign"
          defaultValue={template.checkInRightPanel.contentAlign}
          control={control}
          render={({value, onChange}) => (
            <SelectorButtons
              onChange={onChange}
              options={CONTENT_ALIGN}
              value={value}
            />
          )}
        />
      </StylingPanel>
    </Config>
  )
}

import React, {useState} from 'react'
import moment from 'moment-timezone'
import styled from 'styled-components'
import Button from '@material-ui/core/Button'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'
import {MaterialUiPickersDate} from '@material-ui/pickers/typings/date'
import LocalizedDateTimePicker from 'lib/LocalizedDateTimePicker'
import HelperText from 'lib/ui/TextField/HelperText'
import {Label} from 'lib/ui/typography'
import {
  useZoomAttendance,
  useZoomAttendanceScheduleAction,
} from 'organization/Event/ZoomAttendance/ZoomAttendanceProvider'
import {FormProps} from 'organization/Event/ZoomAttendance/AttendeeActionForm'
import {formatDate} from 'organization/EventList/CreateEventForm/Form'

export default function ScheduledActionForm(
  props: Omit<FormProps, 'onClose'> & {
    onCloseActionForm: () => void
  },
) {
  const {action, onCloseActionForm, onSuccessMessage} = props
  const {
    area,
    attendeeFilterData,
    endDateTime,
    humanReadableActionData,
    humanReadableFilterData,
    scheduledActionData,
    startDateTime,
    scheduledActionFormToggle,
    eventAreas,
  } = useZoomAttendance()
  const submitScheduledAction = useZoomAttendanceScheduleAction()
  const [scheduledDateTime, setScheduledDateTime] = useState<string | null>(
    endDateTime,
  )
  const [error, setError] = useState<string>('')

  const areaName = eventAreas.find((eventArea) => eventArea.id === area)

  const handleScheduledDate = (date: MaterialUiPickersDate) => {
    if (!date) {
      throw new Error('Date is required')
    }

    setScheduledDateTime(date.toISOString())
  }

  const onClick = () => {
    submitScheduledAction(
      action,
      startDateTime,
      endDateTime,
      area,
      scheduledDateTime,
      {
        ...scheduledActionData,
        ...attendeeFilterData,
      },
    )
      .then((response) => {
        onSuccessMessage(response.message ?? null)
        onCloseActionForm()
        scheduledActionFormToggle()
      })
      .catch((e) => {
        setError(e.message)
        return
      })
  }

  const onCancel = () => {
    scheduledActionFormToggle(false)
  }

  return (
    <>
      <DialogTitle>Scheduled Action: {action}</DialogTitle>
      <DialogContent>
        <Grid container spacing={3}>
          <Grid xs={12} sm={6} item>
            <StrongLabel>Zoom Attendance Start date/time</StrongLabel>
            {formatDate(moment(startDateTime))}
          </Grid>
          <Grid xs={12} sm={6} item>
            <StrongLabel>Zoom Attendance End date/time</StrongLabel>
            {formatDate(moment(endDateTime))}
          </Grid>

          <Grid xs={12} item>
            <StrongLabel>Area</StrongLabel>
            {areaName?.name}
          </Grid>

          <Grid xs={12} item>
            <StrongLabel>Action Details</StrongLabel>
            {actionDetails(humanReadableActionData(scheduledActionData))}
          </Grid>

          <Grid xs={12} item>
            <StrongLabel>Advanced Attendee Filter Details</StrongLabel>
            {humanReadableFilterData(attendeeFilterData)}
          </Grid>

          <Grid xs={12} item>
            <StrongLabel>Scheduled Attendee Action date/time</StrongLabel>
            <LocalizedDateTimePicker
              value={scheduledDateTime}
              labelFunc={formatDate}
              onChange={handleScheduledDate}
              fullWidth
              inputProps={{
                'aria-label': 'pick scheduled action time',
              }}
            />
          </Grid>
        </Grid>

        <StyledHelperText error={Boolean(error)}>{error}</StyledHelperText>

        <ButtonContainer>
          <ConfirmButton
            aria-label="schedule confirm"
            color="primary"
            onClick={onClick}
            variant="contained"
          >
            Schedule
          </ConfirmButton>

          <Button
            aria-label="schedule-cancel"
            color="primary"
            onClick={onCancel}
            variant="outlined"
          >
            Cancel
          </Button>
        </ButtonContainer>
      </DialogContent>
    </>
  )
}

const actionDetails = (actionDataStrings: string[]) => {
  return actionDataStrings.map((actionDataString, key) => {
    return <div key={key}>{actionDataString}</div>
  })
}

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: ${(props) => props.theme.spacing[4]} 0;

  & > button:last-child {
    margin-left: ${(props) => props.theme.spacing[4]};
  }
`

const ConfirmButton = styled(Button)`
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  padding-right: ${(props) => props.theme.spacing[3]};
`

const StrongLabel = styled(Label)`
  font-weight: 500;
  margin-bottom: ${(props) => props.theme.spacing[1]};
`

const StyledHelperText = styled(HelperText)`
  margin-bottom: ${(props) => props.theme.spacing[6]};
`

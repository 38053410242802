import {Elements} from '@stripe/react-stripe-js'
import {loadStripe, StripeElementsOptions} from '@stripe/stripe-js'
import React from 'react'

const publicKey = process.env.REACT_APP_STRIPE_PK
const stripe = loadStripe(publicKey || '')

const options: StripeElementsOptions = {
  fonts: [
    {
      cssSrc:
        'https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;700&display=swap',
    },
  ],
}

export default function StripeElementsProvider(props: {children: JSX.Element}) {
  return (
    <Elements stripe={stripe} options={options}>
      {props.children}
    </Elements>
  )
}

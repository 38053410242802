import React from 'react'
import styled from 'styled-components'

export default function Divider(props: {className?: string}) {
  return <Line className={props.className} />
}

const Line = styled.hr`
  // Add negative margin to override panel padding, and let line
  // go all the way to edge.
  margin: ${(props) =>
    `0 -${props.theme.spacing[5]} ${props.theme.spacing[6]}`};
  border: none;
  height: 1px !important; // override normalize.css
  flex-shrink: 0;
  background-color: ${(props) => props.theme.colors.divider};

  box-sizing: content-box;
  height: 0;
  overflow: visible;
`

import Popper from '@material-ui/core/Popper'
import styled from 'styled-components'
import React from 'react'
import Paper from '@material-ui/core/Paper'

export default function PopoverLinks(props: {
  showing: boolean
  anchorEl: HTMLDivElement | null
  children: JSX.Element[]
  onMouseEnter: () => void
  onMouseLeave: () => void
}) {
  const {anchorEl, children, showing, onMouseEnter, onMouseLeave} = props
  const isEmpty = children.length === 0

  if (!anchorEl || isEmpty) {
    return null
  }

  const mappedLinks = children.map(addIsMenuProp)

  return (
    <Popper
      open={showing}
      anchorEl={anchorEl}
      placement="right-start"
      style={{
        zIndex: 5,
      }}
    >
      <Boundary onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
        <Content elevation={4}>{mappedLinks}</Content>
      </Boundary>
    </Popper>
  )
}

function addIsMenuProp(el: JSX.Element) {
  return React.cloneElement(el, {
    ...el.props,
    isMenuLink: true,
  })
}

const Boundary = styled.div`
  z-index: 5;
  position: relative;
  padding-left: ${(props) => props.theme.spacing[4]};
  display: none;

  // Only show popover menu on desktop widths
  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    display: block;
  }
`

const Content = styled(Paper)`
  background: #ffffff;
  padding: ${(props) => props.theme.spacing[1]};
  border-radius: 3px;
  color: #000000;
  width: 180px;
`

import {FeatureFlag} from 'auth/user/flags'
import {Plan} from 'obvio/Billing/plans'
import {Subscription} from 'obvio/Billing/subscribe'
import {PlanLimitsData} from 'obvio/PlanLimitsProvider'
import {Role} from 'organization/Team/Roles/RolesProvider'

export interface User {
  id: number
  first_name: string
  last_name: string
  email: string
  phone_number: string | null
}

export type TeamMember = User & {
  permissions: string[]
  role: Role | null
  has_active_subscription: boolean
  plan: Plan | null
  has_payment_method: boolean
  feature_flags: FeatureFlag[] | null
  has_unresolved_plan_limits: PlanLimitsData | null
  subscriptions?: Subscription[]
  is_admin: boolean
  is_promoter: boolean
  promoter_auth_token: string | null
  has_unpaid_transactions: boolean
}

export function isTeamMember(user: User | null): user is TeamMember {
  if (!user) {
    return false
  }

  /**
   * We assume if the user has waiver they're an Attendee.
   */
  const hasWaiver = Object.prototype.hasOwnProperty.call(user, 'waiver')
  return !hasWaiver
}

import React from 'react'
import styled from 'styled-components'
import Button from 'lib/ui/Button'
import {Typography} from 'lib/ui/typography'
import {useDisconnectMarketplaceAccount} from 'lib/marketplace-api/accounts/use-disconnect-marketplace-account'
import {useMarketplaceAccount} from 'lib/marketplace-api/accounts/use-marketplace-account'

export default function ConnectedAccountDetails() {
  const {data: account} = useMarketplaceAccount()
  const {
    mutate: disconnectAccount,
    isLoading,
  } = useDisconnectMarketplaceAccount()

  if (!account) {
    return null
  }

  const defaultCurrency = account.default_currency
    ? account.default_currency.toUpperCase()
    : '--'

  return (
    <>
      <StyledButton
        aria-label="stripe disconnect button"
        color="danger"
        disabled={isLoading}
        fullWidth
        onClick={() => disconnectAccount()}
        variant="outlined"
      >
        Disconnect Stripe
      </StyledButton>

      <Typography fontWeight="700">Connected Account Email</Typography>
      <Typography mb={4}>{account.email}</Typography>

      <Typography fontWeight="700">Stripe ID</Typography>
      <Typography mb={4}>{account.stripe_account_id}</Typography>

      <Row>
        <div>
          <Typography fontWeight="700">Charges Enabled</Typography>
          <Typography>{account.charges_enabled ? 'Yes' : 'No'}</Typography>
        </div>
        <div>
          <Typography fontWeight="700">Currency</Typography>
          <Typography>{defaultCurrency}</Typography>
        </div>
      </Row>
    </>
  )
}

const StyledButton = styled(Button)`
  margin-bottom: ${(props) => props.theme.spacing[10]};
`

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${(props) => props.theme.spacing[4]};
  width: 100%;

  & > div {
    width: 50%;
  }
`

import {CloseIcon} from 'lib/ui/Icon'
import IconButton from 'lib/ui/IconButton'
import React from 'react'
import styled from 'styled-components'

export default function RemoveButton(props: {
  onClick: () => void
  'aria-label'?: string
}) {
  return (
    <StyledIconButton
      onClick={props.onClick}
      type="button"
      aria-label={props['aria-label']}
    >
      <CloseIcon color="danger" iconSize={16} />
    </StyledIconButton>
  )
}

const StyledIconButton = styled(IconButton)`
  position: absolute;
  top: 0;
  right: ${(props) => props.theme.spacing[1]};
  z-index: 99;
`

import React, {useState} from 'react'
import {useTrackEventPage} from 'analytics'
import {Redirect} from 'react-router-dom'
import {eventRoutes} from 'Event/Routes'
import {api, useQueryParams} from 'lib/url'
import {Attendee} from 'Event/attendee'
import {ValidationError} from 'lib/ui/api-client'
import {getRetryJoinUrlBackoffMs, useEvent} from 'Event/EventProvider'
import {useTemplate} from 'Event/TemplateProvider'
import {components} from 'Event/template'
import {RoomWithTemplate, useFindRoomByToken} from 'Event/room'

export interface RoomRegistrationData {
  first_name: string
  last_name: string
  email: string
  phone_number: string
}

export interface RoomRegistrationFormProps {
  submit: (data: RoomRegistrationData) => void
  canSubmit: boolean
  responseError: ValidationError<RoomRegistrationData>
  room: RoomWithTemplate
}

export default function RoomRegistration() {
  useTrackEventPage({
    page: 'Room Registration',
  })

  const [submitting, setSubmitting] = useState(false)
  const [responseError, setResponseError] = useState<
    ValidationError<RoomRegistrationData>
  >(null)

  const [attempt, setAttempt] = useState(1)

  const {client} = useEvent()
  const {token} = useQueryParams()
  const room = useFindRoomByToken()
  if (!room) {
    return null
  }

  const canSubmit = !submitting

  if (!token) {
    return <Redirect to={eventRoutes.login} />
  }

  const submit = (data: RoomRegistrationData) => {
    setSubmitting(true)

    const withToken = {
      ...data,
      token,
    }

    setResponseError(null)

    return client
      .post<{url: string; attendee: Attendee} | null>(
        api('/room/join'),
        withToken,
      )
      .then((resData) => {
        if (!resData) {
          const backOffSecs = getRetryJoinUrlBackoffMs(attempt)
          setAttempt((attempt) => attempt + 1)
          /**
           * Join URL is NOT ready yet so we'll re-submit
           */
          setTimeout(() => submit(data), backOffSecs)
          return
        }

        window.location.href = resData.url
      })
      .catch((e) => {
        setSubmitting(false)
        setResponseError(e)
      })
  }

  return (
    <TemplateForm
      submit={submit}
      canSubmit={canSubmit}
      responseError={responseError}
      room={room}
    />
  )
}

function TemplateForm(props: RoomRegistrationFormProps) {
  const template = useTemplate()
  const Component = components[template.name].RoomRegistrationForm

  return <Component {...props} />
}

import React, {useState} from 'react'
import {useTrackEventPage} from 'analytics'
import {Redirect, useHistory} from 'react-router-dom'
import {areaRoutes, eventRoutes} from 'Event/Routes'
import {api, useQueryParams} from 'lib/url'
import {Attendee} from 'Event/attendee'
import {useEvent} from 'Event/EventProvider'
import {ValidationError} from 'lib/ui/api-client'
import FullPageLoader from 'lib/ui/layout/FullPageLoader'
import {useEventAuth} from 'Event/auth'
import {
  AreaWithTemplate,
  useFindAreaByToken,
} from 'organization/Event/AreasProvider'
import {useTemplate} from 'Event/TemplateProvider'
import {components} from 'Event/template'

export interface AreaRegistrationData {
  first_name: string
  last_name: string
  email: string
  phone_number: string
}

export interface AreaRegistrationFormProps {
  submit: (data: AreaRegistrationData) => void
  area: AreaWithTemplate
  canSubmit: boolean
  responseError: ValidationError<AreaRegistrationData>
}

export default function AreaRegistration() {
  useTrackEventPage({
    page: 'Area Registration',
  })
  const history = useHistory()
  const [submitting, setSubmitting] = useState(false)
  const [responseError, setResponseError] = useState<
    ValidationError<AreaRegistrationData>
  >(null)

  const {client} = useEvent()
  const {token} = useQueryParams()
  const {login} = useEventAuth()
  const area = useFindAreaByToken()
  if (!area) {
    return null
  }

  const canSubmit = !submitting

  if (!token) {
    return <Redirect to={eventRoutes.login} />
  }

  const autoLogin = (area_key: string, attendee: Attendee) => {
    login({
      login_token: attendee.login_token,
    })
      .then(() => {
        history.push(areaRoutes(area_key).root)
      })
      .catch((e) => {
        setSubmitting(false)
        setResponseError(e)
      })
  }

  const submit = (data: AreaRegistrationData) => {
    if (submitting) {
      return
    }

    setSubmitting(true)
    setResponseError(null)

    const withToken = {
      ...data,
      token,
    }

    return client
      .post<{area_key: string; attendee: Attendee}>(
        api('/area/registration'),
        withToken,
      )
      .then(({area_key, attendee}) => {
        autoLogin(area_key, attendee)
      })
      .catch((e) => {
        setSubmitting(false)
        setResponseError(e)
      })
  }

  if (submitting) {
    return <FullPageLoader />
  }

  return (
    <TemplateForm
      area={area}
      submit={submit}
      canSubmit={canSubmit}
      responseError={responseError}
    />
  )
}

function TemplateForm(props: AreaRegistrationFormProps) {
  const template = useTemplate()
  const Component = components[template.name].AreaRegistrationForm

  return <Component {...props} />
}

import React from 'react'
import {PageConfigurable} from 'organization/Event/Configurable'
import RightPanelConfig from 'Event/template/NiftyFifty/Login/RightPanel/RightPanelConfig'
import {useNiftyFiftyTemplate} from 'Event/template/NiftyFifty/'
import {rgba} from 'lib/color'
import styled from 'styled-components'
import EmailField from 'Event/auth/Login/EmailField'
import PasswordField from 'Event/auth/Login/PasswordField'
import SubmitButton from 'Event/auth/Login/SubmitButton'
import {ErrorMessage} from 'Event/template/NiftyFifty/Login/Page'
import {LoginProps} from 'Event/auth/Login'
import ForgotPasswordLink, {
  ForgotPasswordLinkBase,
} from 'Event/auth/Login/ForgotPasswordLink'
import Description from 'Event/auth/Login/Description'
import SignUpLink, {SignUpLinkBase} from 'Event/auth/Login/SignUpLink'

export default function RightPanel(props: LoginProps) {
  const template = useNiftyFiftyTemplate()
  const {login, textColor} = template

  return (
    <PageConfigurable>
      <RightPanelConfig />
      <RightContainer
        backgroundColor={rgba(
          login.loginRightPanel.backgroundColor,
          login.loginRightPanel.backgroundOpacity / 100,
        )}
      >
        <FormContainer
          textColor={textColor}
          descriptionTextColor={login.description.color}
        >
          <Description />
          <form onSubmit={props.onSubmit}>
            <EmailField {...props} />
            <PasswordField {...props} />
            <ErrorMessage>{props.error}</ErrorMessage>
            <SubmitButton {...props} />
          </form>
        </FormContainer>
        <ForgotPasswordLink Component={StyledRelativeLink} />
        <SignUpLink Component={StyledRelativeSignUpLink} />
      </RightContainer>
    </PageConfigurable>
  )
}

export const RightContainer = styled((props) => {
  const {backgroundColor: _, ...otherProps} = props
  return <div {...otherProps} />
})<{
  backgroundColor: string
}>`
  width: 100%;
  height: 100%;
  margin: 0 auto;

  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  background: ${(props) => props.backgroundColor};
`

export const FormContainer = styled.div<{
  textColor: string
  descriptionTextColor: string | null
}>`
  color: ${(props) =>
    props.descriptionTextColor
      ? props.descriptionTextColor
      : props.textColor} !important;
  width: 80%;
`

export const StyledRelativeLink = styled(ForgotPasswordLinkBase)<{
  color: string
}>`
  margin-top: ${(props) => props.theme.spacing[10]};
`

export const StyledRelativeSignUpLink = styled(SignUpLinkBase)<{
  color: string
}>`
  margin-top: ${(props) => props.theme.spacing[10]};
`

import {useTemplate} from 'Event/TemplateProvider'
import {useSaveTemplate} from 'Event/TemplateUpdateProvider'
import ColorPicker from 'lib/ui/ColorPicker'
import TextField from 'lib/ui/TextField'
import {useConfig} from 'organization/Event/Configurable'
import Config from 'organization/Event/Configurable/Config'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import StylingPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/StylingPanel'
import React from 'react'
import {Controller} from 'react-hook-form'
import Slider from 'lib/ui/Slider'

export default function DescriptionConfig() {
  const save = useSaveTemplate()
  const {changePassword} = useTemplate()

  const {
    form: {register, control},
  } = useConfig()

  return (
    <Config title="Description" onSave={save}>
      <SettingsPanel>
        <TextField
          name="changePassword.description.text"
          defaultValue={changePassword.description.text}
          label="Description"
          fullWidth
          inputProps={{
            'aria-label': 'set form description',
            ref: register,
          }}
        />
      </SettingsPanel>
      <StylingPanel>
        <Controller
          name="changePassword.description.color"
          defaultValue={changePassword.description.color}
          control={control}
          render={({value, onChange}) => (
            <ColorPicker
              label="Description Text Color"
              color={value}
              onPick={onChange}
              aria-label="description text color"
            />
          )}
        />
        <Controller
          name="changePassword.description.fontSize"
          defaultValue={changePassword.description.fontSize}
          control={control}
          render={({value, onChange}) => (
            <Slider
              valueLabelDisplay="auto"
              aria-label="description font size"
              value={value}
              onChange={onChange}
              step={1}
              min={5}
              max={50}
              label="Description Font Size"
            />
          )}
        />
      </StylingPanel>
    </Config>
  )
}

import Typography from '@material-ui/core/Typography'
import styled from 'styled-components'
import Card from 'Event/template/Panels/Dashboard/Sponsors/SponsorList/Card'
import React from 'react'
import {usePanelsTemplate, usePanelsUpdate} from 'Event/template/Panels'
import DraggableGrid from 'lib/ui/DraggableGrid'
import {createPositions, orderedIdsByPosition} from 'lib/list'
import {useEditMode} from 'Event/EditModeProvider'
import {VisibleSponsor} from 'Event/Sponsors'

export default function SponsorList(props: {className?: string}) {
  const {
    sponsors: {perRow, items},
  } = usePanelsTemplate()
  const isEditMode = useEditMode()

  const ids = orderedIdsByPosition(items)

  const isEmpty = ids.length === 0
  if (isEmpty) {
    return <Typography align="center">No sponsor have been added</Typography>
  }

  const sponsors = ids.map((id, index) => (
    <VisibleSponsor key={id} sponsor={items[id]}>
      <Card key={id} id={id} index={index} sponsor={items[id]} />
    </VisibleSponsor>
  ))

  if (!isEditMode) {
    return <Box perRow={perRow}>{sponsors}</Box>
  }

  return <DraggableList className={props.className}>{sponsors}</DraggableList>
}

function DraggableList(props: {
  children: React.ReactElement[]
  className?: string
}) {
  const {
    sponsors: {perRow, items},
  } = usePanelsTemplate()
  const ids = orderedIdsByPosition(items)

  const handleDrag = useHandleDragGrid()

  return (
    <DraggableGrid onDrag={handleDrag} ids={ids}>
      <Box className={props.className} perRow={perRow}>
        {props.children}
      </Box>
    </DraggableGrid>
  )
}

function useHandleDragGrid() {
  const update = usePanelsUpdate()
  const {
    sponsors: {items},
  } = usePanelsTemplate()

  return (fromIndex: number, toIndex: number) => {
    const ids = orderedIdsByPosition(items)

    const moved = Array.from(ids)
    const [removed] = moved.splice(fromIndex, 1)
    moved.splice(toIndex, 0, removed)

    update({
      sponsors: {
        items: createPositions(moved),
      },
    })
  }
}

const Box = styled.div<{perRow: number}>`
  display: grid;
  grid-auto-rows: 1fr;
  grid-template-columns: 1fr;

  grid-gap: 16px;

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    grid-template-columns: repeat(${(props) => props.perRow}, 1fr);
  }
`

import React from 'react'
import UserOrganizationsProvider, {
  useUserOrganizations,
} from 'obvio/Organizations/UserOrganizationsProvider'
import Collection from 'obvio/Organizations/Collection'
import {obvioRoutes} from 'obvio/Routes'
import {useBreadcrumbs} from 'lib/ui/BreadcrumbProvider'
import Layout from 'obvio/user/Layout'
import {useObvioUser} from 'obvio/auth'
import {Redirect} from 'react-router'
import {createRoutesFor} from 'organization/OrganizationProvider'
import Page from 'lib/ui/layout/Page'
import Title from 'lib/ui/PageHeader/Title'
import {useCanCreateOrganization} from 'obvio/Billing/plans'
import NewItemButton from 'lib/ui/PageHeader/NewItemButton'
import {useToggleArray} from 'lib/toggle'
import Button from 'lib/ui/Button'
import styled from 'styled-components'
import {
  ToggleViewIcon,
  DesktopPageHeader,
  MobilePageHeader,
  HeaderLink,
} from 'lib/ui/GridCollection'

export default function Organizations() {
  useBreadcrumbs([
    {
      title: 'Organizations',
      url: obvioRoutes.root,
    },
  ])

  return (
    <UserOrganizationsProvider>
      <Content />
    </UserOrganizationsProvider>
  )
}

function Content() {
  const {organizations} = useUserOrganizations()
  const shouldRedirectToOrganization = useShouldRedirectToOrganization()
  const [showingListView, toggleListView] = useToggleArray()

  if (shouldRedirectToOrganization) {
    const firstOrganization = organizations[0]
    const routes = createRoutesFor(firstOrganization)
    return <Redirect to={routes.root} />
  }

  return (
    <Layout>
      <Page>
        <DesktopPageHeader>
          <Title text="Organizations" />
          <Container>
            <Button onClick={toggleListView} aria-label="toggle view">
              <ToggleViewIcon isListView={showingListView} />
            </Button>
            <CreateOrganizationButton />
          </Container>
        </DesktopPageHeader>
        <MobilePageHeader>
          <Title text="Organizations" />
          <CreateOrganizationButton />
          <Container>
            <Button onClick={toggleListView} aria-label="toggle view">
              <ToggleViewIcon isListView={showingListView} />
            </Button>
          </Container>
        </MobilePageHeader>
        <Collection showingListView={showingListView} />
      </Page>
    </Layout>
  )
}

function CreateOrganizationButton() {
  const canCreate = useCanCreateOrganization()
  if (!canCreate) {
    return (
      <HeaderLink to={obvioRoutes.billing.root} disableStyles>
        <NewItemButton text="Subscribe to Obvio" textCollapsed="Subscribe" />
      </HeaderLink>
    )
  }

  return (
    <HeaderLink to={obvioRoutes.organizations.create} disableStyles>
      <NewItemButton
        text="New Organization"
        aria-label="create new organization"
      />
    </HeaderLink>
  )
}

function useShouldRedirectToOrganization() {
  const user = useObvioUser()
  const {organizations} = useUserOrganizations()

  // If no organizations exist, there's nothing to
  // auto-redirect to.
  const hasNone = organizations.length === 0
  if (hasNone) {
    return false
  }

  // If a user has multiple organizations, lets let them
  // choose which one they'd like to access.
  const hasMultiple = organizations.length > 1
  if (hasMultiple) {
    return false
  }

  // If a user only has one organization, and they don't have a plan,
  // then they can't create any more, so let's just re-direct them.
  if (!user.plan) {
    return true
  }

  // If a user has an active plan, we'll always show the organizations
  // list
  return false
}

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

import React from 'react'
import {Controller, UseFormMethods} from 'react-hook-form'
import {Resource} from 'Event/template/Cards/Dashboard/Sidebar/SidebarItem/ResourceList/ResourceItem'
import TextField from 'lib/ui/TextField'
import {Label} from 'lib/ui/typography'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'
import ToggleButton from '@material-ui/lab/ToggleButton'
import ResourceUpload from 'Event/template/Cards/Dashboard/Sidebar/SidebarItem/ResourceList/ResourceUpload'
import {EnabledSwitch} from 'lib/ui/form/Switch'
import IconSelect from 'lib/fontawesome/IconSelect'
import {onChangeCheckedHandler} from 'lib/dom'
import ActionSelect from 'Event/ActionsProvider/ActionSelect'

export default function Settings(props: {
  control: UseFormMethods['control']
  resource: Resource
  isUrl?: boolean
  setIsUrl: (flag: boolean) => void
  filePath: string
  setFilePath: (newPath: string) => void
}) {
  const {control, resource, setIsUrl, isUrl, filePath, setFilePath} = props
  return (
    <>
      <Controller
        name="isVisible"
        defaultValue={resource.isVisible}
        control={control}
        render={({value, onChange}) => (
          <EnabledSwitch
            checked={value}
            onChange={onChangeCheckedHandler(onChange)}
          />
        )}
      />
      <Label>Name</Label>
      <Controller
        name="name"
        control={control}
        defaultValue={resource.name}
        render={({value, onChange}) => (
          <TextField
            aria-label="resource name"
            fullWidth
            value={value}
            onChange={onChange}
          />
        )}
      />

      <ToggleButtonGroup value={isUrl ? 'true' : 'false'} exclusive>
        <ToggleButton value="false" onClick={() => setIsUrl(false)}>
          File
        </ToggleButton>
        <ToggleButton
          value="true"
          aria-label="set url resource"
          onClick={() => setIsUrl(true)}
        >
          Link
        </ToggleButton>
      </ToggleButtonGroup>
      <ResourceUpload
        isUrl={isUrl}
        filePath={filePath}
        onChange={setFilePath}
      />
      <UrlField
        defaultValue={resource.url || ''}
        control={control}
        isUrl={isUrl}
      />
      <Controller
        name="actionId"
        defaultValue={resource.actionId || null}
        control={control}
        render={({value, onChange}) => (
          <ActionSelect noneLabel="default" value={value} onChange={onChange} />
        )}
      />
      <Controller
        control={control}
        name="icon"
        defaultValue={resource.icon || ''}
        render={({value, onChange}) => (
          <IconSelect value={value || null} onChange={onChange} />
        )}
      />
    </>
  )
}

function UrlField(props: {
  isUrl?: boolean
  control: UseFormMethods['control']
  defaultValue: string
}) {
  if (!props.isUrl) {
    return null
  }

  return (
    <>
      <Controller
        name="url"
        control={props.control}
        defaultValue={props.defaultValue}
        render={({value, onChange}) => (
          <TextField
            aria-label="resource external file url"
            fullWidth
            value={value}
            onChange={onChange}
          />
        )}
      />
      <Label>URL starting with http:// or https://</Label>
    </>
  )
}

import Container from 'Event/Marketplace/Block/Container'

import React from 'react'
import styled from 'styled-components'
import {orderedIdsByPosition} from 'lib/list'
import Content from 'lib/ui/form/TextEditor/Content'
import {Font, fontStyles, useLoadFont} from 'lib/FontSelect'
import {Icon, IconProps} from 'lib/fontawesome/Icon'
import {BlockComponentProps} from '../index'
import {HashMap, Ordered} from '../../lib/list'
import {BlockBase} from '../base'

export type BulletItem = Ordered & {
  visibility: 'all' | 'mobile_only' | 'desktop_only'
  text: string
}

export interface BulletedListBlock extends BlockBase {
  type: 'BulletedList'
  icon: string | null
  font: Font | null
  fontSize: number
  color: string
  iconColor: string
  iconSize: number
  bulletSpace: number
  textSpace: number
  items: HashMap<BulletItem>
}

export default function BulletedList(
  props: BulletedListBlock & BlockComponentProps,
) {
  return (
    <BulletedListContainer {...props}>
      <ListItems {...props} />
    </BulletedListContainer>
  )
}

export function BulletedListContainer(
  props: BulletedListBlock &
    BlockComponentProps & {
      children: React.ReactNode
    },
) {
  const {children} = props

  useLoadFont(props.font)

  return (
    <Container {...props}>
      <BulletedListBox>{children}</BulletedListBox>
    </Container>
  )
}

function ListItems(props: BulletedListBlock) {
  const {items} = props

  if (!items) {
    return null
  }

  const sortedIds = orderedIdsByPosition(items)

  return (
    <>
      {sortedIds.map((id) => (
        <ListItem key={id} {...props} item={items[id]} />
      ))}
    </>
  )
}

export function ListItem(
  props: BulletedListBlock & {
    item: BulletItem
  },
) {
  const {item, icon} = props

  return (
    <ListItemBox {...props} aria-label="bullet list">
      <StyledIcon iconClass={icon} {...props} />
      <StyledContent iconSize={props.iconSize} fontSize={props.fontSize}>
        {item.text}
      </StyledContent>
    </ListItemBox>
  )
}

export const createBulletItem = (): BulletItem => ({
  text: '<p>New Bullet</p>',
  visibility: 'all',
})

export const StyledIcon = styled(
  (
    props: IconProps & {
      iconColor: string
      iconSize: number
      textSpace: number
    },
  ) => {
    const {iconSize: _, ...otherProps} = props
    return <Icon {...otherProps} />
  },
)`
  font-size: ${(props) => props.iconSize}px !important;
  width: ${(props) => props.iconSize}px;
  height: ${(props) => props.iconSize}px;
  margin-right: ${(props) => props.textSpace}px;
  color: ${(props) => props.iconColor};
`

const ListItemBox = styled.div<{
  bulletSpace: number
  fontSize: number
  color: string
  font: Font | null
}>`
  margin-top: ${(props) => props.bulletSpace}px;
  display: flex;
  align-items: flex-start;

  ${(props) => fontStyles(props.font)}
  font-size: ${(props) => props.fontSize}px;
  color: ${(props) => props.color};

  &:last-child {
    margin-bottom: ${(props) => props.bulletSpace}px;
  }
`

const BulletedListBox = styled.div`
  flex-direction: column;

  & > div > div {
    left: auto !important;
    top: auto !important;
  }
`

const StyledContent = styled(Content)<{
  fontSize: number
  iconSize: number
}>`
  margin-top: ${(props) => props.iconSize / 2 - (props.fontSize * 1.4) / 2}px;
  & > p {
    margin-bottom: 0 !important;
    padding-top: 0 !important;
  }
`

import {IconName, IconPrefix} from '@fortawesome/fontawesome-common-types'

export const normalIconPrefixes: IconPrefix[] = [
  'fas',
  'far',
  'fal',
  'fat',
  'fad',
]

/**
 * Normal (non-brand) Icon
 *
 * All included FA icons. Unfortunately FA doesn't export the list, so we
 * need to duplicate them here, but at least we have the types to
 * ensure we're not including any missing icons.
 *
 * Number of Icons From FA:
 *  Solid: 3,124
 *  Regular: 3,124
 *  Light: 3,124
 *  Thin: 3,124
 *  Duotons: 3,124
 *  Brands: 463
 *
 * Total: 16,083 Icons
 * */
export const normalIcons: IconName[] = [
  '42-group',
  'innosoft',
  '500px',
  'accessible-icon',
  'accusoft',
  'adn',
  'adversal',
  'affiliatetheme',
  'airbnb',
  'algolia',
  'alipay',
  'amazon',
  'amazon-pay',
  'amilia',
  'android',
  'angellist',
  'angrycreative',
  'angular',
  'app-store',
  'app-store-ios',
  'apper',
  'apple',
  'apple-pay',
  'artstation',
  'asymmetrik',
  'atlassian',
  'audible',
  'autoprefixer',
  'avianex',
  'aviato',
  'aws',
  'bandcamp',
  'battle-net',
  'behance',
  'behance-square',
  'bilibili',
  'bimobject',
  'bitbucket',
  'bitcoin',
  'bity',
  'black-tie',
  'blackberry',
  'blogger',
  'blogger-b',
  'bluetooth',
  'bluetooth-b',
  'bootstrap',
  'bots',
  'btc',
  'buffer',
  'buromobelexperte',
  'buy-n-large',
  'buysellads',
  'canadian-maple-leaf',
  'cc-amazon-pay',
  'cc-amex',
  'cc-apple-pay',
  'cc-diners-club',
  'cc-discover',
  'cc-jcb',
  'cc-mastercard',
  'cc-paypal',
  'cc-stripe',
  'cc-visa',
  'centercode',
  'centos',
  'chrome',
  'chromecast',
  'cloudflare',
  'cloudscale',
  'cloudsmith',
  'cloudversify',
  'cmplid',
  'codepen',
  'codiepie',
  'confluence',
  'connectdevelop',
  'contao',
  'cotton-bureau',
  'cpanel',
  'creative-commons',
  'creative-commons-by',
  'creative-commons-nc',
  'creative-commons-nc-eu',
  'creative-commons-nc-jp',
  'creative-commons-nd',
  'creative-commons-pd',
  'creative-commons-pd-alt',
  'creative-commons-remix',
  'creative-commons-sa',
  'creative-commons-sampling',
  'creative-commons-sampling-plus',
  'creative-commons-share',
  'creative-commons-zero',
  'critical-role',
  'css3',
  'css3-alt',
  'cuttlefish',
  'd-and-d',
  'd-and-d-beyond',
  'dailymotion',
  'dashcube',
  'deezer',
  'delicious',
  'deploydog',
  'deskpro',
  'dev',
  'deviantart',
  'dhl',
  'diaspora',
  'digg',
  'digital-ocean',
  'discord',
  'discourse',
  'dochub',
  'docker',
  'draft2digital',
  'dribbble',
  'dribbble-square',
  'dropbox',
  'drupal',
  'dyalog',
  'earlybirds',
  'ebay',
  'edge',
  'edge-legacy',
  'elementor',
  'ello',
  'ember',
  'empire',
  'envira',
  'erlang',
  'ethereum',
  'etsy',
  'evernote',
  'expeditedssl',
  'facebook',
  'facebook-f',
  'facebook-messenger',
  'facebook-square',
  'fantasy-flight-games',
  'fedex',
  'fedora',
  'figma',
  'firefox',
  'firefox-browser',
  'first-order',
  'first-order-alt',
  'firstdraft',
  'flickr',
  'flipboard',
  'fly',
  'font-awesome',
  'font-awesome-flag',
  'font-awesome-logo-full',
  'fonticons',
  'fonticons-fi',
  'fort-awesome',
  'fort-awesome-alt',
  'forumbee',
  'foursquare',
  'free-code-camp',
  'freebsd',
  'fulcrum',
  'galactic-republic',
  'galactic-senate',
  'get-pocket',
  'gg',
  'gg-circle',
  'git',
  'git-alt',
  'git-square',
  'github',
  'github-alt',
  'github-square',
  'gitkraken',
  'gitlab',
  'gitter',
  'glide',
  'glide-g',
  'gofore',
  'golang',
  'goodreads',
  'goodreads-g',
  'google',
  'google-drive',
  'google-pay',
  'google-play',
  'google-plus',
  'google-plus-g',
  'google-plus-square',
  'google-wallet',
  'gratipay',
  'grav',
  'gripfire',
  'grunt',
  'guilded',
  'gulp',
  'hacker-news',
  'hacker-news-square',
  'hackerrank',
  'hashnode',
  'hips',
  'hire-a-helper',
  'hive',
  'hooli',
  'hornbill',
  'hotjar',
  'houzz',
  'html5',
  'hubspot',
  'ideal',
  'imdb',
  'instagram',
  'instagram-square',
  'instalod',
  'intercom',
  'internet-explorer',
  'invision',
  'ioxhost',
  'itch-io',
  'itunes',
  'itunes-note',
  'java',
  'jedi-order',
  'jenkins',
  'jira',
  'joget',
  'joomla',
  'js',
  'js-square',
  'jsfiddle',
  'kaggle',
  'keybase',
  'keycdn',
  'kickstarter',
  'kickstarter-k',
  'korvue',
  'laravel',
  'lastfm',
  'lastfm-square',
  'leanpub',
  'less',
  'line',
  'linkedin',
  'linkedin-in',
  'linode',
  'linux',
  'lyft',
  'magento',
  'mailchimp',
  'mandalorian',
  'markdown',
  'mastodon',
  'maxcdn',
  'mdb',
  'medapps',
  'medium',
  'medium-m',
  'medrt',
  'meetup',
  'megaport',
  'mendeley',
  'meta',
  'microblog',
  'microsoft',
  'mix',
  'mixcloud',
  'mixer',
  'mizuni',
  'modx',
  'monero',
  'napster',
  'neos',
  'nimblr',
  'node',
  'node-js',
  'npm',
  'ns8',
  'nutritionix',
  'octopus-deploy',
  'odnoklassniki',
  'odnoklassniki-square',
  'old-republic',
  'opencart',
  'openid',
  'opera',
  'optin-monster',
  'orcid',
  'osi',
  'padlet',
  'page4',
  'pagelines',
  'palfed',
  'patreon',
  'paypal',
  'perbyte',
  'periscope',
  'phabricator',
  'phoenix-framework',
  'phoenix-squadron',
  'php',
  'pied-piper',
  'pied-piper-alt',
  'pied-piper-hat',
  'pied-piper-pp',
  'pied-piper-square',
  'pinterest',
  'pinterest-p',
  'pinterest-square',
  'pix',
  'playstation',
  'product-hunt',
  'pushed',
  'python',
  'qq',
  'quinscape',
  'quora',
  'r-project',
  'raspberry-pi',
  'ravelry',
  'react',
  'reacteurope',
  'readme',
  'rebel',
  'red-river',
  'reddit',
  'reddit-alien',
  'reddit-square',
  'redhat',
  'renren',
  'replyd',
  'researchgate',
  'resolving',
  'rev',
  'rocketchat',
  'rockrms',
  'rust',
  'safari',
  'salesforce',
  'sass',
  'schlix',
  'scribd',
  'searchengin',
  'sellcast',
  'sellsy',
  'servicestack',
  'shirtsinbulk',
  'shopify',
  'shopware',
  'simplybuilt',
  'sistrix',
  'sith',
  'sitrox',
  'sketch',
  'skyatlas',
  'skype',
  'slack',
  'slack-hash',
  'slideshare',
  'snapchat',
  'snapchat-ghost',
  'snapchat-square',
  'soundcloud',
  'sourcetree',
  'speakap',
  'speaker-deck',
  'spotify',
  'square-font-awesome',
  'square-font-awesome-stroke',
  'font-awesome-alt',
  'squarespace',
  'stack-exchange',
  'stack-overflow',
  'stackpath',
  'staylinked',
  'steam',
  'steam-square',
  'steam-symbol',
  'sticker-mule',
  'strava',
  'stripe',
  'stripe-s',
  'studiovinari',
  'stumbleupon',
  'stumbleupon-circle',
  'superpowers',
  'supple',
  'suse',
  'swift',
  'symfony',
  'teamspeak',
  'telegram',
  'telegram-plane',
  'tencent-weibo',
  'the-red-yeti',
  'themeco',
  'themeisle',
  'think-peaks',
  'tiktok',
  'trade-federation',
  'trello',
  'tumblr',
  'tumblr-square',
  'twitch',
  'twitter',
  'twitter-square',
  'typo3',
  'uber',
  'ubuntu',
  'uikit',
  'umbraco',
  'uncharted',
  'uniregistry',
  'unity',
  'unsplash',
  'untappd',
  'ups',
  'usb',
  'usps',
  'ussunnah',
  'vaadin',
  'viacoin',
  'viadeo',
  'viadeo-square',
  'viber',
  'vimeo',
  'vimeo-square',
  'vimeo-v',
  'vine',
  'vk',
  'vnv',
  'vuejs',
  'watchman-monitoring',
  'waze',
  'weebly',
  'weibo',
  'weixin',
  'whatsapp',
  'whatsapp-square',
  'whmcs',
  'wikipedia-w',
  'windows',
  'wirsindhandwerk',
  'wsh',
  'wix',
  'wizards-of-the-coast',
  'wodu',
  'wolf-pack-battalion',
  'wordpress',
  'wordpress-simple',
  'wpbeginner',
  'wpexplorer',
  'wpforms',
  'wpressr',
  'xbox',
  'xing',
  'xing-square',
  'y-combinator',
  'yahoo',
  'yammer',
  'yandex',
  'yandex-international',
  'yarn',
  'yelp',
  'yoast',
  'youtube',
  'youtube-square',
  'zhihu',
  '0',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '00',
  '360-degrees',
  'a',
  'abacus',
  'accent-grave',
  'acorn',
  'address-book',
  'contact-book',
  'address-card',
  'contact-card',
  'vcard',
  'air-conditioner',
  'airplay',
  'alarm-clock',
  'alarm-exclamation',
  'alarm-plus',
  'alarm-snooze',
  'album',
  'album-circle-plus',
  'album-circle-user',
  'album-collection',
  'album-collection-circle-plus',
  'album-collection-circle-user',
  'alicorn',
  'alien',
  'alien-8bit',
  'alien-monster',
  'align-center',
  'align-justify',
  'align-left',
  'align-right',
  'align-slash',
  'alt',
  'amp-guitar',
  'ampersand',
  'anchor',
  'angel',
  'angle',
  'angle-90',
  'angle-down',
  'angle-left',
  'angle-right',
  'angle-up',
  'angles-down',
  'angle-double-down',
  'angles-left',
  'angle-double-left',
  'angles-right',
  'angle-double-right',
  'angles-up',
  'angle-double-up',
  'ankh',
  'apartment',
  'aperture',
  'apostrophe',
  'apple-core',
  'apple-whole',
  'apple-alt',
  'archway',
  'arrow-down',
  'arrow-down-1-9',
  'sort-numeric-asc',
  'sort-numeric-down',
  'arrow-down-9-1',
  'sort-numeric-desc',
  'sort-numeric-down-alt',
  'arrow-down-a-z',
  'sort-alpha-asc',
  'sort-alpha-down',
  'arrow-down-arrow-up',
  'sort-alt',
  'arrow-down-big-small',
  'sort-size-down',
  'arrow-down-from-dotted-line',
  'arrow-down-from-line',
  'arrow-from-top',
  'arrow-down-left',
  'arrow-down-left-and-arrow-up-right-to-center',
  'arrow-down-long',
  'long-arrow-down',
  'arrow-down-right',
  'arrow-down-short-wide',
  'sort-amount-desc',
  'sort-amount-down-alt',
  'arrow-down-small-big',
  'sort-size-down-alt',
  'arrow-down-square-triangle',
  'sort-shapes-down-alt',
  'arrow-down-to-bracket',
  'arrow-down-to-dotted-line',
  'arrow-down-to-line',
  'arrow-to-bottom',
  'arrow-down-to-square',
  'arrow-down-triangle-square',
  'sort-shapes-down',
  'arrow-down-wide-short',
  'sort-amount-asc',
  'sort-amount-down',
  'arrow-down-z-a',
  'sort-alpha-desc',
  'sort-alpha-down-alt',
  'arrow-left',
  'arrow-left-from-line',
  'arrow-from-right',
  'arrow-left-long',
  'long-arrow-left',
  'arrow-left-long-to-line',
  'arrow-left-to-line',
  'arrow-to-left',
  'arrow-pointer',
  'mouse-pointer',
  'arrow-right',
  'arrow-right-arrow-left',
  'exchange',
  'arrow-right-from-bracket',
  'sign-out',
  'arrow-right-from-line',
  'arrow-from-left',
  'arrow-right-long',
  'long-arrow-right',
  'arrow-right-long-to-line',
  'arrow-right-to-bracket',
  'sign-in',
  'arrow-right-to-line',
  'arrow-to-right',
  'arrow-rotate-left',
  'arrow-left-rotate',
  'arrow-rotate-back',
  'arrow-rotate-backward',
  'undo',
  'arrow-rotate-right',
  'arrow-right-rotate',
  'arrow-rotate-forward',
  'redo',
  'arrow-trend-down',
  'arrow-trend-up',
  'arrow-turn-down',
  'level-down',
  'arrow-turn-down-left',
  'arrow-turn-down-right',
  'arrow-turn-up',
  'level-up',
  'arrow-up',
  'arrow-up-1-9',
  'sort-numeric-up',
  'arrow-up-9-1',
  'sort-numeric-up-alt',
  'arrow-up-a-z',
  'sort-alpha-up',
  'arrow-up-arrow-down',
  'sort-up-down',
  'arrow-up-big-small',
  'sort-size-up',
  'arrow-up-from-bracket',
  'arrow-up-from-dotted-line',
  'arrow-up-from-line',
  'arrow-from-bottom',
  'arrow-up-from-square',
  'arrow-up-left',
  'arrow-up-left-from-circle',
  'arrow-up-long',
  'long-arrow-up',
  'arrow-up-right',
  'arrow-up-right-and-arrow-down-left-from-center',
  'arrow-up-right-from-square',
  'external-link',
  'arrow-up-short-wide',
  'sort-amount-up-alt',
  'arrow-up-small-big',
  'sort-size-up-alt',
  'arrow-up-square-triangle',
  'sort-shapes-up-alt',
  'arrow-up-to-dotted-line',
  'arrow-up-to-line',
  'arrow-to-top',
  'arrow-up-triangle-square',
  'sort-shapes-up',
  'arrow-up-wide-short',
  'sort-amount-up',
  'arrow-up-z-a',
  'sort-alpha-up-alt',
  'arrows-cross',
  'arrows-from-dotted-line',
  'arrows-from-line',
  'arrows-left-right',
  'arrows-h',
  'arrows-maximize',
  'expand-arrows',
  'arrows-minimize',
  'compress-arrows',
  'arrows-repeat',
  'repeat-alt',
  'arrows-repeat-1',
  'repeat1-alt',
  'arrows-retweet',
  'retweet-alt',
  'arrows-rotate',
  'refresh',
  'sync',
  'arrows-to-dotted-line',
  'arrows-to-line',
  'arrows-up-down',
  'arrows-v',
  'arrows-up-down-left-right',
  'arrows',
  'asterisk',
  'at',
  'atom',
  'atom-simple',
  'atom-alt',
  'audio-description',
  'audio-description-slash',
  'austral-sign',
  'avocado',
  'award',
  'award-simple',
  'axe',
  'axe-battle',
  'b',
  'baby',
  'baby-carriage',
  'carriage-baby',
  'backpack',
  'backward',
  'backward-fast',
  'fast-backward',
  'backward-step',
  'step-backward',
  'bacon',
  'bacteria',
  'bacterium',
  'badge',
  'badge-check',
  'badge-dollar',
  'badge-percent',
  'badge-sheriff',
  'badger-honey',
  'badminton',
  'bag-shopping',
  'shopping-bag',
  'bagel',
  'bags-shopping',
  'baguette',
  'bahai',
  'baht-sign',
  'ball-pile',
  'balloon',
  'balloons',
  'ballot',
  'ballot-check',
  'ban',
  'cancel',
  'ban-bug',
  'debug',
  'ban-parking',
  'parking-circle-slash',
  'ban-smoking',
  'smoking-ban',
  'banana',
  'bandage',
  'band-aid',
  'bangladeshi-taka-sign',
  'banjo',
  'barcode',
  'barcode-read',
  'barcode-scan',
  'bars',
  'navicon',
  'bars-filter',
  'bars-progress',
  'tasks-alt',
  'bars-sort',
  'bars-staggered',
  'reorder',
  'stream',
  'baseball',
  'baseball-ball',
  'baseball-bat-ball',
  'basket-shopping',
  'shopping-basket',
  'basket-shopping-simple',
  'shopping-basket-alt',
  'basketball',
  'basketball-ball',
  'basketball-hoop',
  'bat',
  'bath',
  'bathtub',
  'battery-bolt',
  'battery-empty',
  'battery0',
  'battery-exclamation',
  'battery-full',
  'battery',
  'battery5',
  'battery-half',
  'battery3',
  'battery-low',
  'battery1',
  'battery-quarter',
  'battery2',
  'battery-slash',
  'battery-three-quarters',
  'battery4',
  'bed',
  'bed-bunk',
  'bed-empty',
  'bed-front',
  'bed-alt',
  'bed-pulse',
  'procedures',
  'bee',
  'beer-mug',
  'beer-foam',
  'beer-mug-empty',
  'beer',
  'bell',
  'bell-concierge',
  'concierge-bell',
  'bell-exclamation',
  'bell-on',
  'bell-plus',
  'bell-school',
  'bell-school-slash',
  'bell-slash',
  'bells',
  'bench-tree',
  'bezier-curve',
  'bicycle',
  'binary',
  'binary-circle-check',
  'binary-lock',
  'binary-slash',
  'binoculars',
  'biohazard',
  'bird',
  'bitcoin-sign',
  'blanket',
  'blanket-fire',
  'blender',
  'blender-phone',
  'blinds',
  'blinds-open',
  'blinds-raised',
  'block',
  'block-brick',
  'wall-brick',
  'block-brick-fire',
  'firewall',
  'block-question',
  'block-quote',
  'blog',
  'blueberries',
  'bluetooth',
  'bold',
  'bolt',
  'zap',
  'bolt-auto',
  'bolt-lightning',
  'bolt-slash',
  'bomb',
  'bone',
  'bone-break',
  'bong',
  'book',
  'book-arrow-right',
  'book-arrow-up',
  'book-atlas',
  'atlas',
  'book-bible',
  'bible',
  'book-blank',
  'book-alt',
  'book-bookmark',
  'book-circle-arrow-right',
  'book-circle-arrow-up',
  'book-copy',
  'book-font',
  'book-heart',
  'book-journal-whills',
  'journal-whills',
  'book-medical',
  'book-open',
  'book-open-cover',
  'book-open-alt',
  'book-open-reader',
  'book-reader',
  'book-quran',
  'quran',
  'book-section',
  'book-law',
  'book-skull',
  'book-dead',
  'book-sparkles',
  'book-spells',
  'book-tanakh',
  'tanakh',
  'book-user',
  'bookmark',
  'bookmark-slash',
  'books',
  'books-medical',
  'boombox',
  'boot',
  'boot-heeled',
  'booth-curtain',
  'border-all',
  'border-bottom',
  'border-bottom-right',
  'border-style-alt',
  'border-center-h',
  'border-center-v',
  'border-inner',
  'border-left',
  'border-none',
  'border-outer',
  'border-right',
  'border-top',
  'border-top-left',
  'border-style',
  'bow-arrow',
  'bowl-chopsticks',
  'bowl-chopsticks-noodles',
  'bowl-hot',
  'soup',
  'bowl-rice',
  'bowl-scoop',
  'bowl-shaved-ice',
  'bowl-scoops',
  'bowl-soft-serve',
  'bowl-spoon',
  'bowling-ball',
  'bowling-ball-pin',
  'bowling-pins',
  'box',
  'box-archive',
  'archive',
  'box-ballot',
  'box-check',
  'box-circle-check',
  'box-dollar',
  'box-usd',
  'box-heart',
  'box-open',
  'box-open-full',
  'box-full',
  'box-taped',
  'box-alt',
  'box-tissue',
  'boxes-stacked',
  'boxes',
  'boxes-alt',
  'boxing-glove',
  'glove-boxing',
  'bracket-curly',
  'bracket-curly-left',
  'bracket-curly-right',
  'bracket-round',
  'parenthesis',
  'bracket-round-right',
  'bracket-square',
  'bracket',
  'bracket-left',
  'bracket-square-right',
  'brackets-curly',
  'brackets-round',
  'parentheses',
  'brackets-square',
  'brackets',
  'braille',
  'brain',
  'brain-arrow-curved-right',
  'mind-share',
  'brain-circuit',
  'brake-warning',
  'brazilian-real-sign',
  'bread-loaf',
  'bread-slice',
  'bread-slice-butter',
  'briefcase',
  'briefcase-arrow-right',
  'briefcase-blank',
  'briefcase-medical',
  'brightness',
  'brightness-low',
  'bring-forward',
  'bring-front',
  'broccoli',
  'broom',
  'broom-ball',
  'quidditch',
  'quidditch-broom-ball',
  'browser',
  'browsers',
  'brush',
  'bug',
  'bug-slash',
  'building',
  'building-columns',
  'bank',
  'institution',
  'museum',
  'university',
  'buildings',
  'bullhorn',
  'bullseye',
  'bullseye-arrow',
  'bullseye-pointer',
  'burger',
  'hamburger',
  'burger-cheese',
  'cheeseburger',
  'burger-fries',
  'burger-glass',
  'burger-lettuce',
  'burger-soda',
  'burrito',
  'bus',
  'bus-school',
  'bus-simple',
  'bus-alt',
  'business-time',
  'briefcase-clock',
  'butter',
  'c',
  'cabin',
  'cabinet-filing',
  'cable-car',
  'cactus',
  'cake-candles',
  'birthday-cake',
  'cake',
  'cake-slice',
  'shortcake',
  'calculator',
  'calculator-simple',
  'calculator-alt',
  'calendar',
  'calendar-arrow-down',
  'calendar-download',
  'calendar-arrow-up',
  'calendar-upload',
  'calendar-check',
  'calendar-circle-exclamation',
  'calendar-circle-minus',
  'calendar-circle-plus',
  'calendar-circle-user',
  'calendar-clock',
  'calendar-time',
  'calendar-day',
  'calendar-days',
  'calendar-alt',
  'calendar-exclamation',
  'calendar-heart',
  'calendar-image',
  'calendar-lines',
  'calendar-note',
  'calendar-lines-pen',
  'calendar-minus',
  'calendar-pen',
  'calendar-edit',
  'calendar-plus',
  'calendar-range',
  'calendar-star',
  'calendar-week',
  'calendar-xmark',
  'calendar-times',
  'calendars',
  'camcorder',
  'video-handheld',
  'camera',
  'camera-alt',
  'camera-cctv',
  'cctv',
  'camera-movie',
  'camera-polaroid',
  'camera-retro',
  'camera-rotate',
  'camera-security',
  'camera-home',
  'camera-slash',
  'camera-viewfinder',
  'screenshot',
  'camera-web',
  'webcam',
  'camera-web-slash',
  'webcam-slash',
  'campfire',
  'campground',
  'can-food',
  'candle-holder',
  'candy',
  'candy-bar',
  'chocolate-bar',
  'candy-cane',
  'candy-corn',
  'cannabis',
  'capsules',
  'car',
  'automobile',
  'car-battery',
  'battery-car',
  'car-bolt',
  'car-building',
  'car-bump',
  'car-bus',
  'car-circle-bolt',
  'car-crash',
  'car-garage',
  'car-mirrors',
  'car-rear',
  'car-alt',
  'car-side',
  'car-side-bolt',
  'car-tilt',
  'car-wash',
  'car-wrench',
  'car-mechanic',
  'caravan',
  'caravan-simple',
  'caravan-alt',
  'card-club',
  'card-diamond',
  'card-heart',
  'card-spade',
  'cards',
  'caret-down',
  'caret-left',
  'caret-right',
  'caret-up',
  'carrot',
  'cars',
  'cart-arrow-down',
  'cart-arrow-up',
  'cart-circle-arrow-down',
  'cart-circle-arrow-up',
  'cart-circle-check',
  'cart-circle-exclamation',
  'cart-circle-plus',
  'cart-circle-xmark',
  'cart-flatbed',
  'dolly-flatbed',
  'cart-flatbed-boxes',
  'dolly-flatbed-alt',
  'cart-flatbed-empty',
  'dolly-flatbed-empty',
  'cart-flatbed-suitcase',
  'luggage-cart',
  'cart-minus',
  'cart-plus',
  'cart-shopping',
  'shopping-cart',
  'cart-shopping-fast',
  'cart-xmark',
  'cash-register',
  'cassette-betamax',
  'betamax',
  'cassette-tape',
  'cassette-vhs',
  'vhs',
  'castle',
  'cat',
  'cat-space',
  'cauldron',
  'cedi-sign',
  'cent-sign',
  'certificate',
  'chair',
  'chair-office',
  'chalkboard',
  'blackboard',
  'chalkboard-user',
  'chalkboard-teacher',
  'champagne-glass',
  'glass-champagne',
  'champagne-glasses',
  'glass-cheers',
  'charging-station',
  'chart-area',
  'area-chart',
  'chart-bar',
  'bar-chart',
  'chart-bullet',
  'chart-candlestick',
  'chart-column',
  'chart-gantt',
  'chart-line',
  'line-chart',
  'chart-line-down',
  'chart-line-up',
  'chart-mixed',
  'analytics',
  'chart-network',
  'chart-pie',
  'pie-chart',
  'chart-pie-simple',
  'chart-pie-alt',
  'chart-pyramid',
  'chart-radar',
  'chart-scatter',
  'chart-scatter-3d',
  'chart-scatter-bubble',
  'chart-simple',
  'chart-simple-horizontal',
  'chart-tree-map',
  'chart-user',
  'user-chart',
  'chart-waterfall',
  'check',
  'check-double',
  'check-to-slot',
  'vote-yea',
  'cheese',
  'cheese-swiss',
  'cherries',
  'chess',
  'chess-bishop',
  'chess-bishop-piece',
  'chess-bishop-alt',
  'chess-board',
  'chess-clock',
  'chess-clock-flip',
  'chess-clock-alt',
  'chess-king',
  'chess-king-piece',
  'chess-king-alt',
  'chess-knight',
  'chess-knight-piece',
  'chess-knight-alt',
  'chess-pawn',
  'chess-pawn-piece',
  'chess-pawn-alt',
  'chess-queen',
  'chess-queen-piece',
  'chess-queen-alt',
  'chess-rook',
  'chess-rook-piece',
  'chess-rook-alt',
  'chestnut',
  'chevron-down',
  'chevron-left',
  'chevron-right',
  'chevron-up',
  'chevrons-down',
  'chevron-double-down',
  'chevrons-left',
  'chevron-double-left',
  'chevrons-right',
  'chevron-double-right',
  'chevrons-up',
  'chevron-double-up',
  'child',
  'chimney',
  'chopsticks',
  'church',
  'circle',
  'circle-0',
  'circle-1',
  'circle-2',
  'circle-3',
  'circle-4',
  'circle-5',
  'circle-6',
  'circle-7',
  'circle-8',
  'circle-9',
  'circle-a',
  'circle-ampersand',
  'circle-arrow-down',
  'arrow-circle-down',
  'circle-arrow-down-left',
  'circle-arrow-down-right',
  'circle-arrow-left',
  'arrow-circle-left',
  'circle-arrow-right',
  'arrow-circle-right',
  'circle-arrow-up',
  'arrow-circle-up',
  'circle-arrow-up-left',
  'circle-arrow-up-right',
  'circle-b',
  'circle-bolt',
  'circle-book-open',
  'book-circle',
  'circle-bookmark',
  'bookmark-circle',
  'circle-c',
  'circle-calendar',
  'calendar-circle',
  'circle-camera',
  'camera-circle',
  'circle-caret-down',
  'caret-circle-down',
  'circle-caret-left',
  'caret-circle-left',
  'circle-caret-right',
  'caret-circle-right',
  'circle-caret-up',
  'caret-circle-up',
  'circle-check',
  'check-circle',
  'circle-chevron-down',
  'chevron-circle-down',
  'circle-chevron-left',
  'chevron-circle-left',
  'circle-chevron-right',
  'chevron-circle-right',
  'circle-chevron-up',
  'chevron-circle-up',
  'circle-d',
  'circle-dashed',
  'circle-divide',
  'circle-dollar',
  'dollar-circle',
  'usd-circle',
  'circle-dollar-to-slot',
  'donate',
  'circle-dot',
  'dot-circle',
  'circle-down',
  'arrow-alt-circle-down',
  'circle-down-left',
  'circle-down-right',
  'circle-e',
  'circle-ellipsis',
  'circle-ellipsis-vertical',
  'circle-envelope',
  'envelope-circle',
  'circle-exclamation',
  'exclamation-circle',
  'circle-exclamation-check',
  'circle-f',
  'circle-g',
  'circle-h',
  'hospital-symbol',
  'circle-half',
  'circle-half-stroke',
  'adjust',
  'circle-heart',
  'heart-circle',
  'circle-i',
  'circle-info',
  'info-circle',
  'circle-j',
  'circle-k',
  'circle-l',
  'circle-left',
  'arrow-alt-circle-left',
  'circle-location-arrow',
  'location-circle',
  'circle-m',
  'circle-microphone',
  'microphone-circle',
  'circle-microphone-lines',
  'microphone-circle-alt',
  'circle-minus',
  'minus-circle',
  'circle-n',
  'circle-notch',
  'circle-o',
  'circle-p',
  'circle-parking',
  'parking-circle',
  'circle-pause',
  'pause-circle',
  'circle-phone',
  'phone-circle',
  'circle-phone-flip',
  'phone-circle-alt',
  'circle-phone-hangup',
  'phone-circle-down',
  'circle-play',
  'play-circle',
  'circle-plus',
  'plus-circle',
  'circle-q',
  'circle-quarter',
  'circle-quarters',
  'circle-question',
  'question-circle',
  'circle-r',
  'circle-radiation',
  'radiation-alt',
  'circle-right',
  'arrow-alt-circle-right',
  'circle-s',
  'circle-small',
  'circle-sort',
  'sort-circle',
  'circle-sort-down',
  'sort-circle-down',
  'circle-sort-up',
  'sort-circle-up',
  'circle-star',
  'star-circle',
  'circle-stop',
  'stop-circle',
  'circle-t',
  'circle-three-quarters',
  'circle-trash',
  'trash-circle',
  'circle-u',
  'circle-up',
  'arrow-alt-circle-up',
  'circle-up-left',
  'circle-up-right',
  'circle-user',
  'user-circle',
  'circle-v',
  'circle-video',
  'video-circle',
  'circle-w',
  'circle-waveform-lines',
  'waveform-circle',
  'circle-x',
  'circle-xmark',
  'times-circle',
  'xmark-circle',
  'circle-y',
  'circle-z',
  'citrus',
  'citrus-slice',
  'city',
  'clapperboard',
  'clapperboard-play',
  'clarinet',
  'claw-marks',
  'clipboard',
  'clipboard-check',
  'clipboard-list',
  'clipboard-list-check',
  'clipboard-medical',
  'clipboard-prescription',
  'clipboard-user',
  'clock',
  'clock-four',
  'clock-desk',
  'clock-eight',
  'clock-eight-thirty',
  'clock-eleven',
  'clock-eleven-thirty',
  'clock-five',
  'clock-five-thirty',
  'clock-four-thirty',
  'clock-nine',
  'clock-nine-thirty',
  'clock-one',
  'clock-one-thirty',
  'clock-rotate-left',
  'history',
  'clock-seven',
  'clock-seven-thirty',
  'clock-six',
  'clock-six-thirty',
  'clock-ten',
  'clock-ten-thirty',
  'clock-three',
  'clock-three-thirty',
  'clock-twelve',
  'clock-twelve-thirty',
  'clock-two',
  'clock-two-thirty',
  'clone',
  'closed-captioning',
  'closed-captioning-slash',
  'clothes-hanger',
  'cloud',
  'cloud-arrow-down',
  'cloud-download',
  'cloud-download-alt',
  'cloud-arrow-up',
  'cloud-upload',
  'cloud-upload-alt',
  'cloud-bolt',
  'thunderstorm',
  'cloud-bolt-moon',
  'thunderstorm-moon',
  'cloud-bolt-sun',
  'thunderstorm-sun',
  'cloud-check',
  'cloud-drizzle',
  'cloud-exclamation',
  'cloud-fog',
  'fog',
  'cloud-hail',
  'cloud-hail-mixed',
  'cloud-meatball',
  'cloud-minus',
  'cloud-moon',
  'cloud-moon-rain',
  'cloud-music',
  'cloud-plus',
  'cloud-question',
  'cloud-rain',
  'cloud-rainbow',
  'cloud-showers',
  'cloud-showers-heavy',
  'cloud-slash',
  'cloud-sleet',
  'cloud-snow',
  'cloud-sun',
  'cloud-sun-rain',
  'cloud-word',
  'cloud-xmark',
  'clouds',
  'clouds-moon',
  'clouds-sun',
  'clover',
  'club',
  'coconut',
  'code',
  'code-branch',
  'code-commit',
  'code-compare',
  'code-fork',
  'code-merge',
  'code-pull-request',
  'code-pull-request-closed',
  'code-pull-request-draft',
  'code-simple',
  'coffee-bean',
  'coffee-beans',
  'coffee-pot',
  'coffin',
  'coffin-cross',
  'coin',
  'coin-blank',
  'coin-front',
  'coin-vertical',
  'coins',
  'colon',
  'colon-sign',
  'columns-3',
  'comet',
  'comma',
  'command',
  'comment',
  'comment-arrow-down',
  'comment-arrow-up',
  'comment-arrow-up-right',
  'comment-captions',
  'comment-check',
  'comment-code',
  'comment-dollar',
  'comment-dots',
  'commenting',
  'comment-exclamation',
  'comment-image',
  'comment-lines',
  'comment-medical',
  'comment-middle',
  'comment-middle-top',
  'comment-minus',
  'comment-music',
  'comment-pen',
  'comment-edit',
  'comment-plus',
  'comment-question',
  'comment-quote',
  'comment-slash',
  'comment-smile',
  'comment-sms',
  'sms',
  'comment-text',
  'comment-xmark',
  'comment-times',
  'comments',
  'comments-dollar',
  'comments-question',
  'comments-question-check',
  'compact-disc',
  'compass',
  'compass-drafting',
  'drafting-compass',
  'compass-slash',
  'compress',
  'compress-wide',
  'computer-classic',
  'computer-mouse',
  'mouse',
  'computer-mouse-scrollwheel',
  'mouse-alt',
  'computer-speaker',
  'container-storage',
  'conveyor-belt',
  'conveyor-belt-boxes',
  'conveyor-belt-alt',
  'conveyor-belt-empty',
  'cookie',
  'cookie-bite',
  'copy',
  'copyright',
  'corn',
  'corner',
  'couch',
  'cow',
  'cowbell',
  'cowbell-circle-plus',
  'cowbell-more',
  'crab',
  'crate-apple',
  'apple-crate',
  'crate-empty',
  'credit-card',
  'credit-card-alt',
  'credit-card-blank',
  'credit-card-front',
  'cricket-bat-ball',
  'cricket',
  'croissant',
  'crop',
  'crop-simple',
  'crop-alt',
  'cross',
  'crosshairs',
  'crow',
  'crown',
  'crutch',
  'crutches',
  'cruzeiro-sign',
  'crystal-ball',
  'cube',
  'cubes',
  'cucumber',
  'cup-straw',
  'cup-straw-swoosh',
  'cup-togo',
  'coffee-togo',
  'cupcake',
  'curling-stone',
  'curling',
  'custard',
  'd',
  'dagger',
  'dash',
  'database',
  'deer',
  'deer-rudolph',
  'delete-left',
  'backspace',
  'delete-right',
  'democrat',
  'desktop',
  'desktop-alt',
  'desktop-arrow-down',
  'dharmachakra',
  'diagram-cells',
  'diagram-lean-canvas',
  'diagram-nested',
  'diagram-next',
  'diagram-predecessor',
  'diagram-previous',
  'diagram-project',
  'project-diagram',
  'diagram-sankey',
  'diagram-subtask',
  'diagram-successor',
  'diagram-venn',
  'dial',
  'dial-med-high',
  'dial-high',
  'dial-low',
  'dial-max',
  'dial-med',
  'dial-med-low',
  'dial-min',
  'dial-off',
  'diamond',
  'diamond-exclamation',
  'diamond-turn-right',
  'directions',
  'dice',
  'dice-d10',
  'dice-d12',
  'dice-d20',
  'dice-d4',
  'dice-d6',
  'dice-d8',
  'dice-five',
  'dice-four',
  'dice-one',
  'dice-six',
  'dice-three',
  'dice-two',
  'diploma',
  'scroll-ribbon',
  'disc-drive',
  'disease',
  'display',
  'display-arrow-down',
  'display-code',
  'desktop-code',
  'display-medical',
  'desktop-medical',
  'display-slash',
  'desktop-slash',
  'distribute-spacing-horizontal',
  'distribute-spacing-vertical',
  'ditto',
  'divide',
  'dna',
  'do-not-enter',
  'dog',
  'dog-leashed',
  'dollar-sign',
  'dollar',
  'usd',
  'dolly',
  'dolly-box',
  'dolly-empty',
  'dolphin',
  'dong-sign',
  'donut',
  'doughnut',
  'door-closed',
  'door-open',
  'dove',
  'down',
  'arrow-alt-down',
  'down-from-dotted-line',
  'down-from-line',
  'arrow-alt-from-top',
  'down-left',
  'down-left-and-up-right-to-center',
  'compress-alt',
  'down-long',
  'long-arrow-alt-down',
  'down-right',
  'down-to-dotted-line',
  'down-to-line',
  'arrow-alt-to-bottom',
  'download',
  'dragon',
  'draw-circle',
  'draw-polygon',
  'draw-square',
  'dreidel',
  'drone',
  'drone-front',
  'drone-alt',
  'droplet',
  'tint',
  'droplet-degree',
  'dewpoint',
  'droplet-percent',
  'humidity',
  'droplet-slash',
  'tint-slash',
  'drum',
  'drum-steelpan',
  'drumstick',
  'drumstick-bite',
  'dryer',
  'dryer-heat',
  'dryer-alt',
  'duck',
  'dumbbell',
  'dumpster',
  'dumpster-fire',
  'dungeon',
  'e',
  'ear',
  'ear-deaf',
  'deaf',
  'deafness',
  'hard-of-hearing',
  'ear-listen',
  'assistive-listening-systems',
  'ear-muffs',
  'earth-africa',
  'globe-africa',
  'earth-americas',
  'earth',
  'earth-america',
  'globe-americas',
  'earth-asia',
  'globe-asia',
  'earth-europe',
  'globe-europe',
  'earth-oceania',
  'globe-oceania',
  'eclipse',
  'egg',
  'egg-fried',
  'eggplant',
  'eject',
  'elephant',
  'elevator',
  'ellipsis',
  'ellipsis-h',
  'ellipsis-stroke',
  'ellipsis-h-alt',
  'ellipsis-stroke-vertical',
  'ellipsis-v-alt',
  'ellipsis-vertical',
  'ellipsis-v',
  'empty-set',
  'engine',
  'engine-warning',
  'engine-exclamation',
  'envelope',
  'envelope-dot',
  'envelope-badge',
  'envelope-open',
  'envelope-open-dollar',
  'envelope-open-text',
  'envelopes',
  'envelopes-bulk',
  'mail-bulk',
  'equals',
  'eraser',
  'escalator',
  'ethernet',
  'euro-sign',
  'eur',
  'euro',
  'exclamation',
  'expand',
  'expand-wide',
  'eye',
  'eye-dropper',
  'eye-dropper-empty',
  'eyedropper',
  'eye-dropper-full',
  'eye-dropper-half',
  'eye-evil',
  'eye-low-vision',
  'low-vision',
  'eye-slash',
  'eyes',
  'f',
  'face-angry',
  'angry',
  'face-angry-horns',
  'face-anguished',
  'face-anxious-sweat',
  'face-astonished',
  'face-awesome',
  'gave-dandy',
  'face-beam-hand-over-mouth',
  'face-clouds',
  'face-confounded',
  'face-confused',
  'face-cowboy-hat',
  'face-diagonal-mouth',
  'face-disappointed',
  'face-disguise',
  'face-dizzy',
  'dizzy',
  'face-dotted',
  'face-downcast-sweat',
  'face-drooling',
  'face-exhaling',
  'face-explode',
  'exploding-head',
  'face-expressionless',
  'face-eyes-xmarks',
  'face-fearful',
  'face-flushed',
  'flushed',
  'face-frown',
  'frown',
  'face-frown-open',
  'frown-open',
  'face-frown-slight',
  'face-glasses',
  'face-grimace',
  'grimace',
  'face-grin',
  'grin',
  'face-grin-beam',
  'grin-beam',
  'face-grin-beam-sweat',
  'grin-beam-sweat',
  'face-grin-hearts',
  'grin-hearts',
  'face-grin-squint',
  'grin-squint',
  'face-grin-squint-tears',
  'grin-squint-tears',
  'face-grin-stars',
  'grin-stars',
  'face-grin-tears',
  'grin-tears',
  'face-grin-tongue',
  'grin-tongue',
  'face-grin-tongue-squint',
  'grin-tongue-squint',
  'face-grin-tongue-wink',
  'grin-tongue-wink',
  'face-grin-wide',
  'grin-alt',
  'face-grin-wink',
  'grin-wink',
  'face-hand-over-mouth',
  'face-hand-peeking',
  'face-hand-yawn',
  'face-head-bandage',
  'face-holding-back-tears',
  'face-hushed',
  'face-icicles',
  'face-kiss',
  'kiss',
  'face-kiss-beam',
  'kiss-beam',
  'face-kiss-closed-eyes',
  'face-kiss-wink-heart',
  'kiss-wink-heart',
  'face-laugh',
  'laugh',
  'face-laugh-beam',
  'laugh-beam',
  'face-laugh-squint',
  'laugh-squint',
  'face-laugh-wink',
  'laugh-wink',
  'face-lying',
  'face-mask',
  'face-meh',
  'meh',
  'face-meh-blank',
  'meh-blank',
  'face-melting',
  'face-monocle',
  'face-nauseated',
  'face-nose-steam',
  'face-party',
  'face-pensive',
  'face-persevering',
  'face-pleading',
  'face-pouting',
  'face-raised-eyebrow',
  'face-relieved',
  'face-rolling-eyes',
  'meh-rolling-eyes',
  'face-sad-cry',
  'sad-cry',
  'face-sad-sweat',
  'face-sad-tear',
  'sad-tear',
  'face-saluting',
  'face-scream',
  'face-shush',
  'face-sleeping',
  'face-sleepy',
  'face-smile',
  'smile',
  'face-smile-beam',
  'smile-beam',
  'face-smile-halo',
  'face-smile-hearts',
  'face-smile-horns',
  'face-smile-plus',
  'smile-plus',
  'face-smile-relaxed',
  'face-smile-tear',
  'face-smile-tongue',
  'face-smile-upside-down',
  'face-smile-wink',
  'smile-wink',
  'face-smiling-hands',
  'face-smirking',
  'face-spiral-eyes',
  'face-sunglasses',
  'face-surprise',
  'surprise',
  'face-swear',
  'face-thermometer',
  'face-thinking',
  'face-tired',
  'tired',
  'face-tissue',
  'face-tongue-money',
  'face-tongue-sweat',
  'face-unamused',
  'face-viewfinder',
  'face-vomit',
  'face-weary',
  'face-woozy',
  'face-worried',
  'face-zany',
  'face-zipper',
  'falafel',
  'family',
  'family-dress',
  'family-pants',
  'fan',
  'fan-table',
  'farm',
  'barn-silo',
  'faucet',
  'faucet-drip',
  'fax',
  'feather',
  'feather-pointed',
  'feather-alt',
  'fence',
  'ferris-wheel',
  'field-hockey-stick-ball',
  'field-hockey',
  'file',
  'file-arrow-down',
  'file-download',
  'file-arrow-up',
  'file-upload',
  'file-audio',
  'file-binary',
  'file-certificate',
  'file-award',
  'file-chart-column',
  'file-chart-line',
  'file-chart-pie',
  'file-check',
  'file-circle-info',
  'file-circle-plus',
  'file-code',
  'file-contract',
  'file-csv',
  'file-dashed-line',
  'page-break',
  'file-excel',
  'file-exclamation',
  'file-export',
  'arrow-right-from-file',
  'file-heart',
  'file-image',
  'file-import',
  'arrow-right-to-file',
  'file-invoice',
  'file-invoice-dollar',
  'file-lines',
  'file-alt',
  'file-text',
  'file-lock',
  'file-magnifying-glass',
  'file-search',
  'file-medical',
  'file-minus',
  'file-music',
  'file-pdf',
  'file-pen',
  'file-edit',
  'file-plus',
  'file-plus-minus',
  'file-powerpoint',
  'file-prescription',
  'file-signature',
  'file-slash',
  'file-spreadsheet',
  'file-user',
  'file-video',
  'file-waveform',
  'file-medical-alt',
  'file-word',
  'file-xmark',
  'file-times',
  'file-zipper',
  'file-archive',
  'files',
  'files-medical',
  'fill',
  'fill-drip',
  'film',
  'film-canister',
  'film-simple',
  'film-alt',
  'film-slash',
  'films',
  'filter',
  'filter-circle-dollar',
  'funnel-dollar',
  'filter-circle-xmark',
  'filter-list',
  'filter-slash',
  'filters',
  'fingerprint',
  'fire',
  'fire-extinguisher',
  'fire-flame',
  'flame',
  'fire-flame-curved',
  'fire-alt',
  'fire-flame-simple',
  'burn',
  'fire-hydrant',
  'fire-smoke',
  'fireplace',
  'fish',
  'fish-bones',
  'fish-cooked',
  'fishing-rod',
  'flag',
  'flag-checkered',
  'flag-pennant',
  'pennant',
  'flag-swallowtail',
  'flag-alt',
  'flag-usa',
  'flashlight',
  'flask',
  'flask-round-poison',
  'flask-poison',
  'flask-round-potion',
  'flask-potion',
  'flatbread',
  'flatbread-stuffed',
  'floppy-disk',
  'save',
  'floppy-disk-circle-arrow-right',
  'save-circle-arrow-right',
  'floppy-disk-circle-xmark',
  'floppy-disk-times',
  'save-circle-xmark',
  'save-times',
  'floppy-disk-pen',
  'floppy-disks',
  'florin-sign',
  'flower',
  'flower-daffodil',
  'flower-tulip',
  'flute',
  'flux-capacitor',
  'flying-disc',
  'folder',
  'folder-arrow-down',
  'folder-download',
  'folder-arrow-up',
  'folder-upload',
  'folder-blank',
  'folder-bookmark',
  'folder-gear',
  'folder-cog',
  'folder-grid',
  'folder-heart',
  'folder-image',
  'folder-magnifying-glass',
  'folder-search',
  'folder-medical',
  'folder-minus',
  'folder-music',
  'folder-open',
  'folder-plus',
  'folder-tree',
  'folder-user',
  'folder-xmark',
  'folder-times',
  'folders',
  'fondue-pot',
  'font',
  'font-awesome',
  'font-awesome-flag',
  'font-awesome-logo-full',
  'font-case',
  'football',
  'football-ball',
  'football-helmet',
  'fork',
  'utensil-fork',
  'fork-knife',
  'utensils-alt',
  'forklift',
  'fort',
  'forward',
  'forward-fast',
  'fast-forward',
  'forward-step',
  'step-forward',
  'frame',
  'franc-sign',
  'french-fries',
  'frog',
  'function',
  'futbol',
  'futbol-ball',
  'soccer-ball',
  'g',
  'galaxy',
  'gallery-thumbnails',
  'game-board',
  'game-board-simple',
  'game-board-alt',
  'game-console-handheld',
  'gamepad',
  'gamepad-modern',
  'gamepad-alt',
  'garage',
  'garage-car',
  'garage-open',
  'garlic',
  'gas-pump',
  'gas-pump-slash',
  'gauge',
  'dashboard',
  'gauge-med',
  'tachometer-alt-average',
  'gauge-circle-bolt',
  'gauge-circle-minus',
  'gauge-circle-plus',
  'gauge-high',
  'tachometer-alt',
  'tachometer-alt-fast',
  'gauge-low',
  'tachometer-alt-slow',
  'gauge-max',
  'tachometer-alt-fastest',
  'gauge-min',
  'tachometer-alt-slowest',
  'gauge-simple',
  'gauge-simple-med',
  'tachometer-average',
  'gauge-simple-high',
  'tachometer',
  'tachometer-fast',
  'gauge-simple-low',
  'tachometer-slow',
  'gauge-simple-max',
  'tachometer-fastest',
  'gauge-simple-min',
  'tachometer-slowest',
  'gavel',
  'legal',
  'gear',
  'cog',
  'gears',
  'cogs',
  'gem',
  'genderless',
  'ghost',
  'gif',
  'gift',
  'gift-card',
  'gifts',
  'gingerbread-man',
  'glass',
  'glass-citrus',
  'glass-empty',
  'glass-half',
  'glass-half-empty',
  'glass-half-full',
  'glasses',
  'glasses-round',
  'glasses-alt',
  'globe',
  'globe-snow',
  'globe-stand',
  'goal-net',
  'golf-ball-tee',
  'golf-ball',
  'golf-club',
  'golf-flag-hole',
  'gopuram',
  'graduation-cap',
  'mortar-board',
  'gramophone',
  'grapes',
  'grate',
  'grate-droplet',
  'greater-than',
  'greater-than-equal',
  'grid',
  'grid3',
  'grid-2',
  'grid-2-plus',
  'grid-4',
  'grid-5',
  'grid-dividers',
  'grid-horizontal',
  'grip',
  'grip-horizontal',
  'grip-dots',
  'grip-dots-vertical',
  'grip-lines',
  'grip-lines-vertical',
  'grip-vertical',
  'guarani-sign',
  'guitar',
  'guitar-electric',
  'guitars',
  'gun',
  'gun-slash',
  'gun-squirt',
  'h',
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
  'hammer',
  'hammer-crash',
  'hammer-war',
  'hamsa',
  'hand',
  'hand-paper',
  'hand-back-fist',
  'hand-rock',
  'hand-back-point-down',
  'hand-back-point-left',
  'hand-back-point-ribbon',
  'hand-back-point-right',
  'hand-back-point-up',
  'hand-dots',
  'allergies',
  'hand-fingers-crossed',
  'hand-fist',
  'fist-raised',
  'hand-heart',
  'hand-holding',
  'hand-holding-box',
  'hand-holding-dollar',
  'hand-holding-usd',
  'hand-holding-droplet',
  'hand-holding-water',
  'hand-holding-heart',
  'hand-holding-magic',
  'hand-holding-medical',
  'hand-holding-seedling',
  'hand-holding-skull',
  'hand-horns',
  'hand-lizard',
  'hand-love',
  'hand-middle-finger',
  'hand-peace',
  'hand-point-down',
  'hand-point-left',
  'hand-point-ribbon',
  'hand-point-right',
  'hand-point-up',
  'hand-pointer',
  'hand-scissors',
  'hand-sparkles',
  'hand-spock',
  'hand-wave',
  'hands',
  'sign-language',
  'signing',
  'hands-asl-interpreting',
  'american-sign-language-interpreting',
  'asl-interpreting',
  'hands-american-sign-language-interpreting',
  'hands-bubbles',
  'hands-wash',
  'hands-clapping',
  'hands-holding',
  'hands-holding-diamond',
  'hand-receiving',
  'hands-holding-dollar',
  'hands-usd',
  'hands-holding-heart',
  'hands-heart',
  'hands-praying',
  'praying-hands',
  'handshake',
  'handshake-angle',
  'hands-helping',
  'handshake-simple',
  'handshake-alt',
  'handshake-simple-slash',
  'handshake-alt-slash',
  'handshake-slash',
  'hanukiah',
  'hard-drive',
  'hdd',
  'hashtag',
  'hashtag-lock',
  'hat-chef',
  'hat-cowboy',
  'hat-cowboy-side',
  'hat-santa',
  'hat-winter',
  'hat-witch',
  'hat-wizard',
  'head-side',
  'head-side-brain',
  'head-side-cough',
  'head-side-cough-slash',
  'head-side-goggles',
  'head-vr',
  'head-side-headphones',
  'head-side-heart',
  'head-side-mask',
  'head-side-medical',
  'head-side-virus',
  'heading',
  'header',
  'headphones',
  'headphones-simple',
  'headphones-alt',
  'headset',
  'heart',
  'heart-crack',
  'heart-broken',
  'heart-half',
  'heart-half-stroke',
  'heart-half-alt',
  'heart-pulse',
  'heartbeat',
  'heat',
  'helicopter',
  'helmet-battle',
  'helmet-safety',
  'hard-hat',
  'hat-hard',
  'hexagon',
  'hexagon-check',
  'hexagon-divide',
  'hexagon-exclamation',
  'hexagon-minus',
  'minus-hexagon',
  'hexagon-plus',
  'plus-hexagon',
  'hexagon-xmark',
  'times-hexagon',
  'xmark-hexagon',
  'high-definition',
  'rectangle-hd',
  'highlighter',
  'highlighter-line',
  'hippo',
  'hockey-mask',
  'hockey-puck',
  'hockey-stick-puck',
  'hockey-sticks',
  'holly-berry',
  'honey-pot',
  'hood-cloak',
  'horizontal-rule',
  'horse',
  'horse-head',
  'horse-saddle',
  'hose',
  'hose-reel',
  'hospital',
  'hospital-alt',
  'hospital-wide',
  'hospital-user',
  'hospitals',
  'hot-tub-person',
  'hot-tub',
  'hotdog',
  'hotel',
  'hourglass',
  'hourglass2',
  'hourglass-half',
  'hourglass-clock',
  'hourglass-empty',
  'hourglass-end',
  'hourglass3',
  'hourglass-start',
  'hourglass1',
  'house',
  'home',
  'home-alt',
  'home-lg-alt',
  'house-blank',
  'home-blank',
  'house-building',
  'house-chimney',
  'home-lg',
  'house-chimney-blank',
  'house-chimney-crack',
  'house-damage',
  'house-chimney-heart',
  'house-chimney-medical',
  'clinic-medical',
  'house-chimney-user',
  'house-chimney-window',
  'house-crack',
  'house-day',
  'house-flood',
  'house-heart',
  'home-heart',
  'house-laptop',
  'laptop-house',
  'house-medical',
  'house-night',
  'house-person-leave',
  'house-leave',
  'house-person-depart',
  'house-person-return',
  'house-person-arrive',
  'house-return',
  'house-signal',
  'house-tree',
  'house-turret',
  'house-user',
  'home-user',
  'house-window',
  'hryvnia-sign',
  'hryvnia',
  'hundred-points',
  '100',
  'hurricane',
  'hyphen',
  'i',
  'i-cursor',
  'ice-cream',
  'ice-skate',
  'icicles',
  'icons',
  'heart-music-camera-bolt',
  'id-badge',
  'id-card',
  'drivers-license',
  'id-card-clip',
  'id-card-alt',
  'igloo',
  'image',
  'image-landscape',
  'landscape',
  'image-polaroid',
  'image-polaroid-user',
  'image-portrait',
  'portrait',
  'image-slash',
  'image-user',
  'images',
  'images-user',
  'inbox',
  'inbox-full',
  'inbox-in',
  'inbox-arrow-down',
  'inbox-out',
  'inbox-arrow-up',
  'inboxes',
  'indent',
  'indian-rupee-sign',
  'indian-rupee',
  'inr',
  'industry',
  'industry-windows',
  'industry-alt',
  'infinity',
  'info',
  'inhaler',
  'input-numeric',
  'input-pipe',
  'input-text',
  'integral',
  'intersection',
  'island-tropical',
  'island-tree-palm',
  'italic',
  'j',
  'jack-o-lantern',
  'jedi',
  'jet-fighter',
  'fighter-jet',
  'joint',
  'joystick',
  'jug',
  'k',
  'kaaba',
  'kazoo',
  'kerning',
  'key',
  'key-skeleton',
  'key-skeleton-left-right',
  'keyboard',
  'keyboard-brightness',
  'keyboard-brightness-low',
  'keyboard-down',
  'keyboard-left',
  'keynote',
  'khanda',
  'kidneys',
  'kip-sign',
  'kit-medical',
  'first-aid',
  'kite',
  'kiwi-bird',
  'kiwi-fruit',
  'knife',
  'utensil-knife',
  'knife-kitchen',
  'l',
  'lacrosse-stick',
  'lacrosse-stick-ball',
  'lambda',
  'lamp',
  'lamp-desk',
  'lamp-floor',
  'lamp-street',
  'landmark',
  'landmark-dome',
  'landmark-alt',
  'language',
  'laptop',
  'laptop-arrow-down',
  'laptop-code',
  'laptop-medical',
  'laptop-mobile',
  'phone-laptop',
  'laptop-slash',
  'lari-sign',
  'lasso',
  'lasso-sparkles',
  'layer-group',
  'layer-minus',
  'layer-group-minus',
  'layer-plus',
  'layer-group-plus',
  'leaf',
  'leaf-heart',
  'leaf-maple',
  'leaf-oak',
  'leafy-green',
  'left',
  'arrow-alt-left',
  'left-from-line',
  'arrow-alt-from-right',
  'left-long',
  'long-arrow-alt-left',
  'left-long-to-line',
  'left-right',
  'arrows-alt-h',
  'left-to-line',
  'arrow-alt-to-left',
  'lemon',
  'less-than',
  'less-than-equal',
  'life-ring',
  'light-ceiling',
  'light-emergency',
  'light-emergency-on',
  'light-switch',
  'light-switch-off',
  'light-switch-on',
  'lightbulb',
  'lightbulb-dollar',
  'lightbulb-exclamation',
  'lightbulb-exclamation-on',
  'lightbulb-on',
  'lightbulb-slash',
  'lights-holiday',
  'line-columns',
  'line-height',
  'link',
  'chain',
  'link-horizontal',
  'chain-horizontal',
  'link-horizontal-slash',
  'chain-horizontal-slash',
  'link-simple',
  'link-simple-slash',
  'link-slash',
  'chain-broken',
  'chain-slash',
  'unlink',
  'lips',
  'lira-sign',
  'list',
  'list-squares',
  'list-check',
  'tasks',
  'list-dropdown',
  'list-music',
  'list-ol',
  'list12',
  'list-numeric',
  'list-radio',
  'list-timeline',
  'list-tree',
  'list-ul',
  'list-dots',
  'litecoin-sign',
  'loader',
  'lobster',
  'location-arrow',
  'location-check',
  'map-marker-check',
  'location-crosshairs',
  'location',
  'location-crosshairs-slash',
  'location-slash',
  'location-dot',
  'map-marker-alt',
  'location-dot-slash',
  'map-marker-alt-slash',
  'location-exclamation',
  'map-marker-exclamation',
  'location-minus',
  'map-marker-minus',
  'location-pen',
  'map-marker-edit',
  'location-pin',
  'map-marker',
  'location-pin-slash',
  'map-marker-slash',
  'location-plus',
  'map-marker-plus',
  'location-question',
  'map-marker-question',
  'location-smile',
  'map-marker-smile',
  'location-xmark',
  'map-marker-times',
  'map-marker-xmark',
  'lock',
  'lock-a',
  'lock-hashtag',
  'lock-keyhole',
  'lock-alt',
  'lock-keyhole-open',
  'lock-open-alt',
  'lock-open',
  'lollipop',
  'lollypop',
  'loveseat',
  'couch-small',
  'luchador-mask',
  'luchador',
  'mask-luchador',
  'lungs',
  'lungs-virus',
  'm',
  'mace',
  'magnet',
  'magnifying-glass',
  'search',
  'magnifying-glass-dollar',
  'search-dollar',
  'magnifying-glass-location',
  'search-location',
  'magnifying-glass-minus',
  'search-minus',
  'magnifying-glass-plus',
  'search-plus',
  'mailbox',
  'manat-sign',
  'mandolin',
  'mango',
  'manhole',
  'map',
  'map-location',
  'map-marked',
  'map-location-dot',
  'map-marked-alt',
  'map-pin',
  'marker',
  'mars',
  'mars-and-venus',
  'mars-double',
  'mars-stroke',
  'mars-stroke-right',
  'mars-stroke-h',
  'mars-stroke-up',
  'mars-stroke-v',
  'martini-glass',
  'glass-martini-alt',
  'martini-glass-citrus',
  'cocktail',
  'martini-glass-empty',
  'glass-martini',
  'mask',
  'mask-face',
  'mask-snorkel',
  'masks-theater',
  'theater-masks',
  'maximize',
  'expand-arrows-alt',
  'meat',
  'medal',
  'megaphone',
  'melon',
  'melon-slice',
  'memo',
  'memo-circle-check',
  'memo-circle-info',
  'memo-pad',
  'memory',
  'menorah',
  'mercury',
  'message',
  'comment-alt',
  'message-arrow-down',
  'comment-alt-arrow-down',
  'message-arrow-up',
  'comment-alt-arrow-up',
  'message-arrow-up-right',
  'message-bot',
  'message-captions',
  'comment-alt-captions',
  'message-check',
  'comment-alt-check',
  'message-code',
  'message-dollar',
  'comment-alt-dollar',
  'message-dots',
  'comment-alt-dots',
  'messaging',
  'message-exclamation',
  'comment-alt-exclamation',
  'message-image',
  'comment-alt-image',
  'message-lines',
  'comment-alt-lines',
  'message-medical',
  'comment-alt-medical',
  'message-middle',
  'comment-middle-alt',
  'message-middle-top',
  'comment-middle-top-alt',
  'message-minus',
  'comment-alt-minus',
  'message-music',
  'comment-alt-music',
  'message-pen',
  'comment-alt-edit',
  'message-edit',
  'message-plus',
  'comment-alt-plus',
  'message-question',
  'message-quote',
  'comment-alt-quote',
  'message-slash',
  'comment-alt-slash',
  'message-smile',
  'comment-alt-smile',
  'message-sms',
  'message-text',
  'comment-alt-text',
  'message-xmark',
  'comment-alt-times',
  'message-times',
  'messages',
  'comments-alt',
  'messages-dollar',
  'comments-alt-dollar',
  'messages-question',
  'meteor',
  'meter',
  'meter-bolt',
  'meter-droplet',
  'meter-fire',
  'microchip',
  'microchip-ai',
  'microphone',
  'microphone-lines',
  'microphone-alt',
  'microphone-lines-slash',
  'microphone-alt-slash',
  'microphone-slash',
  'microphone-stand',
  'microscope',
  'microwave',
  'mill-sign',
  'minimize',
  'compress-arrows-alt',
  'minus',
  'subtract',
  'mistletoe',
  'mitten',
  'mobile',
  'mobile-android',
  'mobile-phone',
  'mobile-button',
  'mobile-notch',
  'mobile-iphone',
  'mobile-screen',
  'mobile-android-alt',
  'mobile-screen-button',
  'mobile-alt',
  'mobile-signal',
  'mobile-signal-out',
  'money-bill',
  'money-bill-1',
  'money-bill-alt',
  'money-bill-1-wave',
  'money-bill-wave-alt',
  'money-bill-simple',
  'money-bill-simple-wave',
  'money-bill-wave',
  'money-bills',
  'money-bills-simple',
  'money-bills-alt',
  'money-check',
  'money-check-dollar',
  'money-check-alt',
  'money-check-dollar-pen',
  'money-check-edit-alt',
  'money-check-pen',
  'money-check-edit',
  'money-from-bracket',
  'money-simple-from-bracket',
  'monitor-waveform',
  'monitor-heart-rate',
  'monkey',
  'monument',
  'moon',
  'moon-cloud',
  'moon-over-sun',
  'eclipse-alt',
  'moon-stars',
  'moped',
  'mortar-pestle',
  'mosque',
  'motorcycle',
  'mountain',
  'mountains',
  'mp3-player',
  'mug',
  'mug-hot',
  'mug-marshmallows',
  'mug-saucer',
  'coffee',
  'mug-tea',
  'mug-tea-saucer',
  'mushroom',
  'music',
  'music-note',
  'music-alt',
  'music-note-slash',
  'music-alt-slash',
  'music-slash',
  'n',
  'naira-sign',
  'narwhal',
  'nesting-dolls',
  'network-wired',
  'neuter',
  'newspaper',
  'nfc',
  'nfc-lock',
  'nfc-magnifying-glass',
  'nfc-pen',
  'nfc-signal',
  'nfc-slash',
  'nfc-trash',
  'not-equal',
  'notdef',
  'note',
  'note-medical',
  'note-sticky',
  'sticky-note',
  'notebook',
  'notes',
  'notes-medical',
  'o',
  'object-exclude',
  'object-group',
  'object-intersect',
  'object-subtract',
  'object-ungroup',
  'object-union',
  'objects-align-bottom',
  'objects-align-center-horizontal',
  'objects-align-center-vertical',
  'objects-align-left',
  'objects-align-right',
  'objects-align-top',
  'objects-column',
  'octagon',
  'octagon-check',
  'octagon-divide',
  'octagon-exclamation',
  'octagon-minus',
  'minus-octagon',
  'octagon-plus',
  'plus-octagon',
  'octagon-xmark',
  'times-octagon',
  'xmark-octagon',
  'oil-can',
  'oil-can-drip',
  'oil-temperature',
  'oil-temp',
  'olive',
  'olive-branch',
  'om',
  'omega',
  'onion',
  'option',
  'ornament',
  'otter',
  'outdent',
  'dedent',
  'outlet',
  'oven',
  'overline',
  'p',
  'page',
  'page-caret-down',
  'file-caret-down',
  'page-caret-up',
  'file-caret-up',
  'pager',
  'paint-roller',
  'paintbrush',
  'paint-brush',
  'paintbrush-fine',
  'paint-brush-alt',
  'paint-brush-fine',
  'paintbrush-alt',
  'paintbrush-pencil',
  'palette',
  'pallet',
  'pallet-box',
  'pallet-boxes',
  'palette-boxes',
  'pallet-alt',
  'pan-food',
  'pan-frying',
  'pancakes',
  'panel-ews',
  'panel-fire',
  'panorama',
  'paper-plane',
  'paper-plane-top',
  'paper-plane-alt',
  'send',
  'paperclip',
  'paperclip-vertical',
  'parachute-box',
  'paragraph',
  'paragraph-left',
  'paragraph-rtl',
  'party-bell',
  'party-horn',
  'passport',
  'paste',
  'file-clipboard',
  'pause',
  'paw',
  'paw-claws',
  'paw-simple',
  'paw-alt',
  'peace',
  'peach',
  'peanut',
  'peanuts',
  'peapod',
  'pear',
  'pedestal',
  'pegasus',
  'pen',
  'pen-circle',
  'pen-clip',
  'pen-alt',
  'pen-clip-slash',
  'pen-alt-slash',
  'pen-fancy',
  'pen-fancy-slash',
  'pen-field',
  'pen-line',
  'pen-nib',
  'pen-nib-slash',
  'pen-paintbrush',
  'pencil-paintbrush',
  'pen-ruler',
  'pencil-ruler',
  'pen-slash',
  'pen-swirl',
  'pen-to-square',
  'edit',
  'pencil',
  'pencil-alt',
  'pencil-slash',
  'people',
  'people-arrows-left-right',
  'people-arrows',
  'people-carry-box',
  'people-carry',
  'people-dress',
  'people-dress-simple',
  'people-pants',
  'people-pants-simple',
  'people-simple',
  'pepper',
  'pepper-hot',
  'percent',
  'percentage',
  'period',
  'person',
  'male',
  'person-biking',
  'biking',
  'person-biking-mountain',
  'biking-mountain',
  'person-booth',
  'person-carry-box',
  'person-carry',
  'person-digging',
  'digging',
  'person-dolly',
  'person-dolly-empty',
  'person-dots-from-line',
  'diagnoses',
  'person-dress',
  'female',
  'person-dress-simple',
  'person-from-portal',
  'portal-exit',
  'person-hiking',
  'hiking',
  'person-pinball',
  'person-praying',
  'pray',
  'person-pregnant',
  'person-running',
  'running',
  'person-seat',
  'person-seat-reclined',
  'person-sign',
  'person-simple',
  'person-skating',
  'skating',
  'person-ski-jumping',
  'ski-jump',
  'person-ski-lift',
  'ski-lift',
  'person-skiing',
  'skiing',
  'person-skiing-nordic',
  'skiing-nordic',
  'person-sledding',
  'sledding',
  'person-snowboarding',
  'snowboarding',
  'person-snowmobiling',
  'snowmobile',
  'person-swimming',
  'swimmer',
  'person-to-door',
  'person-to-portal',
  'portal-enter',
  'person-walking',
  'walking',
  'person-walking-with-cane',
  'blind',
  'peseta-sign',
  'peso-sign',
  'phone',
  'phone-arrow-down-left',
  'phone-arrow-down',
  'phone-incoming',
  'phone-arrow-up-right',
  'phone-arrow-up',
  'phone-outgoing',
  'phone-flip',
  'phone-alt',
  'phone-hangup',
  'phone-intercom',
  'phone-missed',
  'phone-office',
  'phone-plus',
  'phone-rotary',
  'phone-slash',
  'phone-volume',
  'volume-control-phone',
  'phone-xmark',
  'photo-film',
  'photo-video',
  'photo-film-music',
  'pi',
  'piano',
  'piano-keyboard',
  'pickleball',
  'pie',
  'pig',
  'piggy-bank',
  'pills',
  'pinata',
  'pinball',
  'pineapple',
  'pipe',
  'pipe-circle-check',
  'pipe-collar',
  'pipe-section',
  'pipe-smoking',
  'pipe-valve',
  'pizza',
  'pizza-slice',
  'place-of-worship',
  'plane',
  'plane-arrival',
  'plane-departure',
  'plane-engines',
  'plane-alt',
  'plane-prop',
  'plane-slash',
  'plane-tail',
  'plane-up',
  'plane-up-slash',
  'planet-moon',
  'planet-ringed',
  'plate-utensils',
  'play',
  'play-pause',
  'plug',
  'plus',
  'add',
  'plus-minus',
  'podcast',
  'podium',
  'podium-star',
  'police-box',
  'poll-people',
  'pompebled',
  'poo',
  'poo-storm',
  'poo-bolt',
  'pool-8-ball',
  'poop',
  'popcorn',
  'popsicle',
  'pot-food',
  'potato',
  'power-off',
  'prescription',
  'prescription-bottle',
  'prescription-bottle-medical',
  'prescription-bottle-alt',
  'presentation-screen',
  'presentation',
  'pretzel',
  'print',
  'print-magnifying-glass',
  'print-search',
  'print-slash',
  'projector',
  'pump',
  'pump-medical',
  'pump-soap',
  'pumpkin',
  'puzzle',
  'puzzle-piece',
  'puzzle-piece-simple',
  'puzzle-piece-alt',
  'q',
  'qrcode',
  'question',
  'quote-left',
  'quote-left-alt',
  'quote-right',
  'quote-right-alt',
  'quotes',
  'r',
  'rabbit',
  'rabbit-running',
  'rabbit-fast',
  'racquet',
  'radar',
  'radiation',
  'radio',
  'radio-tuner',
  'radio-alt',
  'rainbow',
  'raindrops',
  'ram',
  'ramp-loading',
  'raygun',
  'receipt',
  'record-vinyl',
  'rectangle',
  'rectangle-landscape',
  'rectangle-ad',
  'ad',
  'rectangle-barcode',
  'barcode-alt',
  'rectangle-code',
  'rectangle-history',
  'rectangle-history-circle-plus',
  'rectangle-history-circle-user',
  'rectangle-list',
  'list-alt',
  'rectangle-pro',
  'pro',
  'rectangle-terminal',
  'rectangle-vertical',
  'rectangle-portrait',
  'rectangle-vertical-history',
  'rectangle-wide',
  'rectangle-xmark',
  'rectangle-times',
  'times-rectangle',
  'window-close',
  'rectangles-mixed',
  'recycle',
  'reel',
  'refrigerator',
  'registered',
  'repeat',
  'repeat-1',
  'reply',
  'mail-reply',
  'reply-all',
  'mail-reply-all',
  'reply-clock',
  'reply-time',
  'republican',
  'restroom',
  'restroom-simple',
  'retweet',
  'rhombus',
  'ribbon',
  'right',
  'arrow-alt-right',
  'right-from-bracket',
  'sign-out-alt',
  'right-from-line',
  'arrow-alt-from-left',
  'right-left',
  'exchange-alt',
  'right-long',
  'long-arrow-alt-right',
  'right-long-to-line',
  'right-to-bracket',
  'sign-in-alt',
  'right-to-line',
  'arrow-alt-to-right',
  'ring',
  'rings-wedding',
  'road',
  'robot',
  'robot-astromech',
  'rocket',
  'rocket-launch',
  'roller-coaster',
  'rotate',
  'sync-alt',
  'rotate-exclamation',
  'rotate-left',
  'rotate-back',
  'rotate-backward',
  'undo-alt',
  'rotate-right',
  'redo-alt',
  'rotate-forward',
  'route',
  'route-highway',
  'route-interstate',
  'router',
  'rss',
  'feed',
  'ruble-sign',
  'rouble',
  'rub',
  'ruble',
  'rugby-ball',
  'ruler',
  'ruler-combined',
  'ruler-horizontal',
  'ruler-triangle',
  'ruler-vertical',
  'rupee-sign',
  'rupee',
  'rupiah-sign',
  'rv',
  's',
  'sack',
  'sack-dollar',
  'sailboat',
  'salad',
  'bowl-salad',
  'salt-shaker',
  'sandwich',
  'satellite',
  'satellite-dish',
  'sausage',
  'saxophone',
  'saxophone-fire',
  'sax-hot',
  'scale-balanced',
  'balance-scale',
  'scale-unbalanced',
  'balance-scale-left',
  'scale-unbalanced-flip',
  'balance-scale-right',
  'scalpel',
  'scalpel-line-dashed',
  'scalpel-path',
  'scanner',
  'scanner-image',
  'scanner-gun',
  'scanner-keyboard',
  'scanner-touchscreen',
  'scarecrow',
  'scarf',
  'school',
  'scissors',
  'cut',
  'screen-users',
  'users-class',
  'screencast',
  'screwdriver',
  'screwdriver-wrench',
  'tools',
  'scribble',
  'scroll',
  'scroll-old',
  'scroll-torah',
  'torah',
  'scrubber',
  'scythe',
  'sd-card',
  'sd-cards',
  'seal',
  'seal-exclamation',
  'seal-question',
  'seat-airline',
  'section',
  'seedling',
  'sprout',
  'semicolon',
  'send-back',
  'send-backward',
  'sensor',
  'sensor-cloud',
  'sensor-smoke',
  'sensor-fire',
  'sensor-on',
  'sensor-triangle-exclamation',
  'sensor-alert',
  'server',
  'shapes',
  'triangle-circle-square',
  'share',
  'arrow-turn-right',
  'mail-forward',
  'share-all',
  'arrows-turn-right',
  'share-from-square',
  'share-square',
  'share-nodes',
  'share-alt',
  'sheep',
  'shekel-sign',
  'ils',
  'shekel',
  'sheqel',
  'sheqel-sign',
  'shelves',
  'inventory',
  'shelves-empty',
  'shield',
  'shield-blank',
  'shield-alt',
  'shield-check',
  'shield-cross',
  'shield-exclamation',
  'shield-keyhole',
  'shield-minus',
  'shield-plus',
  'shield-slash',
  'shield-virus',
  'shield-xmark',
  'shield-times',
  'ship',
  'shirt',
  't-shirt',
  'tshirt',
  'shirt-long-sleeve',
  'shirt-running',
  'shirt-tank-top',
  'shish-kebab',
  'shoe-prints',
  'shop',
  'store-alt',
  'shop-lock',
  'shop-slash',
  'store-alt-slash',
  'shovel',
  'shovel-snow',
  'shower',
  'shower-down',
  'shower-alt',
  'shredder',
  'shrimp',
  'shuffle',
  'random',
  'shutters',
  'shuttle-space',
  'space-shuttle',
  'shuttlecock',
  'sickle',
  'sidebar',
  'sidebar-flip',
  'sigma',
  'sign-hanging',
  'sign',
  'signal',
  'signal5',
  'signal-perfect',
  'signal-bars',
  'signal-alt',
  'signal-alt4',
  'signal-bars-strong',
  'signal-bars-fair',
  'signal-alt2',
  'signal-bars-good',
  'signal-alt3',
  'signal-bars-slash',
  'signal-alt-slash',
  'signal-bars-weak',
  'signal-alt1',
  'signal-fair',
  'signal2',
  'signal-good',
  'signal3',
  'signal-slash',
  'signal-stream',
  'signal-stream-slash',
  'signal-strong',
  'signal4',
  'signal-weak',
  'signal1',
  'signature',
  'signature-lock',
  'signature-slash',
  'signs-post',
  'map-signs',
  'sim-card',
  'sim-cards',
  'sink',
  'siren',
  'siren-on',
  'sitemap',
  'skeleton',
  'ski-boot',
  'ski-boot-ski',
  'skull',
  'skull-cow',
  'skull-crossbones',
  'slash',
  'slash-back',
  'slash-forward',
  'sleigh',
  'slider',
  'sliders',
  'sliders-h',
  'sliders-simple',
  'sliders-up',
  'sliders-v',
  'slot-machine',
  'smog',
  'smoke',
  'smoking',
  'snake',
  'snooze',
  'zzz',
  'snow-blowing',
  'snowflake',
  'snowflakes',
  'snowman',
  'snowman-head',
  'frosty-head',
  'snowplow',
  'soap',
  'socks',
  'soft-serve',
  'creemee',
  'solar-panel',
  'solar-system',
  'sort',
  'unsorted',
  'sort-down',
  'sort-desc',
  'sort-up',
  'sort-asc',
  'spa',
  'space-station-moon',
  'space-station-moon-construction',
  'space-station-moon-alt',
  'spade',
  'spaghetti-monster-flying',
  'pastafarianism',
  'sparkles',
  'speaker',
  'speakers',
  'spell-check',
  'spider',
  'spider-black-widow',
  'spider-web',
  'spinner',
  'spinner-third',
  'split',
  'splotch',
  'spoon',
  'utensil-spoon',
  'sportsball',
  'spray-can',
  'spray-can-sparkles',
  'air-freshener',
  'sprinkler',
  'sprinkler-ceiling',
  'square',
  'square-0',
  'square-1',
  'square-2',
  'square-3',
  'square-4',
  'square-5',
  'square-6',
  'square-7',
  'square-8',
  'square-9',
  'square-a',
  'square-a-lock',
  'square-ampersand',
  'square-arrow-down',
  'arrow-square-down',
  'square-arrow-down-left',
  'square-arrow-down-right',
  'square-arrow-left',
  'arrow-square-left',
  'square-arrow-right',
  'arrow-square-right',
  'square-arrow-up',
  'arrow-square-up',
  'square-arrow-up-left',
  'square-arrow-up-right',
  'external-link-square',
  'square-b',
  'square-bolt',
  'square-c',
  'square-caret-down',
  'caret-square-down',
  'square-caret-left',
  'caret-square-left',
  'square-caret-right',
  'caret-square-right',
  'square-caret-up',
  'caret-square-up',
  'square-check',
  'check-square',
  'square-chevron-down',
  'chevron-square-down',
  'square-chevron-left',
  'chevron-square-left',
  'square-chevron-right',
  'chevron-square-right',
  'square-chevron-up',
  'chevron-square-up',
  'square-code',
  'square-d',
  'square-dashed',
  'square-divide',
  'square-dollar',
  'dollar-square',
  'usd-square',
  'square-down',
  'arrow-alt-square-down',
  'square-down-left',
  'square-down-right',
  'square-e',
  'square-ellipsis',
  'square-ellipsis-vertical',
  'square-envelope',
  'envelope-square',
  'square-exclamation',
  'exclamation-square',
  'square-f',
  'square-fragile',
  'box-fragile',
  'square-wine-glass-crack',
  'square-full',
  'square-g',
  'square-h',
  'h-square',
  'square-heart',
  'heart-square',
  'square-i',
  'square-info',
  'info-square',
  'square-j',
  'square-k',
  'square-kanban',
  'square-l',
  'square-left',
  'arrow-alt-square-left',
  'square-list',
  'square-m',
  'square-minus',
  'minus-square',
  'square-n',
  'square-o',
  'square-p',
  'square-parking',
  'parking',
  'square-parking-slash',
  'parking-slash',
  'square-pen',
  'pen-square',
  'pencil-square',
  'square-phone',
  'phone-square',
  'square-phone-flip',
  'phone-square-alt',
  'square-phone-hangup',
  'phone-square-down',
  'square-plus',
  'plus-square',
  'square-poll-horizontal',
  'poll-h',
  'square-poll-vertical',
  'poll',
  'square-q',
  'square-quarters',
  'square-question',
  'question-square',
  'square-quote',
  'square-r',
  'square-right',
  'arrow-alt-square-right',
  'square-ring',
  'square-root',
  'square-root-variable',
  'square-root-alt',
  'square-rss',
  'rss-square',
  'square-s',
  'square-share-nodes',
  'share-alt-square',
  'square-sliders',
  'sliders-h-square',
  'square-sliders-vertical',
  'sliders-v-square',
  'square-small',
  'square-star',
  'square-t',
  'square-terminal',
  'square-this-way-up',
  'box-up',
  'square-u',
  'square-up',
  'arrow-alt-square-up',
  'square-up-left',
  'square-up-right',
  'external-link-square-alt',
  'square-user',
  'square-v',
  'square-w',
  'square-x',
  'square-xmark',
  'times-square',
  'xmark-square',
  'square-y',
  'square-z',
  'squid',
  'squirrel',
  'staff',
  'stairs',
  'stamp',
  'standard-definition',
  'rectangle-sd',
  'star',
  'star-and-crescent',
  'star-christmas',
  'star-exclamation',
  'star-half',
  'star-half-stroke',
  'star-half-alt',
  'star-of-david',
  'star-of-life',
  'star-sharp',
  'star-sharp-half',
  'star-sharp-half-stroke',
  'star-sharp-half-alt',
  'star-shooting',
  'starfighter',
  'starfighter-twin-ion-engine',
  'starfighter-alt',
  'starfighter-twin-ion-engine-advanced',
  'starfighter-alt-advanced',
  'stars',
  'starship',
  'starship-freighter',
  'steak',
  'steering-wheel',
  'sterling-sign',
  'gbp',
  'pound-sign',
  'stethoscope',
  'stocking',
  'stomach',
  'stop',
  'stopwatch',
  'stopwatch-20',
  'store',
  'store-lock',
  'store-slash',
  'strawberry',
  'street-view',
  'stretcher',
  'strikethrough',
  'stroopwafel',
  'subscript',
  'suitcase',
  'suitcase-medical',
  'medkit',
  'suitcase-rolling',
  'sun',
  'sun-bright',
  'sun-alt',
  'sun-cloud',
  'sun-dust',
  'sun-haze',
  'sunglasses',
  'sunrise',
  'sunset',
  'superscript',
  'sushi',
  'nigiri',
  'sushi-roll',
  'maki-roll',
  'makizushi',
  'swatchbook',
  'sword',
  'sword-laser',
  'sword-laser-alt',
  'swords',
  'swords-laser',
  'symbols',
  'icons-alt',
  'synagogue',
  'syringe',
  't',
  'table',
  'table-cells',
  'th',
  'table-cells-large',
  'th-large',
  'table-columns',
  'columns',
  'table-layout',
  'table-list',
  'th-list',
  'table-picnic',
  'table-pivot',
  'table-rows',
  'rows',
  'table-tennis-paddle-ball',
  'ping-pong-paddle-ball',
  'table-tennis',
  'table-tree',
  'tablet',
  'tablet-android',
  'tablet-button',
  'tablet-rugged',
  'tablet-screen',
  'tablet-android-alt',
  'tablet-screen-button',
  'tablet-alt',
  'tablets',
  'tachograph-digital',
  'digital-tachograph',
  'taco',
  'tag',
  'tags',
  'tally',
  'tally5',
  'tally-1',
  'tally-2',
  'tally-3',
  'tally-4',
  'tamale',
  'tank-water',
  'tape',
  'taxi',
  'cab',
  'taxi-bus',
  'teddy-bear',
  'teeth',
  'teeth-open',
  'telescope',
  'temperature-arrow-down',
  'temperature-down',
  'temperature-arrow-up',
  'temperature-up',
  'temperature-empty',
  'temperature0',
  'thermometer0',
  'thermometer-empty',
  'temperature-full',
  'temperature4',
  'thermometer4',
  'thermometer-full',
  'temperature-half',
  'temperature2',
  'thermometer2',
  'thermometer-half',
  'temperature-high',
  'temperature-list',
  'temperature-low',
  'temperature-quarter',
  'temperature1',
  'thermometer1',
  'thermometer-quarter',
  'temperature-snow',
  'temperature-frigid',
  'temperature-sun',
  'temperature-hot',
  'temperature-three-quarters',
  'temperature3',
  'thermometer3',
  'thermometer-three-quarters',
  'tenge-sign',
  'tenge',
  'tennis-ball',
  'terminal',
  'text',
  'text-height',
  'text-size',
  'text-slash',
  'remove-format',
  'text-width',
  'thermometer',
  'theta',
  'thought-bubble',
  'thumbs-down',
  'thumbs-up',
  'thumbtack',
  'thumb-tack',
  'tick',
  'ticket',
  'ticket-airline',
  'ticket-simple',
  'ticket-alt',
  'tickets-airline',
  'tilde',
  'timeline',
  'timeline-arrow',
  'timer',
  'tire',
  'tire-flat',
  'tire-pressure-warning',
  'tire-rugged',
  'toggle-off',
  'toggle-on',
  'toilet',
  'toilet-paper',
  'toilet-paper-blank',
  'toilet-paper-alt',
  'toilet-paper-blank-under',
  'toilet-paper-reverse-alt',
  'toilet-paper-slash',
  'toilet-paper-under',
  'toilet-paper-reverse',
  'toilet-paper-under-slash',
  'toilet-paper-reverse-slash',
  'tomato',
  'tombstone',
  'tombstone-blank',
  'tombstone-alt',
  'toolbox',
  'tooth',
  'toothbrush',
  'torii-gate',
  'tornado',
  'tower-broadcast',
  'broadcast-tower',
  'tower-control',
  'tractor',
  'trademark',
  'traffic-cone',
  'traffic-light',
  'traffic-light-go',
  'traffic-light-slow',
  'traffic-light-stop',
  'trailer',
  'train',
  'train-subway',
  'subway',
  'train-subway-tunnel',
  'subway-tunnel',
  'train-track',
  'train-tram',
  'tram',
  'train-tunnel',
  'transformer-bolt',
  'transgender',
  'transgender-alt',
  'transporter',
  'transporter-1',
  'transporter-2',
  'transporter-3',
  'transporter-4',
  'transporter-5',
  'transporter-6',
  'transporter-7',
  'transporter-empty',
  'trash',
  'trash-arrow-up',
  'trash-restore',
  'trash-can',
  'trash-alt',
  'trash-can-arrow-up',
  'trash-restore-alt',
  'trash-can-check',
  'trash-can-clock',
  'trash-can-list',
  'trash-can-plus',
  'trash-can-slash',
  'trash-alt-slash',
  'trash-can-undo',
  'trash-can-arrow-turn-left',
  'trash-undo-alt',
  'trash-can-xmark',
  'trash-check',
  'trash-clock',
  'trash-list',
  'trash-plus',
  'trash-slash',
  'trash-undo',
  'trash-arrow-turn-left',
  'trash-xmark',
  'treasure-chest',
  'tree',
  'tree-christmas',
  'tree-deciduous',
  'tree-alt',
  'tree-decorated',
  'tree-large',
  'tree-palm',
  'trees',
  'triangle',
  'triangle-exclamation',
  'exclamation-triangle',
  'warning',
  'triangle-instrument',
  'triangle-music',
  'triangle-person-digging',
  'construction',
  'trophy',
  'trophy-star',
  'trophy-alt',
  'truck',
  'truck-bolt',
  'truck-clock',
  'shipping-timed',
  'truck-container',
  'truck-container-empty',
  'truck-fast',
  'shipping-fast',
  'truck-flatbed',
  'truck-front',
  'truck-medical',
  'ambulance',
  'truck-monster',
  'truck-moving',
  'truck-pickup',
  'truck-plow',
  'truck-ramp',
  'truck-ramp-box',
  'truck-loading',
  'truck-ramp-couch',
  'truck-couch',
  'truck-tow',
  'trumpet',
  'tty',
  'teletype',
  'tty-answer',
  'teletype-answer',
  'tugrik-sign',
  'turkey',
  'turkish-lira-sign',
  'try',
  'turkish-lira',
  'turn-down',
  'level-down-alt',
  'turn-down-left',
  'turn-down-right',
  'turn-up',
  'level-up-alt',
  'turntable',
  'turtle',
  'tv',
  'television',
  'tv-alt',
  'tv-music',
  'tv-retro',
  'typewriter',
  'u',
  'ufo',
  'ufo-beam',
  'umbrella',
  'umbrella-beach',
  'umbrella-simple',
  'umbrella-alt',
  'underline',
  'unicorn',
  'uniform-martial-arts',
  'union',
  'universal-access',
  'unlock',
  'unlock-keyhole',
  'unlock-alt',
  'up',
  'arrow-alt-up',
  'up-down',
  'arrows-alt-v',
  'up-down-left-right',
  'arrows-alt',
  'up-from-dotted-line',
  'up-from-line',
  'arrow-alt-from-bottom',
  'up-left',
  'up-long',
  'long-arrow-alt-up',
  'up-right',
  'up-right-and-down-left-from-center',
  'expand-alt',
  'up-right-from-square',
  'external-link-alt',
  'up-to-dotted-line',
  'up-to-line',
  'arrow-alt-to-top',
  'upload',
  'usb-drive',
  'user',
  'user-alien',
  'user-astronaut',
  'user-bounty-hunter',
  'user-check',
  'user-chef',
  'user-clock',
  'user-cowboy',
  'user-crown',
  'user-doctor',
  'user-md',
  'user-doctor-hair',
  'user-doctor-hair-long',
  'user-doctor-message',
  'user-md-chat',
  'user-gear',
  'user-cog',
  'user-graduate',
  'user-group',
  'user-friends',
  'user-group-crown',
  'users-crown',
  'user-hair',
  'user-hair-buns',
  'user-hair-long',
  'user-hair-mullet',
  'business-front',
  'party-back',
  'trian-balbot',
  'user-headset',
  'user-helmet-safety',
  'user-construction',
  'user-hard-hat',
  'user-injured',
  'user-large',
  'user-alt',
  'user-large-slash',
  'user-alt-slash',
  'user-lock',
  'user-minus',
  'user-music',
  'user-ninja',
  'user-nurse',
  'user-nurse-hair',
  'user-nurse-hair-long',
  'user-pen',
  'user-edit',
  'user-pilot',
  'user-pilot-tie',
  'user-plus',
  'user-police',
  'user-police-tie',
  'user-robot',
  'user-robot-xmarks',
  'user-secret',
  'user-shakespeare',
  'user-shield',
  'user-slash',
  'user-tag',
  'user-tie',
  'user-tie-hair',
  'user-tie-hair-long',
  'user-unlock',
  'user-visor',
  'user-vneck',
  'user-vneck-hair',
  'user-vneck-hair-long',
  'user-xmark',
  'user-times',
  'users',
  'users-gear',
  'users-cog',
  'users-medical',
  'users-slash',
  'utensils',
  'cutlery',
  'utensils-slash',
  'utility-pole',
  'utility-pole-double',
  'v',
  'vacuum',
  'vacuum-robot',
  'value-absolute',
  'van-shuttle',
  'shuttle-van',
  'vault',
  'vector-circle',
  'vector-polygon',
  'vector-square',
  'vent-damper',
  'venus',
  'venus-double',
  'venus-mars',
  'vest',
  'vest-patches',
  'vial',
  'vials',
  'video',
  'video-camera',
  'video-arrow-down-left',
  'video-arrow-up-right',
  'video-plus',
  'video-slash',
  'vihara',
  'violin',
  'virus',
  'virus-covid',
  'virus-covid-slash',
  'virus-slash',
  'viruses',
  'voicemail',
  'volcano',
  'volleyball',
  'volleyball-ball',
  'volume',
  'volume-medium',
  'volume-high',
  'volume-up',
  'volume-low',
  'volume-down',
  'volume-off',
  'volume-slash',
  'volume-xmark',
  'volume-mute',
  'volume-times',
  'vr-cardboard',
  'w',
  'waffle',
  'wagon-covered',
  'walker',
  'walkie-talkie',
  'wallet',
  'wand',
  'wand-magic',
  'magic',
  'wand-magic-sparkles',
  'magic-wand-sparkles',
  'wand-sparkles',
  'warehouse',
  'warehouse-full',
  'warehouse-alt',
  'washing-machine',
  'washer',
  'watch',
  'watch-apple',
  'watch-calculator',
  'watch-fitness',
  'watch-smart',
  'water',
  'water-arrow-down',
  'water-lower',
  'water-arrow-up',
  'water-rise',
  'water-ladder',
  'ladder-water',
  'swimming-pool',
  'watermelon-slice',
  'wave-pulse',
  'heart-rate',
  'wave-sine',
  'wave-square',
  'wave-triangle',
  'waveform',
  'waveform-lines',
  'waveform-path',
  'weight-hanging',
  'weight-scale',
  'weight',
  'whale',
  'wheat',
  'wheat-awn',
  'wheat-alt',
  'wheat-awn-slash',
  'wheat-slash',
  'wheelchair',
  'wheelchair-move',
  'wheelchair-alt',
  'whiskey-glass',
  'glass-whiskey',
  'whiskey-glass-ice',
  'glass-whiskey-rocks',
  'whistle',
  'wifi',
  'wifi3',
  'wifi-strong',
  'wifi-exclamation',
  'wifi-fair',
  'wifi2',
  'wifi-slash',
  'wifi-weak',
  'wifi1',
  'wind',
  'wind-turbine',
  'wind-warning',
  'wind-circle-exclamation',
  'window',
  'window-flip',
  'window-alt',
  'window-frame',
  'window-frame-open',
  'window-maximize',
  'window-minimize',
  'window-restore',
  'windsock',
  'wine-bottle',
  'wine-glass',
  'wine-glass-crack',
  'fragile',
  'wine-glass-empty',
  'wine-glass-alt',
  'won-sign',
  'krw',
  'won',
  'wreath',
  'wrench',
  'wrench-simple',
  'x',
  'x-ray',
  'xmark',
  'close',
  'multiply',
  'remove',
  'times',
  'xmark-to-slot',
  'times-to-slot',
  'vote-nay',
  'y',
  'yen-sign',
  'cny',
  'jpy',
  'rmb',
  'yen',
  'yin-yang',
  'z',
  '0',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '00',
  '360-degrees',
  'a',
  'abacus',
  'accent-grave',
  'acorn',
  'address-book',
  'contact-book',
  'address-card',
  'contact-card',
  'vcard',
  'air-conditioner',
  'airplay',
  'alarm-clock',
  'alarm-exclamation',
  'alarm-plus',
  'alarm-snooze',
  'album',
  'album-circle-plus',
  'album-circle-user',
  'album-collection',
  'album-collection-circle-plus',
  'album-collection-circle-user',
  'alicorn',
  'alien',
  'alien-8bit',
  'alien-monster',
  'align-center',
  'align-justify',
  'align-left',
  'align-right',
  'align-slash',
  'alt',
  'amp-guitar',
  'ampersand',
  'anchor',
  'angel',
  'angle',
  'angle-90',
  'angle-down',
  'angle-left',
  'angle-right',
  'angle-up',
  'angles-down',
  'angle-double-down',
  'angles-left',
  'angle-double-left',
  'angles-right',
  'angle-double-right',
  'angles-up',
  'angle-double-up',
  'ankh',
  'apartment',
  'aperture',
  'apostrophe',
  'apple-core',
  'apple-whole',
  'apple-alt',
  'archway',
  'arrow-down',
  'arrow-down-1-9',
  'sort-numeric-asc',
  'sort-numeric-down',
  'arrow-down-9-1',
  'sort-numeric-desc',
  'sort-numeric-down-alt',
  'arrow-down-a-z',
  'sort-alpha-asc',
  'sort-alpha-down',
  'arrow-down-arrow-up',
  'sort-alt',
  'arrow-down-big-small',
  'sort-size-down',
  'arrow-down-from-dotted-line',
  'arrow-down-from-line',
  'arrow-from-top',
  'arrow-down-left',
  'arrow-down-left-and-arrow-up-right-to-center',
  'arrow-down-long',
  'long-arrow-down',
  'arrow-down-right',
  'arrow-down-short-wide',
  'sort-amount-desc',
  'sort-amount-down-alt',
  'arrow-down-small-big',
  'sort-size-down-alt',
  'arrow-down-square-triangle',
  'sort-shapes-down-alt',
  'arrow-down-to-bracket',
  'arrow-down-to-dotted-line',
  'arrow-down-to-line',
  'arrow-to-bottom',
  'arrow-down-to-square',
  'arrow-down-triangle-square',
  'sort-shapes-down',
  'arrow-down-wide-short',
  'sort-amount-asc',
  'sort-amount-down',
  'arrow-down-z-a',
  'sort-alpha-desc',
  'sort-alpha-down-alt',
  'arrow-left',
  'arrow-left-from-line',
  'arrow-from-right',
  'arrow-left-long',
  'long-arrow-left',
  'arrow-left-long-to-line',
  'arrow-left-to-line',
  'arrow-to-left',
  'arrow-pointer',
  'mouse-pointer',
  'arrow-right',
  'arrow-right-arrow-left',
  'exchange',
  'arrow-right-from-bracket',
  'sign-out',
  'arrow-right-from-line',
  'arrow-from-left',
  'arrow-right-long',
  'long-arrow-right',
  'arrow-right-long-to-line',
  'arrow-right-to-bracket',
  'sign-in',
  'arrow-right-to-line',
  'arrow-to-right',
  'arrow-rotate-left',
  'arrow-left-rotate',
  'arrow-rotate-back',
  'arrow-rotate-backward',
  'undo',
  'arrow-rotate-right',
  'arrow-right-rotate',
  'arrow-rotate-forward',
  'redo',
  'arrow-trend-down',
  'arrow-trend-up',
  'arrow-turn-down',
  'level-down',
  'arrow-turn-down-left',
  'arrow-turn-down-right',
  'arrow-turn-up',
  'level-up',
  'arrow-up',
  'arrow-up-1-9',
  'sort-numeric-up',
  'arrow-up-9-1',
  'sort-numeric-up-alt',
  'arrow-up-a-z',
  'sort-alpha-up',
  'arrow-up-arrow-down',
  'sort-up-down',
  'arrow-up-big-small',
  'sort-size-up',
  'arrow-up-from-bracket',
  'arrow-up-from-dotted-line',
  'arrow-up-from-line',
  'arrow-from-bottom',
  'arrow-up-from-square',
  'arrow-up-left',
  'arrow-up-left-from-circle',
  'arrow-up-long',
  'long-arrow-up',
  'arrow-up-right',
  'arrow-up-right-and-arrow-down-left-from-center',
  'arrow-up-right-from-square',
  'external-link',
  'arrow-up-short-wide',
  'sort-amount-up-alt',
  'arrow-up-small-big',
  'sort-size-up-alt',
  'arrow-up-square-triangle',
  'sort-shapes-up-alt',
  'arrow-up-to-dotted-line',
  'arrow-up-to-line',
  'arrow-to-top',
  'arrow-up-triangle-square',
  'sort-shapes-up',
  'arrow-up-wide-short',
  'sort-amount-up',
  'arrow-up-z-a',
  'sort-alpha-up-alt',
  'arrows-cross',
  'arrows-from-dotted-line',
  'arrows-from-line',
  'arrows-left-right',
  'arrows-h',
  'arrows-maximize',
  'expand-arrows',
  'arrows-minimize',
  'compress-arrows',
  'arrows-repeat',
  'repeat-alt',
  'arrows-repeat-1',
  'repeat1-alt',
  'arrows-retweet',
  'retweet-alt',
  'arrows-rotate',
  'refresh',
  'sync',
  'arrows-to-dotted-line',
  'arrows-to-line',
  'arrows-up-down',
  'arrows-v',
  'arrows-up-down-left-right',
  'arrows',
  'asterisk',
  'at',
  'atom',
  'atom-simple',
  'atom-alt',
  'audio-description',
  'audio-description-slash',
  'austral-sign',
  'avocado',
  'award',
  'award-simple',
  'axe',
  'axe-battle',
  'b',
  'baby',
  'baby-carriage',
  'carriage-baby',
  'backpack',
  'backward',
  'backward-fast',
  'fast-backward',
  'backward-step',
  'step-backward',
  'bacon',
  'bacteria',
  'bacterium',
  'badge',
  'badge-check',
  'badge-dollar',
  'badge-percent',
  'badge-sheriff',
  'badger-honey',
  'badminton',
  'bag-shopping',
  'shopping-bag',
  'bagel',
  'bags-shopping',
  'baguette',
  'bahai',
  'baht-sign',
  'ball-pile',
  'balloon',
  'balloons',
  'ballot',
  'ballot-check',
  'ban',
  'cancel',
  'ban-bug',
  'debug',
  'ban-parking',
  'parking-circle-slash',
  'ban-smoking',
  'smoking-ban',
  'banana',
  'bandage',
  'band-aid',
  'bangladeshi-taka-sign',
  'banjo',
  'barcode',
  'barcode-read',
  'barcode-scan',
  'bars',
  'navicon',
  'bars-filter',
  'bars-progress',
  'tasks-alt',
  'bars-sort',
  'bars-staggered',
  'reorder',
  'stream',
  'baseball',
  'baseball-ball',
  'baseball-bat-ball',
  'basket-shopping',
  'shopping-basket',
  'basket-shopping-simple',
  'shopping-basket-alt',
  'basketball',
  'basketball-ball',
  'basketball-hoop',
  'bat',
  'bath',
  'bathtub',
  'battery-bolt',
  'battery-empty',
  'battery0',
  'battery-exclamation',
  'battery-full',
  'battery',
  'battery5',
  'battery-half',
  'battery3',
  'battery-low',
  'battery1',
  'battery-quarter',
  'battery2',
  'battery-slash',
  'battery-three-quarters',
  'battery4',
  'bed',
  'bed-bunk',
  'bed-empty',
  'bed-front',
  'bed-alt',
  'bed-pulse',
  'procedures',
  'bee',
  'beer-mug',
  'beer-foam',
  'beer-mug-empty',
  'beer',
  'bell',
  'bell-concierge',
  'concierge-bell',
  'bell-exclamation',
  'bell-on',
  'bell-plus',
  'bell-school',
  'bell-school-slash',
  'bell-slash',
  'bells',
  'bench-tree',
  'bezier-curve',
  'bicycle',
  'binary',
  'binary-circle-check',
  'binary-lock',
  'binary-slash',
  'binoculars',
  'biohazard',
  'bird',
  'bitcoin-sign',
  'blanket',
  'blanket-fire',
  'blender',
  'blender-phone',
  'blinds',
  'blinds-open',
  'blinds-raised',
  'block',
  'block-brick',
  'wall-brick',
  'block-brick-fire',
  'firewall',
  'block-question',
  'block-quote',
  'blog',
  'blueberries',
  'bluetooth',
  'bold',
  'bolt',
  'zap',
  'bolt-auto',
  'bolt-lightning',
  'bolt-slash',
  'bomb',
  'bone',
  'bone-break',
  'bong',
  'book',
  'book-arrow-right',
  'book-arrow-up',
  'book-atlas',
  'atlas',
  'book-bible',
  'bible',
  'book-blank',
  'book-alt',
  'book-bookmark',
  'book-circle-arrow-right',
  'book-circle-arrow-up',
  'book-copy',
  'book-font',
  'book-heart',
  'book-journal-whills',
  'journal-whills',
  'book-medical',
  'book-open',
  'book-open-cover',
  'book-open-alt',
  'book-open-reader',
  'book-reader',
  'book-quran',
  'quran',
  'book-section',
  'book-law',
  'book-skull',
  'book-dead',
  'book-sparkles',
  'book-spells',
  'book-tanakh',
  'tanakh',
  'book-user',
  'bookmark',
  'bookmark-slash',
  'books',
  'books-medical',
  'boombox',
  'boot',
  'boot-heeled',
  'booth-curtain',
  'border-all',
  'border-bottom',
  'border-bottom-right',
  'border-style-alt',
  'border-center-h',
  'border-center-v',
  'border-inner',
  'border-left',
  'border-none',
  'border-outer',
  'border-right',
  'border-top',
  'border-top-left',
  'border-style',
  'bow-arrow',
  'bowl-chopsticks',
  'bowl-chopsticks-noodles',
  'bowl-hot',
  'soup',
  'bowl-rice',
  'bowl-scoop',
  'bowl-shaved-ice',
  'bowl-scoops',
  'bowl-soft-serve',
  'bowl-spoon',
  'bowling-ball',
  'bowling-ball-pin',
  'bowling-pins',
  'box',
  'box-archive',
  'archive',
  'box-ballot',
  'box-check',
  'box-circle-check',
  'box-dollar',
  'box-usd',
  'box-heart',
  'box-open',
  'box-open-full',
  'box-full',
  'box-taped',
  'box-alt',
  'box-tissue',
  'boxes-stacked',
  'boxes',
  'boxes-alt',
  'boxing-glove',
  'glove-boxing',
  'bracket-curly',
  'bracket-curly-left',
  'bracket-curly-right',
  'bracket-round',
  'parenthesis',
  'bracket-round-right',
  'bracket-square',
  'bracket',
  'bracket-left',
  'bracket-square-right',
  'brackets-curly',
  'brackets-round',
  'parentheses',
  'brackets-square',
  'brackets',
  'braille',
  'brain',
  'brain-arrow-curved-right',
  'mind-share',
  'brain-circuit',
  'brake-warning',
  'brazilian-real-sign',
  'bread-loaf',
  'bread-slice',
  'bread-slice-butter',
  'briefcase',
  'briefcase-arrow-right',
  'briefcase-blank',
  'briefcase-medical',
  'brightness',
  'brightness-low',
  'bring-forward',
  'bring-front',
  'broccoli',
  'broom',
  'broom-ball',
  'quidditch',
  'quidditch-broom-ball',
  'browser',
  'browsers',
  'brush',
  'bug',
  'bug-slash',
  'building',
  'building-columns',
  'bank',
  'institution',
  'museum',
  'university',
  'buildings',
  'bullhorn',
  'bullseye',
  'bullseye-arrow',
  'bullseye-pointer',
  'burger',
  'hamburger',
  'burger-cheese',
  'cheeseburger',
  'burger-fries',
  'burger-glass',
  'burger-lettuce',
  'burger-soda',
  'burrito',
  'bus',
  'bus-school',
  'bus-simple',
  'bus-alt',
  'business-time',
  'briefcase-clock',
  'butter',
  'c',
  'cabin',
  'cabinet-filing',
  'cable-car',
  'cactus',
  'cake-candles',
  'birthday-cake',
  'cake',
  'cake-slice',
  'shortcake',
  'calculator',
  'calculator-simple',
  'calculator-alt',
  'calendar',
  'calendar-arrow-down',
  'calendar-download',
  'calendar-arrow-up',
  'calendar-upload',
  'calendar-check',
  'calendar-circle-exclamation',
  'calendar-circle-minus',
  'calendar-circle-plus',
  'calendar-circle-user',
  'calendar-clock',
  'calendar-time',
  'calendar-day',
  'calendar-days',
  'calendar-alt',
  'calendar-exclamation',
  'calendar-heart',
  'calendar-image',
  'calendar-lines',
  'calendar-note',
  'calendar-lines-pen',
  'calendar-minus',
  'calendar-pen',
  'calendar-edit',
  'calendar-plus',
  'calendar-range',
  'calendar-star',
  'calendar-week',
  'calendar-xmark',
  'calendar-times',
  'calendars',
  'camcorder',
  'video-handheld',
  'camera',
  'camera-alt',
  'camera-cctv',
  'cctv',
  'camera-movie',
  'camera-polaroid',
  'camera-retro',
  'camera-rotate',
  'camera-security',
  'camera-home',
  'camera-slash',
  'camera-viewfinder',
  'screenshot',
  'camera-web',
  'webcam',
  'camera-web-slash',
  'webcam-slash',
  'campfire',
  'campground',
  'can-food',
  'candle-holder',
  'candy',
  'candy-bar',
  'chocolate-bar',
  'candy-cane',
  'candy-corn',
  'cannabis',
  'capsules',
  'car',
  'automobile',
  'car-battery',
  'battery-car',
  'car-bolt',
  'car-building',
  'car-bump',
  'car-bus',
  'car-circle-bolt',
  'car-crash',
  'car-garage',
  'car-mirrors',
  'car-rear',
  'car-alt',
  'car-side',
  'car-side-bolt',
  'car-tilt',
  'car-wash',
  'car-wrench',
  'car-mechanic',
  'caravan',
  'caravan-simple',
  'caravan-alt',
  'card-club',
  'card-diamond',
  'card-heart',
  'card-spade',
  'cards',
  'caret-down',
  'caret-left',
  'caret-right',
  'caret-up',
  'carrot',
  'cars',
  'cart-arrow-down',
  'cart-arrow-up',
  'cart-circle-arrow-down',
  'cart-circle-arrow-up',
  'cart-circle-check',
  'cart-circle-exclamation',
  'cart-circle-plus',
  'cart-circle-xmark',
  'cart-flatbed',
  'dolly-flatbed',
  'cart-flatbed-boxes',
  'dolly-flatbed-alt',
  'cart-flatbed-empty',
  'dolly-flatbed-empty',
  'cart-flatbed-suitcase',
  'luggage-cart',
  'cart-minus',
  'cart-plus',
  'cart-shopping',
  'shopping-cart',
  'cart-shopping-fast',
  'cart-xmark',
  'cash-register',
  'cassette-betamax',
  'betamax',
  'cassette-tape',
  'cassette-vhs',
  'vhs',
  'castle',
  'cat',
  'cat-space',
  'cauldron',
  'cedi-sign',
  'cent-sign',
  'certificate',
  'chair',
  'chair-office',
  'chalkboard',
  'blackboard',
  'chalkboard-user',
  'chalkboard-teacher',
  'champagne-glass',
  'glass-champagne',
  'champagne-glasses',
  'glass-cheers',
  'charging-station',
  'chart-area',
  'area-chart',
  'chart-bar',
  'bar-chart',
  'chart-bullet',
  'chart-candlestick',
  'chart-column',
  'chart-gantt',
  'chart-line',
  'line-chart',
  'chart-line-down',
  'chart-line-up',
  'chart-mixed',
  'analytics',
  'chart-network',
  'chart-pie',
  'pie-chart',
  'chart-pie-simple',
  'chart-pie-alt',
  'chart-pyramid',
  'chart-radar',
  'chart-scatter',
  'chart-scatter-3d',
  'chart-scatter-bubble',
  'chart-simple',
  'chart-simple-horizontal',
  'chart-tree-map',
  'chart-user',
  'user-chart',
  'chart-waterfall',
  'check',
  'check-double',
  'check-to-slot',
  'vote-yea',
  'cheese',
  'cheese-swiss',
  'cherries',
  'chess',
  'chess-bishop',
  'chess-bishop-piece',
  'chess-bishop-alt',
  'chess-board',
  'chess-clock',
  'chess-clock-flip',
  'chess-clock-alt',
  'chess-king',
  'chess-king-piece',
  'chess-king-alt',
  'chess-knight',
  'chess-knight-piece',
  'chess-knight-alt',
  'chess-pawn',
  'chess-pawn-piece',
  'chess-pawn-alt',
  'chess-queen',
  'chess-queen-piece',
  'chess-queen-alt',
  'chess-rook',
  'chess-rook-piece',
  'chess-rook-alt',
  'chestnut',
  'chevron-down',
  'chevron-left',
  'chevron-right',
  'chevron-up',
  'chevrons-down',
  'chevron-double-down',
  'chevrons-left',
  'chevron-double-left',
  'chevrons-right',
  'chevron-double-right',
  'chevrons-up',
  'chevron-double-up',
  'child',
  'chimney',
  'chopsticks',
  'church',
  'circle',
  'circle-0',
  'circle-1',
  'circle-2',
  'circle-3',
  'circle-4',
  'circle-5',
  'circle-6',
  'circle-7',
  'circle-8',
  'circle-9',
  'circle-a',
  'circle-ampersand',
  'circle-arrow-down',
  'arrow-circle-down',
  'circle-arrow-down-left',
  'circle-arrow-down-right',
  'circle-arrow-left',
  'arrow-circle-left',
  'circle-arrow-right',
  'arrow-circle-right',
  'circle-arrow-up',
  'arrow-circle-up',
  'circle-arrow-up-left',
  'circle-arrow-up-right',
  'circle-b',
  'circle-bolt',
  'circle-book-open',
  'book-circle',
  'circle-bookmark',
  'bookmark-circle',
  'circle-c',
  'circle-calendar',
  'calendar-circle',
  'circle-camera',
  'camera-circle',
  'circle-caret-down',
  'caret-circle-down',
  'circle-caret-left',
  'caret-circle-left',
  'circle-caret-right',
  'caret-circle-right',
  'circle-caret-up',
  'caret-circle-up',
  'circle-check',
  'check-circle',
  'circle-chevron-down',
  'chevron-circle-down',
  'circle-chevron-left',
  'chevron-circle-left',
  'circle-chevron-right',
  'chevron-circle-right',
  'circle-chevron-up',
  'chevron-circle-up',
  'circle-d',
  'circle-dashed',
  'circle-divide',
  'circle-dollar',
  'dollar-circle',
  'usd-circle',
  'circle-dollar-to-slot',
  'donate',
  'circle-dot',
  'dot-circle',
  'circle-down',
  'arrow-alt-circle-down',
  'circle-down-left',
  'circle-down-right',
  'circle-e',
  'circle-ellipsis',
  'circle-ellipsis-vertical',
  'circle-envelope',
  'envelope-circle',
  'circle-exclamation',
  'exclamation-circle',
  'circle-exclamation-check',
  'circle-f',
  'circle-g',
  'circle-h',
  'hospital-symbol',
  'circle-half',
  'circle-half-stroke',
  'adjust',
  'circle-heart',
  'heart-circle',
  'circle-i',
  'circle-info',
  'info-circle',
  'circle-j',
  'circle-k',
  'circle-l',
  'circle-left',
  'arrow-alt-circle-left',
  'circle-location-arrow',
  'location-circle',
  'circle-m',
  'circle-microphone',
  'microphone-circle',
  'circle-microphone-lines',
  'microphone-circle-alt',
  'circle-minus',
  'minus-circle',
  'circle-n',
  'circle-notch',
  'circle-o',
  'circle-p',
  'circle-parking',
  'parking-circle',
  'circle-pause',
  'pause-circle',
  'circle-phone',
  'phone-circle',
  'circle-phone-flip',
  'phone-circle-alt',
  'circle-phone-hangup',
  'phone-circle-down',
  'circle-play',
  'play-circle',
  'circle-plus',
  'plus-circle',
  'circle-q',
  'circle-quarter',
  'circle-quarters',
  'circle-question',
  'question-circle',
  'circle-r',
  'circle-radiation',
  'radiation-alt',
  'circle-right',
  'arrow-alt-circle-right',
  'circle-s',
  'circle-small',
  'circle-sort',
  'sort-circle',
  'circle-sort-down',
  'sort-circle-down',
  'circle-sort-up',
  'sort-circle-up',
  'circle-star',
  'star-circle',
  'circle-stop',
  'stop-circle',
  'circle-t',
  'circle-three-quarters',
  'circle-trash',
  'trash-circle',
  'circle-u',
  'circle-up',
  'arrow-alt-circle-up',
  'circle-up-left',
  'circle-up-right',
  'circle-user',
  'user-circle',
  'circle-v',
  'circle-video',
  'video-circle',
  'circle-w',
  'circle-waveform-lines',
  'waveform-circle',
  'circle-x',
  'circle-xmark',
  'times-circle',
  'xmark-circle',
  'circle-y',
  'circle-z',
  'citrus',
  'citrus-slice',
  'city',
  'clapperboard',
  'clapperboard-play',
  'clarinet',
  'claw-marks',
  'clipboard',
  'clipboard-check',
  'clipboard-list',
  'clipboard-list-check',
  'clipboard-medical',
  'clipboard-prescription',
  'clipboard-user',
  'clock',
  'clock-four',
  'clock-desk',
  'clock-eight',
  'clock-eight-thirty',
  'clock-eleven',
  'clock-eleven-thirty',
  'clock-five',
  'clock-five-thirty',
  'clock-four-thirty',
  'clock-nine',
  'clock-nine-thirty',
  'clock-one',
  'clock-one-thirty',
  'clock-rotate-left',
  'history',
  'clock-seven',
  'clock-seven-thirty',
  'clock-six',
  'clock-six-thirty',
  'clock-ten',
  'clock-ten-thirty',
  'clock-three',
  'clock-three-thirty',
  'clock-twelve',
  'clock-twelve-thirty',
  'clock-two',
  'clock-two-thirty',
  'clone',
  'closed-captioning',
  'closed-captioning-slash',
  'clothes-hanger',
  'cloud',
  'cloud-arrow-down',
  'cloud-download',
  'cloud-download-alt',
  'cloud-arrow-up',
  'cloud-upload',
  'cloud-upload-alt',
  'cloud-bolt',
  'thunderstorm',
  'cloud-bolt-moon',
  'thunderstorm-moon',
  'cloud-bolt-sun',
  'thunderstorm-sun',
  'cloud-check',
  'cloud-drizzle',
  'cloud-exclamation',
  'cloud-fog',
  'fog',
  'cloud-hail',
  'cloud-hail-mixed',
  'cloud-meatball',
  'cloud-minus',
  'cloud-moon',
  'cloud-moon-rain',
  'cloud-music',
  'cloud-plus',
  'cloud-question',
  'cloud-rain',
  'cloud-rainbow',
  'cloud-showers',
  'cloud-showers-heavy',
  'cloud-slash',
  'cloud-sleet',
  'cloud-snow',
  'cloud-sun',
  'cloud-sun-rain',
  'cloud-word',
  'cloud-xmark',
  'clouds',
  'clouds-moon',
  'clouds-sun',
  'clover',
  'club',
  'coconut',
  'code',
  'code-branch',
  'code-commit',
  'code-compare',
  'code-fork',
  'code-merge',
  'code-pull-request',
  'code-pull-request-closed',
  'code-pull-request-draft',
  'code-simple',
  'coffee-bean',
  'coffee-beans',
  'coffee-pot',
  'coffin',
  'coffin-cross',
  'coin',
  'coin-blank',
  'coin-front',
  'coin-vertical',
  'coins',
  'colon',
  'colon-sign',
  'columns-3',
  'comet',
  'comma',
  'command',
  'comment',
  'comment-arrow-down',
  'comment-arrow-up',
  'comment-arrow-up-right',
  'comment-captions',
  'comment-check',
  'comment-code',
  'comment-dollar',
  'comment-dots',
  'commenting',
  'comment-exclamation',
  'comment-image',
  'comment-lines',
  'comment-medical',
  'comment-middle',
  'comment-middle-top',
  'comment-minus',
  'comment-music',
  'comment-pen',
  'comment-edit',
  'comment-plus',
  'comment-question',
  'comment-quote',
  'comment-slash',
  'comment-smile',
  'comment-sms',
  'sms',
  'comment-text',
  'comment-xmark',
  'comment-times',
  'comments',
  'comments-dollar',
  'comments-question',
  'comments-question-check',
  'compact-disc',
  'compass',
  'compass-drafting',
  'drafting-compass',
  'compass-slash',
  'compress',
  'compress-wide',
  'computer-classic',
  'computer-mouse',
  'mouse',
  'computer-mouse-scrollwheel',
  'mouse-alt',
  'computer-speaker',
  'container-storage',
  'conveyor-belt',
  'conveyor-belt-boxes',
  'conveyor-belt-alt',
  'conveyor-belt-empty',
  'cookie',
  'cookie-bite',
  'copy',
  'copyright',
  'corn',
  'corner',
  'couch',
  'cow',
  'cowbell',
  'cowbell-circle-plus',
  'cowbell-more',
  'crab',
  'crate-apple',
  'apple-crate',
  'crate-empty',
  'credit-card',
  'credit-card-alt',
  'credit-card-blank',
  'credit-card-front',
  'cricket-bat-ball',
  'cricket',
  'croissant',
  'crop',
  'crop-simple',
  'crop-alt',
  'cross',
  'crosshairs',
  'crow',
  'crown',
  'crutch',
  'crutches',
  'cruzeiro-sign',
  'crystal-ball',
  'cube',
  'cubes',
  'cucumber',
  'cup-straw',
  'cup-straw-swoosh',
  'cup-togo',
  'coffee-togo',
  'cupcake',
  'curling-stone',
  'curling',
  'custard',
  'd',
  'dagger',
  'dash',
  'database',
  'deer',
  'deer-rudolph',
  'delete-left',
  'backspace',
  'delete-right',
  'democrat',
  'desktop',
  'desktop-alt',
  'desktop-arrow-down',
  'dharmachakra',
  'diagram-cells',
  'diagram-lean-canvas',
  'diagram-nested',
  'diagram-next',
  'diagram-predecessor',
  'diagram-previous',
  'diagram-project',
  'project-diagram',
  'diagram-sankey',
  'diagram-subtask',
  'diagram-successor',
  'diagram-venn',
  'dial',
  'dial-med-high',
  'dial-high',
  'dial-low',
  'dial-max',
  'dial-med',
  'dial-med-low',
  'dial-min',
  'dial-off',
  'diamond',
  'diamond-exclamation',
  'diamond-turn-right',
  'directions',
  'dice',
  'dice-d10',
  'dice-d12',
  'dice-d20',
  'dice-d4',
  'dice-d6',
  'dice-d8',
  'dice-five',
  'dice-four',
  'dice-one',
  'dice-six',
  'dice-three',
  'dice-two',
  'diploma',
  'scroll-ribbon',
  'disc-drive',
  'disease',
  'display',
  'display-arrow-down',
  'display-code',
  'desktop-code',
  'display-medical',
  'desktop-medical',
  'display-slash',
  'desktop-slash',
  'distribute-spacing-horizontal',
  'distribute-spacing-vertical',
  'ditto',
  'divide',
  'dna',
  'do-not-enter',
  'dog',
  'dog-leashed',
  'dollar-sign',
  'dollar',
  'usd',
  'dolly',
  'dolly-box',
  'dolly-empty',
  'dolphin',
  'dong-sign',
  'donut',
  'doughnut',
  'door-closed',
  'door-open',
  'dove',
  'down',
  'arrow-alt-down',
  'down-from-dotted-line',
  'down-from-line',
  'arrow-alt-from-top',
  'down-left',
  'down-left-and-up-right-to-center',
  'compress-alt',
  'down-long',
  'long-arrow-alt-down',
  'down-right',
  'down-to-dotted-line',
  'down-to-line',
  'arrow-alt-to-bottom',
  'download',
  'dragon',
  'draw-circle',
  'draw-polygon',
  'draw-square',
  'dreidel',
  'drone',
  'drone-front',
  'drone-alt',
  'droplet',
  'tint',
  'droplet-degree',
  'dewpoint',
  'droplet-percent',
  'humidity',
  'droplet-slash',
  'tint-slash',
  'drum',
  'drum-steelpan',
  'drumstick',
  'drumstick-bite',
  'dryer',
  'dryer-heat',
  'dryer-alt',
  'duck',
  'dumbbell',
  'dumpster',
  'dumpster-fire',
  'dungeon',
  'e',
  'ear',
  'ear-deaf',
  'deaf',
  'deafness',
  'hard-of-hearing',
  'ear-listen',
  'assistive-listening-systems',
  'ear-muffs',
  'earth-africa',
  'globe-africa',
  'earth-americas',
  'earth',
  'earth-america',
  'globe-americas',
  'earth-asia',
  'globe-asia',
  'earth-europe',
  'globe-europe',
  'earth-oceania',
  'globe-oceania',
  'eclipse',
  'egg',
  'egg-fried',
  'eggplant',
  'eject',
  'elephant',
  'elevator',
  'ellipsis',
  'ellipsis-h',
  'ellipsis-stroke',
  'ellipsis-h-alt',
  'ellipsis-stroke-vertical',
  'ellipsis-v-alt',
  'ellipsis-vertical',
  'ellipsis-v',
  'empty-set',
  'engine',
  'engine-warning',
  'engine-exclamation',
  'envelope',
  'envelope-dot',
  'envelope-badge',
  'envelope-open',
  'envelope-open-dollar',
  'envelope-open-text',
  'envelopes',
  'envelopes-bulk',
  'mail-bulk',
  'equals',
  'eraser',
  'escalator',
  'ethernet',
  'euro-sign',
  'eur',
  'euro',
  'exclamation',
  'expand',
  'expand-wide',
  'eye',
  'eye-dropper',
  'eye-dropper-empty',
  'eyedropper',
  'eye-dropper-full',
  'eye-dropper-half',
  'eye-evil',
  'eye-low-vision',
  'low-vision',
  'eye-slash',
  'eyes',
  'f',
  'face-angry',
  'angry',
  'face-angry-horns',
  'face-anguished',
  'face-anxious-sweat',
  'face-astonished',
  'face-awesome',
  'gave-dandy',
  'face-beam-hand-over-mouth',
  'face-clouds',
  'face-confounded',
  'face-confused',
  'face-cowboy-hat',
  'face-diagonal-mouth',
  'face-disappointed',
  'face-disguise',
  'face-dizzy',
  'dizzy',
  'face-dotted',
  'face-downcast-sweat',
  'face-drooling',
  'face-exhaling',
  'face-explode',
  'exploding-head',
  'face-expressionless',
  'face-eyes-xmarks',
  'face-fearful',
  'face-flushed',
  'flushed',
  'face-frown',
  'frown',
  'face-frown-open',
  'frown-open',
  'face-frown-slight',
  'face-glasses',
  'face-grimace',
  'grimace',
  'face-grin',
  'grin',
  'face-grin-beam',
  'grin-beam',
  'face-grin-beam-sweat',
  'grin-beam-sweat',
  'face-grin-hearts',
  'grin-hearts',
  'face-grin-squint',
  'grin-squint',
  'face-grin-squint-tears',
  'grin-squint-tears',
  'face-grin-stars',
  'grin-stars',
  'face-grin-tears',
  'grin-tears',
  'face-grin-tongue',
  'grin-tongue',
  'face-grin-tongue-squint',
  'grin-tongue-squint',
  'face-grin-tongue-wink',
  'grin-tongue-wink',
  'face-grin-wide',
  'grin-alt',
  'face-grin-wink',
  'grin-wink',
  'face-hand-over-mouth',
  'face-hand-peeking',
  'face-hand-yawn',
  'face-head-bandage',
  'face-holding-back-tears',
  'face-hushed',
  'face-icicles',
  'face-kiss',
  'kiss',
  'face-kiss-beam',
  'kiss-beam',
  'face-kiss-closed-eyes',
  'face-kiss-wink-heart',
  'kiss-wink-heart',
  'face-laugh',
  'laugh',
  'face-laugh-beam',
  'laugh-beam',
  'face-laugh-squint',
  'laugh-squint',
  'face-laugh-wink',
  'laugh-wink',
  'face-lying',
  'face-mask',
  'face-meh',
  'meh',
  'face-meh-blank',
  'meh-blank',
  'face-melting',
  'face-monocle',
  'face-nauseated',
  'face-nose-steam',
  'face-party',
  'face-pensive',
  'face-persevering',
  'face-pleading',
  'face-pouting',
  'face-raised-eyebrow',
  'face-relieved',
  'face-rolling-eyes',
  'meh-rolling-eyes',
  'face-sad-cry',
  'sad-cry',
  'face-sad-sweat',
  'face-sad-tear',
  'sad-tear',
  'face-saluting',
  'face-scream',
  'face-shush',
  'face-sleeping',
  'face-sleepy',
  'face-smile',
  'smile',
  'face-smile-beam',
  'smile-beam',
  'face-smile-halo',
  'face-smile-hearts',
  'face-smile-horns',
  'face-smile-plus',
  'smile-plus',
  'face-smile-relaxed',
  'face-smile-tear',
  'face-smile-tongue',
  'face-smile-upside-down',
  'face-smile-wink',
  'smile-wink',
  'face-smiling-hands',
  'face-smirking',
  'face-spiral-eyes',
  'face-sunglasses',
  'face-surprise',
  'surprise',
  'face-swear',
  'face-thermometer',
  'face-thinking',
  'face-tired',
  'tired',
  'face-tissue',
  'face-tongue-money',
  'face-tongue-sweat',
  'face-unamused',
  'face-viewfinder',
  'face-vomit',
  'face-weary',
  'face-woozy',
  'face-worried',
  'face-zany',
  'face-zipper',
  'falafel',
  'family',
  'family-dress',
  'family-pants',
  'fan',
  'fan-table',
  'farm',
  'barn-silo',
  'faucet',
  'faucet-drip',
  'fax',
  'feather',
  'feather-pointed',
  'feather-alt',
  'fence',
  'ferris-wheel',
  'field-hockey-stick-ball',
  'field-hockey',
  'file',
  'file-arrow-down',
  'file-download',
  'file-arrow-up',
  'file-upload',
  'file-audio',
  'file-binary',
  'file-certificate',
  'file-award',
  'file-chart-column',
  'file-chart-line',
  'file-chart-pie',
  'file-check',
  'file-circle-info',
  'file-circle-plus',
  'file-code',
  'file-contract',
  'file-csv',
  'file-dashed-line',
  'page-break',
  'file-excel',
  'file-exclamation',
  'file-export',
  'arrow-right-from-file',
  'file-heart',
  'file-image',
  'file-import',
  'arrow-right-to-file',
  'file-invoice',
  'file-invoice-dollar',
  'file-lines',
  'file-alt',
  'file-text',
  'file-lock',
  'file-magnifying-glass',
  'file-search',
  'file-medical',
  'file-minus',
  'file-music',
  'file-pdf',
  'file-pen',
  'file-edit',
  'file-plus',
  'file-plus-minus',
  'file-powerpoint',
  'file-prescription',
  'file-signature',
  'file-slash',
  'file-spreadsheet',
  'file-user',
  'file-video',
  'file-waveform',
  'file-medical-alt',
  'file-word',
  'file-xmark',
  'file-times',
  'file-zipper',
  'file-archive',
  'files',
  'files-medical',
  'fill',
  'fill-drip',
  'film',
  'film-canister',
  'film-simple',
  'film-alt',
  'film-slash',
  'films',
  'filter',
  'filter-circle-dollar',
  'funnel-dollar',
  'filter-circle-xmark',
  'filter-list',
  'filter-slash',
  'filters',
  'fingerprint',
  'fire',
  'fire-extinguisher',
  'fire-flame',
  'flame',
  'fire-flame-curved',
  'fire-alt',
  'fire-flame-simple',
  'burn',
  'fire-hydrant',
  'fire-smoke',
  'fireplace',
  'fish',
  'fish-bones',
  'fish-cooked',
  'fishing-rod',
  'flag',
  'flag-checkered',
  'flag-pennant',
  'pennant',
  'flag-swallowtail',
  'flag-alt',
  'flag-usa',
  'flashlight',
  'flask',
  'flask-round-poison',
  'flask-poison',
  'flask-round-potion',
  'flask-potion',
  'flatbread',
  'flatbread-stuffed',
  'floppy-disk',
  'save',
  'floppy-disk-circle-arrow-right',
  'save-circle-arrow-right',
  'floppy-disk-circle-xmark',
  'floppy-disk-times',
  'save-circle-xmark',
  'save-times',
  'floppy-disk-pen',
  'floppy-disks',
  'florin-sign',
  'flower',
  'flower-daffodil',
  'flower-tulip',
  'flute',
  'flux-capacitor',
  'flying-disc',
  'folder',
  'folder-arrow-down',
  'folder-download',
  'folder-arrow-up',
  'folder-upload',
  'folder-blank',
  'folder-bookmark',
  'folder-gear',
  'folder-cog',
  'folder-grid',
  'folder-heart',
  'folder-image',
  'folder-magnifying-glass',
  'folder-search',
  'folder-medical',
  'folder-minus',
  'folder-music',
  'folder-open',
  'folder-plus',
  'folder-tree',
  'folder-user',
  'folder-xmark',
  'folder-times',
  'folders',
  'fondue-pot',
  'font',
  'font-awesome',
  'font-awesome-flag',
  'font-awesome-logo-full',
  'font-case',
  'football',
  'football-ball',
  'football-helmet',
  'fork',
  'utensil-fork',
  'fork-knife',
  'utensils-alt',
  'forklift',
  'fort',
  'forward',
  'forward-fast',
  'fast-forward',
  'forward-step',
  'step-forward',
  'frame',
  'franc-sign',
  'french-fries',
  'frog',
  'function',
  'futbol',
  'futbol-ball',
  'soccer-ball',
  'g',
  'galaxy',
  'gallery-thumbnails',
  'game-board',
  'game-board-simple',
  'game-board-alt',
  'game-console-handheld',
  'gamepad',
  'gamepad-modern',
  'gamepad-alt',
  'garage',
  'garage-car',
  'garage-open',
  'garlic',
  'gas-pump',
  'gas-pump-slash',
  'gauge',
  'dashboard',
  'gauge-med',
  'tachometer-alt-average',
  'gauge-circle-bolt',
  'gauge-circle-minus',
  'gauge-circle-plus',
  'gauge-high',
  'tachometer-alt',
  'tachometer-alt-fast',
  'gauge-low',
  'tachometer-alt-slow',
  'gauge-max',
  'tachometer-alt-fastest',
  'gauge-min',
  'tachometer-alt-slowest',
  'gauge-simple',
  'gauge-simple-med',
  'tachometer-average',
  'gauge-simple-high',
  'tachometer',
  'tachometer-fast',
  'gauge-simple-low',
  'tachometer-slow',
  'gauge-simple-max',
  'tachometer-fastest',
  'gauge-simple-min',
  'tachometer-slowest',
  'gavel',
  'legal',
  'gear',
  'cog',
  'gears',
  'cogs',
  'gem',
  'genderless',
  'ghost',
  'gif',
  'gift',
  'gift-card',
  'gifts',
  'gingerbread-man',
  'glass',
  'glass-citrus',
  'glass-empty',
  'glass-half',
  'glass-half-empty',
  'glass-half-full',
  'glasses',
  'glasses-round',
  'glasses-alt',
  'globe',
  'globe-snow',
  'globe-stand',
  'goal-net',
  'golf-ball-tee',
  'golf-ball',
  'golf-club',
  'golf-flag-hole',
  'gopuram',
  'graduation-cap',
  'mortar-board',
  'gramophone',
  'grapes',
  'grate',
  'grate-droplet',
  'greater-than',
  'greater-than-equal',
  'grid',
  'grid3',
  'grid-2',
  'grid-2-plus',
  'grid-4',
  'grid-5',
  'grid-dividers',
  'grid-horizontal',
  'grip',
  'grip-horizontal',
  'grip-dots',
  'grip-dots-vertical',
  'grip-lines',
  'grip-lines-vertical',
  'grip-vertical',
  'guarani-sign',
  'guitar',
  'guitar-electric',
  'guitars',
  'gun',
  'gun-slash',
  'gun-squirt',
  'h',
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
  'hammer',
  'hammer-crash',
  'hammer-war',
  'hamsa',
  'hand',
  'hand-paper',
  'hand-back-fist',
  'hand-rock',
  'hand-back-point-down',
  'hand-back-point-left',
  'hand-back-point-ribbon',
  'hand-back-point-right',
  'hand-back-point-up',
  'hand-dots',
  'allergies',
  'hand-fingers-crossed',
  'hand-fist',
  'fist-raised',
  'hand-heart',
  'hand-holding',
  'hand-holding-box',
  'hand-holding-dollar',
  'hand-holding-usd',
  'hand-holding-droplet',
  'hand-holding-water',
  'hand-holding-heart',
  'hand-holding-magic',
  'hand-holding-medical',
  'hand-holding-seedling',
  'hand-holding-skull',
  'hand-horns',
  'hand-lizard',
  'hand-love',
  'hand-middle-finger',
  'hand-peace',
  'hand-point-down',
  'hand-point-left',
  'hand-point-ribbon',
  'hand-point-right',
  'hand-point-up',
  'hand-pointer',
  'hand-scissors',
  'hand-sparkles',
  'hand-spock',
  'hand-wave',
  'hands',
  'sign-language',
  'signing',
  'hands-asl-interpreting',
  'american-sign-language-interpreting',
  'asl-interpreting',
  'hands-american-sign-language-interpreting',
  'hands-bubbles',
  'hands-wash',
  'hands-clapping',
  'hands-holding',
  'hands-holding-diamond',
  'hand-receiving',
  'hands-holding-dollar',
  'hands-usd',
  'hands-holding-heart',
  'hands-heart',
  'hands-praying',
  'praying-hands',
  'handshake',
  'handshake-angle',
  'hands-helping',
  'handshake-simple',
  'handshake-alt',
  'handshake-simple-slash',
  'handshake-alt-slash',
  'handshake-slash',
  'hanukiah',
  'hard-drive',
  'hdd',
  'hashtag',
  'hashtag-lock',
  'hat-chef',
  'hat-cowboy',
  'hat-cowboy-side',
  'hat-santa',
  'hat-winter',
  'hat-witch',
  'hat-wizard',
  'head-side',
  'head-side-brain',
  'head-side-cough',
  'head-side-cough-slash',
  'head-side-goggles',
  'head-vr',
  'head-side-headphones',
  'head-side-heart',
  'head-side-mask',
  'head-side-medical',
  'head-side-virus',
  'heading',
  'header',
  'headphones',
  'headphones-simple',
  'headphones-alt',
  'headset',
  'heart',
  'heart-crack',
  'heart-broken',
  'heart-half',
  'heart-half-stroke',
  'heart-half-alt',
  'heart-pulse',
  'heartbeat',
  'heat',
  'helicopter',
  'helmet-battle',
  'helmet-safety',
  'hard-hat',
  'hat-hard',
  'hexagon',
  'hexagon-check',
  'hexagon-divide',
  'hexagon-exclamation',
  'hexagon-minus',
  'minus-hexagon',
  'hexagon-plus',
  'plus-hexagon',
  'hexagon-xmark',
  'times-hexagon',
  'xmark-hexagon',
  'high-definition',
  'rectangle-hd',
  'highlighter',
  'highlighter-line',
  'hippo',
  'hockey-mask',
  'hockey-puck',
  'hockey-stick-puck',
  'hockey-sticks',
  'holly-berry',
  'honey-pot',
  'hood-cloak',
  'horizontal-rule',
  'horse',
  'horse-head',
  'horse-saddle',
  'hose',
  'hose-reel',
  'hospital',
  'hospital-alt',
  'hospital-wide',
  'hospital-user',
  'hospitals',
  'hot-tub-person',
  'hot-tub',
  'hotdog',
  'hotel',
  'hourglass',
  'hourglass2',
  'hourglass-half',
  'hourglass-clock',
  'hourglass-empty',
  'hourglass-end',
  'hourglass3',
  'hourglass-start',
  'hourglass1',
  'house',
  'home',
  'home-alt',
  'home-lg-alt',
  'house-blank',
  'home-blank',
  'house-building',
  'house-chimney',
  'home-lg',
  'house-chimney-blank',
  'house-chimney-crack',
  'house-damage',
  'house-chimney-heart',
  'house-chimney-medical',
  'clinic-medical',
  'house-chimney-user',
  'house-chimney-window',
  'house-crack',
  'house-day',
  'house-flood',
  'house-heart',
  'home-heart',
  'house-laptop',
  'laptop-house',
  'house-medical',
  'house-night',
  'house-person-leave',
  'house-leave',
  'house-person-depart',
  'house-person-return',
  'house-person-arrive',
  'house-return',
  'house-signal',
  'house-tree',
  'house-turret',
  'house-user',
  'home-user',
  'house-window',
  'hryvnia-sign',
  'hryvnia',
  'hundred-points',
  '100',
  'hurricane',
  'hyphen',
  'i',
  'i-cursor',
  'ice-cream',
  'ice-skate',
  'icicles',
  'icons',
  'heart-music-camera-bolt',
  'id-badge',
  'id-card',
  'drivers-license',
  'id-card-clip',
  'id-card-alt',
  'igloo',
  'image',
  'image-landscape',
  'landscape',
  'image-polaroid',
  'image-polaroid-user',
  'image-portrait',
  'portrait',
  'image-slash',
  'image-user',
  'images',
  'images-user',
  'inbox',
  'inbox-full',
  'inbox-in',
  'inbox-arrow-down',
  'inbox-out',
  'inbox-arrow-up',
  'inboxes',
  'indent',
  'indian-rupee-sign',
  'indian-rupee',
  'inr',
  'industry',
  'industry-windows',
  'industry-alt',
  'infinity',
  'info',
  'inhaler',
  'input-numeric',
  'input-pipe',
  'input-text',
  'integral',
  'intersection',
  'island-tropical',
  'island-tree-palm',
  'italic',
  'j',
  'jack-o-lantern',
  'jedi',
  'jet-fighter',
  'fighter-jet',
  'joint',
  'joystick',
  'jug',
  'k',
  'kaaba',
  'kazoo',
  'kerning',
  'key',
  'key-skeleton',
  'key-skeleton-left-right',
  'keyboard',
  'keyboard-brightness',
  'keyboard-brightness-low',
  'keyboard-down',
  'keyboard-left',
  'keynote',
  'khanda',
  'kidneys',
  'kip-sign',
  'kit-medical',
  'first-aid',
  'kite',
  'kiwi-bird',
  'kiwi-fruit',
  'knife',
  'utensil-knife',
  'knife-kitchen',
  'l',
  'lacrosse-stick',
  'lacrosse-stick-ball',
  'lambda',
  'lamp',
  'lamp-desk',
  'lamp-floor',
  'lamp-street',
  'landmark',
  'landmark-dome',
  'landmark-alt',
  'language',
  'laptop',
  'laptop-arrow-down',
  'laptop-code',
  'laptop-medical',
  'laptop-mobile',
  'phone-laptop',
  'laptop-slash',
  'lari-sign',
  'lasso',
  'lasso-sparkles',
  'layer-group',
  'layer-minus',
  'layer-group-minus',
  'layer-plus',
  'layer-group-plus',
  'leaf',
  'leaf-heart',
  'leaf-maple',
  'leaf-oak',
  'leafy-green',
  'left',
  'arrow-alt-left',
  'left-from-line',
  'arrow-alt-from-right',
  'left-long',
  'long-arrow-alt-left',
  'left-long-to-line',
  'left-right',
  'arrows-alt-h',
  'left-to-line',
  'arrow-alt-to-left',
  'lemon',
  'less-than',
  'less-than-equal',
  'life-ring',
  'light-ceiling',
  'light-emergency',
  'light-emergency-on',
  'light-switch',
  'light-switch-off',
  'light-switch-on',
  'lightbulb',
  'lightbulb-dollar',
  'lightbulb-exclamation',
  'lightbulb-exclamation-on',
  'lightbulb-on',
  'lightbulb-slash',
  'lights-holiday',
  'line-columns',
  'line-height',
  'link',
  'chain',
  'link-horizontal',
  'chain-horizontal',
  'link-horizontal-slash',
  'chain-horizontal-slash',
  'link-simple',
  'link-simple-slash',
  'link-slash',
  'chain-broken',
  'chain-slash',
  'unlink',
  'lips',
  'lira-sign',
  'list',
  'list-squares',
  'list-check',
  'tasks',
  'list-dropdown',
  'list-music',
  'list-ol',
  'list12',
  'list-numeric',
  'list-radio',
  'list-timeline',
  'list-tree',
  'list-ul',
  'list-dots',
  'litecoin-sign',
  'loader',
  'lobster',
  'location-arrow',
  'location-check',
  'map-marker-check',
  'location-crosshairs',
  'location',
  'location-crosshairs-slash',
  'location-slash',
  'location-dot',
  'map-marker-alt',
  'location-dot-slash',
  'map-marker-alt-slash',
  'location-exclamation',
  'map-marker-exclamation',
  'location-minus',
  'map-marker-minus',
  'location-pen',
  'map-marker-edit',
  'location-pin',
  'map-marker',
  'location-pin-slash',
  'map-marker-slash',
  'location-plus',
  'map-marker-plus',
  'location-question',
  'map-marker-question',
  'location-smile',
  'map-marker-smile',
  'location-xmark',
  'map-marker-times',
  'map-marker-xmark',
  'lock',
  'lock-a',
  'lock-hashtag',
  'lock-keyhole',
  'lock-alt',
  'lock-keyhole-open',
  'lock-open-alt',
  'lock-open',
  'lollipop',
  'lollypop',
  'loveseat',
  'couch-small',
  'luchador-mask',
  'luchador',
  'mask-luchador',
  'lungs',
  'lungs-virus',
  'm',
  'mace',
  'magnet',
  'magnifying-glass',
  'search',
  'magnifying-glass-dollar',
  'search-dollar',
  'magnifying-glass-location',
  'search-location',
  'magnifying-glass-minus',
  'search-minus',
  'magnifying-glass-plus',
  'search-plus',
  'mailbox',
  'manat-sign',
  'mandolin',
  'mango',
  'manhole',
  'map',
  'map-location',
  'map-marked',
  'map-location-dot',
  'map-marked-alt',
  'map-pin',
  'marker',
  'mars',
  'mars-and-venus',
  'mars-double',
  'mars-stroke',
  'mars-stroke-right',
  'mars-stroke-h',
  'mars-stroke-up',
  'mars-stroke-v',
  'martini-glass',
  'glass-martini-alt',
  'martini-glass-citrus',
  'cocktail',
  'martini-glass-empty',
  'glass-martini',
  'mask',
  'mask-face',
  'mask-snorkel',
  'masks-theater',
  'theater-masks',
  'maximize',
  'expand-arrows-alt',
  'meat',
  'medal',
  'megaphone',
  'melon',
  'melon-slice',
  'memo',
  'memo-circle-check',
  'memo-circle-info',
  'memo-pad',
  'memory',
  'menorah',
  'mercury',
  'message',
  'comment-alt',
  'message-arrow-down',
  'comment-alt-arrow-down',
  'message-arrow-up',
  'comment-alt-arrow-up',
  'message-arrow-up-right',
  'message-bot',
  'message-captions',
  'comment-alt-captions',
  'message-check',
  'comment-alt-check',
  'message-code',
  'message-dollar',
  'comment-alt-dollar',
  'message-dots',
  'comment-alt-dots',
  'messaging',
  'message-exclamation',
  'comment-alt-exclamation',
  'message-image',
  'comment-alt-image',
  'message-lines',
  'comment-alt-lines',
  'message-medical',
  'comment-alt-medical',
  'message-middle',
  'comment-middle-alt',
  'message-middle-top',
  'comment-middle-top-alt',
  'message-minus',
  'comment-alt-minus',
  'message-music',
  'comment-alt-music',
  'message-pen',
  'comment-alt-edit',
  'message-edit',
  'message-plus',
  'comment-alt-plus',
  'message-question',
  'message-quote',
  'comment-alt-quote',
  'message-slash',
  'comment-alt-slash',
  'message-smile',
  'comment-alt-smile',
  'message-sms',
  'message-text',
  'comment-alt-text',
  'message-xmark',
  'comment-alt-times',
  'message-times',
  'messages',
  'comments-alt',
  'messages-dollar',
  'comments-alt-dollar',
  'messages-question',
  'meteor',
  'meter',
  'meter-bolt',
  'meter-droplet',
  'meter-fire',
  'microchip',
  'microchip-ai',
  'microphone',
  'microphone-lines',
  'microphone-alt',
  'microphone-lines-slash',
  'microphone-alt-slash',
  'microphone-slash',
  'microphone-stand',
  'microscope',
  'microwave',
  'mill-sign',
  'minimize',
  'compress-arrows-alt',
  'minus',
  'subtract',
  'mistletoe',
  'mitten',
  'mobile',
  'mobile-android',
  'mobile-phone',
  'mobile-button',
  'mobile-notch',
  'mobile-iphone',
  'mobile-screen',
  'mobile-android-alt',
  'mobile-screen-button',
  'mobile-alt',
  'mobile-signal',
  'mobile-signal-out',
  'money-bill',
  'money-bill-1',
  'money-bill-alt',
  'money-bill-1-wave',
  'money-bill-wave-alt',
  'money-bill-simple',
  'money-bill-simple-wave',
  'money-bill-wave',
  'money-bills',
  'money-bills-simple',
  'money-bills-alt',
  'money-check',
  'money-check-dollar',
  'money-check-alt',
  'money-check-dollar-pen',
  'money-check-edit-alt',
  'money-check-pen',
  'money-check-edit',
  'money-from-bracket',
  'money-simple-from-bracket',
  'monitor-waveform',
  'monitor-heart-rate',
  'monkey',
  'monument',
  'moon',
  'moon-cloud',
  'moon-over-sun',
  'eclipse-alt',
  'moon-stars',
  'moped',
  'mortar-pestle',
  'mosque',
  'motorcycle',
  'mountain',
  'mountains',
  'mp3-player',
  'mug',
  'mug-hot',
  'mug-marshmallows',
  'mug-saucer',
  'coffee',
  'mug-tea',
  'mug-tea-saucer',
  'mushroom',
  'music',
  'music-note',
  'music-alt',
  'music-note-slash',
  'music-alt-slash',
  'music-slash',
  'n',
  'naira-sign',
  'narwhal',
  'nesting-dolls',
  'network-wired',
  'neuter',
  'newspaper',
  'nfc',
  'nfc-lock',
  'nfc-magnifying-glass',
  'nfc-pen',
  'nfc-signal',
  'nfc-slash',
  'nfc-trash',
  'not-equal',
  'notdef',
  'note',
  'note-medical',
  'note-sticky',
  'sticky-note',
  'notebook',
  'notes',
  'notes-medical',
  'o',
  'object-exclude',
  'object-group',
  'object-intersect',
  'object-subtract',
  'object-ungroup',
  'object-union',
  'objects-align-bottom',
  'objects-align-center-horizontal',
  'objects-align-center-vertical',
  'objects-align-left',
  'objects-align-right',
  'objects-align-top',
  'objects-column',
  'octagon',
  'octagon-check',
  'octagon-divide',
  'octagon-exclamation',
  'octagon-minus',
  'minus-octagon',
  'octagon-plus',
  'plus-octagon',
  'octagon-xmark',
  'times-octagon',
  'xmark-octagon',
  'oil-can',
  'oil-can-drip',
  'oil-temperature',
  'oil-temp',
  'olive',
  'olive-branch',
  'om',
  'omega',
  'onion',
  'option',
  'ornament',
  'otter',
  'outdent',
  'dedent',
  'outlet',
  'oven',
  'overline',
  'p',
  'page',
  'page-caret-down',
  'file-caret-down',
  'page-caret-up',
  'file-caret-up',
  'pager',
  'paint-roller',
  'paintbrush',
  'paint-brush',
  'paintbrush-fine',
  'paint-brush-alt',
  'paint-brush-fine',
  'paintbrush-alt',
  'paintbrush-pencil',
  'palette',
  'pallet',
  'pallet-box',
  'pallet-boxes',
  'palette-boxes',
  'pallet-alt',
  'pan-food',
  'pan-frying',
  'pancakes',
  'panel-ews',
  'panel-fire',
  'panorama',
  'paper-plane',
  'paper-plane-top',
  'paper-plane-alt',
  'send',
  'paperclip',
  'paperclip-vertical',
  'parachute-box',
  'paragraph',
  'paragraph-left',
  'paragraph-rtl',
  'party-bell',
  'party-horn',
  'passport',
  'paste',
  'file-clipboard',
  'pause',
  'paw',
  'paw-claws',
  'paw-simple',
  'paw-alt',
  'peace',
  'peach',
  'peanut',
  'peanuts',
  'peapod',
  'pear',
  'pedestal',
  'pegasus',
  'pen',
  'pen-circle',
  'pen-clip',
  'pen-alt',
  'pen-clip-slash',
  'pen-alt-slash',
  'pen-fancy',
  'pen-fancy-slash',
  'pen-field',
  'pen-line',
  'pen-nib',
  'pen-nib-slash',
  'pen-paintbrush',
  'pencil-paintbrush',
  'pen-ruler',
  'pencil-ruler',
  'pen-slash',
  'pen-swirl',
  'pen-to-square',
  'edit',
  'pencil',
  'pencil-alt',
  'pencil-slash',
  'people',
  'people-arrows-left-right',
  'people-arrows',
  'people-carry-box',
  'people-carry',
  'people-dress',
  'people-dress-simple',
  'people-pants',
  'people-pants-simple',
  'people-simple',
  'pepper',
  'pepper-hot',
  'percent',
  'percentage',
  'period',
  'person',
  'male',
  'person-biking',
  'biking',
  'person-biking-mountain',
  'biking-mountain',
  'person-booth',
  'person-carry-box',
  'person-carry',
  'person-digging',
  'digging',
  'person-dolly',
  'person-dolly-empty',
  'person-dots-from-line',
  'diagnoses',
  'person-dress',
  'female',
  'person-dress-simple',
  'person-from-portal',
  'portal-exit',
  'person-hiking',
  'hiking',
  'person-pinball',
  'person-praying',
  'pray',
  'person-pregnant',
  'person-running',
  'running',
  'person-seat',
  'person-seat-reclined',
  'person-sign',
  'person-simple',
  'person-skating',
  'skating',
  'person-ski-jumping',
  'ski-jump',
  'person-ski-lift',
  'ski-lift',
  'person-skiing',
  'skiing',
  'person-skiing-nordic',
  'skiing-nordic',
  'person-sledding',
  'sledding',
  'person-snowboarding',
  'snowboarding',
  'person-snowmobiling',
  'snowmobile',
  'person-swimming',
  'swimmer',
  'person-to-door',
  'person-to-portal',
  'portal-enter',
  'person-walking',
  'walking',
  'person-walking-with-cane',
  'blind',
  'peseta-sign',
  'peso-sign',
  'phone',
  'phone-arrow-down-left',
  'phone-arrow-down',
  'phone-incoming',
  'phone-arrow-up-right',
  'phone-arrow-up',
  'phone-outgoing',
  'phone-flip',
  'phone-alt',
  'phone-hangup',
  'phone-intercom',
  'phone-missed',
  'phone-office',
  'phone-plus',
  'phone-rotary',
  'phone-slash',
  'phone-volume',
  'volume-control-phone',
  'phone-xmark',
  'photo-film',
  'photo-video',
  'photo-film-music',
  'pi',
  'piano',
  'piano-keyboard',
  'pickleball',
  'pie',
  'pig',
  'piggy-bank',
  'pills',
  'pinata',
  'pinball',
  'pineapple',
  'pipe',
  'pipe-circle-check',
  'pipe-collar',
  'pipe-section',
  'pipe-smoking',
  'pipe-valve',
  'pizza',
  'pizza-slice',
  'place-of-worship',
  'plane',
  'plane-arrival',
  'plane-departure',
  'plane-engines',
  'plane-alt',
  'plane-prop',
  'plane-slash',
  'plane-tail',
  'plane-up',
  'plane-up-slash',
  'planet-moon',
  'planet-ringed',
  'plate-utensils',
  'play',
  'play-pause',
  'plug',
  'plus',
  'add',
  'plus-minus',
  'podcast',
  'podium',
  'podium-star',
  'police-box',
  'poll-people',
  'pompebled',
  'poo',
  'poo-storm',
  'poo-bolt',
  'pool-8-ball',
  'poop',
  'popcorn',
  'popsicle',
  'pot-food',
  'potato',
  'power-off',
  'prescription',
  'prescription-bottle',
  'prescription-bottle-medical',
  'prescription-bottle-alt',
  'presentation-screen',
  'presentation',
  'pretzel',
  'print',
  'print-magnifying-glass',
  'print-search',
  'print-slash',
  'projector',
  'pump',
  'pump-medical',
  'pump-soap',
  'pumpkin',
  'puzzle',
  'puzzle-piece',
  'puzzle-piece-simple',
  'puzzle-piece-alt',
  'q',
  'qrcode',
  'question',
  'quote-left',
  'quote-left-alt',
  'quote-right',
  'quote-right-alt',
  'quotes',
  'r',
  'rabbit',
  'rabbit-running',
  'rabbit-fast',
  'racquet',
  'radar',
  'radiation',
  'radio',
  'radio-tuner',
  'radio-alt',
  'rainbow',
  'raindrops',
  'ram',
  'ramp-loading',
  'raygun',
  'receipt',
  'record-vinyl',
  'rectangle',
  'rectangle-landscape',
  'rectangle-ad',
  'ad',
  'rectangle-barcode',
  'barcode-alt',
  'rectangle-code',
  'rectangle-history',
  'rectangle-history-circle-plus',
  'rectangle-history-circle-user',
  'rectangle-list',
  'list-alt',
  'rectangle-pro',
  'pro',
  'rectangle-terminal',
  'rectangle-vertical',
  'rectangle-portrait',
  'rectangle-vertical-history',
  'rectangle-wide',
  'rectangle-xmark',
  'rectangle-times',
  'times-rectangle',
  'window-close',
  'rectangles-mixed',
  'recycle',
  'reel',
  'refrigerator',
  'registered',
  'repeat',
  'repeat-1',
  'reply',
  'mail-reply',
  'reply-all',
  'mail-reply-all',
  'reply-clock',
  'reply-time',
  'republican',
  'restroom',
  'restroom-simple',
  'retweet',
  'rhombus',
  'ribbon',
  'right',
  'arrow-alt-right',
  'right-from-bracket',
  'sign-out-alt',
  'right-from-line',
  'arrow-alt-from-left',
  'right-left',
  'exchange-alt',
  'right-long',
  'long-arrow-alt-right',
  'right-long-to-line',
  'right-to-bracket',
  'sign-in-alt',
  'right-to-line',
  'arrow-alt-to-right',
  'ring',
  'rings-wedding',
  'road',
  'robot',
  'robot-astromech',
  'rocket',
  'rocket-launch',
  'roller-coaster',
  'rotate',
  'sync-alt',
  'rotate-exclamation',
  'rotate-left',
  'rotate-back',
  'rotate-backward',
  'undo-alt',
  'rotate-right',
  'redo-alt',
  'rotate-forward',
  'route',
  'route-highway',
  'route-interstate',
  'router',
  'rss',
  'feed',
  'ruble-sign',
  'rouble',
  'rub',
  'ruble',
  'rugby-ball',
  'ruler',
  'ruler-combined',
  'ruler-horizontal',
  'ruler-triangle',
  'ruler-vertical',
  'rupee-sign',
  'rupee',
  'rupiah-sign',
  'rv',
  's',
  'sack',
  'sack-dollar',
  'sailboat',
  'salad',
  'bowl-salad',
  'salt-shaker',
  'sandwich',
  'satellite',
  'satellite-dish',
  'sausage',
  'saxophone',
  'saxophone-fire',
  'sax-hot',
  'scale-balanced',
  'balance-scale',
  'scale-unbalanced',
  'balance-scale-left',
  'scale-unbalanced-flip',
  'balance-scale-right',
  'scalpel',
  'scalpel-line-dashed',
  'scalpel-path',
  'scanner',
  'scanner-image',
  'scanner-gun',
  'scanner-keyboard',
  'scanner-touchscreen',
  'scarecrow',
  'scarf',
  'school',
  'scissors',
  'cut',
  'screen-users',
  'users-class',
  'screencast',
  'screwdriver',
  'screwdriver-wrench',
  'tools',
  'scribble',
  'scroll',
  'scroll-old',
  'scroll-torah',
  'torah',
  'scrubber',
  'scythe',
  'sd-card',
  'sd-cards',
  'seal',
  'seal-exclamation',
  'seal-question',
  'seat-airline',
  'section',
  'seedling',
  'sprout',
  'semicolon',
  'send-back',
  'send-backward',
  'sensor',
  'sensor-cloud',
  'sensor-smoke',
  'sensor-fire',
  'sensor-on',
  'sensor-triangle-exclamation',
  'sensor-alert',
  'server',
  'shapes',
  'triangle-circle-square',
  'share',
  'arrow-turn-right',
  'mail-forward',
  'share-all',
  'arrows-turn-right',
  'share-from-square',
  'share-square',
  'share-nodes',
  'share-alt',
  'sheep',
  'shekel-sign',
  'ils',
  'shekel',
  'sheqel',
  'sheqel-sign',
  'shelves',
  'inventory',
  'shelves-empty',
  'shield',
  'shield-blank',
  'shield-alt',
  'shield-check',
  'shield-cross',
  'shield-exclamation',
  'shield-keyhole',
  'shield-minus',
  'shield-plus',
  'shield-slash',
  'shield-virus',
  'shield-xmark',
  'shield-times',
  'ship',
  'shirt',
  't-shirt',
  'tshirt',
  'shirt-long-sleeve',
  'shirt-running',
  'shirt-tank-top',
  'shish-kebab',
  'shoe-prints',
  'shop',
  'store-alt',
  'shop-lock',
  'shop-slash',
  'store-alt-slash',
  'shovel',
  'shovel-snow',
  'shower',
  'shower-down',
  'shower-alt',
  'shredder',
  'shrimp',
  'shuffle',
  'random',
  'shutters',
  'shuttle-space',
  'space-shuttle',
  'shuttlecock',
  'sickle',
  'sidebar',
  'sidebar-flip',
  'sigma',
  'sign-hanging',
  'sign',
  'signal',
  'signal5',
  'signal-perfect',
  'signal-bars',
  'signal-alt',
  'signal-alt4',
  'signal-bars-strong',
  'signal-bars-fair',
  'signal-alt2',
  'signal-bars-good',
  'signal-alt3',
  'signal-bars-slash',
  'signal-alt-slash',
  'signal-bars-weak',
  'signal-alt1',
  'signal-fair',
  'signal2',
  'signal-good',
  'signal3',
  'signal-slash',
  'signal-stream',
  'signal-stream-slash',
  'signal-strong',
  'signal4',
  'signal-weak',
  'signal1',
  'signature',
  'signature-lock',
  'signature-slash',
  'signs-post',
  'map-signs',
  'sim-card',
  'sim-cards',
  'sink',
  'siren',
  'siren-on',
  'sitemap',
  'skeleton',
  'ski-boot',
  'ski-boot-ski',
  'skull',
  'skull-cow',
  'skull-crossbones',
  'slash',
  'slash-back',
  'slash-forward',
  'sleigh',
  'slider',
  'sliders',
  'sliders-h',
  'sliders-simple',
  'sliders-up',
  'sliders-v',
  'slot-machine',
  'smog',
  'smoke',
  'smoking',
  'snake',
  'snooze',
  'zzz',
  'snow-blowing',
  'snowflake',
  'snowflakes',
  'snowman',
  'snowman-head',
  'frosty-head',
  'snowplow',
  'soap',
  'socks',
  'soft-serve',
  'creemee',
  'solar-panel',
  'solar-system',
  'sort',
  'unsorted',
  'sort-down',
  'sort-desc',
  'sort-up',
  'sort-asc',
  'spa',
  'space-station-moon',
  'space-station-moon-construction',
  'space-station-moon-alt',
  'spade',
  'spaghetti-monster-flying',
  'pastafarianism',
  'sparkles',
  'speaker',
  'speakers',
  'spell-check',
  'spider',
  'spider-black-widow',
  'spider-web',
  'spinner',
  'spinner-third',
  'split',
  'splotch',
  'spoon',
  'utensil-spoon',
  'sportsball',
  'spray-can',
  'spray-can-sparkles',
  'air-freshener',
  'sprinkler',
  'sprinkler-ceiling',
  'square',
  'square-0',
  'square-1',
  'square-2',
  'square-3',
  'square-4',
  'square-5',
  'square-6',
  'square-7',
  'square-8',
  'square-9',
  'square-a',
  'square-a-lock',
  'square-ampersand',
  'square-arrow-down',
  'arrow-square-down',
  'square-arrow-down-left',
  'square-arrow-down-right',
  'square-arrow-left',
  'arrow-square-left',
  'square-arrow-right',
  'arrow-square-right',
  'square-arrow-up',
  'arrow-square-up',
  'square-arrow-up-left',
  'square-arrow-up-right',
  'external-link-square',
  'square-b',
  'square-bolt',
  'square-c',
  'square-caret-down',
  'caret-square-down',
  'square-caret-left',
  'caret-square-left',
  'square-caret-right',
  'caret-square-right',
  'square-caret-up',
  'caret-square-up',
  'square-check',
  'check-square',
  'square-chevron-down',
  'chevron-square-down',
  'square-chevron-left',
  'chevron-square-left',
  'square-chevron-right',
  'chevron-square-right',
  'square-chevron-up',
  'chevron-square-up',
  'square-code',
  'square-d',
  'square-dashed',
  'square-divide',
  'square-dollar',
  'dollar-square',
  'usd-square',
  'square-down',
  'arrow-alt-square-down',
  'square-down-left',
  'square-down-right',
  'square-e',
  'square-ellipsis',
  'square-ellipsis-vertical',
  'square-envelope',
  'envelope-square',
  'square-exclamation',
  'exclamation-square',
  'square-f',
  'square-fragile',
  'box-fragile',
  'square-wine-glass-crack',
  'square-full',
  'square-g',
  'square-h',
  'h-square',
  'square-heart',
  'heart-square',
  'square-i',
  'square-info',
  'info-square',
  'square-j',
  'square-k',
  'square-kanban',
  'square-l',
  'square-left',
  'arrow-alt-square-left',
  'square-list',
  'square-m',
  'square-minus',
  'minus-square',
  'square-n',
  'square-o',
  'square-p',
  'square-parking',
  'parking',
  'square-parking-slash',
  'parking-slash',
  'square-pen',
  'pen-square',
  'pencil-square',
  'square-phone',
  'phone-square',
  'square-phone-flip',
  'phone-square-alt',
  'square-phone-hangup',
  'phone-square-down',
  'square-plus',
  'plus-square',
  'square-poll-horizontal',
  'poll-h',
  'square-poll-vertical',
  'poll',
  'square-q',
  'square-quarters',
  'square-question',
  'question-square',
  'square-quote',
  'square-r',
  'square-right',
  'arrow-alt-square-right',
  'square-ring',
  'square-root',
  'square-root-variable',
  'square-root-alt',
  'square-rss',
  'rss-square',
  'square-s',
  'square-share-nodes',
  'share-alt-square',
  'square-sliders',
  'sliders-h-square',
  'square-sliders-vertical',
  'sliders-v-square',
  'square-small',
  'square-star',
  'square-t',
  'square-terminal',
  'square-this-way-up',
  'box-up',
  'square-u',
  'square-up',
  'arrow-alt-square-up',
  'square-up-left',
  'square-up-right',
  'external-link-square-alt',
  'square-user',
  'square-v',
  'square-w',
  'square-x',
  'square-xmark',
  'times-square',
  'xmark-square',
  'square-y',
  'square-z',
  'squid',
  'squirrel',
  'staff',
  'stairs',
  'stamp',
  'standard-definition',
  'rectangle-sd',
  'star',
  'star-and-crescent',
  'star-christmas',
  'star-exclamation',
  'star-half',
  'star-half-stroke',
  'star-half-alt',
  'star-of-david',
  'star-of-life',
  'star-sharp',
  'star-sharp-half',
  'star-sharp-half-stroke',
  'star-sharp-half-alt',
  'star-shooting',
  'starfighter',
  'starfighter-twin-ion-engine',
  'starfighter-alt',
  'starfighter-twin-ion-engine-advanced',
  'starfighter-alt-advanced',
  'stars',
  'starship',
  'starship-freighter',
  'steak',
  'steering-wheel',
  'sterling-sign',
  'gbp',
  'pound-sign',
  'stethoscope',
  'stocking',
  'stomach',
  'stop',
  'stopwatch',
  'stopwatch-20',
  'store',
  'store-lock',
  'store-slash',
  'strawberry',
  'street-view',
  'stretcher',
  'strikethrough',
  'stroopwafel',
  'subscript',
  'suitcase',
  'suitcase-medical',
  'medkit',
  'suitcase-rolling',
  'sun',
  'sun-bright',
  'sun-alt',
  'sun-cloud',
  'sun-dust',
  'sun-haze',
  'sunglasses',
  'sunrise',
  'sunset',
  'superscript',
  'sushi',
  'nigiri',
  'sushi-roll',
  'maki-roll',
  'makizushi',
  'swatchbook',
  'sword',
  'sword-laser',
  'sword-laser-alt',
  'swords',
  'swords-laser',
  'symbols',
  'icons-alt',
  'synagogue',
  'syringe',
  't',
  'table',
  'table-cells',
  'th',
  'table-cells-large',
  'th-large',
  'table-columns',
  'columns',
  'table-layout',
  'table-list',
  'th-list',
  'table-picnic',
  'table-pivot',
  'table-rows',
  'rows',
  'table-tennis-paddle-ball',
  'ping-pong-paddle-ball',
  'table-tennis',
  'table-tree',
  'tablet',
  'tablet-android',
  'tablet-button',
  'tablet-rugged',
  'tablet-screen',
  'tablet-android-alt',
  'tablet-screen-button',
  'tablet-alt',
  'tablets',
  'tachograph-digital',
  'digital-tachograph',
  'taco',
  'tag',
  'tags',
  'tally',
  'tally5',
  'tally-1',
  'tally-2',
  'tally-3',
  'tally-4',
  'tamale',
  'tank-water',
  'tape',
  'taxi',
  'cab',
  'taxi-bus',
  'teddy-bear',
  'teeth',
  'teeth-open',
  'telescope',
  'temperature-arrow-down',
  'temperature-down',
  'temperature-arrow-up',
  'temperature-up',
  'temperature-empty',
  'temperature0',
  'thermometer0',
  'thermometer-empty',
  'temperature-full',
  'temperature4',
  'thermometer4',
  'thermometer-full',
  'temperature-half',
  'temperature2',
  'thermometer2',
  'thermometer-half',
  'temperature-high',
  'temperature-list',
  'temperature-low',
  'temperature-quarter',
  'temperature1',
  'thermometer1',
  'thermometer-quarter',
  'temperature-snow',
  'temperature-frigid',
  'temperature-sun',
  'temperature-hot',
  'temperature-three-quarters',
  'temperature3',
  'thermometer3',
  'thermometer-three-quarters',
  'tenge-sign',
  'tenge',
  'tennis-ball',
  'terminal',
  'text',
  'text-height',
  'text-size',
  'text-slash',
  'remove-format',
  'text-width',
  'thermometer',
  'theta',
  'thought-bubble',
  'thumbs-down',
  'thumbs-up',
  'thumbtack',
  'thumb-tack',
  'tick',
  'ticket',
  'ticket-airline',
  'ticket-simple',
  'ticket-alt',
  'tickets-airline',
  'tilde',
  'timeline',
  'timeline-arrow',
  'timer',
  'tire',
  'tire-flat',
  'tire-pressure-warning',
  'tire-rugged',
  'toggle-off',
  'toggle-on',
  'toilet',
  'toilet-paper',
  'toilet-paper-blank',
  'toilet-paper-alt',
  'toilet-paper-blank-under',
  'toilet-paper-reverse-alt',
  'toilet-paper-slash',
  'toilet-paper-under',
  'toilet-paper-reverse',
  'toilet-paper-under-slash',
  'toilet-paper-reverse-slash',
  'tomato',
  'tombstone',
  'tombstone-blank',
  'tombstone-alt',
  'toolbox',
  'tooth',
  'toothbrush',
  'torii-gate',
  'tornado',
  'tower-broadcast',
  'broadcast-tower',
  'tower-control',
  'tractor',
  'trademark',
  'traffic-cone',
  'traffic-light',
  'traffic-light-go',
  'traffic-light-slow',
  'traffic-light-stop',
  'trailer',
  'train',
  'train-subway',
  'subway',
  'train-subway-tunnel',
  'subway-tunnel',
  'train-track',
  'train-tram',
  'tram',
  'train-tunnel',
  'transformer-bolt',
  'transgender',
  'transgender-alt',
  'transporter',
  'transporter-1',
  'transporter-2',
  'transporter-3',
  'transporter-4',
  'transporter-5',
  'transporter-6',
  'transporter-7',
  'transporter-empty',
  'trash',
  'trash-arrow-up',
  'trash-restore',
  'trash-can',
  'trash-alt',
  'trash-can-arrow-up',
  'trash-restore-alt',
  'trash-can-check',
  'trash-can-clock',
  'trash-can-list',
  'trash-can-plus',
  'trash-can-slash',
  'trash-alt-slash',
  'trash-can-undo',
  'trash-can-arrow-turn-left',
  'trash-undo-alt',
  'trash-can-xmark',
  'trash-check',
  'trash-clock',
  'trash-list',
  'trash-plus',
  'trash-slash',
  'trash-undo',
  'trash-arrow-turn-left',
  'trash-xmark',
  'treasure-chest',
  'tree',
  'tree-christmas',
  'tree-deciduous',
  'tree-alt',
  'tree-decorated',
  'tree-large',
  'tree-palm',
  'trees',
  'triangle',
  'triangle-exclamation',
  'exclamation-triangle',
  'warning',
  'triangle-instrument',
  'triangle-music',
  'triangle-person-digging',
  'construction',
  'trophy',
  'trophy-star',
  'trophy-alt',
  'truck',
  'truck-bolt',
  'truck-clock',
  'shipping-timed',
  'truck-container',
  'truck-container-empty',
  'truck-fast',
  'shipping-fast',
  'truck-flatbed',
  'truck-front',
  'truck-medical',
  'ambulance',
  'truck-monster',
  'truck-moving',
  'truck-pickup',
  'truck-plow',
  'truck-ramp',
  'truck-ramp-box',
  'truck-loading',
  'truck-ramp-couch',
  'truck-couch',
  'truck-tow',
  'trumpet',
  'tty',
  'teletype',
  'tty-answer',
  'teletype-answer',
  'tugrik-sign',
  'turkey',
  'turkish-lira-sign',
  'try',
  'turkish-lira',
  'turn-down',
  'level-down-alt',
  'turn-down-left',
  'turn-down-right',
  'turn-up',
  'level-up-alt',
  'turntable',
  'turtle',
  'tv',
  'television',
  'tv-alt',
  'tv-music',
  'tv-retro',
  'typewriter',
  'u',
  'ufo',
  'ufo-beam',
  'umbrella',
  'umbrella-beach',
  'umbrella-simple',
  'umbrella-alt',
  'underline',
  'unicorn',
  'uniform-martial-arts',
  'union',
  'universal-access',
  'unlock',
  'unlock-keyhole',
  'unlock-alt',
  'up',
  'arrow-alt-up',
  'up-down',
  'arrows-alt-v',
  'up-down-left-right',
  'arrows-alt',
  'up-from-dotted-line',
  'up-from-line',
  'arrow-alt-from-bottom',
  'up-left',
  'up-long',
  'long-arrow-alt-up',
  'up-right',
  'up-right-and-down-left-from-center',
  'expand-alt',
  'up-right-from-square',
  'external-link-alt',
  'up-to-dotted-line',
  'up-to-line',
  'arrow-alt-to-top',
  'upload',
  'usb-drive',
  'user',
  'user-alien',
  'user-astronaut',
  'user-bounty-hunter',
  'user-check',
  'user-chef',
  'user-clock',
  'user-cowboy',
  'user-crown',
  'user-doctor',
  'user-md',
  'user-doctor-hair',
  'user-doctor-hair-long',
  'user-doctor-message',
  'user-md-chat',
  'user-gear',
  'user-cog',
  'user-graduate',
  'user-group',
  'user-friends',
  'user-group-crown',
  'users-crown',
  'user-hair',
  'user-hair-buns',
  'user-hair-long',
  'user-hair-mullet',
  'business-front',
  'party-back',
  'trian-balbot',
  'user-headset',
  'user-helmet-safety',
  'user-construction',
  'user-hard-hat',
  'user-injured',
  'user-large',
  'user-alt',
  'user-large-slash',
  'user-alt-slash',
  'user-lock',
  'user-minus',
  'user-music',
  'user-ninja',
  'user-nurse',
  'user-nurse-hair',
  'user-nurse-hair-long',
  'user-pen',
  'user-edit',
  'user-pilot',
  'user-pilot-tie',
  'user-plus',
  'user-police',
  'user-police-tie',
  'user-robot',
  'user-robot-xmarks',
  'user-secret',
  'user-shakespeare',
  'user-shield',
  'user-slash',
  'user-tag',
  'user-tie',
  'user-tie-hair',
  'user-tie-hair-long',
  'user-unlock',
  'user-visor',
  'user-vneck',
  'user-vneck-hair',
  'user-vneck-hair-long',
  'user-xmark',
  'user-times',
  'users',
  'users-gear',
  'users-cog',
  'users-medical',
  'users-slash',
  'utensils',
  'cutlery',
  'utensils-slash',
  'utility-pole',
  'utility-pole-double',
  'v',
  'vacuum',
  'vacuum-robot',
  'value-absolute',
  'van-shuttle',
  'shuttle-van',
  'vault',
  'vector-circle',
  'vector-polygon',
  'vector-square',
  'vent-damper',
  'venus',
  'venus-double',
  'venus-mars',
  'vest',
  'vest-patches',
  'vial',
  'vials',
  'video',
  'video-camera',
  'video-arrow-down-left',
  'video-arrow-up-right',
  'video-plus',
  'video-slash',
  'vihara',
  'violin',
  'virus',
  'virus-covid',
  'virus-covid-slash',
  'virus-slash',
  'viruses',
  'voicemail',
  'volcano',
  'volleyball',
  'volleyball-ball',
  'volume',
  'volume-medium',
  'volume-high',
  'volume-up',
  'volume-low',
  'volume-down',
  'volume-off',
  'volume-slash',
  'volume-xmark',
  'volume-mute',
  'volume-times',
  'vr-cardboard',
  'w',
  'waffle',
  'wagon-covered',
  'walker',
  'walkie-talkie',
  'wallet',
  'wand',
  'wand-magic',
  'magic',
  'wand-magic-sparkles',
  'magic-wand-sparkles',
  'wand-sparkles',
  'warehouse',
  'warehouse-full',
  'warehouse-alt',
  'washing-machine',
  'washer',
  'watch',
  'watch-apple',
  'watch-calculator',
  'watch-fitness',
  'watch-smart',
  'water',
  'water-arrow-down',
  'water-lower',
  'water-arrow-up',
  'water-rise',
  'water-ladder',
  'ladder-water',
  'swimming-pool',
  'watermelon-slice',
  'wave-pulse',
  'heart-rate',
  'wave-sine',
  'wave-square',
  'wave-triangle',
  'waveform',
  'waveform-lines',
  'waveform-path',
  'weight-hanging',
  'weight-scale',
  'weight',
  'whale',
  'wheat',
  'wheat-awn',
  'wheat-alt',
  'wheat-awn-slash',
  'wheat-slash',
  'wheelchair',
  'wheelchair-move',
  'wheelchair-alt',
  'whiskey-glass',
  'glass-whiskey',
  'whiskey-glass-ice',
  'glass-whiskey-rocks',
  'whistle',
  'wifi',
  'wifi3',
  'wifi-strong',
  'wifi-exclamation',
  'wifi-fair',
  'wifi2',
  'wifi-slash',
  'wifi-weak',
  'wifi1',
  'wind',
  'wind-turbine',
  'wind-warning',
  'wind-circle-exclamation',
  'window',
  'window-flip',
  'window-alt',
  'window-frame',
  'window-frame-open',
  'window-maximize',
  'window-minimize',
  'window-restore',
  'windsock',
  'wine-bottle',
  'wine-glass',
  'wine-glass-crack',
  'fragile',
  'wine-glass-empty',
  'wine-glass-alt',
  'won-sign',
  'krw',
  'won',
  'wreath',
  'wrench',
  'wrench-simple',
  'x',
  'x-ray',
  'xmark',
  'close',
  'multiply',
  'remove',
  'times',
  'xmark-to-slot',
  'times-to-slot',
  'vote-nay',
  'y',
  'yen-sign',
  'cny',
  'jpy',
  'rmb',
  'yen',
  'yin-yang',
  'z',
  '0',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '00',
  '360-degrees',
  'a',
  'abacus',
  'accent-grave',
  'acorn',
  'address-book',
  'contact-book',
  'address-card',
  'contact-card',
  'vcard',
  'air-conditioner',
  'airplay',
  'alarm-clock',
  'alarm-exclamation',
  'alarm-plus',
  'alarm-snooze',
  'album',
  'album-circle-plus',
  'album-circle-user',
  'album-collection',
  'album-collection-circle-plus',
  'album-collection-circle-user',
  'alicorn',
  'alien',
  'alien-8bit',
  'alien-monster',
  'align-center',
  'align-justify',
  'align-left',
  'align-right',
  'align-slash',
  'alt',
  'amp-guitar',
  'ampersand',
  'anchor',
  'angel',
  'angle',
  'angle-90',
  'angle-down',
  'angle-left',
  'angle-right',
  'angle-up',
  'angles-down',
  'angle-double-down',
  'angles-left',
  'angle-double-left',
  'angles-right',
  'angle-double-right',
  'angles-up',
  'angle-double-up',
  'ankh',
  'apartment',
  'aperture',
  'apostrophe',
  'apple-core',
  'apple-whole',
  'apple-alt',
  'archway',
  'arrow-down',
  'arrow-down-1-9',
  'sort-numeric-asc',
  'sort-numeric-down',
  'arrow-down-9-1',
  'sort-numeric-desc',
  'sort-numeric-down-alt',
  'arrow-down-a-z',
  'sort-alpha-asc',
  'sort-alpha-down',
  'arrow-down-arrow-up',
  'sort-alt',
  'arrow-down-big-small',
  'sort-size-down',
  'arrow-down-from-dotted-line',
  'arrow-down-from-line',
  'arrow-from-top',
  'arrow-down-left',
  'arrow-down-left-and-arrow-up-right-to-center',
  'arrow-down-long',
  'long-arrow-down',
  'arrow-down-right',
  'arrow-down-short-wide',
  'sort-amount-desc',
  'sort-amount-down-alt',
  'arrow-down-small-big',
  'sort-size-down-alt',
  'arrow-down-square-triangle',
  'sort-shapes-down-alt',
  'arrow-down-to-bracket',
  'arrow-down-to-dotted-line',
  'arrow-down-to-line',
  'arrow-to-bottom',
  'arrow-down-to-square',
  'arrow-down-triangle-square',
  'sort-shapes-down',
  'arrow-down-wide-short',
  'sort-amount-asc',
  'sort-amount-down',
  'arrow-down-z-a',
  'sort-alpha-desc',
  'sort-alpha-down-alt',
  'arrow-left',
  'arrow-left-from-line',
  'arrow-from-right',
  'arrow-left-long',
  'long-arrow-left',
  'arrow-left-long-to-line',
  'arrow-left-to-line',
  'arrow-to-left',
  'arrow-pointer',
  'mouse-pointer',
  'arrow-right',
  'arrow-right-arrow-left',
  'exchange',
  'arrow-right-from-bracket',
  'sign-out',
  'arrow-right-from-line',
  'arrow-from-left',
  'arrow-right-long',
  'long-arrow-right',
  'arrow-right-long-to-line',
  'arrow-right-to-bracket',
  'sign-in',
  'arrow-right-to-line',
  'arrow-to-right',
  'arrow-rotate-left',
  'arrow-left-rotate',
  'arrow-rotate-back',
  'arrow-rotate-backward',
  'undo',
  'arrow-rotate-right',
  'arrow-right-rotate',
  'arrow-rotate-forward',
  'redo',
  'arrow-trend-down',
  'arrow-trend-up',
  'arrow-turn-down',
  'level-down',
  'arrow-turn-down-left',
  'arrow-turn-down-right',
  'arrow-turn-up',
  'level-up',
  'arrow-up',
  'arrow-up-1-9',
  'sort-numeric-up',
  'arrow-up-9-1',
  'sort-numeric-up-alt',
  'arrow-up-a-z',
  'sort-alpha-up',
  'arrow-up-arrow-down',
  'sort-up-down',
  'arrow-up-big-small',
  'sort-size-up',
  'arrow-up-from-bracket',
  'arrow-up-from-dotted-line',
  'arrow-up-from-line',
  'arrow-from-bottom',
  'arrow-up-from-square',
  'arrow-up-left',
  'arrow-up-left-from-circle',
  'arrow-up-long',
  'long-arrow-up',
  'arrow-up-right',
  'arrow-up-right-and-arrow-down-left-from-center',
  'arrow-up-right-from-square',
  'external-link',
  'arrow-up-short-wide',
  'sort-amount-up-alt',
  'arrow-up-small-big',
  'sort-size-up-alt',
  'arrow-up-square-triangle',
  'sort-shapes-up-alt',
  'arrow-up-to-dotted-line',
  'arrow-up-to-line',
  'arrow-to-top',
  'arrow-up-triangle-square',
  'sort-shapes-up',
  'arrow-up-wide-short',
  'sort-amount-up',
  'arrow-up-z-a',
  'sort-alpha-up-alt',
  'arrows-cross',
  'arrows-from-dotted-line',
  'arrows-from-line',
  'arrows-left-right',
  'arrows-h',
  'arrows-maximize',
  'expand-arrows',
  'arrows-minimize',
  'compress-arrows',
  'arrows-repeat',
  'repeat-alt',
  'arrows-repeat-1',
  'repeat1-alt',
  'arrows-retweet',
  'retweet-alt',
  'arrows-rotate',
  'refresh',
  'sync',
  'arrows-to-dotted-line',
  'arrows-to-line',
  'arrows-up-down',
  'arrows-v',
  'arrows-up-down-left-right',
  'arrows',
  'asterisk',
  'at',
  'atom',
  'atom-simple',
  'atom-alt',
  'audio-description',
  'audio-description-slash',
  'austral-sign',
  'avocado',
  'award',
  'award-simple',
  'axe',
  'axe-battle',
  'b',
  'baby',
  'baby-carriage',
  'carriage-baby',
  'backpack',
  'backward',
  'backward-fast',
  'fast-backward',
  'backward-step',
  'step-backward',
  'bacon',
  'bacteria',
  'bacterium',
  'badge',
  'badge-check',
  'badge-dollar',
  'badge-percent',
  'badge-sheriff',
  'badger-honey',
  'badminton',
  'bag-shopping',
  'shopping-bag',
  'bagel',
  'bags-shopping',
  'baguette',
  'bahai',
  'baht-sign',
  'ball-pile',
  'balloon',
  'balloons',
  'ballot',
  'ballot-check',
  'ban',
  'cancel',
  'ban-bug',
  'debug',
  'ban-parking',
  'parking-circle-slash',
  'ban-smoking',
  'smoking-ban',
  'banana',
  'bandage',
  'band-aid',
  'bangladeshi-taka-sign',
  'banjo',
  'barcode',
  'barcode-read',
  'barcode-scan',
  'bars',
  'navicon',
  'bars-filter',
  'bars-progress',
  'tasks-alt',
  'bars-sort',
  'bars-staggered',
  'reorder',
  'stream',
  'baseball',
  'baseball-ball',
  'baseball-bat-ball',
  'basket-shopping',
  'shopping-basket',
  'basket-shopping-simple',
  'shopping-basket-alt',
  'basketball',
  'basketball-ball',
  'basketball-hoop',
  'bat',
  'bath',
  'bathtub',
  'battery-bolt',
  'battery-empty',
  'battery0',
  'battery-exclamation',
  'battery-full',
  'battery',
  'battery5',
  'battery-half',
  'battery3',
  'battery-low',
  'battery1',
  'battery-quarter',
  'battery2',
  'battery-slash',
  'battery-three-quarters',
  'battery4',
  'bed',
  'bed-bunk',
  'bed-empty',
  'bed-front',
  'bed-alt',
  'bed-pulse',
  'procedures',
  'bee',
  'beer-mug',
  'beer-foam',
  'beer-mug-empty',
  'beer',
  'bell',
  'bell-concierge',
  'concierge-bell',
  'bell-exclamation',
  'bell-on',
  'bell-plus',
  'bell-school',
  'bell-school-slash',
  'bell-slash',
  'bells',
  'bench-tree',
  'bezier-curve',
  'bicycle',
  'binary',
  'binary-circle-check',
  'binary-lock',
  'binary-slash',
  'binoculars',
  'biohazard',
  'bird',
  'bitcoin-sign',
  'blanket',
  'blanket-fire',
  'blender',
  'blender-phone',
  'blinds',
  'blinds-open',
  'blinds-raised',
  'block',
  'block-brick',
  'wall-brick',
  'block-brick-fire',
  'firewall',
  'block-question',
  'block-quote',
  'blog',
  'blueberries',
  'bluetooth',
  'bold',
  'bolt',
  'zap',
  'bolt-auto',
  'bolt-lightning',
  'bolt-slash',
  'bomb',
  'bone',
  'bone-break',
  'bong',
  'book',
  'book-arrow-right',
  'book-arrow-up',
  'book-atlas',
  'atlas',
  'book-bible',
  'bible',
  'book-blank',
  'book-alt',
  'book-bookmark',
  'book-circle-arrow-right',
  'book-circle-arrow-up',
  'book-copy',
  'book-font',
  'book-heart',
  'book-journal-whills',
  'journal-whills',
  'book-medical',
  'book-open',
  'book-open-cover',
  'book-open-alt',
  'book-open-reader',
  'book-reader',
  'book-quran',
  'quran',
  'book-section',
  'book-law',
  'book-skull',
  'book-dead',
  'book-sparkles',
  'book-spells',
  'book-tanakh',
  'tanakh',
  'book-user',
  'bookmark',
  'bookmark-slash',
  'books',
  'books-medical',
  'boombox',
  'boot',
  'boot-heeled',
  'booth-curtain',
  'border-all',
  'border-bottom',
  'border-bottom-right',
  'border-style-alt',
  'border-center-h',
  'border-center-v',
  'border-inner',
  'border-left',
  'border-none',
  'border-outer',
  'border-right',
  'border-top',
  'border-top-left',
  'border-style',
  'bow-arrow',
  'bowl-chopsticks',
  'bowl-chopsticks-noodles',
  'bowl-hot',
  'soup',
  'bowl-rice',
  'bowl-scoop',
  'bowl-shaved-ice',
  'bowl-scoops',
  'bowl-soft-serve',
  'bowl-spoon',
  'bowling-ball',
  'bowling-ball-pin',
  'bowling-pins',
  'box',
  'box-archive',
  'archive',
  'box-ballot',
  'box-check',
  'box-circle-check',
  'box-dollar',
  'box-usd',
  'box-heart',
  'box-open',
  'box-open-full',
  'box-full',
  'box-taped',
  'box-alt',
  'box-tissue',
  'boxes-stacked',
  'boxes',
  'boxes-alt',
  'boxing-glove',
  'glove-boxing',
  'bracket-curly',
  'bracket-curly-left',
  'bracket-curly-right',
  'bracket-round',
  'parenthesis',
  'bracket-round-right',
  'bracket-square',
  'bracket',
  'bracket-left',
  'bracket-square-right',
  'brackets-curly',
  'brackets-round',
  'parentheses',
  'brackets-square',
  'brackets',
  'braille',
  'brain',
  'brain-arrow-curved-right',
  'mind-share',
  'brain-circuit',
  'brake-warning',
  'brazilian-real-sign',
  'bread-loaf',
  'bread-slice',
  'bread-slice-butter',
  'briefcase',
  'briefcase-arrow-right',
  'briefcase-blank',
  'briefcase-medical',
  'brightness',
  'brightness-low',
  'bring-forward',
  'bring-front',
  'broccoli',
  'broom',
  'broom-ball',
  'quidditch',
  'quidditch-broom-ball',
  'browser',
  'browsers',
  'brush',
  'bug',
  'bug-slash',
  'building',
  'building-columns',
  'bank',
  'institution',
  'museum',
  'university',
  'buildings',
  'bullhorn',
  'bullseye',
  'bullseye-arrow',
  'bullseye-pointer',
  'burger',
  'hamburger',
  'burger-cheese',
  'cheeseburger',
  'burger-fries',
  'burger-glass',
  'burger-lettuce',
  'burger-soda',
  'burrito',
  'bus',
  'bus-school',
  'bus-simple',
  'bus-alt',
  'business-time',
  'briefcase-clock',
  'butter',
  'c',
  'cabin',
  'cabinet-filing',
  'cable-car',
  'cactus',
  'cake-candles',
  'birthday-cake',
  'cake',
  'cake-slice',
  'shortcake',
  'calculator',
  'calculator-simple',
  'calculator-alt',
  'calendar',
  'calendar-arrow-down',
  'calendar-download',
  'calendar-arrow-up',
  'calendar-upload',
  'calendar-check',
  'calendar-circle-exclamation',
  'calendar-circle-minus',
  'calendar-circle-plus',
  'calendar-circle-user',
  'calendar-clock',
  'calendar-time',
  'calendar-day',
  'calendar-days',
  'calendar-alt',
  'calendar-exclamation',
  'calendar-heart',
  'calendar-image',
  'calendar-lines',
  'calendar-note',
  'calendar-lines-pen',
  'calendar-minus',
  'calendar-pen',
  'calendar-edit',
  'calendar-plus',
  'calendar-range',
  'calendar-star',
  'calendar-week',
  'calendar-xmark',
  'calendar-times',
  'calendars',
  'camcorder',
  'video-handheld',
  'camera',
  'camera-alt',
  'camera-cctv',
  'cctv',
  'camera-movie',
  'camera-polaroid',
  'camera-retro',
  'camera-rotate',
  'camera-security',
  'camera-home',
  'camera-slash',
  'camera-viewfinder',
  'screenshot',
  'camera-web',
  'webcam',
  'camera-web-slash',
  'webcam-slash',
  'campfire',
  'campground',
  'can-food',
  'candle-holder',
  'candy',
  'candy-bar',
  'chocolate-bar',
  'candy-cane',
  'candy-corn',
  'cannabis',
  'capsules',
  'car',
  'automobile',
  'car-battery',
  'battery-car',
  'car-bolt',
  'car-building',
  'car-bump',
  'car-bus',
  'car-circle-bolt',
  'car-crash',
  'car-garage',
  'car-mirrors',
  'car-rear',
  'car-alt',
  'car-side',
  'car-side-bolt',
  'car-tilt',
  'car-wash',
  'car-wrench',
  'car-mechanic',
  'caravan',
  'caravan-simple',
  'caravan-alt',
  'card-club',
  'card-diamond',
  'card-heart',
  'card-spade',
  'cards',
  'caret-down',
  'caret-left',
  'caret-right',
  'caret-up',
  'carrot',
  'cars',
  'cart-arrow-down',
  'cart-arrow-up',
  'cart-circle-arrow-down',
  'cart-circle-arrow-up',
  'cart-circle-check',
  'cart-circle-exclamation',
  'cart-circle-plus',
  'cart-circle-xmark',
  'cart-flatbed',
  'dolly-flatbed',
  'cart-flatbed-boxes',
  'dolly-flatbed-alt',
  'cart-flatbed-empty',
  'dolly-flatbed-empty',
  'cart-flatbed-suitcase',
  'luggage-cart',
  'cart-minus',
  'cart-plus',
  'cart-shopping',
  'shopping-cart',
  'cart-shopping-fast',
  'cart-xmark',
  'cash-register',
  'cassette-betamax',
  'betamax',
  'cassette-tape',
  'cassette-vhs',
  'vhs',
  'castle',
  'cat',
  'cat-space',
  'cauldron',
  'cedi-sign',
  'cent-sign',
  'certificate',
  'chair',
  'chair-office',
  'chalkboard',
  'blackboard',
  'chalkboard-user',
  'chalkboard-teacher',
  'champagne-glass',
  'glass-champagne',
  'champagne-glasses',
  'glass-cheers',
  'charging-station',
  'chart-area',
  'area-chart',
  'chart-bar',
  'bar-chart',
  'chart-bullet',
  'chart-candlestick',
  'chart-column',
  'chart-gantt',
  'chart-line',
  'line-chart',
  'chart-line-down',
  'chart-line-up',
  'chart-mixed',
  'analytics',
  'chart-network',
  'chart-pie',
  'pie-chart',
  'chart-pie-simple',
  'chart-pie-alt',
  'chart-pyramid',
  'chart-radar',
  'chart-scatter',
  'chart-scatter-3d',
  'chart-scatter-bubble',
  'chart-simple',
  'chart-simple-horizontal',
  'chart-tree-map',
  'chart-user',
  'user-chart',
  'chart-waterfall',
  'check',
  'check-double',
  'check-to-slot',
  'vote-yea',
  'cheese',
  'cheese-swiss',
  'cherries',
  'chess',
  'chess-bishop',
  'chess-bishop-piece',
  'chess-bishop-alt',
  'chess-board',
  'chess-clock',
  'chess-clock-flip',
  'chess-clock-alt',
  'chess-king',
  'chess-king-piece',
  'chess-king-alt',
  'chess-knight',
  'chess-knight-piece',
  'chess-knight-alt',
  'chess-pawn',
  'chess-pawn-piece',
  'chess-pawn-alt',
  'chess-queen',
  'chess-queen-piece',
  'chess-queen-alt',
  'chess-rook',
  'chess-rook-piece',
  'chess-rook-alt',
  'chestnut',
  'chevron-down',
  'chevron-left',
  'chevron-right',
  'chevron-up',
  'chevrons-down',
  'chevron-double-down',
  'chevrons-left',
  'chevron-double-left',
  'chevrons-right',
  'chevron-double-right',
  'chevrons-up',
  'chevron-double-up',
  'child',
  'chimney',
  'chopsticks',
  'church',
  'circle',
  'circle-0',
  'circle-1',
  'circle-2',
  'circle-3',
  'circle-4',
  'circle-5',
  'circle-6',
  'circle-7',
  'circle-8',
  'circle-9',
  'circle-a',
  'circle-ampersand',
  'circle-arrow-down',
  'arrow-circle-down',
  'circle-arrow-down-left',
  'circle-arrow-down-right',
  'circle-arrow-left',
  'arrow-circle-left',
  'circle-arrow-right',
  'arrow-circle-right',
  'circle-arrow-up',
  'arrow-circle-up',
  'circle-arrow-up-left',
  'circle-arrow-up-right',
  'circle-b',
  'circle-bolt',
  'circle-book-open',
  'book-circle',
  'circle-bookmark',
  'bookmark-circle',
  'circle-c',
  'circle-calendar',
  'calendar-circle',
  'circle-camera',
  'camera-circle',
  'circle-caret-down',
  'caret-circle-down',
  'circle-caret-left',
  'caret-circle-left',
  'circle-caret-right',
  'caret-circle-right',
  'circle-caret-up',
  'caret-circle-up',
  'circle-check',
  'check-circle',
  'circle-chevron-down',
  'chevron-circle-down',
  'circle-chevron-left',
  'chevron-circle-left',
  'circle-chevron-right',
  'chevron-circle-right',
  'circle-chevron-up',
  'chevron-circle-up',
  'circle-d',
  'circle-dashed',
  'circle-divide',
  'circle-dollar',
  'dollar-circle',
  'usd-circle',
  'circle-dollar-to-slot',
  'donate',
  'circle-dot',
  'dot-circle',
  'circle-down',
  'arrow-alt-circle-down',
  'circle-down-left',
  'circle-down-right',
  'circle-e',
  'circle-ellipsis',
  'circle-ellipsis-vertical',
  'circle-envelope',
  'envelope-circle',
  'circle-exclamation',
  'exclamation-circle',
  'circle-exclamation-check',
  'circle-f',
  'circle-g',
  'circle-h',
  'hospital-symbol',
  'circle-half',
  'circle-half-stroke',
  'adjust',
  'circle-heart',
  'heart-circle',
  'circle-i',
  'circle-info',
  'info-circle',
  'circle-j',
  'circle-k',
  'circle-l',
  'circle-left',
  'arrow-alt-circle-left',
  'circle-location-arrow',
  'location-circle',
  'circle-m',
  'circle-microphone',
  'microphone-circle',
  'circle-microphone-lines',
  'microphone-circle-alt',
  'circle-minus',
  'minus-circle',
  'circle-n',
  'circle-notch',
  'circle-o',
  'circle-p',
  'circle-parking',
  'parking-circle',
  'circle-pause',
  'pause-circle',
  'circle-phone',
  'phone-circle',
  'circle-phone-flip',
  'phone-circle-alt',
  'circle-phone-hangup',
  'phone-circle-down',
  'circle-play',
  'play-circle',
  'circle-plus',
  'plus-circle',
  'circle-q',
  'circle-quarter',
  'circle-quarters',
  'circle-question',
  'question-circle',
  'circle-r',
  'circle-radiation',
  'radiation-alt',
  'circle-right',
  'arrow-alt-circle-right',
  'circle-s',
  'circle-small',
  'circle-sort',
  'sort-circle',
  'circle-sort-down',
  'sort-circle-down',
  'circle-sort-up',
  'sort-circle-up',
  'circle-star',
  'star-circle',
  'circle-stop',
  'stop-circle',
  'circle-t',
  'circle-three-quarters',
  'circle-trash',
  'trash-circle',
  'circle-u',
  'circle-up',
  'arrow-alt-circle-up',
  'circle-up-left',
  'circle-up-right',
  'circle-user',
  'user-circle',
  'circle-v',
  'circle-video',
  'video-circle',
  'circle-w',
  'circle-waveform-lines',
  'waveform-circle',
  'circle-x',
  'circle-xmark',
  'times-circle',
  'xmark-circle',
  'circle-y',
  'circle-z',
  'citrus',
  'citrus-slice',
  'city',
  'clapperboard',
  'clapperboard-play',
  'clarinet',
  'claw-marks',
  'clipboard',
  'clipboard-check',
  'clipboard-list',
  'clipboard-list-check',
  'clipboard-medical',
  'clipboard-prescription',
  'clipboard-user',
  'clock',
  'clock-four',
  'clock-desk',
  'clock-eight',
  'clock-eight-thirty',
  'clock-eleven',
  'clock-eleven-thirty',
  'clock-five',
  'clock-five-thirty',
  'clock-four-thirty',
  'clock-nine',
  'clock-nine-thirty',
  'clock-one',
  'clock-one-thirty',
  'clock-rotate-left',
  'history',
  'clock-seven',
  'clock-seven-thirty',
  'clock-six',
  'clock-six-thirty',
  'clock-ten',
  'clock-ten-thirty',
  'clock-three',
  'clock-three-thirty',
  'clock-twelve',
  'clock-twelve-thirty',
  'clock-two',
  'clock-two-thirty',
  'clone',
  'closed-captioning',
  'closed-captioning-slash',
  'clothes-hanger',
  'cloud',
  'cloud-arrow-down',
  'cloud-download',
  'cloud-download-alt',
  'cloud-arrow-up',
  'cloud-upload',
  'cloud-upload-alt',
  'cloud-bolt',
  'thunderstorm',
  'cloud-bolt-moon',
  'thunderstorm-moon',
  'cloud-bolt-sun',
  'thunderstorm-sun',
  'cloud-check',
  'cloud-drizzle',
  'cloud-exclamation',
  'cloud-fog',
  'fog',
  'cloud-hail',
  'cloud-hail-mixed',
  'cloud-meatball',
  'cloud-minus',
  'cloud-moon',
  'cloud-moon-rain',
  'cloud-music',
  'cloud-plus',
  'cloud-question',
  'cloud-rain',
  'cloud-rainbow',
  'cloud-showers',
  'cloud-showers-heavy',
  'cloud-slash',
  'cloud-sleet',
  'cloud-snow',
  'cloud-sun',
  'cloud-sun-rain',
  'cloud-word',
  'cloud-xmark',
  'clouds',
  'clouds-moon',
  'clouds-sun',
  'clover',
  'club',
  'coconut',
  'code',
  'code-branch',
  'code-commit',
  'code-compare',
  'code-fork',
  'code-merge',
  'code-pull-request',
  'code-pull-request-closed',
  'code-pull-request-draft',
  'code-simple',
  'coffee-bean',
  'coffee-beans',
  'coffee-pot',
  'coffin',
  'coffin-cross',
  'coin',
  'coin-blank',
  'coin-front',
  'coin-vertical',
  'coins',
  'colon',
  'colon-sign',
  'columns-3',
  'comet',
  'comma',
  'command',
  'comment',
  'comment-arrow-down',
  'comment-arrow-up',
  'comment-arrow-up-right',
  'comment-captions',
  'comment-check',
  'comment-code',
  'comment-dollar',
  'comment-dots',
  'commenting',
  'comment-exclamation',
  'comment-image',
  'comment-lines',
  'comment-medical',
  'comment-middle',
  'comment-middle-top',
  'comment-minus',
  'comment-music',
  'comment-pen',
  'comment-edit',
  'comment-plus',
  'comment-question',
  'comment-quote',
  'comment-slash',
  'comment-smile',
  'comment-sms',
  'sms',
  'comment-text',
  'comment-xmark',
  'comment-times',
  'comments',
  'comments-dollar',
  'comments-question',
  'comments-question-check',
  'compact-disc',
  'compass',
  'compass-drafting',
  'drafting-compass',
  'compass-slash',
  'compress',
  'compress-wide',
  'computer-classic',
  'computer-mouse',
  'mouse',
  'computer-mouse-scrollwheel',
  'mouse-alt',
  'computer-speaker',
  'container-storage',
  'conveyor-belt',
  'conveyor-belt-boxes',
  'conveyor-belt-alt',
  'conveyor-belt-empty',
  'cookie',
  'cookie-bite',
  'copy',
  'copyright',
  'corn',
  'corner',
  'couch',
  'cow',
  'cowbell',
  'cowbell-circle-plus',
  'cowbell-more',
  'crab',
  'crate-apple',
  'apple-crate',
  'crate-empty',
  'credit-card',
  'credit-card-alt',
  'credit-card-blank',
  'credit-card-front',
  'cricket-bat-ball',
  'cricket',
  'croissant',
  'crop',
  'crop-simple',
  'crop-alt',
  'cross',
  'crosshairs',
  'crow',
  'crown',
  'crutch',
  'crutches',
  'cruzeiro-sign',
  'crystal-ball',
  'cube',
  'cubes',
  'cucumber',
  'cup-straw',
  'cup-straw-swoosh',
  'cup-togo',
  'coffee-togo',
  'cupcake',
  'curling-stone',
  'curling',
  'custard',
  'd',
  'dagger',
  'dash',
  'database',
  'deer',
  'deer-rudolph',
  'delete-left',
  'backspace',
  'delete-right',
  'democrat',
  'desktop',
  'desktop-alt',
  'desktop-arrow-down',
  'dharmachakra',
  'diagram-cells',
  'diagram-lean-canvas',
  'diagram-nested',
  'diagram-next',
  'diagram-predecessor',
  'diagram-previous',
  'diagram-project',
  'project-diagram',
  'diagram-sankey',
  'diagram-subtask',
  'diagram-successor',
  'diagram-venn',
  'dial',
  'dial-med-high',
  'dial-high',
  'dial-low',
  'dial-max',
  'dial-med',
  'dial-med-low',
  'dial-min',
  'dial-off',
  'diamond',
  'diamond-exclamation',
  'diamond-turn-right',
  'directions',
  'dice',
  'dice-d10',
  'dice-d12',
  'dice-d20',
  'dice-d4',
  'dice-d6',
  'dice-d8',
  'dice-five',
  'dice-four',
  'dice-one',
  'dice-six',
  'dice-three',
  'dice-two',
  'diploma',
  'scroll-ribbon',
  'disc-drive',
  'disease',
  'display',
  'display-arrow-down',
  'display-code',
  'desktop-code',
  'display-medical',
  'desktop-medical',
  'display-slash',
  'desktop-slash',
  'distribute-spacing-horizontal',
  'distribute-spacing-vertical',
  'ditto',
  'divide',
  'dna',
  'do-not-enter',
  'dog',
  'dog-leashed',
  'dollar-sign',
  'dollar',
  'usd',
  'dolly',
  'dolly-box',
  'dolly-empty',
  'dolphin',
  'dong-sign',
  'donut',
  'doughnut',
  'door-closed',
  'door-open',
  'dove',
  'down',
  'arrow-alt-down',
  'down-from-dotted-line',
  'down-from-line',
  'arrow-alt-from-top',
  'down-left',
  'down-left-and-up-right-to-center',
  'compress-alt',
  'down-long',
  'long-arrow-alt-down',
  'down-right',
  'down-to-dotted-line',
  'down-to-line',
  'arrow-alt-to-bottom',
  'download',
  'dragon',
  'draw-circle',
  'draw-polygon',
  'draw-square',
  'dreidel',
  'drone',
  'drone-front',
  'drone-alt',
  'droplet',
  'tint',
  'droplet-degree',
  'dewpoint',
  'droplet-percent',
  'humidity',
  'droplet-slash',
  'tint-slash',
  'drum',
  'drum-steelpan',
  'drumstick',
  'drumstick-bite',
  'dryer',
  'dryer-heat',
  'dryer-alt',
  'duck',
  'dumbbell',
  'dumpster',
  'dumpster-fire',
  'dungeon',
  'e',
  'ear',
  'ear-deaf',
  'deaf',
  'deafness',
  'hard-of-hearing',
  'ear-listen',
  'assistive-listening-systems',
  'ear-muffs',
  'earth-africa',
  'globe-africa',
  'earth-americas',
  'earth',
  'earth-america',
  'globe-americas',
  'earth-asia',
  'globe-asia',
  'earth-europe',
  'globe-europe',
  'earth-oceania',
  'globe-oceania',
  'eclipse',
  'egg',
  'egg-fried',
  'eggplant',
  'eject',
  'elephant',
  'elevator',
  'ellipsis',
  'ellipsis-h',
  'ellipsis-stroke',
  'ellipsis-h-alt',
  'ellipsis-stroke-vertical',
  'ellipsis-v-alt',
  'ellipsis-vertical',
  'ellipsis-v',
  'empty-set',
  'engine',
  'engine-warning',
  'engine-exclamation',
  'envelope',
  'envelope-dot',
  'envelope-badge',
  'envelope-open',
  'envelope-open-dollar',
  'envelope-open-text',
  'envelopes',
  'envelopes-bulk',
  'mail-bulk',
  'equals',
  'eraser',
  'escalator',
  'ethernet',
  'euro-sign',
  'eur',
  'euro',
  'exclamation',
  'expand',
  'expand-wide',
  'eye',
  'eye-dropper',
  'eye-dropper-empty',
  'eyedropper',
  'eye-dropper-full',
  'eye-dropper-half',
  'eye-evil',
  'eye-low-vision',
  'low-vision',
  'eye-slash',
  'eyes',
  'f',
  'face-angry',
  'angry',
  'face-angry-horns',
  'face-anguished',
  'face-anxious-sweat',
  'face-astonished',
  'face-awesome',
  'gave-dandy',
  'face-beam-hand-over-mouth',
  'face-clouds',
  'face-confounded',
  'face-confused',
  'face-cowboy-hat',
  'face-diagonal-mouth',
  'face-disappointed',
  'face-disguise',
  'face-dizzy',
  'dizzy',
  'face-dotted',
  'face-downcast-sweat',
  'face-drooling',
  'face-exhaling',
  'face-explode',
  'exploding-head',
  'face-expressionless',
  'face-eyes-xmarks',
  'face-fearful',
  'face-flushed',
  'flushed',
  'face-frown',
  'frown',
  'face-frown-open',
  'frown-open',
  'face-frown-slight',
  'face-glasses',
  'face-grimace',
  'grimace',
  'face-grin',
  'grin',
  'face-grin-beam',
  'grin-beam',
  'face-grin-beam-sweat',
  'grin-beam-sweat',
  'face-grin-hearts',
  'grin-hearts',
  'face-grin-squint',
  'grin-squint',
  'face-grin-squint-tears',
  'grin-squint-tears',
  'face-grin-stars',
  'grin-stars',
  'face-grin-tears',
  'grin-tears',
  'face-grin-tongue',
  'grin-tongue',
  'face-grin-tongue-squint',
  'grin-tongue-squint',
  'face-grin-tongue-wink',
  'grin-tongue-wink',
  'face-grin-wide',
  'grin-alt',
  'face-grin-wink',
  'grin-wink',
  'face-hand-over-mouth',
  'face-hand-peeking',
  'face-hand-yawn',
  'face-head-bandage',
  'face-holding-back-tears',
  'face-hushed',
  'face-icicles',
  'face-kiss',
  'kiss',
  'face-kiss-beam',
  'kiss-beam',
  'face-kiss-closed-eyes',
  'face-kiss-wink-heart',
  'kiss-wink-heart',
  'face-laugh',
  'laugh',
  'face-laugh-beam',
  'laugh-beam',
  'face-laugh-squint',
  'laugh-squint',
  'face-laugh-wink',
  'laugh-wink',
  'face-lying',
  'face-mask',
  'face-meh',
  'meh',
  'face-meh-blank',
  'meh-blank',
  'face-melting',
  'face-monocle',
  'face-nauseated',
  'face-nose-steam',
  'face-party',
  'face-pensive',
  'face-persevering',
  'face-pleading',
  'face-pouting',
  'face-raised-eyebrow',
  'face-relieved',
  'face-rolling-eyes',
  'meh-rolling-eyes',
  'face-sad-cry',
  'sad-cry',
  'face-sad-sweat',
  'face-sad-tear',
  'sad-tear',
  'face-saluting',
  'face-scream',
  'face-shush',
  'face-sleeping',
  'face-sleepy',
  'face-smile',
  'smile',
  'face-smile-beam',
  'smile-beam',
  'face-smile-halo',
  'face-smile-hearts',
  'face-smile-horns',
  'face-smile-plus',
  'smile-plus',
  'face-smile-relaxed',
  'face-smile-tear',
  'face-smile-tongue',
  'face-smile-upside-down',
  'face-smile-wink',
  'smile-wink',
  'face-smiling-hands',
  'face-smirking',
  'face-spiral-eyes',
  'face-sunglasses',
  'face-surprise',
  'surprise',
  'face-swear',
  'face-thermometer',
  'face-thinking',
  'face-tired',
  'tired',
  'face-tissue',
  'face-tongue-money',
  'face-tongue-sweat',
  'face-unamused',
  'face-viewfinder',
  'face-vomit',
  'face-weary',
  'face-woozy',
  'face-worried',
  'face-zany',
  'face-zipper',
  'falafel',
  'family',
  'family-dress',
  'family-pants',
  'fan',
  'fan-table',
  'farm',
  'barn-silo',
  'faucet',
  'faucet-drip',
  'fax',
  'feather',
  'feather-pointed',
  'feather-alt',
  'fence',
  'ferris-wheel',
  'field-hockey-stick-ball',
  'field-hockey',
  'file',
  'file-arrow-down',
  'file-download',
  'file-arrow-up',
  'file-upload',
  'file-audio',
  'file-binary',
  'file-certificate',
  'file-award',
  'file-chart-column',
  'file-chart-line',
  'file-chart-pie',
  'file-check',
  'file-circle-info',
  'file-circle-plus',
  'file-code',
  'file-contract',
  'file-csv',
  'file-dashed-line',
  'page-break',
  'file-excel',
  'file-exclamation',
  'file-export',
  'arrow-right-from-file',
  'file-heart',
  'file-image',
  'file-import',
  'arrow-right-to-file',
  'file-invoice',
  'file-invoice-dollar',
  'file-lines',
  'file-alt',
  'file-text',
  'file-lock',
  'file-magnifying-glass',
  'file-search',
  'file-medical',
  'file-minus',
  'file-music',
  'file-pdf',
  'file-pen',
  'file-edit',
  'file-plus',
  'file-plus-minus',
  'file-powerpoint',
  'file-prescription',
  'file-signature',
  'file-slash',
  'file-spreadsheet',
  'file-user',
  'file-video',
  'file-waveform',
  'file-medical-alt',
  'file-word',
  'file-xmark',
  'file-times',
  'file-zipper',
  'file-archive',
  'files',
  'files-medical',
  'fill',
  'fill-drip',
  'film',
  'film-canister',
  'film-simple',
  'film-alt',
  'film-slash',
  'films',
  'filter',
  'filter-circle-dollar',
  'funnel-dollar',
  'filter-circle-xmark',
  'filter-list',
  'filter-slash',
  'filters',
  'fingerprint',
  'fire',
  'fire-extinguisher',
  'fire-flame',
  'flame',
  'fire-flame-curved',
  'fire-alt',
  'fire-flame-simple',
  'burn',
  'fire-hydrant',
  'fire-smoke',
  'fireplace',
  'fish',
  'fish-bones',
  'fish-cooked',
  'fishing-rod',
  'flag',
  'flag-checkered',
  'flag-pennant',
  'pennant',
  'flag-swallowtail',
  'flag-alt',
  'flag-usa',
  'flashlight',
  'flask',
  'flask-round-poison',
  'flask-poison',
  'flask-round-potion',
  'flask-potion',
  'flatbread',
  'flatbread-stuffed',
  'floppy-disk',
  'save',
  'floppy-disk-circle-arrow-right',
  'save-circle-arrow-right',
  'floppy-disk-circle-xmark',
  'floppy-disk-times',
  'save-circle-xmark',
  'save-times',
  'floppy-disk-pen',
  'floppy-disks',
  'florin-sign',
  'flower',
  'flower-daffodil',
  'flower-tulip',
  'flute',
  'flux-capacitor',
  'flying-disc',
  'folder',
  'folder-arrow-down',
  'folder-download',
  'folder-arrow-up',
  'folder-upload',
  'folder-blank',
  'folder-bookmark',
  'folder-gear',
  'folder-cog',
  'folder-grid',
  'folder-heart',
  'folder-image',
  'folder-magnifying-glass',
  'folder-search',
  'folder-medical',
  'folder-minus',
  'folder-music',
  'folder-open',
  'folder-plus',
  'folder-tree',
  'folder-user',
  'folder-xmark',
  'folder-times',
  'folders',
  'fondue-pot',
  'font',
  'font-awesome',
  'font-awesome-flag',
  'font-awesome-logo-full',
  'font-case',
  'football',
  'football-ball',
  'football-helmet',
  'fork',
  'utensil-fork',
  'fork-knife',
  'utensils-alt',
  'forklift',
  'fort',
  'forward',
  'forward-fast',
  'fast-forward',
  'forward-step',
  'step-forward',
  'frame',
  'franc-sign',
  'french-fries',
  'frog',
  'function',
  'futbol',
  'futbol-ball',
  'soccer-ball',
  'g',
  'galaxy',
  'gallery-thumbnails',
  'game-board',
  'game-board-simple',
  'game-board-alt',
  'game-console-handheld',
  'gamepad',
  'gamepad-modern',
  'gamepad-alt',
  'garage',
  'garage-car',
  'garage-open',
  'garlic',
  'gas-pump',
  'gas-pump-slash',
  'gauge',
  'dashboard',
  'gauge-med',
  'tachometer-alt-average',
  'gauge-circle-bolt',
  'gauge-circle-minus',
  'gauge-circle-plus',
  'gauge-high',
  'tachometer-alt',
  'tachometer-alt-fast',
  'gauge-low',
  'tachometer-alt-slow',
  'gauge-max',
  'tachometer-alt-fastest',
  'gauge-min',
  'tachometer-alt-slowest',
  'gauge-simple',
  'gauge-simple-med',
  'tachometer-average',
  'gauge-simple-high',
  'tachometer',
  'tachometer-fast',
  'gauge-simple-low',
  'tachometer-slow',
  'gauge-simple-max',
  'tachometer-fastest',
  'gauge-simple-min',
  'tachometer-slowest',
  'gavel',
  'legal',
  'gear',
  'cog',
  'gears',
  'cogs',
  'gem',
  'genderless',
  'ghost',
  'gif',
  'gift',
  'gift-card',
  'gifts',
  'gingerbread-man',
  'glass',
  'glass-citrus',
  'glass-empty',
  'glass-half',
  'glass-half-empty',
  'glass-half-full',
  'glasses',
  'glasses-round',
  'glasses-alt',
  'globe',
  'globe-snow',
  'globe-stand',
  'goal-net',
  'golf-ball-tee',
  'golf-ball',
  'golf-club',
  'golf-flag-hole',
  'gopuram',
  'graduation-cap',
  'mortar-board',
  'gramophone',
  'grapes',
  'grate',
  'grate-droplet',
  'greater-than',
  'greater-than-equal',
  'grid',
  'grid3',
  'grid-2',
  'grid-2-plus',
  'grid-4',
  'grid-5',
  'grid-dividers',
  'grid-horizontal',
  'grip',
  'grip-horizontal',
  'grip-dots',
  'grip-dots-vertical',
  'grip-lines',
  'grip-lines-vertical',
  'grip-vertical',
  'guarani-sign',
  'guitar',
  'guitar-electric',
  'guitars',
  'gun',
  'gun-slash',
  'gun-squirt',
  'h',
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
  'hammer',
  'hammer-crash',
  'hammer-war',
  'hamsa',
  'hand',
  'hand-paper',
  'hand-back-fist',
  'hand-rock',
  'hand-back-point-down',
  'hand-back-point-left',
  'hand-back-point-ribbon',
  'hand-back-point-right',
  'hand-back-point-up',
  'hand-dots',
  'allergies',
  'hand-fingers-crossed',
  'hand-fist',
  'fist-raised',
  'hand-heart',
  'hand-holding',
  'hand-holding-box',
  'hand-holding-dollar',
  'hand-holding-usd',
  'hand-holding-droplet',
  'hand-holding-water',
  'hand-holding-heart',
  'hand-holding-magic',
  'hand-holding-medical',
  'hand-holding-seedling',
  'hand-holding-skull',
  'hand-horns',
  'hand-lizard',
  'hand-love',
  'hand-middle-finger',
  'hand-peace',
  'hand-point-down',
  'hand-point-left',
  'hand-point-ribbon',
  'hand-point-right',
  'hand-point-up',
  'hand-pointer',
  'hand-scissors',
  'hand-sparkles',
  'hand-spock',
  'hand-wave',
  'hands',
  'sign-language',
  'signing',
  'hands-asl-interpreting',
  'american-sign-language-interpreting',
  'asl-interpreting',
  'hands-american-sign-language-interpreting',
  'hands-bubbles',
  'hands-wash',
  'hands-clapping',
  'hands-holding',
  'hands-holding-diamond',
  'hand-receiving',
  'hands-holding-dollar',
  'hands-usd',
  'hands-holding-heart',
  'hands-heart',
  'hands-praying',
  'praying-hands',
  'handshake',
  'handshake-angle',
  'hands-helping',
  'handshake-simple',
  'handshake-alt',
  'handshake-simple-slash',
  'handshake-alt-slash',
  'handshake-slash',
  'hanukiah',
  'hard-drive',
  'hdd',
  'hashtag',
  'hashtag-lock',
  'hat-chef',
  'hat-cowboy',
  'hat-cowboy-side',
  'hat-santa',
  'hat-winter',
  'hat-witch',
  'hat-wizard',
  'head-side',
  'head-side-brain',
  'head-side-cough',
  'head-side-cough-slash',
  'head-side-goggles',
  'head-vr',
  'head-side-headphones',
  'head-side-heart',
  'head-side-mask',
  'head-side-medical',
  'head-side-virus',
  'heading',
  'header',
  'headphones',
  'headphones-simple',
  'headphones-alt',
  'headset',
  'heart',
  'heart-crack',
  'heart-broken',
  'heart-half',
  'heart-half-stroke',
  'heart-half-alt',
  'heart-pulse',
  'heartbeat',
  'heat',
  'helicopter',
  'helmet-battle',
  'helmet-safety',
  'hard-hat',
  'hat-hard',
  'hexagon',
  'hexagon-check',
  'hexagon-divide',
  'hexagon-exclamation',
  'hexagon-minus',
  'minus-hexagon',
  'hexagon-plus',
  'plus-hexagon',
  'hexagon-xmark',
  'times-hexagon',
  'xmark-hexagon',
  'high-definition',
  'rectangle-hd',
  'highlighter',
  'highlighter-line',
  'hippo',
  'hockey-mask',
  'hockey-puck',
  'hockey-stick-puck',
  'hockey-sticks',
  'holly-berry',
  'honey-pot',
  'hood-cloak',
  'horizontal-rule',
  'horse',
  'horse-head',
  'horse-saddle',
  'hose',
  'hose-reel',
  'hospital',
  'hospital-alt',
  'hospital-wide',
  'hospital-user',
  'hospitals',
  'hot-tub-person',
  'hot-tub',
  'hotdog',
  'hotel',
  'hourglass',
  'hourglass2',
  'hourglass-half',
  'hourglass-clock',
  'hourglass-empty',
  'hourglass-end',
  'hourglass3',
  'hourglass-start',
  'hourglass1',
  'house',
  'home',
  'home-alt',
  'home-lg-alt',
  'house-blank',
  'home-blank',
  'house-building',
  'house-chimney',
  'home-lg',
  'house-chimney-blank',
  'house-chimney-crack',
  'house-damage',
  'house-chimney-heart',
  'house-chimney-medical',
  'clinic-medical',
  'house-chimney-user',
  'house-chimney-window',
  'house-crack',
  'house-day',
  'house-flood',
  'house-heart',
  'home-heart',
  'house-laptop',
  'laptop-house',
  'house-medical',
  'house-night',
  'house-person-leave',
  'house-leave',
  'house-person-depart',
  'house-person-return',
  'house-person-arrive',
  'house-return',
  'house-signal',
  'house-tree',
  'house-turret',
  'house-user',
  'home-user',
  'house-window',
  'hryvnia-sign',
  'hryvnia',
  'hundred-points',
  '100',
  'hurricane',
  'hyphen',
  'i',
  'i-cursor',
  'ice-cream',
  'ice-skate',
  'icicles',
  'icons',
  'heart-music-camera-bolt',
  'id-badge',
  'id-card',
  'drivers-license',
  'id-card-clip',
  'id-card-alt',
  'igloo',
  'image',
  'image-landscape',
  'landscape',
  'image-polaroid',
  'image-polaroid-user',
  'image-portrait',
  'portrait',
  'image-slash',
  'image-user',
  'images',
  'images-user',
  'inbox',
  'inbox-full',
  'inbox-in',
  'inbox-arrow-down',
  'inbox-out',
  'inbox-arrow-up',
  'inboxes',
  'indent',
  'indian-rupee-sign',
  'indian-rupee',
  'inr',
  'industry',
  'industry-windows',
  'industry-alt',
  'infinity',
  'info',
  'inhaler',
  'input-numeric',
  'input-pipe',
  'input-text',
  'integral',
  'intersection',
  'island-tropical',
  'island-tree-palm',
  'italic',
  'j',
  'jack-o-lantern',
  'jedi',
  'jet-fighter',
  'fighter-jet',
  'joint',
  'joystick',
  'jug',
  'k',
  'kaaba',
  'kazoo',
  'kerning',
  'key',
  'key-skeleton',
  'key-skeleton-left-right',
  'keyboard',
  'keyboard-brightness',
  'keyboard-brightness-low',
  'keyboard-down',
  'keyboard-left',
  'keynote',
  'khanda',
  'kidneys',
  'kip-sign',
  'kit-medical',
  'first-aid',
  'kite',
  'kiwi-bird',
  'kiwi-fruit',
  'knife',
  'utensil-knife',
  'knife-kitchen',
  'l',
  'lacrosse-stick',
  'lacrosse-stick-ball',
  'lambda',
  'lamp',
  'lamp-desk',
  'lamp-floor',
  'lamp-street',
  'landmark',
  'landmark-dome',
  'landmark-alt',
  'language',
  'laptop',
  'laptop-arrow-down',
  'laptop-code',
  'laptop-medical',
  'laptop-mobile',
  'phone-laptop',
  'laptop-slash',
  'lari-sign',
  'lasso',
  'lasso-sparkles',
  'layer-group',
  'layer-minus',
  'layer-group-minus',
  'layer-plus',
  'layer-group-plus',
  'leaf',
  'leaf-heart',
  'leaf-maple',
  'leaf-oak',
  'leafy-green',
  'left',
  'arrow-alt-left',
  'left-from-line',
  'arrow-alt-from-right',
  'left-long',
  'long-arrow-alt-left',
  'left-long-to-line',
  'left-right',
  'arrows-alt-h',
  'left-to-line',
  'arrow-alt-to-left',
  'lemon',
  'less-than',
  'less-than-equal',
  'life-ring',
  'light-ceiling',
  'light-emergency',
  'light-emergency-on',
  'light-switch',
  'light-switch-off',
  'light-switch-on',
  'lightbulb',
  'lightbulb-dollar',
  'lightbulb-exclamation',
  'lightbulb-exclamation-on',
  'lightbulb-on',
  'lightbulb-slash',
  'lights-holiday',
  'line-columns',
  'line-height',
  'link',
  'chain',
  'link-horizontal',
  'chain-horizontal',
  'link-horizontal-slash',
  'chain-horizontal-slash',
  'link-simple',
  'link-simple-slash',
  'link-slash',
  'chain-broken',
  'chain-slash',
  'unlink',
  'lips',
  'lira-sign',
  'list',
  'list-squares',
  'list-check',
  'tasks',
  'list-dropdown',
  'list-music',
  'list-ol',
  'list12',
  'list-numeric',
  'list-radio',
  'list-timeline',
  'list-tree',
  'list-ul',
  'list-dots',
  'litecoin-sign',
  'loader',
  'lobster',
  'location-arrow',
  'location-check',
  'map-marker-check',
  'location-crosshairs',
  'location',
  'location-crosshairs-slash',
  'location-slash',
  'location-dot',
  'map-marker-alt',
  'location-dot-slash',
  'map-marker-alt-slash',
  'location-exclamation',
  'map-marker-exclamation',
  'location-minus',
  'map-marker-minus',
  'location-pen',
  'map-marker-edit',
  'location-pin',
  'map-marker',
  'location-pin-slash',
  'map-marker-slash',
  'location-plus',
  'map-marker-plus',
  'location-question',
  'map-marker-question',
  'location-smile',
  'map-marker-smile',
  'location-xmark',
  'map-marker-times',
  'map-marker-xmark',
  'lock',
  'lock-a',
  'lock-hashtag',
  'lock-keyhole',
  'lock-alt',
  'lock-keyhole-open',
  'lock-open-alt',
  'lock-open',
  'lollipop',
  'lollypop',
  'loveseat',
  'couch-small',
  'luchador-mask',
  'luchador',
  'mask-luchador',
  'lungs',
  'lungs-virus',
  'm',
  'mace',
  'magnet',
  'magnifying-glass',
  'search',
  'magnifying-glass-dollar',
  'search-dollar',
  'magnifying-glass-location',
  'search-location',
  'magnifying-glass-minus',
  'search-minus',
  'magnifying-glass-plus',
  'search-plus',
  'mailbox',
  'manat-sign',
  'mandolin',
  'mango',
  'manhole',
  'map',
  'map-location',
  'map-marked',
  'map-location-dot',
  'map-marked-alt',
  'map-pin',
  'marker',
  'mars',
  'mars-and-venus',
  'mars-double',
  'mars-stroke',
  'mars-stroke-right',
  'mars-stroke-h',
  'mars-stroke-up',
  'mars-stroke-v',
  'martini-glass',
  'glass-martini-alt',
  'martini-glass-citrus',
  'cocktail',
  'martini-glass-empty',
  'glass-martini',
  'mask',
  'mask-face',
  'mask-snorkel',
  'masks-theater',
  'theater-masks',
  'maximize',
  'expand-arrows-alt',
  'meat',
  'medal',
  'megaphone',
  'melon',
  'melon-slice',
  'memo',
  'memo-circle-check',
  'memo-circle-info',
  'memo-pad',
  'memory',
  'menorah',
  'mercury',
  'message',
  'comment-alt',
  'message-arrow-down',
  'comment-alt-arrow-down',
  'message-arrow-up',
  'comment-alt-arrow-up',
  'message-arrow-up-right',
  'message-bot',
  'message-captions',
  'comment-alt-captions',
  'message-check',
  'comment-alt-check',
  'message-code',
  'message-dollar',
  'comment-alt-dollar',
  'message-dots',
  'comment-alt-dots',
  'messaging',
  'message-exclamation',
  'comment-alt-exclamation',
  'message-image',
  'comment-alt-image',
  'message-lines',
  'comment-alt-lines',
  'message-medical',
  'comment-alt-medical',
  'message-middle',
  'comment-middle-alt',
  'message-middle-top',
  'comment-middle-top-alt',
  'message-minus',
  'comment-alt-minus',
  'message-music',
  'comment-alt-music',
  'message-pen',
  'comment-alt-edit',
  'message-edit',
  'message-plus',
  'comment-alt-plus',
  'message-question',
  'message-quote',
  'comment-alt-quote',
  'message-slash',
  'comment-alt-slash',
  'message-smile',
  'comment-alt-smile',
  'message-sms',
  'message-text',
  'comment-alt-text',
  'message-xmark',
  'comment-alt-times',
  'message-times',
  'messages',
  'comments-alt',
  'messages-dollar',
  'comments-alt-dollar',
  'messages-question',
  'meteor',
  'meter',
  'meter-bolt',
  'meter-droplet',
  'meter-fire',
  'microchip',
  'microchip-ai',
  'microphone',
  'microphone-lines',
  'microphone-alt',
  'microphone-lines-slash',
  'microphone-alt-slash',
  'microphone-slash',
  'microphone-stand',
  'microscope',
  'microwave',
  'mill-sign',
  'minimize',
  'compress-arrows-alt',
  'minus',
  'subtract',
  'mistletoe',
  'mitten',
  'mobile',
  'mobile-android',
  'mobile-phone',
  'mobile-button',
  'mobile-notch',
  'mobile-iphone',
  'mobile-screen',
  'mobile-android-alt',
  'mobile-screen-button',
  'mobile-alt',
  'mobile-signal',
  'mobile-signal-out',
  'money-bill',
  'money-bill-1',
  'money-bill-alt',
  'money-bill-1-wave',
  'money-bill-wave-alt',
  'money-bill-simple',
  'money-bill-simple-wave',
  'money-bill-wave',
  'money-bills',
  'money-bills-simple',
  'money-bills-alt',
  'money-check',
  'money-check-dollar',
  'money-check-alt',
  'money-check-dollar-pen',
  'money-check-edit-alt',
  'money-check-pen',
  'money-check-edit',
  'money-from-bracket',
  'money-simple-from-bracket',
  'monitor-waveform',
  'monitor-heart-rate',
  'monkey',
  'monument',
  'moon',
  'moon-cloud',
  'moon-over-sun',
  'eclipse-alt',
  'moon-stars',
  'moped',
  'mortar-pestle',
  'mosque',
  'motorcycle',
  'mountain',
  'mountains',
  'mp3-player',
  'mug',
  'mug-hot',
  'mug-marshmallows',
  'mug-saucer',
  'coffee',
  'mug-tea',
  'mug-tea-saucer',
  'mushroom',
  'music',
  'music-note',
  'music-alt',
  'music-note-slash',
  'music-alt-slash',
  'music-slash',
  'n',
  'naira-sign',
  'narwhal',
  'nesting-dolls',
  'network-wired',
  'neuter',
  'newspaper',
  'nfc',
  'nfc-lock',
  'nfc-magnifying-glass',
  'nfc-pen',
  'nfc-signal',
  'nfc-slash',
  'nfc-trash',
  'not-equal',
  'notdef',
  'note',
  'note-medical',
  'note-sticky',
  'sticky-note',
  'notebook',
  'notes',
  'notes-medical',
  'o',
  'object-exclude',
  'object-group',
  'object-intersect',
  'object-subtract',
  'object-ungroup',
  'object-union',
  'objects-align-bottom',
  'objects-align-center-horizontal',
  'objects-align-center-vertical',
  'objects-align-left',
  'objects-align-right',
  'objects-align-top',
  'objects-column',
  'octagon',
  'octagon-check',
  'octagon-divide',
  'octagon-exclamation',
  'octagon-minus',
  'minus-octagon',
  'octagon-plus',
  'plus-octagon',
  'octagon-xmark',
  'times-octagon',
  'xmark-octagon',
  'oil-can',
  'oil-can-drip',
  'oil-temperature',
  'oil-temp',
  'olive',
  'olive-branch',
  'om',
  'omega',
  'onion',
  'option',
  'ornament',
  'otter',
  'outdent',
  'dedent',
  'outlet',
  'oven',
  'overline',
  'p',
  'page',
  'page-caret-down',
  'file-caret-down',
  'page-caret-up',
  'file-caret-up',
  'pager',
  'paint-roller',
  'paintbrush',
  'paint-brush',
  'paintbrush-fine',
  'paint-brush-alt',
  'paint-brush-fine',
  'paintbrush-alt',
  'paintbrush-pencil',
  'palette',
  'pallet',
  'pallet-box',
  'pallet-boxes',
  'palette-boxes',
  'pallet-alt',
  'pan-food',
  'pan-frying',
  'pancakes',
  'panel-ews',
  'panel-fire',
  'panorama',
  'paper-plane',
  'paper-plane-top',
  'paper-plane-alt',
  'send',
  'paperclip',
  'paperclip-vertical',
  'parachute-box',
  'paragraph',
  'paragraph-left',
  'paragraph-rtl',
  'party-bell',
  'party-horn',
  'passport',
  'paste',
  'file-clipboard',
  'pause',
  'paw',
  'paw-claws',
  'paw-simple',
  'paw-alt',
  'peace',
  'peach',
  'peanut',
  'peanuts',
  'peapod',
  'pear',
  'pedestal',
  'pegasus',
  'pen',
  'pen-circle',
  'pen-clip',
  'pen-alt',
  'pen-clip-slash',
  'pen-alt-slash',
  'pen-fancy',
  'pen-fancy-slash',
  'pen-field',
  'pen-line',
  'pen-nib',
  'pen-nib-slash',
  'pen-paintbrush',
  'pencil-paintbrush',
  'pen-ruler',
  'pencil-ruler',
  'pen-slash',
  'pen-swirl',
  'pen-to-square',
  'edit',
  'pencil',
  'pencil-alt',
  'pencil-slash',
  'people',
  'people-arrows-left-right',
  'people-arrows',
  'people-carry-box',
  'people-carry',
  'people-dress',
  'people-dress-simple',
  'people-pants',
  'people-pants-simple',
  'people-simple',
  'pepper',
  'pepper-hot',
  'percent',
  'percentage',
  'period',
  'person',
  'male',
  'person-biking',
  'biking',
  'person-biking-mountain',
  'biking-mountain',
  'person-booth',
  'person-carry-box',
  'person-carry',
  'person-digging',
  'digging',
  'person-dolly',
  'person-dolly-empty',
  'person-dots-from-line',
  'diagnoses',
  'person-dress',
  'female',
  'person-dress-simple',
  'person-from-portal',
  'portal-exit',
  'person-hiking',
  'hiking',
  'person-pinball',
  'person-praying',
  'pray',
  'person-pregnant',
  'person-running',
  'running',
  'person-seat',
  'person-seat-reclined',
  'person-sign',
  'person-simple',
  'person-skating',
  'skating',
  'person-ski-jumping',
  'ski-jump',
  'person-ski-lift',
  'ski-lift',
  'person-skiing',
  'skiing',
  'person-skiing-nordic',
  'skiing-nordic',
  'person-sledding',
  'sledding',
  'person-snowboarding',
  'snowboarding',
  'person-snowmobiling',
  'snowmobile',
  'person-swimming',
  'swimmer',
  'person-to-door',
  'person-to-portal',
  'portal-enter',
  'person-walking',
  'walking',
  'person-walking-with-cane',
  'blind',
  'peseta-sign',
  'peso-sign',
  'phone',
  'phone-arrow-down-left',
  'phone-arrow-down',
  'phone-incoming',
  'phone-arrow-up-right',
  'phone-arrow-up',
  'phone-outgoing',
  'phone-flip',
  'phone-alt',
  'phone-hangup',
  'phone-intercom',
  'phone-missed',
  'phone-office',
  'phone-plus',
  'phone-rotary',
  'phone-slash',
  'phone-volume',
  'volume-control-phone',
  'phone-xmark',
  'photo-film',
  'photo-video',
  'photo-film-music',
  'pi',
  'piano',
  'piano-keyboard',
  'pickleball',
  'pie',
  'pig',
  'piggy-bank',
  'pills',
  'pinata',
  'pinball',
  'pineapple',
  'pipe',
  'pipe-circle-check',
  'pipe-collar',
  'pipe-section',
  'pipe-smoking',
  'pipe-valve',
  'pizza',
  'pizza-slice',
  'place-of-worship',
  'plane',
  'plane-arrival',
  'plane-departure',
  'plane-engines',
  'plane-alt',
  'plane-prop',
  'plane-slash',
  'plane-tail',
  'plane-up',
  'plane-up-slash',
  'planet-moon',
  'planet-ringed',
  'plate-utensils',
  'play',
  'play-pause',
  'plug',
  'plus',
  'add',
  'plus-minus',
  'podcast',
  'podium',
  'podium-star',
  'police-box',
  'poll-people',
  'pompebled',
  'poo',
  'poo-storm',
  'poo-bolt',
  'pool-8-ball',
  'poop',
  'popcorn',
  'popsicle',
  'pot-food',
  'potato',
  'power-off',
  'prescription',
  'prescription-bottle',
  'prescription-bottle-medical',
  'prescription-bottle-alt',
  'presentation-screen',
  'presentation',
  'pretzel',
  'print',
  'print-magnifying-glass',
  'print-search',
  'print-slash',
  'projector',
  'pump',
  'pump-medical',
  'pump-soap',
  'pumpkin',
  'puzzle',
  'puzzle-piece',
  'puzzle-piece-simple',
  'puzzle-piece-alt',
  'q',
  'qrcode',
  'question',
  'quote-left',
  'quote-left-alt',
  'quote-right',
  'quote-right-alt',
  'quotes',
  'r',
  'rabbit',
  'rabbit-running',
  'rabbit-fast',
  'racquet',
  'radar',
  'radiation',
  'radio',
  'radio-tuner',
  'radio-alt',
  'rainbow',
  'raindrops',
  'ram',
  'ramp-loading',
  'raygun',
  'receipt',
  'record-vinyl',
  'rectangle',
  'rectangle-landscape',
  'rectangle-ad',
  'ad',
  'rectangle-barcode',
  'barcode-alt',
  'rectangle-code',
  'rectangle-history',
  'rectangle-history-circle-plus',
  'rectangle-history-circle-user',
  'rectangle-list',
  'list-alt',
  'rectangle-pro',
  'pro',
  'rectangle-terminal',
  'rectangle-vertical',
  'rectangle-portrait',
  'rectangle-vertical-history',
  'rectangle-wide',
  'rectangle-xmark',
  'rectangle-times',
  'times-rectangle',
  'window-close',
  'rectangles-mixed',
  'recycle',
  'reel',
  'refrigerator',
  'registered',
  'repeat',
  'repeat-1',
  'reply',
  'mail-reply',
  'reply-all',
  'mail-reply-all',
  'reply-clock',
  'reply-time',
  'republican',
  'restroom',
  'restroom-simple',
  'retweet',
  'rhombus',
  'ribbon',
  'right',
  'arrow-alt-right',
  'right-from-bracket',
  'sign-out-alt',
  'right-from-line',
  'arrow-alt-from-left',
  'right-left',
  'exchange-alt',
  'right-long',
  'long-arrow-alt-right',
  'right-long-to-line',
  'right-to-bracket',
  'sign-in-alt',
  'right-to-line',
  'arrow-alt-to-right',
  'ring',
  'rings-wedding',
  'road',
  'robot',
  'robot-astromech',
  'rocket',
  'rocket-launch',
  'roller-coaster',
  'rotate',
  'sync-alt',
  'rotate-exclamation',
  'rotate-left',
  'rotate-back',
  'rotate-backward',
  'undo-alt',
  'rotate-right',
  'redo-alt',
  'rotate-forward',
  'route',
  'route-highway',
  'route-interstate',
  'router',
  'rss',
  'feed',
  'ruble-sign',
  'rouble',
  'rub',
  'ruble',
  'rugby-ball',
  'ruler',
  'ruler-combined',
  'ruler-horizontal',
  'ruler-triangle',
  'ruler-vertical',
  'rupee-sign',
  'rupee',
  'rupiah-sign',
  'rv',
  's',
  'sack',
  'sack-dollar',
  'sailboat',
  'salad',
  'bowl-salad',
  'salt-shaker',
  'sandwich',
  'satellite',
  'satellite-dish',
  'sausage',
  'saxophone',
  'saxophone-fire',
  'sax-hot',
  'scale-balanced',
  'balance-scale',
  'scale-unbalanced',
  'balance-scale-left',
  'scale-unbalanced-flip',
  'balance-scale-right',
  'scalpel',
  'scalpel-line-dashed',
  'scalpel-path',
  'scanner',
  'scanner-image',
  'scanner-gun',
  'scanner-keyboard',
  'scanner-touchscreen',
  'scarecrow',
  'scarf',
  'school',
  'scissors',
  'cut',
  'screen-users',
  'users-class',
  'screencast',
  'screwdriver',
  'screwdriver-wrench',
  'tools',
  'scribble',
  'scroll',
  'scroll-old',
  'scroll-torah',
  'torah',
  'scrubber',
  'scythe',
  'sd-card',
  'sd-cards',
  'seal',
  'seal-exclamation',
  'seal-question',
  'seat-airline',
  'section',
  'seedling',
  'sprout',
  'semicolon',
  'send-back',
  'send-backward',
  'sensor',
  'sensor-cloud',
  'sensor-smoke',
  'sensor-fire',
  'sensor-on',
  'sensor-triangle-exclamation',
  'sensor-alert',
  'server',
  'shapes',
  'triangle-circle-square',
  'share',
  'arrow-turn-right',
  'mail-forward',
  'share-all',
  'arrows-turn-right',
  'share-from-square',
  'share-square',
  'share-nodes',
  'share-alt',
  'sheep',
  'shekel-sign',
  'ils',
  'shekel',
  'sheqel',
  'sheqel-sign',
  'shelves',
  'inventory',
  'shelves-empty',
  'shield',
  'shield-blank',
  'shield-alt',
  'shield-check',
  'shield-cross',
  'shield-exclamation',
  'shield-keyhole',
  'shield-minus',
  'shield-plus',
  'shield-slash',
  'shield-virus',
  'shield-xmark',
  'shield-times',
  'ship',
  'shirt',
  't-shirt',
  'tshirt',
  'shirt-long-sleeve',
  'shirt-running',
  'shirt-tank-top',
  'shish-kebab',
  'shoe-prints',
  'shop',
  'store-alt',
  'shop-lock',
  'shop-slash',
  'store-alt-slash',
  'shovel',
  'shovel-snow',
  'shower',
  'shower-down',
  'shower-alt',
  'shredder',
  'shrimp',
  'shuffle',
  'random',
  'shutters',
  'shuttle-space',
  'space-shuttle',
  'shuttlecock',
  'sickle',
  'sidebar',
  'sidebar-flip',
  'sigma',
  'sign-hanging',
  'sign',
  'signal',
  'signal5',
  'signal-perfect',
  'signal-bars',
  'signal-alt',
  'signal-alt4',
  'signal-bars-strong',
  'signal-bars-fair',
  'signal-alt2',
  'signal-bars-good',
  'signal-alt3',
  'signal-bars-slash',
  'signal-alt-slash',
  'signal-bars-weak',
  'signal-alt1',
  'signal-fair',
  'signal2',
  'signal-good',
  'signal3',
  'signal-slash',
  'signal-stream',
  'signal-stream-slash',
  'signal-strong',
  'signal4',
  'signal-weak',
  'signal1',
  'signature',
  'signature-lock',
  'signature-slash',
  'signs-post',
  'map-signs',
  'sim-card',
  'sim-cards',
  'sink',
  'siren',
  'siren-on',
  'sitemap',
  'skeleton',
  'ski-boot',
  'ski-boot-ski',
  'skull',
  'skull-cow',
  'skull-crossbones',
  'slash',
  'slash-back',
  'slash-forward',
  'sleigh',
  'slider',
  'sliders',
  'sliders-h',
  'sliders-simple',
  'sliders-up',
  'sliders-v',
  'slot-machine',
  'smog',
  'smoke',
  'smoking',
  'snake',
  'snooze',
  'zzz',
  'snow-blowing',
  'snowflake',
  'snowflakes',
  'snowman',
  'snowman-head',
  'frosty-head',
  'snowplow',
  'soap',
  'socks',
  'soft-serve',
  'creemee',
  'solar-panel',
  'solar-system',
  'sort',
  'unsorted',
  'sort-down',
  'sort-desc',
  'sort-up',
  'sort-asc',
  'spa',
  'space-station-moon',
  'space-station-moon-construction',
  'space-station-moon-alt',
  'spade',
  'spaghetti-monster-flying',
  'pastafarianism',
  'sparkles',
  'speaker',
  'speakers',
  'spell-check',
  'spider',
  'spider-black-widow',
  'spider-web',
  'spinner',
  'spinner-third',
  'split',
  'splotch',
  'spoon',
  'utensil-spoon',
  'sportsball',
  'spray-can',
  'spray-can-sparkles',
  'air-freshener',
  'sprinkler',
  'sprinkler-ceiling',
  'square',
  'square-0',
  'square-1',
  'square-2',
  'square-3',
  'square-4',
  'square-5',
  'square-6',
  'square-7',
  'square-8',
  'square-9',
  'square-a',
  'square-a-lock',
  'square-ampersand',
  'square-arrow-down',
  'arrow-square-down',
  'square-arrow-down-left',
  'square-arrow-down-right',
  'square-arrow-left',
  'arrow-square-left',
  'square-arrow-right',
  'arrow-square-right',
  'square-arrow-up',
  'arrow-square-up',
  'square-arrow-up-left',
  'square-arrow-up-right',
  'external-link-square',
  'square-b',
  'square-bolt',
  'square-c',
  'square-caret-down',
  'caret-square-down',
  'square-caret-left',
  'caret-square-left',
  'square-caret-right',
  'caret-square-right',
  'square-caret-up',
  'caret-square-up',
  'square-check',
  'check-square',
  'square-chevron-down',
  'chevron-square-down',
  'square-chevron-left',
  'chevron-square-left',
  'square-chevron-right',
  'chevron-square-right',
  'square-chevron-up',
  'chevron-square-up',
  'square-code',
  'square-d',
  'square-dashed',
  'square-divide',
  'square-dollar',
  'dollar-square',
  'usd-square',
  'square-down',
  'arrow-alt-square-down',
  'square-down-left',
  'square-down-right',
  'square-e',
  'square-ellipsis',
  'square-ellipsis-vertical',
  'square-envelope',
  'envelope-square',
  'square-exclamation',
  'exclamation-square',
  'square-f',
  'square-fragile',
  'box-fragile',
  'square-wine-glass-crack',
  'square-full',
  'square-g',
  'square-h',
  'h-square',
  'square-heart',
  'heart-square',
  'square-i',
  'square-info',
  'info-square',
  'square-j',
  'square-k',
  'square-kanban',
  'square-l',
  'square-left',
  'arrow-alt-square-left',
  'square-list',
  'square-m',
  'square-minus',
  'minus-square',
  'square-n',
  'square-o',
  'square-p',
  'square-parking',
  'parking',
  'square-parking-slash',
  'parking-slash',
  'square-pen',
  'pen-square',
  'pencil-square',
  'square-phone',
  'phone-square',
  'square-phone-flip',
  'phone-square-alt',
  'square-phone-hangup',
  'phone-square-down',
  'square-plus',
  'plus-square',
  'square-poll-horizontal',
  'poll-h',
  'square-poll-vertical',
  'poll',
  'square-q',
  'square-quarters',
  'square-question',
  'question-square',
  'square-quote',
  'square-r',
  'square-right',
  'arrow-alt-square-right',
  'square-ring',
  'square-root',
  'square-root-variable',
  'square-root-alt',
  'square-rss',
  'rss-square',
  'square-s',
  'square-share-nodes',
  'share-alt-square',
  'square-sliders',
  'sliders-h-square',
  'square-sliders-vertical',
  'sliders-v-square',
  'square-small',
  'square-star',
  'square-t',
  'square-terminal',
  'square-this-way-up',
  'box-up',
  'square-u',
  'square-up',
  'arrow-alt-square-up',
  'square-up-left',
  'square-up-right',
  'external-link-square-alt',
  'square-user',
  'square-v',
  'square-w',
  'square-x',
  'square-xmark',
  'times-square',
  'xmark-square',
  'square-y',
  'square-z',
  'squid',
  'squirrel',
  'staff',
  'stairs',
  'stamp',
  'standard-definition',
  'rectangle-sd',
  'star',
  'star-and-crescent',
  'star-christmas',
  'star-exclamation',
  'star-half',
  'star-half-stroke',
  'star-half-alt',
  'star-of-david',
  'star-of-life',
  'star-sharp',
  'star-sharp-half',
  'star-sharp-half-stroke',
  'star-sharp-half-alt',
  'star-shooting',
  'starfighter',
  'starfighter-twin-ion-engine',
  'starfighter-alt',
  'starfighter-twin-ion-engine-advanced',
  'starfighter-alt-advanced',
  'stars',
  'starship',
  'starship-freighter',
  'steak',
  'steering-wheel',
  'sterling-sign',
  'gbp',
  'pound-sign',
  'stethoscope',
  'stocking',
  'stomach',
  'stop',
  'stopwatch',
  'stopwatch-20',
  'store',
  'store-lock',
  'store-slash',
  'strawberry',
  'street-view',
  'stretcher',
  'strikethrough',
  'stroopwafel',
  'subscript',
  'suitcase',
  'suitcase-medical',
  'medkit',
  'suitcase-rolling',
  'sun',
  'sun-bright',
  'sun-alt',
  'sun-cloud',
  'sun-dust',
  'sun-haze',
  'sunglasses',
  'sunrise',
  'sunset',
  'superscript',
  'sushi',
  'nigiri',
  'sushi-roll',
  'maki-roll',
  'makizushi',
  'swatchbook',
  'sword',
  'sword-laser',
  'sword-laser-alt',
  'swords',
  'swords-laser',
  'symbols',
  'icons-alt',
  'synagogue',
  'syringe',
  't',
  'table',
  'table-cells',
  'th',
  'table-cells-large',
  'th-large',
  'table-columns',
  'columns',
  'table-layout',
  'table-list',
  'th-list',
  'table-picnic',
  'table-pivot',
  'table-rows',
  'rows',
  'table-tennis-paddle-ball',
  'ping-pong-paddle-ball',
  'table-tennis',
  'table-tree',
  'tablet',
  'tablet-android',
  'tablet-button',
  'tablet-rugged',
  'tablet-screen',
  'tablet-android-alt',
  'tablet-screen-button',
  'tablet-alt',
  'tablets',
  'tachograph-digital',
  'digital-tachograph',
  'taco',
  'tag',
  'tags',
  'tally',
  'tally5',
  'tally-1',
  'tally-2',
  'tally-3',
  'tally-4',
  'tamale',
  'tank-water',
  'tape',
  'taxi',
  'cab',
  'taxi-bus',
  'teddy-bear',
  'teeth',
  'teeth-open',
  'telescope',
  'temperature-arrow-down',
  'temperature-down',
  'temperature-arrow-up',
  'temperature-up',
  'temperature-empty',
  'temperature0',
  'thermometer0',
  'thermometer-empty',
  'temperature-full',
  'temperature4',
  'thermometer4',
  'thermometer-full',
  'temperature-half',
  'temperature2',
  'thermometer2',
  'thermometer-half',
  'temperature-high',
  'temperature-list',
  'temperature-low',
  'temperature-quarter',
  'temperature1',
  'thermometer1',
  'thermometer-quarter',
  'temperature-snow',
  'temperature-frigid',
  'temperature-sun',
  'temperature-hot',
  'temperature-three-quarters',
  'temperature3',
  'thermometer3',
  'thermometer-three-quarters',
  'tenge-sign',
  'tenge',
  'tennis-ball',
  'terminal',
  'text',
  'text-height',
  'text-size',
  'text-slash',
  'remove-format',
  'text-width',
  'thermometer',
  'theta',
  'thought-bubble',
  'thumbs-down',
  'thumbs-up',
  'thumbtack',
  'thumb-tack',
  'tick',
  'ticket',
  'ticket-airline',
  'ticket-simple',
  'ticket-alt',
  'tickets-airline',
  'tilde',
  'timeline',
  'timeline-arrow',
  'timer',
  'tire',
  'tire-flat',
  'tire-pressure-warning',
  'tire-rugged',
  'toggle-off',
  'toggle-on',
  'toilet',
  'toilet-paper',
  'toilet-paper-blank',
  'toilet-paper-alt',
  'toilet-paper-blank-under',
  'toilet-paper-reverse-alt',
  'toilet-paper-slash',
  'toilet-paper-under',
  'toilet-paper-reverse',
  'toilet-paper-under-slash',
  'toilet-paper-reverse-slash',
  'tomato',
  'tombstone',
  'tombstone-blank',
  'tombstone-alt',
  'toolbox',
  'tooth',
  'toothbrush',
  'torii-gate',
  'tornado',
  'tower-broadcast',
  'broadcast-tower',
  'tower-control',
  'tractor',
  'trademark',
  'traffic-cone',
  'traffic-light',
  'traffic-light-go',
  'traffic-light-slow',
  'traffic-light-stop',
  'trailer',
  'train',
  'train-subway',
  'subway',
  'train-subway-tunnel',
  'subway-tunnel',
  'train-track',
  'train-tram',
  'tram',
  'train-tunnel',
  'transformer-bolt',
  'transgender',
  'transgender-alt',
  'transporter',
  'transporter-1',
  'transporter-2',
  'transporter-3',
  'transporter-4',
  'transporter-5',
  'transporter-6',
  'transporter-7',
  'transporter-empty',
  'trash',
  'trash-arrow-up',
  'trash-restore',
  'trash-can',
  'trash-alt',
  'trash-can-arrow-up',
  'trash-restore-alt',
  'trash-can-check',
  'trash-can-clock',
  'trash-can-list',
  'trash-can-plus',
  'trash-can-slash',
  'trash-alt-slash',
  'trash-can-undo',
  'trash-can-arrow-turn-left',
  'trash-undo-alt',
  'trash-can-xmark',
  'trash-check',
  'trash-clock',
  'trash-list',
  'trash-plus',
  'trash-slash',
  'trash-undo',
  'trash-arrow-turn-left',
  'trash-xmark',
  'treasure-chest',
  'tree',
  'tree-christmas',
  'tree-deciduous',
  'tree-alt',
  'tree-decorated',
  'tree-large',
  'tree-palm',
  'trees',
  'triangle',
  'triangle-exclamation',
  'exclamation-triangle',
  'warning',
  'triangle-instrument',
  'triangle-music',
  'triangle-person-digging',
  'construction',
  'trophy',
  'trophy-star',
  'trophy-alt',
  'truck',
  'truck-bolt',
  'truck-clock',
  'shipping-timed',
  'truck-container',
  'truck-container-empty',
  'truck-fast',
  'shipping-fast',
  'truck-flatbed',
  'truck-front',
  'truck-medical',
  'ambulance',
  'truck-monster',
  'truck-moving',
  'truck-pickup',
  'truck-plow',
  'truck-ramp',
  'truck-ramp-box',
  'truck-loading',
  'truck-ramp-couch',
  'truck-couch',
  'truck-tow',
  'trumpet',
  'tty',
  'teletype',
  'tty-answer',
  'teletype-answer',
  'tugrik-sign',
  'turkey',
  'turkish-lira-sign',
  'try',
  'turkish-lira',
  'turn-down',
  'level-down-alt',
  'turn-down-left',
  'turn-down-right',
  'turn-up',
  'level-up-alt',
  'turntable',
  'turtle',
  'tv',
  'television',
  'tv-alt',
  'tv-music',
  'tv-retro',
  'typewriter',
  'u',
  'ufo',
  'ufo-beam',
  'umbrella',
  'umbrella-beach',
  'umbrella-simple',
  'umbrella-alt',
  'underline',
  'unicorn',
  'uniform-martial-arts',
  'union',
  'universal-access',
  'unlock',
  'unlock-keyhole',
  'unlock-alt',
  'up',
  'arrow-alt-up',
  'up-down',
  'arrows-alt-v',
  'up-down-left-right',
  'arrows-alt',
  'up-from-dotted-line',
  'up-from-line',
  'arrow-alt-from-bottom',
  'up-left',
  'up-long',
  'long-arrow-alt-up',
  'up-right',
  'up-right-and-down-left-from-center',
  'expand-alt',
  'up-right-from-square',
  'external-link-alt',
  'up-to-dotted-line',
  'up-to-line',
  'arrow-alt-to-top',
  'upload',
  'usb-drive',
  'user',
  'user-alien',
  'user-astronaut',
  'user-bounty-hunter',
  'user-check',
  'user-chef',
  'user-clock',
  'user-cowboy',
  'user-crown',
  'user-doctor',
  'user-md',
  'user-doctor-hair',
  'user-doctor-hair-long',
  'user-doctor-message',
  'user-md-chat',
  'user-gear',
  'user-cog',
  'user-graduate',
  'user-group',
  'user-friends',
  'user-group-crown',
  'users-crown',
  'user-hair',
  'user-hair-buns',
  'user-hair-long',
  'user-hair-mullet',
  'business-front',
  'party-back',
  'trian-balbot',
  'user-headset',
  'user-helmet-safety',
  'user-construction',
  'user-hard-hat',
  'user-injured',
  'user-large',
  'user-alt',
  'user-large-slash',
  'user-alt-slash',
  'user-lock',
  'user-minus',
  'user-music',
  'user-ninja',
  'user-nurse',
  'user-nurse-hair',
  'user-nurse-hair-long',
  'user-pen',
  'user-edit',
  'user-pilot',
  'user-pilot-tie',
  'user-plus',
  'user-police',
  'user-police-tie',
  'user-robot',
  'user-robot-xmarks',
  'user-secret',
  'user-shakespeare',
  'user-shield',
  'user-slash',
  'user-tag',
  'user-tie',
  'user-tie-hair',
  'user-tie-hair-long',
  'user-unlock',
  'user-visor',
  'user-vneck',
  'user-vneck-hair',
  'user-vneck-hair-long',
  'user-xmark',
  'user-times',
  'users',
  'users-gear',
  'users-cog',
  'users-medical',
  'users-slash',
  'utensils',
  'cutlery',
  'utensils-slash',
  'utility-pole',
  'utility-pole-double',
  'v',
  'vacuum',
  'vacuum-robot',
  'value-absolute',
  'van-shuttle',
  'shuttle-van',
  'vault',
  'vector-circle',
  'vector-polygon',
  'vector-square',
  'vent-damper',
  'venus',
  'venus-double',
  'venus-mars',
  'vest',
  'vest-patches',
  'vial',
  'vials',
  'video',
  'video-camera',
  'video-arrow-down-left',
  'video-arrow-up-right',
  'video-plus',
  'video-slash',
  'vihara',
  'violin',
  'virus',
  'virus-covid',
  'virus-covid-slash',
  'virus-slash',
  'viruses',
  'voicemail',
  'volcano',
  'volleyball',
  'volleyball-ball',
  'volume',
  'volume-medium',
  'volume-high',
  'volume-up',
  'volume-low',
  'volume-down',
  'volume-off',
  'volume-slash',
  'volume-xmark',
  'volume-mute',
  'volume-times',
  'vr-cardboard',
  'w',
  'waffle',
  'wagon-covered',
  'walker',
  'walkie-talkie',
  'wallet',
  'wand',
  'wand-magic',
  'magic',
  'wand-magic-sparkles',
  'magic-wand-sparkles',
  'wand-sparkles',
  'warehouse',
  'warehouse-full',
  'warehouse-alt',
  'washing-machine',
  'washer',
  'watch',
  'watch-apple',
  'watch-calculator',
  'watch-fitness',
  'watch-smart',
  'water',
  'water-arrow-down',
  'water-lower',
  'water-arrow-up',
  'water-rise',
  'water-ladder',
  'ladder-water',
  'swimming-pool',
  'watermelon-slice',
  'wave-pulse',
  'heart-rate',
  'wave-sine',
  'wave-square',
  'wave-triangle',
  'waveform',
  'waveform-lines',
  'waveform-path',
  'weight-hanging',
  'weight-scale',
  'weight',
  'whale',
  'wheat',
  'wheat-awn',
  'wheat-alt',
  'wheat-awn-slash',
  'wheat-slash',
  'wheelchair',
  'wheelchair-move',
  'wheelchair-alt',
  'whiskey-glass',
  'glass-whiskey',
  'whiskey-glass-ice',
  'glass-whiskey-rocks',
  'whistle',
  'wifi',
  'wifi3',
  'wifi-strong',
  'wifi-exclamation',
  'wifi-fair',
  'wifi2',
  'wifi-slash',
  'wifi-weak',
  'wifi1',
  'wind',
  'wind-turbine',
  'wind-warning',
  'wind-circle-exclamation',
  'window',
  'window-flip',
  'window-alt',
  'window-frame',
  'window-frame-open',
  'window-maximize',
  'window-minimize',
  'window-restore',
  'windsock',
  'wine-bottle',
  'wine-glass',
  'wine-glass-crack',
  'fragile',
  'wine-glass-empty',
  'wine-glass-alt',
  'won-sign',
  'krw',
  'won',
  'wreath',
  'wrench',
  'wrench-simple',
  'x',
  'x-ray',
  'xmark',
  'close',
  'multiply',
  'remove',
  'times',
  'xmark-to-slot',
  'times-to-slot',
  'vote-nay',
  'y',
  'yen-sign',
  'cny',
  'jpy',
  'rmb',
  'yen',
  'yin-yang',
  'z',
  '0',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '00',
  '360-degrees',
  'a',
  'abacus',
  'accent-grave',
  'acorn',
  'address-book',
  'contact-book',
  'address-card',
  'contact-card',
  'vcard',
  'air-conditioner',
  'airplay',
  'alarm-clock',
  'alarm-exclamation',
  'alarm-plus',
  'alarm-snooze',
  'album',
  'album-circle-plus',
  'album-circle-user',
  'album-collection',
  'album-collection-circle-plus',
  'album-collection-circle-user',
  'alicorn',
  'alien',
  'alien-8bit',
  'alien-monster',
  'align-center',
  'align-justify',
  'align-left',
  'align-right',
  'align-slash',
  'alt',
  'amp-guitar',
  'ampersand',
  'anchor',
  'angel',
  'angle',
  'angle-90',
  'angle-down',
  'angle-left',
  'angle-right',
  'angle-up',
  'angles-down',
  'angle-double-down',
  'angles-left',
  'angle-double-left',
  'angles-right',
  'angle-double-right',
  'angles-up',
  'angle-double-up',
  'ankh',
  'apartment',
  'aperture',
  'apostrophe',
  'apple-core',
  'apple-whole',
  'apple-alt',
  'archway',
  'arrow-down',
  'arrow-down-1-9',
  'sort-numeric-asc',
  'sort-numeric-down',
  'arrow-down-9-1',
  'sort-numeric-desc',
  'sort-numeric-down-alt',
  'arrow-down-a-z',
  'sort-alpha-asc',
  'sort-alpha-down',
  'arrow-down-arrow-up',
  'sort-alt',
  'arrow-down-big-small',
  'sort-size-down',
  'arrow-down-from-dotted-line',
  'arrow-down-from-line',
  'arrow-from-top',
  'arrow-down-left',
  'arrow-down-left-and-arrow-up-right-to-center',
  'arrow-down-long',
  'long-arrow-down',
  'arrow-down-right',
  'arrow-down-short-wide',
  'sort-amount-desc',
  'sort-amount-down-alt',
  'arrow-down-small-big',
  'sort-size-down-alt',
  'arrow-down-square-triangle',
  'sort-shapes-down-alt',
  'arrow-down-to-bracket',
  'arrow-down-to-dotted-line',
  'arrow-down-to-line',
  'arrow-to-bottom',
  'arrow-down-to-square',
  'arrow-down-triangle-square',
  'sort-shapes-down',
  'arrow-down-wide-short',
  'sort-amount-asc',
  'sort-amount-down',
  'arrow-down-z-a',
  'sort-alpha-desc',
  'sort-alpha-down-alt',
  'arrow-left',
  'arrow-left-from-line',
  'arrow-from-right',
  'arrow-left-long',
  'long-arrow-left',
  'arrow-left-long-to-line',
  'arrow-left-to-line',
  'arrow-to-left',
  'arrow-pointer',
  'mouse-pointer',
  'arrow-right',
  'arrow-right-arrow-left',
  'exchange',
  'arrow-right-from-bracket',
  'sign-out',
  'arrow-right-from-line',
  'arrow-from-left',
  'arrow-right-long',
  'long-arrow-right',
  'arrow-right-long-to-line',
  'arrow-right-to-bracket',
  'sign-in',
  'arrow-right-to-line',
  'arrow-to-right',
  'arrow-rotate-left',
  'arrow-left-rotate',
  'arrow-rotate-back',
  'arrow-rotate-backward',
  'undo',
  'arrow-rotate-right',
  'arrow-right-rotate',
  'arrow-rotate-forward',
  'redo',
  'arrow-trend-down',
  'arrow-trend-up',
  'arrow-turn-down',
  'level-down',
  'arrow-turn-down-left',
  'arrow-turn-down-right',
  'arrow-turn-up',
  'level-up',
  'arrow-up',
  'arrow-up-1-9',
  'sort-numeric-up',
  'arrow-up-9-1',
  'sort-numeric-up-alt',
  'arrow-up-a-z',
  'sort-alpha-up',
  'arrow-up-arrow-down',
  'sort-up-down',
  'arrow-up-big-small',
  'sort-size-up',
  'arrow-up-from-bracket',
  'arrow-up-from-dotted-line',
  'arrow-up-from-line',
  'arrow-from-bottom',
  'arrow-up-from-square',
  'arrow-up-left',
  'arrow-up-left-from-circle',
  'arrow-up-long',
  'long-arrow-up',
  'arrow-up-right',
  'arrow-up-right-and-arrow-down-left-from-center',
  'arrow-up-right-from-square',
  'external-link',
  'arrow-up-short-wide',
  'sort-amount-up-alt',
  'arrow-up-small-big',
  'sort-size-up-alt',
  'arrow-up-square-triangle',
  'sort-shapes-up-alt',
  'arrow-up-to-dotted-line',
  'arrow-up-to-line',
  'arrow-to-top',
  'arrow-up-triangle-square',
  'sort-shapes-up',
  'arrow-up-wide-short',
  'sort-amount-up',
  'arrow-up-z-a',
  'sort-alpha-up-alt',
  'arrows-cross',
  'arrows-from-dotted-line',
  'arrows-from-line',
  'arrows-left-right',
  'arrows-h',
  'arrows-maximize',
  'expand-arrows',
  'arrows-minimize',
  'compress-arrows',
  'arrows-repeat',
  'repeat-alt',
  'arrows-repeat-1',
  'repeat1-alt',
  'arrows-retweet',
  'retweet-alt',
  'arrows-rotate',
  'refresh',
  'sync',
  'arrows-to-dotted-line',
  'arrows-to-line',
  'arrows-up-down',
  'arrows-v',
  'arrows-up-down-left-right',
  'arrows',
  'asterisk',
  'at',
  'atom',
  'atom-simple',
  'atom-alt',
  'audio-description',
  'audio-description-slash',
  'austral-sign',
  'avocado',
  'award',
  'award-simple',
  'axe',
  'axe-battle',
  'b',
  'baby',
  'baby-carriage',
  'carriage-baby',
  'backpack',
  'backward',
  'backward-fast',
  'fast-backward',
  'backward-step',
  'step-backward',
  'bacon',
  'bacteria',
  'bacterium',
  'badge',
  'badge-check',
  'badge-dollar',
  'badge-percent',
  'badge-sheriff',
  'badger-honey',
  'badminton',
  'bag-shopping',
  'shopping-bag',
  'bagel',
  'bags-shopping',
  'baguette',
  'bahai',
  'baht-sign',
  'ball-pile',
  'balloon',
  'balloons',
  'ballot',
  'ballot-check',
  'ban',
  'cancel',
  'ban-bug',
  'debug',
  'ban-parking',
  'parking-circle-slash',
  'ban-smoking',
  'smoking-ban',
  'banana',
  'bandage',
  'band-aid',
  'bangladeshi-taka-sign',
  'banjo',
  'barcode',
  'barcode-read',
  'barcode-scan',
  'bars',
  'navicon',
  'bars-filter',
  'bars-progress',
  'tasks-alt',
  'bars-sort',
  'bars-staggered',
  'reorder',
  'stream',
  'baseball',
  'baseball-ball',
  'baseball-bat-ball',
  'basket-shopping',
  'shopping-basket',
  'basket-shopping-simple',
  'shopping-basket-alt',
  'basketball',
  'basketball-ball',
  'basketball-hoop',
  'bat',
  'bath',
  'bathtub',
  'battery-bolt',
  'battery-empty',
  'battery0',
  'battery-exclamation',
  'battery-full',
  'battery',
  'battery5',
  'battery-half',
  'battery3',
  'battery-low',
  'battery1',
  'battery-quarter',
  'battery2',
  'battery-slash',
  'battery-three-quarters',
  'battery4',
  'bed',
  'bed-bunk',
  'bed-empty',
  'bed-front',
  'bed-alt',
  'bed-pulse',
  'procedures',
  'bee',
  'beer-mug',
  'beer-foam',
  'beer-mug-empty',
  'beer',
  'bell',
  'bell-concierge',
  'concierge-bell',
  'bell-exclamation',
  'bell-on',
  'bell-plus',
  'bell-school',
  'bell-school-slash',
  'bell-slash',
  'bells',
  'bench-tree',
  'bezier-curve',
  'bicycle',
  'binary',
  'binary-circle-check',
  'binary-lock',
  'binary-slash',
  'binoculars',
  'biohazard',
  'bird',
  'bitcoin-sign',
  'blanket',
  'blanket-fire',
  'blender',
  'blender-phone',
  'blinds',
  'blinds-open',
  'blinds-raised',
  'block',
  'block-brick',
  'wall-brick',
  'block-brick-fire',
  'firewall',
  'block-question',
  'block-quote',
  'blog',
  'blueberries',
  'bluetooth',
  'bold',
  'bolt',
  'zap',
  'bolt-auto',
  'bolt-lightning',
  'bolt-slash',
  'bomb',
  'bone',
  'bone-break',
  'bong',
  'book',
  'book-arrow-right',
  'book-arrow-up',
  'book-atlas',
  'atlas',
  'book-bible',
  'bible',
  'book-blank',
  'book-alt',
  'book-bookmark',
  'book-circle-arrow-right',
  'book-circle-arrow-up',
  'book-copy',
  'book-font',
  'book-heart',
  'book-journal-whills',
  'journal-whills',
  'book-medical',
  'book-open',
  'book-open-cover',
  'book-open-alt',
  'book-open-reader',
  'book-reader',
  'book-quran',
  'quran',
  'book-section',
  'book-law',
  'book-skull',
  'book-dead',
  'book-sparkles',
  'book-spells',
  'book-tanakh',
  'tanakh',
  'book-user',
  'bookmark',
  'bookmark-slash',
  'books',
  'books-medical',
  'boombox',
  'boot',
  'boot-heeled',
  'booth-curtain',
  'border-all',
  'border-bottom',
  'border-bottom-right',
  'border-style-alt',
  'border-center-h',
  'border-center-v',
  'border-inner',
  'border-left',
  'border-none',
  'border-outer',
  'border-right',
  'border-top',
  'border-top-left',
  'border-style',
  'bow-arrow',
  'bowl-chopsticks',
  'bowl-chopsticks-noodles',
  'bowl-hot',
  'soup',
  'bowl-rice',
  'bowl-scoop',
  'bowl-shaved-ice',
  'bowl-scoops',
  'bowl-soft-serve',
  'bowl-spoon',
  'bowling-ball',
  'bowling-ball-pin',
  'bowling-pins',
  'box',
  'box-archive',
  'archive',
  'box-ballot',
  'box-check',
  'box-circle-check',
  'box-dollar',
  'box-usd',
  'box-heart',
  'box-open',
  'box-open-full',
  'box-full',
  'box-taped',
  'box-alt',
  'box-tissue',
  'boxes-stacked',
  'boxes',
  'boxes-alt',
  'boxing-glove',
  'glove-boxing',
  'bracket-curly',
  'bracket-curly-left',
  'bracket-curly-right',
  'bracket-round',
  'parenthesis',
  'bracket-round-right',
  'bracket-square',
  'bracket',
  'bracket-left',
  'bracket-square-right',
  'brackets-curly',
  'brackets-round',
  'parentheses',
  'brackets-square',
  'brackets',
  'braille',
  'brain',
  'brain-arrow-curved-right',
  'mind-share',
  'brain-circuit',
  'brake-warning',
  'brazilian-real-sign',
  'bread-loaf',
  'bread-slice',
  'bread-slice-butter',
  'briefcase',
  'briefcase-arrow-right',
  'briefcase-blank',
  'briefcase-medical',
  'brightness',
  'brightness-low',
  'bring-forward',
  'bring-front',
  'broccoli',
  'broom',
  'broom-ball',
  'quidditch',
  'quidditch-broom-ball',
  'browser',
  'browsers',
  'brush',
  'bug',
  'bug-slash',
  'building',
  'building-columns',
  'bank',
  'institution',
  'museum',
  'university',
  'buildings',
  'bullhorn',
  'bullseye',
  'bullseye-arrow',
  'bullseye-pointer',
  'burger',
  'hamburger',
  'burger-cheese',
  'cheeseburger',
  'burger-fries',
  'burger-glass',
  'burger-lettuce',
  'burger-soda',
  'burrito',
  'bus',
  'bus-school',
  'bus-simple',
  'bus-alt',
  'business-time',
  'briefcase-clock',
  'butter',
  'c',
  'cabin',
  'cabinet-filing',
  'cable-car',
  'cactus',
  'cake-candles',
  'birthday-cake',
  'cake',
  'cake-slice',
  'shortcake',
  'calculator',
  'calculator-simple',
  'calculator-alt',
  'calendar',
  'calendar-arrow-down',
  'calendar-download',
  'calendar-arrow-up',
  'calendar-upload',
  'calendar-check',
  'calendar-circle-exclamation',
  'calendar-circle-minus',
  'calendar-circle-plus',
  'calendar-circle-user',
  'calendar-clock',
  'calendar-time',
  'calendar-day',
  'calendar-days',
  'calendar-alt',
  'calendar-exclamation',
  'calendar-heart',
  'calendar-image',
  'calendar-lines',
  'calendar-note',
  'calendar-lines-pen',
  'calendar-minus',
  'calendar-pen',
  'calendar-edit',
  'calendar-plus',
  'calendar-range',
  'calendar-star',
  'calendar-week',
  'calendar-xmark',
  'calendar-times',
  'calendars',
  'camcorder',
  'video-handheld',
  'camera',
  'camera-alt',
  'camera-cctv',
  'cctv',
  'camera-movie',
  'camera-polaroid',
  'camera-retro',
  'camera-rotate',
  'camera-security',
  'camera-home',
  'camera-slash',
  'camera-viewfinder',
  'screenshot',
  'camera-web',
  'webcam',
  'camera-web-slash',
  'webcam-slash',
  'campfire',
  'campground',
  'can-food',
  'candle-holder',
  'candy',
  'candy-bar',
  'chocolate-bar',
  'candy-cane',
  'candy-corn',
  'cannabis',
  'capsules',
  'car',
  'automobile',
  'car-battery',
  'battery-car',
  'car-bolt',
  'car-building',
  'car-bump',
  'car-bus',
  'car-circle-bolt',
  'car-crash',
  'car-garage',
  'car-mirrors',
  'car-rear',
  'car-alt',
  'car-side',
  'car-side-bolt',
  'car-tilt',
  'car-wash',
  'car-wrench',
  'car-mechanic',
  'caravan',
  'caravan-simple',
  'caravan-alt',
  'card-club',
  'card-diamond',
  'card-heart',
  'card-spade',
  'cards',
  'caret-down',
  'caret-left',
  'caret-right',
  'caret-up',
  'carrot',
  'cars',
  'cart-arrow-down',
  'cart-arrow-up',
  'cart-circle-arrow-down',
  'cart-circle-arrow-up',
  'cart-circle-check',
  'cart-circle-exclamation',
  'cart-circle-plus',
  'cart-circle-xmark',
  'cart-flatbed',
  'dolly-flatbed',
  'cart-flatbed-boxes',
  'dolly-flatbed-alt',
  'cart-flatbed-empty',
  'dolly-flatbed-empty',
  'cart-flatbed-suitcase',
  'luggage-cart',
  'cart-minus',
  'cart-plus',
  'cart-shopping',
  'shopping-cart',
  'cart-shopping-fast',
  'cart-xmark',
  'cash-register',
  'cassette-betamax',
  'betamax',
  'cassette-tape',
  'cassette-vhs',
  'vhs',
  'castle',
  'cat',
  'cat-space',
  'cauldron',
  'cedi-sign',
  'cent-sign',
  'certificate',
  'chair',
  'chair-office',
  'chalkboard',
  'blackboard',
  'chalkboard-user',
  'chalkboard-teacher',
  'champagne-glass',
  'glass-champagne',
  'champagne-glasses',
  'glass-cheers',
  'charging-station',
  'chart-area',
  'area-chart',
  'chart-bar',
  'bar-chart',
  'chart-bullet',
  'chart-candlestick',
  'chart-column',
  'chart-gantt',
  'chart-line',
  'line-chart',
  'chart-line-down',
  'chart-line-up',
  'chart-mixed',
  'analytics',
  'chart-network',
  'chart-pie',
  'pie-chart',
  'chart-pie-simple',
  'chart-pie-alt',
  'chart-pyramid',
  'chart-radar',
  'chart-scatter',
  'chart-scatter-3d',
  'chart-scatter-bubble',
  'chart-simple',
  'chart-simple-horizontal',
  'chart-tree-map',
  'chart-user',
  'user-chart',
  'chart-waterfall',
  'check',
  'check-double',
  'check-to-slot',
  'vote-yea',
  'cheese',
  'cheese-swiss',
  'cherries',
  'chess',
  'chess-bishop',
  'chess-bishop-piece',
  'chess-bishop-alt',
  'chess-board',
  'chess-clock',
  'chess-clock-flip',
  'chess-clock-alt',
  'chess-king',
  'chess-king-piece',
  'chess-king-alt',
  'chess-knight',
  'chess-knight-piece',
  'chess-knight-alt',
  'chess-pawn',
  'chess-pawn-piece',
  'chess-pawn-alt',
  'chess-queen',
  'chess-queen-piece',
  'chess-queen-alt',
  'chess-rook',
  'chess-rook-piece',
  'chess-rook-alt',
  'chestnut',
  'chevron-down',
  'chevron-left',
  'chevron-right',
  'chevron-up',
  'chevrons-down',
  'chevron-double-down',
  'chevrons-left',
  'chevron-double-left',
  'chevrons-right',
  'chevron-double-right',
  'chevrons-up',
  'chevron-double-up',
  'child',
  'chimney',
  'chopsticks',
  'church',
  'circle',
  'circle-0',
  'circle-1',
  'circle-2',
  'circle-3',
  'circle-4',
  'circle-5',
  'circle-6',
  'circle-7',
  'circle-8',
  'circle-9',
  'circle-a',
  'circle-ampersand',
  'circle-arrow-down',
  'arrow-circle-down',
  'circle-arrow-down-left',
  'circle-arrow-down-right',
  'circle-arrow-left',
  'arrow-circle-left',
  'circle-arrow-right',
  'arrow-circle-right',
  'circle-arrow-up',
  'arrow-circle-up',
  'circle-arrow-up-left',
  'circle-arrow-up-right',
  'circle-b',
  'circle-bolt',
  'circle-book-open',
  'book-circle',
  'circle-bookmark',
  'bookmark-circle',
  'circle-c',
  'circle-calendar',
  'calendar-circle',
  'circle-camera',
  'camera-circle',
  'circle-caret-down',
  'caret-circle-down',
  'circle-caret-left',
  'caret-circle-left',
  'circle-caret-right',
  'caret-circle-right',
  'circle-caret-up',
  'caret-circle-up',
  'circle-check',
  'check-circle',
  'circle-chevron-down',
  'chevron-circle-down',
  'circle-chevron-left',
  'chevron-circle-left',
  'circle-chevron-right',
  'chevron-circle-right',
  'circle-chevron-up',
  'chevron-circle-up',
  'circle-d',
  'circle-dashed',
  'circle-divide',
  'circle-dollar',
  'dollar-circle',
  'usd-circle',
  'circle-dollar-to-slot',
  'donate',
  'circle-dot',
  'dot-circle',
  'circle-down',
  'arrow-alt-circle-down',
  'circle-down-left',
  'circle-down-right',
  'circle-e',
  'circle-ellipsis',
  'circle-ellipsis-vertical',
  'circle-envelope',
  'envelope-circle',
  'circle-exclamation',
  'exclamation-circle',
  'circle-exclamation-check',
  'circle-f',
  'circle-g',
  'circle-h',
  'hospital-symbol',
  'circle-half',
  'circle-half-stroke',
  'adjust',
  'circle-heart',
  'heart-circle',
  'circle-i',
  'circle-info',
  'info-circle',
  'circle-j',
  'circle-k',
  'circle-l',
  'circle-left',
  'arrow-alt-circle-left',
  'circle-location-arrow',
  'location-circle',
  'circle-m',
  'circle-microphone',
  'microphone-circle',
  'circle-microphone-lines',
  'microphone-circle-alt',
  'circle-minus',
  'minus-circle',
  'circle-n',
  'circle-notch',
  'circle-o',
  'circle-p',
  'circle-parking',
  'parking-circle',
  'circle-pause',
  'pause-circle',
  'circle-phone',
  'phone-circle',
  'circle-phone-flip',
  'phone-circle-alt',
  'circle-phone-hangup',
  'phone-circle-down',
  'circle-play',
  'play-circle',
  'circle-plus',
  'plus-circle',
  'circle-q',
  'circle-quarter',
  'circle-quarters',
  'circle-question',
  'question-circle',
  'circle-r',
  'circle-radiation',
  'radiation-alt',
  'circle-right',
  'arrow-alt-circle-right',
  'circle-s',
  'circle-small',
  'circle-sort',
  'sort-circle',
  'circle-sort-down',
  'sort-circle-down',
  'circle-sort-up',
  'sort-circle-up',
  'circle-star',
  'star-circle',
  'circle-stop',
  'stop-circle',
  'circle-t',
  'circle-three-quarters',
  'circle-trash',
  'trash-circle',
  'circle-u',
  'circle-up',
  'arrow-alt-circle-up',
  'circle-up-left',
  'circle-up-right',
  'circle-user',
  'user-circle',
  'circle-v',
  'circle-video',
  'video-circle',
  'circle-w',
  'circle-waveform-lines',
  'waveform-circle',
  'circle-x',
  'circle-xmark',
  'times-circle',
  'xmark-circle',
  'circle-y',
  'circle-z',
  'citrus',
  'citrus-slice',
  'city',
  'clapperboard',
  'clapperboard-play',
  'clarinet',
  'claw-marks',
  'clipboard',
  'clipboard-check',
  'clipboard-list',
  'clipboard-list-check',
  'clipboard-medical',
  'clipboard-prescription',
  'clipboard-user',
  'clock',
  'clock-four',
  'clock-desk',
  'clock-eight',
  'clock-eight-thirty',
  'clock-eleven',
  'clock-eleven-thirty',
  'clock-five',
  'clock-five-thirty',
  'clock-four-thirty',
  'clock-nine',
  'clock-nine-thirty',
  'clock-one',
  'clock-one-thirty',
  'clock-rotate-left',
  'history',
  'clock-seven',
  'clock-seven-thirty',
  'clock-six',
  'clock-six-thirty',
  'clock-ten',
  'clock-ten-thirty',
  'clock-three',
  'clock-three-thirty',
  'clock-twelve',
  'clock-twelve-thirty',
  'clock-two',
  'clock-two-thirty',
  'clone',
  'closed-captioning',
  'closed-captioning-slash',
  'clothes-hanger',
  'cloud',
  'cloud-arrow-down',
  'cloud-download',
  'cloud-download-alt',
  'cloud-arrow-up',
  'cloud-upload',
  'cloud-upload-alt',
  'cloud-bolt',
  'thunderstorm',
  'cloud-bolt-moon',
  'thunderstorm-moon',
  'cloud-bolt-sun',
  'thunderstorm-sun',
  'cloud-check',
  'cloud-drizzle',
  'cloud-exclamation',
  'cloud-fog',
  'fog',
  'cloud-hail',
  'cloud-hail-mixed',
  'cloud-meatball',
  'cloud-minus',
  'cloud-moon',
  'cloud-moon-rain',
  'cloud-music',
  'cloud-plus',
  'cloud-question',
  'cloud-rain',
  'cloud-rainbow',
  'cloud-showers',
  'cloud-showers-heavy',
  'cloud-slash',
  'cloud-sleet',
  'cloud-snow',
  'cloud-sun',
  'cloud-sun-rain',
  'cloud-word',
  'cloud-xmark',
  'clouds',
  'clouds-moon',
  'clouds-sun',
  'clover',
  'club',
  'coconut',
  'code',
  'code-branch',
  'code-commit',
  'code-compare',
  'code-fork',
  'code-merge',
  'code-pull-request',
  'code-pull-request-closed',
  'code-pull-request-draft',
  'code-simple',
  'coffee-bean',
  'coffee-beans',
  'coffee-pot',
  'coffin',
  'coffin-cross',
  'coin',
  'coin-blank',
  'coin-front',
  'coin-vertical',
  'coins',
  'colon',
  'colon-sign',
  'columns-3',
  'comet',
  'comma',
  'command',
  'comment',
  'comment-arrow-down',
  'comment-arrow-up',
  'comment-arrow-up-right',
  'comment-captions',
  'comment-check',
  'comment-code',
  'comment-dollar',
  'comment-dots',
  'commenting',
  'comment-exclamation',
  'comment-image',
  'comment-lines',
  'comment-medical',
  'comment-middle',
  'comment-middle-top',
  'comment-minus',
  'comment-music',
  'comment-pen',
  'comment-edit',
  'comment-plus',
  'comment-question',
  'comment-quote',
  'comment-slash',
  'comment-smile',
  'comment-sms',
  'sms',
  'comment-text',
  'comment-xmark',
  'comment-times',
  'comments',
  'comments-dollar',
  'comments-question',
  'comments-question-check',
  'compact-disc',
  'compass',
  'compass-drafting',
  'drafting-compass',
  'compass-slash',
  'compress',
  'compress-wide',
  'computer-classic',
  'computer-mouse',
  'mouse',
  'computer-mouse-scrollwheel',
  'mouse-alt',
  'computer-speaker',
  'container-storage',
  'conveyor-belt',
  'conveyor-belt-boxes',
  'conveyor-belt-alt',
  'conveyor-belt-empty',
  'cookie',
  'cookie-bite',
  'copy',
  'copyright',
  'corn',
  'corner',
  'couch',
  'cow',
  'cowbell',
  'cowbell-circle-plus',
  'cowbell-more',
  'crab',
  'crate-apple',
  'apple-crate',
  'crate-empty',
  'credit-card',
  'credit-card-alt',
  'credit-card-blank',
  'credit-card-front',
  'cricket-bat-ball',
  'cricket',
  'croissant',
  'crop',
  'crop-simple',
  'crop-alt',
  'cross',
  'crosshairs',
  'crow',
  'crown',
  'crutch',
  'crutches',
  'cruzeiro-sign',
  'crystal-ball',
  'cube',
  'cubes',
  'cucumber',
  'cup-straw',
  'cup-straw-swoosh',
  'cup-togo',
  'coffee-togo',
  'cupcake',
  'curling-stone',
  'curling',
  'custard',
  'd',
  'dagger',
  'dash',
  'database',
  'deer',
  'deer-rudolph',
  'delete-left',
  'backspace',
  'delete-right',
  'democrat',
  'desktop',
  'desktop-alt',
  'desktop-arrow-down',
  'dharmachakra',
  'diagram-cells',
  'diagram-lean-canvas',
  'diagram-nested',
  'diagram-next',
  'diagram-predecessor',
  'diagram-previous',
  'diagram-project',
  'project-diagram',
  'diagram-sankey',
  'diagram-subtask',
  'diagram-successor',
  'diagram-venn',
  'dial',
  'dial-med-high',
  'dial-high',
  'dial-low',
  'dial-max',
  'dial-med',
  'dial-med-low',
  'dial-min',
  'dial-off',
  'diamond',
  'diamond-exclamation',
  'diamond-turn-right',
  'directions',
  'dice',
  'dice-d10',
  'dice-d12',
  'dice-d20',
  'dice-d4',
  'dice-d6',
  'dice-d8',
  'dice-five',
  'dice-four',
  'dice-one',
  'dice-six',
  'dice-three',
  'dice-two',
  'diploma',
  'scroll-ribbon',
  'disc-drive',
  'disease',
  'display',
  'display-arrow-down',
  'display-code',
  'desktop-code',
  'display-medical',
  'desktop-medical',
  'display-slash',
  'desktop-slash',
  'distribute-spacing-horizontal',
  'distribute-spacing-vertical',
  'ditto',
  'divide',
  'dna',
  'do-not-enter',
  'dog',
  'dog-leashed',
  'dollar-sign',
  'dollar',
  'usd',
  'dolly',
  'dolly-box',
  'dolly-empty',
  'dolphin',
  'dong-sign',
  'donut',
  'doughnut',
  'door-closed',
  'door-open',
  'dove',
  'down',
  'arrow-alt-down',
  'down-from-dotted-line',
  'down-from-line',
  'arrow-alt-from-top',
  'down-left',
  'down-left-and-up-right-to-center',
  'compress-alt',
  'down-long',
  'long-arrow-alt-down',
  'down-right',
  'down-to-dotted-line',
  'down-to-line',
  'arrow-alt-to-bottom',
  'download',
  'dragon',
  'draw-circle',
  'draw-polygon',
  'draw-square',
  'dreidel',
  'drone',
  'drone-front',
  'drone-alt',
  'droplet',
  'tint',
  'droplet-degree',
  'dewpoint',
  'droplet-percent',
  'humidity',
  'droplet-slash',
  'tint-slash',
  'drum',
  'drum-steelpan',
  'drumstick',
  'drumstick-bite',
  'dryer',
  'dryer-heat',
  'dryer-alt',
  'duck',
  'dumbbell',
  'dumpster',
  'dumpster-fire',
  'dungeon',
  'e',
  'ear',
  'ear-deaf',
  'deaf',
  'deafness',
  'hard-of-hearing',
  'ear-listen',
  'assistive-listening-systems',
  'ear-muffs',
  'earth-africa',
  'globe-africa',
  'earth-americas',
  'earth',
  'earth-america',
  'globe-americas',
  'earth-asia',
  'globe-asia',
  'earth-europe',
  'globe-europe',
  'earth-oceania',
  'globe-oceania',
  'eclipse',
  'egg',
  'egg-fried',
  'eggplant',
  'eject',
  'elephant',
  'elevator',
  'ellipsis',
  'ellipsis-h',
  'ellipsis-stroke',
  'ellipsis-h-alt',
  'ellipsis-stroke-vertical',
  'ellipsis-v-alt',
  'ellipsis-vertical',
  'ellipsis-v',
  'empty-set',
  'engine',
  'engine-warning',
  'engine-exclamation',
  'envelope',
  'envelope-dot',
  'envelope-badge',
  'envelope-open',
  'envelope-open-dollar',
  'envelope-open-text',
  'envelopes',
  'envelopes-bulk',
  'mail-bulk',
  'equals',
  'eraser',
  'escalator',
  'ethernet',
  'euro-sign',
  'eur',
  'euro',
  'exclamation',
  'expand',
  'expand-wide',
  'eye',
  'eye-dropper',
  'eye-dropper-empty',
  'eyedropper',
  'eye-dropper-full',
  'eye-dropper-half',
  'eye-evil',
  'eye-low-vision',
  'low-vision',
  'eye-slash',
  'eyes',
  'f',
  'face-angry',
  'angry',
  'face-angry-horns',
  'face-anguished',
  'face-anxious-sweat',
  'face-astonished',
  'face-awesome',
  'gave-dandy',
  'face-beam-hand-over-mouth',
  'face-clouds',
  'face-confounded',
  'face-confused',
  'face-cowboy-hat',
  'face-diagonal-mouth',
  'face-disappointed',
  'face-disguise',
  'face-dizzy',
  'dizzy',
  'face-dotted',
  'face-downcast-sweat',
  'face-drooling',
  'face-exhaling',
  'face-explode',
  'exploding-head',
  'face-expressionless',
  'face-eyes-xmarks',
  'face-fearful',
  'face-flushed',
  'flushed',
  'face-frown',
  'frown',
  'face-frown-open',
  'frown-open',
  'face-frown-slight',
  'face-glasses',
  'face-grimace',
  'grimace',
  'face-grin',
  'grin',
  'face-grin-beam',
  'grin-beam',
  'face-grin-beam-sweat',
  'grin-beam-sweat',
  'face-grin-hearts',
  'grin-hearts',
  'face-grin-squint',
  'grin-squint',
  'face-grin-squint-tears',
  'grin-squint-tears',
  'face-grin-stars',
  'grin-stars',
  'face-grin-tears',
  'grin-tears',
  'face-grin-tongue',
  'grin-tongue',
  'face-grin-tongue-squint',
  'grin-tongue-squint',
  'face-grin-tongue-wink',
  'grin-tongue-wink',
  'face-grin-wide',
  'grin-alt',
  'face-grin-wink',
  'grin-wink',
  'face-hand-over-mouth',
  'face-hand-peeking',
  'face-hand-yawn',
  'face-head-bandage',
  'face-holding-back-tears',
  'face-hushed',
  'face-icicles',
  'face-kiss',
  'kiss',
  'face-kiss-beam',
  'kiss-beam',
  'face-kiss-closed-eyes',
  'face-kiss-wink-heart',
  'kiss-wink-heart',
  'face-laugh',
  'laugh',
  'face-laugh-beam',
  'laugh-beam',
  'face-laugh-squint',
  'laugh-squint',
  'face-laugh-wink',
  'laugh-wink',
  'face-lying',
  'face-mask',
  'face-meh',
  'meh',
  'face-meh-blank',
  'meh-blank',
  'face-melting',
  'face-monocle',
  'face-nauseated',
  'face-nose-steam',
  'face-party',
  'face-pensive',
  'face-persevering',
  'face-pleading',
  'face-pouting',
  'face-raised-eyebrow',
  'face-relieved',
  'face-rolling-eyes',
  'meh-rolling-eyes',
  'face-sad-cry',
  'sad-cry',
  'face-sad-sweat',
  'face-sad-tear',
  'sad-tear',
  'face-saluting',
  'face-scream',
  'face-shush',
  'face-sleeping',
  'face-sleepy',
  'face-smile',
  'smile',
  'face-smile-beam',
  'smile-beam',
  'face-smile-halo',
  'face-smile-hearts',
  'face-smile-horns',
  'face-smile-plus',
  'smile-plus',
  'face-smile-relaxed',
  'face-smile-tear',
  'face-smile-tongue',
  'face-smile-upside-down',
  'face-smile-wink',
  'smile-wink',
  'face-smiling-hands',
  'face-smirking',
  'face-spiral-eyes',
  'face-sunglasses',
  'face-surprise',
  'surprise',
  'face-swear',
  'face-thermometer',
  'face-thinking',
  'face-tired',
  'tired',
  'face-tissue',
  'face-tongue-money',
  'face-tongue-sweat',
  'face-unamused',
  'face-viewfinder',
  'face-vomit',
  'face-weary',
  'face-woozy',
  'face-worried',
  'face-zany',
  'face-zipper',
  'falafel',
  'family',
  'family-dress',
  'family-pants',
  'fan',
  'fan-table',
  'farm',
  'barn-silo',
  'faucet',
  'faucet-drip',
  'fax',
  'feather',
  'feather-pointed',
  'feather-alt',
  'fence',
  'ferris-wheel',
  'field-hockey-stick-ball',
  'field-hockey',
  'file',
  'file-arrow-down',
  'file-download',
  'file-arrow-up',
  'file-upload',
  'file-audio',
  'file-binary',
  'file-certificate',
  'file-award',
  'file-chart-column',
  'file-chart-line',
  'file-chart-pie',
  'file-check',
  'file-circle-info',
  'file-circle-plus',
  'file-code',
  'file-contract',
  'file-csv',
  'file-dashed-line',
  'page-break',
  'file-excel',
  'file-exclamation',
  'file-export',
  'arrow-right-from-file',
  'file-heart',
  'file-image',
  'file-import',
  'arrow-right-to-file',
  'file-invoice',
  'file-invoice-dollar',
  'file-lines',
  'file-alt',
  'file-text',
  'file-lock',
  'file-magnifying-glass',
  'file-search',
  'file-medical',
  'file-minus',
  'file-music',
  'file-pdf',
  'file-pen',
  'file-edit',
  'file-plus',
  'file-plus-minus',
  'file-powerpoint',
  'file-prescription',
  'file-signature',
  'file-slash',
  'file-spreadsheet',
  'file-user',
  'file-video',
  'file-waveform',
  'file-medical-alt',
  'file-word',
  'file-xmark',
  'file-times',
  'file-zipper',
  'file-archive',
  'files',
  'files-medical',
  'fill',
  'fill-drip',
  'film',
  'film-canister',
  'film-simple',
  'film-alt',
  'film-slash',
  'films',
  'filter',
  'filter-circle-dollar',
  'funnel-dollar',
  'filter-circle-xmark',
  'filter-list',
  'filter-slash',
  'filters',
  'fingerprint',
  'fire',
  'fire-extinguisher',
  'fire-flame',
  'flame',
  'fire-flame-curved',
  'fire-alt',
  'fire-flame-simple',
  'burn',
  'fire-hydrant',
  'fire-smoke',
  'fireplace',
  'fish',
  'fish-bones',
  'fish-cooked',
  'fishing-rod',
  'flag',
  'flag-checkered',
  'flag-pennant',
  'pennant',
  'flag-swallowtail',
  'flag-alt',
  'flag-usa',
  'flashlight',
  'flask',
  'flask-round-poison',
  'flask-poison',
  'flask-round-potion',
  'flask-potion',
  'flatbread',
  'flatbread-stuffed',
  'floppy-disk',
  'save',
  'floppy-disk-circle-arrow-right',
  'save-circle-arrow-right',
  'floppy-disk-circle-xmark',
  'floppy-disk-times',
  'save-circle-xmark',
  'save-times',
  'floppy-disk-pen',
  'floppy-disks',
  'florin-sign',
  'flower',
  'flower-daffodil',
  'flower-tulip',
  'flute',
  'flux-capacitor',
  'flying-disc',
  'folder',
  'folder-arrow-down',
  'folder-download',
  'folder-arrow-up',
  'folder-upload',
  'folder-blank',
  'folder-bookmark',
  'folder-gear',
  'folder-cog',
  'folder-grid',
  'folder-heart',
  'folder-image',
  'folder-magnifying-glass',
  'folder-search',
  'folder-medical',
  'folder-minus',
  'folder-music',
  'folder-open',
  'folder-plus',
  'folder-tree',
  'folder-user',
  'folder-xmark',
  'folder-times',
  'folders',
  'fondue-pot',
  'font',
  'font-awesome',
  'font-awesome-flag',
  'font-awesome-logo-full',
  'font-case',
  'football',
  'football-ball',
  'football-helmet',
  'fork',
  'utensil-fork',
  'fork-knife',
  'utensils-alt',
  'forklift',
  'fort',
  'forward',
  'forward-fast',
  'fast-forward',
  'forward-step',
  'step-forward',
  'frame',
  'franc-sign',
  'french-fries',
  'frog',
  'function',
  'futbol',
  'futbol-ball',
  'soccer-ball',
  'g',
  'galaxy',
  'gallery-thumbnails',
  'game-board',
  'game-board-simple',
  'game-board-alt',
  'game-console-handheld',
  'gamepad',
  'gamepad-modern',
  'gamepad-alt',
  'garage',
  'garage-car',
  'garage-open',
  'garlic',
  'gas-pump',
  'gas-pump-slash',
  'gauge',
  'dashboard',
  'gauge-med',
  'tachometer-alt-average',
  'gauge-circle-bolt',
  'gauge-circle-minus',
  'gauge-circle-plus',
  'gauge-high',
  'tachometer-alt',
  'tachometer-alt-fast',
  'gauge-low',
  'tachometer-alt-slow',
  'gauge-max',
  'tachometer-alt-fastest',
  'gauge-min',
  'tachometer-alt-slowest',
  'gauge-simple',
  'gauge-simple-med',
  'tachometer-average',
  'gauge-simple-high',
  'tachometer',
  'tachometer-fast',
  'gauge-simple-low',
  'tachometer-slow',
  'gauge-simple-max',
  'tachometer-fastest',
  'gauge-simple-min',
  'tachometer-slowest',
  'gavel',
  'legal',
  'gear',
  'cog',
  'gears',
  'cogs',
  'gem',
  'genderless',
  'ghost',
  'gif',
  'gift',
  'gift-card',
  'gifts',
  'gingerbread-man',
  'glass',
  'glass-citrus',
  'glass-empty',
  'glass-half',
  'glass-half-empty',
  'glass-half-full',
  'glasses',
  'glasses-round',
  'glasses-alt',
  'globe',
  'globe-snow',
  'globe-stand',
  'goal-net',
  'golf-ball-tee',
  'golf-ball',
  'golf-club',
  'golf-flag-hole',
  'gopuram',
  'graduation-cap',
  'mortar-board',
  'gramophone',
  'grapes',
  'grate',
  'grate-droplet',
  'greater-than',
  'greater-than-equal',
  'grid',
  'grid3',
  'grid-2',
  'grid-2-plus',
  'grid-4',
  'grid-5',
  'grid-dividers',
  'grid-horizontal',
  'grip',
  'grip-horizontal',
  'grip-dots',
  'grip-dots-vertical',
  'grip-lines',
  'grip-lines-vertical',
  'grip-vertical',
  'guarani-sign',
  'guitar',
  'guitar-electric',
  'guitars',
  'gun',
  'gun-slash',
  'gun-squirt',
  'h',
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
  'hammer',
  'hammer-crash',
  'hammer-war',
  'hamsa',
  'hand',
  'hand-paper',
  'hand-back-fist',
  'hand-rock',
  'hand-back-point-down',
  'hand-back-point-left',
  'hand-back-point-ribbon',
  'hand-back-point-right',
  'hand-back-point-up',
  'hand-dots',
  'allergies',
  'hand-fingers-crossed',
  'hand-fist',
  'fist-raised',
  'hand-heart',
  'hand-holding',
  'hand-holding-box',
  'hand-holding-dollar',
  'hand-holding-usd',
  'hand-holding-droplet',
  'hand-holding-water',
  'hand-holding-heart',
  'hand-holding-magic',
  'hand-holding-medical',
  'hand-holding-seedling',
  'hand-holding-skull',
  'hand-horns',
  'hand-lizard',
  'hand-love',
  'hand-middle-finger',
  'hand-peace',
  'hand-point-down',
  'hand-point-left',
  'hand-point-ribbon',
  'hand-point-right',
  'hand-point-up',
  'hand-pointer',
  'hand-scissors',
  'hand-sparkles',
  'hand-spock',
  'hand-wave',
  'hands',
  'sign-language',
  'signing',
  'hands-asl-interpreting',
  'american-sign-language-interpreting',
  'asl-interpreting',
  'hands-american-sign-language-interpreting',
  'hands-bubbles',
  'hands-wash',
  'hands-clapping',
  'hands-holding',
  'hands-holding-diamond',
  'hand-receiving',
  'hands-holding-dollar',
  'hands-usd',
  'hands-holding-heart',
  'hands-heart',
  'hands-praying',
  'praying-hands',
  'handshake',
  'handshake-angle',
  'hands-helping',
  'handshake-simple',
  'handshake-alt',
  'handshake-simple-slash',
  'handshake-alt-slash',
  'handshake-slash',
  'hanukiah',
  'hard-drive',
  'hdd',
  'hashtag',
  'hashtag-lock',
  'hat-chef',
  'hat-cowboy',
  'hat-cowboy-side',
  'hat-santa',
  'hat-winter',
  'hat-witch',
  'hat-wizard',
  'head-side',
  'head-side-brain',
  'head-side-cough',
  'head-side-cough-slash',
  'head-side-goggles',
  'head-vr',
  'head-side-headphones',
  'head-side-heart',
  'head-side-mask',
  'head-side-medical',
  'head-side-virus',
  'heading',
  'header',
  'headphones',
  'headphones-simple',
  'headphones-alt',
  'headset',
  'heart',
  'heart-crack',
  'heart-broken',
  'heart-half',
  'heart-half-stroke',
  'heart-half-alt',
  'heart-pulse',
  'heartbeat',
  'heat',
  'helicopter',
  'helmet-battle',
  'helmet-safety',
  'hard-hat',
  'hat-hard',
  'hexagon',
  'hexagon-check',
  'hexagon-divide',
  'hexagon-exclamation',
  'hexagon-minus',
  'minus-hexagon',
  'hexagon-plus',
  'plus-hexagon',
  'hexagon-xmark',
  'times-hexagon',
  'xmark-hexagon',
  'high-definition',
  'rectangle-hd',
  'highlighter',
  'highlighter-line',
  'hippo',
  'hockey-mask',
  'hockey-puck',
  'hockey-stick-puck',
  'hockey-sticks',
  'holly-berry',
  'honey-pot',
  'hood-cloak',
  'horizontal-rule',
  'horse',
  'horse-head',
  'horse-saddle',
  'hose',
  'hose-reel',
  'hospital',
  'hospital-alt',
  'hospital-wide',
  'hospital-user',
  'hospitals',
  'hot-tub-person',
  'hot-tub',
  'hotdog',
  'hotel',
  'hourglass',
  'hourglass2',
  'hourglass-half',
  'hourglass-clock',
  'hourglass-empty',
  'hourglass-end',
  'hourglass3',
  'hourglass-start',
  'hourglass1',
  'house',
  'home',
  'home-alt',
  'home-lg-alt',
  'house-blank',
  'home-blank',
  'house-building',
  'house-chimney',
  'home-lg',
  'house-chimney-blank',
  'house-chimney-crack',
  'house-damage',
  'house-chimney-heart',
  'house-chimney-medical',
  'clinic-medical',
  'house-chimney-user',
  'house-chimney-window',
  'house-crack',
  'house-day',
  'house-flood',
  'house-heart',
  'home-heart',
  'house-laptop',
  'laptop-house',
  'house-medical',
  'house-night',
  'house-person-leave',
  'house-leave',
  'house-person-depart',
  'house-person-return',
  'house-person-arrive',
  'house-return',
  'house-signal',
  'house-tree',
  'house-turret',
  'house-user',
  'home-user',
  'house-window',
  'hryvnia-sign',
  'hryvnia',
  'hundred-points',
  '100',
  'hurricane',
  'hyphen',
  'i',
  'i-cursor',
  'ice-cream',
  'ice-skate',
  'icicles',
  'icons',
  'heart-music-camera-bolt',
  'id-badge',
  'id-card',
  'drivers-license',
  'id-card-clip',
  'id-card-alt',
  'igloo',
  'image',
  'image-landscape',
  'landscape',
  'image-polaroid',
  'image-polaroid-user',
  'image-portrait',
  'portrait',
  'image-slash',
  'image-user',
  'images',
  'images-user',
  'inbox',
  'inbox-full',
  'inbox-in',
  'inbox-arrow-down',
  'inbox-out',
  'inbox-arrow-up',
  'inboxes',
  'indent',
  'indian-rupee-sign',
  'indian-rupee',
  'inr',
  'industry',
  'industry-windows',
  'industry-alt',
  'infinity',
  'info',
  'inhaler',
  'input-numeric',
  'input-pipe',
  'input-text',
  'integral',
  'intersection',
  'island-tropical',
  'island-tree-palm',
  'italic',
  'j',
  'jack-o-lantern',
  'jedi',
  'jet-fighter',
  'fighter-jet',
  'joint',
  'joystick',
  'jug',
  'k',
  'kaaba',
  'kazoo',
  'kerning',
  'key',
  'key-skeleton',
  'key-skeleton-left-right',
  'keyboard',
  'keyboard-brightness',
  'keyboard-brightness-low',
  'keyboard-down',
  'keyboard-left',
  'keynote',
  'khanda',
  'kidneys',
  'kip-sign',
  'kit-medical',
  'first-aid',
  'kite',
  'kiwi-bird',
  'kiwi-fruit',
  'knife',
  'utensil-knife',
  'knife-kitchen',
  'l',
  'lacrosse-stick',
  'lacrosse-stick-ball',
  'lambda',
  'lamp',
  'lamp-desk',
  'lamp-floor',
  'lamp-street',
  'landmark',
  'landmark-dome',
  'landmark-alt',
  'language',
  'laptop',
  'laptop-arrow-down',
  'laptop-code',
  'laptop-medical',
  'laptop-mobile',
  'phone-laptop',
  'laptop-slash',
  'lari-sign',
  'lasso',
  'lasso-sparkles',
  'layer-group',
  'layer-minus',
  'layer-group-minus',
  'layer-plus',
  'layer-group-plus',
  'leaf',
  'leaf-heart',
  'leaf-maple',
  'leaf-oak',
  'leafy-green',
  'left',
  'arrow-alt-left',
  'left-from-line',
  'arrow-alt-from-right',
  'left-long',
  'long-arrow-alt-left',
  'left-long-to-line',
  'left-right',
  'arrows-alt-h',
  'left-to-line',
  'arrow-alt-to-left',
  'lemon',
  'less-than',
  'less-than-equal',
  'life-ring',
  'light-ceiling',
  'light-emergency',
  'light-emergency-on',
  'light-switch',
  'light-switch-off',
  'light-switch-on',
  'lightbulb',
  'lightbulb-dollar',
  'lightbulb-exclamation',
  'lightbulb-exclamation-on',
  'lightbulb-on',
  'lightbulb-slash',
  'lights-holiday',
  'line-columns',
  'line-height',
  'link',
  'chain',
  'link-horizontal',
  'chain-horizontal',
  'link-horizontal-slash',
  'chain-horizontal-slash',
  'link-simple',
  'link-simple-slash',
  'link-slash',
  'chain-broken',
  'chain-slash',
  'unlink',
  'lips',
  'lira-sign',
  'list',
  'list-squares',
  'list-check',
  'tasks',
  'list-dropdown',
  'list-music',
  'list-ol',
  'list12',
  'list-numeric',
  'list-radio',
  'list-timeline',
  'list-tree',
  'list-ul',
  'list-dots',
  'litecoin-sign',
  'loader',
  'lobster',
  'location-arrow',
  'location-check',
  'map-marker-check',
  'location-crosshairs',
  'location',
  'location-crosshairs-slash',
  'location-slash',
  'location-dot',
  'map-marker-alt',
  'location-dot-slash',
  'map-marker-alt-slash',
  'location-exclamation',
  'map-marker-exclamation',
  'location-minus',
  'map-marker-minus',
  'location-pen',
  'map-marker-edit',
  'location-pin',
  'map-marker',
  'location-pin-slash',
  'map-marker-slash',
  'location-plus',
  'map-marker-plus',
  'location-question',
  'map-marker-question',
  'location-smile',
  'map-marker-smile',
  'location-xmark',
  'map-marker-times',
  'map-marker-xmark',
  'lock',
  'lock-a',
  'lock-hashtag',
  'lock-keyhole',
  'lock-alt',
  'lock-keyhole-open',
  'lock-open-alt',
  'lock-open',
  'lollipop',
  'lollypop',
  'loveseat',
  'couch-small',
  'luchador-mask',
  'luchador',
  'mask-luchador',
  'lungs',
  'lungs-virus',
  'm',
  'mace',
  'magnet',
  'magnifying-glass',
  'search',
  'magnifying-glass-dollar',
  'search-dollar',
  'magnifying-glass-location',
  'search-location',
  'magnifying-glass-minus',
  'search-minus',
  'magnifying-glass-plus',
  'search-plus',
  'mailbox',
  'manat-sign',
  'mandolin',
  'mango',
  'manhole',
  'map',
  'map-location',
  'map-marked',
  'map-location-dot',
  'map-marked-alt',
  'map-pin',
  'marker',
  'mars',
  'mars-and-venus',
  'mars-double',
  'mars-stroke',
  'mars-stroke-right',
  'mars-stroke-h',
  'mars-stroke-up',
  'mars-stroke-v',
  'martini-glass',
  'glass-martini-alt',
  'martini-glass-citrus',
  'cocktail',
  'martini-glass-empty',
  'glass-martini',
  'mask',
  'mask-face',
  'mask-snorkel',
  'masks-theater',
  'theater-masks',
  'maximize',
  'expand-arrows-alt',
  'meat',
  'medal',
  'megaphone',
  'melon',
  'melon-slice',
  'memo',
  'memo-circle-check',
  'memo-circle-info',
  'memo-pad',
  'memory',
  'menorah',
  'mercury',
  'message',
  'comment-alt',
  'message-arrow-down',
  'comment-alt-arrow-down',
  'message-arrow-up',
  'comment-alt-arrow-up',
  'message-arrow-up-right',
  'message-bot',
  'message-captions',
  'comment-alt-captions',
  'message-check',
  'comment-alt-check',
  'message-code',
  'message-dollar',
  'comment-alt-dollar',
  'message-dots',
  'comment-alt-dots',
  'messaging',
  'message-exclamation',
  'comment-alt-exclamation',
  'message-image',
  'comment-alt-image',
  'message-lines',
  'comment-alt-lines',
  'message-medical',
  'comment-alt-medical',
  'message-middle',
  'comment-middle-alt',
  'message-middle-top',
  'comment-middle-top-alt',
  'message-minus',
  'comment-alt-minus',
  'message-music',
  'comment-alt-music',
  'message-pen',
  'comment-alt-edit',
  'message-edit',
  'message-plus',
  'comment-alt-plus',
  'message-question',
  'message-quote',
  'comment-alt-quote',
  'message-slash',
  'comment-alt-slash',
  'message-smile',
  'comment-alt-smile',
  'message-sms',
  'message-text',
  'comment-alt-text',
  'message-xmark',
  'comment-alt-times',
  'message-times',
  'messages',
  'comments-alt',
  'messages-dollar',
  'comments-alt-dollar',
  'messages-question',
  'meteor',
  'meter',
  'meter-bolt',
  'meter-droplet',
  'meter-fire',
  'microchip',
  'microchip-ai',
  'microphone',
  'microphone-lines',
  'microphone-alt',
  'microphone-lines-slash',
  'microphone-alt-slash',
  'microphone-slash',
  'microphone-stand',
  'microscope',
  'microwave',
  'mill-sign',
  'minimize',
  'compress-arrows-alt',
  'minus',
  'subtract',
  'mistletoe',
  'mitten',
  'mobile',
  'mobile-android',
  'mobile-phone',
  'mobile-button',
  'mobile-notch',
  'mobile-iphone',
  'mobile-screen',
  'mobile-android-alt',
  'mobile-screen-button',
  'mobile-alt',
  'mobile-signal',
  'mobile-signal-out',
  'money-bill',
  'money-bill-1',
  'money-bill-alt',
  'money-bill-1-wave',
  'money-bill-wave-alt',
  'money-bill-simple',
  'money-bill-simple-wave',
  'money-bill-wave',
  'money-bills',
  'money-bills-simple',
  'money-bills-alt',
  'money-check',
  'money-check-dollar',
  'money-check-alt',
  'money-check-dollar-pen',
  'money-check-edit-alt',
  'money-check-pen',
  'money-check-edit',
  'money-from-bracket',
  'money-simple-from-bracket',
  'monitor-waveform',
  'monitor-heart-rate',
  'monkey',
  'monument',
  'moon',
  'moon-cloud',
  'moon-over-sun',
  'eclipse-alt',
  'moon-stars',
  'moped',
  'mortar-pestle',
  'mosque',
  'motorcycle',
  'mountain',
  'mountains',
  'mp3-player',
  'mug',
  'mug-hot',
  'mug-marshmallows',
  'mug-saucer',
  'coffee',
  'mug-tea',
  'mug-tea-saucer',
  'mushroom',
  'music',
  'music-note',
  'music-alt',
  'music-note-slash',
  'music-alt-slash',
  'music-slash',
  'n',
  'naira-sign',
  'narwhal',
  'nesting-dolls',
  'network-wired',
  'neuter',
  'newspaper',
  'nfc',
  'nfc-lock',
  'nfc-magnifying-glass',
  'nfc-pen',
  'nfc-signal',
  'nfc-slash',
  'nfc-trash',
  'not-equal',
  'notdef',
  'note',
  'note-medical',
  'note-sticky',
  'sticky-note',
  'notebook',
  'notes',
  'notes-medical',
  'o',
  'object-exclude',
  'object-group',
  'object-intersect',
  'object-subtract',
  'object-ungroup',
  'object-union',
  'objects-align-bottom',
  'objects-align-center-horizontal',
  'objects-align-center-vertical',
  'objects-align-left',
  'objects-align-right',
  'objects-align-top',
  'objects-column',
  'octagon',
  'octagon-check',
  'octagon-divide',
  'octagon-exclamation',
  'octagon-minus',
  'minus-octagon',
  'octagon-plus',
  'plus-octagon',
  'octagon-xmark',
  'times-octagon',
  'xmark-octagon',
  'oil-can',
  'oil-can-drip',
  'oil-temperature',
  'oil-temp',
  'olive',
  'olive-branch',
  'om',
  'omega',
  'onion',
  'option',
  'ornament',
  'otter',
  'outdent',
  'dedent',
  'outlet',
  'oven',
  'overline',
  'p',
  'page',
  'page-caret-down',
  'file-caret-down',
  'page-caret-up',
  'file-caret-up',
  'pager',
  'paint-roller',
  'paintbrush',
  'paint-brush',
  'paintbrush-fine',
  'paint-brush-alt',
  'paint-brush-fine',
  'paintbrush-alt',
  'paintbrush-pencil',
  'palette',
  'pallet',
  'pallet-box',
  'pallet-boxes',
  'palette-boxes',
  'pallet-alt',
  'pan-food',
  'pan-frying',
  'pancakes',
  'panel-ews',
  'panel-fire',
  'panorama',
  'paper-plane',
  'paper-plane-top',
  'paper-plane-alt',
  'send',
  'paperclip',
  'paperclip-vertical',
  'parachute-box',
  'paragraph',
  'paragraph-left',
  'paragraph-rtl',
  'party-bell',
  'party-horn',
  'passport',
  'paste',
  'file-clipboard',
  'pause',
  'paw',
  'paw-claws',
  'paw-simple',
  'paw-alt',
  'peace',
  'peach',
  'peanut',
  'peanuts',
  'peapod',
  'pear',
  'pedestal',
  'pegasus',
  'pen',
  'pen-circle',
  'pen-clip',
  'pen-alt',
  'pen-clip-slash',
  'pen-alt-slash',
  'pen-fancy',
  'pen-fancy-slash',
  'pen-field',
  'pen-line',
  'pen-nib',
  'pen-nib-slash',
  'pen-paintbrush',
  'pencil-paintbrush',
  'pen-ruler',
  'pencil-ruler',
  'pen-slash',
  'pen-swirl',
  'pen-to-square',
  'edit',
  'pencil',
  'pencil-alt',
  'pencil-slash',
  'people',
  'people-arrows-left-right',
  'people-arrows',
  'people-carry-box',
  'people-carry',
  'people-dress',
  'people-dress-simple',
  'people-pants',
  'people-pants-simple',
  'people-simple',
  'pepper',
  'pepper-hot',
  'percent',
  'percentage',
  'period',
  'person',
  'male',
  'person-biking',
  'biking',
  'person-biking-mountain',
  'biking-mountain',
  'person-booth',
  'person-carry-box',
  'person-carry',
  'person-digging',
  'digging',
  'person-dolly',
  'person-dolly-empty',
  'person-dots-from-line',
  'diagnoses',
  'person-dress',
  'female',
  'person-dress-simple',
  'person-from-portal',
  'portal-exit',
  'person-hiking',
  'hiking',
  'person-pinball',
  'person-praying',
  'pray',
  'person-pregnant',
  'person-running',
  'running',
  'person-seat',
  'person-seat-reclined',
  'person-sign',
  'person-simple',
  'person-skating',
  'skating',
  'person-ski-jumping',
  'ski-jump',
  'person-ski-lift',
  'ski-lift',
  'person-skiing',
  'skiing',
  'person-skiing-nordic',
  'skiing-nordic',
  'person-sledding',
  'sledding',
  'person-snowboarding',
  'snowboarding',
  'person-snowmobiling',
  'snowmobile',
  'person-swimming',
  'swimmer',
  'person-to-door',
  'person-to-portal',
  'portal-enter',
  'person-walking',
  'walking',
  'person-walking-with-cane',
  'blind',
  'peseta-sign',
  'peso-sign',
  'phone',
  'phone-arrow-down-left',
  'phone-arrow-down',
  'phone-incoming',
  'phone-arrow-up-right',
  'phone-arrow-up',
  'phone-outgoing',
  'phone-flip',
  'phone-alt',
  'phone-hangup',
  'phone-intercom',
  'phone-missed',
  'phone-office',
  'phone-plus',
  'phone-rotary',
  'phone-slash',
  'phone-volume',
  'volume-control-phone',
  'phone-xmark',
  'photo-film',
  'photo-video',
  'photo-film-music',
  'pi',
  'piano',
  'piano-keyboard',
  'pickleball',
  'pie',
  'pig',
  'piggy-bank',
  'pills',
  'pinata',
  'pinball',
  'pineapple',
  'pipe',
  'pipe-circle-check',
  'pipe-collar',
  'pipe-section',
  'pipe-smoking',
  'pipe-valve',
  'pizza',
  'pizza-slice',
  'place-of-worship',
  'plane',
  'plane-arrival',
  'plane-departure',
  'plane-engines',
  'plane-alt',
  'plane-prop',
  'plane-slash',
  'plane-tail',
  'plane-up',
  'plane-up-slash',
  'planet-moon',
  'planet-ringed',
  'plate-utensils',
  'play',
  'play-pause',
  'plug',
  'plus',
  'add',
  'plus-minus',
  'podcast',
  'podium',
  'podium-star',
  'police-box',
  'poll-people',
  'pompebled',
  'poo',
  'poo-storm',
  'poo-bolt',
  'pool-8-ball',
  'poop',
  'popcorn',
  'popsicle',
  'pot-food',
  'potato',
  'power-off',
  'prescription',
  'prescription-bottle',
  'prescription-bottle-medical',
  'prescription-bottle-alt',
  'presentation-screen',
  'presentation',
  'pretzel',
  'print',
  'print-magnifying-glass',
  'print-search',
  'print-slash',
  'projector',
  'pump',
  'pump-medical',
  'pump-soap',
  'pumpkin',
  'puzzle',
  'puzzle-piece',
  'puzzle-piece-simple',
  'puzzle-piece-alt',
  'q',
  'qrcode',
  'question',
  'quote-left',
  'quote-left-alt',
  'quote-right',
  'quote-right-alt',
  'quotes',
  'r',
  'rabbit',
  'rabbit-running',
  'rabbit-fast',
  'racquet',
  'radar',
  'radiation',
  'radio',
  'radio-tuner',
  'radio-alt',
  'rainbow',
  'raindrops',
  'ram',
  'ramp-loading',
  'raygun',
  'receipt',
  'record-vinyl',
  'rectangle',
  'rectangle-landscape',
  'rectangle-ad',
  'ad',
  'rectangle-barcode',
  'barcode-alt',
  'rectangle-code',
  'rectangle-history',
  'rectangle-history-circle-plus',
  'rectangle-history-circle-user',
  'rectangle-list',
  'list-alt',
  'rectangle-pro',
  'pro',
  'rectangle-terminal',
  'rectangle-vertical',
  'rectangle-portrait',
  'rectangle-vertical-history',
  'rectangle-wide',
  'rectangle-xmark',
  'rectangle-times',
  'times-rectangle',
  'window-close',
  'rectangles-mixed',
  'recycle',
  'reel',
  'refrigerator',
  'registered',
  'repeat',
  'repeat-1',
  'reply',
  'mail-reply',
  'reply-all',
  'mail-reply-all',
  'reply-clock',
  'reply-time',
  'republican',
  'restroom',
  'restroom-simple',
  'retweet',
  'rhombus',
  'ribbon',
  'right',
  'arrow-alt-right',
  'right-from-bracket',
  'sign-out-alt',
  'right-from-line',
  'arrow-alt-from-left',
  'right-left',
  'exchange-alt',
  'right-long',
  'long-arrow-alt-right',
  'right-long-to-line',
  'right-to-bracket',
  'sign-in-alt',
  'right-to-line',
  'arrow-alt-to-right',
  'ring',
  'rings-wedding',
  'road',
  'robot',
  'robot-astromech',
  'rocket',
  'rocket-launch',
  'roller-coaster',
  'rotate',
  'sync-alt',
  'rotate-exclamation',
  'rotate-left',
  'rotate-back',
  'rotate-backward',
  'undo-alt',
  'rotate-right',
  'redo-alt',
  'rotate-forward',
  'route',
  'route-highway',
  'route-interstate',
  'router',
  'rss',
  'feed',
  'ruble-sign',
  'rouble',
  'rub',
  'ruble',
  'rugby-ball',
  'ruler',
  'ruler-combined',
  'ruler-horizontal',
  'ruler-triangle',
  'ruler-vertical',
  'rupee-sign',
  'rupee',
  'rupiah-sign',
  'rv',
  's',
  'sack',
  'sack-dollar',
  'sailboat',
  'salad',
  'bowl-salad',
  'salt-shaker',
  'sandwich',
  'satellite',
  'satellite-dish',
  'sausage',
  'saxophone',
  'saxophone-fire',
  'sax-hot',
  'scale-balanced',
  'balance-scale',
  'scale-unbalanced',
  'balance-scale-left',
  'scale-unbalanced-flip',
  'balance-scale-right',
  'scalpel',
  'scalpel-line-dashed',
  'scalpel-path',
  'scanner',
  'scanner-image',
  'scanner-gun',
  'scanner-keyboard',
  'scanner-touchscreen',
  'scarecrow',
  'scarf',
  'school',
  'scissors',
  'cut',
  'screen-users',
  'users-class',
  'screencast',
  'screwdriver',
  'screwdriver-wrench',
  'tools',
  'scribble',
  'scroll',
  'scroll-old',
  'scroll-torah',
  'torah',
  'scrubber',
  'scythe',
  'sd-card',
  'sd-cards',
  'seal',
  'seal-exclamation',
  'seal-question',
  'seat-airline',
  'section',
  'seedling',
  'sprout',
  'semicolon',
  'send-back',
  'send-backward',
  'sensor',
  'sensor-cloud',
  'sensor-smoke',
  'sensor-fire',
  'sensor-on',
  'sensor-triangle-exclamation',
  'sensor-alert',
  'server',
  'shapes',
  'triangle-circle-square',
  'share',
  'arrow-turn-right',
  'mail-forward',
  'share-all',
  'arrows-turn-right',
  'share-from-square',
  'share-square',
  'share-nodes',
  'share-alt',
  'sheep',
  'shekel-sign',
  'ils',
  'shekel',
  'sheqel',
  'sheqel-sign',
  'shelves',
  'inventory',
  'shelves-empty',
  'shield',
  'shield-blank',
  'shield-alt',
  'shield-check',
  'shield-cross',
  'shield-exclamation',
  'shield-keyhole',
  'shield-minus',
  'shield-plus',
  'shield-slash',
  'shield-virus',
  'shield-xmark',
  'shield-times',
  'ship',
  'shirt',
  't-shirt',
  'tshirt',
  'shirt-long-sleeve',
  'shirt-running',
  'shirt-tank-top',
  'shish-kebab',
  'shoe-prints',
  'shop',
  'store-alt',
  'shop-lock',
  'shop-slash',
  'store-alt-slash',
  'shovel',
  'shovel-snow',
  'shower',
  'shower-down',
  'shower-alt',
  'shredder',
  'shrimp',
  'shuffle',
  'random',
  'shutters',
  'shuttle-space',
  'space-shuttle',
  'shuttlecock',
  'sickle',
  'sidebar',
  'sidebar-flip',
  'sigma',
  'sign-hanging',
  'sign',
  'signal',
  'signal5',
  'signal-perfect',
  'signal-bars',
  'signal-alt',
  'signal-alt4',
  'signal-bars-strong',
  'signal-bars-fair',
  'signal-alt2',
  'signal-bars-good',
  'signal-alt3',
  'signal-bars-slash',
  'signal-alt-slash',
  'signal-bars-weak',
  'signal-alt1',
  'signal-fair',
  'signal2',
  'signal-good',
  'signal3',
  'signal-slash',
  'signal-stream',
  'signal-stream-slash',
  'signal-strong',
  'signal4',
  'signal-weak',
  'signal1',
  'signature',
  'signature-lock',
  'signature-slash',
  'signs-post',
  'map-signs',
  'sim-card',
  'sim-cards',
  'sink',
  'siren',
  'siren-on',
  'sitemap',
  'skeleton',
  'ski-boot',
  'ski-boot-ski',
  'skull',
  'skull-cow',
  'skull-crossbones',
  'slash',
  'slash-back',
  'slash-forward',
  'sleigh',
  'slider',
  'sliders',
  'sliders-h',
  'sliders-simple',
  'sliders-up',
  'sliders-v',
  'slot-machine',
  'smog',
  'smoke',
  'smoking',
  'snake',
  'snooze',
  'zzz',
  'snow-blowing',
  'snowflake',
  'snowflakes',
  'snowman',
  'snowman-head',
  'frosty-head',
  'snowplow',
  'soap',
  'socks',
  'soft-serve',
  'creemee',
  'solar-panel',
  'solar-system',
  'sort',
  'unsorted',
  'sort-down',
  'sort-desc',
  'sort-up',
  'sort-asc',
  'spa',
  'space-station-moon',
  'space-station-moon-construction',
  'space-station-moon-alt',
  'spade',
  'spaghetti-monster-flying',
  'pastafarianism',
  'sparkles',
  'speaker',
  'speakers',
  'spell-check',
  'spider',
  'spider-black-widow',
  'spider-web',
  'spinner',
  'spinner-third',
  'split',
  'splotch',
  'spoon',
  'utensil-spoon',
  'sportsball',
  'spray-can',
  'spray-can-sparkles',
  'air-freshener',
  'sprinkler',
  'sprinkler-ceiling',
  'square',
  'square-0',
  'square-1',
  'square-2',
  'square-3',
  'square-4',
  'square-5',
  'square-6',
  'square-7',
  'square-8',
  'square-9',
  'square-a',
  'square-a-lock',
  'square-ampersand',
  'square-arrow-down',
  'arrow-square-down',
  'square-arrow-down-left',
  'square-arrow-down-right',
  'square-arrow-left',
  'arrow-square-left',
  'square-arrow-right',
  'arrow-square-right',
  'square-arrow-up',
  'arrow-square-up',
  'square-arrow-up-left',
  'square-arrow-up-right',
  'external-link-square',
  'square-b',
  'square-bolt',
  'square-c',
  'square-caret-down',
  'caret-square-down',
  'square-caret-left',
  'caret-square-left',
  'square-caret-right',
  'caret-square-right',
  'square-caret-up',
  'caret-square-up',
  'square-check',
  'check-square',
  'square-chevron-down',
  'chevron-square-down',
  'square-chevron-left',
  'chevron-square-left',
  'square-chevron-right',
  'chevron-square-right',
  'square-chevron-up',
  'chevron-square-up',
  'square-code',
  'square-d',
  'square-dashed',
  'square-divide',
  'square-dollar',
  'dollar-square',
  'usd-square',
  'square-down',
  'arrow-alt-square-down',
  'square-down-left',
  'square-down-right',
  'square-e',
  'square-ellipsis',
  'square-ellipsis-vertical',
  'square-envelope',
  'envelope-square',
  'square-exclamation',
  'exclamation-square',
  'square-f',
  'square-fragile',
  'box-fragile',
  'square-wine-glass-crack',
  'square-full',
  'square-g',
  'square-h',
  'h-square',
  'square-heart',
  'heart-square',
  'square-i',
  'square-info',
  'info-square',
  'square-j',
  'square-k',
  'square-kanban',
  'square-l',
  'square-left',
  'arrow-alt-square-left',
  'square-list',
  'square-m',
  'square-minus',
  'minus-square',
  'square-n',
  'square-o',
  'square-p',
  'square-parking',
  'parking',
  'square-parking-slash',
  'parking-slash',
  'square-pen',
  'pen-square',
  'pencil-square',
  'square-phone',
  'phone-square',
  'square-phone-flip',
  'phone-square-alt',
  'square-phone-hangup',
  'phone-square-down',
  'square-plus',
  'plus-square',
  'square-poll-horizontal',
  'poll-h',
  'square-poll-vertical',
  'poll',
  'square-q',
  'square-quarters',
  'square-question',
  'question-square',
  'square-quote',
  'square-r',
  'square-right',
  'arrow-alt-square-right',
  'square-ring',
  'square-root',
  'square-root-variable',
  'square-root-alt',
  'square-rss',
  'rss-square',
  'square-s',
  'square-share-nodes',
  'share-alt-square',
  'square-sliders',
  'sliders-h-square',
  'square-sliders-vertical',
  'sliders-v-square',
  'square-small',
  'square-star',
  'square-t',
  'square-terminal',
  'square-this-way-up',
  'box-up',
  'square-u',
  'square-up',
  'arrow-alt-square-up',
  'square-up-left',
  'square-up-right',
  'external-link-square-alt',
  'square-user',
  'square-v',
  'square-w',
  'square-x',
  'square-xmark',
  'times-square',
  'xmark-square',
  'square-y',
  'square-z',
  'squid',
  'squirrel',
  'staff',
  'stairs',
  'stamp',
  'standard-definition',
  'rectangle-sd',
  'star',
  'star-and-crescent',
  'star-christmas',
  'star-exclamation',
  'star-half',
  'star-half-stroke',
  'star-half-alt',
  'star-of-david',
  'star-of-life',
  'star-sharp',
  'star-sharp-half',
  'star-sharp-half-stroke',
  'star-sharp-half-alt',
  'star-shooting',
  'starfighter',
  'starfighter-twin-ion-engine',
  'starfighter-alt',
  'starfighter-twin-ion-engine-advanced',
  'starfighter-alt-advanced',
  'stars',
  'starship',
  'starship-freighter',
  'steak',
  'steering-wheel',
  'sterling-sign',
  'gbp',
  'pound-sign',
  'stethoscope',
  'stocking',
  'stomach',
  'stop',
  'stopwatch',
  'stopwatch-20',
  'store',
  'store-lock',
  'store-slash',
  'strawberry',
  'street-view',
  'stretcher',
  'strikethrough',
  'stroopwafel',
  'subscript',
  'suitcase',
  'suitcase-medical',
  'medkit',
  'suitcase-rolling',
  'sun',
  'sun-bright',
  'sun-alt',
  'sun-cloud',
  'sun-dust',
  'sun-haze',
  'sunglasses',
  'sunrise',
  'sunset',
  'superscript',
  'sushi',
  'nigiri',
  'sushi-roll',
  'maki-roll',
  'makizushi',
  'swatchbook',
  'sword',
  'sword-laser',
  'sword-laser-alt',
  'swords',
  'swords-laser',
  'symbols',
  'icons-alt',
  'synagogue',
  'syringe',
  't',
  'table',
  'table-cells',
  'th',
  'table-cells-large',
  'th-large',
  'table-columns',
  'columns',
  'table-layout',
  'table-list',
  'th-list',
  'table-picnic',
  'table-pivot',
  'table-rows',
  'rows',
  'table-tennis-paddle-ball',
  'ping-pong-paddle-ball',
  'table-tennis',
  'table-tree',
  'tablet',
  'tablet-android',
  'tablet-button',
  'tablet-rugged',
  'tablet-screen',
  'tablet-android-alt',
  'tablet-screen-button',
  'tablet-alt',
  'tablets',
  'tachograph-digital',
  'digital-tachograph',
  'taco',
  'tag',
  'tags',
  'tally',
  'tally5',
  'tally-1',
  'tally-2',
  'tally-3',
  'tally-4',
  'tamale',
  'tank-water',
  'tape',
  'taxi',
  'cab',
  'taxi-bus',
  'teddy-bear',
  'teeth',
  'teeth-open',
  'telescope',
  'temperature-arrow-down',
  'temperature-down',
  'temperature-arrow-up',
  'temperature-up',
  'temperature-empty',
  'temperature0',
  'thermometer0',
  'thermometer-empty',
  'temperature-full',
  'temperature4',
  'thermometer4',
  'thermometer-full',
  'temperature-half',
  'temperature2',
  'thermometer2',
  'thermometer-half',
  'temperature-high',
  'temperature-list',
  'temperature-low',
  'temperature-quarter',
  'temperature1',
  'thermometer1',
  'thermometer-quarter',
  'temperature-snow',
  'temperature-frigid',
  'temperature-sun',
  'temperature-hot',
  'temperature-three-quarters',
  'temperature3',
  'thermometer3',
  'thermometer-three-quarters',
  'tenge-sign',
  'tenge',
  'tennis-ball',
  'terminal',
  'text',
  'text-height',
  'text-size',
  'text-slash',
  'remove-format',
  'text-width',
  'thermometer',
  'theta',
  'thought-bubble',
  'thumbs-down',
  'thumbs-up',
  'thumbtack',
  'thumb-tack',
  'tick',
  'ticket',
  'ticket-airline',
  'ticket-simple',
  'ticket-alt',
  'tickets-airline',
  'tilde',
  'timeline',
  'timeline-arrow',
  'timer',
  'tire',
  'tire-flat',
  'tire-pressure-warning',
  'tire-rugged',
  'toggle-off',
  'toggle-on',
  'toilet',
  'toilet-paper',
  'toilet-paper-blank',
  'toilet-paper-alt',
  'toilet-paper-blank-under',
  'toilet-paper-reverse-alt',
  'toilet-paper-slash',
  'toilet-paper-under',
  'toilet-paper-reverse',
  'toilet-paper-under-slash',
  'toilet-paper-reverse-slash',
  'tomato',
  'tombstone',
  'tombstone-blank',
  'tombstone-alt',
  'toolbox',
  'tooth',
  'toothbrush',
  'torii-gate',
  'tornado',
  'tower-broadcast',
  'broadcast-tower',
  'tower-control',
  'tractor',
  'trademark',
  'traffic-cone',
  'traffic-light',
  'traffic-light-go',
  'traffic-light-slow',
  'traffic-light-stop',
  'trailer',
  'train',
  'train-subway',
  'subway',
  'train-subway-tunnel',
  'subway-tunnel',
  'train-track',
  'train-tram',
  'tram',
  'train-tunnel',
  'transformer-bolt',
  'transgender',
  'transgender-alt',
  'transporter',
  'transporter-1',
  'transporter-2',
  'transporter-3',
  'transporter-4',
  'transporter-5',
  'transporter-6',
  'transporter-7',
  'transporter-empty',
  'trash',
  'trash-arrow-up',
  'trash-restore',
  'trash-can',
  'trash-alt',
  'trash-can-arrow-up',
  'trash-restore-alt',
  'trash-can-check',
  'trash-can-clock',
  'trash-can-list',
  'trash-can-plus',
  'trash-can-slash',
  'trash-alt-slash',
  'trash-can-undo',
  'trash-can-arrow-turn-left',
  'trash-undo-alt',
  'trash-can-xmark',
  'trash-check',
  'trash-clock',
  'trash-list',
  'trash-plus',
  'trash-slash',
  'trash-undo',
  'trash-arrow-turn-left',
  'trash-xmark',
  'treasure-chest',
  'tree',
  'tree-christmas',
  'tree-deciduous',
  'tree-alt',
  'tree-decorated',
  'tree-large',
  'tree-palm',
  'trees',
  'triangle',
  'triangle-exclamation',
  'exclamation-triangle',
  'warning',
  'triangle-instrument',
  'triangle-music',
  'triangle-person-digging',
  'construction',
  'trophy',
  'trophy-star',
  'trophy-alt',
  'truck',
  'truck-bolt',
  'truck-clock',
  'shipping-timed',
  'truck-container',
  'truck-container-empty',
  'truck-fast',
  'shipping-fast',
  'truck-flatbed',
  'truck-front',
  'truck-medical',
  'ambulance',
  'truck-monster',
  'truck-moving',
  'truck-pickup',
  'truck-plow',
  'truck-ramp',
  'truck-ramp-box',
  'truck-loading',
  'truck-ramp-couch',
  'truck-couch',
  'truck-tow',
  'trumpet',
  'tty',
  'teletype',
  'tty-answer',
  'teletype-answer',
  'tugrik-sign',
  'turkey',
  'turkish-lira-sign',
  'try',
  'turkish-lira',
  'turn-down',
  'level-down-alt',
  'turn-down-left',
  'turn-down-right',
  'turn-up',
  'level-up-alt',
  'turntable',
  'turtle',
  'tv',
  'television',
  'tv-alt',
  'tv-music',
  'tv-retro',
  'typewriter',
  'u',
  'ufo',
  'ufo-beam',
  'umbrella',
  'umbrella-beach',
  'umbrella-simple',
  'umbrella-alt',
  'underline',
  'unicorn',
  'uniform-martial-arts',
  'union',
  'universal-access',
  'unlock',
  'unlock-keyhole',
  'unlock-alt',
  'up',
  'arrow-alt-up',
  'up-down',
  'arrows-alt-v',
  'up-down-left-right',
  'arrows-alt',
  'up-from-dotted-line',
  'up-from-line',
  'arrow-alt-from-bottom',
  'up-left',
  'up-long',
  'long-arrow-alt-up',
  'up-right',
  'up-right-and-down-left-from-center',
  'expand-alt',
  'up-right-from-square',
  'external-link-alt',
  'up-to-dotted-line',
  'up-to-line',
  'arrow-alt-to-top',
  'upload',
  'usb-drive',
  'user',
  'user-alien',
  'user-astronaut',
  'user-bounty-hunter',
  'user-check',
  'user-chef',
  'user-clock',
  'user-cowboy',
  'user-crown',
  'user-doctor',
  'user-md',
  'user-doctor-hair',
  'user-doctor-hair-long',
  'user-doctor-message',
  'user-md-chat',
  'user-gear',
  'user-cog',
  'user-graduate',
  'user-group',
  'user-friends',
  'user-group-crown',
  'users-crown',
  'user-hair',
  'user-hair-buns',
  'user-hair-long',
  'user-hair-mullet',
  'business-front',
  'party-back',
  'trian-balbot',
  'user-headset',
  'user-helmet-safety',
  'user-construction',
  'user-hard-hat',
  'user-injured',
  'user-large',
  'user-alt',
  'user-large-slash',
  'user-alt-slash',
  'user-lock',
  'user-minus',
  'user-music',
  'user-ninja',
  'user-nurse',
  'user-nurse-hair',
  'user-nurse-hair-long',
  'user-pen',
  'user-edit',
  'user-pilot',
  'user-pilot-tie',
  'user-plus',
  'user-police',
  'user-police-tie',
  'user-robot',
  'user-robot-xmarks',
  'user-secret',
  'user-shakespeare',
  'user-shield',
  'user-slash',
  'user-tag',
  'user-tie',
  'user-tie-hair',
  'user-tie-hair-long',
  'user-unlock',
  'user-visor',
  'user-vneck',
  'user-vneck-hair',
  'user-vneck-hair-long',
  'user-xmark',
  'user-times',
  'users',
  'users-gear',
  'users-cog',
  'users-medical',
  'users-slash',
  'utensils',
  'cutlery',
  'utensils-slash',
  'utility-pole',
  'utility-pole-double',
  'v',
  'vacuum',
  'vacuum-robot',
  'value-absolute',
  'van-shuttle',
  'shuttle-van',
  'vault',
  'vector-circle',
  'vector-polygon',
  'vector-square',
  'vent-damper',
  'venus',
  'venus-double',
  'venus-mars',
  'vest',
  'vest-patches',
  'vial',
  'vials',
  'video',
  'video-camera',
  'video-arrow-down-left',
  'video-arrow-up-right',
  'video-plus',
  'video-slash',
  'vihara',
  'violin',
  'virus',
  'virus-covid',
  'virus-covid-slash',
  'virus-slash',
  'viruses',
  'voicemail',
  'volcano',
  'volleyball',
  'volleyball-ball',
  'volume',
  'volume-medium',
  'volume-high',
  'volume-up',
  'volume-low',
  'volume-down',
  'volume-off',
  'volume-slash',
  'volume-xmark',
  'volume-mute',
  'volume-times',
  'vr-cardboard',
  'w',
  'waffle',
  'wagon-covered',
  'walker',
  'walkie-talkie',
  'wallet',
  'wand',
  'wand-magic',
  'magic',
  'wand-magic-sparkles',
  'magic-wand-sparkles',
  'wand-sparkles',
  'warehouse',
  'warehouse-full',
  'warehouse-alt',
  'washing-machine',
  'washer',
  'watch',
  'watch-apple',
  'watch-calculator',
  'watch-fitness',
  'watch-smart',
  'water',
  'water-arrow-down',
  'water-lower',
  'water-arrow-up',
  'water-rise',
  'water-ladder',
  'ladder-water',
  'swimming-pool',
  'watermelon-slice',
  'wave-pulse',
  'heart-rate',
  'wave-sine',
  'wave-square',
  'wave-triangle',
  'waveform',
  'waveform-lines',
  'waveform-path',
  'weight-hanging',
  'weight-scale',
  'weight',
  'whale',
  'wheat',
  'wheat-awn',
  'wheat-alt',
  'wheat-awn-slash',
  'wheat-slash',
  'wheelchair',
  'wheelchair-move',
  'wheelchair-alt',
  'whiskey-glass',
  'glass-whiskey',
  'whiskey-glass-ice',
  'glass-whiskey-rocks',
  'whistle',
  'wifi',
  'wifi3',
  'wifi-strong',
  'wifi-exclamation',
  'wifi-fair',
  'wifi2',
  'wifi-slash',
  'wifi-weak',
  'wifi1',
  'wind',
  'wind-turbine',
  'wind-warning',
  'wind-circle-exclamation',
  'window',
  'window-flip',
  'window-alt',
  'window-frame',
  'window-frame-open',
  'window-maximize',
  'window-minimize',
  'window-restore',
  'windsock',
  'wine-bottle',
  'wine-glass',
  'wine-glass-crack',
  'fragile',
  'wine-glass-empty',
  'wine-glass-alt',
  'won-sign',
  'krw',
  'won',
  'wreath',
  'wrench',
  'wrench-simple',
  'x',
  'x-ray',
  'xmark',
  'close',
  'multiply',
  'remove',
  'times',
  'xmark-to-slot',
  'times-to-slot',
  'vote-nay',
  'y',
  'yen-sign',
  'cny',
  'jpy',
  'rmb',
  'yen',
  'yin-yang',
  'z',
  '0',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '00',
  '360-degrees',
  'a',
  'abacus',
  'accent-grave',
  'acorn',
  'address-book',
  'contact-book',
  'address-card',
  'contact-card',
  'vcard',
  'air-conditioner',
  'airplay',
  'alarm-clock',
  'alarm-exclamation',
  'alarm-plus',
  'alarm-snooze',
  'album',
  'album-circle-plus',
  'album-circle-user',
  'album-collection',
  'album-collection-circle-plus',
  'album-collection-circle-user',
  'alicorn',
  'alien',
  'alien-8bit',
  'alien-monster',
  'align-center',
  'align-justify',
  'align-left',
  'align-right',
  'align-slash',
  'alt',
  'amp-guitar',
  'ampersand',
  'anchor',
  'angel',
  'angle',
  'angle-90',
  'angle-down',
  'angle-left',
  'angle-right',
  'angle-up',
  'angles-down',
  'angle-double-down',
  'angles-left',
  'angle-double-left',
  'angles-right',
  'angle-double-right',
  'angles-up',
  'angle-double-up',
  'ankh',
  'apartment',
  'aperture',
  'apostrophe',
  'apple-core',
  'apple-whole',
  'apple-alt',
  'archway',
  'arrow-down',
  'arrow-down-1-9',
  'sort-numeric-asc',
  'sort-numeric-down',
  'arrow-down-9-1',
  'sort-numeric-desc',
  'sort-numeric-down-alt',
  'arrow-down-a-z',
  'sort-alpha-asc',
  'sort-alpha-down',
  'arrow-down-arrow-up',
  'sort-alt',
  'arrow-down-big-small',
  'sort-size-down',
  'arrow-down-from-dotted-line',
  'arrow-down-from-line',
  'arrow-from-top',
  'arrow-down-left',
  'arrow-down-left-and-arrow-up-right-to-center',
  'arrow-down-long',
  'long-arrow-down',
  'arrow-down-right',
  'arrow-down-short-wide',
  'sort-amount-desc',
  'sort-amount-down-alt',
  'arrow-down-small-big',
  'sort-size-down-alt',
  'arrow-down-square-triangle',
  'sort-shapes-down-alt',
  'arrow-down-to-bracket',
  'arrow-down-to-dotted-line',
  'arrow-down-to-line',
  'arrow-to-bottom',
  'arrow-down-to-square',
  'arrow-down-triangle-square',
  'sort-shapes-down',
  'arrow-down-wide-short',
  'sort-amount-asc',
  'sort-amount-down',
  'arrow-down-z-a',
  'sort-alpha-desc',
  'sort-alpha-down-alt',
  'arrow-left',
  'arrow-left-from-line',
  'arrow-from-right',
  'arrow-left-long',
  'long-arrow-left',
  'arrow-left-long-to-line',
  'arrow-left-to-line',
  'arrow-to-left',
  'arrow-pointer',
  'mouse-pointer',
  'arrow-right',
  'arrow-right-arrow-left',
  'exchange',
  'arrow-right-from-bracket',
  'sign-out',
  'arrow-right-from-line',
  'arrow-from-left',
  'arrow-right-long',
  'long-arrow-right',
  'arrow-right-long-to-line',
  'arrow-right-to-bracket',
  'sign-in',
  'arrow-right-to-line',
  'arrow-to-right',
  'arrow-rotate-left',
  'arrow-left-rotate',
  'arrow-rotate-back',
  'arrow-rotate-backward',
  'undo',
  'arrow-rotate-right',
  'arrow-right-rotate',
  'arrow-rotate-forward',
  'redo',
  'arrow-trend-down',
  'arrow-trend-up',
  'arrow-turn-down',
  'level-down',
  'arrow-turn-down-left',
  'arrow-turn-down-right',
  'arrow-turn-up',
  'level-up',
  'arrow-up',
  'arrow-up-1-9',
  'sort-numeric-up',
  'arrow-up-9-1',
  'sort-numeric-up-alt',
  'arrow-up-a-z',
  'sort-alpha-up',
  'arrow-up-arrow-down',
  'sort-up-down',
  'arrow-up-big-small',
  'sort-size-up',
  'arrow-up-from-bracket',
  'arrow-up-from-dotted-line',
  'arrow-up-from-line',
  'arrow-from-bottom',
  'arrow-up-from-square',
  'arrow-up-left',
  'arrow-up-left-from-circle',
  'arrow-up-long',
  'long-arrow-up',
  'arrow-up-right',
  'arrow-up-right-and-arrow-down-left-from-center',
  'arrow-up-right-from-square',
  'external-link',
  'arrow-up-short-wide',
  'sort-amount-up-alt',
  'arrow-up-small-big',
  'sort-size-up-alt',
  'arrow-up-square-triangle',
  'sort-shapes-up-alt',
  'arrow-up-to-dotted-line',
  'arrow-up-to-line',
  'arrow-to-top',
  'arrow-up-triangle-square',
  'sort-shapes-up',
  'arrow-up-wide-short',
  'sort-amount-up',
  'arrow-up-z-a',
  'sort-alpha-up-alt',
  'arrows-cross',
  'arrows-from-dotted-line',
  'arrows-from-line',
  'arrows-left-right',
  'arrows-h',
  'arrows-maximize',
  'expand-arrows',
  'arrows-minimize',
  'compress-arrows',
  'arrows-repeat',
  'repeat-alt',
  'arrows-repeat-1',
  'repeat1-alt',
  'arrows-retweet',
  'retweet-alt',
  'arrows-rotate',
  'refresh',
  'sync',
  'arrows-to-dotted-line',
  'arrows-to-line',
  'arrows-up-down',
  'arrows-v',
  'arrows-up-down-left-right',
  'arrows',
  'asterisk',
  'at',
  'atom',
  'atom-simple',
  'atom-alt',
  'audio-description',
  'audio-description-slash',
  'austral-sign',
  'avocado',
  'award',
  'award-simple',
  'axe',
  'axe-battle',
  'b',
  'baby',
  'baby-carriage',
  'carriage-baby',
  'backpack',
  'backward',
  'backward-fast',
  'fast-backward',
  'backward-step',
  'step-backward',
  'bacon',
  'bacteria',
  'bacterium',
  'badge',
  'badge-check',
  'badge-dollar',
  'badge-percent',
  'badge-sheriff',
  'badger-honey',
  'badminton',
  'bag-shopping',
  'shopping-bag',
  'bagel',
  'bags-shopping',
  'baguette',
  'bahai',
  'baht-sign',
  'ball-pile',
  'balloon',
  'balloons',
  'ballot',
  'ballot-check',
  'ban',
  'cancel',
  'ban-bug',
  'debug',
  'ban-parking',
  'parking-circle-slash',
  'ban-smoking',
  'smoking-ban',
  'banana',
  'bandage',
  'band-aid',
  'bangladeshi-taka-sign',
  'banjo',
  'barcode',
  'barcode-read',
  'barcode-scan',
  'bars',
  'navicon',
  'bars-filter',
  'bars-progress',
  'tasks-alt',
  'bars-sort',
  'bars-staggered',
  'reorder',
  'stream',
  'baseball',
  'baseball-ball',
  'baseball-bat-ball',
  'basket-shopping',
  'shopping-basket',
  'basket-shopping-simple',
  'shopping-basket-alt',
  'basketball',
  'basketball-ball',
  'basketball-hoop',
  'bat',
  'bath',
  'bathtub',
  'battery-bolt',
  'battery-empty',
  'battery0',
  'battery-exclamation',
  'battery-full',
  'battery',
  'battery5',
  'battery-half',
  'battery3',
  'battery-low',
  'battery1',
  'battery-quarter',
  'battery2',
  'battery-slash',
  'battery-three-quarters',
  'battery4',
  'bed',
  'bed-bunk',
  'bed-empty',
  'bed-front',
  'bed-alt',
  'bed-pulse',
  'procedures',
  'bee',
  'beer-mug',
  'beer-foam',
  'beer-mug-empty',
  'beer',
  'bell',
  'bell-concierge',
  'concierge-bell',
  'bell-exclamation',
  'bell-on',
  'bell-plus',
  'bell-school',
  'bell-school-slash',
  'bell-slash',
  'bells',
  'bench-tree',
  'bezier-curve',
  'bicycle',
  'binary',
  'binary-circle-check',
  'binary-lock',
  'binary-slash',
  'binoculars',
  'biohazard',
  'bird',
  'bitcoin-sign',
  'blanket',
  'blanket-fire',
  'blender',
  'blender-phone',
  'blinds',
  'blinds-open',
  'blinds-raised',
  'block',
  'block-brick',
  'wall-brick',
  'block-brick-fire',
  'firewall',
  'block-question',
  'block-quote',
  'blog',
  'blueberries',
  'bluetooth',
  'bold',
  'bolt',
  'zap',
  'bolt-auto',
  'bolt-lightning',
  'bolt-slash',
  'bomb',
  'bone',
  'bone-break',
  'bong',
  'book',
  'book-arrow-right',
  'book-arrow-up',
  'book-atlas',
  'atlas',
  'book-bible',
  'bible',
  'book-blank',
  'book-alt',
  'book-bookmark',
  'book-circle-arrow-right',
  'book-circle-arrow-up',
  'book-copy',
  'book-font',
  'book-heart',
  'book-journal-whills',
  'journal-whills',
  'book-medical',
  'book-open',
  'book-open-cover',
  'book-open-alt',
  'book-open-reader',
  'book-reader',
  'book-quran',
  'quran',
  'book-section',
  'book-law',
  'book-skull',
  'book-dead',
  'book-sparkles',
  'book-spells',
  'book-tanakh',
  'tanakh',
  'book-user',
  'bookmark',
  'bookmark-slash',
  'books',
  'books-medical',
  'boombox',
  'boot',
  'boot-heeled',
  'booth-curtain',
  'border-all',
  'border-bottom',
  'border-bottom-right',
  'border-style-alt',
  'border-center-h',
  'border-center-v',
  'border-inner',
  'border-left',
  'border-none',
  'border-outer',
  'border-right',
  'border-top',
  'border-top-left',
  'border-style',
  'bow-arrow',
  'bowl-chopsticks',
  'bowl-chopsticks-noodles',
  'bowl-hot',
  'soup',
  'bowl-rice',
  'bowl-scoop',
  'bowl-shaved-ice',
  'bowl-scoops',
  'bowl-soft-serve',
  'bowl-spoon',
  'bowling-ball',
  'bowling-ball-pin',
  'bowling-pins',
  'box',
  'box-archive',
  'archive',
  'box-ballot',
  'box-check',
  'box-circle-check',
  'box-dollar',
  'box-usd',
  'box-heart',
  'box-open',
  'box-open-full',
  'box-full',
  'box-taped',
  'box-alt',
  'box-tissue',
  'boxes-stacked',
  'boxes',
  'boxes-alt',
  'boxing-glove',
  'glove-boxing',
  'bracket-curly',
  'bracket-curly-left',
  'bracket-curly-right',
  'bracket-round',
  'parenthesis',
  'bracket-round-right',
  'bracket-square',
  'bracket',
  'bracket-left',
  'bracket-square-right',
  'brackets-curly',
  'brackets-round',
  'parentheses',
  'brackets-square',
  'brackets',
  'braille',
  'brain',
  'brain-arrow-curved-right',
  'mind-share',
  'brain-circuit',
  'brake-warning',
  'brazilian-real-sign',
  'bread-loaf',
  'bread-slice',
  'bread-slice-butter',
  'briefcase',
  'briefcase-arrow-right',
  'briefcase-blank',
  'briefcase-medical',
  'brightness',
  'brightness-low',
  'bring-forward',
  'bring-front',
  'broccoli',
  'broom',
  'broom-ball',
  'quidditch',
  'quidditch-broom-ball',
  'browser',
  'browsers',
  'brush',
  'bug',
  'bug-slash',
  'building',
  'building-columns',
  'bank',
  'institution',
  'museum',
  'university',
  'buildings',
  'bullhorn',
  'bullseye',
  'bullseye-arrow',
  'bullseye-pointer',
  'burger',
  'hamburger',
  'burger-cheese',
  'cheeseburger',
  'burger-fries',
  'burger-glass',
  'burger-lettuce',
  'burger-soda',
  'burrito',
  'bus',
  'bus-school',
  'bus-simple',
  'bus-alt',
  'business-time',
  'briefcase-clock',
  'butter',
  'c',
  'cabin',
  'cabinet-filing',
  'cable-car',
  'cactus',
  'cake-candles',
  'birthday-cake',
  'cake',
  'cake-slice',
  'shortcake',
  'calculator',
  'calculator-simple',
  'calculator-alt',
  'calendar',
  'calendar-arrow-down',
  'calendar-download',
  'calendar-arrow-up',
  'calendar-upload',
  'calendar-check',
  'calendar-circle-exclamation',
  'calendar-circle-minus',
  'calendar-circle-plus',
  'calendar-circle-user',
  'calendar-clock',
  'calendar-time',
  'calendar-day',
  'calendar-days',
  'calendar-alt',
  'calendar-exclamation',
  'calendar-heart',
  'calendar-image',
  'calendar-lines',
  'calendar-note',
  'calendar-lines-pen',
  'calendar-minus',
  'calendar-pen',
  'calendar-edit',
  'calendar-plus',
  'calendar-range',
  'calendar-star',
  'calendar-week',
  'calendar-xmark',
  'calendar-times',
  'calendars',
  'camcorder',
  'video-handheld',
  'camera',
  'camera-alt',
  'camera-cctv',
  'cctv',
  'camera-movie',
  'camera-polaroid',
  'camera-retro',
  'camera-rotate',
  'camera-security',
  'camera-home',
  'camera-slash',
  'camera-viewfinder',
  'screenshot',
  'camera-web',
  'webcam',
  'camera-web-slash',
  'webcam-slash',
  'campfire',
  'campground',
  'can-food',
  'candle-holder',
  'candy',
  'candy-bar',
  'chocolate-bar',
  'candy-cane',
  'candy-corn',
  'cannabis',
  'capsules',
  'car',
  'automobile',
  'car-battery',
  'battery-car',
  'car-bolt',
  'car-building',
  'car-bump',
  'car-bus',
  'car-circle-bolt',
  'car-crash',
  'car-garage',
  'car-mirrors',
  'car-rear',
  'car-alt',
  'car-side',
  'car-side-bolt',
  'car-tilt',
  'car-wash',
  'car-wrench',
  'car-mechanic',
  'caravan',
  'caravan-simple',
  'caravan-alt',
  'card-club',
  'card-diamond',
  'card-heart',
  'card-spade',
  'cards',
  'caret-down',
  'caret-left',
  'caret-right',
  'caret-up',
  'carrot',
  'cars',
  'cart-arrow-down',
  'cart-arrow-up',
  'cart-circle-arrow-down',
  'cart-circle-arrow-up',
  'cart-circle-check',
  'cart-circle-exclamation',
  'cart-circle-plus',
  'cart-circle-xmark',
  'cart-flatbed',
  'dolly-flatbed',
  'cart-flatbed-boxes',
  'dolly-flatbed-alt',
  'cart-flatbed-empty',
  'dolly-flatbed-empty',
  'cart-flatbed-suitcase',
  'luggage-cart',
  'cart-minus',
  'cart-plus',
  'cart-shopping',
  'shopping-cart',
  'cart-shopping-fast',
  'cart-xmark',
  'cash-register',
  'cassette-betamax',
  'betamax',
  'cassette-tape',
  'cassette-vhs',
  'vhs',
  'castle',
  'cat',
  'cat-space',
  'cauldron',
  'cedi-sign',
  'cent-sign',
  'certificate',
  'chair',
  'chair-office',
  'chalkboard',
  'blackboard',
  'chalkboard-user',
  'chalkboard-teacher',
  'champagne-glass',
  'glass-champagne',
  'champagne-glasses',
  'glass-cheers',
  'charging-station',
  'chart-area',
  'area-chart',
  'chart-bar',
  'bar-chart',
  'chart-bullet',
  'chart-candlestick',
  'chart-column',
  'chart-gantt',
  'chart-line',
  'line-chart',
  'chart-line-down',
  'chart-line-up',
  'chart-mixed',
  'analytics',
  'chart-network',
  'chart-pie',
  'pie-chart',
  'chart-pie-simple',
  'chart-pie-alt',
  'chart-pyramid',
  'chart-radar',
  'chart-scatter',
  'chart-scatter-3d',
  'chart-scatter-bubble',
  'chart-simple',
  'chart-simple-horizontal',
  'chart-tree-map',
  'chart-user',
  'user-chart',
  'chart-waterfall',
  'check',
  'check-double',
  'check-to-slot',
  'vote-yea',
  'cheese',
  'cheese-swiss',
  'cherries',
  'chess',
  'chess-bishop',
  'chess-bishop-piece',
  'chess-bishop-alt',
  'chess-board',
  'chess-clock',
  'chess-clock-flip',
  'chess-clock-alt',
  'chess-king',
  'chess-king-piece',
  'chess-king-alt',
  'chess-knight',
  'chess-knight-piece',
  'chess-knight-alt',
  'chess-pawn',
  'chess-pawn-piece',
  'chess-pawn-alt',
  'chess-queen',
  'chess-queen-piece',
  'chess-queen-alt',
  'chess-rook',
  'chess-rook-piece',
  'chess-rook-alt',
  'chestnut',
  'chevron-down',
  'chevron-left',
  'chevron-right',
  'chevron-up',
  'chevrons-down',
  'chevron-double-down',
  'chevrons-left',
  'chevron-double-left',
  'chevrons-right',
  'chevron-double-right',
  'chevrons-up',
  'chevron-double-up',
  'child',
  'chimney',
  'chopsticks',
  'church',
  'circle',
  'circle-0',
  'circle-1',
  'circle-2',
  'circle-3',
  'circle-4',
  'circle-5',
  'circle-6',
  'circle-7',
  'circle-8',
  'circle-9',
  'circle-a',
  'circle-ampersand',
  'circle-arrow-down',
  'arrow-circle-down',
  'circle-arrow-down-left',
  'circle-arrow-down-right',
  'circle-arrow-left',
  'arrow-circle-left',
  'circle-arrow-right',
  'arrow-circle-right',
  'circle-arrow-up',
  'arrow-circle-up',
  'circle-arrow-up-left',
  'circle-arrow-up-right',
  'circle-b',
  'circle-bolt',
  'circle-book-open',
  'book-circle',
  'circle-bookmark',
  'bookmark-circle',
  'circle-c',
  'circle-calendar',
  'calendar-circle',
  'circle-camera',
  'camera-circle',
  'circle-caret-down',
  'caret-circle-down',
  'circle-caret-left',
  'caret-circle-left',
  'circle-caret-right',
  'caret-circle-right',
  'circle-caret-up',
  'caret-circle-up',
  'circle-check',
  'check-circle',
  'circle-chevron-down',
  'chevron-circle-down',
  'circle-chevron-left',
  'chevron-circle-left',
  'circle-chevron-right',
  'chevron-circle-right',
  'circle-chevron-up',
  'chevron-circle-up',
  'circle-d',
  'circle-dashed',
  'circle-divide',
  'circle-dollar',
  'dollar-circle',
  'usd-circle',
  'circle-dollar-to-slot',
  'donate',
  'circle-dot',
  'dot-circle',
  'circle-down',
  'arrow-alt-circle-down',
  'circle-down-left',
  'circle-down-right',
  'circle-e',
  'circle-ellipsis',
  'circle-ellipsis-vertical',
  'circle-envelope',
  'envelope-circle',
  'circle-exclamation',
  'exclamation-circle',
  'circle-exclamation-check',
  'circle-f',
  'circle-g',
  'circle-h',
  'hospital-symbol',
  'circle-half',
  'circle-half-stroke',
  'adjust',
  'circle-heart',
  'heart-circle',
  'circle-i',
  'circle-info',
  'info-circle',
  'circle-j',
  'circle-k',
  'circle-l',
  'circle-left',
  'arrow-alt-circle-left',
  'circle-location-arrow',
  'location-circle',
  'circle-m',
  'circle-microphone',
  'microphone-circle',
  'circle-microphone-lines',
  'microphone-circle-alt',
  'circle-minus',
  'minus-circle',
  'circle-n',
  'circle-notch',
  'circle-o',
  'circle-p',
  'circle-parking',
  'parking-circle',
  'circle-pause',
  'pause-circle',
  'circle-phone',
  'phone-circle',
  'circle-phone-flip',
  'phone-circle-alt',
  'circle-phone-hangup',
  'phone-circle-down',
  'circle-play',
  'play-circle',
  'circle-plus',
  'plus-circle',
  'circle-q',
  'circle-quarter',
  'circle-quarters',
  'circle-question',
  'question-circle',
  'circle-r',
  'circle-radiation',
  'radiation-alt',
  'circle-right',
  'arrow-alt-circle-right',
  'circle-s',
  'circle-small',
  'circle-sort',
  'sort-circle',
  'circle-sort-down',
  'sort-circle-down',
  'circle-sort-up',
  'sort-circle-up',
  'circle-star',
  'star-circle',
  'circle-stop',
  'stop-circle',
  'circle-t',
  'circle-three-quarters',
  'circle-trash',
  'trash-circle',
  'circle-u',
  'circle-up',
  'arrow-alt-circle-up',
  'circle-up-left',
  'circle-up-right',
  'circle-user',
  'user-circle',
  'circle-v',
  'circle-video',
  'video-circle',
  'circle-w',
  'circle-waveform-lines',
  'waveform-circle',
  'circle-x',
  'circle-xmark',
  'times-circle',
  'xmark-circle',
  'circle-y',
  'circle-z',
  'citrus',
  'citrus-slice',
  'city',
  'clapperboard',
  'clapperboard-play',
  'clarinet',
  'claw-marks',
  'clipboard',
  'clipboard-check',
  'clipboard-list',
  'clipboard-list-check',
  'clipboard-medical',
  'clipboard-prescription',
  'clipboard-user',
  'clock',
  'clock-four',
  'clock-desk',
  'clock-eight',
  'clock-eight-thirty',
  'clock-eleven',
  'clock-eleven-thirty',
  'clock-five',
  'clock-five-thirty',
  'clock-four-thirty',
  'clock-nine',
  'clock-nine-thirty',
  'clock-one',
  'clock-one-thirty',
  'clock-rotate-left',
  'history',
  'clock-seven',
  'clock-seven-thirty',
  'clock-six',
  'clock-six-thirty',
  'clock-ten',
  'clock-ten-thirty',
  'clock-three',
  'clock-three-thirty',
  'clock-twelve',
  'clock-twelve-thirty',
  'clock-two',
  'clock-two-thirty',
  'clone',
  'closed-captioning',
  'closed-captioning-slash',
  'clothes-hanger',
  'cloud',
  'cloud-arrow-down',
  'cloud-download',
  'cloud-download-alt',
  'cloud-arrow-up',
  'cloud-upload',
  'cloud-upload-alt',
  'cloud-bolt',
  'thunderstorm',
  'cloud-bolt-moon',
  'thunderstorm-moon',
  'cloud-bolt-sun',
  'thunderstorm-sun',
  'cloud-check',
  'cloud-drizzle',
  'cloud-exclamation',
  'cloud-fog',
  'fog',
  'cloud-hail',
  'cloud-hail-mixed',
  'cloud-meatball',
  'cloud-minus',
  'cloud-moon',
  'cloud-moon-rain',
  'cloud-music',
  'cloud-plus',
  'cloud-question',
  'cloud-rain',
  'cloud-rainbow',
  'cloud-showers',
  'cloud-showers-heavy',
  'cloud-slash',
  'cloud-sleet',
  'cloud-snow',
  'cloud-sun',
  'cloud-sun-rain',
  'cloud-word',
  'cloud-xmark',
  'clouds',
  'clouds-moon',
  'clouds-sun',
  'clover',
  'club',
  'coconut',
  'code',
  'code-branch',
  'code-commit',
  'code-compare',
  'code-fork',
  'code-merge',
  'code-pull-request',
  'code-pull-request-closed',
  'code-pull-request-draft',
  'code-simple',
  'coffee-bean',
  'coffee-beans',
  'coffee-pot',
  'coffin',
  'coffin-cross',
  'coin',
  'coin-blank',
  'coin-front',
  'coin-vertical',
  'coins',
  'colon',
  'colon-sign',
  'columns-3',
  'comet',
  'comma',
  'command',
  'comment',
  'comment-arrow-down',
  'comment-arrow-up',
  'comment-arrow-up-right',
  'comment-captions',
  'comment-check',
  'comment-code',
  'comment-dollar',
  'comment-dots',
  'commenting',
  'comment-exclamation',
  'comment-image',
  'comment-lines',
  'comment-medical',
  'comment-middle',
  'comment-middle-top',
  'comment-minus',
  'comment-music',
  'comment-pen',
  'comment-edit',
  'comment-plus',
  'comment-question',
  'comment-quote',
  'comment-slash',
  'comment-smile',
  'comment-sms',
  'sms',
  'comment-text',
  'comment-xmark',
  'comment-times',
  'comments',
  'comments-dollar',
  'comments-question',
  'comments-question-check',
  'compact-disc',
  'compass',
  'compass-drafting',
  'drafting-compass',
  'compass-slash',
  'compress',
  'compress-wide',
  'computer-classic',
  'computer-mouse',
  'mouse',
  'computer-mouse-scrollwheel',
  'mouse-alt',
  'computer-speaker',
  'container-storage',
  'conveyor-belt',
  'conveyor-belt-boxes',
  'conveyor-belt-alt',
  'conveyor-belt-empty',
  'cookie',
  'cookie-bite',
  'copy',
  'copyright',
  'corn',
  'corner',
  'couch',
  'cow',
  'cowbell',
  'cowbell-circle-plus',
  'cowbell-more',
  'crab',
  'crate-apple',
  'apple-crate',
  'crate-empty',
  'credit-card',
  'credit-card-alt',
  'credit-card-blank',
  'credit-card-front',
  'cricket-bat-ball',
  'cricket',
  'croissant',
  'crop',
  'crop-simple',
  'crop-alt',
  'cross',
  'crosshairs',
  'crow',
  'crown',
  'crutch',
  'crutches',
  'cruzeiro-sign',
  'crystal-ball',
  'cube',
  'cubes',
  'cucumber',
  'cup-straw',
  'cup-straw-swoosh',
  'cup-togo',
  'coffee-togo',
  'cupcake',
  'curling-stone',
  'curling',
  'custard',
  'd',
  'dagger',
  'dash',
  'database',
  'deer',
  'deer-rudolph',
  'delete-left',
  'backspace',
  'delete-right',
  'democrat',
  'desktop',
  'desktop-alt',
  'desktop-arrow-down',
  'dharmachakra',
  'diagram-cells',
  'diagram-lean-canvas',
  'diagram-nested',
  'diagram-next',
  'diagram-predecessor',
  'diagram-previous',
  'diagram-project',
  'project-diagram',
  'diagram-sankey',
  'diagram-subtask',
  'diagram-successor',
  'diagram-venn',
  'dial',
  'dial-med-high',
  'dial-high',
  'dial-low',
  'dial-max',
  'dial-med',
  'dial-med-low',
  'dial-min',
  'dial-off',
  'diamond',
  'diamond-exclamation',
  'diamond-turn-right',
  'directions',
  'dice',
  'dice-d10',
  'dice-d12',
  'dice-d20',
  'dice-d4',
  'dice-d6',
  'dice-d8',
  'dice-five',
  'dice-four',
  'dice-one',
  'dice-six',
  'dice-three',
  'dice-two',
  'diploma',
  'scroll-ribbon',
  'disc-drive',
  'disease',
  'display',
  'display-arrow-down',
  'display-code',
  'desktop-code',
  'display-medical',
  'desktop-medical',
  'display-slash',
  'desktop-slash',
  'distribute-spacing-horizontal',
  'distribute-spacing-vertical',
  'ditto',
  'divide',
  'dna',
  'do-not-enter',
  'dog',
  'dog-leashed',
  'dollar-sign',
  'dollar',
  'usd',
  'dolly',
  'dolly-box',
  'dolly-empty',
  'dolphin',
  'dong-sign',
  'donut',
  'doughnut',
  'door-closed',
  'door-open',
  'dove',
  'down',
  'arrow-alt-down',
  'down-from-dotted-line',
  'down-from-line',
  'arrow-alt-from-top',
  'down-left',
  'down-left-and-up-right-to-center',
  'compress-alt',
  'down-long',
  'long-arrow-alt-down',
  'down-right',
  'down-to-dotted-line',
  'down-to-line',
  'arrow-alt-to-bottom',
  'download',
  'dragon',
  'draw-circle',
  'draw-polygon',
  'draw-square',
  'dreidel',
  'drone',
  'drone-front',
  'drone-alt',
  'droplet',
  'tint',
  'droplet-degree',
  'dewpoint',
  'droplet-percent',
  'humidity',
  'droplet-slash',
  'tint-slash',
  'drum',
  'drum-steelpan',
  'drumstick',
  'drumstick-bite',
  'dryer',
  'dryer-heat',
  'dryer-alt',
  'duck',
  'dumbbell',
  'dumpster',
  'dumpster-fire',
  'dungeon',
  'e',
  'ear',
  'ear-deaf',
  'deaf',
  'deafness',
  'hard-of-hearing',
  'ear-listen',
  'assistive-listening-systems',
  'ear-muffs',
  'earth-africa',
  'globe-africa',
  'earth-americas',
  'earth',
  'earth-america',
  'globe-americas',
  'earth-asia',
  'globe-asia',
  'earth-europe',
  'globe-europe',
  'earth-oceania',
  'globe-oceania',
  'eclipse',
  'egg',
  'egg-fried',
  'eggplant',
  'eject',
  'elephant',
  'elevator',
  'ellipsis',
  'ellipsis-h',
  'ellipsis-stroke',
  'ellipsis-h-alt',
  'ellipsis-stroke-vertical',
  'ellipsis-v-alt',
  'ellipsis-vertical',
  'ellipsis-v',
  'empty-set',
  'engine',
  'engine-warning',
  'engine-exclamation',
  'envelope',
  'envelope-dot',
  'envelope-badge',
  'envelope-open',
  'envelope-open-dollar',
  'envelope-open-text',
  'envelopes',
  'envelopes-bulk',
  'mail-bulk',
  'equals',
  'eraser',
  'escalator',
  'ethernet',
  'euro-sign',
  'eur',
  'euro',
  'exclamation',
  'expand',
  'expand-wide',
  'eye',
  'eye-dropper',
  'eye-dropper-empty',
  'eyedropper',
  'eye-dropper-full',
  'eye-dropper-half',
  'eye-evil',
  'eye-low-vision',
  'low-vision',
  'eye-slash',
  'eyes',
  'f',
  'face-angry',
  'angry',
  'face-angry-horns',
  'face-anguished',
  'face-anxious-sweat',
  'face-astonished',
  'face-awesome',
  'gave-dandy',
  'face-beam-hand-over-mouth',
  'face-clouds',
  'face-confounded',
  'face-confused',
  'face-cowboy-hat',
  'face-diagonal-mouth',
  'face-disappointed',
  'face-disguise',
  'face-dizzy',
  'dizzy',
  'face-dotted',
  'face-downcast-sweat',
  'face-drooling',
  'face-exhaling',
  'face-explode',
  'exploding-head',
  'face-expressionless',
  'face-eyes-xmarks',
  'face-fearful',
  'face-flushed',
  'flushed',
  'face-frown',
  'frown',
  'face-frown-open',
  'frown-open',
  'face-frown-slight',
  'face-glasses',
  'face-grimace',
  'grimace',
  'face-grin',
  'grin',
  'face-grin-beam',
  'grin-beam',
  'face-grin-beam-sweat',
  'grin-beam-sweat',
  'face-grin-hearts',
  'grin-hearts',
  'face-grin-squint',
  'grin-squint',
  'face-grin-squint-tears',
  'grin-squint-tears',
  'face-grin-stars',
  'grin-stars',
  'face-grin-tears',
  'grin-tears',
  'face-grin-tongue',
  'grin-tongue',
  'face-grin-tongue-squint',
  'grin-tongue-squint',
  'face-grin-tongue-wink',
  'grin-tongue-wink',
  'face-grin-wide',
  'grin-alt',
  'face-grin-wink',
  'grin-wink',
  'face-hand-over-mouth',
  'face-hand-peeking',
  'face-hand-yawn',
  'face-head-bandage',
  'face-holding-back-tears',
  'face-hushed',
  'face-icicles',
  'face-kiss',
  'kiss',
  'face-kiss-beam',
  'kiss-beam',
  'face-kiss-closed-eyes',
  'face-kiss-wink-heart',
  'kiss-wink-heart',
  'face-laugh',
  'laugh',
  'face-laugh-beam',
  'laugh-beam',
  'face-laugh-squint',
  'laugh-squint',
  'face-laugh-wink',
  'laugh-wink',
  'face-lying',
  'face-mask',
  'face-meh',
  'meh',
  'face-meh-blank',
  'meh-blank',
  'face-melting',
  'face-monocle',
  'face-nauseated',
  'face-nose-steam',
  'face-party',
  'face-pensive',
  'face-persevering',
  'face-pleading',
  'face-pouting',
  'face-raised-eyebrow',
  'face-relieved',
  'face-rolling-eyes',
  'meh-rolling-eyes',
  'face-sad-cry',
  'sad-cry',
  'face-sad-sweat',
  'face-sad-tear',
  'sad-tear',
  'face-saluting',
  'face-scream',
  'face-shush',
  'face-sleeping',
  'face-sleepy',
  'face-smile',
  'smile',
  'face-smile-beam',
  'smile-beam',
  'face-smile-halo',
  'face-smile-hearts',
  'face-smile-horns',
  'face-smile-plus',
  'smile-plus',
  'face-smile-relaxed',
  'face-smile-tear',
  'face-smile-tongue',
  'face-smile-upside-down',
  'face-smile-wink',
  'smile-wink',
  'face-smiling-hands',
  'face-smirking',
  'face-spiral-eyes',
  'face-sunglasses',
  'face-surprise',
  'surprise',
  'face-swear',
  'face-thermometer',
  'face-thinking',
  'face-tired',
  'tired',
  'face-tissue',
  'face-tongue-money',
  'face-tongue-sweat',
  'face-unamused',
  'face-viewfinder',
  'face-vomit',
  'face-weary',
  'face-woozy',
  'face-worried',
  'face-zany',
  'face-zipper',
  'falafel',
  'family',
  'family-dress',
  'family-pants',
  'fan',
  'fan-table',
  'farm',
  'barn-silo',
  'faucet',
  'faucet-drip',
  'fax',
  'feather',
  'feather-pointed',
  'feather-alt',
  'fence',
  'ferris-wheel',
  'field-hockey-stick-ball',
  'field-hockey',
  'file',
  'file-arrow-down',
  'file-download',
  'file-arrow-up',
  'file-upload',
  'file-audio',
  'file-binary',
  'file-certificate',
  'file-award',
  'file-chart-column',
  'file-chart-line',
  'file-chart-pie',
  'file-check',
  'file-circle-info',
  'file-circle-plus',
  'file-code',
  'file-contract',
  'file-csv',
  'file-dashed-line',
  'page-break',
  'file-excel',
  'file-exclamation',
  'file-export',
  'arrow-right-from-file',
  'file-heart',
  'file-image',
  'file-import',
  'arrow-right-to-file',
  'file-invoice',
  'file-invoice-dollar',
  'file-lines',
  'file-alt',
  'file-text',
  'file-lock',
  'file-magnifying-glass',
  'file-search',
  'file-medical',
  'file-minus',
  'file-music',
  'file-pdf',
  'file-pen',
  'file-edit',
  'file-plus',
  'file-plus-minus',
  'file-powerpoint',
  'file-prescription',
  'file-signature',
  'file-slash',
  'file-spreadsheet',
  'file-user',
  'file-video',
  'file-waveform',
  'file-medical-alt',
  'file-word',
  'file-xmark',
  'file-times',
  'file-zipper',
  'file-archive',
  'files',
  'files-medical',
  'fill',
  'fill-drip',
  'film',
  'film-canister',
  'film-simple',
  'film-alt',
  'film-slash',
  'films',
  'filter',
  'filter-circle-dollar',
  'funnel-dollar',
  'filter-circle-xmark',
  'filter-list',
  'filter-slash',
  'filters',
  'fingerprint',
  'fire',
  'fire-extinguisher',
  'fire-flame',
  'flame',
  'fire-flame-curved',
  'fire-alt',
  'fire-flame-simple',
  'burn',
  'fire-hydrant',
  'fire-smoke',
  'fireplace',
  'fish',
  'fish-bones',
  'fish-cooked',
  'fishing-rod',
  'flag',
  'flag-checkered',
  'flag-pennant',
  'pennant',
  'flag-swallowtail',
  'flag-alt',
  'flag-usa',
  'flashlight',
  'flask',
  'flask-round-poison',
  'flask-poison',
  'flask-round-potion',
  'flask-potion',
  'flatbread',
  'flatbread-stuffed',
  'floppy-disk',
  'save',
  'floppy-disk-circle-arrow-right',
  'save-circle-arrow-right',
  'floppy-disk-circle-xmark',
  'floppy-disk-times',
  'save-circle-xmark',
  'save-times',
  'floppy-disk-pen',
  'floppy-disks',
  'florin-sign',
  'flower',
  'flower-daffodil',
  'flower-tulip',
  'flute',
  'flux-capacitor',
  'flying-disc',
  'folder',
  'folder-arrow-down',
  'folder-download',
  'folder-arrow-up',
  'folder-upload',
  'folder-blank',
  'folder-bookmark',
  'folder-gear',
  'folder-cog',
  'folder-grid',
  'folder-heart',
  'folder-image',
  'folder-magnifying-glass',
  'folder-search',
  'folder-medical',
  'folder-minus',
  'folder-music',
  'folder-open',
  'folder-plus',
  'folder-tree',
  'folder-user',
  'folder-xmark',
  'folder-times',
  'folders',
  'fondue-pot',
  'font',
  'font-awesome',
  'font-awesome-flag',
  'font-awesome-logo-full',
  'font-case',
  'football',
  'football-ball',
  'football-helmet',
  'fork',
  'utensil-fork',
  'fork-knife',
  'utensils-alt',
  'forklift',
  'fort',
  'forward',
  'forward-fast',
  'fast-forward',
  'forward-step',
  'step-forward',
  'frame',
  'franc-sign',
  'french-fries',
  'frog',
  'function',
  'futbol',
  'futbol-ball',
  'soccer-ball',
  'g',
  'galaxy',
  'gallery-thumbnails',
  'game-board',
  'game-board-simple',
  'game-board-alt',
  'game-console-handheld',
  'gamepad',
  'gamepad-modern',
  'gamepad-alt',
  'garage',
  'garage-car',
  'garage-open',
  'garlic',
  'gas-pump',
  'gas-pump-slash',
  'gauge',
  'dashboard',
  'gauge-med',
  'tachometer-alt-average',
  'gauge-circle-bolt',
  'gauge-circle-minus',
  'gauge-circle-plus',
  'gauge-high',
  'tachometer-alt',
  'tachometer-alt-fast',
  'gauge-low',
  'tachometer-alt-slow',
  'gauge-max',
  'tachometer-alt-fastest',
  'gauge-min',
  'tachometer-alt-slowest',
  'gauge-simple',
  'gauge-simple-med',
  'tachometer-average',
  'gauge-simple-high',
  'tachometer',
  'tachometer-fast',
  'gauge-simple-low',
  'tachometer-slow',
  'gauge-simple-max',
  'tachometer-fastest',
  'gauge-simple-min',
  'tachometer-slowest',
  'gavel',
  'legal',
  'gear',
  'cog',
  'gears',
  'cogs',
  'gem',
  'genderless',
  'ghost',
  'gif',
  'gift',
  'gift-card',
  'gifts',
  'gingerbread-man',
  'glass',
  'glass-citrus',
  'glass-empty',
  'glass-half',
  'glass-half-empty',
  'glass-half-full',
  'glasses',
  'glasses-round',
  'glasses-alt',
  'globe',
  'globe-snow',
  'globe-stand',
  'goal-net',
  'golf-ball-tee',
  'golf-ball',
  'golf-club',
  'golf-flag-hole',
  'gopuram',
  'graduation-cap',
  'mortar-board',
  'gramophone',
  'grapes',
  'grate',
  'grate-droplet',
  'greater-than',
  'greater-than-equal',
  'grid',
  'grid3',
  'grid-2',
  'grid-2-plus',
  'grid-4',
  'grid-5',
  'grid-dividers',
  'grid-horizontal',
  'grip',
  'grip-horizontal',
  'grip-dots',
  'grip-dots-vertical',
  'grip-lines',
  'grip-lines-vertical',
  'grip-vertical',
  'guarani-sign',
  'guitar',
  'guitar-electric',
  'guitars',
  'gun',
  'gun-slash',
  'gun-squirt',
  'h',
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
  'hammer',
  'hammer-crash',
  'hammer-war',
  'hamsa',
  'hand',
  'hand-paper',
  'hand-back-fist',
  'hand-rock',
  'hand-back-point-down',
  'hand-back-point-left',
  'hand-back-point-ribbon',
  'hand-back-point-right',
  'hand-back-point-up',
  'hand-dots',
  'allergies',
  'hand-fingers-crossed',
  'hand-fist',
  'fist-raised',
  'hand-heart',
  'hand-holding',
  'hand-holding-box',
  'hand-holding-dollar',
  'hand-holding-usd',
  'hand-holding-droplet',
  'hand-holding-water',
  'hand-holding-heart',
  'hand-holding-magic',
  'hand-holding-medical',
  'hand-holding-seedling',
  'hand-holding-skull',
  'hand-horns',
  'hand-lizard',
  'hand-love',
  'hand-middle-finger',
  'hand-peace',
  'hand-point-down',
  'hand-point-left',
  'hand-point-ribbon',
  'hand-point-right',
  'hand-point-up',
  'hand-pointer',
  'hand-scissors',
  'hand-sparkles',
  'hand-spock',
  'hand-wave',
  'hands',
  'sign-language',
  'signing',
  'hands-asl-interpreting',
  'american-sign-language-interpreting',
  'asl-interpreting',
  'hands-american-sign-language-interpreting',
  'hands-bubbles',
  'hands-wash',
  'hands-clapping',
  'hands-holding',
  'hands-holding-diamond',
  'hand-receiving',
  'hands-holding-dollar',
  'hands-usd',
  'hands-holding-heart',
  'hands-heart',
  'hands-praying',
  'praying-hands',
  'handshake',
  'handshake-angle',
  'hands-helping',
  'handshake-simple',
  'handshake-alt',
  'handshake-simple-slash',
  'handshake-alt-slash',
  'handshake-slash',
  'hanukiah',
  'hard-drive',
  'hdd',
  'hashtag',
  'hashtag-lock',
  'hat-chef',
  'hat-cowboy',
  'hat-cowboy-side',
  'hat-santa',
  'hat-winter',
  'hat-witch',
  'hat-wizard',
  'head-side',
  'head-side-brain',
  'head-side-cough',
  'head-side-cough-slash',
  'head-side-goggles',
  'head-vr',
  'head-side-headphones',
  'head-side-heart',
  'head-side-mask',
  'head-side-medical',
  'head-side-virus',
  'heading',
  'header',
  'headphones',
  'headphones-simple',
  'headphones-alt',
  'headset',
  'heart',
  'heart-crack',
  'heart-broken',
  'heart-half',
  'heart-half-stroke',
  'heart-half-alt',
  'heart-pulse',
  'heartbeat',
  'heat',
  'helicopter',
  'helmet-battle',
  'helmet-safety',
  'hard-hat',
  'hat-hard',
  'hexagon',
  'hexagon-check',
  'hexagon-divide',
  'hexagon-exclamation',
  'hexagon-minus',
  'minus-hexagon',
  'hexagon-plus',
  'plus-hexagon',
  'hexagon-xmark',
  'times-hexagon',
  'xmark-hexagon',
  'high-definition',
  'rectangle-hd',
  'highlighter',
  'highlighter-line',
  'hippo',
  'hockey-mask',
  'hockey-puck',
  'hockey-stick-puck',
  'hockey-sticks',
  'holly-berry',
  'honey-pot',
  'hood-cloak',
  'horizontal-rule',
  'horse',
  'horse-head',
  'horse-saddle',
  'hose',
  'hose-reel',
  'hospital',
  'hospital-alt',
  'hospital-wide',
  'hospital-user',
  'hospitals',
  'hot-tub-person',
  'hot-tub',
  'hotdog',
  'hotel',
  'hourglass',
  'hourglass2',
  'hourglass-half',
  'hourglass-clock',
  'hourglass-empty',
  'hourglass-end',
  'hourglass3',
  'hourglass-start',
  'hourglass1',
  'house',
  'home',
  'home-alt',
  'home-lg-alt',
  'house-blank',
  'home-blank',
  'house-building',
  'house-chimney',
  'home-lg',
  'house-chimney-blank',
  'house-chimney-crack',
  'house-damage',
  'house-chimney-heart',
  'house-chimney-medical',
  'clinic-medical',
  'house-chimney-user',
  'house-chimney-window',
  'house-crack',
  'house-day',
  'house-flood',
  'house-heart',
  'home-heart',
  'house-laptop',
  'laptop-house',
  'house-medical',
  'house-night',
  'house-person-leave',
  'house-leave',
  'house-person-depart',
  'house-person-return',
  'house-person-arrive',
  'house-return',
  'house-signal',
  'house-tree',
  'house-turret',
  'house-user',
  'home-user',
  'house-window',
  'hryvnia-sign',
  'hryvnia',
  'hundred-points',
  '100',
  'hurricane',
  'hyphen',
  'i',
  'i-cursor',
  'ice-cream',
  'ice-skate',
  'icicles',
  'icons',
  'heart-music-camera-bolt',
  'id-badge',
  'id-card',
  'drivers-license',
  'id-card-clip',
  'id-card-alt',
  'igloo',
  'image',
  'image-landscape',
  'landscape',
  'image-polaroid',
  'image-polaroid-user',
  'image-portrait',
  'portrait',
  'image-slash',
  'image-user',
  'images',
  'images-user',
  'inbox',
  'inbox-full',
  'inbox-in',
  'inbox-arrow-down',
  'inbox-out',
  'inbox-arrow-up',
  'inboxes',
  'indent',
  'indian-rupee-sign',
  'indian-rupee',
  'inr',
  'industry',
  'industry-windows',
  'industry-alt',
  'infinity',
  'info',
  'inhaler',
  'input-numeric',
  'input-pipe',
  'input-text',
  'integral',
  'intersection',
  'island-tropical',
  'island-tree-palm',
  'italic',
  'j',
  'jack-o-lantern',
  'jedi',
  'jet-fighter',
  'fighter-jet',
  'joint',
  'joystick',
  'jug',
  'k',
  'kaaba',
  'kazoo',
  'kerning',
  'key',
  'key-skeleton',
  'key-skeleton-left-right',
  'keyboard',
  'keyboard-brightness',
  'keyboard-brightness-low',
  'keyboard-down',
  'keyboard-left',
  'keynote',
  'khanda',
  'kidneys',
  'kip-sign',
  'kit-medical',
  'first-aid',
  'kite',
  'kiwi-bird',
  'kiwi-fruit',
  'knife',
  'utensil-knife',
  'knife-kitchen',
  'l',
  'lacrosse-stick',
  'lacrosse-stick-ball',
  'lambda',
  'lamp',
  'lamp-desk',
  'lamp-floor',
  'lamp-street',
  'landmark',
  'landmark-dome',
  'landmark-alt',
  'language',
  'laptop',
  'laptop-arrow-down',
  'laptop-code',
  'laptop-medical',
  'laptop-mobile',
  'phone-laptop',
  'laptop-slash',
  'lari-sign',
  'lasso',
  'lasso-sparkles',
  'layer-group',
  'layer-minus',
  'layer-group-minus',
  'layer-plus',
  'layer-group-plus',
  'leaf',
  'leaf-heart',
  'leaf-maple',
  'leaf-oak',
  'leafy-green',
  'left',
  'arrow-alt-left',
  'left-from-line',
  'arrow-alt-from-right',
  'left-long',
  'long-arrow-alt-left',
  'left-long-to-line',
  'left-right',
  'arrows-alt-h',
  'left-to-line',
  'arrow-alt-to-left',
  'lemon',
  'less-than',
  'less-than-equal',
  'life-ring',
  'light-ceiling',
  'light-emergency',
  'light-emergency-on',
  'light-switch',
  'light-switch-off',
  'light-switch-on',
  'lightbulb',
  'lightbulb-dollar',
  'lightbulb-exclamation',
  'lightbulb-exclamation-on',
  'lightbulb-on',
  'lightbulb-slash',
  'lights-holiday',
  'line-columns',
  'line-height',
  'link',
  'chain',
  'link-horizontal',
  'chain-horizontal',
  'link-horizontal-slash',
  'chain-horizontal-slash',
  'link-simple',
  'link-simple-slash',
  'link-slash',
  'chain-broken',
  'chain-slash',
  'unlink',
  'lips',
  'lira-sign',
  'list',
  'list-squares',
  'list-check',
  'tasks',
  'list-dropdown',
  'list-music',
  'list-ol',
  'list12',
  'list-numeric',
  'list-radio',
  'list-timeline',
  'list-tree',
  'list-ul',
  'list-dots',
  'litecoin-sign',
  'loader',
  'lobster',
  'location-arrow',
  'location-check',
  'map-marker-check',
  'location-crosshairs',
  'location',
  'location-crosshairs-slash',
  'location-slash',
  'location-dot',
  'map-marker-alt',
  'location-dot-slash',
  'map-marker-alt-slash',
  'location-exclamation',
  'map-marker-exclamation',
  'location-minus',
  'map-marker-minus',
  'location-pen',
  'map-marker-edit',
  'location-pin',
  'map-marker',
  'location-pin-slash',
  'map-marker-slash',
  'location-plus',
  'map-marker-plus',
  'location-question',
  'map-marker-question',
  'location-smile',
  'map-marker-smile',
  'location-xmark',
  'map-marker-times',
  'map-marker-xmark',
  'lock',
  'lock-a',
  'lock-hashtag',
  'lock-keyhole',
  'lock-alt',
  'lock-keyhole-open',
  'lock-open-alt',
  'lock-open',
  'lollipop',
  'lollypop',
  'loveseat',
  'couch-small',
  'luchador-mask',
  'luchador',
  'mask-luchador',
  'lungs',
  'lungs-virus',
  'm',
  'mace',
  'magnet',
  'magnifying-glass',
  'search',
  'magnifying-glass-dollar',
  'search-dollar',
  'magnifying-glass-location',
  'search-location',
  'magnifying-glass-minus',
  'search-minus',
  'magnifying-glass-plus',
  'search-plus',
  'mailbox',
  'manat-sign',
  'mandolin',
  'mango',
  'manhole',
  'map',
  'map-location',
  'map-marked',
  'map-location-dot',
  'map-marked-alt',
  'map-pin',
  'marker',
  'mars',
  'mars-and-venus',
  'mars-double',
  'mars-stroke',
  'mars-stroke-right',
  'mars-stroke-h',
  'mars-stroke-up',
  'mars-stroke-v',
  'martini-glass',
  'glass-martini-alt',
  'martini-glass-citrus',
  'cocktail',
  'martini-glass-empty',
  'glass-martini',
  'mask',
  'mask-face',
  'mask-snorkel',
  'masks-theater',
  'theater-masks',
  'maximize',
  'expand-arrows-alt',
  'meat',
  'medal',
  'megaphone',
  'melon',
  'melon-slice',
  'memo',
  'memo-circle-check',
  'memo-circle-info',
  'memo-pad',
  'memory',
  'menorah',
  'mercury',
  'message',
  'comment-alt',
  'message-arrow-down',
  'comment-alt-arrow-down',
  'message-arrow-up',
  'comment-alt-arrow-up',
  'message-arrow-up-right',
  'message-bot',
  'message-captions',
  'comment-alt-captions',
  'message-check',
  'comment-alt-check',
  'message-code',
  'message-dollar',
  'comment-alt-dollar',
  'message-dots',
  'comment-alt-dots',
  'messaging',
  'message-exclamation',
  'comment-alt-exclamation',
  'message-image',
  'comment-alt-image',
  'message-lines',
  'comment-alt-lines',
  'message-medical',
  'comment-alt-medical',
  'message-middle',
  'comment-middle-alt',
  'message-middle-top',
  'comment-middle-top-alt',
  'message-minus',
  'comment-alt-minus',
  'message-music',
  'comment-alt-music',
  'message-pen',
  'comment-alt-edit',
  'message-edit',
  'message-plus',
  'comment-alt-plus',
  'message-question',
  'message-quote',
  'comment-alt-quote',
  'message-slash',
  'comment-alt-slash',
  'message-smile',
  'comment-alt-smile',
  'message-sms',
  'message-text',
  'comment-alt-text',
  'message-xmark',
  'comment-alt-times',
  'message-times',
  'messages',
  'comments-alt',
  'messages-dollar',
  'comments-alt-dollar',
  'messages-question',
  'meteor',
  'meter',
  'meter-bolt',
  'meter-droplet',
  'meter-fire',
  'microchip',
  'microchip-ai',
  'microphone',
  'microphone-lines',
  'microphone-alt',
  'microphone-lines-slash',
  'microphone-alt-slash',
  'microphone-slash',
  'microphone-stand',
  'microscope',
  'microwave',
  'mill-sign',
  'minimize',
  'compress-arrows-alt',
  'minus',
  'subtract',
  'mistletoe',
  'mitten',
  'mobile',
  'mobile-android',
  'mobile-phone',
  'mobile-button',
  'mobile-notch',
  'mobile-iphone',
  'mobile-screen',
  'mobile-android-alt',
  'mobile-screen-button',
  'mobile-alt',
  'mobile-signal',
  'mobile-signal-out',
  'money-bill',
  'money-bill-1',
  'money-bill-alt',
  'money-bill-1-wave',
  'money-bill-wave-alt',
  'money-bill-simple',
  'money-bill-simple-wave',
  'money-bill-wave',
  'money-bills',
  'money-bills-simple',
  'money-bills-alt',
  'money-check',
  'money-check-dollar',
  'money-check-alt',
  'money-check-dollar-pen',
  'money-check-edit-alt',
  'money-check-pen',
  'money-check-edit',
  'money-from-bracket',
  'money-simple-from-bracket',
  'monitor-waveform',
  'monitor-heart-rate',
  'monkey',
  'monument',
  'moon',
  'moon-cloud',
  'moon-over-sun',
  'eclipse-alt',
  'moon-stars',
  'moped',
  'mortar-pestle',
  'mosque',
  'motorcycle',
  'mountain',
  'mountains',
  'mp3-player',
  'mug',
  'mug-hot',
  'mug-marshmallows',
  'mug-saucer',
  'coffee',
  'mug-tea',
  'mug-tea-saucer',
  'mushroom',
  'music',
  'music-note',
  'music-alt',
  'music-note-slash',
  'music-alt-slash',
  'music-slash',
  'n',
  'naira-sign',
  'narwhal',
  'nesting-dolls',
  'network-wired',
  'neuter',
  'newspaper',
  'nfc',
  'nfc-lock',
  'nfc-magnifying-glass',
  'nfc-pen',
  'nfc-signal',
  'nfc-slash',
  'nfc-trash',
  'not-equal',
  'notdef',
  'note',
  'note-medical',
  'note-sticky',
  'sticky-note',
  'notebook',
  'notes',
  'notes-medical',
  'o',
  'object-exclude',
  'object-group',
  'object-intersect',
  'object-subtract',
  'object-ungroup',
  'object-union',
  'objects-align-bottom',
  'objects-align-center-horizontal',
  'objects-align-center-vertical',
  'objects-align-left',
  'objects-align-right',
  'objects-align-top',
  'objects-column',
  'octagon',
  'octagon-check',
  'octagon-divide',
  'octagon-exclamation',
  'octagon-minus',
  'minus-octagon',
  'octagon-plus',
  'plus-octagon',
  'octagon-xmark',
  'times-octagon',
  'xmark-octagon',
  'oil-can',
  'oil-can-drip',
  'oil-temperature',
  'oil-temp',
  'olive',
  'olive-branch',
  'om',
  'omega',
  'onion',
  'option',
  'ornament',
  'otter',
  'outdent',
  'dedent',
  'outlet',
  'oven',
  'overline',
  'p',
  'page',
  'page-caret-down',
  'file-caret-down',
  'page-caret-up',
  'file-caret-up',
  'pager',
  'paint-roller',
  'paintbrush',
  'paint-brush',
  'paintbrush-fine',
  'paint-brush-alt',
  'paint-brush-fine',
  'paintbrush-alt',
  'paintbrush-pencil',
  'palette',
  'pallet',
  'pallet-box',
  'pallet-boxes',
  'palette-boxes',
  'pallet-alt',
  'pan-food',
  'pan-frying',
  'pancakes',
  'panel-ews',
  'panel-fire',
  'panorama',
  'paper-plane',
  'paper-plane-top',
  'paper-plane-alt',
  'send',
  'paperclip',
  'paperclip-vertical',
  'parachute-box',
  'paragraph',
  'paragraph-left',
  'paragraph-rtl',
  'party-bell',
  'party-horn',
  'passport',
  'paste',
  'file-clipboard',
  'pause',
  'paw',
  'paw-claws',
  'paw-simple',
  'paw-alt',
  'peace',
  'peach',
  'peanut',
  'peanuts',
  'peapod',
  'pear',
  'pedestal',
  'pegasus',
  'pen',
  'pen-circle',
  'pen-clip',
  'pen-alt',
  'pen-clip-slash',
  'pen-alt-slash',
  'pen-fancy',
  'pen-fancy-slash',
  'pen-field',
  'pen-line',
  'pen-nib',
  'pen-nib-slash',
  'pen-paintbrush',
  'pencil-paintbrush',
  'pen-ruler',
  'pencil-ruler',
  'pen-slash',
  'pen-swirl',
  'pen-to-square',
  'edit',
  'pencil',
  'pencil-alt',
  'pencil-slash',
  'people',
  'people-arrows-left-right',
  'people-arrows',
  'people-carry-box',
  'people-carry',
  'people-dress',
  'people-dress-simple',
  'people-pants',
  'people-pants-simple',
  'people-simple',
  'pepper',
  'pepper-hot',
  'percent',
  'percentage',
  'period',
  'person',
  'male',
  'person-biking',
  'biking',
  'person-biking-mountain',
  'biking-mountain',
  'person-booth',
  'person-carry-box',
  'person-carry',
  'person-digging',
  'digging',
  'person-dolly',
  'person-dolly-empty',
  'person-dots-from-line',
  'diagnoses',
  'person-dress',
  'female',
  'person-dress-simple',
  'person-from-portal',
  'portal-exit',
  'person-hiking',
  'hiking',
  'person-pinball',
  'person-praying',
  'pray',
  'person-pregnant',
  'person-running',
  'running',
  'person-seat',
  'person-seat-reclined',
  'person-sign',
  'person-simple',
  'person-skating',
  'skating',
  'person-ski-jumping',
  'ski-jump',
  'person-ski-lift',
  'ski-lift',
  'person-skiing',
  'skiing',
  'person-skiing-nordic',
  'skiing-nordic',
  'person-sledding',
  'sledding',
  'person-snowboarding',
  'snowboarding',
  'person-snowmobiling',
  'snowmobile',
  'person-swimming',
  'swimmer',
  'person-to-door',
  'person-to-portal',
  'portal-enter',
  'person-walking',
  'walking',
  'person-walking-with-cane',
  'blind',
  'peseta-sign',
  'peso-sign',
  'phone',
  'phone-arrow-down-left',
  'phone-arrow-down',
  'phone-incoming',
  'phone-arrow-up-right',
  'phone-arrow-up',
  'phone-outgoing',
  'phone-flip',
  'phone-alt',
  'phone-hangup',
  'phone-intercom',
  'phone-missed',
  'phone-office',
  'phone-plus',
  'phone-rotary',
  'phone-slash',
  'phone-volume',
  'volume-control-phone',
  'phone-xmark',
  'photo-film',
  'photo-video',
  'photo-film-music',
  'pi',
  'piano',
  'piano-keyboard',
  'pickleball',
  'pie',
  'pig',
  'piggy-bank',
  'pills',
  'pinata',
  'pinball',
  'pineapple',
  'pipe',
  'pipe-circle-check',
  'pipe-collar',
  'pipe-section',
  'pipe-smoking',
  'pipe-valve',
  'pizza',
  'pizza-slice',
  'place-of-worship',
  'plane',
  'plane-arrival',
  'plane-departure',
  'plane-engines',
  'plane-alt',
  'plane-prop',
  'plane-slash',
  'plane-tail',
  'plane-up',
  'plane-up-slash',
  'planet-moon',
  'planet-ringed',
  'plate-utensils',
  'play',
  'play-pause',
  'plug',
  'plus',
  'add',
  'plus-minus',
  'podcast',
  'podium',
  'podium-star',
  'police-box',
  'poll-people',
  'pompebled',
  'poo',
  'poo-storm',
  'poo-bolt',
  'pool-8-ball',
  'poop',
  'popcorn',
  'popsicle',
  'pot-food',
  'potato',
  'power-off',
  'prescription',
  'prescription-bottle',
  'prescription-bottle-medical',
  'prescription-bottle-alt',
  'presentation-screen',
  'presentation',
  'pretzel',
  'print',
  'print-magnifying-glass',
  'print-search',
  'print-slash',
  'projector',
  'pump',
  'pump-medical',
  'pump-soap',
  'pumpkin',
  'puzzle',
  'puzzle-piece',
  'puzzle-piece-simple',
  'puzzle-piece-alt',
  'q',
  'qrcode',
  'question',
  'quote-left',
  'quote-left-alt',
  'quote-right',
  'quote-right-alt',
  'quotes',
  'r',
  'rabbit',
  'rabbit-running',
  'rabbit-fast',
  'racquet',
  'radar',
  'radiation',
  'radio',
  'radio-tuner',
  'radio-alt',
  'rainbow',
  'raindrops',
  'ram',
  'ramp-loading',
  'raygun',
  'receipt',
  'record-vinyl',
  'rectangle',
  'rectangle-landscape',
  'rectangle-ad',
  'ad',
  'rectangle-barcode',
  'barcode-alt',
  'rectangle-code',
  'rectangle-history',
  'rectangle-history-circle-plus',
  'rectangle-history-circle-user',
  'rectangle-list',
  'list-alt',
  'rectangle-pro',
  'pro',
  'rectangle-terminal',
  'rectangle-vertical',
  'rectangle-portrait',
  'rectangle-vertical-history',
  'rectangle-wide',
  'rectangle-xmark',
  'rectangle-times',
  'times-rectangle',
  'window-close',
  'rectangles-mixed',
  'recycle',
  'reel',
  'refrigerator',
  'registered',
  'repeat',
  'repeat-1',
  'reply',
  'mail-reply',
  'reply-all',
  'mail-reply-all',
  'reply-clock',
  'reply-time',
  'republican',
  'restroom',
  'restroom-simple',
  'retweet',
  'rhombus',
  'ribbon',
  'right',
  'arrow-alt-right',
  'right-from-bracket',
  'sign-out-alt',
  'right-from-line',
  'arrow-alt-from-left',
  'right-left',
  'exchange-alt',
  'right-long',
  'long-arrow-alt-right',
  'right-long-to-line',
  'right-to-bracket',
  'sign-in-alt',
  'right-to-line',
  'arrow-alt-to-right',
  'ring',
  'rings-wedding',
  'road',
  'robot',
  'robot-astromech',
  'rocket',
  'rocket-launch',
  'roller-coaster',
  'rotate',
  'sync-alt',
  'rotate-exclamation',
  'rotate-left',
  'rotate-back',
  'rotate-backward',
  'undo-alt',
  'rotate-right',
  'redo-alt',
  'rotate-forward',
  'route',
  'route-highway',
  'route-interstate',
  'router',
  'rss',
  'feed',
  'ruble-sign',
  'rouble',
  'rub',
  'ruble',
  'rugby-ball',
  'ruler',
  'ruler-combined',
  'ruler-horizontal',
  'ruler-triangle',
  'ruler-vertical',
  'rupee-sign',
  'rupee',
  'rupiah-sign',
  'rv',
  's',
  'sack',
  'sack-dollar',
  'sailboat',
  'salad',
  'bowl-salad',
  'salt-shaker',
  'sandwich',
  'satellite',
  'satellite-dish',
  'sausage',
  'saxophone',
  'saxophone-fire',
  'sax-hot',
  'scale-balanced',
  'balance-scale',
  'scale-unbalanced',
  'balance-scale-left',
  'scale-unbalanced-flip',
  'balance-scale-right',
  'scalpel',
  'scalpel-line-dashed',
  'scalpel-path',
  'scanner',
  'scanner-image',
  'scanner-gun',
  'scanner-keyboard',
  'scanner-touchscreen',
  'scarecrow',
  'scarf',
  'school',
  'scissors',
  'cut',
  'screen-users',
  'users-class',
  'screencast',
  'screwdriver',
  'screwdriver-wrench',
  'tools',
  'scribble',
  'scroll',
  'scroll-old',
  'scroll-torah',
  'torah',
  'scrubber',
  'scythe',
  'sd-card',
  'sd-cards',
  'seal',
  'seal-exclamation',
  'seal-question',
  'seat-airline',
  'section',
  'seedling',
  'sprout',
  'semicolon',
  'send-back',
  'send-backward',
  'sensor',
  'sensor-cloud',
  'sensor-smoke',
  'sensor-fire',
  'sensor-on',
  'sensor-triangle-exclamation',
  'sensor-alert',
  'server',
  'shapes',
  'triangle-circle-square',
  'share',
  'arrow-turn-right',
  'mail-forward',
  'share-all',
  'arrows-turn-right',
  'share-from-square',
  'share-square',
  'share-nodes',
  'share-alt',
  'sheep',
  'shekel-sign',
  'ils',
  'shekel',
  'sheqel',
  'sheqel-sign',
  'shelves',
  'inventory',
  'shelves-empty',
  'shield',
  'shield-blank',
  'shield-alt',
  'shield-check',
  'shield-cross',
  'shield-exclamation',
  'shield-keyhole',
  'shield-minus',
  'shield-plus',
  'shield-slash',
  'shield-virus',
  'shield-xmark',
  'shield-times',
  'ship',
  'shirt',
  't-shirt',
  'tshirt',
  'shirt-long-sleeve',
  'shirt-running',
  'shirt-tank-top',
  'shish-kebab',
  'shoe-prints',
  'shop',
  'store-alt',
  'shop-lock',
  'shop-slash',
  'store-alt-slash',
  'shovel',
  'shovel-snow',
  'shower',
  'shower-down',
  'shower-alt',
  'shredder',
  'shrimp',
  'shuffle',
  'random',
  'shutters',
  'shuttle-space',
  'space-shuttle',
  'shuttlecock',
  'sickle',
  'sidebar',
  'sidebar-flip',
  'sigma',
  'sign-hanging',
  'sign',
  'signal',
  'signal5',
  'signal-perfect',
  'signal-bars',
  'signal-alt',
  'signal-alt4',
  'signal-bars-strong',
  'signal-bars-fair',
  'signal-alt2',
  'signal-bars-good',
  'signal-alt3',
  'signal-bars-slash',
  'signal-alt-slash',
  'signal-bars-weak',
  'signal-alt1',
  'signal-fair',
  'signal2',
  'signal-good',
  'signal3',
  'signal-slash',
  'signal-stream',
  'signal-stream-slash',
  'signal-strong',
  'signal4',
  'signal-weak',
  'signal1',
  'signature',
  'signature-lock',
  'signature-slash',
  'signs-post',
  'map-signs',
  'sim-card',
  'sim-cards',
  'sink',
  'siren',
  'siren-on',
  'sitemap',
  'skeleton',
  'ski-boot',
  'ski-boot-ski',
  'skull',
  'skull-cow',
  'skull-crossbones',
  'slash',
  'slash-back',
  'slash-forward',
  'sleigh',
  'slider',
  'sliders',
  'sliders-h',
  'sliders-simple',
  'sliders-up',
  'sliders-v',
  'slot-machine',
  'smog',
  'smoke',
  'smoking',
  'snake',
  'snooze',
  'zzz',
  'snow-blowing',
  'snowflake',
  'snowflakes',
  'snowman',
  'snowman-head',
  'frosty-head',
  'snowplow',
  'soap',
  'socks',
  'soft-serve',
  'creemee',
  'solar-panel',
  'solar-system',
  'sort',
  'unsorted',
  'sort-down',
  'sort-desc',
  'sort-up',
  'sort-asc',
  'spa',
  'space-station-moon',
  'space-station-moon-construction',
  'space-station-moon-alt',
  'spade',
  'spaghetti-monster-flying',
  'pastafarianism',
  'sparkles',
  'speaker',
  'speakers',
  'spell-check',
  'spider',
  'spider-black-widow',
  'spider-web',
  'spinner',
  'spinner-third',
  'split',
  'splotch',
  'spoon',
  'utensil-spoon',
  'sportsball',
  'spray-can',
  'spray-can-sparkles',
  'air-freshener',
  'sprinkler',
  'sprinkler-ceiling',
  'square',
  'square-0',
  'square-1',
  'square-2',
  'square-3',
  'square-4',
  'square-5',
  'square-6',
  'square-7',
  'square-8',
  'square-9',
  'square-a',
  'square-a-lock',
  'square-ampersand',
  'square-arrow-down',
  'arrow-square-down',
  'square-arrow-down-left',
  'square-arrow-down-right',
  'square-arrow-left',
  'arrow-square-left',
  'square-arrow-right',
  'arrow-square-right',
  'square-arrow-up',
  'arrow-square-up',
  'square-arrow-up-left',
  'square-arrow-up-right',
  'external-link-square',
  'square-b',
  'square-bolt',
  'square-c',
  'square-caret-down',
  'caret-square-down',
  'square-caret-left',
  'caret-square-left',
  'square-caret-right',
  'caret-square-right',
  'square-caret-up',
  'caret-square-up',
  'square-check',
  'check-square',
  'square-chevron-down',
  'chevron-square-down',
  'square-chevron-left',
  'chevron-square-left',
  'square-chevron-right',
  'chevron-square-right',
  'square-chevron-up',
  'chevron-square-up',
  'square-code',
  'square-d',
  'square-dashed',
  'square-divide',
  'square-dollar',
  'dollar-square',
  'usd-square',
  'square-down',
  'arrow-alt-square-down',
  'square-down-left',
  'square-down-right',
  'square-e',
  'square-ellipsis',
  'square-ellipsis-vertical',
  'square-envelope',
  'envelope-square',
  'square-exclamation',
  'exclamation-square',
  'square-f',
  'square-fragile',
  'box-fragile',
  'square-wine-glass-crack',
  'square-full',
  'square-g',
  'square-h',
  'h-square',
  'square-heart',
  'heart-square',
  'square-i',
  'square-info',
  'info-square',
  'square-j',
  'square-k',
  'square-kanban',
  'square-l',
  'square-left',
  'arrow-alt-square-left',
  'square-list',
  'square-m',
  'square-minus',
  'minus-square',
  'square-n',
  'square-o',
  'square-p',
  'square-parking',
  'parking',
  'square-parking-slash',
  'parking-slash',
  'square-pen',
  'pen-square',
  'pencil-square',
  'square-phone',
  'phone-square',
  'square-phone-flip',
  'phone-square-alt',
  'square-phone-hangup',
  'phone-square-down',
  'square-plus',
  'plus-square',
  'square-poll-horizontal',
  'poll-h',
  'square-poll-vertical',
  'poll',
  'square-q',
  'square-quarters',
  'square-question',
  'question-square',
  'square-quote',
  'square-r',
  'square-right',
  'arrow-alt-square-right',
  'square-ring',
  'square-root',
  'square-root-variable',
  'square-root-alt',
  'square-rss',
  'rss-square',
  'square-s',
  'square-share-nodes',
  'share-alt-square',
  'square-sliders',
  'sliders-h-square',
  'square-sliders-vertical',
  'sliders-v-square',
  'square-small',
  'square-star',
  'square-t',
  'square-terminal',
  'square-this-way-up',
  'box-up',
  'square-u',
  'square-up',
  'arrow-alt-square-up',
  'square-up-left',
  'square-up-right',
  'external-link-square-alt',
  'square-user',
  'square-v',
  'square-w',
  'square-x',
  'square-xmark',
  'times-square',
  'xmark-square',
  'square-y',
  'square-z',
  'squid',
  'squirrel',
  'staff',
  'stairs',
  'stamp',
  'standard-definition',
  'rectangle-sd',
  'star',
  'star-and-crescent',
  'star-christmas',
  'star-exclamation',
  'star-half',
  'star-half-stroke',
  'star-half-alt',
  'star-of-david',
  'star-of-life',
  'star-sharp',
  'star-sharp-half',
  'star-sharp-half-stroke',
  'star-sharp-half-alt',
  'star-shooting',
  'starfighter',
  'starfighter-twin-ion-engine',
  'starfighter-alt',
  'starfighter-twin-ion-engine-advanced',
  'starfighter-alt-advanced',
  'stars',
  'starship',
  'starship-freighter',
  'steak',
  'steering-wheel',
  'sterling-sign',
  'gbp',
  'pound-sign',
  'stethoscope',
  'stocking',
  'stomach',
  'stop',
  'stopwatch',
  'stopwatch-20',
  'store',
  'store-lock',
  'store-slash',
  'strawberry',
  'street-view',
  'stretcher',
  'strikethrough',
  'stroopwafel',
  'subscript',
  'suitcase',
  'suitcase-medical',
  'medkit',
  'suitcase-rolling',
  'sun',
  'sun-bright',
  'sun-alt',
  'sun-cloud',
  'sun-dust',
  'sun-haze',
  'sunglasses',
  'sunrise',
  'sunset',
  'superscript',
  'sushi',
  'nigiri',
  'sushi-roll',
  'maki-roll',
  'makizushi',
  'swatchbook',
  'sword',
  'sword-laser',
  'sword-laser-alt',
  'swords',
  'swords-laser',
  'symbols',
  'icons-alt',
  'synagogue',
  'syringe',
  't',
  'table',
  'table-cells',
  'th',
  'table-cells-large',
  'th-large',
  'table-columns',
  'columns',
  'table-layout',
  'table-list',
  'th-list',
  'table-picnic',
  'table-pivot',
  'table-rows',
  'rows',
  'table-tennis-paddle-ball',
  'ping-pong-paddle-ball',
  'table-tennis',
  'table-tree',
  'tablet',
  'tablet-android',
  'tablet-button',
  'tablet-rugged',
  'tablet-screen',
  'tablet-android-alt',
  'tablet-screen-button',
  'tablet-alt',
  'tablets',
  'tachograph-digital',
  'digital-tachograph',
  'taco',
  'tag',
  'tags',
  'tally',
  'tally5',
  'tally-1',
  'tally-2',
  'tally-3',
  'tally-4',
  'tamale',
  'tank-water',
  'tape',
  'taxi',
  'cab',
  'taxi-bus',
  'teddy-bear',
  'teeth',
  'teeth-open',
  'telescope',
  'temperature-arrow-down',
  'temperature-down',
  'temperature-arrow-up',
  'temperature-up',
  'temperature-empty',
  'temperature0',
  'thermometer0',
  'thermometer-empty',
  'temperature-full',
  'temperature4',
  'thermometer4',
  'thermometer-full',
  'temperature-half',
  'temperature2',
  'thermometer2',
  'thermometer-half',
  'temperature-high',
  'temperature-list',
  'temperature-low',
  'temperature-quarter',
  'temperature1',
  'thermometer1',
  'thermometer-quarter',
  'temperature-snow',
  'temperature-frigid',
  'temperature-sun',
  'temperature-hot',
  'temperature-three-quarters',
  'temperature3',
  'thermometer3',
  'thermometer-three-quarters',
  'tenge-sign',
  'tenge',
  'tennis-ball',
  'terminal',
  'text',
  'text-height',
  'text-size',
  'text-slash',
  'remove-format',
  'text-width',
  'thermometer',
  'theta',
  'thought-bubble',
  'thumbs-down',
  'thumbs-up',
  'thumbtack',
  'thumb-tack',
  'tick',
  'ticket',
  'ticket-airline',
  'ticket-simple',
  'ticket-alt',
  'tickets-airline',
  'tilde',
  'timeline',
  'timeline-arrow',
  'timer',
  'tire',
  'tire-flat',
  'tire-pressure-warning',
  'tire-rugged',
  'toggle-off',
  'toggle-on',
  'toilet',
  'toilet-paper',
  'toilet-paper-blank',
  'toilet-paper-alt',
  'toilet-paper-blank-under',
  'toilet-paper-reverse-alt',
  'toilet-paper-slash',
  'toilet-paper-under',
  'toilet-paper-reverse',
  'toilet-paper-under-slash',
  'toilet-paper-reverse-slash',
  'tomato',
  'tombstone',
  'tombstone-blank',
  'tombstone-alt',
  'toolbox',
  'tooth',
  'toothbrush',
  'torii-gate',
  'tornado',
  'tower-broadcast',
  'broadcast-tower',
  'tower-control',
  'tractor',
  'trademark',
  'traffic-cone',
  'traffic-light',
  'traffic-light-go',
  'traffic-light-slow',
  'traffic-light-stop',
  'trailer',
  'train',
  'train-subway',
  'subway',
  'train-subway-tunnel',
  'subway-tunnel',
  'train-track',
  'train-tram',
  'tram',
  'train-tunnel',
  'transformer-bolt',
  'transgender',
  'transgender-alt',
  'transporter',
  'transporter-1',
  'transporter-2',
  'transporter-3',
  'transporter-4',
  'transporter-5',
  'transporter-6',
  'transporter-7',
  'transporter-empty',
  'trash',
  'trash-arrow-up',
  'trash-restore',
  'trash-can',
  'trash-alt',
  'trash-can-arrow-up',
  'trash-restore-alt',
  'trash-can-check',
  'trash-can-clock',
  'trash-can-list',
  'trash-can-plus',
  'trash-can-slash',
  'trash-alt-slash',
  'trash-can-undo',
  'trash-can-arrow-turn-left',
  'trash-undo-alt',
  'trash-can-xmark',
  'trash-check',
  'trash-clock',
  'trash-list',
  'trash-plus',
  'trash-slash',
  'trash-undo',
  'trash-arrow-turn-left',
  'trash-xmark',
  'treasure-chest',
  'tree',
  'tree-christmas',
  'tree-deciduous',
  'tree-alt',
  'tree-decorated',
  'tree-large',
  'tree-palm',
  'trees',
  'triangle',
  'triangle-exclamation',
  'exclamation-triangle',
  'warning',
  'triangle-instrument',
  'triangle-music',
  'triangle-person-digging',
  'construction',
  'trophy',
  'trophy-star',
  'trophy-alt',
  'truck',
  'truck-bolt',
  'truck-clock',
  'shipping-timed',
  'truck-container',
  'truck-container-empty',
  'truck-fast',
  'shipping-fast',
  'truck-flatbed',
  'truck-front',
  'truck-medical',
  'ambulance',
  'truck-monster',
  'truck-moving',
  'truck-pickup',
  'truck-plow',
  'truck-ramp',
  'truck-ramp-box',
  'truck-loading',
  'truck-ramp-couch',
  'truck-couch',
  'truck-tow',
  'trumpet',
  'tty',
  'teletype',
  'tty-answer',
  'teletype-answer',
  'tugrik-sign',
  'turkey',
  'turkish-lira-sign',
  'try',
  'turkish-lira',
  'turn-down',
  'level-down-alt',
  'turn-down-left',
  'turn-down-right',
  'turn-up',
  'level-up-alt',
  'turntable',
  'turtle',
  'tv',
  'television',
  'tv-alt',
  'tv-music',
  'tv-retro',
  'typewriter',
  'u',
  'ufo',
  'ufo-beam',
  'umbrella',
  'umbrella-beach',
  'umbrella-simple',
  'umbrella-alt',
  'underline',
  'unicorn',
  'uniform-martial-arts',
  'union',
  'universal-access',
  'unlock',
  'unlock-keyhole',
  'unlock-alt',
  'up',
  'arrow-alt-up',
  'up-down',
  'arrows-alt-v',
  'up-down-left-right',
  'arrows-alt',
  'up-from-dotted-line',
  'up-from-line',
  'arrow-alt-from-bottom',
  'up-left',
  'up-long',
  'long-arrow-alt-up',
  'up-right',
  'up-right-and-down-left-from-center',
  'expand-alt',
  'up-right-from-square',
  'external-link-alt',
  'up-to-dotted-line',
  'up-to-line',
  'arrow-alt-to-top',
  'upload',
  'usb-drive',
  'user',
  'user-alien',
  'user-astronaut',
  'user-bounty-hunter',
  'user-check',
  'user-chef',
  'user-clock',
  'user-cowboy',
  'user-crown',
  'user-doctor',
  'user-md',
  'user-doctor-hair',
  'user-doctor-hair-long',
  'user-doctor-message',
  'user-md-chat',
  'user-gear',
  'user-cog',
  'user-graduate',
  'user-group',
  'user-friends',
  'user-group-crown',
  'users-crown',
  'user-hair',
  'user-hair-buns',
  'user-hair-long',
  'user-hair-mullet',
  'business-front',
  'party-back',
  'trian-balbot',
  'user-headset',
  'user-helmet-safety',
  'user-construction',
  'user-hard-hat',
  'user-injured',
  'user-large',
  'user-alt',
  'user-large-slash',
  'user-alt-slash',
  'user-lock',
  'user-minus',
  'user-music',
  'user-ninja',
  'user-nurse',
  'user-nurse-hair',
  'user-nurse-hair-long',
  'user-pen',
  'user-edit',
  'user-pilot',
  'user-pilot-tie',
  'user-plus',
  'user-police',
  'user-police-tie',
  'user-robot',
  'user-robot-xmarks',
  'user-secret',
  'user-shakespeare',
  'user-shield',
  'user-slash',
  'user-tag',
  'user-tie',
  'user-tie-hair',
  'user-tie-hair-long',
  'user-unlock',
  'user-visor',
  'user-vneck',
  'user-vneck-hair',
  'user-vneck-hair-long',
  'user-xmark',
  'user-times',
  'users',
  'users-gear',
  'users-cog',
  'users-medical',
  'users-slash',
  'utensils',
  'cutlery',
  'utensils-slash',
  'utility-pole',
  'utility-pole-double',
  'v',
  'vacuum',
  'vacuum-robot',
  'value-absolute',
  'van-shuttle',
  'shuttle-van',
  'vault',
  'vector-circle',
  'vector-polygon',
  'vector-square',
  'vent-damper',
  'venus',
  'venus-double',
  'venus-mars',
  'vest',
  'vest-patches',
  'vial',
  'vials',
  'video',
  'video-camera',
  'video-arrow-down-left',
  'video-arrow-up-right',
  'video-plus',
  'video-slash',
  'vihara',
  'violin',
  'virus',
  'virus-covid',
  'virus-covid-slash',
  'virus-slash',
  'viruses',
  'voicemail',
  'volcano',
  'volleyball',
  'volleyball-ball',
  'volume',
  'volume-medium',
  'volume-high',
  'volume-up',
  'volume-low',
  'volume-down',
  'volume-off',
  'volume-slash',
  'volume-xmark',
  'volume-mute',
  'volume-times',
  'vr-cardboard',
  'w',
  'waffle',
  'wagon-covered',
  'walker',
  'walkie-talkie',
  'wallet',
  'wand',
  'wand-magic',
  'magic',
  'wand-magic-sparkles',
  'magic-wand-sparkles',
  'wand-sparkles',
  'warehouse',
  'warehouse-full',
  'warehouse-alt',
  'washing-machine',
  'washer',
  'watch',
  'watch-apple',
  'watch-calculator',
  'watch-fitness',
  'watch-smart',
  'water',
  'water-arrow-down',
  'water-lower',
  'water-arrow-up',
  'water-rise',
  'water-ladder',
  'ladder-water',
  'swimming-pool',
  'watermelon-slice',
  'wave-pulse',
  'heart-rate',
  'wave-sine',
  'wave-square',
  'wave-triangle',
  'waveform',
  'waveform-lines',
  'waveform-path',
  'weight-hanging',
  'weight-scale',
  'weight',
  'whale',
  'wheat',
  'wheat-awn',
  'wheat-alt',
  'wheat-awn-slash',
  'wheat-slash',
  'wheelchair',
  'wheelchair-move',
  'wheelchair-alt',
  'whiskey-glass',
  'glass-whiskey',
  'whiskey-glass-ice',
  'glass-whiskey-rocks',
  'whistle',
  'wifi',
  'wifi3',
  'wifi-strong',
  'wifi-exclamation',
  'wifi-fair',
  'wifi2',
  'wifi-slash',
  'wifi-weak',
  'wifi1',
  'wind',
  'wind-turbine',
  'wind-warning',
  'wind-circle-exclamation',
  'window',
  'window-flip',
  'window-alt',
  'window-frame',
  'window-frame-open',
  'window-maximize',
  'window-minimize',
  'window-restore',
  'windsock',
  'wine-bottle',
  'wine-glass',
  'wine-glass-crack',
  'fragile',
  'wine-glass-empty',
  'wine-glass-alt',
  'won-sign',
  'krw',
  'won',
  'wreath',
  'wrench',
  'wrench-simple',
  'x',
  'x-ray',
  'xmark',
  'close',
  'multiply',
  'remove',
  'times',
  'xmark-to-slot',
  'times-to-slot',
  'vote-nay',
  'y',
  'yen-sign',
  'cny',
  'jpy',
  'rmb',
  'yen',
  'yin-yang',
  'z',
]

import React from 'react'
import Slider from 'lib/ui/Slider'
import {Controller, UseFormMethods} from 'react-hook-form'
import {
  NavButtonWithSize,
  DEFAULT_BUTTON_HEIGHT,
} from 'Event/Dashboard/components/NavButton'
import Checkbox from 'lib/ui/Checkbox'
import CollapsibleSection from 'lib/ui/ConfigPanel/CollapsibleSection'
import styled from 'styled-components'

export default function Size(props: {
  control: UseFormMethods['control']
  button: NavButtonWithSize
}) {
  const {button, control} = props

  return (
    <>
      <CollapsibleSection label="Size" storageKey="mainNav.config.styling.size">
        <Controller
          name="height"
          defaultValue={button.height || DEFAULT_BUTTON_HEIGHT}
          control={control}
          render={({value, onChange}) => (
            <Slider
              min={1}
              max={400}
              onChange={onChange}
              valueLabelDisplay="off"
              value={value}
              label="Height"
            />
          )}
        />
        <Controller
          name="size"
          defaultValue={button.size || 12}
          control={control}
          render={({value, onChange}) => (
            <Slider
              min={8}
              max={12}
              onChange={onChange}
              valueLabelDisplay="off"
              value={value}
              hideUnits
              label="Width"
            />
          )}
        />
        <Box>
          <Controller
            name="newLine"
            defaultValue={button.newLine || false}
            control={control}
            render={({value, onChange}) => (
              <Checkbox
                label="New Line"
                checked={value}
                aria-label="toggle new line"
                onChange={onChange}
              />
            )}
          />
        </Box>
      </CollapsibleSection>
    </>
  )
}

const Box = styled.div`
  margin-bottom: ${(props) => props.theme.spacing[4]};
`

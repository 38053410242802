import React from 'react'
import styled from 'styled-components'
import {parseFileLocation} from 'lib/url'

export type IconProps = {
  className?: string
  iconClass?: string | null
  color?: string
  'aria-label'?: string
  onClick?: () => void
}

export function Icon(props: IconProps) {
  const {iconClass, color, className, onClick} = props

  if (!iconClass) {
    return null
  }

  const isImage = iconClass?.startsWith('url')

  if (!isImage) {
    return (
      <StyledIcon
        onClick={onClick}
        color={color}
        className={`${iconClass} ${className}`}
        aria-label={props['aria-label']}
      />
    )
  }

  const image = parseFileLocation(iconClass)

  return (
    <img
      src={image?.url}
      alt={image?.name || ''}
      className={props.className}
      aria-label={props['aria-label']}
    />
  )
}

const StyledIcon = styled((props) => {
  const {color: _1, icon: _2, ...otherProps} = props
  return <i {...otherProps} />
})`
  color: ${(props) => props.color || props.theme.colors.text.primary};
`

import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import CustomButtonResize from './custombuttonresize';
import InsertCustomButtonUI from './insertcustombutton/insertcustombuttonui';
import ObvioCustomButtonEditing from './obviocustombuttonediting';
import './theme/style.css';
import CustomButtonText from './custombuttontext';
import CustomButtonLink from './custombuttonlink';
import CustomButtonAlignment from './custombuttonalignment';
import CustomButtonFontSize from './custombuttonfontsize';
export default class ObvioCustomButton extends Plugin {
	static get requires() {
		return [
			ObvioCustomButtonEditing,
			InsertCustomButtonUI,
			CustomButtonResize,
			CustomButtonText,
			CustomButtonLink,
			CustomButtonAlignment,
			CustomButtonFontSize
		];
	}
}

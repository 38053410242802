import React from 'react'
import LocalizedDateTimePicker from 'lib/LocalizedDateTimePicker'
import {Controller, UseFormMethods} from 'react-hook-form'
import {HasSchedule, SHOWING_FROM, SHOWING_UNTIL} from 'lib/ui/layout/Scheduled'
import {onChangeDate} from 'lib/dom'

export type ScheduleInputProps = {
  fromName?: string
  untilName?: string
  values: HasSchedule
  control: UseFormMethods['control']
  setValue: UseFormMethods['setValue']
  disabledFrom?: boolean
}

export default function ScheduleInput(props: ScheduleInputProps) {
  const {
    control,
    fromName = SHOWING_FROM,
    untilName = SHOWING_UNTIL,
    values,
    setValue,
    disabledFrom,
  } = props

  const {showingFrom = null, showingUntil = null} = values

  const onClear = (fieldName: string) => () => {
    setValue(fieldName, null)
  }

  return (
    <>
      <Controller
        name={fromName}
        control={control}
        defaultValue={showingFrom}
        render={({onChange, value}) => (
          <LocalizedDateTimePicker
            label="Show From"
            name="showingdate"
            value={value}
            onChange={onChangeDate(onChange)}
            fullWidth
            disabled={disabledFrom}
            inputProps={{
              'aria-label': 'show from',
              onChange, // to manually fire events in tests
            }}
            onClear={onClear(fromName)}
          />
        )}
      />
      <Controller
        name={untilName}
        control={control}
        defaultValue={showingUntil}
        render={({onChange, value}) => (
          <LocalizedDateTimePicker
            label="Show Until"
            value={value}
            onChange={onChangeDate(onChange)}
            fullWidth
            inputProps={{
              'aria-label': 'show until',
              onChange,
            }}
            onClear={onClear(untilName)}
          />
        )}
      />
    </>
  )
}

import FormControlLabel from '@material-ui/core/FormControlLabel'
import styled from 'styled-components'
import Switch from '@material-ui/core/Switch'
import React from 'react'
import grey from '@material-ui/core/colors/grey'
import {useEditMode, useSetEditMode} from 'Event/EditModeProvider'
import TogglePreviewConfigButton from 'Event/Dashboard/editor/views/ConfigBar/TogglePreviewConfigButton'
import PreviewConfig from 'Event/Dashboard/editor/views/ConfigBar/PreviewConfig'
import {useToggleArray} from 'lib/toggle'

export default function ConfigBar() {
  const isEditMode = useEditMode()
  const setIsEditMode = useSetEditMode()
  const [showingPreviewConfig, togglePreviewConfig] = useToggleArray()

  const toggle = () => setIsEditMode(!isEditMode)

  return (
    <Box>
      <FormControlLabel
        control={
          <Switch
            checked={!isEditMode}
            onChange={toggle}
            color="primary"
            aria-label="toggle preview mode"
          />
        }
        label="Preview"
      />
      <TogglePreviewConfigButton onClick={togglePreviewConfig} />
      <PreviewConfig
        showing={showingPreviewConfig}
        onClose={togglePreviewConfig}
      />
    </Box>
  )
}

const Box = styled.div`
  height: 50px;
  border-bottom: 1px solid ${grey[300]};
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 ${(props) => props.theme.spacing[4]};
`

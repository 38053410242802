import {LoginProps} from 'Event/auth/Login'
import styled from 'styled-components'
import React from 'react'
import Typography from '@material-ui/core/Typography'
import MuiButton, {ButtonProps} from '@material-ui/core/Button'
import {spacing} from 'lib/ui/theme'
import {useTownhallTemplate} from 'Event/template/Townhall'
import Paper from '@material-ui/core/Paper'
import Background, {ColorOverlay, Content} from 'Event/auth/Login/Background'
import BaseLogo from 'Event/auth/Login/Logo'
import Text from 'Event/auth/Login/Description/Text'
import Grid from '@material-ui/core/Grid'
import LeftPanel from 'Event/template/Townhall/Login/LeftPanel'
import RightPanel from 'Event/template/Townhall/Login/RightPanel'

export default function Page(props: {
  isPreview?: LoginProps['isPreview']
  children: React.ReactElement | React.ReactElement[]
}) {
  return (
    <StyledBackground isPreview={props.isPreview}>
      <StyledPaper>
        <Grid container>
          <LeftPanel />
          <RightPanel>{props.children}</RightPanel>
        </Grid>
      </StyledPaper>
    </StyledBackground>
  )
}

export function ErrorMessage(props: {children?: string}) {
  if (!props.children) {
    return null
  }

  return <ErrorText color="error">{props.children}</ErrorText>
}

export function Button(props: ButtonProps) {
  const template = useTownhallTemplate()
  const {login} = template
  const borderRadius = `${login.submitButton.borderRadius}px` || spacing[14]
  const hoverColor =
    login.submitButton.hoverColor || login.submitButton.backgroundColor
  return (
    <StyledButton
      variant="contained"
      fullWidth
      backgroundColor={login.submitButton.backgroundColor}
      color={login.submitButton.textColor}
      borderRadius={borderRadius}
      hoverColor={hoverColor}
      {...props}
    />
  )
}

export const DescriptionText = styled(Text)<{
  textColor?: string
  loginColor?: string | null
}>`
  color: ${(props) =>
    props.loginColor ? props.loginColor : props.textColor} !important;
  text-align: left;
  margin-bottom: ${(props) => props.theme.spacing[4]};
`

const StyledBackground = styled(Background)`
  transition: all 300ms ease-in 200ms;
  display: flex;
  background-size: cover;
  background-position: center;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  ${ColorOverlay} {
    flex-direction: column;

    @media (min-width: ${(props) => props.theme.breakpoints.lg}) {
      flex-direction: row;
    }

    @media (min-width: ${(props) => props.theme.breakpoints.xl}) {
      display: block;
    }
  }

  ${Content} {
    padding: unset;
    height: 100%;
    @media (min-width: ${(props) => props.theme.breakpoints.sm}) {
      width: 100%;
      margin-bottom: 0;
    }

    @media (min-width: ${(props) => props.theme.breakpoints.lg}) {
      display: flex;
      flex-direction: row;
      margin: unset;
      width: 100%;
    }
  }
`

export const StyledButton = styled(
  ({
    color: _1,
    backgroundColor: _2,
    borderRadius: _3,
    hoverColor: _4,
    ...otherProps
  }) => <MuiButton {...otherProps} />,
)`
  border-radius: ${(props) => props.borderRadius} !important;
  height: 50px;
  color: ${(props) => props.color} !important;
  background-color: ${(props) => props.backgroundColor} !important;

  &:hover {
    background-color: ${(props) => props.hoverColor} !important;
  }
`

export const StyledPaper = styled(Paper)`
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  vertical-align: middle;
  margin: auto;
  overflow: auto;
  border-radius: 4px;
  min-height: 500px;
  background-color: unset;

  @media (min-width: ${(props) => props.theme.breakpoints.sm}) {
    height: unset;
    width: 380px;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    height: unset;
    width: 600px;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.lg}) {
    width: 960px;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.xl}) {
    height: unset;
    width: 1200px;
  }
`

export const StyledFormContainer = styled.div`
  width: 100%;
  margin: auto;
`

export const ErrorText = styled(Typography)`
  margin-bottom: ${(props) => props.theme.spacing[3]}!important;
  text-align: center;
`

export const Logo = styled(BaseLogo)`
  width: 100%;
  text-align: center;
  padding: ${(props) => props.theme.spacing[4]};
  margin: auto;

  @media (min-width: ${(props) => props.theme.breakpoints.sm}) {
    width: 100%;
  }
  @media (min-width: ${(props) => props.theme.breakpoints.lg}) {
    width: 70%;
  }
`

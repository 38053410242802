import React from 'react'
import {useForm} from 'react-hook-form'
import ComponentConfig, {
  ComponentConfigProps,
  SaveButton,
  Footer,
} from 'organization/Event/DashboardConfig/ComponentConfigPanel'
import {NiftyFifty, useNiftyFiftyUpdate} from 'Event/template/NiftyFifty'
import Styling from 'Event/template/NiftyFifty/Dashboard/LeftPanel/LeftPanelConfig/Styling'
import StylingPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/StylingPanel'
import {usePruneAssets} from 'lib/asset'
import {useTemplateEditor} from 'organization/Event/TemplateEditor'

export default function LeftPanelConfig(props: ComponentConfigProps) {
  const {showing: isVisible, onClose} = props

  const update = useNiftyFiftyUpdate()
  const {control, handleSubmit, formState} = useForm()
  const pruneAssets = usePruneAssets()
  const {saved} = useTemplateEditor<NiftyFifty>()

  const submit = (data: NiftyFifty) => {
    pruneAssets(
      {image: data.dashboardBackground.image},
      saved.dashboardBackground,
    )
    pruneAssets({image: data.dashboardLogo?.image}, saved.dashboardLogo)

    update(data)

    onClose()
  }

  return (
    <ComponentConfig
      showing={isVisible}
      onClose={onClose}
      title="Left Panel Config"
      onSubmit={handleSubmit(submit)}
      hasChanges={formState.isDirty}
    >
      <StylingPanel>
        <Styling control={control} />
      </StylingPanel>
      <Footer>
        <SaveButton type="submit" />
      </Footer>
    </ComponentConfig>
  )
}

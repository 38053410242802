import {useWaiver} from 'Event/Step2/Waivers/Waiver'
import {useTemplate} from 'Event/TemplateProvider'
import {useSaveTemplate} from 'Event/TemplateUpdateProvider'
import {orderedIdsByPosition} from 'lib/list'
import TextField from 'lib/ui/TextField'
import {ENTERPRISE} from 'obvio/Billing/plans'
import IfOwnerHasPlan from 'organization/auth/IfOwnerPlan'
import React from 'react'

export default function TitleField() {
  const {waiver, id} = useWaiver()
  const saveTemplate = useSaveTemplate()
  const template = useTemplate()
  const ids = orderedIdsByPosition(template.waiver.targets)
  const hasAdditionalWaivers = ids.length > 1

  if (!hasAdditionalWaivers) {
    return null
  }

  const save = (title: string) =>
    saveTemplate({
      waiver: {
        targets: {
          [id]: {
            title,
          },
        },
      },
    })

  return (
    <IfOwnerHasPlan plan={ENTERPRISE}>
      <TextField
        label="File Title (optional)"
        helperText="Defaults to the event name"
        value={waiver.title}
        onChange={save}
        variant="outlined"
        aria-label="waiver title"
      />
    </IfOwnerHasPlan>
  )
}

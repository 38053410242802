import {TeamMember} from 'auth/user'
import {api} from 'lib/url'
import {teamMemberClient} from 'obvio/obvio-client'

export function usePurchaseAddOns() {
  const url = api(`/purchase/add_ons`)

  return (params: {
    quantity: number
    duration?: number
    addOnKey: string
    paymentMethodId: string
    paymentIntentId?: string
    organizationId?: number
  }) => {
    const body = () => {
      const base = {
        quantity: params.quantity,
        duration: params.duration,
        add_on_key: params.addOnKey,
        payment_method_id: params.paymentMethodId,
        organization_id: params.organizationId,
      }

      // Payment intent is optional, and is only used for one-off
      // purchase to an existing card, where a payment intent
      // was first previously created.
      if (!params.paymentIntentId) {
        return base
      }

      return {
        ...base,
        payment_intent_id: params.paymentIntentId,
      }
    }

    return teamMemberClient.post<TeamMember>(url, body())
  }
}

import {colors} from 'lib/ui/theme'
import React from 'react'
import styled from 'styled-components'

export type IconProps = {
  className?: string
  color?: string
  iconSize?: number
  onClick?: () => void
  title?: string
  'aria-label'?: string
}

export default function Icon(props: IconProps) {
  return (
    <StyledIcon
      aria-label={props['aria-label']}
      title={props.title}
      className={props.className}
      iconSize={props.iconSize}
      color={textColor(props.color)}
      onClick={props.onClick}
    />
  )
}

export function ChevronDownIcon(props: IconProps) {
  return <Icon {...props} className="fas fa-chevron-down" />
}

export function ChevronRightIcon(props: IconProps) {
  return <Icon {...props} className="fas fa-chevron-right" />
}

export function MenuIcon(props: IconProps) {
  return <Icon {...props} className="fas fa-ellipsis-h" />
}

export function PlusIcon(props: IconProps) {
  return <Icon {...props} className="fas fa-plus" />
}

export function MinusIcon(props: IconProps) {
  return <Icon {...props} className="fas fa-minus" />
}

export function UserFilledIcon(props: IconProps) {
  return <Icon {...props} className="fa-solid fa-user" />
}

export function GridIcon(props: IconProps) {
  return <Icon {...props} className="fa-solid fa-grid-2" />
}

export function ListIcon(props: IconProps) {
  return <Icon {...props} className="fa-solid fa-list" />
}

export const BoothIcon = createIcon('fal fa-person-booth')
export const CalendarIcon = createIcon('fal fa-calendar-alt')
export const ChartIcon = createIcon('fal fa-chart-line')
export const CheckIcon = createIcon('fas fa-circle-check')
export const CheckSquare = createIcon('fas fa-square-check')
export const CircleDotIcon = createIcon('fa-regular fa-circle-dot')
export const CircleUserIcon = createIcon('fa fa-circle-user')
export const ClipboardIcon = createIcon('fal fa-tasks')
export const ClockIcon = createIcon('fa-light fa-clock')
export const CloseIcon = createIcon('fas fa-times')
export const CogIcon = createIcon('fal fa-cogs')
export const CollapseIcon = createIcon('fas fa-compress-alt')
export const CropIcon = createIcon('fal fa-crop')
export const DownloadIcon = createIcon('fa-solid fa-file-arrow-down')
export const EmojiIcon = createIcon('fa-regular fa-face-smile')
export const ExpandIcon = createIcon('fas fa-expand-alt')
export const HouseIcon = createIcon('fa-regular fa-house')
export const ImageWaterfallIcon = createIcon('fa-solid fa-image')
export const InfoIcon = createIcon('fal fa-info-circle')
export const InfoRegularIcon = createIcon('fa-regular fa-info-circle')
export const InfoSquareIcon = createIcon('fas fa-square-info')
export const InvisibleIcon = createIcon('fas fa-eye-slash')
export const LinkIcon = createIcon('fa-solid fas fa-link')
export const MarketplaceIcon = createIcon('fal fa-shopping-cart')
export const MegaphoneIcon = createIcon('fal fa-megaphone')
export const MoveDown = createIcon('fa-solid fa-arrow-down')
export const MoveUp = createIcon('fa-solid fa-arrow-up')
export const PanoramaIcon = createIcon('fa-solid fa-panorama')
export const PeopleIcon = createIcon('fal fa-users')
export const PowerOffIcon = createIcon('fal fa-power-off')
export const PowerOnIcon = createIcon('fas fa-power-off')
export const SidebarIcon = createIcon('fa-regular fa-sidebar-flip')
export const TimesCircle = createIcon('fas fa-times-circle')
export const TimesSolid = createIcon('fa-solid fa-xmark')
export const TimesSquare = createIcon('fas fa-square-xmark')
export const TrashCan = createIcon('fa-regular fa-trash')
export const TowerCellIcon = createIcon('fa-light fa-tower-cell')
export const UserIcon = createIcon('fal fa-user')
export const VisibleIcon = createIcon('fas fa-eye')
export const WindowIcon = createIcon('fa-regular fa-window-maximize')

function createIcon(classes: string) {
  return (props: IconProps) => (
    <Icon {...props} className={`${classes} ${props.className || ''}`} />
  )
}

function textColor(color?: string) {
  if (color === 'danger') {
    return colors.error
  }

  if (color === 'primary') {
    return colors.primary
  }

  if (color === 'info') {
    return colors.info
  }

  // If a literal color, ie., #fafafa, was provided, we'll just
  // return it directly.
  return color
}

const StyledIcon = styled.i<IconProps>`
  color: ${(props) => props.color};
  ${(props) => (props.iconSize ? `font-size: ${props.iconSize}px` : '')}
`

import React from 'react'
import Select from 'lib/ui/Select'
import Option from 'lib/ui/Select/Option'
import {onUnknownChangeHandler} from 'lib/dom'

export type BackgroundRepeatSetting =
  | 'none'
  | 'full'
  | 'full-center-parallax'
  | 'fill-width'
  | 'fill-width-height'
  | 'no-repeat'
  | 'repeat'
  | 'repeat-x'
  | 'repeat-y'

export type BackgroundRepeatSelectorProps = {
  showing?: boolean
  label: string
  value: BackgroundRepeatSetting
  onChange: (repeat: BackgroundRepeatSetting) => void
}

export default function BackgroundRepeatSelector(
  props: BackgroundRepeatSelectorProps,
) {
  const {showing = true, label, value, onChange} = props

  if (!showing) {
    return null
  }

  return (
    <Select
      fullWidth
      label={label}
      value={value}
      onChange={onUnknownChangeHandler(onChange)}
    >
      <Option value="none">None</Option>
      <Option value="full">Full</Option>
      <Option value="full-center-parallax">Full Center (Parallax)</Option>
      <Option value="fill-width">Fill 100% Width</Option>
      <Option value="fill-width-height">Fill 100% Width & Height</Option>
      <Option value="no-repeat">No Repeat</Option>
      <Option value="repeat">Repeat</Option>
      <Option value="repeat-x">Repeat Horizontally</Option>
      <Option value="repeat-y">Repeat Vertically</Option>
    </Select>
  )
}

import {useLeftyTemplate, useLeftyUpdate} from 'Event/template/Lefty'
import ColorPicker from 'lib/ui/ColorPicker'
import {useConfig} from 'organization/Event/Configurable'
import Config from 'organization/Event/Configurable/Config'
import StylingPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/StylingPanel'
import React from 'react'
import {Controller} from 'react-hook-form'
import Slider from 'lib/ui/Slider'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import Label from 'lib/ui/TextField/Label'
import ImageAssetUploader from 'lib/asset/ImageAssetUploader'
import CollapsibleSection from 'lib/ui/ConfigPanel/CollapsibleSection'

export default function PageConfig() {
  const save = useLeftyUpdate()
  const {checkIn} = useLeftyTemplate()
  const {
    form: {control},
  } = useConfig()

  return (
    <Config title="Page Config" onSave={save}>
      <SettingsPanel>
        <Label>Page Background</Label>
        <Controller
          name="checkIn.page.backgroundImage"
          control={control}
          defaultValue={checkIn.page.backgroundImage}
          render={({onChange, value}) => (
            <ImageAssetUploader
              onChange={onChange}
              value={value}
              uploadLabel="Upload"
              canResize
              uploadInputProps={{
                'aria-label': 'check in page background image upload',
              }}
              disableAutoRemove
            />
          )}
        />
      </SettingsPanel>
      <StylingPanel>
        <CollapsibleSection label="Background">
          <Controller
            name="checkIn.page.backgroundColor"
            defaultValue={checkIn.page.backgroundColor}
            control={control}
            render={({value, onChange}) => (
              <ColorPicker
                label="Color"
                color={value}
                onPick={onChange}
                aria-label="checkin page background color"
              />
            )}
          />
          <Controller
            name="checkIn.page.backgroundOpacity"
            defaultValue={checkIn.page.backgroundOpacity}
            control={control}
            render={({value, onChange}) => (
              <Slider
                data-testid="checkin-page-opacity-slider"
                label="Opacity"
                aria-label="checkin page background color opacity"
                value={value}
                onChange={onChange}
                step={1}
                min={0}
                max={100}
                unit="%"
              />
            )}
          />
        </CollapsibleSection>
      </StylingPanel>
    </Config>
  )
}

import React, {useCallback} from 'react'
import {Agenda} from 'Event/template/Townhall/Dashboard/Main/AgendaSection'
import {
  REMOVE,
  useRemoveIfEmpty,
  useSaveTemplate,
} from 'Event/TemplateUpdateProvider'
import Settings from 'Event/template/Townhall/Dashboard/Main/AgendaSection/AgendaItemConfig/Settings'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import Config from 'organization/Event/Configurable/Config'

export function AgendaItemConfig(props: {
  agenda: Agenda
  id: string
  sectionId: string
}) {
  const {agenda, id, sectionId} = props

  const save = useSaveTemplate()

  const remove = useCallback(() => {
    if (!id) {
      throw new Error("Missing 'id' of agenda to remove.")
    }

    save({
      dashboardSections: {
        [sectionId]: {
          items: {
            [id]: REMOVE,
          },
        },
      },
    })
  }, [id, sectionId, save])

  useRemoveIfEmpty(remove, agenda)

  return (
    <Config title="Agenda" onSave={save} onRemove={remove}>
      <SettingsPanel>
        <Settings agenda={agenda} sectionId={sectionId} id={id} />
      </SettingsPanel>
    </Config>
  )
}

import React from 'react'
import {NavLink} from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import styled from 'styled-components'
import {
  APPROVED,
  ImageEntry,
  AttendeeName,
  REJECTED,
} from 'organization/Event/ImageEntriesProvider'
import Button from '@material-ui/core/Button'
import {useImageEntries} from 'organization/Event/ImageEntriesProvider'
import DangerButton from 'lib/ui/Button/DangerButton'
import {useToggle} from 'lib/toggle'
import {useEventRoutes} from 'organization/Event/EventRoutes'

export default function ImageEntryCard(props: {
  entry: ImageEntry
  attendeeName: AttendeeName
  setSelected: (entry: ImageEntry) => void
}) {
  const {entry, attendeeName, setSelected} = props
  const routes = useEventRoutes()
  const {approve, reject} = useImageEntries()

  const {flag: processing, toggle: toggleProcessing} = useToggle()

  const handleAction = (action: () => Promise<void>) => () => {
    if (processing) {
      return
    }

    toggleProcessing()

    action().catch(toggleProcessing)
  }

  const canSet = (status: ImageEntry['status']) =>
    !processing && entry.status !== status

  const attendeeNameString = `${attendeeName?.first_name || ''} ${
    attendeeName?.last_name || ''
  }`
  const attendeeRoute = `${routes.attendees}?search=${attendeeNameString}`

  return (
    <ImageEntryCardContainer item container direction="column">
      <ImageContainer container onClick={() => setSelected(entry)}>
        <ImageItem image={`${entry.file.url}`} aria-label="image entry" />
      </ImageContainer>
      <CenteredNavLink
        to={attendeeRoute}
        aria-label={`attendee link ${attendeeNameString}`}
      >
        {attendeeNameString}
      </CenteredNavLink>
      <StyledButton
        variant="contained"
        color="primary"
        onClick={handleAction(() => approve(entry))}
        disabled={!canSet(APPROVED)}
        aria-label="approve button"
      >
        Approve
      </StyledButton>
      <DangerButton
        variant="contained"
        color="primary"
        onClick={handleAction(() => reject(entry))}
        disabled={!canSet(REJECTED)}
        aria-label="reject button"
      >
        Reject
      </DangerButton>
    </ImageEntryCardContainer>
  )
}

const ImageEntryCardContainer = styled((props: any) => {
  return <Grid {...props} />
})`
  margin: ${(props) => props.theme.spacing[3]};
  width: 180px;
  transition: transform 0.5s ease-in-out;
  z-index: 0;
`

const ImageContainer = styled((props: any) => {
  return <Grid {...props} />
})`
  height: 200px;
  margin-bottom: ${(props) => props.theme.spacing[2]};
`

const ImageItem = styled.div<{image: string}>`
  background-image: url(${(props) => props.image});
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
`

const StyledButton = styled(Button)`
  margin-bottom: ${(props) => props.theme.spacing[2]};
`

const CenteredNavLink = styled(NavLink)`
  margin-bottom: ${(props) => props.theme.spacing[2]};
  max-width: 100%;
  overflow: hidden;
  text-align: center;
`

import {MenuIconButton} from 'lib/ui/IconButton/MenuIconButton'
import styled from 'styled-components'
import {useLayout} from 'organization/Event/Layout'
import React from 'react'

export default function MobileSidebarButton() {
  const {showingMobileSidebar, toggleMobileSidebar} = useLayout()
  return (
    <StyledMenuIconButton
      active={showingMobileSidebar}
      onClick={toggleMobileSidebar}
    />
  )
}

const StyledMenuIconButton = styled(MenuIconButton)`
  // Show above logo
  z-index: 5;
`

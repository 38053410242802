import React from 'react'
import styled from 'styled-components'

type TypographyProps = {
  align?: string
  children: React.ReactNode | string
  color?: string
  fontSize?: number
  fontWeight?: number | string
  lineHeight?: number
  margin?: string
  mb?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8
  ml?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8
  mr?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8
  mt?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8
}

export const Title = styled.h1<TypographyProps>`
  font-weight: bold;
  font-size: 36px;
  line-height: 43px;
  color: ${(props) => props.color || props.theme.colors.text.primary};
  margin-block-start: 0;
  margin-block-end: 0;
  margin: ${(props) => (props.margin ? props.margin : null)};
  margin-bottom: ${(props) =>
    props.mb ? props.theme.spacing[props.mb] : null};
  margin-top: ${(props) => (props.mt ? props.theme.spacing[props.mt] : null)};
  margin-left: ${(props) => (props.ml ? props.theme.spacing[props.ml] : null)};
  margin-right: ${(props) => (props.mr ? props.theme.spacing[props.mr] : null)};
  text-align: ${(props) => (props.align ? props.align : null)};
  font-family: 'Rubik', sans-serif;
`

export const Header = styled.h2<TypographyProps>`
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  color: ${(props) => props.color || props.theme.colors.text.primary};
  margin-block-start: 0;
  margin-block-end: 0;
  margin: ${(props) => (props.margin ? props.margin : null)};
  margin-bottom: ${(props) =>
    props.mb ? props.theme.spacing[props.mb] : null};
  margin-top: ${(props) => (props.mt ? props.theme.spacing[props.mt] : null)};
  margin-left: ${(props) => (props.ml ? props.theme.spacing[props.ml] : null)};
  margin-right: ${(props) => (props.mr ? props.theme.spacing[props.mr] : null)};
  text-align: ${(props) => (props.align ? props.align : null)};
`

export const SubHead = styled.h3<TypographyProps>`
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: ${(props) => props.color || props.theme.colors.text.primary};
  margin-block-start: 0;
  margin-block-end: 0;
  margin: ${(props) => (props.margin ? props.margin : null)};
  margin-bottom: ${(props) =>
    props.mb ? props.theme.spacing[props.mb] : null};
  margin-top: ${(props) => (props.mt ? props.theme.spacing[props.mt] : null)};
  margin-left: ${(props) => (props.ml ? props.theme.spacing[props.ml] : null)};
  margin-right: ${(props) => (props.mr ? props.theme.spacing[props.mr] : null)};
  text-align: ${(props) => (props.align ? props.align : null)};
`

export const TableHeader = styled.h4<TypographyProps>`
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: ${(props) => props.color || props.theme.colors.text.primary};
  margin-block-start: 0;
  margin-block-end: 0;
  margin: ${(props) => (props.margin ? props.margin : null)};
  margin-bottom: ${(props) =>
    props.mb ? props.theme.spacing[props.mb] : null};
  margin-top: ${(props) => (props.mt ? props.theme.spacing[props.mt] : null)};
  margin-left: ${(props) => (props.ml ? props.theme.spacing[props.ml] : null)};
  margin-right: ${(props) => (props.mr ? props.theme.spacing[props.mr] : null)};
  text-align: ${(props) => (props.align ? props.align : null)};
  line-height: 19px;
`

export const Label = styled.span<TypographyProps>`
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  color: ${(props) => props.theme.colors.text.primary};
  display: block;
  margin: ${(props) => (props.margin ? props.margin : null)};
  margin-bottom: ${(props) =>
    props.mb ? props.theme.spacing[props.mb] : null};
  margin-top: ${(props) => (props.mt ? props.theme.spacing[props.mt] : null)};
  margin-left: ${(props) => (props.ml ? props.theme.spacing[props.ml] : null)};
  margin-right: ${(props) => (props.mr ? props.theme.spacing[props.mr] : null)};
`

export const MenuItemLabel = styled.span<TypographyProps>`
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: ${(props) => (props.color ? props.color : '#4f4f4f')};
  margin: ${(props) => (props.margin ? props.margin : null)};
  margin-bottom: ${(props) =>
    props.mb ? props.theme.spacing[props.mb] : null};
  margin-top: ${(props) => (props.mt ? props.theme.spacing[props.mt] : null)};
  margin-left: ${(props) => (props.ml ? props.theme.spacing[props.ml] : null)};
  margin-right: ${(props) => (props.mr ? props.theme.spacing[props.mr] : null)};
`

export const Tiny = styled.span<TypographyProps>`
  font-weight: 300;
  font-size: 12px;
  line-height: 14px;
  color: ${(props) => (props.color ? props.color : '#2794d2')};
  margin: ${(props) => (props.margin ? props.margin : null)};
  margin-bottom: ${(props) =>
    props.mb ? props.theme.spacing[props.mb] : null};
  margin-top: ${(props) => (props.mt ? props.theme.spacing[props.mt] : null)};
  margin-left: ${(props) => (props.ml ? props.theme.spacing[props.ml] : null)};
  margin-right: ${(props) => (props.mr ? props.theme.spacing[props.mr] : null)};
  text-align: ${(props) => props.align || null};
`

export const Description = styled.p<TypographyProps>`
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
  color: ${(props) => (props.color ? props.color : '#939393')};
  margin-block-start: 0;
  margin-block-end: 0;
  margin: ${(props) => (props.margin ? props.margin : null)};
  margin-bottom: ${(props) =>
    props.mb ? props.theme.spacing[props.mb] : null};
  margin-top: ${(props) => (props.mt ? props.theme.spacing[props.mt] : null)};
  margin-left: ${(props) => (props.ml ? props.theme.spacing[props.ml] : null)};
  margin-right: ${(props) => (props.mr ? props.theme.spacing[props.mr] : null)};
  text-align: ${(props) => (props.align ? props.align : null)};
  font-family: 'Rubik', sans-serif;
`

export const Text = styled.p<TypographyProps>`
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: ${(props) => props.color || props.theme.colors.text.primary};
  margin-block-start: 0;
  margin-block-end: 0;
  margin: ${(props) => (props.margin ? props.margin : null)};
  margin-bottom: ${(props) =>
    props.mb ? props.theme.spacing[props.mb] : null};
  margin-top: ${(props) => (props.mt ? props.theme.spacing[props.mt] : null)};
  margin-left: ${(props) => (props.ml ? props.theme.spacing[props.ml] : null)};
  margin-right: ${(props) => (props.mr ? props.theme.spacing[props.mr] : null)};
  text-align: ${(props) => (props.align ? props.align : null)};
`

export const Typography = styled.p<TypographyProps>`
  font-weight: ${(props) => props.fontWeight || 'unset'};
  font-size: ${(props) => (props.fontSize ? `${props.fontSize}px` : 'unset')};
  line-height: ${(props) =>
    props.lineHeight ? `${props.lineHeight}px` : 'unset'};
  color: ${(props) => props.color || 'unset'};
  margin-block-start: 0;
  margin-block-end: 0;
  margin: ${(props) => (props.margin ? props.margin : null)};
  margin-bottom: ${(props) =>
    props.mb ? props.theme.spacing[props.mb] : null};
  margin-top: ${(props) => (props.mt ? props.theme.spacing[props.mt] : null)};
  margin-left: ${(props) => (props.ml ? props.theme.spacing[props.ml] : null)};
  margin-right: ${(props) => (props.mr ? props.theme.spacing[props.mr] : null)};
  text-align: ${(props) => (props.align ? props.align : null)};
`

export const ErrorMessage = styled(Typography)<TypographyProps>`
  color: ${(props) => props.theme.colors.error};
  font-size: 12px !important;
  font-weight: normal !important;
  line-height: 16px !important;
  margin: ${(props) => (props.margin ? props.margin : null)};
  margin-bottom: ${(props) =>
    props.mb ? props.theme.spacing[props.mb] : null};
  margin-top: ${(props) => (props.mt ? props.theme.spacing[props.mt] : null)};
  margin-left: ${(props) => (props.ml ? props.theme.spacing[props.ml] : null)};
  margin-right: ${(props) => (props.mr ? props.theme.spacing[props.mr] : null)};
  text-align: ${(props) => (props.align ? props.align : null)};
`

export const ControlLabel = styled.span<TypographyProps>`
  color: ${(props) => props.color || props.theme.colors.text.primary};
  display: block;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  margin: ${(props) => (props.margin ? props.margin : null)};
  margin-bottom: ${(props) =>
    props.mb ? props.theme.spacing[props.mb] : '12px'};
  margin-top: ${(props) => (props.mt ? props.theme.spacing[props.mt] : null)};
  margin-left: ${(props) => (props.ml ? props.theme.spacing[props.ml] : null)};
  margin-right: ${(props) => (props.mr ? props.theme.spacing[props.mr] : null)};
`

import React from 'react'
import {User} from 'auth/user'
import SpeakerHeader from 'Event/template/Lefty/Speakers/SpeakersHeader'
import FeaturedSpeakersList from 'Event/template/Lefty/Speakers/FeaturedSpeakersList'
import SpeakersList from 'Event/template/Lefty/Speakers/SpeakersList'
import {LeftyBreadcrumbs} from 'Event/template/Lefty/Page'
import {Speaker} from 'Event/Speakers'
import {NavButtonProps} from 'Event/Dashboard/components/NavButton'
import FeaturePage from 'Event/template/Lefty/Page'
import {FeaturePageTitle, StyledConfigurable} from 'Event/template/Lefty/Page'
import {useLeftyTemplate} from 'Event/template/Lefty'
import {useAttendeeVariables} from 'Event'
import SpeakersHeaderConfig from 'Event/template/Lefty/Speakers/SpeakersHeaderConfig'

export type LeftySpeaker = Speaker & {
  button?: NavButtonProps
}

export default function SpeakerPage(props: {user: User}) {
  const template = useLeftyTemplate()
  const {speakers} = template
  const v = useAttendeeVariables()

  return (
    <FeaturePage user={props.user}>
      <StyledConfigurable>
        <SpeakersHeaderConfig />
        <>
          <FeaturePageTitle aria-label="speakers title">
            {v(speakers.title)}
          </FeaturePageTitle>
          <LeftyBreadcrumbs page={v(speakers.title)} />
          <SpeakerHeader />
        </>
      </StyledConfigurable>
      <FeaturedSpeakersList />
      <SpeakersList />
    </FeaturePage>
  )
}

import {useMarketplaceSampleEvents} from 'lib/event-api/event/use-marketplace-purchase-page-templates'
import React, {useState} from 'react'
import PageHeader from 'lib/ui/PageHeader'
import Title from 'lib/ui/PageHeader/Title'
import Grid from '@material-ui/core/Grid'
import Page from 'organization/Event/Page'
import Switch from 'lib/ui/form/Switch'
import {onChangeCheckedHandler} from 'lib/dom'
import Visible from 'lib/ui/layout/Visible'
import ObvioEventsGrid from 'organization/Marketplace/PurchasePageConfig/Content/TemplateSelector/ObvioEventsGrid'
import {usePurchasePageEvents} from 'lib/marketplace-api/purchase-page/use-purchase-page-events'
import LoadingGrid from 'organization/Marketplace/PurchasePageConfig/Content/TemplateSelector/LoadingGrid'
import UserEventsGrid from 'organization/Marketplace/PurchasePageConfig/Content/TemplateSelector/UserEventsGrid'

export default function TemplateSelector() {
  const [showOwnEvents, setShowOwnEvents] = useState(false)

  const {data: sampleEvents} = useMarketplaceSampleEvents()
  const {data: userEvents} = usePurchasePageEvents()

  if (!userEvents || !sampleEvents) {
    return <LoadingGrid />
  }

  const hasUserEvents = userEvents.length > 0

  return (
    <Page>
      <PageHeader>
        <Title text="Choose Your Purchase Page's Template" />
        <Visible when={hasUserEvents}>
          <Switch
            checked={showOwnEvents}
            labelPlacement="end"
            label={
              showOwnEvents
                ? 'Show my own events'
                : 'Show obvio designed events'
            }
            aria-label="switch templates"
            onChange={onChangeCheckedHandler(setShowOwnEvents)}
          />
        </Visible>
      </PageHeader>
      <Grid container spacing={2}>
        <Visible when={showOwnEvents}>
          <UserEventsGrid events={userEvents} />
        </Visible>
        <Visible when={!showOwnEvents}>
          <ObvioEventsGrid events={sampleEvents} />
        </Visible>
      </Grid>
    </Page>
  )
}

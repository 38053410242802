import React from 'react'
import styled from 'styled-components'
import {api} from 'lib/url'
import {useEvent} from 'Event/EventProvider'
import {useToggleArray} from 'lib/toggle'
import {useRoomControls} from 'Event/Dashboard/TeamAccessControls/RoomControls'
import {Room} from 'Event/room'
import Button from 'lib/ui/Button'

export default function TurnOffRoomButton() {
  const {room, setRoom} = useRoomControls()
  const {client} = useEvent()
  const [processing, toggleProcessing] = useToggleArray()

  const turnOffRoom = () => {
    if (processing) {
      return
    }

    toggleProcessing()

    const url = api(`/rooms/${room.id}/stop`)
    client
      .patch<Room>(url)
      .then((updated) =>
        setRoom({
          ...room,
          ...updated,
        }),
      )
      .finally(toggleProcessing)
  }

  const canStopRoom = room.is_online && !processing

  return (
    <StyledButton
      variant="outlined"
      color="danger"
      fullWidth
      aria-label="turn off room"
      onClick={turnOffRoom}
      disabled={!canStopRoom}
    >
      Turn Off Room
    </StyledButton>
  )
}

const StyledButton = styled(Button)`
  margin-top: 10px;
`

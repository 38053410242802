import React from 'react'
import styled from 'styled-components'
import Typography from '@material-ui/core/Typography'
import {useGuestVariables} from 'Event'
import {useTemplate} from 'Event/TemplateProvider'
import Configurable from 'organization/Event/Configurable'
import ErrorMessageConfig from './ErrorMessageConfig'

const EMAIL_ALREADY_TAKEN = 'The email has already been taken.'

export default function ErrorMessage(props: {error?: string}) {
  return (
    <Configurable aria-label="edit error message">
      <ErrorMessageConfig />
      <ErrorContent {...props} />
    </Configurable>
  )
}

function ErrorContent(props: {error?: string}) {
  const v = useGuestVariables()
  const {signUp} = useTemplate()

  if (!props.error) {
    return null
  }

  if (props.error !== EMAIL_ALREADY_TAKEN) {
    return <ErrorText color="error">{props.error}</ErrorText>
  }

  return (
    <ErrorText color="error">
      {v(signUp.error.emailAlreadyTakenMessage)}
    </ErrorText>
  )
}

export const ErrorText = styled(Typography)`
  margin-bottom: ${(props) => props.theme.spacing[3]}!important;
  text-align: center;
`

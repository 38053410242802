import React from 'react'
import Text from 'Event/auth/Login/Description/Text'
import Configurable from 'organization/Event/Configurable'
import DescriptionConfig from 'Event/auth/SignUp/Description/DescriptionConfig'

export default function Description(props: {
  className?: string
  Component?: React.FC
  children: string
}) {
  const {children} = props

  const Component = props.Component || Text

  return (
    <Configurable>
      <DescriptionConfig />
      <Component className={props.className}>{children}</Component>
    </Configurable>
  )
}

import React, {useRef} from 'react'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableCell from '@material-ui/core/TableCell'
import TableBody from '@material-ui/core/TableBody'
import TableFooter from '@material-ui/core/TableFooter'
import TablePagination from '@material-ui/core/TablePagination'
import PaginationActions from 'lib/ui/table/PaginationActions'
import TransactionRow from 'obvio/Billing/TransactionSection/TransactionRow'
import styled from 'styled-components'
import {PaginatedCollection} from 'lib/ui/api-client'
import {Transaction} from 'obvio/Billing/TransactionSection/list-transactions'

type TransactionsTableProps = {
  transactions: Transaction[]
  goToPage: (page: number) => void
  markComplete: (transaction: Transaction) => void
  paginated: PaginatedCollection<Transaction> | null
}

export default function TransactionsTable(props: TransactionsTableProps) {
  const {transactions, paginated, markComplete, goToPage} = props
  const tableContainerEl = useRef<HTMLElement>(null)

  if (paginated === null) {
    return <p>...</p>
  }

  const hasTransactions = transactions.length > 0

  if (!hasTransactions) {
    return <p>No transactions have been recorded.</p>
  }

  return (
    <TableContainer component={Paper} ref={tableContainerEl} elevation={0}>
      <StyledTable stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell>Status</TableCell>
            <FullWidthCell>Transaction Type</FullWidthCell>
            <TableCell>Quantity</TableCell>
            <TableCell>Cost</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {transactions.map((transaction) => (
            <TransactionRow
              transaction={transaction}
              key={transaction.id}
              onPayment={() => markComplete(transaction)}
            />
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              colSpan={5}
              count={paginated.total}
              rowsPerPageOptions={[]} //  Hide selector
              rowsPerPage={parseInt(paginated.per_page)}
              page={paginated.current_page - 1} // MUI is 0 index based
              SelectProps={{
                inputProps: {'aria-label': 'select rows per page'},
                native: true,
              }}
              onChangePage={(_event, page) => goToPage(page)}
              ActionsComponent={PaginationActions}
            />
          </TableRow>
        </TableFooter>
      </StyledTable>
    </TableContainer>
  )
}

const FullWidthCell = styled(TableCell)`
  width: 100%;
`

const StyledTable = styled(Table)`
  td {
    height: 4rem;
  }
`

import FullPageLoader from 'lib/ui/layout/FullPageLoader'
import {useQueryParams} from 'lib/url'
import {useHostJoinUrl} from 'organization/Event/Room/meeting'
import {useOrganization} from 'organization/OrganizationProvider'
import React, {useEffect} from 'react'
import {useParams} from 'react-router'

export default function HostJoinRoomPage() {
  const {room: roomId} = useParams<{room: string}>()
  const {client} = useOrganization()
  const {start_url} = useQueryParams()

  const url = useHostJoinUrl({
    client,
    room: {id: parseInt(roomId)},
    useStartUrl: start_url === 'true',
  })

  useEffect(() => {
    if (!url) {
      return
    }

    window.location.href = url
  }, [url])

  return <FullPageLoader />
}

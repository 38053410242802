import React from 'react'
import {TeamMember} from 'auth/user'
import {FeatureFlag} from 'auth/user/flags'
import {assignProps} from 'lib/react-utils'

export type FeatureToggleProps = {
  fallback?: React.ReactElement
  children: React.ReactElement | React.ReactElement[]
  flags: FeatureFlag | FeatureFlag[]
}

/**
 * A component used to evaluate the available flags of a supplied user, to
 * determine which component to return.
 */
export default function FeatureToggle(
  props: FeatureToggleProps & {
    user: TeamMember
  },
) {
  const {user, flags, children, ...forwardedProps} = props
  const disabled = props.fallback || null

  const isEnabled = hasFeatureToggle(user, flags)

  if (!isEnabled) {
    return disabled
  }

  // In case this is used as HOC, we'll forward along any props intended for
  // children components.
  const content = assignProps(forwardedProps, children)

  return <>{content}</>
}

export function hasFeatureToggle(
  user: TeamMember,
  flags: FeatureToggleProps['flags'],
): boolean {
  // If the user doesn't have any flags to compare, there's nothing else to do
  // except render the "disabled" component.
  if (user.feature_flags === null) {
    return false
  }

  // If we're only checking a single flag, we'll wrap it in an array as well
  // to user the same code when checking.
  const possibleFlags = Array.isArray(flags) ? flags : [flags]
  // Getting it local to maneuver type considerations.
  const availableFlags = user.feature_flags || []

  // Default to the "disabled" component for a return value.
  let result = false

  // Iterate the array of possible flags that should return us an "enabled"
  // component, to see if ANY of them exist in the availableFlags array.
  for (const flag of possibleFlags) {
    const isEnabled = availableFlags.includes(flag)

    if (isEnabled) {
      result = true
      break
    }
  }

  return result
}

import {ActiveCampaignTag} from 'Event/activeCampaign'
import {
  ATTENDEE_CHECKED_IN,
  ATTENDEE_CREATED,
  ATTENDEE_SIGNED_WAIVER,
  IMPORT_TAG,
  Tag,
} from 'organization/Event/Services/Apps/ActiveCampaign'
import TagsAutocomplete from 'organization/Event/Services/Apps/ActiveCampaign/Config/TagsAutocomplete'
import React, {useEffect, useState} from 'react'

export default function TagIdInput(props: {
  tag: Tag
  onChange: (tag: Tag) => void
  error?: string
}) {
  const {tag} = props

  const [
    activeCampaignTag,
    setActiveCampaignTag,
  ] = useState<ActiveCampaignTag | null>(null)

  useEffect(() => {
    if (!tag.active_campaign_id || !tag.name) {
      setActiveCampaignTag(null)
      return
    }

    setActiveCampaignTag({
      id: tag.active_campaign_id,
      tag: tag.name,
    })
  }, [tag])

  const handleTagSelect = (selected: ActiveCampaignTag | null) => {
    if (!selected) {
      const empty = {
        ...tag,
        name: null,
        active_campaign_id: null,
      }

      props.onChange(empty)
      return
    }

    const updated = {
      ...tag,
      name: selected.tag,
      active_campaign_id: selected.id,
    }

    props.onChange(updated)
  }

  return (
    <TagsAutocomplete
      errorText={props.error}
      inputVariant="outlined"
      inputLabel={label(tag)}
      value={activeCampaignTag}
      onChange={handleTagSelect}
    />
  )
}

function label(tag: Tag) {
  const isSet = Boolean(tag.name)
  return isSet ? `${typeLabel(tag)} - ${tag.name}` : `${typeLabel(tag)}`
}

function typeLabel(tag: Tag) {
  const labels: Record<string, string> = {
    [ATTENDEE_CREATED]: 'Attendee Created',
    [ATTENDEE_SIGNED_WAIVER]: 'Attendee Signed Waiver',
    [ATTENDEE_CHECKED_IN]: 'Attendee Checked In',
    [IMPORT_TAG]: 'Import Tag',
  }

  const label = labels[tag.type]
  if (!label) {
    throw new Error(`Label not defined for tag type: ${tag.type}`)
  }

  return label
}

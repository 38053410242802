import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import {useEvent} from 'Event/EventProvider'
import {api, obvioUrl} from 'lib/url'
import {obvioRoutes} from 'obvio/Routes'
import Page from 'organization/Event/Page'
import {useSetEventAuthRoute} from 'organization/Event/Services/Apps/Hubspot/HubspotAuthCallbackHandler'
import {useOrganization} from 'organization/OrganizationProvider'
import React, {useState} from 'react'

export default function LinkPage() {
  const {link, isProcessing} = useLink()

  return (
    <Page>
      <Typography variant="h4">Hubspot</Typography>
      <p>
        Click the button below to link obv.io with Hubspot.
        <br />
        You will be redirected to Hubspot to complete authorization.
      </p>
      <Button
        color="primary"
        variant="contained"
        disabled={isProcessing}
        onClick={link}
        aria-label="authorize"
      >
        Authorize
      </Button>
    </Page>
  )
}

function useLink() {
  const {client} = useOrganization()
  const {event} = useEvent()
  const [isProcessing, setIsProcessing] = useState(false)
  const redirectUrl = useRedirectUrl()
  const setEventAuthRoute = useSetEventAuthRoute()

  const linkUrl = api(`/events/${event.id}/integrations/hubspot/link`)

  const link = () => {
    if (isProcessing) {
      return
    }

    setIsProcessing(true)
    client
      .post<{auth_url: string}>(linkUrl, {
        redirect_url: redirectUrl,
      })
      .then(({auth_url}) => {
        // Redirect to Hubspot
        window.location.href = auth_url
        setEventAuthRoute()
      })
      .catch(() => {
        setIsProcessing(false)
      })
  }

  return {link, isProcessing}
}

export function useRedirectUrl() {
  return `${obvioUrl(obvioRoutes.hubspot.auth)}`
}

import React from 'react'
import {onChangeCheckedHandler} from 'lib/dom'
import TextField from '@material-ui/core/TextField'
import FormSelect from 'organization/Event/FormsProvider/FormSelect'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import {AttachmentConfigProps} from 'Event/Dashboard/components/BlogPosts/BlogPostConfig/AttachmentConfig'
import {Controller} from 'react-hook-form'
import Show from 'lib/Show'

export function FormConfig(props: AttachmentConfigProps) {
  const {post, control, register, attachment, watch} = props

  // Watch current variables in case they're being changed
  // before the config is saved.
  const hasForm = Boolean(post.formId) || watch('formId')
  const isModalForm = post.isModalForm || watch('isModalForm')

  // If attaching buttons instead of a form, we'll hide the config
  if (attachment === 'buttons') {
    return null
  }

  return (
    <>
      <FormControl fullWidth>
        <InputLabel>Form</InputLabel>
        <Controller
          name="formId"
          defaultValue={post.formId}
          control={control}
          render={({value, onChange}) => (
            <FormSelect value={value} onChange={onChange} />
          )}
        />
      </FormControl>
      <Show when={hasForm}>
        <FormControl>
          <Controller
            name="isModalForm"
            defaultValue={post.isModalForm}
            control={control}
            render={({value, onChange}) => (
              <FormControlLabel
                label="Open in modal?"
                control={
                  <Checkbox
                    disableRipple
                    checked={value}
                    onChange={onChangeCheckedHandler(onChange)}
                    inputProps={{
                      'aria-label': 'toggle open form in modal',
                    }}
                  />
                }
              />
            )}
          />
        </FormControl>
        <Show when={hasForm && isModalForm}>
          <TextField
            label="Modal Button Text"
            defaultValue={post.modalButtonText}
            name="modalButtonText"
            inputProps={{
              'aria-label': 'open form modal text',
              ref: register,
            }}
            fullWidth
          />
        </Show>
      </Show>
    </>
  )
}

import React, {useState} from 'react'
import {FAQ} from 'Event/FAQs'
import RulesPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/RulesPanel'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import Settings from 'Event/FAQs/FAQConfig/Settings'
import {REMOVE, useSaveTemplate} from 'Event/TemplateUpdateProvider'
import {useConfig} from 'organization/Event/Configurable'
import Config from 'organization/Event/Configurable/Config'

export default function FaqConfig(props: {faq: FAQ; id: string}) {
  const {id, faq} = props

  const [rules, setRules] = useState(faq.rules)

  const {
    form: {control, register},
  } = useConfig()
  const save = useSaveTemplate()

  const handleSave = (data: any) => {
    save({
      faq: {
        items: {
          [id]: {
            ...data,
            rules,
          },
        },
      },
    })
  }

  const handleRemove = () => {
    save({
      faq: {
        items: {
          [id]: REMOVE,
        },
      },
    })
  }

  return (
    <Config title="FAQ" onSave={handleSave} onRemove={handleRemove}>
      <SettingsPanel>
        <Settings control={control} register={register} faq={faq} />
      </SettingsPanel>
      <RulesPanel rules={rules} setRules={setRules} />
    </Config>
  )
}

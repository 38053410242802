import React from 'react'
import Button from '@material-ui/core/Button'
import {useToggleArray} from 'lib/toggle'
import AddFeaturePageDialog from 'Event/template/Townhall/Dashboard/NavBar/LinkTabs/AddPageButton/AddFeaturePageDialog'

export default function AddPageButton() {
  const [showing, toggle] = useToggleArray()

  return (
    <>
      <AddFeaturePageDialog showing={showing} onClose={toggle} />
      <Button
        variant="text"
        color="primary"
        aria-label="add feature page"
        onClick={toggle}
      >
        Add
      </Button>
    </>
  )
}

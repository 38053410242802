import React, {useMemo} from 'react'
import styled from 'styled-components'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import Dialog from 'lib/ui/Dialog'
import Button from 'lib/ui/Button'
import {useQueryParams, useSetBulkQueryParam} from 'lib/url'
import FilterList, {Filter} from 'Event/Statistics/Filters/FilterList'

export default function FiltersDialog(props: {
  isVisible: boolean
  onClose: () => void
}) {
  const {onClose} = props
  const setBulkQueryParam = useSetBulkQueryParam()
  const urlParams = useUrlParams()

  const clearFilters = () => {
    setBulkQueryParam({
      filters: '',
    })
    props.onClose()
  }

  const setFilters = (filters: Filter[]) => {
    setBulkQueryParam({
      filters: encodeURIComponent(JSON.stringify(filters)),
    })
  }
  const stringFilters = decodeURIComponent(urlParams.filters ?? '')
  const filters = stringFilters ? JSON.parse(stringFilters) : undefined

  return (
    <Dialog open={props.isVisible} onClose={onClose}>
      <DialogTitle>Add Filter</DialogTitle>

      <StyledDialogContent>
        <FilterForm
          filters={filters}
          onChange={setFilters}
          onClear={clearFilters}
        />
      </StyledDialogContent>
    </Dialog>
  )
}

export function FilterForm(props: {
  filters?: Filter[]
  onChange: (filters: Filter[]) => void
  onClear: () => void
}) {
  return (
    <>
      <StyledFilterList filters={props.filters} onChange={props.onChange} />
      <ClearFilterButton
        variant="outlined"
        color="danger"
        fullWidth
        aria-label="clear filter"
        onClick={props.onClear}
      >
        Clear Filter
      </ClearFilterButton>
    </>
  )
}

const StyledFilterList = styled(FilterList)`
  margin-bottom: ${(props) => props.theme.spacing[3]};
`

const StyledDialogContent = styled(DialogContent)`
  min-width: 480px;
  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    min-width: 320px;
  }
`

const ClearFilterButton = styled(Button)`
  margin-bottom: ${(props) => props.theme.spacing[3]};
`

function useUrlParams() {
  const queryParams = useQueryParams()
  return useMemo(() => queryParams, [queryParams])
}

import React from 'react'
import styled from 'styled-components'
import {TeamMember} from 'auth/user'
import Menu from 'lib/ui/Menu'
import MenuItem from 'lib/ui/Menu/MenuItem'
import IconButton from 'lib/ui/IconButton'
import Icon from 'lib/ui/Icon'
import {TeamInvitation} from '../TeamInvitationsProvider'
import Role from 'organization/Team/TeamMembersTable/Role'
import Divider from '@material-ui/core/Divider'
import MuiTableRow from '@material-ui/core/TableRow'
import MuiTableCell from '@material-ui/core/TableCell'

type TeamMemberItemProps = {
  member: TeamMember | TeamInvitation
  background?: 'light' | 'dark'
  isOwner?: boolean
  isInvitation?: boolean
  processing?: boolean
  'aria-label'?: string
  onRemove?: () => void
  onResend?: () => void
}

export default function TeamMemberItem(props: TeamMemberItemProps) {
  const {member, background, isInvitation} = props

  const name = () => {
    if (isInvitation) {
      return '-'
    }

    const user = member as TeamMember
    return `${user.first_name} ${user.last_name}`
  }

  return (
    <StyledTableRow background={background} aria-label={props['aria-label']}>
      <MuiTableCell>{name()}</MuiTableCell>
      <MuiTableCell>{member.email}</MuiTableCell>
      <MuiTableCell>{member.role?.name}</MuiTableCell>
      <MuiTableCell align="right">
        <Actions {...props} />
      </MuiTableCell>
    </StyledTableRow>
  )
}

function Actions(props: TeamMemberItemProps) {
  const {isOwner, member} = props
  const menuButtonLabel = `menu-button-${member.id}`

  if (isOwner) {
    return null
  }

  return (
    <Center>
      <RoleSelect {...props} />
      <Menu
        button={({open}) => (
          <IconButton onClick={open} aria-label={menuButtonLabel}>
            <Icon className="fas fa-ellipsis-h" color="#929292" iconSize={18} />
          </IconButton>
        )}
      >
        <MenuContent {...props} />
      </Menu>
    </Center>
  )
}

function RoleSelect(props: TeamMemberItemProps) {
  const {isInvitation, member} = props

  if (isInvitation) {
    return null
  }
  return (
    <RoleBox>
      <Role teamMember={member as TeamMember} />
    </RoleBox>
  )
}

const MenuContent = React.forwardRef<HTMLDivElement, TeamMemberItemProps>(
  (props, ref) => {
    const {isInvitation, onRemove, onResend, processing} = props
    if (isInvitation) {
      return (
        <div ref={ref}>
          <MenuItem
            children="Resend Invitation"
            aria-label="resend invitation"
            disabled={processing}
            onClick={() => onResend && onResend()}
          />
          <Divider />
          <MenuItem
            children="Remove Invitation"
            aria-label="remove invitation"
            disabled={processing}
            onClick={() => onRemove && onRemove()}
          />
        </div>
      )
    }

    return (
      <MenuItem
        children="Delete Team member"
        aria-label="delete member"
        disabled={processing}
        onClick={() => onRemove && onRemove()}
      />
    )
  },
)

const RoleBox = styled.div`
  margin-right: ${(props) => props.theme.spacing[2]};
`

const Center = styled.div`
  display: flex;
  align-items: center;
`

const StyledTableRow = styled((props) => {
  const {background: _1, ...otherProps} = props

  return <MuiTableRow {...otherProps} />
})`
  background-color: ${(props) =>
    props.background !== 'light' ? '#F9F9F9' : '#F2F5F9'};
`

import React from 'react'
import {UseFormMethods} from 'react-hook-form'
import {Label} from 'lib/ui/typography'
import TextField from 'lib/ui/TextField'
import {useLeftyTemplate} from 'Event/template/Lefty'

export default function Settings(props: {
  control: UseFormMethods['control']
  register: UseFormMethods['register']
}) {
  const {register} = props
  const template = useLeftyTemplate()
  const {welcomeText} = template

  return (
    <>
      <Label>Text</Label>
      <TextField
        name="welcomeText"
        placeholder="Text"
        defaultValue={welcomeText}
        aria-label="dashboard welcome text"
        inputProps={{
          ref: register,
        }}
        fullWidth
      />
    </>
  )
}

import React, {useEffect, useState} from 'react'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import {useOrganization} from 'organization/OrganizationProvider'
import {api} from 'lib/url'
import {useEvent} from 'Event/EventProvider'
import ErrorAlert from 'lib/ui/alerts/ErrorAlert'
import SuccessAlert from 'lib/ui/alerts/SuccessAlert'
import {Area} from 'organization/Event/AreasProvider'
import {useGet} from 'lib/requests'
import Menu from 'lib/ui/Menu'
import MenuItem from '@material-ui/core/MenuItem'

export default function ExportAreaAttendees(props: {area: Area}) {
  const [error, setError] = useState<string | null>(null)
  const [success, setSuccess] = useState<string | null>(null)

  return (
    <>
      <ErrorAlert>{error}</ErrorAlert>
      <SuccessAlert>{success}</SuccessAlert>
      <Box mb={2}>
        <Menu
          button={({open}) => (
            <Button
              color="primary"
              variant="outlined"
              aria-label="export log"
              onClick={open}
            >
              Export Log
            </Button>
          )}
        >
          <ExportMenuItem
            area={props.area}
            isSummary
            setError={setError}
            setSuccess={setSuccess}
          />
          <ExportMenuItem
            area={props.area}
            setError={setError}
            setSuccess={setSuccess}
          />
        </Menu>
      </Box>
    </>
  )
}

const ExportMenuItem = React.forwardRef<
  HTMLLIElement,
  {
    area: Area
    isSummary?: boolean
    setError: (error: string | null) => void
    setSuccess: (success: string | null) => void
  }
>((props, ref) => {
  const {area, isSummary, setError, setSuccess} = props

  const ariaLabel = isSummary ? 'export summary log' : 'export detail log'
  const buttonText = isSummary ? 'Export Summary Log' : 'Export Detail Log'

  const {exportLog, processing, errorMessage, successMessage} = useExportLog(
    area,
    isSummary,
  )

  useEffect(() => {
    setError(errorMessage)
    setSuccess(successMessage)
  }, [errorMessage, setError, setSuccess, successMessage])

  return (
    <MenuItem
      disabled={processing}
      onClick={exportLog}
      aria-label={ariaLabel}
      ref={ref}
    >
      {buttonText}
    </MenuItem>
  )
})

function useExportLog(area: Area, isSummary?: boolean) {
  const {event} = useEvent()
  const exportMode = isSummary ? 'summary' : 'detail'
  const url = api(
    `/events/${event.id}/areas/${area.id}/attendees/export?exportMode=${exportMode}`,
  )
  const {client} = useOrganization()

  const {send: exportLog, processing, errorMessage, successMessage} = useGet(
    client,
    url,
  )

  return {
    exportLog,
    processing,
    errorMessage,
    successMessage,
  }
}

import {ImageMasonryWall, useFetchEntries} from 'Event/ImageWaterfall'
import FullPageLoader from 'lib/ui/layout/FullPageLoader'
import React from 'react'
import {useAttendeeVariables} from 'Event'
import {useLeftyTemplate} from 'Event/template/Lefty'
import {useAttendee} from 'Event/auth'
import FeaturePage from 'Event/template/Lefty/Page'
import {FeaturePageTitle, PageTexts} from 'Event/template/Lefty/Page'
import {LeftyBreadcrumbs} from 'Event/template/Lefty/Page'

export default function LeftyImageWaterfall() {
  const user = useAttendee()

  const {loading, entries} = useFetchEntries()
  const {imageWaterfall: pageSettings} = useLeftyTemplate()
  const v = useAttendeeVariables()

  if (loading) {
    return <FullPageLoader />
  }

  return (
    <FeaturePage user={user}>
      <FeaturePageTitle>{v(pageSettings.title)}</FeaturePageTitle>
      <LeftyBreadcrumbs page={v(pageSettings.title)} />
      <PageTexts
        title={pageSettings.secondaryTitle}
        description={pageSettings.description}
      />
      <ImageMasonryWall entries={entries} />
    </FeaturePage>
  )
}

import styled from 'styled-components'

const FormButtons = styled.div`
  display: flex;
  justify-content: center;
`

export const FormButtonBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export default FormButtons

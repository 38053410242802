import moment, {unitOfTime} from 'moment-timezone'

// Moment locales
import 'moment/locale/es' // Spanish
import 'moment/locale/es-mx'
import 'moment/locale/es-us'
import 'moment/locale/es-do'
import 'moment/locale/pt' // Portuguese
import 'moment/locale/pt-br' // Brazil
import 'moment/locale/fr' // France
import 'moment/locale/en-au' // English
import 'moment/locale/en-ca'
import 'moment/locale/en-gb'
import 'moment/locale/en-nz'
import 'moment/locale/de' // Germany
import 'moment/locale/de-at'
import 'moment/locale/de-ch'
import 'moment/locale/da' // Denmark
import 'moment/locale/sv' // Sweden
import 'moment/locale/nb' // Norway
import 'moment/locale/it' // Italy
import 'moment/locale/hu' // Hungary
import 'moment/locale/ru' // Russia
import 'moment/locale/ja' // Japan
import 'moment/locale/th'
import 'moment/locale/zh-cn' // Chinese
import 'moment/locale/zh-hk'
import 'moment/locale/zh-mo'
import 'moment/locale/zh-tw'
import 'moment/locale/is' // Iceland
import 'moment/locale/fi' // Finland
import {useDate} from 'lib/date-time/DateProvider'
import {useInterval} from 'lib/interval'
import {useCallback, useState} from 'react'

/**
 * Date Time Formats
 */
export const ISO_MINUTE_FORMAT = 'YYYY-MM-DDTHH:mm' // 2022-06-29T03:40 (removes seconds, and timezone info from ISO8601)
export const SHORT_DATE_TIME_FORMAT = 'YYYY-MM-DD h:mma z'
export const LONG_DATE_TIME_FORMAT = 'MMMM Do h:mma z'
export const LONG_DATE_FORMAT = 'MMMM D, YYYY'

/**
 * Converts the given time to the client's local time.
 *
 * @returns
 */
export function useLocalTime() {
  const {timezone} = useDate()
  return (date: string, format = LONG_DATE_TIME_FORMAT) =>
    localize({date, format, timezone})
}

/**
 * Converts a UTC time to local time for a given Timezone.
 *
 * @param param0
 * @returns
 */
export const localize = ({
  date,
  format = LONG_DATE_TIME_FORMAT,
  timezone,
}: {
  date: string
  format?: string
  timezone: string
}) => moment.utc(date).tz(timezone).format(format)

export const browserTimezone = () => moment.tz.guess()

export const now = () => new Date().toISOString()

export const time = () => formatDate(now(), 'HH:mm')

export const today = () => formatDate(now())

export const tomorrow = () => moment().add(1, 'days').toISOString()

export const yesterday = () => moment().subtract(1, 'days').toISOString()

export const customDaysForward = (days: number) =>
  moment().add(days, 'days').toISOString()

export const customDaysBack = (days: number) =>
  moment().subtract(days, 'days').toISOString()

export const formatDate = (value: string, format = 'DD-MM-YYYY') =>
  moment.utc(value).format(format)

export const formatDateInLocalTimezone = (
  value: string,
  format = 'DD-MM-YYYY',
) => {
  return moment(value).format(format)
}

export const getDiffDatetime = (
  d1: string,
  d2: string,
  resultType: unitOfTime.Diff = 's',
) => {
  var date1 = moment(d1)
  var date2 = moment(d2)
  var diff = date1.diff(date2, resultType)
  return diff
}

export const isPast = (target: string, start = now(), timezone = 'UTC') =>
  !date(target, timezone).isAfter(start)
export const isFuture = (target: string, start = now(), timezone = 'UTC') =>
  date(target, timezone).isAfter(start)
export const isPast8am = (target: string, start = now(), timezone = 'UTC') =>
  moment
    .tz(target, timezone)
    .set('hours', 8)
    .set('minutes', 0)
    .isBefore(moment.tz(start, timezone))

/**
 * Date comparison utils
 *
 * @param target
 * @returns
 */
export const date = (target: string, timezone = 'UTC') => {
  return {
    isAfter: (value: string) => {
      /**
       * Handle bug where sometimes moment.isAfter would return true
       * for the same string
       */
      if (target === value) {
        return false
      }

      return moment.tz(target, timezone).isAfter(moment.tz(value, timezone))
    },
  }
}

/**
 * Get the number of days from the start to the end. The end date
 * counts as a day.
 *
 * @param start
 * @param end
 * @returns
 */
export const getNumDays = (start: string, end: string) => {
  var date1 = moment(start)
  var date2 = moment(end)

  const secondsInDay = 86400
  var diff = date2.diff(date1, 's')
  return Math.ceil(diff / secondsInDay)
}

export interface Duration {
  days?: string
  hours: string
  minutes: string
  seconds: string
}

/**
 *
 * @param start
 * @param end
 * @returns an object with days, hours, minutes and seconds
 */
export const duration = (
  start: string,
  end: string,
  leadingZero?: boolean,
): Duration => {
  const duration = moment.duration(moment(end).diff(moment(start)))

  if (leadingZero === undefined) {
    leadingZero = true
  }

  const days = Math.floor(duration.asDays())
  const hours = duration.hours()
  const minutes = duration.minutes()
  const seconds = duration.seconds()

  const diffToString = (diff: number, leadingZero: boolean) => {
    if (diff < 0) return '00'
    if (leadingZero && diff < 10) return `0${diff}`
    return `${diff}`
  }

  if (days < 1)
    return {
      hours: diffToString(hours, leadingZero),
      minutes: diffToString(minutes, leadingZero),
      seconds: diffToString(seconds, leadingZero),
    }
  return {
    days: leadingZero && days < 10 ? `0${days}` : `${days}`,
    hours: diffToString(hours, leadingZero),
    minutes: diffToString(minutes, leadingZero),
    seconds: diffToString(seconds, leadingZero),
  }
}

export const getLengthAsDuration = (
  length: number,
  interval?: string,
  leadingZero?: boolean,
) => {
  const start = moment().toISOString()
  const end = moment()
    .add(length, (interval as unitOfTime.Base) || 'minutes')
    .toISOString()

  return duration(start, end, leadingZero)
}

export const inThreeDays = () =>
  moment().add(3, 'days').seconds(0).milliseconds(0).toISOString()

export const inFiveMinutes = () =>
  moment().add(5, 'minutes').seconds(0).milliseconds(0).toISOString()

export const inTenMinutes = () =>
  moment().add(10, 'minutes').seconds(0).milliseconds(0).toISOString()

/**
 * Returns a 'now' that updates every second.
 *
 * @returns
 */
export function useCurrentTime() {
  const [time, setTime] = useState(now())

  const tick = useCallback(() => {
    setTime(now())
  }, [])
  useInterval(tick, 1000)

  return time
}

import React from 'react'
import {Controller, UseFormMethods} from 'react-hook-form'
import Slider from 'lib/ui/Slider'
import {HasOffset} from 'Event/template/Lefty/Dashboard/Sidebar/SidebarItem'

const MIN_OFFSET = 0
const MAX_OFFSET = 200

export default function OffsetInputs(
  props: {
    control: UseFormMethods['control']
  } & HasOffset,
) {
  const {control, offsetTop, offsetBottom} = props

  return (
    <>
      <Controller
        name="offsetTop"
        defaultValue={offsetTop}
        control={control}
        render={({value, onChange}) => (
          <Slider
            min={MIN_OFFSET}
            max={MAX_OFFSET}
            step={1}
            onChange={onChange}
            valueLabelDisplay="auto"
            value={value}
            aria-label="top offset"
            label="Top Spacing"
          />
        )}
      />
      <Controller
        name="offsetBottom"
        defaultValue={offsetBottom}
        control={control}
        render={({value, onChange}) => (
          <Slider
            min={MIN_OFFSET}
            max={MAX_OFFSET}
            step={1}
            onChange={onChange}
            valueLabelDisplay="auto"
            value={value}
            aria-label="bottom offset"
            label="Bottom Spacing"
          />
        )}
      />
    </>
  )
}

import React from 'react'
import {useEvent} from 'Event/EventProvider'
import {ObvioEvent} from 'Event'
import TemplateCard from 'organization/Marketplace/PurchasePageConfig/Content/TemplateSelector/TemplateCard'

interface UserEventsGridProps {
  events: ObvioEvent[]
}

export default function UserEventsGrid(props: UserEventsGridProps) {
  const {events} = props
  const {event: currentEvent} = useEvent()

  // Don't want to include current Event as a selection
  const otherEvents = events.filter((item) => item.id !== currentEvent.id)

  return (
    <>
      {otherEvents.map((event) => (
        <TemplateCard event={event} key={event.id} />
      ))}
    </>
  )
}

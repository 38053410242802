import {PurchasePage} from 'Event/Marketplace/purchase-page'
import {marketplaceApi} from 'lib/marketplace-api/url'
import {Client} from 'lib/ui/api-client'
import {useMutation} from 'react-query'

export function useUpdatePurchasePageTemplate(params: {client: Client}) {
  const {client} = params

  return useMutation({
    mutationFn: (data: {
      purchasePage: PurchasePage
      updates: Record<string, any>
    }) => {
      const {purchasePage, updates} = data

      const url = marketplaceApi(`/purchase_pages/${purchasePage.id}/template`)
      return client.put<PurchasePage>(url, {
        template: updates,
      })
    },
  })
}

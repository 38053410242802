import {useEditMode} from 'Event/EditModeProvider'
import {useTownhallTemplate, useTownhallUpdate} from 'Event/template/Townhall'
import {createPositions, orderedIdsByPosition} from 'lib/list'
import React from 'react'
import {
  DragDropContext,
  DropResult,
  Droppable,
  DroppableProvidedProps,
} from 'react-beautiful-dnd'
import styled from 'styled-components'
import TabMenuItem from 'Event/template/Townhall/Dashboard/NavBar/LinkTabs/TabMenuItem'

export default function Tabs(props: {
  selectedId: string
  onClickTab: (id: string) => void
}) {
  const {selectedId, onClickTab} = props
  const {navBar} = useTownhallTemplate()
  const isEditMode = useEditMode()

  const items = navBar.menuItems
  const ids = orderedIdsByPosition(items)
  const enabledIds = ids.filter((id) => {
    const item = items[id]
    return item.isEnabled || isEditMode
  })

  if (isEditMode) {
    return (
      <Editable>
        {enabledIds.map((id, index) => (
          <TabMenuItem
            key={id}
            menuItem={items[id]}
            id={id}
            index={index}
            onClick={onClickTab}
            active={id === selectedId}
          />
        ))}
      </Editable>
    )
  }

  return (
    <>
      {enabledIds.map((id, index) => (
        <TabMenuItem
          key={id}
          menuItem={items[id]}
          id={id}
          index={index}
          onClick={onClickTab}
          active={id === selectedId}
        />
      ))}
    </>
  )
}

function Editable(props: {children: React.ReactElement[]}) {
  const handleDrag = useHandleDrag()

  return (
    <DragDropContext onDragEnd={handleDrag}>
      <Droppable droppableId="nav_items" direction="horizontal">
        {(provided) => (
          <Container ref={provided.innerRef} {...provided.droppableProps}>
            <>
              {props.children}
              {provided.placeholder}
            </>
          </Container>
        )}
      </Droppable>
    </DragDropContext>
  )
}

const Container = React.forwardRef<
  HTMLDivElement,
  {
    className?: string
    children: React.ReactElement | React.ReactElement[]
  } & Partial<DroppableProvidedProps>
>((props, ref) => (
  <Box className={props.className} ref={ref} {...props}>
    {props.children}
  </Box>
))

function useHandleDrag() {
  const {
    navBar: {menuItems},
  } = useTownhallTemplate()
  const updateTemplate = useTownhallUpdate()

  return (result: DropResult) => {
    const {destination, source} = result

    if (!destination) {
      return
    }

    const ids = orderedIdsByPosition(menuItems)
    const [removed] = ids.splice(source.index, 1)
    ids.splice(destination.index, 0, removed)

    updateTemplate({
      navBar: {
        menuItems: createPositions(ids),
      },
    })
  }
}

const Box = styled.div`
  display: flex;
  flex-direction: row;
`

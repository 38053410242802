import React from 'react'
import {Controller} from 'react-hook-form'
import CollapsibleSection from 'lib/ui/ConfigPanel/CollapsibleSection'
import {LeftySponsor} from 'Event/template/Lefty/Sponsors/SponsorList'
import Slider from 'lib/ui/Slider'
import {useConfig} from 'organization/Event/Configurable'
import MarginPaddingInputs from 'lib/ui/MarginPaddingInputs'
import {Label} from 'lib/ui/typography'
import ColorPicker from 'lib/ui/ColorPicker'
import {useLeftyTemplate} from 'Event/template/Lefty'

export default function Styling(props: {sponsor: LeftySponsor; id: string}) {
  const {id, sponsor} = props
  const {
    form: {control},
  } = useConfig()
  const {sponsors, textColor} = useLeftyTemplate()
  const sponsorsTextColor = sponsors.text.color
    ? sponsors.text.color
    : textColor

  return (
    <>
      <CollapsibleSection
        label="Logo"
        storageKey="sponsor.card.styling.logo.config"
      >
        <Controller
          name={`sponsors.items.${id}.logoSize`}
          defaultValue={sponsor.logoSize}
          control={control}
          render={({value, onChange}) => (
            <Slider
              label="Size"
              min={10}
              max={100}
              step={1}
              onChange={onChange}
              valueLabelDisplay="auto"
              value={value}
              aria-label="logo size"
              unit="%"
            />
          )}
        />
        <Label>Padding</Label>
        <MarginPaddingInputs
          control={control}
          namePrefix={`sponsors.items.${id}.padding`}
          values={sponsor.padding}
          settings={{max: 300}}
        />
      </CollapsibleSection>
      <CollapsibleSection
        label="Background"
        storageKey="sponsor.card.styling.background.config"
      >
        <Controller
          name="sponsors.background.color"
          defaultValue={sponsors.background.color}
          control={control}
          render={({value, onChange}) => (
            <ColorPicker
              label="Color"
              color={value}
              onPick={onChange}
              aria-label="card background color"
            />
          )}
        />
        <Controller
          name="sponsors.background.opacity"
          defaultValue={sponsors.background.opacity}
          control={control}
          render={({value, onChange}) => (
            <Slider
              label="Opacity"
              min={0}
              max={100}
              step={1}
              onChange={onChange}
              valueLabelDisplay="auto"
              value={value}
              aria-label="card background opacity"
              unit="%"
            />
          )}
        />
      </CollapsibleSection>
      <CollapsibleSection
        label="Text"
        storageKey="sponsor.card.styling.text.config"
      >
        <Controller
          name="sponsors.text.color"
          defaultValue={sponsorsTextColor}
          control={control}
          render={({value, onChange}) => (
            <ColorPicker
              label="Color"
              color={value}
              onPick={onChange}
              aria-label="card text color"
            />
          )}
        />
      </CollapsibleSection>
      <CollapsibleSection
        label="Separator"
        storageKey="sponsor.card.styling.separator.config"
      >
        <Controller
          name="sponsors.separator.color"
          defaultValue={sponsors.separator.color}
          control={control}
          render={({value, onChange}) => (
            <ColorPicker
              label="Color"
              color={value}
              onPick={onChange}
              aria-label="card separator color"
            />
          )}
        />
        <Controller
          name="sponsors.separator.width"
          defaultValue={sponsors.separator.width}
          control={control}
          render={({value, onChange}) => (
            <Slider
              label="Width"
              min={0}
              max={5}
              step={0.1}
              onChange={onChange}
              valueLabelDisplay="auto"
              value={value}
              aria-label="card separator width"
            />
          )}
        />
      </CollapsibleSection>
      <CollapsibleSection
        label="Border"
        storageKey="sponsor.card.styling.border.config"
      >
        <Controller
          name="sponsors.border.color"
          defaultValue={sponsors.border.color}
          control={control}
          render={({value, onChange}) => (
            <ColorPicker
              label="Color"
              color={value}
              onPick={onChange}
              aria-label="card border color"
            />
          )}
        />
        <Controller
          name="sponsors.border.width"
          defaultValue={sponsors.border.width}
          control={control}
          render={({value, onChange}) => (
            <Slider
              label="Width"
              min={0}
              max={5}
              step={0.1}
              onChange={onChange}
              valueLabelDisplay="auto"
              value={value}
              aria-label="card border width"
            />
          )}
        />
        <Controller
          name="sponsors.border.radius"
          defaultValue={sponsors.border.radius}
          control={control}
          render={({value, onChange}) => (
            <Slider
              label="Radius"
              min={0}
              max={90}
              step={1}
              onChange={onChange}
              valueLabelDisplay="auto"
              value={value}
              aria-label="card border radius"
            />
          )}
        />
      </CollapsibleSection>
    </>
  )
}

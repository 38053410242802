import {useAttendeeVariables} from 'Event'
import StyledText from 'lib/ui/typography/StyledText'
import React from 'react'
import styled from 'styled-components'
import {useTownhallTemplate} from 'Event/template/Townhall'
import {FontStyle} from 'lib/ui/typography/FontStyleInput'
import {useEditMode} from 'Event/EditModeProvider'

export default function SectionDescription(props: {
  description?: string
  fontStyles?: FontStyle[]
  'aria-label'?: string
  color?: string | null
}) {
  const {description, fontStyles, color} = props

  const {textColor} = useTownhallTemplate()
  const v = useAttendeeVariables()
  const isEditMode = useEditMode()

  if (!description && !isEditMode) {
    return null
  }

  return (
    <StyledText
      Component={Description}
      fontStyles={fontStyles}
      aria-label={props['aria-label']}
      color={color ?? textColor}
    >
      {v(description || '')}
    </StyledText>
  )
}

const Description = styled.p<{
  color: string | null
}>`
  color: ${(props) => props.color};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 170%;
  letter-spacing: 0.16px;
  margin-bottom: ${(props) => props.theme.spacing[6]};
`

import {useTemplate} from 'Event/TemplateProvider'
import {useSaveTemplate} from 'Event/TemplateUpdateProvider'
import Title from 'lib/ui/ConfigPanel/Title'
import ColorPicker from 'lib/ui/ColorPicker'
import Slider from 'lib/ui/Slider'
import {useConfig} from 'organization/Event/Configurable'
import Config from 'organization/Event/Configurable/Config'
import StylingPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/StylingPanel'
import React from 'react'
import {Controller} from 'react-hook-form'

export default function BackgroundLayoutConfig() {
  const save = useSaveTemplate()
  const {zoomBackgrounds} = useTemplate()

  const {
    form: {control},
  } = useConfig()

  return (
    <Config title="Background" onSave={save}>
      <StylingPanel>
        <Controller
          name="zoomBackgrounds.borderColor"
          defaultValue={zoomBackgrounds.borderColor}
          control={control}
          render={({value, onChange}) => (
            <ColorPicker
              label="Border Color"
              color={value}
              onPick={onChange}
              aria-label="background image border color"
            />
          )}
        />
        <Controller
          name="zoomBackgrounds.borderThickness"
          defaultValue={zoomBackgrounds.borderThickness}
          control={control}
          render={({value, onChange}) => (
            <Slider
              data-testid="border-thickness-slider"
              label="Border Thickness"
              aria-label="background image border thickness"
              value={value}
              onChange={onChange}
              step={1}
              min={0}
              max={60}
            />
          )}
        />
        <Controller
          name="zoomBackgrounds.borderRadius"
          defaultValue={zoomBackgrounds.borderRadius}
          control={control}
          render={({value, onChange}) => (
            <Slider
              data-testid="border-radius-slider"
              label="Border Radius"
              aria-label="background image border radius"
              value={value}
              onChange={onChange}
              step={1}
              min={0}
              max={60}
            />
          )}
        />
        <Title>Image Display</Title>
        <Controller
          name="zoomBackgrounds.imagesPerRow"
          defaultValue={zoomBackgrounds.imagesPerRow}
          control={control}
          render={({value, onChange}) => (
            <Slider
              label="Number of Images per row"
              aria-label="background images per row"
              value={value}
              onChange={onChange}
              step={1}
              min={1}
              max={3}
              hideUnits
            />
          )}
        />
      </StylingPanel>
    </Config>
  )
}

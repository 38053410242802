import grey from '@material-ui/core/colors/grey'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import React, {useCallback, useState} from 'react'
import IconButton from 'lib/ui/IconButton'
import styled from 'styled-components'
import Tooltip from '@material-ui/core/Tooltip'
import {ButtonProps} from 'lib/ui/Button'
import {useIfMounted} from 'lib/dom'

const BEFORE_COPY_TOOLTIP_TEXT = 'Copy'
const AFTER_COPY_TOOLTIP_TEXT = 'Copied'

// Add a reset delay to prevent text from changing BEFORE
// tooltip has properly closed.
const RESET_DELAY_MS = 100

export default function CopyIconButton(
  props: Omit<ButtonProps, 'onClick' | 'children'> & {
    beforeCopyTooltip?: string
    onClick: () => void
  },
) {
  const {beforeCopyTooltip = BEFORE_COPY_TOOLTIP_TEXT} = props
  const [copied, setCopied] = useState(false)

  const ifMounted = useIfMounted()

  const text = copied ? AFTER_COPY_TOOLTIP_TEXT : beforeCopyTooltip

  const reset = useCallback(() => ifMounted(() => setCopied(false)), [
    ifMounted,
    setCopied,
  ])

  return (
    <Tooltip
      title={text}
      placement="bottom"
      onClose={() => setTimeout(ifMounted(reset), RESET_DELAY_MS)}
    >
      <span>
        <StyledIconButton
          aria-label="copy"
          copied={copied}
          {...props}
          onClick={() => {
            props.onClick()
            setCopied(true)
          }}
        >
          <FileCopyIcon />
        </StyledIconButton>
      </span>
    </Tooltip>
  )
}

const StyledIconButton = styled(IconButton)<{copied: boolean}>`
  color: ${(props) => (props.copied ? props.theme.colors.primary : grey[500])};
`

import React from 'react'
import styled from 'styled-components'

export default function SettingsErrorPage() {
  return (
    <SettingsErrorContainer>
      <DescriptionHeader aria-label="settings error">
        Local Storage Access Required
      </DescriptionHeader>
      <Description>
        Our application needs access to your browser's Local Storage to function
        properly. It seems like this feature is currently disabled in your
        browser settings. After making these changes, please refresh the page.
      </Description>
    </SettingsErrorContainer>
  )
}

const SettingsErrorContainer = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: black;
  color: white;
  min-height: 100%;
  padding: ${(props) => props.theme.spacing[12]};
`

const DescriptionHeader = styled.div`
  font-size: 4vw;
`

const Description = styled.div`
  font-size: 2vw;
  text-align: center;
`

import {useCardsTemplate, useCardsUpdate} from 'Event/template/Cards'
import ColorPicker from 'lib/ui/ColorPicker'
import {useConfig} from 'organization/Event/Configurable'
import Config from 'organization/Event/Configurable/Config'
import StylingPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/StylingPanel'
import React from 'react'
import {Controller} from 'react-hook-form'
import Slider from 'lib/ui/Slider'

export default function ContentPanelConfig() {
  const save = useCardsUpdate()
  const template = useCardsTemplate()
  const {
    form: {control},
  } = useConfig()

  return (
    <Config title="Content Panel" onSave={save}>
      <StylingPanel>
        <Controller
          name="contentPanel.backgroundColor"
          defaultValue={template.contentPanel.backgroundColor}
          control={control}
          render={({value, onChange}) => (
            <ColorPicker
              label="Background Color"
              color={value}
              onPick={onChange}
              aria-label="page content background color"
            />
          )}
        />
        <Controller
          name="contentPanel.backgroundOpacity"
          defaultValue={template.contentPanel.backgroundOpacity}
          control={control}
          render={({value, onChange}) => (
            <Slider
              label="Background Color Opacity"
              aria-label="page content background color opacity "
              value={value}
              onChange={onChange}
              step={1}
              min={0}
              max={100}
              unit="%"
            />
          )}
        />
      </StylingPanel>
    </Config>
  )
}

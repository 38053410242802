import {PurchaseFormBlock} from 'Event/Marketplace/Block/PurchaseForm'
import Button from 'lib/ui/Button'
import React from 'react'
import styled from 'styled-components'

interface BackButtonButtonProps extends PurchaseFormBlock {
  disabled?: boolean
  onClick: () => void
}

export default function BackButtonButton(props: BackButtonButtonProps) {
  const {onClick, disabled, ...config} = props

  return (
    <StyledButton
      config={config}
      disabled={disabled}
      onClick={onClick}
      size="large"
      type="button"
      variant="contained"
    >
      <i
        className="fa-light fa-circle-arrow-left"
        title="Back to previous step"
      />
    </StyledButton>
  )
}

const StyledButton = styled(Button)<{
  active?: boolean
  config: PurchaseFormBlock
  noBorder?: boolean
}>`
  background-color: ${(props) => props.config.buttonStyle.backgroundColor};
  border: ${(props) => props.config.buttonStyle.borderWidth}px solid;
  border-color: ${(props) => props.config.buttonStyle.borderColor};
  border-radius: ${(props) => props.config.buttonStyle.borderRadius}px;
  color: ${(props) => props.config.buttonStyle.textColor};
  font-size: ${(props) => props.config.buttonStyle.fontSize}px;
  margin-right: 8px;

  &:hover {
    border-color: ${(props) => props.config.buttonStyle.hoverBorderColor};
    background: ${(props) => props.config.buttonStyle.hoverBackgroundColor};
    border: ${(props) => props.config.buttonStyle.hoverBorderColor};
    color: ${(props) => props.config.buttonStyle.hoverTextColor};
    div i {
      color: ${(props) => props.config.buttonStyle.hoverTextColor};
    }
  }
`

import React, {useCallback} from 'react'
import styled from 'styled-components'
import ErrorAlert from 'lib/ui/alerts/ErrorAlert'
import TextArea from 'lib/ui/TextField/TextArea'
import Button from 'lib/ui/Button'
import {useToggleArray} from 'lib/toggle'
import {useValidatedForm} from 'lib/form'
import {client} from 'lib/ui/api-client'
import {useEvent} from 'Event/EventProvider'
import {useOrganization} from 'organization/OrganizationProvider'
import {api} from 'lib/url'
import {Page, Script} from 'Event/Scripts'
import InputLabel from 'lib/ui/TextField/InputLabel'
import {useAsync} from 'lib/async'
import {Controller} from 'react-hook-form'
import PagesSelector from 'organization/Event/Scripts/ScriptForm/PagesSelector'
import TextField from 'lib/ui/TextField'

export type SaveScriptData = {
  file: string
  name: string
  pages: Page[]
}

export default function ScriptForm(props: {
  script?: Script
  onSubmit: (data: SaveScriptData) => Promise<void>
  defaultPages?: Page[]
}) {
  const {script, onSubmit, defaultPages = []} = props

  const [submitting, toggleSubmitting] = useToggleArray()
  const {data: content, loading} = useFetchContent(script)
  const uploadContent = useUploadContent()

  const {
    handleSubmit,
    register,
    control,
    responseError,
    setResponseError,
    errors,
  } = useValidatedForm()

  const submit = (form: {name: string; content: string; pages: Page[]}) => {
    if (submitting) {
      return
    }

    toggleSubmitting()

    const updatedContent = form.content !== content
    if (updatedContent) {
      uploadContent(form.content)
        .then((file: string) => {
          submitData({
            name: form.name,
            file,
            pages: form.pages,
          })
        })
        .catch(toggleSubmitting)
      return
    }

    if (!script?.file) {
      throw new Error('Missing script file')
    }

    submitData({file: script.file, name: form.name, pages: form.pages})
  }

  const submitData = (data: SaveScriptData) => {
    onSubmit(data).catch((e) => {
      setResponseError(e)
      toggleSubmitting()
    })
  }

  if (loading) {
    return null
  }

  return (
    <>
      <ErrorAlert>{responseError?.message}</ErrorAlert>
      <form onSubmit={handleSubmit(submit)}>
        <TextField
          label="Name"
          name="name"
          defaultValue={script?.name || ''}
          fullWidth
          variant="outlined"
          disabled={submitting}
          inputProps={{
            ref: register({required: 'Name is required'}),
            'aria-label': 'name',
          }}
          error={!!errors.name}
          helperText={errors.name}
        />
        <InputLabel>Script</InputLabel>
        <TextArea
          name="content"
          rows={10}
          fullWidth
          defaultValue={content || ''}
          inputProps={{
            'aria-label': 'script input',
            ref: register,
          }}
        />
        <Controller
          name="pages"
          defaultValue={script?.pages || defaultPages}
          control={control}
          render={({value, onChange}) => (
            <PagesSelector value={value} onChange={onChange} />
          )}
        />
        <SaveButton
          variant="contained"
          color="success"
          fullWidth
          disabled={submitting}
          type="submit"
        >
          Save
        </SaveButton>
      </form>
    </>
  )
}

function useUploadContent() {
  const getNewFile = useGetNewFile()
  const uploadToS3 = useUploadToS3()

  return async (content: string) => {
    const {upload_url: uploadUrl, file} = await getNewFile()
    const upload = new File([content.replace(/(<([^>]+)>)/gi, '')], file, {
      type: 'text/plain',
    })
    await uploadToS3(uploadUrl, upload)
    return file
  }
}

/**
 * Returns a new script file we can upload to
 */
function useGetNewFile() {
  const {event} = useEvent()
  const {client} = useOrganization()
  const url = api(`/events/${event.id}/scripts/file`)

  return () =>
    client.get<{
      upload_url: string
      file: string
    }>(url)
}

function useUploadToS3() {
  return (url: string, file: File) =>
    client.put(url, file, {
      headers: {
        'Content-Type': file.type,
      },
    })
}

export function useFetchContent(script?: Script) {
  const request = useCallback(() => {
    if (!script) {
      return Promise.resolve('')
    }

    try {
      return client.get<string>(script.url)
    } catch {
      return Promise.resolve('')
    }
  }, [script])

  return useAsync(request)
}

const SaveButton = styled(Button)`
  margin: ${(props) => props.theme.spacing[4]} 0;
`

import React from 'react'
import styled from 'styled-components'

export default function PageHeader(props: {
  children: JSX.Element | JSX.Element[]
  className?: string
}) {
  return <Container className={props.className}>{props.children}</Container>
}

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${(props) => props.theme.spacing[21]} !important;

  @media (min-width: ${(props) => props.theme.breakpoints.sm}) {
    margin-bottom: ${(props) => props.theme.spacing[19]} !important;
  }
`

import React, {useCallback, useEffect, useState} from 'react'
import {useLocation} from 'react-router'
import {clearLastLoggedOut, getLastLoggedOut} from 'auth/token'
import {getDiffDatetime, now} from 'lib/date-time'
import {useIfMounted} from 'lib/dom'
import {useObvioAuth} from 'obvio/auth'
import {obvioRoutes} from 'obvio/Routes'

const IGNORE_THRESHOLD_SECONDS = 2

interface DeepLinkContextProps {
  clearDeepLink: () => void
  deepLinkLocation: () => string
}

export const DeepLinkContext = React.createContext<
  DeepLinkContextProps | undefined
>(undefined)

export function AdminDeepLinkProvider(props: {children: React.ReactElement}) {
  const {user, loading} = useObvioAuth()

  if (loading) {
    return null
  }

  const shouldRememberLocation = !Boolean(user)

  return (
    <DeepLinkProvider shouldRememberLocation={shouldRememberLocation}>
      {props.children}
    </DeepLinkProvider>
  )
}

export default function DeepLinkProvider(props: {
  children: React.ReactElement
  shouldRememberLocation?: boolean
}) {
  const {shouldRememberLocation} = props
  const location = useLocation()
  const ifMounted = useIfMounted()

  const [preAuthRequest, setPreAuthRequest] = useState<string>('')

  useEffect(() => {
    // Certain paths we don't want to remember for after authentication.
    if (isExcludedPath(location.pathname)) {
      return
    }

    if (!shouldRememberLocation) {
      setPreAuthRequest('')
      return
    }

    // If the ignoreLogout flag has been set, a user has clicked the logout link
    // and the way the app works, it would record the "preAuthRequest" right after
    // logout, which isn't the correct UX.
    if (isIgnoringLogout()) {
      return
    }

    // Remember the full requested path coupled with any query string from the
    // initial request. This is what will be redirected to after successful auth.
    setPreAuthRequest(`${location.pathname}${location.search}`)
  }, [location, ifMounted, shouldRememberLocation])

  /**
   * If we have a mounted component, clearing out the preAuthRequest value, since
   * there was a redirect to the location, we don't need it anymore, we only want
   * to remember one preAuthRequest.
   */
  const clearDeepLink = useCallback(() => {
    ifMounted(() => setPreAuthRequest(''))
  }, [ifMounted])

  /**
   * Sipmle accessor to return the string, using a method name that makes sense.
   * @returns string
   */
  const deepLinkLocation = () => {
    return preAuthRequest
  }

  return (
    <DeepLinkContext.Provider
      value={{
        clearDeepLink,
        deepLinkLocation,
      }}
    >
      {props.children}
    </DeepLinkContext.Provider>
  )
}

export function useDeepLink() {
  const context = React.useContext(DeepLinkContext)
  if (context === undefined) {
    throw new Error('useDeepLink must be used within a DeepLinkProvider')
  }

  return context
}

/**
 * Determines if the incoming path happens to be one we don't want to remember a
 * preAuthRequest for.
 *
 * @param path string
 * @returns boolean
 */
function isExcludedPath(path: string) {
  switch (path) {
    case '/login':
      return true
    case obvioRoutes.registration:
      return true
    case obvioRoutes.forgot_password:
      return true
    case obvioRoutes.reset_password:
      return true
    case obvioRoutes.accept_invitation:
      return true
    case obvioRoutes.purchase:
      return true
    default:
      return false
  }
}

/**
 * If the user just logged out, the way the App's routing works, the location/page
 * they were on when they clicked "logout" would be recoreded as a preAuthRequest.
 * This is a bogus UX, because if/when they login again, they'll go to that page.
 * We'll want to ignore the request if it's RIGHT after they logged out.
 *
 * @returns boolean
 */
function isIgnoringLogout() {
  // Logging out stores a timestamp in localStorage, we'll get that here.
  const lastLoggedOut = getLastLoggedOut()

  // If we don't have a lastLoggedOut timestamp at all, we're not ignoring.
  if (!lastLoggedOut) {
    return false
  }

  const diff = getDiffDatetime(now(), lastLoggedOut)

  // If the difference in seconds between "now" and the last logged out time is
  // greater than our threshold, user has not JUST logged out, so any request
  // that is made is something that we would WANT to record to redirect to after
  // a successful login.
  if (diff > IGNORE_THRESHOLD_SECONDS) {
    // We're only ignoring the first location after a logout, so we clear the
    // value out of storage to not be a factor moving forward.
    clearLastLoggedOut()
    return false
  }

  // If we've made it to this place, we're going to ignore.
  return true
}

import React from 'react'
import {
  Broadcast,
  useBroadcasts,
} from 'organization/Event/Broadcasts/BroadcastsProvider'
import {useOrganization} from 'organization/OrganizationProvider'
import {communicationsApi} from 'lib/url'
import {useEvent} from 'Event/EventProvider'
import BroadcastForm, {
  Payload,
} from 'organization/Event/Broadcasts/BroadcastForm'

export default function NewBroadcastDialog(props: {
  showing: boolean
  onClose: () => void
}) {
  const {showing, onClose} = props

  const create = useCreateBroadcast()

  const broadcasts = useBroadcasts()

  const handleSubmit = (data: Payload) =>
    create(data).then((broadcast) => {
      broadcasts.add(broadcast)
      props.onClose()
    })

  return (
    <BroadcastForm
      title="Add Broadcast"
      showing={showing}
      onSubmit={handleSubmit}
      onClose={onClose}
    />
  )
}

export function useCreateBroadcast() {
  const {client} = useOrganization()
  const {event} = useEvent()

  return (data: Payload) => {
    const url = communicationsApi(`/events/${event.id}/broadcasts`)
    return client.post<Broadcast>(url, data)
  }
}

export type HashMap<T> = Record<string, T>

export type Ordered = {
  // Position starts off as undefined, and we'll sort those last by default. This
  // prevents any position discrepencies between clients from inserting an item
  // in any position other than last.
  position?: number
}

export function orderedIdsByPosition(items: HashMap<Ordered>) {
  return Object.entries(items)
    .sort(([_aId, {position: aPosition}], [_bId, {position: bPosition}]) => {
      // Place items without positions last
      if (!aPosition) {
        return 1
      }

      if (!bPosition) {
        return -1
      }

      return aPosition - bPosition
    })
    .map(([id]) => id)
}

import Card from 'Event/template/Cards/Speakers/SpeakerList/Card'
import Grid, {GridSpacing} from '@material-ui/core/Grid'
import React from 'react'
import Typography from '@material-ui/core/Typography'
import {DragDropContext, Droppable} from 'react-beautiful-dnd'
import {useCardsTemplate} from 'Event/template/Cards'
import {orderedIdsByPosition} from 'lib/list'
import {useEditMode} from 'Event/EditModeProvider'
import {useHandleDragSpeakers} from 'organization/Event/SpeakersConfig'
import {VisibleSpeaker} from 'Event/Speakers'

export default function SpeakerList() {
  const template = useCardsTemplate()
  const speakerItems = template.speakers.items
  const ids = orderedIdsByPosition(speakerItems)
  const isEditMode = useEditMode()

  const isEmpty = ids.length === 0
  if (isEmpty) {
    return <Typography align="center">No speakers have been added</Typography>
  }

  const spacing = template.speakers?.spacing as GridSpacing

  const speakers = ids.map((id: string, index: number) => (
    <VisibleSpeaker key={id} speaker={speakerItems[id]}>
      <Grid item xs={12} key={id}>
        <Card id={id} speaker={speakerItems[id]} index={index} />
      </Grid>
    </VisibleSpeaker>
  ))

  if (!isEditMode) {
    return (
      <Grid container spacing={spacing}>
        {speakers}
      </Grid>
    )
  }
  return <DroppableList spacing={spacing}>{speakers}</DroppableList>
}

function DroppableList(props: {
  spacing: GridSpacing
  children: React.ReactNode[]
}) {
  const handleDrag = useHandleDragSpeakers()

  return (
    <DragDropContext onDragEnd={handleDrag}>
      <Droppable droppableId="drag-and-drop-speaker">
        {(provided) => (
          <Grid
            container
            spacing={props.spacing}
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            <>
              {props.children}
              {provided.placeholder}
            </>
          </Grid>
        )}
      </Droppable>
    </DragDropContext>
  )
}

import React from 'react'
import {Controller, UseFormMethods} from 'react-hook-form'
import Slider from 'lib/ui/Slider'
import ColorPicker from 'lib/ui/ColorPicker'
import {Label} from 'lib/ui/typography'
import {useNiftyFiftyTemplate} from 'Event/template/NiftyFifty'
import BackgroundPicker from 'lib/ui/form/BackgroundPicker'

export default function Styling(props: {
  control: UseFormMethods['control']
  register: UseFormMethods['register']
}) {
  const {control} = props
  const template = useNiftyFiftyTemplate()
  const {rightPanel, rowBackgroundPrimary, rowBackgroundSecondary} = template
  const titleTextColor = template.pageTitleColor
    ? template.pageTitleColor
    : template.textColor

  return (
    <>
      <Controller
        name="rightPanel.barBackgroundColor"
        defaultValue={rightPanel.barBackgroundColor}
        control={control}
        render={({value, onChange}) => (
          <ColorPicker
            label="Bar Background Color"
            color={value}
            onPick={onChange}
            aria-label="bar background color"
          />
        )}
      />
      <Controller
        name="rightPanel.barTextColor"
        defaultValue={rightPanel.barTextColor}
        control={control}
        render={({value, onChange}) => (
          <ColorPicker
            label="Bar Text Color"
            color={value}
            onPick={onChange}
            aria-label="bar text color"
          />
        )}
      />
      <Controller
        name="rightPanel.tabUnderlineColor"
        defaultValue={rightPanel.tabUnderlineColor}
        control={control}
        render={({value, onChange}) => (
          <ColorPicker
            label="Tab Underline Color"
            color={value}
            onPick={onChange}
            aria-label="Tab underline color"
          />
        )}
      />
      <Controller
        name="textColor"
        defaultValue={template.textColor}
        control={control}
        render={({value, onChange}) => (
          <ColorPicker
            label="Text Color"
            color={value}
            onPick={onChange}
            aria-label="text color"
          />
        )}
      />
      <Controller
        name="pageTitleColor"
        defaultValue={titleTextColor}
        control={control}
        render={({value, onChange}) => (
          <ColorPicker
            label="Title Text Color"
            color={value}
            onPick={onChange}
            aria-label="title text color"
          />
        )}
      />
      <Controller
        name="rightPanel.backgroundColor"
        defaultValue={rightPanel.backgroundColor}
        control={control}
        render={({value, onChange}) => (
          <ColorPicker
            label="Background Color"
            color={value}
            onPick={onChange}
            aria-label="panel background color"
          />
        )}
      />
      <Controller
        name="rightPanel.backgroundOpacity"
        defaultValue={rightPanel.backgroundOpacity}
        control={control}
        render={({value, onChange}) => (
          <Slider
            min={0}
            max={100}
            step={1}
            onChange={onChange}
            valueLabelDisplay="auto"
            value={value}
            label="Background Opacity"
            unit="%"
          />
        )}
      />
      <Controller
        name="rightPanel.indicatorWidth"
        defaultValue={rightPanel.indicatorWidth}
        control={control}
        render={({value, onChange}) => (
          <Slider
            min={0}
            max={100}
            step={1}
            onChange={onChange}
            valueLabelDisplay="auto"
            value={value}
            label="Indicator Width"
          />
        )}
      />
      <Controller
        name="rightPanel.padding"
        defaultValue={rightPanel.padding}
        control={control}
        render={({value, onChange}) => (
          <Slider
            min={0}
            max={100}
            step={1}
            onChange={onChange}
            valueLabelDisplay="auto"
            value={value}
            label="Content Padding"
          />
        )}
      />
      <Label>Row Background Primary</Label>
      <Controller
        name="rowBackgroundPrimary"
        control={control}
        defaultValue={rowBackgroundPrimary || ''}
        render={({value, onChange}) => (
          <BackgroundPicker
            label="Background"
            background={value}
            onChange={onChange}
          />
        )}
      />
      <Label>Row Background Secondary</Label>
      <Controller
        name="rowBackgroundSecondary"
        control={control}
        defaultValue={rowBackgroundSecondary || ''}
        render={({value, onChange}) => (
          <BackgroundPicker
            label="Background"
            background={value}
            onChange={onChange}
          />
        )}
      />
    </>
  )
}

import {RequestJoinUrlError} from 'Event/EventProvider'
import React from 'react'
import {useTemplate} from 'Event/TemplateProvider'
import Scripts, {AREA_OFFLINE_PAGES} from 'Event/Scripts'
import {components} from 'Event/template'
import {useAttendeeVariables} from 'Event'

const FALLBACK_OFFLINE_TITLE = 'Area is currently offline'

export type OfflinePageProps = {
  title: string
  description: string
  isPreview?: boolean
}

export default function OfflinePage(props: {error: RequestJoinUrlError}) {
  const {error} = props
  const v = useAttendeeVariables()

  const title = v(error.offline_title || FALLBACK_OFFLINE_TITLE)
  const description = v(error.offline_description || '')

  return (
    <>
      <Scripts page={AREA_OFFLINE_PAGES} />
      <TemplateOfflinePage title={title} description={description} />
    </>
  )
}

function TemplateOfflinePage(props: OfflinePageProps) {
  const {name} = useTemplate()
  const Component = components[name].EventOfflinePage.index

  return <Component {...props} />
}

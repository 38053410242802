import Page from 'organization/Event/Page'
import React, {useEffect, useState} from 'react'
import {useEvent} from 'Event/EventProvider'
import {useOrganization} from 'organization/OrganizationProvider'
import {api} from 'lib/url'
import {useServices} from 'organization/Event/Services/ServicesProvider'
import {useIsMounted} from 'lib/dom'
import {useEventRoutes} from 'organization/Event/EventRoutes'
import {useLocation} from 'react-router-dom'
import {useHistory} from 'react-router'
import {RelativeLink} from 'lib/ui/link/RelativeLink'
import {ServicesBreadcrumbs} from 'organization/Event/Page/PageBreadcrumbs'
import {useRedirectUrl} from 'organization/Event/Services/Apps/HighLevel/LinkPage'
import {HighLevelIntegration} from 'organization/Event/Services/Apps/HighLevel'

export default function AuthPage(props: {authCode: string}) {
  const [hasSubmitted, setHasSubmitted] = useState(false)
  const [failed, setFailed] = useState(false)
  const completeAuth = useCompleteAuth()
  const {update: updateIntegration} = useServices()
  const {authCode} = props
  const isMounted = useIsMounted()
  const routes = useEventRoutes()
  const location = useLocation()
  const history = useHistory()

  useEffect(() => {
    if (hasSubmitted) {
      return
    }

    setHasSubmitted(true)

    completeAuth(authCode)
      .then(updateIntegration)
      .catch((e) => {
        console.error(e)

        if (!isMounted.current) {
          return
        }

        setFailed(true)
      })
  }, [
    authCode,
    completeAuth,
    hasSubmitted,
    updateIntegration,
    isMounted,
    routes,
    history,
    location,
  ])

  if (failed) {
    return (
      <Content>
        <>
          <p>Error: could not authenticate with High Level</p>
          <p>
            <RelativeLink to={routes.services.highlevel}>
              Click here
            </RelativeLink>{' '}
            to go back.
          </p>
        </>
      </Content>
    )
  }
  return (
    <Content>
      <div>processing...</div>
    </Content>
  )
}

function Content(props: {children: JSX.Element}) {
  return (
    <ServicesBreadcrumbs page="High Level">
      <Page>{props.children}</Page>
    </ServicesBreadcrumbs>
  )
}

function useCompleteAuth() {
  const {event} = useEvent()
  const {client} = useOrganization()
  const url = api(`/events/${event.id}/integrations/high_level/auth`)

  const redirectUrl = useRedirectUrl()

  return (authCode: string) =>
    client.post<HighLevelIntegration>(url, {
      auth_code: authCode,
      redirect_url: redirectUrl,
    })
}

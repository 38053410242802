import React from 'react'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import BaseConfig from 'Event/Sponsors/SponsorConfig'
import Settings from 'Event/template/Townhall/Sponsors/SponsorList/Card/SponsorConfig/Settings'
import Styling from 'Event/template/Townhall/Sponsors/SponsorList/Card/SponsorConfig/Styling'
import StylingPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/StylingPanel'
import {TownhallSponsor} from 'Event/template/Townhall/Sponsors/SponsorList'

export default function SponsorConfig(props: {
  id: string
  sponsor: TownhallSponsor
}) {
  return (
    <BaseConfig
      {...props}
      settingsPanel={
        <SettingsPanel>
          <Settings id={props.id} sponsor={props.sponsor} />
        </SettingsPanel>
      }
      stylingPanel={
        <StylingPanel>
          <Styling id={props.id} sponsor={props.sponsor} />
        </StylingPanel>
      }
    />
  )
}

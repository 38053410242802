import {Client, client} from 'lib/ui/api-client'
import {useAsync} from 'lib/async'
import {api, getDomain, getEventUrl, isObvioApp, isObvioDomain} from 'lib/url'
import {domainEventSlug, useParamEventId} from 'Event/url'
import React, {useCallback, useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {refreshEvent, setEvent} from 'Event/state/actions'
import {ObvioEvent} from 'Event'
import {RootState} from 'store'
import {eventClient} from 'Event/api-client'
import {useInterval} from 'lib/interval'
import {useEditMode} from 'Event/EditModeProvider'
import {useOrganization} from 'organization/OrganizationProvider'
import FullPageLoader from 'lib/ui/layout/FullPageLoader'
import {useTemplate} from 'Event/TemplateProvider'

interface EventContextProps {
  event: ObvioEvent
  client: Client
  set: (event: ObvioEvent) => void
  url: string
}

export const EventContext = React.createContext<EventContextProps | undefined>(
  undefined,
)

export function DomainEventProvider(props: {children: React.ReactNode}) {
  const domain = getDomain(window.location.host)
  const slug = domainEventSlug()

  // If we're not on the obvio app (app.obv.io / admin), and this is
  // not a defined system domain, this event must be for a custom
  // user domain.
  if (!isObvioApp() && !isObvioDomain(domain)) {
    return <EventProvider domain={domain} slug={slug} {...props} />
  }

  return <EventProvider slug={slug} {...props} />
}

export function RouteEventProvider(props: {children: React.ReactNode}) {
  const slug = useParamEventId()
  return <EventProvider slug={slug} {...props} noCache />
}

function EventProvider(props: {
  children: React.ReactNode
  slug: string
  domain?: string
  noCache?: boolean
}) {
  const {slug, domain, noCache} = props

  const getEvent = useCallback(() => {
    if (domain) {
      return findEventByDomain(domain, {noCache})
    }

    return findEvent(slug, {noCache})
  }, [slug, domain, noCache])

  const dispatch = useDispatch()

  const {data: saved, loading} = useAsync(getEvent)
  const current = useSelector((state: RootState) => state.event)

  const set = useCallback(
    (target: ObvioEvent) => {
      dispatch(setEvent(target))
    },
    [dispatch],
  )

  useEffect(() => {
    if (!saved) {
      return
    }
    set(saved)
  }, [saved, set])

  if (loading) {
    return <FullPageLoader />
  }

  if (!saved) {
    return (
      <div>
        <h1>404 - Event not found</h1>
      </div>
    )
  }

  if (!current) {
    return <FullPageLoader />
  }

  const url = getEventUrl(current)

  return (
    <EventContext.Provider
      value={{
        event: current,
        client: eventClient,
        set,
        url,
      }}
    >
      {props.children}
    </EventContext.Provider>
  )
}

export function useEvent() {
  const context = React.useContext(EventContext)
  if (context === undefined) {
    throw new Error('useEvent must be used within a EventProvider')
  }

  return context
}

function findEvent(slug: string, options: {noCache?: boolean} = {}) {
  const url = api(`/events/${slug}`, {cdn: true})
  return client.get<ObvioEvent>(url, {noCache: options.noCache})
}

function findEventById(id: number, options: {noCache?: boolean} = {}) {
  const url = api(`/events/${id}`, {cdn: true})
  return client.get<ObvioEvent>(url, {noCache: options.noCache})
}

function findEventByDomain(domain: string, options: {noCache?: boolean}) {
  const url = api(`/events/domain/${domain}`)
  return client.get<ObvioEvent>(url, {noCache: options.noCache})
}

export function useHasTechCheck() {
  const {
    techCheck: {isEnabled},
  } = useTemplate()

  return isEnabled
}

/**
 * How long to wait until we retry fetching the join url again
 * @param attempt
 */
export const getRetryJoinUrlBackoffMs = (attempt: number) => {
  // For first 2 attempts we'll wait 5 seconds..
  if (attempt < 2) {
    return 5000
  }

  // After attempt 3, we'll wait  10 seconds...
  if (attempt < 4) {
    return 10000
  }

  // Finally if we're here we'll wait 20 seconds...
  return 20000
}

export interface RequestJoinUrlError {
  message: string
  offline_title?: string | null
  offline_description?: string | null
}

export function useJoinUrl(areaId: string) {
  const {
    event: {id},
    client,
  } = useEvent()
  const [joinUrl, setJoinUrl] = useState<null | string>(null)
  const isEditMode = useEditMode()
  const [error, setError] = useState<RequestJoinUrlError | null>(null)
  const [attempt, setAttempt] = useState(1)

  const fetchUrl = useCallback(() => {
    if (isEditMode) {
      return Promise.resolve(null)
    }

    const url = api(`/events/${id}/areas/${areaId}/join`)

    return client
      .get<{url: string | null}>(url)
      .then(({url}) => {
        setJoinUrl(url)
        return url
      })
      .catch((e) => {
        setError(e)
        console.error(`Could not fetch join url: ${e.message}`)
      })
  }, [client, id, areaId, isEditMode])

  // Kick off initial fetch
  useEffect(() => {
    if (joinUrl) {
      return
    }

    let timer: ReturnType<typeof setTimeout>

    fetchUrl().then((url) => {
      if (!url) {
        const backOffSecs = getRetryJoinUrlBackoffMs(attempt)
        timer = setTimeout(() => {
          setAttempt((attempt) => attempt + 1)
        }, backOffSecs)
      }
    })

    return () => {
      clearTimeout(timer)
    }
  }, [fetchUrl, attempt, joinUrl])

  return {error, joinUrl}
}

export function useUpdate() {
  const {client} = useOrganization()
  const {event, set: setEvent} = useEvent()

  const url = api(`/events/${event.id}`)

  return (data: Partial<ObvioEvent> | FormData) =>
    client.put<ObvioEvent>(url, data).then((updated) => {
      setEvent(updated)
      return updated
    })
}

/**
 * Auto-refresh event interval will fetch the event every x seconds. This
 * value should be at least greater than the cached value.
 */

export const AUTO_REFRESH_EVENT_INTERVAL_SEC = 100

export function AutoRefreshEvent(props: {children: React.ReactElement}) {
  const {event, set} = useEvent()

  const refresh = useCallback(() => {
    findEventById(event.id).then(set)
  }, [event, set])

  useInterval(refresh, AUTO_REFRESH_EVENT_INTERVAL_SEC * 1000)

  return props.children
}

export type RefreshEventParams = {
  updatedAt: string
  socketId?: string | null
  id?: string | null
}

export function useRefreshEvent() {
  const dispatch = useDispatch()

  return useCallback(
    (params: RefreshEventParams) => {
      dispatch(refreshEvent(params))
    },
    [dispatch],
  )
}

import React from 'react'
import styled, {useTheme} from 'styled-components'
import IconButton from 'lib/ui/IconButton'
import {MoveDown, MoveUp} from 'lib/ui/Icon'

interface MoveIconButtonProps {
  onClick?: () => void
  className?: string
  color?: string
  showing?: boolean
  ['aria-label']?: string
  type?: 'button' | 'submit'
  direction: 'up' | 'down'
}

export default function MoveIconButton(props: MoveIconButtonProps) {
  const theme = useTheme()
  const {
    color = theme.colors.primary,
    type = 'button',
    showing,
    direction,
    className,
    onClick,
  } = props

  if (!showing) {
    return null
  }

  const Icon = direction === 'up' ? MoveUp : MoveDown

  return (
    <IconButton
      className={className}
      onClick={onClick}
      aria-label={props['aria-label']}
      type={type}
    >
      <Box>
        <Icon color={color} iconSize={24} />
      </Box>
    </IconButton>
  )
}

const Box = styled.div`
  background: #ffffff;
  display: inline-flex;
  border-radius: 4px;
  width: 24px;
  height: 24px;
  align-items: center;
  justify-content: center;
`

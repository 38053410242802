import React, {useState} from 'react'
import styled from 'styled-components'
import Tabs from '@material-ui/core/Tabs'
import {is} from 'lib/react-utils'
import {withStyles} from '@material-ui/core/styles'
import {ConfigPanelProps} from 'lib/ui/ConfigPanel'

export default function Content(props: ConfigPanelProps) {
  // Index of currently visible tab
  const [value, setValue] = useState(0)

  const {container = <div />} = props

  // We're expecting the children to be pairs of tabs, and panels in
  // sequential order.
  // ie.
  // <ConfigPanel>
  //   <Tab>1</Tab>
  //   <TabPanel> 1 content </TabPanel>
  //   <Tab>2</Tab>
  //   <TabPanel> 2 content </TabPanel>
  // </ConfigPanel>

  const body = props.children.find((c) => is(Body, c))
  if (!body) {
    throw new Error(
      'Missing <Body/> component was one provided to <ConfigPanel/>',
    )
  }

  const footer = props.children.filter((c) => !is(Body, c))

  const isEven = (_: JSX.Element, index: number) => index % 2 === 0
  const isOdd = (_: JSX.Element, index: number) => index % 2 !== 0

  const tabs = body.props.children.filter(isEven)

  const withPanelProps = (component: JSX.Element, index: number) =>
    React.cloneElement(component, {
      ...component.props,
      value,
      index,
      key: index,
    })
  const panels = body.props.children
    .filter(isOdd)
    .filter((c: JSX.Element) => {
      return !c.props.hidden
    })
    .map(withPanelProps)

  // If the parent has provided a specific container, we'll
  // wrap our contents in that.
  const content = React.cloneElement(container, {
    children: [...panels, ...footer],
  })

  return (
    <>
      <StyledTabs
        value={value}
        onChange={(_, value) => {
          setValue(parseInt(value))
        }}
        TabIndicatorProps={{
          style: {
            display: 'none',
          },
        }}
        scrollButtons="off"
      >
        {tabs}
      </StyledTabs>
      <Box className={props.className}>{content}</Box>
    </>
  )
}

/**
 * A container component that we expect to contain
 * the Tab / TabPanel pairs.
 *
 * @param props
 * @returns
 */
export function Body(props: {children: JSX.Element[]}) {
  return <div>{props.children}</div>
}

const Box = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

// Prevent button margin from causing scroll/shift
const StyledTabs = withStyles({
  flexContainer: {
    overflow: 'hidden',
  },
})(Tabs)

import React from 'react'
import Radio, {RadioProps} from 'lib/ui/RadioButtons/Radio'
import styled from 'styled-components'

type RadioOption = {
  label: string
  value: string
}

type RadioButtonProps = {
  options: RadioOption[]
  value?: string | null
  onChange: (val: string) => void
  name: string
  inputProps?: React.InputHTMLAttributes<HTMLInputElement>
} & RadioProps

export default function RadioButtons(props: RadioButtonProps) {
  return (
    <StyledDiv>
      {props.options.map((option, index) => (
        <Radio
          key={index}
          checked={props.value === option.value}
          onChange={props.onChange}
          value={option.value}
          name={props.name}
          label={option.label}
        />
      ))}
    </StyledDiv>
  )
}

const StyledDiv = styled.div`
  display: flex;
  justify-content: space-between;
`

import styled from 'styled-components'

export const DesktopOnly = (component: React.FC<any>) => styled(component)<{
  display?: 'flex' | 'block'
}>`
  display: none;

  @media screen and (min-width: ${(props) => props.theme.breakpoints.sm}) {
    display: ${(props) => props.display || 'flex'};
  }
`

export const MobileOnly = (component: React.FC<any>) => styled(component)<{
  display?: 'flex' | 'block'
}>`
  display: ${(props) => props.display || 'flex'};

  @media screen and (min-width: ${(props) => props.theme.breakpoints.sm}) {
    display: none;
  }
`

import React from 'react'
import styled, {useTheme} from 'styled-components'
import HelperText from 'lib/ui/TextField/HelperText'
import {BaseProps} from 'lib/ui'
import {onChangeNumberHandler, onChangeStringHandler} from 'lib/dom'
import InputLabel from 'lib/ui/TextField/InputLabel'

export type BaseTextFieldProps<T extends string | number> = {
  className?: string
  rounded?: boolean
  variant?: 'filled' | 'outlined'
  fullWidth?: boolean
  isTight?: boolean
  name?: string
  type?: 'text' | 'password' | 'number'
  'aria-label'?: string
  placeholder?: string
  value?: T
  defaultValue?: T | null
  disabled?: boolean
  error?: boolean
  onChange?: React.ChangeEventHandler<HTMLInputElement>
  onFocus?: React.FocusEventHandler<HTMLInputElement>
  onClick?: React.MouseEventHandler<HTMLInputElement>
  helperText?: string | JSX.Element
  required?: boolean
  endAdornment?: JSX.Element
  startAdornment?: JSX.Element
  inputProps?: BaseProps<HTMLInputElement> &
    React.InputHTMLAttributes<HTMLInputElement | HTMLTextAreaElement>
  label?: string
  emptyValue?: string | number
}

export type TextFieldProps = Omit<BaseTextFieldProps<string>, 'onChange'> & {
  onChange?: (value: string) => void
}

export default function TextField(props: TextFieldProps) {
  const {label, ...textFieldProps} = props
  return (
    <>
      <InputLabel>{label}</InputLabel>
      <BaseTextField
        type="text"
        {...textFieldProps}
        onChange={props.onChange && onChangeStringHandler(props.onChange)}
      />
    </>
  )
}

export function NumberField(
  props: Omit<BaseTextFieldProps<number>, 'onChange' | 'inputProps'> & {
    onChange?: (value: number) => void
    inputProps?: BaseTextFieldProps<number>['inputProps'] & {
      min?: number
      max?: number
      step?: number
    }
  },
) {
  const {label, ...fieldProps} = props
  return (
    <div>
      <InputLabel>{label}</InputLabel>
      <BaseTextField
        {...fieldProps}
        type="number"
        onChange={props.onChange && onChangeNumberHandler(props.onChange)}
      />
    </div>
  )
}

export function BaseTextField<T extends string | number>(
  props: BaseTextFieldProps<T>,
) {
  const backgroundColor = useBackgroundColor(props)
  const {
    endAdornment = null,
    startAdornment = null,
    variant,
    inputProps,
    value,
    className,
    defaultValue,
    ...forwardProps
  } = props
  const hasBorder = variant === 'outlined'

  const baseProps = {
    ...forwardProps,
    backgroundColor,
    color: useTextColor(),
    hasBorder,
    error: Boolean(props.error),
  }

  // Dynamically add 'value' relevant props to avoid warning about having
  // both 'value', and 'defaultValue' set.
  const keys = Object.keys(props)
  const hasValue = keys.includes('value')

  // Need an onChange when `value` is defined, or MUI will throw a warning.
  // In cases where we don't care about an onChange this should silence
  // the warning.
  const noopOnChange = () => {}

  const propsWithValue = hasValue
    ? {
        ...baseProps,
        value: value || getEmptyValue(props.type || '', props.emptyValue),
        onChange: props.onChange || noopOnChange,
      }
    : {
        ...baseProps,
        defaultValue: defaultValue || '',
      }

  return (
    <Box
      fullWidth={props.fullWidth}
      isTight={props.isTight}
      className={className}
    >
      <Content>
        {startAdornment}
        <StyledInput
          type="text"
          {...propsWithValue}
          {...inputProps}
          name={props.name}
        />
        {endAdornment}
      </Content>
      <HelperText error={props.error} disabled={props.disabled}>
        {props.helperText}
      </HelperText>
    </Box>
  )
}

function getEmptyValue(type: string, value?: string | number) {
  if (type === 'number') {
    return value === undefined ? 0 : value
  }

  return value === undefined ? '' : value
}

function useTextColor() {
  const theme = useTheme()
  if (theme.name === 'dark') {
    return '#FFFFFF'
  }

  return '#000000'
}

export function useBackgroundColor<T extends string | number>(
  props: BaseTextFieldProps<T>,
) {
  const theme = useTheme()

  if (theme.name === 'dark') {
    return theme.colors.input.dark
  }

  if (props.variant === 'filled') {
    return theme.colors.input.filled
  }

  return theme.colors.input.background
}

type StyleInputProps = {
  backgroundColor: string
  color: string
  rounded?: boolean
  hasBorder?: boolean
  error?: boolean
  disabled?: boolean
}

export const StyledInput = styled.input<StyleInputProps>`
  height: ${(props) => props.theme.spacing[10]};
  box-sizing: border-box;
  padding: ${(props) => `${props.theme.spacing[3]} ${props.theme.spacing[3]}`};
  color: ${(props) =>
    props.disabled ? props.theme.colors.disabled : props.color};
  background-color: ${(props) => props.backgroundColor};
  border-radius: ${(props) => `${props.rounded ? '24px' : '4px'}`};
  border-width: ${(props) => `${props.hasBorder || props.error ? '1px' : 0}`};
  border-color: ${(props) =>
    `${props.error ? props.theme.colors.error : props.theme.colors.gray300}`};
  border-style: solid;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  &:focus {
    outline: none !important;
    border-color: ${(props) =>
      `${
        props.error ? props.theme.colors.error : props.theme.colors.input.focus
      }`};
  }

  &::placeholder {
    ${(props) =>
      props.disabled ? `color: ${props.theme.colors.disabled};` : ''}
  }
`

const Box = styled.div<{
  fullWidth?: boolean
  isTight?: boolean
}>`
  display: flex;
  width: ${(props) => (props.fullWidth ? '100%' : 'auto')};
  flex-direction: column;
  margin-bottom: ${(props) => props.theme.spacing[6]};

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    margin-bottom: ${(props) => (props.isTight ? 0 : props.theme.spacing[6])};
  }
`

const Content = styled.div`
  display: flex;

  ${StyledInput} {
    flex: 1;
  }
`

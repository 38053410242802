import React, {useState} from 'react'
import {
  BlogPost,
  useInsertPost,
  useRemovePost,
  useUpdatePost,
} from 'Event/Dashboard/components/BlogPosts'
import {useTemplate} from 'Event/TemplateProvider'
import {SubmitHandler, UseFormMethods, useForm} from 'react-hook-form'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import RulesPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/RulesPanel'
import Settings from 'Event/Dashboard/components/BlogPosts/BlogPostConfig/Settings'
import ScheduleInput from 'organization/Event/DashboardConfig/ComponentConfigPanel/ScheduleInput'

import ComponentConfig, {
  ComponentConfigProps,
  SaveButton,
  RemoveButton,
  Footer,
} from 'organization/Event/DashboardConfig/ComponentConfigPanel'
import {Rule} from 'Event/attendee-rules'

export const DEFAULT_MODAL_BUTTON_TEXT = 'Submit'

export function EditPost(props: {id: string | null; onClose: () => void}) {
  const {id, onClose} = props
  const {blogPosts} = useTemplate()

  if (!id) {
    return null
  }

  const post = blogPosts[id]
  if (!post) {
    return null
  }

  return <BlogPostConfig id={id} showing post={post} onClose={onClose} />
}

export default function BlogPostConfig<T extends BlogPost>(
  props: ComponentConfigProps & {
    post: T
    id?: string
    onUpdate?: (id: string, updated: Partial<T>) => void
    onInsert?: (post: T) => void
    onRemove?: (id: string) => void
    additionalInputs?: (params: {
      control: UseFormMethods['control']
    }) => JSX.Element
  },
) {
  const {
    showing: visible,
    onClose,
    id,
    post,
    additionalInputs,
    onUpdate,
    onInsert,
    onRemove,
  } = props

  const {
    register,
    control,
    handleSubmit,
    watch,
    setValue,
    formState: {isDirty},
    // Set to `any` type since the default generic Record<string, any> clashes with BlogPost.
    // Not a big deal since the default isn't type-safe anyway.
  } = useForm<any>()

  const [rules, setRules] = useState(post.rules)
  const [hasRulesChanges, setHasRulesChanges] = useState(false)

  // Have to watch date fields separately as it lives on the rules 'panel', and
  // when it's hidden on close, so is the `showingUntil` field. ie., the
  // value is never saved with form submit.

  const defaultUpdate = useUpdatePost()
  const update = onUpdate ?? defaultUpdate
  const defaultInsert = useInsertPost()
  const insert = onInsert ?? defaultInsert
  const defaultRemove = useRemovePost()
  const remove = onRemove ?? defaultRemove

  const save: SubmitHandler<T> = (form) => {
    const data = {
      ...post,
      rules,
      ...form,
    } as T

    if (id) {
      update(id, data)
      onClose()
      return
    }

    insert(data)
    onClose()
  }

  const handleRemove = () => {
    if (!id) {
      throw new Error(`Could not remove post; missing id.`)
    }

    onClose()
    remove(id)
  }

  const handleRules = (newRules: Rule[]) => {
    setHasRulesChanges(true)
    setRules(newRules)
  }

  return (
    <ComponentConfig
      showing={visible}
      onClose={onClose}
      title="Blog Post"
      onSubmit={handleSubmit(save)}
      hasChanges={isDirty || hasRulesChanges}
      isDialogModal
    >
      <SettingsPanel>
        <Settings
          control={control}
          post={post}
          id={id}
          register={register}
          watch={watch}
          update={update}
          additionalInputs={additionalInputs}
        />
      </SettingsPanel>
      <RulesPanel rules={rules} setRules={handleRules}>
        <ScheduleInput
          control={control}
          setValue={setValue}
          values={{
            showingFrom: post.publishAt,
            showingUntil: post.showingUntil,
          }}
          disabledFrom
        />
      </RulesPanel>
      <Footer>
        <SaveButton type="submit" />
        <RemoveButton
          showing={Boolean(id)}
          fullWidth
          variant="outlined"
          aria-label="remove blog post"
          onClick={handleRemove}
          disabled={!id}
        />
      </Footer>
    </ComponentConfig>
  )
}

import {useEvent} from 'Event/EventProvider'
import ErrorAlert from 'lib/ui/alerts/ErrorAlert'
import Button from 'lib/ui/Button'
import TextField from 'lib/ui/TextField'
import {api} from 'lib/url'
import {ActiveCampaignField} from 'organization/Event/Services/Apps/ActiveCampaign/Config/FieldsAutocomplete'
import {useOrganization} from 'organization/OrganizationProvider'
import React, {useState} from 'react'
import {useForm} from 'react-hook-form'
import styled from 'styled-components'
import {Controller} from 'react-hook-form'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'

const defaultFieldTypes = [
  'text',
  'textarea',
  'dropdown',
  'hidden',
  'checkbox',
  'date',
  'datetime',
  'listbox',
  'radio',
]

export default function CreateNewFieldForm(props: {
  onCreate: (newField: ActiveCampaignField) => void
  types?: string[]
}) {
  const {handleSubmit, register, control} = useForm()
  const {
    event: {id: eventId},
  } = useEvent()
  const {client} = useOrganization()
  const [processing, setProcessing] = useState<boolean>(false)
  const [error, setError] = useState<string | null>(null)

  const create = (data: {field_name: string; field_type: string}) => {
    setProcessing(true)

    const url = api(
      `/events/${eventId}/integrations/active_campaign/create_field`,
    )

    client
      .post<ActiveCampaignField>(url, data)
      .then((newField) => props.onCreate(newField))
      .catch((e) => setError(e.message))
      .finally(() => {
        setProcessing(false)
      })
  }

  const fieldTypes = props.types ?? defaultFieldTypes

  return (
    <form onSubmit={handleSubmit(create)}>
      <TextField
        fullWidth
        placeholder="Field name"
        variant="outlined"
        inputProps={{
          'aria-label': 'field name',
          ref: register,
        }}
        name="field_name"
      />

      <Controller
        name="field_type"
        defaultValue="text"
        control={control}
        render={({onChange, value}) => (
          <TypePicker
            value={value}
            fullWidth
            onChange={onChange}
            variant="outlined"
            inputProps={{
              'aria-label': 'field type',
            }}
          >
            {fieldTypes.map((type) => (
              <MenuItem key={type} value={type} aria-label={`pick ${type}`}>
                {type}
              </MenuItem>
            ))}
          </TypePicker>
        )}
      />

      <ErrorAlert>{error}</ErrorAlert>
      <CreateButton
        fullWidth
        variant="contained"
        color="primary"
        type="submit"
        aria-label="create new field"
        disabled={processing}
      >
        Create
      </CreateButton>
    </form>
  )
}

const CreateButton = styled(Button)`
  margin-bottom: ${(props) => props.theme.spacing[4]}!important;
`

const TypePicker = styled(Select)`
  margin-bottom: ${(props) => props.theme.spacing[6]};

  > div {
    padding: ${(props) => props.theme.spacing[3]};
  }
`

import React from 'react'
import {RoomRegistrationFormProps} from 'Event/RoomRegistration'
import Page from 'Event/template/NiftyFifty/Page'
import LeftPanel from 'Event/template/NiftyFifty/Login/LeftPanel/index'
import RightPanel from 'Event/template/NiftyFifty/RoomRegistration/RightPanel'
import MobilePanel from 'Event/template/NiftyFifty/RoomRegistration/MobilePanel'

export default function NiftyFiftyRoomRegistrationForm(
  props: RoomRegistrationFormProps,
) {
  return (
    <Page
      Left={<LeftPanel />}
      Right={<RightPanel {...props} />}
      Mobile={<MobilePanel {...props} />}
    />
  )
}

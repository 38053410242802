import Box from '@material-ui/core/Box'
import {
  NiftyFifty,
  useNiftyFiftyTemplate,
  useNiftyFiftyUpdate,
} from 'Event/template/NiftyFifty'
import {onChangeCheckedHandler} from 'lib/dom'
import ColorPicker from 'lib/ui/ColorPicker'
import Switch from 'lib/ui/form/Switch'
import {SectionTitle} from 'organization/Event/Page'
import Page from 'organization/Event/Page'
import React from 'react'
import {useForm, Controller} from 'react-hook-form'
import {SaveButton} from 'organization/Event/DashboardConfig/ConfigDialog'
import {DesignBreadcrumbs} from 'organization/Event/Page/PageBreadcrumbs'
import {FontFamilySelect} from 'lib/FontSelect'
import InputLabel from 'lib/ui/TextField/InputLabel'
import FormControl from 'lib/ui/FormControl'

export default function GlobalStylesConfig() {
  const update = useNiftyFiftyUpdate()
  const template = useNiftyFiftyTemplate()
  const {handleSubmit, control} = useForm()
  const pageTitleColor = template.pageTitleColor
    ? template.pageTitleColor
    : template.textColor

  const submit = (data: NiftyFifty) => {
    update(data)
  }

  return (
    <DesignBreadcrumbs page="Global Styles">
      <Page>
        <SectionTitle>Global Styles</SectionTitle>
        <form onSubmit={handleSubmit(submit)}>
          <FormControl>
            <InputLabel>Font Family</InputLabel>
            <Controller
              control={control}
              name="font"
              defaultValue={template.font || null}
              render={({value, onChange}) => (
                <FormControl fullWidth>
                  <FontFamilySelect value={value} onChange={onChange} />
                </FormControl>
              )}
            />
          </FormControl>
          <Box mb={2}>
            <Controller
              name="textColor"
              defaultValue={template.textColor}
              control={control}
              render={({value, onChange}) => (
                <ColorPicker
                  label="Text Color"
                  color={value}
                  onPick={onChange}
                  aria-label="text color"
                />
              )}
            />
          </Box>
          <Box mb={2}>
            <Controller
              name="pageTitleColor"
              defaultValue={pageTitleColor}
              control={control}
              render={({value, onChange}) => (
                <ColorPicker
                  label="Title Text Color"
                  color={value}
                  onPick={onChange}
                  aria-label="title text color"
                />
              )}
            />
          </Box>
          <Box mb={2}>
            <Controller
              name="accentColor"
              defaultValue={template.accentColor}
              control={control}
              render={({value, onChange}) => (
                <ColorPicker
                  label="Accent Color"
                  color={value}
                  onPick={onChange}
                  aria-label="accent color"
                />
              )}
            />
          </Box>
          <Box mb={2}>
            <Controller
              name="linkColor"
              defaultValue={template.linkColor}
              control={control}
              render={({value, onChange}) => (
                <ColorPicker
                  label="Link Color"
                  color={value}
                  onPick={onChange}
                  aria-label="link color"
                />
              )}
            />
          </Box>
          <Box mb={2}>
            <Controller
              name="linkUnderline"
              defaultValue={template.linkUnderline}
              control={control}
              render={({value, onChange}) => (
                <Switch
                  label={
                    value ? 'Link Underline Enabled' : 'Link Underline Disabled'
                  }
                  checked={value}
                  onChange={onChangeCheckedHandler(onChange)}
                  labelPlacement="end"
                />
              )}
            />
          </Box>
          <SaveButton />
        </form>
      </Page>
    </DesignBreadcrumbs>
  )
}

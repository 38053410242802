'use client'

import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {Layouts, Responsive, WidthProvider} from 'react-grid-layout'
import {rgba} from 'lib/color'
import {breakpoints} from 'lib/ui/theme'
import {useOnResize} from 'lib/resize'
import {debounceTime} from 'rxjs/operators'
import {Subject} from 'rxjs'
import {useDelay} from 'lib/delay'
import {useToggleArray} from 'lib/toggle'
import {PurchasePageSection, PurchasePageTemplate} from './purchase-page'
import {backgroundRepeat, backgroundSize} from './lib/background'
import classNames from 'classnames'
import styles from './Section.module.css'

interface SectionProps {
  section: PurchasePageSection
  ResponsiveReactGridLayoutProps?: ReactGridLayout.ResponsiveProps &
    ReactGridLayout.WidthProviderProps
  template: PurchasePageTemplate
  children: React.ReactNode
  isMobileMode?: boolean
  disableItemResize?: boolean
  onRender?: () => void
  minTopPadding?: number
}

export const sectionRowHeight = 10

const SectionContext = React.createContext<undefined | SectionContextProps>(
  undefined,
)

interface SectionContextProps {
  calculateVisibility: () => void
}

export default function Section(props: SectionProps) {
  const {
    section,
    ResponsiveReactGridLayoutProps = {},
    children,
    isMobileMode = false,
    disableItemResize = false,
    template,
    minTopPadding = 0,
  } = props
  const {layouts, numColumns, blockSpacing} = section
  const {contentMaxWidth} = template
  const [_, rerender] = useToggleArray()

  // Fix bug where API sends back an empty object as an array causing
  // layouts to error
  const layoutsObject = Array.isArray(layouts)
    ? {
        desktop: layouts['desktop'] ?? [],
        mobile: layouts['mobile'] ?? [],
      }
    : layouts

  const containerStyles = getContainerStyles(section, {
    minTopPadding,
    isMobileMode,
  })
  const contentStyles = getContentStyles(section, {contentMaxWidth})

  const ResponsiveReactGridLayout = useMemo(() => WidthProvider(Responsive), [])

  const resizedLayouts = useResizedLayouts(layoutsObject, blockSpacing)

  return (
    <SectionContext.Provider value={{calculateVisibility: rerender}}>
      <div
        style={containerStyles}
        className={classNames({
          [styles.mobileOnly]: section.visibility === 'mobile_only',
          [styles.desktopOnly]: section.visibility === 'desktop_only',
        })}
      >
        <ResponsiveReactGridLayout
          breakpoints={
            // On mobile mode we'll just set desktop breakpoint to something ridiculously high, i.e, forcing
            // it to always be editing mobile.
            isMobileMode
              ? {desktop: 10000000000, mobile: 0}
              : {desktop: parseInt(breakpoints.md), mobile: 0}
          }
          layouts={disableItemResize ? layoutsObject : resizedLayouts}
          cols={{desktop: numColumns ?? 24, mobile: 1}}
          isDraggable={false}
          isResizable={false}
          rowHeight={sectionRowHeight}
          margin={[section.blockSpacing ?? 10, section.blockSpacing ?? 10]}
          style={{
            backgroundColor: contentStyles.backgroundColor,
            backgroundImage: contentStyles.backgroundImage,
            outline: contentStyles.outline,
            boxShadow: `inset 0 0 0 100cqh ${contentStyles.backgroundColor}`,
            backgroundPosition: contentStyles.backgroundPosition,
            backgroundSize: 'cover!important',
            backgroundRepeat: 'no-repeat',
            outlineColor: contentStyles.outlineColor,
            borderRadius: contentStyles.borderRadius,
            maxWidth: contentStyles.maxWidth,
            width: '100%',
          }}
          {...ResponsiveReactGridLayoutProps}
        >
          {children}
        </ResponsiveReactGridLayout>
      </div>
    </SectionContext.Provider>
  )
}

function getContainerStyles(
  section: PurchasePageSection,
  options: {
    minTopPadding: number
    isMobileMode: boolean
  },
): React.CSSProperties {
  return {
    display:
      section.visibility === 'desktop_only' && options.isMobileMode
        ? 'none!important'
        : 'flex',
    backgroundColor: rgba(
      section.background.color || '#FFFFFF',
      section.background.opacity / 100,
    ),
    backgroundImage: section.background
      ? `url(${section.background.image})`
      : undefined,
    boxShadow: `inset 0 0 0 100cqh ${rgba(
      section.background.color || '#FFFFFF',
      section.background.opacity / 100,
    )}`,
    backgroundPosition:
      section.background.repeat === 'full-center-parallax'
        ? 'center'
        : section.background.position,
    backgroundSize: backgroundSize(section.background.repeat),
    backgroundAttachment:
      section.background.repeat === 'full-center-parallax'
        ? 'fixed'
        : undefined,
    backgroundRepeat: backgroundRepeat(section.background.repeat),
    border: `${section.border.width}px solid`,
    borderColor: section.border.color,
    borderRadius: `${section.border.radius}px`,
    paddingTop: `${Math.max(options.minTopPadding, section.padding.top)}px`,
    paddingRight: `${section.padding.right}px`,
    paddingBottom: `${section.padding.bottom}px`,
    paddingLeft: `${section.padding.left}px`,
    marginTop: `${section.margin.top}px`,
    marginRight: `${section.margin.right}px`,
    marginBottom: `${section.margin.bottom}px`,
    marginLeft: `${section.margin.left}px`,
    minHeight: `${section.minHeight}px`,
    justifyContent: 'center',
    alignItems: 'center',
  }
}

function getContentStyles(
  section: PurchasePageSection,
  options: {
    contentMaxWidth: number
  },
) {
  return {
    backgroundImage: section.content.background
      ? `url(${section.content.background.image})`
      : undefined,
    outline: `${section.border.width}px solid`,
    backgroundColor: rgba(
      section.content.background.color || '#FFFFFF',
      section.content.background.opacity / 100,
    ),
    backgroundPosition: section.content.background.position,
    outlineColor: section.border.color,
    borderRadius: section.border.radius,
    maxWidth: section.fullWidth ? 'none' : `${options.contentMaxWidth}px`,
  }
}

function useResizedLayouts(layoutsObject: Layouts, blockSpacing: number = 10) {
  const [resizedLayouts, setResizedLayouts] = useState(layoutsObject)
  const layoutsString = JSON.stringify(layoutsObject)

  const subject = useMemo(() => new Subject<string>(), [])

  const calculateResize = useCallback(() => {
    subject.next(layoutsString)
  }, [subject, layoutsString])

  useDelay(calculateResize, 50) // delay to make sure things DOM is rendered
  useOnResize(calculateResize)

  useEffect(() => {
    // Debounce to avoid race conditions on resize
    const subscription = subject.pipe(debounceTime(50)).subscribe({
      next: (val) =>
        setResizedLayouts(resizeLayout(JSON.parse(val), blockSpacing)),
    })

    return () => {
      subscription.unsubscribe()
    }
  }, [layoutsString, subject, blockSpacing])

  return resizedLayouts
}

function resizeLayout(layouts: Layouts, blockSpacing: number) {
  const result: Layouts = {}

  for (const [key, breakpointLayouts] of Object.entries(layouts)) {
    const resizedBreakpoints = []

    for (const layout of breakpointLayouts) {
      const {i} = layout

      const el = document.getElementById(i)

      if (!el) {
        continue
      }

      const content = el.firstElementChild
      if (!content) {
        continue
      }

      const blockHeight =
        sectionRowHeight * layout.h + blockSpacing * layout.h - blockSpacing * 1

      const unitHeight =
        sectionRowHeight + blockSpacing - blockSpacing / layout.h

      const contentIsVerticallyClipped = content.clientHeight > blockHeight

      const minHeight = Math.ceil(content.clientHeight / unitHeight)

      const height = contentIsVerticallyClipped ? minHeight : layout.h

      resizedBreakpoints.push({...layout, h: height})
    }

    result[key] = resizedBreakpoints
  }

  return result
}

export function useSection() {
  const context = React.useContext(SectionContext)

  if (!context) {
    throw new Error('useSection must be used within Section')
  }

  return context
}

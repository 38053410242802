import CustomThemeCommand from './customthemecommand';
import Plugin from '@ckeditor/ckeditor5-core/src/plugin';

export default class CustomThemeEditing extends Plugin {
	static get pluginName() {
		return 'CustomThemeEditing';
	}

	init() {
		this.editor.commands.add( 'customTheme', new CustomThemeCommand( this.editor ) );
	}
}


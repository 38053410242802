import {useToggleArray} from 'lib/toggle'
import Dialog from 'lib/ui/Dialog'
import SuccessDialog from 'lib/ui/Dialog/SuccessDialog'
import OtherCardForm from 'obvio/Billing/TransactionSection/TransactionRow/PayDialog/OtherCardForm'
import SavedCardConfirmation from 'obvio/Billing/TransactionSection/TransactionRow/PayDialog/SavedCardConfirmation'
import {Transaction} from 'obvio/Billing/TransactionSection/list-transactions'
import React from 'react'

export default function PayDialog(props: {
  transaction: Transaction
  open: boolean
  onClose: () => void
  onSuccess: () => void
}) {
  const {open, onClose, transaction, onSuccess} = props
  const [paid, togglePaid] = useToggleArray()

  if (paid) {
    return (
      <SuccessDialog showing onClose={onSuccess}>
        Transaction payment complete!
      </SuccessDialog>
    )
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        style: {
          width: '450px',
        },
      }}
    >
      <Content
        transaction={transaction}
        onCancel={onClose}
        onSuccess={togglePaid}
      />
    </Dialog>
  )
}

function Content(props: {
  transaction: Transaction
  onCancel: () => void
  onSuccess: () => void
}) {
  const {transaction, onCancel, onSuccess} = props
  const [usingOtherCard, toggleUseOtherCard] = useToggleArray()

  if (usingOtherCard) {
    return (
      <OtherCardForm
        transaction={transaction}
        onSuccess={onSuccess}
        onCancel={toggleUseOtherCard}
      />
    )
  }

  return (
    <SavedCardConfirmation
      transaction={transaction}
      onCancel={onCancel}
      onUseOtherCard={toggleUseOtherCard}
      onSuccess={onSuccess}
    />
  )
}

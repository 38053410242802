import React from 'react'
import Button from 'lib/ui/Button'
import {Speaker} from 'Event/Speakers'
import styled from 'styled-components'
import {useEditMode} from 'Event/EditModeProvider'
import {useTemplate} from 'Event/TemplateProvider'
import {useSaveTemplate} from 'Event/TemplateUpdateProvider'
import {uuid} from 'lib/uuid'

type AddSpeakerButtonProps = {
  onAdd?: (speaker: Speaker) => void
}

export default function AddSpeakerButton(props: AddSpeakerButtonProps) {
  const isEditMode = useEditMode()
  if (!isEditMode) {
    return null
  }

  return <Content {...props} />
}

function Content(props: AddSpeakerButtonProps) {
  const {onAdd} = props
  const isEditMode = useEditMode()
  const {speakers} = useTemplate()
  const save = useSaveTemplate()

  const add = () => {
    const numSpeakers = Object.keys(speakers.items).length
    const position = numSpeakers + 1
    const id = uuid()

    const newSpeaker: Speaker = {
      name: 'Speaker',
      text: 'Speaker bio',
      image: null,
      position,
    }

    save({
      speakers: {
        items: {
          [id]: newSpeaker,
        },
      },
    })

    onAdd && onAdd(newSpeaker)
  }

  if (!isEditMode) {
    return null
  }

  return (
    <StyledButton
      fullWidth
      variant="contained"
      color="secondary"
      aria-label="add speaker"
      onClick={add}
    >
      Add Speaker
    </StyledButton>
  )
}

const StyledButton = styled(Button)`
  margin: ${(props) => `${props.theme.spacing[8]} 0`}!important;
`

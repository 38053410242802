import React, {useCallback, useEffect, useState} from 'react'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableBody from '@material-ui/core/TableBody'
import EditButton from 'lib/ui/Button/CustomButton'
import {colors} from 'lib/ui/theme'
import Button from '@material-ui/core/Button'
import {useEvent} from 'Event/EventProvider'
import {api} from 'lib/url'
import {useInterval} from 'lib/interval'
import {TechCheckAssignment} from 'organization/Event/Room/TechCheckAttendees'
import {Attendee} from 'Event/attendee'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import AttendeeUpdateDialog from 'Event/Dashboard/TeamAccessControls/RoomControls/TechCheckActions/AttendeeUpdateDialog'
import PauseButton from 'Event/Dashboard/TeamAccessControls/RoomControls/TechCheckActions/PauseButton'
import styled from 'styled-components'
import {RoomDetails} from 'Event/Dashboard/TeamAccessControls'

const POLL_TECH_CHECK_ATTENDEES_INTERVAL_MS = 30000

export default function TechCheckActions(props: {room: RoomDetails}) {
  const {room} = props

  const assignments = useTechCheckAssignments(room)
  const [attendees, setAttendees] = useState<Attendee[]>([])
  const [error, setError] = useState('')
  const [isProcessing, setIsProcessing] = useState<boolean>(false)
  const checkIn = useMarkTechCheckComplete()

  const [editing, setEditing] = useState<Attendee | null>(null)
  const edit = (attendee: Attendee) => () => setEditing(attendee)

  const stopEditing = () => {
    setEditing(null)
  }

  useEffect(() => {
    const attendees = assignments.map(({attendee}) => attendee)
    setAttendees(attendees)
  }, [assignments])

  const handleCheckIn = (attendee: Attendee) => () => {
    setError('')

    if (isProcessing) {
      return
    }
    setIsProcessing(true)

    checkIn(attendee)
      .then(() => {
        const updated = attendees.filter((item) => item.id !== attendee.id)
        setAttendees(updated)
      })
      .catch((e) => {
        setError(e.message)
      })
      .finally(() => {
        setIsProcessing(false)
      })
  }

  const update = (target: Attendee) => {
    setAttendees((current) =>
      current.map((c) => {
        if (c.id === target.id) {
          return target
        }
        return c
      }),
    )
  }

  return (
    <>
      <AttendeeUpdateDialog
        attendee={editing}
        onClose={stopEditing}
        update={update}
      />
      <Box mt={3}>
        <Box mt={2}>
          <TitleHolder variant="h6">Attendees</TitleHolder>
          <PauseButton room={room} />
        </Box>
        <Error>{error}</Error>
        <Table size="small" aria-label="tech check assignments table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <AttendeeList
            attendees={attendees}
            edit={edit}
            handleCheckIn={handleCheckIn}
            isProcessing={isProcessing}
          />
        </Table>
      </Box>
    </>
  )
}

export function AttendeeList(props: {
  attendees: Attendee[]
  edit: (attendee: Attendee) => () => void
  handleCheckIn: (attendee: Attendee) => () => void
  isProcessing: boolean
}) {
  const {attendees, edit, handleCheckIn, isProcessing} = props

  return (
    <TableBody>
      {attendees.map((attendee) => (
        <TableRow key={attendee.id}>
          <TableCell component="th" scope="row" aria-label="name">
            <EditButton
              variant="text"
              onClick={edit(attendee)}
              textColor={colors.primary}
              aria-label={`edit attendee ${attendee.id}`}
            >
              {`${attendee.first_name} ${attendee.last_name}`}
            </EditButton>
          </TableCell>
          <TableCell>{attendee.email}</TableCell>
          <TableCell>
            <Button
              variant="contained"
              color="primary"
              aria-label="check in button"
              onClick={handleCheckIn(attendee)}
              disabled={isProcessing}
            >
              Check-In
            </Button>
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  )
}

export function useMarkTechCheckComplete() {
  const {event, client} = useEvent()

  return (attendee: Attendee) => {
    const url = api(`/events/${event.id}/attendees/${attendee.id}/tech_check`)
    return client.patch<Attendee>(url, {})
  }
}

export function useTechCheckAssignments(room: RoomDetails) {
  const {client, event} = useEvent()

  const [assignments, setAssignments] = useState<TechCheckAssignment[]>([])
  const [shouldAutoRefresh, setShouldAutoRefresh] = useState(true)

  useEffect(() => {
    setShouldAutoRefresh(room.is_online)
  }, [room])

  const url = api(
    `/events/${event.id}/areas/${room.area.id}/room/${room.id}/tech_check_assignments`,
  )

  const fetch = useCallback(() => {
    if (!shouldAutoRefresh) {
      return
    }

    return client.get<TechCheckAssignment[]>(url).then(setAssignments)
  }, [client, shouldAutoRefresh, url])

  useEffect(() => {
    fetch()
  }, [fetch])

  useInterval(fetch, POLL_TECH_CHECK_ATTENDEES_INTERVAL_MS)

  return assignments
}

function Error(props: {children: string | null}) {
  if (!props.children) {
    return null
  }

  return <ErrorText color="error">{props.children}</ErrorText>
}

const ErrorText = styled(Typography)`
  margin-bottom: ${(props) => props.theme.spacing[3]};
`

const TitleHolder = styled(Typography)`
  display: inline;
`

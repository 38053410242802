import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import CustomButtonAlignmentEditing from './custombuttonalignmentediting';
import CustomButtonAlignmentUI from './custombuttonalignmentui';

export default class CustomButtonAlignment extends Plugin {
	static get requires() {
		return [ CustomButtonAlignmentEditing, CustomButtonAlignmentUI ];
	}

	static get pluginName() {
		return 'CustomButtonAlignment';
	}
}

import styled from 'styled-components'
import React from 'react'
import Body from 'Event/template/NiftyFifty/Dashboard/Sponsors/SponsorList/Card/Body'
import {useNiftyFiftyTemplate} from 'Event/template/NiftyFifty'
import {Draggable} from 'react-beautiful-dnd'
import Grid, {GridSize} from '@material-ui/core/Grid'
import {useEditMode} from 'Event/EditModeProvider'
import SponsorForm from 'Event/Sponsors/SponsorForm'
import {useToggleArray} from 'lib/toggle'
import Buttons from 'Event/Sponsors/Buttons'
import Logo from 'Event/Sponsors/Logo'
import {Sponsor} from 'Event/Sponsors'
import SponsorConfig, {SponsorConfigurable} from 'Event/Sponsors/SponsorConfig'

export const SPONSOR_QUESTION_ICON_PLACEHOLDER = 'http://placehold.jp/50x50.png'

/**
 * Button width is fixed for all buttons. This sets the width relative
 * to the current size of the sponsor card.
 */

type SponsorProps = {
  index: number
  id: string
  sponsor: Sponsor
  isLast: boolean
}

export default function Card(props: SponsorProps) {
  const {index, id} = props
  const isEditMode = useEditMode()

  if (!isEditMode) {
    return <Content {...props} />
  }

  return (
    <Draggable draggableId={id} index={index}>
      {(provided) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <Content {...props} />
        </div>
      )}
    </Draggable>
  )
}

function Content(props: SponsorProps) {
  const {sponsor, index} = props
  const isEditMode = useEditMode()
  const template = useNiftyFiftyTemplate()
  const imageSize = template.sponsors.imageSize
  const spacing = template.sponsors.spacing
  const [showingForm, toggleForm] = useToggleArray()
  const {rowBackgroundPrimary, rowBackgroundSecondary} = template

  return (
    <>
      <Box
        aria-label="sponsor"
        backgroundColor={
          index % 2 === 0 ? rowBackgroundPrimary : rowBackgroundSecondary
        }
        spacing={spacing}
      >
        <SponsorConfigurable>
          <SponsorConfig id={props.id} sponsor={sponsor} />
          <>
            <SponsorForm
              sponsor={sponsor}
              showing={showingForm}
              onClose={toggleForm}
            />
            <Left
              sponsor={sponsor}
              id={props.id}
              size={imageSize}
              isEditMode={isEditMode}
            />
            <StyledBody
              sponsor={sponsor}
              toggleForm={toggleForm}
              id={props.id}
            />
            <BottomButtons
              sponsor={sponsor}
              id={props.id}
              isEditMode={isEditMode}
            />
            <ClearContent />
          </>
        </SponsorConfigurable>
      </Box>
    </>
  )
}

function Left(props: {
  sponsor: Sponsor
  id: string
  size: GridSize
  isEditMode: boolean | undefined
}) {
  const logo = props.sponsor.image

  if (!logo) {
    return null
  }

  return (
    <LeftContainer item xs={props.size}>
      <StyledImage sponsor={props.sponsor} />
      <Buttons sponsorId={props.id} />
    </LeftContainer>
  )
}

function BottomButtons(props: {
  sponsor: Sponsor
  id: string
  isEditMode: boolean | undefined
}) {
  const logo = props.sponsor.image

  if (!logo) {
    return <Buttons sponsorId={props.id} />
  }

  return null
}

const Box = styled.div<{
  backgroundColor: string | null
  spacing: number
}>`
  position: relative;
  padding: ${(props) => props.theme.spacing[5]}
    ${(props) => props.theme.spacing[9]};
  background: ${(props) => props.backgroundColor};
  margin-bottom: ${(props) => props.theme.spacing[props.spacing]};
`

const StyledBody = styled(Body)`
  margin-left: ${(props) => props.theme.spacing[5]};
`

const LeftContainer = styled(Grid)`
  float: left;
  width: 100%;
  margin: ${(props) =>
    `${props.theme.spacing[5]} ${props.theme.spacing[4]} ${props.theme.spacing[4]} 0`};
`

const StyledImage = styled(Logo)`
  margin-bottom: ${(props) => props.theme.spacing[4]};
`
const ClearContent = styled.div`
  clear: both;
`

import React from 'react'
import styled from 'styled-components'
import withStyles from '@material-ui/core/styles/withStyles'
import {spacing} from 'lib/ui/theme'
import Button from '@material-ui/core/Button'
import {HeroConfig} from 'Event/template/SimpleBlog/Dashboard/Hero/HeroConfig'
import {useAttendeeVariables} from 'Event'
import {useSimpleBlogTemplate} from 'Event/template/SimpleBlog'
import {useToggle} from 'lib/toggle'
import EditModeOnly from 'Event/Dashboard/editor/views/EditModeOnly'
import {Font, useLoadFont} from 'lib/FontSelect'
import {BOLD, ITALIC, CAPS} from 'lib/ui/typography/FontStyleInput'

export default function Hero() {
  return (
    <>
      <EditModeOnly>
        <EditButton />
      </EditModeOnly>
      <WelcomeText />
      <Image />
    </>
  )
}

function EditButton() {
  const {flag: configVisible, toggle: toggleConfig} = useToggle()

  return (
    <>
      <HeroConfig showing={configVisible} onClose={toggleConfig} />
      <StyledEditButton
        onClick={toggleConfig}
        fullWidth
        size="large"
        variant="outlined"
        color="primary"
        aria-label="edit hero"
      >
        Edit Hero
      </StyledEditButton>
    </>
  )
}

function WelcomeText() {
  const template = useSimpleBlogTemplate()
  const {
    welcomeText,
    welcomeTextFont,
    welcomeTextColor,
    welcomeTextFontSize,
  } = template
  useLoadFont(welcomeTextFont)
  const v = useAttendeeVariables()

  if (!welcomeText) {
    return null
  }

  return (
    <Text
      aria-label="welcome"
      font={welcomeTextFont}
      textColor={welcomeTextColor}
      fontSize={welcomeTextFontSize}
    >
      {v(welcomeText)}
    </Text>
  )
}

function Image() {
  const template = useSimpleBlogTemplate()
  const {heroImageSize, heroImage} = template

  const size = heroImageSize

  if (!heroImage) {
    return null
  }

  return (
    <ImageBox>
      <ImageSizer size={size}>
        <StyledImg src={heroImage} />
      </ImageSizer>
    </ImageBox>
  )
}

const ImageBox = styled.div`
  padding: ${(props) => props.theme.spacing[5]} 0;
`

const ImageSizer = styled.div<{size: number}>`
  width: ${(props) => props.size}%;
  margin: 0 auto;
`
const StyledImg = styled.img`
  width: 100%;
`
const Text = styled.div<{
  font: Font | null
  textColor: string | null
  fontSize: number
}>`
  font-weight: ${(props) =>
    props.font?.styles?.includes(BOLD) ? 'bold' : 'normal'};
  font-family: ${(props) => (props.font ? props.font.family : 'inherit')};
  font-style: ${(props) =>
    props.font?.styles?.includes(ITALIC) ? 'italic' : 'normal'};
  text-transform: ${(props) =>
    props.font && !props.font.styles?.includes(CAPS) ? 'none' : 'uppercase'};
  color: ${(props) => (props.color ? props.color : 'inherit')};
  font-size: ${(props) => props.fontSize}px;
  line-height: 1;
  text-align: center;
  &:empty {
    height: ${(props) => props.theme.spacing[5]};
    width: 100%;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    line-height: 1.5;
  }
`

const StyledEditButton = withStyles({
  root: {
    marginTop: spacing[6],
  },
})(Button)

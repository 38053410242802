import {api} from 'lib/url'
import {useEvent} from 'Event/EventProvider'
import {Property} from 'organization/Event/Services/Apps/Hubspot/Config/HubspotPropertiesProvider'

export type HubspotTag = {
  property_label: string | null
  property_name: string | null
  property_type: Property['type'] | null
  property_value: string | null
}

export function useAddTag() {
  const {client, event} = useEvent()

  const url = api(`/events/${event.id}/integrations/hubspot/add_tag`)
  return (tag: HubspotTag) => {
    try {
      client.post(url, tag)
    } catch {
      // Ignore errors, failing to add a tag should
      // not break the app.
    }
  }
}

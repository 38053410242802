import React from 'react'
import {useTemplate} from 'Event/TemplateProvider'
import {components} from 'Event/template'

export type LeaderboardConfigProps = {onComplete: () => void}

export default function LeaderboardSettingsDialog(props: {
  onClose: () => void
  visible: boolean
}) {
  const {visible, onClose} = props
  const {name} = useTemplate()
  const Component = components[name].Leaderboard.LeaderboardConfig.index

  return <Component showing={visible} onClose={onClose} />
}

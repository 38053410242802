import {Label} from 'lib/ui/typography'
import React from 'react'
import {Controller, UseFormMethods} from 'react-hook-form'
import {CardsNavButtonProps} from 'Event/template/Cards/Dashboard/CardsNavButton'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import {onUnknownChangeHandler} from 'lib/dom'
import TargetInput, {
  TargetInputProps,
} from 'Event/Dashboard/components/NavButton/NavButtonConfig/TargetInput'
import ContentInput from 'Event/Dashboard/components/NavButton/NavButtonConfig/ContentInput'
import VisibilityField from 'Event/Dashboard/components/NavButton/NavButtonConfig/BaseConfig/Settings/VisibilityField'
import ActionField from 'Event/Dashboard/components/NavButton/NavButtonConfig/BaseConfig/Settings/ActionField'
import TagFields from 'Event/Dashboard/components/NavButton/NavButtonConfig/BaseConfig/Settings/TagFields'
import WebhookFields from 'Event/Dashboard/components/NavButton/NavButtonConfig/BaseConfig/Settings/WebhookFields'
import styled from 'styled-components'

export default function Settings(
  props: {
    button: CardsNavButtonProps
  } & Omit<TargetInputProps, 'button'>,
) {
  const {control, button, watch, register, setValue} = props

  return (
    <>
      <VisibilityField button={button} control={control} />
      <ContentInput
        button={button}
        control={control}
        register={register}
        watch={watch}
      />
      <ButtonRowField button={button} control={control} />
      <ActionField button={button} control={control} />
      <TargetInput
        button={button}
        control={control}
        register={register}
        watch={watch}
        setValue={setValue}
      />
      <TagFields button={button} control={control} />
      <WebhookFields
        button={button}
        control={control}
        register={register}
        watch={watch}
      />
    </>
  )
}

function ButtonRowField(props: {
  button: CardsNavButtonProps
  control: UseFormMethods['control']
}) {
  const {button, control} = props
  return (
    <Box>
      <FormControl fullWidth>
        <Label>Select Button Row</Label>
        <Controller
          name="row"
          defaultValue={button.row}
          control={control}
          render={({value, onChange}) => (
            <Select
              value={value}
              onChange={onUnknownChangeHandler(onChange)}
              label="Select Button Row"
            >
              <MenuItem value={1}>Row 1</MenuItem>
              <MenuItem value={2}>Row 2</MenuItem>
            </Select>
          )}
        />
      </FormControl>
    </Box>
  )
}

const Box = styled.div`
  margin: ${(props) => props.theme.spacing[2]} 0;
`

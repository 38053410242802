import {browserTimezone} from 'lib/date-time'
import DateProvider from 'lib/date-time/DateProvider'
import moment from 'moment'
import React, {useEffect, useState} from 'react'

type StaticDateContextProps = {
  setDate: (date: string | null) => void
  date: string | null
  setTimezone: (timezone: string | null) => void
}

const StaticDateContext = React.createContext<
  undefined | StaticDateContextProps
>(undefined)

export function StaticDateProvider(props: {children: JSX.Element}) {
  const [date, setDate] = useState<string | null>(null)
  const [timezone, setTimezone] = useState<string | null>(null)

  // Date picker uses the 'moment' instance, so we need
  // to update the default timezone there as well.
  useEffect(() => {
    const value = timezone || browserTimezone()
    moment.tz.setDefault(value)
  }, [timezone])

  return (
    <StaticDateContext.Provider value={{setDate, date, setTimezone}}>
      <DateProvider now={date} timezone={timezone}>
        {props.children}
      </DateProvider>
    </StaticDateContext.Provider>
  )
}

export function useStaticDate() {
  const context = React.useContext(StaticDateContext)
  if (context === undefined) {
    throw new Error('useSetDate must be used within a StaticDateProvider')
  }

  return context
}

import React from 'react'
import styled from 'styled-components'

export default function TabPanel(props: {
  children?: React.ReactNode
  index?: number
  value?: number
  hidden?: boolean
}) {
  const {children, value, index} = props

  if (typeof value == 'undefined' || typeof index === 'undefined') {
    throw new Error(
      `Missing required props; <TabPanel> should be a direct child of <ConfigPanel>.`,
    )
  }

  return (
    <TabPanelBox role="tabpanel" hidden={value !== index}>
      {children}
    </TabPanelBox>
  )
}

export const TabPanelBox = styled.div`
  // Need some padding to prevent slider overflow from
  // creating a scrollbar.
  padding: 1px;
`

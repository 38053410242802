import {useAuthClient} from 'auth/auth-client'
import {User} from 'auth/user'
import {Attendee} from 'Event/attendee'
import {useEvent} from 'Event/EventProvider'
import {api} from 'lib/url'
import {useCallback, useMemo} from 'react'
import {createPrivate as createEcho} from 'lib/sockets/echo'
import {getToken} from 'auth/token'

export const EVENT_TOKEN_KEY = `__obvio_event_user_token__`

export const useEventAuth = () => {
  const {event} = useEvent()
  const baseUrl = `/events/${event.id}`

  const settings = useMemo(
    () => ({
      tokenKey: EVENT_TOKEN_KEY,
      endpoints: {
        user: `${baseUrl}/user`,
        login: `${baseUrl}/login`,
        register: `/register`,
      },
    }),
    [baseUrl],
  )

  return useAuthClient(settings)
}

export function isAttendee(user: User | null): user is Attendee {
  if (!user) {
    return false
  }

  return Object.prototype.hasOwnProperty.call(user, 'waiver')
}

export function useAttendee() {
  const {user} = useEventAuth()
  if (!user) {
    throw new Error(`Missing user; was useAttendee called in a guest route?`)
  }

  if (!isAttendee(user)) {
    throw new Error(`Invalid user; missing required attendee fields.`)
  }

  return user
}

/**
 * Checks whether a user is currently authenticated as an Attendee.
 * @returns
 */
export function useIsAttendee() {
  const {user} = useEventAuth()

  return isAttendee(user)
}

/**
 * Sets a group for the currently authenticated Attendee.
 *
 * @returns
 */
export function useSetGroup() {
  const {setUser} = useEventAuth()
  const {client} = useEvent()

  return useCallback(
    (key: string, value: string) => {
      const url = api(`/groups`)

      return (
        client
          .post<Attendee>(url, {
            key,
            value,
          })
          // Update user with new group
          .then((updated) => setUser(updated))
      )
    },
    [client, setUser],
  )
}

/**
 * Get the currently authenticated attendee's auth token.
 *
 * @returns
 */
export function useAuthToken() {
  const token = getToken(EVENT_TOKEN_KEY)
  if (!token) {
    throw new Error(
      'Missing auth token; was useToken called in an unauthenticated route?',
    )
  }

  return token
}

export function useAttendeeEcho() {
  const token = useAuthToken()
  return useMemo(() => createEcho(token), [token])
}

import React from 'react'
import SponsorList from 'Event/template/Cards/Sponsors/SponsorList'
import {useAttendeeVariables} from 'Event'
import {PageTitle, PageDescription} from 'Event/template/Cards/Page'
import {useCardsTemplate} from 'Event/template/Cards'
import Content from 'lib/ui/form/TextEditor/Content'
import CardsPage from 'Event/template/Cards/Page'
import {User} from 'auth/user'
import Configurable from 'organization/Event/Configurable'
import HeaderConfig from 'Event/Sponsors/HeaderConfig'
import AddSponsorButton from 'Event/Sponsors/AddSponsorButton'

export default function Sponsors(props: {user: User}) {
  const {
    sponsors: sponsorsPageSettings,
    linkColor,
    linkUnderline,
  } = useCardsTemplate()
  const v = useAttendeeVariables()

  return (
    <CardsPage user={props.user}>
      <Configurable>
        <HeaderConfig />
        <PageTitle aria-label="sponsors title">
          {v(sponsorsPageSettings.title)}
        </PageTitle>
      </Configurable>
      <PageDescription
        aria-label="sponsors description"
        linkColor={linkColor}
        linkUnderline={linkUnderline}
      >
        <Content>{v(sponsorsPageSettings.description)}</Content>
      </PageDescription>
      <SponsorList />
      <AddSponsorButton />
    </CardsPage>
  )
}

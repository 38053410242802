import React from 'react'
import Card from 'organization/Event/AreaList/Card'
import AreasProvider, {Area, useAreas} from 'organization/Event/AreasProvider'
import {useEventRoutes} from 'organization/Event/EventRoutes'
import Page from 'organization/Event/Page'
import {
  CHECK_IN_ATTENDEES,
  EVENT_SETTINGS,
  START_AREAS,
  START_ROOMS,
  usePermissions,
} from 'organization/PermissionsProvider'
import PageHeader from 'lib/ui/PageHeader'
import Title from 'lib/ui/PageHeader/Title'
import HasPermission from 'organization/HasPermission'
import {RelativeLink} from 'lib/ui/link/RelativeLink'
import NewItemButton from 'lib/ui/PageHeader/NewItemButton'
import {StaticAreaProvider} from 'organization/Event/Area/AreaProvider'
import PageBreadcrumbs from 'organization/Event/Page/PageBreadcrumbs'
import FullPageLoader from 'lib/ui/layout/FullPageLoader'

export default function AreaList() {
  return (
    <PageBreadcrumbs page="Zoom Areas">
      <Page>
        <AreasProvider>
          <Content />
        </AreasProvider>
      </Page>
    </PageBreadcrumbs>
  )
}

function Content() {
  const routes = useEventRoutes()
  return (
    <>
      <PageHeader>
        <Title text="Zoom Areas" />
        <HasPermission required={EVENT_SETTINGS}>
          <RelativeLink disableStyles to={routes.areas.create}>
            <NewItemButton text="New Area" aria-label="create area" />
          </RelativeLink>
        </HasPermission>
      </PageHeader>
      <Areas />
    </>
  )
}

function Areas() {
  const {areas, loading} = useAreas()
  const {can} = usePermissions()

  if (loading || !areas) {
    return <FullPageLoader />
  }

  const visibleAreas = areas.filter((a) => {
    if (can(START_ROOMS) || can(EVENT_SETTINGS) || can(START_AREAS)) {
      return true
    }

    /**
     * If a user only has permission to check in attendees (tech check agent), then
     * we'll only show the tech check area.
     */
    if (can(CHECK_IN_ATTENDEES)) {
      return a.is_tech_check
    }

    return false
  })

  return (
    <div>
      <List areas={visibleAreas} />
    </div>
  )
}

function List(props: {areas: Area[]}) {
  const {areas} = props

  const isEmpty = areas.length === 0
  if (isEmpty) {
    return <div>No areas have been created</div>
  }

  return (
    <>
      {areas.map((a) => (
        <StaticAreaProvider area={a} key={a.id}>
          <Card />
        </StaticAreaProvider>
      ))}
    </>
  )
}

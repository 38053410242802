import {
  Townhall,
  useTownhallTemplate,
  useTownhallUpdate,
} from 'Event/template/Townhall'
import IconSelect from 'lib/fontawesome/IconSelect'
import ColorPicker from 'lib/ui/ColorPicker'
import {useConfig} from 'organization/Event/Configurable'
import Config from 'organization/Event/Configurable/Config'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import StylingPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/StylingPanel'
import React from 'react'
import {Controller} from 'react-hook-form'

export default function StepConfig(props: {step: 1 | 2 | 3}) {
  const {step} = props
  const save = useTownhallUpdate()
  const title = `Step ${step}`
  const template = useTownhallTemplate()
  const {
    form: {control},
  } = useConfig()

  const iconKey: keyof Townhall['checkIn'] = `step${step}Icon`

  return (
    <Config title={title} onSave={save}>
      <SettingsPanel>
        <Controller
          name={`checkIn.${iconKey}`}
          defaultValue={template.checkIn[iconKey]}
          control={control}
          render={({value, onChange}) => (
            <IconSelect value={value} onChange={onChange} />
          )}
        />
      </SettingsPanel>
      <StylingPanel>
        <Controller
          name="checkIn.stepIconColor"
          defaultValue={template.checkIn.stepIconColor}
          control={control}
          render={({value, onChange}) => (
            <ColorPicker
              label="Step Icon Color"
              color={value}
              onPick={onChange}
              aria-label="step icon color"
            />
          )}
        />
      </StylingPanel>
    </Config>
  )
}

import React from 'react'
import {useAttendee} from 'Event/auth'
import {HasRules} from 'Event/attendee-rules'
import {useTrackEventPage} from 'analytics'
import {useTemplate} from 'Event/TemplateProvider'
import {Ordered} from 'lib/list'
import {components} from 'Event/template'
import {User} from 'auth/user'
import Scripts, {FAQS as SCRIPTS_FAQS} from 'Event/Scripts'

export interface FAQ extends Ordered, HasRules {
  question: string
  answer: string
  showAnswerOnLoad?: boolean
  enabled?: boolean
}

export default function FAQs() {
  const user = useAttendee()

  useTrackEventPage({
    page: 'Visited FAQ',
  })

  return (
    <>
      <Scripts page={SCRIPTS_FAQS} />
      <TemplateFAQs user={user} />
    </>
  )
}

export function TemplateFAQs(props: {user: User}) {
  const template = useTemplate()
  const Component = components[template.name].FAQs.index

  return <Component user={props.user} />
}

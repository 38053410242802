import React from 'react'
import {Controller, UseFormMethods} from 'react-hook-form'
import {NavButtonWithSize} from 'Event/Dashboard/components/NavButton'
import Slider from 'lib/ui/Slider'
import CollapsibleSection from 'lib/ui/ConfigPanel/CollapsibleSection'

const DEFAULT_BUTTON_HEIGHT = 34

export default function Size(props: {
  control: UseFormMethods['control']
  button: NavButtonWithSize
}) {
  const {control, button} = props

  return (
    <CollapsibleSection
      label="Size"
      storageKey="blogPostButton.config.styling.typography"
    >
      <Controller
        name="height"
        defaultValue={button.height || DEFAULT_BUTTON_HEIGHT}
        control={control}
        render={({value, onChange}) => (
          <Slider
            min={1}
            max={400}
            onChange={onChange}
            valueLabelDisplay="off"
            value={value}
            label="Height"
          />
        )}
      />
      <Controller
        name="size"
        defaultValue={button.size || 0}
        control={control}
        render={({value, onChange}) => (
          <Slider
            min={1}
            max={12}
            onChange={onChange}
            valueLabelDisplay="off"
            value={value}
            hideUnits
            label="Width"
          />
        )}
      />
    </CollapsibleSection>
  )
}

import styled, {useTheme} from 'styled-components'
import React from 'react'
import PageHeader from 'lib/ui/PageHeader'
import {RelativeLink} from 'lib/ui/link/RelativeLink'
import {GridIcon, ListIcon} from 'lib/ui/Icon'

export function ToggleViewIcon(props: {isListView: boolean}) {
  const theme = useTheme()
  const Icon = props.isListView ? GridIcon : ListIcon
  return <Icon color={theme.colors.gray200} iconSize={20} />
}

export const DesktopPageHeader = styled(PageHeader)`
  display: none;

  @media all and (min-width: ${(props) => props.theme.breakpoints.sm}) {
    display: flex;
  }
`

export const MobilePageHeader = styled(PageHeader)`
  display: inherit;
  text-align: center;
  margin-bottom: ${(props) => props.theme.spacing[8]} !important;

  a {
    margin-left: 0;
  }

  a,
  a button {
    width: 100%;
    padding: ${(props) => props.theme.spacing[4]};
  }

  h1 {
    margin: ${(props) => props.theme.spacing[8]};
  }

  @media all and (min-width: ${(props) => props.theme.breakpoints.sm}) {
    display: none;
  }
`

export const HeaderLink = styled(RelativeLink)`
  display: inline-block;
  margin-left: ${(props) => props.theme.spacing[2]};
`

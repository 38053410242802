import React from 'react'
import {Controller, UseFormMethods} from 'react-hook-form'
import {EnabledSwitch} from 'lib/ui/form/Switch'
import TextField from 'lib/ui/TextField'
import {Label} from 'lib/ui/typography'
import TextEditor, {TextEditorContainer} from 'lib/ui/form/TextEditor'
import ActionSelect from 'Event/ActionsProvider/ActionSelect'
import {useLeftyTemplate} from 'Event/template/Lefty'
import {onChangeCheckedHandler} from 'lib/dom'
import InputLabel from 'lib/ui/InputLabel'

export default function Settings(props: {
  control: UseFormMethods['control']
  register: UseFormMethods['register']
}) {
  const {control, register} = props
  const template = useLeftyTemplate()
  const {imageWaterfall: current} = template

  return (
    <>
      <Controller
        name="isVisible"
        control={control}
        defaultValue={current.isVisible}
        render={({value, onChange}) => (
          <EnabledSwitch
            checked={value}
            onChange={onChangeCheckedHandler(onChange)}
            arial-label="toggle image waterfall page"
          />
        )}
      />
      <TextField
        name="title"
        defaultValue={current.title}
        label="Title"
        fullWidth
        inputProps={{
          'aria-label': 'imageWaterfall title',
          ref: register,
        }}
      />
      <TextField
        name="secondaryTitle"
        defaultValue={current.secondaryTitle}
        label="Secondary Title"
        fullWidth
        inputProps={{
          'aria-label': 'imageWaterfall secondary title',
          ref: register,
        }}
      />
      <InputLabel>Description</InputLabel>
      <TextEditorContainer>
        <Controller
          name="description"
          defaultValue={current.description}
          control={control}
          render={({value, onChange}) => (
            <TextEditor data={value} onChange={onChange} />
          )}
        />
      </TextEditorContainer>
      <Label>Upload Form Title</Label>
      <TextField
        name="uploadFormTitle"
        aria-label="uploadFormTitle"
        fullWidth
        defaultValue={current.uploadFormTitle}
        inputProps={{
          ref: register,
        }}
      />
      <Controller
        name="uploadFormDescription"
        control={control}
        defaultValue={current.uploadFormDescription}
        render={({onChange, value}) => (
          <TextEditorContainer>
            <TextEditor data={value} onChange={onChange} />
          </TextEditorContainer>
        )}
      />
      <Label>Upload Button Text</Label>
      <TextField
        name="uploadButtonText"
        aria-label="uploadButtonText"
        fullWidth
        defaultValue={current.uploadButtonText}
        inputProps={{
          ref: register,
        }}
      />
      <Controller
        name="actionId"
        defaultValue={current.actionId}
        control={control}
        render={({value, onChange}) => (
          <ActionSelect
            value={value}
            onChange={onChange}
            label="Approved Action"
          />
        )}
      />
    </>
  )
}

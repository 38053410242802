import React from 'react'
import {useForm} from 'react-hook-form'
import {uuid} from 'lib/uuid'
import {useTownhallUpdate} from 'Event/template/Townhall'
import {DashboardSectionProps} from 'Event/template/Townhall/Dashboard/Main/DashboardSection'
import {createAgendaSection} from 'Event/template/Townhall/Dashboard/Main/AgendaSection'
import {createBlogSection} from 'Event/template/Townhall/Dashboard/Main/BlogSection'
import ComponentConfig, {
  SaveButton,
  Footer,
} from 'organization/Event/DashboardConfig/ComponentConfigPanel'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import Settings from 'Event/template/Townhall/Dashboard/Main/AddSectionButton/AddSectionConfig/Settings'
import {createResourceSection} from 'Event/template/Townhall/Dashboard/Main/ResourceSection'
import {createCountdownTimerSection} from 'Event/template/Townhall/Dashboard/Main/CountdownTimerSection'

interface AddSectionConfigProps {
  showing: boolean
  onClose: () => void
}

const createHandlers: Record<
  DashboardSectionProps['name'],
  () => DashboardSectionProps
> = {
  agenda: createAgendaSection,
  blog: createBlogSection,
  resource: createResourceSection,
  countdown_timer: createCountdownTimerSection,
}

export default function AddSectionConfig(props: AddSectionConfigProps) {
  const {showing, onClose} = props
  const {control, handleSubmit, formState} = useForm()
  const update = useTownhallUpdate()

  const add = (data: {name: DashboardSectionProps['name']}) => {
    if (!data.name) {
      return
    }

    const id = uuid()

    const createSection = createHandlers[data.name]
    const section = createSection()

    update({
      dashboardSections: {
        [id]: section,
      },
      navBar: {
        menuItems: {
          [id]: {
            title: section.title,
            isEnabled: true,
          },
        },
      },
    })

    onClose()
  }

  return (
    <ComponentConfig
      showing={showing}
      onClose={onClose}
      title="Add Section"
      onSubmit={handleSubmit(add)}
      hasChanges={formState.isDirty}
    >
      <SettingsPanel>
        <Settings control={control} />
      </SettingsPanel>
      <Footer>
        <SaveButton type="submit" />
      </Footer>
    </ComponentConfig>
  )
}

import React, {useState, useEffect, useCallback, useLayoutEffect} from 'react'
import styled from 'styled-components'
import {ImageEntry} from 'organization/Event/ImageEntriesProvider'
import {useInterval} from 'lib/interval'
import {uuid} from 'lib/uuid'
import {useTemplate} from 'Event/TemplateProvider'

const MAX_NUM_IMAGES_PER_COLUMN = 20

export default function AnimationWall(props: {entries: ImageEntry[]}) {
  const {entries} = props
  const template = useTemplate()
  const {
    imageWaterfall: {
      page: {addImageIntervalSecs, numColumns},
    },
  } = template

  const [activeColumnIndex, setActiveColumnIndex] = useState(0)

  // Only want to add an image for 1 column at a time, so we'll mark the
  // target column as 'active'.
  const switchColumn = useCallback(() => {
    const nextIndex =
      activeColumnIndex === numColumns - 1 ? 0 : activeColumnIndex + 1

    // If we're only showing 1 column, we'll need to fake toggling it 'off' by
    // setting an invalid index, -1.
    if (activeColumnIndex === nextIndex && numColumns === 1) {
      setActiveColumnIndex(-1)
      return
    }

    // If we're already active, we need to avoid setting the index again, or we'll
    // run into an inf-loop.
    if (activeColumnIndex === nextIndex) {
      return
    }

    setActiveColumnIndex(nextIndex)
  }, [activeColumnIndex, setActiveColumnIndex, numColumns])

  // If we only have 1 column, there won't be any switching so we need to
  // divide the interval by 2.
  const defaultSwitchInterval = addImageIntervalSecs * 1000
  const switchInterval =
    numColumns === 1 ? defaultSwitchInterval / 2 : defaultSwitchInterval

  useInterval(switchColumn, switchInterval)

  return (
    <Container>
      {Array.from({length: numColumns}).map((_, index) => (
        <Column
          key={index}
          entries={entries}
          index={index}
          numColumns={numColumns}
          isActive={activeColumnIndex === index}
          addImageIntervalSecs={addImageIntervalSecs}
        />
      ))}
    </Container>
  )
}

/**
 * ImageEntry that we'll render as an item. We need to add an extra uid here
 * as the same ImageEntry could be rendered multiple times, and we're also
 * shifting indices, so we can't use the index.
 */
type ImageEntryItem = ImageEntry & {
  uid: string
}

function Column(props: {
  entries: ImageEntry[]
  index: number
  numColumns: number
  isActive: boolean
  addImageIntervalSecs: number
}) {
  const [items, setItems] = useState<ImageEntryItem[]>([])
  const [nextItemIndex, setNextItemIndex] = useState(0)
  const [heights, setHeights] = useState<number[]>([])
  const [recorded, setRecorded] = useState<Record<string, boolean>>({})
  const [added, setAdded] = useState(false)
  const {index, entries, numColumns, isActive, addImageIntervalSecs} = props

  /**
   * Add a new entry image.
   */
  const addImage = useCallback(() => {
    // Only get the entries for THIS column...
    const columnEntries = entries.filter((_e, i) => {
      const columnNumber = i % numColumns
      return index === columnNumber
    })
    const newEntry = columnEntries[nextItemIndex]

    const item: ImageEntryItem = {
      uid: uuid(),
      ...newEntry,
    }
    setItems((items) => [item, ...items])

    // Shift the next index, so that on the next loop we'll grab the next entry...
    const lastIndex = columnEntries.length - 1
    setNextItemIndex((current) => (current === lastIndex ? 0 : current + 1))
  }, [nextItemIndex, entries, index, numColumns])

  // Handle adding an image. We only want to add an image when THIS column is active. And we'll
  // also only want to add one image each time.
  useEffect(() => {
    if (!isActive) {
      setAdded(false)
      return
    }

    if (added) {
      return
    }
    setAdded(true)
    addImage()
  }, [isActive, addImage, setAdded, added, numColumns, addImageIntervalSecs])

  const widthPercent = 100 / numColumns

  /**
   * Record the entry's height. We need to record every height so we know
   * how much to translate the column each time for a slide effect.
   * @param id
   */
  const recordEntryHeight = (id: string) => (height: number) => {
    // Need to note which elements we've already recorded heights to avoid
    // recording the same element multiple times.
    const alreadyRecorded = recorded[id]
    if (alreadyRecorded) {
      return
    }
    setRecorded((recorded) => ({...recorded, [id]: true}))
    setHeights((heights) => [height, ...heights])
  }

  useEffect(() => {
    if (items.length !== heights.length) {
      return
    }

    if (items.length <= MAX_NUM_IMAGES_PER_COLUMN) {
      return
    }

    setItems(items.filter((_item, i) => i < MAX_NUM_IMAGES_PER_COLUMN))
    setHeights(heights.filter((_height, i) => i < MAX_NUM_IMAGES_PER_COLUMN))
  }, [items, heights, numColumns])

  return (
    <ColumnBox
      width={widthPercent}
      numColumns={numColumns}
      aria-label="waterfall entry"
    >
      {items.map((e, index) => {
        const position = heights
          .filter((_h, i) => {
            // Due to the render-cycle nature of the component, the <Image/> is actually rendered
            // BEFORE the height is appended.
            const hasHeight = heights.length === items.length
            if (hasHeight) {
              // The first element's height has been added, so we can include it here...
              return i <= index
            }

            // The first element's height has NOT been added, so we don't want to include it, i.e, the
            // first element will have 0 position, and still be hidden until its height has been
            // added.
            return i < index
          })
          .reduce((acc, i) => {
            return acc + i
          }, 0)

        return (
          <Image
            key={e.uid}
            entry={e}
            onRender={recordEntryHeight(e.uid)}
            position={position}
            numColumns={numColumns}
          />
        )
      })}
    </ColumnBox>
  )
}

function Image(props: {
  entry: ImageEntryItem
  onRender: (height: number) => void
  position: number
  numColumns: number
}) {
  const {entry, onRender, position, numColumns} = props
  const [el, setEl] = useState<HTMLImageElement | null>(null)
  const [height, setHeight] = useState(0)

  // Calculate element height
  useLayoutEffect(() => {
    if (height) {
      return
    }

    if (!el) {
      return
    }

    const timer = setTimeout(() => {
      const height = el?.getBoundingClientRect().height
      setHeight(height)
      onRender(height)
    }, 250) // Not pretty, but we need an arbitrary timeout here to let the DOM render.

    return () => {
      clearTimeout(timer)
    }
  }, [el, setHeight, onRender, height])

  // Before the height is calculated, we'll place the entry somewhere way
  // far off so it doesn't show. Once we know the height we'll put it
  // just above the screen, then animate it in using the position.
  const top = height ? -height : -5000

  return (
    <ImageEl
      src={entry.file?.url}
      alt="entry"
      top={top}
      translateY={position}
      ref={setEl}
      numColumns={numColumns}
    />
  )
}

const Container = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
`

const ColumnBox = styled.div<{width: number; numColumns: number}>`
  width: ${(props) => props.width}%;
  position: relative;
  display: ${(props) => (props.numColumns === 1 ? 'flex' : 'initial')};
  justify-content: ${(props) =>
    props.numColumns === 1 ? 'center' : 'inherit'};
`

const ImageEl = styled.img<{
  top: number
  translateY: number
  numColumns: number
}>`
  max-width: 100%;
  position: absolute;
  left: ${(props) => (props.numColumns === 1 ? 'auto' : '0')};
  top: ${(props) => props.top}px;
  transform: translateY(${(props) => props.translateY}px);
  transition: transform 0.25s ease-out;
`

import Config from 'organization/Event/Services/Apps/ActiveCampaign/Config'
import LinkPage from 'organization/Event/Services/Apps/ActiveCampaign/LinkPage'
import {
  ACTIVE_CAMPAIGN,
  BaseIntegration,
  useServices,
} from 'organization/Event/Services/ServicesProvider'
import React from 'react'

export type ActiveCampaignIntegration = BaseIntegration & {
  service: typeof ACTIVE_CAMPAIGN
  api_url: string
  api_key: string
  login_field_id: string | null
  login_field_title: string | null
  login_field_type: string | null
  tags: Tag[]
  groups: Group[]
  imported_at: string | null
  has_set_import_tag: boolean
}

export interface Tag {
  id: number
  active_campaign_id: number | null
  name: string | null
  type: TagType
}

export interface Group {
  field_id: string | null
  field_title: string | null
  field_type: string | null
  key: string | null
}

export interface LoginField {
  login_field_id: string | null
  login_field_title: string | null
  login_field_type: string | null
}

export const ATTENDEE_CREATED = 'attendee_created'
export const ATTENDEE_SIGNED_WAIVER = 'attendee_signed_waiver'
export const ATTENDEE_CHECKED_IN = 'attendee_checked_in'
export const IMPORT_TAG = 'import_tag'
export const FORM_SUBMITTED = 'form_submitted'

export type TagType =
  | typeof ATTENDEE_CREATED
  | typeof ATTENDEE_CHECKED_IN
  | typeof ATTENDEE_SIGNED_WAIVER
  | typeof IMPORT_TAG
  | typeof FORM_SUBMITTED

export default function ActiveCampaign() {
  const {isLinked} = useServices()
  const showConfig = isLinked(ACTIVE_CAMPAIGN)

  if (showConfig) {
    return <Config />
  }

  return <LinkPage />
}

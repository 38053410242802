import styled from 'styled-components'
import React, {useState} from 'react'
import {Controller, useForm} from 'react-hook-form'
import {ValidationError} from 'lib/ui/api-client'
import {useOrganization} from 'organization/OrganizationProvider'
import {api} from 'lib/url'
import {TeamMember} from 'auth/user'
import {
  TeamInvitation,
  useTeamInvitations,
} from 'organization/Team/TeamInvitationsProvider'
import {useTeam} from 'organization/Team/TeamProvider'
import ErrorAlert from 'lib/ui/alerts/ErrorAlert'
import {SubHead} from 'lib/ui/typography'
import Box from '@material-ui/core/Box'
import Button from 'lib/ui/Button'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import {useCanAssign, useRoles} from 'organization/Team/Roles/RolesProvider'
import {onUnknownChangeHandler} from 'lib/dom'
import DescribedTextField from 'lib/ui/TextField/DescribedTextField'
import Select from 'lib/ui/Select'

interface InviteData {
  email: string
  role_id: number
}

export default function AddTeamMemberForm() {
  const {control, handleSubmit, reset: resetForm} = useForm()
  const {add: addInvite} = useTeamInvitations()
  const {add: addMember} = useTeam()
  const [submitting, setSubmitting] = useState(false)
  const [responseError, setResponseError] = useState<
    ValidationError<InviteData>
  >(null)
  const inviteTeamMember = useInviteTeamMember()
  const {roles} = useRoles()
  const canAssignRole = useCanAssign()

  const submit = (data: InviteData) => {
    if (submitting) {
      return
    }
    setSubmitting(true)
    setResponseError(null)

    inviteTeamMember(data)
      .then((added) => {
        if (isInvitation(added)) {
          addInvite(added)
          return
        }

        addMember(added)
        resetForm()
      })
      .catch((e) => {
        setResponseError(e)
      })
      .finally(() => {
        setSubmitting(false)
      })
  }

  return (
    <SyledForm onSubmit={handleSubmit(submit)}>
      <ErrorAlert>{responseError?.message}</ErrorAlert>
      <HeaderBox>
        <StyledHeader>Add Team Member</StyledHeader>
      </HeaderBox>
      <Controller
        control={control}
        name="role_id"
        label="Role"
        defaultValue={''}
        render={({value, onChange}) => (
          <FormControl>
            <InputLabel variant="outlined" shrink>
              Role
            </InputLabel>
            <Select
              required
              variant="outlined"
              disabled={submitting}
              /**
               * Mui Select does NOT recognize NULL values, so we'll
               * have to use 0 as a workaround.
               */
              value={value || 0}
              displayEmpty={true}
              onChange={onUnknownChangeHandler((val) =>
                /**
                 * Handle our workaround, and set as NULL for the
                 * request to be correct.
                 */
                onChange(val || null),
              )}
              aria-label="team member role"
            >
              <MenuItem value={0}>None</MenuItem>
              {roles.map((role) => (
                <MenuItem
                  value={role.id}
                  aria-label={`pick ${role.name}`}
                  key={role.id}
                  disabled={!canAssignRole(role)}
                >
                  {role.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      />

      <Controller
        name="email"
        control={control}
        defaultValue={''}
        rules={{
          required: 'Email is required',
        }}
        render={({onChange, value}) => (
          <DescribedTextField
            variant="outlined"
            fullWidth
            aria-label="team member email"
            description="User email address"
            placeholder="Enter Email"
            value={value}
            onChange={onChange}
            disabled={submitting}
            endAdornment={
              <StyledButton
                color="primary"
                variant="contained"
                type="submit"
                disabled={submitting}
                aria-label="add team member"
              >
                Invite
              </StyledButton>
            }
          />
        )}
      />
    </SyledForm>
  )
}

function useInviteTeamMember() {
  const {organization, client} = useOrganization()
  const url = api(`/organizations/${organization.id}/team_members`)

  return (data: InviteData) =>
    client.post<TeamMember | TeamInvitation>(url, {
      email: data.email,
      role_id: data.role_id,
    })
}

/**
 * Helper to check whether the returned data from an invite was a
 * Team Member (existing), or an invitation (account not found).
 *
 * @param data
 * @returns
 */
function isInvitation(
  data: TeamMember | TeamInvitation,
): data is TeamInvitation {
  /**
   * An invitation does NOT have name info
   */
  return !Object.prototype.hasOwnProperty.call(data, 'first_name')
}

const SyledForm = styled.form`
  margin-bottom: ${(props) => props.theme.spacing[10]};
  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    margin-bottom: ${(props) => props.theme.spacing[3]};
  }
`

const StyledHeader = styled(SubHead)`
  flex: 1;
  margin-right: ${(props) => props.theme.spacing[4]};
`

const StyledButton = styled(Button)`
  padding: ${(props) => `${props.theme.spacing[2]} ${props.theme.spacing[5]}`};
  margin-left: ${(props) => `-${props.theme.spacing[1]}`};
`

const HeaderBox = styled(Box)`
  display: flex;
  align-items: center;
  margin-bottom: ${(props) => props.theme.spacing[5]};
`

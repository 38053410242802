import React from 'react'
import {useEvent} from 'Event/EventProvider'
import {eventRoutes} from 'Event/Routes'
import {useForgotPassword} from 'auth/password'
import {api} from 'lib/url'
import {useTemplate} from 'Event/TemplateProvider'
import {components} from 'Event/template'
import Scripts, {FORGOT_PASSWORD} from 'Event/Scripts'

export default function ForgotPassword() {
  const {name} = useTemplate()

  const {url: eventUrl, event} = useEvent()

  const forgotPassword = useForgotPassword({
    url: api(`/events/${event.id}/forgot_password`),
    resetFormUrl: `${eventUrl}${eventRoutes.resetPassword}`,
  })

  const Component = components[name].ForgotPassword.index

  return (
    <>
      <Scripts page={FORGOT_PASSWORD} />
      <Component {...forgotPassword} />
    </>
  )
}

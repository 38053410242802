import React from 'react'
import styled from 'styled-components'
import Button from '@material-ui/core/Button'
import {HeroConfig} from 'Event/template/Cards/Dashboard/Hero/HeroConfig'
import {useAttendeeVariables} from 'Event'
import {useCardsTemplate} from 'Event/template/Cards'
import {useToggle} from 'lib/toggle'
import EditModeOnly from 'Event/Dashboard/editor/views/EditModeOnly'
import {useEditMode} from 'Event/EditModeProvider'
import TextContent from 'lib/ui/form/TextEditor/Content'

export default function Hero() {
  const template = useCardsTemplate()
  const isEdit = useEditMode()

  if (!isEdit && !template.header.isEnabled) {
    return null
  }

  return (
    <>
      <EditModeOnly>
        <EditButton />
      </EditModeOnly>
      <Content />
    </>
  )
}

function EditButton() {
  const {flag: configVisible, toggle: toggleConfig} = useToggle()

  return (
    <>
      <HeroConfig showing={configVisible} onClose={toggleConfig} />
      <StyledEditButton
        onClick={toggleConfig}
        fullWidth
        size="large"
        variant="outlined"
        color="primary"
        aria-label="edit hero"
      >
        Edit Hero
      </StyledEditButton>
    </>
  )
}

function HeroText() {
  const {
    hero: {text: heroText, fontSize: heroFontSize, textColor: heroTextColor},
    textColor,
  } = useCardsTemplate()

  const v = useAttendeeVariables()

  if (!heroText) {
    return null
  }

  return (
    <StyledContent
      aria-label="hero text"
      textColor={textColor}
      color={heroTextColor}
      fontSize={heroFontSize}
    >
      {v(heroText)}
    </StyledContent>
  )
}

function Image() {
  const {
    hero: {image: heroImage, imageSize: heroImageSize},
  } = useCardsTemplate()

  const size = heroImageSize

  if (!heroImage) {
    return null
  }

  return (
    <ImageBox>
      <ImageSizer size={size}>
        <StyledImg src={heroImage} />
      </ImageSizer>
    </ImageBox>
  )
}

function Content() {
  const {
    hero: {text: heroText, image: heroImage},
  } = useCardsTemplate()

  if (!heroText && !heroImage) {
    return null
  }

  return (
    <StyledContentContainer>
      <Image />
      <HeroText />
    </StyledContentContainer>
  )
}

const ImageBox = styled.div`
  padding: ${(props) => props.theme.spacing[5]} 0;
`

const ImageSizer = styled.div<{size: number}>`
  width: ${(props) => props.size}%;
  margin: 0 auto;
`
const StyledImg = styled.img`
  width: 100%;
`

const StyledContent = styled(TextContent)<{
  fontSize: number
  textColor: string
  color: string | null
}>`
  font-size: ${(props) => props.fontSize}px;
  color: ${(props) => (props.color ? props.color : props.textColor)};
`

const StyledContentContainer = styled.div`
  width: 100%;
  margin-bottom: 20px;
`

const StyledEditButton = styled(Button)`
  margin-bottom: 20px;
`

/**
 * Required - explicitly import pusher BEFORE echo, otherwise
 * we run into Pusher is not defined error as Laravel
 * expects it to be a globally defined variable.
 */
import Echo from 'laravel-echo'
import {useCallback, useEffect, useState} from 'react'
import PrivateChannel from 'pusher-js/types/src/core/channels/private_channel'
import {Channel} from 'laravel-echo/dist/channel'
import {ConnectionManager} from 'pusher-js'

export function usePrivateChannel(params: {
  echo: Echo
  name: string | null
  onConnect?: () => void
  onDisconnect?: () => void
}) {
  const [socketId, setSocketId] = useState<string | null>(null)
  const [channel, setChannel] = useState<Channel | null>(null)
  const disconnect = useCallback(() => {
    setSocketId(null)
  }, [])

  const {echo, name, onConnect, onDisconnect} = params

  const handleConnected = useCallback(
    (connection: ConnectionManager) => {
      setSocketId(connection.socket_id)
      onConnect && onConnect()
    },
    [onConnect, setSocketId],
  )

  useEffect(() => {
    if (!name) {
      return
    }

    const privateChannel = echo.private(name).error((error: {type: string}) => {
      /**
       * Handle authentication failure which would mean we would never receive auth updates
       */
      if (error.type === 'AuthError') {
        // disconnect()
      }
    })

    /**
     * Bind socket connected/disconnected events
     */

    const connection = ((privateChannel as unknown) as PrivateChannel).pusher
      .connection

    // If the socket is already connected, we'll set it immediately,
    // otherwise we'll wait for the `connected` event to fire.
    if (connection.socket_id) {
      handleConnected(connection)
    } else {
      connection.bind('connected', () => {
        handleConnected(connection)
      })
    }

    connection.bind('disconnected', disconnect)

    setChannel(privateChannel)

    return () => {
      if (!name) {
        return
      }

      echo.leave(name)
      onDisconnect && onDisconnect()
    }
  }, [echo, disconnect, name, onDisconnect, handleConnected])

  const connected = Boolean(socketId) && Boolean(channel)
  return {socketId, channel, connected}
}

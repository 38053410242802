import React from 'react'
import {AgendaSectionProps} from 'Event/template/Townhall/Dashboard/Main/AgendaSection'
import Settings from 'Event/template/Townhall/Dashboard/Main/AgendaSection/AgendaSectionConfig/Settings'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import RulesPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/RulesPanel'
import ScheduleInput from 'organization/Event/DashboardConfig/ComponentConfigPanel/ScheduleInput'
import Config from 'organization/Event/Configurable/Config'
import {REMOVE, useSaveTemplate} from 'Event/TemplateUpdateProvider'
import {useConfig} from 'organization/Event/Configurable'
import StylingPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/StylingPanel'
import Styling from './Styling'

export function AgendaSectionConfig(props: {
  agendaSection: AgendaSectionProps
  sectionId: string
}) {
  const {agendaSection, sectionId} = props

  const save = useSaveTemplate()

  const {
    form: {setValue, control},
  } = useConfig()

  const remove = () =>
    save({
      dashboardSections: {
        [sectionId]: REMOVE,
      },
      navBar: {
        menuItems: {
          [sectionId]: REMOVE,
        },
      },
    })

  return (
    <Config title="Agendas" onSave={save} onRemove={remove}>
      <SettingsPanel>
        <Settings agendaSection={agendaSection} sectionId={sectionId} />
      </SettingsPanel>
      <StylingPanel>
        <Styling agendaSection={agendaSection} sectionId={sectionId} />
      </StylingPanel>
      <RulesPanel
        rules={agendaSection.rules}
        control={control}
        name={`dashboardSections.${sectionId}.rules`}
      >
        <ScheduleInput
          setValue={setValue}
          control={control}
          values={agendaSection}
          fromName={`dashboardSections.${sectionId}.showingFrom`}
          untilName={`dashboardSections.${sectionId}.showingUntil`}
        />
      </RulesPanel>
    </Config>
  )
}

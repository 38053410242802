import React from 'react'
import CardsPage from 'Event/template/Cards/Page'
import FaqList from 'Event/template/Cards/FAQs/FaqList'
import {User} from 'auth/user'
import {PageTitle, PageDescription} from 'Event/template/Cards/Page'
import {useAttendeeVariables} from 'Event'
import {useCardsTemplate} from 'Event/template/Cards'
import Content from 'lib/ui/form/TextEditor/Content'
import Configurable from 'organization/Event/Configurable'
import AddFaqButton from 'Event/FAQs/AddFaqButton'
import TitleConfig from 'Event/FAQs/TitleConfig'
import DescriptionConfig from 'Event/FAQs/DescriptionConfig'

export default function Faqs(props: {user: User}) {
  const template = useCardsTemplate()
  const {faq: pageSettings, linkColor, linkUnderline} = template
  const v = useAttendeeVariables()

  return (
    <CardsPage user={props.user}>
      <AddFaqButton />
      <Configurable>
        <TitleConfig />
        <PageTitle aria-label="faqs title">{v(pageSettings?.title)}</PageTitle>
      </Configurable>
      <Configurable>
        <DescriptionConfig />
        <PageDescription linkColor={linkColor} linkUnderline={linkUnderline}>
          <Content aria-label="description">
            {v(pageSettings?.description)}
          </Content>
        </PageDescription>
      </Configurable>
      <FaqList />
    </CardsPage>
  )
}

import {NiftyFifty, useNiftyFiftyTemplate} from 'Event/template/NiftyFifty'
import React from 'react'
import BaseProgressBar from 'lib/ui/ProgressBar'
import Configurable from 'organization/Event/Configurable'
import ProgressBarConfig from 'Event/template/NiftyFifty/check-in/ProgressBar/ProgressBarConfig'

export type ProgressBarProps = {
  textKey: keyof NiftyFifty['progressBar'] &
    ('step1Text' | 'step2Text' | 'step3Text')
  percentKey: keyof NiftyFifty['progressBar'] &
    ('step1Percent' | 'step2Percent' | 'step3Percent')
}

export default function ProgressBar(props: ProgressBarProps) {
  const {progressBar} = useNiftyFiftyTemplate()
  const {textKey, percentKey} = props

  return (
    <Configurable>
      <ProgressBarConfig {...props} />
      <BaseProgressBar
        showing={progressBar.showing}
        text={progressBar[textKey]}
        value={progressBar[percentKey]}
        barColor={progressBar.barColor}
        backgroundColor={progressBar.backgroundColor}
        textColor={progressBar.textColor}
        borderRadius={progressBar.borderRadius}
        thickness={progressBar.thickness}
      />
    </Configurable>
  )
}

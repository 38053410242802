import React from 'react'
import styled from 'styled-components'
import {Draggable} from 'react-beautiful-dnd'
import {useLeftyTemplate} from 'Event/template/Lefty'
import {useEditMode} from 'Event/EditModeProvider'
import SponsorForm from 'Event/Sponsors/SponsorForm'
import Body from 'Event/template/Lefty/Sponsors/SponsorList/Card/Body'
import {useToggleArray} from 'lib/toggle'
import {LeftySponsor} from 'Event/template/Lefty/Sponsors/SponsorList'
import {SponsorConfigurable} from 'Event/Sponsors/SponsorConfig'
import SponsorConfig from 'Event/template/Lefty/Sponsors/SponsorList/Card/SponsorConfig'
import {Lefty} from 'Event/template/Lefty'
import {rgba} from 'lib/color'

type SponsorProps = {
  index: number
  id: string
  sponsor: LeftySponsor
  className?: string
}

export default function Card(props: SponsorProps) {
  const {index, id} = props

  const isEditMode = useEditMode()
  if (!isEditMode) {
    return <Content {...props} />
  }

  return (
    <Draggable draggableId={id} index={index}>
      {(provided) => (
        <div
          ref={provided.innerRef}
          className={props.className}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <Content {...props} />
        </div>
      )}
    </Draggable>
  )
}

function Content(props: SponsorProps) {
  const {sponsor} = props
  const template = useLeftyTemplate()
  const [showingForm, toggleForm] = useToggleArray()
  const isEditMode = useEditMode()

  const isStock = isEditMode ? false : (sponsor.level ?? 0) > 1
  const backgroundRGBColor = rgba(
    template.sponsors.background.color || '#FFFFFF',
    template.sponsors.background.opacity / 100 || 0,
  )

  return (
    <SponsorConfigurable>
      <SponsorConfig id={props.id} sponsor={sponsor} />
      <Container
        isEditMode={isEditMode}
        border={template.sponsors.border}
        backgroundColor={backgroundRGBColor}
        textColor={template.textColor}
        sponsorTextColor={template.sponsors.text.color}
      >
        <Box aria-label="sponsor" className={props.className}>
          <>
            <Top isStock={isStock}>
              <SponsorForm
                sponsor={sponsor}
                showing={showingForm}
                onClose={toggleForm}
              />
              <Left sponsor={sponsor} id={props.id} />
              <RightContainer>
                <Body sponsor={sponsor} toggleForm={toggleForm} id={props.id} />
              </RightContainer>
            </Top>
          </>
        </Box>
      </Container>
    </SponsorConfigurable>
  )
}

function Left(props: {sponsor: LeftySponsor; id: string}) {
  const {sponsor} = props
  const {image: logo} = sponsor
  const {
    sponsors: {separator},
  } = useLeftyTemplate()

  if (!logo) {
    return null
  }

  return (
    <LeftContainer separator={separator}>
      <Logo sponsor={sponsor} />
    </LeftContainer>
  )
}

const Box = styled.div`
  position: relative;
  outline: none;
`

const LeftContainer = styled.div<{
  separator: Lefty['sponsors']['separator']
}>`
  flex: 1;
  border-right: ${(props) => props.separator.width}px solid
    ${(props) => props.separator.color};
`
const RightContainer = styled.div`
  flex: 1;
`
const Top = styled.div<{isStock?: boolean}>`
  display: ${(props) => (props.isStock ? 'block' : 'flex')};
`
const Container = styled.div<{
  isEditMode?: boolean
  border: Lefty['sponsors']['border']
  backgroundColor: string
  textColor: string
  sponsorTextColor: string | null
}>`
  color: ${(props) =>
    props.sponsorTextColor ? props.sponsorTextColor : props.textColor};
  box-shadow: 0px 0px 4px ${(props) => props.border.color};
  border: solid ${(props) => props.border.width}px
    ${(props) => props.border.color};
  border-radius: ${(props) => props.border.radius}px;
  background-color: ${(props) => props.backgroundColor || '#FFFFFF'};
  margin-bottom: ${(props) =>
    props.isEditMode ? props.theme.spacing[4] : '0'};
  height: ${(props) => (props.isEditMode ? 'unset' : '100%')};
  overflow: hidden;
`

function Logo(props: {sponsor: LeftySponsor; className?: string}) {
  const {sponsor} = props
  const alt = sponsor.name

  if (!sponsor.image) {
    return null
  }

  return (
    <ImageBox
      className={props.className}
      width={sponsor.logoSize}
      padding={sponsor.padding}
    >
      <img src={sponsor.image} alt={alt} aria-label="sponsor image" />
    </ImageBox>
  )
}

const ImageBox = styled.div<{
  width?: number
  padding: LeftySponsor['padding']
}>`
  display: flex;
  height: 100%;
  padding-top: ${(props) => props.padding?.top || '0'}px;
  padding-bottom: ${(props) => props.padding?.bottom || '0'}px;
  padding-left: ${(props) => props.padding?.left || '0'}px;
  padding-right: ${(props) => props.padding?.right || '0'}px;
  img {
    width: ${(props) => props.width || 100}%;
    margin: auto;
  }
`

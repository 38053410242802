import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import Button from '@material-ui/core/Button'
import {Rule} from 'Event/attendee-rules'
import RuleConfig from 'Event/attendee-rules/RuleConfig'
import ConfigureRulesButton from 'Event/attendee-rules/ConfigureRulesButton'
import FormControl from '@material-ui/core/FormControl'
import {Controller, useForm} from 'react-hook-form'
import TextField from '@material-ui/core/TextField'
import InputLabel from '@material-ui/core/InputLabel'
import FormSelect from 'organization/Event/FormsProvider/FormSelect'
import TextEditor, {TextEditorContainer} from 'lib/ui/form/TextEditor'
import withStyles from '@material-ui/core/styles/withStyles'
import {spacing} from 'lib/ui/theme'
import {useToggleArray} from 'lib/toggle'
import DangerButton from 'lib/ui/Button/DangerButton'
import {Waiver} from 'Event/template'
import {REMOVE, useSaveTemplate} from 'Event/TemplateUpdateProvider'

export default function Form(props: {
  onDone: () => void
  waiver: Waiver
  id: string
}) {
  const {waiver, onDone, id} = props
  const [rules, setRules] = useState<Rule[]>([])

  const [ruleConfigVisible, toggleRuleConfig] = useToggleArray()
  const {register, handleSubmit, control, errors} = useForm<Waiver>()

  const saveTemplate = useSaveTemplate()

  useEffect(() => {
    if (!waiver.rules) {
      return
    }

    setRules(waiver.rules)
  }, [setRules, waiver])

  const handleRemove = () => {
    saveTemplate({
      waiver: {
        targets: {
          [id]: REMOVE,
        },
      },
    })

    onDone()
  }

  const save = (data: Waiver) => {
    saveTemplate({
      waiver: {
        targets: {
          [id]: {
            ...data,
            rules,
          },
        },
      },
    })
    onDone()
  }

  return (
    <>
      <RuleConfig
        visible={ruleConfigVisible}
        onChange={setRules}
        rules={rules}
        close={toggleRuleConfig}
        description="Waiver will be shown when"
      >
        <form onSubmit={handleSubmit(save)}>
          <ConfigureRulesButton onClick={toggleRuleConfig} />
          <TextField
            name="title"
            label="Waiver File Title (optional)"
            defaultValue={waiver.title ? waiver.title : ''}
            fullWidth
            inputProps={{ref: register, 'aria-label': 'waiver title'}}
            helperText="Defaults to the event name"
          />
          <FormControl fullWidth>
            <InputLabel>Form</InputLabel>
            <Controller
              name="formId"
              control={control}
              defaultValue={waiver.formId || ''}
              render={({onChange, value}) => (
                <FormSelect onChange={onChange} value={value} />
              )}
            />
          </FormControl>
          <Editor>
            <BodyLabel error={Boolean(errors.body)}>Body</BodyLabel>
            <TextEditorContainer>
              <Controller
                name="body"
                defaultValue={waiver.body}
                control={control}
                render={({value, onChange}) => (
                  <TextEditor data={value} onChange={onChange} />
                )}
              />
            </TextEditorContainer>
          </Editor>

          <TextField
            name="agreeStatement"
            label="Agree Statement"
            defaultValue={waiver.agreeStatement}
            multiline
            rows={4}
            fullWidth
            inputProps={{
              'aria-label': 'agree statement',
              ref: register,
            }}
          />

          <TextField
            name="signaturePrompt"
            label="Signature Prompt"
            defaultValue={waiver.signaturePrompt}
            fullWidth
            inputProps={{
              'aria-label': 'signature prompt',
              ref: register,
            }}
          />

          <SaveButton
            variant="contained"
            color="primary"
            fullWidth
            type="submit"
            aria-label="save additional waiver"
          >
            Save
          </SaveButton>
          <RemoveButton onClick={handleRemove} />
        </form>
      </RuleConfig>
    </>
  )
}

const SaveButton = styled(Button)`
  margin-top: ${(props) => props.theme.spacing[2]}!important;
  margin-bottom: ${(props) => props.theme.spacing[4]}!important;
`

const StyledDangerButton = styled(DangerButton)`
  margin-bottom: ${(props) => props.theme.spacing[4]}!important;
`

function RemoveButton(props: {onClick: () => void; disabled?: boolean}) {
  return (
    <StyledDangerButton
      fullWidth
      variant="outlined"
      onClick={props.onClick}
      disabled={props.disabled}
    >
      Remove
    </StyledDangerButton>
  )
}

const BodyLabel = withStyles({
  root: {
    marginBottom: spacing[3],
  },
})(InputLabel)

const Editor = styled.div`
  margin-top: ${(props) => props.theme.spacing[4]};
  margin-bottom: ${(props) => props.theme.spacing[6]};

  .ck-editor__editable_inline {
    min-height: 300px;
    max-height: 600px;
  }
`

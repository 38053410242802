import Slider from 'lib/ui/Slider'
import {CountDownTimerSettings} from 'Event/Dashboard/components/CountDownTimer'
import ColorPicker from 'lib/ui/ColorPicker'
import React from 'react'
import {Controller, UseFormMethods} from 'react-hook-form'
import Title from 'lib/ui/ConfigPanel/Title'
import Divider from 'lib/ui/ConfigPanel/Divider'
import {NumberField} from 'lib/ui/TextField'

export default function Styling(props: {
  control: UseFormMethods['control']
  countDownTimer: CountDownTimerSettings
}) {
  const {control, countDownTimer} = props

  return (
    <>
      <Title>Background</Title>
      <Controller
        name="backgroundColor"
        control={control}
        defaultValue={countDownTimer.backgroundColor}
        render={({value, onChange}) => (
          <ColorPicker label="Color" color={value} onPick={onChange} />
        )}
      />
      <Controller
        name="backgroundOpacity"
        defaultValue={countDownTimer.backgroundOpacity}
        control={control}
        render={({value, onChange}) => (
          <Slider
            min={0}
            max={100}
            step={1}
            onChange={onChange}
            valueLabelDisplay="auto"
            value={value}
            label="Opacity"
            unit="%"
          />
        )}
      />
      <Divider />
      <Title>Number</Title>
      <Controller
        name="numberColor"
        control={control}
        defaultValue={countDownTimer.numberColor}
        render={({value, onChange}) => (
          <ColorPicker label="Color" color={value} onPick={onChange} />
        )}
      />
      <Controller
        name="numberBackgroundColor"
        control={control}
        defaultValue={countDownTimer.numberBackgroundColor}
        render={({value, onChange}) => (
          <ColorPicker
            label="Block Background Color"
            color={value}
            onPick={onChange}
          />
        )}
      />
      <Controller
        name="numberBackgroundOpacity"
        defaultValue={countDownTimer.numberBackgroundOpacity}
        control={control}
        render={({value, onChange}) => (
          <Slider
            min={0}
            max={100}
            step={1}
            onChange={onChange}
            valueLabelDisplay="auto"
            value={value}
            label="Block Background Opacity"
            unit="%"
          />
        )}
      />
      <Controller
        name="numberBackgroundRadius"
        defaultValue={countDownTimer.numberBackgroundRadius}
        control={control}
        render={({value, onChange}) => (
          <Slider
            min={0}
            max={1}
            step={0.1}
            onChange={onChange}
            valueLabelDisplay="auto"
            value={value}
            label="Block Background Radius"
          />
        )}
      />
      <Divider />
      <Title>Description & Separator Text</Title>
      <Controller
        name="textColor"
        control={control}
        defaultValue={countDownTimer.textColor}
        render={({value, onChange}) => (
          <ColorPicker label="Color" color={value} onPick={onChange} />
        )}
      />
      <Controller
        name="numberBackgroundWidth"
        control={control}
        defaultValue={countDownTimer.numberBackgroundWidth}
        render={({value, onChange}) => (
          <NumberField
            aria-label="number block width"
            fullWidth
            onChange={onChange}
            value={value}
            label="Width"
          />
        )}
      />
      <Controller
        name="numberBackgroundHeight"
        control={control}
        defaultValue={countDownTimer.numberBackgroundHeight}
        render={({value, onChange}) => (
          <NumberField
            aria-label="number block height"
            fullWidth
            onChange={onChange}
            value={value}
            label="Height"
          />
        )}
      />
    </>
  )
}

import React from 'react'
import Slider from 'lib/ui/Slider'
import {Controller, UseFormMethods} from 'react-hook-form'
import {
  NavButtonProps,
  DEFAULT_BUTTON_HEIGHT,
  DEFAULT_FONT_SIZE,
} from 'Event/Dashboard/components/NavButton'
import {Label} from 'lib/ui/typography'
import ColorPicker from 'lib/ui/ColorPicker'
import Checkbox from 'lib/ui/Checkbox'
import BackgroundPicker from 'lib/ui/form/BackgroundPicker'
import FontSelect from 'lib/FontSelect'
import CollapsibleSection from 'lib/ui/ConfigPanel/CollapsibleSection'
import styled from 'styled-components'
import Visible from 'lib/ui/layout/Visible'
import {
  DEFAULT_BUTTON_BACKGROUND_COLOR,
  DEFAULT_BUTTON_TEXT_COLOR,
} from 'lib/ui/Button/CustomButton'
export default function Styling(props: {
  control: UseFormMethods['control']
  watch: UseFormMethods['watch']
  button: NavButtonProps
}) {
  const {control, button, watch} = props

  const showingHoverBackgroundInput = !watch(
    'disableHover',
    button.disableHover,
  )

  return (
    <>
      <CollapsibleSection
        label="Typography"
        storageKey="speaker.button.config.styling.typography"
      >
        <Label>Font</Label>
        <Controller
          control={control}
          name="font"
          defaultValue={button.font || null}
          render={({value, onChange}) => (
            <FontSelect value={value} onChange={onChange} />
          )}
        />
        <Controller
          name="fontSize"
          defaultValue={button.fontSize || DEFAULT_FONT_SIZE}
          control={control}
          render={({value, onChange}) => (
            <Slider
              min={1}
              max={100}
              onChange={onChange}
              valueLabelDisplay="off"
              value={value}
              label="Font Size"
            />
          )}
        />
        <Controller
          name="textColor"
          control={control}
          defaultValue={button.textColor || DEFAULT_BUTTON_TEXT_COLOR}
          render={({value, onChange}) => (
            <ColorPicker label="Text Color" color={value} onPick={onChange} />
          )}
        />
        <Controller
          name="hoverTextColor"
          control={control}
          defaultValue={button.hoverTextColor || DEFAULT_BUTTON_TEXT_COLOR}
          render={({value, onChange}) => (
            <ColorPicker
              label="Text Hover Color"
              color={value}
              onPick={onChange}
              aria-label="text hover color"
            />
          )}
        />
      </CollapsibleSection>
      <CollapsibleSection
        label="Background"
        storageKey="speaker.button.config.styling.background"
      >
        <Controller
          name="backgroundColor"
          control={control}
          defaultValue={
            button.backgroundColor || DEFAULT_BUTTON_BACKGROUND_COLOR
          }
          render={({value, onChange}) => (
            <BackgroundPicker
              label="Background"
              background={value}
              onChange={onChange}
            />
          )}
        />
        <Controller
          name="disableHover"
          control={control}
          defaultValue={button.disableHover || false}
          render={({value, onChange}) => (
            <StyledCheckBox
              label="Customize Hover Background"
              // Because we're toggling 'enable' here, but the prop is a 'disable', we need
              // to invert the values here.
              checked={!value}
              onChange={(val) => onChange(!val)}
              aria-label="toggle custom hover background"
            />
          )}
        />
        <Visible when={showingHoverBackgroundInput}>
          <Controller
            name="hoverBackgroundColor"
            control={control}
            defaultValue={
              button.hoverBackgroundColor || DEFAULT_BUTTON_BACKGROUND_COLOR
            }
            render={({value, onChange}) => (
              <BackgroundPicker
                label="Hover Background"
                background={value}
                onChange={onChange}
              />
            )}
          />
        </Visible>
      </CollapsibleSection>
      <CollapsibleSection
        label="Size"
        storageKey="speaker.button.config.styling.size"
      >
        <Controller
          name="height"
          defaultValue={button.height || DEFAULT_BUTTON_HEIGHT}
          control={control}
          render={({value, onChange}) => (
            <Slider
              min={1}
              max={400}
              onChange={onChange}
              valueLabelDisplay="off"
              value={value}
              label="Height"
            />
          )}
        />
        <Controller
          name="width"
          defaultValue={button.width || 20}
          control={control}
          render={({value, onChange}) => (
            <Slider
              min={1}
              max={100}
              onChange={onChange}
              valueLabelDisplay="off"
              value={value}
              hideUnits
              label="Width"
            />
          )}
        />
      </CollapsibleSection>
      <CollapsibleSection
        label="Border"
        storageKey="speaker.button.config.styling.border"
      >
        <Controller
          name="borderColor"
          control={control}
          defaultValue={button.borderColor || ''}
          render={({value, onChange}) => (
            <ColorPicker label="Color" color={value} onPick={onChange} />
          )}
        />
        <Controller
          name="hoverBorderColor"
          control={control}
          defaultValue={button.hoverBorderColor || ''}
          render={({value, onChange}) => (
            <ColorPicker label="Hover Color" color={value} onPick={onChange} />
          )}
        />
        <Controller
          name="borderWidth"
          defaultValue={button.borderWidth || 0}
          control={control}
          render={({value, onChange}) => (
            <Slider
              min={0}
              max={100}
              onChange={onChange}
              valueLabelDisplay="off"
              value={value}
              label="Width"
            />
          )}
        />
        <Controller
          name="borderRadius"
          defaultValue={button.borderRadius || 0}
          control={control}
          render={({value, onChange}) => (
            <Slider
              min={0}
              max={100}
              onChange={onChange}
              valueLabelDisplay="off"
              value={value}
              label="Radius"
            />
          )}
        />
      </CollapsibleSection>
    </>
  )
}

const StyledCheckBox = styled(Checkbox)`
  margin-bottom: ${(props) => props.theme.spacing[4]};
`

import {isPast, useCurrentTime} from 'lib/date-time'
import {useToggleArray} from 'lib/toggle'
import MenuItem from 'lib/ui/Menu/MenuItem'
import {communicationsApi} from 'lib/url'
import BroadcastForm, {
  Payload,
} from 'organization/Event/Broadcasts/BroadcastForm'
import {
  Broadcast,
  useBroadcasts,
} from 'organization/Event/Broadcasts/BroadcastsProvider'
import {useOrganization} from 'organization/OrganizationProvider'
import React from 'react'

export default React.forwardRef<
  HTMLLIElement,
  {
    broadcast: Broadcast
  }
>((props, ref) => {
  const {broadcast} = props
  const [showing, toggleShowing] = useToggleArray()
  const now = useCurrentTime()

  const sent = isPast(broadcast.send_at, now)
  const canEdit = broadcast.is_draft || !sent

  const title = canEdit ? 'Edit Broadcast' : 'Broadcast'
  const menuText = canEdit ? 'Edit' : 'View'
  const menuLabel = canEdit ? 'edit broadcast' : 'view broadcast'

  return (
    <>
      <MenuItem onClick={toggleShowing} ref={ref} aria-label={menuLabel}>
        {menuText}
      </MenuItem>
      <EditDialog
        broadcast={broadcast}
        showing={showing}
        onClose={toggleShowing}
        title={title}
        readOnly={!canEdit}
      />
    </>
  )
})

export function EditDialog(props: {
  showing: boolean
  onClose: () => void
  broadcast: Broadcast
  title: string
  readOnly?: boolean
}) {
  const {showing, onClose, broadcast, title, readOnly} = props

  const update = useUpdateBroadcast(broadcast)
  const list = useBroadcasts()

  const handleSubmit = (data: Payload) =>
    update(data).then((broadcast) => {
      list.update(broadcast)
      onClose()
    })

  return (
    <BroadcastForm
      title={title}
      readOnly={readOnly}
      onSubmit={handleSubmit}
      broadcast={broadcast}
      showing={showing}
      onClose={onClose}
    />
  )
}

function useUpdateBroadcast(broadcast: Broadcast) {
  const {client} = useOrganization()

  return (data: Payload) => {
    const url = communicationsApi(`/broadcasts/${broadcast.id}`)
    return client.put<Broadcast>(url, data)
  }
}

import React, {useCallback, useEffect, useState} from 'react'
import styled from 'styled-components'
import SignaturePad from 'signature_pad'
import {publicAsset} from 'lib/url'
import WebFont from 'webfontloader'
import Controls from 'lib/ui/form/Signature/Controls'
import {SIGNATURE_FONT} from 'lib/ui/form/Signature/Controls/TypeInput'

export default function Signature(props: {
  value: null | string
  onUpdate: (data: string | null) => void
  className?: string
  name: string
  typeButtonText: string
  typeButtonColor: string
  typeInputLabel: string
}) {
  const {onUpdate} = props

  const [canvas, setCanvas] = useState<HTMLCanvasElement | null>(null)
  const [box, setBox] = useState<HTMLDivElement | null>(null)

  const pad = useSignaturePad({
    canvas,
    box,
    onUpdate,
  })

  // Load typed signature font in case the users prefers to type out their signature..
  useEffect(() => {
    WebFont.load({
      custom: {
        families: [SIGNATURE_FONT],
      },
    })
  }, [])

  return (
    <Container>
      <Box className={props.className}>
        <CanvasBox ref={setBox}>
          <Canvas ref={setCanvas} aria-label="signature canvas" width="300" />
        </CanvasBox>
        <Controls {...props} canvas={canvas} pad={pad} />
      </Box>
    </Container>
  )
}

function useSignaturePad(params: {
  onUpdate: (data: string | null) => void
  canvas: HTMLCanvasElement | null
  box: HTMLDivElement | null
}): SignaturePad | null {
  const {onUpdate, canvas, box} = params
  const [pad, setPad] = useState<null | SignaturePad>(null)

  const createCanvas = useCallback(() => {
    // Wait for canvas, and box elements to be ready...
    if (!canvas || !box) {
      return
    }

    // Canvas el needs width explicitly set or the alignment will be off,
    // we'll use a responsive parent div, and calculate
    // the width based on that.
    //
    // Reference: https://github.com/szimek/signature_pad/issues/268#issuecomment-328837931
    //
    const width = box.clientWidth
    canvas.setAttribute('width', String(width))

    const signaturePad = new SignaturePad(canvas)
    setPad(signaturePad)

    signaturePad.onEnd = () => {
      const data = signaturePad.toDataURL()
      onUpdate(data)
    }

    return () => {
      signaturePad.clear()
      signaturePad.off()
      setPad(null)
    }
  }, [onUpdate, canvas, box])

  useEffect(createCanvas, [createCanvas])

  useEffect(() => {
    window.addEventListener('resize', createCanvas)

    return () => {
      window.removeEventListener('resize', createCanvas)
    }
  }, [createCanvas])

  return pad
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: ${(props) => props.theme.spacing[4]};
  position: relative;
`

const Box = styled.div`
  display: inline-block;
  position: relative;
`

const CanvasBox = styled.div`
  width: 300px;

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    width: 500px;
  }
`

const Canvas = styled.canvas`
  border-width: 2px;
  border-style: dashed;
  border-color: #706f70;
  background-color: rgb(255, 255, 255);
  cursor: url(${publicAsset('images/pen.cur')}), pointer;
  touch-action: none;
`

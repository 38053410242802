import React from 'react'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import {api} from 'lib/url'
import {useValidatedForm} from 'lib/form'
import {teamMemberClient} from 'obvio/obvio-client'
import {useToggle} from 'lib/toggle'
import ErrorAlert from 'lib/ui/alerts/ErrorAlert'
import SuccessAlert from 'lib/ui/alerts/SuccessAlert'
import {useState} from 'react'
import {useObvioUser} from 'obvio/auth'
import {useSetUser} from 'auth/auth-client'
import {User} from 'auth/user'

type AccountSettingData = {
  first_name: string
  last_name: string
  password: string
  new_password: string
  new_password_confirmation: string
}

export default function Form() {
  const {flag: submitting, toggle: toggleSubmitting} = useToggle()
  const user = useObvioUser()
  const setUser = useSetUser()

  const [showSuccess, setShowSuccess] = useState(false)

  const {
    register,
    errors,
    handleSubmit,
    responseError,
    setResponseError,
    clearErrors,
    reset,
  } = useValidatedForm<AccountSettingData>()

  const submit = (data: AccountSettingData) => {
    if (submitting) {
      return
    }

    setShowSuccess(false)

    toggleSubmitting()
    clearErrors()

    teamMemberClient
      .put<User>(api('/account'), data)
      .then((data: User) => {
        setShowSuccess(true)
        reset({
          first_name: data.first_name,
          last_name: data.last_name,
        })
        setUser(data)
      })
      .catch(setResponseError)
      .finally(toggleSubmitting)
  }

  return (
    <>
      <ErrorAlert>{responseError?.message}</ErrorAlert>
      <SuccessMessage showing={showSuccess} />
      <form onSubmit={handleSubmit(submit)}>
        <TextField
          label="First Name"
          type="text"
          fullWidth
          variant="outlined"
          disabled={submitting}
          name="first_name"
          inputProps={{
            ref: register(),
            'aria-label': 'first name',
          }}
          error={Boolean(errors.first_name)}
          helperText={errors.first_name}
          defaultValue={user.first_name}
        />
        <TextField
          label="Last Name"
          type="text"
          fullWidth
          variant="outlined"
          disabled={submitting}
          name="last_name"
          inputProps={{
            ref: register(),
            'aria-label': 'last name',
          }}
          error={Boolean(errors.last_name)}
          helperText={errors.last_name}
          defaultValue={user.last_name}
        />
        <TextField
          label="Current Password"
          type="password"
          fullWidth
          variant="outlined"
          disabled={submitting}
          name="password"
          inputProps={{
            ref: register(),
            'aria-label': 'current password',
          }}
          error={Boolean(errors.password)}
          helperText={errors.password}
        />
        <TextField
          label="New Password"
          type="password"
          fullWidth
          variant="outlined"
          disabled={submitting}
          name="new_password"
          inputProps={{
            ref: register(),
            'aria-label': 'new password',
          }}
          error={Boolean(errors.new_password)}
          helperText={errors.new_password}
        />
        <TextField
          label="Confirm Password"
          type="password"
          fullWidth
          variant="outlined"
          disabled={submitting}
          name="new_password_confirmation"
          inputProps={{
            ref: register(),
            'aria-label': 'password confirmation',
          }}
          error={Boolean(errors.new_password_confirmation)}
          helperText={errors.new_password_confirmation}
        />
        <Button
          variant="contained"
          fullWidth
          color="primary"
          disabled={submitting}
          aria-label="submit account settings"
          type="submit"
        >
          Update
        </Button>
      </form>
    </>
  )
}

function SuccessMessage(props: {showing: boolean}) {
  if (!props.showing) {
    return null
  }

  return <SuccessAlert>Your account has been updated.</SuccessAlert>
}

import InputLabelBase from 'lib/ui/InputLabel'
import React from 'react'
import styled from 'styled-components'

export default function InputLabel(props: {
  children?: React.ReactNode
  disabled?: boolean
}) {
  if (!props.children) {
    return null
  }

  return <LabelText disabled={props.disabled}>{props.children}</LabelText>
}

const LabelText = styled(InputLabelBase)<{
  disabled?: boolean
}>`
  display: block;
  margin-bottom: ${(props) => props.theme.spacing[3]};
  color: ${(props) =>
    props.disabled
      ? props.theme.colors.disabled
      : props.theme.colors.text.primary};
`

import {useTemplate} from 'Event/TemplateProvider'
import {useSaveTemplate} from 'Event/TemplateUpdateProvider'
import React from 'react'
import AreaSelect from 'organization/Event/Area/AreaSelect'

export default function AreaInput() {
  const {techCheck} = useTemplate()
  const save = useSaveTemplate()

  const setAreaKey = (areaKey: string) => {
    save({
      techCheck: {
        areaKey,
      },
    })
  }

  return <AreaSelect value={techCheck.areaKey} onPick={setAreaKey} />
}

import React, {useMemo} from 'react'
import {Elements} from '@stripe/react-stripe-js'
import {loadStripe, StripeElementsOptions} from '@stripe/stripe-js'

const publicKey = process.env.REACT_APP_STRIPE_PK as string

const options: StripeElementsOptions = {
  fonts: [
    {
      cssSrc:
        'https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;700&display=swap',
    },
  ],
}

export default function PurchaseFormStripe(props: {
  children: React.ReactNode
  stripeAccountId: string
}) {
  const {stripeAccountId} = props

  const stripe = useMemo(
    () => loadStripe(publicKey, {stripeAccount: stripeAccountId}),
    [stripeAccountId],
  )

  return (
    <Elements stripe={stripe} options={options}>
      {props.children}
    </Elements>
  )
}

import React from 'react'
import SponsorList from 'Event/template/NiftyFifty/Dashboard/Sponsors/SponsorList'
import {useAttendeeVariables} from 'Event'
import {PageTitle, PageDescription} from 'Event/template/NiftyFifty/Page'
import {useNiftyFiftyTemplate} from 'Event/template/NiftyFifty'
import Content from 'lib/ui/form/TextEditor/Content'
import Configurable from 'organization/Event/Configurable'
import HeaderConfig from 'Event/template/NiftyFifty/Dashboard/Sponsors/HeaderConfig'
import AddSponsorButton from 'Event/Sponsors/AddSponsorButton'

export default function Sponsors() {
  const template = useNiftyFiftyTemplate()
  const {sponsors: sponsorsPageSettings} = template
  const v = useAttendeeVariables()

  return (
    <>
      <Configurable>
        <HeaderConfig />
        <PageTitle
          pageTitleColor={template.pageTitleColor}
          textColor={template.textColor}
          aria-label="sponsors title"
          extraPadding
        >
          {v(sponsorsPageSettings.title)}
        </PageTitle>
      </Configurable>
      <PageDescription
        color={template.textColor}
        aria-label="sponsors description"
        extraPadding
      >
        <Content>{v(sponsorsPageSettings.description)}</Content>
      </PageDescription>
      <SponsorList />
      <AddSponsorButton />
    </>
  )
}

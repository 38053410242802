import {NavButtonWithSize} from 'Event/Dashboard/components/NavButton'
import React from 'react'
import ComponentConfig, {
  ComponentConfigProps,
  SaveButton,
  RemoveButton,
  Footer,
} from 'organization/Event/DashboardConfig/ComponentConfigPanel'
import {UseFormMethods} from 'react-hook-form'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import StylingPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/StylingPanel'
import BaseSettings from 'Event/Dashboard/components/NavButton/NavButtonConfig/BaseConfig/Settings'
import BaseStyling from 'Event/Dashboard/components/NavButton/NavButtonConfig/BaseConfig/Styling'
import RulesPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/RulesPanel'
import ScheduleInput from 'organization/Event/DashboardConfig/ComponentConfigPanel/ScheduleInput'
import {Rule} from 'Event/attendee-rules'
import {CardsNavButtonProps} from 'Event/template/Cards/Dashboard/CardsNavButton'

export default function MainNavButtonConfigBase(
  props: ComponentConfigProps & {
    button: NavButtonWithSize | CardsNavButtonProps
    id?: string | null
    control: UseFormMethods['control']
    register: UseFormMethods['register']
    watch: UseFormMethods['watch']
    setValue: (
      name: string,
      value: any,
      config?:
        | Partial<{shouldValidate: boolean; shouldDirty: boolean}>
        | undefined,
    ) => void
    rules: Rule[] | undefined
    setRules: any
    removeButton: () => void
    customSettings?: JSX.Element | boolean
    customStyling?: JSX.Element | boolean
    isVisible: boolean
    onClose: () => void
    handleSubmit: any
    save: any
    formState: UseFormMethods['formState']
    title?: string
  },
) {
  const {
    button,
    control,
    register,
    watch,
    setValue,
    rules,
    setRules,
    id,
    removeButton,
    isVisible,
    onClose,
    handleSubmit,
    save,
    customSettings,
    customStyling,
    formState,
    title,
  } = props

  return (
    <ComponentConfig
      showing={isVisible}
      onClose={onClose}
      title={title || 'Main Nav Button'}
      onSubmit={handleSubmit(save)}
      hasChanges={formState.isDirty}
    >
      <SettingsPanel>
        <BaseSettings
          control={control}
          register={register}
          watch={watch}
          button={button}
          setValue={setValue}
          id={id}
          customSettings={customSettings}
        />
      </SettingsPanel>
      <StylingPanel>
        <BaseStyling
          control={control}
          button={button}
          watch={watch}
          customStyling={customStyling}
        />
      </StylingPanel>
      <RulesPanel rules={rules} setRules={setRules}>
        <ScheduleInput control={control} setValue={setValue} values={button} />
      </RulesPanel>
      <Footer>
        <SaveButton type="submit" />
        <RemoveButton
          aria-label="remove button"
          onClick={removeButton}
          showing={Boolean(id)}
        />
      </Footer>
    </ComponentConfig>
  )
}

import Button, {ButtonProps} from 'lib/ui/Button'
import React from 'react'

interface PageSettingsButtonProps extends Omit<ButtonProps, 'children'> {}

export default function PageSettingsButton(props: PageSettingsButtonProps) {
  return (
    <Button
      variant="outlined"
      fullWidth
      color="primary"
      size="small"
      aria-label="page settings"
      {...props}
    >
      Page Settings
    </Button>
  )
}

import React from 'react'
import MuiCardHeader, {
  CardHeaderProps as MuiCardHeaderProps,
} from '@material-ui/core/CardHeader'
import {makeStyles} from '@material-ui/styles'

type CardHeaderProps = MuiCardHeaderProps & {
  titleColor?: string
}
export default function CardHeader(props: CardHeaderProps) {
  const useStyles = makeStyles({
    root: {
      paddingBottom: 0,
      '& :hover': {
        opacity: 0.6,
        cursor: 'pointer',
      },
    },
    title: {
      color: props.titleColor || 'unset',
      fontFamily: 'unset',
    },
  })
  const classes = useStyles()

  return <MuiCardHeader {...props} classes={classes} />
}

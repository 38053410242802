import {useEvent} from 'Event/EventProvider'
import {api} from 'lib/url'

export type HighLevelTag = {
  name: string
}

export function useAddTag() {
  const {client, event} = useEvent()

  const url = api(`/events/${event.id}/integrations/high_level/add_tag`)
  return (tag: HighLevelTag) => {
    try {
      client.post(url, {
        name: tag,
      })
    } catch {
      // Ignore errors, failing to add a tag should
      // not break the app.
    }
  }
}

import React from 'react'
import {Controller, UseFormMethods} from 'react-hook-form'
import {EnabledSwitch} from 'lib/ui/form/Switch'
import TextField from 'lib/ui/TextField'
import {Label} from 'lib/ui/typography'
import TextEditor, {TextEditorContainer} from 'lib/ui/form/TextEditor'
import ActionSelect from 'Event/ActionsProvider/ActionSelect'
import {useSimpleBlogTemplate} from 'Event/template/SimpleBlog'
import {onChangeCheckedHandler} from 'lib/dom'

export default function Settings(props: {
  control: UseFormMethods['control']
  register: UseFormMethods['register']
}) {
  const {control, register} = props
  const template = useSimpleBlogTemplate()
  const {imageWaterfall: current} = template

  return (
    <>
      <Controller
        name="isVisible"
        control={control}
        defaultValue={current.isVisible}
        render={({value, onChange}) => (
          <EnabledSwitch
            checked={value}
            onChange={onChangeCheckedHandler(onChange)}
            arial-label="toggle image waterfall page"
          />
        )}
      />
      <Label>Title</Label>
      <TextField
        name="title"
        aria-label="title"
        fullWidth
        defaultValue={current.title}
        inputProps={{
          ref: register,
        }}
      />
      <Controller
        name="description"
        defaultValue={current.description}
        control={control}
        render={({onChange, value}) => (
          <TextEditorContainer>
            <TextEditor data={value} onChange={onChange} />
          </TextEditorContainer>
        )}
      />
      <Label>Back To Dashboard Text</Label>
      <TextField
        name="backToDashboardText"
        aria-label="backToDashboardText"
        fullWidth
        defaultValue={current.backToDashboardText}
        inputProps={{
          ref: register,
        }}
      />
      <Label>Upload Form Title</Label>
      <TextField
        name="uploadFormTitle"
        aria-label="uploadFormTitle"
        fullWidth
        defaultValue={current.uploadFormTitle}
        inputProps={{
          ref: register,
        }}
      />
      <Controller
        name="uploadFormDescription"
        control={control}
        defaultValue={current.uploadFormDescription}
        render={({onChange, value}) => (
          <TextEditorContainer>
            <TextEditor data={value} onChange={onChange} />
          </TextEditorContainer>
        )}
      />
      <Label>Upload Button Text</Label>
      <TextField
        name="uploadButtonText"
        aria-label="uploadButtonText"
        fullWidth
        defaultValue={current.uploadButtonText}
        inputProps={{
          ref: register,
        }}
      />
      <Controller
        name="actionId"
        defaultValue={current.actionId}
        control={control}
        render={({value, onChange}) => (
          <ActionSelect
            value={value}
            onChange={onChange}
            label="Approved Action"
          />
        )}
      />
    </>
  )
}

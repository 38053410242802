import React from 'react'
import {useEventAuth} from 'Event/auth'
import {useTownhallTemplate} from 'Event/template/Townhall'
import {useRequiresPassword} from 'Event/Step1/SetPasswordForm'
import styled from 'styled-components'
import {RelativeLink} from 'lib/ui/link/RelativeLink'
import {eventRoutes} from 'Event/Routes'

export default function UserInfo(props: {email: string}) {
  const {
    footer: {textColor},
  } = useTownhallTemplate()

  const {logout} = useEventAuth()

  return (
    <UserInfoText textColor={textColor}>
      {`You're logged in as ${props.email} • `}
      <Logout onClick={logout}>Logout</Logout>
      <ChangePassword />
    </UserInfoText>
  )
}

function ChangePassword() {
  const {
    footer: {textColor},
  } = useTownhallTemplate()

  const requiresPassword = useRequiresPassword()
  if (!requiresPassword) {
    return null
  }

  return (
    <StyledRelativeLink
      to={eventRoutes.changePassword}
      aria-label="change password"
      disableStyles
      textColor={textColor}
    >
      Change Password
    </StyledRelativeLink>
  )
}

const UserInfoText = styled.p<{textColor: string | null}>`
  text-align: center;
  color: ${(props) => props.textColor} !important;
  span {
    &:not(:last-child):after {
      content: ' • ';
    }
  }
`

const Logout = styled.span`
  cursor: pointer;
`

const StyledRelativeLink = styled(RelativeLink)<{textColor: string | null}>`
  color: ${(props) => props.textColor};
`

import View from '@ckeditor/ckeditor5-ui/src/view';
import ViewCollection from '@ckeditor/ckeditor5-ui/src/viewcollection';

import ButtonView from '@ckeditor/ckeditor5-ui/src/button/buttonview';

import LabeledFieldView from '@ckeditor/ckeditor5-ui/src/labeledfield/labeledfieldview';
import { createLabeledInputText } from '@ckeditor/ckeditor5-ui/src/labeledfield/utils';

import submitHandler from '@ckeditor/ckeditor5-ui/src/bindings/submithandler';
import KeystrokeHandler from '@ckeditor/ckeditor5-utils/src/keystrokehandler';
import FocusTracker from '@ckeditor/ckeditor5-utils/src/focustracker';
import FocusCycler from '@ckeditor/ckeditor5-ui/src/focuscycler';

import checkIcon from '@ckeditor/ckeditor5-core/theme/icons/check.svg';
import cancelIcon from '@ckeditor/ckeditor5-core/theme/icons/cancel.svg';

export default class CustomButtonTextFormView extends View {
	/**
      * @inheritDoc
      */
	constructor( locale ) {
		super( locale );

		const t = this.locale.t;

		/**
          * Tracks information about the DOM focus in the form.
          *
          * @readonly
          * @member {module:utils/focustracker~FocusTracker}
          */
		this.focusTracker = new FocusTracker();

		/**
          * An instance of the {@link module:utils/keystrokehandler~KeystrokeHandler}.
          *
          * @readonly
          * @member {module:utils/keystrokehandler~KeystrokeHandler}
          */
		this.keystrokes = new KeystrokeHandler();

		/**
          * An input with a label.
          *
          * @member {module:ui/labeledfield/labeledfieldview~LabeledFieldView} #labeledInput
          */
		this.labeledInput = this._createLabeledInputView();

		/**
          * A button used to submit the form.
          *
          * @member {module:ui/button/buttonview~ButtonView} #saveButtonView
          */
		this.saveButtonView = this._createButton( t( 'Save' ), checkIcon, 'ck-button-save' );
		this.saveButtonView.type = 'submit';

		/**
          * A button used to cancel the form.
          *
          * @member {module:ui/button/buttonview~ButtonView} #cancelButtonView
          */
		this.cancelButtonView = this._createButton( t( 'Cancel' ), cancelIcon, 'ck-button-cancel', 'cancel' );

		/**
          * A collection of views which can be focused in the form.
          *
          * @readonly
          * @protected
          * @member {module:ui/viewcollection~ViewCollection}
          */
		this._focusables = new ViewCollection();

		/**
          * Helps cycling over {@link #_focusables} in the form.
          *
          * @readonly
          * @protected
          * @member {module:ui/focuscycler~FocusCycler}
          */
		this._focusCycler = new FocusCycler( {
			focusables: this._focusables,
			focusTracker: this.focusTracker,
			keystrokeHandler: this.keystrokes,
			actions: {
				// Navigate form fields backwards using the Shift + Tab keystroke.
				focusPrevious: 'shift + tab',

				// Navigate form fields forwards using the Tab key.
				focusNext: 'tab'
			}
		} );

		this.setTemplate( {
			tag: 'form',

			attributes: {
				class: [
					'ck',
					'ck-responsive-form',
					'obvio-custom-button-input-form'
				],

				// https://github.com/ckeditor/ckeditor5-image/issues/40
				tabindex: '-1'
			},

			children: [
				this.labeledInput,
				this.saveButtonView,
				this.cancelButtonView
			]
		} );
	}

	/**
      * @inheritDoc
      */
	render() {
		super.render();

		this.keystrokes.listenTo( this.element );

		submitHandler( { view: this } );

		[ this.labeledInput, this.saveButtonView, this.cancelButtonView ]
			.forEach( v => {
				// Register the view as focusable.
				this._focusables.add( v );

				// Register the view in the focus tracker.
				this.focusTracker.add( v.element );
			} );
	}

	/**
      * Creates the button view.
      *
      * @private
      * @param {String} label The button label
      * @param {String} icon The button's icon.
      * @param {String} className The additional button CSS class name.
      * @param {String} [eventName] The event name that the ButtonView#execute event will be delegated to.
      * @returns {module:ui/button/buttonview~ButtonView} The button view instance.
      */
	_createButton( label, icon, className, eventName ) {
		const button = new ButtonView( this.locale );

		button.set( {
			label,
			icon,
			tooltip: true
		} );

		button.extendTemplate( {
			attributes: {
				class: className
			}
		} );

		if ( eventName ) {
			button.delegate( 'execute' ).to( this, eventName );
		}

		return button;
	}

	/**
      * Creates an input with a label.
      *
      * @private
      * @returns {module:ui/labeledfield/labeledfieldview~LabeledFieldView} Labeled field view instance.
      */
	_createLabeledInputView() {
		const t = this.locale.t;
		const labeledInput = new LabeledFieldView( this.locale, createLabeledInputText );

		labeledInput.label = t( 'Text' );
		labeledInput.fieldView.placeholder = t( 'Text' );

		return labeledInput;
	}
}


import styled from 'styled-components'
import $ from 'jquery'
import VisibleOnMatch from 'Event/attendee-rules/VisibleOnMatch'
import {Editable} from 'Event/Dashboard/editor/views/EditComponent'
import React, {
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import Published from 'Event/Dashboard/editor/views/Published'
import {Draggable, DraggableProvidedDraggableProps} from 'react-beautiful-dnd'
import {useEditMode} from 'Event/EditModeProvider'
import {DraggableOverlay, DragHandle} from 'lib/ui/drag-and-drop'
import {useToggle} from 'lib/toggle'
import {MainNavButtonConfig} from 'Event/template/Cards/Dashboard/MainNav/MainNavButton/MainNavButtonConfig'
import CardsNavButton, {
  CardsNavButtonProps,
} from 'Event/template/Cards/Dashboard/CardsNavButton'
import {useCardsTemplate} from 'Event/template/Cards'
import {getBoundingRectPoints, isSamePoint, Point} from 'lib/dom'
import {useObserve} from 'lib/rx'
import {zip} from 'rxjs'
import {useOnResize} from 'lib/resize'

type MainNavButtonProps = {
  id: string
  button: CardsNavButtonProps
  index: number
}

export default React.memo((props: MainNavButtonProps) => {
  const isEditMode = useEditMode()
  const {flag: configVisible, toggle: toggleConfig} = useToggle()
  const {flag: configCopyVisible, toggle: toggleCopyConfig} = useToggle()

  if (!isEditMode) {
    return (
      <Container button={props.button}>
        <Button {...props} />
      </Container>
    )
  }

  return (
    <>
      <MainNavButtonConfig
        showing={configVisible}
        onClose={toggleConfig}
        id={props.id}
        button={props.button}
      />
      {/* Duplicate config for 'copying', note the missing ID prop. */}
      <MainNavButtonConfig
        showing={configCopyVisible}
        onClose={toggleCopyConfig}
        button={props.button}
      />
      <Draggable draggableId={props.id} index={props.index}>
        {(provided) => (
          <Container
            button={props.button}
            ref={provided.innerRef}
            draggableProps={provided.draggableProps}
          >
            <DraggableOverlay>
              <Editable onEdit={toggleConfig} onCopy={toggleCopyConfig}>
                <>
                  <DragHandle handleProps={provided.dragHandleProps} />
                  <Button onClick={toggleConfig} {...props} />
                </>
              </Editable>
            </DraggableOverlay>
          </Container>
        )}
      </Draggable>
    </>
  )
})

function Button(props: {button: CardsNavButtonProps; onClick?: () => void}) {
  return (
    <CardsNavButton
      {...props.button}
      aria-label="main nav button"
      iconSize={65}
      iconStacked
      onClick={props.onClick}
    />
  )
}

const Container = React.forwardRef<
  HTMLDivElement,
  {
    children: React.ReactElement
    button: CardsNavButtonProps
    draggableProps?: DraggableProvidedDraggableProps
  }
>((props, ref) => {
  const {
    mainNav: {borderRadius},
  } = useCardsTemplate()

  const {row} = props.button

  const boxRef = useRef<HTMLDivElement | null>()

  const {isTopLeft, isTopRight, isBottomLeft, isBottomRight} = usePositions(
    props.button,
    boxRef,
  )

  return (
    <VisibleOnMatch rules={props.button.rules}>
      <Published component={props.button}>
        <Box
          ref={(el) => {
            // Want to have a reference to the el here, but also forward it along
            // to parent.
            boxRef.current = el

            if (typeof ref === 'function') {
              ref(el)
            }

            if (ref && typeof ref === 'object') {
              ref.current = el
            }
          }}
          {...props}
          {...props.draggableProps}
          borderRadius={borderRadius}
          row={row}
          isTopLeft={isTopLeft}
          isTopRight={isTopRight}
          isBottomLeft={isBottomLeft}
          isBottomRight={isBottomRight}
        />
      </Published>
    </VisibleOnMatch>
  )
})

function usePositions(
  props: CardsNavButtonProps,
  ref: MutableRefObject<HTMLDivElement | null | undefined>,
) {
  const [container, setContainer] = useState<null | Point[]>(null)
  const [button, setButton] = useState<null | Point[]>(null)
  const {value$: container$, onReady: onReadyContainer} = useObserve(container)
  const {value$: button$, onReady: onReadyButton} = useObserve(button)
  const {value$: position$, onReady: onReadyPosition} = useObserve(
    props.position,
  )

  const {
    mainNav: {buttons},
  } = useCardsTemplate()

  const numButtons = Object.keys(buttons).length

  const [isTopLeft, setIsTopLeft] = useState(false)
  const [isTopRight, setIsTopRight] = useState(false)
  const [isBottomLeft, setIsBottomLeft] = useState(false)
  const [isBottomRight, setIsBottomRight] = useState(false)
  const nav = $('#main-nav')[0]

  // Set current positions
  const calculate = useCallback(() => {
    const target = ref.current
    if (!nav || !target) {
      return
    }

    setContainer(getBoundingRectPoints(nav))
    setButton(getBoundingRectPoints(target))
  }, [nav, ref])

  useOnResize(calculate) // Handle updating browser height
  useEffect(calculate, [calculate, numButtons]) // Calculate on render, and add/remove

  // Determine props whenever positions change
  useEffect(() => {
    if (!onReadyContainer || !onReadyButton) {
      return
    }

    // Zip to keep container/button in sync.
    zip(container$, button$).subscribe(([container, button]) => {
      if (!container || !button) {
        return
      }

      setIsTopLeft(isSamePoint(container[0], button[0]))
      setIsTopRight(isSamePoint(container[1], button[1]))
      setIsBottomLeft(isSamePoint(container[2], button[2]))
      setIsBottomRight(isSamePoint(container[3], button[3]))
    })

    onReadyContainer()
    onReadyButton()
  }, [onReadyContainer, container$, button$, onReadyButton])

  // Re-calculate whenever button order changes
  useEffect(() => {
    if (!onReadyPosition) {
      return
    }

    position$.subscribe(calculate)
    onReadyPosition()
  }, [position$, onReadyPosition, calculate])

  return {
    isTopLeft,
    isTopRight,
    isBottomLeft,
    isBottomRight,
  }
}

const Box = styled.div<{
  row: number
  borderRadius: number
  isTopLeft: boolean
  isTopRight: boolean
  isBottomLeft: boolean
  isBottomRight: boolean
}>`
  flex: 1;
  padding: 1px;

  .nav-button {
    ${(props) =>
      props.isTopLeft ? `border-top-left-radius: ${props.borderRadius}px;` : ''}
    ${(props) =>
      props.isTopRight
        ? `border-top-right-radius: ${props.borderRadius}px;`
        : ''}
    ${(props) =>
      props.isBottomLeft
        ? `border-bottom-left-radius: ${props.borderRadius}px;`
        : ''}
    ${(props) =>
      props.isBottomRight
        ? `border-bottom-right-radius: ${props.borderRadius}px;`
        : ''}
  }

  /**
   * Fix case where the MainNavHeight is smaller than the intrinsic
   * button height, which would result in various button heights
   * in a single row.
   */

  display: flex;
  flex-direction: column;

  /* Not ideal, but we know .drag-handle is the only child div that shouldn't be 100% height */
  div:not(.drag-handle) {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  a {
    flex: 1;
  }
`

import React from 'react'
import {FAQ} from 'Event/FAQs'
import Body from 'Event/template/NiftyFifty/Dashboard/Faqs/FaqList/Card/Body'
import {Draggable} from 'react-beautiful-dnd'
import {useEditMode} from 'Event/EditModeProvider'

export const DEFAULT_DESCRIPTION = ''
export const DEFAULT_TITLE = 'FAQ'
export const DEFAULT_BACK_TO_DASHBOARD_TEXT = 'Back to Dashboard'
export const DEFAULT_BACK_TO_DASHBOARD_TEXT_COLOR = '#000000'

type FaqProps = {
  index: number
  id: string
  faq: FAQ
  className?: string
}

export default function Card(props: FaqProps) {
  const {id, faq, index} = props
  const isEditMode = useEditMode()

  if (!isEditMode) {
    return (
      <div aria-label="faq" className={props.className}>
        <Body faq={faq} id={id} index={index} />
      </div>
    )
  }

  return (
    <Draggable draggableId={id} index={index}>
      {(provided) => (
        <div
          ref={provided.innerRef}
          aria-label="faq"
          className={props.className}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <Body faq={faq} id={id} index={index} />
        </div>
      )}
    </Draggable>
  )
}

import {useTemplate} from 'Event/TemplateProvider'
import {useSaveTemplate} from 'Event/TemplateUpdateProvider'
import TextEditor, {TextEditorContainer} from 'lib/ui/form/TextEditor'
import {useConfig} from 'organization/Event/Configurable'
import Config from 'organization/Event/Configurable/Config'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import React from 'react'
import {Controller} from 'react-hook-form'

export default function BodyConfig() {
  const {techCheck} = useTemplate()
  const save = useSaveTemplate()

  const {
    form: {control},
  } = useConfig()

  return (
    <Config title="Body" onSave={save}>
      <SettingsPanel>
        <TextEditorContainer>
          <Controller
            name="techCheck.body"
            defaultValue={techCheck.body}
            control={control}
            render={({value, onChange}) => (
              <TextEditor data={value} onChange={onChange} />
            )}
          />
        </TextEditorContainer>
      </SettingsPanel>
    </Config>
  )
}

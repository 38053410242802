import React from 'react'
import {Rule} from 'Event/attendee-rules'
import GroupRuleConfig from 'Event/attendee-rules/RuleConfig/RuleList/SingleRule/GroupRule/GroupRuleConfig'
import {TAGS} from 'Event/attendee-rules/RuleConfig/RuleList/SingleRule/TagsRule'
import TagsRuleConfig from 'Event/attendee-rules/RuleConfig/RuleList/SingleRule/TagsRule/TagsRuleConfig'
import {NESTED_RULE} from 'Event/attendee-rules/RuleConfig/RuleList/SingleRule/NestedRule'
import NestedRuleConfig from 'Event/attendee-rules/RuleConfig/RuleList/SingleRule/NestedRule/NestedRuleConfig'
import {GROUP} from 'Event/attendee-rules/RuleConfig/RuleList/SingleRule/GroupRule'
import GroupsProvider from 'organization/Event/Groups/GroupsProvider'
import {FORM_RESPONSE} from '../SingleRule/FormResponseRule'
import FormResponseRuleConfig from '../SingleRule/FormResponseRule/FormResponseRuleConfig'
import {TIME} from 'Event/attendee-rules/RuleConfig/RuleList/SingleRule/TimeRule'
import TimeRuleConfig from 'Event/attendee-rules/RuleConfig/RuleList/SingleRule/TimeRule/TimeRuleConfig'

export type RuleConfigProps = {
  onSet: (rule: Rule | null) => void
  rule: Rule | null
  onToggleNestedRule?: () => void
}

export default function SourceConfig(
  props: RuleConfigProps & {
    source: Rule['source'] | null
  },
) {
  if (!props.source) {
    return null
  }

  switch (props.source) {
    case TAGS:
      return <TagsRuleConfig rule={props.rule} onSet={props.onSet} />
    case GROUP:
      return (
        <GroupsProvider disableLoader>
          <GroupRuleConfig rule={props.rule} onSet={props.onSet} />
        </GroupsProvider>
      )
    case NESTED_RULE:
      return (
        <NestedRuleConfig
          rule={props.rule}
          onSet={props.onSet}
          onToggleNestedRule={props.onToggleNestedRule}
        />
      )
    case FORM_RESPONSE:
      return <FormResponseRuleConfig rule={props.rule} onSet={props.onSet} />
    case TIME:
      return <TimeRuleConfig rule={props.rule} onSet={props.onSet} />
  }
}

import ExistingCard from 'obvio/Billing/CreditCardSection/DefaultCardManagement/ExistingCard'
import NewCardForm from 'obvio/Billing/CreditCardSection/DefaultCardManagement/NewCardForm'
import React from 'react'

export default function DefaultCardManagement() {
  return (
    <>
      <ExistingCard />
      <NewCardForm />
    </>
  )
}

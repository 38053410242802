import styled from 'styled-components'
import React from 'react'
import {useTemplate} from 'Event/TemplateProvider'

export default function Container(props: {
  children: JSX.Element | JSX.Element[]
  disableSpacing: boolean
  postId: string
  textColor?: string
}) {
  const {disableSpacing, postId, textColor} = props
  const {postStyles} = useTemplate()

  const marginBottom = disableSpacing ? 0 : postStyles.spacing

  return (
    <Post
      aria-label="blog post"
      className="blog-post"
      id={`blog-post--${postId}`}
      marginBottom={marginBottom}
      textColor={textColor}
    >
      {props.children}
    </Post>
  )
}

const Post = styled.div<{
  marginBottom: number
  textColor?: string
}>`
  margin-bottom: ${(props) => props.marginBottom}px;

  img {
    max-width: 100%;
  }

  & > * {
    color: ${(props) => props.textColor};
  }
`

import {withStyles} from '@material-ui/core/styles'
import MUIContainer from '@material-ui/core/Container'

const Container = withStyles({
  root: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
  },
})(MUIContainer)

export default Container

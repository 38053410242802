import React from 'react'
import EmailField from 'Event/auth/Login/EmailField'
import Page, {
  ErrorMessage,
  StyledFormContainer,
  DescriptionText,
} from 'Event/template/Townhall/Login/Page'
import {ForgotPasswordProps} from 'auth/password'
import {useTownhallTemplate} from 'Event/template/Townhall'
import Description from 'Event/auth/ForgotPassword/Description'
import SubmitButton from 'Event/auth/ForgotPassword/SubmitButton'
import Title from 'Event/template/Townhall/ForgotPassword/Title'

export default function ForgotPassword(props: ForgotPasswordProps) {
  return (
    <Page isPreview={false}>
      <StyledFormContainer>
        <Content {...props} />
      </StyledFormContainer>
    </Page>
  )
}

export function Content(props: ForgotPasswordProps) {
  const {login} = useTownhallTemplate()

  if (props.resetLinkSent) {
    return (
      <Description
        aria-label="password reset link sent"
        Component={DescriptionText}
      >
        {login.passwordReset.successMessage}
      </Description>
    )
  }

  return (
    <>
      <Title />
      <Description
        aria-label="event login description"
        Component={DescriptionText}
      >
        {login.passwordReset?.description}
      </Description>
      <form onSubmit={props.onSubmit}>
        <EmailField {...props} />
        <ErrorMessage>{props.responseError?.message}</ErrorMessage>
        <SubmitButton {...props} />
      </form>
    </>
  )
}

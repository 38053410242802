import {SeparatorBlock} from 'Event/Marketplace/Block/Separator'

export const createSeparatorBlock = (): SeparatorBlock => ({
  type: 'Separator',
  visibility: 'all',
  background: {
    image: '',
    color: '',
    opacity: 0,
  },
  padding: {
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
  alignment: {
    vertical: 'center',
    horizontal: 'center',
  },
  border: {
    width: 0,
    color: '#FFFFFF',
    radius: 0,
  },
  separatorStyle: 'solid',
  color: '#000000',
  opacity: 100,
  height: 1,
  width: 100,
  radius: 1,
})

import React, {useState} from 'react'
import {Controller, UseFormMethods} from 'react-hook-form'
import {TextFieldProps} from '@material-ui/core/TextField'
import {MaterialUiPickersDate} from '@material-ui/pickers/typings/date'
import {formatPrice} from 'lib/currency'
import {onChangeCheckedHandler} from 'lib/dom'
import {fieldError} from 'lib/form'
import LocalizedDateTimePicker from 'lib/LocalizedDateTimePicker'
import {ValidationError} from 'lib/ui/api-client'
import DescribedGroupsInput, {GroupPair} from 'lib/ui/form/DescribedGroupsInput'
import DescribedTagsInput from 'lib/ui/form/DescribedTagsInput'
import DescribedSwitch from 'lib/ui/form/DescribedSwitch'
import PageHeader from 'lib/ui/PageHeader'
import PageHeaderButton from 'lib/ui/PageHeader/Button'
import Title from 'lib/ui/PageHeader/Title'
import DescribedTextArea from 'lib/ui/TextField/DescribedTextArea'
import DescribedTextField from 'lib/ui/TextField/DescribedTextField'
import {CreateTicketData} from 'lib/marketplace-api/tickets/use-create-ticket'
import {MarketplaceTicket} from 'lib/marketplace-api/tickets'

export default function Form(props: {
  control: UseFormMethods['control']
  formErrors: UseFormMethods['errors']
  isEditing?: boolean
  onSubmit: () => void
  responseError: ValidationError<CreateTicketData>
  setValue: UseFormMethods['setValue']
  submitting: boolean
  ticket?: MarketplaceTicket
}) {
  const {
    formErrors,
    isEditing,
    onSubmit,
    responseError,
    setValue,
    submitting,
    ticket,
  } = props

  const [slugDirty, setSlugDirty] = useState<boolean>(Boolean(isEditing))

  // If we're in edit mode and don't have a ticket value yet, null-out.
  if (isEditing && !ticket) {
    return null
  }

  const error = (key: keyof CreateTicketData) =>
    fieldError(key, {
      form: formErrors,
      response: responseError,
    })

  const errors = {
    name: error('name'),
    slug: error('slug'),
    description: error('description'),
    // start: error('start'),
    // end: error('end'),
    price: error('price'),
  }

  // Convert the string[] items of group:value into a GroupPair[] for the
  // component rendering it.
  const ticketGroups = groupsAsGroupPairs(ticket?.groups)

  const handleFromDate = (date: MaterialUiPickersDate) => {
    if (!date) {
      return
    }
    setValue('available_from', date.toISOString())
  }

  const handleToDate = (date: MaterialUiPickersDate) => {
    if (!date) {
      return
    }
    setValue('available_to', date.toISOString())
  }

  const slugValue = (value: string) => {
    // If a true value for slugDirty, means that user has manually adjusted the
    // slug value and we don't want to auto-compute it for them.
    if (slugDirty) {
      return
    }

    const cleanedSlug = value
      .toLowerCase()
      .replace(/ /g, '-')
      .replace(/[^0-9a-z-]/gi, '')

    // Set the computed value into Control
    setValue('slug', cleanedSlug)
  }

  const formatTicketPrice = (value: string) => {
    setValue('price', value)
  }

  return (
    <>
      <PageHeader>
        <Title text={formTitle(ticket)} />
        <PageHeaderButton
          disabled={submitting}
          onClick={onSubmit}
          text={formButtonText(ticket)}
        />
      </PageHeader>

      <form onSubmit={onSubmit}>
        <Controller
          name="name"
          control={props.control}
          defaultValue={ticket?.name || ''}
          rules={{
            required: 'Name is required',
          }}
          render={({onChange, value}) => (
            <DescribedTextField
              title="Ticket Name*"
              aria-label="ticket name"
              description={'Your ticket name'}
              name="name"
              required
              fullWidth
              disabled={submitting}
              value={value}
              onChange={(value) => {
                slugValue(value)
                onChange(value)
              }}
              helperText={errors.name}
              error={Boolean(errors.name)}
            />
          )}
        />
        <Controller
          name="slug"
          defaultValue={ticket?.slug || ''}
          control={props.control}
          rules={{
            required: 'Ticket Slug is required',
          }}
          render={({onChange, value}) => (
            <DescribedTextField
              title="Ticket Slug*"
              aria-label="ticket slug"
              description={"The value for the ticket's URL"}
              name="slug"
              required
              fullWidth
              disabled={submitting}
              value={value}
              onChange={(value) => {
                setSlugDirty(true)
                onChange(value)
              }}
              helperText={errors.slug}
              error={Boolean(errors.slug)}
            />
          )}
        />
        <Controller
          name="description"
          defaultValue={ticket?.description || ''}
          control={props.control}
          render={({onChange, value}) => (
            <DescribedTextArea
              title="Description"
              aria-label="ticket description"
              description="A short blurb to describe this Ticket to new Attendees"
              name="description"
              rows={3}
              fullWidth
              disabled={submitting}
              value={value}
              onChange={onChange}
              helperText={errors.description}
              error={Boolean(errors.description)}
            />
          )}
        />
        <Controller
          name="available_from"
          control={props.control}
          defaultValue={ticket?.available_from ?? null}
          render={({onChange, value}) => (
            <LocalizedDateTimePicker
              disabled={submitting}
              value={value}
              onChange={handleFromDate}
              fullWidth
              label="Available From"
              TextFieldComponent={(props: TextFieldProps) => (
                <DescribedTextField
                  aria-label="ticket available from"
                  description="Optional date/time when this ticket will start being available"
                  name="available_from"
                  fullWidth
                  disabled={submitting}
                  onChange={onChange}
                  value={props.value as string}
                  onClick={props.onClick}
                />
              )}
            />
          )}
        />
        <Controller
          name="available_to"
          control={props.control}
          defaultValue={ticket?.available_to ?? null}
          render={({onChange, value}) => (
            <LocalizedDateTimePicker
              disabled={submitting}
              value={value}
              onChange={handleToDate}
              fullWidth
              label="Available To"
              TextFieldComponent={(props: TextFieldProps) => (
                <DescribedTextField
                  aria-label="ticket available to"
                  description="Optional date/time when this ticket will no longer be available"
                  name="available_to"
                  fullWidth
                  disabled={submitting}
                  onChange={onChange}
                  value={props.value as string}
                  onClick={props.onClick}
                />
              )}
            />
          )}
        />
        <Controller
          name="tags"
          control={props.control}
          defaultValue={ticket?.tags || ''}
          render={({onChange, value}) => (
            <DescribedTagsInput
              value={value}
              title="Applied Tags"
              description="Any tags listed will be applied to the Attendee after a successful purchase of this ticket"
              fullWidth
              name="tags"
              aria-label="ticket tags"
              onChange={onChange}
              disabled={submitting}
            />
          )}
        />

        <Controller
          name="groups"
          control={props.control}
          defaultValue={ticketGroups}
          render={({onChange, value}) => (
            <DescribedGroupsInput
              value={value}
              title="Applied Groups"
              description="Any groups listed will be applied to the Attendee after a successful purchase of this ticket"
              fullWidth
              name="groups"
              aria-label="ticket groups"
              onChange={onChange}
              disabled={submitting}
            />
          )}
        />

        <Controller
          name="price"
          defaultValue={formatPrice(ticket?.price || 0)}
          control={props.control}
          rules={{
            required: 'Ticket Price is required',
          }}
          render={({onChange, value}) => (
            <DescribedTextField
              title="Ticket Price*"
              aria-label="ticket price"
              description={'The price an Attendee will pay for this ticket'}
              name="price"
              required
              fullWidth
              disabled={submitting}
              value={value}
              onChange={(value) => {
                onChange(value)
                formatTicketPrice(value)
              }}
              helperText={errors.price}
              error={Boolean(errors.price)}
            />
          )}
        />
        <Controller
          name="hidden"
          defaultValue={ticket ? ticket.hidden : false}
          control={props.control}
          render={({onChange, value}) => (
            <DescribedSwitch
              title="Hidden"
              description="When a ticket is hidden, it is only available via it's direct URL using the Ticket Slug and does not show up in the Event's ticket listing"
              aria-label="ticket hidden"
              name="hidden"
              disabled={submitting}
              checked={value}
              onChange={onChangeCheckedHandler(onChange)}
            />
          )}
        />
        <Controller
          name="active"
          defaultValue={ticket ? ticket.active : true}
          control={props.control}
          render={({onChange, value}) => (
            <DescribedSwitch
              title="Active"
              description="A ticket is only available for purchase if it is active"
              aria-label="ticket active"
              name="active"
              disabled={submitting}
              checked={value}
              onChange={onChangeCheckedHandler(onChange)}
            />
          )}
        />
      </form>
    </>
  )
}

const formTitle = (ticket: MarketplaceTicket | undefined) => {
  return ticket ? 'Modify Ticket' : 'Add New Ticket'
}

const formButtonText = (ticket: MarketplaceTicket | undefined) => {
  return ticket ? 'Save Ticket' : 'Create Ticket'
}

const groupsAsGroupPairs = (groups?: string[]): GroupPair[] => {
  if (!groups) {
    return []
  }

  const groupPairs = groups.map((group) => {
    const temp = group.split(':')

    return {key: temp[0], value: temp[1]}
  })

  return groupPairs
}

import styled from 'styled-components'
import React from 'react'
import {useTownhallTemplate} from 'Event/template/Townhall'
import {Font, fontStyles, useLoadFont} from 'lib/FontSelect'
import {Draggable, DraggableProvidedDraggableProps} from 'react-beautiful-dnd'
import {useEditMode} from 'Event/EditModeProvider'
import {DragHandle, DraggableOverlay} from 'lib/ui/drag-and-drop'

export default React.memo(
  (props: {
    index?: number
    active?: boolean
    label: string
    value: string
    onClick?: () => void
  }) => {
    const {navBar} = useTownhallTemplate()
    useLoadFont(navBar.font)
    const isEditMode = useEditMode()

    if (!isEditMode || props.index === undefined) {
      return (
        <StyledTab
          {...props}
          color={navBar.color}
          fontSize={navBar.fontSize}
          font={navBar.font}
          onClick={props.onClick}
          active={props.active}
        >
          {props.label}
        </StyledTab>
      )
    }

    return (
      <Draggable draggableId={props.value} index={props.index}>
        {(provided) => (
          <Container
            ref={provided.innerRef}
            draggableProps={provided.draggableProps}
          >
            <DraggableOverlay>
              <DragHandle handleProps={provided.dragHandleProps} />
              <StyledTab
                {...props}
                color={navBar.color}
                fontSize={navBar.fontSize}
                font={navBar.font}
              >
                {props.label}
              </StyledTab>
            </DraggableOverlay>
          </Container>
        )}
      </Draggable>
    )
  },
)

const StyledTab = styled.div<{
  color: string
  fontSize: number
  font: Font | null
  active?: boolean
}>`
  ${(props) => fontStyles(props.font)};
  color: ${(props) => props.color};
  font-weight: bold;
  font-size: ${(props) => props.fontSize}px;
  text-transform: none;
  min-width: auto;
  padding-right: ${(props) => props.theme.spacing[4]};
  cursor: pointer;
  white-space: nowrap;
  ${(props) => (props.active ? `text-decoration: underline;` : null)}
`

const Container = React.forwardRef<
  HTMLDivElement,
  {
    children: React.ReactElement
    draggableProps?: DraggableProvidedDraggableProps
  }
>((props, ref) => {
  return (
    <>
      <StyledGrid ref={ref} {...props.draggableProps}>
        {props.children}
      </StyledGrid>
    </>
  )
})

const StyledGrid = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

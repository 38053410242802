import {useLayout} from 'organization/Event/Layout'
import styled from 'styled-components'
import React from 'react'
import {CloseIcon} from 'lib/ui/Icon'

export default function CloseButton() {
  const {toggleMobileSidebar} = useLayout()

  return (
    <Button aria-label="toggle sidebar" onClick={toggleMobileSidebar}>
      <CloseIcon iconSize={14} />
      <span>Close</span>
    </Button>
  )
}

const Button = styled.button`
  background: ${(props) => props.theme.colors.input.filled};
  color: #000000;

  border: 0;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  display: flex;

  // Only show on mobile drawer-style sidebar
  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    display: none;
  }

  > * {
    flex: 0;
  }

  i {
    margin-right: ${(props) => props.theme.spacing[2]};
  }

  padding: ${(props) => `${props.theme.spacing[2]} ${props.theme.spacing[10]}`};

  border-top: 1px solid #ffffff;

  span {
    font-size: 14px;
  }
`

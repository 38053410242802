import EditModeOnly from 'Event/Dashboard/editor/views/EditModeOnly'
import React from 'react'
import AreaInput from 'organization/Event/TechCheckConfig/ConfigFields/AreaInput'

export default function ConfigFields() {
  return (
    <EditModeOnly>
      <AreaInput />
    </EditModeOnly>
  )
}

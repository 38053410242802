import ColorPicker from 'lib/ui/ColorPicker'
import {useTownhallTemplate} from 'Event/template/Townhall'
import React from 'react'
import {Controller} from 'react-hook-form'
import CollapsibleSection from 'lib/ui/ConfigPanel/CollapsibleSection'
import Slider from 'lib/ui/Slider'
import {Label} from 'lib/ui/typography'
import {NumberField} from 'lib/ui/TextField'
import FontSelect from 'lib/FontSelect'
import {useConfig} from 'organization/Event/Configurable'

export default function Styling() {
  const {
    form: {control},
  } = useConfig()
  const template = useTownhallTemplate()

  return (
    <>
      <CollapsibleSection label="NavBar" storageKey="dashboard.navbar.styling">
        <Label>Font</Label>
        <Controller
          control={control}
          name="navBar.font"
          defaultValue={template.navBar.font || null}
          render={({value, onChange}) => (
            <FontSelect value={value} onChange={onChange} />
          )}
        />
        <Controller
          name="navBar.fontSize"
          defaultValue={template.navBar.fontSize || 24}
          control={control}
          render={({value, onChange}) => (
            <Slider
              aria-label="navBar text font size"
              min={10}
              max={50}
              onChange={onChange}
              valueLabelDisplay="off"
              value={value}
              label="Font Size"
            />
          )}
        />
        <Controller
          name="navBar.height"
          defaultValue={template.navBar.height}
          control={control}
          render={({value, onChange}) => (
            <Slider
              min={1}
              max={400}
              onChange={onChange}
              valueLabelDisplay="off"
              value={value}
              label="Height"
            />
          )}
        />
        <Controller
          name="navBar.backgroundColor"
          defaultValue={template.navBar.backgroundColor}
          control={control}
          render={({value, onChange}) => (
            <ColorPicker
              label="Background Color"
              color={value}
              onPick={onChange}
              aria-label="nabar background color"
            />
          )}
        />
        <Controller
          name="navBar.color"
          defaultValue={template.navBar.color}
          control={control}
          render={({value, onChange}) => (
            <ColorPicker
              label="Color"
              color={value}
              onPick={onChange}
              aria-label="navbar text color"
            />
          )}
        />
        <Controller
          name="navBar.indicatorColor"
          defaultValue={template.navBar.indicatorColor}
          control={control}
          render={({value, onChange}) => (
            <ColorPicker
              label="indicator Color"
              color={value}
              onPick={onChange}
              aria-label="navbar indicator color"
            />
          )}
        />
      </CollapsibleSection>
      <CollapsibleSection
        label="Emoji"
        storageKey="dashboard.navbar.emojis.styling"
      >
        <Controller
          name="emojiList.emojiWidth"
          defaultValue={template.emojiList.emojiWidth || 20}
          control={control}
          render={({value, onChange}) => (
            <NumberField
              label="Size"
              fullWidth
              value={value}
              onChange={onChange}
              inputProps={{
                min: 1,
                max: 100,
              }}
            />
          )}
        />
      </CollapsibleSection>
    </>
  )
}

import React from 'react'
import {useUpdateTemplate} from 'organization/Marketplace/PurchasePageConfig/TemplateUpdateProvider'
import Button from 'lib/ui/Button'
import {uuid} from 'lib/uuid'
import styled from 'styled-components'
import {createPositions, orderedIdsByPosition} from 'lib/list'
import Icon from 'lib/ui/Icon'
import {createSection} from 'Event/Marketplace/create-section'
import {PurchasePageTemplate} from 'Event/Marketplace/purchase-page'

export default function AddSectionButton(props: {
  template: PurchasePageTemplate
}) {
  const updateTemplate = useUpdateTemplate()
  const {template} = props
  const addSection = () => {
    const ids = orderedIdsByPosition(template.sections)

    const sections = createPositions(ids)

    const id = uuid()
    updateTemplate({
      sections: {
        ...sections,
        [id]: createSection(),
      },
    })
  }

  return (
    <Box>
      <StyledButton
        variant="contained"
        fullWidth
        onClick={addSection}
        size="large"
        startIcon={<Icon className="fa-solid fa-circle-plus" />}
      >
        Add Section
      </StyledButton>
    </Box>
  )
}

const Box = styled.div`
  margin: 3rem 0;
  padding: ${(props) => `${props.theme.spacing[6]} ${props.theme.spacing[3]}`};
  display: flex;
  align-items: center;
  justify-content: center;
`

const StyledButton = styled(Button)`
  max-width: 300px;
  color: white;
  background-color: #1e4686;
  &:hover {
    background-color: #1e4686;
    opacity: 0.9;
  }
`

import React from 'react'
import {Controller, UseFormMethods} from 'react-hook-form'
import Alert from '@material-ui/lab/Alert'
import AlertTitle from '@material-ui/lab/AlertTitle'
import Typography from '@material-ui/core/Typography'
import FormControl from '@material-ui/core/FormControl'
import {onChangeCheckedHandler} from 'lib/dom'
import {EnabledSwitch} from 'lib/ui/form/Switch'
import TextEditor, {TextEditorContainer} from 'lib/ui/form/TextEditor'
import {Label} from 'lib/ui/typography'
import TextField from 'lib/ui/TextField'
import {useCardsTemplate} from 'Event/template/Cards'
import TextArea from 'lib/ui/TextField/TextArea'

export default function Settings(props: {
  control: UseFormMethods['control']
  register: UseFormMethods['register']
}) {
  const template = useCardsTemplate()
  const {leaderboard} = template
  const {control, register} = props

  return (
    <>
      <Alert severity="info">
        <AlertTitle>Variables</AlertTitle>
        <div>
          <Typography variant="caption">
            {`{{points_unit}} - Name for points`}
          </Typography>
        </div>
        <div>
          <Typography variant="caption">
            {`{{action_description}} - Action description`}
          </Typography>
        </div>
        <div>
          <Typography variant="caption">
            {`{{action_points}} - Number of points received`}
          </Typography>
        </div>
        <div>
          <Typography variant="caption">
            {`{{leaderboard_points}} - Attendee's current points`}
          </Typography>
        </div>
        <div>
          <Typography variant="caption">
            {`{{leaderboard_position}} - Attendee's current position on leaderboard`}
          </Typography>
        </div>
      </Alert>
      <FormControl>
        <Controller
          name="isVisible"
          control={control}
          defaultValue={leaderboard.isVisible}
          render={({value, onChange}) => (
            <EnabledSwitch
              checked={value}
              onChange={onChangeCheckedHandler(onChange)}
              arial-label="toggle points"
            />
          )}
        />
      </FormControl>
      <Label>Title</Label>
      <TextField
        name="title"
        aria-label="set leaderboard page title"
        defaultValue={leaderboard.title}
        fullWidth
        inputProps={{
          ref: register({
            required: 'Title is required',
          }),
        }}
      />
      <Controller
        name="description"
        control={control}
        defaultValue={leaderboard.description}
        rules={{
          required: 'Description is required',
        }}
        render={({value, onChange}) => (
          <TextEditorContainer>
            <TextEditor
              data={value}
              onChange={onChange}
              customLinks={['Leaderboard']}
            />
          </TextEditorContainer>
        )}
      />
      <Label>Menu Title</Label>
      <TextField
        defaultValue={leaderboard.menuTitle}
        name="menuTitle"
        aria-label="set leaderboard menu title"
        fullWidth
        inputProps={{
          ref: register,
        }}
      />
      <Label>Points Unit</Label>
      <TextField
        name="points_unit"
        aria-label="points unit"
        fullWidth
        defaultValue={template.points_unit}
        inputProps={{
          ref: register({
            required: 'Points unit is required',
          }),
        }}
      />
      <Label>Popup Text</Label>
      <TextArea
        name="rewardAlert.text"
        label="Popup Text"
        aria-label="set leaderboard popup text"
        fullWidth
        defaultValue={template.rewardAlert.text}
        rows={3}
        variant="outlined"
        inputProps={{
          ref: register({required: 'Title is required'}),
        }}
      />
    </>
  )
}

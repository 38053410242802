import CustomButtonTextCommand from './custombuttontextcommand';
import Plugin from '@ckeditor/ckeditor5-core/src/plugin';

export default class CustomButtonTextEditing extends Plugin {
	/**
      * @inheritDoc
      */
	static get pluginName() {
		return 'CustomButtonTextEditing';
	}

	/**
      * @inheritDoc
      */
	init() {
		this.editor.commands.add( 'customButtonText', new CustomButtonTextCommand( this.editor ) );
	}
}


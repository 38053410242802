import React from 'react'
import styled from 'styled-components'
import {Label as Typography} from 'lib/ui/typography'

export interface RadioProps {
  className?: string
  label?: string
  checked?: boolean
  disabled?: boolean
  'aria-label'?: string
  onChange?: (v: string) => void
  name: string
  value: string
}

export default function Radio(props: RadioProps) {
  const {label, checked, disabled, onChange} = props

  const toggle = () => {
    if (!onChange || disabled) {
      return
    }

    onChange(props.value)
  }

  return (
    <Label className={props.className} onClick={toggle} disabled={disabled}>
      {label}
      <StyledInput
        type="radio"
        aria-label={props['aria-label']}
        checked={checked}
        readOnly
        disabled={disabled}
        name={props.name}
        value={props.value}
      />
      <StyledSpan disabled={disabled} />
    </Label>
  )
}

const Label = styled(Typography)<{
  disabled?: boolean
}>`
  display: block;
  position: relative;
  padding-left: 32px;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  user-select: none;

  color: ${(props) =>
    props.disabled
      ? props.theme.colors.disabled
      : props.theme.colors.text.primary};

  &::hover {
    + span {
      background-color: #ccc;
    }
  }
  input:checked {
    + span {
      display: flex;
      text-align: center;
      align-items: center;
      justify-content: center;

      &::after {
        display: block;
      }
    }
  }
`

const StyledInput = styled.input`
  position: absolute;
  opacity: 0;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  height: 0;
  width: 0;
`

const StyledSpan = styled.span<{
  disabled?: boolean
}>`
  position: absolute;
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  border: 1px solid
    ${(props) =>
      props.disabled
        ? props.theme.colors.disabled
        : props.theme.colors.primary};
  border-radius: 50%;
  &:after {
    content: '';
    position: absolute;
    display: none;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: white;
    transition: all 250ms ease;
  }
`

import React from 'react'
import {Controller} from 'react-hook-form'

import TargetInput, {
  TargetInputProps,
} from 'Event/Dashboard/components/NavButton/NavButtonConfig/TargetInput'

import ActionSelect from 'Event/ActionsProvider/ActionSelect'
import {EnabledSwitch} from 'lib/ui/form/Switch'
import {onChangeCheckedHandler} from 'lib/dom'
import MailchimpTagInput from 'organization/Event/DashboardConfig/MailchimpTagInput'
import InfusionsoftTagInput from 'organization/Event/DashboardConfig/InfusionsoftTagInput'
import ContentInput from 'Event/Dashboard/components/NavButton/NavButtonConfig/ContentInput'
import ZapierTagInput from 'organization/Event/DashboardConfig/ZapierTagInput'
import HubspotTagInput from 'organization/Event/DashboardConfig/HubspotTagInput'
import ActiveCampaignTagInput from 'organization/Event/DashboardConfig/ActiveCampaignTagInput'
import HighLevelTagInput from 'organization/Event/DashboardConfig/HighLevelTagInput'
import ConvertKitTagInput from 'organization/Event/DashboardConfig/ConvertKitTagInput'
import OntraportTagInput from 'organization/Event/DashboardConfig/OntraportTagInput'

export default function Settings(
  props: {
    id?: string | null
  } & TargetInputProps,
) {
  const {control, button, watch, register, setValue} = props

  return (
    <>
      <Controller
        defaultValue={button.isVisible}
        name="isVisible"
        control={control}
        render={({value, onChange}) => (
          <EnabledSwitch
            checked={value}
            onChange={onChangeCheckedHandler(onChange)}
            arial-label="config switch to attendee"
          />
        )}
      />
      <ContentInput
        button={button}
        control={control}
        register={register}
        watch={watch}
      />
      <Controller
        name="actionId"
        defaultValue={button.actionId}
        control={control}
        render={({value, onChange}) => (
          <ActionSelect value={value} onChange={onChange} />
        )}
      />
      <TargetInput
        disablePageSelect
        button={button}
        control={control}
        register={register}
        watch={watch}
        setValue={setValue}
      />
      <Controller
        name="infusionsoftTag"
        defaultValue={button.infusionsoftTag}
        control={control}
        render={({value, onChange}) => (
          <InfusionsoftTagInput value={value} onChange={onChange} />
        )}
      />
      <Controller
        name="mailchimpTag"
        defaultValue={button.mailchimpTag}
        control={control}
        render={({value, onChange}) => (
          <MailchimpTagInput
            value={{name: value}}
            onChange={(tag) => onChange(tag?.name ?? null)}
          />
        )}
      />
      <Controller
        name="zapierTag"
        defaultValue={button.zapierTag}
        control={control}
        render={({value, onChange}) => (
          <ZapierTagInput
            value={{name: value}}
            onChange={(tag) => onChange(tag?.name ?? null)}
          />
        )}
      />
      <Controller
        name="highLevelTag"
        defaultValue={button.highLevelTag}
        control={control}
        render={({value, onChange}) => (
          <HighLevelTagInput
            value={{name: value}}
            onChange={(tag) => onChange(tag?.name ?? null)}
          />
        )}
      />
      <Controller
        name="hubspotTag"
        defaultValue={button.hubspotTag}
        control={control}
        render={({value, onChange}) => (
          <HubspotTagInput value={value} onChange={onChange} />
        )}
      />
      <Controller
        name="activeCampaignTag"
        defaultValue={button.activeCampaignTag}
        control={control}
        render={({value, onChange}) => (
          <ActiveCampaignTagInput value={value} onChange={onChange} />
        )}
      />
      <Controller
        name="convertKitTag"
        defaultValue={button.convertKitTag}
        control={control}
        render={({value, onChange}) => (
          <ConvertKitTagInput value={value} onChange={onChange} />
        )}
      />
      <Controller
        name="ontraportTag"
        defaultValue={button.ontraportTag}
        control={control}
        render={({value, onChange}) => (
          <OntraportTagInput value={value} onChange={onChange} />
        )}
      />
    </>
  )
}

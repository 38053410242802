import React, {useState} from 'react'
import TextField from '@material-ui/core/TextField'
import styled from 'styled-components'
import {api} from 'lib/url'
import {RedirectLink} from 'Event/Redirects/CustomRedirect'
import {useValidatedForm} from 'lib/form'
import {useOrganization} from 'organization/OrganizationProvider'
import {useEvent} from 'Event/EventProvider'
import Button from 'lib/ui/Button'
import {UseFormMethods} from 'react-hook-form'
import {copyRedirectURL} from 'organization/Event/Redirects/RedirectsTable'

export interface AddRedirectData {
  description: string
  redirect_slug: string
  destination: string
}

export default function RedirectAddForm(props: {
  onClose: () => void
  onAdd: (redirect: RedirectLink) => void
  description?: string
  destination?: string
  copyToClipboard?: boolean
}) {
  const [submitting, setSubmitting] = useState(false)
  const {
    register,
    handleSubmit,
    errors,
    setResponseError,
    clearErrors,
  } = useValidatedForm()
  const {client} = useOrganization()
  const {event, url: eventUrl} = useEvent()

  const submit = (data: AddRedirectData) => {
    if (submitting) {
      return
    }
    setSubmitting(true)

    clearErrors()

    // Fix an issue where Safari would NOT copy to clipboard without
    // direct user input. In this case we were copying AFTER the
    // promise resolves, which didn't work.
    if (props.copyToClipboard) {
      copyRedirectURL(data.redirect_slug, eventUrl)
    }

    const url = api(`/redirects/${event.id}`)
    client
      .post<RedirectLink>(url, data)
      .then(props.onAdd)
      .then(props.onClose)
      .catch(setResponseError)
      .finally(() => {
        setSubmitting(false)
      })
  }

  return (
    <>
      <form onSubmit={handleSubmit(submit)}>
        <FormInputs
          submitting={submitting}
          errors={errors}
          register={register}
          description={props.description}
          destination={props.destination}
          readOnlyDestination={Boolean(props.destination)}
        />
        <div>
          <SaveButton
            variant="contained"
            color="primary"
            fullWidth
            disabled={submitting}
            type="submit"
            aria-label="save redirect"
          >
            Add Redirect
          </SaveButton>
        </div>
      </form>
    </>
  )
}

export function FormInputs(
  props: {
    submitting: boolean
    errors: Record<string, string | undefined>
    current?: RedirectLink
    description?: string
    destination?: string
    redirectSlug?: string
    readOnlyDestination?: boolean
  } & Pick<UseFormMethods, 'register'>,
) {
  const {
    submitting,
    register,
    errors,
    description = '',
    destination = '',
    redirectSlug = '',
    readOnlyDestination = false,
  } = props
  return (
    <>
      <TextField
        name="description"
        label="Description"
        required
        fullWidth
        disabled={submitting}
        defaultValue={description}
        inputProps={{
          ref: register({
            required: 'Description is required',
          }),
          'aria-label': 'redirect description',
        }}
        error={Boolean(errors.description)}
        helperText={errors.description}
      />
      <TextField
        name="destination"
        label="Destination"
        required
        fullWidth
        defaultValue={destination}
        disabled={submitting}
        inputProps={{
          readOnly: readOnlyDestination,
          ref: register({
            required: 'Destination is required',
            pattern: {
              message: 'Please, provide valid URL.',
              value: RegExp(
                '[(http(s)?):\\/\\/(www\\.)?a-zA-Z0-9@:%._\\+~#=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%_\\+.~#?&//=]*)',
              ),
            },
            maxLength: {
              value: 5000,
              message: 'Destination must be less than 5000 characters.',
            },
          }),
          'aria-label': 'redirect destination',
        }}
        error={Boolean(errors.destination)}
        helperText={errors.destination}
      />
      <TextField
        name="redirect_slug"
        label="Slash Tag"
        required
        fullWidth
        defaultValue={redirectSlug}
        disabled={submitting}
        inputProps={{
          ref: register({
            required: 'Slug is required',
            pattern: {
              message: 'Please, provide valid slash tag.',
              value: RegExp(/^[^/].*/),
            },
          }),
          'aria-label': 'redirect slug',
        }}
        error={Boolean(errors.redirect_slug)}
        helperText={errors.redirect_slug}
      />
    </>
  )
}

const SaveButton = styled(Button)`
  margin-bottom: ${(props) => props.theme.spacing[4]}!important;
`

import {useTownhallTemplate, useTownhallUpdate} from 'Event/template/Townhall'
import ColorPicker from 'lib/ui/ColorPicker'
import CollapsibleSection from 'lib/ui/ConfigPanel/CollapsibleSection'
import Slider from 'lib/ui/Slider'
import {useConfig} from 'organization/Event/Configurable'
import Config from 'organization/Event/Configurable/Config'
import StylingPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/StylingPanel'
import React from 'react'
import {Controller} from 'react-hook-form'

export default function RightPanelConfig() {
  const save = useTownhallUpdate()
  const {login} = useTownhallTemplate()

  const {
    form: {control},
  } = useConfig()

  return (
    <Config title="Login Content Right Panel" onSave={save}>
      <StylingPanel>
        <CollapsibleSection
          label="Background"
          storageKey="login.content.rightPanel.config"
        >
          <Controller
            name="login.rightPanel.backgroundColor"
            defaultValue={login.rightPanel.backgroundColor}
            control={control}
            render={({value, onChange}) => (
              <ColorPicker
                label="Color"
                color={value}
                onPick={onChange}
                aria-label="login content right panel background color"
              />
            )}
          />
          <Controller
            name="login.rightPanel.backgroundOpacity"
            defaultValue={login.rightPanel.backgroundOpacity}
            control={control}
            render={({value, onChange}) => (
              <Slider
                label="Opacity"
                unit="%"
                valueLabelDisplay="auto"
                aria-label="login content right panel background opacity"
                value={value}
                onChange={onChange}
                step={1}
                min={0}
                max={100}
              />
            )}
          />
        </CollapsibleSection>
      </StylingPanel>
    </Config>
  )
}

import React from 'react'
import {Controller, UseFormMethods} from 'react-hook-form'
import {useSimpleBlogTemplate} from 'Event/template/SimpleBlog'
import ColorPicker from 'lib/ui/ColorPicker'

export default function Styling(props: {control: UseFormMethods['control']}) {
  const {control} = props
  const template = useSimpleBlogTemplate()
  const {imageWaterfall: current} = template

  return (
    <>
      <Controller
        name="backToDashboardTextColor"
        control={control}
        defaultValue={current.backToDashboardTextColor}
        render={({value, onChange}) => (
          <ColorPicker
            label="Back to Dashboard Text Color"
            color={value}
            onPick={onChange}
            aria-label="set back to dashboard text color"
          />
        )}
      />
      <Controller
        name="rewardAlert.backgroundColor"
        control={control}
        defaultValue={template.rewardAlert.backgroundColor}
        render={({value, onChange}) => (
          <ColorPicker
            label="Popup Background Color"
            color={value}
            onPick={onChange}
          />
        )}
      />
      <Controller
        name="rewardAlert.textColor"
        control={control}
        defaultValue={template.rewardAlert.textColor}
        render={({value, onChange}) => (
          <ColorPicker label="Popup Color" color={value} onPick={onChange} />
        )}
      />
    </>
  )
}

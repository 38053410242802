import React from 'react'
import {useForm} from 'react-hook-form'
import {Cards} from 'Event/template/Cards'
import {useCardsUpdate} from 'Event/template/Cards'
import ComponentConfig, {
  ComponentConfigProps,
  SaveButton,
  Footer,
} from 'organization/Event/DashboardConfig/ComponentConfigPanel'
import Settings from 'Event/template/Cards/ImageWaterfall/ImageWaterfallConfig/Settings'
import Styling from 'Event/template/Cards/ImageWaterfall/ImageWaterfallConfig/Styling'
import StylingPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/StylingPanel'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'

export default function CardsImageWaterfallConfig(props: ComponentConfigProps) {
  const {showing: isVisible, onClose} = props

  const {control, handleSubmit, register, formState} = useForm()

  const updateTemplate = useCardsUpdate()

  const submit = (data: Cards['imageWaterfall']) => {
    updateTemplate({
      imageWaterfall: {
        ...data,
      },
    })

    props.onClose()
  }

  return (
    <ComponentConfig
      showing={isVisible}
      onClose={onClose}
      title="Image Waterfall"
      onSubmit={handleSubmit(submit)}
      hasChanges={formState.isDirty}
    >
      <SettingsPanel>
        <Settings control={control} register={register} />
      </SettingsPanel>
      <StylingPanel>
        <Styling control={control} />
      </StylingPanel>
      <Footer>
        <SaveButton type="submit" />
      </Footer>
    </ComponentConfig>
  )
}

import {Label} from 'lib/ui/typography'
import styled from 'styled-components'
import React from 'react'
import {Controller, UseFormMethods} from 'react-hook-form'
import Slider from 'lib/ui/Slider'
import {onUnknownChangeHandler} from 'lib/dom'
import ColorPicker from 'lib/ui/ColorPicker'
import Select from 'lib/ui/Select'
import MenuItem from '@material-ui/core/MenuItem'
import {useCardsTemplate} from 'Event/template/Cards'
import Title from 'lib/ui/ConfigPanel/Title'
import Divider from 'lib/ui/ConfigPanel/Divider'

export default function Styling(props: {control: UseFormMethods['control']}) {
  const {control} = props
  const {header, backgroundPosition, menu} = useCardsTemplate()

  return (
    <>
      <Title>Background</Title>
      <Controller
        name="header.backgroundColor"
        defaultValue={header.backgroundColor}
        control={control}
        render={({value, onChange}) => (
          <ColorPicker
            label="Color"
            color={value}
            onPick={onChange}
            aria-label="header background color"
          />
        )}
      />

      <Controller
        name="header.backgroundOpacity"
        defaultValue={header.backgroundOpacity}
        control={control}
        render={({value, onChange}) => (
          <Slider
            min={0}
            max={100}
            step={1}
            onChange={onChange}
            valueLabelDisplay="auto"
            valueLabelFormat={() => <div>{value * 100}</div>}
            value={value || 0}
            aria-label="background opacity"
            label="Opacity"
            unit="%"
          />
        )}
      />

      <Label>Position</Label>
      <Controller
        name="backgroundPosition"
        defaultValue={backgroundPosition}
        control={control}
        render={({value, onChange}) => (
          <Select
            value={value}
            onChange={onUnknownChangeHandler(onChange)}
            fullWidth
          >
            <MenuItem value="fixed">Fixed</MenuItem>
            <MenuItem value="bottom">Bottom</MenuItem>
          </Select>
        )}
      />
      <StyledDivider />
      <Title>Menu</Title>
      <Controller
        name="menu.backgroundColor"
        defaultValue={menu.backgroundColor}
        control={control}
        render={({value, onChange}) => (
          <ColorPicker
            label="Background Color"
            color={value}
            onPick={onChange}
            aria-label="menu background color"
          />
        )}
      />
      <Controller
        name="menu.textColor"
        defaultValue={menu.textColor}
        control={control}
        render={({value, onChange}) => (
          <ColorPicker
            label="Text Color"
            color={value}
            onPick={onChange}
            aria-label="menu text color"
          />
        )}
      />
      <Controller
        name="menu.iconColor"
        defaultValue={menu.iconColor}
        control={control}
        render={({value, onChange}) => (
          <ColorPicker
            label="Icon Color"
            color={value}
            onPick={onChange}
            aria-label="menu icon color"
          />
        )}
      />
    </>
  )
}

const StyledDivider = styled(Divider)`
  margin-top: ${(props) => props.theme.spacing[6]} !important;
`

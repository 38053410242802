import React from 'react'
import styled from 'styled-components'
import MenuItem, {MenuItemProps} from '@material-ui/core/MenuItem'
import AppBarBase, {AppBarProps} from 'lib/ui/AppBar'
import {obvioRoutes} from 'obvio/Routes'
import {RelativeLink} from 'lib/ui/link/RelativeLink'
import {useOrganization} from 'organization/OrganizationProvider'
import Divider from '@material-ui/core/Divider'
import HasPermission from 'organization/HasPermission'
import {PURCHASE_ADD_ONS, UPDATE_TEAM} from 'organization/PermissionsProvider'
import AccountMenu from 'lib/ui/AppBar/AccountMenu'
import OwnerOnly from 'organization/auth/OwnerOnly'
import BillingMenuItem from 'obvio/Billing/BillingMenuItem'
import Menu from 'lib/ui/Menu'
import Button from 'lib/ui/Button'
import {ChevronDownIcon} from 'lib/ui/Icon'

export default function AppBar(props: Partial<AppBarProps>) {
  const {organization} = useOrganization()

  return (
    <AppBarBase
      homeLink={obvioRoutes.organizations.root}
      desktopRight={
        <>
          <Menu
            button={({open}) => (
              <StyledButton
                aria-label="organization menu"
                variant="text"
                color="dark"
                onClick={open}
              >
                <span>{organization.name}</span>
                <ChevronDownIcon />
              </StyledButton>
            )}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
          >
            <EventsMenuItem />
            <HasPermission required={UPDATE_TEAM}>
              <TeamMenuItem />
            </HasPermission>
            <HasPermission required={PURCHASE_ADD_ONS}>
              <OrgAddOnsMenuItem />
            </HasPermission>
            <OwnerOnly>
              <OrgSettingsMenuItem />
            </OwnerOnly>
          </Menu>
        </>
      }
      accountMenu={
        <AccountMenu>
          <MobileOnly>
            <EventsMenuItem />
            <HasPermission required={UPDATE_TEAM}>
              <TeamMenuItem />
            </HasPermission>
            {/* <HasPermission required={PURCHASE_ADD_ONS}>
              <OrgAddOnsMenuItem />
            </HasPermission> */}
          </MobileOnly>
          <BillingMenuItem />
        </AccountMenu>
      }
      {...props}
    />
  )
}

const OrgSettingsMenuItem = React.forwardRef<MenuItemProps, any>(
  (props, ref) => {
    const {routes} = useOrganization()

    return (
      <>
        <RelativeLink
          to={routes.settings}
          disableStyles
          aria-label="organization settings"
        >
          <MenuItem ref={ref} {...props}>
            Settings
          </MenuItem>
        </RelativeLink>
        <Divider />
      </>
    )
  },
)

const OrgAddOnsMenuItem = React.forwardRef<MenuItemProps, any>((props, ref) => {
  const {routes} = useOrganization()

  return (
    <>
      <RelativeLink
        to={routes.add_ons.root}
        disableStyles
        aria-label="organization add-ons"
      >
        <MenuItem ref={ref} {...props}>
          Add-ons
        </MenuItem>
      </RelativeLink>
      <Divider />
    </>
  )
})

const EventsMenuItem = React.forwardRef<MenuItemProps, any>((props, ref) => {
  const {routes} = useOrganization()

  return (
    <>
      <RelativeLink to={routes.events.root} disableStyles aria-label="events">
        <MenuItem ref={ref} {...props}>
          Events
        </MenuItem>
      </RelativeLink>
      <Divider />
    </>
  )
})

const TeamMenuItem = React.forwardRef<MenuItemProps, any>((props, ref) => {
  const {routes} = useOrganization()

  return (
    <>
      <RelativeLink to={routes.team} disableStyles>
        <MenuItem ref={ref} {...props}>
          Team
        </MenuItem>
      </RelativeLink>
      <Divider />
    </>
  )
})

const MobileOnly = styled.div`
  display: block;
  @media screen and (min-width: ${(props) => props.theme.breakpoints.md}) {
    display: none;
  }
`

const StyledButton = styled(Button)`
  i {
    margin-left: 0.5rem;
  }
`

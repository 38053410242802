import React from 'react'
import Container from 'Event/Marketplace/Block/Container'
import styled from 'styled-components'
import {BlockComponentProps} from 'Event/Marketplace/Block'
import {BlockBase} from '../base'

export interface SeparatorBlock extends BlockBase {
  type: 'Separator'
  separatorStyle: 'solid' | 'dotted' | 'dashed'
  color: string
  opacity: number
  height: number
  width: number
  radius: number
}

export default function Separator(props: SeparatorBlock & BlockComponentProps) {
  return (
    <Container {...props}>
      <StyledSeparator {...props} />
    </Container>
  )
}

const StyledSeparator = styled.hr<SeparatorBlock>`
  border-top: ${(props) => props.height}px ${(props) => props.separatorStyle}
    ${(props) => props.color};
  width: ${(props) => props.width}%;
  opacity: ${(props) => props.opacity / 100};
  border-radius: ${(props) => props.radius}px;
  margin: 0;
  background-color: #ffffff;
  border-bottom: none;
`

import React from 'react'
import {ForgotPasswordProps} from 'auth/password'
import {PageConfigurable} from 'organization/Event/Configurable'
import RightPanelConfig from 'Event/template/NiftyFifty/ForgotPassword/RightPanel/RightPanelConfig'
import styled from 'styled-components'
import {useNiftyFiftyTemplate} from 'Event/template/NiftyFifty'
import {rgba} from 'lib/color'
import Description from 'Event/auth/ForgotPassword/Description'
import {useGuestVariables} from 'Event'
import EmailField from 'Event/auth/Login/EmailField'
import {ErrorMessage} from 'Event/template/NiftyFifty/Login/Page'
import SubmitButton from 'Event/auth/ForgotPassword/SubmitButton'
import {
  RightContainer,
  FormContainer,
} from 'Event/template/NiftyFifty/Login/RightPanel/index'

export default function RightPanel(props: ForgotPasswordProps) {
  const template = useNiftyFiftyTemplate()
  const {login, textColor} = template

  return (
    <PageConfigurable>
      <RightPanelConfig />
      <RightContainer
        backgroundColor={rgba(
          login.loginRightPanel.backgroundColor,
          login.loginRightPanel.backgroundOpacity,
        )}
      >
        <FormContainer
          textColor={textColor}
          descriptionTextColor={login.description.color}
        >
          <Content {...props} />
        </FormContainer>
      </RightContainer>
    </PageConfigurable>
  )
}

export function Content(props: ForgotPasswordProps) {
  const template = useNiftyFiftyTemplate()
  const {login} = template
  const v = useGuestVariables()

  if (props.resetLinkSent) {
    return (
      <Description aria-label="password reset link sent">
        {v(login.passwordReset.successMessage)}
      </Description>
    )
  }

  return (
    <>
      <Description aria-label="event login description">
        {v(login.passwordReset.description)}
      </Description>
      <StyledForm onSubmit={props.onSubmit}>
        <EmailField {...props} />
        <ErrorMessage>{props.responseError?.message}</ErrorMessage>
        <SubmitButton {...props} />
      </StyledForm>
    </>
  )
}

const StyledForm = styled.form`
  width: 100%;
`

import {useAttendeeVariables} from 'Event'
import {useWaiver} from 'Event/Step2/Waivers/Waiver'
import SignaturePromptConfig from 'Event/Step2/Waivers/Waiver/SignaturePrompt/SignaturePromptConfig'
import Configurable from 'organization/Event/Configurable'
import React from 'react'
import styled from 'styled-components'

export default function SignaturePrompt() {
  const v = useAttendeeVariables()
  const {waiver} = useWaiver()

  return (
    <Configurable>
      <SignaturePromptConfig />
      <Box>
        <span
          dangerouslySetInnerHTML={{
            __html: v(waiver.signaturePrompt),
          }}
        />
      </Box>
    </Configurable>
  )
}

const Box = styled.div`
  text-align: center;
  margin-bottom: 8px;
`

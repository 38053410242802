import CircularProgress, {
  CircularProgressProps,
} from '@material-ui/core/CircularProgress'
import React from 'react'

export function Loader(props: CircularProgressProps) {
  return (
    <CircularProgress
      size={18}
      thickness={6}
      value={100}
      variant="indeterminate"
      color="primary"
      {...props}
    />
  )
}

import TextField from '@material-ui/core/TextField'
import {onUnknownChangeHandler} from 'lib/dom'
import ColorPicker from 'lib/ui/ColorPicker'
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Box from '@material-ui/core/Box'
import Slider from 'lib/ui/Slider'
import React, {useEffect, useState} from 'react'
import Button from '@material-ui/core/Button'
import styled from 'styled-components'
import ComponentConfig, {
  SaveButton,
  Footer,
} from 'organization/Event/DashboardConfig/ComponentConfigPanel'
import {useToggle} from 'lib/toggle'
import {Controller, UseFormMethods, useForm} from 'react-hook-form'
import {useSaveTemplate} from 'Event/TemplateUpdateProvider'
import ConfirmUnsavedChangesDialog from 'organization/Event/Configurable/ConfirmUnsavedChangesDialog'
import {BlogSectionProps} from 'Event/template/Townhall/Dashboard/Main/BlogSection'
import {useHasUnsavedChanges} from 'organization/Event/TemplateEditor'
import StylingPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/StylingPanel'
import {Townhall} from 'Event/template/Townhall'

export default function PostFormStylesConfig(props: {
  blogSection: BlogSectionProps
  sectionId: string
}) {
  const {flag: showingConfig, toggle: toggleConfig} = useToggle()

  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: {isDirty},
    watch,
  } = useForm()
  const [
    showingUnsavedChangesDialog,
    setShowingUnsavedChangesDialog,
  ] = useState(false)

  const hasChanges = useHasUnsavedChanges(watch())
  const update = useSaveTemplate()

  const save = (data: Townhall) => {
    update(data)

    toggleConfig()
  }

  const onClose = () => {
    if (isDirty) {
      setShowingUnsavedChangesDialog(true)
    } else {
      toggleConfig()
    }
  }

  useEffect(() => {
    if (!showingConfig) {
      reset()
      setShowingUnsavedChangesDialog(false)
    }
  }, [reset, showingConfig])

  return (
    <>
      <StyledEditPostFormStylesButton onClick={toggleConfig} />
      <ComponentConfig
        showing={showingConfig}
        onClose={onClose}
        title="Post Form Styles"
        hasChanges={hasChanges}
        onSubmit={handleSubmit(save)}
      >
        <ConfirmUnsavedChangesDialog
          onClose={toggleConfig}
          open={showingUnsavedChangesDialog}
          onSubmit={handleSubmit(save)}
        />
        <StylingPanel>
          <Styling control={control} register={register} {...props} />
        </StylingPanel>
        <Footer>
          <SaveButton type="submit" />
        </Footer>
      </ComponentConfig>
    </>
  )
}

function EditPostFormStylesButton(props: {
  onClick: () => void
  className?: string
}) {
  return (
    <Button
      className={props.className}
      fullWidth
      size="large"
      variant="contained"
      color="primary"
      aria-label="style post form"
      onClick={props.onClick}
    >
      Edit Post Form Styles
    </Button>
  )
}

function Styling(props: {
  control: UseFormMethods['control']
  register: UseFormMethods['register']
  blogSection: BlogSectionProps
  sectionId: string
}) {
  const {control, register, blogSection, sectionId} = props
  const {postFormStyles} = blogSection

  return (
    <>
      <TextField
        name={`dashboardSections.${sectionId}.postFormStyles.width`}
        defaultValue={postFormStyles?.width}
        label="Form Width %"
        type="number"
        fullWidth
        inputProps={{
          min: 1,
          max: 100,
          ref: register,
        }}
      />
      <Box mb={2}>
        <InputLabel>Form Position</InputLabel>
        <Controller
          name={`dashboardSections.${sectionId}.postFormStyles.position`}
          defaultValue={postFormStyles?.position}
          control={control}
          render={({value, onChange}) => (
            <Select
              value={value}
              onChange={onUnknownChangeHandler(onChange)}
              fullWidth
            >
              <MenuItem value="flex-end">Right</MenuItem>
              <MenuItem value="center">Center</MenuItem>
              <MenuItem value="flex-start">Left</MenuItem>
            </Select>
          )}
        />
      </Box>
      <Controller
        name={`dashboardSections.${sectionId}.postFormStyles.inputStyles.labelColor`}
        defaultValue={postFormStyles?.inputStyles?.labelColor}
        control={control}
        render={({value, onChange}) => (
          <ColorPicker
            label="Label Text Color"
            color={value}
            onPick={onChange}
            aria-label="label text color"
          />
        )}
      />
      <Controller
        name={`dashboardSections.${sectionId}.postFormStyles.inputStyles.borderColor`}
        defaultValue={postFormStyles?.inputStyles?.borderColor}
        control={control}
        render={({value, onChange}) => (
          <ColorPicker
            label="Input Border Color"
            color={value}
            onPick={onChange}
            aria-label="input box border color"
          />
        )}
      />
      <Controller
        name={`dashboardSections.${sectionId}.postFormStyles.inputStyles.backgroundColor`}
        defaultValue={postFormStyles?.inputStyles?.backgroundColor}
        control={control}
        render={({value, onChange}) => (
          <ColorPicker
            label="Input Background Color"
            color={value}
            onPick={onChange}
            aria-label="input box background color"
          />
        )}
      />
      <Box>
        <Controller
          name={`dashboardSections.${sectionId}.postFormStyles.inputStyles.backgroundOpacity`}
          defaultValue={postFormStyles?.inputStyles?.backgroundOpacity}
          control={control}
          render={({value, onChange}) => (
            <Slider
              min={1}
              max={100}
              step={1}
              onChange={onChange}
              valueLabelDisplay="auto"
              defaultValue={value}
              label="Input Background Color Opacity"
              unit="%"
            />
          )}
        />
      </Box>
      <Controller
        name={`dashboardSections.${sectionId}.postFormStyles.inputStyles.textColor`}
        defaultValue={postFormStyles?.inputStyles?.textColor}
        control={control}
        render={({value, onChange}) => (
          <ColorPicker
            label="Input Text Color"
            color={value}
            onPick={onChange}
            aria-label="input box text color"
          />
        )}
      />
      <Controller
        name={`dashboardSections.${sectionId}.postFormStyles.inputStyles.helperTextColor`}
        defaultValue={postFormStyles?.inputStyles?.helperTextColor}
        control={control}
        render={({value, onChange}) => (
          <ColorPicker
            label="Helper Text Color"
            color={value}
            onPick={onChange}
            aria-label="helper text color"
          />
        )}
      />
      <TextField
        name={`dashboardSections.${sectionId}.postFormStyles.buttonSize`}
        defaultValue={postFormStyles?.buttonSize}
        label="Form Button Size %"
        type="number"
        fullWidth
        inputProps={{
          min: 1,
          max: 100,
          ref: register,
        }}
      />
      <TextField
        name={`dashboardSections.${sectionId}.postFormStyles.buttonRadius`}
        defaultValue={postFormStyles?.buttonRadius}
        label="Form Button Radius"
        type="number"
        fullWidth
        inputProps={{
          min: 0,
          max: 50,
          ref: register,
        }}
      />
      <Controller
        name={`dashboardSections.${sectionId}.postFormStyles.buttonColor`}
        defaultValue={postFormStyles?.buttonColor}
        control={control}
        render={({value, onChange}) => (
          <ColorPicker
            label="Submit Button Text Color"
            color={value}
            onPick={onChange}
            aria-label="submit button color"
          />
        )}
      />
      <Controller
        name={`dashboardSections.${sectionId}.postFormStyles.buttonBackgroundColor`}
        defaultValue={postFormStyles?.buttonBackgroundColor}
        control={control}
        render={({value, onChange}) => (
          <ColorPicker
            label="Submit Button Background Color"
            color={value}
            onPick={onChange}
            aria-label="submit button background color"
          />
        )}
      />
      <Controller
        name={`dashboardSections.${sectionId}.postFormStyles.buttonHoverBackgroundColor`}
        defaultValue={postFormStyles?.buttonHoverBackgroundColor}
        control={control}
        render={({value, onChange}) => (
          <ColorPicker
            label="Submit Button Background Hover Color"
            color={value}
            onPick={onChange}
            aria-label="submit button background hover color"
          />
        )}
      />
      <TextField
        name={`dashboardSections.${sectionId}.postFormStyles.buttonFontSize`}
        defaultValue={postFormStyles?.buttonFontSize}
        label="Submit Button Font Size"
        type="number"
        fullWidth
        inputProps={{
          min: 0,
          max: 50,
          ref: register,
        }}
      />
      <TextField
        name={`dashboardSections.${sectionId}.postFormStyles.buttonBorderWidth`}
        defaultValue={postFormStyles?.buttonBorderWidth}
        label="Submit Button Border Width"
        type="number"
        fullWidth
        inputProps={{
          min: 0,
          max: 50,
          ref: register,
        }}
      />
      <Controller
        name={`dashboardSections.${sectionId}.postFormStyles.buttonBorderColor`}
        defaultValue={postFormStyles?.buttonBorderColor}
        control={control}
        render={({value, onChange}) => (
          <ColorPicker
            label="Submit Button Border Color"
            color={value}
            onPick={onChange}
            aria-label="submit button border color"
          />
        )}
      />
      <Box mb={2}>
        <InputLabel>Submit Button Position</InputLabel>

        <Controller
          name={`dashboardSections.${sectionId}.postFormStyles.buttonPosition`}
          defaultValue={postFormStyles?.buttonPosition}
          control={control}
          render={({value, onChange}) => (
            <Select
              value={value}
              onChange={onUnknownChangeHandler(onChange)}
              fullWidth
            >
              <MenuItem value="flex-end">Right</MenuItem>
              <MenuItem value="center">Center</MenuItem>
              <MenuItem value="flex-start">Left</MenuItem>
            </Select>
          )}
        />
      </Box>
    </>
  )
}

const StyledEditPostFormStylesButton = styled(EditPostFormStylesButton)`
  margin-bottom: ${(props) => props.theme.spacing[3]}!important;
`

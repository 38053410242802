import {Panel} from 'organization/Event/DashboardConfig/ComponentConfigPanel/Panel'
import React from 'react'

/**
 * Contains all settings related fields. Just a wrapper component, but we must use
 * this exact function for ComponentConfig to figure out this is the settings
 * content.
 * @param props
 * @returns
 */
export default function SettingsPanel(props: {
  children: JSX.Element | JSX.Element[]
}) {
  return <Panel>{props.children}</Panel>
}

import React from 'react'
import {useTemplate} from 'Event/TemplateProvider'
import {components} from 'Event/template'

export default function ImageWaterfallConfig(props: {
  isVisible: boolean
  onClose: () => void
}) {
  const {name} = useTemplate()
  const Component = components[name].ImageWaterfall.ImageWaterfallConfig.index

  return <Component showing={props.isVisible} onClose={props.onClose} />
}

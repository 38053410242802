import {QuestionTypeConfigProps} from 'organization/Event/Form/dialog/Form/QuestionTypeConfig'
import React from 'react'
import {isCurrencyQuestion} from 'Event/Question/Currency'
import TextField from '@material-ui/core/TextField'

export function CurrencyConfig(props: QuestionTypeConfigProps) {
  const {question, register} = props

  return (
    <TextField
      label="Currency"
      fullWidth
      variant="outlined"
      name="start_adornment"
      defaultValue={
        isCurrencyQuestion(question) ? question.start_adornment : ''
      }
      inputProps={{
        ref: register,
        'aria-label': 'currency symbol',
      }}
    />
  )
}

import React from 'react'
import styled from 'styled-components'
import Grid, {GridSize} from '@material-ui/core/Grid'
import Body from 'Event/template/Cards/Sponsors/SponsorList/Card/Body'
import {Draggable} from 'react-beautiful-dnd'
import {useCardsTemplate} from 'Event/template/Cards'
import {useEditMode} from 'Event/EditModeProvider'
import SponsorForm from 'Event/Sponsors/SponsorForm'
import {useToggleArray} from 'lib/toggle'
import Buttons from 'Event/Sponsors/Buttons'
import Divider from 'Event/Sponsors/Divider'
import Logo from 'Event/Sponsors/Logo'
import {Sponsor} from 'Event/Sponsors'
import SponsorConfig, {SponsorConfigurable} from 'Event/Sponsors/SponsorConfig'
import {useAttendeeVariables} from 'Event'
import TextContent from 'lib/ui/form/TextEditor/Content'

type SponsorProps = {
  index: number
  id: string
  sponsor: Sponsor
  className?: string
  isLast: boolean
}

export default function Card(props: SponsorProps) {
  const {index, id} = props

  const isEditMode = useEditMode()
  if (!isEditMode) {
    return <Content {...props} />
  }

  return (
    <Draggable draggableId={id} index={index}>
      {(provided) => (
        <div
          ref={provided.innerRef}
          className={props.className}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <Content {...props} />
        </div>
      )}
    </Draggable>
  )
}

function Content(props: SponsorProps) {
  const {sponsor} = props
  const template = useCardsTemplate()
  const {linkColor, linkUnderline} = template
  const [showingForm, toggleForm] = useToggleArray()
  const imageSize = template.sponsors.imageSize
  const v = useAttendeeVariables()

  return (
    <>
      <Box
        aria-label="sponsor"
        className={props.className}
        linkColor={linkColor}
        linkUnderline={linkUnderline}
      >
        <SponsorConfigurable>
          <SponsorConfig id={props.id} sponsor={sponsor} />
          <>
            <DesktopContainer>
              <SponsorForm
                sponsor={sponsor}
                showing={showingForm}
                onClose={toggleForm}
              />
              <Left sponsor={sponsor} id={props.id} size={imageSize} />
              <StyledBody
                sponsor={sponsor}
                toggleForm={toggleForm}
                id={props.id}
              />
              <BottomButtons sponsor={sponsor} id={props.id} />
              <ClearContent />
            </DesktopContainer>
            <MobileContainer>
              <SponsorForm
                sponsor={sponsor}
                showing={showingForm}
                onClose={toggleForm}
              />
              <Body sponsor={sponsor} toggleForm={toggleForm} id={props.id} />
              <Left sponsor={sponsor} id={props.id} size={imageSize} />
              <TextContent>{v(sponsor.description)}</TextContent>
              <BottomButtons sponsor={sponsor} id={props.id} />
            </MobileContainer>
          </>
        </SponsorConfigurable>
      </Box>
      <Divider
        isLast={props.isLast}
        showingSeparator={template.sponsors.hasDivider}
        spacing={template.sponsors.spacing}
      />
    </>
  )
}

function Left(props: {sponsor: Sponsor; size: GridSize; id: string}) {
  const logo = props.sponsor.image

  if (!logo) {
    return null
  }

  return (
    <LeftContainer item xs={props.size}>
      <StyledImage sponsor={props.sponsor} />
      <Buttons sponsorId={props.id} />
    </LeftContainer>
  )
}

function BottomButtons(props: {sponsor: Sponsor; id: string}) {
  const logo = props.sponsor.image

  if (!logo) {
    return <Buttons sponsorId={props.id} />
  }

  return null
}

const DesktopContainer = styled.div`
  display: none;

  @media (min-width: ${(props) => props.theme.breakpoints.sm}) {
    display: initial;
  }
`

const MobileContainer = styled.div`
  display: initial;

  @media (min-width: ${(props) => props.theme.breakpoints.sm}) {
    display: none;
  }
`

const Box = styled.div<{
  linkColor: string
  linkUnderline: boolean
}>`
  position: relative;
  outline: none;

  a {
    color: ${(props) => props.linkColor};
    text-decoration: none;

    &:hover {
      text-decoration: ${(props) =>
        props.linkUnderline ? 'underline' : 'none'};
    }
  }
`

const StyledBody = styled(Body)`
  margin-left: ${(props) => props.theme.spacing[5]};
`

const LeftContainer = styled(Grid)`
  float: none;
  max-width: 100%;
  margin: 0;

  @media (min-width: ${(props) => props.theme.breakpoints.sm}) {
    float: left;
    width: 100%;
    max-width: 33%;
    margin-right: ${(props) => props.theme.spacing[4]};
  }
`

const StyledImage = styled(Logo)`
  margin-bottom: ${(props) => props.theme.spacing[4]};
`

const ClearContent = styled.div`
  clear: both;
`

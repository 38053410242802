import Container from 'Event/Marketplace/Block/Container'
import React from 'react'
import Content from 'lib/ui/form/TextEditor/Content'
import styled from 'styled-components'
import {BlockComponentProps} from 'Event/Marketplace/Block'
import {BlockBase} from '../base'

export interface TextBlock extends BlockBase {
  type: 'Text'
  text: string
}

export default function Text(props: TextBlock & BlockComponentProps) {
  const {text} = props
  return (
    <Container {...props}>
      <TextContent>{text}</TextContent>
    </Container>
  )
}

const TextContent = styled(Content)`
  width: 100%;
`

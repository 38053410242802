import React from 'react'
import Grid from '@material-ui/core/Grid'
import {Button} from 'Event/Dashboard/components/NavButton'
import {BlockComponentProps} from 'Event/Marketplace/Block'
import {PurchaseFormBlock} from 'Event/Marketplace/Block/PurchaseForm'

export interface PurchaseFormButtonProps {
  config: PurchaseFormBlock & BlockComponentProps
  text: string
  disabled?: boolean
  onClick?: () => void
  button: {
    text: string
    icon?: string | null
    iconAlign?: 'left' | 'right'
    image?: string
  }
  'aria-label'?: string
  type?: 'submit' | 'button' | 'reset'
}

export default function PurchaseFormButton(props: PurchaseFormButtonProps) {
  const {config, text, disabled, onClick, button, type} = props

  return (
    <Grid item xs={12} md={config.buttonStyle.size}>
      <Button
        {...button}
        {...config.buttonStyle}
        text={text}
        disabled={disabled}
        onClick={onClick}
        type={type}
      />
    </Grid>
  )
}

import React from 'react'
import {useForm} from 'react-hook-form'
import ComponentConfig, {
  ComponentConfigProps,
  SaveButton,
  Footer,
} from 'organization/Event/DashboardConfig/ComponentConfigPanel'
import {Panels, usePanelsUpdate} from 'Event/template/Panels'
import Styling from 'Event/template/Panels/Dashboard/LeftPanel/LeftPanelConfig/Styling'
import StylingPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/StylingPanel'

export default function LeftPanelConfig(props: ComponentConfigProps) {
  const {showing: isVisible, onClose} = props

  const update = usePanelsUpdate()
  const {control, handleSubmit, formState} = useForm()

  const submit = (data: Panels) => {
    update(data)

    onClose()
  }

  return (
    <ComponentConfig
      showing={isVisible}
      onClose={onClose}
      title="Left Panel Bar"
      onSubmit={handleSubmit(submit)}
      hasChanges={formState.isDirty}
    >
      <StylingPanel>
        <Styling control={control} />
      </StylingPanel>
      <Footer>
        <SaveButton type="submit" />
      </Footer>
    </ComponentConfig>
  )
}

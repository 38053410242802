import React from 'react'
import styled from 'styled-components'
import {ErrorMessage} from 'lib/ui/typography'

export default function ErrorDisplay(props: {children: string | undefined}) {
  if (!props.children) {
    return null
  }

  return <ErrorText color="error">{props.children}</ErrorText>
}

const ErrorText = styled(ErrorMessage)`
  margin-bottom: ${(props) => props.theme.spacing[3]};
`

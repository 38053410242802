import styled from 'styled-components'
import React from 'react'
import {Sponsor} from 'Event/Sponsors'

export default function Logo(props: {sponsor: Sponsor; className?: string}) {
  const {sponsor} = props
  const alt = sponsor.name

  if (!sponsor.image) {
    return null
  }

  return (
    <ImageBox className={props.className}>
      <img src={sponsor.image} alt={alt} aria-label="sponsor image" />
    </ImageBox>
  )
}

const ImageBox = styled.div`
  img {
    width: 100%;
  }
`

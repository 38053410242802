
import Command from '@ckeditor/ckeditor5-core/src/command';
import {
	DEFAULT_BUTTON_ALIGNMENT,
	DEFAULT_BUTTON_BACKGROUND_COLOR,
	DEFAULT_BUTTON_COLOR,
	DEFAULT_BUTTON_TEXT,
	DEFAULT_FONT_SIZE,
	insertCustomButton
} from '../utils';

export default class InsertCustomButtonCommand extends Command {
	refresh() {
		const model = this.editor.model;
		const selection = model.document.selection;
		const allowedIn = model.schema.findAllowedParent( selection.getFirstPosition(), 'customButton' );

		this.isEnabled = allowedIn !== null;
	}

	execute( options ) {
		const model = this.editor.model;

		this.editor.model.change( writer => {
			const attr = {
				text: DEFAULT_BUTTON_TEXT,
				float: DEFAULT_BUTTON_ALIGNMENT,
				buttonFontSize: DEFAULT_FONT_SIZE,
				buttonFontColor: DEFAULT_BUTTON_COLOR,
				buttonBackgroundColor: DEFAULT_BUTTON_BACKGROUND_COLOR,
				...options
			};

			insertCustomButton( writer, model, attr );
		} );
	}
}

import React from 'react'
import {Controller, UseFormMethods} from 'react-hook-form'
import {useCardsTemplate} from 'Event/template/Cards'
import ColorPicker from 'lib/ui/ColorPicker'

export default function Styling(props: {control: UseFormMethods['control']}) {
  const {control} = props
  const template = useCardsTemplate()

  return (
    <>
      <Controller
        name="rewardAlert.backgroundColor"
        control={control}
        defaultValue={template.rewardAlert.backgroundColor}
        render={({value, onChange}) => (
          <ColorPicker
            label="Popup Background Color"
            color={value}
            onPick={onChange}
          />
        )}
      />
      <Controller
        name="rewardAlert.textColor"
        control={control}
        defaultValue={template.rewardAlert.textColor}
        render={({value, onChange}) => (
          <ColorPicker label="Popup Color" color={value} onPick={onChange} />
        )}
      />
    </>
  )
}

import {ButtonBlock} from 'Event/Marketplace/Block/Button'

export const createButtonBlock = (): ButtonBlock => ({
  type: 'Button',
  visibility: 'all',
  background: {
    image: '',
    color: '',
    opacity: 0,
  },
  padding: {
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
  border: {
    width: 0,
    color: '#FFFFFF',
    radius: 0,
  },
  alignment: {
    vertical: 'center',
    horizontal: 'center',
  },
  text: 'My Button',
  textSecond: null,
  isVisible: true,
  size: 12,
  link: '',
  destination: 'ticket_selector',
})

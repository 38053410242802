import {Controller} from 'react-hook-form'
import {useTemplate} from 'Event/TemplateProvider'
import {TemplateSave, useSaveTemplate} from 'Event/TemplateUpdateProvider'
import Switch from 'lib/ui/form/Switch'
import Slider from 'lib/ui/Slider'
import {useConfig} from 'organization/Event/Configurable'
import Config from 'organization/Event/Configurable/Config'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import StylingPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/StylingPanel'
import React from 'react'
import {onChangeCheckedHandler} from 'lib/dom'
import ImageAssetUploader from 'lib/asset/ImageAssetUploader'
import Title from 'lib/ui/ConfigPanel/Title'
import InfoTooltip from 'lib/ui/ConfigPanel/InfoTooltip'
import {Template} from 'Event/template'
import {usePruneAssets} from 'lib/asset'

const MAX_LOGO_SIZE_PERCENT = 100
const MIN_LOGO_SIZE_PERCENT = 20

export default function LogoConfig() {
  const save = useSaveTemplate()
  const {login} = useTemplate()
  const pruneAssets = usePruneAssets()

  const {
    form: {control},
  } = useConfig()

  const handleSave: TemplateSave<Template> = (updates) => {
    // Kinda lame, but to keep TS happy we have to check that login is in fact
    // an object, and not '__REMOVE__' value.
    if (typeof updates.login === 'object') {
      pruneAssets(
        {
          logo: updates.login.logo,
        },
        login,
      )
    }

    save(updates)
  }

  return (
    <Config title="Logo Image" onSave={handleSave}>
      <SettingsPanel>
        <Title>
          Image{' '}
          <InfoTooltip>
            Will update Login, and Forgot Password pages.
          </InfoTooltip>
        </Title>
        <Controller
          name="login.logoEnabled"
          control={control}
          defaultValue={login.logoEnabled}
          render={({value, onChange}) => (
            <Switch
              checked={value}
              onChange={onChangeCheckedHandler(onChange)}
              arial-label="toggle logo visible"
              labelPlacement="end"
              label={value ? 'Logo Enabled' : 'Logo Disabled'}
            />
          )}
        />
        <Controller
          name="login.logo"
          control={control}
          defaultValue={login.logo}
          render={({onChange, value}) => (
            <ImageAssetUploader
              onChange={onChange}
              value={value}
              uploadLabel="Upload"
              canResize
              uploadInputProps={{
                'aria-label': 'logo upload',
              }}
              disableAutoRemove
            />
          )}
        />
      </SettingsPanel>
      <StylingPanel>
        <Controller
          name="login.logoSize"
          defaultValue={login.logoSize}
          control={control}
          render={({value, onChange}) => (
            <Slider
              valueLabelDisplay="auto"
              aria-label="logo weight"
              value={value}
              onChange={onChange}
              step={1}
              min={MIN_LOGO_SIZE_PERCENT}
              max={MAX_LOGO_SIZE_PERCENT}
              label="Logo Size"
            />
          )}
        />
      </StylingPanel>
    </Config>
  )
}

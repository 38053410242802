import {ObvioEvent} from 'Event'
import {PurchasePage} from 'Event/Marketplace/purchase-page'
import {marketplaceApi} from 'lib/marketplace-api/url'
import {client} from 'lib/ui/api-client'
import {useQuery} from 'react-query'

export function usePurchasePage(params: {event: ObvioEvent}) {
  const {event} = params

  return useQuery({
    queryKey: ['events', event.id, 'purchase_page'],
    queryFn: () =>
      client.get<PurchasePage | null>(
        marketplaceApi(`/events/${event.id}/purchase_page`),
      ),
  })
}

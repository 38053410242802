import React from 'react'
import styled, {useTheme} from 'styled-components'
import SettingsIcon from '@material-ui/icons/Settings'
import IconButton from 'lib/ui/IconButton'

const EditIconButton = React.forwardRef<
  HTMLButtonElement,
  {
    onClick?: (event: React.MouseEvent) => void
    className?: string
    color?: string
    ['aria-label']?: string
    type?: 'button' | 'submit'
  }
>((props, ref) => {
  const theme = useTheme()
  const color = props.color || theme.colors.primary
  const type = props.type || 'button'

  return (
    <IconButton
      className={props.className}
      onClick={props.onClick}
      aria-label={props['aria-label']}
      type={type}
      ref={ref}
    >
      <Box>
        <StyledSettingsIcon color={color} />
      </Box>
    </IconButton>
  )
})

export default EditIconButton

const Box = styled.div`
  background: #ffffff;
  display: inline-flex;
  border-radius: 4px;
`

const StyledSettingsIcon = styled((props: {color: string}) => {
  const {color: _, ...otherProps} = props

  return <SettingsIcon {...otherProps} />
})`
  color: ${(props) => props.color};
`

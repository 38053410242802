import {useUpdateTemplate} from 'organization/Marketplace/PurchasePageConfig/TemplateUpdateProvider'
import {useConfig} from 'organization/Event/Configurable'
import Config from 'organization/Event/Configurable/Config'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import StylingPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/StylingPanel'
import React, {useState} from 'react'
import {Controller} from 'react-hook-form'
import Slider from 'lib/ui/Slider'
import ImageAssetUploader from 'lib/asset/ImageAssetUploader'
import Select from 'lib/ui/Select'
import Option from 'lib/ui/Select/Option'
import ColorPicker from 'lib/ui/ColorPicker'
import {onUnknownChangeHandler} from 'lib/dom'
import CollapsibleSection from 'lib/ui/ConfigPanel/CollapsibleSection'
import {REMOVE} from 'Event/TemplateUpdateProvider'
import VisibilitySetting from 'organization/Marketplace/PurchasePageConfig/BlockConfig/VisibilitySettings'
import {Label} from 'lib/ui/typography'
import MarginPaddingInputs from 'lib/ui/MarginPaddingInputs'
import {ImageBlock} from 'Event/Marketplace/Block/Image'

interface ImageConfigProps extends ImageBlock {
  id: string
  sectionId: string
}

export default function ImageConfig(props: ImageConfigProps) {
  const {
    id,
    sectionId,
    image,
    size,
    sizeMobile,
    alignment,
    background,
    padding,
  } = props
  const [visibility, setVisibility] = useState(props.visibility)

  const updateTemplate = useUpdateTemplate()
  const {
    form: {control},
  } = useConfig()

  const save = (data: any) => {
    data.sections[sectionId].blocks[id].visibility = visibility
    updateTemplate(data)
  }

  const handleRemove = () => {
    updateTemplate({
      sections: {
        [sectionId]: {
          blocks: {
            [id]: REMOVE,
          },
        },
      },
    })
  }

  return (
    <Config title="Image Block" onSave={save} onRemove={handleRemove}>
      <SettingsPanel>
        <VisibilitySetting
          visibility={visibility}
          setVisibility={setVisibility}
        />
        <Controller
          name={`sections.${sectionId}.blocks.${id}.image`}
          control={control}
          defaultValue={image}
          render={({value, onChange}) => (
            <ImageAssetUploader
              onChange={onChange}
              value={value}
              label="Upload"
              uploadInputProps={{
                'aria-label': 'image',
              }}
              canResize
            />
          )}
        />
      </SettingsPanel>
      <StylingPanel>
        <CollapsibleSection
          label="Image Size"
          storageKey={`blocks-${id}-image-size`}
        >
          <Controller
            name={`sections.${sectionId}.blocks.${id}.size`}
            defaultValue={size}
            control={control}
            render={({value, onChange}) => (
              <Slider
                label="Size"
                min={10}
                max={100}
                step={1}
                unit="%"
                onChange={onChange}
                value={value}
              />
            )}
          />
          <Controller
            name={`sections.${sectionId}.blocks.${id}.sizeMobile`}
            defaultValue={sizeMobile}
            control={control}
            render={({value, onChange}) => (
              <Slider
                label="Mobile Size"
                min={10}
                max={100}
                step={1}
                unit="%"
                onChange={onChange}
                value={value}
              />
            )}
          />
        </CollapsibleSection>
        <CollapsibleSection
          label="Background"
          storageKey={`blocks-${id}-background`}
        >
          <Controller
            name={`sections.${sectionId}.blocks.${id}.background.image`}
            control={control}
            defaultValue={background.image}
            render={({onChange, value}) => (
              <ImageAssetUploader
                onChange={onChange}
                value={value}
                uploadLabel="Upload"
                uploadInputProps={{
                  'aria-label': 'block background image',
                }}
                width={1920}
                height={1200}
                canResize
                disableAutoRemove
              />
            )}
          />
          <Controller
            name={`sections.${sectionId}.blocks.${id}.background.color`}
            defaultValue={background.color}
            control={control}
            render={({value, onChange}) => (
              <ColorPicker
                label="Color"
                color={value}
                onPick={onChange}
                aria-label="block background color"
              />
            )}
          />
          <Controller
            name={`sections.${sectionId}.blocks.${id}.background.opacity`}
            defaultValue={background.opacity}
            control={control}
            render={({value, onChange}) => (
              <Slider
                label="Opacity"
                unit="%"
                valueLabelDisplay="auto"
                aria-label="block background opacity"
                value={value}
                onChange={onChange}
                step={1}
                min={0}
                max={100}
              />
            )}
          />
        </CollapsibleSection>
        <CollapsibleSection label="Border" storageKey={`blocks-${id}-border`}>
          <Controller
            name={`sections.${sectionId}.blocks.${id}.borderWidth`}
            defaultValue={props.borderWidth}
            control={control}
            render={({value, onChange}) => (
              <Slider
                min={0}
                max={50}
                step={1}
                onChange={onChange}
                value={value}
                label="Thickness"
              />
            )}
          />
          <Controller
            name={`sections.${sectionId}.blocks.${id}.borderRadius`}
            defaultValue={props.borderRadius}
            control={control}
            render={({value, onChange}) => (
              <Slider
                min={0}
                max={50}
                step={1}
                onChange={onChange}
                value={value}
                label="Radius"
              />
            )}
          />
          <Controller
            name={`sections.${sectionId}.blocks.${id}.borderColor`}
            defaultValue={props.borderColor}
            control={control}
            render={({value, onChange}) => (
              <ColorPicker label="Color" color={value} onPick={onChange} />
            )}
          />
        </CollapsibleSection>
        <CollapsibleSection
          label="Alignment"
          storageKey={`blocks-${id}-alignment`}
        >
          <Controller
            name={`sections.${sectionId}.blocks.${id}.alignment.vertical`}
            defaultValue={alignment.vertical}
            control={control}
            render={({value, onChange}) => (
              <Select
                fullWidth
                label="Vertical"
                value={value}
                onChange={onUnknownChangeHandler(onChange)}
              >
                <Option value="start">Top</Option>
                <Option value="center">Center</Option>
                <Option value="end">Bottom</Option>
              </Select>
            )}
          />
          <Controller
            name={`sections.${sectionId}.blocks.${id}.alignment.horizontal`}
            defaultValue={alignment.horizontal}
            control={control}
            render={({value, onChange}) => (
              <Select
                fullWidth
                label="Horizontal"
                value={value}
                onChange={onUnknownChangeHandler(onChange)}
              >
                <Option value="left">Left</Option>
                <Option value="center">Center</Option>
                <Option value="right">Right</Option>
              </Select>
            )}
          />
        </CollapsibleSection>
        <CollapsibleSection
          label="Advanced Spacing"
          storageKey={`blocks-${id}-spacing`}
        >
          <Label>Padding</Label>
          <MarginPaddingInputs
            control={control}
            namePrefix={`sections.${sectionId}.blocks.${id}.padding`}
            values={padding}
          />
        </CollapsibleSection>
      </StylingPanel>
    </Config>
  )
}

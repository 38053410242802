import ForgotPassword from 'Event/auth/ForgotPassword'
import {DesignBreadcrumbs} from 'organization/Event/Page/PageBreadcrumbs'
import Page from 'organization/Event/TemplateEditor/Page'
import React from 'react'

export default function ForgotPasswordConfig() {
  return (
    <DesignBreadcrumbs page="Forgot Password">
      <Page>
        <ForgotPassword />
      </Page>
    </DesignBreadcrumbs>
  )
}

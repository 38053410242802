import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import {useEvent} from 'Event/EventProvider'
import Question from 'Event/Question'
import Dialog from 'lib/ui/Dialog'
import {Form} from 'organization/Event/FormsProvider'
import React from 'react'
import Box from '@material-ui/core/Box'
import styled from 'styled-components'
import {useAttendeeVariables} from 'Event'
import {Button, NavButtonProps} from 'Event/Dashboard/components/NavButton'
import MuiButton from '@material-ui/core/Button'
import {useTemplate} from 'Event/TemplateProvider'
import {useEditMode} from 'Event/EditModeProvider'
import {useSubmitForm} from 'Event/form'

export default function FormButton(props: NavButtonProps) {
  const {event} = useEvent()
  const isEditMode = useEditMode()

  // If we can't find the referenced form, instead of crashing the app, let's
  // just render a button that doesn't do anything...
  const form = event.forms.find((f) => f.id === props.formId)
  if (!form) {
    return <Button {...props} />
  }

  // If we're editing, we do NOT want to open the form...
  if (isEditMode) {
    return <Button {...props} />
  }

  return <ButtonWithDialog form={form} button={props} />
}

function ButtonWithDialog(props: {form: Form; button: NavButtonProps}) {
  const {form} = props

  const {
    openDialog,
    dialogVisible,
    alreadySubmitted,
    submit,
    answers,
    responseError,
    submitting,
    closeDialog,
    register,
    handleSubmit,
    errors: formErrors,
    control,
    watch,
    setValue,
  } = useSubmitForm(form, {showInDialog: true})
  const v = useAttendeeVariables()
  const {postFormStyles: formStyles} = useTemplate()

  const submittedMessage = <p>{v(props.form.submitted_message)}</p>

  const formContent = (
    <Container justifyContent={formStyles.position}>
      <StyledForm onSubmit={handleSubmit(submit)} width={formStyles.width}>
        {form.questions.map((question, index) => (
          <Question
            formErrors={formErrors}
            key={question.id}
            index={index}
            control={control}
            question={question}
            answers={answers}
            register={register}
            watch={watch}
            responseError={responseError}
            setValue={setValue}
            disabled={submitting}
            inputStyles={formStyles.inputStyles}
          />
        ))}
        <Container justifyContent={formStyles.buttonPosition}>
          <StyledButtonn
            type="submit"
            variant="outlined"
            disabled={submitting}
            color={formStyles.buttonColor}
            backgroundColor={formStyles.buttonBackgroundColor}
            backgroundHoverColor={formStyles.buttonHoverBackgroundColor}
            radius={formStyles.buttonRadius}
            width={formStyles.buttonSize}
          >
            {v(form.submit_label)}
          </StyledButtonn>
        </Container>
      </StyledForm>
    </Container>
  )

  const showingSubmittedMessage = alreadySubmitted && !form.can_resubmit
  const content = showingSubmittedMessage ? submittedMessage : formContent

  // To prevent dialog content flash, we'll just NOT return the dialog
  // at all when it's hidden.
  if (!dialogVisible) {
    return <Button {...props.button} onClick={openDialog} />
  }

  return (
    <>
      <Dialog open onClose={closeDialog}>
        <DialogTitle>{form.name}</DialogTitle>
        <DialogContent>
          <Box pb={2}>{content}</Box>
        </DialogContent>
      </Dialog>
      <Button {...props.button} onClick={openDialog} />
    </>
  )
}

const StyledForm = styled.form<{
  width: number
}>`
  width: ${(props) => props.width}%;
`

const StyledButtonn = styled((props) => {
  const {
    color: _1,
    backgroundColor: _2,
    backgroundHoverColor: _3,
    raidus: _4,
    fontSize: _5,
    width: _6,
    borderWidth: _7,
    borderColor: _8,
    ...otherProps
  } = props
  return <MuiButton {...otherProps} />
})<{
  color: string
  backgroundColor: string
  backgroundHoverColor: string
  radius: string
  fontSize: number
  width: number
  borderWidth: number
  borderColor: string
}>`
  color: ${(props) => props.color} !important;
  cursor: pointer;
  font-size: ${(props) => props.fontSize}px !important;
  border-radius: ${(props) => props.raidus}px !important;
  background-color: ${(props) => props.backgroundColor} !important;
  width: ${(props) => props.width}% !important;
  border-width: ${(props) => props.borderWidth}px !important;
  border-color: ${(props) => props.borderColor} !important;
  &:hover {
    background-color: ${(props) => props.backgroundHoverColor} !important;
  }
`

const Container = styled.div<{
  justifyContent: string
}>`
  display: flex;
  justify-content: ${(props) => props.justifyContent};
`

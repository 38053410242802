import styled from 'styled-components'
import React from 'react'
import NavButton, {NavButtonProps} from 'Event/Dashboard/components/NavButton'
import SpeakerButtonConfig from 'Event/template/Lefty/Speakers/SpeakerButton/SpeakerButtonConfig'
import {useToggle} from 'lib/toggle'
import {useLoadFont} from 'lib/FontSelect'
import {REMOVE, useSaveTemplate} from 'Event/TemplateUpdateProvider'
import {EditComponentOverlay} from 'Event/Dashboard/editor/views/EditComponent'

export default function Button(props: {
  button: NavButtonProps
  speakerId: string
}) {
  const {button} = props
  useLoadFont(button.font)

  return <StyledNavButton {...button} aria-label="speaker button" />
}

export function EditableButton(props: {
  button: NavButtonProps
  speakerId: string
}) {
  const updateTemplate = useSaveTemplate()
  const {button, speakerId} = props
  useLoadFont(button.font)
  const {flag: configVisible, toggle: toggleConfig} = useToggle()

  const edit = () => {
    toggleConfig()
  }

  const updateButton = (button: NavButtonProps) => {
    updateTemplate({
      featuredSpeakers: {
        items: {
          [speakerId]: {
            button,
          },
        },
      },
    })
  }

  const removeButton = () => {
    updateTemplate({
      featuredSpeakers: {
        items: {
          [speakerId]: {
            button: REMOVE,
          },
        },
      },
    })
  }

  return (
    <>
      <SpeakerButtonConfig
        button={button}
        onClose={toggleConfig}
        showing={configVisible}
        onChange={updateButton}
        onRemove={removeButton}
      />
      <EditComponentOverlay onClick={edit} aria-label="edit speaker button">
        <StyledNavButton
          onClick={edit}
          aria-label="speaker button"
          {...button}
        />
      </EditComponentOverlay>
    </>
  )
}

const StyledNavButton = styled(NavButton)`
  margin-right: auto;
`

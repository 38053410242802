import Menu from 'lib/ui/Menu'
import React from 'react'
import {ReactComponent as ProfileIconGrey} from 'lib/ui/AppBar/icons/profile-grey.svg'
import {useObvioAuth} from 'obvio/auth'
import AccountMenuItem from 'lib/ui/AppBar/AccountMenu/AccountMenuItem'
import {useHistory} from 'react-router'
import {obvioRoutes} from 'obvio/Routes'
import UserMenuItem from 'lib/ui/AppBar/AccountMenu/UserMenuItem'
import MenuItem from '@material-ui/core/MenuItem'
import ShareObvioMenuItem from 'lib/ui/AppBar/AccountMenu/ShareObvioMenuItem'
import UserFeatureToggle from 'obvio/UserFeatureToggle'
import {PROMOTER} from 'auth/user/flags'
import IconButton from 'lib/ui/IconButton'

export default function AccountMenu(props: {
  children: JSX.Element | JSX.Element[]
}) {
  const {logout} = useObvioAuth()
  const history = useHistory()

  const handleLogout = () => {
    logout()
    history.push(obvioRoutes.login)
  }

  return (
    <Menu
      button={({open}) => (
        <IconButton
          aria-label="account menu"
          variant="text"
          color="dark"
          onClick={open}
        >
          <ProfileIconGrey />
        </IconButton>
      )}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
    >
      <UserFeatureToggle flags={PROMOTER}>
        <ShareObvioMenuItem />
      </UserFeatureToggle>
      <UserMenuItem />
      {props.children}
      <AccountMenuItem />
      <MenuItem aria-label="logout" onClick={handleLogout}>
        Logout
      </MenuItem>
    </Menu>
  )
}

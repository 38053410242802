import Container from '@material-ui/core/Container'
import {User} from 'auth/user'
import React from 'react'
import CheckInPage from 'Event/template/Lefty/check-in/Page'
import StepIndicator from 'Event/template/Lefty/check-in/StepIndicator'
import Waivers from 'Event/Step2/Waivers'

export default function Step2(props: {user: User}) {
  return (
    <CheckInPage user={props.user}>
      <Container maxWidth="md">
        <StepIndicator step={2} />
        <Waivers user={props.user} />
      </Container>
    </CheckInPage>
  )
}

import React, {useContext} from 'react'
import {useToggleArray} from 'lib/toggle'
import AddBlockButton from 'organization/Marketplace/PurchasePageConfig/AddBlockButton'
import {Layout, Layouts} from 'react-grid-layout'
import {useUpdateTemplate} from 'organization/Marketplace/PurchasePageConfig/TemplateUpdateProvider'
import AddBlockDialog from 'organization/Marketplace/PurchasePageConfig/AddBlockDialog'
import ConfigurableBlock, {
  BLOCK_DRAG_HANDLE,
} from 'organization/Marketplace/PurchasePageConfig/ConfigurableBlock'
import isEqual from 'lodash/isEqual'
import Section from 'Event/Marketplace/Section'
import {grey} from '@material-ui/core/colors'
import styled from 'styled-components'
import {useEditMode} from 'Event/EditModeProvider'
import {usePurchasePageConfig} from 'organization/Marketplace/PurchasePageConfig/PurchasePageConfigProvider'
import {resizeLayoutVisibility} from 'organization/Marketplace/PurchasePageConfig/resize-layout-visibility'
import {BlockBase} from 'Event/Marketplace/Block/base'
import {
  PurchasePageSection,
  PurchasePageTemplate,
} from 'Event/Marketplace/purchase-page'

interface ConfigurableSectionProps {
  section: PurchasePageSection
  id: string
  template: PurchasePageTemplate
}

interface ConfigurableSectionContextProps {
  sectionId: string
}

const ConfigurableSectionContext = React.createContext<
  undefined | ConfigurableSectionContextProps
>(undefined)

export default function ConfigurableSection(props: ConfigurableSectionProps) {
  const {section, id: sectionId, template} = props
  const [showingAddBlockDialog, toggleAddBlockDialog] = useToggleArray()
  const update = useUpdateTemplate()
  const isEditMode = useEditMode()
  const {isMobileMode} = usePurchasePageConfig()

  const handleLayoutChange = (newLayouts: Layouts) => {
    const layoutWithVisibilitySizes = resizeLayoutVisibility({
      layouts: newLayouts,
      blocks: section.blocks,
    })

    if (isEqual(layoutWithVisibilitySizes, section.layouts)) {
      return
    }

    update({
      sections: {
        [sectionId]: {layouts: newLayouts},
      },
    })
  }

  return (
    <ConfigurableSectionContext.Provider value={{sectionId}}>
      <Box
        hasBorder={isEditMode}
        isMobileMode={isMobileMode}
        visibility={section.visibility}
      >
        <AddBlockDialog
          open={showingAddBlockDialog}
          onClose={toggleAddBlockDialog}
          sectionId={sectionId}
          section={section}
        />
        <Section
          section={section}
          disableItemResize
          ResponsiveReactGridLayoutProps={{
            isDraggable: true,
            isResizable: true,
            draggableHandle: `.${BLOCK_DRAG_HANDLE}`,
            onLayoutChange: (
              _currentLayout: Layout[],
              allBreakpointLayouts: Layouts,
            ) => {
              handleLayoutChange(allBreakpointLayouts)
            },
          }}
          isMobileMode={isMobileMode}
          template={template}
          minTopPadding={24}
        >
          {Object.entries(section.blocks).map(([id, block]) => (
            <ConfigurableBlock
              block={block}
              key={id}
              id={id}
              template={template}
            />
          ))}
        </Section>
        <AddBlockButton onClick={toggleAddBlockDialog} />
      </Box>
    </ConfigurableSectionContext.Provider>
  )
}

export function useConfigurableSection() {
  const context = useContext(ConfigurableSectionContext)
  if (!context) {
    throw new Error(
      'useConfigurableSection must be used within ConfigurableSection',
    )
  }

  return context
}

const Box = styled.div<{
  hasBorder: boolean
  visibility: BlockBase['visibility']
  isMobileMode: boolean
}>`
  border-bottom: ${(props) =>
    props.hasBorder ? `2px dashed ${grey[600]}` : 'none'};

  display: ${(props) =>
    props.visibility === 'desktop_only' ? 'none' : 'block'};
  @media (min-width: ${(props) => props.theme.breakpoints.lg}) {
    display: ${(props) =>
      (props.visibility === 'mobile_only' && !props.isMobileMode) ||
      (props.visibility === 'desktop_only' && props.isMobileMode)
        ? 'none'
        : 'block'};
  }
`

import styled from 'styled-components'
import React from 'react'

export type HelperTextProps = {
  children?: string | JSX.Element
  error?: boolean
  disabled?: boolean
  className?: string
}

export default function HelperText(props: HelperTextProps) {
  const {children, className} = props
  if (!children) {
    return null
  }

  return (
    <Box disabled={props.disabled} className={className}>
      <Content {...props} />
    </Box>
  )
}

function Content(props: HelperTextProps) {
  if (props.error) {
    return <ErrorText>{props.children}</ErrorText>
  }

  if (!props.children) {
    return null
  }

  if (typeof props.children === 'string') {
    return <span>{props.children}</span>
  }

  return props.children
}

const Box = styled.div<{
  disabled?: boolean
}>`
  margin-top: ${(props) => props.theme.spacing[3]};
  color: ${(props) =>
    props.disabled
      ? props.theme.colors.disabled
      : props.theme.colors.text.info};
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
`

export const ErrorText = styled.span`
  color: ${(props) => props.theme.colors.error};
`

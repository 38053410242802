import React from 'react'
import MuiCardContent, {
  CardContentProps as MuiCardContentProps,
} from '@material-ui/core/CardContent'
import {makeStyles} from '@material-ui/styles'
import {spacing} from 'lib/ui/theme'
import grey from '@material-ui/core/colors/grey'

type CardContentProps = MuiCardContentProps

export default function CardContent(props: CardContentProps) {
  const useStyles = makeStyles({
    root: {
      paddingTop: 0,
      paddingBottom: spacing[2],
      marginBottom: spacing[3],
      borderBottom: `1px solid ${grey[300]};`,
    },
  })
  const classes = useStyles()

  return <MuiCardContent {...props} classes={classes} />
}

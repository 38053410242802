import {useTemplate} from 'Event/TemplateProvider'
import {useSaveTemplate} from 'Event/TemplateUpdateProvider'
import Config from 'organization/Event/Configurable/Config'
import RulesPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/RulesPanel'
import React, {useState} from 'react'

export default function RulesConfig() {
  const {techCheck} = useTemplate()
  const saveTemplate = useSaveTemplate()

  const [rules, setRules] = useState(techCheck.skipRules)

  const save = () => {
    saveTemplate({
      techCheck: {
        skipRules: rules,
      },
    })
  }

  return (
    <Config title="Skip Tech Check" onSave={save}>
      <RulesPanel
        rules={rules}
        setRules={setRules}
        configProps={{
          description: 'Tech Check will be skipped when',
        }}
      />
    </Config>
  )
}

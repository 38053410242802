import React from 'react'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import {useArea} from 'organization/Event/Area/AreaProvider'
import Dialog from 'lib/ui/Dialog'
import styled from 'styled-components'
import {Controller, useForm} from 'react-hook-form'
import {Area} from 'organization/Event/AreasProvider'
import Box from '@material-ui/core/Box'
import InputLabel from '@material-ui/core/InputLabel'
import TextEditor, {TextEditorContainer} from 'lib/ui/form/TextEditor'
import {
  EMAIL,
  EVENT_NAME,
  FIRST_NAME,
  LAST_NAME,
  TIME,
  TODAY,
} from 'lib/ui/form/TextEditor/variables'
import {defaultToolbar} from 'lib/ui/form/TextEditor/toolbar'

interface ConfigProps {
  isVisible: boolean
  onClose: () => void
}

export default function OfflineTextConfig(props: ConfigProps) {
  const {isVisible, onClose} = props
  const {area, update, processing} = useArea()
  const {handleSubmit, register, control} = useForm()

  const save = (data: Pick<Area, 'offline_title' | 'offline_description'>) => {
    update(data)
    onClose()
  }

  return (
    <Dialog
      open={isVisible}
      onClose={onClose}
      maxWidth={'lg'}
      disableEnforceFocus
      fullWidth
    >
      <FormContainer>
        <form onSubmit={handleSubmit(save)}>
          <TextField
            defaultValue={area.offline_title}
            name="offline_title"
            fullWidth
            variant="outlined"
            label="Offline Title"
            disabled={processing}
            inputProps={{
              ref: register,
              'aria-label': 'offline title',
            }}
          />
          <Box mb={1}>
            <InputLabel shrink={true}>Offline Description</InputLabel>
          </Box>
          <TextEditorContainer>
            <Controller
              name="offline_description"
              defaultValue={area.offline_description}
              control={control}
              render={({value, onChange}) => (
                <TextEditor
                  data={value}
                  onChange={onChange}
                  aria-label="offline description"
                  toolbar={[...defaultToolbar, 'obvioVariable']}
                  variables={[
                    FIRST_NAME,
                    LAST_NAME,
                    EMAIL,
                    EVENT_NAME,
                    TODAY,
                    TIME,
                  ]}
                />
              )}
            />
          </TextEditorContainer>
          <StyledSaveButton
            fullWidth
            variant="contained"
            color="primary"
            type="submit"
            aria-label="save offline text"
          >
            Save
          </StyledSaveButton>
        </form>
      </FormContainer>
    </Dialog>
  )
}

const FormContainer = styled.div`
  padding: 50px;
  padding-bottom: 40px;
`

const StyledSaveButton = styled(Button)`
  margin-top: ${(props) => props.theme.spacing[4]}!important;
  margin-bottom: ${(props) => props.theme.spacing[2]}!important;
`

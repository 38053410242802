import React from 'react'
import Content from 'lib/ui/form/TextEditor/Content'
import grey from '@material-ui/core/colors/grey'
import styled from 'styled-components'
import {useWaiver} from 'Event/Step2/Waivers/Waiver'
import {useAttendeeVariables} from 'Event'
import Configurable from 'organization/Event/Configurable'
import BodyConfig from 'Event/Step2/Waivers/Waiver/Body/BodyConfig'

export default function Body() {
  const v = useAttendeeVariables()
  const {waiver} = useWaiver()

  return (
    <Configurable>
      <BodyConfig />
      <BodyContent>{v(waiver.body)}</BodyContent>
    </Configurable>
  )
}

export const BodyContent = styled(Content)`
  max-height: 240px;
  overflow-y: auto;
  border: 1px solid ${grey[300]};
  padding: 0 ${(props) => props.theme.spacing[4]};
  margin-bottom: ${(props) => props.theme.spacing[4]};
`

import {ObvioEvent} from 'Event'
import {useEvents} from 'lib/event-api/organization/use-events'
import {PurchasePage} from 'Event/Marketplace/purchase-page'
import {marketplaceApi} from 'lib/marketplace-api/url'
import {client} from 'lib/ui/api-client'
import {useOrganization} from 'organization/OrganizationProvider'
import {useQuery} from 'react-query'

export function usePurchasePageEvents() {
  const {organization} = useOrganization()
  const {data: events} = useEvents()

  return useQuery({
    queryKey: ['organizations', organization.id, 'purchase_page_events'],
    queryFn: () =>
      new Promise<ObvioEvent[]>(async (resolve) => {
        if (!events) {
          resolve([])
          return
        }

        const result: ObvioEvent[] = []

        for (const event of events) {
          const purchasePage = await client.get<PurchasePage | null>(
            marketplaceApi(`/events/${event.id}/purchase_page`),
          )

          const hasSections = Boolean(purchasePage?.template?.sections)

          if (hasSections) {
            result.push(event)
          }
        }

        resolve(result)
      }),
    enabled: !!events,
  })
}

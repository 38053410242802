import React from 'react'
import ColorPicker from 'lib/ui/ColorPicker'
import Slider from 'lib/ui/Slider'
import {Controller, UseFormMethods} from 'react-hook-form'
import {usePanelsTemplate} from 'Event/template/Panels'

export default function Styling(props: {control: UseFormMethods['control']}) {
  const {control} = props
  const template = usePanelsTemplate()
  const {resourceList: list, textColor} = template
  const resourceTextColor = list.color ? list.color : textColor

  return (
    <>
      <Controller
        name="color"
        control={control}
        defaultValue={resourceTextColor}
        render={({value, onChange}) => (
          <ColorPicker
            label="Text Color"
            color={value}
            onPick={onChange}
            aria-label="resource card color"
          />
        )}
      />
      <Controller
        name="linkColor"
        control={control}
        defaultValue={list.linkColor}
        rules={{
          required: 'Link color is required',
        }}
        render={({value, onChange}) => (
          <ColorPicker
            label="Link Color"
            color={value}
            onPick={onChange}
            aria-label="resource card link color"
          />
        )}
      />

      <Controller
        name="cardBackgroundColor"
        control={control}
        defaultValue={list.cardBackgroundColor}
        rules={{
          required: 'Card background color is required',
        }}
        render={({value, onChange}) => (
          <ColorPicker
            label="Card Background Color"
            color={value}
            onPick={onChange}
            aria-label="resource card background color"
          />
        )}
      />

      <Controller
        name="cardBackgroundOpacity"
        control={control}
        defaultValue={list.cardBackgroundOpacity}
        rules={{
          required: 'Card background opacity is required',
        }}
        render={({value, onChange}) => (
          <Slider
            min={0}
            max={100}
            step={1}
            onChange={onChange}
            valueLabelDisplay="auto"
            value={value}
            label="Card Background Opacity"
            unit="%"
          />
        )}
      />
    </>
  )
}

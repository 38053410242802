import React from 'react'
import {useForm} from 'react-hook-form'
import {usePanelsUpdate, Panels} from 'Event/template/Panels'
import ComponentConfig, {
  ComponentConfigProps,
  SaveButton,
  Footer,
} from 'organization/Event/DashboardConfig/ComponentConfigPanel'
import Settings from 'Event/template/Panels/Dashboard/Home/WelcomeTextConfig/Settings'
import Styling from 'Event/template/Panels/Dashboard/Home/WelcomeTextConfig/Styling'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import StylingPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/StylingPanel'
import {useAutoUpdate} from 'organization/Event/TemplateEditor'

export default function WelcomeTextConfig(props: ComponentConfigProps) {
  const {showing, onClose} = props
  const {control, register, watch, handleSubmit, formState} = useForm()
  const update = usePanelsUpdate()

  const save = (
    data: Pick<
      Panels,
      | 'welcomeText'
      | 'homeMenuTitle'
      | 'welcomeTextFont'
      | 'welcomeTextColor'
      | 'welcomeTextFontSize'
    >,
  ) => {
    update(data)
    onClose()
  }

  useAutoUpdate({values: watch(), when: showing})

  return (
    <ComponentConfig
      showing={showing}
      onClose={onClose}
      title="Welcome Text"
      onSubmit={handleSubmit(save)}
      hasChanges={formState.isDirty}
    >
      <SettingsPanel>
        <Settings register={register} />
      </SettingsPanel>
      <StylingPanel>
        <Styling control={control} />
      </StylingPanel>
      <Footer>
        <SaveButton type="submit" aria-label="save welcome text" />
      </Footer>
    </ComponentConfig>
  )
}

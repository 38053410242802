import {ButtonProps} from '@material-ui/core/Button'
import React from 'react'
import ConfirmDialog from 'lib/ui/ConfirmDialog'
import DangerButton from 'lib/ui/Button/DangerButton'

export default function ConfirmRemoveButton(
  props: ButtonProps & {
    confirmButtonLabel?: string
    confirmDescription?: string
  },
) {
  const {
    confirmButtonLabel,
    confirmDescription,
    onClick,
    children,
    ...buttonProps
  } = props

  return (
    <ConfirmDialog
      onConfirm={(event) => onClick && event && onClick(event)}
      confirmLabel={confirmButtonLabel}
      description={
        confirmDescription || 'Would you like to proceed with removing this?'
      }
    >
      {(confirm: (event: React.MouseEvent<HTMLButtonElement>) => void) => (
        <DangerButton {...buttonProps} onClick={confirm}>
          {children}
        </DangerButton>
      )}
    </ConfirmDialog>
  )
}

import React from 'react'
import styled, {useTheme} from 'styled-components'
import {ControlLabel, Header, SubHead} from 'lib/ui/typography'
import {Row} from 'Event/Statistics/Summary'

export default function MetricCard(props: {
  title: string
  titleMobile?: string
  value: number | string
  total?: number
  icon?: React.ReactElement
  'aria-label'?: string
}) {
  const {icon, title, titleMobile, total, value} = props

  return (
    <div>
      <DetailCard>
        <StyledTitle
          title={title}
          titleMobile={titleMobile}
          aria-label={`${props['aria-label']} title`}
        />
        <Row>
          <MetricIcon icon={icon} />
          <Header color="white" aria-label={`${props['aria-label']} value`}>
            {value}
          </Header>
        </Row>
      </DetailCard>
      <ProgressBar value={value} total={total} />
    </div>
  )
}

function MetricIcon(props: {icon?: React.ReactElement}) {
  const {icon} = props

  if (!icon) {
    return null
  }

  return icon
}

function ProgressBar(props: {value: number | string; total?: number}) {
  const {value, total} = props
  const theme = useTheme()

  if (!total || typeof value === 'string') {
    return null
  }

  const percentMath = Math.round((value / total) * 100)
  const percent = isNaN(percentMath) ? 0 : percentMath

  const color = percent === 100 ? 'white' : theme.colors.primary

  return (
    <ProgressContainer>
      <ProgressContent width={percent} />
      <ProgressLabel color={color}>{`${percent}%`}</ProgressLabel>
    </ProgressContainer>
  )
}

const StyledTitle = (props: {
  title: string
  titleMobile?: string
  'aria-label'?: string
}) => {
  const {title, titleMobile} = props

  if (!titleMobile) {
    return (
      <StyledSubHead color="white" aria-label={`${props['aria-label']} title`}>
        {title}
      </StyledSubHead>
    )
  }

  return (
    <>
      <SubHeadLarge color="white" aria-label={`${props['aria-label']} title`}>
        {title}
      </SubHeadLarge>
      <SubHeadMobile color="white" aria-label={`${props['aria-label']} title`}>
        {titleMobile}
      </SubHeadMobile>
    </>
  )
}

const StyledSubHead = styled(SubHead)`
  text-align: center;
`

const SubHeadLarge = styled(SubHead)`
  text-align: center;
  display: none;

  @media (min-width: ${(props) => props.theme.breakpoints.lg}) {
    display: block;
  }
`
const SubHeadMobile = styled(SubHead)`
  text-align: center;
  display: none;

  @media (max-width: ${(props) => props.theme.breakpoints.lg}) {
    display: block;
  }
`

const DetailCard = styled.div`
  background: #1f4687;
  width: 100%;
  border-radius: ${(props) => props.theme.spacing[1]};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing[2]};
  height: ${(props) => props.theme.spacing[36]};
`

const ProgressContainer = styled.div`
  width: 100%;
  height: ${(props) => props.theme.spacing[6]};
  background-color: #f2f5f9;
  border-bottom-left-radius: ${(props) => props.theme.spacing[1]};
  border-bottom-right-radius: ${(props) => props.theme.spacing[1]};
  position: relative;
`

type ProgressContentProps = {
  width: number
}

const ProgressContent = styled.div<ProgressContentProps>`
  width: ${(props) => `${props.width}%`};
  height: 100%;
  background-color: #3490dc;
  border-bottom-left-radius: ${(props) => props.theme.spacing[1]};
  border-bottom-right-radius: ${(props) =>
    props.width === 100 ? props.theme.spacing[1] : 0};
`

type ProgressLabelProps = {
  color: string
}

const ProgressLabel = styled(ControlLabel)<ProgressLabelProps>`
  color: ${(props) => props.color};
  position: absolute;
  right: ${(props) => props.theme.spacing[2]};
  top: 2px;
`

import React from 'react'
import {Controller} from 'react-hook-form'
import {Label} from 'lib/ui/typography'
import TextField from 'lib/ui/TextField'
import {useTownhallTemplate} from 'Event/template/Townhall'
import TextEditor, {TextEditorContainer} from 'lib/ui/form/TextEditor'
import {useConfig} from 'organization/Event/Configurable'

export default function Settings() {
  const {
    form: {control, register},
  } = useConfig()
  const {hero} = useTownhallTemplate()

  return (
    <>
      <Label>Text</Label>
      <TextField
        name="hero.headline"
        placeholder="Text"
        defaultValue={hero.headline}
        aria-label="dashboard welcome text"
        inputProps={{
          ref: register,
        }}
        fullWidth
      />
      <Label>Description</Label>
      <TextEditorContainer>
        <Controller
          name="hero.body"
          defaultValue={hero.body}
          control={control}
          render={({value, onChange}) => (
            <TextEditor data={value} onChange={onChange} />
          )}
        />
      </TextEditorContainer>
    </>
  )
}

import React from 'react'
import {UseFormMethods} from 'react-hook-form'
import {PointsSummaryProps} from 'Event/template/Cards/Dashboard/Sidebar/SidebarItem/PointsSummary'
import OffsetInputs from 'Event/template/Cards/Dashboard/Sidebar/SidebarItem/OffsetInputs'

export default function Styling(props: {
  control: UseFormMethods['control']
  points: PointsSummaryProps
}) {
  return (
    <OffsetInputs
      control={props.control}
      offsetTop={props.points.offsetTop}
      offsetBottom={props.points.offsetBottom}
    />
  )
}

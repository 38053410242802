import {Room} from 'Event/room'
import React from 'react'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableBody from '@material-ui/core/TableBody'
import OnlineSwitch from 'organization/Event/Room/OnlineSwitch'
import {StaticRoomProvider, useRoom} from 'organization/Event/Room/RoomProvider'
import {RelativeLink} from 'lib/ui/link/RelativeLink'
import {useRoomRoutes} from 'organization/Event/Room/RoomRoutes'
import {IfCanStartRooms} from 'organization/PermissionsProvider'
import StartButton from 'organization/Event/Room/StartButton'
import {startLabel} from 'organization/Event/Room'

export default function RoomList(props: {rooms: Room[]}) {
  const {rooms} = props
  const isEmpty = rooms.length === 0

  if (isEmpty) {
    return (
      <div>
        <p>No rooms have been created</p>
      </div>
    )
  }

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>#</TableCell>
          <TableCell>Internal Description</TableCell>
          <TableCell>Num Attendees</TableCell>
          <TableCell>Open Rooms</TableCell>
          <IfCanStartRooms>
            <TableCell>Start/Join</TableCell>
          </IfCanStartRooms>
        </TableRow>
      </TableHead>
      <TableBody>
        {rooms.map((room) => (
          <StaticRoomProvider room={room} key={room.id}>
            <TableRow key={room.id} aria-label="room">
              <TableCell>
                <RoomLink />
              </TableCell>
              <TableCell>{room.description}</TableCell>
              <TableCell>{room.attendee_count || '-'}</TableCell>
              <TableCell>
                <OnlineSwitch />
              </TableCell>
              <IfCanStartRooms>
                <TableCell>
                  <StartButton>{startLabel(room.attendee_count)}</StartButton>
                </TableCell>
              </IfCanStartRooms>
            </TableRow>
          </StaticRoomProvider>
        ))}
      </TableBody>
    </Table>
  )
}

function RoomLink() {
  const {room} = useRoom()
  const routes = useRoomRoutes(room)
  const label = `view ${room.number} room`

  return (
    <RelativeLink to={routes.root} aria-label={label}>
      {room.number}
    </RelativeLink>
  )
}

import React from 'react'
import Grid from '@material-ui/core/Grid'
import Skeleton from '@material-ui/lab/Skeleton'

export default function LoadingGrid() {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={4}>
        <Skeleton variant="rect" height={180} />
      </Grid>
      <Grid item xs={12} md={4}>
        <Skeleton variant="rect" height={180} />
      </Grid>
      <Grid item xs={12} md={4}>
        <Skeleton variant="rect" height={180} />
      </Grid>
    </Grid>
  )
}

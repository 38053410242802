import React from 'react'
import {Controller, UseFormMethods} from 'react-hook-form'

import DefaultRibbonSelect from 'Event/template/Cards/Dashboard/Sidebar/SidebarItem/TicketRibbonList/TicketRibbonConfig/DefaultRibbonSelect'
import ColorPicker from 'lib/ui/ColorPicker'
import TicketRibbonUpload from 'organization/Event/DashboardConfig/TicketRibbonUpload'
import TextField from 'lib/ui/TextField'
import {TicketRibbonProps} from 'Event/template/Cards/Dashboard/Sidebar/SidebarItem/TicketRibbonList/TicketRibbon'
import {Label} from 'lib/ui/typography'

export default function Settings(props: {
  control: UseFormMethods['control']
  register: UseFormMethods['register']
  ticketRibbon: TicketRibbonProps
  setCustomRibbon: (newCustomRibbon: TicketRibbonProps['customRibbon']) => void
  customRibbon: TicketRibbonProps['customRibbon']
  processing: boolean
  setProcessing: (processing: boolean) => void
}) {
  const {
    processing,
    setProcessing,
    ticketRibbon,
    customRibbon,
    setCustomRibbon,
    control,
    register,
  } = props

  const configProps = {
    processing,
    setProcessing,
    ticketRibbon,
    customRibbon,
    setCustomRibbon,
    control,
  }

  return (
    <>
      <DefaultRibbonSelect {...configProps} />
      <TicketRibbonUpload
        {...configProps}
        width={800}
        height={150}
        disableAutoRemove
      />

      <Label>Text</Label>
      <TextField
        name="text"
        defaultValue={ticketRibbon.text || ''}
        fullWidth
        aria-label="ticket ribbon text input"
        inputProps={{
          ref: register,
        }}
      />

      <Controller
        name="color"
        control={control}
        defaultValue={ticketRibbon.color}
        render={({value, onChange}) => (
          <ColorPicker
            label="Ribbon Text Color"
            color={value}
            onPick={onChange}
          />
        )}
      />
    </>
  )
}

import Divider from '@material-ui/core/Divider'
import MenuItem from '@material-ui/core/MenuItem'
import {obvioRoutes} from 'obvio/Routes'
import React from 'react'
import {useHistory} from 'react-router-dom'

export default function AccountMenuItem() {
  const history = useHistory()

  const goToChangePassword = () => {
    history.push(obvioRoutes.account)
  }

  return (
    <>
      <MenuItem onClick={goToChangePassword}>Account</MenuItem>
      <Divider />
    </>
  )
}

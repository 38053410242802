import React from 'react'
import MuiCard, {CardProps as MuiCardProps} from '@material-ui/core/Card'
import {makeStyles} from '@material-ui/styles'

type CardProps = MuiCardProps & {
  backgroundColor?: string
  color?: string
}
export default function Card(props: CardProps) {
  const useStyles = makeStyles({
    root: {
      backgroundColor: props.backgroundColor ? props.backgroundColor : 'unset',
      color: props.color ? props.color : 'unset',
      boxShadow: 'unset',
      borderRadius: 'unset',
    },
  })
  const classes = useStyles()

  return <MuiCard {...props} classes={classes} />
}

import AutocompleteTags from 'organization/Event/Services/Apps/ConvertKit/Config/Tags/AutocompleteTags'
import {
  ATTENDEE_CHECKED_IN,
  ATTENDEE_CREATED,
  ATTENDEE_SIGNED_WAIVER,
  ConvertKitTag,
  IMPORT_TAG,
  Tag,
  UPDATE_TAG,
} from 'organization/Event/Services/Apps/ConvertKit/Config/Tags'
import React, {useEffect, useState} from 'react'

export default function TagSelector(props: {
  tag: Tag
  onChange: (tag: Tag) => void
  error?: string
}) {
  const {tag} = props

  const [convertKitTag, setConvertKitTag] = useState<ConvertKitTag | null>(null)

  useEffect(() => {
    if (!tag.tag_id || !tag.name) {
      setConvertKitTag(null)
      return
    }

    setConvertKitTag({
      id: tag.tag_id,
      name: tag.name,
    })
  }, [tag])

  const handleTagSelect = (selected: ConvertKitTag | null) => {
    if (!selected) {
      const empty = {
        ...tag,
        name: null,
        tag_id: null,
      }

      props.onChange(empty)
      return
    }

    const updated = {
      ...tag,
      name: selected.name,
      tag_id: selected.id,
    }

    props.onChange(updated)
  }

  return (
    <AutocompleteTags
      errorText={props.error}
      inputVariant="outlined"
      inputLabel={label(tag)}
      value={convertKitTag}
      onChange={handleTagSelect}
    />
  )
}

function label(tag: Tag) {
  const isSet = Boolean(tag.name)
  return isSet ? `${typeLabel(tag)} - ${tag.name}` : `${typeLabel(tag)}`
}

function typeLabel(tag: Tag) {
  const labels: Record<string, string> = {
    [ATTENDEE_CREATED]: 'Attendee Created',
    [ATTENDEE_SIGNED_WAIVER]: 'Attendee Signed Waiver',
    [ATTENDEE_CHECKED_IN]: 'Attendee Checked In',
    [IMPORT_TAG]: 'Import Tag',
    [UPDATE_TAG]: 'Update Tag',
  }

  const label = labels[tag.type]
  if (!label) {
    throw new Error(`Label not defined for tag type: ${tag.type}`)
  }

  return label
}

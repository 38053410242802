import {useEvent} from 'Event/EventProvider'
import {useAsync} from 'lib/async'
import {api} from 'lib/url'
import {Area} from 'organization/Event/AreasProvider'
import {useOrganization} from 'organization/OrganizationProvider'
import React, {useCallback, useEffect, useState} from 'react'
import {useHistory, useParams} from 'react-router-dom'
import {useEventSocket} from 'organization/Event/EventSocketProvider'
import {
  totalAttendees,
  UPDATED_ROOM,
} from 'organization/Event/Area/RoomsProvider'
import {Room} from 'Event/room'
import FullPageLoader from 'lib/ui/layout/FullPageLoader'

const UPDATED_AREA = '.area.updated'

type UpdatedAreaProps = {
  area_id: number
}

type AreaContextProps = {
  area: Area
  update: (data: Partial<Area>) => Promise<void>
  processing: boolean
  deleteArea: () => Promise<void>
  setRegistration: (hasRegistration: boolean) => void
  setRegisterExistingAttendeesOnly: (existingAttendeesOnly: boolean) => void
  totalAttendees: number
}

const AreaContext = React.createContext<AreaContextProps | undefined>(undefined)

export function RouteAreaProvider(props: {children: React.ReactElement}) {
  const {area: routeId} = useParams<{area: string}>()
  const id = parseInt(routeId)
  const [area, setArea] = useState<Area | null>(null)
  const {data: saved, loading, error} = useAreaWithId(id)

  const {channel} = useEventSocket()
  const history = useHistory()
  const {routes, client} = useOrganization()
  const {event} = useEvent()
  const {
    event: {id: eventId},
  } = useEvent()
  const url = api(`/events/${eventId}/areas/${area?.id}`)

  useEffect(() => {
    if (!area) {
      return
    }

    channel.listen(UPDATED_AREA, (updated: UpdatedAreaProps) => {
      if (updated.area_id === area.id) {
        client.get<Area>(url).then(setArea)
      }
    })

    return () => {
      channel.stopListening(UPDATED_AREA)
    }
  }, [area, channel, client, url])

  useEffect(() => {
    setArea(saved)
  }, [saved])

  if (error) {
    history.push(`${routes.events.root}/${event.id}/areas`)
  }

  if (loading || !area) {
    return <FullPageLoader />
  }

  return <StaticAreaProvider area={area}>{props.children}</StaticAreaProvider>
}

export function StaticAreaProvider(props: {area: Area; children: JSX.Element}) {
  const {area: savedArea} = props
  const [area, setArea] = useState(savedArea)
  const {id} = area
  const [processing, setProcessing] = useState(false)
  const update = useUpdateArea(id, setArea, setProcessing)
  const deleteArea = useDeleteArea(id, setProcessing)
  const setRegistration = useToggleRegistration(id, setArea, setProcessing)
  const setRegisterExistingAttendeesOnly = useToggleRegisterExistingAttendeesOnly(
    id,
    setArea,
    setProcessing,
  )

  const {channel} = useEventSocket()
  const {client} = useOrganization()
  const {
    event: {id: eventId},
  } = useEvent()
  const url = api(`/events/${eventId}/areas/${area.id}`)

  useEffect(() => {
    setArea(savedArea)
  }, [savedArea])

  useEffect(() => {
    channel.listen(UPDATED_AREA, (updated: UpdatedAreaProps) => {
      if (updated.area_id === area.id) {
        client.get<Area>(url).then(setArea)
      }
    })

    return () => {
      channel.stopListening(UPDATED_AREA)
    }
  }, [area, channel, client, url])

  // If an Area's room was updated, let's update it here too..
  useEffect(() => {
    channel.listen(UPDATED_ROOM, (updated: Room) => {
      const hasRoom = area.rooms.find((r) => r.id === updated.id)
      if (!hasRoom) {
        return
      }

      const updatedRooms = area.rooms.map((r) =>
        r.id === updated.id ? updated : r,
      )

      setArea({
        ...area,
        rooms: updatedRooms,
      })
    })

    return () => {
      channel.stopListening(UPDATED_ROOM)
    }
  }, [channel, area])

  return (
    <AreaContext.Provider
      value={{
        area,
        update,
        processing,
        deleteArea,
        setRegistration,
        totalAttendees: totalAttendees(area.rooms),
        setRegisterExistingAttendeesOnly,
      }}
    >
      {props.children}
    </AreaContext.Provider>
  )
}

function useAreaWithId(id: number) {
  const {client} = useOrganization()
  const {
    event: {id: eventId},
  } = useEvent()

  const fetch = useCallback(() => {
    const url = api(`/events/${eventId}/areas/${id}`)
    return client.get<Area>(url)
  }, [id, client, eventId])

  return useAsync(fetch)
}

function useUpdateArea(
  id: number,
  setArea: (area: Area) => void,
  setProcessing: (processing: boolean) => void,
) {
  const {client} = useOrganization()
  const {
    event: {id: eventId},
  } = useEvent()

  const update = useCallback(
    (data: Partial<Area>) => {
      const url = api(`/events/${eventId}/areas/${id}`)
      setProcessing(true)
      return client
        .patch<Area>(url, data)
        .then(setArea)
        .finally(() => {
          setProcessing(false)
        })
    },
    [client, eventId, setArea, id, setProcessing],
  )

  return update
}

function useDeleteArea(
  id: number,
  setProcessing: (processing: boolean) => void,
) {
  const {event} = useEvent()
  const {client} = useOrganization()

  return useCallback(() => {
    const endpoint = `/events/${event.id}/areas/${id}`
    const url = api(endpoint)

    setProcessing(true)
    return client.delete<void>(url).catch((e) => {
      setProcessing(false)
      throw e // re-throw to prevent downstream from thinking it was a success
    })
  }, [id, client, setProcessing, event])
}

function useToggleRegistration(
  id: number,
  setArea: (area: Area) => void,
  setProcessing: (processing: boolean) => void,
) {
  const {client} = useOrganization()
  const {event} = useEvent()

  return useCallback(
    (enable: boolean) => {
      const endpoint = `/events/${event.id}/areas/${id}/registration`
      const url = api(endpoint)
      const sendRequest = enable ? client.patch : client.delete

      setProcessing(true)

      sendRequest<Area>(url)
        .then(setArea)
        .finally(() => {
          setProcessing(false)
        })
    },
    [id, client, setProcessing, setArea, event.id],
  )
}

function useToggleRegisterExistingAttendeesOnly(
  id: number,
  setArea: (area: Area) => void,
  setProcessing: (processing: boolean) => void,
) {
  const {client} = useOrganization()
  const {event} = useEvent()

  return useCallback(
    (enable: boolean) => {
      const endpoint = `/events/${event.id}/areas/${id}/register_existing_attendees_only`
      const url = api(endpoint)
      const sendRequest = enable ? client.patch : client.delete

      setProcessing(true)

      sendRequest<Area>(url)
        .then(setArea)
        .finally(() => {
          setProcessing(false)
        })
    },
    [id, client, setProcessing, setArea, event.id],
  )
}

export function useArea() {
  const context = React.useContext(AreaContext)
  if (context === undefined) {
    throw new Error('useArea must be used within a AreaProvider')
  }

  return context
}

import React from 'react'
import {Controller} from 'react-hook-form'
import {useTownhallTemplate} from 'Event/template/Townhall'
import Title from 'lib/ui/ConfigPanel/Title'
import {Label} from 'lib/ui/typography'
import Slider from 'lib/ui/Slider'
import FontSelect from 'lib/FontSelect'
import ColorPicker from 'lib/ui/ColorPicker'
import Select from 'lib/ui/Select'
import Option from 'lib/ui/Select/Option'
import {onUnknownChangeHandler} from 'lib/dom'
import {useConfig} from 'organization/Event/Configurable'

const DEFAULT_FONT_SIZE = 42
const MAX_FONT_SIZE = 50
const MIN_FONT_SIZE = 16

export default function Styling() {
  const {
    form: {control},
  } = useConfig()

  const template = useTownhallTemplate()
  const heroTextColor = template.hero.color
    ? template.hero.color
    : template.textColor

  return (
    <>
      <Title>Typography</Title>
      <Label>Font</Label>
      <Controller
        control={control}
        name="hero.font"
        defaultValue={template.hero.font || null}
        render={({value, onChange}) => (
          <FontSelect value={value} onChange={onChange} />
        )}
      />
      <Controller
        name="hero.color"
        control={control}
        defaultValue={heroTextColor}
        render={({value, onChange}) => (
          <ColorPicker
            label="Font Color"
            aria-label="welcome text color"
            color={value}
            onPick={onChange}
          />
        )}
      />
      <Controller
        name="hero.fontSize"
        defaultValue={template.hero.fontSize || DEFAULT_FONT_SIZE}
        control={control}
        render={({value, onChange}) => (
          <Slider
            aria-label="welcome text font size"
            min={MIN_FONT_SIZE}
            max={MAX_FONT_SIZE}
            onChange={onChange}
            valueLabelDisplay="off"
            value={value}
            label="Font Size"
          />
        )}
      />
      <Controller
        name="hero.alignment"
        defaultValue={template.hero.alignment}
        control={control}
        render={({value, onChange}) => (
          <Select
            fullWidth
            label="Text Align"
            value={value}
            onChange={onUnknownChangeHandler(onChange)}
          >
            <Option value="left">Left</Option>
            <Option value="center">Center</Option>
            <Option value="right">Right</Option>
          </Select>
        )}
      />

      <Controller
        name="hero.vertical_alignment"
        defaultValue={template.hero.vertical_alignment}
        control={control}
        render={({value, onChange}) => (
          <Select
            fullWidth
            label="Vertical Alignment"
            value={value}
            onChange={onUnknownChangeHandler(onChange)}
          >
            <Option value="start">Top</Option>
            <Option value="center">Middle</Option>
            <Option value="end">Bottom</Option>
          </Select>
        )}
      />
    </>
  )
}

import React from 'react'
import {ResourceSectionProps} from 'Event/template/Townhall/Dashboard/Main/ResourceSection'
import Settings from 'Event/template/Townhall/Dashboard/Main/ResourceSection/ResourceListConfig/Settings'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import RulesPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/RulesPanel'
import ScheduleInput from 'organization/Event/DashboardConfig/ComponentConfigPanel/ScheduleInput'
import Config from 'organization/Event/Configurable/Config'
import {REMOVE, useSaveTemplate} from 'Event/TemplateUpdateProvider'
import {useConfig} from 'organization/Event/Configurable'
import StylingPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/StylingPanel'
import Styling from 'Event/template/Townhall/Dashboard/Main/ResourceSection/ResourceListConfig/Styling'

export function ResourceListConfig(props: {
  section: ResourceSectionProps
  id: string
}) {
  const {section, id} = props

  const save = useSaveTemplate()

  const {
    form: {setValue, control},
  } = useConfig()

  const remove = () =>
    save({
      dashboardSections: {
        [id]: REMOVE,
      },
      navBar: {
        menuItems: {[id]: REMOVE},
      },
    })

  return (
    <Config title="Resources" onSave={save} onRemove={remove}>
      <SettingsPanel>
        <Settings section={section} id={id} />
      </SettingsPanel>
      <StylingPanel>
        <Styling section={section} sectionId={id} />
      </StylingPanel>
      <RulesPanel
        rules={section.rules}
        control={control}
        name={`dashboardSections.${id}.rules`}
      >
        <ScheduleInput setValue={setValue} control={control} values={section} />
      </RulesPanel>
    </Config>
  )
}

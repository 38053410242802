import React, {useEffect, useMemo, useState} from 'react'
import styled, {useTheme} from 'styled-components'
import {
  StripeCardCvcElement,
  StripeCardExpiryElement,
  StripeCardNumberElement,
  StripeElementStyle,
} from '@stripe/stripe-js'
import {useElements} from '@stripe/react-stripe-js'
import {colors} from 'lib/ui/theme'

export default function CardFields(props: {
  disabled?: boolean
  onLoad: (element: StripeCardNumberElement) => void
  style?: StripeElementStyle
}) {
  const {disabled, onLoad, style} = props
  const elements = useElements()
  const [
    numberElement,
    setNumberElement,
  ] = useState<StripeCardNumberElement | null>(null)
  const [
    expiryElement,
    setExpiryElement,
  ] = useState<StripeCardExpiryElement | null>(null)
  const [cvcElement, setCvcElement] = useState<StripeCardCvcElement | null>(
    null,
  )
  const [numberDiv, setNumberDiv] = useState<HTMLDivElement | null>(null)
  const [expiryDiv, setExpiryDiv] = useState<HTMLDivElement | null>(null)
  const [cvcDiv, setCvcDiv] = useState<HTMLDivElement | null>(null)
  const theme = useTheme()

  // Styles for element fields that are rendered inside Stripe's iframe.
  const stripeElementStyle: StripeElementStyle = useMemo(() => {
    if (style) {
      return style
    }

    return {
      base: {
        fontFamily: 'Rubik, sans-serif',
        fontSize: '16px',
        ':disabled': {
          color: 'rgba(0, 0, 0, 0.36)',
        },
        '::placeholder': {
          color:
            theme.name === 'dark'
              ? 'rgba(255, 255, 255, 0.48)'
              : 'rgba(0, 0, 0, 0.36)',
        },
        color: theme.colors.text.primary,
      },
      invalid: {
        color: colors.error,
      },
    }
  }, [style, theme])

  // Load Card Fields
  useEffect(() => {
    if (!elements) {
      return
    }

    if (!numberDiv || !expiryDiv || !cvcDiv) {
      return
    }

    const numberElement =
      elements.getElement('cardNumber') ??
      elements.create('cardNumber', {
        style: stripeElementStyle,
      })
    numberElement.mount(numberDiv)
    setNumberElement(numberElement)

    const expiryElement =
      elements.getElement('cardExpiry') ??
      elements.create('cardExpiry', {
        style: stripeElementStyle,
      })
    expiryElement.mount(expiryDiv)
    setExpiryElement(expiryElement)

    const cvcElement =
      elements.getElement('cardCvc') ??
      elements.create('cardCvc', {
        style: stripeElementStyle,
      })
    cvcElement.mount(cvcDiv)
    setCvcElement(cvcElement)

    // Pass any element to parent component so that it can be referenced when
    // the card form is submitted.
    onLoad(numberElement)
    numberElement.focus()
  }, [
    elements,
    setNumberElement,
    numberDiv,
    expiryDiv,
    cvcDiv,
    onLoad,
    stripeElementStyle,
  ])

  // Handle disabled state
  useEffect(() => {
    if (!numberElement || !expiryElement || !cvcElement) {
      return
    }

    numberElement.update({disabled})
    expiryElement.update({disabled})
    cvcElement.update({disabled})
  }, [disabled, numberElement, expiryElement, cvcElement])

  return (
    <>
      <div ref={setNumberDiv} />
      <BottomFields>
        <div ref={setExpiryDiv} />
        <div ref={setCvcDiv} />
      </BottomFields>
    </>
  )
}

const BottomFields = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 16px;
  margin-top: 16px;
`

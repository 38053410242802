import MenuItem from 'lib/ui/Menu/MenuItem'
import {Broadcast} from 'organization/Event/Broadcasts/BroadcastsProvider'
import React, {useState} from 'react'
import SendTestDialog from 'organization/Event/Broadcasts/BroadcastsTable/SendTestDialog'

export default React.forwardRef<
  HTMLLIElement,
  {
    broadcast: Broadcast
    onSuccess: (message: string) => void
  }
>((props, ref) => {
  const {broadcast, onSuccess} = props
  const [testDialogOpen, setTestDialogOpen] = useState<boolean>(false)

  // Currently, sending broadcasts is only available for emails as SMS isn't
  // working properly anyway.
  const showing = broadcast.channel.name === 'email'
  if (!showing) {
    return null
  }

  return (
    <>
      <SendTestDialog
        isOpen={testDialogOpen}
        onClose={() => setTestDialogOpen(false)}
        onSuccess={onSuccess}
        broadcast={broadcast}
      />
      <MenuItem
        onClick={() => {
          setTestDialogOpen(true)
        }}
        ref={ref}
      >
        Send Test
      </MenuItem>
    </>
  )
})

import React from 'react'
import {FeaturesBreadcrumbs} from 'organization/Event/Page/PageBreadcrumbs'
import ToggleSpeakerSwitch from 'organization/Event/SpeakersConfig/ToggleSpeakerSwitch'
import Page from 'organization/Event/TemplateEditor/Page'
import {useObvioUser} from 'obvio/auth'
import {TemplateSpeakers} from 'Event/Speakers'
import {useSaveTemplate} from 'Event/TemplateUpdateProvider'
import {useTemplate} from 'Event/TemplateProvider'
import {DropResult} from 'react-beautiful-dnd'
import {orderedIdsByPosition, createPositions} from 'lib/list'

export default function SpeakersConfig() {
  const user = useObvioUser()

  return (
    <FeaturesBreadcrumbs page="Speakers">
      <Page
        toolbarItems={
          <>
            <ToggleSpeakerSwitch />
          </>
        }
      >
        <TemplateSpeakers user={user} />
      </Page>
    </FeaturesBreadcrumbs>
  )
}

export function useHandleDragSpeakers() {
  const update = useSaveTemplate()
  const {
    speakers: {items},
  } = useTemplate()

  return (result: DropResult) => {
    const {destination, source} = result

    if (!destination) {
      return
    }

    const ids = orderedIdsByPosition(items)

    const [removed] = ids.splice(source.index, 1)
    ids.splice(destination.index, 0, removed)

    update({
      speakers: {
        items: createPositions(ids),
      },
    })
  }
}


export function modelToViewAttributeConverter( attributeKey ) {
	return dispatcher => {
		dispatcher.on( `attribute:${ attributeKey }:customButton`, converter );
	};

	function converter( evt, data, conversionApi ) {
		if ( !conversionApi.consumable.consume( data.item, evt.name ) ) {
			return;
		}

		const viewWriter = conversionApi.writer;
		const button = conversionApi.mapper.toViewElement( data.item );

		if ( data.attributeNewValue !== null ) {
			viewWriter.setAttribute( data.attributeKey, data.attributeNewValue, button );
		} else {
			viewWriter.removeAttribute( data.attributeKey, button );
		}
	}
}

export function modelToViewStyleConverter( attributeKey, styleKey, styleUnit ) {
	return dispatcher => {
		dispatcher.on( `attribute:${ attributeKey }:customButton`, converter );
	};

	function converter( evt, data, conversionApi ) {
		if ( !conversionApi.consumable.consume( data.item, evt.name ) ) {
			return;
		}

		const viewWriter = conversionApi.writer;
		const button = conversionApi.mapper.toViewElement( data.item );

		if ( data.attributeNewValue !== null ) {
			viewWriter.setAttribute( data.attributeKey, data.attributeNewValue, button );
			viewWriter.setStyle( styleKey, data.attributeNewValue + styleUnit, button );
		} else {
			viewWriter.removeAttribute( data.attributeKey, button );
		}
	}
}

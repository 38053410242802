import React from 'react'
import {AreaRegistrationFormProps} from 'Event/AreaRegistration'
import Page from 'Event/template/NiftyFifty/Page'
import LeftPanel from 'Event/template/NiftyFifty/Login/LeftPanel/index'
import RightPanel from 'Event/template/NiftyFifty/AreaRegistration/RightPanel'
import MobilePanel from 'Event/template/NiftyFifty/AreaRegistration/MobilePanel'

export default function NiftyFiftyAreaRegistrationForm(
  props: AreaRegistrationFormProps,
) {
  return (
    <Page
      Left={<LeftPanel />}
      Right={<RightPanel {...props} />}
      Mobile={<MobilePanel {...props} />}
    />
  )
}

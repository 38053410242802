import React from 'react'
import styled from 'styled-components'
import Container from '@material-ui/core/Container'
import {User} from 'auth/user'
import {Townhall, useTownhallTemplate} from 'Event/template/Townhall'
import {rgba} from 'lib/color'
import LanguageSelector from 'Event/LanguageSelector'
import {muiTheme} from 'lib/ui/theme'
import {ThemeProvider} from '@material-ui/core/styles'
import {withStyles} from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import {Logo} from 'Event/template/Townhall/Login/Page'
import Configurable from 'organization/Event/Configurable'
import ContentPanelConfig from 'Event/template/Townhall/check-in/ContentPanelConfig'
import {Font, useLoadFont} from 'lib/FontSelect'
import PageConfig from 'Event/template/Townhall/check-in/PageConfig'

export default function CheckInPage(props: {
  user: User
  children: React.ReactElement | React.ReactElement[]
}) {
  const template = useTownhallTemplate()

  const dashboardBackground = template.checkIn.page.backgroundImage
    ? `url(${template.checkIn.page.backgroundImage})`
    : '#FFFFFF'

  const backgroundRGBColor = rgba(
    template.checkIn.page.backgroundColor,
    template.checkIn.page.backgroundOpacity / 100,
  )

  const color = template.textColor
  const linkUnderline = template.linkUnderline
  const linkColor = template.linkColor
  const font = template.font

  const contentBackgroundColor = rgba(
    template.checkIn.contentPanel.backgroundColor,
    template.checkIn.contentPanel.backgroundOpacity / 100,
  )
  const loginTextColor = template.checkIn.contentPanel.textColor

  useLoadFont(font)

  return (
    <ThemeProvider theme={muiTheme}>
      <Configurable aria-label="checkin page config">
        <PageConfig />
        <TopBox
          background={dashboardBackground}
          color={color}
          loginTextColor={loginTextColor}
          linkColor={linkColor}
          linkUnderline={linkUnderline}
          position={template.pageBackgroundPosition}
          font={font}
          fullHeight
        >
          <ColorOverlay color={backgroundRGBColor}>
            <Content>
              <StyledContainer maxWidth="lg">
                <Configurable>
                  <ContentPanelConfig />
                  <StyledPaper backgroundColor={contentBackgroundColor}>
                    <Logo />
                    {props.children}
                  </StyledPaper>
                </Configurable>
              </StyledContainer>
            </Content>
            <LanguageSelector />
          </ColorOverlay>
        </TopBox>
      </Configurable>
    </ThemeProvider>
  )
}

const Content = styled.div`
  flex: 1;
  width: 100%;
  justify-content: center;
  display: flex;
  flex-direction: column;
  padding-top: ${(props) => props.theme.spacing[10]};
  padding-bottom: ${(props) => props.theme.spacing[10]};
  @media (min-width: ${(props) => props.theme.breakpoints.lg}) {
    display: block;
  }
`
const ColorOverlay = styled.div<{
  color: string
}>`
  background-color: ${(props) => props.color};
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
`

const StyledContainer = withStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    '@media (min-width: 600px)': {
      width: '50%',
    },
  },
})(Container)

const StyledPaper = styled(
  ({backgroundColor: _backgroundColor, color: _color, ...otherProps}) => (
    <Paper {...otherProps} />
  ),
)<{
  backgroundColor: string
  color?: string
}>`
  padding: ${(props) => props.theme.spacing[10]};
  background-color: ${(props) => props.backgroundColor};
  color: inherit;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  width: 100%;
`

export const TopBox = styled.div<{
  background: string
  color: string
  loginTextColor: string | null
  linkColor: string
  linkUnderline: boolean
  position: Townhall['pageBackgroundPosition']
  font: Font | null
  fullHeight?: boolean
}>`
  height: ${(props) => (props.fullHeight ? '100vh' : 'auto')};
  font-size: 17px;
  ${(props) => (props.font ? `font-family: ${props.font.family};` : '')}
  color: ${(props) =>
    props.loginTextColor ? props.loginTextColor : props.color} !important;
  background: ${(props) => props.background};
  ${(props) =>
    props.position === 'bottom' &&
    `
      background-position: bottom;
      background-size: cover;
    `}
  ${(props) =>
    props.position === 'fixed' &&
    `
      background-position: bottom;
      background-attachment: fixed;
      background-size: cover;
    `}
  background-repeat: no-repeat;

  a {
    color: ${(props) => props.linkColor};
    text-decoration: none;

    &:hover {
      text-decoration: ${(props) =>
        props.linkUnderline ? 'underline' : 'none'};
    }
  }
  -webkit-transition: all 300ms ease-in 200ms;
  -moz-transition: all 300ms ease-in 200ms;
  -o-transition: all 300ms ease-in 200ms;
  transition: all 300ms ease-in 200ms;
`

import TextField from '@material-ui/core/TextField'
import styled from 'styled-components'
import CloseIcon from '@material-ui/icons/Close'
import React from 'react'
import Grid from '@material-ui/core/Grid'
import {onChangeStringHandler} from 'lib/dom'
import IconButton from 'lib/ui/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'
import {Group} from 'organization/Event/Services/Apps/ConvertKit/Config/Groups/convertKitGroup'
import AutocompleteFields, {
  ConvertKitField,
} from 'organization/Event/Services/Apps/ConvertKit/Config/Fields/AutocompleteFields'

export default function GroupInput(props: {
  group: Group
  onChange: (group: Group) => void
  onRemove: () => void
}) {
  const {onChange, group, onRemove} = props
  const update = <K extends keyof Group>(key: K) => (value: Group[K]) => {
    const updated = {
      ...group,
      [key]: value,
    }

    onChange(updated)
  }

  const setField = (field: ConvertKitField | null) => {
    const updated = {
      ...group,
      field_id: field?.id || null,
      field_name: field?.name || null,
      field_key: field?.key || null,
      field_label: field?.label || null,
    }

    onChange(updated)
  }

  return (
    <Grid container spacing={2}>
      <Grid item sm={6}>
        <TextField
          label="Key"
          value={group.key}
          fullWidth
          variant="outlined"
          inputProps={{'aria-label': 'group name'}}
          onChange={onChangeStringHandler(update('key'))}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <RemoveButton aria-label="remove group" onClick={onRemove}>
                  <CloseIcon color="error" />
                </RemoveButton>
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item sm={6}>
        <AutocompleteFields
          onChange={setField}
          inputVariant={'outlined'}
          value={{
            id: group.field_id || '',
            name: group.field_name || '',
            key: group.field_key || '',
            label: group.field_label || '',
          }}
        />
      </Grid>
    </Grid>
  )
}

const RemoveButton = styled(IconButton)`
  margin-left: ${(props) => props.theme.spacing[2]};
`

import Accordion from '@material-ui/core/Accordion'
import styled from 'styled-components'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import React, {useState} from 'react'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Typography from '@material-ui/core/Typography'
import {useAttendeeVariables} from 'Event'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import SubmissionForm from 'Event/business-mastery/TeamCompetition/Day/SubmissionForm'
import {useAttendee} from 'Event/auth'

/**
 * Prevent a user from editing the form if they have this tag. Used to prevent team members who
 * access as an Attendee from accidentally editing the form.
 */
const DISABLE_EDIT_TAG = 'TeamGRNoEdit'

export default function Day(props: {
  day: number
  description: string
  isShowing: boolean
  isLocked: boolean
  activeDay: number | null
}) {
  const {day, isLocked, isShowing, description, activeDay} = props
  const v = useAttendeeVariables()
  const [expanded, setExpanded] = useState(day === activeDay)

  const [completedSubmissionOne, setCompletedSubmissionOne] = useState(false)
  const [completedSubmissionTwo, setCompletedSubmissionTwo] = useState(false)

  const a = useAttendee()

  const hasDisableEditTag = a.tags.includes(DISABLE_EDIT_TAG)

  const disabled = isLocked || hasDisableEditTag

  if (!isShowing) {
    return null
  }

  const dayLabel = v('{{team_day}}', 'Day')
  const submissionsClosedLabel = v(
    '{{team_submissions_closed}}',
    'Submissions Closed',
  )

  const heading = disabled
    ? `${dayLabel} ${day} -- ${submissionsClosedLabel}`
    : `${dayLabel} ${day}`

  return (
    <Accordion
      elevation={4}
      expanded={expanded}
      onChange={(_event, isExpanded) => {
        setExpanded(isExpanded)
      }}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="h5">{heading}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <FullWidth>
          <Description>{description}</Description>
          <SubmissionForm
            day={day}
            submission={1}
            disabled={disabled}
            activeDay={activeDay}
            onComplete={() => setCompletedSubmissionOne(true)}
          />
          <SubmissionForm
            day={day}
            submission={2}
            disabled={disabled}
            activeDay={activeDay}
            hidden={completedSubmissionOne === false}
            onComplete={() => setCompletedSubmissionTwo(true)}
          />
          <SubmissionForm
            day={day}
            submission={3}
            disabled={disabled}
            activeDay={activeDay}
            hidden={completedSubmissionTwo === false}
          />
        </FullWidth>
      </AccordionDetails>
    </Accordion>
  )
}

const FullWidth = styled.div`
  width: 100%;
`

const Description = styled(Typography)`
  margin-bottom: 12px;
`

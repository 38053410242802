import styled from 'styled-components'
import React from 'react'
import {useAttendeeVariables} from 'Event'
import {Font} from 'lib/FontSelect'
import {BOLD, ITALIC, CAPS} from 'lib/ui/typography/FontStyleInput'
import {
  BlogSectionProps,
  TownhallBlogPost,
} from 'Event/template/Townhall/Dashboard/Main/BlogSection'

export default function Title(props: {
  post: TownhallBlogPost
  postStyles?: BlogSectionProps['postStyles']
  textColor: string
}) {
  const {post, postStyles, textColor} = props
  const v = useAttendeeVariables()

  return (
    <TitleText
      textColor={textColor}
      titleTextColor={postStyles?.titleTextColor}
      fontSize={postStyles?.titleFontSize}
      font={postStyles?.titleFont}
    >
      {v(post.title)}
    </TitleText>
  )
}

const TitleText = styled.h2<{
  textColor: string
  titleTextColor?: string | null
  fontSize?: number
  font?: Font | null
}>`
  font-weight: ${(props) =>
    props.font?.styles?.includes(BOLD) ? 'bold' : 'normal'};
  font-family: ${(props) => (props.font ? props.font.family : 'inherit')};
  font-style: ${(props) =>
    props.font?.styles?.includes(ITALIC) ? 'italic' : 'normal'};
  text-transform: ${(props) =>
    props.font && !props.font?.styles?.includes(CAPS) ? 'none' : 'uppercase'};
  color: ${(props) =>
    props.titleTextColor ? props.titleTextColor : props.textColor} !important;
  margin: 0;
  font-size: ${(props) => props.fontSize}px;
`

import Slider from 'lib/ui/Slider'
import ColorPicker from 'lib/ui/ColorPicker'
import {onUnknownChangeHandler} from 'lib/dom'
import Select from 'lib/ui/Select'
import Option from 'lib/ui/Select/Option'
import {useSimpleBlogTemplate} from 'Event/template/SimpleBlog'
import React from 'react'
import {Controller, UseFormMethods} from 'react-hook-form'

const MIN_HEADER_HEIGHT = 50
const MAX_HEADER_HEIGHT = 500
const MIN_LOGO_HEIGHT = 30

export default function Styling(props: {
  control: UseFormMethods['control']
  watch: UseFormMethods['watch']
}) {
  const {control, watch} = props
  const template = useSimpleBlogTemplate()

  const headerHeight = watch('header.height')

  return (
    <>
      <Controller
        name="header.logoHeight"
        defaultValue={template.header.logoHeight}
        control={control}
        render={({value, onChange}) => (
          <Slider
            min={MIN_LOGO_HEIGHT}
            max={headerHeight}
            step={1}
            onChange={onChange}
            valueLabelDisplay="auto"
            value={value}
            aria-label="logo height"
            label="Logo Height"
          />
        )}
      />
      <Controller
        name="header.backgroundColor"
        defaultValue={template.header.backgroundColor}
        control={control}
        render={({value, onChange}) => (
          <ColorPicker
            label="Header Background Color"
            color={value}
            onPick={onChange}
            aria-label="header background color"
          />
        )}
      />
      <Controller
        name="header.backgroundOpacity"
        defaultValue={template.header.backgroundOpacity || 0}
        control={control}
        render={({value, onChange}) => (
          <Slider
            min={0}
            max={100}
            step={1}
            onChange={onChange}
            valueLabelDisplay="auto"
            value={value}
            aria-label="background opacity"
            label="Header Background Opacity"
            unit="%"
          />
        )}
      />
      <Controller
        name="header.height"
        defaultValue={template.header.height || 0}
        control={control}
        render={({value, onChange}) => (
          <Slider
            min={MIN_HEADER_HEIGHT}
            max={MAX_HEADER_HEIGHT}
            step={1}
            onChange={onChange}
            valueLabelDisplay="auto"
            value={value}
            aria-label="header height"
            label="Header Height"
          />
        )}
      />
      <Controller
        name="dashboardBackground.color"
        defaultValue={template.dashboardBackground?.color}
        control={control}
        render={({value, onChange}) => (
          <ColorPicker
            label="Dashboard Background Color"
            color={value}
            onPick={onChange}
            aria-label="dashboard background color"
          />
        )}
      />
      <Controller
        name="dashboardBackground.opacity"
        defaultValue={template.dashboardBackground?.opacity || 0}
        control={control}
        render={({value, onChange}) => (
          <Slider
            min={0}
            max={100}
            step={1}
            onChange={onChange}
            valueLabelDisplay="auto"
            value={value}
            aria-label="dashboard background color opacity"
            label="Dashboard Background Color Opacity"
            unit="%"
          />
        )}
      />
      <Controller
        name="backgroundPosition"
        defaultValue={template.backgroundPosition}
        control={control}
        render={({value, onChange}) => (
          <Select
            fullWidth
            label="Background Position"
            value={value}
            onChange={onUnknownChangeHandler(onChange)}
          >
            <Option value="fixed">Fixed</Option>
            <Option value="bottom">Bottom</Option>
          </Select>
        )}
      />
      <Controller
        name="menu.backgroundColor"
        defaultValue={template.menu.backgroundColor}
        control={control}
        render={({value, onChange}) => (
          <ColorPicker
            label="Menu Background Color"
            color={value}
            onPick={onChange}
            aria-label="menu background color"
          />
        )}
      />
      <Controller
        name="menu.textColor"
        defaultValue={template.menu.textColor}
        control={control}
        render={({value, onChange}) => (
          <ColorPicker
            label="Menu Text Color"
            color={value}
            onPick={onChange}
            aria-label="menu text color"
          />
        )}
      />
      <Controller
        name="menu.iconColor"
        defaultValue={template.menu.iconColor}
        control={control}
        render={({value, onChange}) => (
          <ColorPicker
            label="Menu Icon Color"
            color={value}
            onPick={onChange}
            aria-label="menu icon color"
          />
        )}
      />
    </>
  )
}

import React from 'react'
import {Controller, UseFormMethods} from 'react-hook-form'
import Slider from 'lib/ui/Slider'
import ColorPicker from 'lib/ui/ColorPicker'
import {useCardsTemplate} from 'Event/template/Cards'
import Title from 'lib/ui/ConfigPanel/Title'

const MIN_HERO_WELCOME_FONT_SIZE = 5
const MAX_HERO_WELCOME_FONT_SIZE = 50

export default function Settings(props: {control: UseFormMethods['control']}) {
  const {hero, textColor} = useCardsTemplate()
  const {control} = props
  const heroColor = hero.textColor ? hero.textColor : textColor

  return (
    <>
      <Title>Typography</Title>
      <Controller
        name="textColor"
        defaultValue={heroColor}
        control={control}
        render={({value, onChange}) => (
          <ColorPicker
            label="Color"
            color={value}
            onPick={onChange}
            aria-label="hero text color"
          />
        )}
      />
      <Controller
        control={control}
        name="fontSize"
        defaultValue={hero.fontSize}
        render={({value, onChange}) => (
          <Slider
            min={MIN_HERO_WELCOME_FONT_SIZE}
            max={MAX_HERO_WELCOME_FONT_SIZE}
            step={1}
            onChange={onChange}
            valueLabelDisplay="auto"
            value={value}
            aria-label="hero text font size"
            label="Font Size"
          />
        )}
      />
    </>
  )
}

import styled from 'styled-components'

const InputLabel = styled.span`
  font-size: 16px;
  line-height: 18px;
  font-weight: 400;
  color: ${(props) => props.theme.colors.input.label};
`

export default InputLabel

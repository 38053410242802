import React from 'react'
import Container from 'Event/Marketplace/Block/Container'
import CountDownTimer, {
  CountDownTimerSettings,
} from 'Event/Dashboard/components/CountDownTimer'
import {BlockComponentProps} from 'Event/Marketplace/Block'
import {useSection} from 'Event/Marketplace/Section'
import {BlockBase} from 'Event/Marketplace/Block/base'

export interface CountdownTimerBlock extends BlockBase {
  type: 'CountdownTimer'
  countdownTimer: CountDownTimerSettings
}

export default function CountdownTimer(
  props: CountdownTimerBlock & BlockComponentProps,
) {
  const {calculateVisibility} = useSection()
  return (
    <Container {...props}>
      <CountDownTimer
        {...props.countdownTimer}
        id={props.id || ''}
        onHidden={calculateVisibility}
      />
    </Container>
  )
}

import React from 'react'
import {Controller, UseFormMethods} from 'react-hook-form'
import {onChangeCheckedHandler} from 'lib/dom'
import {NavButtonWithSize} from 'Event/Dashboard/components/NavButton'
import {EnabledSwitch} from 'lib/ui/form/Switch'
import {CardsNavButtonProps} from 'Event/template/Cards/Dashboard/CardsNavButton'

export default function VisibilityField(props: {
  button: NavButtonWithSize | CardsNavButtonProps
  control: UseFormMethods['control']
}) {
  const {control, button} = props

  return (
    <Controller
      name="isVisible"
      control={control}
      defaultValue={button.isVisible}
      render={({value, onChange}) => (
        <EnabledSwitch
          checked={value}
          onChange={onChangeCheckedHandler(onChange)}
          arial-label="config switch to attendee"
        />
      )}
    />
  )
}

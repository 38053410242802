import {useLeftyTemplate} from 'Event/template/Lefty'
import React from 'react'
import styled from 'styled-components'

export default function BackgroundImage() {
  const template = useLeftyTemplate()
  const {sidebar} = template

  if (!sidebar.backgroundImage) {
    return null
  }

  return (
    <StyledImage
      src={sidebar.backgroundImage}
      borderRadius={sidebar.borderRadius}
    />
  )
}

const StyledImage = styled.img<{borderRadius: number}>`
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-top-left-radius: ${(props) => props.borderRadius}px;
  border-top-right-radius: ${(props) => props.borderRadius}px;
`

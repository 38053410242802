import {AttendeeFilterForm} from 'organization/Event/AttendeeManagement/AttendeeFilterDialog'
import {
  buildFilterParams,
  parseAttendeeUrlParams,
} from 'organization/Event/AttendeesProvider'
import {useBroadcastForm} from 'organization/Event/Broadcasts/BroadcastForm'
import React from 'react'
import {Controller} from 'react-hook-form'
import styled from 'styled-components'

export default function AttendeeFilterInput(props: {
  onDone: () => void
  showing: boolean
}) {
  const {onDone, showing} = props
  const {broadcast, control} = useBroadcastForm()

  const defaultValue =
    broadcast?.attendee_segment === 'filtered' ? broadcast.filter_query : ''

  return (
    <Box>
      <Controller
        name="filter_query"
        control={control}
        defaultValue={defaultValue}
        render={({value, onChange}) => {
          const searchParams = new URLSearchParams(value || '')
          const data = Object.fromEntries(searchParams.entries()) // Convert URLSearchParams to object
          const values = parseAttendeeUrlParams(data)

          if (!showing) {
            return <></>
          }

          return (
            <AttendeeFilterForm
              values={{
                activeOnly: values.is_active,
                checkedIn: values.checked_in_status,
                passwordCreated: values.password_created,
                waiverSigned: values.waiver_signed,
                techCheckCompleted: values.tech_check_completed,
                includedTags: values.tags_includes,
                excludedTags: values.tags_excludes,
                correspondingGroups: values.group_is,
                oppositeGroups: values.group_is_not,
                filledGroups: values.group_is_filled,
                blankGroups: values.group_is_blank,
              }}
              onChange={(value) => {
                const params = buildFilterParams(value)
                for (const [key, value] of Object.entries(params)) {
                  if (value === null) {
                    searchParams.delete(key)
                    continue
                  }

                  // If a tag/group is returning an empty string we should unset
                  // it here.
                  if (typeof value === 'string' && !value) {
                    searchParams.delete(key)
                    continue
                  }

                  searchParams.set(key, value)
                }

                onChange(searchParams.toString())
              }}
              onClear={() => {
                onChange('')
                onDone()
              }}
            />
          )
        }}
      />
    </Box>
  )
}

const Box = styled.div`
  margin-bottom: ${(props) => props.theme.spacing[4]};
`

import {useAttendeeVariables} from 'Event'
import {Answer, hasSubmission, useSubmissions} from 'Event/SubmissionsProvider'
import {Form} from 'organization/Event/FormsProvider'
import React, {useEffect} from 'react'
import {useCallback} from 'react'
import {useForm} from 'react-hook-form'

type State = {
  submitting: boolean
  isEditing: boolean // Editing a single submission
  canSubmitAgain: boolean // For multiple submissions
  showingDialog: boolean
}

/**
 * How long the user has to wait before being able
 * to send another submission if the form allows
 * it.
 */
export const NEXT_SUBMISSION_WAIT_TIME_MS = 10000

const reducer = (state: State, action: Partial<State>): State => {
  // If a modal form
  const didToggleSubmitAgain = !state.canSubmitAgain && action.canSubmitAgain
  const shouldCloseDialog = state.showingDialog && didToggleSubmitAgain
  if (shouldCloseDialog) {
    return {
      ...state,
      ...action,
      showingDialog: false,
    }
  }

  return {
    ...state,
    ...action,
  }
}

export function useSubmitForm(
  form: Form,
  options: {
    showInDialog?: boolean
  } = {},
) {
  const {showInDialog = false} = options
  const [state, dispatch] = React.useReducer(reducer, {
    submitting: false,
    isEditing: false,
    canSubmitAgain: true,
    showingDialog: false,
  })
  const {canSubmitAgain} = state
  const {responseError, answers: savedAnswers} = useSubmissions()
  const submitAnswers = useSubmitAnswers(form)

  const htmlForm = useForm()

  // If a form allows multiple submissions, we ALWAYS want to ignore any previous
  // answers.
  const answers = form.allows_multiple_submissions ? [] : savedAnswers

  const openDialog = () => {
    dispatch({
      showingDialog: true,
    })
  }

  const resubmit = () => {
    dispatch({
      isEditing: true,
      showingDialog: showInDialog,
    })
  }

  const closeDialog = useCallback(() => {
    dispatch({
      showingDialog: false,
      // If a user has explicitly closed the form dialog, we'll ALWAYS allow them
      // to submit another submission
      canSubmitAgain: true,
      isEditing: false,
    })
  }, [dispatch])

  const submit = (data: {answers: Answer[]}) => {
    if (state.submitting) {
      throw new Error(
        'Attempted to submit a form that was already submitting; did we forget to disable the form?',
      )
    }

    dispatch({
      submitting: true,
    })

    return submitAnswers(data)
      .then(() => {
        dispatch({
          isEditing: false,
          // If form allows multiple submissions we want to show the thank you message
          // for some time until the user closes it.
          showingDialog: form.allows_multiple_submissions ? true : false,
          canSubmitAgain: false,
        })

        if (form.allows_multiple_submissions) {
          htmlForm.reset()
        }
      })
      .finally(() => {
        dispatch({
          submitting: false,
        })
      })
  }

  const submitted = () => {
    if (form.allows_multiple_submissions) {
      return !state.canSubmitAgain
    }

    return hasSubmission(answers, form)
  }

  // Set timer to always set form to allow submitting again every 10s
  useEffect(() => {
    if (canSubmitAgain) {
      return
    }

    const timer = setTimeout(() => {
      dispatch({
        canSubmitAgain: true,
      })
    }, NEXT_SUBMISSION_WAIT_TIME_MS)

    return () => clearTimeout(timer)
  }, [canSubmitAgain])

  return {
    ...htmlForm,
    resubmit,
    isEditing: state.isEditing,
    submit,
    responseError,
    answers,
    dialogVisible: state.showingDialog,
    openDialog,
    closeDialog,
    alreadySubmitted: submitted(),
    submitting: state.submitting,
  }
}

export function useSubmitAnswers(form: Form) {
  const {submit: submitAnswers} = useSubmissions()
  const v = useAttendeeVariables()

  return (data: {answers: Answer[]}) =>
    submitAnswers(form, data).then(() => {
      if (form.on_submit_redirect_url) {
        window.location.href = v(form.on_submit_redirect_url)
        return
      }
    })
}

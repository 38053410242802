import React from 'react'
import {SetPasswordFormProps} from 'Event/Step1/SetPasswordForm'
import Page from 'Event/template/NiftyFifty/Page'
import Content from 'Event/template/NiftyFifty/Step1/SetPasswordForm/Content'
import LeftPanel from 'Event/template/NiftyFifty/check-in/LeftPanel'
import MobilePanel from 'Event/template/NiftyFifty/check-in/MobilePanel'
import RightPanel from 'Event/template/NiftyFifty/check-in/RightPanel'
import ProgressBar from 'Event/template/NiftyFifty/check-in/ProgressBar'

export default function SetPasswordForm(props: SetPasswordFormProps) {
  return (
    <Page
      Left={<LeftPanel />}
      Right={
        <RightPanel>
          <ProgressBar textKey="step1Text" percentKey="step1Percent" />
          <Content {...props} />
        </RightPanel>
      }
      Mobile={
        <MobilePanel>
          <Content {...props} />
        </MobilePanel>
      }
    />
  )
}

import React from 'react'
import {
  Button,
  ErrorMessage,
  DescriptionText,
} from 'Event/template/Lefty/Login/Page'
import Container from '@material-ui/core/Container'
import LeftyPage from 'Event/template/Lefty/Page'
import {ChangePasswordRequest} from 'auth/password/change'
import {useHistory} from 'react-router-dom'
import {eventRoutes} from 'Event/Routes'
import Description from 'Event/auth/ChangePassword/Description'
import NewPasswordField from 'Event/auth/ChangePassword/NewPasswordField'
import ConfirmNewPasswordField from 'Event/auth/ChangePassword/ConfirmNewPasswordField'
import SubmitButton from 'Event/auth/ChangePassword/SubmitButton'
import {User} from 'auth/user'

export default function ChangePassword(props: {
  changePassword: ChangePasswordRequest
  user: User
}) {
  return (
    <LeftyPage user={props.user}>
      <Content {...props} />
    </LeftyPage>
  )
}

function Content(props: {changePassword: ChangePasswordRequest}) {
  const {changePassword} = props
  const {responseError} = changePassword
  const history = useHistory()
  const goBackToDashboard = () => {
    history.push(eventRoutes.root)
  }

  if (changePassword.wasSuccessful) {
    return (
      <>
        <DescriptionText>Password Updated!</DescriptionText>
        <Button
          variant="contained"
          fullWidth
          aria-label="back to dashboard"
          type="submit"
          onClick={goBackToDashboard}
        >
          Go To Dashboard
        </Button>
      </>
    )
  }

  return (
    <>
      <Container maxWidth="md">
        <Description />
        <form onSubmit={changePassword.onSubmit}>
          <NewPasswordField {...changePassword} />
          <ConfirmNewPasswordField {...changePassword} />
          <ErrorMessage>{responseError?.message}</ErrorMessage>
          <SubmitButton {...changePassword} />
        </form>
      </Container>
    </>
  )
}

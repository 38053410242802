import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Slider from 'lib/ui/Slider'
import {Panels, usePanelsTemplate, usePanelsUpdate} from 'Event/template/Panels'
import {onChangeCheckedHandler} from 'lib/dom'
import ColorPicker from 'lib/ui/ColorPicker'
import Switch from 'lib/ui/form/Switch'
import {FontFamilySelect} from 'lib/FontSelect'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from 'lib/ui/TextField/InputLabel'
import {SectionTitle} from 'organization/Event/Page'
import Page from 'organization/Event/Page'
import React from 'react'
import {useForm, Controller} from 'react-hook-form'
import Button from '@material-ui/core/Button'
import ImageAssetUploader from 'lib/asset/ImageAssetUploader'
import Label from 'lib/ui/form/ImageUpload/Label'
import {DesignBreadcrumbs} from 'organization/Event/Page/PageBreadcrumbs'

export default function GlobalStylesConfig() {
  const update = usePanelsUpdate()
  const template = usePanelsTemplate()

  const {handleSubmit, control} = useForm()

  const submit = (data: Panels) => {
    update(data)
  }

  return (
    <DesignBreadcrumbs page="Global Styles">
      <Page>
        <SectionTitle>Global Styles</SectionTitle>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <Box mb={2}>
              <Label>Logo</Label>
              <Controller
                name="logo"
                control={control}
                defaultValue={template.logo}
                render={({onChange, value}) => (
                  <ImageAssetUploader
                    onChange={onChange}
                    value={value}
                    uploadLabel="Upload"
                    canResize
                    uploadInputProps={{
                      'aria-label': 'logo upload',
                    }}
                  />
                )}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box mb={2}>
              <Label>Mobile Logo</Label>
              <Controller
                name="mobileLogo"
                control={control}
                defaultValue={template.mobileLogo}
                render={({onChange, value}) => (
                  <ImageAssetUploader
                    onChange={onChange}
                    value={value}
                    uploadLabel="Upload"
                    canResize
                    uploadInputProps={{
                      'aria-label': 'mobilelogo upload',
                    }}
                  />
                )}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box mb={2}>
              <Label>Dashboard background</Label>
              <Controller
                name="dashboardBackground.image"
                control={control}
                defaultValue={template.dashboardBackground.image}
                render={({onChange, value}) => (
                  <ImageAssetUploader
                    onChange={onChange}
                    value={value}
                    uploadLabel="Upload"
                    canResize
                    uploadInputProps={{
                      'aria-label': 'dashboard background upload',
                    }}
                  />
                )}
              />
            </Box>
          </Grid>
        </Grid>

        <form onSubmit={handleSubmit(submit)}>
          <FormControl>
            <InputLabel>Font Family</InputLabel>
            <Controller
              control={control}
              name="font"
              defaultValue={template.font || null}
              render={({value, onChange}) => (
                <FormControl fullWidth>
                  <FontFamilySelect value={value} onChange={onChange} />
                </FormControl>
              )}
            />
          </FormControl>
          <Box mb={2}>
            <Controller
              name="accentColor"
              defaultValue={template.accentColor}
              control={control}
              render={({value, onChange}) => (
                <ColorPicker
                  label="Accent Color"
                  color={value}
                  onPick={onChange}
                  aria-label="accent color"
                />
              )}
            />
          </Box>
          <Box mb={2}>
            <Controller
              name="background.color"
              defaultValue={template.background?.color}
              control={control}
              render={({value, onChange}) => (
                <ColorPicker
                  label="Background Color"
                  color={value}
                  onPick={onChange}
                  aria-label="dashboard background color"
                />
              )}
            />
          </Box>

          <Controller
            name="background.opacity"
            defaultValue={template.background.opacity}
            control={control}
            render={({value, onChange}) => (
              <Slider
                min={0}
                max={100}
                step={1}
                onChange={onChange}
                valueLabelDisplay="auto"
                value={value}
                valueLabelFormat={() => (
                  <div>{template.background.opacity}</div>
                )}
                aria-label="background color opacity"
                data-testid="background-color-opacity"
                label="Background Color Opacity"
                unit="%"
              />
            )}
          />
          <Box mb={2}>
            <Controller
              name="textColor"
              defaultValue={template.textColor}
              control={control}
              render={({value, onChange}) => (
                <ColorPicker
                  label="Text Color"
                  color={value}
                  onPick={onChange}
                  aria-label="text color"
                />
              )}
            />
          </Box>
          <Box mb={2}>
            <Controller
              name="linkColor"
              defaultValue={template.linkColor}
              control={control}
              render={({value, onChange}) => (
                <ColorPicker
                  label="Link Color"
                  color={value}
                  onPick={onChange}
                  aria-label="link color"
                />
              )}
            />
          </Box>
          <Box mb={2}>
            <Controller
              name="linkUnderline"
              defaultValue={template.linkUnderline}
              control={control}
              render={({value, onChange}) => (
                <Switch
                  label={
                    value ? 'Link Underline Enabled' : 'Link Underline Disabled'
                  }
                  checked={value}
                  onChange={onChangeCheckedHandler(onChange)}
                  labelPlacement="end"
                />
              )}
            />
          </Box>
          <Box mb={2}>
            <Button
              variant="contained"
              aria-label="save"
              type="submit"
              color="primary"
            >
              Save
            </Button>
          </Box>
        </form>
      </Page>
    </DesignBreadcrumbs>
  )
}

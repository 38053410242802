import FeaturePageSelect, {
  featurePages,
} from 'Event/Dashboard/components/FeaturePageSelect'
import {useTownhallTemplate} from 'Event/template/Townhall'
import {onChangeCheckedHandler} from 'lib/dom'
import TextField from 'lib/ui/TextField'
import {EnabledSwitch} from 'lib/ui/form/Switch'
import {useConfig} from 'organization/Event/Configurable'
import React from 'react'
import {Controller} from 'react-hook-form'

export default function Settings(props: {id: string}) {
  const {id} = props
  const template = useTownhallTemplate()

  const {
    form: {register, control},
  } = useConfig()

  return (
    <>
      <Controller
        name={`template.navBar.menuItems.${id}.isEnabled`}
        control={control}
        defaultValue={template.navBar.menuItems[id].isEnabled}
        render={({value, onChange}) => (
          <EnabledSwitch
            checked={value}
            onChange={onChangeCheckedHandler(onChange)}
            arial-label="config enabled switch"
          />
        )}
      />
      <TextField
        label="Title"
        name={`navBar.menuItems.${id}.title`}
        defaultValue={template.navBar.menuItems[id].title}
        inputProps={{
          ref: register,
        }}
      />
      <Controller
        name={`navBar.menuItems.${id}.link`}
        control={control}
        defaultValue={template.navBar.menuItems[id].link}
        render={({value, onChange}) => (
          <FeaturePageSelect
            value={
              featurePages.find((featurePage) => featurePage.link === value)
                ?.key ?? 'speakers'
            }
            onChange={(key) =>
              onChange(
                featurePages.find((featurePage) => featurePage.key === key)
                  ?.link,
              )
            }
          />
        )}
      />
    </>
  )
}

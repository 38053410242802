import {Attendee} from 'Event/attendee'
import styled from 'styled-components'
import React from 'react'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import Dialog from 'lib/ui/Dialog'
import {useAttendees} from 'organization/Event/AttendeesProvider'
import {useEvent} from 'Event/EventProvider'
import {useOrganization} from 'organization/OrganizationProvider'
import {api} from 'lib/url'
import Form from 'organization/Event/AttendeeManagement/attendee-dialog/Form'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import RemoveWaiverButton from 'organization/Event/AttendeeManagement/attendee-dialog/UpdateDialog/RemoveWaiverButton'
import ClearPasswordButton from 'organization/Event/AttendeeManagement/attendee-dialog/UpdateDialog/ClearPasswordButton'
import SyncMailchimpTagsButton from 'organization/Event/AttendeeManagement/attendee-dialog/UpdateDialog/SyncMailchimpTagsButton'
import CopyIconButton from 'lib/ui/IconButton/CopyIconButton'
import OpenLoginLinkButton from 'organization/Event/AttendeeManagement/attendee-dialog/UpdateDialog/OpenLoginLinkButton'
import DownloadWaiverButton from './DownloadWaiverButton'
import {downloadFile} from 'lib/http-client'

export default function UpdateDialog(props: {
  showing: boolean
  attendee: Attendee | null
  onClose: () => void
}) {
  const {attendee, showing} = props
  const update = useUpdate()
  const isVisible = Boolean(props.attendee)

  if (!showing || !attendee) {
    return null
  }

  return (
    <Dialog open={isVisible} onClose={props.onClose}>
      <DialogTitle>Attendee</DialogTitle>
      <DialogContent>
        <Actions>
          <RemoveWaiverButton attendee={attendee} />
          <ClearPasswordButton attendee={attendee} />
          <SyncMailchimpTagsButton attendee={attendee} />
        </Actions>
        <LoginUrl attendee={attendee} />
        <WaiverUrl attendee={attendee} />
        <Form
          attendee={attendee}
          submit={update.bind(null, attendee)}
          onClose={props.onClose}
          isVisible={isVisible}
        />
      </DialogContent>
    </Dialog>
  )
}

function useUpdate() {
  const list = useAttendees()
  const {event} = useEvent()
  const {client} = useOrganization()

  return (attendee: Attendee, data: Partial<Attendee>) => {
    const url = api(`/events/${event.id}/attendees/${attendee.id}`)
    return client.patch<Attendee>(url, data).then(list.update)
  }
}

function LoginUrl(props: {attendee: Attendee}) {
  const {login_url} = props.attendee

  const copy = () => {
    navigator.clipboard.writeText(login_url)
  }

  const openLoginUrl = () => {
    window.open(login_url, 'new')
  }

  return (
    <TextField
      value={login_url}
      fullWidth
      variant="outlined"
      label="Login URL"
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <CopyIconButton aria-label="copy login url" onClick={copy} />
            <StyledOpenLoginLinkButton onClick={openLoginUrl} />
          </InputAdornment>
        ),
      }}
      inputProps={{
        'aria-label': 'login url',
      }}
    />
  )
}

function WaiverUrl(props: {attendee: Attendee}) {
  const {signed_waiver: waiver} = props.attendee

  const copy = () => {
    if (waiver) {
      navigator.clipboard.writeText(waiver)
    }
  }

  const downloadWaiver = () => {
    if (waiver) {
      downloadFile(waiver, 'waiver.pdf')
    }
  }

  if (!waiver) {
    return null
  }

  return (
    <TextField
      value={waiver}
      fullWidth
      variant="outlined"
      label="Waiver URL"
      inputProps={{
        'aria-label': 'waiver url',
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <CopyIconButton aria-label="copy waiver url" onClick={copy} />
            <StyledDownloadWaiverButton onClick={downloadWaiver} />
          </InputAdornment>
        ),
      }}
    />
  )
}

const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: ${(props) => props.theme.spacing[5]};

  button {
    &:not(:last-child) {
      margin-right: ${(props) => props.theme.spacing[2]};
    }
  }
`

const StyledOpenLoginLinkButton = styled(OpenLoginLinkButton)`
  margin-left: ${(props) => props.theme.spacing[1]};
  font-size: 18px;
`

const StyledDownloadWaiverButton = styled(DownloadWaiverButton)`
  margin-left: ${(props) => props.theme.spacing[1]};
  font-size: 20px;
`

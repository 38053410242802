import React from 'react'
import styled from 'styled-components'
import {useAttendeeVariables} from 'Event'
import {usePanelsTemplate} from 'Event/template/Panels'
import Card from '@material-ui/core/Card'
import {rgba} from 'lib/color'
import Configurable from 'organization/Event/Configurable'
import HeaderConfig from 'Event/Backgrounds/HeaderConfig'
import BackgroundLayoutConfig from 'Event/Backgrounds/BackgroundLayoutConfig'
import BackgroundList from 'Event/Backgrounds/BackgroundList'
import UploadBackgroundButton from 'Event/Backgrounds/UploadBackgroundButton'
import {PageTitle, PageDescription} from 'Event/template/Panels/Page'
import Content from 'lib/ui/form/TextEditor/Content'
import {useEditMode} from 'Event/EditModeProvider'
import Scripts, {ZOOM_BACKGROUNDS} from 'Event/Scripts'

export default function PanelsBackgrounds() {
  const v = useAttendeeVariables()
  const {
    zoomBackgrounds: settings,
    resourceList: list,
    textColor,
  } = usePanelsTemplate()
  const isEditMode = useEditMode()
  const hasBackgrounds = Object.values(settings.items).length
  const backgroundColor = rgba(
    list.cardBackgroundColor,
    list.cardBackgroundOpacity / 100,
  )

  if (!isEditMode && !hasBackgrounds) {
    return null
  }

  return (
    <>
      <Scripts page={ZOOM_BACKGROUNDS} />
      <StyledCard
        variant="outlined"
        backgroundColor={backgroundColor}
        borderRadius={10}
        textColor={textColor}
      >
        <Configurable>
          <HeaderConfig />
          <PageTitle aria-label="backgrounds title">
            {v(settings.title)}
          </PageTitle>
        </Configurable>
        <PageDescription>
          <Content aria-label="backgrounds description">
            {v(settings?.description)}
          </Content>
        </PageDescription>
        <UploadBackgroundButton />
        <Configurable
          dataTestId="background list config"
          aria-label="background list config"
        >
          <BackgroundLayoutConfig />
          <BackgroundList />
        </Configurable>
      </StyledCard>
    </>
  )
}

const StyledCard = styled((props) => {
  const {
    backgroundColor: _1,
    borderRadius: _2,
    textColor: _3,
    ...otherProps
  } = props
  return <Card {...otherProps} />
})`
  color: ${(props) => props.textColor} !important;
  background-color: ${(props) => props.backgroundColor} !important;
  border-radius: ${(props) => props.borderRadius}px !important;
  margin-bottom: 15px;
  padding: ${(props) => props.theme.spacing[4]};
  opacity: 0.8;
  &:hover {
    opacity: 1;
  }
`

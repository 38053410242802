import {useTrackEventPage} from 'analytics'
import {useAttendee} from 'Event/auth'
import {eventRoutes} from 'Event/Routes'
import SetPasswordForm, {useRequiresPassword} from 'Event/Step1/SetPasswordForm'
import React from 'react'
import {Redirect} from 'react-router-dom'
import {useTemplate} from 'Event/TemplateProvider'
import {hasMatch} from 'Event/attendee-rules/matcher'
import {useSubmissions} from 'Event/SubmissionsProvider'
import {useEvent} from 'Event/EventProvider'
import Scripts, {CHECK_IN_SET_PASSWORD} from 'Event/Scripts'

export default function Step1() {
  const needsToSetPassword = useNeedsToSetPassword()

  useTrackEventPage({
    page: 'Visited Step 1',
  })

  if (!needsToSetPassword) {
    return <Redirect to={eventRoutes.step2} />
  }

  return (
    <>
      <Scripts page={CHECK_IN_SET_PASSWORD} />
      <SetPasswordForm />
    </>
  )
}

export function useNeedsToSetPassword() {
  const template = useTemplate()
  const attendee = useAttendee()
  const {answers} = useSubmissions()
  const {event} = useEvent()
  const {forms} = event

  const requiresPassword = useRequiresPassword()
  if (!requiresPassword) {
    return false
  }

  const matchesRulesToSkip = hasMatch(
    {groups: attendee.groups, tags: attendee.tags, answers, forms},
    template.login.skipSetPasswordRules,
  )
  if (matchesRulesToSkip) {
    return false
  }

  return !attendee.has_password
}

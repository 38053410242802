import {useEffect, useState} from 'react'
import React from 'react'
import styled from 'styled-components'
import QRCodeGenerator, {QRCodeToDataURLOptions} from 'qrcode'

export default function QRCode(props: {
  children: string
  className?: string
  options?: QRCodeToDataURLOptions
}) {
  const {children, options} = props
  const [src, setSrc] = useState<string | null>(null)

  useEffect(() => {
    QRCodeGenerator.toDataURL(children, {
      width: 500,
      margin: 1,
      ...options,
    }).then(setSrc)
  }, [children, options])

  if (!src) {
    return null
  }

  return <QRImage src={src} className={props.className} alt={children} />
}

const QRImage = styled.img`
  width: 100%;
`

import React from 'react'
import {useAttendeeVariables} from 'Event'
import {useTownhallTemplate} from 'Event/template/Townhall'
import Tabs from 'Event/template/Townhall/Dashboard/NavBar/LinkTabs/Tabs'
import Tab from 'Event/template/Townhall/Dashboard/NavBar/LinkTabs/Tab'
import styled from 'styled-components'
import Configurable from 'organization/Event/Configurable'
import {NavBarConfig} from 'Event/template/Townhall/Dashboard/NavBar/NavBarConfig'
import AddPageButton from 'Event/template/Townhall/Dashboard/NavBar/LinkTabs/AddPageButton'
import EditModeOnly from 'Event/Dashboard/editor/views/EditModeOnly'

export default function LinkTabs(props: {
  sectionId: string
  onSelectItem: (id: string) => void
}) {
  const {navBar} = useTownhallTemplate()

  const {sectionId, onSelectItem} = props
  const v = useAttendeeVariables()

  return (
    <Container>
      <div>
        <Configurable>
          <NavBarConfig />
          <Tab
            value="header"
            label={v(navBar.mainMenuItemTitle)}
            aria-label="header"
            active={sectionId === 'header'}
            onClick={() => onSelectItem('header')}
          />
        </Configurable>
      </div>
      <Tabs selectedId={sectionId} onClickTab={onSelectItem} />
      <EditModeOnly>
        <AddPageButton />
      </EditModeOnly>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

import React from 'react'

/**
 * Helper to conditionally include components.
 *
 * @param props
 * @returns
 */
export default function Show(props: {
  when: boolean
  children: JSX.Element | JSX.Element[]
}) {
  if (!props.when) {
    return null
  }

  return <>{props.children}</>
}

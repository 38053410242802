import React from 'react'
import styled from 'styled-components'
import Box from '@material-ui/core/Box'
import {Label} from 'lib/ui/typography'
import Icon from 'lib/ui/Icon'
import IconButton from 'lib/ui/IconButton'
import {routesWithValue} from 'lib/url'
import {Form, useForms} from 'organization/Event/FormsProvider'
import {useEventRoutes} from 'organization/Event/EventRoutes'
import {useOrganization} from 'organization/OrganizationProvider'
import {api} from 'lib/url'
import {useToggle} from 'lib/toggle'
import Menu from 'lib/ui/Menu'
import MenuItem from 'lib/ui/Menu/MenuItem'
import DeleteIcon from 'assets/images/ui/icons/trash.svg'
import DuplicateIcon from 'assets/images/ui/icons/duplicate.svg'
import DownloadIcon from 'assets/images/ui/icons/download.svg'
import Divider from '@material-ui/core/Divider'
import {RelativeLink} from 'lib/ui/link/RelativeLink'
import ConfirmDialog from 'lib/ui/ConfirmDialog'

export type FormItemProps = {
  form: Form
}

export default function FormItem(props: FormItemProps) {
  const {form} = props

  const {add, remove} = useForms()
  const eventRoutes = useEventRoutes()
  const duplicate = useDuplicate()
  const deleteForm = useDelete()
  const exportForm = useExport()
  const {flag: duplicateProcessing, toggle: toggleProcessing} = useToggle()
  const {flag: deleteProcessing, toggle: toggleDeleteProcessing} = useToggle()
  const {flag: exportProcessing, toggle: toggleExportProcessing} = useToggle()

  const formRoute = (form: Form) =>
    routesWithValue(':form', String(form.id), eventRoutes.forms[':form'])

  const handleDuplicate = (form: Form) => {
    if (duplicateProcessing) {
      return
    }
    toggleProcessing()
    duplicate(form)
      .then(add)
      .finally(() => {
        toggleProcessing()
      })
  }

  const handleDelete = (form: Form) => {
    if (deleteProcessing) {
      return
    }
    toggleDeleteProcessing()
    deleteForm(form)
      .then(() => {
        remove(form)
      })
      .finally(() => {
        toggleDeleteProcessing()
      })
  }

  const handleExport = (form: Form) => {
    if (exportProcessing) {
      return
    }
    toggleExportProcessing()
    exportForm(form).then(() => {
      toggleExportProcessing()
    })
  }

  return (
    <Container aria-label="form">
      <RelativeLink disableStyles to={formRoute(form).root}>
        <Label aria-label="form-name">{form.name}</Label>
      </RelativeLink>

      <Menu
        button={({open}) => (
          <IconButton onClick={open} aria-label="view form actions">
            <Icon className="fas fa-ellipsis-h" color="black" iconSize={18} />
          </IconButton>
        )}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <div>
          <ConfirmDialog
            onConfirm={() => handleDelete(form)}
            aria-label="delete form confirm description"
            description="Are you sure you wish to delete this form?"
          >
            {(confirm) => (
              <MenuItem
                icon={DeleteIcon}
                children="Delete"
                disabled={deleteProcessing}
                onClick={confirm}
              />
            )}
          </ConfirmDialog>
        </div>
        <Divider />
        <MenuItem
          icon={DuplicateIcon}
          children="Duplicate"
          disabled={duplicateProcessing}
          onClick={() => handleDuplicate(form)}
        />
        <Divider />
        <MenuItem
          icon={DownloadIcon}
          children="Download Submissions"
          disabled={exportProcessing}
          onClick={() => handleExport(form)}
        />
      </Menu>
    </Container>
  )
}

function useDuplicate() {
  const {client} = useOrganization()
  return (form: Form) => {
    const url = api(`/forms/${form.id}/duplicate`)
    return client.post<Form>(url)
  }
}

function useDelete() {
  const {client} = useOrganization()
  return (form: Form) => {
    const url = api(`/forms/${form.id}`)
    return client.delete<Form>(url)
  }
}

function useExport() {
  const {client} = useOrganization()
  return (form: Form) => {
    const url = api(`/forms/${form.id}/submissions/export`)
    return client.get(url)
  }
}

export const Container = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${(props) =>
    `${props.theme.spacing[4]} ${props.theme.spacing[3]}`} !important;
  border-bottom: 1px solid ${(props) => props.theme.colors.gray300} !important;
`

import grey from '@material-ui/core/colors/grey'
import IconButton from 'lib/ui/IconButton'
import styled from 'styled-components'
import Tooltip from '@material-ui/core/Tooltip'
import React from 'react'
import {CircleUserIcon} from 'lib/ui/Icon'

export default function OpenLoginLinkButton(props: {
  onClick: () => void
  className?: string
}) {
  const {onClick, className} = props

  return (
    <Tooltip
      title="Login to Attendee's dashboard"
      placement="bottom"
      className={className}
    >
      <span>
        <StyledIconButton
          aria-label="login to attendee's dashboard"
          onClick={onClick}
        >
          <CircleUserIcon />
        </StyledIconButton>
      </span>
    </Tooltip>
  )
}

const StyledIconButton = styled(IconButton)`
  color: ${grey[500]};
`

import Container from '@material-ui/core/Container'
import React from 'react'
import Typography from '@material-ui/core/Typography/'
import {useForm} from 'react-hook-form'
import withStyles from '@material-ui/core/styles/withStyles'
import {spacing} from 'lib/ui/theme'
import {SetPasswordFormProps} from 'Event/Step1/SetPasswordForm'
import SimpleBlogPage from 'Event/template/SimpleBlog/Page'
import Box from '@material-ui/core/Box'
import {useAttendeeVariables} from 'Event'
import {useSimpleBlogTemplate} from 'Event/template/SimpleBlog'
import Content from 'lib/ui/form/TextEditor/Content'
import PasswordField from 'Event/Step1/SetPasswordForm/PasswordField'
import ConfirmPasswordField from 'Event/Step1/SetPasswordForm/ConfirmPasswordField'
import SubmitButton from 'Event/Step1/SubmitButton/Index'
import ProgressBar from 'Event/template/SimpleBlog/check-in/ProgressBar'
import Configurable from 'organization/Event/Configurable'
import DescriptionConfig from 'Event/Step1/SetPasswordForm/DescriptionConfig'
import TitleConfig from 'Event/Step1/SetPasswordForm/TitleConfig'

export default function SetPasswordForm(props: SetPasswordFormProps) {
  const {register, handleSubmit, errors, watch} = useForm()
  const template = useSimpleBlogTemplate()
  const v = useAttendeeVariables()

  const {setPasswordForm} = template

  return (
    <SimpleBlogPage user={props.user}>
      <Container maxWidth="sm">
        <ProgressBar textKey="step1Text" percentKey="step1Percent" />

        <Configurable aria-label="password form title">
          <TitleConfig />
          <Typography align="center" variant="h6">
            {v(setPasswordForm.title)}
          </Typography>
        </Configurable>

        <Configurable>
          <DescriptionConfig />
          <Description>{v(setPasswordForm?.description || '')}</Description>
        </Configurable>
        <Box mt={2}>
          <form onSubmit={handleSubmit(props.submit)}>
            <PasswordField
              error={errors.password?.message}
              register={register}
              disabled={props.submitting}
            />
            <ConfirmPasswordField
              error={errors.password_confirmation?.message}
              register={register}
              watch={watch}
              disabled={props.submitting}
            />
            <Error>{props.responseError && props.responseError.message}</Error>
            <SubmitButton />
          </form>
        </Box>
      </Container>
    </SimpleBlogPage>
  )
}
function Description(props: {children?: string}) {
  if (!props.children) {
    return null
  }

  return <Content>{props.children}</Content>
}

function Error(props: {children: string | null}) {
  if (!props.children) {
    return null
  }

  return <ErrorText color="error">{props.children}</ErrorText>
}

const ErrorText = withStyles({
  root: {
    marginBottom: spacing[3],
  },
})(Typography)

import React from 'react'
import grey from '@material-ui/core/colors/grey'
import {useEditMode} from 'Event/EditModeProvider'
import styled from 'styled-components'
import Configurable from 'organization/Event/Configurable'
import DividerConfig from 'Event/Sponsors/Divider/DividerConfig'

export default function Divider(props: {
  showingSeparator: boolean
  spacing: number
  isLast: boolean
}) {
  const {showingSeparator, isLast, spacing} = props
  const isEditMode = useEditMode()

  const hasDivider = showingSeparator && !isLast
  const showing = () => {
    if (props.isLast) {
      return false
    }

    if (isEditMode) {
      return true
    }

    return hasDivider
  }

  return (
    <Configurable aria-label="divider config">
      <DividerConfig />
      <StyledHr
        showing={showing()}
        isPlaceholder={!hasDivider}
        spacing={spacing}
      />
    </Configurable>
  )
}

const StyledHr = styled.hr<{
  showing: boolean
  isPlaceholder?: boolean
  spacing: number
}>`
  opacity: ${(props) => (props.showing ? 1 : 0)};
  margin: ${(props) => `${props.theme.spacing[props.spacing]} 0`};
  border: 1px ${(props) => (props.isPlaceholder ? 'dashed' : 'solid')}
    ${grey[300]};
`

import Skeleton from '@material-ui/lab/Skeleton'
import {useEvent} from 'Event/EventProvider'
import {Attendee} from 'Event/attendee'
import {useAsync} from 'lib/async'
import {formatIntegerPrice} from 'lib/currency'
import Checkbox from 'lib/ui/Checkbox'
import {PaginatedCollection} from 'lib/ui/api-client'
import {api} from 'lib/url'
import {useBroadcastForm} from 'organization/Event/Broadcasts/BroadcastForm'
import {useOrganization} from 'organization/OrganizationProvider'
import React, {useCallback} from 'react'

export default function AgreeToSmsChargesCheckbox() {
  const {channel} = useBroadcastForm()

  if (channel !== 'sms') {
    return null
  }

  return <Content />
}

function Content() {
  const {data: numAttendees} = useNumAttendees()
  const {data: chargeEstimate} = useSmsEstimate()

  const {hasAgreedToSmsCharges, setHasAgreedToSmsCharges} = useBroadcastForm()

  if (chargeEstimate === null || numAttendees === null) {
    return (
      <>
        <Skeleton />
        <Skeleton />
        <Skeleton width="60%" />
      </>
    )
  }

  const amount = formatIntegerPrice(chargeEstimate)

  const message = `I agree to schedule the card below to be charged $${amount} for this SMS Broadcast for an estimated ${numAttendees} Attendee(s).  If the number of attendees is different at the time of broadcast, additional fees may apply based on Obvio SMS Rates of $0.02 for US and Canadian phone numbers, or $0.10 for all other numbers.`

  return (
    <Checkbox
      checked={hasAgreedToSmsCharges}
      onChange={setHasAgreedToSmsCharges}
      label={message}
      aria-label="toggle agree to sms charges"
    />
  )
}

function useNumAttendees() {
  const url = useQueryUrl()

  const {client} = useOrganization()

  const request = useCallback(() => {
    if (!url) {
      return Promise.resolve(null)
    }

    return client.get<PaginatedCollection<Attendee>>(url).then((p) => p.total)
  }, [client, url])

  return useAsync(request)
}

function useQueryUrl() {
  const {attendeeQuery} = useBroadcastForm()

  const {event} = useEvent()

  const baseUrl = api(`/events/${event.id}/attendees`)

  if (!attendeeQuery) {
    return baseUrl
  }

  return `${baseUrl}${attendeeQuery}`
}

function useSmsEstimate() {
  const url = useQuerySmsChargeUrl()

  const {client} = useOrganization()

  const request = useCallback(
    () =>
      client
        .get<{
          amount: number
          num_north_america: number
          num_international: number
        }>(url)
        .then((result) => result.amount),
    [client, url],
  )

  return useAsync(request)
}

function useQuerySmsChargeUrl() {
  const {attendeeQuery} = useBroadcastForm()
  const {event} = useEvent()
  const baseUrl = api(`/events/${event.id}/sms_broadcast/estimate`)

  return `${baseUrl}${attendeeQuery}`
}

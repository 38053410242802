import {useTemplate} from 'Event/TemplateProvider'
import {useSaveTemplate} from 'Event/TemplateUpdateProvider'
import {onChangeCheckedHandler} from 'lib/dom'
import Switch from 'lib/ui/form/Switch'
import InputLabel from 'lib/ui/InputLabel'
import Slider from 'lib/ui/Slider'
import {useConfig} from 'organization/Event/Configurable'
import Config from 'organization/Event/Configurable/Config'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import React from 'react'
import {Controller} from 'react-hook-form'

export default function DividerConfig() {
  const save = useSaveTemplate()
  const {sponsors} = useTemplate()

  if (!('hasDivider' in sponsors)) {
    throw new Error(
      "Missing 'hasDivider' value for Sponsors/DividerConfig; was <DividerConfig/> included for a template that should not have a divider?",
    )
  }

  const {
    form: {control},
  } = useConfig()

  return (
    <Config title="Divider" onSave={save}>
      <SettingsPanel>
        <InputLabel>Show Separator</InputLabel>
        <Controller
          name="sponsors.hasDivider"
          defaultValue={sponsors.hasDivider}
          control={control}
          render={({onChange, value}) => (
            <Switch
              onChange={onChangeCheckedHandler(onChange)}
              checked={value}
              labelPlacement="end"
              aria-label="toggle divider"
            />
          )}
        />
        <Controller
          name="sponsors.spacing"
          defaultValue={sponsors.spacing}
          control={control}
          render={({onChange, value}) => (
            <Slider
              min={0}
              max={10}
              step={1}
              onChange={onChange}
              valueLabelDisplay="auto"
              value={value}
              hideUnits
              label="Space Between Sponsors"
            />
          )}
        />
      </SettingsPanel>
    </Config>
  )
}

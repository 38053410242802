import {RuleConfigProps} from 'Event/attendee-rules/RuleConfig/RuleList/RuleForm/SourceConfig'
import {onChangeDate} from 'lib/dom'
import React, {useEffect, useState} from 'react'
import {Rule} from 'Event/attendee-rules'
import LocalizedDateTimePicker from 'lib/LocalizedDateTimePicker'
import {
  createTimeRule,
  TIME,
  TimeRule,
} from 'Event/attendee-rules/RuleConfig/RuleList/SingleRule/TimeRule'

export default function TimeRuleConfig(props: RuleConfigProps) {
  const {onSet} = props
  const [from, setFrom] = useState<TimeRule['from']>(initialFrom(props.rule))
  const [to, setTo] = useState<TimeRule['to']>(initialTo(props.rule))

  useEffect(() => {
    const isValid = to || from
    const rule = isValid ? createTimeRule(from, to) : null
    onSet(rule)
  }, [from, to, onSet])

  const onFromClear = () => () => {
    setFrom(null)
  }

  const onTolear = () => () => {
    setTo(null)
  }

  return (
    <>
      <LocalizedDateTimePicker
        label="Show From"
        value={from}
        onChange={onChangeDate(setFrom)}
        fullWidth
        inputProps={{
          'aria-label': 'show from',
          onFromClear, // to manually fire events in tests
        }}
        onClear={onFromClear()}
      />
      <LocalizedDateTimePicker
        label="Show Until"
        value={to}
        onChange={onChangeDate(setTo)}
        fullWidth
        inputProps={{
          'aria-label': 'show until',
          onTolear, // to manually fire events in tests
        }}
        onClear={() => {
          setTo(null)
        }}
      />
    </>
  )
}

function initialFrom(rule: Rule | null) {
  if (!rule || rule.source !== TIME) {
    return null
  }

  return rule.from
}

function initialTo(rule: Rule | null) {
  if (!rule || rule.source !== TIME) {
    return null
  }

  return rule.to
}

import styled from 'styled-components'
import React from 'react'
import {
  DragDropContext,
  Droppable,
  DroppableProvidedProps,
  DropResult,
} from 'react-beautiful-dnd'
import Grid from '@material-ui/core/Grid'
import {useEditMode} from 'Event/EditModeProvider'
import CountDownTimer from 'Event/template/Townhall/Dashboard/Main/CountdownTimerSection/CountdownTimers/CountdownTimer'
import NewCountDownTimerButton from 'Event/template/Townhall/Dashboard/Main/CountdownTimerSection/CountdownTimers/CountdownTimer/NewCountDownTimerButton'
import {useHasCountDownTimers} from 'lib/countdown-timers'
import {createPositions, orderedIdsByPosition} from 'lib/list'
import {CountdownTimerSectionProps} from 'Event/template/Townhall/Dashboard/Main/CountdownTimerSection'
import {useSaveTemplate} from 'Event/TemplateUpdateProvider'

export default function CountDownTimers(
  props: CountdownTimerSectionProps & {sectionId: string; className?: string},
) {
  const {items: countDownTimers} = props
  const isEditMode = useEditMode()

  const hasTimers = useHasCountDownTimers(countDownTimers)
  const ids = orderedIdsByPosition(countDownTimers)

  const timers = ids.map((id, index) => (
    <CountDownTimer
      id={id}
      index={index}
      key={id}
      countDownTimer={countDownTimers[id]}
      sectionId={props.sectionId}
    />
  ))

  if (isEditMode) {
    return <Editable {...props}>{timers}</Editable>
  }

  if (!hasTimers) {
    return null
  }

  return <Container {...props}>{timers}</Container>
}

function Editable(
  props: CountdownTimerSectionProps & {
    sectionId: string
    className?: string
    children: React.ReactElement[]
  },
) {
  const handleDrag = useHandleDrag(props)

  return (
    <DragDropContext onDragEnd={handleDrag}>
      <Droppable droppableId="countdown_timers">
        {(provided) => (
          <Container
            className={props.className}
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            <>
              {props.children}
              {provided.placeholder}
              <StyledNewCountDownTimer sectionId={props.sectionId} />
            </>
          </Container>
        )}
      </Droppable>
    </DragDropContext>
  )
}

const Container = React.forwardRef<
  HTMLDivElement,
  {
    className?: string
    children: React.ReactElement | React.ReactElement[]
  } & Partial<DroppableProvidedProps>
>((props, ref) => (
  <Box className={props.className} ref={ref} {...props}>
    <Grid container justify="center" spacing={2}>
      {props.children}
    </Grid>
  </Box>
))

function useHandleDrag(
  props: CountdownTimerSectionProps & {
    sectionId: string
  },
) {
  const {items, sectionId} = props

  const save = useSaveTemplate()

  return (result: DropResult) => {
    const {destination, source} = result

    if (!destination) {
      return
    }

    const ids = orderedIdsByPosition(items)
    const [removed] = ids.splice(source.index, 1)
    ids.splice(destination.index, 0, removed)

    save({
      dashboardSections: {
        [sectionId]: {
          items: createPositions(ids),
        },
      },
    })
  }
}

const Box = styled.div`
  margin-bottom: ${(props) => props.theme.spacing[7]};
  margin-top: ${(props) => props.theme.spacing[7]};
  width: 100%;
`

const StyledNewCountDownTimer = styled(NewCountDownTimerButton)`
  padding-top: ${(props) => props.theme.spacing[2]}!important;
`

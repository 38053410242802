import {useEvent} from 'Event/EventProvider'
import {useAsync} from 'lib/async'
import {client} from 'lib/ui/api-client'
import FullPageLoader from 'lib/ui/layout/FullPageLoader'
import {communicationsApi} from 'lib/url'
import React, {useCallback, useEffect, useState} from 'react'

export type BroadcastBase = {
  id: number
  name: string
  send_at: string
  distribution_minutes: number
  sent: boolean
  is_draft: boolean
  payment_method_id: string | null
}

export type BroadcastUnfiltered = BroadcastBase & {
  attendee_segment: 'all' | 'checked_in' | 'not_checked_in'
}

export type BroadcastFiltered = BroadcastBase & {
  attendee_segment: 'filtered'
  filter_query: string | null
}

export type BroadcastAll = BroadcastUnfiltered | BroadcastFiltered

export type Segment = BroadcastAll['attendee_segment']

export type BroadcastEmail = BroadcastAll & {
  channel: {
    name: 'email'
    from_name: string
    from_email: string
    body: string
    subject: string
  }
}

export type BroadcastSms = BroadcastAll & {
  channel: {
    name: 'sms'
    body: string
  }
}

export type Broadcast = BroadcastEmail | BroadcastSms

export type ChannelName = Broadcast['channel']['name']

export type BroadcastsContextProps = {
  add: (broadcast: Broadcast) => void
  update: (broadcast: Broadcast) => void
  remove: (broadcast: Broadcast) => void
  broadcasts: Broadcast[]
}

export const BroadcastsContext = React.createContext<
  BroadcastsContextProps | undefined
>(undefined)

export default function BroadcastsProvider(props: {
  children: React.ReactElement
}) {
  const {data: saved, loading} = useFetchBroadcasts()
  const [broadcasts, setBroadcasts] = useState<Broadcast[]>([])

  useEffect(() => {
    if (!saved) {
      return
    }

    const sorted = saved.sort(
      (a, b) => new Date(a.send_at).valueOf() - new Date(b.send_at).valueOf(),
    )
    setBroadcasts(sorted)
  }, [saved])

  const update = (broadcast: Broadcast) => {
    const updated = broadcasts.map((b) => {
      const isTarget = b.id === broadcast.id
      if (isTarget) {
        return broadcast
      }

      return b
    })

    setBroadcasts(updated)
  }

  const add = (broadcast: Broadcast) => {
    const appended = [...broadcasts, broadcast]
    setBroadcasts(appended)
  }

  const remove = (broadcast: Broadcast) => {
    const removed = broadcasts.filter((a) => a.id !== broadcast.id)
    setBroadcasts(removed)
  }

  if (loading) {
    return <FullPageLoader />
  }

  return (
    <BroadcastsContext.Provider
      value={{
        add,
        remove,
        update,
        broadcasts,
      }}
    >
      {props.children}
    </BroadcastsContext.Provider>
  )
}

function useFetchBroadcasts() {
  const {
    event: {id},
  } = useEvent()

  const request = useCallback(() => {
    const url = communicationsApi(`/events/${id}/broadcasts`)
    return client.get<Broadcast[]>(url)
  }, [id])

  return useAsync(request)
}

export function useBroadcasts() {
  const context = React.useContext(BroadcastsContext)
  if (context === undefined) {
    throw new Error('useBroadcasts must be used within an BroadcastsProvider')
  }

  return context
}

export function isEmailBroadcast(
  broadcast: Broadcast,
): broadcast is BroadcastEmail {
  return broadcast.channel.name === 'email'
}

import {BlogPost} from 'Event/Dashboard/components/BlogPosts'
import styled from 'styled-components'
import React, {useEffect, useState} from 'react'
import Dialog from 'lib/ui/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import Content from 'Event/Dashboard/components/BlogPosts/Content'
import {useEditMode} from 'Event/EditModeProvider'
import PostForm from 'Event/Dashboard/components/BlogPosts/PostForm'
import Buttons from 'Event/Dashboard/components/BlogPosts/Buttons'
import {useEvent} from 'Event/EventProvider'
import {useAttendeeVariables} from 'Event'

export default function WelcomePostDialog(props: {
  post: BlogPost
  postId: string
}) {
  const {post, postId} = props
  const isEditMode = useEditMode()
  const [open, setOpen] = useState(Boolean(post.isWelcomePost))
  const v = useAttendeeVariables()

  const shown = useShown(open, postId)

  const showing = open && !shown && !isEditMode

  return (
    <Dialog open={showing} onClose={() => setOpen(false)} fullWidth>
      <DialogTitle>{v(post.title)}</DialogTitle>
      <DialogContent>
        <StyledContent post={post} />
        <PostForm post={post} />
        <Buttons post={post} postId={postId} />
      </DialogContent>
    </Dialog>
  )
}

function useShown(open: boolean, postId: string) {
  const {event} = useEvent()
  const storageKey = `__obvio_${event.id}_viewed_welcome_post__`
  const val = localStorage.getItem(storageKey)

  const shown = val === postId
  const isEditMode = useEditMode()

  // Save to storage on open to prevent showing again
  useEffect(() => {
    if (!open) {
      return
    }

    if (shown) {
      return
    }

    localStorage.setItem(storageKey, postId)
  }, [storageKey, postId, shown, open])

  // If we're in edit mode, let's mark as not shown so the
  // user will see it again when they preview.
  useEffect(() => {
    if (!isEditMode) {
      return
    }

    localStorage.removeItem(storageKey)
  }, [isEditMode, storageKey])

  return shown
}

// The Dialog background is ALWAYS white, so we don't want to inherit
// the post color here in case the user has set a light colored
// background.
const StyledContent = styled(Content)`
  color: #000000 !important;
`

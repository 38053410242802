import React, {useEffect} from 'react'
import {useTemplate} from 'Event/TemplateProvider'
import {useAttendee, useEventAuth} from 'Event/auth'
import {usePlatformActions} from 'Event/ActionsProvider/platform-actions'
import {usePoints} from 'Event/PointsProvider'
import {User} from 'auth/user'
import {components} from 'Event/template'

const TECH_CHECK_POLL_SECS = 10

export type TechCheckProps = {
  user: User
}

export default function TechCheck() {
  const attendee = useEventAuth()
  const {completeCheckIn} = usePlatformActions()
  const {submit: submitAction} = usePoints()
  const user = useAttendee()

  /**
   * Poll to check if attendee has been marked as tech check completed.
   */
  useEffect(() => {
    const pollTimer = setInterval(attendee.refresh, TECH_CHECK_POLL_SECS * 1000)

    return () => {
      clearInterval(pollTimer)
    }
  }, [attendee])

  /**
   * Only want to submit action once: on completing tech-check.
   * ie. when this component unloads, so we'll explicitly
   * ignore all other dependencies.
   */
  useEffect(() => {
    return () => {
      if (completeCheckIn?.key) {
        submitAction(completeCheckIn.key)
      }
    }
    // eslint-disable-next-line
  }, [])

  return <TemplateTechCheck user={user} />
}

export function TemplateTechCheck(props: TechCheckProps) {
  const template = useTemplate()
  const Component = components[template.name].Step3.TechCheck
  return <Component {...props} />
}

import {useLeftyTemplate} from 'Event/template/Lefty'
import React from 'react'
import {Controller, UseFormMethods} from 'react-hook-form'
import ImageAssetUploader from 'lib/asset/ImageAssetUploader'
import Title from 'lib/ui/ConfigPanel/Title'

export default function Settings(props: {control: UseFormMethods['control']}) {
  const {control} = props
  const template = useLeftyTemplate()

  return (
    <>
      <Title>Logo</Title>
      <Controller
        name="featurePageHeader.logo"
        control={control}
        defaultValue={template.featurePageHeader.logo}
        render={({onChange, value}) => (
          <ImageAssetUploader
            onChange={onChange}
            value={value}
            uploadLabel="Upload"
            canResize
            removeButtonProps={{
              'aria-label': 'remove featured page logo',
            }}
            uploadInputProps={{
              'aria-label': 'featured page logo upload',
            }}
            disableAutoRemove
          />
        )}
      />
      <Title>Header Background Image</Title>
      <Controller
        name="featurePageHeader.backgroundImage"
        control={control}
        defaultValue={template.featurePageHeader.backgroundImage}
        render={({onChange, value}) => (
          <ImageAssetUploader
            onChange={onChange}
            value={value}
            uploadLabel="Upload"
            canResize
            uploadInputProps={{
              'aria-label': 'featured header background upload',
            }}
            disableAutoRemove
          />
        )}
      />
    </>
  )
}

import {User} from 'auth/user'
import styled from 'styled-components'
import SimpleBlogPage, {
  PageDescription,
  PageTitle,
} from 'Event/template/SimpleBlog/Page'
import React from 'react'
import {useSimpleBlogTemplate} from 'Event/template/SimpleBlog'
import {useAttendeeVariables} from 'Event'
import Configurable from 'organization/Event/Configurable'
import HeaderConfig from 'Event/Sponsors/HeaderConfig'
import Content from 'lib/ui/form/TextEditor/Content'
import DashboardLinkConfig from 'Event/template/SimpleBlog/Sponsors/DashboardLinkConfig'
import {Link} from 'react-router-dom'
import SponsorList from 'Event/template/SimpleBlog/Sponsors/SponsorList'
import AddSponsorButton from 'Event/Sponsors/AddSponsorButton'

export default function Sponsors(props: {user: User}) {
  const template = useSimpleBlogTemplate()
  const {sponsors} = template
  const v = useAttendeeVariables()

  return (
    <SimpleBlogPage user={props.user}>
      <Configurable>
        <HeaderConfig />
        <PageTitle aria-label="sponsors title">{v(sponsors.title)}</PageTitle>
      </Configurable>
      <PageDescription>
        <Content aria-label="sponsors description">
          {v(sponsors.description)}
        </Content>
      </PageDescription>
      <Configurable>
        <DashboardLinkConfig />
        <BackToDashboard color={sponsors.backToDashboardTextColor}>
          <Link to="/">{sponsors.backToDashboardText}</Link>
        </BackToDashboard>
      </Configurable>
      <SponsorList />
      <AddSponsorButton />
    </SimpleBlogPage>
  )
}

const BackToDashboard = styled.div`
  text-align: center;
  margin-bottom: 20px;
  a {
    line-height: 1.5;
    color: ${(props) => props.color};
  }
`

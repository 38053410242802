import React, {useState} from 'react'
import Dialog from 'lib/ui/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import {Attendee} from 'Event/attendee'
import {useValidatedForm} from 'lib/form'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import {useEvent} from 'Event/EventProvider'
import {api} from 'lib/url'
import styled from 'styled-components'

export default function AttendeeUpdateDialog(props: {
  attendee: Attendee | null
  onClose: () => void
  update: (attendee: Attendee) => void
}) {
  const {attendee} = props
  const isVisible = Boolean(props.attendee)

  if (!attendee) {
    return null
  }

  return (
    <Dialog open={isVisible} onClose={props.onClose}>
      <DialogTitle>Attendee</DialogTitle>
      <DialogContent>
        <Form
          attendee={attendee}
          closeDialog={props.onClose}
          updateAttendee={props.update}
        />
      </DialogContent>
    </Dialog>
  )
}

function useUpdate() {
  const {event, client} = useEvent()
  return (attendee: Attendee, data: Partial<Attendee>) => {
    const url = api(`/events/${event.id}/attendees/${attendee.id}`)
    return client.patch<Attendee>(url, data)
  }
}

function Form(props: {
  attendee: Attendee
  closeDialog: () => void
  updateAttendee: (attendee: Attendee) => void
}) {
  const {
    register,
    handleSubmit,
    setResponseError,
    clearErrors,
    errors,
  } = useValidatedForm()
  const [submitting, setSubmitting] = useState(false)
  const update = useUpdate()

  const {attendee} = props

  const submit = (data: Partial<Attendee>) => {
    if (submitting) {
      return
    }

    clearErrors()
    setSubmitting(true)

    update(props.attendee, data)
      .then((updated) => {
        props.updateAttendee(updated)
      })
      .catch(setResponseError)
      .finally(() => {
        setSubmitting(false)
        props.closeDialog()
      })
  }

  return (
    <form onSubmit={handleSubmit(submit)}>
      <TextField
        label="First Name"
        fullWidth
        variant="outlined"
        name="first_name"
        defaultValue={attendee?.first_name || ''}
        inputProps={{
          ref: register({
            required: 'First name is required',
          }),
          'aria-label': 'first name',
        }}
        error={Boolean(errors.first_name)}
        helperText={errors.first_name}
      />
      <TextField
        label="Last Name"
        fullWidth
        variant="outlined"
        name="last_name"
        defaultValue={attendee?.last_name || ''}
        inputProps={{
          ref: register({
            required: 'Last name is required',
          }),
          'aria-label': 'last name',
        }}
        error={Boolean(errors.last_name)}
        helperText={errors.last_name}
      />
      <SaveButton
        type="submit"
        variant="contained"
        fullWidth
        color="primary"
        size="large"
        disabled={submitting}
        aria-label="save"
      >
        Save
      </SaveButton>
    </form>
  )
}

const SaveButton = styled(Button)`
  margin-bottom: ${(props) => props.theme.spacing[5]};
`

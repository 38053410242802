import React, {useState} from 'react'
import styled from 'styled-components'
import Title from 'lib/ui/ConfigPanel/Title'
import Divider from 'lib/ui/ConfigPanel/Divider'

export default function CollapsibleSection(props: {
  label: string
  open?: boolean
  storageKey?: string
  noDivider?: boolean
  children: React.ReactElement | React.ReactElement[]
  hidden?: boolean
}) {
  const {hidden = false, storageKey, label, children, noDivider} = props
  const {open = getFromStorage(storageKey) !== '0'} = props
  const [isOpen, setIsOpen] = useState<boolean>(open)

  const toggleSetting = (openSetting: boolean) => {
    setIsOpen(openSetting)
    setInStorage(openSetting, storageKey)
  }

  if (hidden) {
    return null
  }

  const divider = noDivider ? null : <Divider />

  return (
    <>
      <TitleBar onClick={() => toggleSetting(!isOpen)}>
        <Title>{label}</Title>
        <Icon isOpen={isOpen} />
      </TitleBar>
      <Section isOpen={isOpen}>{children}</Section>
      {divider}
    </>
  )
}

const TitleBar = styled.div`
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  opacity: 0.6;
  transition: opacity 0.2s ease-in-out;

  &:hover {
    opacity: 1;
  }
`

const Icon = styled((props) => {
  const className = `${props.className}${
    props.isOpen ? ' isOpen' : ''
  } fas fa-chevron-down`

  return <i className={className} />
})<{
  isOpen: boolean
}>`
  height: 16px;
  transition: all 0.25s ease-in-out;
  transform-origin: center;

  &::before {
    position: relative;
    top: -4px;
  }

  &.isOpen {
    transform: rotate(540deg);
  }
`

const Section = styled((props) => {
  const {isOpen, children} = props
  const className = `${props.className}${isOpen ? ' isOpen' : ''}`

  return <div className={className}>{children}</div>
})<{
  className: string
  isOpen: boolean
  children: React.ReactElement | React.ReactElement[]
}>`
  padding: ${(props) => `${props.theme.spacing[2]} ${props.theme.spacing[5]}`};
  display: none;

  &.isOpen {
    display: block;
    animation-name: transition-in;
    animation-duration: 0.7s;
  }

  @keyframes transition-in {
    from {
      display: none;
      opacity: 0;
    }
    1% {
      display: block;
    }
    to {
      opacity: 1;
    }
  }
`

function getFromStorage(storageKey?: string) {
  if (!storageKey) {
    return false
  }

  return localStorage.getItem(storageKey)
}

function setInStorage(setting: boolean, storageKey?: string) {
  if (!storageKey) {
    return
  }

  localStorage.setItem(storageKey, setting ? '1' : '0')
}

import React from 'react'
import styled from 'styled-components'
import Grid from '@material-ui/core/Grid'
import {formatPrice} from 'lib/currency'
import Button from 'lib/ui/Button'
import {RelativeLink} from 'lib/ui/link/RelativeLink'
import {SubHead} from 'lib/ui/typography'
import Subheading from 'lib/ui/typography/Subheading'
import {PlanAddOns, PlanInfo} from 'obvio/Billing/plans'
import {Section} from 'organization/AddOns'
import {useOrganization} from 'organization/OrganizationProvider'

export default function PlanAddOnsSection(props: {plan: PlanInfo}) {
  const {plan} = props

  return (
    <>
      <Subheading>Available Plan Add-ons</Subheading>
      <Section>
        <AddOnRow
          header
          values={['Add-on', 'Description', 'Block Size', 'Duration', 'Price']}
        />
        <div>
          {Object.keys(plan.addOns).map((key) => (
            <StyledPlanAddOn detailKey={key} key={key} plan={plan} />
          ))}
        </div>
      </Section>
    </>
  )
}

function PlanAddOn(props: {
  plan: PlanInfo | null
  detailKey: string
  className?: string
}) {
  const {plan, detailKey} = props
  const addOnDetail = plan?.addOns[detailKey as keyof PlanAddOns]
  const {routes} = useOrganization()

  if (!addOnDetail) {
    return null
  }

  const duration = !addOnDetail.duration
    ? 'N/A'
    : `${addOnDetail.duration} Days`

  return (
    <AddOnRow
      className={props.className}
      values={[
        addOnDetail.details.displayName,
        addOnDetail.details.description,
        addOnDetail.block,
        duration,
        `$${formatPrice(addOnDetail.cost)}`,
        <RelativeLink
          to={`${routes.add_ons.purchase_add_on}?addon=${detailKey}`}
          disableStyles
          aria-label={`purchase ${detailKey} add-on button`}
        >
          <Button color="primary" variant="contained">
            Purchase
          </Button>
        </RelativeLink>,
      ]}
    />
  )
}

function AddOnRow(props: {
  values: (string | number | JSX.Element)[]
  header?: boolean
  className?: string
}) {
  const {className, header, values} = props

  return (
    <Grid container spacing={2} className={className}>
      <Grid xs={2} item>
        <RowValue header={header} value={values[0]} />
      </Grid>
      <Grid xs={4} item>
        <RowValue header={header} value={values[1]} />
      </Grid>
      <CenteredGrid xs={2} item>
        <RowValue header={header} value={values[2]} />
      </CenteredGrid>
      <CenteredGrid xs={2} item>
        <RowValue header={header} value={values[3]} />
      </CenteredGrid>
      <Grid xs={1} item>
        <RowValue header={header} value={values[4]} />
      </Grid>
      <Grid xs={1} item>
        <RowValue header={header} value={values[5]} />
      </Grid>
    </Grid>
  )
}

/**
 * Cleans up a value passed in into a blank string, if not defined. If we're
 * being told this is a header value, wraps the return in a <SubHead> element.
 */
function RowValue(props: {
  value?: string | number | JSX.Element
  header?: boolean
}) {
  const theValue = props.value || ''
  return props.header ? <SubHead>{theValue}</SubHead> : <>{theValue}</>
}

const CenteredGrid = styled(Grid)`
  text-align: center;
`

const StyledPlanAddOn = styled(PlanAddOn)`
  padding-bottom: ${(props) => props.theme.spacing[4]};
  margin-bottom: ${(props) => props.theme.spacing[4]};

  &:not(:last-child) {
    border-bottom: solid 1px ${(props) => props.theme.colors.gray700};
  }
`

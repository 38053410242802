import React from 'react'
import Page, {
  DescriptionText,
  StyledPaper,
  StyledFormContainer,
} from 'Event/template/Cards/Login/Page'
import {useGuestVariables} from 'Event'
import {TitleBase} from 'Event/template/Cards/Login/Title'
import Configurable from 'organization/Event/Configurable'
import OfflineContentConfig from 'Event/EventOfflinePage/OfflineContentConfig'
import {OfflinePageProps} from 'Event/JoinArea/OfflinePage'
import {useCardsTemplate} from 'Event/template/Cards'

export default function EventOfflinePage(props: OfflinePageProps) {
  const v = useGuestVariables()
  const template = useCardsTemplate()
  const {linkColor, linkUnderline} = template

  return (
    <Page isPreview={props.isPreview || false}>
      <Configurable
        aria-label="event offline content config"
        dataTestId="event offline config"
      >
        <OfflineContentConfig />
        <StyledPaper>
          <StyledFormContainer>
            <TitleBase>{v(props.title)}</TitleBase>
            <DescriptionText
              linkColor={linkColor}
              linkUnderline={linkUnderline}
            >
              <div dangerouslySetInnerHTML={{__html: v(props.description)}} />
            </DescriptionText>
          </StyledFormContainer>
        </StyledPaper>
      </Configurable>
    </Page>
  )
}

import React from 'react'
import {Link} from 'react-router-dom'
import Page from 'Event/template/SimpleBlog/Page'
import styled from 'styled-components'
import FaqList from 'Event/template/SimpleBlog/FAQs/FaqList'
import {User} from 'auth/user'
import {PageTitle, PageDescription} from 'Event/template/SimpleBlog/Page'
import {useAttendeeVariables} from 'Event'
import {useSimpleBlogTemplate} from 'Event/template/SimpleBlog'
import Content from 'lib/ui/form/TextEditor/Content'
import Configurable from 'organization/Event/Configurable'
import BackToDashboardConfig from 'Event/template/SimpleBlog/FAQs/BackToDashboardConfig'
import AddFaqButton from 'Event/FAQs/AddFaqButton'
import TitleConfig from 'Event/FAQs/TitleConfig'
import DescriptionConfig from 'Event/FAQs/DescriptionConfig'

export default function SimpleBlogFaqPage(props: {user: User}) {
  const template = useSimpleBlogTemplate()
  const {faq: pageSettings} = template
  const v = useAttendeeVariables()

  return (
    <Page user={props.user}>
      <AddFaqButton />
      <Configurable>
        <TitleConfig />
        <PageTitle aria-label="faqs title">{v(pageSettings?.title)}</PageTitle>
      </Configurable>
      <Configurable>
        <DescriptionConfig />
        <PageDescription>
          <Content aria-label="description">
            {v(pageSettings?.description)}
          </Content>
        </PageDescription>
      </Configurable>
      <Configurable>
        <BackToDashboardConfig />
        <BackToDashboard color={pageSettings?.backToDashboardTextColor}>
          <Link to="/">{v(pageSettings?.backToDashboardText)}</Link>
        </BackToDashboard>
      </Configurable>
      <FaqList />
    </Page>
  )
}

const BackToDashboard = styled.div`
  text-align: center;
  margin-bottom: 20px;
  a {
    line-height: 1.5;
    color: ${(props) => props.color};
  }
`

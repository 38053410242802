import React from 'react'
import {UseFormMethods} from 'react-hook-form'
import {NavButtonWithSize} from 'Event/Dashboard/components/NavButton'
import {CardsNavButtonProps} from 'Event/template/Cards/Dashboard/CardsNavButton'
import TypographySection from 'Event/Dashboard/components/NavButton/NavButtonConfig/BaseConfig/Styling/TypographySection'
import BackgroundSection from 'Event/Dashboard/components/NavButton/NavButtonConfig/BaseConfig/Styling/BackgroundSection'

export default function BaseStyling(props: {
  control: UseFormMethods['control']
  watch: UseFormMethods['watch']
  button: NavButtonWithSize | CardsNavButtonProps
  customStyling?: JSX.Element | boolean
}) {
  const {control, button, watch, customStyling} = props

  if (customStyling) {
    return <>{customStyling}</>
  }

  return (
    <>
      <TypographySection
        button={button}
        control={control}
        storageKey="mainNav.config.styling.typography"
      />
      <BackgroundSection
        button={button}
        control={control}
        watch={watch}
        storageKey="mainNav.config.styling.background"
      />
    </>
  )
}

import {Template} from 'Event/template'
import {flatten} from 'lib/object'
import {DeepPartialSubstitute} from 'lib/type-utils'
import React, {useState, useCallback, useEffect, useMemo} from 'react'
import setAtPath from 'lodash/set'
import unsetAtPath from 'lodash/unset'
import getAtPath from 'lodash/get'
import {useOrganization} from 'organization/OrganizationProvider'
import {useEvent} from 'Event/EventProvider'
import {api} from 'lib/url'
import {ObvioEvent} from 'Event'
import {useEventSocket} from 'organization/Event/EventSocketProvider'
import moment from 'moment'
import {useDispatch} from 'react-redux'
import {setIsConnected} from 'Event/Dashboard/editor/state/actions'
import {clone} from 'ramda'

// Updated template event broadcasted by Laravel via
// Pusher
const UPDATED_TEMPLATE = '.template.updated'

// Updated data sent via Pusher to notify other users of any updates
type TemplateUpdate = {
  updated_at: string
  template: KeyPaths
}

// When we want the server to remove a key, we'll set it to a
// specific remove value.
export const REMOVE = '__REMOVE__'

export type TemplateSave<T> = (
  updates: DeepPartialSubstitute<T, typeof REMOVE>,
) => void

const TemplateUpdateContext = React.createContext<
  TemplateSave<Template> | undefined
>(undefined)

// A dictionary of the updated template keys, and their
// respective values.
type KeyPaths = Record<string, any>

export default function TemplateUpdateProvider(props: {
  children: (template: Template) => React.ReactElement
  template: Template
}) {
  const {template: saved} = props
  const [current, setCurrent] = useState(saved)
  const save = useSave()

  // If the template has been changed entirely, such as moving
  // from SimpleBlog -> Cards, then we'll replace the
  // current template.
  useEffect(() => {
    if (saved.name === current.name) {
      return
    }

    setCurrent(saved)
  }, [saved, current])

  const {channel} = useEventSocket()
  const dispatch = useDispatch()

  // Updates are a dictionary of all successful property updates,
  // and their respective timestamps.
  const updates: Record<string, string> = useMemo(() => ({}), [])

  // Filter update data to only return keys that are NEWER than any updates
  // we've alredy saved. This prevents any flickering we may see if our
  // save went through, but an older update comes through after.
  const onlyNewUpdates = useCallback(
    (data: TemplateUpdate) =>
      Object.entries(data.template).reduce((acc, [key, val]) => {
        const lastSaved = updates[key]
        if (!lastSaved) {
          acc[key] = val
          return acc
        }

        const lastSavedTime = moment(lastSaved)
        const updateTime = moment(data.updated_at)
        const isNewer = updateTime.isAfter(lastSavedTime)

        // We already have a save that should be newer than this, so
        // let's ignore it.
        if (!isNewer) {
          return acc
        }

        acc[key] = val
        return acc
      }, {} as KeyPaths),
    [updates],
  )

  // Since the last write wins, we'll record the timestamp on any successful save.
  // This way if any updates via Pusher come in for previous saves, we can
  // safely ignore, knowing our saved value will eventually arrive.
  const recordUpdates = useCallback(
    (lastSaved: string, keyPaths: KeyPaths) => {
      for (const key of Object.keys(keyPaths)) {
        updates[key] = lastSaved
      }
    },
    [updates],
  )

  // Set the values in the template for the given keypaths.
  const set = useCallback(
    (keyPaths: KeyPaths) => {
      // We're using lodash's _.set() func here which unfortunately is a
      // mutating function, so we'll need to clone the template before
      // modifying it to avoid potential shared state bugs. Note
      // we're using Ramda's clone, because Lodash's had a
      // weird bug that wouldn't clone an empty object.
      const updated = clone(current)

      for (const [keyPath, val] of Object.entries(keyPaths)) {
        if (val === REMOVE) {
          unsetAtPath(updated, keyPath)
          continue
        }
        setAtPathWithObjectCasting(updated, keyPath, val)
      }

      setCurrent(updated)
    },
    [current],
  )

  // Update the template, this is where the type-safety checks happen, so this
  // should be the only exposed method to other parts of the app.
  const update: TemplateSave<typeof current> = useCallback(
    (updates) => {
      const keyPaths = flatten(updates)
      set(keyPaths)
      save(keyPaths)
        .then(({updated_at}) => recordUpdates(updated_at, keyPaths))
        .catch(() => {
          // Save failed for some reason, let's force a user refresh
          dispatch(setIsConnected(false))
        })
    },
    [dispatch, recordUpdates, set, save],
  )

  // Handle live updates from server
  useEffect(() => {
    channel.listen(UPDATED_TEMPLATE, (data: TemplateUpdate) => {
      const newUpdates = onlyNewUpdates(data)
      recordUpdates(data.updated_at, newUpdates)
      set(newUpdates)
    })

    return () => {
      channel.stopListening(UPDATED_TEMPLATE)
    }
  }, [channel, onlyNewUpdates, recordUpdates, set])

  return (
    <TemplateUpdateContext.Provider value={update}>
      {props.children(current)}
    </TemplateUpdateContext.Provider>
  )
}

/**
 * Sets a value on an object at the given key path. This is an extension that
 * will automatically convert an [] into a {} if you are trying to set a
 * key that is a string.
 *
 * Fixes a bug where the server may cast objects, such as `speakers.items` from {} -> [].
 * When we call `setAtPath` on an array with a string it attempts to use it as an
 * array index which results in weird downstream.
 */
export function setAtPathWithObjectCasting<T extends Record<string, any>>(
  object: T,
  path: string,
  value: any,
): T {
  // keypaths are in the format `speakers.items.{uuid}.name`
  const parts = path.split('.')
  const key = parts[parts.length - 2] // {uuid}

  // If the last key is a number, we'll assume no casting is required as
  // we're only worried about setting a string on an [].
  const isNumberKey = Number.isInteger(Number(key))
  if (isNumberKey) {
    setAtPath(object, path, value)
    return object
  }

  const isList = parts.length > 2 // at least 3 parts
  if (!isList) {
    setAtPath(object, path, value)
    return object
  }

  // Finally, we want to check if we're setting a string on an Array[] here,
  // and if we are, let's convert it into an object{} before we do.
  const listKey = parts.filter((_p, i) => i < parts.length - 2).join('.')
  const listVal = getAtPath(object, listKey)
  if (Array.isArray(listVal) && listVal.length === 0) {
    setAtPath(object, listKey, {})
  }

  setAtPath(object, path, value)
  return object
}

function useSave() {
  const {client} = useOrganization()
  const {event} = useEvent()

  const url = api(`/events/${event.id}/template`)

  return useCallback(
    (keyPaths: Record<string, any>) =>
      client.put<ObvioEvent>(url, {template: keyPaths}),
    [client, url],
  )
}

export function useSaveTemplate<T = Template>() {
  const context = React.useContext(TemplateUpdateContext)
  if (context === undefined) {
    throw new Error(
      'useSaveTemplate must be used within a TemplateUpdateProvider',
    )
  }

  return (context as unknown) as TemplateSave<T>
}

// Hook that will automatically check if an item has been removed, but still
// has some dangling property set. This can occur if the position was
// set AFTER an item has been removed.
export function useRemoveIfEmpty(
  remove: () => void,
  item: Record<string, any>,
  options: {
    shouldSkip?: boolean
  } = {},
) {
  const {shouldSkip} = options
  useEffect(() => {
    if (shouldSkip) {
      return
    }

    // Currently only expecting a single property ('position') that could be
    // dangling since an inserted item should be persisted.
    const wasRemoved = Object.keys(item).length === 1
    if (wasRemoved) {
      remove()
    }
  }, [item, remove, shouldSkip])
}

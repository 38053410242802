import styled from 'styled-components'
import React from 'react'
import Card from 'organization/Event/Services/Apps/Card'
import {useEventRoutes} from 'organization/Event/EventRoutes'
import zapierLogo from 'organization/Event/Services/Apps/Zapier/logo.jpg'
import infusionsoftLogo from 'organization/Event/Services/Apps/Infusionsoft/logo.jpg'
import mailchimpLogo from 'organization/Event/Services/Apps/Mailchimp/logo.png'
import {
  INFUSIONSOFT,
  MAILCHIMP,
  ZAPIER,
  HUBSPOT,
  HIGHLEVEL,
  ACTIVE_CAMPAIGN,
  ONTRAPORT,
  CONVERT_KIT,
} from 'organization/Event/Services/ServicesProvider'
import hubspotLogo from 'organization/Event/Services/Apps/Hubspot/logo.jpg'
import highlevelLogo from 'organization/Event/Services/Apps/HighLevel/logo.jpg'
import activeCampaignLogo from 'organization/Event/Services/Apps/ActiveCampaign/logo.png'
import ontraportLogo from 'organization/Event/Services/Apps/Ontraport/logo.webp'
import convertKitLogo from 'organization/Event/Services/Apps/ConvertKit/logo.webp'

export const APPS = 'Apps'

export default function Apps() {
  const routes = useEventRoutes()

  return (
    <Grid>
      <Card
        service={ZAPIER}
        link={routes.services.zapier}
        logo={zapierLogo}
        color="#FF4A00"
      />
      <Card
        service={INFUSIONSOFT}
        label={'Keap'}
        link={routes.services.infusionsoft}
        logo={infusionsoftLogo}
        color="#36A735"
      />
      <Card
        service={MAILCHIMP}
        link={routes.services.mailchimp}
        logo={mailchimpLogo}
        color="#FFE01B"
      />
      <Card
        service={HUBSPOT}
        link={routes.services.hubspot}
        logo={hubspotLogo}
        color="#FFFFFF"
      />
      <Card
        service={HIGHLEVEL}
        link={routes.services.highlevel}
        logo={highlevelLogo}
        color="#f4f4f4"
      />
      <Card
        service={ACTIVE_CAMPAIGN}
        link={routes.services.active_campaign}
        logo={activeCampaignLogo}
        color="#345AAB"
      />
      <Card
        service={CONVERT_KIT}
        link={routes.services.convert_kit}
        logo={convertKitLogo}
        color="#345AAB"
      />
      <Card
        service={ONTRAPORT}
        link={routes.services.ontraport}
        logo={ontraportLogo}
        color="#000000"
      />
    </Grid>
  )
}

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: 1fr;
  grid-gap: ${(props) => props.theme.spacing[8]};

  @media (min-width: ${(props) => props.theme.breakpoints.sm}) {
    grid-template-columns: 1fr 1fr;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.lg}) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
`

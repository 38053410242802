import {useToggleArray} from 'lib/toggle'
import MenuItem from 'lib/ui/Menu/MenuItem'
import {api} from 'lib/url'
import {useOrganization} from 'organization/OrganizationProvider'
import React from 'react'
import {Script} from 'Event/Scripts'
import ScriptDialog from 'organization/Event/Scripts/AddScriptButton/ScriptDialog'
import ScriptForm, {SaveScriptData} from 'organization/Event/Scripts/ScriptForm'
import {useSaveTemplate} from 'Event/TemplateUpdateProvider'

export default React.forwardRef<
  HTMLLIElement,
  {
    script: Script
    id: string
  }
>((props, ref) => {
  const {script, id} = props
  const [showing, toggleShowing] = useToggleArray()

  return (
    <>
      <MenuItem
        onClick={toggleShowing}
        ref={ref}
        aria-label="edit embed script"
      >
        Edit
      </MenuItem>
      <EditDialog
        id={id}
        script={script}
        showing={showing}
        onClose={toggleShowing}
      />
    </>
  )
})

export function EditDialog(props: {
  showing: boolean
  onClose: () => void
  id: string
  script: Script
}) {
  const {showing, onClose, script, id} = props
  const update = useUpdateScript(script)

  const saveTemplate = useSaveTemplate()

  const handleSubmit = (data: SaveScriptData) =>
    update(data).then(({file_url}) => {
      saveTemplate({
        scripts: {
          [id]: {
            name: data.name,
            file: data.file,
            url: file_url,
            pages: data.pages,
          },
        },
      })
      onClose()
    })

  return (
    <ScriptDialog showing={showing} onClose={onClose} title={script.name}>
      <ScriptForm onSubmit={handleSubmit} script={script} />
    </ScriptDialog>
  )
}

function useUpdateScript(script: Script) {
  const {client} = useOrganization()

  return (data: {file: string}) => {
    const url = api(`/scripts/${script.file}`)
    return client.put<{file_url: string}>(url, {file_name: data.file})
  }
}

import React from 'react'
import Config from 'organization/Event/Configurable/Config'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import StylingPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/StylingPanel'
import {useConfig} from 'organization/Event/Configurable'
import {TemplateSave, useSaveTemplate} from 'Event/TemplateUpdateProvider'
import {Controller} from 'react-hook-form'
import {onChangeCheckedHandler} from 'lib/dom'
import ColorPicker from 'lib/ui/ColorPicker'
import Slider from 'lib/ui/Slider'
import {useTemplate} from 'Event/TemplateProvider'
import ImageAssetUploader from 'lib/asset/ImageAssetUploader'
import Switch from 'lib/ui/form/Switch'
import {usePruneAssets} from 'lib/asset'
import {Template} from 'Event/template'

export default function BackgroundConfig() {
  const save = useSaveTemplate()
  const {login} = useTemplate()
  const pruneAssets = usePruneAssets()

  const {
    form: {control},
  } = useConfig()

  const handleSave: TemplateSave<Template> = (updates) => {
    // Kinda lame, but to keep TS happy we have to check that login is in fact
    // an object, and not '__REMOVE__' value.
    if (typeof updates.login === 'object') {
      pruneAssets(
        {
          background: updates.login.background,
        },
        login,
      )
    }

    save(updates)
  }

  return (
    <Config title="Background" onSave={handleSave}>
      <SettingsPanel>
        <Controller
          name="login.backgroundEnabled"
          control={control}
          defaultValue={login.backgroundEnabled}
          render={({value, onChange}) => (
            <Switch
              checked={value}
              onChange={onChangeCheckedHandler(onChange)}
              arial-label="toggle login background"
              labelPlacement="end"
              label={
                value ? 'Background Image Enabled' : 'Background Image Disabled'
              }
            />
          )}
        />
        <Controller
          name="login.background"
          control={control}
          defaultValue={login.background}
          render={({onChange, value}) => (
            <ImageAssetUploader
              onChange={onChange}
              value={value}
              uploadLabel="Upload"
              uploadInputProps={{
                'aria-label': 'login background input',
              }}
              width={1920}
              height={1200}
              canResize
              disableAutoRemove
            />
          )}
        />
      </SettingsPanel>
      <StylingPanel>
        <Controller
          name="login.backgroundColor"
          defaultValue={login.backgroundColor}
          control={control}
          render={({value, onChange}) => (
            <ColorPicker
              label="Background Color"
              color={value}
              onPick={onChange}
              aria-label="login background color"
            />
          )}
        />
        <Controller
          name="login.backgroundOpacity"
          defaultValue={login.backgroundOpacity}
          control={control}
          render={({value, onChange}) => (
            <Slider
              valueLabelDisplay="auto"
              aria-label="login background opacity"
              value={value}
              valueLabelFormat={() => <div>{value * 100}</div>}
              onChange={onChange}
              step={0.01}
              min={0}
              max={1}
              label="Background Opacity"
            />
          )}
        />
      </StylingPanel>
    </Config>
  )
}

import {now} from 'lib/date-time'
import {getLocalStorageItem} from 'lib/localStorage'

type AuthToken = string

const LAST_LOGGED_OUT_TIME = '__last_logged_out_time__'

export function getToken(key: string): AuthToken | null {
  return getLocalStorageItem(key)
}

export function saveToken(key: string, token: string) {
  return new Promise<void>((resolve) => {
    window.localStorage.setItem(key, token)
    resolve()
  })
}

export function deleteToken(key: string) {
  return window.localStorage.removeItem(key)
}

// Storing a timestamp of when the user clicks the "logout" link. This is used
// for "deep link" remembering of a requested location, we don't care to remember
// a request immediately after a user clicks "logout".
export function setLastLoggedOut() {
  return window.localStorage.setItem(LAST_LOGGED_OUT_TIME, now())
}

export function getLastLoggedOut() {
  return getLocalStorageItem(LAST_LOGGED_OUT_TIME)
}

export function clearLastLoggedOut() {
  return window.localStorage.removeItem(LAST_LOGGED_OUT_TIME)
}

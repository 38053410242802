import React from 'react'
import {useTemplate} from 'Event/TemplateProvider'
import {useEvent} from 'Event/EventProvider'
import {api} from 'lib/url'
import {useChangePassword} from 'auth/password/change'
import {components} from 'Event/template'
import Scripts, {CHANGE_PASSWORD} from 'Event/Scripts'
import {useAttendee} from 'Event/auth'
import {User} from 'auth/user'

export default function ChangePassword() {
  const user = useAttendee()

  return (
    <>
      <Scripts page={CHANGE_PASSWORD} />
      <TemplateChangePassword user={user} />
    </>
  )
}

export function TemplateChangePassword(props: {user: User}) {
  const {name} = useTemplate()
  const {event, client} = useEvent()
  const changePassword = useChangePassword(
    client,
    api(`/events/${event.id}/password`),
  )

  const Component = components[name].ChangePassword.index

  return (
    <Component {...props} user={props.user} changePassword={changePassword} />
  )
}

import {useWaiver} from 'Event/Step2/Waivers/Waiver'
import {useSaveTemplate} from 'Event/TemplateUpdateProvider'
import TextEditor, {TextEditorContainer} from 'lib/ui/form/TextEditor'
import {useConfig} from 'organization/Event/Configurable'
import Config from 'organization/Event/Configurable/Config'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import React from 'react'
import {Controller} from 'react-hook-form'

export default function SignaturePromptConfig() {
  const save = useSaveTemplate()
  const {
    form: {control},
  } = useConfig()

  const {waiver, id} = useWaiver()

  return (
    <Config title="Signature Prompt" onSave={save}>
      <SettingsPanel>
        <TextEditorContainer>
          <Controller
            name={`waiver.targets.${id}.signaturePrompt`}
            defaultValue={waiver.signaturePrompt}
            control={control}
            render={({value, onChange}) => (
              <TextEditor data={value} onChange={onChange} />
            )}
          />
        </TextEditorContainer>
      </SettingsPanel>
    </Config>
  )
}

import React from 'react'
import styled from 'styled-components'
import {useEvent} from 'Event/EventProvider'
import {useOrganization} from 'organization/OrganizationProvider'
import {api} from 'lib/url'
import EmojiSelect from 'Event/Dashboard/components/EmojiList/EmojiSelect'
import EmojiUpload from 'Event/Dashboard/components/EmojiList/EmojiUpload'
import {
  DEFAULT_EMOJIS,
  createCustomEmoji,
  Emoji,
  isCustom,
} from 'Event/Dashboard/components/EmojiList/emoji'
import CloseIcon from '@material-ui/icons/Close'
import IconButton from 'lib/ui/IconButton'
import Label from 'lib/ui/TextField/Label'
import TextField from 'lib/ui/TextField'
import {useTownhallTemplate} from 'Event/template/Townhall'
import {useConfig} from 'organization/Event/Configurable'

export default function Settings(props: {
  emojis: string[]
  addNewEmoji: (emoji: Emoji['name']) => void
  updateEmoji: (index: number) => (target: Emoji['name']) => void
  setEmojis: (value: string[]) => void
}) {
  const {emojis, addNewEmoji, updateEmoji, setEmojis} = props

  const {
    form: {register},
  } = useConfig()

  const template = useTownhallTemplate()
  const deleteFile = useDeleteFile()
  const customEmojis = emojis.filter(isCustom).map(createCustomEmoji)
  const availableEmojis = [...DEFAULT_EMOJIS, ...customEmojis]

  const removeEmoji = (index: number) => () => {
    const emoji = emojis[index]
    const isLast = emojis.filter((e) => e === emoji).length === 1
    if (isCustom(emoji) && isLast) {
      deleteFile(emoji)
    }

    const removed = emojis.filter((e, i) => i !== index)
    setEmojis(removed)
  }

  return (
    <>
      <Label>Main Menu Item Title</Label>
      <TextField
        name="navBar.mainMenuItemTitle"
        defaultValue={template.navBar.mainMenuItemTitle}
        aria-label="update main menu item title"
        fullWidth
        inputProps={{
          ref: register,
        }}
      />
      {emojis.map((emoji, index) => (
        <Existing key={index}>
          <EmojiSelect
            emojis={availableEmojis}
            value={emoji}
            onPick={updateEmoji(index)}
          />
          <RemoveButton aria-label="remove emoji" onClick={removeEmoji(index)}>
            <CloseIcon color="error" />
          </RemoveButton>
        </Existing>
      ))}
      <EmojiSelect
        dark
        value=""
        emojis={availableEmojis}
        onPick={addNewEmoji}
      />
      <EmojiUpload onSuccess={addNewEmoji} />
    </>
  )
}

const Existing = styled.div`
  display: flex;
  align-items: center;
`

const RemoveButton = styled(IconButton)`
  margin-left: ${(props) => props.theme.spacing[2]};
  margin-top: ${(props) => props.theme.spacing[3]};
`

function useDeleteFile() {
  const {event} = useEvent()
  const {client} = useOrganization()

  return (emoji: string) => {
    const url = api(`/events/${event.id}/emojis/${emoji}`)
    return client.delete(url)
  }
}

import styled from 'styled-components'
import React from 'react'
import {background, useNiftyFiftyTemplate} from 'Event/template/NiftyFifty'
import {rgba} from 'lib/color'
import {StepLogo} from 'Event/template/NiftyFifty/check-in/LeftPanel'

export default function MobilePanel(props: {children: React.ReactElement}) {
  const template = useNiftyFiftyTemplate()
  const {
    checkInRightPanel,
    checkInLeftPanel,
    checkInLogo,
    checkInBackground,
  } = template

  return (
    <Box>
      <LogoBox
        background={background({
          enabled: checkInBackground.enabled,
          image: checkInBackground.image,
          color: checkInLeftPanel.backgroundColor,
          opacity: checkInLeftPanel.backgroundOpacity,
        })}
      >
        <StepLogo
          src={checkInLogo.image}
          size={checkInLogo.size}
          enabled={checkInLogo.enabled}
        />
      </LogoBox>
      <Panel
        backgroundColor={rgba(
          checkInRightPanel.backgroundColor,
          checkInRightPanel.backgroundOpacity,
        )}
      >
        {props.children}
      </Panel>
    </Box>
  )
}

const Box = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  overflow: auto;
`

const LogoBox = styled.div<{
  background: string
}>`
  background: ${(props) => props.background};
  text-align: center;
  background-size: cover;
  display: flex;
  align-items: center;
  padding: ${(props) => props.theme.spacing[18]} 0;
  width: 100%;
`

const Panel = styled.div<{
  backgroundColor: string
}>`
  flex: 1;
  display: flex;
  justify-content: center;
  background: ${(props) => props.backgroundColor};
  width: 100%;
  align-items: flex-start;
  padding-top: ${(props) => props.theme.spacing[16]};

  @media (min-width: ${(props) => props.theme.breakpoints.sm}) {
    width: 100%;
  }
`

import React from 'react'
import TagInput, {
  TagInputProps,
} from 'organization/Event/Services/Apps/Hubspot/Config/TagInput'
import HubspotPropertiesProvider from 'organization/Event/Services/Apps/Hubspot/Config/HubspotPropertiesProvider'

export default function HubspotTagInput(
  props: Omit<TagInputProps, 'targetTypes'>,
) {
  return (
    <HubspotPropertiesProvider>
      <TagInput {...props} targetTypes={['text', 'checkbox']} />
    </HubspotPropertiesProvider>
  )
}

import React from 'react'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import styled from 'styled-components'
import {Label} from 'lib/ui/typography'
import MultilineTextField from 'lib/ui/TextField/TextArea'
import {UseFormMethods} from 'react-hook-form'
import {useTemplate} from 'Event/TemplateProvider'

export default function Settings(props: {
  register: UseFormMethods['register']
}) {
  const template = useTemplate()
  const {register} = props

  return (
    <>
      <Box mb={4}>
        <Typography color="error" variant="h6">
          Important
        </Typography>

        <List>
          <li>
            <strong>
              Only insert HTML from trusted sources to avoid data leaks, or
              other malicious behavior.
            </strong>
          </li>
          <li>Turn on preview mode to insert HTML in editor.</li>
          <li>If the HTML crashes the app, refresh to try again.</li>
        </List>
      </Box>
      <Label>HTML</Label>
      <MultilineTextField
        name="bodyHTMLEmbed"
        defaultValue={template.bodyHTMLEmbed || ''}
        rows={10}
        fullWidth
        inputProps={{
          'aria-label': 'body embed html input',
          ref: register,
        }}
      />
    </>
  )
}

const List = styled.ul`
  padding-left: ${(props) => props.theme.spacing[5]};
  margin: 0;
`

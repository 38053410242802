import React from 'react'
import styled from 'styled-components'
import withStyles from '@material-ui/core/styles/withStyles'
import {spacing} from 'lib/ui/theme'
import Button from '@material-ui/core/Button'
import {HeroConfig} from 'Event/template/Lefty/Dashboard/Hero/HeroConfig'
import {useAttendeeVariables} from 'Event'
import {useLeftyTemplate} from 'Event/template/Lefty'
import {useToggle} from 'lib/toggle'
import EditModeOnly from 'Event/Dashboard/editor/views/EditModeOnly'
import {Font, fontStyles, useLoadFont} from 'lib/FontSelect'
import {WELCOME_TEXT_ALIGN} from 'Event/template/Lefty'
import {
  WELCOME_TEXT_ALIGN_LEFT,
  WELCOME_TEXT_ALIGN_CENTER,
} from 'Event/template/Lefty/Dashboard/Hero/HeroConfig/Styling'

export default function Hero() {
  return (
    <>
      <EditModeOnly>
        <EditButton />
      </EditModeOnly>
      <WelcomeText />
    </>
  )
}

function EditButton() {
  const {flag: configVisible, toggle: toggleConfig} = useToggle()

  return (
    <>
      <HeroConfig showing={configVisible} onClose={toggleConfig} />
      <StyledEditButton
        onClick={toggleConfig}
        fullWidth
        size="large"
        variant="outlined"
        color="primary"
        aria-label="edit hero"
      >
        Edit Hero
      </StyledEditButton>
    </>
  )
}

function WelcomeText() {
  const template = useLeftyTemplate()
  const {
    welcomeText,
    welcomeTextFont,
    welcomeTextColor,
    welcomeTextFontSize,
    welcomeTextAlign,
    textColor,
  } = template
  useLoadFont(welcomeTextFont)
  const v = useAttendeeVariables()

  if (!welcomeText) {
    return null
  }

  return (
    <Text
      aria-label="welcome"
      font={welcomeTextFont}
      textColor={textColor}
      welcomeTextColor={welcomeTextColor}
      fontSize={welcomeTextFontSize}
      align={welcomeTextAlign}
    >
      {v(welcomeText)}
    </Text>
  )
}

const textAlign = (align: WELCOME_TEXT_ALIGN) => {
  switch (align) {
    case WELCOME_TEXT_ALIGN_LEFT:
      return 'left'
    case WELCOME_TEXT_ALIGN_CENTER:
      return 'center'
    default:
      return 'right'
  }
}

const Text = styled.div<{
  font: Font | null
  textColor: string
  welcomeTextColor: string | null
  fontSize: number
  align: WELCOME_TEXT_ALIGN
}>`
  ${(props) => fontStyles(props.font)}
  color: ${(props) =>
    props.welcomeTextColor ? props.welcomeTextColor : props.textColor};
  font-size: ${(props) => props.fontSize}px;
  line-height: 1;
  text-align: ${(props) => textAlign(props.align)};
  &:empty {
    height: ${(props) => props.theme.spacing[5]};
    width: 100%;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    line-height: 1.5;
  }
`

const StyledEditButton = withStyles({
  root: {
    marginTop: spacing[6],
  },
})(Button)

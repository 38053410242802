import Option from 'lib/ui/Select/Option'
import Select from 'lib/ui/Select'
import styled from 'styled-components'
import {AND, OR, Rule} from 'Event/attendee-rules'
import {onUnknownChangeHandler} from 'lib/dom'
import React from 'react'

export default function ConditionSelector(props: {
  visible: boolean
  onSelect: (condition: Rule['condition']) => void
  rule: Rule
  className?: string
}) {
  if (!props.visible) {
    return null
  }

  return (
    <ConditionSelect
      value={props.rule.condition}
      onChange={onUnknownChangeHandler(props.onSelect)}
      aria-label="update rule condition"
      variant="outlined"
      className={props.className}
    >
      <Option value={AND}>and</Option>
      <Option value={OR}>or</Option>
    </ConditionSelect>
  )
}

const ConditionSelect = styled(Select)`
  margin-bottom: 0 !important;
  width: 80px;

  .MuiSelect-root {
    padding: ${(props) => `10px ${props.theme.spacing[3]}`};
  }
`

import {useTemplate} from 'Event/TemplateProvider'
import {useSaveTemplate} from 'Event/TemplateUpdateProvider'
import Title from 'lib/ui/ConfigPanel/Title'
import TextEditor, {TextEditorContainer} from 'lib/ui/form/TextEditor'
import {useConfig} from 'organization/Event/Configurable'
import Config from 'organization/Event/Configurable/Config'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import React from 'react'
import {Controller} from 'react-hook-form'

export default function DescriptionConfig() {
  const save = useSaveTemplate()

  const template = useTemplate()
  const {faq} = template
  const {
    form: {control},
  } = useConfig()

  return (
    <Config title="Description" onSave={save}>
      <SettingsPanel>
        <Title>Description</Title>
        <Controller
          name="faq.description"
          defaultValue={faq.description}
          control={control}
          render={({onChange, value}) => (
            <TextEditorContainer>
              <TextEditor data={value} onChange={onChange} />
            </TextEditorContainer>
          )}
        />
      </SettingsPanel>
    </Config>
  )
}

import {useEvent} from 'Event/EventProvider'
import axios from 'axios'
import {useEventMarketplaceToken} from 'lib/event-api/event/use-event-marketplace-token'
import {Client} from 'lib/ui/api-client'
import {useMarketplaceClient} from 'lib/marketplace-api/use-marketplace-client'
import {useOrganization} from 'organization/OrganizationProvider'
import {useCallback} from 'react'
import {useQueryClient} from 'react-query'

export function useEventMarketplaceClient():
  | {isLoading: true; client: null}
  | {isLoading: false; client: Client} {
  const {event} = useEvent()
  const {id: eventId} = event
  const {client: orgClient} = useOrganization()
  const qc = useQueryClient()

  const {data: token, isFetching, isLoading} = useEventMarketplaceToken({
    event,
    client: orgClient,
  })

  const handleError = useCallback(
    (error: unknown) => {
      if (axios.isAxiosError(error)) {
        if (axios.isAxiosError(error) && error.response?.status === 401) {
          // Force re-fetch token
          qc.invalidateQueries(['events', eventId, 'marketplace', 'token'])
        }
      }
    },
    [qc, eventId],
  )

  const client = useMarketplaceClient({token, handleError})

  if (!client || isLoading || isFetching) {
    return {
      isLoading: true,
      client: null,
    }
  }

  return {
    isLoading: false,
    client,
  }
}

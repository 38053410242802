import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import {formatIntegerPrice} from 'lib/currency'
import {LONG_DATE_TIME_FORMAT, useLocalTime} from 'lib/date-time'
import {useToggleArray} from 'lib/toggle'
import Button from 'lib/ui/Button'
import ErrorAlert from 'lib/ui/alerts/ErrorAlert'
import {colors} from 'lib/ui/theme'
import {Title, Typography} from 'lib/ui/typography'
import {api} from 'lib/url'
import {useDefaultPaymentMethod} from 'obvio/Billing/DefaultPaymentMethodProvider'
import {Transaction} from 'obvio/Billing/TransactionSection/list-transactions'
import {teamMemberClient} from 'obvio/obvio-client'
import React, {useState} from 'react'

export default function SavedCardConfirmation(props: {
  transaction: Transaction
  onCancel: () => void
  onUseOtherCard: () => void
  onSuccess: () => void
}) {
  const {transaction, onSuccess, onCancel, onUseOtherCard} = props
  const [processing, toggleProcessing] = useToggleArray()
  const payForTransaction = usePayForTransaction(transaction)
  const [error, setError] = useState<string | null>(null)
  const clearError = () => setError(null)
  const {paymentMethod} = useDefaultPaymentMethod()
  const getDetails = useGetDetails()

  const onConfirm = () => {
    if (processing || !paymentMethod) {
      return
    }

    toggleProcessing()

    payForTransaction(paymentMethod.id)
      .then(onSuccess)
      .catch((e) => {
        setError(e.message)
        toggleProcessing()
      })
  }

  return (
    <>
      <DialogContent>
        <Title mt={2} mb={5}>
          Are you sure?
        </Title>

        <Typography>
          You're about to pay for the following transaction:
        </Typography>
        <Typography color={colors.text.muted} margin="20px 10px">
          {getDetails(transaction)}
        </Typography>
        <Typography aria-label="confirmation text">
          The card on file ending in {paymentMethod?.card?.last4} will be
          charged ${formatIntegerPrice(transaction.amount)}.
        </Typography>

        <ErrorAlert onClose={clearError}>{error}</ErrorAlert>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} disabled={processing}>
          Cancel
        </Button>
        <Button
          color="grey"
          disabled={processing}
          onClick={onUseOtherCard}
          variant="contained"
        >
          Use a Different Card
        </Button>
        <Button
          autoFocus
          color="primary"
          onClick={onConfirm}
          variant="contained"
          disabled={processing}
        >
          Pay
        </Button>
      </DialogActions>
    </>
  )
}

function useGetDetails() {
  const localTime = useLocalTime()

  return (transaction: Transaction) => {
    switch (transaction.type) {
      case 'Sms':
        return `SMS Broadcast sent to ${
          transaction.details.num_sent_north_america
        } North American phone numbers, and ${
          transaction.details.num_sent_international
        } International phone numbers at ${localTime(
          transaction.details.broadcast_send_at,
          LONG_DATE_TIME_FORMAT,
        )}.`
    }
  }
}

export function usePayForTransaction(transaction: Transaction) {
  const url = api(`/transactions/${transaction.id}/pay`)

  return (paymentMethodId: string) =>
    teamMemberClient.post(url, {
      payment_method_id: paymentMethodId,
    })
}

import {useGuestVariables} from 'Event'
import {useTemplate} from 'Event/TemplateProvider'
import {ChangePasswordRequest} from 'auth/password'
import Configurable from 'organization/Event/Configurable'
import React from 'react'
import SubmitButtonConfig from 'Event/auth/ChangePassword/SubmitButton/SubmitButtonConfig'
import Button from 'Event/auth/ChangePassword/SubmitButton/Button'

export default function SubmitButton(props: ChangePasswordRequest) {
  const v = useGuestVariables()
  const {changePassword} = useTemplate()

  return (
    <Configurable>
      <SubmitButtonConfig />
      <Button disabled={props.submitting} aria-label="submit change password">
        {v(changePassword.submitButton.label)}
      </Button>
    </Configurable>
  )
}

import styled from 'styled-components'
import React, {useEffect} from 'react'
import {rgba} from 'lib/color'
import {useTemplate} from 'Event/TemplateProvider'
import {useLayout} from 'organization/Event/Layout'

export default function Page(props: {
  children: React.ReactElement | React.ReactElement[]
}) {
  const template = useTemplate()
  const {emojiPage} = template
  const {showFullScreen, setFullScreen} = useLayout()

  const background = emojiPage.backgroundImage

  const backgroundRGBColor = rgba(
    emojiPage.backgroundColor || '#FFFFFF',
    emojiPage.backgroundOpacity / 100 || 0,
  )

  useEffect(() => {
    setFullScreen(true)
    return () => {
      setFullScreen(false)
    }
  }, [showFullScreen, setFullScreen])

  return (
    <Background
      background={background}
      aria-label="emoji page background"
      isEnabled={emojiPage.backgroundEnabled}
    >
      <ColorOverlay color={backgroundRGBColor}>
        <Container>{props.children}</Container>
      </ColorOverlay>
    </Background>
  )
}

const Background = styled.div<{
  background: string | null
  isEnabled?: boolean
}>`
  ${(props) => (props.isEnabled ? `background: url(${props.background});` : '')}
  display: flex;
  background-size: cover;
  background-position: center;
  position: 'absolute';
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  justify-content: center;
  align-items: center;
`

const ColorOverlay = styled.div<{
  color: string
}>`
  background-color: ${(props) => props.color};
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
`

const Container = styled.div`
  width: auto;
  padding: ${(props) => props.theme.spacing[4]};
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  @media (min-width: ${(props) => props.theme.breakpoints.sm}) {
    width: 600px;
  }
`

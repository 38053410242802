import React from 'react'
import styled from 'styled-components'
import Grid from '@material-ui/core/Grid'
import {safeColor} from 'lib/color'
import {ThemeProvider} from '@material-ui/core/styles'
import {NiftyFifty, useNiftyFiftyTemplate} from 'Event/template/NiftyFifty'
import Hidden from '@material-ui/core/Hidden'
import {createMuiLightTheme, MuiThemeOptions} from 'lib/ui/theme'
import LanguageSelector from 'Event/LanguageSelector'
import {Font, useLoadFont} from 'lib/FontSelect'

export const TOP_BAR_HEIGHT = 65

export default function Page(props: {
  Left: React.ReactElement
  Right: React.ReactElement
  Mobile: React.ReactElement
}) {
  const {Left, Right, Mobile} = props
  const {linkUnderline, font} = useNiftyFiftyTemplate()
  const textColor = useTextColor()
  const linkColor = useLinkColor()
  const theme = useTheme()

  useLoadFont(font)

  return (
    <ThemeProvider theme={theme}>
      <Box
        linkUnderline={linkUnderline}
        linkColor={linkColor}
        color={textColor}
        font={font}
      >
        <ColorOverlay>
          <Hidden smDown>
            <FullHeightGrid container>
              <SidePanel item md={6} xs={12}>
                {Left}
              </SidePanel>
              <SidePanel item md={6} xs={12}>
                {Right}
              </SidePanel>
            </FullHeightGrid>
          </Hidden>
          <Hidden mdUp>{Mobile}</Hidden>
          <LanguageSelector />
        </ColorOverlay>
      </Box>
    </ThemeProvider>
  )
}

function useTextColor() {
  return '#000000'
}

function useLinkColor() {
  const {linkColor} = useNiftyFiftyTemplate()

  const textColor = useTextColor()

  if (!linkColor) {
    return textColor
  }

  return linkColor
}

export function useTheme() {
  const template = useNiftyFiftyTemplate()

  // Material-UI: Unsupported color names.
  // Material-UI supports the following formats: #fff, #ffffff, rgb(), rgba(), hsl(), hsla()
  const options: MuiThemeOptions = {
    secondaryColor: safeColor(template.accentColor),
  }

  return createMuiLightTheme(options)
}

const Box = styled.div<{
  background?: string
  backgroundPosition?: NiftyFifty['backgroundPosition']
  color: string
  linkUnderline?: boolean
  linkColor: string
  font: Font | null
}>`
  ${(props) => props.background};
  font-size: 17px;
  color: ${(props) => props.color};
  min-height: 100vh;
  background-size: cover;
  overflow-x: hidden;
  ${(props) => (props.font ? `font-family: ${props.font.family};` : '')}

  a {
    color: ${(props) => props.linkColor};
    text-decoration: none;

    &:hover {
      text-decoration: ${(props) =>
        props.linkUnderline ? 'underline' : 'none'};
    }
  }

  p {
    margin-top: 0;
  }

  /* Background */
  ${(props) =>
    props.backgroundPosition === 'bottom' &&
    `
      background-position: bottom;
    `}
  ${(props) =>
    props.backgroundPosition === 'fixed' &&
    `
      background-position: bottom;
      background-attachment: fixed;
    `}
  background-repeat: no-repeat;
  @media (max-width: ${(props) => props.theme.breakpoints.lg}) {
    background-repeat: repeat-y;
  }
`

const ColorOverlay = styled.div<{
  color?: string
}>`
  background-color: ${(props) => props.color};
  height: 100vh;

  display: flex;

  > * {
    flex: 1;
  }
`

export const PageTitle = styled.h2<{
  textColor: string
  pageTitleColor: string | null
  extraPadding?: boolean
}>`
  font-size: 28px;
  line-height: 1.5;
  font-weight: 700;
  text-transform: uppercase;
  text-align: left;
  margin: 0 0 30px;
  color: ${(props) =>
    props.pageTitleColor ? props.pageTitleColor : props.textColor} !important;
  padding: 0 ${(props) => (props.extraPadding ? 35 : 0)}px;

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    font-size: 24px;
    line-height: 1.5;
    font-weight: 700;
  }
`

export const PageDescription = styled.h2<{
  color?: string
  extraPadding?: boolean
}>`
  font-size: 18px;
  line-height: 1.2;
  font-weight: 500;
  text-align: left;
  margin: 0 0 30px;
  color: ${(props) => props.color};
  padding: 0 ${(props) => (props.extraPadding ? 35 : 0)}px;

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    font-size: 14px;
    line-height: 1;
    font-weight: 500;
  }
`

const FullHeightGrid = styled(Grid)`
  height: 100vh;
`

const SidePanel = styled(FullHeightGrid)`
  display: flex;
  overflow: visible;

  > * {
    flex: 1;
  }
`

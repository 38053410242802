import {useEvent} from 'Event/EventProvider'
import {useAsync} from 'lib/async'
import FullPageLoader from 'lib/ui/layout/FullPageLoader'
import {api} from 'lib/url'
import Page from 'organization/Event/Page'
import {HighLevelIntegration} from 'organization/Event/Services/Apps/HighLevel'
import {ActiveCampaignIntegration} from 'organization/Event/Services/Apps/ActiveCampaign'
import {HubspotIntegration} from 'organization/Event/Services/Apps/Hubspot'
import {InfusionsoftIntegration} from 'organization/Event/Services/Apps/Infusionsoft'
import {MailchimpIntegration} from 'organization/Event/Services/Apps/Mailchimp'
import {ZapierIntegration} from 'organization/Event/Services/Apps/Zapier'
import {useOrganization} from 'organization/OrganizationProvider'

import React, {useCallback, useEffect, useState} from 'react'
import {ConvertKitIntegration} from 'organization/Event/Services/Apps/ConvertKit'
import {OntraportIntagration} from 'organization/Event/Services/Apps/Ontraport'

export const ZAPIER = 'Zapier'
export const INFUSIONSOFT = 'Infusionsoft'
export const MAILCHIMP = 'Mailchimp'
export const HUBSPOT = 'Hubspot'
export const HIGHLEVEL = 'Highlevel'
export const ACTIVE_CAMPAIGN = 'Activecampaign'
export const ONTRAPORT = 'Ontraport'
export const CONVERT_KIT = 'Convertkit'

export type Service =
  | typeof ZAPIER
  | typeof INFUSIONSOFT
  | typeof MAILCHIMP
  | typeof HUBSPOT
  | typeof HIGHLEVEL
  | typeof ACTIVE_CAMPAIGN
  | typeof ONTRAPORT
  | typeof CONVERT_KIT

export type BaseIntegration = {
  service: Service
  is_linked: boolean
}

export type Integration =
  | ZapierIntegration
  | InfusionsoftIntegration
  | MailchimpIntegration
  | HubspotIntegration
  | HighLevelIntegration
  | ActiveCampaignIntegration
  | ConvertKitIntegration
  | OntraportIntagration

export interface ServicesContextProps {
  integrations: Integration[]
  isLinked: (service: Service) => boolean
  add: (integration: Integration) => void
  update: (integration: Integration) => void
  infusionsoft?: InfusionsoftIntegration
  unlink: (service: Service) => Promise<any>
  remove: (service: Service) => void
}

const ServicesContext = React.createContext<undefined | ServicesContextProps>(
  undefined,
)

export default function ServicesProvider(props: {
  children: React.ReactElement
  noLoader?: boolean
}) {
  const {noLoader} = props
  const {data: fetched, loading} = useIntegrations()
  const [integrations, setIntegrations] = useState<Integration[]>([])
  const unlink = useUnlink()

  useEffect(() => {
    if (!fetched) {
      return
    }

    setIntegrations(fetched)
  }, [fetched])

  if (noLoader !== true && loading) {
    return (
      <Page>
        <FullPageLoader />
      </Page>
    )
  }

  const isLinked = (service: Service) => {
    const integration = integrations.find((i) => i.service === service)
    if (!integration) {
      return false
    }

    return integration.is_linked
  }

  const add = (integration: Integration) => {
    setIntegrations((current) => {
      current.push(integration)
      return current
    })

    update(integration)
  }

  const update = (target: Integration) => {
    setIntegrations((current) => {
      return current.map((i) => {
        const isTarget = i.service === target.service
        if (isTarget) {
          return target
        }

        return i
      })
    })
  }

  const remove = (service: Service) => {
    const removed = integrations.filter((i) => i.service !== service)
    setIntegrations(removed)
  }

  return (
    <ServicesContext.Provider
      value={{isLinked, integrations, add, update, unlink, remove}}
    >
      {props.children}
    </ServicesContext.Provider>
  )
}

function useIntegrations() {
  const {client} = useOrganization()
  const {event} = useEvent()
  const url = api(`/events/${event.id}/integrations`)

  const request = useCallback(() => client.get<Integration[]>(url), [
    client,
    url,
  ])
  return useAsync(request)
}

export function useServices() {
  const context = React.useContext(ServicesContext)
  if (context === undefined) {
    throw new Error(`useServices must be used within a ServicesProvider`)
  }

  return context
}

export function useInfusionsoft() {
  const {integrations} = useServices()

  for (const integration of integrations) {
    if (integration.service === INFUSIONSOFT) {
      return integration
    }
  }

  throw new Error('Infusionsoft integration has not been created')
}

export function useHubspot() {
  const {integrations} = useServices()

  for (const integration of integrations) {
    if (integration.service === HUBSPOT) {
      return integration
    }
  }

  throw new Error('Hubspot integration has not been created')
}

export function useActiveCampaign() {
  const {integrations} = useServices()

  for (const integration of integrations) {
    if (integration.service === ACTIVE_CAMPAIGN) {
      return integration
    }
  }

  throw new Error('Active Campaign integration has not been created')
}

export function useHighLevel() {
  const {integrations} = useServices()

  for (const integration of integrations) {
    if (integration.service === HIGHLEVEL) {
      return integration
    }
  }
  throw new Error('Highlevel integration has not been created')
}

export function useConvertKit() {
  const {integrations} = useServices()

  for (const integration of integrations) {
    if (integration.service === CONVERT_KIT) {
      return integration
    }
  }

  throw new Error('Convert Kit integration has not been created')
}

export function useOntraport() {
  const {integrations} = useServices()

  for (const integration of integrations) {
    if (integration.service === ONTRAPORT) {
      return integration
    }
  }

  throw new Error('Convert Kit integration has not been created')
}

function useUnlink() {
  const {client} = useOrganization()
  const url = useLinkUrl()

  return (service: Service) => client.delete(api(url(service)))
}

function useLinkUrl() {
  const {event} = useEvent()

  return (service: Service) => {
    switch (service) {
      case ZAPIER:
        return `/events/${event.id}/integrations/zapier/link`
      case INFUSIONSOFT:
        return `/events/${event.id}/integrations/infusionsoft/link`
      case MAILCHIMP:
        return `/events/${event.id}/integrations/mailchimp/link`
      case HUBSPOT:
        return `/events/${event.id}/integrations/hubspot/link`
      case HIGHLEVEL:
        return `/events/${event.id}/integrations/high_level/link`
      case ACTIVE_CAMPAIGN:
        return `/events/${event.id}/integrations/active_campaign/link`
      case ONTRAPORT:
        return `/events/${event.id}/integrations/ontraport/link`
      case CONVERT_KIT:
        return `/events/${event.id}/integrations/convert_kit/link`
    }
  }
}

import Dashboard from 'Event/Dashboard'
import React from 'react'
import ActionsProvider from 'Event/ActionsProvider'
import {useObvioUser} from 'obvio/auth'
import PageBreadcrumbs from 'organization/Event/Page/PageBreadcrumbs'
import FullPageLoader from 'lib/ui/layout/FullPageLoader'

export default function DashboardConfig() {
  const user = useObvioUser()

  return (
    <ActionsProvider loader={<FullPageLoader />}>
      <PageBreadcrumbs page="Dashboard">
        <Dashboard user={user} isEditMode />
      </PageBreadcrumbs>
    </ActionsProvider>
  )
}

import {Client, RequestOptions, client as baseClient} from 'lib/ui/api-client'
import {useMemo} from 'react'

export function useMarketplaceClient(params: {
  token: string | undefined
  handleError: (error: unknown) => void
}): Client | null {
  const {token, handleError} = params
  return useMemo(() => {
    if (!token) {
      return null
    }

    return {
      get: (url: string, options?: RequestOptions) =>
        createMarketplaceRequest(
          baseClient.get(url, createAuthOptions(token, options)),
          {onError: handleError},
        ),
      post: (url: string, data: {} = {}, options?: RequestOptions) =>
        createMarketplaceRequest(
          baseClient.post(url, data, createAuthOptions(token, options)),
          {onError: handleError},
        ),
      put: (url: string, data: {} = {}, options?: RequestOptions) =>
        createMarketplaceRequest(
          baseClient.put(url, data, createAuthOptions(token, options)),
          {onError: handleError},
        ),
      patch: (url: string, data: {} = {}, options?: RequestOptions) =>
        createMarketplaceRequest(
          baseClient.patch(url, data, createAuthOptions(token, options)),
          {onError: handleError},
        ),
      delete: (url: string, options?: RequestOptions) =>
        createMarketplaceRequest(
          baseClient.delete(url, createAuthOptions(token, options)),
          {onError: handleError},
        ),
    }
  }, [token, handleError])
}

async function createMarketplaceRequest<T>(
  result: Promise<T>,
  options: {
    onError: (error: unknown) => void
  },
) {
  try {
    return await result
  } catch (error) {
    options.onError(error)
    throw error
  }
}

function createAuthOptions(
  token: string,
  options: RequestOptions = {},
): RequestOptions {
  return {
    ...options,
    headers: {
      ...options.headers,
      Authorization: `Bearer ${token}`,
    },
  }
}

import React from 'react'
import styled from 'styled-components'
import Image from 'Event/template/Townhall/Dashboard/Footer/Image'
import {FooterConfig} from 'Event/template/Townhall/Dashboard/Footer/FooterConfig'
import {useTownhallTemplate} from 'Event/template/Townhall'
import {User} from 'auth/user'
import Configurable from 'organization/Event/Configurable'
import UserInfo from 'Event/template/Townhall/Dashboard/Footer/UserInfo'
import Features from 'Event/template/Townhall/Dashboard/Footer/Features'
import Copyright from 'Event/template/Townhall/Dashboard/Footer/Copyright'
import Links from 'Event/template/Townhall/Dashboard/Footer/Links'

export default function Footer(props: {user: User}) {
  const template = useTownhallTemplate()
  const {footer, textColor} = template

  return (
    <Configurable aria-label="edit townhall footer">
      <FooterConfig />
      <Box
        background={footer.background}
        textColor={textColor}
        footerColor={footer.textColor}
        aria-label="footer"
      >
        <Image />
        <UserInfo email={props.user.email} />
        <Features />
        <Links />
        <Copyright />
      </Box>
    </Configurable>
  )
}

const Box = styled.div<{
  background: string
  textColor: string
  footerColor: string | null
}>`
  padding: 40px 25px 25px;
  background: ${(props) => props.background};
  color: ${(props) =>
    props.footerColor ? props.footerColor : props.textColor};
  text-align: center;

  a {
    color: ${(props) =>
      props.footerColor ? props.footerColor : props.textColor};
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
`

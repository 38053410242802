import {useGuestVariables} from 'Event'
import EmailFieldConfig from 'Event/auth/Login/EmailField/EmailFieldConfig'
import TextField from 'Event/auth/Login/TextField'
import {useTemplate} from 'Event/TemplateProvider'
import {useQueryParams} from 'lib/url'
import Configurable from 'organization/Event/Configurable'
import React from 'react'
import {UseFormMethods} from 'react-hook-form'

export default function EmailField(props: {
  submitting: boolean
  errors: Record<string, any>
  register: UseFormMethods['register']
}) {
  const {login} = useTemplate()
  const v = useGuestVariables()
  const {email} = useQueryParams()

  const emailLabel = v(login.emailLabel)

  return (
    <Configurable>
      <EmailFieldConfig />
      <TextField
        label={emailLabel}
        type="email"
        fullWidth
        variant="outlined"
        name="email"
        disabled={props.submitting}
        defaultValue={email}
        inputProps={{
          ref: props.register({
            required: `${emailLabel} is required`,
          }),
          'aria-label': 'email',
        }}
        error={!!props.errors.email}
        helperText={props.errors.email && props.errors.email.message}
      />
    </Configurable>
  )
}

import {useCardsTemplate, useCardsUpdate} from 'Event/template/Cards'
import ColorPicker from 'lib/ui/ColorPicker'
import {useConfig} from 'organization/Event/Configurable'
import Config from 'organization/Event/Configurable/Config'
import StylingPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/StylingPanel'
import React from 'react'
import {Controller} from 'react-hook-form'
import Slider from 'lib/ui/Slider'

export default function ContentPanelConfig() {
  const save = useCardsUpdate()
  const {checkIn, textColor} = useCardsTemplate()
  const {
    form: {control},
  } = useConfig()
  const checkInTextColor = checkIn.contentPanel.textColor
    ? checkIn.contentPanel.textColor
    : textColor

  return (
    <Config title="Content Panel" onSave={save}>
      <StylingPanel>
        <Controller
          name="checkIn.contentPanel.backgroundColor"
          defaultValue={checkIn.contentPanel.backgroundColor}
          control={control}
          render={({value, onChange}) => (
            <ColorPicker
              label="Background Color"
              color={value}
              onPick={onChange}
              aria-label="checkin page content background color"
            />
          )}
        />

        <Controller
          name="checkIn.contentPanel.backgroundOpacity"
          defaultValue={checkIn.contentPanel.backgroundOpacity}
          control={control}
          render={({value, onChange}) => (
            <Slider
              data-testid="checkin-content-opacity-slider"
              label="Opacity"
              aria-label="checkin page content background color opacity "
              value={value}
              onChange={onChange}
              step={1}
              min={0}
              max={100}
              unit="%"
            />
          )}
        />

        <Controller
          name="checkIn.contentPanel.textColor"
          defaultValue={checkInTextColor}
          control={control}
          render={({value, onChange}) => (
            <ColorPicker
              label="Text Color"
              color={value}
              onPick={onChange}
              aria-label="checkin page content text color"
            />
          )}
        />
      </StylingPanel>
    </Config>
  )
}

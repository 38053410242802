import React from 'react'
import styled from 'styled-components'
import {useForm} from 'react-hook-form'
import {fieldError} from 'lib/form'
import {api} from 'lib/url'
import {ValidationError} from 'lib/ui/api-client'
import Dialog from 'lib/ui/Dialog'
import Button from 'lib/ui/Button'
import Typography from '@material-ui/core/Typography'
import Grid from 'lib/ui/Grid'
import {DialogContent, DialogTitle} from '@material-ui/core'
import TextField from 'lib/ui/TextField'
import {Organization} from 'organization'
import {teamMemberClient} from 'obvio/obvio-client'
import {useSnackbar} from 'lib/ui/SnackbarProvider'

export interface DeleteOrganizationData {
  password: string
}

export default function DeleteOrganizationDialog(props: {
  onClose: () => void
  visible: boolean
  organization: Organization
}) {
  const [loading, setLoading] = React.useState(false)
  const [responseError, setResponseError] = React.useState<
    ValidationError<DeleteOrganizationData>
  >(null)

  const {register, errors: formErrors, handleSubmit} = useForm()
  const {visible, onClose} = props

  const {enqueueSnackbar} = useSnackbar()

  const error = (key: keyof DeleteOrganizationData) =>
    fieldError(key, {
      form: formErrors,
      response: responseError,
    })
  const errors = {
    password: error('password'),
  }

  const onSubmit = (data: DeleteOrganizationData) => {
    setLoading(true)
    deleteOrganization(props.organization.id, data)
      .then(() => {
        props.onClose()
        enqueueSnackbar('Successfully scheduled organization to be deleted.', {
          variant: 'success',
        })
      })
      .catch((error) => {
        setResponseError(error)
        setLoading(false)
      })
  }

  return (
    <Dialog open={visible} onClose={onClose} disableEnforceFocus>
      <DialogTitle>Delete Organization Confirmation</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={3}>
            <Grid item md={12} xs={12}>
              <Typography>{props.organization.name}</Typography>
            </Grid>
            <Grid item md={12} xs={12}>
              <Typography color="textSecondary">
                This Organization will permanently be deleted. There is no way
                of restoring an Organization once deleted. Please confirm
                deletion by entering your accounts password below.
              </Typography>
            </Grid>
            <Grid item md={12} xs={12}>
              <TextField
                label="Please enter your password to continue"
                name="password"
                type="password"
                required
                variant="outlined"
                fullWidth
                inputProps={{
                  ref: register({
                    required: 'Password is required',
                  }),
                  'aria-label': 'password',
                }}
                error={!!errors.password}
                helperText={errors.password}
                disabled={loading}
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <Error>{responseError && responseError.message}</Error>
            </Grid>
            <Grid item md={12} xs={12}>
              <ActionButton
                variant="contained"
                color="primary"
                type="submit"
                disabled={loading}
                aria-label="ok"
              >
                OK
              </ActionButton>
              <ActionButton
                variant="outlined"
                color="primary"
                onClick={onClose}
                disabled={loading}
              >
                Cancel
              </ActionButton>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  )
}

function deleteOrganization(
  organizatioId: number,
  data: DeleteOrganizationData,
) {
  const url = api(`/organizations/${organizatioId}/delete`)
  return teamMemberClient.post<Organization>(url, data)
}

function Error(props: {children: string | null}) {
  if (!props.children) {
    return null
  }

  return <Typography color="error">{props.children}</Typography>
}

const ActionButton = styled(Button)`
  margin: ${(props) => props.theme.spacing[2]};
`

import React from 'react'
import {Controller} from 'react-hook-form'
import Slider from 'lib/ui/Slider'
import ColorPicker from 'lib/ui/ColorPicker'
import {QuestionTypeConfigProps} from 'organization/Event/Form/dialog/Form/QuestionTypeConfig'
import {getStyles} from 'Event/Question/SelectPriority'

export const DEFAULT_BACKGROUND_COLOR = '#FFFFFF'
export const DEFAULT_TEXT_COLOR = '#000000'
export const MIN_BORDER_RADIUS = 0
export const MAX_BORDER_RADIUS = 20

export default function SelectPriorityConfig(props: QuestionTypeConfigProps) {
  const {question, control} = props

  const styles = getStyles(question)

  return (
    <>
      <Controller
        name="styles.optionBackgroundColor"
        defaultValue={styles.optionBackgroundColor}
        control={control}
        render={({value, onChange}) => (
          <ColorPicker
            label="Option Background Color"
            color={value}
            onPick={onChange}
            aria-label="option background color"
          />
        )}
      />
      <Controller
        name="styles.optionTextColor"
        defaultValue={styles.optionTextColor}
        control={control}
        render={({value, onChange}) => (
          <ColorPicker
            label="Option Text Color"
            color={value}
            onPick={onChange}
            aria-label="option text color"
          />
        )}
      />
      <Controller
        name="styles.boxBackgroundColor"
        defaultValue={styles.boxBackgroundColor}
        control={control}
        render={({value, onChange}) => (
          <ColorPicker
            label="Container Background Color"
            color={value}
            onPick={onChange}
            aria-label="container background color"
          />
        )}
      />
      <Controller
        name="styles.boxTextColor"
        defaultValue={styles.boxTextColor}
        control={control}
        render={({value, onChange}) => (
          <ColorPicker
            label="Container Text Color"
            color={value}
            onPick={onChange}
            aria-label="container text color"
          />
        )}
      />
      <Controller
        name="styles.borderRadius"
        defaultValue={styles.borderRadius}
        control={control}
        render={({value, onChange}) => (
          <Slider
            valueLabelDisplay="auto"
            aria-label="progress bar border"
            value={value}
            onChange={onChange}
            step={1}
            min={MIN_BORDER_RADIUS}
            max={MAX_BORDER_RADIUS}
            label="Border Radius"
          />
        )}
      />
    </>
  )
}

import {useGuestVariables} from 'Event'
import {LoginProps} from 'Event/auth/Login'
import Button from 'Event/auth/Login/SubmitButton/Button'
import SubmitButtonConfig from 'Event/auth/Login/SubmitButton/SubmitButtonConfig'
import {useTemplate} from 'Event/TemplateProvider'
import Configurable from 'organization/Event/Configurable'
import React from 'react'

export default function SubmitButton(props: LoginProps) {
  const v = useGuestVariables()
  const {login} = useTemplate()

  return (
    <Configurable>
      <SubmitButtonConfig />
      <Button disabled={props.submitting} aria-label="submit login">
        {v(login.submitButton.label)}
      </Button>
    </Configurable>
  )
}

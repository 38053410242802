import React from 'react'
import MuiMenu, {MenuProps as BaseMenuProps} from '@material-ui/core/Menu'
import IconButton, {IconButtonProps} from '@material-ui/core/IconButton'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'

export type MenuProps = Omit<BaseMenuProps, 'open'> & {
  disabled?: boolean
  button: (props: {
    open: (event: React.MouseEvent<HTMLElement>) => void
  }) => React.ReactElement
}

/**
 * Wrapper around Material UI's dropdown Menu that provides consistent
 * defaults used in the app.
  
 * @param props 
 * @returns 
 */
export default function Menu(props: MenuProps) {
  const {button, ...menuProps} = props

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  return (
    <>
      {button({open})}
      <MuiMenu
        onClick={handleClose}
        MenuListProps={{
          disablePadding: true,
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        PaperProps={{
          style: {
            minWidth: 150,
          },
        }}
        keepMounted
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        getContentAnchorEl={null}
        {...menuProps}
      />
    </>
  )
}

export function MoreActionsMenu(props: Omit<MenuProps, 'button'>) {
  return (
    <Menu
      keepMounted
      MenuListProps={{
        disablePadding: true,
      }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      button={({open}) => (
        <ShowMoreButton onClick={open} aria-label="more actions" />
      )}
      {...props}
    />
  )
}

export function ShowMoreButton(props: Omit<IconButtonProps, 'children'>) {
  return (
    <IconButton aria-haspopup="true" {...props} onClick={props.onClick}>
      <MoreHorizIcon />
    </IconButton>
  )
}

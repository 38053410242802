import styled from 'styled-components'
import React, {useCallback, useMemo, useState} from 'react'
import {TEAM_MEMBER_TOKEN_KEY} from 'obvio/auth'
import Grid from '@material-ui/core/Grid'
import {fetchUser, useSetUser} from 'auth/auth-client'
import logo from 'assets/images/logo.png'
import {api, useQueryParams} from 'lib/url'
import {useValidatedForm} from 'lib/form'
import {client} from 'lib/ui/api-client'
import ErrorAlert from 'lib/ui/alerts/ErrorAlert'
import {
  ConfirmCardSetupData,
  PaymentMethod,
  SetupIntent,
  StripeCardNumberElement,
  StripeElementStyle,
} from '@stripe/stripe-js'
import {useRecordIntentError} from 'lib/stripe/SaveCreditCardForm'
import {useAsync} from 'lib/async'
import {useStripe} from '@stripe/react-stripe-js'
import {colors} from 'lib/ui/theme'
import {useToggleArray} from 'lib/toggle'
import {saveToken} from 'auth/token'
import StripeElementsProvider from 'obvio/Billing/StripeElementsProvider'
import FullPageLoader from 'lib/ui/layout/FullPageLoader'
import {
  INTERVAL_MONTH,
  INTERVAL_YEAR,
  PlanInfo,
  PROFESSIONAL,
  useGetPlanFromQueryParams,
} from 'obvio/Billing/plans'
import Container from '@material-ui/core/Container'
import {Description, Title, Tiny} from 'lib/ui/typography'
import PlanSummary from 'obvio/Billing/PurchasePage/SelectedPlan'
import TextField from 'lib/ui/TextField'
import Button from 'lib/ui/Button'
import Select from 'lib/ui/Select'
import Option from 'lib/ui/Select/Option'
import {Controller} from 'react-hook-form'
import CountrySelect from 'lib/CountrySelect'
import StateSelect from 'lib/StateSelect'
import {useTrackReferral} from 'lib/first-promoter'
import {formatInteger} from 'lib/number'
import CardFields from 'lib/stripe/CreditCardForm/CardFields'
import {queryParser} from 'lib/url'
import {obvioRoutes} from 'obvio/Routes'
import {useLocation} from 'react-router-dom'
import MuiAlert from '@material-ui/lab/Alert'

type SubmitData = {
  email: string
  first_name: string
  last_name: string
  password: string
  password_confirmation: string
  card_name: string
  phone_number: string
  company: string
  street: string
  address_unit: string
  city: string
  country_id: string
  state_id: string
}

// Subscription errors that
const ALREADY_SUBSCRIBED_ERROR = 'user_already_subscribed'
const NOT_ELIGIBLE_FOR_TRIAL_ERROR = 'not_eligible_for_trial'

type SubscriptionError =
  | typeof ALREADY_SUBSCRIBED_ERROR
  | typeof NOT_ELIGIBLE_FOR_TRIAL_ERROR

export default function PurchasePage() {
  return (
    <StripeElementsProvider>
      <Content />
    </StripeElementsProvider>
  )
}

function Content() {
  const {
    register: registerForm,
    handleSubmit,
    errors,
    watch,
    setResponseError,
    responseError,
    control,
    clearErrors,
  } = useValidatedForm()

  const stripe = useStripe()
  const plan = useGetPlanFromQueryParams()
  const query = useQueryParams()
  const parsedQuery = queryParser(query)

  const isTrial = parsedQuery.boolean('with_trial') || false
  const defaultBillingInterval =
    isTrial && plan?.name === PROFESSIONAL ? INTERVAL_MONTH : INTERVAL_YEAR

  const recordIntentError = useRecordIntentError()
  const [processing, toggleProcessing] = useToggleArray()
  const [billingInterval, setBillingInterval] = useState<string>(
    defaultBillingInterval,
  )
  const setUser = useSetUser()
  const trackReferral = useTrackReferral()
  const [
    cardElement,
    setCardElement,
  ] = useState<StripeCardNumberElement | null>(null)
  const [
    subscriptionError,
    setSubscriptionError,
  ] = useState<SubscriptionError | null>(null)
  const [errorFormData, setErrorFormData] = useState<SubmitData | null>(null)

  const cardStyle: StripeElementStyle = useMemo(
    () => ({
      base: {
        fontFamily: 'Rubik, sans-serif',
        fontSize: '14px',
        color: '#000000',
        padding: '8px',
        ':disabled': {
          color: 'rgba(0, 0, 0, 0.36)',
        },
        '::placeholder': {
          color: '#00000',
        },
      },
      invalid: {
        color: colors.error,
      },
    }),
    [],
  )

  const usage = useObvioUsage()

  const setupIntent = useSetupIntent(processing)
  const clientSecret = setupIntent?.client_secret

  const canSubmit =
    Boolean(stripe) && !processing && Boolean(clientSecret) && Boolean(plan)

  const countryId = watch('country_id', null)

  if (!stripe || !usage) {
    return <FullPageLoader />
  }

  const toggleBillingInterval = () => {
    setBillingInterval((current) =>
      current === INTERVAL_YEAR ? INTERVAL_MONTH : INTERVAL_YEAR,
    )
  }

  const getPaymentMethod = async (cardName: string) => {
    if (!clientSecret) {
      throw new Error('client secret has not been fetched')
    }

    if (!cardElement) {
      throw new Error('Missing card element')
    }

    const cardData: ConfirmCardSetupData = {
      payment_method: {
        card: cardElement,
        billing_details: {
          name: cardName,
        },
      },
    }

    // Use your card Element with other Stripe.js APIs
    const {setupIntent: updatedIntent, error} = await stripe.confirmCardSetup(
      clientSecret,
      cardData,
    )

    if (error) {
      const message = error.message || 'Payment Error'
      // Sending the Stripe error object to the backend to record in Telemetry.
      recordIntentError(error)
      throw new Error(message)
    }

    if (!updatedIntent?.payment_method) {
      throw new Error(
        'Failed to add payment method, you will not be charged. Please contact support.',
      )
    }

    return updatedIntent.payment_method
  }

  const purchase = (
    formData: SubmitData,
    paymentMethodId: string | PaymentMethod,
  ) => {
    const data = {
      ...formData,
      plan: plan?.name,
      with_trial: parsedQuery.boolean('with_trial') || false,
      interval: billingInterval,
      payment_method_id: paymentMethodId,
    }

    return client
      .post<{access_token: string}>(api('/purchase'), data)
      .then(({access_token: token}) => {
        saveToken(TEAM_MEMBER_TOKEN_KEY, token)
        return fetchUser(TEAM_MEMBER_TOKEN_KEY, '/user')
      })
      .then((user) => {
        if (user) {
          trackReferral(user)
        }

        setUser(user)
      })
      .catch((error) => {
        setErrorFormData(formData)

        if (error.type === ALREADY_SUBSCRIBED_ERROR) {
          setSubscriptionError(ALREADY_SUBSCRIBED_ERROR)
          return
        }

        if (error.type === NOT_ELIGIBLE_FOR_TRIAL_ERROR) {
          setSubscriptionError(NOT_ELIGIBLE_FOR_TRIAL_ERROR)
          return
        }

        throw error
      })
  }

  const onSubmit = (formData: SubmitData) => {
    if (processing) {
      return
    }

    toggleProcessing()
    clearErrors()

    return getPaymentMethod(formData.card_name)
      .then((paymentMethodId) => purchase(formData, paymentMethodId))
      .catch((e) => {
        setResponseError(e)
        toggleProcessing()
      })
  }

  const numEvents = formatInteger(usage.num_events)
  const numAttendees = formatInteger(usage.num_attendees)

  return (
    <StyledContainer maxWidth="lg">
      <Header>
        <Logo src={logo} alt="logo_image" />
      </Header>
      <StyledTitle>
        {' '}
        {isTrial
          ? 'Sign Up for My Obvio Free Trial!'
          : `Subscribe to ${plan?.label}`}{' '}
      </StyledTitle>
      <StyledDescription>
        Join the platform that is trusted by Tony Robbins, Dean Graziosi, Grant
        Cardone, Russell Brunson, and so many more to host their virtual events!
        The same platform that has hosted {numEvents} events for {numAttendees}{' '}
        attendees since March of 2021!!
      </StyledDescription>
      <PlanSummary
        billingInterval={billingInterval}
        plan={plan}
        toggleBillingInterval={toggleBillingInterval}
      />
      <StyledErrorAlert>{responseError?.message}</StyledErrorAlert>
      <ExistingOrIneligibleSubscriptionAlert
        error={subscriptionError}
        formData={errorFormData}
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <StyledTextField
              placeholder="First Name"
              name="first_name"
              required
              defaultValue={query.first_name}
              fullWidth
              variant="outlined"
              inputProps={{
                ref: registerForm({
                  required: 'First name is required',
                }),
                'aria-label': 'first name',
              }}
              error={!!errors.first_name}
              helperText={errors.first_name}
              disabled={!canSubmit}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <StyledTextField
              placeholder="Last Name"
              name="last_name"
              required
              defaultValue={query.last_name}
              fullWidth
              variant="outlined"
              inputProps={{
                ref: registerForm({
                  required: 'Last name is required',
                }),
                'aria-label': 'last name',
              }}
              error={!!errors.last_name}
              helperText={errors.last_name}
              disabled={!canSubmit}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <StyledTextField
              placeholder="Email"
              fullWidth
              required
              variant="outlined"
              name="email"
              defaultValue={query.email}
              inputProps={{
                ref: registerForm({
                  required: 'Email is required',
                }),
                'aria-label': 'email',
              }}
              error={!!errors.email}
              helperText={errors.email}
              disabled={!canSubmit}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <StyledTextField
              placeholder="Phone Number"
              fullWidth
              variant="outlined"
              name="phone_number"
              required
              defaultValue={query.phone_number}
              inputProps={{
                ref: registerForm({
                  required: 'Phone Number is required',
                }),
                'aria-label': 'phone number',
              }}
              error={!!errors.phone_number}
              helperText={errors.phone_number}
              disabled={!canSubmit}
            />
          </Grid>
          <Grid item xs={12}>
            <StyledTextField
              placeholder="Company"
              fullWidth
              variant="outlined"
              name="company"
              defaultValue={query.company}
              inputProps={{
                ref: registerForm,
                'aria-label': 'company',
              }}
              error={!!errors.company}
              helperText={errors.company}
              disabled={!canSubmit}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <StyledTextField
              placeholder="Address"
              fullWidth
              variant="outlined"
              name="street"
              required
              defaultValue={query.street}
              inputProps={{
                ref: registerForm({
                  required: 'Address is required',
                }),
                'aria-label': 'street',
              }}
              error={!!errors.street}
              helperText={errors.street}
              disabled={!canSubmit}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <StyledTextField
              placeholder="Unit / Apt / Suite #"
              fullWidth
              variant="outlined"
              name="address_unit"
              defaultValue={query.address_unit}
              inputProps={{
                ref: registerForm,
                'aria-label': 'address unit',
              }}
              error={!!errors.address_unit}
              helperText={errors.address_unit}
              disabled={!canSubmit}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <StyledTextField
              placeholder="City"
              fullWidth
              variant="outlined"
              required
              name="city"
              defaultValue={query.city}
              inputProps={{
                ref: registerForm({
                  required: 'City is required',
                }),
                'aria-label': 'city',
              }}
              error={!!errors.city}
              helperText={errors.city}
              disabled={!canSubmit}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Controller
              control={control}
              name="country_id"
              defaultValue={query.country_id || 0}
              rules={{
                validate: (v) => (v && v !== 0) || 'Country is required',
              }}
              render={({value, onChange}) => (
                <CountrySelect
                  value={value}
                  onChange={onChange}
                  fullWidth
                  variant="outlined"
                  required
                  error={!!errors.country_id}
                  disabled={!canSubmit}
                  Select={StyledSelect}
                  Option={StyledOption}
                  helperText={errors.country_id}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Controller
              control={control}
              name="state_id"
              defaultValue={query.state_id || 0}
              rules={{
                validate: (v) => (v && v !== 0) || 'State is required',
              }}
              render={({value, onChange}) => (
                <StateSelect
                  value={value}
                  onChange={onChange}
                  countryId={countryId}
                  fullWidth
                  variant="outlined"
                  required
                  error={!!errors.state_id}
                  disabled={!canSubmit}
                  Select={StyledSelect}
                  Option={StyledOption}
                  helperText={errors.country_id}
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <StyledTextField
              variant="outlined"
              placeholder="Credit Card Name"
              name="card_name"
              fullWidth
              required
              disabled={!canSubmit}
            />
          </Grid>
          <Grid item xs={12}>
            <CardInputBox>
              <CardFields
                disabled={!canSubmit}
                onLoad={setCardElement}
                style={cardStyle}
              />
            </CardInputBox>
          </Grid>
          <Grid item xs={12} sm={6}>
            <StyledTextField
              placeholder="Password"
              type="password"
              fullWidth
              variant="outlined"
              name="password"
              inputProps={{
                ref: registerForm({
                  required: 'Password is required',
                }),
                'aria-label': 'password',
              }}
              error={!!errors.password}
              helperText={errors.password}
              disabled={!canSubmit}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <StyledTextField
              placeholder="Confirm Password"
              type="password"
              fullWidth
              variant="outlined"
              name="password_confirmation"
              inputProps={{
                ref: registerForm({
                  required: 'Password confirmation is required',
                  validate: (value: any) =>
                    value === watch('password') || 'Passwords do not match',
                }),
                'aria-label': 'password confirmation',
              }}
              error={!!errors.password_confirmation}
              helperText={errors.password_confirmation}
              disabled={!canSubmit}
            />
          </Grid>
        </Grid>
        <PurchaseButton
          variant="contained"
          fullWidth
          color="primary"
          type="submit"
          aria-label="complete purchase"
          disabled={!canSubmit}
        >
          {isTrial ? 'Claim My Free Trial' : 'PURCHASE'}
        </PurchaseButton>
        <FooterNote plan={plan} isTrial={isTrial} />
      </form>
    </StyledContainer>
  )
}

function FooterNote(props: {plan: PlanInfo | null; isTrial: boolean}) {
  const isProPlan = props.plan?.name === PROFESSIONAL

  if (props.isTrial && isProPlan) {
    return (
      <Note>
        * Obvio's communication engine is scheduled to launch publicly July 1st,
        2023, and is currently in private beta. Obvio's Marketplace is scheduled
        to launch July 1st, 2023, and is currently in private beta. Tickets sold
        through the Marketplace will be subject to a 3% platform fee.
        Marketplace transactions will also be subject to Stripe processing fees.
        Dates are subject to change. Obvio will process your first payment 30
        days after you register for the free trial. You can cancel your
        subscription prior to that, as well as upgrade, or downgrade to a
        different plan at any time.
      </Note>
    )
  }

  return (
    <Note>
      * Obvio's communication engine is scheduled to launch publicly January
      1st, 2023, and is currently in private beta. Obvio's Marketplace is
      scheduled to launch January 1, 2023, and is currently in private beta.
      Tickets sold through the Marketplace will be subject to a 2% platform fee.
      Marketplace transactions will also be subject to Stripe processing fees.
      Dates are subject to change. Obvio Provides a refund policy. You can
      receive a full refund prior to the first day of your first event hosted on
      the platform OR within 30 days from the date of purchase, whichever comes
      first.
    </Note>
  )
}

function useSetupIntent(processing: boolean) {
  const request = useCallback(() => {
    if (processing) {
      return Promise.resolve(null)
    }

    const url = api('/purchase/setup_intent')
    return client.get<SetupIntent>(url)
  }, [processing])

  const {data} = useAsync(request)

  return data
}

type ObvioUsage = {
  num_events: number
  num_attendees: number
}

function useObvioUsage() {
  const request = useCallback(() => {
    const url = api('/obvio/usage')

    return client.get<ObvioUsage>(url)
  }, [])

  return useAsync(request).data
}

const StyledContainer = styled(Container)`
  padding-top: 24px;
  padding-bottom: 60px;
`

const Header = styled.div`
  margin-bottom: 45px;
  text-align: center;
`

const Logo = styled.img`
  width: 160px;
`

const StyledTitle = styled(Title)`
  font-weight: 700;
  font-family: 'Rubik';
  margin: 0 0 12px;
`

const StyledDescription = styled(Description)`
  max-width: 400px;
  margin-bottom: 40px;
`

const StyledTextField = styled(TextField)`
  margin: 0 !important;
`

const PurchaseButton = styled(Button)`
  height: 50px;
  border-radius: 10px;
  margin: 20px 0;

  @media (min-width: ${(props) => props.theme.breakpoints.sm}) {
    margin: 54px 0;
  }
`

const CardInputBox = styled.div`
  .StripeElement {
    width: 100%;
    padding: 12px;
    border: 1px solid #dfdfdf;
    border-radius: 3px;

    &.StripeElement--focus {
      border-color: ${colors.primary};
    }

    &.StripeElement--invalid {
      border-color: ${colors.error};
    }
  }

  .InputElement {
    font-size: 14px;
  }
`

const StyledErrorAlert = styled(ErrorAlert)`
  width: 100%;
  margin-bottom: ${(props) => props.theme.spacing[6]};
`

const StyledCustomErrorAlert = styled(MuiAlert)`
  margin-bottom: ${(props) => props.theme.spacing[6]};
`

const StyledSelect = styled(Select)`
  margin-bottom: 0 !important;

  .MuiSelect-root {
    padding: 13px 12px 11px;
    border-color: #dfdfdf;
    font-size: 14px;
  }

  .MuiSelect-select:focus {
    border-color: ${colors.primary};
  }
`

const StyledOption = styled(Option)`
  font-size: 14px;
`

const Note = styled(Tiny)`
  color: ${(props) => props.theme.colors.disabled};
`

const ExistingOrIneligibleSubscriptionAlert = (props: {
  error: string | null
  formData: SubmitData | null
}) => {
  const {error, formData} = props
  const location = useLocation()

  if (!error) {
    return null
  }

  if (error === ALREADY_SUBSCRIBED_ERROR) {
    setTimeout(() => (window.location.href = obvioRoutes.billing.root), 5000)

    return (
      <StyledErrorAlert>
        The supplied email address is already subscribed to an Obvio plan.
        Redirecting to the billing page...
      </StyledErrorAlert>
    )
  }

  const queryParams = new URLSearchParams(location.search)

  if (queryParams.has('with_trial')) {
    queryParams.delete('with_trial')
  }

  queryParams.append('first_name', formData?.first_name || '')
  queryParams.append('last_name', formData?.last_name || '')
  queryParams.append('email', formData?.email || '')
  queryParams.append('phone_number', formData?.phone_number || '')
  queryParams.append('company', formData?.company || '')
  queryParams.append('street', formData?.street || '')
  queryParams.append('address_unit', formData?.address_unit || '')
  queryParams.append('city', formData?.city || '')
  queryParams.append('country_id', formData?.country_id || '')
  queryParams.append('state_id', formData?.state_id || '')

  const redirectLocation = `${obvioRoutes.purchase}?${queryParams.toString()}`

  return (
    <StyledCustomErrorAlert severity="error">
      As a previous subscriber of Obvio, you are not eligible for a free trial.{' '}
      <a href={redirectLocation}>Click Here</a> to create a new subscription.
    </StyledCustomErrorAlert>
  )
}

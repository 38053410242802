import {
  appRoot,
  isProduction,
  isStaging,
  OBVIO_SUBDOMAIN,
  isTestEnv,
  isInTest,
} from 'env'
import {ExtendRecursively} from 'lib/type-utils'
import {useHistory, useLocation} from 'react-router-dom'
import {FileLocation} from 'lib/http-client'
import {useMemo} from 'react'
import {ObvioEvent} from 'Event'

/**
 * obv.io Domains
 */

const OBVIO_DOMAINS = [
  'obv.io',
  'obv.localhost:3000',
  'obv.localhost',
  'app.localhost:3000',
  'zoomiverse.com',
  'bootcamp.live',
  'eventsoft.io',
  'sagehub.com',
]

/**
 * Available system domains that an event can belong to. These map to the records
 * in Event API DB. We're opting to hard-code them here to avoid an extra
 * request + look-up which would happen frequently.
 */
export const SYSTEM_DOMAINS: Record<number, string> = {
  1: 'obv.io',
  2: 'bootcamp.live',
  3: 'eventsoft.io',
  4: 'zoomiverse.com',
  5: 'sagehub.com',
}

export const getSystemDomain = (
  event: Pick<ObvioEvent, 'system_domain_id'>,
) => {
  const fullDomain = window.location.host
  if (fullDomain.includes('localhost') || isInTest) {
    return appRoot
  }

  const domain = SYSTEM_DOMAINS[event.system_domain_id ?? 1]

  if (isStaging) {
    return `staging.${domain}`
  }

  if (isTestEnv) {
    return `test.${domain}`
  }

  return domain
}

/**
 * Staging environments are prefixed with *.staging.obv.io or *.test.obv.io
 */
const STAGING_SUBDOMAINS = ['staging', 'test']

export const getSubdomain = (location: string) => {
  const urlParts = location.split('.')

  const isDev = urlParts.length === 2 && urlParts[1] === 'localhost:3000'

  const missingSubdomain = urlParts.length < 3 && !isDev
  if (missingSubdomain) {
    return ''
  }

  const hasNestedSubdomains = urlParts.length > 3
  if (!hasNestedSubdomains) {
    return urlParts[0]
  }

  const index = urlParts.length - 3
  const firstSubdomain = urlParts[index]
  const isStaging = STAGING_SUBDOMAINS.includes(firstSubdomain)
  if (isStaging) {
    // return next child to allow app.staging.obv.io
    return urlParts[index - 1]
  }

  return firstSubdomain
}

export const getDomain = (location: string) => {
  const urlParts = location.split('.')

  /**
   * If we have multiple subdomains (app.staging.obv.io), we only want the
   * domain, and TLD
   */

  if (urlParts.length > 2) {
    return [urlParts[urlParts.length - 2], urlParts[urlParts.length - 1]].join(
      '.',
    )
  }

  return location
}

/**
 * Check whether showing obv.io app ie. app.obv.io, or app.staging.obv.io
 * @returns
 */

export const isObvioApp = () => {
  /**
   * Is this a custom event domain?
   */

  const domain = getDomain(window.location.host)
  if (!isObvioDomain(domain)) {
    return false
  }

  const subdomain = getSubdomain(window.location.host)
  if (!subdomain) {
    return true
  }

  return subdomain === OBVIO_SUBDOMAIN
}

export function isObvioDomain(value: string) {
  return OBVIO_DOMAINS.includes(value)
}

export const api = (
  path: string,
  options?: {
    cdn?: boolean
  },
) => {
  return `${ApiBaseUrl(options)}${path}`
}

export function ApiBaseUrl(options?: {cdn?: boolean}) {
  const hasSingleEndpoint = !isProduction && !isTestEnv && !isStaging
  if (hasSingleEndpoint) {
    // Single API url for dev
    return process.env.REACT_APP_API_URL
  }

  if (isObvioApp()) {
    return process.env.REACT_APP_ADMIN_API_URL
  }

  if (options?.cdn) {
    return process.env.REACT_APP_EVENT_API_CDN_URL
  }

  return process.env.REACT_APP_EVENT_API_URL
}

/**
 * Storage path for public assets store on the server
 *
 * @param path
 */
export const storage = (path: string) => {
  const local = `${process.env.REACT_APP_API_URL}/storage`
  const prodBucket = 'https://event-api-s3.obv.io'
  const stagingBucket = 'https://staging-event-api-s3.obv.io'

  if (!isProduction) {
    return `${local}${path}`
  }

  const bucket = isStaging ? stagingBucket : prodBucket
  return `${bucket}${path}`
}

/**
 * Generates an absolute URL for a given obv.io path
 *
 * @param path
 * @returns
 */
export const obvioUrl = (path: string) => {
  const scheme = isProduction ? 'https://' : 'http://'
  const absolutePath = `${OBVIO_SUBDOMAIN}.${appRoot}${path}`

  return `${scheme}${absolutePath}`
}

export type Routes = {
  [key: string]: string | Routes
}

/**
 * Public path for frontend assets
 * Reference: https://create-react-app.dev/docs/using-the-public-folder/
 *
 * @param path
 */
export function publicAsset(path: string) {
  return `${process.env.PUBLIC_URL}${path}`
}

/**
 * Helper that automatically converts a JSON object
 * into route URLs with prepended namespaces.
 *
 * @param routes
 * @param namespace
 */
export function createRoutes<T extends Routes>(
  routes: T,
  namespace?: string,
): ExtendRecursively<T, {root: string}> {
  const childRoutes = Object.entries(routes).reduce((acc, [key, val]) => {
    if (typeof val === 'string') {
      const prependedVal = namespace ? `/${namespace}${val}` : val
      acc[key] = prependedVal
      return acc
    }

    const prependedKey = namespace ? `${namespace}/${key}` : key
    acc[key] = createRoutes(val, prependedKey)

    return acc
  }, {} as any)

  // append root
  childRoutes.root = namespace ? `/${namespace}` : '/'

  return childRoutes
}

export function routesWithValue<T>(param: string, value: string, routes: T): T {
  return Object.entries(routes).reduce((acc, [key, route]) => {
    if (typeof route === 'string') {
      const withParam = route.replace(param, value)
      acc[key] = withParam
      return acc
    }

    acc[key] = routesWithValue(param, value, route)
    return acc
  }, {} as any)
}

export type QueryParams = Record<string, string | undefined>

export function useQueryParams() {
  const {search} = useLocation()

  return useMemo(() => {
    const params = new URLSearchParams(search)

    const result: QueryParams = {}

    for (const [key, val] of params.entries()) {
      result[key] = val
    }

    return result
  }, [search])
}

export function queryParser(query: QueryParams) {
  const parse = <T>(key: string, parser: (value: string) => T) => {
    const val = query[key]
    if (val === undefined) {
      return null
    }

    return parser(val)
  }

  const type = <T>(parser: (value: string) => T) => (key: string) =>
    parse(key, parser)

  return {
    string: type((v) => v),
    boolean: type((v) => v === 'true'),
    number: type((v) => (isNaN(parseInt(v)) ? null : parseInt(v))),
    list: type((v) => v.split(',')),
    dictionary: type((v) => {
      // Dictionaries / Objects in the URL are defined as comma separated lists
      // with a colon (:) separating key/value pairs.
      // ie. ?groups=Ticket:VIP,status:is_active
      const pairs = v.split(',').map((pair) => pair.split(':'))
      return pairs.reduce((acc, [key, val]) => {
        acc[key] = val
        return acc
      }, {} as Record<string, string>)
    }),
  }
}

export function useSetBulkQueryParam() {
  const {set, update} = useQueryString()

  return (updates: Record<string, string>) => {
    for (const [key, val] of Object.entries(updates)) {
      set(key, val)
    }

    update()
  }
}

export function useSetQueryParam() {
  const {set, update} = useQueryString()

  return (key: string, val: any) => {
    set(key, val)
    update()
  }
}

function useQueryString() {
  const {search} = useLocation()
  const params = new URLSearchParams(search)
  const {pathname} = useLocation()
  const history = useHistory()

  const set = (key: string, val: string) => {
    if (val === '' || val === undefined || val === null) {
      params.delete(key)
    } else {
      params.set(key, val)
    }
  }

  const update = () => {
    const query = params.toString()
    history.push({
      pathname,
      search: `?${query}`,
    })
  }

  return {set, update}
}

export function parseFileLocation(value?: string): FileLocation | null {
  return createFileLocation(parseUrl(value))
}

/**
 * Get asset file url.
 * API returns file url wrapped with url() function, so need to parse this to get file path directly.
 */
export function parseUrl(value?: string | null): string | null {
  if (!value) {
    return null
  }

  const urlRegex = /url\((.*)\)/

  const isFile = urlRegex.test(value)
  if (!isFile) {
    return null
  }

  const matches = value.match(urlRegex)
  if (!matches || matches.length !== 2) {
    return null
  }

  /**
   * Parse values out of the background CSS style
   */
  return matches[1]
}

/**
 * Create a FileLocation object from a given URL. This is just a simple factory function
 * that parses the file name out of a url.
 * @param url
 * @returns
 */
export function createFileLocation(url?: string | null): FileLocation | null {
  if (!url) {
    return null
  }

  const urlParts = url.split('/')
  const name = urlParts[urlParts.length - 1]

  return {url, name}
}

/**
 * Absolute URL to Obvio Communications API
 *
 * @param path
 * @returns
 */
export const communicationsApi = (path: string) => {
  return `${process.env.REACT_APP_COMMUNICATIONS_API_URL}${path}`
}

export function removeQueryParam(key: string) {
  const search = new URLSearchParams(window.location.search)
  search.delete(key)

  const query = search.toString()
  const base = window.location.origin + window.location.pathname
  const updated = query ? `${base}?${query}` : base

  // Update URL without history (forward/back)
  window.history.replaceState(null, '', updated)
}

export function getEventUrl(event: ObvioEvent): string {
  const domain = getSystemDomain(event)
  const scheme = isProduction ? 'https://' : 'http://'
  return `${scheme}${event.slug}.${domain}`
}

import React from 'react'
import {UseFormMethods} from 'react-hook-form'
import {NavButtonWithSize} from 'Event/Dashboard/components/NavButton'
import TypographySection from 'Event/Dashboard/components/NavButton/NavButtonConfig/BaseConfig/Styling/TypographySection'

export default function Styling(props: {
  control: UseFormMethods['control']
  watch: UseFormMethods['watch']
  button: NavButtonWithSize
}) {
  const {control, button} = props

  return (
    <>
      <TypographySection
        button={button}
        control={control}
        storageKey="actionButton.config.styling.typography"
      />
    </>
  )
}

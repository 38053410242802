import React, {useEffect} from 'react'
import {User} from 'auth/user'
import ConfigBar from 'Event/Dashboard/editor/views/ConfigBar'
import {useTemplate} from 'Event/TemplateProvider'
import {useCheckIn} from 'Event/attendee'
import {useSetEditMode} from 'Event/EditModeProvider'
import Scripts, {DASHBOARD} from 'Event/Scripts'
import {components} from 'Event/template'

export type DashboardProps = {
  isEditMode?: boolean
  user: User
}

export default function Dashboard(props: DashboardProps) {
  const {isEditMode = false} = props

  const setEditMode = useSetEditMode()

  useEffect(() => {
    setEditMode(isEditMode)
  }, [isEditMode, setEditMode])

  useCheckIn(props.user)

  return (
    <>
      <Scripts page={DASHBOARD} />
      <ConfigComponents isEditMode={props.isEditMode}>
        <TemplateDashboard user={props.user} />
      </ConfigComponents>
    </>
  )
}

function ConfigComponents(props: {
  isEditMode?: boolean
  children: React.ReactElement
}) {
  if (props.isEditMode) {
    return (
      <>
        <ConfigBar />
        {props.children}
      </>
    )
  }

  return props.children
}

function TemplateDashboard(props: {user: User}) {
  const {name} = useTemplate()
  const Component = components[name].Dashboard.index

  return <Component user={props.user} />
}

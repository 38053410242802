import React, {useCallback, useState} from 'react'
import {Answer, StaticSubmissionsProvider} from 'Event/SubmissionsProvider'
import {uuid} from 'lib/uuid'
import {useEvent} from 'Event/EventProvider'

interface PreviewSubmissionsContextProps {
  answers: Answer[]
  add: () => void
  setAnswers: (answers: Answer[]) => void
  update: (answer: Answer) => void
  remove: (answer: Answer) => void
  clear: () => void
  saveAnswers: () => void
}

const PreviewSubmissionsContext = React.createContext(
  (undefined as unknown) as PreviewSubmissionsContextProps,
)

/**
 * A submissions provider that wraps StaticSubmissionsProvider, allowing us to set answers
 * without having to actually submit the form each time.
 *
 * @param props
 * @returns
 */
export default function PreviewSubmissionsProvider(props: {
  children: React.ReactNode
}) {
  const {answers: savedAnswers, save} = useSavedAnswers()

  const [answers, setAnswers] = useState<Answer[]>(savedAnswers)

  const add = () => {
    const newAnswer: Answer = {
      id: uuid(),
      form_id: 0,
      question_id: 0,
      value: '',
    }

    setAnswers((current) => [...current, newAnswer])
  }

  const clear = () => {
    setAnswers([])
  }

  const remove = (answer: Answer) => {
    const removed = answers.filter((a) => {
      const isTarget = a.id && a.id === answer.id
      if (isTarget) {
        return false
      }

      return true
    })

    setAnswers(removed)
  }

  const update = useCallback(
    (answer: Answer) => {
      setAnswers((current) =>
        current.map((a) => {
          const isTarget = a.id && a.id === answer.id
          if (isTarget) {
            return answer
          }

          return a
        }),
      )
    },
    [setAnswers],
  )

  const saveAnswers = () => save(answers)

  return (
    <PreviewSubmissionsContext.Provider
      value={{
        answers,
        setAnswers,
        add,
        clear,
        remove,
        update,
        saveAnswers,
      }}
    >
      <StaticSubmissionsProvider answers={answers}>
        {props.children}
      </StaticSubmissionsProvider>
    </PreviewSubmissionsContext.Provider>
  )
}

export function usePreviewSubmissions() {
  const context = React.useContext(PreviewSubmissionsContext)
  if (context === undefined) {
    throw new Error(
      'usePreviewSubmissions must be used within a PreviewSubmissionsProvider',
    )
  }

  return context
}

function useSavedAnswers() {
  const {event} = useEvent()
  const previewAnswersStore = `__obvio_${event.id}_preview_answers__`

  const savedAnswers = localStorage.getItem(previewAnswersStore)

  const save = (answers: Answer[]) => {
    localStorage.setItem(previewAnswersStore, JSON.stringify(answers))
  }

  return {answers: savedAnswers ? JSON.parse(savedAnswers) : [], save}
}

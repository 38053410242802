/* eslint-disable no-useless-constructor */
import Command from '@ckeditor/ckeditor5-core/src/command';
import { THEME_AUTO } from './customthemeui';

export default class CustomThemeCommand extends Command {
	constructor( editor ) {
		super( editor );

		const themeConfig = this.editor.config.get( 'theme' );
		this.isEnabled = true;
		this.value = themeConfig.mode || THEME_AUTO;
	}

	refresh() {}

	execute( { value, callback } ) {
		this.value = value;
		if ( typeof callback == 'function' ) {
			callback( value );
		}
	}
}


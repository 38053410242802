
import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import Widget from '@ckeditor/ckeditor5-widget/src/widget';
import InsertCustomButtonCommand from './insertcustombutton/insertcustombuttoncommand';
import ObvioCustomButtonCommand from './obviocustombuttoncommand';
import { toCustomButtonWidget } from './utils';
import { modelToViewAttributeConverter, modelToViewStyleConverter } from './converter';
export default class ObvioCustomButtonEditing extends Plugin {
	static get requires() {
		return [ Widget ];
	}

	init() {
		this._defineSchema();
		this._defineConverters();

		this.editor.commands.add( 'insertCustomButton', new InsertCustomButtonCommand( this.editor ) );
		this.editor.commands.add( 'updateCustomButton', new ObvioCustomButtonCommand( this.editor ) );
	}

	_defineSchema() {
		const schema = this.editor.model.schema;

		schema.register( 'customButton', {
			// Behaves like a self-contained object (e.g. an image).
			isObject: true,
			isBlock: true,
			// Allow in places where other blocks are allowed (e.g. directly in the root).
			allowWhere: '$block',
			allowAttributes: [ 'text', 'href', 'float', 'buttonFontSize', 'buttonBackgroundColor', 'buttonFontColor' ]
		} );
	}

	_defineConverters() {
		const conversion = this.editor.conversion;

		conversion.for( 'dataDowncast' ).elementToElement( {
			model: 'customButton',
			view: ( modelItem, { writer: viewWriter } ) => {
				return createCustomButton( modelItem, viewWriter );
			}

		} );

		// When user clicks the widget, it should enable the editing.
		conversion.for( 'editingDowncast' ).elementToElement( {
			model: 'customButton',
			// eslint-disable-next-line max-len
			view: ( modelElement, { writer: viewWriter } ) => {
				return toCustomButtonWidget( createCustomButton( modelElement, viewWriter ), viewWriter, 'custom button widget' );
			}
		} );

		conversion.for( 'downcast' )
			.add( modelToViewAttributeConverter( 'href' ) )
			.add( modelToViewStyleConverter( 'float', 'float', '' ) )
			.add( modelToViewStyleConverter( 'buttonBackgroundColor', 'background-color', '' ) )
			.add( modelToViewStyleConverter( 'buttonFontColor', 'color', '' ) )
			.add( modelToViewStyleConverter( 'buttonFontSize', 'font-size', 'px' ) );

		conversion.for( 'upcast' ).elementToElement( {
			view: {
				name: 'a',
				classes: [ 'obvio-custom-button' ]
			},
			model: ( viewElement, { writer } ) => {
				const attributes = {
					...Object.fromEntries( viewElement.getAttributes() ),
					buttonBackgroundColor: viewElement.getStyle( 'background-color' ),
					buttonFontColor: viewElement.getStyle( 'color' ),
					buttonFontSize: viewElement.getStyle( 'font-size' ),
					text: viewElement.getChild( 0 ).data
				};
				return writer.createElement( 'customButton', attributes );
			}
		} );

		function createCustomButton( modelItem, viewWriter ) {
			const buttonText = modelItem.getAttribute( 'text' ) || 'Button';

			const view = viewWriter.createContainerElement( 'a', {
				class: 'obvio-custom-button',
				target: '_blank'
			} );

			const innerText = viewWriter.createText( buttonText );
			viewWriter.insert( viewWriter.createPositionAt( view, 0 ), innerText );

			return view;
		}
	}
}

import TextField from '@material-ui/core/TextField'
import {Attendee} from 'Event/attendee'
import React from 'react'

export default React.forwardRef(
  (props: {group: string; attendee?: Attendee; disabled?: boolean}, ref) => {
    const value = props.attendee?.groups[props.group] || ''

    return (
      <TextField
        variant="outlined"
        fullWidth
        inputProps={{ref, 'aria-label': `${props.group} input`}}
        defaultValue={value}
        disabled={props.disabled}
        label={props.group}
        name={`groups[${removeGroupKeyDot(props.group)}]`}
      />
    )
  },
)

/**
 * React hook form turns '.' inside field names into arrays
 * automatically which breaks when trying to save a group
 * key with a '.'.
 */
const DOT_REPLACEMENT = '~_~'

/**
 * Replaces any dots '.' in a group key with a safe string. The
 * key should then be replaced with a '.' again before the
 * form is submitted.
 *
 * @param key
 * @returns
 */
export const removeGroupKeyDot = (key: string) =>
  key.replace('.', DOT_REPLACEMENT)

/**
 * Add back any group key dots '.' that were removed to prevent
 * the form from sending an array.
 *
 * @param key
 * @returns
 */
export const addGroupKeyDot = (key: string) => key.replace(DOT_REPLACEMENT, '.')

import {grey} from '@material-ui/core/colors'
import {ButtonProps} from 'lib/ui/Button'
import React from 'react'
import styled from 'styled-components'

const IconButton = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (props, ref) => {
    const color = useColor(props)

    return (
      <Button {...props} type={props.type || 'button'} color={color} ref={ref}>
        {props.children}
      </Button>
    )
  },
)

export default IconButton

function useColor(props: {disabled?: boolean}) {
  if (props.disabled) {
    return grey[500]
  }

  return undefined
}

const Button = styled.button<{color?: string}>`
  cursor: pointer;
  position: relative;
  display: inline-flex;
  border: 0;
  background: 0;
  padding: 0;
  line-height: 1;

  svg {
    ${(props) => (props.color ? `color: ${props.color};` : '')};
  }
`

import {TextEditorContainer} from 'lib/ui/form/TextEditor'
import FormControl from 'lib/ui/FormControl'
import {useBroadcastForm} from 'organization/Event/Broadcasts/BroadcastForm'
import React from 'react'
import {Controller} from 'react-hook-form'
import HtmlNotificationTextEditor from 'lib/ui/form/TextEditor/HtmllNotificationTextEditor'

export default function BodyInput(props: {disabled?: boolean}) {
  const {disabled} = props
  const {broadcast, control} = useBroadcastForm()

  const defaultValue = broadcast?.channel.body || ''

  return (
    <FormControl>
      <Controller
        name="channel.body"
        defaultValue={defaultValue}
        control={control}
        render={({value, onChange}) => (
          <TextEditorContainer>
            <HtmlNotificationTextEditor
              onChange={onChange}
              data={value}
              disabled={disabled}
              customLinks={['Login']}
            />
          </TextEditorContainer>
        )}
      />
    </FormControl>
  )
}

import React from 'react'
import {
  TEAM_ACCESS_COHOST_TAG,
  TEAM_ACCESS_HOST_TAG,
} from 'Event/Dashboard/TeamAccessControls/index'
import {useAttendeeProfile} from 'Event/attendee-rules/AttendeeProfileProvider'
import JoinAsAttendeeButton from 'Event/Dashboard/TeamAccessControls/RoomControls/JoinAsAttendeeButton'
import StartRoomButton from 'Event/Dashboard/TeamAccessControls/RoomControls/StartRoomButton'
import TurnOffRoomButton from 'Event/Dashboard/TeamAccessControls/RoomControls/TurnOffRoomButton'
import {useRoomControls} from 'Event/Dashboard/TeamAccessControls/RoomControls'

export default function MeetingButtons() {
  const {room} = useRoomControls()

  const numAttendees = room.attendee_count

  const meetingHasStarted = Boolean(numAttendees) && room.is_online

  return (
    <div>
      <ZoomMeetingNotStartedText showing={!meetingHasStarted} />
      <Controls meetingHasStarted={meetingHasStarted} showing />
    </div>
  )
}

function Controls(props: {showing: boolean; meetingHasStarted: boolean}) {
  const {meetingHasStarted, showing} = props
  const {room} = useRoomControls()
  const {tags} = useAttendeeProfile()
  const isHost = tags.includes(TEAM_ACCESS_HOST_TAG)
  const isCoHost = tags.includes(TEAM_ACCESS_COHOST_TAG)
  const isTechCheckRoom = room.area.is_tech_check

  if (!showing) {
    return null
  }

  if (isTechCheckRoom) {
    return <TechCheckControls meetingHasStarted={meetingHasStarted} />
  }

  if (isHost) {
    return <HostControls meetingHasStarted={meetingHasStarted} />
  }

  if (isCoHost) {
    return <CoHostControls showing={meetingHasStarted} />
  }

  return <DefaultControls showing={meetingHasStarted} />
}

function ZoomMeetingNotStartedText(props: {showing: boolean}) {
  const {showing} = props

  if (!showing) {
    return null
  }

  return <span aria-label={'zoom not started'}>Zoom Meeting Not Started</span>
}

function DefaultControls(props: {showing: boolean}) {
  const {showing} = props

  if (!showing) {
    return null
  }

  return <JoinAsAttendeeButton />
}

function CoHostControls(props: {showing: boolean}) {
  const {showing} = props

  if (!showing) {
    return null
  }

  return (
    <>
      <StartRoomButton>Join Room as Co-Host</StartRoomButton>
      <JoinAsAttendeeButton />
    </>
  )
}

function HostControls(props: {meetingHasStarted: boolean}) {
  const {meetingHasStarted} = props

  const text = meetingHasStarted ? 'Join Room as Co-Host' : 'Start Zoom Meeting'

  return <StartRoomButton>{text}</StartRoomButton>
}

function TechCheckControls(props: {meetingHasStarted: boolean}) {
  const {meetingHasStarted} = props

  const startText = meetingHasStarted
    ? 'Join Tech Check Room'
    : 'Start Tech Check Room'

  return (
    <>
      <StartRoomButton>{startText}</StartRoomButton>
      <TurnOffRoomButton />
    </>
  )
}

import {
  Broadcast,
  isEmailBroadcast,
} from 'organization/Event/Broadcasts/BroadcastsProvider'
import React from 'react'
import TextField from 'lib/ui/TextField'
import {useBroadcastForm} from 'organization/Event/Broadcasts/BroadcastForm'

export default function SubjectInput(props: {disabled?: boolean}) {
  const {disabled} = props
  const {broadcast, register} = useBroadcastForm()

  return (
    <TextField
      {...props}
      disabled={disabled}
      defaultValue={targetSubject(broadcast)}
      label="Subject"
      name="channel.subject"
      inputProps={{
        ref: register,
        'aria-label': 'subject',
      }}
    />
  )
}

function targetSubject(broadcast?: Broadcast) {
  if (!broadcast) {
    return null
  }

  if (!isEmailBroadcast(broadcast)) {
    return null
  }

  const subject = broadcast.channel.subject
  if (!subject) {
    return null
  }

  return subject
}

import React from 'react'
import Box from '@material-ui/core/Box'
import {RelativeLink} from 'lib/ui/link/RelativeLink'
import {useEventRoutes} from 'organization/Event/EventRoutes'
import {useTemplate} from 'Event/TemplateProvider'
import withStyles from '@material-ui/core/styles/withStyles'
import Page from 'organization/Event/Page'
import {onChangeCheckedHandler} from 'lib/dom'
import ColorPicker from 'lib/ui/ColorPicker'
import {Controller, useForm} from 'react-hook-form'
import {Template} from 'Event/template'
import {useSaveTemplate} from 'Event/TemplateUpdateProvider'
import ImageAssetUploader from 'lib/asset/ImageAssetUploader'
import {spacing} from 'lib/ui/theme'
import {NumberField} from 'lib/ui/TextField'
import PageBreadcrumbs from 'organization/Event/Page/PageBreadcrumbs'
import Button from 'lib/ui/Button'
import Switch from 'lib/ui/form/Switch'
import Slider from 'lib/ui/Slider'
import InputLabel from 'lib/ui/InputLabel'

export default function ImageWaterfallSettings() {
  const {
    imageWaterfall: {page},
  } = useTemplate()
  const routes = useEventRoutes()
  const {control, handleSubmit} = useForm()
  const update = useSaveTemplate()

  const submit = (data: Template['imageWaterfall']['page']) => {
    update({
      imageWaterfall: {
        page: data,
      },
    })
  }

  return (
    <PageBreadcrumbs
      parent={{
        title: 'Production',
        url: routes.webhooks,
      }}
      page="Image Waterfall"
    >
      <Page>
        <Box display="flex" justifyContent="flex-end" mb={2}>
          <RelativeLink
            to={routes.image_waterfall.root}
            aria-label="view image waterfall page"
            newTab
            disableStyles
          >
            <Button variant="outlined">View Image Waterfall</Button>
          </RelativeLink>
        </Box>
        <form onSubmit={handleSubmit(submit)}>
          <Box display="flex" flexDirection="column" flex="1" mb={2}>
            <Controller
              name="backgroundEnabled"
              defaultValue={page.backgroundEnabled}
              control={control}
              render={({value, onChange}) => (
                <Switch
                  checked={value}
                  onChange={onChangeCheckedHandler(onChange)}
                  aria-label="toggle background visible"
                  labelPlacement="end"
                  label={
                    value
                      ? 'Background Image Enabled'
                      : 'Background Image Disabled'
                  }
                />
              )}
            />
          </Box>
          <Box mb={2}>
            <Label>Background Image</Label>
            <Controller
              name="backgroundImage"
              control={control}
              defaultValue={page.backgroundImage}
              render={({onChange, value}) => (
                <ImageAssetUploader
                  onChange={onChange}
                  value={value}
                  uploadLabel="Upload"
                  canResize
                  removeButtonProps={{
                    'aria-label':
                      'remove image waterfall page background image',
                  }}
                  uploadInputProps={{
                    'aria-label':
                      'upload image waterfall page background image',
                  }}
                />
              )}
            />
          </Box>
          <Controller
            name="backgroundColor"
            defaultValue={page.backgroundColor}
            control={control}
            render={({value, onChange}) => (
              <ColorPicker
                label="Background Color"
                color={value}
                onPick={onChange}
                aria-label="image waterfall page background color"
              />
            )}
          />
          <Box mb={1}>
            <Controller
              name="backgroundOpacity"
              defaultValue={page.backgroundOpacity}
              control={control}
              render={({value, onChange}) => (
                <Slider
                  valueLabelDisplay="auto"
                  aria-label="image waterfall background opacity"
                  value={value}
                  valueLabelFormat={() => <div>{value}</div>}
                  onChange={onChange}
                  step={1}
                  min={0}
                  max={100}
                  unit="%"
                  label="Background Opacity"
                />
              )}
            />
          </Box>
          <Box mb={1}>
            <Controller
              name="addImageIntervalSecs"
              defaultValue={page.addImageIntervalSecs}
              control={control}
              render={({value, onChange}) => (
                <NumberField
                  variant="outlined"
                  label="Delay Between Images"
                  fullWidth
                  value={value}
                  onChange={onChange}
                  inputProps={{
                    min: 1,
                    'aria-label': 'delay between images',
                  }}
                />
              )}
            />
          </Box>
          <Box mb={1}>
            <Controller
              name="numColumns"
              defaultValue={page.numColumns}
              control={control}
              render={({value, onChange}) => (
                <NumberField
                  variant="outlined"
                  label="Number of Columns"
                  fullWidth
                  value={value}
                  onChange={onChange}
                  inputProps={{
                    min: 1,
                    max: 10,
                    'aria-label': 'number of columns',
                  }}
                />
              )}
            />
          </Box>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            hoverBackgroundColor="secondary"
            fullWidth
            aria-label="save"
            hasRipple
          >
            Save
          </Button>
        </form>
      </Page>
    </PageBreadcrumbs>
  )
}

const Label = withStyles({
  root: {
    marginBottom: spacing[3],
  },
})(InputLabel)

import Box from '@material-ui/core/Box'
import ColorPicker from 'lib/ui/ColorPicker'
import Switch from 'lib/ui/form/Switch'
import {FontFamilySelect} from 'lib/FontSelect'
import {SectionTitle} from 'organization/Event/Page'
import React from 'react'
import InputLabel from 'lib/ui/TextField/InputLabel'
import {onChangeCheckedHandler} from 'lib/dom'
import Page from 'organization/Event/Page'
import {SaveButton} from 'organization/Event/DashboardConfig/ConfigDialog'
import {Controller, useForm} from 'react-hook-form'
import FormControl from 'lib/ui/FormControl'
import {DesignBreadcrumbs} from 'organization/Event/Page/PageBreadcrumbs'
import {
  Townhall,
  useTownhallTemplate,
  useTownhallUpdate,
} from 'Event/template/Townhall'

type GlobalStylesFormData = Pick<
  Townhall,
  'textColor' | 'linkColor' | 'linkUnderline'
>

export default function GlobalStylesConfig() {
  const template = useTownhallTemplate()
  const updateBackground = useTownhallUpdate()
  const {handleSubmit, control} = useForm()

  const save = (data: GlobalStylesFormData) => {
    updateBackground({
      ...data,
    })
  }
  return (
    <DesignBreadcrumbs page="Global Styles">
      <Page>
        <SectionTitle>Global Styles</SectionTitle>
        <form onSubmit={handleSubmit(save)}>
          <FormControl>
            <InputLabel>Font Family</InputLabel>
            <Controller
              control={control}
              name="font"
              defaultValue={template.font || null}
              render={({value, onChange}) => (
                <FormControl fullWidth>
                  <FontFamilySelect value={value} onChange={onChange} />
                </FormControl>
              )}
            />
          </FormControl>

          <Box mb={2}>
            <Controller
              name="textColor"
              defaultValue={template.textColor}
              control={control}
              render={({value, onChange}) => (
                <ColorPicker
                  label="Text Color"
                  color={value}
                  onPick={onChange}
                  aria-label="text color"
                />
              )}
            />
          </Box>
          <Box mb={2}>
            <Controller
              name="linkColor"
              defaultValue={template.linkColor}
              control={control}
              render={({value, onChange}) => (
                <ColorPicker
                  label="Link Color"
                  color={value}
                  onPick={onChange}
                  aria-label="link color"
                />
              )}
            />
          </Box>
          <Box mb={2}>
            <Controller
              name="linkUnderline"
              defaultValue={template.linkUnderline}
              control={control}
              render={({value, onChange}) => (
                <Switch
                  label="Link Underline"
                  checked={value}
                  onChange={onChangeCheckedHandler(onChange)}
                  labelPlacement="end"
                />
              )}
            />
          </Box>
          <SaveButton />
        </form>
      </Page>
    </DesignBreadcrumbs>
  )
}

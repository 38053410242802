import {User} from 'auth/user'
import PanelsDashboard from 'Event/template/Panels/Dashboard'
import React, {useEffect} from 'react'
import {useHistory} from 'react-router-dom'

export default function Sponsors(props: {user: User}) {
  const history = useHistory()

  // Panels has tab nav rather than pages, so we'll pre-select the sponsors
  // tab via url.
  useEffect(() => {
    history.push({
      search: 'tab=sponsors',
    })
  }, [history])

  return <PanelsDashboard user={props.user} />
}

import Page from 'Event/template/NiftyFifty/Page'
import React from 'react'
import styled from 'styled-components'
import LeftPanel from 'Event/template/NiftyFifty/check-in/LeftPanel'
import RightPanel from 'Event/template/NiftyFifty/check-in/RightPanel'
import MobilePanel from 'Event/template/NiftyFifty/check-in/MobilePanel'
import Waivers from 'Event/Step2/Waivers'
import ProgressBar from 'Event/template/NiftyFifty/check-in/ProgressBar'
import {User} from 'auth/user'

export default function Step2(props: {user: User}) {
  return (
    <Page
      Left={<LeftPanel />}
      Right={
        <RightPanel>
          <ProgressBar textKey="step2Text" percentKey="step2Percent" />
          <Content user={props.user} />
        </RightPanel>
      }
      Mobile={
        <MobilePanel>
          <Content user={props.user} />
        </MobilePanel>
      }
    />
  )
}

function Content(props: {user: User}) {
  return (
    <Container>
      <Waivers user={props.user} />
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 60%;
  form {
    width: 100%;
    overflow-y: auto;
  }

  padding-bottom: ${(props) => props.theme.spacing[4]};

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    padding-bottom: none;
    justify-content: center;
    width: 100%;
    max-height: 80vh;
  }
`

import React from 'react'
import MuiTextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import timezones from 'timezones-list'
import {handleAutocomplete} from 'lib/dom'
import styled from 'styled-components'

interface TimeZonePickerProps {
  value?: string | null
  onChange: (val: string) => void
  label?: string
  className?: string
  'aria-label'?: string
  disabled?: boolean
  error?: string
}

export default function TimeZonePicker(props: TimeZonePickerProps) {
  const {value, onChange, className} = props

  const autoCompleteValue = props.value
    ? timezones.find((item) => item.tzCode === props.value)
    : null

  const onChangeTimeZone = (val: any) => {
    onChange(val.tzCode)
  }
  return (
    <Autocomplete
      className={className}
      options={timezones}
      // Avoid TS 'autoCompleteValue' null error, and MUI undefined is
      // not allowed error.
      value={autoCompleteValue as typeof timezones[0]}
      getOptionLabel={(option) => `${option.tzCode} (UTC ${option.utc}) `}
      getOptionSelected={(option) => option.tzCode === value}
      onChange={handleAutocomplete(onChangeTimeZone)}
      disableClearable
      disabled={props.disabled}
      renderInput={(params) => (
        <StyledTextField
          {...params}
          variant="outlined"
          inputProps={{
            ...params.inputProps,
            'aria-label': 'time zone search input',
          }}
          InputProps={{
            ...params.InputProps,
          }}
          error={Boolean(props.error)}
          helperText={props.error}
        />
      )}
      aria-label={props['aria-label'] || 'pick time zone'}
    />
  )
}

const StyledTextField = styled(MuiTextField)`
  .MuiOutlinedInput-root {
    padding: unset;
    padding-left: ${(props) => props.theme.spacing[2]};
  }
`

import React from 'react'
import styled from 'styled-components'
import Container from '@material-ui/core/Container'
import {User} from 'auth/user'
import {useTownhallTemplate} from 'Event/template/Townhall'
import {rgba} from 'lib/color'
import LanguageSelector from 'Event/LanguageSelector'
import {muiTheme} from 'lib/ui/theme'
import {ThemeProvider} from '@material-ui/core/styles'
import {withStyles} from '@material-ui/core/styles'
import {RelativeLink} from 'lib/ui/link/RelativeLink'
import {Font, useLoadFont} from 'lib/FontSelect'
import MuiBreadcrumbs from '@material-ui/core/Breadcrumbs'
import {Typography} from 'lib/ui/typography'
import chevronRight from 'lib/ui/AppBar/icons/chevron-right.svg'
import {HouseIcon} from 'lib/ui/Icon'
import {eventRoutes} from 'Event/Routes'
import Configurable from 'organization/Event/Configurable'
import {useAttendeeVariables} from 'Event'
import TextContent from 'lib/ui/form/TextEditor/Content'
import TopBar from 'Event/template/Townhall/Dashboard/TopBar'

export default function Page(props: {
  user: User
  children: React.ReactElement | React.ReactElement[]
}) {
  const template = useTownhallTemplate()
  const {pageBackground: page} = template
  const pageBackground = page.image ? `url(${page.image})` : '#FFFFFF'

  const backgroundRGBColor = page
    ? rgba(page.color || '#FFFFFF', page.opacity / 100 || 0)
    : '#FFFFFF'

  const color = template.textColor
  const linkUnderline = template.linkUnderline
  const linkColor = template.linkColor
  const font = template.font

  useLoadFont(font)

  return (
    <ThemeProvider theme={muiTheme}>
      <TopBar />
      <Box
        background={pageBackground}
        color={color}
        linkColor={linkColor}
        linkUnderline={linkUnderline}
        font={font}
      >
        <ColorOverlay color={backgroundRGBColor}>
          <Content>
            <StyledContainer maxWidth="lg">{props.children}</StyledContainer>
          </Content>
        </ColorOverlay>
      </Box>
      <LanguageSelector />
    </ThemeProvider>
  )
}

export const StyledContainer = withStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    '@media (min-width: 600px)': {
      width: '80%',
    },
  },
})(Container)

export function TownhallBreadcrumbs(props: {page: string}) {
  return (
    <StyledMuiBreadcrumbs
      aria-label="townhall breadcrumb"
      separator={<img src={chevronRight} alt="arrow right" />}
    >
      <BreadcrumbDashboardLink to={eventRoutes.root}>
        <StyledHouseIcon />
        <span>Dashboard</span>
      </BreadcrumbDashboardLink>
      <BreadcrumbStaticText>{props.page}</BreadcrumbStaticText>
    </StyledMuiBreadcrumbs>
  )
}

export function PageDescription(props: {
  description: string
  children: JSX.Element
}) {
  const {description, children} = props

  if (!description) {
    return null
  }

  return <PageDescriptionContainer>{children}</PageDescriptionContainer>
}

export function PageTexts(props: {title: string; description: string}) {
  const v = useAttendeeVariables()
  const {title, description} = props
  const isEmpty =
    (!title || title === '') && (!description || description === '')

  if (isEmpty) {
    return null
  }

  return (
    <TextContainer>
      <SecondaryTitle>{v(title)}</SecondaryTitle>
      <PageDescription description={description}>
        <TextContent>{v(description)}</TextContent>
      </PageDescription>
    </TextContainer>
  )
}

export const Box = styled.div<{
  background: string
  color: string
  linkColor: string
  linkUnderline: boolean
  font: Font | null
}>`
  height: auto;
  ${(props) => (props.font ? `font-family: ${props.font.family};` : '')}
  font-size: 17px;
  color: ${(props) => props.color};
  background: ${(props) => props.background};
  background-position: bottom;
  background-size: cover;
  background-repeat: no-repeat;

  a {
    color: ${(props) => props.linkColor};
    text-decoration: none;

    &:hover {
      text-decoration: ${(props) =>
        props.linkUnderline ? 'underline' : 'none'};
    }
  }
`

export const Content = styled.div`
  flex: 1;
  margin-bottom: 70px;
  display: flex;
  z-index: 1;
`

export const ColorOverlay = styled.div<{
  color: string
}>`
  background-color: ${(props) => props.color};
  height: 100%;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`

export const FeaturePageTitle = styled.h2`
  font-style: normal;
  font-weight: 500;
  font-size: 48px;
  line-height: 64px;
  width: 100%;
  margin-top: 36px;
  margin-bottom: 8px;
`

export const SecondaryTitle = styled(FeaturePageTitle)`
  margin-top: 0;
`

export const TextContainer = styled.div`
  width: 100%;
  margin-bottom: ${(props) => props.theme.spacing[16]};
`

export const PageTitle = styled.h2`
  font-size: 42px;
  line-height: 1.5;
  text-transform: uppercase;
  width: 100%;
`

export const PageDescriptionContainer = styled.div`
  margin: 30px 0;

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    width: 100%;
  }
`

export const BackToDashboardLink = styled(RelativeLink)<{
  color?: string
}>`
  display: flex;
  text-align: center;
  line-height: 1.5;
  color: ${(props) => (props.color ? props.color : '#000000')} !important;
`

const StyledMuiBreadcrumbs = styled(MuiBreadcrumbs)`
  width: 100%;
  margin-bottom: 36px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
`

const StyledHouseIcon = styled(HouseIcon)`
  margin-right: 10px;
`

const BreadcrumbDashboardLink = styled(RelativeLink)`
  color: ${(props) => props.theme.colors.gray100} !important;
`

const BreadcrumbStaticText = styled(Typography)`
  color: #000000;
`

export const StyledConfigurable = styled(Configurable)`
  button {
    top: 40px;
  }
`

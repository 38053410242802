import Subheading from 'lib/ui/typography/Subheading'
import {Section} from 'obvio/Billing'
import TransactionsTable from 'obvio/Billing/TransactionSection/TransactionsTable'
import {useTransactions} from 'obvio/Billing/TransactionSection/list-transactions'
import React from 'react'

export default function TransactionSection() {
  const {transactions, goToPage, markComplete, paginated} = useTransactions(
    '/transactions',
  )

  return (
    <>
      <Subheading>Transactions</Subheading>
      <Section>
        <TransactionsTable
          transactions={transactions}
          goToPage={goToPage}
          markComplete={markComplete}
          paginated={paginated}
        />
      </Section>
    </>
  )
}

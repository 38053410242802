import React, {useState} from 'react'
import styled from 'styled-components'
import useDebounce from 'lib/debounce'
import Button from 'lib/ui/Button'
import TextField from 'lib/ui/TextField'
import {useEventTickets} from 'lib/marketplace-api/tickets/use-event-tickets'

export default function TicketFilter() {
  const [filterName, setFilterName] = useState<string>('')
  const debouncedFilterName = useDebounce(filterName, 500)

  useEventTickets({
    name: debouncedFilterName,
  })

  const clearFilter = () => {
    setFilterName('')
  }

  const clearButton = (
    <StyledButton variant="contained" color="primary" onClick={clearFilter}>
      Clear
    </StyledButton>
  )

  return (
    <TextField
      endAdornment={clearButton}
      onChange={(value) => {
        setFilterName(value)
      }}
      placeholder="Search"
      value={filterName || ''}
      variant="outlined"
    />
  )
}

const StyledButton = styled(Button)`
  margin-left: ${(props) => props.theme.spacing[2]};
`

import Command from '@ckeditor/ckeditor5-core/src/command';
import { isCustomButtonElement } from '../utils';

export default class CustomButtonFontSizeCommand extends Command {
	refresh() {
		const element = this.editor.model.document.selection.getSelectedElement();

		this.isEnabled = isCustomButtonElement( element );

		if ( isCustomButtonElement( element ) && element.hasAttribute( 'buttonFontSize' ) ) {
			this.value = parseInt( element.getAttribute( 'buttonFontSize' ) );
		} else {
			this.value = false;
		}
	}

	execute( options ) {
		const model = this.editor.model;
		const customButtonElement = model.document.selection.getSelectedElement();
		this.value = options.value;

		model.change( writer => {
			writer.setAttribute( 'buttonFontSize', options.value, customButtonElement );
		} );
	}
}


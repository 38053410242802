import React, {useCallback} from 'react'
import {useDropzone} from 'react-dropzone'
import styled from 'styled-components'
import Typography from '@material-ui/core/Typography'
import AttachFile from '@material-ui/icons/AttachFile'
import Close from '@material-ui/icons/Close'
import {Note, NoteContext} from 'lib/note'
import {useAttendeeVariables} from 'Event'
import Box from '@material-ui/core/Box'
import IconButton from '@material-ui/core/IconButton'
import InputLabel from '@material-ui/core/InputLabel'

export default function Attachments(props: {
  day: number
  attachments: NoteContext
  disabled: boolean
  files: Note['files']
  onAdd: (file: File) => void
  onRemove: (onRemove: File) => void
  newFiles: File[]
}) {
  const {attachments, disabled, files, onAdd, onRemove, newFiles} = props

  const v = useAttendeeVariables()

  const onDrop = useCallback(
    (acceptedFiles) => {
      for (const file of acceptedFiles) {
        onAdd(file)
      }
    },
    [onAdd],
  )

  const {getRootProps, getInputProps, isDragActive} = useDropzone({
    onDrop,
    accept: ['application/pdf', 'image/*', 'video/*', '.pdf', 'xlsx'],
    disabled: props.disabled,
  })

  const {css: _css, ...rootProps} = getRootProps()

  const dropHereLabel = v('{{team_drop_files_here}}', 'Drop the files here...')

  const uploadInstructions = v(
    '{{team_upload_instructions}}',
    'Click or Drag and drop files here to upload',
  )

  const dropzoneLabel = isDragActive ? dropHereLabel : uploadInstructions

  return (
    <Container disabled={disabled}>
      <StyledInputLabel>
        {v(
          '{{team_upload_files}}',
          'Upload Files: PDF / Images / Video (Max. 20MB per file)',
        )}
      </StyledInputLabel>
      <ul>
        {files.map((file) => (
          <FileLine key={file.original_name}>
            <a href={file.url} target="_blank" rel="noopener noreferrer">
              <Typography>{file.original_name}</Typography>
            </a>
            <IconButton
              className="delete-button"
              size="small"
              color="secondary"
              onClick={() => attachments.removeFile(file.hash)}
              aria-label="remove attachment"
            >
              <Close />
            </IconButton>
          </FileLine>
        ))}
      </ul>
      <ul>
        {newFiles.map((file) => (
          <FileLine key={file.name}>
            <Typography>{file.name}</Typography>
            <IconButton
              className="delete-button"
              size="small"
              color="secondary"
              onClick={() => onRemove(file)}
              aria-label="remove attachment"
            >
              <Close />
            </IconButton>
          </FileLine>
        ))}
      </ul>
      <Box
        {...rootProps}
        p={5}
        border="1px dashed #d2d2d2"
        borderRadius={4}
        color="rgba(0,0,0,0.54)"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <input {...getInputProps()} aria-label="upload attachment" />
        <AttachFile htmlColor="#000000" />
        <Typography color="textPrimary">{dropzoneLabel}</Typography>
      </Box>
    </Container>
  )
}

const FileLine = styled.li`
  display: flex;
  align-items: flex-start;

  .delete-button {
    margin-top: -6px;
    margin-left: -4px;
    transform: scale(0.5);
  }
`

const StyledInputLabel = styled(InputLabel)`
  color: #000000;
`

const Container = styled.div<{disabled?: boolean}>`
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  opacity: ${(props) => (props.disabled ? 0.6 : 1)};
`

import React from 'react'
import {RelativeLink} from 'lib/ui/link/RelativeLink'
import {NavButtonProps} from 'Event/Dashboard/components/NavButton'
import {Button} from 'Event/Dashboard/components/NavButton'

/**
 * A NavButton used to navigate tabs, such as '?tab=sponsors'.
 *
 * @param props
 * @returns
 */
export default function TabNavButton(props: NavButtonProps) {
  const {newTab, 'aria-label': ariaLabel, page = '', ...buttonProps} = props

  const tab = page?.startsWith('/') ? page.substring(1) : page // Remove any leading '/'
  const url = `?tab=${tab}`

  return (
    <RelativeLink to={url} disableStyles aria-label={ariaLabel} newTab={newTab}>
      <Button {...buttonProps} />
    </RelativeLink>
  )
}

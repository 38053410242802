import React from 'react'
import {Controller, UseFormMethods} from 'react-hook-form'
import {NavButtonWithSize} from 'Event/Dashboard/components/NavButton'
import ActionSelect from 'Event/ActionsProvider/ActionSelect'
import {CardsNavButtonProps} from 'Event/template/Cards/Dashboard/CardsNavButton'

export default function ActionField(props: {
  button: NavButtonWithSize | CardsNavButtonProps
  control: UseFormMethods['control']
}) {
  const {control, button} = props

  return (
    <Controller
      name="actionId"
      defaultValue={button.actionId || ''}
      control={control}
      render={({value, onChange}) => (
        <ActionSelect value={value} onChange={onChange} />
      )}
    />
  )
}

import Card from 'Event/template/SimpleBlog/Speakers/SpeakerList/Card'
import Grid, {GridSpacing} from '@material-ui/core/Grid'
import React from 'react'
import Typography from '@material-ui/core/Typography'
import {DragDropContext, Droppable} from 'react-beautiful-dnd'
import {useSimpleBlogTemplate} from 'Event/template/SimpleBlog'
import {orderedIdsByPosition} from 'lib/list'
import {useEditMode} from 'Event/EditModeProvider'
import {useHandleDragSpeakers} from 'organization/Event/SpeakersConfig'
import {VisibleSpeaker} from 'Event/Speakers'

export default function SpeakerList() {
  const template = useSimpleBlogTemplate()
  const speakerItems = template.speakers.items
  const isEditMode = useEditMode()

  const ids = orderedIdsByPosition(speakerItems)

  const isEmpty = ids.length === 0
  if (isEmpty) {
    return <Typography align="center">No speakers have been added</Typography>
  }

  const spacing = template.speakers?.spacing as GridSpacing

  const speakers = ids.map((id, index) => (
    <VisibleSpeaker key={id} speaker={speakerItems[id]}>
      <Grid item xs={12} key={id}>
        <Card id={id} speaker={speakerItems[id]} index={index} />
      </Grid>
    </VisibleSpeaker>
  ))

  if (!isEditMode) {
    return (
      <Grid container spacing={spacing}>
        {speakers}
      </Grid>
    )
  }

  return <DraggableSpeakers>{speakers}</DraggableSpeakers>
}

function DraggableSpeakers(props: {children: React.ReactElement[]}) {
  const handleDrag = useHandleDragSpeakers()
  const template = useSimpleBlogTemplate()

  return (
    <DragDropContext onDragEnd={handleDrag}>
      <Droppable droppableId="drag-and-drop-speaker">
        {(provided) => (
          <Grid
            container
            spacing={template.speakers.spacing as GridSpacing}
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            <>
              {props.children}
              {provided.placeholder}
            </>
          </Grid>
        )}
      </Droppable>
    </DragDropContext>
  )
}

import React, {useState, useEffect} from 'react'
import styled from 'styled-components'
import {withStyles} from '@material-ui/core/styles'
import Tabs from '@material-ui/core/Tabs'
import Button from '@material-ui/core/Button'
import {DarkThemeProvider} from 'lib/ui/theme/ThemeProvider'
import TabPanel from 'lib/ui/ConfigPanel/TabPanel'
import ConfigDrawer, {Title} from 'lib/ui/ConfigDrawer'
import {
  SaveButton,
  Footer,
  RulesTab,
} from 'organization/Event/DashboardConfig/ComponentConfigPanel'
import TagsInput, {Tag} from 'lib/ui/form/TagsInput'
import {spacing} from 'lib/ui/theme'
import {
  Group,
  usePreviewAttendeeProfile,
} from 'Event/attendee-rules/AttendeeProfileProvider/PreviewAttendeeProfileProvider'
import ConfirmRemoveButton from 'lib/ui/Button/ConfirmRemoveButton'
import LocalizedDateTimePicker from 'lib/LocalizedDateTimePicker'
import {onChangeDate} from 'lib/dom'
import {useStaticDate} from 'lib/date-time/StaticDateProvider'
import {usePrevious} from 'lib/state'
import {usePreviewSubmissions} from 'Event/SubmissionsProvider/PreviewSubmissionsProvider'
import MenuItem from '@material-ui/core/MenuItem'
import Menu from 'lib/ui/Menu'
import {AnswerInput} from 'Event/Dashboard/editor/views/ConfigBar/PreviewConfig/AnswerInput'
import {useEvent} from 'Event/EventProvider'
import {Panel} from 'organization/Event/DashboardConfig/ComponentConfigPanel/Panel'
import GroupInput from 'lib/ui/form/GroupInput'

export default function PreviewConfig(props: {
  showing: boolean
  onClose: () => void
}) {
  const {showing, onClose} = props

  const {
    tags: previewTags,
    setTags: setPreviewTags,
    groups: previewGroups,
    setGroups: setPreviewGroups,
    save: saveProfile,
  } = usePreviewAttendeeProfile()

  const {date: savedDate, save: saveDate} = useSavedDate()

  const previewSubmissions = usePreviewSubmissions()
  const prevShowing = usePrevious(showing)
  const staticDate = useStaticDate()

  const [tags, setTags] = useState<Tag[]>(previewTags)
  const [groups, setGroups] = useState<Group[]>([])
  const [date, setDate] = useState<string | null>(staticDate.date)

  const addGroup = () => {
    const added = [...groups, {key: '', value: ''}]
    setGroups(added)
  }

  const updateGroup = (targetIndex: number) => (updated: Group) => {
    const updatedList = groups.map((g, i) => {
      const isTarget = i === targetIndex
      if (isTarget) {
        return updated
      }

      return g
    })

    setGroups(updatedList)
  }

  const removeGroup = (targetIndex: number) => () => {
    const updated = [...groups]
    updated.splice(targetIndex, 1)
    setGroups(updated)
  }

  const save = () => {
    saveProfile(groups, tags)
    saveDate(date)
    previewSubmissions.saveAnswers()
  }

  const clearAll = () => {
    setGroups([])
    setTags([])
    setDate(null)
    previewSubmissions.clear()
    save()
  }

  const handleClose = () => {
    setPreviewGroups(groups)
    setPreviewTags(tags)
    staticDate.setDate(date)
    save()
    onClose()
  }

  // Load values
  useEffect(() => {
    const onOpen = showing && !prevShowing
    if (!onOpen) {
      return
    }

    setTags(previewTags)
    setGroups(previewGroups)
    setDate(staticDate.date)
  }, [previewGroups, previewTags, showing, prevShowing, staticDate])

  // Load a saved date if one exists
  useEffect(() => {
    if (savedDate) {
      staticDate.setDate(savedDate)
    }
  }, [savedDate, staticDate])

  return (
    <ConfigDrawer open={showing} onClose={onClose}>
      <DarkThemeProvider>
        <Title>Preview Rules</Title>
        <StyledTabs
          value={0}
          TabIndicatorProps={{
            style: {
              display: 'none',
            },
          }}
          scrollButtons="off"
        >
          <RulesTab />
        </StyledTabs>
        <Content>
          <TabPanel value={0} index={0}>
            <Panel>
              <LocalizedDateTimePicker
                label="Date"
                value={date}
                onChange={onChangeDate(setDate)}
                fullWidth
                onClear={() => setDate(null)}
                inputProps={{
                  'aria-label': 'date input',
                }}
              />
              <TagsInput
                value={tags}
                onChange={setTags}
                name="tags"
                aria-label="tags"
                label="Tags"
              />
              {groups.map((group, index) => (
                <GroupInput
                  key={index}
                  group={group}
                  onChange={updateGroup(index)}
                  remove={removeGroup(index)}
                />
              ))}
              {previewSubmissions.answers.map((answer, index) => (
                <AnswerInput key={index} answer={answer} />
              ))}
              <Menu
                button={({open}) => (
                  <AddButton
                    variant="outlined"
                    color="primary"
                    onClick={open}
                    fullWidth
                    size="large"
                  >
                    Add
                  </AddButton>
                )}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
              >
                <MenuItem onClick={addGroup}>Group</MenuItem>
                <MenuItem onClick={previewSubmissions.add}>
                  Form Response
                </MenuItem>
              </Menu>
              <ConfirmRemoveButton
                aria-label="clear all"
                onClick={clearAll}
                fullWidth
                size="large"
                variant="outlined"
              >
                Clear All
              </ConfirmRemoveButton>
            </Panel>
          </TabPanel>
        </Content>
        <Footer>
          <SaveButton onClick={handleClose} />
        </Footer>
      </DarkThemeProvider>
    </ConfigDrawer>
  )
}

function useSavedDate() {
  const {event} = useEvent()
  const key = `__obvio_${event.id}_preview_date__`

  const date = localStorage.getItem(key)

  const save = (date: string | null) => {
    if (!date) {
      localStorage.removeItem(key)
      return
    }

    localStorage.setItem(key, date)
  }

  return {date, save}
}

const StyledTabs = withStyles({
  flexContainer: {
    overflow: 'hidden',
  },
})(Tabs)

const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

const AddButton = withStyles({
  root: {
    marginTop: spacing[4],
    marginBottom: spacing[4],
  },
})(Button)

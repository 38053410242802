import React from 'react'
import SpeakerList from 'Event/template/Cards/Speakers/SpeakerList'
import {PageDescription, PageTitle} from 'Event/template/Cards/Page'
import {useAttendeeVariables} from 'Event'
import {useCardsTemplate} from 'Event/template/Cards'
import Content from 'lib/ui/form/TextEditor/Content'
import CardsPage from 'Event/template/Cards/Page'
import {User} from 'auth/user'
import AddSpeakerButton from 'Event/Speakers/AddSpeakerButton'
import Configurable from 'organization/Event/Configurable'
import HeaderConfig from 'Event/Speakers/HeaderConfig'

export default function SpeakerPage(props: {user: User}) {
  const {
    speakers: speakerPageSettings,
    linkColor,
    linkUnderline,
  } = useCardsTemplate()
  const v = useAttendeeVariables()

  return (
    <CardsPage user={props.user}>
      <Configurable>
        <HeaderConfig />
        <PageTitle aria-label="speakers title">
          {v(speakerPageSettings.title)}
        </PageTitle>
      </Configurable>
      <PageDescription linkColor={linkColor} linkUnderline={linkUnderline}>
        <Content>{v(speakerPageSettings.description)}</Content>
      </PageDescription>
      <SpeakerList />
      <AddSpeakerButton />
    </CardsPage>
  )
}

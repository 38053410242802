import styled from 'styled-components'
import React from 'react'
import NavButton, {NavButtonProps} from 'Event/Dashboard/components/NavButton'
import {EditComponentOverlay} from 'Event/Dashboard/editor/views/EditComponent'
import {Draggable} from 'react-beautiful-dnd'
import {DraggableOverlay, DragHandle} from 'lib/ui/drag-and-drop'
import SponsorButtonConfig from 'Event/Sponsors/Buttons/SponsorButtonConfig'
import {useToggle} from 'lib/toggle'
import {useLoadFont} from 'lib/FontSelect'
import {useButtons} from 'organization/Event/SponsorsConfig'

export default function Button(props: {
  button: NavButtonProps
  sponsorId: string
  id: string
  index: number
  isEditMode?: boolean
}) {
  const isEditMode = props.isEditMode
  const {button} = props
  useLoadFont(button.font)

  if (!isEditMode) {
    return <NavButton {...button} aria-label="sponsor button" />
  }

  return <EditableButton {...props} />
}

function EditableButton(props: {
  button: NavButtonProps
  id: string
  index: number
  sponsorId: string
}) {
  const {
    duplicate: duplicateButton,
    update: updateButton,
    remove: removeButton,
  } = useButtons(props.sponsorId)

  const {index, id, button} = props
  const {flag: configVisible, toggle: toggleConfig} = useToggle()

  const edit = () => {
    toggleConfig()
  }

  const duplicate = () => {
    duplicateButton(id)
  }

  return (
    <>
      <SponsorButtonConfig
        button={button}
        onClose={toggleConfig}
        showing={configVisible}
        onChange={updateButton(id)}
        onRemove={() => removeButton(id)}
        id={id}
      />
      <Draggable draggableId={id} index={index} key={id}>
        {(provided) => (
          <ButtonBox ref={provided.innerRef} {...provided.draggableProps}>
            <DraggableOverlay>
              <EditComponentOverlay
                onClick={edit}
                onCopy={duplicate}
                aria-label="edit sponsor button"
              >
                <>
                  <DragHandle handleProps={provided.dragHandleProps} />
                  <NavButton
                    onClick={edit}
                    aria-label="sponsor button"
                    {...button}
                  />
                </>
              </EditComponentOverlay>
            </DraggableOverlay>
          </ButtonBox>
        )}
      </Draggable>
    </>
  )
}

const ButtonBox = styled.div`
  &:not(:last-child) {
    margin-bottom: ${(props) => props.theme.spacing[1]};
  }
`

import React from 'react'
import {useAttendee} from 'Event/auth'
import {useTemplate} from 'Event/TemplateProvider'
import {useTrackEventPage} from 'analytics'
import PagePoints, {
  SPEAKERS as POINTS_SPEAKERS,
} from 'Event/PointsProvider/PagePoints'
import {Ordered} from 'lib/list'
import {User} from 'auth/user'
import {components} from 'Event/template'
import {HasRules} from 'Event/attendee-rules'
import {useEditMode} from 'Event/EditModeProvider'
import VisibleOnMatch from 'Event/attendee-rules/VisibleOnMatch'
import Scripts, {SPEAKERS as SCRIPTS_SPEAKERS} from 'Event/Scripts'

export interface Speaker extends Ordered, HasRules {
  image: string | null
  name: string
  text: string
  isActive?: boolean
}

export default function SpeakersPage() {
  const user = useAttendee()

  useTrackEventPage({
    page: 'Visited Speakers',
  })

  return (
    <PagePoints page={POINTS_SPEAKERS}>
      <>
        <Scripts page={SCRIPTS_SPEAKERS} />
        <TemplateSpeakers user={user} />
      </>
    </PagePoints>
  )
}

export function TemplateSpeakers(props: {user: User}) {
  const template = useTemplate()
  const Component = components[template.name].Speakers.index
  return <Component user={props.user} />
}

export function VisibleSpeaker(props: {
  children: JSX.Element
  speaker: Speaker
}) {
  const {speaker, children} = props

  // Always render Speaker in edit mode...
  const isEditMode = useEditMode()
  if (isEditMode) {
    return children
  }

  if (speaker.isActive === false) {
    return null
  }

  return (
    <VisibleOnMatch rules={props.speaker.rules}>
      {props.children}
    </VisibleOnMatch>
  )
}

import {useEvent} from 'Event/EventProvider'
import {MarketplaceTicket} from 'lib/marketplace-api/tickets'
import {marketplaceApi} from 'lib/marketplace-api/url'
import {useEventMarketplaceClient} from 'lib/marketplace-api/use-event-marketplace-client'
import {useMutation, useQueryClient} from 'react-query'

export interface CreateTicketData {
  account_id: number
  name: string
  slug: string
  description: string
  tags: string[]
  groups: string[]
  price: number
  available_from: string
  available_to: string
  hidden?: boolean
  active?: boolean
}

export function useCreateTicket() {
  const {client} = useEventMarketplaceClient()
  const qc = useQueryClient()
  const {event} = useEvent()

  return useMutation({
    mutationFn: (data: CreateTicketData) => {
      const url = marketplaceApi(`/events/${event.id}/tickets`)
      return client!.post<MarketplaceTicket>(url, data)
    },
    onSuccess: (ticket) => {
      qc.setQueryData<MarketplaceTicket[]>(
        ['events', event.id, 'tickets'],
        (tickets) => {
          if (!tickets) {
            return [ticket]
          }

          return [...tickets, ticket]
        },
      )

      qc.setQueryData<MarketplaceTicket>(['tickets', ticket.id], () => ticket)
    },
  })
}

import DialogActions from '@material-ui/core/DialogActions'
import styled from 'styled-components'
import DialogContent from '@material-ui/core/DialogContent'
import SingleUseCreditCardForm from 'lib/stripe/SingleUseCreditCardForm'
import {useToggleArray} from 'lib/toggle'
import Button from 'lib/ui/Button'
import ErrorAlert from 'lib/ui/alerts/ErrorAlert'
import {usePayForTransaction} from 'obvio/Billing/TransactionSection/TransactionRow/PayDialog/SavedCardConfirmation'
import React, {useState} from 'react'
import {Title} from 'lib/ui/typography'
import {Transaction} from 'obvio/Billing/TransactionSection/list-transactions'

export default function OtherCardForm(props: {
  transaction: Transaction
  onSuccess: () => void
  onCancel: () => void
}) {
  const {transaction, onSuccess, onCancel} = props
  const [processing, toggleProcessing] = useToggleArray()
  const [error, setError] = useState<string | null>(null)
  const clearError = () => setError(null)
  const payForTransaction = usePayForTransaction(transaction)

  const completePayment = (paymentMethodId: string) => {
    if (processing) {
      return
    }

    toggleProcessing()

    payForTransaction(paymentMethodId)
      .then(onSuccess)
      .catch((e) => {
        setError(e.message)
        toggleProcessing()
      })
  }

  return (
    <StyledDialogContent>
      <Title mt={2} mb={5}>
        Use a Different Card!
      </Title>
      <ErrorAlert onClose={clearError}>{error}</ErrorAlert>
      <SingleUseCreditCardForm submitLabel="Pay" onSuccess={completePayment} />
      <DialogActions>
        <Button
          onClick={onCancel}
          aria-label="cancel different card"
          fullWidth
          disabled={processing}
        >
          Cancel
        </Button>
      </DialogActions>
    </StyledDialogContent>
  )
}

const StyledDialogContent = styled(DialogContent)`
  max-width: 450px;
`

import React from 'react'
import Dialog, {DialogBody} from 'lib/ui/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import styled from 'styled-components'
import Content from 'lib/ui/form/TextEditor/Content'
import Button from 'lib/ui/Button'
import {Header} from 'lib/ui/typography'
import {Speaker} from 'Event/Speakers'
import {useAttendeeVariables} from 'Event'
import {useTownhallTemplate} from 'Event/template/Townhall'

export default function SpeakerModal(props: {
  speaker: Speaker
  showing: boolean
  onClose: () => void
}) {
  const {speaker, showing, onClose} = props
  const template = useTownhallTemplate()
  const {textColor} = template

  return (
    <Dialog open={showing} maxWidth="lg">
      <DialogBody>
        <ModalContent speaker={speaker} color={textColor} />
      </DialogBody>
      <StyledDialogActions>
        <Button variant="contained" color="primary" onClick={onClose}>
          Close
        </Button>
      </StyledDialogActions>
    </Dialog>
  )
}

export function ModalContent(props: {speaker: Speaker; color: string}) {
  const {speaker, color} = props
  const v = useAttendeeVariables()

  if (!speaker.image) {
    return (
      <ContentContainer color={color}>
        <StyledTitle>{v(speaker.name)}</StyledTitle>
        <Content>{v(speaker.text)}</Content>
      </ContentContainer>
    )
  }

  return (
    <ContentContainer color={color}>
      <StyledImage src={speaker.image} />
      <StyledTitle>{v(speaker.name)}</StyledTitle>
      <StyledContent>{v(speaker.text)}</StyledContent>
    </ContentContainer>
  )
}

const ContentContainer = styled.div<{
  color: string
}>`
  color: ${(props) => props.color} !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${(props) => `${props.theme.spacing[4]} 0`};
`

const StyledImage = styled.img`
  border-radius: 50%;
  margin-bottom: ${(props) => props.theme.spacing[6]};
  object-fit: cover;
  width: 15rem;
  height: 15rem;
`

const StyledContent = styled(Content)`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
`

const StyledTitle = styled(Header)`
  margin-bottom: ${(props) => props.theme.spacing[6]};
  color: inherit;
`

const StyledDialogActions = styled(DialogActions)`
  display: flex;
  justify-content: flex-end;
  padding: ${(props) => `${props.theme.spacing[6]}`};
`

import React from 'react'
import {createPositions, orderedIdsByPosition} from 'lib/list'
import {useEditMode} from 'Event/EditModeProvider'
import {DragDropContext, Droppable, DropResult} from 'react-beautiful-dnd'
import Agenda from 'Event/template/Townhall/Dashboard/Main/AgendaSection/AgendaItem'
import {AgendaSectionProps} from 'Event/template/Townhall/Dashboard/Main/AgendaSection'
import AddAgendaButton from 'Event/template/Townhall/Dashboard/Main/AgendaSection/AddAgendaButton'
import styled from 'styled-components'
import {useSaveTemplate} from 'Event/TemplateUpdateProvider'

export default function AgendaListContent(
  props: AgendaSectionProps & {sectionId: string},
) {
  const isEdit = useEditMode()
  if (!isEdit) {
    return <AgendaItemList {...props} />
  }
  return <DraggableList {...props} />
}

function DraggableList(
  props: AgendaSectionProps & {
    sectionId: string
  },
) {
  const {sectionId} = props
  const handleDrag = useHandleDrag(props)

  return (
    <>
      <DragDropContext onDragEnd={handleDrag}>
        <Droppable droppableId="drag-and-drop-agendas">
          {(provided) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              <>
                <AgendaItemList {...props} />
                {provided.placeholder}
              </>
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <StyledAddAgendaEventButton sectionId={sectionId} />
    </>
  )
}

function AgendaItemList(
  props: AgendaSectionProps & {
    sectionId: string
  },
) {
  const {sectionId, separator} = props
  const ids = orderedIdsByPosition(props.items)

  return (
    <>
      {ids.map((id, index) => {
        const agenda = props.items[id]
        return (
          <Agenda
            agenda={agenda}
            id={id}
            key={id}
            index={index}
            sectionId={sectionId}
            borderColor={separator?.color || '#ffffff'}
            color={props.color}
          />
        )
      })}
    </>
  )
}

function useHandleDrag(
  props: AgendaSectionProps & {
    sectionId: string
  },
) {
  const {items, sectionId} = props

  const save = useSaveTemplate()

  return (result: DropResult) => {
    const {destination, source} = result

    if (!destination) {
      return
    }

    const ids = orderedIdsByPosition(items)
    const [removed] = ids.splice(source.index, 1)
    ids.splice(destination.index, 0, removed)

    save({
      dashboardSections: {
        [sectionId]: {
          items: createPositions(ids),
        },
      },
    })
  }
}

const StyledAddAgendaEventButton = styled(AddAgendaButton)`
  margin-top: ${(props) => props.theme.spacing[4]}!important;
`

import {useEditMode} from 'Event/EditModeProvider'
import {LeftySponsor} from 'Event/template/Lefty/Sponsors/SponsorList'
import {useTemplate} from 'Event/TemplateProvider'
import {useSaveTemplate} from 'Event/TemplateUpdateProvider'
import Button from 'lib/ui/Button'
import {uuid} from 'lib/uuid'
import React from 'react'

export default function AddLeftySponsorButton() {
  const isEditMode = useEditMode()
  if (!isEditMode) {
    return null
  }

  return <Content />
}

function Content() {
  const isEditMode = useEditMode()
  const {sponsors} = useTemplate()
  const save = useSaveTemplate()

  const add = () => {
    const numSponsors = Object.keys(sponsors.items).length
    const position = numSponsors + 1
    const id = uuid()

    const newSponsor: LeftySponsor = {
      name: 'Sponsor',
      description: '',
      formId: null,
      buttons: {},
      image: null,
      position,
      level: 1,
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      },
      logoSize: 100,
    }

    save({
      sponsors: {
        items: {
          [id]: newSponsor,
        },
      },
    })
  }

  if (!isEditMode) {
    return null
  }

  return (
    <Button
      fullWidth
      variant="contained"
      color="secondary"
      aria-label="add sponsor"
      onClick={add}
    >
      Add Sponsor
    </Button>
  )
}

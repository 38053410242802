import {DialogTitle} from '@material-ui/core'
import Dialog, {DialogBody} from 'lib/ui/Dialog'
import React from 'react'
import {DarkThemeProvider} from 'lib/ui/theme/ThemeProvider'

export default function ScriptDialog(props: {
  title: string
  showing: boolean
  onClose: () => void
  children: JSX.Element
}) {
  const {showing, onClose, title, children} = props

  return (
    <DarkThemeProvider>
      <Dialog open={showing} onClose={onClose} disableEnforceFocus expandable>
        <DialogTitle>{title}</DialogTitle>
        <DialogBody>{children}</DialogBody>
      </Dialog>
    </DarkThemeProvider>
  )
}

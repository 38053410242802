import {Tags} from 'Event/attendee'
import {
  DOES_NOT_INCLUDE,
  TagsRule,
} from 'Event/attendee-rules/RuleConfig/RuleList/SingleRule/TagsRule'

export function matchesTagsRule(tags: Tags, rule: TagsRule) {
  const tagRule = rule.target.toLowerCase()
  const match = (tag: string) => tag.toLowerCase() === tagRule

  if (rule.type === DOES_NOT_INCLUDE) {
    return !tags.some(match)
  }

  return tags.some(match)
}

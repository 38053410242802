import {useOrganization} from 'organization/OrganizationProvider'
import {useEvent} from 'Event/EventProvider'
import {useAsync} from 'lib/async'
import {api} from 'lib/url'
import React, {useCallback} from 'react'

export type HubspotList = {
  listId: number
  name: string
}

interface HubspotListsProviderContextProps {
  lists: HubspotList[]
}

const HubspotListsProviderContext = React.createContext<
  undefined | HubspotListsProviderContextProps
>(undefined)

export default function HubspotListsProvider(props: {
  children: React.ReactElement
}) {
  const {event} = useEvent()
  const {data, loading} = useFetchLists(event.has_hubspot)

  const lists = data || []

  if (loading) {
    return null
  }

  return (
    <HubspotListsProviderContext.Provider
      value={{
        lists,
      }}
    >
      {props.children}
    </HubspotListsProviderContext.Provider>
  )
}

export function useHubspotList() {
  const context = React.useContext(HubspotListsProviderContext)

  if (context === undefined) {
    throw new Error('useHubspotList must be used within a HubspotListsProvider')
  }

  return context
}

function useFetchLists(shouldFetch: boolean) {
  const {client} = useOrganization()
  const {
    event: {id: eventId},
  } = useEvent()
  const url = api(`/events/${eventId}/integrations/hubspot/lists`)
  const request = useCallback(
    () => (shouldFetch ? client.get<HubspotList[]>(url) : Promise.resolve([])),
    [url, client, shouldFetch],
  )

  return useAsync(request)
}

import {ObvioEvent} from 'Event'
import {MailchimpField} from 'lib/event-api/integrations/mailchimp/fields/list'
import {Client} from 'lib/ui/api-client'
import {api} from 'lib/url'
import {useMutation, useQueryClient} from 'react-query'

export function useAddMailchimpField(params: {
  client: Client
  event: ObvioEvent
}) {
  const {client, event} = params

  const qc = useQueryClient()

  return useMutation({
    mutationFn: (data: {name: string}) => {
      const url = api(`/events/${event.id}/integrations/mailchimp/fields`)
      return client.post<MailchimpField>(url, data)
    },
    onSuccess: (field) => {
      qc.setQueryData<MailchimpField[]>(
        ['events', event.id, 'integrations', 'mailchimp', 'fields'],
        (fields) => {
          if (!fields) {
            return [field]
          }

          return [...fields, field]
        },
      )
    },
    onError: () => {
      // Re-fetch to get latest fields on error
      qc.invalidateQueries({
        queryKey: ['events', event.id, 'integrations', 'mailchimp', 'fields'],
        exact: true,
      })
    },
  })
}

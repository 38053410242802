import React, {useState} from 'react'
import styled from 'styled-components'
import Button from 'lib/ui/Button'
import {Attendee} from 'Event/attendee'
import {api} from 'lib/url'
import {getRetryJoinUrlBackoffMs, useEvent} from 'Event/EventProvider'
import {useRoomControls} from 'Event/Dashboard/TeamAccessControls/RoomControls'

export default function JoinAsAttendee() {
  const {room} = useRoomControls()
  const [submitting, setSubmitting] = useState(false)
  const {client} = useEvent()
  const [attempt, setAttempt] = useState(1)

  const handleJoin = () => {
    setSubmitting(true)

    return client
      .get<{url: string; attendee: Attendee} | null>(
        api(`/rooms/${room.id}/join`),
      )
      .then((resData) => {
        if (!resData) {
          const backOffSecs = getRetryJoinUrlBackoffMs(attempt)

          setAttempt((attempt) => attempt + 1)
          /**
           * Join URL is NOT ready yet so we'll re-submit
           */
          setTimeout(handleJoin, backOffSecs)
          return
        }

        window.open(resData.url, '_blank')?.focus()
      })
      .catch(() => {
        setSubmitting(false)
      })
  }

  return (
    <div>
      <JoinAsAttendeeButton
        variant="contained"
        color="warning"
        aria-label="join as attendee"
        fullWidth={true}
        disabled={submitting}
        onClick={handleJoin}
      >
        Join as attendee
      </JoinAsAttendeeButton>
    </div>
  )
}

const JoinAsAttendeeButton = styled(Button)`
  margin-top: ${(props) => props.theme.spacing[1]};
`

import React from 'react'
import {useToggle} from 'lib/toggle'
import {useAttendeeVariables} from 'Event'
import {useLeftyTemplate} from 'Event/template/Lefty'
import {FeaturePageTitle} from 'Event/template/Lefty/Page'
import Content from 'lib/ui/form/TextEditor/Content'
import {orderedIdsByPosition} from 'lib/list'
import styled from 'styled-components'
import Slider, {Settings} from 'react-slick'
import SpeakerModal from 'Event/template/Lefty/Speakers/SpeakerModal'

export default function SpeakersHeader() {
  const {speakers: speakerPageSettings} = useLeftyTemplate()
  const ids = orderedIdsByPosition(speakerPageSettings.items)
  const hasSpeakers = ids.length > 0

  return (
    <HeaderContainer hasSpeakers={hasSpeakers}>
      <SpeakersImageList />
      <SpeakerTexts
        hasSpeakers={hasSpeakers}
        title={speakerPageSettings.secondaryTitle}
        description={speakerPageSettings.description}
      />
    </HeaderContainer>
  )
}

function SpeakerTexts(props: {
  hasSpeakers?: boolean
  title: string
  description: string
}) {
  const v = useAttendeeVariables()
  const {title, description, hasSpeakers} = props

  return (
    <TextContainer hasSpeakers={hasSpeakers}>
      <FeaturePageTitle>{v(title)}</FeaturePageTitle>
      <PageDescription description={description}>
        <Content>{v(description)}</Content>
      </PageDescription>
    </TextContainer>
  )
}

function SpeakersImageList() {
  const template = useLeftyTemplate()
  const speakerItems = template.speakers.items
  const ids = orderedIdsByPosition(speakerItems)
  const speakers = ids.filter(
    (id) => speakerItems[id].image && speakerItems[id].isActive,
  )

  const settings: Settings = {
    draggable: false,
    arrows: false,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    cssEase: 'linear',
  }

  if (speakers.length === 0) {
    return null
  }

  return (
    <ImageListContainer>
      <Slider {...settings}>
        {speakers.map((speaker, index) => (
          <SpeakerItem id={speaker} key={index} />
        ))}
      </Slider>
    </ImageListContainer>
  )
}

function SpeakerItem(props: {id: string}) {
  const {flag: showing, toggle} = useToggle()
  const template = useLeftyTemplate()
  const speakerItems = template.speakers.items
  const item = speakerItems[props.id]

  if (!item.image) {
    return null
  }

  return (
    <div>
      <SpeakerModal showing={showing} speaker={item} onClose={toggle} />
      <SpeakerImage src={item.image} onClick={() => toggle()} />
    </div>
  )
}

function PageDescription(props: {description: string; children: JSX.Element}) {
  const {description, children} = props

  if (!description) {
    return null
  }

  return <PageDescriptionContainer>{children}</PageDescriptionContainer>
}

export const PageDescriptionContainer = styled.div`
  margin: 16px 0;

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    width: 100%;
  }
`

const SpeakerImage = styled.img`
  width: 100%;
  object-fit: cover;
  height: 20rem;
  cursor: pointer;
`

const HeaderContainer = styled.div<{
  hasSpeakers?: boolean
}>`
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-top: ${(props) => (props.hasSpeakers ? props.theme.spacing[4] : 0)};
  margin-bottom: ${(props) => props.theme.spacing[16]};
  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    flex-direction: column;
  }
`

const ImageListContainer = styled.div`
  width: 50%;
  padding-right: ${(props) => props.theme.spacing[20]};
  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    width: 100%;
    padding-right: 0;
    margin-bottom: ${(props) => props.theme.spacing[16]};
  }
`

const TextContainer = styled.div<{hasSpeakers?: boolean}>`
  width: ${(props) => (props.hasSpeakers ? 50 : 100)}%;
  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    width: 100%;
  }
`

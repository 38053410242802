import {useAttendeeVariables} from 'Event'
import PasswordFieldConfig from 'Event/Step1/SetPasswordForm/PasswordField/PasswordFieldConfig'
import {useTemplate} from 'Event/TemplateProvider'
import TextField from 'Event/auth/TextField'
import Configurable from 'organization/Event/Configurable'
import React from 'react'
import {UseFormMethods} from 'react-hook-form'

export default function PasswordField(
  props: Pick<UseFormMethods, 'register'> & {
    error?: string
    disabled?: boolean
  },
) {
  const {error, disabled, register} = props
  const v = useAttendeeVariables()
  const {setPasswordForm} = useTemplate()

  return (
    <Configurable>
      <PasswordFieldConfig />
      <TextField
        label={v(setPasswordForm.passwordLabel)}
        type="password"
        fullWidth
        name="password"
        variant="outlined"
        inputProps={{
          ref: register({
            required: 'Password is required',
            minLength: {
              value: 8,
              message: 'Password must be at least 8 characters',
            },
          }),
          'aria-label': 'password input',
        }}
        error={Boolean(error)}
        helperText={error}
        disabled={disabled}
        {...setPasswordForm.input}
      />
    </Configurable>
  )
}

import Button from 'lib/ui/Button'
import React from 'react'
import {useTownhallUpdate} from 'Event/template/Townhall'
import {Resource} from 'Event/template/Townhall/Dashboard/Main/ResourceSection/ResourceItem'
import {uuid} from 'lib/uuid'

export default function AddResourceButton(props: {
  className?: string
  sectionId: string
}) {
  const {sectionId} = props

  const updateTemplate = useTownhallUpdate()

  const addResource = () => {
    const id = uuid()

    const newResource: Resource = {
      name: 'Resource',
      filePath: '',
      actionId: null,
      icon: null,
      isVisible: true,
      rules: [],
      backgroundColor: '#000000',
      font: null,
      fontSize: 16,
      textColor: '#FFFFFF',
      hoverTextColor: '#FFFFFF',
      hoverBackgroundColor: '#000000',
      disableHover: false,
      height: 34,
      size: 4,
      newLine: false,
      borderRadius: 0,
      borderWidth: 0,
      borderColor: '#FFFFFF',
      hoverBorderColor: '#FFFFFF',
    }

    updateTemplate({
      dashboardSections: {
        [sectionId]: {
          items: {
            [id]: newResource,
          },
        },
      },
    })
  }

  return (
    <Button
      size="large"
      variant="outlined"
      color="secondary"
      fullWidth
      aria-label="add resource"
      onClick={addResource}
      className={props.className}
    >
      Add Resource
    </Button>
  )
}

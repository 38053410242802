import React from 'react'
import Button from '@material-ui/core/Button'
import MenuItem from '@material-ui/core/MenuItem'
import {
  ALL,
  PENDING,
  APPROVED,
  REJECTED,
  ImageEntry,
} from 'organization/Event/ImageEntriesProvider'
import {api} from 'lib/url'
import {useEvent} from 'Event/EventProvider'
import {useOrganization} from 'organization/OrganizationProvider'
import {useToggle} from 'lib/toggle'
import Menu from 'lib/ui/Menu'

type StatusFilter = ImageEntry['status'] | typeof ALL

export default function ExportButton(props: {
  onSuccess: (message: string | null) => void
  onError: (message: string | null) => void
}) {
  const {onSuccess: setSuccessMessage, onError: setErrorMessage} = props

  const {event} = useEvent()
  const {client} = useOrganization()
  const {flag: processing, toggle: toggleProcessing} = useToggle()

  const exportWithStatus = (status: StatusFilter) => () => {
    setSuccessMessage(null)
    setErrorMessage(null)

    if (processing) {
      return
    }

    toggleProcessing()

    const url = api(`/events/${event.id}/image_entries/export?status=${status}`)

    client
      .get<{message: string}>(url)
      .then((res) => {
        setSuccessMessage(res.message)
      })
      .catch((e) => {
        setErrorMessage(e.message)
      })
      .finally(toggleProcessing)
  }

  return (
    <>
      <div>
        <Menu
          button={({open}) => (
            <Button
              aria-controls="export-menu"
              aria-haspopup="true"
              variant="contained"
              color="primary"
              disabled={processing}
              onClick={open}
            >
              Export
            </Button>
          )}
        >
          <MenuItem onClick={exportWithStatus(ALL)}>All</MenuItem>
          <MenuItem onClick={exportWithStatus(PENDING)}>Pending</MenuItem>
          <MenuItem onClick={exportWithStatus(APPROVED)}>Approved</MenuItem>
          <MenuItem onClick={exportWithStatus(REJECTED)}>Rejected</MenuItem>
        </Menu>
      </div>
    </>
  )
}

import React, {useState} from 'react'
import styled from 'styled-components'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import {useBreadcrumbs} from 'lib/ui/BreadcrumbProvider'
import Button from 'lib/ui/Button'
import Switch from 'lib/ui/form/Switch'
import Divider from 'lib/ui/layout/Divider'
import Page from 'lib/ui/layout/Page'
import Subheading from 'lib/ui/typography/Subheading'
import Title from 'lib/ui/typography/Title'
import PlanCard from 'obvio/Billing/PlanCard'
import {
  useAvailablePlans,
  INTERVAL_MONTH,
  INTERVAL_YEAR,
  BillingInterval,
} from 'obvio/Billing/plans'
import {obvioRoutes} from 'obvio/Routes'
import {useGetSubscription} from 'obvio/Billing/subscribe'
import Layout from 'obvio/user/Layout'
import TransactionSection from 'obvio/Billing/TransactionSection'
import CreditCardSection from 'obvio/Billing/CreditCardSection'

export default function Billing() {
  const subscription = useGetSubscription()

  useBreadcrumbs([
    {
      title: 'Billing',
      url: obvioRoutes.billing.root,
    },
  ])

  const plans = useAvailablePlans()

  const [billingInterval, setBillingInterval] = useState<BillingInterval>(
    subscription?.plan.interval || INTERVAL_YEAR,
  )

  const toggleBillingInterval = (interval?: BillingInterval) => {
    // If we've got a value for interval, we're not so much a toggle, but a
    // setter. Set the value and get out.
    if (interval) {
      setBillingInterval(interval)

      return
    }

    setBillingInterval(
      billingInterval === INTERVAL_YEAR ? INTERVAL_MONTH : INTERVAL_YEAR,
    )
  }
  const isYearly = billingInterval === INTERVAL_YEAR

  return (
    <Layout>
      <Page>
        <Title>Billing & Subscription</Title>
        <Divider />
        <CreditCardSection />
        <TransactionSection />
        <Divider />
        <Subheading>Plans & Pricing</Subheading>
        <Box mb={4}>
          <Typography>
            Pick an account plan that fits your virtual event needs.
          </Typography>
        </Box>

        <IntervalToggle
          isYearly={isYearly}
          toggleBillingInterval={toggleBillingInterval}
        />

        <Box mb={4}>
          <Grid container spacing={2}>
            {plans.map((plan) => (
              <Grid xs={12} lg key={plan.name} item>
                <PlanCard billingInterval={billingInterval} plan={plan} />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Page>
    </Layout>
  )
}

function IntervalToggle(props: {
  isYearly: boolean
  toggleBillingInterval: (interval?: BillingInterval) => void
}) {
  const {isYearly, toggleBillingInterval} = props

  const clickMonthly = () => toggleBillingInterval(INTERVAL_MONTH)
  const clickYearly = () => toggleBillingInterval(INTERVAL_YEAR)
  const clickToggle = () => toggleBillingInterval()

  return (
    <IntervalBox aria-label="billing intervals">
      <Button aria-label="billing interval monthly" onClick={clickMonthly}>
        Billed Monthly
      </Button>
      <StyledSwitch
        aria-label="billing interval toggle"
        checked={isYearly}
        onChange={clickToggle}
        labelPlacement="top"
      />
      <Button aria-label="billing interval yearly" onClick={clickYearly}>
        Billed Annually
      </Button>
    </IntervalBox>
  )
}

const IntervalBox = styled.div`
  align-items: center;
  border: 1px solid ${(props) => props.theme.colors.border};
  border-radius: 15px;
  display: flex;
  margin: 0 auto ${(props) => props.theme.spacing[10]};
  padding: ${(props) => `${props.theme.spacing[6]} ${props.theme.spacing[10]}`};

  & > div {
    text-align: center;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.sm}) {
    max-width: 450px;

    & > div:first-child {
      text-align: right;
    }
    & > div:last-child {
      text-align: left;
    }
  }
`

const StyledSwitch = styled(Switch)`
  margin-bottom: 0;
  padding-left: 0;
`

export const Section = styled.div`
  margin-bottom: ${(props) => props.theme.spacing[16]};
`

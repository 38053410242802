import React, {useState} from 'react'
import {useHistory} from 'react-router-dom'
import {useEventRoutes} from 'organization/Event/EventRoutes'
import {useForm} from 'react-hook-form'
import Page from 'organization/Event/Page'
import Form from 'organization/Marketplace/Tickets/TicketForm/Form'
import {ValidationError} from 'lib/ui/api-client'
import {GroupPair} from 'lib/ui/form/DescribedGroupsInput'
import {TicketsBreadcrumbs} from 'organization/Event/Page/PageBreadcrumbs'
import {
  CreateTicketData,
  useCreateTicket,
} from 'lib/marketplace-api/tickets/use-create-ticket'
import {useMarketplaceAccount} from 'lib/marketplace-api/accounts/use-marketplace-account'

export default function CreateTicketForm() {
  const history = useHistory()
  const eventRoutes = useEventRoutes()
  const {mutate: createTicket, isLoading: isCreatingTicket} = useCreateTicket()
  const {errors: formErrors, handleSubmit, control, setValue} = useForm()
  const {data: account} = useMarketplaceAccount()
  const [responseError, setResponseError] = useState<
    ValidationError<CreateTicketData>
  >(null)

  const submit = (data: CreateTicketData) => {
    if (!account) {
      return
    }

    const groupsAsStrings = data.groups.map((group) => {
      // Need to convert to unkown first because there is no overlap, we've
      // actually got a GroupPair[] in data.
      const temp = (group as unknown) as GroupPair

      // Backend wants the group attribute to be an array of strings, group:value
      // in format.
      return `${temp.key}:${temp.value}`
    })

    // Put in our massaged string[] back into the data that is being submitted.
    data.groups = groupsAsStrings

    createTicket(
      {...data, account_id: account.id},
      {
        onError: (error: any) => {
          setResponseError(error)
        },
        onSuccess: () => {
          history.push(eventRoutes.marketplace.tickets.root)
        },
      },
    )
  }

  return (
    <TicketsBreadcrumbs page="Add">
      <Page>
        <Form
          control={control}
          formErrors={formErrors}
          onSubmit={handleSubmit(submit)}
          responseError={responseError}
          setValue={setValue}
          submitting={isCreatingTicket}
        />
      </Page>
    </TicketsBreadcrumbs>
  )
}

import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import {useAttendeeVariables} from 'Event'
import {useEntries} from 'Event/Leaderboard'
import {useCardsTemplate} from 'Event/template/Cards'
import {PageTitle} from 'Event/template/Cards/Page'
import Content from 'lib/ui/form/TextEditor/Content'
import React from 'react'
import {User} from 'auth/user'
import CardsPage from 'Event/template/Cards/Page'
import styled from 'styled-components'

export default function Leaderboard(props: {user: User}) {
  const v = useAttendeeVariables()
  const {leaderboard, textColor, linkColor, linkUnderline} = useCardsTemplate()
  const {entries} = useEntries()

  return (
    <CardsPage user={props.user}>
      <PageTitle>{v(leaderboard.title)}</PageTitle>
      <StyledContent linkColor={linkColor} linkUnderline={linkUnderline}>
        {v(leaderboard.description)}
      </StyledContent>
      <Table>
        <TableHead>
          <TableRow>
            <StyledTableCell color={textColor}>#</StyledTableCell>
            <StyledTableCell align="left" color={textColor}>
              Name
            </StyledTableCell>
            <StyledTableCell align="right" size="small" color={textColor}>
              Points
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {entries.map((entry, index) => (
            <TableRow key={index} aria-label="entry">
              <StyledTableCell component="th" scope="row" color={textColor}>
                {index + 1}
              </StyledTableCell>
              <StyledTableCell align="left" color={textColor}>
                {entry.attendee.first_name} {entry.attendee.last_name}
              </StyledTableCell>
              <StyledTableCell align="right" color={textColor}>
                {entry.score}
              </StyledTableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </CardsPage>
  )
}

const StyledTableCell = styled(TableCell)<{color: string}>`
  color: ${(props) => props.color};
`

const StyledContent = styled(Content)<{
  linkColor: string
  linkUnderline: boolean
}>`
  a {
    color: ${(props) => props.linkColor};
    text-decoration: none;

    &:hover {
      text-decoration: ${(props) =>
        props.linkUnderline ? 'underline' : 'none'};
    }
  }
`

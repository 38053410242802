import {useCardsTemplate} from 'Event/template/Cards'
import styled from 'styled-components'
import React from 'react'
import {useGuestVariables} from 'Event'
import Configurable from 'organization/Event/Configurable'
import AdditionalDescriptionConfig from 'Event/template/Cards/Login/AdditionalDescription/AdditionalDescriptionConfig'

export default function AdditionalDescription() {
  const {
    login: {
      additionalDescription: {color, text, fontSize},
    },
    textColor,
  } = useCardsTemplate()
  const v = useGuestVariables()

  return (
    <Configurable>
      <AdditionalDescriptionConfig />
      <StyledDescription
        color={textColor}
        additionalDescriptionColor={color}
        fontSize={fontSize}
      >
        {v(text)}
      </StyledDescription>
    </Configurable>
  )
}

const StyledDescription = styled.div<{
  color: string
  additionalDescriptionColor: string | null
  fontSize: number
}>`
  color: ${(props) =>
    props.additionalDescriptionColor
      ? props.additionalDescriptionColor
      : props.color} !important;
  font-size: ${(props) => props.fontSize}px;
  margin-top: ${(props) => props.theme.spacing[4]};
  font-weight: 500;
  margin-bottom: ${(props) => props.theme.spacing[8]};
  text-align: left;
`

import {AlertLevelProps, AlertProps} from 'lib/ui/alerts/Alert'
import ErrorAlert from 'lib/ui/alerts/ErrorAlert'
import InfoAlert from 'lib/ui/alerts/InfoAlert'
import SuccessAlert from 'lib/ui/alerts/SuccessAlert'
import React, {useCallback, useState} from 'react'

type Alert = {
  message: string
  severity: AlertProps['severity']
}

type AlertContext = {
  alert: Alert | null
  setAlert: (alert: Alert | null) => void
  clear: () => void
}

/**
 * A convenient Alert component that determines its value via a context.
 *
 * @param props
 */
export default function ContextAlert(
  props: {context: AlertContext; canClose?: boolean} & Omit<
    AlertLevelProps,
    'onClose' | 'children'
  >,
) {
  const {context, canClose, ...forwardAlertProps} = props

  const onClose = canClose ? context.clear : undefined

  const alertProps = {
    ...forwardAlertProps,
    onClose,
  }

  if (!context.alert) {
    return null
  }

  if (context.alert.severity === 'success') {
    return <SuccessAlert {...alertProps}>{context.alert.message}</SuccessAlert>
  }

  if (context.alert.severity === 'error') {
    return <ErrorAlert {...alertProps}>{context.alert.message}</ErrorAlert>
  }

  if (context.alert.severity === 'info') {
    return <InfoAlert {...alertProps}>{context.alert.message}</InfoAlert>
  }

  throw new Error(`Unhandled error severity: ${context.alert.severity}`)
}

export function createAlertContext() {
  const Context = React.createContext<AlertContext | undefined>(undefined)

  const useAlert = () => {
    const context = React.useContext(Context)
    if (context === undefined) {
      throw new Error('useAlert called for the wrong AlertContext')
    }

    return context
  }

  return {Provider: Context.Provider, useAlert}
}

export function useAlertContext() {
  const [alert, setAlert] = useState<{
    message: string
    severity: AlertProps['severity']
  } | null>(null)

  const clear = useCallback(() => {
    setAlert(null)
  }, [])

  return {
    alert,
    setAlert,
    clear,
  }
}

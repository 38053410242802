import React, {useState} from 'react'
import {User} from 'auth/user'
import NavBar from 'Event/template/Townhall/Dashboard/NavBar'
import {ThemeProvider} from '@material-ui/core/styles'
import {muiTheme} from 'lib/ui/theme'
import Header from 'Event/template/Townhall/Dashboard/Header'
import Main from 'Event/template/Townhall/Dashboard/Main'
import BodyHtmlEmbed from 'Event/Dashboard/components/BodyHtmlEmbed'

export default function Dashboard(props: {user: User}) {
  const [sectionId, setSectionId] = useState('header')

  return (
    <ThemeProvider theme={muiTheme}>
      <Header aria-label="header" />
      <NavBar sectionId={sectionId} setSectionId={setSectionId} />
      <Main {...props} />
      <BodyHtmlEmbed />
    </ThemeProvider>
  )
}

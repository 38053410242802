import React, {useState} from 'react'
import TableBody from '@material-ui/core/TableBody'
import Table from '@material-ui/core/Table'
import TableContainer from '@material-ui/core/TableContainer'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import {RedirectLink} from 'Event/Redirects/CustomRedirect'
import {api} from 'lib/url'
import {useOrganization} from 'organization/OrganizationProvider'
import ConfirmDialog from 'lib/ui/ConfirmDialog'
import CopyIconButton from 'lib/ui/IconButton/CopyIconButton'
import styled from 'styled-components'
import {useEvent} from 'Event/EventProvider'
import {eventRoutes} from 'Event/Routes'
import IconButton from 'lib/ui/IconButton'
import Icon from 'lib/ui/Icon'
import MenuItem from 'lib/ui/Menu/MenuItem'
import DeleteIcon from 'assets/images/ui/icons/trash.svg'
import Divider from '@material-ui/core/Divider'
import UpdateIcon from 'assets/images/ui/icons/edit.svg'
import Menu from 'lib/ui/Menu'

export default function RedirectTable(props: {
  redirects: RedirectLink[]
  onSelect: (redirect: RedirectLink) => void
  onRemove: (redirect: RedirectLink) => void
}) {
  const [submitting, setSubmitting] = useState(false)
  const {client} = useOrganization()
  const {url} = useEvent()

  const removeRedirect = (redirect: RedirectLink) => {
    setSubmitting(true)
    const url = api(`/redirects/${redirect.id}`)

    client
      .delete(url)
      .then(() => {
        props.onRemove(redirect)
      })
      .finally(() => {
        setSubmitting(false)
      })
  }

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Description</TableCell>
            <TableCell align="center">Slash Tag</TableCell>
            <TableCell align="center">Destination</TableCell>
            <TableCell align="center">{/* Actions */}</TableCell>
            <TableCell align="center">{/* Copy */}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.redirects.map((redirect) => (
            <TableRow key={redirect.description} aria-label="redirect">
              <TableCell>{redirect.description}</TableCell>
              <TableCell align="center">{redirect.redirect_slug}</TableCell>
              <TableCell align="center">{redirect.destination}</TableCell>

              <TableCell>
                <Menu
                  button={({open}) => (
                    <IconButton
                      onClick={open}
                      aria-label="view redirect actions"
                    >
                      <Icon
                        className="fas fa-ellipsis-h"
                        color="black"
                        iconSize={18}
                      />
                    </IconButton>
                  )}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                >
                  <div>
                    <MenuItem
                      icon={UpdateIcon}
                      children="Update"
                      disabled={submitting}
                      onClick={() => props.onSelect(redirect)}
                      aria-label="update redirect"
                    />
                    <Divider />
                    <ConfirmDialog
                      onConfirm={() => {
                        removeRedirect(redirect)
                      }}
                      title="Remove redirect"
                      description={<div>Are you sure?</div>}
                      confirmLabel="Yes"
                    >
                      {(confirm) => (
                        <MenuItem
                          icon={DeleteIcon}
                          children="Delete"
                          disabled={submitting}
                          onClick={confirm}
                          aria-label="remove redirect"
                        />
                      )}
                    </ConfirmDialog>
                  </div>
                </Menu>
              </TableCell>
              <CopyCell align="right">
                <CopyIconButton
                  aria-label="copy redirect URL"
                  onClick={() => copyRedirectURL(redirect.redirect_slug, url)}
                />
              </CopyCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export function copyRedirectURL(redirectSlug: string, eventURL: string) {
  const redirectRoute = eventRoutes.r.root
  const redirectURL = eventURL + redirectRoute + '/' + redirectSlug
  navigator.clipboard.writeText(redirectURL)
}

const CopyCell = styled(TableCell)`
  text-align: right;
  width: 50px;
`

import Grid from '@material-ui/core/Grid'
import {formatPrice} from 'lib/currency'
import {useToggleArray} from 'lib/toggle'
import {useBreadcrumbs} from 'lib/ui/BreadcrumbProvider'
import Button from 'lib/ui/Button'
import SuccessDialog from 'lib/ui/Dialog/SuccessDialog'
import Select from 'lib/ui/Select'
import Option from 'lib/ui/Select/Option'
import {NumberField} from 'lib/ui/TextField'
import Divider from 'lib/ui/layout/Divider'
import Page from 'lib/ui/layout/Page'
import {RelativeLink} from 'lib/ui/link/RelativeLink'
import {SubHead} from 'lib/ui/typography'
import Subheading from 'lib/ui/typography/Subheading'
import Title from 'lib/ui/typography/Title'
import {useQueryParams} from 'lib/url'
import {
  ADDON_KEY_ZOOM_MEETINGS,
  PlanAddOnDetails,
  PlanInfo,
  isAddOn,
} from 'obvio/Billing/plans'
import {Section} from 'organization/AddOns'
import AddOnBlockSlider, {
  MAX_NUM_BLOCKS,
  MIN_NUM_BLOCKS,
} from 'organization/AddOns/PurchaseAddOnPage/AddOnBlockSlider'
import ConfirmDialog from 'organization/AddOns/PurchaseAddOnPage/ConfirmDialog'
import {useOrganization} from 'organization/OrganizationProvider'
import {useOwner} from 'organization/OwnerProvider'
import Layout from 'organization/user/Layout'
import React, {useState} from 'react'
import {Redirect, useHistory} from 'react-router'
import styled from 'styled-components'

export default function PurchaseAddOnPage() {
  const {plan} = useOwner()
  const {routes} = useOrganization()
  const history = useHistory()
  const [quantity, setQuantity] = useState(MIN_NUM_BLOCKS)
  const [showingConfirmDialog, toggleConfirmDialog] = useToggleArray()
  const {addon: addOnKey, return: returnLocation} = useQueryParams()
  const [duration, setDuration] = useState(1)
  const [purchased, setPurchased] = useState(false)

  useBreadcrumbs(
    [{title: 'Billing', url: routes.add_ons.root}, {title: 'Purchase Add-on'}],
    [],
  )

  const goBackToBilling = () => {
    history.push(routes.add_ons.root)
  }

  if (!plan || !addOnKey || !isAddOn(addOnKey)) {
    return <Redirect to={routes.events.root} />
  }

  const blockLabel =
    addOnKey === ADDON_KEY_ZOOM_MEETINGS ? 'Meeting(s)' : 'Block(s)'

  const successMessage =
    quantity > 1
      ? 'Add-ons Successfully Purchased!'
      : 'Add-on Successfully Purchased!'

  const addOnDetail = plan.addOns[addOnKey]

  const price = quantity * addOnDetail.cost * duration

  const handleSuccess = () => {
    toggleConfirmDialog()

    if (returnLocation) {
      history.push(returnLocation)
    } else {
      setPurchased(true)
    }
  }

  return (
    <>
      <SuccessDialog showing={purchased} onClose={goBackToBilling}>
        {successMessage}
      </SuccessDialog>
      <ConfirmDialog
        showing={showingConfirmDialog}
        onClose={toggleConfirmDialog}
        blockLabel={blockLabel}
        duration={duration}
        quantity={quantity}
        addOnDetail={addOnDetail}
        addOnKey={addOnKey}
        onSuccess={handleSuccess}
        price={price}
      />
      <Layout>
        <Page>
          <Title>Purchase Add-on</Title>
          <Divider />
          <Section>
            <AddOnTitle addOnDetail={addOnDetail} />
            <SubSection>{addOnDetail.details.description}</SubSection>

            <Grid container alignItems="center" spacing={2}>
              <Grid xs={6} sm={1} item>
                <SubHead>{blockLabel}</SubHead>
                <NumberField
                  fullWidth
                  type="number"
                  aria-label="add-on blocks"
                  inputProps={{
                    min: MIN_NUM_BLOCKS,
                    max: MAX_NUM_BLOCKS,
                  }}
                  value={quantity}
                  onChange={setQuantity}
                  variant="outlined"
                />
              </Grid>
              <DurationContainer
                addOnDetail={addOnDetail}
                duration={duration}
                setDuration={setDuration}
              />
              <SliderContainer
                addOnDetail={addOnDetail}
                addOnKey={addOnKey}
                plan={plan}
                quantity={quantity}
                setQuantity={setQuantity}
              />
            </Grid>
          </Section>
          <Section>
            <Subheading>Your account will be charged</Subheading>
            <p aria-label="charge amount">${formatPrice(price)}</p>
          </Section>
          <Divider />
          <Actions>
            <RelativeLink to={routes.add_ons.root} disableStyles>
              <Button color="primary">Cancel</Button>
            </RelativeLink>
            <Button
              color="primary"
              variant="contained"
              onClick={toggleConfirmDialog}
              aria-label="purchase add-on"
            >
              Purchase Add-on
            </Button>
          </Actions>
        </Page>
      </Layout>
    </>
  )
}

function AddOnTitle(props: {addOnDetail: PlanAddOnDetails}) {
  const {addOnDetail} = props

  const title = `${addOnDetail.details.displayName} - $${formatPrice(
    addOnDetail.cost,
  )}`

  return <Subheading>{title}</Subheading>
}

function DurationContainer(props: {
  addOnDetail: PlanAddOnDetails
  duration?: number
  setDuration: React.Dispatch<React.SetStateAction<number>>
}) {
  const {addOnDetail, duration, setDuration} = props

  if (!addOnDetail.duration) {
    return null
  }

  return (
    <Grid xs={6} sm={2} item>
      <SubHead>Duration</SubHead>
      <StyledSelect
        aria-label="add-on duration"
        defaultValue={1}
        value={duration}
        fullWidth
        onChange={(event) => setDuration(event.target.value)}
        variant="outlined"
      >
        {Array.from({length: 3}, (_, i) => i + 1).map((day) => {
          const humanDuration = day * Number(addOnDetail.duration)

          return (
            <Option key={day} value={day}>{`${humanDuration} Days`}</Option>
          )
        })}
      </StyledSelect>
    </Grid>
  )
}

function SliderContainer(props: {
  addOnDetail: PlanAddOnDetails
  addOnKey: string
  plan: PlanInfo
  quantity: number
  setQuantity: React.Dispatch<React.SetStateAction<number>>
}) {
  const {addOnDetail, addOnKey, plan, quantity, setQuantity} = props

  return (
    <StyledGrid xs={12} sm={addOnDetail.duration ? 9 : 11} item>
      <SliderBox>
        <AddOnBlockSlider
          addOnKey={addOnKey}
          value={quantity}
          onChange={setQuantity}
          plan={plan}
        />
      </SliderBox>
    </StyledGrid>
  )
}

const SubSection = styled.div`
  margin-bottom: ${(props) => props.theme.spacing[8]};
`

const StyledSelect = styled(Select)`
  margin-bottom: ${(props) => props.theme.spacing[4]} !important;
`

const StyledGrid = styled(Grid)`
  padding-left: ${(props) => props.theme.spacing[10]} !important;
`

const SliderBox = styled.div`
  display: flex;
  justify-content: flex-end;
`

const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  & > * {
    margin-right: ${(props) => props.theme.spacing[4]};

    &:last-child {
      margin-right: 0;
    }
  }
`

import React from 'react'
import Settings from 'Event/template/Townhall/Dashboard/Main/BlogSection/BlogSectionConfig/Settings'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import Config from 'organization/Event/Configurable/Config'
import {REMOVE, useSaveTemplate} from 'Event/TemplateUpdateProvider'
import {BlogSectionProps} from 'Event/template/Townhall/Dashboard/Main/BlogSection'
import StylingPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/StylingPanel'
import Styling from 'Event/template/Townhall/Dashboard/Main/BlogSection/BlogSectionConfig/Styling'

export function BlogSectionConfig(props: {
  blogSection: BlogSectionProps
  sectionId: string
}) {
  const {blogSection, sectionId} = props

  const save = useSaveTemplate()

  const remove = () =>
    save({
      dashboardSections: {
        [sectionId]: REMOVE,
      },
      navBar: {
        menuItems: {
          [sectionId]: REMOVE,
        },
      },
    })

  return (
    <Config title="Blog" onSave={save} onRemove={remove}>
      <SettingsPanel>
        <Settings blogSection={blogSection} sectionId={sectionId} />
      </SettingsPanel>
      <StylingPanel>
        <Styling blogSection={blogSection} sectionId={sectionId} />
      </StylingPanel>
    </Config>
  )
}

import {useEffect} from 'react'
import {useLocation, useHistory} from 'react-router-dom'
import {useOrganization} from 'organization/OrganizationProvider'
import {useSetMarketplaceAccountConnected} from 'lib/marketplace-api/accounts/use-set-marketplace-account-connected'
import {useMarketplaceAccount} from 'lib/marketplace-api/accounts/use-marketplace-account'

export default function StripeConnectedCallbackHandler() {
  const {
    routes: {settings: orgSettings},
  } = useOrganization()
  const location = useLocation()
  const history = useHistory()

  const {data: account} = useMarketplaceAccount()
  const {mutate: setConnected, isLoading} = useSetMarketplaceAccountConnected()

  const stripeAccountId = location.pathname.split('/')[5]

  useEffect(() => {
    if (!account) {
      return
    }

    if (isLoading) {
      return
    }

    if (account.completed_setup) {
      return
    }

    setConnected(
      {account, stripeAccountId},
      {
        onSuccess: () => {
          history.push(orgSettings)
        },
      },
    )
  }, [account, stripeAccountId, isLoading, setConnected, history, orgSettings])

  return null
}

import React, {useEffect, useState} from 'react'
import {
  ATTENDEE_CHECKED_IN,
  ATTENDEE_CREATED,
  ATTENDEE_SIGNED_WAIVER,
  IMPORT_TAG,
  OntraportTag,
  Tag,
  UPDATE_TAG,
} from 'organization/Event/Services/Apps/Ontraport/Config/Tags'
import AutocompleteTags from 'organization/Event/Services/Apps/Ontraport/Config/Tags/AutocompleteTags'

export default function TagSelector(props: {
  tag: Tag
  onChange: (tag: Tag) => void
  error?: string
}) {
  const {tag} = props

  const [ontraportTag, setOntraportTag] = useState<OntraportTag | null>(null)

  useEffect(() => {
    if (!tag.tag_id || !tag.tag_name) {
      setOntraportTag(null)
      return
    }

    setOntraportTag({
      tag_id: tag.tag_id,
      tag_name: tag.tag_name,
    })
  }, [tag])

  const handleTagSelect = (selected: OntraportTag | null) => {
    if (!selected) {
      const empty = {
        ...tag,
        tag_name: null,
        tag_id: null,
      }

      props.onChange(empty)
      return
    }

    const updated = {
      ...tag,
      tag_name: selected.tag_name,
      tag_id: selected.tag_id,
    }

    props.onChange(updated)
  }

  return (
    <AutocompleteTags
      errorText={props.error}
      inputVariant="outlined"
      inputLabel={label(tag)}
      value={ontraportTag}
      onChange={handleTagSelect}
    />
  )
}

function label(tag: Tag) {
  const isSet = Boolean(tag.tag_name)
  return isSet ? `${typeLabel(tag)} - ${tag.tag_name}` : `${typeLabel(tag)}`
}

function typeLabel(tag: Tag) {
  const labels: Record<string, string> = {
    [ATTENDEE_CREATED]: 'Attendee Created',
    [ATTENDEE_SIGNED_WAIVER]: 'Attendee Signed Waiver',
    [ATTENDEE_CHECKED_IN]: 'Attendee Checked In',
    [IMPORT_TAG]: 'Import Tag',
    [UPDATE_TAG]: 'Update Tag',
  }

  const label = labels[tag.type]
  if (!label) {
    throw new Error(`Label not defined for tag type: ${tag.type}`)
  }

  return label
}

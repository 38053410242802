import Box from '@material-ui/core/Box'
import {Tag, TagType} from 'organization/Event/Services/Apps/Mailchimp'
import TagInput from 'organization/Event/Services/Apps/Mailchimp/Config/TagsConfig/TagInput'
import React from 'react'

/**
 * Only show inputs for these tag types
 */
const visibleTagTypes: TagType[] = [
  'attendee_checked_in',
  'attendee_signed_waiver',
]

export default function TagsConfig(props: {
  tags: Tag[]
  onChange: (tags: Tag[]) => void
}) {
  const {onChange, tags} = props

  const updateTags = (tag: Tag) => {
    const updatedTags = tags.map((t) => {
      const isTarget = t.type === tag.type
      if (isTarget) {
        return tag
      }

      return t
    })

    onChange(updatedTags)
  }

  return (
    <Box>
      <Box mb={3}>
        <h3>Action Tags</h3>
      </Box>
      {tags
        .filter((tag) => visibleTagTypes.includes(tag.type))
        .map((tag) => (
          <TagInput tag={tag} key={tag.id} onChange={updateTags} />
        ))}
    </Box>
  )
}

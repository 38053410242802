import {useStripe} from '@stripe/react-stripe-js'
import {
  CreatePaymentMethodData,
  StripeCardNumberElement,
} from '@stripe/stripe-js'
import {usePurchase} from 'Event/Marketplace/PurchaseProvider'
import React, {useState} from 'react'
import {UseFormMethods, useForm} from 'react-hook-form'
import styled from 'styled-components'

export interface PurchaseFormData {
  first_name: string
  last_name: string
  email: string
  phone_number: string
  billing_address: {
    address_1: string
    address_2?: string
    country_id: string
    state_id: string
    city: string
    zip_postal: string
  }
  shipping_address: {
    address_1: string
    address_2?: string
    country_id: string
    state_id: string
    city: string
    zip_postal: string
  }
  payment_method_id: string | null
}

interface PurchaseFormProviderProps {
  children: JSX.Element
}

interface PurchaseFormContextProps {
  form: UseFormMethods<any>
  useSameAddressForShipping: boolean
  setUseSameAddressForShipping: (sameAddress: boolean) => void
  setCardElement: (element: StripeCardNumberElement) => void
}

const PurchaseFormContext = React.createContext<
  PurchaseFormContextProps | undefined
>(undefined)

export default function PurchaseFormProvider(props: PurchaseFormProviderProps) {
  const {children} = props

  const stripe = useStripe()

  const [
    cardElement,
    setCardElement,
  ] = useState<StripeCardNumberElement | null>(null)

  const {selectedTicket, submit} = usePurchase()

  const [useSameAddressForShipping, setUseSameAddressForShipping] = useState(
    true,
  )

  const form = useForm({
    defaultValues: {
      first_name: '',
      billing_address: {
        country_id: 0,
        state_id: 0,
      },
      shipping_address: {
        country_id: 0,
        state_id: 0,
      },
    },
  })

  const handleSubmit = async (data: any) => {
    if (!selectedTicket) {
      return
    }

    // Submit free tickets immediately without registering a card with
    // Stripe
    if (selectedTicket.price === 0) {
      submit({
        payment_method_id: null,
        ...data,
      })

      return
    }

    if (!stripe || !cardElement) {
      return
    }

    const paymentMethodData: CreatePaymentMethodData = {
      type: 'card',
      card: cardElement,
      billing_details: {
        name: `${data.first_name} ${data.last_name}`,
        email: data.email,
        phone: data.phone_number,
        address: data.billing_address?.address_1 ?? '',
      },
    }

    const {paymentMethod} = await stripe.createPaymentMethod(paymentMethodData)

    submit({
      payment_method_id: paymentMethod?.id,
      ...data,
    })
  }

  return (
    <PurchaseFormContext.Provider
      value={{
        form,
        useSameAddressForShipping,
        setUseSameAddressForShipping,
        setCardElement,
      }}
    >
      <FullPageForm onSubmit={form.handleSubmit(handleSubmit)}>
        {children}
      </FullPageForm>
    </PurchaseFormContext.Provider>
  )
}

export function usePurchaseForm() {
  const context = React.useContext(PurchaseFormContext)
  if (context === undefined) {
    throw new Error(
      'usePurchaseForm must be used within a PurchaseFormProvider',
    )
  }

  return context
}

const FullPageForm = styled.form`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

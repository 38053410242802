import React, {useState} from 'react'
import styled from 'styled-components'
import {Description as DescriptionText} from 'lib/ui/typography'
import Label from 'lib/ui/TextField/Label'
import TextField from 'lib/ui/TextField'
import Button from 'lib/ui/Button'
import NewItemButton from 'lib/ui/PageHeader/NewItemButton'

export type DescribedGroupsInputProps = {
  'aria-label'?: string
  name: string
  className?: string
  label?: string
  title?: string
  disabled?: boolean
  description?: string
  fullWidth?: boolean
  value?: GroupPair[]
  onChange: (groups: GroupPair[]) => void
}

export type GroupPair = {
  key: string
  value: string
}

export default function DescribedGroupsInput(props: DescribedGroupsInputProps) {
  const {className, title, description} = props
  const [newGroup, setNewGroup] = useState<string>('')
  const [newValue, setNewValue] = useState<string>('')

  const addNewGroup = () => {
    if (!newGroup || !newValue) {
      return
    }

    const existingGroups = props.value

    existingGroups?.push({key: newGroup, value: newValue})

    props.onChange(existingGroups || [{key: newGroup, value: newValue}])

    setNewGroup('')
    setNewValue('')
  }

  return (
    <>
      <FieldTitle>{title}</FieldTitle>
      <Box fullWidth={props.fullWidth} className={className}>
        <TopDescription>{description}</TopDescription>
        <Contents>
          <GroupInputsBox>
            {props.value?.map((group: GroupPair, key) => {
              return (
                <GroupBox key={key} data-testid="group-box">
                  <GroupTextField
                    variant="outlined"
                    fullWidth
                    defaultValue={group.value}
                    disabled={props.disabled}
                    name={`groups[${group.key}]`}
                    startAdornment={
                      <GroupLabel variant="contained" color="grey">
                        {group.key}
                      </GroupLabel>
                    }
                  />
                </GroupBox>
              )
            })}
          </GroupInputsBox>
          <NewGroupInputBox>
            <NewGroupInputField>
              <TextField
                label="New Group"
                fullWidth
                variant="outlined"
                inputProps={{'aria-label': 'group name'}}
                value={newGroup}
                onChange={setNewGroup}
              />
            </NewGroupInputField>
            <NewGroupInputField>
              <TextField
                label="group value"
                fullWidth
                variant="outlined"
                inputProps={{'aria-label': 'group value'}}
                value={newValue}
                onChange={setNewValue}
              />
            </NewGroupInputField>
          </NewGroupInputBox>
          <NewItemButton
            aria-label="add group"
            onClick={addNewGroup}
            text="Add Group"
          />
        </Contents>
        <RightDescription>{description}</RightDescription>
      </Box>
    </>
  )
}

function TopDescription(props: {children?: string}) {
  if (!props.children) {
    return null
  }
  return (
    <TopDescriptionBox>
      <DescriptionText>{props.children}</DescriptionText>
    </TopDescriptionBox>
  )
}

function RightDescription(props: {children?: string}) {
  if (!props.children) {
    return null
  }

  return (
    <RightDescriptionBox>
      <DescriptionText>{props.children}</DescriptionText>
    </RightDescriptionBox>
  )
}

const Box = styled.div<{fullWidth?: boolean}>`
  ${(props) => (props.fullWidth ? 'width: 100%;' : '')}
  margin-bottom: ${(props) => props.theme.spacing[10]};

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    display: flex;
    align-items: baseline;
    margin-bottom: ${(props) => props.theme.spacing[5]};
  }
`

const TopDescriptionBox = styled.div`
  padding: 0;
  border: none;
  margin-left: 0;
  margin-bottom: ${(props) => props.theme.spacing[4]} !important;

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    display: none;
  }
`

const RightDescriptionBox = styled(TopDescriptionBox)`
  display: none;
  flex: 1 0 40%;
  padding: ${(props) => `${props.theme.spacing[1]} ${props.theme.spacing[7]}`};
  border-left: 1px solid ${(props) => props.theme.colors.primary};
  margin-left: ${(props) => props.theme.spacing[27]};

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    display: inline;
  }
`

const Contents = styled.div`
  max-width: 640px;
  width: 100%;
`

const GroupLabel = styled(Button)`
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  color: black;
  cursor: text;
  max-width: 140px;
  width: 140px;
`

const GroupTextField = styled(TextField)`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  max-width: 640px;
`

const GroupInputsBox = styled.div``

const GroupBox = styled.div`
  max-width: 640px;
  margin-bottom: ${(props) => props.theme.spacing[3]};
`

const NewGroupInputBox = styled.div`
  flex-wrap: wrap;

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    display: flex;
    flex-wrap: inherit;
    max-width: 640px;
    width: 100%;
  }
`
const NewGroupInputField = styled.div`
  flex: 1;
  margin-bottom: ${(props) => props.theme.spacing[4]};
  width: 100%;

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    margin-right: ${(props) => props.theme.spacing[4]};

    &:last-child {
      margin-right: 0;
    }
  }
`

function FieldTitle(props: {children?: string}) {
  if (!props.children) {
    return null
  }

  return <StyledLabel>{props.children}</StyledLabel>
}

const StyledLabel = styled(Label)`
  margin-bottom: ${(props) => props.theme.spacing[4]} !important;

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    display: none;
  }
`

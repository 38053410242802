import {onChangeCheckedHandler} from 'lib/dom'
import LocalizedDateTimePicker from 'lib/LocalizedDateTimePicker'
import {EnabledSwitch} from 'lib/ui/form/Switch'
import TextField from 'lib/ui/TextField'
import {Label} from 'lib/ui/typography'
import {AgendaSettings} from 'Event/template/Lefty/Dashboard/Sidebar/SidebarItem/AgendaList'
import React from 'react'
import {Controller, UseFormMethods} from 'react-hook-form'
import {MaterialUiPickersDate} from '@material-ui/pickers/typings/date'

export default function Settings(props: {
  control: UseFormMethods['control']
  register: UseFormMethods['register']
  agenda: AgendaSettings
  watch: UseFormMethods['watch']
  setValue: UseFormMethods['setValue']
}) {
  const {control, register, agenda, watch, setValue} = props

  // If the start date has been modified we'll use that, else
  // we'll use whatever the current set date is.
  const startDate = watch('startDate') || agenda.startDate

  const endDate = watch('endDate')
  const hasUpdatedEndDate = Boolean(endDate)

  const handleStartDate = (onChange: (...event: any[]) => void) => (
    date: MaterialUiPickersDate,
  ) => {
    if (!date) {
      throw new Error('Date is required')
    }

    const value = date.toISOString()

    onChange(value)

    // If end date hasn't been set, we'll automatically set it forward
    // to the start date.
    if (!hasUpdatedEndDate) {
      setValue('endDate', value)
    }
  }

  const handleEndDate = (onChange: (...event: any[]) => void) => (
    date: MaterialUiPickersDate,
  ) => {
    if (!date) {
      throw new Error('Date is required')
    }

    onChange(date.toISOString())
  }

  return (
    <>
      <Controller
        name="isVisible"
        control={control}
        defaultValue={agenda.isVisible}
        render={({value, onChange}) => (
          <EnabledSwitch
            checked={value}
            onChange={onChangeCheckedHandler(onChange)}
            arial-label="config visible switch"
          />
        )}
      />
      <Label>Event</Label>
      <TextField
        name="text"
        defaultValue={agenda.text}
        aria-label="agenda text"
        fullWidth
        inputProps={{
          ref: register,
        }}
      />
      <Controller
        name="startDate"
        control={control}
        defaultValue={agenda.startDate}
        render={({value, onChange}) => {
          return (
            <LocalizedDateTimePicker
              value={value}
              onChange={handleStartDate(onChange)}
              fullWidth
              label="Start"
              minDate={new Date()}
              inputProps={{
                'aria-label': 'agenda start date',
              }}
            />
          )
        }}
      />
      <Controller
        name="endDate"
        control={control}
        defaultValue={agenda.endDate}
        render={({value, onChange}) => (
          <LocalizedDateTimePicker
            value={value}
            onChange={handleEndDate(onChange)}
            fullWidth
            label="End"
            minDate={startDate}
            inputProps={{
              'aria-label': 'agenda end date',
            }}
          />
        )}
      />
      <Label>Link</Label>
      <TextField
        name="link"
        defaultValue={agenda.link || ''}
        aria-label="agenda link"
        fullWidth
        inputProps={{
          ref: register,
        }}
      />
    </>
  )
}

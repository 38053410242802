import React from 'react'
import {Controller, UseFormMethods} from 'react-hook-form'
import {useNiftyFiftyTemplate} from 'Event/template/NiftyFifty'
import Title from 'lib/ui/ConfigPanel/Title'
import {Label} from 'lib/ui/typography'
import Slider from 'lib/ui/Slider'
import FontSelect from 'lib/FontSelect'
import ColorPicker from 'lib/ui/ColorPicker'
import SelectorButtons from 'lib/ui/SelectorButtons'
import {ControlLabel} from 'lib/ui/typography'

const DEFAULT_FONT_SIZE = 42
const MAX_FONT_SIZE = 50
const MIN_FONT_SIZE = 16
const MAX_FONT_WIDTH = 100
const MIN_FONT_WIDTH = 50

const TEXT_JUSTIFICATION = [
  {label: 'Left', value: 'left'},
  {label: 'Center', value: 'center'},
  {label: 'Right', value: 'right'},
]

export default function Styling(props: {control: UseFormMethods['control']}) {
  const {control} = props
  const template = useNiftyFiftyTemplate()
  const welcomeTextColor = template.welcomeTextColor
    ? template.welcomeTextColor
    : template.textColor

  return (
    <>
      <Title>Typography</Title>
      <Label>Font</Label>
      <Controller
        control={control}
        name="welcomeTextFont"
        defaultValue={template.welcomeTextFont || null}
        render={({value, onChange}) => (
          <FontSelect value={value} onChange={onChange} />
        )}
      />
      <Controller
        name="welcomeTextColor"
        control={control}
        defaultValue={welcomeTextColor || ''}
        render={({value, onChange}) => (
          <ColorPicker
            label="Font Color"
            aria-label="welcome text color"
            color={value}
            onPick={onChange}
          />
        )}
      />
      <Controller
        name="welcomeTextFontSize"
        defaultValue={template.welcomeTextFontSize || DEFAULT_FONT_SIZE}
        control={control}
        render={({value, onChange}) => (
          <Slider
            aria-label="welcome text font size"
            min={MIN_FONT_SIZE}
            max={MAX_FONT_SIZE}
            onChange={onChange}
            valueLabelDisplay="off"
            value={value}
            label="Font Size"
          />
        )}
      />
      <Controller
        name="welcomeTextWidth"
        defaultValue={template.welcomeTextWidth || MAX_FONT_WIDTH}
        control={control}
        render={({value, onChange}) => (
          <Slider
            aria-label="welcome text width"
            min={MIN_FONT_WIDTH}
            max={MAX_FONT_WIDTH}
            onChange={onChange}
            valueLabelDisplay="off"
            value={value}
            unit="%"
            label="Font Width"
          />
        )}
      />
      <ControlLabel>Text Alignment</ControlLabel>
      <Controller
        name="welcomeTextJustify"
        defaultValue={template.welcomeTextJustify}
        control={control}
        render={({value, onChange}) => (
          <SelectorButtons
            onChange={onChange}
            options={TEXT_JUSTIFICATION}
            value={value}
          />
        )}
      />
    </>
  )
}

import {PaymentMethod} from '@stripe/stripe-js'
import {useAsync} from 'lib/async'
import FullPageLoader from 'lib/ui/layout/FullPageLoader'
import {api} from 'lib/url'
import {DefaultPaymentMethodContext} from 'obvio/Billing/DefaultPaymentMethodProvider'
import {teamMemberClient} from 'obvio/obvio-client'
import {useOrganization} from 'organization/OrganizationProvider'
import React, {useCallback, useEffect, useState} from 'react'

export function OrganizationPaymentMethodProvider(props: {
  children: React.ReactElement | React.ReactElement[]
}) {
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethod | null>(null)
  const {
    paymentMethod: savedPaymentMethod,
    loading,
  } = useOrganizationPaymentMethod()

  useEffect(() => {
    if (!savedPaymentMethod) {
      return
    }

    setPaymentMethod(savedPaymentMethod)
  }, [savedPaymentMethod])

  if (loading) {
    return <FullPageLoader />
  }

  return (
    <DefaultPaymentMethodContext.Provider
      value={{
        paymentMethod,
        setPaymentMethod,
      }}
    >
      {props.children}
    </DefaultPaymentMethodContext.Provider>
  )
}

function useOrganizationPaymentMethod() {
  const {organization} = useOrganization()
  const request = useCallback(() => {
    const url = api(`/organizations/${organization.id}/default_payment_method`)

    return teamMemberClient.get<PaymentMethod>(url)
  }, [organization.id])

  const {data: paymentMethod, ...res} = useAsync(request)

  return {paymentMethod, ...res}
}

import React from 'react'
import Button from 'lib/ui/Button'
import {now} from 'lib/date-time'
import {useTownhallUpdate} from 'Event/template/Townhall'
import {v4 as uuid} from 'uuid'

export default function AddAgendaButton(props: {
  className?: string
  sectionId: string
}) {
  const {sectionId} = props

  const save = useTownhallUpdate()

  const id = uuid()

  const addAgenda = () => {
    save({
      dashboardSections: {
        [sectionId]: {
          items: {
            [id]: {
              text: 'Event',
              description: '',
              startDate: now(),
              endDate: now(),
              link: null,
              isVisible: true,
              speakers: [],
            },
          },
        },
      },
    })
  }

  return (
    <Button
      size="large"
      variant="outlined"
      color="secondary"
      fullWidth
      aria-label="add agenda event"
      onClick={addAgenda}
      className={props.className}
    >
      Add Agenda Event
    </Button>
  )
}

import FormControl from '@material-ui/core/FormControl'
import {RuleConfigProps} from 'Event/attendee-rules/RuleConfig/RuleList/RuleForm/SourceConfig'
import {Rule} from 'Event/attendee-rules'
import {
  IS_NOT,
  GROUP,
  GroupRule,
  IS,
  IS_FILLED,
  IS_BLANK,
} from 'Event/attendee-rules/RuleConfig/RuleList/SingleRule/GroupRule'
import {onUnknownChangeHandler} from 'lib/dom'
import React, {useEffect, useState} from 'react'
import {createGroupRule} from 'Event/attendee-rules/RuleConfig/RuleList/SingleRule/GroupRule'
import {useGroups} from 'organization/Event/Groups/GroupsProvider'
import Select from 'lib/ui/Select'
import TextField from 'lib/ui/TextField'
import SearchableTextField from 'lib/ui/SearchableTextField/SearchableTextField'
import styled from 'styled-components'
import Option from 'lib/ui/Select/Option'

export default function GroupRuleConfig(props: RuleConfigProps) {
  const [key, setKey] = useState<GroupRule['key']>(initialKey(props.rule))
  const [type, setType] = useState<GroupRule['type']>(initialType(props.rule))
  const [target, setTarget] = useState<GroupRule['target']>(
    initialTarget(props.rule),
  )
  const {groups, loading} = useGroups()
  const {onSet} = props

  useEffect(() => {
    const isValidRule = () =>
      type === IS_FILLED || type === IS_BLANK
        ? type && key
        : type && key && target

    const isValid = isValidRule()
    const rule = isValid ? createGroupRule(type, key, target) : null
    onSet(rule)
  }, [type, key, target, onSet])

  const onChange = (val: string | null) => {
    setKey(val || '') // Key must be a string
  }

  return (
    <Box>
      <SearchableTextField
        label={'Group key'}
        options={groups}
        value={key}
        onChange={onChange}
        disabled={loading}
      />

      <FormControl fullWidth>
        <StyledSelect
          value={type}
          fullWidth
          onChange={onUnknownChangeHandler(setType)}
          aria-label="pick group rule type"
        >
          <Option value={IS}>{IS}</Option>
          <Option value={IS_NOT}>{IS_NOT}</Option>
          <Option value={IS_FILLED}>{IS_FILLED}</Option>
          <Option value={IS_BLANK}>{IS_BLANK}</Option>
        </StyledSelect>
      </FormControl>
      <ValueTextField target={target} setTarget={setTarget} type={type} />
    </Box>
  )
}

function ValueTextField(props: {
  target: GroupRule['target']
  setTarget: (value: GroupRule['target']) => void
  type: GroupRule['type']
}) {
  const {target, setTarget, type} = props

  useEffect(() => {
    if (!target) {
      return
    }

    if (type === IS_FILLED || type === IS_BLANK) {
      setTarget('')
    }
  }, [type, setTarget, target])

  if (type === IS_FILLED || type === IS_BLANK) {
    return null
  }

  return (
    <TextField
      variant="outlined"
      fullWidth
      placeholder="Target"
      aria-label="group value"
      value={target}
      onChange={setTarget}
    />
  )
}

function initialType(rule: Rule | null): GroupRule['type'] {
  if (!rule || rule.source !== GROUP) {
    return IS
  }

  return rule.type
}

function initialKey(rule: Rule | null): GroupRule['key'] {
  if (!rule || rule.source !== GROUP) {
    return ''
  }

  return rule.key
}

function initialTarget(rule: Rule | null): GroupRule['target'] {
  if (!rule || rule.source !== GROUP) {
    return ''
  }

  return rule.target
}

const StyledSelect = styled(Select)`
  margin-bottom: 0;
`

const Box = styled.div`
  > div[role='presentation'] {
    margin-top: -${(props) => props.theme.spacing[4]};
  }
`

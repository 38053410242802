import styled from 'styled-components'
import FormControl from '@material-ui/core/FormControl'
import Select, {StyledSelect as BaseSelect} from 'lib/ui/Select'
import Option from 'lib/ui/Select/Option'
import {useActions} from 'Event/ActionsProvider'
import React from 'react'
import {onUnknownChangeHandler} from 'lib/dom'

const DEFAULT_LABEL = 'Pick an action for points'

export default function ActionSelect<T extends string | number>(props: {
  value?: T | null
  onChange: (id: T | null) => void
  useId?: boolean
  disabled?: boolean
  disableMargin?: boolean
  label?: string
  noneLabel?: string
}) {
  const {actions} = useActions()

  const setAction = (id: T) => {
    const value = id === 0 ? null : id
    props.onChange(value)
  }

  const label = props.label || DEFAULT_LABEL

  return (
    <StyledFormControl disableMargin={props.disableMargin} fullWidth>
      <StyledSelect
        label={label}
        value={props.value || 0}
        fullWidth
        aria-label="pick action"
        disabled={props.disabled}
        onChange={onUnknownChangeHandler(setAction)}
      >
        {actions.map((action) => (
          <Option
            key={action.id}
            value={props.useId ? action.id : action.key}
            aria-label={`pick ${action.description}`}
          >
            {action.description}
          </Option>
        ))}
        <Option value={0} aria-label="unassign action">
          {props.noneLabel ?? 'None'}
        </Option>
      </StyledSelect>
    </StyledFormControl>
  )
}

const StyledFormControl = styled((props) => {
  const {disableMargin: _, ...otherProps} = props

  return <FormControl {...otherProps} />
})`
  ${(props) => (props.disableMargin ? 'margin: 0!important;' : '')}
`

const StyledSelect = styled(Select)`
  ${BaseSelect} {
    margin-top: 14px !important;
  }
`

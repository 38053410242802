import {User} from 'auth/user'
import FeaturePage from 'Event/template/Townhall/Page'
import {
  FeaturePageTitle,
  PageTexts,
  StyledConfigurable,
} from 'Event/template/Townhall/Page'
import React from 'react'
import {useTownhallTemplate} from 'Event/template/Townhall'
import {useAttendeeVariables} from 'Event'
import SponsorList from 'Event/template/Townhall/Sponsors/SponsorList'
import {TownhallBreadcrumbs} from 'Event/template/Townhall/Page'
import AddTownhallSponsorButton from 'Event/template/Townhall/Sponsors/AddTownhallSponsorButton'
import SponsorsHeaderConfig from 'Event/template/Townhall/Sponsors/SponsorsHeaderConfig'

export default function Sponsors(props: {user: User}) {
  const template = useTownhallTemplate()
  const {sponsors} = template
  const v = useAttendeeVariables()

  return (
    <FeaturePage user={props.user}>
      <StyledConfigurable>
        <SponsorsHeaderConfig />
        <>
          <FeaturePageTitle aria-label="sponsors title">
            {v(sponsors.title)}
          </FeaturePageTitle>
          <TownhallBreadcrumbs page={v(sponsors.title)} />
          <PageTexts
            title={sponsors.secondaryTitle}
            description={sponsors.description}
          />
        </>
      </StyledConfigurable>
      <SponsorList />
      <AddTownhallSponsorButton />
    </FeaturePage>
  )
}

import React from 'react'
import {
  useSimpleBlogTemplate,
  useSimpleBlogUpdate,
  SimpleBlog,
} from 'Event/template/SimpleBlog'
import ComponentConfig, {
  ComponentConfigProps,
  SaveButton,
  Footer,
} from 'organization/Event/DashboardConfig/ComponentConfigPanel'
import {useForm} from 'react-hook-form'
import Settings from 'Event/template/SimpleBlog/Dashboard/Footer/FooterConfig/Settings'
import Styling from 'Event/template/SimpleBlog/Dashboard/Footer/FooterConfig/Styling'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import StylingPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/StylingPanel'
import {
  useAutoUpdate,
  useTemplateEditor,
} from 'organization/Event/TemplateEditor'
import {usePruneAssets} from 'lib/asset'

export const DEFAULT_FOOTER_IMAGE_SIZE = 100

export function FooterConfig(props: ComponentConfigProps) {
  const {showing, onClose} = props

  const {footer} = useSimpleBlogTemplate()
  const update = useSimpleBlogUpdate()

  const {control, watch, handleSubmit, formState} = useForm()
  const pruneAssets = usePruneAssets()
  const {saved} = useTemplateEditor<SimpleBlog>()

  const save = (formData: SimpleBlog['footer']) => {
    const image = formData.image
    pruneAssets({image}, saved.footer)

    update({
      footer: formData,
    })
    onClose()
  }

  useAutoUpdate({values: {footer: watch()}, when: showing})

  return (
    <ComponentConfig
      title="Footer"
      showing={showing}
      onClose={onClose}
      onSubmit={handleSubmit(save)}
      hasChanges={formState.isDirty}
    >
      <SettingsPanel>
        <Settings control={control} footer={footer} />
      </SettingsPanel>
      <StylingPanel>
        <Styling control={control} footer={footer} />
      </StylingPanel>
      <Footer>
        <SaveButton type="submit" />
      </Footer>
    </ComponentConfig>
  )
}

import {useTemplate} from 'Event/TemplateProvider'
import styled from 'styled-components'
import MuiButton, {ButtonProps} from '@material-ui/core/Button'
import React from 'react'
import {spacing} from 'lib/ui/theme'

export default function Button(props: ButtonProps) {
  const template = useTemplate()
  const {changePassword} = template
  const borderRadius =
    `${changePassword.submitButton.borderRadius}px` || spacing[14]
  const hoverColor =
    changePassword.submitButton.hoverColor ||
    changePassword.submitButton.backgroundColor

  return (
    <StyledButton
      variant="contained"
      fullWidth
      backgroundColor={changePassword.submitButton.backgroundColor}
      color={changePassword.submitButton.textColor}
      borderRadius={borderRadius}
      type="submit"
      hoverColor={hoverColor}
      {...props}
    />
  )
}

export const StyledButton = styled(
  ({
    color: _1,
    backgroundColor: _2,
    borderRadius: _3,
    hoverColor: _4,
    ...otherProps
  }) => <MuiButton {...otherProps} />,
)`
  border-radius: ${(props) => props.borderRadius} !important;
  height: 50px;
  color: ${(props) => props.color} !important;
  background: ${(props) => props.backgroundColor} !important;

  &:hover {
    background: ${(props) => props.hoverColor} !important;
  }
`

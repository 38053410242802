import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import Box from '@material-ui/core/Box'
import {
  MailchimpGroup,
  MailchimpIntegration,
  Tag,
  useMailchimp,
} from 'organization/Event/Services/Apps/Mailchimp'
import LoginUrlFieldSelect from 'organization/Event/Services/Apps/Mailchimp/Config/LoginUrlFieldSelect/LoginUrlFieldSelect'
import EnableAutoSyncSwitch from 'organization/Event/Services/Apps/Mailchimp/Config/EnableAutoSyncSwitch'
import TagsConfig from 'organization/Event/Services/Apps/Mailchimp/Config/TagsConfig'
import {AccessTokenSelect} from 'organization/Event/Services/Apps/Mailchimp/Config/AccessTokenSelect'
import ImportAudienceButton from 'organization/Event/Services/Apps/Mailchimp/Config/ImportAudienceButton'
import InfoAlert from 'lib/ui/alerts/InfoAlert'
import {withStyles} from '@material-ui/core'
import {spacing} from 'lib/ui/theme'
import Button from 'lib/ui/Button'
import {useOrganization} from 'organization/OrganizationProvider'
import {useEvent} from 'Event/EventProvider'
import {useServices} from 'organization/Event/Services/ServicesProvider'
import {api} from 'lib/url'
import GroupsConfig from 'organization/Event/Services/Apps/Mailchimp/Config/GroupsConfig'
import {
  MailchimpField,
  useMailchimpFields,
} from 'lib/event-api/integrations/mailchimp/fields/list'
import AudienceSelect from 'organization/Event/Services/Apps/Mailchimp/Config/AudienceSelect'
import Select from 'lib/ui/Select'
import Option from 'lib/ui/Select/Option'
import {onUnknownChangeHandler} from 'lib/dom'
import ErrorAlert from 'lib/ui/alerts/ErrorAlert'

export default function FullConfig() {
  const mailchimp = useMailchimp()

  const [successMessage, setSuccessMessage] = useState<null | string>(null)
  const [loginFieldId, setLoginFieldId] = useState<MailchimpField['id']>(
    mailchimp.login_url_field_id ?? '',
  )
  const [tags, setTags] = useState<Tag[]>(mailchimp.tags)
  const [groups, setGroups] = useState<MailchimpGroup[]>(mailchimp.groups)

  const {client} = useOrganization()
  const {event} = useEvent()

  const {data: fieldsData, isLoading} = useMailchimpFields({client, event})

  const fields = fieldsData ?? []

  const [firstNameFieldId, setFirstNameFieldId] = useState<string | null>(null)
  const [lastNameFieldId, setLastNameFieldId] = useState<string | null>(null)
  const [phoneFieldId, setPhoneFieldId] = useState<string | null>(null)

  const clearSuccessMessage = () => setSuccessMessage(null)
  const {save, processing, error, clearErrorMessage} = useSave()

  const saveData = () => {
    if (processing) {
      return
    }

    save({
      login_url_field_id: loginFieldId,
      tags,
      groups,
      first_name_field_id: firstNameFieldId,
      last_name_field_id: lastNameFieldId,
      phone_field_id: phoneFieldId,
    })
  }

  // Set default field ids
  useEffect(() => {
    if (!fieldsData) {
      return
    }

    // Already set values
    if (
      firstNameFieldId !== null ||
      lastNameFieldId !== null ||
      phoneFieldId !== null
    ) {
      return
    }

    // We only want to set the default field if it is defined, otherwise we'll just
    // leave it blank.
    const getDefaultField = (id: string) => {
      const idExists = Boolean(fieldsData.find((field) => field.id === id))
      if (idExists) {
        return id
      }

      return ''
    }

    setFirstNameFieldId(
      mailchimp.first_name_field_id ?? getDefaultField('FNAME'),
    )

    setLastNameFieldId(mailchimp.last_name_field_id ?? getDefaultField('LNAME'))
    setPhoneFieldId(mailchimp.phone_field_id ?? getDefaultField('PHONE'))
  }, [fieldsData, mailchimp, firstNameFieldId, lastNameFieldId, phoneFieldId])

  return (
    <>
      <StyledSuccessAlert onClose={clearSuccessMessage}>
        {successMessage}
      </StyledSuccessAlert>
      <Box display="flex" justifyContent="flex-end" mb={2}>
        <EnableAutoSyncSwitch />
      </Box>
      <AudienceSelect disabled />
      <Box mb={3}>
        <ImportAudienceButton onSuccess={setSuccessMessage} />
      </Box>
      <AccessTokenSelect disabled />
      <Box mb={3}>
        <h3>Fields Mapping</h3>
      </Box>
      <Select
        value={firstNameFieldId || ''}
        fullWidth
        onChange={onUnknownChangeHandler(setFirstNameFieldId)}
        aria-label={'pick first name field'}
        variant="outlined"
        disabled={isLoading}
        label={'First Name Field'}
      >
        {fields.map((field) => (
          <Option
            key={field.id}
            value={field.id}
            aria-label={`pick ${field.name}`}
          >
            {field.name}
          </Option>
        ))}
      </Select>
      <Select
        value={lastNameFieldId || ''}
        fullWidth
        onChange={onUnknownChangeHandler(setLastNameFieldId)}
        aria-label={'pick last name field'}
        variant="outlined"
        disabled={isLoading}
        label={'Last Name Field'}
      >
        {fields.map((field) => (
          <Option
            key={field.id}
            value={field.id}
            aria-label={`pick ${field.name}`}
          >
            {field.name}
          </Option>
        ))}
      </Select>
      <Select
        value={phoneFieldId || ''}
        fullWidth
        onChange={onUnknownChangeHandler(setPhoneFieldId)}
        aria-label={'pick phone field'}
        variant="outlined"
        disabled={isLoading}
        label={'Phone Field'}
      >
        {fields.map((field) => (
          <Option
            key={field.id}
            value={field.id}
            aria-label={`pick ${field.name}`}
          >
            {field.name}
          </Option>
        ))}
      </Select>

      <LoginUrlFieldSelect value={loginFieldId} onChange={setLoginFieldId} />
      <TagsConfig onChange={setTags} tags={tags} />
      <Box mb={3}>
        <h3>Groups</h3>
      </Box>
      <GroupsConfig onChange={setGroups} groups={groups} />

      <StyledErrorMeesage onClose={clearErrorMessage}>
        {error}
      </StyledErrorMeesage>
      <SaveButton
        type="submit"
        variant="contained"
        color="primary"
        size="large"
        aria-label="save"
        disabled={processing}
        onClick={saveData}
      >
        Save
      </SaveButton>
    </>
  )
}

function useSave() {
  const {client} = useOrganization()
  const {event} = useEvent()

  const [processing, setProcessing] = useState<boolean>(false)
  const {update: updateIntegration} = useServices()
  const [error, setError] = useState<string | null>(null)
  const save = (
    data: Pick<
      MailchimpIntegration,
      | 'login_url_field_id'
      | 'tags'
      | 'groups'
      | 'first_name_field_id'
      | 'last_name_field_id'
      | 'phone_field_id'
    >,
  ) => {
    setProcessing(true)
    setError(null)
    client
      .put<MailchimpIntegration>(
        api(`/events/${event.id}/integrations/mailchimp`),
        data,
      )
      .then(updateIntegration)
      .catch((e) => {
        setError(e.message)
      })
      .finally(() => {
        setProcessing(false)
      })
  }

  const clearErrorMessage = () => {
    setError(null)
  }

  return {
    processing,
    save,
    error,
    clearErrorMessage,
  }
}

const StyledSuccessAlert = styled(InfoAlert)`
  margin-bottom: ${(props) => props.theme.spacing[5]};
`

const StyledErrorMeesage = styled(ErrorAlert)`
  margin-bottom: ${(props) => props.theme.spacing[5]};
`

const SaveButton = withStyles({
  root: {
    marginBottom: spacing[5],
  },
})(Button)

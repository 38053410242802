import React from 'react'
import {ReactComponent as AddIcon} from 'assets/images/icons/plus.svg'
import PageHeaderButton, {
  PageHeaderButtonProps,
} from 'lib/ui/PageHeader/PageHeaderButton'

export default function NewItemButton(
  props: Pick<PageHeaderButtonProps, 'text'> & Partial<PageHeaderButtonProps>,
) {
  return (
    <PageHeaderButton
      variant="contained"
      textCollapsed="New"
      color="primary"
      startIcon={<AddIcon />}
      {...props}
    />
  )
}

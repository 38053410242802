import Box from '@material-ui/core/Box'
import {Lefty, useLeftyTemplate, useLeftyUpdate} from 'Event/template/Lefty'
import ColorPicker from 'lib/ui/ColorPicker'
import Switch from 'lib/ui/form/Switch'
import {FontFamilySelect} from 'lib/FontSelect'
import {SectionTitle} from 'organization/Event/Page'
import React from 'react'
import InputLabel from 'lib/ui/TextField/InputLabel'
import Slider from 'lib/ui/Slider'
import {onChangeCheckedHandler} from 'lib/dom'
import Page from 'organization/Event/Page'
import {SaveButton} from 'organization/Event/DashboardConfig/ConfigDialog'
import {Controller, useForm} from 'react-hook-form'
import FormControl from 'lib/ui/FormControl'
import {DesignBreadcrumbs} from 'organization/Event/Page/PageBreadcrumbs'

type GlobalStylesFormData = Pick<
  Lefty,
  'textColor' | 'linkColor' | 'linkUnderline'
> &
  Lefty['background']

export default function GlobalStylesConfig() {
  const template = useLeftyTemplate()
  const updateBackground = useLeftyUpdate()
  const {handleSubmit, control} = useForm()

  const save = (data: GlobalStylesFormData) => {
    updateBackground({
      ...data,
    })
  }
  return (
    <DesignBreadcrumbs page="Global Styles">
      <Page>
        <SectionTitle>Global Styles</SectionTitle>
        <form onSubmit={handleSubmit(save)}>
          <Box mb={2}>
            <Controller
              name="background.color"
              defaultValue={template.background.color}
              control={control}
              render={({value, onChange}) => (
                <ColorPicker
                  label="Background Color"
                  color={value}
                  onPick={onChange}
                  aria-label="dashboard background color"
                />
              )}
            />
          </Box>
          <Controller
            name="background.opacity"
            defaultValue={template.background.opacity}
            control={control}
            render={({value, onChange}) => (
              <Slider
                min={0}
                max={100}
                step={1}
                onChange={onChange}
                valueLabelDisplay="auto"
                value={value}
                valueLabelFormat={() => <div>{value}</div>}
                aria-label="background color opacity"
                label="Background Color Opacity"
                unit="%"
              />
            )}
          />
          <FormControl>
            <InputLabel>Font Family</InputLabel>
            <Controller
              control={control}
              name="font"
              defaultValue={template.font || null}
              render={({value, onChange}) => (
                <FormControl fullWidth>
                  <FontFamilySelect value={value} onChange={onChange} />
                </FormControl>
              )}
            />
          </FormControl>

          <Box mb={2}>
            <Controller
              name="textColor"
              defaultValue={template.textColor}
              control={control}
              render={({value, onChange}) => (
                <ColorPicker
                  label="Text Color"
                  color={value}
                  onPick={onChange}
                  aria-label="text color"
                />
              )}
            />
          </Box>
          <Box mb={2}>
            <Controller
              name="linkColor"
              defaultValue={template.linkColor}
              control={control}
              render={({value, onChange}) => (
                <ColorPicker
                  label="Link Color"
                  color={value}
                  onPick={onChange}
                  aria-label="link color"
                />
              )}
            />
          </Box>
          <Box mb={2}>
            <Controller
              name="linkUnderline"
              defaultValue={template.linkUnderline}
              control={control}
              render={({value, onChange}) => (
                <Switch
                  label="Link Underline"
                  checked={value}
                  onChange={onChangeCheckedHandler(onChange)}
                  labelPlacement="end"
                />
              )}
            />
          </Box>
          <SaveButton />
        </form>
      </Page>
    </DesignBreadcrumbs>
  )
}

import {ObvioVariable} from 'lib/ui/form/TextEditor'

export const LOGIN_URL: ObvioVariable = {
  label: "{{login_url}} - Attendee's magic login link.",
  value: '{{login_url}}',
}

export const FIRST_NAME: ObvioVariable = {
  label: "{{first_name}} - Attendee's first name.",
  value: '{{first_name}}',
}

export const LAST_NAME: ObvioVariable = {
  label: "{{last_name}} - Attendee's last name.",
  value: '{{last_name}}',
}

export const EMAIL: ObvioVariable = {
  label: "{{email}} - Attendee's email address.",
  value: '{{email}}',
}

export const RESET_PASSWORD_URL: ObvioVariable = {
  label: "{{reset_password_url}} - Attendee's password reset URL.",
  value: '{{reset_password_url}}',
}

export const EVENT_NAME: ObvioVariable = {
  label: "{{event}} - Your event's name.",
  value: '{{event}}',
}

export const TICKET_NAME: ObvioVariable = {
  label: '{{ticket_name}} - Ticket purchased name.',
  value: '{{ticket_name}}',
}

export const ORDER_TOTAL: ObvioVariable = {
  label: '{{order_total}} - Purchase order total.',
  value: '{{order_total}}',
}

export const TODAY: ObvioVariable = {
  label: "{{today}} - Today's date.",
  value: '{{today}}',
}

export const TIME: ObvioVariable = {
  label: '{{time}} - Current time.',
  value: '{{time}}',
}

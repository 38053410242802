import React from 'react'
import styled from 'styled-components'
import Page, {
  StyledPaper,
  StyledFormContainer,
} from 'Event/template/Cards/Login/Page'
import Button from 'Event/auth/Login/SubmitButton/Button'
import {Controller, useForm} from 'react-hook-form'
import {useEventAuth} from 'Event/auth'
import {fieldErrors} from 'lib/form'
import ErrorAlert from 'lib/ui/alerts/ErrorAlert'
import {useQueryParams} from 'lib/url'
import TextField from 'Event/auth/Login/TextField'
import Content from 'lib/ui/form/TextEditor/Content'
import {RoomRegistrationFormProps} from 'Event/RoomRegistration'
import Visible from 'lib/ui/layout/Visible'
import AreaRegistrationFormPhoneNumberField from 'Event/AreaRegistrationPhoneNumberField'

export default function CardsRoomRegistrationForm(
  props: RoomRegistrationFormProps,
) {
  const {user} = useEventAuth()
  const {handleSubmit, register, control, errors: formErrors} = useForm()
  const {responseError, submit, canSubmit, room} = props

  const errors = fieldErrors({
    formErrors,
    responseError,
  })

  const query = useQueryParams()

  return (
    <Page>
      <StyledPaper>
        <StyledFormContainer>
          <StyledErrorAlert>{responseError?.message}</StyledErrorAlert>
          <form onSubmit={handleSubmit(submit)}>
            <Content>{room.template.description}</Content>
            <Visible when={!room.register_existing_attendees_only}>
              <TextField
                name="first_name"
                fullWidth
                label={room.template.firstNameLabel}
                defaultValue={user?.first_name || query.first_name}
                inputProps={{
                  ref: register({required: 'First name is required.'}),
                  'aria-label': 'first name',
                }}
                disabled={!canSubmit}
                error={Boolean(errors.first_name)}
                helperText={errors.first_name}
              />
            </Visible>
            <Visible when={!room.register_existing_attendees_only}>
              <TextField
                name="last_name"
                fullWidth
                label={room.template.lastNameLabel}
                defaultValue={user?.last_name || query.last_name}
                inputProps={{
                  ref: register({required: 'Last name is required.'}),
                  'aria-label': 'last name',
                }}
                disabled={!canSubmit}
                error={Boolean(errors.last_name)}
                helperText={errors.last_name}
              />
            </Visible>
            <TextField
              label={room.template.emailLabel}
              type="email"
              fullWidth
              variant="outlined"
              name="email"
              defaultValue={user?.email || query.email}
              inputProps={{
                ref: register({required: 'Email is required.'}),
                'aria-label': 'email',
              }}
              error={Boolean(errors.email)}
              helperText={errors.email}
              disabled={!canSubmit}
            />
            <Visible when={room.template.hasPhoneNumberField}>
              <Controller
                name="phone_number"
                defaultValue={user?.phone_number || query.phone_number}
                control={control}
                rules={{
                  required: room.template.phoneNumberRequired
                    ? 'Phone number is required'
                    : false,
                }}
                render={({onChange, value}) => (
                  <AreaRegistrationFormPhoneNumberField
                    onChange={onChange}
                    value={value}
                    error={Boolean(errors.phone_number)}
                    helperText={errors.phone_number}
                    disabled={!canSubmit}
                    label={room.template.phoneNumberLabel}
                    fullWidth
                    variant="outlined"
                    aria-label="phone number input"
                  />
                )}
              />
            </Visible>
            <Button
              variant="contained"
              fullWidth
              aria-label="join room"
              type="submit"
              disabled={!canSubmit}
            >
              <JoinRoomLoader loading={!canSubmit}>
                {room.template.joinButtonText}
              </JoinRoomLoader>
            </Button>
          </form>
        </StyledFormContainer>
      </StyledPaper>
    </Page>
  )
}

const StyledErrorAlert = styled(ErrorAlert)`
  width: 100%;
  margin-bottom: ${(props) => props.theme.spacing[4]};
`

function JoinRoomLoader(props: {children: string; loading: boolean}) {
  if (props.loading) {
    return <span>loading ...</span>
  }

  return <span>{props.children}</span>
}

import {TicketSelectorBlock} from 'Event/Marketplace/Block/TicketSelector'

export const createTicketSelectorBlock = (): TicketSelectorBlock => ({
  type: 'TicketSelector',
  visibility: 'all',
  padding: {
    left: 20,
    right: 20,
    top: 20,
    bottom: 20,
  },
  alignment: {
    vertical: 'center',
    horizontal: 'center',
  },
  background: {
    image: '',
    color: '',
    opacity: 0,
  },
  border: {
    width: 0,
    color: '#FFFFFF',
    radius: 0,
  },
  name: {
    color: '#000000',
    opacity: 100,
    font: null,
    fontSize: 24,
  },
  description: {
    color: '#000000',
    opacity: 100,
    font: null,
    fontSize: 20,
    enabled: true,
  },
  spacing: 20,
  selectorIcon: 'circle',
})

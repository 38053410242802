import {usePrevious} from 'lib/state'
import {useEffect, useState} from 'react'

export default function useDebounce<T>(value: T, delayMs: number) {
  const [debounced, setDebounced] = useState(value)

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebounced(value)
    }, delayMs)

    return () => {
      clearTimeout(handler)
    }
  }, [value, delayMs])

  return debounced
}

/**
 * Helper that is useful when you would like to read/write the current value, and
 * also be notified when the debounced value changes. Such as for search text
 * inputs where the current value should always be shown.
 *
 * @param value
 * @param onUpdate
 * @param delayMs
 * @returns
 */
export function useDebouncedHandler<T>(
  value: T,
  onUpdate: (v: T) => void,
  delayMs = 1000,
): [T, (current: T) => void] {
  const [current, setCurrent] = useState<T>(value)
  const debounced = useDebounce(current, delayMs)
  const previous = usePrevious(debounced)

  // If the value changes from the parent, we'll always update our local
  // copy here.
  useEffect(() => {
    setCurrent(value)
  }, [value])

  useEffect(() => {
    if (previous === debounced) {
      return
    }

    if (value === debounced) {
      return
    }

    onUpdate(debounced)
  }, [value, onUpdate, debounced, previous])

  return [current, setCurrent]
}

import React from 'react'
import Container from '@material-ui/core/Container'
import {Cards} from 'Event/template/Cards'
import {TechCheckProps} from 'Event/Step3/TechCheck'
import StepIndicator from 'Event/template/Cards/check-in/StepIndicator'
import CheckInPage from 'Event/template/Cards/check-in/Page'
import Body from 'Event/Step3/TechCheck/Body'
import Buttons from 'Event/Step3/TechCheck/Buttons'
import AdditionalContent from 'Event/Step3/TechCheck/AdditionalContent'
import ConfigFields from 'organization/Event/TechCheckConfig/ConfigFields'

export type CardsTechCheckProps = TechCheckProps & {
  settings: Cards['techCheck']
}

export default function TechCheck(props: CardsTechCheckProps) {
  return (
    <CheckInPage user={props.user}>
      <Container maxWidth="md">
        <StepIndicator step={3} />
        <ConfigFields />
        <Body />
        <Buttons />
        <AdditionalContent />
      </Container>
    </CheckInPage>
  )
}

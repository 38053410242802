import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import MuiMenuItem from '@material-ui/core/MenuItem'
import MuiMenu from '@material-ui/core/Menu'
import {useTownhallTemplate} from 'Event/template/Townhall'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import {useAttendeeVariables} from 'Event'
import {orderedIdsByPosition} from 'lib/list'
import {useHistory} from 'react-router-dom'
import {useEditMode} from 'Event/EditModeProvider'

export default function Menu(props: {
  sectionId: string
  onSelectItem: (id: string) => void
  'aria-label'?: string
}) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [sectionId, setSectionId] = React.useState(props.sectionId)

  const {navBar} = useTownhallTemplate()
  const v = useAttendeeVariables()
  const history = useHistory()
  const isEditMode = useEditMode()

  const navItems = navBar.menuItems

  const useStyles = makeStyles({
    root: {
      backgroundColor: navBar.backgroundColor,
      color: navBar.color,
      paddingTop: 0,
      paddingBottom: 0,
    },
    menuItem: {
      borderBottomStyle: 'solid',
      borderBottomWidth: '1px',
      borderBottomColor: navBar.indicatorColor,
    },
  })

  const classes = useStyles()

  const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuItemClick = (id: string, link?: string) => {
    if (link) {
      history.push(link)
      return
    }

    setSectionId(id)
    props.onSelectItem(id)
    setAnchorEl(null)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const ids = orderedIdsByPosition(navItems)
  const enabledIds = ids.filter((id) => {
    const item = navItems[id]
    return item.isEnabled || isEditMode
  })
  const navMenuItems = enabledIds.map((id, index) => {
    const props = navItems[id]
    return (
      <MuiMenuItem
        key={index}
        selected={sectionId === id}
        onClick={(_event) => handleMenuItemClick(id, props.link)}
      >
        {v(props.title)}
      </MuiMenuItem>
    )
  })

  return (
    <div className={classes.root}>
      <List
        component="nav"
        aria-label="dashboard nav buttons"
        className={classes.root}
      >
        <ListItem
          button
          divider
          aria-haspopup="true"
          aria-controls="lock-menu"
          aria-label="when item is locked"
          onClick={handleClickListItem}
        >
          <ListItemText
            primary={
              sectionId === 'header'
                ? v(navBar.mainMenuItemTitle)
                : navItems[sectionId].title
            }
          />
          {anchorEl ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
      </List>
      <MuiMenu
        id="lock-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: 200,
            width: '100%',
            backgroundColor: navBar.backgroundColor,
            color: navBar.color,
          },
        }}
      >
        <MuiMenuItem
          onClick={(_event) => handleMenuItemClick('header', 'section')}
        >
          {v(navBar.mainMenuItemTitle)}
        </MuiMenuItem>
        {navMenuItems}
      </MuiMenu>
    </div>
  )
}

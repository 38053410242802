import React from 'react'
import {Controller, UseFormMethods} from 'react-hook-form'
import Slider from 'lib/ui/Slider'
import ColorPicker from 'lib/ui/ColorPicker'
import {usePanelsTemplate} from 'Event/template/Panels'

export default function Styling(props: {control: UseFormMethods['control']}) {
  const {control} = props
  const template = usePanelsTemplate()
  const {rightPanel, textColor} = template
  const rightPanelTextColor = rightPanel.textColor
    ? rightPanel.textColor
    : textColor

  return (
    <>
      <Controller
        name="barBackgroundColor"
        defaultValue={rightPanel.barBackgroundColor}
        control={control}
        render={({value, onChange}) => (
          <ColorPicker
            label="Bar Background Color"
            color={value}
            onPick={onChange}
            aria-label="bar background color"
          />
        )}
      />
      <Controller
        name="barTextColor"
        defaultValue={rightPanel.barTextColor}
        control={control}
        render={({value, onChange}) => (
          <ColorPicker
            label="Bar Text Color"
            color={value}
            onPick={onChange}
            aria-label="bar text color"
          />
        )}
      />
      <Controller
        name="tabUnderlineColor"
        defaultValue={rightPanel.tabUnderlineColor}
        control={control}
        render={({value, onChange}) => (
          <ColorPicker
            label="Tab Underline Color"
            color={value}
            onPick={onChange}
            aria-label="Tab underline color"
          />
        )}
      />
      <Controller
        name="textColor"
        defaultValue={rightPanelTextColor}
        control={control}
        render={({value, onChange}) => (
          <ColorPicker
            label="Text Color"
            color={value}
            onPick={onChange}
            aria-label="panel text color"
          />
        )}
      />
      <Controller
        name="backgroundColor"
        defaultValue={rightPanel.backgroundColor}
        control={control}
        render={({value, onChange}) => (
          <ColorPicker
            label="Panel Background Color"
            color={value}
            onPick={onChange}
            aria-label="panel background color"
          />
        )}
      />
      <Controller
        name="backgroundOpacity"
        defaultValue={rightPanel.backgroundOpacity}
        control={control}
        render={({value, onChange}) => (
          <Slider
            min={0}
            max={100}
            step={1}
            onChange={onChange}
            valueLabelDisplay="auto"
            value={value}
            label="Background Opacity"
            unit="%"
          />
        )}
      />
    </>
  )
}

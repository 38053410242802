import SignUpLinkConfig from 'Event/auth/Login/SignUpLink/SignUpLinkConfig'
import styled from 'styled-components'
import Configurable from 'organization/Event/Configurable'
import React from 'react'
import {RelativeLink} from 'lib/ui/link/RelativeLink'
import {useTemplate} from 'Event/TemplateProvider'
import {eventRoutes} from 'Event/Routes'
import {useGuestVariables} from 'Event'
import {useEvent} from 'Event/EventProvider'
import {useEditMode} from 'Event/EditModeProvider'

export default function SignUpLink(props: {
  Component?: React.FC<{
    to: any
    'aria-label'?: string
    textColor: string | null
  }>
}) {
  const {event} = useEvent()
  const isEditMode = useEditMode()
  const {
    signUp: {linkLabel},
    login: {
      description: {color},
    },
  } = useTemplate()
  const v = useGuestVariables()

  const Component = props.Component || SignUpLinkBase

  if (!event.sign_up_enabled && !isEditMode) {
    return null
  }

  return (
    <Configurable>
      <SignUpLinkConfig />
      <Component to={eventRoutes.signUp} aria-label="sign up" textColor={color}>
        {v(linkLabel)}
      </Component>
    </Configurable>
  )
}

export const SignUpLinkBase = styled(RelativeLink)<{textColor: string | null}>`
  color: ${(props) => props.textColor};
  text-align: center;
  display: block;
  margin-top: ${(props) => props.theme.spacing[6]};
`

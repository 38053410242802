import React from 'react'
import {UseFormMethods} from 'react-hook-form'
import TextField from 'lib/ui/TextField'
import {Label} from 'lib/ui/typography'
import TicketRibbonUpload, {
  CustomTicketRibbon,
} from 'organization/Event/DashboardConfig/TicketRibbonUpload'
import {TicketRibbon} from 'Event/template/Panels/Dashboard/TicketRibbonList/Ribbon'

const MAX_HOVER_TEXT_CHARS = 15

export default function Settings(props: {
  register: UseFormMethods['register']
  ticketRibbon: TicketRibbon
  letterUpload: CustomTicketRibbon | null
  setLetterUpload: (v: CustomTicketRibbon | null) => void
  hoverUpload: CustomTicketRibbon | null
  setHoverUpload: (v: CustomTicketRibbon | null) => void
  processing: boolean
  setProcessing: (v: boolean) => void
  hoverTextError: boolean
  hoverTextErrorMessage: string
}) {
  const {
    register,
    ticketRibbon,
    letterUpload,
    hoverUpload,
    processing,
    setLetterUpload,
    setHoverUpload,
    setProcessing,
    hoverTextError,
    hoverTextErrorMessage,
  } = props

  return (
    <>
      <Label>Letter Image</Label>
      <TicketRibbonUpload
        processing={processing}
        setProcessing={setProcessing}
        customRibbon={letterUpload}
        setCustomRibbon={setLetterUpload}
        width={30}
        height={65}
        disableAutoRemove
      />
      <Label>Hover Image</Label>
      <TicketRibbonUpload
        processing={processing}
        setProcessing={setProcessing}
        customRibbon={hoverUpload}
        setCustomRibbon={setHoverUpload}
        height={30}
        width={110}
        canResize
        disableHeightResize
        disableAutoRemove
      />
      <Label>Letter</Label>
      <TextField
        name="letter"
        aria-label="letter input"
        fullWidth
        defaultValue={ticketRibbon.letter}
        inputProps={{
          ref: register,
        }}
      />
      <Label>Hover Text</Label>
      <TextField
        name="hoverText"
        aria-label="hover text input"
        fullWidth
        defaultValue={ticketRibbon.hoverText}
        inputProps={{
          ref: register({
            maxLength: MAX_HOVER_TEXT_CHARS,
          }),
        }}
        error={Boolean(hoverTextError)}
        helperText={hoverTextErrorMessage}
      />
    </>
  )
}

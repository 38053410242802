import React, {useEffect, useState} from 'react'
import {Emoji} from 'Event/Dashboard/components/EmojiList/emoji'
import ComponentConfig, {
  ComponentConfigProps,
  SaveButton,
  Footer,
} from 'organization/Event/DashboardConfig/ComponentConfigPanel'
import Settings from 'Event/template/NiftyFifty/Dashboard/EmojiList/EmojiListConfig/Settings'
import Styling from 'Event/template/NiftyFifty/Dashboard/EmojiList/EmojiListConfig/Styling'
import {
  useNiftyFiftyTemplate,
  useNiftyFiftyUpdate,
} from 'Event/template/NiftyFifty'
import {EmojiList} from 'Event/template/NiftyFifty/Dashboard/EmojiList'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import StylingPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/StylingPanel'

export default function EmojiListConfig(props: ComponentConfigProps) {
  const {showing: isVisible, onClose} = props
  const template = useNiftyFiftyTemplate()
  const updateTemplate = useNiftyFiftyUpdate()
  const {emojiList} = template
  const [emojiWidth, setEmojiWidth] = useState(emojiList.emojiWidth)
  const [emojis, setEmojis] = useState(emojiList.emojis)

  useEffect(() => {
    if (isVisible) {
      return
    }

    setEmojiWidth(emojiList.emojiWidth)
    setEmojis(emojiList.emojis)
  }, [isVisible, emojiList])

  const save = () => {
    const data: EmojiList = {
      emojiWidth,
      emojis,
    }

    updateTemplate({
      emojiList: data,
    })

    onClose()
  }

  const addNewEmoji = (emoji: Emoji['name']) => {
    const added = [...emojis, emoji]
    setEmojis(added)
  }

  const updateEmoji = (index: number) => (target: Emoji['name']) => {
    const updated = emojis.map((e, i) => {
      const isTarget = i === index
      if (isTarget) {
        return target
      }

      return e
    })

    setEmojis(updated)
  }

  return (
    <ComponentConfig showing={isVisible} onClose={onClose} title="Emojis">
      <SettingsPanel>
        <Settings
          emojis={emojis}
          addNewEmoji={addNewEmoji}
          updateEmoji={updateEmoji}
          setEmojis={setEmojis}
        />
      </SettingsPanel>
      <StylingPanel>
        <Styling emojiWidth={emojiWidth} setEmojiWidth={setEmojiWidth} />
      </StylingPanel>
      <Footer>
        <SaveButton onClick={save} />
      </Footer>
    </ComponentConfig>
  )
}

import {useTemplate} from 'Event/TemplateProvider'
import {useSaveTemplate} from 'Event/TemplateUpdateProvider'
import Title from 'lib/ui/ConfigPanel/Title'
import TextEditor, {TextEditorContainer} from 'lib/ui/form/TextEditor'
import InputLabel from 'lib/ui/InputLabel'
import TextField from 'lib/ui/TextField'
import {useConfig} from 'organization/Event/Configurable'
import Config from 'organization/Event/Configurable/Config'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import React from 'react'
import {Controller} from 'react-hook-form'
import StylingPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/StylingPanel'
import Slider from 'lib/ui/Slider'

export default function HeaderConfig() {
  const save = useSaveTemplate()
  const {speakers} = useTemplate()

  const {
    form: {control},
  } = useConfig()

  if ('spacing' in speakers) {
    return (
      <Config title="Header" onSave={save}>
        <SettingsPanel>
          <SettingsConfigs />
        </SettingsPanel>
        <StylingPanel>
          <Controller
            name="speakers.spacing"
            defaultValue={speakers.spacing}
            control={control}
            render={({onChange, value}) => (
              <Slider
                min={0}
                hideUnits
                max={10}
                step={1}
                value={value}
                onChange={onChange}
                valueLabelDisplay="auto"
                aria-label="speaker space"
                label="Space Between Speakers"
              />
            )}
          />
        </StylingPanel>
      </Config>
    )
  }

  return (
    <Config title="Header" onSave={save}>
      <SettingsPanel>
        <SettingsConfigs />
      </SettingsPanel>
    </Config>
  )
}

function SettingsConfigs() {
  const {speakers} = useTemplate()

  const {
    form: {register, control},
  } = useConfig()

  return (
    <>
      <MenuTitleConfig />
      <Title>Page</Title>
      <TextField
        name="speakers.title"
        defaultValue={speakers.title}
        label="Title"
        fullWidth
        inputProps={{
          'aria-label': 'speakers title',
          ref: register,
        }}
      />
      <InputLabel>Description</InputLabel>
      <TextEditorContainer>
        <Controller
          name="speakers.description"
          defaultValue={speakers.description}
          control={control}
          render={({value, onChange}) => (
            <TextEditor data={value} onChange={onChange} />
          )}
        />
      </TextEditorContainer>
    </>
  )
}

function MenuTitleConfig() {
  const {
    form: {register},
  } = useConfig()

  const {speakers} = useTemplate()

  if (!('menuTitle' in speakers)) {
    return null
  }

  return (
    <>
      <Title>Menu</Title>
      <TextField
        name="speakers.menuTitle"
        defaultValue={speakers.menuTitle || 'Our Speakers'}
        label="Text"
        fullWidth
        inputProps={{
          'aria-label': 'menu title',
          ref: register,
        }}
      />
    </>
  )
}

import Grid from '@material-ui/core/Grid'
import NextStepButton from 'Event/Marketplace/Block/PurchaseForm/NextStepButton'
import {usePurchaseForm} from 'Event/Marketplace/Block/PurchaseForm/PurchaseFormProvider'
import Visible from 'lib/ui/layout/Visible'
import React from 'react'
import PurchaseButton from 'Event/Marketplace/Block/PurchaseForm/PurchaseButton'
import {usePurchase} from 'Event/Marketplace/PurchaseProvider'
import FormButtons, {
  FormButtonBox,
} from 'Event/Marketplace/Block/PurchaseForm/FormButtons'
import {BlockComponentProps} from 'Event/Marketplace/Block'
import {PurchaseFormBlock} from 'Event/Marketplace/Block/PurchaseForm'

interface CustomerDetailStepProps
  extends PurchaseFormBlock,
    BlockComponentProps {
  disabled?: boolean
  onContinue: () => void
  TextFieldComponent: React.FC<any>
  ButtonComponent: React.FC<any>
}

export default function CustomerDetailStep(props: CustomerDetailStepProps) {
  const {
    onContinue,
    disabled,
    TextFieldComponent,
    ButtonComponent,
    ...config
  } = props

  const {
    form: {register, errors, trigger},
  } = usePurchaseForm()

  const phoneNumberRequired =
    config.phoneNumber.enabled && config.phoneNumber.required

  const handleContinue = async () => {
    const baseFields = ['first_name', 'last_name', 'email']

    const requiredFields = phoneNumberRequired
      ? [...baseFields, 'phone_number']
      : baseFields

    trigger(requiredFields).then((valid) => {
      if (valid) {
        onContinue()
      }
    })
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <TextFieldComponent
          aria-label="purchase first name"
          config={config}
          name="first_name"
          placeholder="firstName"
          inputProps={{
            ref: register({
              required: true,
            }),
            'aria-label': 'first name',
          }}
          error={Boolean(errors.first_name)}
          helperText={
            Boolean(errors.first_name) ? config.errors.requiredField : ''
          }
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextFieldComponent
          aria-label="purchase last name"
          config={config}
          disabled={disabled}
          name="last_name"
          placeholder="lastName"
          inputProps={{
            ref: register({
              required: true,
            }),
            'aria-label': 'last name',
          }}
          error={Boolean(errors.last_name)}
          helperText={
            Boolean(errors.last_name) ? config.errors.requiredField : ''
          }
        />
      </Grid>
      <Grid item xs={12}>
        <TextFieldComponent
          aria-label="purchase email"
          config={config}
          disabled={disabled}
          name="email"
          placeholder="email"
          inputProps={{
            ref: register({
              required: true,
            }),
            'aria-label': 'email',
          }}
          error={Boolean(errors.email)}
          helperText={Boolean(errors.email) ? config.errors.requiredField : ''}
        />
      </Grid>
      <Visible when={config.phoneNumber.enabled}>
        <Grid item xs={12}>
          <TextFieldComponent
            aria-label="purchase phone number"
            config={config}
            disabled={disabled}
            name="phone_number"
            placeholder="phone"
            inputProps={{
              ref: register({
                required: phoneNumberRequired,
              }),
              'aria-label': 'phone number',
            }}
            error={Boolean(errors.phone_number)}
            helperText={
              Boolean(errors.phone_number) ? config.errors.requiredField : ''
            }
          />
        </Grid>
      </Visible>
      <Grid item xs={12}>
        <FormButtonBox>
          <FormButtons>
            <ConfirmButton
              ButtonComponent={ButtonComponent}
              config={config}
              disabled={disabled}
              onNextStep={handleContinue}
            />
          </FormButtons>
        </FormButtonBox>
      </Grid>
    </Grid>
  )
}

function ConfirmButton(props: {
  ButtonComponent: React.FC<any>
  config: PurchaseFormBlock & BlockComponentProps
  disabled?: boolean
  onNextStep: () => void
}) {
  const {selectedTicket} = usePurchase()
  const {ButtonComponent, config, disabled, onNextStep: handleContinue} = props
  if (selectedTicket?.price === 0) {
    return <PurchaseButton ButtonComponent={ButtonComponent} {...config} />
  }

  return (
    <NextStepButton
      ButtonComponent={ButtonComponent}
      {...config}
      disabled={disabled}
      onClick={handleContinue}
    />
  )
}

import {QuestionTypeConfigProps} from 'organization/Event/Form/dialog/Form/QuestionTypeConfig'
import React from 'react'
import {Controller} from 'react-hook-form'
import {isRadioQuestion} from 'Event/Question/Radio'
import Switch from 'lib/ui/form/Switch'

export function RadioConfig(props: QuestionTypeConfigProps) {
  const {question, control} = props

  return (
    <Controller
      name="has_other_option"
      defaultValue={
        isRadioQuestion(question) ? question.has_other_option : false
      }
      control={control}
      render={({onChange, value}) => (
        <Switch
          checked={value}
          onChange={(e) => onChange(e.target.checked)}
          labelPlacement="end"
          aria-label="has other option"
          label={value ? 'Other Option Enabled' : 'Other Option Disabled'}
        />
      )}
    />
  )
}

/* eslint-disable no-undef */
import ButtonView from '@ckeditor/ckeditor5-ui/src/button/buttonview';
import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
// import LinkUI from '@ckeditor/ckeditor5-link/src/linkui';
import CustomButtonLinkUI from './custombuttonlinkui';

export default class CustomButtonMergeCodeLinks extends Plugin {
	init() {
		const editor = this.editor;
		const linkUI = editor.plugins.get( CustomButtonLinkUI );
		this.linkFormView = linkUI.formView;
		const mergeCode = editor.config.get( 'customButton.mergeCode' );

		if ( mergeCode ) {
			this.button = this._createButton( mergeCode );

			this.linkFormView.once( 'render', () => {
				// Render button's tamplate.
				this.button.render();

				// Register the button under the link form view, it will handle its destruction.
				this.linkFormView.registerChild( this.button );

				// Inject the element into DOM.
				this.linkFormView.element.insertBefore( this.button.element, this.linkFormView.saveButtonView.element );
			} );
		}
	}

	_createButton( label ) {
		const editor = this.editor;
		const button = new ButtonView( this.locale );
		const linkCommand = editor.commands.get( 'customButtonLink' );
		const that = this;
		button.set( {
			label,
			withText: true,
			tooltip: true
		} );

		button.bind( 'isEnabled' ).to( linkCommand );

		button.on( 'execute', () => {
			that.linkFormView.labeledInput.fieldView.element.value = label;
		} );

		return button;
	}
}

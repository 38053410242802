import Button, {ButtonProps} from '@material-ui/core/Button'
import React from 'react'
import red from '@material-ui/core/colors/red'
import styled from 'styled-components'

export default function DangerButton(
  props: ButtonProps & {className?: string},
) {
  const isContained = props.variant === 'contained'

  return (
    <StyledButton isContained={isContained} {...props}>
      {props.children}
    </StyledButton>
  )
}

const StyledButton = styled(
  (
    props: ButtonProps & {
      isContained: boolean
    },
  ) => {
    const {hidden: _a, isContained: _b, ...restProps} = props

    return <Button {...restProps} />
  },
)`
  color: ${(props) => (props.isContained ? '#ffffff' : red[500])};
  border-color: ${(props) => (props.isContained ? red[500] : red[300])};
  background: ${(props) => (props.isContained ? red[500] : 'transparent')};
  display: ${(props) => (props.hidden ? 'none' : 'flex')};

  &:hover {
    background-color: ${(props) =>
      props.isContained ? red[700] : 'transparent'};
    border-color: ${red[500]};
  }
`

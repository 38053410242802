import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import {spacing} from 'lib/ui/theme'
import GroupInput from 'organization/Event/Services/Apps/Mailchimp/Config/GroupsConfig/GroupInput'
import {MailchimpGroup} from 'organization/Event/Services/Apps/Mailchimp/index'
import Button from 'lib/ui/Button'
import Box from 'lib/ui/Box'
import {uuid} from 'lib/uuid'

export default function GroupsConfig(props: {
  groups: MailchimpGroup[]
  onChange: (groups: MailchimpGroup[]) => void
}) {
  const {onChange, groups} = props

  const addGroup = () => {
    const added = [
      ...groups,
      {
        id: uuid(),
        mailchimp_field_name: '',
        mailchimp_field_id: '',
        name: '',
      },
    ]
    onChange(added)
  }

  const updateGroup = (updated: MailchimpGroup) => {
    const updatedGroups = groups.map((group) => {
      const isTarget = group.id === updated.id
      if (isTarget) {
        return updated
      }

      return group
    })

    onChange(updatedGroups)
  }

  const removeGroup = (group: MailchimpGroup) => {
    const updatedGroups = groups.filter((g) => g.id !== group.id)

    onChange(updatedGroups)
  }

  return (
    <Box mb={2}>
      {groups.map((group, i) => (
        <GroupInput
          key={i}
          group={group}
          onChange={updateGroup}
          onRemove={removeGroup}
        />
      ))}
      <AddGroupButton
        aria-label="add group"
        variant="outlined"
        color="primary"
        onClick={addGroup}
        fullWidth
        size="large"
      >
        Add Group
      </AddGroupButton>
    </Box>
  )
}

const AddGroupButton = withStyles({
  root: {
    marginBottom: spacing[3],
  },
})(Button)

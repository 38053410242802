import React from 'react'
import styled from 'styled-components'
import {rgba} from 'lib/color'
import {useTownhallTemplate} from 'Event/template/Townhall'
import ActionNavConfig from 'Event/template/Townhall/Dashboard/ActionNav/ActionNavConfig'
import Buttons from 'Event/template/Townhall/Dashboard/ActionNav/Buttons'
import Configurable from 'organization/Event/Configurable'

export default function ActionNav() {
  const {actionNav} = useTownhallTemplate()
  const backgroundColor = rgba(
    actionNav.backgroundColor,
    actionNav.enabled ? actionNav.backgroundOpacity / 100 : 0,
  )

  return (
    <Configurable aria-label="edit action nav config">
      <ActionNavConfig />
      <StyledDiv position={actionNav.position}>
        <Card
          backgroundColor={backgroundColor}
          width={actionNav.width}
          border={actionNav.border}
          aria-label="action nav card "
        >
          <Buttons />
        </Card>
      </StyledDiv>
    </Configurable>
  )
}

const Card = styled.div<{
  backgroundColor: string
  width: number
  border: {
    color: string
    width: number
    radius: number
  }
}>`
  background-color: ${(props) => props.backgroundColor};
  color: ${(props) => props.color};
  width: 100%;
  border: ${(props) => `${props.border.width}px solid ${props.border.color}`};
  border-radius: ${(props) => `${props.border.radius}px`};

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    width: ${(props) => props.width || 100}%;
  }
`

const StyledDiv = styled.div<{position: 'right' | 'left'}>`
  display: flex;
  justify-content: ${(props) => props.position};
  margin-bottom: ${(props) => props.theme.spacing[6]};
`

import React, {useCallback, useEffect, useState} from 'react'
import styled from 'styled-components'
import {Controller} from 'react-hook-form'
import {Title} from 'lib/ui/typography'
import HtmlNotificationTextEditor from 'lib/ui/form/TextEditor/HtmllNotificationTextEditor'
import Button from 'lib/ui/Button'
import TextField from 'lib/ui/TextField'
import Header from 'lib/ui/layout/Header'
import Page from 'organization/Event/Page'
import {useValidatedForm} from 'lib/form'
import {useToggleArray} from 'lib/toggle'
import InputLabel from 'lib/ui/TextField/InputLabel'
import HelperText from 'lib/ui/TextField/HelperText'
import SendTestDialog from 'organization/Event/EmailConfig/SendTestDialog'
import VerifiedEmailField, {
  NAME_EMAIL_DELIMITER,
} from 'organization/Settings/CommunicationSettings/VerifiedEmailField'
import {CommunicationBreadcrumbs} from 'organization/Event/Page/PageBreadcrumbs'
import {communicationsApi} from 'lib/url'
import {client} from 'lib/ui/api-client'
import {EnabledSwitch} from 'lib/ui/form/Switch'
import {onChangeCheckedHandler} from 'lib/dom'
import {ObvioVariable} from 'lib/ui/form/TextEditor'
import EmailsProvider from 'organization/Settings/CommunicationSettings/EmailsProvider'
import {useEvent} from 'Event/EventProvider'
import {teamMemberClient} from 'obvio/obvio-client'
import FullPageLoader from 'lib/ui/layout/FullPageLoader'
import {useAsync} from 'lib/async'

export const WELCOME_EMAIL = 'welcome_email'
export const FORGOT_PASSWORD_EMAIL = 'forgot_password_email'
export const PURCHASE_CONFIRMATION = 'purchase_confirmation_email'

export type EmailType =
  | typeof WELCOME_EMAIL
  | typeof FORGOT_PASSWORD_EMAIL
  | typeof PURCHASE_CONFIRMATION

type UpdateEmailData = Omit<Email, 'id'>

export interface EmailConfigProps {
  type: EmailType
  title: string
  label: string
  customLinks?: string[]
  bodyVariables?: ObvioVariable[]
}

export interface Email {
  id: number
  is_enabled: boolean
  from_email: string
  from_name: string
  subject: string
  body: string
}

export default function EmailConfig(props: EmailConfigProps) {
  return (
    <EmailsProvider>
      <Content {...props} />
    </EmailsProvider>
  )
}

function Content(props: EmailConfigProps) {
  const {
    register,
    handleSubmit,
    control,
    errors,
    clearErrors,
    setResponseError,
  } = useValidatedForm<UpdateEmailData>()
  const [submitting, toggleSubmitting] = useToggleArray()
  const [testDialogOpen, setTestDialogOpen] = useState<boolean>(false)
  const {title, label, customLinks, bodyVariables, type} = props
  const [email, setEmail] = useState<Email | null>(null)
  const {loading, data} = useGetEmail(type)
  const createEmail = useCreateEmail(type)

  // Auto-create email if one hasn't been created
  useEffect(() => {
    if (email) {
      return
    }
    if (loading) {
      return
    }
    if (data) {
      setEmail(data)
      return
    }
    createEmail().then(setEmail)
  }, [data, loading, email, createEmail])

  if (!email) {
    return <FullPageLoader />
  }

  const submit = (
    data: Pick<
      UpdateEmailData,
      'body' | 'subject' | 'from_email' | 'is_enabled'
    >,
  ) => {
    if (submitting) {
      return
    }

    toggleSubmitting()

    // The value from the Select is "{name},{email}", because we're taking both
    // values from the already verified Organization Email listing. Before we
    // submit to the backend, we need to massage the {name},{email} into separate
    // fields for the payload.
    const selectedEmail = data.from_email || ''
    const [from_name, from_email] = selectedEmail.split(NAME_EMAIL_DELIMITER)

    const payload: UpdateEmailData = {
      subject: data.subject,
      body: data.body,
      from_name,
      from_email,
      is_enabled: data.is_enabled,
    }

    clearErrors()
    const url = communicationsApi(`/${type}s/${email.id}`)
    client
      .put<Email>(url, payload)
      .then(setEmail)
      .catch(setResponseError)
      .finally(toggleSubmitting)
  }

  return (
    <CommunicationBreadcrumbs page={title}>
      <Page>
        <SendTestDialog
          isOpen={testDialogOpen}
          onClose={() => setTestDialogOpen(false)}
          id={email.id}
          title={title}
          type={type}
        />

        <form onSubmit={handleSubmit(submit)}>
          <StyledHeader>
            <Title>{title}</Title>
            <div>
              <StyledTestButton
                variant="contained"
                color="primary"
                disabled={submitting}
                onClick={() => setTestDialogOpen(true)}
                type="button"
              >
                Send Test Email
              </StyledTestButton>
              <Button
                variant="contained"
                color="success"
                disabled={submitting}
                type="submit"
              >
                Save
              </Button>
            </div>
          </StyledHeader>
          <Controller
            name="is_enabled"
            defaultValue={email.is_enabled}
            control={control}
            render={({value, onChange}) => (
              <EnabledSwitch
                checked={value}
                onChange={onChangeCheckedHandler(onChange)}
                aria-label={'toggle ' + label}
              />
            )}
          />
          <VerifiedEmailField
            ControllerProps={{
              name: 'from_email',
              control,
            }}
            SelectProps={{
              label: 'From Email',
              error: Boolean(errors.from_email),
              helperText: errors.from_email,
              'aria-label': 'from email',
              disabled: submitting,
            }}
            defaultFromName={email.from_name}
            defaultFromEmail={email.from_email}
          />
          <TextField
            fullWidth
            name="subject"
            variant="outlined"
            disabled={submitting}
            defaultValue={email.subject}
            inputProps={{
              ref: register(),
              'aria-label': 'subject',
            }}
            label="Subject"
            error={!!errors.subject}
            helperText={errors.subject}
            required
          />
          <InputLabel>Body</InputLabel>
          <Controller
            name="body"
            defaultValue={email.body}
            control={control}
            render={({value, onChange}) => (
              <StyledTextEditor
                data={value}
                onChange={onChange}
                customLinks={customLinks}
                variables={bodyVariables}
              />
            )}
          />
          <HelperText error>{errors.body}</HelperText>
        </form>
      </Page>
    </CommunicationBreadcrumbs>
  )
}

export function useGetEmail(type: EmailType) {
  const {
    event: {id},
  } = useEvent()

  const request = useCallback(() => {
    const url = communicationsApi(`/events/${id}/${type}`)
    return teamMemberClient.get<Email>(url)
  }, [id, type])

  return useAsync(request)
}

function useCreateEmail(type: EmailType) {
  const {
    event: {id},
  } = useEvent()
  return useCallback(() => {
    const url = communicationsApi(`/events/${id}/${type}`)
    return teamMemberClient.post<Email>(url, {is_enabled: true})
  }, [id, type])
}

const StyledHeader = styled(Header)`
  flex-direction: column;

  h1 {
    margin-bottom: ${(props) => props.theme.spacing[2]};
  }

  @media (min-width: ${(props) => props.theme.breakpoints.sm}) {
    flex-direction: initial;

    h1 {
      margin-bottom: initial;
    }
  }
`

const StyledTestButton = styled(Button)`
  margin-right: ${(props) => props.theme.spacing[2]};
`

const StyledTextEditor = styled(HtmlNotificationTextEditor)`
  margin-bottom: ${(props) => props.theme.spacing[5]} !important;
`

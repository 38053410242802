import {PaymentMethod} from '@stripe/stripe-js'
import {api} from 'lib/url'
import {teamMemberClient} from 'obvio/obvio-client'
import {useQuery} from 'react-query'

export function usePaymentMethods() {
  return useQuery({
    queryKey: ['stripe', 'payment_methods'],
    queryFn: () =>
      teamMemberClient.get<Array<PaymentMethod>>(
        api(`/stripe/payment_methods`),
      ),
  })
}

import {useCardsTemplate} from 'Event/template/Cards'
import ColorPicker from 'lib/ui/ColorPicker'
import React from 'react'
import {Controller, UseFormMethods} from 'react-hook-form'
import Title from 'lib/ui/ConfigPanel/Title'
import Divider from 'lib/ui/ConfigPanel/Divider'

export default function Styling(props: {control: UseFormMethods['control']}) {
  const {control} = props
  const {footer} = useCardsTemplate()
  const {background, textColor} = footer

  return (
    <>
      <Title>Background</Title>
      <Controller
        control={control}
        name="background"
        defaultValue={background}
        render={({value, onChange}) => (
          <ColorPicker label="Color" color={value} onPick={onChange} />
        )}
      />
      <Divider />
      <Title>Typography</Title>
      <Controller
        control={control}
        name="textColor"
        defaultValue={textColor}
        render={({value, onChange}) => (
          <ColorPicker label="Color" color={value} onPick={onChange} />
        )}
      />
    </>
  )
}

import {useConfig} from 'organization/Event/Configurable'
import Config from 'organization/Event/Configurable/Config'
import {useUpdateTemplate} from 'organization/Marketplace/PurchasePageConfig/TemplateUpdateProvider'
import React, {useState} from 'react'
import {Controller} from 'react-hook-form'
import ImageAssetUploader from 'lib/asset/ImageAssetUploader'
import ColorPicker from 'lib/ui/ColorPicker'
import CollapsibleSection from 'lib/ui/ConfigPanel/CollapsibleSection'
import Slider from 'lib/ui/Slider'
import StylingPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/StylingPanel'
import Select from 'lib/ui/Select'
import Option from 'lib/ui/Select/Option'
import {onUnknownChangeHandler} from 'lib/dom'
import {REMOVE} from 'Event/TemplateUpdateProvider'
import VisibilitySetting from 'organization/Marketplace/PurchasePageConfig/BlockConfig/VisibilitySettings'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import {Label} from 'lib/ui/typography'
import FontSelect from 'lib/FontSelect'
import MarginPaddingInputs from 'lib/ui/MarginPaddingInputs'
import {NumberedListBlock} from 'Event/Marketplace/Block/NumberedList'

interface NumberedListConfigProps extends NumberedListBlock {
  id: string
  sectionId: string
}

export default function NumberedListConfig(props: NumberedListConfigProps) {
  const {
    padding,
    alignment,
    background,
    id,
    sectionId,
    font,
    fontSize,
    color,
    numberSpace,
    textSpace,
    border,
    counter,
  } = props
  const updateTemplate = useUpdateTemplate()
  const {
    form: {control},
  } = useConfig()
  const [visibility, setVisibility] = useState(props.visibility)

  const handleRemove = () => {
    updateTemplate({
      sections: {
        [sectionId]: {
          blocks: {
            [id]: REMOVE,
          },
        },
      },
    })
  }

  const save = (data: any) => {
    data.sections[sectionId].blocks[id].visibility = visibility
    updateTemplate(data)
  }
  return (
    <Config title="Number List" onSave={save} onRemove={handleRemove}>
      <SettingsPanel>
        <Controller
          name={`sections.${sectionId}.blocks.${id}.counter.style`}
          defaultValue={counter.style}
          control={control}
          render={({value, onChange}) => (
            <Select
              fullWidth
              label="List Counter"
              value={value}
              onChange={onUnknownChangeHandler(onChange)}
            >
              <Option value="numeric">Numeric</Option>
              <Option value="alphabetical">Alphabetical</Option>
              <Option value="roman">Roman Numerals</Option>
            </Select>
          )}
        />

        <Controller
          name={`sections.${sectionId}.blocks.${id}.counter.outline`}
          defaultValue={counter.outline}
          control={control}
          render={({value, onChange}) => (
            <Select
              fullWidth
              label="Counter Outline"
              value={value}
              onChange={onUnknownChangeHandler(onChange)}
            >
              <Option value="none">None</Option>
              <Option value="circle">Circle</Option>
              <Option value="square">Square</Option>
            </Select>
          )}
        />

        <VisibilitySetting
          visibility={visibility}
          setVisibility={setVisibility}
        />
      </SettingsPanel>

      <StylingPanel>
        <CollapsibleSection
          label="Typography"
          storageKey={`blocks-${id}-typography`}
        >
          <Label>Font</Label>
          <Controller
            control={control}
            name={`sections.${sectionId}.blocks.${id}.font`}
            defaultValue={font}
            render={({value, onChange}) => (
              <FontSelect value={value} onChange={onChange} />
            )}
          />
          <Controller
            name={`sections.${sectionId}.blocks.${id}.fontSize`}
            defaultValue={fontSize}
            control={control}
            render={({value, onChange}) => (
              <Slider
                min={1}
                max={100}
                onChange={onChange}
                valueLabelDisplay="off"
                value={value}
                label="Font Size"
              />
            )}
          />
          <Controller
            name={`sections.${sectionId}.blocks.${id}.color`}
            control={control}
            defaultValue={color}
            render={({value, onChange}) => (
              <ColorPicker label="Color" color={value} onPick={onChange} />
            )}
          />
        </CollapsibleSection>

        <CollapsibleSection
          label="Counter Style"
          storageKey={`blocks-${id}-typography`}
        >
          <Label>Font</Label>
          <Controller
            control={control}
            name={`sections.${sectionId}.blocks.${id}.counter.font`}
            defaultValue={counter.font}
            render={({value, onChange}) => (
              <FontSelect value={value} onChange={onChange} />
            )}
          />
          <Controller
            name={`sections.${sectionId}.blocks.${id}.counter.fontSize`}
            defaultValue={counter.fontSize}
            control={control}
            render={({value, onChange}) => (
              <Slider
                min={1}
                max={100}
                onChange={onChange}
                valueLabelDisplay="off"
                value={value}
                label="Font Size"
              />
            )}
          />
          <Controller
            name={`sections.${sectionId}.blocks.${id}.counter.color`}
            control={control}
            defaultValue={counter.color}
            render={({value, onChange}) => (
              <ColorPicker label="Font Color" color={value} onPick={onChange} />
            )}
          />
          <Controller
            name={`sections.${sectionId}.blocks.${id}.counter.background.color`}
            defaultValue={counter.background.color}
            control={control}
            render={({value, onChange}) => (
              <ColorPicker
                label="Background Color"
                color={value}
                onPick={onChange}
                aria-label="block background color"
              />
            )}
          />
          <Controller
            name={`sections.${sectionId}.blocks.${id}.counter.background.opacity`}
            defaultValue={counter.background.opacity}
            control={control}
            render={({value, onChange}) => (
              <Slider
                label="Background Opacity"
                unit="%"
                valueLabelDisplay="auto"
                aria-label="block background opacity"
                value={value}
                onChange={onChange}
                step={1}
                min={0}
                max={100}
              />
            )}
          />
        </CollapsibleSection>

        <CollapsibleSection
          label="Number Spacing"
          storageKey={`blocks-${id}-number-spacing`}
        >
          <Controller
            name={`sections.${sectionId}.blocks.${id}.numberSpace`}
            defaultValue={numberSpace}
            control={control}
            render={({value, onChange}) => (
              <Slider
                min={0}
                max={50}
                onChange={onChange}
                valueLabelDisplay="off"
                value={value}
                label="Space Between Numbers"
              />
            )}
          />
          <Controller
            name={`sections.${sectionId}.blocks.${id}.textSpace`}
            defaultValue={textSpace}
            control={control}
            render={({value, onChange}) => (
              <Slider
                min={0}
                max={50}
                onChange={onChange}
                valueLabelDisplay="off"
                value={value}
                label="Text Indent"
              />
            )}
          />
        </CollapsibleSection>

        <CollapsibleSection
          label="Border"
          noDivider
          storageKey={`blocks-${id}-border`}
        >
          <Controller
            name={`sections.${sectionId}.blocks.${id}.border.width`}
            defaultValue={border.width}
            control={control}
            render={({value, onChange}) => (
              <Slider
                min={0}
                max={50}
                step={1}
                onChange={onChange}
                value={value}
                label="Border Thickness"
              />
            )}
          />
          <Controller
            name={`sections.${sectionId}.blocks.${id}.border.radius`}
            defaultValue={border.radius}
            control={control}
            render={({value, onChange}) => (
              <Slider
                min={0}
                max={50}
                step={1}
                onChange={onChange}
                value={value}
                label="Radius"
              />
            )}
          />
          <Controller
            name={`sections.${sectionId}.blocks.${id}.border.color`}
            defaultValue={border.color}
            control={control}
            render={({value, onChange}) => (
              <ColorPicker
                label="Border Color"
                color={value}
                onPick={onChange}
              />
            )}
          />
        </CollapsibleSection>

        <CollapsibleSection
          label="Background"
          storageKey={`blocks-${id}-background`}
        >
          <Controller
            name={`sections.${sectionId}.blocks.${id}.background.image`}
            control={control}
            defaultValue={background.image}
            render={({onChange, value}) => (
              <ImageAssetUploader
                onChange={onChange}
                value={value}
                uploadLabel="Upload"
                uploadInputProps={{
                  'aria-label': 'block background image',
                }}
                width={1920}
                height={1200}
                canResize
                disableAutoRemove
              />
            )}
          />
          <Controller
            name={`sections.${sectionId}.blocks.${id}.background.color`}
            defaultValue={background.color}
            control={control}
            render={({value, onChange}) => (
              <ColorPicker
                label="Color"
                color={value}
                onPick={onChange}
                aria-label="block background color"
              />
            )}
          />
          <Controller
            name={`sections.${sectionId}.blocks.${id}.background.opacity`}
            defaultValue={background.opacity}
            control={control}
            render={({value, onChange}) => (
              <Slider
                label="Opacity"
                unit="%"
                valueLabelDisplay="auto"
                aria-label="block background opacity"
                value={value}
                onChange={onChange}
                step={1}
                min={0}
                max={100}
              />
            )}
          />
        </CollapsibleSection>
        <CollapsibleSection
          label="Alignment"
          storageKey={`blocks-${id}-alignment`}
        >
          <Controller
            name={`sections.${sectionId}.blocks.${id}.alignment.vertical`}
            defaultValue={alignment.vertical}
            control={control}
            render={({value, onChange}) => (
              <Select
                fullWidth
                label="Vertical"
                value={value}
                onChange={onUnknownChangeHandler(onChange)}
              >
                <Option value="start">Top</Option>
                <Option value="center">Center</Option>
                <Option value="end">Bottom</Option>
              </Select>
            )}
          />
          <Controller
            name={`sections.${sectionId}.blocks.${id}.alignment.horizontal`}
            defaultValue={alignment.horizontal}
            control={control}
            render={({value, onChange}) => (
              <Select
                fullWidth
                label="Horizontal"
                value={value}
                onChange={onUnknownChangeHandler(onChange)}
              >
                <Option value="left">Left</Option>
                <Option value="center">Center</Option>
                <Option value="right">Right</Option>
              </Select>
            )}
          />
        </CollapsibleSection>
        <CollapsibleSection
          label="Advanced Spacing"
          storageKey={`blocks-${id}-spacing`}
        >
          <Label>Padding</Label>
          <MarginPaddingInputs
            control={control}
            namePrefix={`sections.${sectionId}.blocks.${id}.padding`}
            values={padding}
          />
        </CollapsibleSection>
      </StylingPanel>
    </Config>
  )
}

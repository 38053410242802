import React from 'react'
import {User} from 'auth/user'
import Page from 'Event/template/SimpleBlog/Page'
import styled from 'styled-components'
import {Link} from 'react-router-dom'
import SpeakerList from 'Event/template/SimpleBlog/Speakers/SpeakerList'
import {PageTitle, PageDescription} from 'Event/template/SimpleBlog/Page'
import {useAttendeeVariables} from 'Event'
import {useSimpleBlogTemplate} from 'Event/template/SimpleBlog'
import Content from 'lib/ui/form/TextEditor/Content'
import Configurable from 'organization/Event/Configurable'
import HeaderConfig from 'Event/Speakers/HeaderConfig'
import AddSpeakerButton from 'Event/Speakers/AddSpeakerButton'
import DashboardLinkConfig from 'Event/template/SimpleBlog/Speakers/DashboardLinkConfig'

export default function SimpleBlogSpeakerPage(props: {user: User}) {
  const {speakers: speakerPageSettings} = useSimpleBlogTemplate()
  const v = useAttendeeVariables()

  return (
    <Page user={props.user}>
      <Configurable>
        <HeaderConfig />
        <PageTitle aria-label="speakers title">
          {v(speakerPageSettings.title)}
        </PageTitle>
      </Configurable>
      <PageDescription>
        <Content aria-label="speakers description">
          {v(speakerPageSettings?.description)}
        </Content>
      </PageDescription>
      <Configurable>
        <DashboardLinkConfig />
        <BackToDashboard color={speakerPageSettings.backToDashboardTextColor}>
          <Link to="/">{v(speakerPageSettings.backToDashboardText)}</Link>
        </BackToDashboard>
      </Configurable>
      <SpeakerList />
      <AddSpeakerButton />
    </Page>
  )
}

const BackToDashboard = styled.div`
  text-align: center;
  margin-bottom: 20px;
  a {
    line-height: 1.5;
    color: ${(props) => props.color};
  }
`

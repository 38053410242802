import React from 'react'
import Page, {
  DescriptionText,
  StyledFormContainer,
} from 'Event/template/Townhall/Login/Page'
import {useGuestVariables} from 'Event'
import {TitleBase} from 'Event/template/Townhall/Login/Title'
import Configurable from 'organization/Event/Configurable'
import OfflineContentConfig from 'Event/EventOfflinePage/OfflineContentConfig'
import {OfflinePageProps} from 'Event/JoinArea/OfflinePage'

export default function EventOfflinePage(props: OfflinePageProps) {
  const v = useGuestVariables()

  return (
    <Page isPreview={props.isPreview || false}>
      <Configurable aria-label="event offline content config">
        <OfflineContentConfig />
        <StyledFormContainer>
          <TitleBase>{v(props.title)}</TitleBase>
          <DescriptionText>
            <div dangerouslySetInnerHTML={{__html: v(props.description)}} />
          </DescriptionText>
        </StyledFormContainer>
      </Configurable>
    </Page>
  )
}

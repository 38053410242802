import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import Button from '@material-ui/core/Button'
import {Room} from 'Event/room'
import {api} from 'lib/url'
import {useOrganization} from 'organization/OrganizationProvider'
import ErrorAlert from 'lib/ui/alerts/ErrorAlert'
import SuccessAlert from 'lib/ui/alerts/SuccessAlert'
import {useGet} from 'lib/requests'
import Menu from 'lib/ui/Menu'
import MenuItem from '@material-ui/core/MenuItem'

export default function ExportRoomAttendees(props: {room: Room}) {
  const [error, setError] = useState<string | null>(null)
  const [success, setSuccess] = useState<string | null>(null)

  return (
    <div>
      <Menu
        button={({open}) => (
          <Button
            color="primary"
            variant="outlined"
            aria-label="export log"
            onClick={open}
          >
            Export Log
          </Button>
        )}
      >
        <ExportMenuItem
          room={props.room}
          isSummary
          setError={setError}
          setSuccess={setSuccess}
        />
        <ExportMenuItem
          room={props.room}
          setError={setError}
          setSuccess={setSuccess}
        />
      </Menu>
      <StyledErrorAlert>{error}</StyledErrorAlert>
      <StyledSuccessAlert>{success}</StyledSuccessAlert>
    </div>
  )
}

const ExportMenuItem = React.forwardRef<
  HTMLLIElement,
  {
    room: Room
    isSummary?: boolean
    setError: (error: string | null) => void
    setSuccess: (success: string | null) => void
  }
>((props, ref) => {
  const {room, isSummary, setError, setSuccess} = props

  const ariaLabel = isSummary ? 'export summary log' : 'export detail log'
  const buttonText = isSummary ? 'Export Summary Log' : 'Export Detail Log'

  const {exportLog, processing, errorMessage, successMessage} = useExportLog(
    room,
    isSummary,
  )

  useEffect(() => {
    setError(errorMessage)
    setSuccess(successMessage)
  }, [errorMessage, setError, setSuccess, successMessage])

  return (
    <MenuItem
      disabled={processing}
      onClick={exportLog}
      aria-label={ariaLabel}
      ref={ref}
    >
      {buttonText}
    </MenuItem>
  )
})

function useExportLog(room: Room, isSummary?: boolean) {
  const exportMode = isSummary ? 'summary' : 'detail'
  const url = api(`/rooms/${room.id}/attendees/export?exportMode=${exportMode}`)
  const {client} = useOrganization()

  const {send: exportLog, processing, errorMessage, successMessage} = useGet(
    client,
    url,
  )

  return {
    exportLog,
    processing,
    errorMessage,
    successMessage,
  }
}

const StyledErrorAlert = styled(ErrorAlert)`
  margin-top: ${(props) => props.theme.spacing[2]};
`

const StyledSuccessAlert = styled(SuccessAlert)`
  margin-top: ${(props) => props.theme.spacing[2]};
`

import {useTemplate} from 'Event/TemplateProvider'
import {useSaveTemplate} from 'Event/TemplateUpdateProvider'
import {useConfig} from 'organization/Event/Configurable'
import Config from 'organization/Event/Configurable/Config'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import StylingPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/StylingPanel'
import React from 'react'
import Settings from 'Event/Step3/TechCheck/Buttons/StartButton/StartButtonConfig/Settings'
import Styling from 'Event/Step3/TechCheck/Buttons/StartButton/StartButtonConfig/Styling'

export default function StartButtonConfig() {
  const save = useSaveTemplate()
  const {techCheck} = useTemplate()
  const {
    form: {register, watch, control},
  } = useConfig()

  const submit = (data: any) => {
    save({
      techCheck: {
        startButton: data,
      },
    })
  }

  // Need to tell Config we're updating techCheck.startButton explicitly, because
  // the input names are just `text`, `link`. Without specifing the values here,
  // useAutoUpdate() would mess up previews.
  const current = {
    techCheck: {
      startButton: watch(),
    },
  }

  return (
    <Config title="Submit Button" onSave={submit} preview={current}>
      <SettingsPanel>
        <Settings
          register={register}
          button={techCheck.startButton}
          control={control}
          watch={watch}
        />
      </SettingsPanel>
      <StylingPanel>
        <Styling
          control={control}
          button={techCheck.startButton}
          watch={watch}
        />
      </StylingPanel>
    </Config>
  )
}

import EmailInputs from 'organization/Event/Broadcasts/BroadcastForm/ChannelInputs/EmailInputs'
import React from 'react'
import {useBroadcastForm} from 'organization/Event/Broadcasts/BroadcastForm'
import BodyInput from 'organization/Event/Broadcasts/BroadcastForm/ChannelInputs/SmsInputs/BodyInput'

export default function ChannelInput() {
  const {channel} = useBroadcastForm()

  switch (channel) {
    case 'email':
      return <EmailInputs />
    case 'sms':
      return <BodyInput /> // No SMS specific inputs
    default:
      throw new Error(`Unhandled channel inputs for channel: ${channel}`)
  }
}

import React from 'react'
import {FileLocation} from 'lib/http-client'
import Skeleton from '@material-ui/lab/Skeleton'
import ConnectedAccountDetails from 'organization/Settings/MarketplaceConnectForm/ConnectedAccountDetails'
import {useMarketplaceAccount} from 'lib/marketplace-api/accounts/use-marketplace-account'
import ConnectAccountForm from 'organization/Settings/MarketplaceConnectForm/ConnectAccountForm'

export interface Data {
  name: string
  slug: string
  logo: FileLocation | null
}

export default function MarketplaceConnectForm() {
  const {data: account, isLoading} = useMarketplaceAccount()

  if (isLoading || account === undefined) {
    return <Skeleton variant="rect" width="100%" height={256} />
  }

  if (account?.completed_setup) {
    return <ConnectedAccountDetails />
  }

  return <ConnectAccountForm />
}

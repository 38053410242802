import {Script} from 'Event/Scripts'
import {REMOVE, useSaveTemplate} from 'Event/TemplateUpdateProvider'
import ConfirmDialog from 'lib/ui/ConfirmDialog'
import MenuItem from 'lib/ui/Menu/MenuItem'
import {api} from 'lib/url'
import {useOrganization} from 'organization/OrganizationProvider'
import React from 'react'

export default function DeleteMenuItem(props: {script: Script; id: string}) {
  const {script, id} = props
  const remove = useRemoveScript(script)
  const saveTemplate = useSaveTemplate()

  const handleDeleteBroadcast = () =>
    remove().then(() => {
      saveTemplate({
        scripts: {
          [id]: REMOVE,
        },
      })
    })

  return (
    <ConfirmDialog
      onConfirm={handleDeleteBroadcast}
      description="Deleting a Embed Script cannot be undone."
    >
      {(confirm) => <MenuItem onClick={confirm}>Delete</MenuItem>}
    </ConfirmDialog>
  )
}

function useRemoveScript(script: Script) {
  const {client} = useOrganization()
  const url = api(`/scripts/${script.file}`)
  return () => client.delete(url)
}

import ColorPicker from 'lib/ui/ColorPicker'
import React from 'react'
import {SimpleBlog} from 'Event/template/SimpleBlog'
import {Controller, UseFormMethods} from 'react-hook-form'

export default function Styling(props: {
  footer: SimpleBlog['footer']
  control: UseFormMethods['control']
}) {
  const {footer, control} = props

  return (
    <>
      <Controller
        name="background"
        control={control}
        defaultValue={footer.background}
        render={({value, onChange}) => (
          <ColorPicker
            label="Background Color"
            color={value}
            onPick={onChange}
          />
        )}
      />
      <Controller
        name="textColor"
        control={control}
        defaultValue={footer.textColor}
        render={({value, onChange}) => (
          <ColorPicker label="Text Color" color={value} onPick={onChange} />
        )}
      />
    </>
  )
}

import React, {useState} from 'react'
import ComponentConfig, {
  ComponentConfigProps,
  SaveButton,
  Footer,
} from 'organization/Event/DashboardConfig/ComponentConfigPanel'
import {useForm} from 'react-hook-form'
import {ResourceListProps} from 'Event/template/Cards/Dashboard/Sidebar/SidebarItem/ResourceList'
import {
  useEditSidebarItem,
  useAutoUpdateSidebarItem,
} from 'Event/template/Cards/Dashboard/Sidebar/SidebarItem'
import {Rule} from 'Event/attendee-rules'
import Settings from 'Event/template/Cards/Dashboard/Sidebar/SidebarItem/ResourceList/ResourceListConfig/Settings'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import RulesPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/RulesPanel'
import Styling from 'Event/template/Cards/Dashboard/Sidebar/SidebarItem/ResourceList/ResourceListConfig/Styling'
import StylingPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/StylingPanel'

export default function ResourceListConfig(
  props: ComponentConfigProps & {
    list: ResourceListProps
  },
) {
  const {showing, onClose, list} = props
  const {handleSubmit, control, watch, formState} = useForm()
  const [rules, setRules] = useState<Rule[]>(list.rules || [])

  const {update} = useEditSidebarItem()

  const save = (data: Pick<ResourceListProps, 'title' | 'description'>) => {
    update({...data, rules})
    onClose()
  }

  useAutoUpdateSidebarItem({item: watch(), when: showing})

  return (
    <ComponentConfig
      showing={showing}
      onClose={onClose}
      title="Resources"
      onSubmit={handleSubmit(save)}
      hasChanges={formState.isDirty}
    >
      <SettingsPanel>
        <Settings control={control} list={list} />
      </SettingsPanel>
      <StylingPanel>
        <Styling control={control} list={list} />
      </StylingPanel>
      <RulesPanel rules={rules} setRules={setRules} />
      <Footer>
        <SaveButton type="submit" />
      </Footer>
    </ComponentConfig>
  )
}

import {useEvent} from 'Event/EventProvider'
import {Room} from 'Event/room'
import {useAsync} from 'lib/async'
import {withDefaults} from 'lib/object'
import {DeepRequired} from 'lib/type-utils'
import FullPageLoader from 'lib/ui/layout/FullPageLoader'
import {api, useQueryParams} from 'lib/url'
import {useOrganization} from 'organization/OrganizationProvider'
import React from 'react'
import {useCallback} from 'react'

/**
 * Load Balance Strategies. These are the same values as those
 * in the API (load_balance_strategies table).
 */
export const BALANCED = 'balanced'
export const OVERFLOW = 'overflow'

export const LOAD_BALANCE_STRATEGIES: LoadBalanceStrategy[] = [
  BALANCED,
  OVERFLOW,
]

type LoadBalanceStrategy = typeof BALANCED | typeof OVERFLOW

export interface Area {
  id: number
  name: string
  is_open: boolean
  requires_approval: boolean
  allows_multiple_devices: boolean
  rooms: Room[]
  is_tech_check: boolean
  offline_title: string | null
  offline_description: string | null
  reassign_on_offline: boolean
  has_registration: boolean
  registration_url: string | null
  key: string
  load_balance_strategy: LoadBalanceStrategy
  template: null | {
    description?: string
    firstNameLabel?: string
    lastNameLabel?: string
    emailLabel?: string
    joinButtonText?: string
    missingRegistrationAttendeeMessage?: string
    hasPhoneNumberField?: boolean
    phoneNumberLabel?: string
    phoneNumberRequired?: boolean
  }
  register_existing_attendees_only: boolean
}

export type AreaWithTemplate = Omit<Area, 'template'> & {
  template: NonNullable<DeepRequired<Area['template']>>
}

const DEFAULT_TEMPLATE: NonNullable<DeepRequired<Area['template']>> = {
  firstNameLabel: 'First name',
  lastNameLabel: 'Last Name',
  emailLabel: 'Email',
  joinButtonText: 'Join area',
  hasPhoneNumberField: false,
  phoneNumberLabel: 'Phone number',
  phoneNumberRequired: false,
  description: '',
  missingRegistrationAttendeeMessage: 'Attendee Not Found',
}

export interface AreasContextProps {
  areas: Area[]
  loading: boolean
}

export const AreasContext = React.createContext<AreasContextProps | undefined>(
  undefined,
)

export default function AreasProvider(props: {children: React.ReactElement}) {
  const fetch = useFetchAreas()

  const {data: areas, loading} = useAsync(fetch)

  if (loading || !areas) {
    return <FullPageLoader />
  }

  return (
    <AreasContext.Provider
      value={{
        areas,
        loading,
      }}
    >
      {props.children}
    </AreasContext.Provider>
  )
}

export function useFetchAreas() {
  const {client} = useOrganization()
  const {event} = useEvent()
  const {id} = event

  return useCallback(() => {
    const url = api(`/events/${id}/areas`)
    return client.get<Area[]>(url)
  }, [client, id])
}

export function useAreas() {
  const context = React.useContext(AreasContext)
  if (context === undefined) {
    throw new Error('useAreas must be used within an AreasProvider')
  }

  return context
}

export function useFindAreaByToken(): AreaWithTemplate | null {
  const {token} = useQueryParams()
  const {client} = useEvent()
  const url = api(`/area?access_token=${token}`)
  const request = useCallback(() => client.get<Area>(url), [url, client])

  const {data: area, loading} = useAsync(request)
  if (loading || !area) {
    return null
  }

  const template = withDefaults(DEFAULT_TEMPLATE, area.template || {})

  return {
    ...area,
    template,
  }
}

import {useSetEditMode} from 'Event/EditModeProvider'
import styled from 'styled-components'
import ConfigBar from 'organization/Event/TemplateEditor/ConfigBar'
import React, {useEffect} from 'react'

export default function Page(props: {
  children: JSX.Element
  toolbarItems?: JSX.Element | JSX.Element[]
}) {
  const setEditMode = useSetEditMode()

  useEffect(() => {
    setEditMode(true)
  }, [setEditMode])

  return (
    <FullHeight>
      <ConfigBar>{props.toolbarItems}</ConfigBar>
      <Body>{props.children}</Body>
    </FullHeight>
  )
}

// In case certain child pages have full height/width via
// position absolute, we'll wrap the content in a
// relative parent.

const FullHeight = styled.div`
  display: flex;
  flex-direction: column;
`

const Body = styled.div`
  position: relative;
  flex: 1;
`

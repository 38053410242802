import {fontStyles, useLoadFont} from 'lib/FontSelect'
import React from 'react'
import RadioGroup from '@material-ui/core/RadioGroup'
import {formatPrice} from 'lib/currency'
import MuiRadio from '@material-ui/core/Radio'
import withStyles from '@material-ui/core/styles/withStyles'
import styled from 'styled-components'
import Icon from 'lib/ui/Icon'
import Container from 'Event/Marketplace/Block/Container'
import {usePurchase} from 'Event/Marketplace/PurchaseProvider'
import {MarketplaceTicket} from 'lib/marketplace-api/tickets'
import {BlockBase} from '../base'
import {Font} from '../../lib/font'
import {BlockComponentProps} from 'Event/Marketplace/Block'

export interface TicketSelectorBlock extends BlockBase {
  type: 'TicketSelector'
  name: {
    color: string
    opacity: number
    font: null | Font
    fontSize: number
  }
  description: {
    color: string
    opacity: number
    font: null | Font
    fontSize: number
    enabled: boolean
  }
  spacing: number
  selectorIcon: 'circle' | 'x' | 'hand' | 'ticket'
}

interface TicketSelectorProps
  extends TicketSelectorBlock,
    BlockComponentProps {}

export default function TicketSelector(props: TicketSelectorProps) {
  const {name, description, spacing, id} = props
  const {selectedTicket, setSelectedTicket, tickets} = usePurchase()

  useLoadFont(name.font)
  useLoadFont(description.font)

  const selectTicket = (id: number) => {
    const target = tickets?.find((ticket) => ticket.id === id)

    if (!target) {
      return
    }

    setSelectedTicket(target)
  }

  return (
    <Container {...props}>
      <Content id={id}>
        <RadioGroup>
          {tickets.map((ticket) => (
            <Ticket key={ticket.id} spacing={spacing}>
              <Body onClick={() => selectTicket(ticket.id)}>
                <StyledRadio
                  disableRipple
                  color="default"
                  checked={ticket.id === selectedTicket?.id}
                  checkedIcon={<RadioIcon {...props} checked />}
                  icon={<RadioIcon {...props} />}
                  aria-label="ticket"
                />
                <Name {...props}>
                  {ticket.name} - ${formatPrice(ticket.price)}
                </Name>
              </Body>
              <Description ticket={ticket} {...props} />
            </Ticket>
          ))}
        </RadioGroup>
      </Content>
    </Container>
  )
}

function RadioIcon(props: TicketSelectorBlock & {checked?: boolean}) {
  const {checked, selectorIcon, ...ticketSelectorProps} = props

  switch (selectorIcon) {
    case 'circle':
      return (
        <RadioIconBox
          {...ticketSelectorProps}
          checked={checked}
          selectorIcon={selectorIcon}
        >
          {checked ? (
            <Icon className="fa-sharp fa-solid fa-circle-check" iconSize={18} />
          ) : (
            <Icon
              className="fa-sharp fa-regular fa-circle-check"
              iconSize={18}
            />
          )}
        </RadioIconBox>
      )
    case 'x':
      return (
        <RadioIconBox
          {...ticketSelectorProps}
          checked={checked}
          selectorIcon={selectorIcon}
        >
          {checked ? (
            <Icon className="fa-solid fa-circle-xmark" iconSize={18} />
          ) : (
            <Icon className="fa-regular fa-circle-xmark" iconSize={18} />
          )}
        </RadioIconBox>
      )
    case 'hand':
      return (
        <RadioIconBox
          {...ticketSelectorProps}
          checked={checked}
          selectorIcon={selectorIcon}
        >
          {checked ? (
            <Icon className="fa-solid fa-hand-point-right" iconSize={18} />
          ) : (
            <Icon className="fa-light fa-hand-point-right" iconSize={18} />
          )}
        </RadioIconBox>
      )
    case 'ticket':
      return (
        <RadioIconBox
          {...ticketSelectorProps}
          checked={checked}
          selectorIcon={selectorIcon}
        >
          {checked ? (
            <Icon className="fa-solid fa-ticket" iconSize={18} />
          ) : (
            <Icon className="fa-light fa-ticket" iconSize={18} />
          )}
        </RadioIconBox>
      )
    default:
      throw new Error('Missing ticket selector icon')
  }
}

function Description(props: TicketSelectorBlock & {ticket: MarketplaceTicket}) {
  const {description, ticket} = props

  if (!description.enabled) {
    return null
  }

  return <StyledDescription {...props}>{ticket.description}</StyledDescription>
}

const Content = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`

const Ticket = styled.div<{
  spacing: number
}>`
  margin-bottom: ${(props) => props.spacing}px;
  &:last-child {
    margin-bottom: 0 !important;
  }
`

const Body = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`

const StyledRadio = withStyles({
  root: {
    padding: 0,
  },
})(MuiRadio)

const Name = styled.span<TicketSelectorBlock>`
  ${(props) => fontStyles(props.name.font)}
  color: ${(props) => props.name.color};
  font-size: ${(props) => props.name.fontSize}px;
  opacity: ${(props) => props.name.opacity / 100};
  margin: 0 0 0 24px;
`

const StyledDescription = styled.p<TicketSelectorBlock>`
  ${(props) => fontStyles(props.description.font)}
  margin: 8px 0 0 44px;
  color: ${(props) => props.description.color};
  font-size: ${(props) => props.description.fontSize}px;
  opacity: ${(props) => props.description.opacity / 100};
`

const RadioIconBox = styled.div<TicketSelectorBlock & {checked?: boolean}>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: none;
  background-color: transparent;
`

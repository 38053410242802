import {User} from 'auth/user'
import Dashboard from 'Event/template/NiftyFifty/Dashboard'
import React, {useEffect} from 'react'
import {useHistory} from 'react-router-dom'

export default function Speakers(props: {user: User}) {
  const history = useHistory()

  // NiftyFifty has tab nav rather than pages, so we'll pre-select the speakers
  // tab via url.
  useEffect(() => {
    history.push({
      search: 'tab=speakers',
    })
  }, [history])

  return <Dashboard user={props.user} />
}

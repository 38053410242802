export type FontStyle = 'bold' | 'underline' | 'italic' | 'caps'

export type Font = {
  family: string
  variants: string[]
  styles: FontStyle[]
}

export const getFontStyles = (font: Font | null): React.CSSProperties => {
  if (!font) {
    return {}
  }

  const styles = font.styles || []

  return {
    fontWeight: styles.includes('bold') ? 'bold' : 'normal',
    fontFamily: font.family,
    fontStyle: styles.includes('italic') ? 'italic' : 'normal',
    textTransform: styles.includes('caps') ? 'uppercase' : 'none',
  }
}

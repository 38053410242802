import styled from 'styled-components'
import React from 'react'
import {DragDropContext, Droppable, DropResult} from 'react-beautiful-dnd'
import TableCell from '@material-ui/core/TableCell'
import Table from '@material-ui/core/Table'
import TableRow from '@material-ui/core/TableRow'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import {useTemplate} from 'Event/TemplateProvider'
import {createPositions, orderedIdsByPosition} from 'lib/list'
import WaiverRow from 'organization/Event/WaiverConfig/AdditionalWaivers/WaiversTable/WaiverRow'
import {useSaveTemplate} from 'Event/TemplateUpdateProvider'

export default function WaiversTable() {
  const {
    waiver: {targets},
  } = useTemplate()

  const ids = orderedIdsByPosition(targets)

  const handleDrag = useHandleDrag()

  const hasAdditionalWaivers = ids.length > 1 // 1 default + any additional
  if (!hasAdditionalWaivers) {
    return <p>No additional waivers have been added</p>
  }

  const [_, ...additionalIds] = ids

  return (
    <DragDropContext onDragEnd={handleDrag}>
      <StyledTable>
        <TableHead>
          <TableRow>
            <PriorityCell>Priority</PriorityCell>
            <TitleCell>Title</TitleCell>
            <RulesCell>Rules</RulesCell>
          </TableRow>
        </TableHead>
        <Droppable droppableId="additional_waivers">
          {(provided) => (
            <TableBody {...provided.droppableProps} ref={provided.innerRef}>
              {additionalIds.map((id, index) => (
                <WaiverRow
                  id={id}
                  index={index}
                  key={id}
                  waiver={targets[id]}
                />
              ))}
              {provided.placeholder}
            </TableBody>
          )}
        </Droppable>
      </StyledTable>
    </DragDropContext>
  )
}

function useHandleDrag() {
  const save = useSaveTemplate()
  const {
    waiver: {targets},
  } = useTemplate()

  return (result: DropResult) => {
    const {destination, source} = result

    if (!destination) {
      return
    }

    const ids = orderedIdsByPosition(targets)

    const moved = Array.from(ids)

    // Since the first (0) is always the default waiver which is not
    // shown in the draggable table, we'll need to offset every
    // index.
    const indexOffset = 1

    const [removed] = moved.splice(source.index + indexOffset, 1)
    moved.splice(destination.index + indexOffset, 0, removed)

    save({
      waiver: {
        targets: createPositions(moved),
      },
    })
  }
}

const StyledTable = styled(Table)`
  table-layout: fixed;
`

/**
 * Cells must have explicity widths, otherwise they will
 * shrink when being 'dragged'. Also need to set row
 * display to 'table' while dragging.
 *
 * Reference: https://github.com/atlassian/react-beautiful-dnd/blob/master/docs/patterns/tables.md
 */

export const PriorityCell = styled(TableCell)`
  width: 20%;

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    width: 10%;
  }
`

export const TitleCell = styled(TableCell)`
  width: 30%;

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    width: 40%;
  }
`

export const RulesCell = styled(TableCell)`
  width: 50%;

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    width: 50%;
  }
`

import React from 'react'
import {Controller, useForm} from 'react-hook-form'
import {useEventAuth} from 'Event/auth'
import {fieldErrors} from 'lib/form'
import ErrorAlert from 'lib/ui/alerts/ErrorAlert'
import Page from 'Event/template/Panels/Login/Page'
import Button from 'Event/auth/Login/SubmitButton/Button'
import {useQueryParams} from 'lib/url'
import TextField from 'Event/auth/Login/TextField'
import Content from 'lib/ui/form/TextEditor/Content'
import {AreaRegistrationFormProps} from 'Event/AreaRegistration'
import Visible from 'lib/ui/layout/Visible'
import styled from 'styled-components'
import AreaRegistrationFormPhoneNumberField from 'Event/AreaRegistrationPhoneNumberField'

export default function PanelsAreaRegistrationForm(
  props: AreaRegistrationFormProps,
) {
  const {user} = useEventAuth()
  const {handleSubmit, register, control, errors: formErrors} = useForm()
  const {responseError, submit, canSubmit, area} = props

  const errors = fieldErrors({
    formErrors,
    responseError,
  })

  const query = useQueryParams()

  return (
    <Page>
      <>
        <ErrorAlert>{responseError?.message}</ErrorAlert>
        <form onSubmit={handleSubmit(submit)}>
          <Content>{area.template.description}</Content>
          <FieldsContainer>
            <Visible when={!area.register_existing_attendees_only}>
              <TextField
                name="first_name"
                label={area.template.firstNameLabel}
                defaultValue={user?.first_name || query.first_name}
                inputProps={{
                  ref: register({required: 'First name is required.'}),
                  'aria-label': 'first name',
                }}
                disabled={!canSubmit}
                error={Boolean(errors.first_name)}
                helperText={errors.first_name}
              />
            </Visible>
            <Visible when={!area.register_existing_attendees_only}>
              <TextField
                name="last_name"
                label={area.template.lastNameLabel}
                defaultValue={user?.last_name || query.last_name}
                inputProps={{
                  ref: register({required: 'Last name is required.'}),
                  'aria-label': 'last name',
                }}
                disabled={!canSubmit}
                error={Boolean(errors.last_name)}
                helperText={errors.last_name}
              />
            </Visible>
          </FieldsContainer>
          <TextField
            label={area.template.emailLabel}
            type="email"
            fullWidth
            variant="outlined"
            name="email"
            defaultValue={user?.email || query.email}
            inputProps={{
              ref: register({required: 'Email is required.'}),
              'aria-label': 'email',
            }}
            error={Boolean(errors.email)}
            helperText={errors.email}
            disabled={!canSubmit}
          />
          <Visible when={area.template.hasPhoneNumberField}>
            <Controller
              name="phone_number"
              defaultValue={user?.phone_number || query.phone_number}
              control={control}
              rules={{
                required: area.template.phoneNumberRequired
                  ? 'Phone number is required'
                  : false,
              }}
              render={({onChange, value}) => (
                <AreaRegistrationFormPhoneNumberField
                  onChange={onChange}
                  value={value}
                  error={Boolean(errors.phone_number)}
                  helperText={errors.phone_number}
                  disabled={!canSubmit}
                  label={area.template.phoneNumberLabel}
                  fullWidth
                  variant="outlined"
                  aria-label="phone number input"
                />
              )}
            />
          </Visible>
          <Button
            variant="contained"
            fullWidth
            aria-label="join area"
            type="submit"
            disabled={!canSubmit}
          >
            {area.template.joinButtonText}
          </Button>
        </form>
      </>
    </Page>
  )
}

export const FieldsContainer = styled.div`
  display: flex;
  flex-direction: column;

  div {
    width: 100%;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.sm}) {
    flex-direction: row;
    justify-content: space-between;

    div {
      width: 49%;
      div {
        width: 100%;
      }
    }
  }
`

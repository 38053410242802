import {useTemplate} from 'Event/TemplateProvider'
import {useEditMode} from 'Event/EditModeProvider'
import React, {useEffect} from 'react'

/**
 * Event pages that can contain a custom script
 */
export const DASHBOARD = 'Dashboard'
export const LOGIN = 'Login'
export const SIGN_UP = 'Sign up'
export const FORGOT_PASSWORD = 'Forgot Password'
export const CHECK_IN_SET_PASSWORD = 'Check in: Set Password'
export const CHECK_IN_WAIVER = 'Check in: Waiver'
export const CHECK_IN_TECH_CHECK = 'Check in: Tech Check'
export const CHANGE_PASSWORD = 'Change Password'
export const SPEAKERS = 'Speakers'
export const SPONSORS = 'Sponsors'
export const FAQS = 'FAQs'
export const LEADERBOARD = 'Leaderboard'
export const ZOOM_BACKGROUNDS = 'Zoom Backgrounds'
export const IMAGE_WATERFALL = 'Image Waterfall'
export const AREA_OFFLINE_PAGES = 'Area Offline Pages'
export const EVENT_OFFLINE_PAGE = 'Event Offline Page'

export type Page =
  | typeof DASHBOARD
  | typeof LOGIN
  | typeof SIGN_UP
  | typeof FORGOT_PASSWORD
  | typeof CHECK_IN_SET_PASSWORD
  | typeof CHECK_IN_WAIVER
  | typeof CHECK_IN_TECH_CHECK
  | typeof CHANGE_PASSWORD
  | typeof SPEAKERS
  | typeof SPONSORS
  | typeof FAQS
  | typeof LEADERBOARD
  | typeof ZOOM_BACKGROUNDS
  | typeof IMAGE_WATERFALL
  | typeof AREA_OFFLINE_PAGES
  | typeof EVENT_OFFLINE_PAGE

export type Script = {
  name: string
  file: string
  url: string
  pages: Page[]
}

export const ALL_PAGES: Page[] = [
  DASHBOARD,
  LOGIN,
  SIGN_UP,
  FORGOT_PASSWORD,
  CHECK_IN_SET_PASSWORD,
  CHECK_IN_WAIVER,
  CHECK_IN_TECH_CHECK,
  CHANGE_PASSWORD,
  SPEAKERS,
  SPONSORS,
  FAQS,
  LEADERBOARD,
  ZOOM_BACKGROUNDS,
  IMAGE_WATERFALL,
  AREA_OFFLINE_PAGES,
  EVENT_OFFLINE_PAGE,
]

export default function Scripts(props: {page: Page}) {
  const {page} = props
  const {scripts} = useTemplate()

  const isEditMode = useEditMode()
  const targets = Object.values(scripts).filter((script) =>
    script.pages.includes(page),
  )

  const hasTargets = targets.length > 0
  if (!hasTargets || isEditMode) {
    return null
  }

  return (
    <>
      {targets.map((script) => (
        <Content key={script.file} script={script} />
      ))}
    </>
  )
}

function Content(props: {script: Script}) {
  const {script} = props

  const isEditMode = useEditMode()

  useEffect(() => {
    if (isEditMode) {
      return
    }

    const scriptToAppend = document.createElement('script')
    scriptToAppend.src = script.url
    scriptToAppend.async = true
    scriptToAppend.setAttribute('aria-label', script.name)
    document.body.appendChild(scriptToAppend)

    return () => {
      document.body.removeChild(scriptToAppend)
    }
  }, [isEditMode, script])

  return null
}

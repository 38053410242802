import Plugin from "@ckeditor/ckeditor5-core/src/plugin";

import {
	addListToDropdown,
	createDropdown,
} from "@ckeditor/ckeditor5-ui/src/dropdown/utils";
import Collection from "@ckeditor/ckeditor5-utils/src/collection";
import Model from "@ckeditor/ckeditor5-ui/src/model";
import { ELEMENT_NAME, INSERT_COMMAND } from "./Value";

export default class EditorComponents extends Plugin {
	init() {
		const editor = this.editor;
		const t = editor.t;
		const variables = editor.config.get("obvioVariables.items");
		// If no merge code from the config, no need to show this.
		if (variables === null || variables.length === 0) {
			return;
		}

		// The dropdown must be registered among the UI components of the editor
		// to be displayed in the toolbar.
		editor.ui.componentFactory.add(ELEMENT_NAME, (locale) => {
			const dropdownView = createDropdown(locale);

			// Populate the list in the dropdown with items.
			addListToDropdown(dropdownView, createDropdownItems(variables));

			dropdownView.buttonView.set({
				// The t() function helps localize the editor. All strings enclosed in t() can be
				// translated and change when the language of the editor changes.
				label: t("{{ Variables }}"),
				tooltip: true,
				withText: true,
			});

			dropdownView.class = "obvio-variables-select";
			// Disable the button when the command is disabled.
			const command = editor.commands.get(INSERT_COMMAND);
			dropdownView.bind("isEnabled").to(command);

			// Execute the command when the dropdown item is clicked (executed).
			this.listenTo(dropdownView, "execute", (evt) => {
				editor.execute(INSERT_COMMAND, {
					value: evt.source.commandParam,
				});
				editor.editing.view.focus();
			});

			return dropdownView;
		});
	}
}

function createDropdownItems(variables) {
	const itemDefinitions = new Collection();

	for (const { label, value } of variables) {
		const definition = {
			type: "button",
			model: new Model({
				commandParam: value,
				label,
				withText: true,
			}),
		};

		// Add the item definition to the collection.
		itemDefinitions.add(definition);
	}

	return itemDefinitions;
}

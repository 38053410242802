import React, {useMemo, useEffect, useState} from 'react'
import {onUnknownChangeHandler} from 'lib/dom'
import Select from 'lib/ui/Select'
import Option from 'lib/ui/Select/Option'
import WebhooksProvider, {
  CUSTOM_WEBHOOK,
  useWebhooks,
} from 'organization/Event/WebhooksProvider'
import AdvancedAttendeeFilter from 'organization/Event/ZoomAttendance/AdvancedAttendeeFilter'
import {FormProps} from 'organization/Event/ZoomAttendance/AttendeeActionForm'
import ButtonRow from 'organization/Event/ZoomAttendance/ButtonRow'
import {
  useZoomAttendanceAction,
  useZoomAttendance,
} from 'organization/Event/ZoomAttendance/ZoomAttendanceProvider'

export default function ActionFormCustomWebhook(props: FormProps) {
  return (
    <WebhooksProvider noLoader>
      <Content {...props} />
    </WebhooksProvider>
  )
}

const Content = (props: FormProps) => {
  const {action, onClose, onSuccessMessage} = props
  const [error, setError] = useState<string>('')
  const {webhooks} = useWebhooks()
  const [webhookId, setWebhookId] = useState<number>(0)
  const submitAction = useZoomAttendanceAction()
  const {
    area,
    attendeeFilterData,
    endDateTime,
    setScheduledActionData,
    startDateTime,
  } = useZoomAttendance()

  const validation = () => {
    setError('')

    if (!webhookId) {
      setError('You must provide a Custom Webhook')
      return false
    }

    return true
  }

  const onClick = () => {
    if (validation() === false) {
      return
    }

    submitAction(action, startDateTime, endDateTime, area, {
      webhookId: webhookId,
      filter: attendeeFilterData,
    })
      .then((response) => {
        onSuccessMessage(response.message ?? null)
        onClose()
      })
      .catch((e) => {
        setError(e.message)
        return
      })
  }

  const customWebhooks = useMemo(() => {
    return webhooks.filter(
      (webhook) => webhook.webhook_event === CUSTOM_WEBHOOK,
    )
  }, [webhooks])

  useEffect(() => {
    if (!webhookId) {
      return
    }

    setScheduledActionData({
      webhookId: webhookId,
      webhook: customWebhooks.find((webhook) => webhook.id === webhookId),
    })
  }, [webhookId, customWebhooks, setScheduledActionData])

  return (
    <>
      <AdvancedAttendeeFilter />

      <Select
        aria-label="pick custom webhook"
        error={Boolean(error)}
        fullWidth
        helperText={error}
        label="Webhook"
        onChange={onUnknownChangeHandler(setWebhookId)}
        value={webhookId || ''}
      >
        {customWebhooks.map((webhook) => (
          <Option key={webhook.id} value={webhook.id}>
            {webhook.custom_label}
          </Option>
        ))}
      </Select>

      <ButtonRow
        action={action}
        onClick={onClick}
        onClose={onClose}
        validation={validation}
      />
    </>
  )
}

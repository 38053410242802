import MenuItem from 'lib/ui/Menu/MenuItem'
import {
  Broadcast,
  useBroadcasts,
} from 'organization/Event/Broadcasts/BroadcastsProvider'
import React from 'react'
import {useCreateBroadcast} from 'organization/Event/Broadcasts/AddBroadcastButton/NewBroadcastDialog'
import {inTenMinutes, isPast} from 'lib/date-time'

export default React.forwardRef<
  HTMLLIElement,
  {
    broadcast: Broadcast
  }
>((props, ref) => {
  const {broadcast} = props
  const createBroadcast = useCreateBroadcast()
  const {add} = useBroadcasts()

  // If the broadcast was originally going to be sent in less than 10 mins, we'll make it
  // at least 10 minutes later.
  const isWithinTenMinutes = isPast(broadcast.send_at, inTenMinutes())
  const sendAt = isWithinTenMinutes ? inTenMinutes() : broadcast.send_at

  const duplicate = (broadcast: Broadcast) => {
    createBroadcast({
      ...broadcast,
      is_draft: true,
      send_at: sendAt,
    }).then(add)
  }

  return (
    <>
      <MenuItem
        aria-label="duplicate broadcast"
        onClick={() => {
          duplicate(broadcast)
        }}
        ref={ref}
      >
        Duplicate
      </MenuItem>
    </>
  )
})

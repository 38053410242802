import {useAttendeeVariables} from 'Event'
import {useEditMode} from 'Event/EditModeProvider'
import {useTownhallTemplate} from 'Event/template/Townhall'
import React from 'react'
import styled from 'styled-components'

export default function SectionHeading(props: {
  children: string
  'aria-label'?: string
  color?: string | null
}) {
  const {color, children} = props
  const template = useTownhallTemplate()
  const v = useAttendeeVariables()

  const isEditMode = useEditMode()

  if (!children && !isEditMode) {
    return null
  }

  return (
    <Text aria-label={props['aria-label']} color={color ?? template.textColor}>
      {v(children)}
    </Text>
  )
}

const Text = styled.h2<{color: string | null}>`
  margin: 0 0 ${(props) => props.theme.spacing[5]};
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 34px;
  color: ${(props) => props.color};

  &:empty {
    width: ${(props) => props.theme.spacing[14]};
    height: ${(props) => props.theme.spacing[8]};
  }
`

import React from 'react'

import {handleAutocomplete} from 'lib/dom'

import {Autocomplete} from '@material-ui/lab'
import TextField from '@material-ui/core/TextField'
import styled from 'styled-components'
import {
  HubspotList,
  useHubspotList,
} from 'organization/Event/Services/Apps/Hubspot/Config/HubspotListsProvider'

export default function ListSelector(props: {
  onChange: (selected: HubspotList | null) => void
  value?: HubspotList | null
  errorText?: string
  ['aria-label']?: string
}) {
  const {lists} = useHubspotList()

  const {value: selected, onChange, errorText} = props

  const optionLabel = (list: HubspotList) => {
    return list.name
  }

  return (
    <Box>
      <Autocomplete
        disablePortal
        options={lists}
        value={selected || null}
        aria-label={props['aria-label'] || 'field autocomplete'}
        onChange={handleAutocomplete(onChange)}
        getOptionLabel={optionLabel}
        closeIcon=""
        noOptionsText="Could not find a field containing search word"
        getOptionSelected={(option, value) => option.name === value.name}
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              variant="outlined"
              label={'List'}
              fullWidth
              error={Boolean(errorText)}
              helperText={errorText || ''}
              inputProps={{
                ...params.inputProps,
                'aria-label': 'list search',
              }}
              InputProps={{
                ...params.InputProps,
              }}
            />
          )
        }}
      />
    </Box>
  )
}

const Box = styled.div`
  position: relative;
  .MuiAutocomplete-popper {
    margin-top: -20px !important;
  }
`

import React from 'react'
import {Redirect, useHistory} from 'react-router-dom'
import {useEventRoutes} from 'organization/Event/EventRoutes'
import Page from 'organization/Event/Page'
import Form from 'organization/Marketplace/Tickets/TicketForm/Form'
import {useParams} from 'react-router-dom'
import {GroupPair} from 'lib/ui/form/DescribedGroupsInput'
import {TicketsBreadcrumbs} from 'organization/Event/Page/PageBreadcrumbs'
import {useEventTickets} from 'lib/marketplace-api/tickets/use-event-tickets'
import {
  UpdateTicketData,
  useUpdateTicket,
} from 'lib/marketplace-api/tickets/use-update-ticket'
import {MarketplaceTicket} from 'lib/marketplace-api/tickets'
import {useValidatedForm} from 'lib/form'

interface ContentProps {
  ticket: MarketplaceTicket
}

export default function EditTicketForm() {
  const eventRoutes = useEventRoutes()
  const {ticket: routeId} = useParams<{ticket: string}>()
  const ticketId = parseInt(routeId)

  const {data: tickets, isLoading} = useEventTickets()

  const ticket = tickets?.find((ticket) => ticket.id === ticketId)

  if (isLoading && !ticket) {
    return null
  }

  if (!ticket) {
    return <Redirect to={eventRoutes.marketplace.tickets.root} />
  }

  return <Content ticket={ticket} />
}

function Content(props: ContentProps) {
  const {ticket} = props
  const {mutate: updateTicket, isLoading: isUpdatingTicket} = useUpdateTicket({
    ticket,
  })
  const history = useHistory()
  const {
    errors: formErrors,
    handleSubmit,
    control,
    setValue,
    responseError,
    setResponseError,
  } = useValidatedForm()
  const eventRoutes = useEventRoutes()

  const submit = (data: UpdateTicketData) => {
    const groupsAsStrings = data.groups?.map((group) => {
      // Need to convert to unkown first because there is no overlap, we've
      // actually got a GroupPair[] in data.
      const temp = (group as unknown) as GroupPair

      // Backend wants the group attribute to be an array of strings, group:value
      // in format.
      return `${temp.key}:${temp.value}`
    })

    // Put in our massaged string[] back into the data that is being submitted.
    data.groups = groupsAsStrings

    updateTicket(
      {...ticket, ...data},
      {
        onError: (error: any) => {
          setResponseError(error)
        },
        onSuccess: () => {
          history.push(eventRoutes.marketplace.tickets.root)
        },
      },
    )
  }

  return (
    <TicketsBreadcrumbs page={ticket.name}>
      <Page>
        <Form
          control={control}
          formErrors={formErrors}
          onSubmit={handleSubmit(submit)}
          responseError={responseError}
          setValue={setValue}
          submitting={isUpdatingTicket}
          ticket={ticket}
        />
      </Page>
    </TicketsBreadcrumbs>
  )
}

import React, {useCallback} from 'react'
import styled from 'styled-components'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import {formatIntegerPrice} from 'lib/currency'
import {
  LONG_DATE_FORMAT,
  LONG_DATE_TIME_FORMAT,
  useLocalTime,
} from 'lib/date-time'
import {useToggle, useToggleArray} from 'lib/toggle'
import {api} from 'lib/url'

import {teamMemberClient} from 'obvio/obvio-client'
import {addOnDetails} from 'obvio/Billing/plans'
import {replace, parseVariables} from 'lib/template'
import {CheckIcon, CircleDotIcon, CloseIcon} from 'lib/ui/Icon'
import {colors} from 'lib/ui/theme'
import Button from 'lib/ui/Button'
import PayDialog from 'obvio/Billing/TransactionSection/TransactionRow/PayDialog'
import {useDefaultPaymentMethod} from 'obvio/Billing/DefaultPaymentMethodProvider'
import {
  AddonTransaction,
  Transaction,
  TransactionEvent,
} from 'obvio/Billing/TransactionSection/list-transactions'

export default function TransactionRow(props: {
  transaction: Transaction
  onPayment: () => void
}) {
  const {transaction, onPayment} = props

  return (
    <Body
      aria-label="view transaction details"
      transaction={transaction}
      onPayment={onPayment}
    />
  )
}

function TransactionDescription(props: {transaction: AddonTransaction}) {
  const {transaction} = props

  const details = addOnDetails[transaction.details.addon_key]
  let result = details.transaction_description

  for (const key of parseVariables(result)) {
    switch (key) {
      case 'quantity':
        result = replace(key, String(transaction.details.quantity), result)
        break
      case 'quantity_grammar':
        result = replace(
          key,
          String(transaction.details.quantity === 1 ? '' : 's'),
          result,
        )
        break
      case 'duration':
        result = replace(key, String(transaction.details.duration), result)
        break
      case 'duration_grammar':
        result = replace(
          key,
          String(transaction.details.duration === 1 ? '' : 's'),
          result,
        )
        break
      case 'cost':
        const cost = formatIntegerPrice(transaction.details.cost)

        result = replace(key, String(`$${cost}`), result)
        break
      case 'block_num':
        result = replace(key, String(transaction.details.block_size), result)
        break
    }
  }

  return <div>{result}</div>
}

function TransactionEvents(props: {transaction: AddonTransaction}) {
  const {transaction} = props

  if (!transaction.events?.length) {
    return null
  }

  return (
    <EventsContainer>
      {transaction.events.map((event: TransactionEvent, key) => {
        return (
          <div key={key}>
            {event.organization} - {event.name} (Blocks used: {event.quantity})
          </div>
        )
      })}
    </EventsContainer>
  )
}

export function Body(props: {
  'aria-label'?: string
  transaction: Transaction
  onPayment: () => void
}) {
  const {transaction, onPayment} = props
  const {flag: expanded, toggle: toggleExpand} = useToggle()

  return (
    <>
      <ClickableRow
        hover
        onClick={toggleExpand}
        aria-label={props['aria-label']}
      >
        <LastTransactionDateCell transaction={transaction} />
        <CenteredCell>
          <StatusIcon transaction={transaction} />
        </CenteredCell>
        <TypeCell>
          <span>
            {transaction.type} - {getName(transaction)}
          </span>
          <PayButton transaction={transaction} onPayment={onPayment} />
        </TypeCell>
        <CenteredCell>{getQuantity(transaction)}</CenteredCell>
        <AmountCell>{formatIntegerPrice(transaction.amount)}</AmountCell>
      </ClickableRow>
      <Details showing={expanded} transaction={transaction} />
    </>
  )
}

function getName(transaction: Transaction) {
  switch (transaction.type) {
    case 'Addon':
      return transaction.details.name
    case 'Sms':
      return 'Broadcast charge'
    default:
      //@ts-ignore
      throw new Error('Missing quantity for type: ', transaction.type)
  }
}

function getQuantity(transaction: Transaction) {
  switch (transaction.type) {
    case 'Addon':
      return transaction.details.quantity
    case 'Sms':
      return (
        transaction.details.num_sent_north_america +
        transaction.details.num_sent_international
      )
    default:
      //@ts-ignore
      throw new Error('Missing quantity for type: ', transaction.type)
  }
}

function LastTransactionDateCell(props: {transaction: Transaction}) {
  const localTime = useLocalTime()
  return (
    <DateCell>
      {localTime(props.transaction.created_at, LONG_DATE_FORMAT)}
    </DateCell>
  )
}

function StatusIcon(props: {transaction: Transaction}) {
  const {transaction} = props
  switch (transaction.status) {
    case 'complete':
      return <CheckIcon color={colors.success} />
    case 'pending':
      return <CircleDotIcon color={colors.warning} />
    case 'failed':
      return <CloseIcon color={colors.error} />
  }
}

export function Details(props: {showing: boolean; transaction: Transaction}) {
  const {showing, transaction} = props

  if (!showing) {
    return null
  }

  return (
    <TableRow>
      <TableCell>{/* Date Column */}</TableCell>
      <TableCell>{/* Status Column */}</TableCell>
      <TableCell colSpan={3}>
        <TransactionTypeDetail transaction={transaction} />
      </TableCell>
    </TableRow>
  )
}

function TransactionTypeDetail(props: {transaction: Transaction}) {
  const {transaction} = props
  const localTime = useLocalTime()

  switch (transaction.type) {
    case 'Addon':
      return (
        <>
          <TransactionDescription transaction={transaction} />
          <TransactionEvents transaction={transaction} />
        </>
      )

    case 'Sms':
      return (
        <div>
          Sent on{' '}
          {localTime(
            transaction.details.broadcast_send_at,
            LONG_DATE_TIME_FORMAT,
          )}
          <br />
          {transaction.details.num_sent_north_america} messages to North America
          at $0.02 per message
          <br />
          {transaction.details.num_sent_international} messages Internationally
          at $0.10 per message
        </div>
      )
  }
  return null
}

function PayButton(props: {transaction: Transaction; onPayment: () => void}) {
  const {transaction, onPayment} = props
  const [showingPayDialog, togglePayDialog] = useToggleArray()
  const {paymentMethod} = useDefaultPaymentMethod()

  const needsPayment =
    transaction.status === 'failed' || transaction.status === 'pending'
  if (needsPayment === false) {
    return null
  }

  // If user doesn't have a saved payment method (card), we won't show the Pay button
  if (paymentMethod === null) {
    return null
  }

  return (
    <>
      <PayDialog
        open={showingPayDialog}
        onClose={togglePayDialog}
        transaction={transaction}
        onSuccess={onPayment}
      />
      <Button
        color="danger"
        variant="contained"
        size="small"
        onClick={(e) => {
          e.stopPropagation()
          togglePayDialog()
        }}
      >
        Pay
      </Button>
    </>
  )
}

export function useFetchTransaction<T>(transaction: {id: number}) {
  const url = api(`/transactions/${transaction.id}`)

  return useCallback(() => teamMemberClient.get<T>(url), [url])
}

const DateCell = styled(TableCell)`
  width: 0%;
  white-space: nowrap;
`

export const ClickableRow = styled(TableRow)`
  cursor: pointer;
`

const EventsContainer = styled.div`
  margin-top: ${(props) => props.theme.spacing[4]} !important;
`

const CenteredCell = styled(TableCell)`
  text-align: center;
`

const AmountCell = styled(TableCell)`
  text-align: right;
`

const TypeCell = styled(TableCell)`
  display: flex;
  align-items: center;
  justify-content: space-between;

  span {
    margin-right: 1rem;
    white-space: nowrap;
  }
`

import React from 'react'
import styled from 'styled-components'
import Box from '@material-ui/core/Box'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import {RelativeLink} from 'lib/ui/link/RelativeLink'
import NewItemButton from 'lib/ui/PageHeader/NewItemButton'
import {useEventRoutes} from 'organization/Event/EventRoutes'
import {useEventTickets} from 'lib/marketplace-api/tickets/use-event-tickets'
import TicketItem from 'organization/Marketplace/Tickets/TicketItem'

export default function TicketListing() {
  return (
    <Box>
      <TableBox>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Slug</TableCell>
              <DateTableCell>Available From</DateTableCell>
              <DateTableCell>Available To</DateTableCell>
              <TableCell>Price</TableCell>
              <TableCell>{/* Indicator Icons Cell */}</TableCell>
              <TableCell>{/* "More Menu" Cell */}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TicketContent />
          </TableBody>
        </Table>
      </TableBox>
    </Box>
  )
}

const TicketContent = () => {
  const eventRoutes = useEventRoutes()
  const {data: tickets} = useEventTickets()

  if (!tickets || tickets.length === 0) {
    return (
      <TableRow>
        <AddNewTableCell colSpan={7} align="center">
          <StyledRelativeLink
            disableStyles
            to={eventRoutes.marketplace.tickets.add}
          >
            <NewItemButton text="New Ticket" color="primary" />
          </StyledRelativeLink>
        </AddNewTableCell>
      </TableRow>
    )
  }

  return (
    <>
      {tickets.map((ticket) => (
        <TicketItem key={ticket.id} ticket={ticket} />
      ))}
    </>
  )
}

const TableBox = styled.div`
  overflow: auto;
`

export const DateTableCell = styled(TableCell)`
  display: none;

  @media (min-width: ${(props) => props.theme.breakpoints.lg}) {
    display: table-cell;
  }
`
export const PriceTableCell = styled(TableCell)`
  width: 110px;

  @media (min-width: ${(props) => props.theme.breakpoints.lg}) {
    width: 160px;
  }
`
const AddNewTableCell = styled(TableCell)`
  border-bottom: none !important;
`
const StyledRelativeLink = styled(RelativeLink)`
  display: inline-block;
`

import Tooltip from '@material-ui/core/Tooltip'
import styled from 'styled-components'
import {Icon} from 'lib/fontawesome/Icon'
import React from 'react'

export default function InfoTooltip(props: {children: string}) {
  return (
    <Box>
      <StyledTooltip title={props.children} placement="bottom" arrow>
        <span>
          <StyledIcon iconClass="fas fa-info-circle" />
        </span>
      </StyledTooltip>
    </Box>
  )
}

const Box = styled.span`
  margin-left: ${(props) => props.theme.spacing[2]};
`

const StyledIcon = styled(Icon)`
  color: #353535;
  position: relative;
  font-size: 20px;

  // White fill for 'i'
  &::after {
    content: ' ';
    background: #ffffff;
    width: 50%;
    height: 50%;
    position: absolute;
    left: 25%;
    top: 25%;
    z-index: -1;
  }
`

const StyledTooltip = styled(({className, ...otherProps}) => {
  return <Tooltip classes={{popper: className}} {...otherProps} />
})`
  > div {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    padding: ${(props) =>
      `${props.theme.spacing[3]} ${props.theme.spacing[2]}`};
    background: #ffffff;
    color: #000000;

    // Make arrow white too
    span {
      color: #ffffff;
    }
  }
`

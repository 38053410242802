import {useTeamCompetition} from 'Event/business-mastery/TeamCompetition'
import {NoteContext, useNote} from 'lib/note'

export interface Submission {
  progress: NoteContext
  valueAdded: NoteContext
  measureSuccess: NoteContext
  alreadySubmitted: boolean
  attachments: NoteContext
  submittedAt: NoteContext
  loading: boolean
}

export function useSubmission(day: number, submission: number): Submission {
  const {teamId} = useTeamCompetition()

  const progress = useNote(
    `team:${teamId}:day:${day}:submission:${submission}:progress`,
  )

  const valueAdded = useNote(
    `team:${teamId}:day:${day}:submission:${submission}:value_added`,
  )

  const measureSuccess = useNote(
    `team:${teamId}:day:${day}:submission:${submission}:measure_success`,
  )

  const attachments = useNote(
    `team:${teamId}:day:${day}:submission:${submission}:attachments`,
  )

  const submittedAt = useNote(
    `team:${teamId}:day:${day}:submission:${submission}:submitted_at`,
  )

  const values = [
    progress.note?.body,
    valueAdded.note?.body,
    measureSuccess.note?.body,
  ]

  // As long as one of the fields has a value, we will assume the
  // form has already been submitted.
  const alreadySubmitted =
    values.filter((v) => v !== null && v !== undefined).length > 0

  return {
    progress,
    valueAdded,
    measureSuccess,
    alreadySubmitted,
    attachments,
    submittedAt,
    loading:
      progress.loading ||
      valueAdded.loading ||
      measureSuccess.loading ||
      attachments.loading ||
      submittedAt.loading,
  }
}

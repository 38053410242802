import React from 'react'
import {obvioRoutes} from 'obvio/Routes'
import AppBarBase from 'lib/ui/AppBar'
import AccountMenu from 'lib/ui/AppBar/AccountMenu'
import BillingMenuItem from 'obvio/Billing/BillingMenuItem'

export default function AppBar() {
  return (
    <AppBarBase
      homeLink={obvioRoutes.root}
      accountMenu={
        <AccountMenu>
          <BillingMenuItem />
        </AccountMenu>
      }
    />
  )
}

import FormControl from '@material-ui/core/FormControl'
import styled from 'styled-components'
import Select from 'lib/ui/Select'
import Option from 'lib/ui/Select/Option'
import {Emoji} from 'Event/Dashboard/components/EmojiList/emoji'
import {onUnknownChangeHandler} from 'lib/dom'
import React from 'react'

export default function EmojiSelect(props: {
  dark?: boolean
  value?: Emoji['name']
  onPick: (emoji: string) => void
  emojis: Emoji[]
}) {
  return (
    <FormControl fullWidth>
      <Select
        label="Pick an emoji"
        aria-label="pick emoji"
        value={props.value}
        fullWidth
        onChange={onUnknownChangeHandler(props.onPick)}
      >
        {props.emojis.map((emoji, index) => {
          return (
            <Option key={index} value={emoji.name} dark={props.dark}>
              <Image src={emoji.image} alt={emoji.name} />
            </Option>
          )
        })}
      </Select>
    </FormControl>
  )
}

const Image = styled.img`
  width: 30px;
`

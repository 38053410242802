import React from 'react'
import {useAttendeeVariables} from 'Event'
import styled from 'styled-components'
import {Droppable} from 'react-beautiful-dnd'
import PriorityItem from 'Event/Question/SelectPriority/PriorityItem'
import {Question} from 'organization/Event/QuestionsProvider'
import {
  Box,
  DROPPABLE_ID_ANSWERS,
  getStyles,
} from 'Event/Question/SelectPriority'

export default function Answers(props: {
  question: Question
  answers: string[]
  onRemove: (index: number) => void
}) {
  const styles = getStyles(props.question)

  return (
    <Droppable droppableId={DROPPABLE_ID_ANSWERS}>
      {(provided) => (
        <Box
          ref={provided.innerRef}
          {...provided.droppableProps}
          backgroundcolor={styles.boxBackgroundColor}
          textColor={styles.boxTextColor}
          borderRadius={styles.borderRadius}
        >
          <Content {...props} />
          {provided.placeholder}
        </Box>
      )}
    </Droppable>
  )
}

function Content(props: {
  question: Question
  answers: string[]
  onRemove: (index: number) => void
}) {
  const {answers, onRemove, question} = props
  const v = useAttendeeVariables()

  const isEmpty = answers.length === 0
  if (isEmpty) {
    return <HelperText>{v(question.helper_text || '')}</HelperText>
  }

  const styles = getStyles(props.question)

  return (
    <>
      {answers.map((value: string, index: number) => {
        return (
          <PriorityItem
            isSelected
            key={value}
            index={index}
            borderRadius={styles.borderRadius}
            backgroundColor={styles.optionBackgroundColor}
            textColor={styles.optionTextColor}
            onClick={() => onRemove(index)}
          >
            {value}
          </PriorityItem>
        )
      })}
    </>
  )
}

const HelperText = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

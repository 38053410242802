import React from 'react'
import {Controller, UseFormMethods} from 'react-hook-form'
import TextField from 'lib/ui/TextField'
import TextEditor, {TextEditorContainer} from 'lib/ui/form/TextEditor'
import {onChangeCheckedHandler} from 'lib/dom'
import Switch from 'lib/ui/form/Switch'
import {useTemplate} from 'Event/TemplateProvider'

export default function Settings(props: {
  control: UseFormMethods['control']
  register: UseFormMethods['register']
}) {
  const {control, register} = props
  const {offlinePage} = useTemplate()

  return (
    <>
      <Controller
        name="offlinePage.shouldRedirect"
        defaultValue={offlinePage.shouldRedirect}
        control={control}
        render={({value, onChange}) => (
          <Switch
            label="Redirect to another URL?"
            checked={value}
            onChange={onChangeCheckedHandler(onChange)}
            labelPlacement="end"
            aria-label="is redirect"
          />
        )}
      />
      <TextField
        name="offlinePage.redirectUrl"
        label="Redirect URL"
        fullWidth
        defaultValue={offlinePage.redirectUrl}
        inputProps={{'aria-label': 'redirect url', ref: register}}
      />
      <TextField
        name="offlinePage.title"
        label="Title"
        fullWidth
        defaultValue={offlinePage.title}
        inputProps={{'aria-label': 'offline page title', ref: register}}
      />
      <Controller
        name="offlinePage.description"
        defaultValue={offlinePage.description}
        control={control}
        render={({value, onChange}) => (
          <TextEditorContainer>
            <TextEditor data={value} onChange={onChange} />
          </TextEditorContainer>
        )}
      />
    </>
  )
}

import {useEvent} from 'Event/EventProvider'
import ErrorAlert from 'lib/ui/alerts/ErrorAlert'
import Button from 'lib/ui/Button'
import TextField from 'lib/ui/TextField'
import {api} from 'lib/url'
import {OntraportField} from 'organization/Event/Services/Apps/Ontraport/Config/Fields'
import {useOrganization} from 'organization/OrganizationProvider'
import React, {useState} from 'react'
import {useForm} from 'react-hook-form'
import styled from 'styled-components'

export default function CreateFieldForm(props: {
  onCreate: (newField: OntraportField) => void
}) {
  const {handleSubmit, register} = useForm()
  const {
    event: {id: eventId},
  } = useEvent()
  const {client} = useOrganization()
  const [processing, setProcessing] = useState<boolean>(false)
  const [error, setError] = useState<string | null>(null)

  const create = (data: {name: string}) => {
    setProcessing(true)

    const url = api(`/events/${eventId}/integrations/ontraport/create_field`)

    client
      .post<OntraportField>(url, data)
      .then((newField) => props.onCreate(newField))
      .catch((e) => setError(e.message))
      .finally(() => {
        setProcessing(false)
      })
  }

  return (
    <form onSubmit={handleSubmit(create)}>
      <TextField
        fullWidth
        placeholder="Field name"
        variant="outlined"
        inputProps={{
          'aria-label': 'field name',
          ref: register,
        }}
        name="name"
      />

      <ErrorAlert>{error}</ErrorAlert>
      <CreateButton
        fullWidth
        variant="contained"
        color="primary"
        type="submit"
        aria-label="create new field"
        disabled={processing}
      >
        Create
      </CreateButton>
    </form>
  )
}

const CreateButton = styled(Button)`
  margin-bottom: ${(props) => props.theme.spacing[4]}!important;
`

import {useTemplate} from 'Event/TemplateProvider'
import {useSaveTemplate} from 'Event/TemplateUpdateProvider'
import TextField from 'lib/ui/TextField'
import {useConfig} from 'organization/Event/Configurable'
import Config from 'organization/Event/Configurable/Config'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import StylingPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/StylingPanel'
import React from 'react'
import StyleFields from 'Event/auth/TextField/StyleFields'

export default function ConfirmNewPasswordFieldConfig() {
  const save = useSaveTemplate()
  const {changePassword} = useTemplate()

  const {
    form: {register},
  } = useConfig()

  return (
    <Config title="Confirm New Password Field" onSave={save}>
      <SettingsPanel>
        <TextField
          label="Password Label"
          name="changePassword.confirmNewPasswordLabel"
          defaultValue={changePassword.confirmNewPasswordLabel}
          inputProps={{
            'aria-label': 'confirm new password label',
            ref: register,
          }}
        />
      </SettingsPanel>
      <StylingPanel>
        <StyleFields
          templateKey="changePassword.input"
          values={changePassword.input}
        />
      </StylingPanel>
    </Config>
  )
}

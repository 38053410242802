import {onUnknownChangeHandler} from 'lib/dom'
import Select from 'lib/ui/Select'
import Option from 'lib/ui/Select/Option'
import React from 'react'
import {Controller, UseFormMethods} from 'react-hook-form'

interface SettingsProps {
  control: UseFormMethods['control']
}

export default function Settings(props: SettingsProps) {
  const {control} = props

  return (
    <Controller
      name="name"
      control={control}
      defaultValue="blog"
      render={({value, onChange}) => (
        <Select
          fullWidth
          label="Pick one"
          value={value}
          onChange={onUnknownChangeHandler(onChange)}
          aria-label="select dashboard section"
        >
          <Option value="countdown_timer">Countdown Timer</Option>
          <Option value="blog">Blog</Option>
          <Option value="agenda">Agenda</Option>
          <Option value="resource">Resources</Option>
        </Select>
      )}
    />
  )
}

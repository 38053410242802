import {ObvioEvent} from 'Event'
import {Client} from 'lib/ui/api-client'
import {api} from 'lib/url'
import {useQuery} from 'react-query'

export function useEventMarketplaceToken(params: {
  client: Client
  event: ObvioEvent
}) {
  const {client, event} = params

  return useQuery({
    queryKey: ['events', event.id, 'marketplace', 'token'],
    queryFn: () =>
      client
        .get<{token: string}>(api(`/events/${event.id}/marketplace/token`))
        .then(({token}) => token),
  })
}

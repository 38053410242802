import React from 'react'
import {Controller, UseFormMethods} from 'react-hook-form'
import {NavButtonWithSize} from 'Event/Dashboard/components/NavButton'
import {Label} from 'lib/ui/typography'
import ColorPicker from 'lib/ui/ColorPicker'
import FontSelect from 'lib/FontSelect'
import Slider from 'lib/ui/Slider'
import CollapsibleSection from 'lib/ui/ConfigPanel/CollapsibleSection'

const DEFAULT_FONT_SIZE = 16

export default function Styling(props: {
  control: UseFormMethods['control']
  button: NavButtonWithSize
}) {
  const {control, button} = props

  return (
    <CollapsibleSection
      label="Typography"
      storageKey="blogPostButton.config.styling.typography"
    >
      <Label>Font</Label>
      <Controller
        control={control}
        name="font"
        defaultValue={button.font || null}
        render={({value, onChange}) => (
          <FontSelect value={value} onChange={onChange} />
        )}
      />
      <Controller
        name="fontSize"
        defaultValue={button.fontSize || DEFAULT_FONT_SIZE}
        control={control}
        render={({value, onChange}) => (
          <Slider
            min={1}
            max={100}
            onChange={onChange}
            valueLabelDisplay="off"
            value={value}
            label="Font Size"
          />
        )}
      />
      <Controller
        name="textColor"
        control={control}
        defaultValue={button.textColor || ''}
        render={({value, onChange}) => (
          <ColorPicker
            label="Text Color"
            color={value}
            onPick={onChange}
            aria-label="text color color"
          />
        )}
      />
      <Controller
        name="hoverTextColor"
        control={control}
        defaultValue={button.hoverTextColor || ''}
        render={({value, onChange}) => (
          <ColorPicker
            label="Text Hover Color"
            color={value}
            onPick={onChange}
            aria-label="text hover color"
          />
        )}
      />
    </CollapsibleSection>
  )
}

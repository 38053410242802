import {useToggleArray} from 'lib/toggle'
import Button from 'lib/ui/Button'
import NewBroadcastDialog from 'organization/Event/Broadcasts/AddBroadcastButton/NewBroadcastDialog'
import React from 'react'

export default function AddBroadcastButton() {
  const [showingDialog, toggleDialog] = useToggleArray()

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        onClick={toggleDialog}
        aria-label="add broadcast"
      >
        + New Broadcast
      </Button>
      <NewBroadcastDialog showing={showingDialog} onClose={toggleDialog} />
    </>
  )
}

import React from 'react'
import Page from 'organization/Event/TemplateEditor/Page'
import 'react-grid-layout/css/styles.css'
import 'react-resizable/css/styles.css'
import AddSectionButton from 'organization/Marketplace/PurchasePageConfig/AddSectionButton'
import {orderedIdsByPosition} from 'lib/list'
import {useEditMode} from 'Event/EditModeProvider'
import Section from 'Event/Marketplace/Section'
import ToggleMobileModeSwitch from 'organization/Marketplace/PurchasePageConfig/ToggleMobileModeSwitch'
import MobileModeContainer from 'organization/Marketplace/PurchasePageConfig/MobileModeContainer'
import {usePurchasePageConfig} from 'organization/Marketplace/PurchasePageConfig/PurchasePageConfigProvider'
import {ConfigContext} from 'organization/Event/Configurable'
import {useToggleArray} from 'lib/toggle'
import {useForm} from 'react-hook-form'
import PageSettings from 'organization/Marketplace/PurchasePageConfig/PageSettings'
import PageSettingsButton from 'organization/Marketplace/PurchasePageConfig/PageSettingsButton'
import MoveableSection from 'organization/Marketplace/PurchasePageConfig/MoveableSection'
import TemplateSelector from 'organization/Marketplace/PurchasePageConfig/Content/TemplateSelector'
import styled from 'styled-components'
import {Typography} from '@material-ui/core'
import Block from 'Event/Marketplace/Block'
import MissingRequiredBlocksAlert from 'organization/Marketplace/PurchasePageConfig/Content/MissingRequiredBlocksAlert'
import {getBlocks} from 'Event/Marketplace/Block/get-blocks'
import {PurchasePageTemplate} from 'Event/Marketplace/purchase-page'
import Container from 'Event/Marketplace/Container'

interface ContentProps {
  template: PurchasePageTemplate
}

export default function Content(props: ContentProps) {
  const {template} = props
  const {sections} = template
  const [showingConfig, toggleConfig] = useToggleArray()
  const form = useForm()

  const sectionIds = orderedIdsByPosition(sections)

  const hasSections = sectionIds.length > 0

  const isEditMode = useEditMode()
  const {isMobileMode} = usePurchasePageConfig()

  if (!hasSections) {
    return <TemplateSelector />
  }

  if (!isEditMode) {
    return (
      <Page
        toolbarItems={
          <>
            <ToggleMobileModeSwitch />
          </>
        }
      >
        <MobileModeContainer>
          <Container template={template}>
            {sectionIds.map((id) => (
              <Section
                section={sections[id]}
                key={id}
                isMobileMode={isMobileMode}
                template={template}
              >
                {Object.entries(getBlocks(sections[id])).map(([id, block]) => (
                  <Block block={block} key={id} id={id} template={template} />
                ))}
              </Section>
            ))}
          </Container>
        </MobileModeContainer>
      </Page>
    )
  }

  return (
    <Page
      toolbarItems={
        <>
          <ToggleMobileModeSwitch />
          <PageSettingsButton onClick={toggleConfig} />
        </>
      }
    >
      <ConfigContext.Provider
        value={{showing: showingConfig, toggle: toggleConfig, form}}
      >
        <PageSettings template={template} />
        <MobileModeContainer>
          <Container template={template}>
            {sectionIds.map((id, index) => (
              <MoveableSection
                key={id}
                sectionId={id}
                canMoveUp={index !== 0}
                canMoveDown={index !== sectionIds.length - 1} // is not last
                template={template}
              />
            ))}
            <AddSectionButton template={template} />
            <MissingRequiredBlocksAlert template={template} />
          </Container>
        </MobileModeContainer>
      </ConfigContext.Provider>
    </Page>
  )
}

export const ErrorText = styled(Typography)`
  text-align: center;
  background: ${(props) => props.theme.colors.error};
  color: white;
  padding: 4.5px 0px;
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 100;
  left: 232px;

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    left: 0px;
  }
`

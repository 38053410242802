import {useAddWaiver} from 'Event/Step2/Waivers'
import Button from 'lib/ui/Button'
import React from 'react'

export default function AddButton() {
  const addWaiver = useAddWaiver()

  return (
    <Button onClick={addWaiver} variant="contained" color="primary">
      Add additional waiver
    </Button>
  )
}

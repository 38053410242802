import React from 'react'
import {Controller, UseFormMethods} from 'react-hook-form'
import TextField from 'lib/ui/TextField'
import TextEditor, {TextEditorContainer} from 'lib/ui/form/TextEditor'
import {FAQ} from 'Event/FAQs'
import {onChangeCheckedHandler} from 'lib/dom'
import Switch, {EnabledSwitch} from 'lib/ui/form/Switch'
import Title from 'lib/ui/ConfigPanel/Title'

export default function Settings(props: {
  control: UseFormMethods['control']
  register: UseFormMethods['register']
  faq: FAQ
}) {
  const {control, register, faq} = props

  return (
    <>
      <Controller
        name="enabled"
        control={control}
        defaultValue={faq.enabled}
        render={({onChange, value}) => (
          <EnabledSwitch
            checked={value}
            onChange={onChangeCheckedHandler(onChange)}
            aria-label="toggle enable"
          />
        )}
      />
      <Controller
        name="showAnswerOnLoad"
        control={control}
        defaultValue={faq.showAnswerOnLoad}
        render={({onChange, value}) => (
          <Switch
            checked={value}
            onChange={onChangeCheckedHandler(onChange)}
            labelPlacement="end"
            label={value ? 'Show on Load Enabled' : 'Show on Load Disabled'}
          />
        )}
      />
      <Title>Question</Title>
      <TextField
        name="question"
        required
        fullWidth
        aria-label="faq question"
        inputProps={{
          ref: register({required: 'Faq question is required.'}),
        }}
        defaultValue={faq.question}
      />
      <Controller
        name="answer"
        control={control}
        defaultValue={faq.answer || ''}
        render={({onChange, value}) => (
          <TextEditorContainer>
            <TextEditor data={value} onChange={onChange} />
          </TextEditorContainer>
        )}
      />
    </>
  )
}

import {Sponsor} from 'Event/Sponsors'
import {useConfig} from 'organization/Event/Configurable'
import React from 'react'
import {Controller} from 'react-hook-form'
import Slider from 'lib/ui/Slider'
import Title from 'lib/ui/ConfigPanel/Title'
import StylingPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/StylingPanel'
import ColorPicker from 'lib/ui/ColorPicker'
import BaseConfig from 'Event/Sponsors/SponsorConfig'
import {usePanelsTemplate} from 'Event/template/Panels'

export default function SponsorConfig(props: {id: string; sponsor: Sponsor}) {
  const {sponsors} = usePanelsTemplate()
  const {
    form: {control},
  } = useConfig()

  return (
    <BaseConfig
      {...props}
      stylingPanel={
        <StylingPanel>
          <Title>Sponsors Per Row</Title>
          <Controller
            name="sponsors.perRow"
            defaultValue={sponsors.perRow}
            control={control}
            render={({onChange, value}) => (
              <Slider
                min={1}
                max={3}
                step={1}
                onChange={onChange}
                valueLabelDisplay="auto"
                value={value}
              />
            )}
          />

          <Title>Background</Title>
          <Controller
            name="sponsors.cardBackgroundColor"
            defaultValue={sponsors.cardBackgroundColor}
            control={control}
            render={({onChange, value}) => (
              <ColorPicker
                label="Container Background Color"
                color={value}
                onPick={onChange}
                aria-label="container background color"
              />
            )}
          />
          <Controller
            name="sponsors.cardBackgroundOpacity"
            defaultValue={sponsors.cardBackgroundOpacity}
            control={control}
            render={({onChange, value}) => (
              <Slider
                min={0}
                max={100}
                step={1}
                onChange={onChange}
                hideUnits
                valueLabelDisplay="auto"
                value={value}
                label="Container Background Opacity"
                unit="%"
              />
            )}
          />
        </StylingPanel>
      }
    />
  )
}

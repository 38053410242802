import {useTemplate} from 'Event/TemplateProvider'
import {useSaveTemplate} from 'Event/TemplateUpdateProvider'
import TextField from 'lib/ui/TextField'
import {useConfig} from 'organization/Event/Configurable'
import Config from 'organization/Event/Configurable/Config'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import StylingPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/StylingPanel'
import React from 'react'
import StyleFields from 'Event/auth/TextField/StyleFields'

export default function PasswordFieldConfig() {
  const save = useSaveTemplate()
  const {login} = useTemplate()

  const {
    form: {register},
  } = useConfig()

  return (
    <Config title="Password Field" onSave={save}>
      <SettingsPanel>
        <TextField
          label="Password Label"
          name="login.passwordLabel"
          defaultValue={login.passwordLabel}
          inputProps={{
            'aria-label': 'password label',
            ref: register,
          }}
        />
      </SettingsPanel>
      <StylingPanel>
        <StyleFields templateKey="login.input" values={login.input} />
      </StylingPanel>
    </Config>
  )
}

import React from 'react'
import styled from 'styled-components'
import Dialog from 'lib/ui/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Box from 'lib/ui/Box'
import Button from 'lib/ui/Button'
import TextField from 'lib/ui/TextField'
import {useValidatedForm} from 'lib/form'
import {useToggleArray} from 'lib/toggle'
import {communicationsApi} from 'lib/url'
import {client} from 'lib/ui/api-client'
import {Controller} from 'react-hook-form'
import {Typography} from 'lib/ui/typography'
import {Broadcast} from 'organization/Event/Broadcasts/BroadcastsProvider'

type TestBroadcastData = {
  first_name: string
  last_name: string
  email: string
}

export default function SendTestDialog(props: {
  isOpen: boolean
  onClose: () => void
  broadcast: Broadcast
  onSuccess: (message: string) => void
}) {
  const {isOpen, onClose, broadcast, onSuccess} = props
  const [submitting, toggleSubmitting] = useToggleArray()

  const {
    register,
    handleSubmit,
    control,
    errors,
    clearErrors,
    setResponseError,
  } = useValidatedForm<TestBroadcastData>()

  const submit = (data: {email: string}) => {
    toggleSubmitting()
    clearErrors()

    const payload: TestBroadcastData = {
      email: data.email,
      first_name: 'Obv.io',
      last_name: 'Test Broadcast',
    }

    const successMessage = `Test broadcast sent to ${data.email}.`

    const url = communicationsApi(`/broadcasts/${broadcast.id}/test`)

    client
      .post<void>(url, payload)
      .catch(setResponseError)
      .finally(() => {
        toggleSubmitting()
        onSuccess(successMessage)
        onClose()
      })
  }

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>Send Test Broadcast</DialogTitle>
      <DialogContent>
        <Box pb={2}>
          <StyledTypography>
            Generated data will be used to populate any{' '}
            <code>{'{{ variables }}'}</code> used within the body of the
            Broadcast email.
          </StyledTypography>

          <form onSubmit={handleSubmit(submit)}>
            <Controller
              control={control}
              defaultValue=""
              name="email"
              render={({onChange, value}) => {
                return (
                  <TextField
                    defaultValue={value}
                    disabled={submitting}
                    error={!!errors.email}
                    fullWidth
                    helperText={errors.email}
                    inputProps={{
                      ref: register(),
                      'aria-label': 'email',
                    }}
                    label="Email to receive test"
                    name="email"
                    onChange={onChange}
                    required
                    variant="outlined"
                  />
                )
              }}
            />

            <StyledButton
              aria-label="send test"
              color="primary"
              disabled={submitting}
              type="submit"
              variant="contained"
            >
              Send Test
            </StyledButton>
          </form>
        </Box>
      </DialogContent>
    </Dialog>
  )
}

const StyledTypography = styled(Typography)`
  margin-bottom: ${(props) => props.theme.spacing[4]};
`

const StyledButton = styled(Button)`
  margin-top: ${(props) => props.theme.spacing[4]};

  @media (min-width: ${(props) => props.theme.breakpoints.sm}) {
    margin-top: initial;
  }
`

import styled from 'styled-components'
import React from 'react'
import Logo from 'Event/auth/Login/Logo'
import Background from 'Event/auth/Login/Background'

export default function LeftPanel() {
  return (
    <LeftPanelContainer>
      <StyledBackground>
        <Logo />
      </StyledBackground>
    </LeftPanelContainer>
  )
}

const LeftPanelContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  overflow: auto;
  background-size: cover;
  border-radius: 0px;
  width: 100%;
  height: 100%;
`

const StyledBackground = styled(Background)`
  position: relative !important;
`

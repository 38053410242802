import React from 'react'
import ConfirmRemoveButton from 'lib/ui/Button/ConfirmRemoveButton'

export default function RemoveButton(props) {
  if (!props.isVisible) {
    return null
  }

  return (
    <ConfirmRemoveButton
      variant="outlined"
      aria-label={props['aria-label']}
      onClick={props.onClick}
      disabled={props.disabled}
      size={props.size}
    >
      Remove
    </ConfirmRemoveButton>
  )
}

import {usePanelsTemplate, usePanelsUpdate} from 'Event/template/Panels'
import ColorPicker from 'lib/ui/ColorPicker'
import Divider from 'lib/ui/ConfigPanel/Divider'
import Title from 'lib/ui/ConfigPanel/Title'
import Slider from 'lib/ui/Slider'
import TextField from 'lib/ui/TextField'
import {useConfig} from 'organization/Event/Configurable'
import Config from 'organization/Event/Configurable/Config'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import StylingPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/StylingPanel'
import React from 'react'
import {Controller} from 'react-hook-form'

export default function LeftPanelConfig() {
  const save = usePanelsUpdate()
  const {
    form: {register, control},
  } = useConfig()

  const template = usePanelsTemplate()

  return (
    <Config title="Check In Left Panel" onSave={save}>
      <SettingsPanel>
        <Title>Title</Title>
        <TextField
          fullWidth
          name="checkInTitle"
          defaultValue={template.checkInTitle}
          inputProps={{
            'aria-label': `check in left panel title`,
            ref: register,
          }}
        />
      </SettingsPanel>
      <StylingPanel>
        <Title>Background</Title>
        <Controller
          name="checkInLeftPanel.backgroundColor"
          defaultValue={template.checkInLeftPanel.backgroundColor}
          control={control}
          render={({value, onChange}) => (
            <ColorPicker
              label="Color"
              color={value}
              onPick={onChange}
              aria-label="check in left panel background color"
            />
          )}
        />
        <Controller
          name="checkInLeftPanel.backgroundOpacity"
          defaultValue={template.checkInLeftPanel.backgroundOpacity}
          control={control}
          render={({value, onChange}) => (
            <Slider
              label="Opacity"
              min={0}
              max={100}
              step={1}
              onChange={onChange}
              value={value}
              unit="%"
            />
          )}
        />
        <Divider />
        <Title>Text</Title>
        <Controller
          name="checkInLeftPanel.textColor"
          defaultValue={template.checkInLeftPanel.textColor}
          control={control}
          render={({value, onChange}) => (
            <ColorPicker
              label="Color"
              color={value}
              onPick={onChange}
              aria-label="check in left panel text color"
            />
          )}
        />
      </StylingPanel>
    </Config>
  )
}

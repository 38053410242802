import {PaymentMethod} from '@stripe/stripe-js'
import {api} from 'lib/url'
import {useOrganization} from 'organization/OrganizationProvider'
import {useMutation, useQueryClient} from 'react-query'

export function useAddPaymentMethod() {
  const {organization, client} = useOrganization()
  const qc = useQueryClient()

  return useMutation({
    mutationFn: (data: {payment_method_id: string}) => {
      const url = api(`/organizations/${organization.id}/payment_methods`)
      return client.post<PaymentMethod>(url, data)
    },
    onSuccess: () => {
      // refetch
      qc.invalidateQueries({
        queryKey: ['organizations', organization.id, 'payment_methods'],
        exact: true,
      })
    },
  })
}

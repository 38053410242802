import React from 'react'
import Page from 'lib/ui/layout/Page'
import Layout from 'organization/user/Layout'
import Title from 'lib/ui/typography/Title'
import {useOrganization} from 'organization/OrganizationProvider'
import styled from 'styled-components'
import Divider from 'lib/ui/layout/Divider'
import PlanAddOnsSection from 'organization/AddOns/PlanAddOnsSection'
import {useOwner} from 'organization/OwnerProvider'
import {Redirect} from 'react-router'
import {useBreadcrumbs} from 'lib/ui/BreadcrumbProvider'
import TransactionSection from 'organization/AddOns/TransactionsSection'

export default function Billing() {
  const {
    organization: {name},
    routes,
  } = useOrganization()
  const {plan} = useOwner()

  useBreadcrumbs(
    [
      {title: name, url: routes.events.root},
      {title: 'Billing', url: routes.add_ons.root},
    ],
    [name],
  )

  if (!plan) {
    return <Redirect to={routes.events.root} />
  }

  return (
    <Layout>
      <Page>
        <Title>Add-ons</Title>
        <TransactionSection />
        <Divider />
        <PlanAddOnsSection plan={plan} />
      </Page>
    </Layout>
  )
}

export const Section = styled.div`
  margin-bottom: ${(props) => props.theme.spacing[16]};
`

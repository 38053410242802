import styled from 'styled-components'
import React, {useState} from 'react'
import grey from '@material-ui/core/colors/grey'
import {Organization} from 'organization'
import {createRoutesFor} from 'organization/OrganizationProvider'
import logoImgVertical from 'assets/images/logo_vertical.png'
import {RelativeLink} from 'lib/ui/link/RelativeLink'
import {MoreActionsMenu} from 'lib/ui/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import {Label} from 'lib/ui/typography'
import DeleteOrganizationDialog from 'obvio/Organizations/Collection/DeleteOrganizationDialog'
import {useObvioUser} from 'obvio/auth'

export default function GridCard(props: {organization: Organization}) {
  const {organization} = props

  const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false)

  const routes = createRoutesFor(organization)
  const url = routes.root
  const label = `view ${organization.name}`

  const logo = organization.logo?.url || logoImgVertical
  return (
    <Container aria-label="grid card">
      <Content>
        <RelativeLink to={url} disableStyles aria-label={label}>
          <Box>
            <Image
              src={logo}
              alt={organization.name}
              aria-label="organization logo"
            />
          </Box>
        </RelativeLink>
        <Bottom>
          <MoreActionsMenu>
            <RelativeLink to={url} disableStyles aria-label="view organization">
              <MenuItem>View</MenuItem>
            </RelativeLink>
            <DeleteOrganizationButton
              organization={organization}
              setShowDeleteDialog={setShowDeleteDialog}
            />
          </MoreActionsMenu>
        </Bottom>
      </Content>
      <Name>{props.organization.name}</Name>

      <DeleteOrganizationDialog
        organization={organization}
        visible={showDeleteDialog}
        onClose={() => {
          setShowDeleteDialog(false)
        }}
      />
    </Container>
  )
}

export function DeleteOrganizationButton(props: {
  organization: Organization
  setShowDeleteDialog: (status: boolean) => void
}) {
  const {setShowDeleteDialog} = props

  const user = useObvioUser()

  const isOwner = !props.organization.joined // if not joined, that mean it's owner

  if (!isOwner && !user.is_admin) {
    return null
  }

  return (
    <MenuItem
      aria-label="delete organization"
      onClick={() => {
        setShowDeleteDialog(true)
      }}
    >
      Delete organization
    </MenuItem>
  )
}

const Box = styled.div`
  padding: ${(props) => props.theme.spacing[5]};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 180px;

  &:hover {
    background: ${grey[200]};
  }
`

const Name = styled(Label)`
  margin: 0;
  font-weight: 400;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${(props) => props.theme.spacing[4]};
  border-radius: ${(props) => props.theme.spacing[1]};
  &:hover img {
    opacity: 0.5;
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${(props) => props.theme.spacing[3]};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
`

const Bottom = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  background-color: ${(props) => props.theme.colors.gray300};
  padding-left: ${(props) => props.theme.spacing[2]};
`

const Image = styled.img`
  max-width: 100%;
  max-height: 152px;
`

import {onChangeCheckedHandler} from 'lib/dom'
import Switch, {EnabledSwitch} from 'lib/ui/form/Switch'
import TextField from 'lib/ui/TextField'
import React from 'react'
import {Controller} from 'react-hook-form'
import {useConfig} from 'organization/Event/Configurable'
import {useTownhallTemplate} from 'Event/template/Townhall'
import InputLabel from 'lib/ui/TextField/InputLabel'
import {CountdownTimerSectionProps} from 'Event/template/Townhall/Dashboard/Main/CountdownTimerSection'

export default function Settings(props: {
  countdownTimerSection: CountdownTimerSectionProps
  sectionId: string
}) {
  const {countdownTimerSection, sectionId} = props
  const {
    form: {control, register},
  } = useConfig()
  const {
    navBar: {menuItems},
  } = useTownhallTemplate()

  return (
    <>
      <Controller
        name={`dashboardSections.${sectionId}.isEnabled`}
        control={control}
        defaultValue={countdownTimerSection.isEnabled}
        render={({value, onChange}) => (
          <EnabledSwitch
            checked={value}
            onChange={onChangeCheckedHandler(onChange)}
            arial-label="config enabled switch"
          />
        )}
      />
      <Controller
        name={`navBar.menuItems.${sectionId}.isEnabled`}
        defaultValue={menuItems[sectionId]?.isEnabled ?? true}
        control={control}
        render={({value, onChange}) => (
          <Switch
            checked={value}
            onChange={onChangeCheckedHandler(onChange)}
            labelPlacement="end"
            label={value ? 'Show NavBar Item' : 'Hide NavBar Item'}
            aria-label="toggle hide navbar item"
          />
        )}
      />
      <InputLabel>Menu Title</InputLabel>
      <TextField
        name={`navBar.menuItems.${sectionId}.title`}
        defaultValue={
          menuItems[sectionId]?.title ?? countdownTimerSection.title
        }
        aria-label="update blogs menu title"
        fullWidth
        inputProps={{
          ref: register,
        }}
      />
    </>
  )
}

import {useNiftyFiftyUpdate} from 'Event/template/NiftyFifty'
import Config from 'organization/Event/Configurable/Config'
import StylingPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/StylingPanel'
import React from 'react'
import {StylingInputs} from 'Event/template/NiftyFifty/Login/RightPanel/RightPanelConfig'

export default function RightPanelConfig() {
  const save = useNiftyFiftyUpdate()

  return (
    <Config title="Forgot Password Right Panel" onSave={save}>
      <StylingPanel>
        <StylingInputs />
      </StylingPanel>
    </Config>
  )
}

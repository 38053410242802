import {useCallback, useEffect, useState} from 'react'
import {api} from 'lib/url'
import {teamMemberClient} from 'obvio/obvio-client'
import {PaginatedCollection} from 'lib/ui/api-client'
import {useAsync} from 'lib/async'

export type BaseTransaction = {
  id: number
  created_at: string
  amount: number
  status: 'complete' | 'pending' | 'failed'
}

export type AddonTransaction = BaseTransaction & {
  type: 'Addon'
  details: {
    addon_key: 'attendees' | 'zoom_meetings'
    amount: number
    block_size: number
    cost: number
    name: string
    quantity: number
    duration: number | null
    transaction_id: number
  }
  events?: TransactionEvent[]
}

export type SmsTransaction = BaseTransaction & {
  type: 'Sms'
  details: {
    broadcast_send_at: string
    num_sent_international: number
    num_sent_north_america: number
    sms_body: string
  }
}

export type Transaction = AddonTransaction | SmsTransaction

export type TransactionEvent = {
  slug: string
  name: string
  organization: string
  quantity: number
}

export function useTransactions(baseUrl: string) {
  const [page, setPage] = useState(0)

  // MUI is 0 index based pages, and Laravel starts at 1, so we
  // need to increment manually>
  const url = listUrl(baseUrl, String(page + 1))
  const request = useCallback(
    () => teamMemberClient.get<PaginatedCollection<Transaction>>(url),
    [url],
  )

  const [transactions, setTransactions] = useState<Transaction[]>([])

  const {data: paginated} = useAsync(request)

  useEffect(() => {
    if (paginated) {
      setTransactions(paginated.data)
    }
  }, [paginated])

  const markComplete = (transaction: Transaction) => {
    const updated: Transaction = {
      ...transaction,
      status: 'complete',
    }

    setTransactions((transactions) =>
      transactions.map((t) => {
        if (t.id === updated.id) {
          return updated
        }

        return t
      }),
    )
  }

  return {
    transactions,
    paginated,
    goToPage: setPage,
    markComplete,
  }
}

function listUrl(url: string, page: string) {
  const baseUrl = api(url)
  const params = new URLSearchParams({
    page,
  })

  const query = params.toString()

  return `${baseUrl}?${query}`
}

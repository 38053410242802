import styled from 'styled-components'

const VideoElement = styled.div<{
  width: number
  height: number
  borderWidth: number
  borderColor: string
  borderRadius: number
}>`
  position: relative;
  width: ${(props) => props.width || 0}px;
  height: ${(props) => props.height || 0}px;
  min-width: 100px;
  min-height: 100px;
  overflow: auto;
  outline: ${(props) => props.borderWidth || 0}px solid
    ${(props) => props.borderColor};
  border-radius: ${(props) => props.borderRadius || 0}px;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
`

export default VideoElement

import Button from 'lib/ui/Button'
import ConfirmDialog from 'lib/ui/ConfirmDialog'
import {useRoom} from 'organization/Event/Room/RoomProvider'
import React from 'react'
import styled from 'styled-components'

export default function EndButton(props: {
  processing: boolean
  numAttendees?: number
}) {
  const {room, endMeeting, processing} = useRoom()

  const canEndMeeting =
    props.numAttendees !== 0 && !processing && room.is_online

  const handleClick = () => {
    endMeeting()
  }

  return (
    <ConfirmDialog
      onConfirm={handleClick}
      title="End meeting"
      description={
        <div>
          The action will end the Zoom meeting in progress. Are you sure you
          wish to end this meeting, and remove all participants from the
          meeting?
        </div>
      }
      confirmLabel="Yes"
      variant="info"
    >
      {(confirm) => (
        <StyledEndButton
          variant="contained"
          aria-label="end meeting"
          disabled={!canEndMeeting}
          onClick={confirm}
          color="danger"
        >
          End meeting
        </StyledEndButton>
      )}
    </ConfirmDialog>
  )
}

const StyledEndButton = styled(Button)`
  margin-left: ${(props) => props.theme.spacing[3]};
`

import React from 'react'
import styled from 'styled-components'
import {rgba} from 'lib/color'
import {useTownhallTemplate} from 'Event/template/Townhall'
import {HeaderConfig} from 'Event/template/Townhall/Dashboard/Header/HeaderConfig'
import Grid, {GridProps} from '@material-ui/core/Grid'
import ActionNav from 'Event/template/Townhall/Dashboard/ActionNav'
import TopBar from 'Event/template/Townhall/Dashboard/TopBar'
import Configurable from 'organization/Event/Configurable'
import MainNav from 'Event/template/Townhall/Dashboard/MainNav'
import Hero from 'Event/template/Townhall/Dashboard/Hero'
import Container from 'Event/template/Townhall/Dashboard/Container'

export default function Header() {
  const template = useTownhallTemplate()
  const {
    header: {
      backgroundImage,
      backgroundPosition,
      backgroundColor,
      backgroundOpacity,
      minHeight,
    },
    hero: {vertical_alignment},
    actionNav: {vertical_alignment: action_vertical_alignment},
  } = template

  const backgroundColorRgba = rgba(backgroundColor, backgroundOpacity / 100)

  return (
    <Box
      backgroundImage={backgroundImage}
      backgroundPosition={backgroundPosition}
      aria-label="header"
    >
      <BackgroundColor
        color={backgroundColorRgba}
        minHeight={minHeight}
        id="header"
      >
        <TopBar />
        <Configurable aria-label="edit townhall header">
          <HeaderConfig />
          <Container maxWidth="lg">
            <GridContainer
              container
              position={template.actionNav.position}
              minHeight={minHeight}
              spacing={3}
            >
              <GridItem
                verticalAlignment={vertical_alignment}
                item
                md={6}
                xs={12}
              >
                <Hero />
                <MainNav />
              </GridItem>
              <GridItem
                verticalAlignment={action_vertical_alignment}
                item
                md={6}
                xs={12}
              >
                <ActionNav />
              </GridItem>
            </GridContainer>
          </Container>
        </Configurable>
      </BackgroundColor>
    </Box>
  )
}

const Box = styled.div<{
  backgroundImage: string | null
  disableShadow?: boolean
  isCollapsed?: boolean
  backgroundPosition?: string
}>`
  ${(props) =>
    props.backgroundImage
      ? `background-image: url(${props.backgroundImage});`
      : null}
  background-size: cover;
  background-position: ${(props) => props.backgroundPosition};
  background-repeat: no-repeat;
`

const BackgroundColor = styled.div<{
  color: string
  minHeight: number
}>`
  background-color: ${(props) => props.color};
  min-height: ${(props) => props.minHeight}px;
`

const GridContainer = styled(
  ({
    minHeight: _1,
    position: _2,
    ...otherProps
  }: GridProps & {
    position: 'left' | 'right'
    minHeight: number
  }) => <Grid {...otherProps} />,
)`
  min-height: calc(${(props) => props.minHeight}px - 86px);
  margin-top: ${(props) => props.theme.spacing[16]};
  flex-direction: ${(props) =>
    props.position === 'right' ? 'row' : 'row-reverse'};
`

const GridItem = styled(
  ({
    verticalAlignment: _verticalAlignment,
    ...gridProps
  }: GridProps & {
    verticalAlignment: string
  }) => <Grid {...gridProps} />,
)`
  display: flex;
  flex-direction: column;
  justify-content: ${(props) => props.verticalAlignment};
  margin: 0px;
`

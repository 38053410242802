import React from 'react'
import Grid from '@material-ui/core/Grid'
import {onUnknownChangeHandler} from 'lib/dom'
import {MailchimpGroup} from 'organization/Event/Services/Apps/Mailchimp/index'
import {useMailchimpFields} from 'lib/event-api/integrations/mailchimp/fields/list'
import {useEvent} from 'Event/EventProvider'
import {useOrganization} from 'organization/OrganizationProvider'
import TextField from 'lib/ui/TextField'
import Select from 'lib/ui/Select'
import Option from 'lib/ui/Select/Option'
import {InputAdornment} from '@material-ui/core'
import IconButton from 'lib/ui/IconButton'
import styled, {useTheme} from 'styled-components'
import {CloseIcon} from 'lib/ui/Icon'

export default function GroupInput(props: {
  group: MailchimpGroup
  onChange: (group: MailchimpGroup) => void
  onRemove: (group: MailchimpGroup) => void
}) {
  const {group, onChange, onRemove} = props

  const {event} = useEvent()
  const {client} = useOrganization()
  const {data: fields = [], isLoading} = useMailchimpFields({event, client})

  const theme = useTheme()

  const selectedField = group.mailchimp_field_id ?? ''

  const updateName = (value: string) => {
    const updated = {
      ...group,
      name: value,
    }

    onChange(updated)
  }

  const select = (fieldId: string) => {
    const target = fields.find((field) => field.id === fieldId)
    if (!target) {
      throw new Error(`Missing field with id: ${fieldId}.`)
    }

    const updatedGroup: MailchimpGroup = {
      ...group,
      mailchimp_field_id: target.id,
      mailchimp_field_name: target.name,
    }

    onChange(updatedGroup)
  }

  return (
    <Grid container spacing={2}>
      <Grid item sm={6}>
        <TextField
          label="Group"
          value={group.name ?? undefined}
          fullWidth
          variant="outlined"
          inputProps={{'aria-label': 'group name'}}
          onChange={updateName}
          disabled={isLoading}
          endAdornment={
            <StyledInputAdornment position="end">
              <RemoveButton
                aria-label="remove group"
                onClick={() => {
                  onRemove(group)
                }}
              >
                <CloseIcon color={theme.colors.error} />
              </RemoveButton>
            </StyledInputAdornment>
          }
        />
      </Grid>
      <Grid item sm={6}>
        <Select
          onChange={onUnknownChangeHandler(select)}
          value={selectedField}
          fullWidth
          aria-label="pick group"
          variant="outlined"
          disabled={isLoading}
          label="Group Field"
        >
          {fields.map((field) => (
            <Option
              key={field.id}
              value={field.id}
              aria-label={`pick group ${field.name}`}
            >
              {field.name}
            </Option>
          ))}
        </Select>
      </Grid>
    </Grid>
  )
}

const StyledInputAdornment = styled(InputAdornment)`
  margin: 0;
  position: relative;
`

const RemoveButton = styled(IconButton)`
  padding-top: 40px;
  left: -20px;
  position: absolute;
`

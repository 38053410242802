import React from 'react'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import Settings from 'Event/EventOfflinePage/OfflineContentConfig/Settings'
import {useSaveTemplate} from 'Event/TemplateUpdateProvider'
import {useConfig} from 'organization/Event/Configurable'
import Config from 'organization/Event/Configurable/Config'

export default function OfflineContentConfig() {
  const save = useSaveTemplate()
  const {
    form: {control, register},
  } = useConfig()

  return (
    <Config title="Event Offline Page" onSave={save}>
      <SettingsPanel>
        <Settings control={control} register={register} />
      </SettingsPanel>
    </Config>
  )
}

import React from 'react'
import {Droppable} from 'react-beautiful-dnd'
import PriorityItem from 'Event/Question/SelectPriority/PriorityItem'
import {
  Box,
  DROPPABLE_ID_OPTIONS,
  getStyles,
} from 'Event/Question/SelectPriority'
import {Question} from 'organization/Event/QuestionsProvider'

export default function Options(props: {
  options: string[]
  question: Question
  onSelect: (index: number) => void
}) {
  const {options, onSelect} = props

  const styles = getStyles(props.question)

  return (
    <Droppable droppableId={DROPPABLE_ID_OPTIONS}>
      {(provided) => (
        <Box
          ref={provided.innerRef}
          {...provided.droppableProps}
          backgroundcolor={styles.boxBackgroundColor}
          textColor={styles.boxTextColor}
          borderRadius={styles.borderRadius}
        >
          <>
            {options.map((value: string, index: number) => (
              <PriorityItem
                key={value}
                index={index}
                backgroundColor={styles.optionBackgroundColor}
                textColor={styles.optionTextColor}
                borderRadius={styles.borderRadius}
                onClick={() => onSelect(index)}
              >
                {value}
              </PriorityItem>
            ))}
            {provided.placeholder}
          </>
        </Box>
      )}
    </Droppable>
  )
}

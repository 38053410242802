import React from 'react'
import PropertySelector from 'organization/Event/Services/Apps/Hubspot/Config/PropertySelector'
import {TargetProperty} from 'organization/Event/Services/Apps/Hubspot/Config'

export default function FieldInput(props: {
  tagsProperty: TargetProperty | null
  onChange: (selected: TargetProperty | null) => void
  errorText?: string
}) {
  const {onChange, tagsProperty, errorText} = props

  return (
    <PropertySelector
      value={tagsProperty}
      targetTypes={[
        'select',
        'text',
        'checkbox',
        'booleancheckbox',
        'radio',
        'phonenumber',
      ]}
      onChange={onChange}
      errorText={errorText}
      aria-label="field input"
    />
  )
}

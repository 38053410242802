import ColorPicker from 'lib/ui/ColorPicker'
import {onUnknownChangeHandler} from 'lib/dom'
import Select from 'lib/ui/Select'
import Option from 'lib/ui/Select/Option'
import {useLeftyTemplate} from 'Event/template/Lefty'
import React from 'react'
import {Controller, UseFormMethods} from 'react-hook-form'
import CollapsibleSection from 'lib/ui/ConfigPanel/CollapsibleSection'
import Slider from 'lib/ui/Slider'
import Checkbox from 'lib/ui/Checkbox'
import MarginPaddingInputs from 'lib/ui/MarginPaddingInputs'
import {Label} from 'lib/ui/typography'
import styled from 'styled-components'

const LOGO_POSITIONS = ['Top', 'Center', 'Bottom']

export default function Styling(props: {
  control: UseFormMethods['control']
  watch: UseFormMethods['watch']
}) {
  const {control} = props
  const template = useLeftyTemplate()

  return (
    <>
      <CollapsibleSection label="Logo" storageKey="dashboard.logo.styling">
        <Controller
          name="logoSize"
          defaultValue={template.logoSize}
          control={control}
          render={({value, onChange}) => (
            <Slider
              label="Size"
              min={0}
              max={100}
              step={1}
              onChange={onChange}
              valueLabelDisplay="auto"
              value={value}
              aria-label="logo size"
              unit="%"
            />
          )}
        />
        <Controller
          name="logoPosition"
          defaultValue={template.logoPosition}
          control={control}
          render={({value, onChange}) => (
            <Select
              fullWidth
              label="Position"
              value={value}
              onChange={onUnknownChangeHandler(onChange)}
            >
              {LOGO_POSITIONS.map((position, index) => (
                <Option value={position} key={index}>
                  {position}
                </Option>
              ))}
            </Select>
          )}
        />
        <Controller
          name="logoIsCenter"
          defaultValue={template.logoIsCenter || false}
          control={control}
          render={({value, onChange}) => (
            <StyledCheckBox
              label="Centering?"
              checked={value}
              aria-label="logo is centered"
              onChange={onChange}
            />
          )}
        />
        <Label>Padding</Label>
        <MarginPaddingInputs
          control={control}
          namePrefix="logoPadding"
          values={template.logoPadding}
          settings={{max: 300}}
        />
      </CollapsibleSection>
      <CollapsibleSection label="Menu" storageKey="dashboard.menu.styling">
        <Controller
          name="menu.backgroundColor"
          defaultValue={template.menu.backgroundColor}
          control={control}
          render={({value, onChange}) => (
            <ColorPicker
              label="Background Color"
              color={value}
              onPick={onChange}
              aria-label="menu background color"
            />
          )}
        />
        <Controller
          name="menu.textColor"
          defaultValue={template.menu.textColor}
          control={control}
          render={({value, onChange}) => (
            <ColorPicker
              label="Color"
              color={value}
              onPick={onChange}
              aria-label="menu text color"
            />
          )}
        />
        <Controller
          name="menu.iconColor"
          defaultValue={template.menu.iconColor}
          control={control}
          render={({value, onChange}) => (
            <ColorPicker
              label="Icon Color"
              color={value}
              onPick={onChange}
              aria-label="menu icon color"
            />
          )}
        />
      </CollapsibleSection>

      <CollapsibleSection label="Header" storageKey="dashboard.header.styling">
        <Controller
          name="header.backgroundColor"
          defaultValue={template.header.backgroundColor}
          control={control}
          render={({value, onChange}) => (
            <ColorPicker
              label="Background Color"
              color={value}
              onPick={onChange}
              aria-label="header background color"
            />
          )}
        />
        <Controller
          name="header.backgroundOpacity"
          defaultValue={template.header.backgroundOpacity || 0}
          control={control}
          render={({value, onChange}) => (
            <Slider
              label="Background Color Opacity"
              min={0}
              max={100}
              step={1}
              onChange={onChange}
              valueLabelDisplay="auto"
              value={value}
              aria-label="background opacity"
              unit="%"
            />
          )}
        />
      </CollapsibleSection>
      <CollapsibleSection label="Page" storageKey="dashboard.page.styling">
        <Controller
          name="pageBackground.color"
          defaultValue={template.pageBackground?.color}
          control={control}
          render={({value, onChange}) => (
            <ColorPicker
              label="Background Color"
              color={value}
              onPick={onChange}
              aria-label="page background color"
            />
          )}
        />
        <Controller
          name="pageBackground.opacity"
          defaultValue={template.pageBackground?.opacity || 0}
          control={control}
          render={({value, onChange}) => (
            <Slider
              label="Background Color Opacity"
              min={0}
              max={100}
              step={1}
              onChange={onChange}
              valueLabelDisplay="auto"
              value={value}
              aria-label="page background color opacity"
              unit="%"
            />
          )}
        />
        <Controller
          name="pageBackgroundPosition"
          defaultValue={template.pageBackgroundPosition}
          control={control}
          render={({value, onChange}) => (
            <Select
              fullWidth
              label="Background Position"
              value={value}
              onChange={onUnknownChangeHandler(onChange)}
            >
              <Option value="fixed">Fixed</Option>
              <Option value="bottom">Bottom</Option>
            </Select>
          )}
        />
        <Controller
          name="contentOverlap"
          defaultValue={template.contentOverlap}
          control={control}
          render={({value, onChange}) => (
            <Slider
              label="Content Overlap"
              min={0}
              max={32}
              step={1}
              onChange={onChange}
              valueLabelDisplay="off"
              hideUnits
              value={value}
              aria-label="content overlap"
            />
          )}
        />
      </CollapsibleSection>
    </>
  )
}

const StyledCheckBox = styled(Checkbox)`
  margin-bottom: ${(props) => props.theme.spacing[4]};
`

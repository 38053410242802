import React from 'react'
import styled from 'styled-components'
import {Draggable} from 'react-beautiful-dnd'
import TableRow, {TableRowProps} from '@material-ui/core/TableRow'
import {useEvent} from 'Event/EventProvider'
import RuleList from 'organization/Event/RuleList'
import {Waiver} from 'Event/template'
import {useToggleArray} from 'lib/toggle'
import ConfigDialog from 'organization/Event/WaiverConfig/AdditionalWaivers/WaiversTable/WaiverRow/ConfigDialog'
import {
  PriorityCell,
  RulesCell,
  TitleCell,
} from 'organization/Event/WaiverConfig/AdditionalWaivers/WaiversTable/WaiversTable'

export default function WaiverRow(props: {
  id: string
  index: number
  waiver: Waiver
}) {
  const {id, index, waiver} = props
  const {event} = useEvent()

  const [showingConfig, toggleConfig] = useToggleArray()

  return (
    <>
      <ConfigDialog
        showing={showingConfig}
        id={id}
        waiver={waiver}
        onClose={toggleConfig}
      />
      <Draggable draggableId={id} index={index}>
        {(provided, snapshot) => (
          <StyledTableRow
            aria-label="additional waiver"
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            ref={provided.innerRef}
            onClick={toggleConfig}
            isDragging={snapshot.isDragging}
          >
            <PriorityCell>{index + 1}</PriorityCell>
            <TitleCell>{waiver.title || event.name}</TitleCell>
            <RulesCell>
              <RuleList rules={waiver.rules || []} />
            </RulesCell>
          </StyledTableRow>
        )}
      </Draggable>
    </>
  )
}

const StyledTableRow = styled(
  React.forwardRef<
    HTMLTableRowElement,
    {
      isDragging?: boolean
    } & TableRowProps
  >(({isDragging: _, ...otherProps}, ref) => (
    <TableRow {...otherProps} ref={ref} />
  )),
)`
  background: #ffffff;
  cursor: pointer;

  display: ${(props) => (props.isDragging ? 'table' : 'table-row')};
`

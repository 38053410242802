import React from 'react'
import {useOrganization} from 'organization/OrganizationProvider'
import {useEvent} from 'Event/EventProvider'
import {api} from 'lib/url'
import ScriptForm, {SaveScriptData} from 'organization/Event/Scripts/ScriptForm'
import ScriptDialog from 'organization/Event/Scripts/AddScriptButton/ScriptDialog'
import {ALL_PAGES} from 'Event/Scripts'
import {useSaveTemplate} from 'Event/TemplateUpdateProvider'
import {uuid} from 'lib/uuid'

export default function NewScriptDialog(props: {
  showing: boolean
  onClose: () => void
}) {
  const {showing, onClose} = props
  const add = useAddScript()
  const saveTemplate = useSaveTemplate()

  const handleSubmit = (data: SaveScriptData) =>
    add(data.file).then(({file_url}) => {
      saveTemplate({
        scripts: {
          [uuid()]: {
            name: data.name,
            file: data.file,
            url: file_url,
            pages: data.pages,
          },
        },
      })

      onClose()
    })

  return (
    <ScriptDialog showing={showing} onClose={onClose} title="Add Script">
      <ScriptForm onSubmit={handleSubmit} defaultPages={ALL_PAGES} />
    </ScriptDialog>
  )
}

function useAddScript() {
  const {event} = useEvent()
  const {client} = useOrganization()
  const url = api(`/events/${event.id}/scripts`)

  return (file: string) => {
    return client.post<{file_url: string; file_name: string}>(url, {
      file_name: file,
    })
  }
}

import styled from 'styled-components'
import React from 'react'
import {useLocalTime} from 'lib/date-time'
import {
  BlogSectionProps,
  TownhallBlogPost,
} from 'Event/template/Townhall/Dashboard/Main/BlogSection'

export default function Date(props: {
  post: TownhallBlogPost
  postStyles?: BlogSectionProps['postStyles']
  TextComponent?: React.FC
  textColor?: string
}) {
  const {post, postStyles, TextComponent = Text, textColor} = props

  const date = post.publishAt || post.postedAt
  const localTime = useLocalTime()

  if (post.hideDate) {
    return null
  }

  return (
    <TextComponent
      dateColor={postStyles?.dateTextColor}
      textColor={textColor}
      fontSize={postStyles?.dateFontSize}
      aria-label="blog post publish date"
    >
      {localTime(date)}
    </TextComponent>
  )
}

const Text = styled.span<{
  dateColor?: string | null
  textColor?: string
  fontSize?: number
}>`
  font-size: ${(props) => (props.fontSize ? props.fontSize : 14)}px;
  color: ${(props) => (props.dateColor ? props.dateColor : props.textColor)};
  display: block;
  margin-bottom: ${(props) => props.theme.spacing[4]};
`

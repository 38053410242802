import CollapsibleSection from 'lib/ui/ConfigPanel/CollapsibleSection'
import {useConfig} from 'organization/Event/Configurable'
import Config from 'organization/Event/Configurable/Config'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import StylingPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/StylingPanel'
import {useUpdateTemplate} from 'organization/Marketplace/PurchasePageConfig/TemplateUpdateProvider'
import React from 'react'
import {Controller} from 'react-hook-form'
import ColorPicker from 'lib/ui/ColorPicker'
import Slider from 'lib/ui/Slider'
import ImageAssetUploader from 'lib/asset/ImageAssetUploader'
import MarginPaddingInputs from 'lib/ui/MarginPaddingInputs'
import MultilineTextField from 'lib/ui/TextField/TextArea'
import InputLabel from 'lib/ui/TextField/InputLabel'
import BackgroundPositionSelector from 'lib/ui/BackgroundPositionSelector'
import PositionSelectorButton from 'lib/ui/BackgroundPositionSelector/BackgroundPositionSelectorButton'
import {useToggleArray} from 'lib/toggle'
import BackgroundRepeatSelector from 'lib/ui/BackgroundRepeatSelector'
import TextField from 'lib/ui/TextField'
import FontSelect from 'lib/FontSelect'
import {Label} from 'lib/ui/typography'
import {PurchasePageTemplate} from 'Event/Marketplace/purchase-page'

export default function PageSettings(props: {template: PurchasePageTemplate}) {
  const {template} = props
  const {
    background,
    padding,
    scripts,
    title,
    description,
    favicon,
    typography,
    contentMaxWidth,
  } = template

  const {font, fontSize, textColor} = typography

  const updateTemplate = useUpdateTemplate()
  const {
    form: {register, control},
  } = useConfig()
  const [showingOptions, toggleOptions] = useToggleArray()

  return (
    <Config title="Page Settings" onSave={updateTemplate}>
      <SettingsPanel>
        <TextField
          name="title"
          defaultValue={title}
          label="Title"
          inputProps={{
            'aria-label': 'page title',
            ref: register,
          }}
        />
        <TextField
          name="description"
          defaultValue={description}
          label="Description"
          inputProps={{
            'aria-label': 'page description',
            ref: register,
          }}
        />
        <Controller
          name="favicon"
          control={control}
          defaultValue={favicon}
          render={({onChange, value}) => (
            <ImageAssetUploader
              onChange={onChange}
              value={value}
              uploadLabel="Favicon"
              uploadInputProps={{
                'aria-label': 'page favicon',
              }}
              width={32}
              height={32}
              disableAutoRemove
            />
          )}
        />
        <InputLabel>Scripts</InputLabel>
        <MultilineTextField
          name="scripts"
          defaultValue={scripts || ''}
          rows={10}
          fullWidth
          inputProps={{
            'aria-label': 'page scripts',
            ref: register,
          }}
        />
      </SettingsPanel>
      <StylingPanel>
        <CollapsibleSection
          label="Typography"
          storageKey="purchase-page-typography"
        >
          <Label>Font</Label>
          <Controller
            control={control}
            name="typography.font"
            defaultValue={font}
            render={({value, onChange}) => (
              <FontSelect value={value} onChange={onChange} />
            )}
          />
          <Controller
            name="typography.fontSize"
            defaultValue={fontSize}
            control={control}
            render={({value, onChange}) => (
              <Slider
                min={1}
                max={100}
                onChange={onChange}
                valueLabelDisplay="off"
                value={value}
                label="Font Size"
              />
            )}
          />
          <Controller
            name="typography.textColor"
            control={control}
            defaultValue={textColor}
            render={({value, onChange}) => (
              <ColorPicker label="Color" color={value} onPick={onChange} />
            )}
          />
        </CollapsibleSection>
        <CollapsibleSection
          label="Background"
          storageKey={`purchase-page-background`}
        >
          <Controller
            name="background.image"
            control={control}
            defaultValue={background.image}
            render={({onChange, value}) => (
              <ImageAssetUploader
                onChange={onChange}
                value={value}
                uploadLabel="Upload"
                uploadInputProps={{
                  'aria-label': 'page background image',
                }}
                width={1920}
                height={1200}
                canResize
                disableAutoRemove
                additionalActions={
                  <PositionSelectorButton onClick={toggleOptions} />
                }
              />
            )}
          />
          <Controller
            name="background.position"
            control={control}
            defaultValue={background.position}
            render={({onChange, value}) => (
              <BackgroundPositionSelector
                showing={showingOptions}
                onChange={onChange}
                value={value}
                label="Background Position"
              />
            )}
          />
          <Controller
            name="background.repeat"
            defaultValue={background.repeat}
            control={control}
            render={({value, onChange}) => (
              <BackgroundRepeatSelector
                showing={showingOptions}
                onChange={onChange}
                value={value}
                label="Background Repeat"
              />
            )}
          />
          <Controller
            name="background.color"
            defaultValue={background.color}
            control={control}
            render={({value, onChange}) => (
              <ColorPicker
                label="Color"
                color={value}
                onPick={onChange}
                aria-label="page background color"
              />
            )}
          />
          <Controller
            name="background.opacity"
            defaultValue={background.opacity}
            control={control}
            render={({value, onChange}) => (
              <Slider
                label="Opacity"
                unit="%"
                valueLabelDisplay="auto"
                aria-label="page background opacity"
                value={value}
                onChange={onChange}
                step={1}
                min={0}
                max={100}
              />
            )}
          />
        </CollapsibleSection>
        <CollapsibleSection label="Spacing" storageKey="purchase-page-spacing">
          <MarginPaddingInputs
            control={control}
            namePrefix="padding"
            values={padding}
            settings={{max: 300}}
          />
        </CollapsibleSection>
        <Controller
          name="contentMaxWidth"
          defaultValue={contentMaxWidth}
          control={control}
          render={({value, onChange}) => (
            <Slider
              label="Constrained Content Max Width"
              unit="px"
              valueLabelDisplay="auto"
              aria-label="content max width"
              value={value}
              onChange={onChange}
              step={1}
              min={720}
              max={1280}
            />
          )}
        />
      </StylingPanel>
    </Config>
  )
}

import React from 'react'
import {Controller, UseFormMethods} from 'react-hook-form'
import {FontStyle} from 'lib/ui/typography/FontStyleInput'
import {TicketRibbon} from 'Event/template/Panels/Dashboard/TicketRibbonList/Ribbon'
import ColorPicker from 'lib/ui/ColorPicker'
import FontStyleInput from 'lib/ui/typography/FontStyleInput'
import {DeepRequired} from 'lib/type-utils'

export default function Styling(props: {
  control: UseFormMethods['control']
  ticketRibbon: TicketRibbon
  hoverTextFontStyles: DeepRequired<FontStyle>[]
  setHoverTextFontStyles: (v: DeepRequired<FontStyle>[]) => void
}) {
  const {
    control,
    ticketRibbon,
    hoverTextFontStyles,
    setHoverTextFontStyles,
  } = props

  return (
    <>
      <Controller
        name="backgroundColor"
        control={control}
        defaultValue={ticketRibbon.backgroundColor}
        render={({value, onChange}) => (
          <ColorPicker
            label="Background Color"
            color={value}
            onPick={onChange}
          />
        )}
      />
      <Controller
        name="textColor"
        control={control}
        defaultValue={ticketRibbon.textColor}
        render={({value, onChange}) => (
          <ColorPicker label="Text Color" color={value} onPick={onChange} />
        )}
      />
      <FontStyleInput
        onChange={setHoverTextFontStyles}
        value={hoverTextFontStyles || []}
      />
    </>
  )
}

import React from 'react'
import Page from 'Event/template/NiftyFifty/Page'
import LeftPanel from 'Event/template/NiftyFifty/check-in/LeftPanel'
import RightPanel from 'Event/template/NiftyFifty/check-in/RightPanel'
import MobilePanel from 'Event/template/NiftyFifty/check-in/MobilePanel'
import Body from 'Event/Step3/TechCheck/Body'
import Buttons from 'Event/Step3/TechCheck/Buttons'
import AdditionalContent from 'Event/Step3/TechCheck/AdditionalContent'
import ConfigFields from 'organization/Event/TechCheckConfig/ConfigFields'
import styled from 'styled-components'
import ProgressBar from 'Event/template/NiftyFifty/check-in/ProgressBar'

export default function TechCheck() {
  return (
    <Page
      Left={<LeftPanel />}
      Right={
        <RightPanel>
          <ProgressBar textKey="step3Text" percentKey="step3Percent" />
          <Content />
        </RightPanel>
      }
      Mobile={
        <MobilePanel>
          <Content />
        </MobilePanel>
      }
    />
  )
}

function Content() {
  return (
    <Container>
      <ConfigFields />
      <Body />
      <Buttons />
      <AdditionalContent />
    </Container>
  )
}

const Container = styled.div`
  width: 60%;
  padding-top: ${(props) => props.theme.spacing[10]};
  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    width: 100%;
    max-height: 80vh;
    overflow-y: auto;
  }
`

import React from 'react'
import {Controller, UseFormMethods} from 'react-hook-form'
import {useCardsTemplate} from 'Event/template/Cards'
import Title from 'lib/ui/ConfigPanel/Title'
import {Label} from 'lib/ui/typography'
import Slider from 'lib/ui/Slider'
import FontSelect from 'lib/FontSelect'
import ColorPicker from 'lib/ui/ColorPicker'

const MIN_WELCOME_MESSAGE_FONT_SIZE = 16
const MAX_WELCOME_MESSAGE_FONT_SIZE = 50
const DEFAULT_FONT_SIZE = 42

export default function Styling(props: {control: UseFormMethods['control']}) {
  const {control} = props
  const {welcomeMessage, textColor} = useCardsTemplate()
  const welcomeColor = welcomeMessage.textColor
    ? welcomeMessage.textColor
    : textColor

  return (
    <>
      <Title>Typography</Title>
      <Label>Font</Label>
      <Controller
        control={control}
        name="font"
        defaultValue={welcomeMessage.font || null}
        render={({value, onChange}) => (
          <FontSelect value={value} onChange={onChange} />
        )}
      />
      <Controller
        name="textColor"
        control={control}
        defaultValue={welcomeColor}
        render={({value, onChange}) => (
          <ColorPicker
            label="Font Color"
            color={value}
            onPick={onChange}
            aria-label="welcome message text color"
          />
        )}
      />
      <Controller
        name="fontSize"
        defaultValue={welcomeMessage.fontSize || DEFAULT_FONT_SIZE}
        control={control}
        render={({value, onChange}) => (
          <Slider
            min={MIN_WELCOME_MESSAGE_FONT_SIZE}
            max={MAX_WELCOME_MESSAGE_FONT_SIZE}
            onChange={onChange}
            valueLabelDisplay="auto"
            value={value}
            aria-label="welcome message text font size"
            label="Font Size"
          />
        )}
      />
    </>
  )
}

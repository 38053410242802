import styled from 'styled-components'
import React from 'react'
import {background, useNiftyFiftyTemplate} from 'Event/template/NiftyFifty'
import Configurable, {
  Clickable,
  PageConfigurable,
} from 'organization/Event/Configurable'
import LeftPanelConfig from 'Event/template/NiftyFifty/check-in/LeftPanel/LeftPanelConfig'

export default function LeftPanel() {
  const template = useNiftyFiftyTemplate()
  const {checkInLeftPanel, checkInLogo, checkInBackground} = template
  return (
    <PageConfigurable aria-label="left panel edit">
      <LeftPanelConfig />
      <Box
        background={background({
          enabled: checkInBackground.enabled,
          image: checkInBackground.image,
          color: checkInLeftPanel.backgroundColor,
          opacity: checkInLeftPanel.backgroundOpacity / 100,
        })}
      >
        <StepLogo
          src={checkInLogo.image}
          size={checkInLogo.size}
          enabled={checkInLogo.enabled}
        />
      </Box>
    </PageConfigurable>
  )
}

export function StepLogo(props: {
  src: string | null
  size: number
  enabled: boolean
}) {
  if (!props.src || !props.enabled) {
    return null
  }

  return <StyledStepLogo src={props.src} size={props.size} alt="step logo" />
}

const Box = styled.div<{
  background: string
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${(props) => props.background};
  border-radius: 10px;
  overflow: auto;
  background-size: cover;
  position: relative;
  border-radius: 0px;
  width: 100%;
  height: 100%;
`

const StyledStepLogo = styled.img<{size: number}>`
  width: ${(props) => props.size}%;
  height: auto;
  margin: 0 auto;
`

export const FullHeightConfigurable = styled(Configurable)`
  > ${Clickable} {
    position: absolute;
    width: 100%;
    top: 0;
    height: 100%;
    display: flex;

    > div {
      flex: 1;
    }
  }
`

import React, {useEffect, useState} from 'react'
import {useServices} from 'organization/Event/Services/ServicesProvider'
import {useOrganization} from 'organization/OrganizationProvider'
import {api} from 'lib/url'
import {useEvent} from 'Event/EventProvider'
import Select from 'lib/ui/Select'
import {onUnknownChangeHandler} from 'lib/dom'
import {
  MailchimpIntegration,
  useMailchimp,
} from 'organization/Event/Services/Apps/Mailchimp/index'
import FormHelperText from '@material-ui/core/FormHelperText'
import ConfirmDialog from 'lib/ui/ConfirmDialog'
import {useMailchimpAudiences} from 'lib/event-api/integrations/mailchimp/audiences/list'
import Option from 'lib/ui/Select/Option'

export default function AudienceSelect(props: {disabled?: boolean}) {
  const {disabled} = props

  const {event} = useEvent()
  const {client} = useOrganization()
  const {data: audiences = [], isLoading} = useMailchimpAudiences({
    event,
    client,
  })
  const {error, setId} = useSetAudienceId()
  const {audience_id} = useMailchimp()
  const [selectedId, setSelectedId] = useState(audience_id || '')

  useEffect(() => {
    setSelectedId(audience_id || '')
  }, [audience_id])

  const handleConfirm = () => {
    setId(selectedId)
  }

  const handleSelect = (confirm: () => void) => (id: string) => {
    /**
     * If an audience has already been selected, let's show a confirmation dialog
     * to the user.
     */
    const hasExistingAudience = Boolean(audience_id)
    if (hasExistingAudience) {
      setSelectedId(id)
      confirm()
      return
    }

    setId(id)
  }

  return (
    <ConfirmDialog
      onConfirm={handleConfirm}
      description="Existing attendees that have been imported will remain in the system."
    >
      {(confirm) => (
        <>
          <Select
            disabled={isLoading || disabled}
            value={audience_id || ''}
            onChange={onUnknownChangeHandler(handleSelect(confirm))}
            fullWidth
            aria-label={'pick audience id'}
            variant="outlined"
            error={Boolean(error)}
            label={'Audience'}
          >
            {audiences.map((audience) => (
              <Option
                key={audience.id}
                value={audience.id}
                aria-label={`pick ${audience.name}`}
              >
                {audience.name}
              </Option>
            ))}
          </Select>
          <FormHelperText error={Boolean(error)} hidden={!error}>
            {error}
          </FormHelperText>
        </>
      )}
    </ConfirmDialog>
  )
}

function useSetAudienceId() {
  const {client} = useOrganization()
  const {event} = useEvent()
  const {update} = useServices()
  const [error, setError] = useState('')

  const setId = (id: string) =>
    client
      .put<MailchimpIntegration>(
        api(`/events/${event.id}/integrations/mailchimp/audience_id`),
        {
          id,
        },
      )
      .then(update)
      .catch((e) => {
        setError(e.message)
      })

  return {error, setId}
}

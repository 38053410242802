import TextField from 'lib/ui/TextField'
import {Label} from 'lib/ui/typography'
import React from 'react'
import Slider from 'lib/ui/Slider'
import {DEFAULT_FOOTER_IMAGE_SIZE} from 'Event/template/Cards/Dashboard/Footer/FooterConfig'
import {Controller} from 'react-hook-form'
import ImageAssetUploader from 'lib/asset/ImageAssetUploader'
import {useTownhallTemplate} from 'Event/template/Townhall'
import {useConfig} from 'organization/Event/Configurable'

const MIN_FOOTER_IMAGE_SIZE = 1
const MAX_FOOTER_IMAGE_SIZE = 550

export default function Settings() {
  const {
    form: {control},
  } = useConfig()

  const {footer} = useTownhallTemplate()

  return (
    <>
      <Controller
        name="footer.image"
        control={control}
        defaultValue={footer.image}
        render={({onChange, value}) => (
          <ImageAssetUploader
            onChange={onChange}
            value={value}
            uploadLabel="Upload"
            canResize
            uploadInputProps={{
              'aria-label': 'footer image upload',
            }}
            disableAutoRemove
          />
        )}
      />
      <Controller
        name="footer.imageSize"
        control={control}
        defaultValue={footer.imageSize || DEFAULT_FOOTER_IMAGE_SIZE}
        render={({value, onChange}) => (
          <Slider
            min={MIN_FOOTER_IMAGE_SIZE}
            max={MAX_FOOTER_IMAGE_SIZE}
            step={1}
            onChange={onChange}
            valueLabelDisplay="auto"
            value={value}
            aria-label="footer image size"
            label="Image Size"
          />
        )}
      />
      <Label>Terms Link</Label>
      <Controller
        name="footer.termsLink"
        control={control}
        defaultValue={footer.termsLink || ''}
        render={({value, onChange}) => (
          <TextField
            value={value}
            aria-label="set footer terms link"
            fullWidth
            onChange={onChange}
          />
        )}
      />
      <Label>Privacy Link</Label>
      <Controller
        name="footer.privacyLink"
        control={control}
        defaultValue={footer.privacyLink || ''}
        render={({value, onChange}) => (
          <TextField
            value={value}
            aria-label="set privacy terms link"
            fullWidth
            onChange={onChange}
          />
        )}
      />
      <Label>Copyright Text</Label>
      <Controller
        name="footer.copyrightText"
        control={control}
        defaultValue={footer.copyrightText || ''}
        render={({value, onChange}) => (
          <TextField
            aria-label="set copyright text"
            fullWidth
            value={value}
            onChange={onChange}
          />
        )}
      />
    </>
  )
}

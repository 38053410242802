import React, {useState} from 'react'
import ComponentConfig, {
  ComponentConfigProps,
  SaveButton,
  Footer,
} from 'organization/Event/DashboardConfig/ComponentConfigPanel'
import {AgendaListProps} from 'Event/template/Cards/Dashboard/Sidebar/SidebarItem/AgendaList'
import {
  useEditSidebarItem,
  useAutoUpdateSidebarItem,
} from 'Event/template/Cards/Dashboard/Sidebar/SidebarItem'
import {Rule} from 'Event/attendee-rules'
import Settings from 'Event/template/Cards/Dashboard/Sidebar/SidebarItem/AgendaList/AgendaListConfig/Settings'
import {useForm} from 'react-hook-form'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import RulesPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/RulesPanel'
import Styling from 'Event/template/Cards/Dashboard/Sidebar/SidebarItem/AgendaList/AgendaListConfig/Styling'
import StylingPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/StylingPanel'

export function AgendaListConfig(
  props: ComponentConfigProps & {
    list: AgendaListProps
  },
) {
  const {showing, onClose, list} = props
  const [rules, setRules] = useState<Rule[]>(list.rules || [])

  const {update: updateItem} = useEditSidebarItem()
  const {control, register, handleSubmit, watch, formState} = useForm()

  const save = (data: AgendaListProps) => {
    updateItem({...data, rules})
    onClose()
  }

  useAutoUpdateSidebarItem({item: watch(), when: showing})

  return (
    <ComponentConfig
      showing={showing}
      onClose={onClose}
      title="Agendas"
      onSubmit={handleSubmit(save)}
      hasChanges={formState.isDirty}
    >
      <SettingsPanel>
        <Settings control={control} register={register} list={list} />
      </SettingsPanel>
      <StylingPanel>
        <Styling control={control} list={list} />
      </StylingPanel>
      <RulesPanel rules={rules} setRules={setRules} />
      <Footer>
        <SaveButton />
      </Footer>
    </ComponentConfig>
  )
}

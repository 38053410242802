import React from 'react'
import {NavButtonWithSize} from 'Event/Dashboard/components/NavButton'
import ComponentConfig, {
  ComponentConfigProps,
  RemoveButton,
  SaveButton,
  Footer,
} from 'organization/Event/DashboardConfig/ComponentConfigPanel'
import BaseSettings from 'Event/Dashboard/components/NavButton/NavButtonConfig/BaseConfig/Settings'
import BaseStyling from 'Event/Dashboard/components/Sidebar/SidebarNavButtonConfig/Styling'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import StylingPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/StylingPanel'
import RulesPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/RulesPanel'
import ScheduleInput from 'organization/Event/DashboardConfig/ComponentConfigPanel/ScheduleInput'
import {UseFormMethods} from 'react-hook-form'
import {Rule} from 'Event/attendee-rules'
import {SidebarNavProps} from 'Event/template'

export default function SidebarNavButtonConfigBase(
  props: ComponentConfigProps & {
    button: NavButtonWithSize
    id?: string
    nav?: SidebarNavProps
    watch: UseFormMethods['watch']
    control: UseFormMethods['control']
    register: UseFormMethods['register']
    formState: UseFormMethods['formState']
    setValue: (
      name: string,
      value: any,
      config?:
        | Partial<{shouldValidate: boolean; shouldDirty: boolean}>
        | undefined,
    ) => void
    rules: Rule[] | undefined
    setRules: any
    removeButton: () => void
    handleSubmit: any
    save: any
  },
) {
  const {
    button,
    id,
    showing,
    onClose,
    watch,
    control,
    register,
    setValue,
    rules,
    setRules,
    removeButton,
    handleSubmit,
    formState,
    save,
  } = props

  return (
    <ComponentConfig
      title="Sidebar Nav Button"
      showing={showing}
      onClose={onClose}
      onSubmit={handleSubmit(save)}
      hasChanges={formState.isDirty}
    >
      <SettingsPanel>
        <BaseSettings
          control={control}
          register={register}
          watch={watch}
          button={button}
          setValue={setValue}
          id={props.id}
          customSettings={false}
        />
      </SettingsPanel>
      <StylingPanel>
        <BaseStyling control={control} button={button} watch={watch} />
      </StylingPanel>
      <RulesPanel rules={rules} setRules={setRules}>
        <ScheduleInput setValue={setValue} control={control} values={button} />
      </RulesPanel>
      <Footer>
        <SaveButton type="submit" />
        <RemoveButton
          showing={Boolean(id)}
          aria-label="remove button"
          onClick={removeButton}
        />
      </Footer>
    </ComponentConfig>
  )
}

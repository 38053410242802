import React, {useMemo} from 'react'
import {PieChart, Pie, Cell, ResponsiveContainer} from 'recharts'
import {useTheme} from 'styled-components'

const RADIAN = Math.PI / 180

const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
}: any) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5
  const x = cx + radius * Math.cos(-midAngle * RADIAN)
  const y = cy + radius * Math.sin(-midAngle * RADIAN)

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? 'start' : 'end'}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  )
}

export default function Chart(props: {
  total: number
  checked_in: number
  radius: number
}) {
  const {total, checked_in: checkedIn, radius} = props

  const theme = useTheme()

  const colors = useMemo(
    () => [theme.colors.chart.background, theme.colors.primary],
    [theme],
  )

  const data = [
    {
      name: 'Not checked in',
      value: total - checkedIn,
    },
    {
      name: 'Checked in',
      value: checkedIn,
    },
  ]

  return (
    <ResponsiveContainer>
      <PieChart>
        <Pie
          data={data}
          cx="50%"
          cy="50%"
          labelLine={false}
          label={renderCustomizedLabel}
          outerRadius={radius}
          stroke="none"
          dataKey="value"
        >
          {data.map((_, index: number) => (
            <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
          ))}
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  )
}

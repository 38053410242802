import {
  ATTENDEE_CHECKED_IN,
  ATTENDEE_CREATED,
  ATTENDEE_SIGNED_WAIVER,
  Tag,
} from 'organization/Event/Services/Apps/HighLevel'
import TagsAutocomplete from 'organization/Event/Services/Apps/HighLevel/Config/TagsAutocomplete'
import React from 'react'

export default function TagIdInput(props: {
  tag: Tag
  onChange: (tag: Tag) => void
  error?: string
}) {
  const {tag, onChange} = props

  const setTagName = (tagName: string | null) => {
    const updatedTag = {
      ...tag,
      name: tagName,
    }

    onChange(updatedTag)
  }

  return (
    <TagsAutocomplete
      value={tag.name}
      onChange={setTagName}
      inputVariant={'outlined'}
      inputLabel={label(tag)}
    />
  )
}

function label(tag: Tag) {
  const isSet = Boolean(tag.name)
  return isSet ? `${typeLabel(tag)} - ${tag.name}` : `${typeLabel(tag)}`
}

function typeLabel(tag: Tag) {
  const labels: Record<string, string> = {
    [ATTENDEE_CREATED]: 'Attendee Created',
    [ATTENDEE_SIGNED_WAIVER]: 'Attendee Signed Waiver',
    [ATTENDEE_CHECKED_IN]: 'Attendee Checked In',
  }

  const label = labels[tag.type]
  if (!label) {
    throw new Error(`Label not defined for tag type: ${tag.type}`)
  }

  return label
}

import {useEvent} from 'Event/EventProvider'
import {Attendee} from 'Event/attendee'
import {client} from 'lib/ui/api-client'
import {api} from 'lib/url'
import {useMutation} from 'react-query'

interface PurchaseTicketData {
  ticket_id: number
  first_name: string
  last_name: string
  email: string
  phone_number: string
  payment_method_id: string | null
  billing_address?: {
    address_1: string
    address_2?: string
    city: string
    state_id: string
    zip_postal: string
    country_id: string
  }
  shipping_address?: {
    address_1: string
    address_2?: string
    city: string
    state_id: string
    zip_postal: string
    country_id: string
  }
}

export function usePurchaseTicket() {
  const {event} = useEvent()

  return useMutation({
    mutationFn: (data: PurchaseTicketData) => {
      const url = api(`/events/${event.id}/purchase_ticket`)
      return client.post<Attendee>(url, data)
    },
    onSuccess: (attendee) => {
      window.location.href = attendee.login_url
    },
  })
}

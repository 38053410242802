import React from 'react'
import styled from 'styled-components'
import MuiTab, {TabProps as MuiTabProps} from '@material-ui/core/Tab'

export type TabProps = {children: JSX.Element} & MuiTabProps

export default function Tab(props: MuiTabProps) {
  const {children, ...otherProps} = props

  return <StyledTab {...otherProps} label={children} disableRipple />
}

const StyledTab = styled(MuiTab)`
  background-color: ${(props) => props.theme.colors.input.dark} !important;
  margin: 0px 1px;
  max-width: 100%;
  flex: 1;

  &.MuiTab-root {
    margin: 0px 1px;
    background-color: ${(props) => props.theme.colors.input.dark};
  }
  &.Mui-selected {
    background-color: ${(props) => props.theme.colors.primary};
  }
`

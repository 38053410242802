import React from 'react'
import styled from 'styled-components'
import TextField, {StyledInput} from 'lib/ui/TextField'
import {Group} from 'organization/Event/AttendeesProvider'
import RemoveButton from 'lib/ui/form/RemoveButton'

export default function GroupInput(props: {
  group: Group
  onChange: (group: Group) => void
  remove: () => void
}) {
  const {group, onChange, remove} = props

  const update = (key: keyof Group) => (value: string) => {
    const updated = {
      ...group,
      [key]: value,
    }

    onChange(updated)
  }

  return (
    <Box>
      <RemoveButton onClick={remove} aria-label="remove group" />

      <Input>
        <TextField
          label="Group"
          fullWidth
          variant="outlined"
          inputProps={{'aria-label': 'group name'}}
          value={group.key}
          onChange={update('key')}
        />
      </Input>
      <Input>
        <TextField
          label="Value"
          fullWidth
          variant="outlined"
          inputProps={{'aria-label': 'group value'}}
          value={group.value}
          onChange={update('value')}
        />
      </Input>
    </Box>
  )
}

const Box = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`

const Input = styled.div`
  margin-right: ${(props) => props.theme.spacing[2]};
  overflow: hidden;

  ${StyledInput} {
    width: 100%;
  }
`

import {useTemplate} from 'Event/TemplateProvider'
import {useSaveTemplate} from 'Event/TemplateUpdateProvider'
import ColorPicker from 'lib/ui/ColorPicker'
import TextField from 'lib/ui/TextField'
import {useConfig} from 'organization/Event/Configurable'
import Config from 'organization/Event/Configurable/Config'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import StylingPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/StylingPanel'
import React from 'react'
import {Controller} from 'react-hook-form'

export default function SignatureBoxConfig() {
  const {
    form: {register, control},
  } = useConfig()
  const save = useSaveTemplate()
  const {waiver: settings} = useTemplate()

  return (
    <Config title="Signature" onSave={save}>
      <SettingsPanel>
        <TextField
          name="waiver.signature.typeButtonText"
          defaultValue={settings.signature.typeButtonText}
          label="Type Button Text"
          fullWidth
          inputProps={{
            'aria-label': 'signature type button text',
            ref: register,
          }}
        />
        <TextField
          name="waiver.signature.inputLabelText"
          defaultValue={settings.signature.typeInputLabel}
          label="Input Label Text"
          fullWidth
          inputProps={{
            'aria-label': 'signature input label text',
            ref: register,
          }}
        />
      </SettingsPanel>
      <StylingPanel>
        <Controller
          name="waiver.signature.typeButtonColor"
          defaultValue={settings.signature.typeButtonColor}
          control={control}
          render={({value, onChange}) => (
            <ColorPicker
              label="Type Button Color"
              color={value}
              onPick={onChange}
              aria-label="signature type button color"
            />
          )}
        />
      </StylingPanel>
    </Config>
  )
}

import React, {useState} from 'react'

type EditModeContextProps = {
  isEditMode: boolean
  setEditMode: (editMode: boolean) => void
}

export const EditModeContext = React.createContext<
  undefined | EditModeContextProps
>(undefined)

export default function EditModeProvider(props: {children: JSX.Element}) {
  const [isEditMode, setEditMode] = useState(false)

  return (
    <EditModeContext.Provider
      value={{
        isEditMode,
        setEditMode,
      }}
    >
      {props.children}
    </EditModeContext.Provider>
  )
}

function useEditContext() {
  const context = React.useContext(EditModeContext)

  if (!context) {
    throw new Error('useEditContext must be used within EditModeProvider')
  }

  return context
}

export function useEditMode() {
  const context = useEditContext()
  return context.isEditMode
}

export function useSetEditMode() {
  const context = useEditContext()
  return context.setEditMode
}

import React, {useCallback} from 'react'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import RadioGroup from '@material-ui/core/RadioGroup'
import Grid from '@material-ui/core/Grid'
import Radio from '@material-ui/core/Radio'
import Checkbox from 'lib/ui/Checkbox'
import {onUnknownChangeHandler} from 'lib/dom'
import {ALL_PAGES, Page} from 'Event/Scripts'

type Mode = 'all' | 'selected'

export default function PageSelector(props: {
  value: Page[]
  onChange: (pages: Page[]) => void
}) {
  const {onChange, value} = props

  const targetMode: Mode = hasSelectedAll(value) ? 'all' : 'selected'

  const selectTargetMode = useCallback(
    (mode: Mode) => {
      const pages = mode === 'all' ? ALL_PAGES : []
      onChange(pages)
    },
    [onChange],
  )

  return (
    <>
      <FormControl>
        <RadioGroup
          row
          value={targetMode}
          onChange={onUnknownChangeHandler(selectTargetMode)}
        >
          <FormControlLabel
            value="all"
            control={<Radio />}
            label="Add to All Pages"
          />
          <FormControlLabel
            value="selected"
            control={<Radio />}
            label="Add to Specific Pages"
          />
        </RadioGroup>
      </FormControl>
      <PageList value={value} onChange={onChange} />
    </>
  )
}

/**
 * Checks whether the pages provided are all the available script pages.
 */
export const hasSelectedAll = (pages: Page[]) => {
  return pages.length === ALL_PAGES.length
}

function PageList(props: {value: Page[]; onChange: (pages: Page[]) => void}) {
  const {value, onChange} = props

  const selected = (page: Page) => value.includes(page)

  const assign = (page: Page) => {
    if (selected(page)) {
      return
    }

    onChange([...value, page])
  }

  const unassign = (page: Page) => {
    if (!selected(page)) {
      return
    }

    const removed = value.filter((p) => p !== page)
    onChange(removed)
  }

  const update = (page: Page) => (select: boolean) => {
    const handle = select ? assign : unassign
    handle(page)
  }

  return (
    <Grid container spacing={2}>
      {ALL_PAGES.map((page) => (
        <Grid item xs={6} key={page}>
          <Checkbox
            label={page}
            checked={selected(page)}
            onChange={update(page)}
          />
        </Grid>
      ))}
    </Grid>
  )
}

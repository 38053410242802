import Container from 'Event/Marketplace/Block/Container'
import React from 'react'
import styled from 'styled-components'
import {BlockComponentProps} from 'Event/Marketplace/Block'
import {BlockBase} from '../base'

export interface SpacerBlock extends BlockBase {
  type: 'Spacer'
}

export default function Spacer(props: SpacerBlock & BlockComponentProps) {
  return (
    <Container {...props}>
      <Content />
    </Container>
  )
}

const Content = styled.div`
  width: 100%;
  height: 100%;
`

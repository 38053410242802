import React from 'react'
import styled from 'styled-components'
import MenuItem from '@material-ui/core/MenuItem'

export type OptionProps = {
  dark?: boolean
  value?: any
  selected?: any
  children?: string | number | React.ReactNode
  disabled?: boolean
  'aria-label'?: string
}

export default React.forwardRef<HTMLLIElement, OptionProps>((props, ref) => (
  <StyledMenuItem {...props} ref={ref} />
))

const StyledMenuItem = styled(MenuItem)``

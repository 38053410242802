import React from 'react'
import styled from 'styled-components'
import Hidden from '@material-ui/core/Hidden'
import Icon from 'lib/ui/Icon'

export default function TabLabel(props: {
  iconClass: string
  text: string
  icon?: React.ReactElement
}) {
  const {text, iconClass} = props

  return (
    <Box>
      <IconBox>
        <StyledIcon className={iconClass} iconSize={24} />
      </IconBox>
      <Hidden xsDown>
        <Label>{text}</Label>
      </Hidden>
    </Box>
  )
}

const Box = styled.div`
  display: flex;
  align-items: center;
`

const IconBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 10px;
  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    padding-right: 0;
  }
`

const StyledIcon = styled(Icon)`
  font-size: 20px;
`

const Label = styled.span`
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;

  // Overwrite MUI style that has bold tabs
  font-weight: normal;
`

import {useEvent} from 'Event/EventProvider'
import FullPageLoader from 'lib/ui/layout/FullPageLoader'
import {useQueryParams} from 'lib/url'
import {useHostJoinUrl} from 'organization/Event/Room/meeting'
import React from 'react'
import {useEffect} from 'react'
import {useParams} from 'react-router'

export default function TeamJoinRoomPage() {
  const {room: roomId} = useParams<{room: string}>()
  const {start_url} = useQueryParams()

  const {client} = useEvent()

  const url = useHostJoinUrl({
    client,
    room: {id: parseInt(roomId)},
    useStartUrl: start_url === 'true',
  })

  useEffect(() => {
    if (!url) {
      return
    }

    window.location.href = url
  }, [url])

  return <FullPageLoader />
}

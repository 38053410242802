import React from 'react'
import {Controller, UseFormMethods} from 'react-hook-form'
import {NavButtonProps} from 'Event/Dashboard/components/NavButton'
import Checkbox from 'lib/ui/Checkbox'
import BackgroundPicker from 'lib/ui/form/BackgroundPicker'
import CollapsibleSection from 'lib/ui/ConfigPanel/CollapsibleSection'
import styled from 'styled-components'
import Visible from 'lib/ui/layout/Visible'
import {DEFAULT_BUTTON_BACKGROUND_COLOR} from 'lib/ui/Button/CustomButton'

export default function BackgroundSection(props: {
  control: UseFormMethods['control']
  watch: UseFormMethods['watch']
  button: Pick<
    NavButtonProps,
    'backgroundColor' | 'disableHover' | 'hoverBackgroundColor'
  >
  storageKey: string
  namePrefix?: string
}) {
  const {control, button, watch, namePrefix} = props

  const showingHoverBackgroundInput = !watch(
    'disableHover',
    button.disableHover,
  )

  return (
    <CollapsibleSection label="Background" storageKey={props.storageKey}>
      <Controller
        name={namePrefix ? `${namePrefix}.backgroundColor` : 'backgroundColor'}
        control={control}
        defaultValue={button.backgroundColor || DEFAULT_BUTTON_BACKGROUND_COLOR}
        render={({value, onChange}) => (
          <BackgroundPicker
            label="Background"
            background={value}
            onChange={onChange}
          />
        )}
      />
      <Controller
        name={namePrefix ? `${namePrefix}.disableHover` : 'disableHover'}
        control={control}
        defaultValue={button.disableHover || false}
        render={({value, onChange}) => (
          <StyledCheckBox
            label="Customize Hover Background"
            // Because we're toggling 'enable' here, but the prop is a 'disable', we need
            // to invert the values here.
            checked={!value}
            onChange={(val) => onChange(!val)}
            aria-label="toggle custom hover background"
          />
        )}
      />
      <Visible when={showingHoverBackgroundInput}>
        <Controller
          name={
            namePrefix
              ? `${namePrefix}.hoverBackgroundColor`
              : 'hoverBackgroundColor'
          }
          control={control}
          defaultValue={
            button.hoverBackgroundColor || DEFAULT_BUTTON_BACKGROUND_COLOR
          }
          render={({value, onChange}) => (
            <BackgroundPicker
              label="Hover Background"
              background={value}
              onChange={onChange}
            />
          )}
        />
      </Visible>
    </CollapsibleSection>
  )
}

const StyledCheckBox = styled(Checkbox)`
  margin-bottom: ${(props) => props.theme.spacing[4]};
`

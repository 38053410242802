import React from 'react'
import {browserTimezone, now as actualNow} from 'lib/date-time'

type DateContextProps = {
  now: () => string
  timezone: string
}

const DateContext = React.createContext<undefined | DateContextProps>(undefined)

export default function DateProvider(props: {
  now?: string | null
  timezone?: string | null
  children: JSX.Element
}) {
  const {now: fixedDateTime} = props

  // Add the ability to override out any `now()` calls in the app
  const now = fixedDateTime ? () => fixedDateTime : actualNow

  // If a fixed timezone was
  const timezone = props.timezone || browserTimezone()

  return (
    <DateContext.Provider
      value={{
        now,
        timezone,
      }}
    >
      {props.children}
    </DateContext.Provider>
  )
}

export function useNow() {
  const context = React.useContext(DateContext)
  if (context === undefined) {
    throw new Error('useNow must be used within a DateProvider.')
  }

  return context.now()
}

export function useDate() {
  const context = React.useContext(DateContext)
  if (context === undefined) {
    throw new Error('useDate must be used within a DateProvider.')
  }

  return context
}

import styled from 'styled-components'
import React from 'react'
import {usePurchasePageConfig} from 'organization/Marketplace/PurchasePageConfig/PurchasePageConfigProvider'
import {breakpoints} from 'lib/ui/theme'
import grey from '@material-ui/core/colors/grey'

interface MobileModeContainerProps {
  children: React.ReactNode
}

export default function MobileModeContainer(props: MobileModeContainerProps) {
  const {children} = props

  const {isMobileMode} = usePurchasePageConfig()

  return (
    <Background isMobileMode={isMobileMode}>
      <Content isMobileMode={isMobileMode}>{children}</Content>
    </Background>
  )
}

const Background = styled.div<{isMobileMode: boolean}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${(props) => (props.isMobileMode ? grey[300] : '#FFFFFF')};
  height: 100%;
`

const Content = styled.div<{isMobileMode: boolean}>`
  max-width: ${(props) => (props.isMobileMode ? breakpoints.sm : 'none')};
  width: 100%;
  height: 100%;
`

import {BlockComponentProps} from 'Event/Marketplace/Block'
import Container from 'Event/Marketplace/Block/Container'
import {BlockBase} from '../base'
import {fontStyles, useLoadFont} from 'lib/FontSelect'
import Content from 'lib/ui/form/TextEditor/Content'
import React from 'react'
import styled from 'styled-components'
import {Font} from '../../lib/font'

export interface TitleBlock extends BlockBase {
  type: 'Title'
  text: string
  font: Font | null
  fontSize: number
  color: string
  opacity: number
  lineHeight: number
  letterSpacing: number
}

export default function Title(props: TitleBlock & BlockComponentProps) {
  const {text} = props

  useLoadFont(props.font)
  return (
    <Container {...props}>
      <Body {...props}>
        <StyledContent>{text}</StyledContent>
      </Body>
    </Container>
  )
}

const StyledContent = styled(Content)`
  & > p {
    margin-bottom: 0 !important;
    padding-top: 0 !important;
  }
`

const Body = styled.div<TitleBlock>`
  margin: 0;
  ${(props) => fontStyles(props.font)}
  font-size: ${(props) => props.fontSize}px;
  line-height: ${(props) => props.lineHeight}%;
  letter-spacing: ${(props) => props.letterSpacing}px;
  text-align: ${(props) => props.alignment.horizontal};
  color: ${(props) => props.color};
  opacity: ${(props) => props.opacity / 100};
  width: 100%;
  overflow-wrap: break-word;
`

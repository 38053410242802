import styled, {createGlobalStyle} from 'styled-components'
import AppBar from 'organization/user/Layout/AppBar'
import React, {useState} from 'react'
import {useToggleArray} from 'lib/toggle'
import Sidebar from 'organization/Event/Layout/Sidebar'
import MobileSidebar from 'organization/Event/Layout/Sidebar/MobileSidebar'
import MobileSidebarButton from 'organization/Event/Layout/Sidebar/MobileSidebarButton'

type LayoutContextProps = {
  sidebarExpanded: boolean
  toggleSidebar: () => void
  showingMobileSidebar: boolean
  toggleMobileSidebar: () => void
  showFullScreen: boolean
  setFullScreen: (fullScreen: boolean) => void
}

export const LayoutContext = React.createContext<
  LayoutContextProps | undefined
>(undefined)

export default function Layout(props: {children: JSX.Element | JSX.Element[]}) {
  const {children} = props
  const [sidebarExpanded, toggleSidebar] = useToggleArray(true)
  const [showingMobileSidebar, toggleMobileSidebar] = useToggleArray(false)
  const [showFullScreen, setFullScreen] = useState(false)

  // Add sidebar here
  return (
    <>
      <FullPageStyles />
      <LayoutContext.Provider
        value={{
          sidebarExpanded,
          toggleSidebar,
          showingMobileSidebar,
          toggleMobileSidebar,
          showFullScreen,
          setFullScreen,
        }}
      >
        <Content>{children}</Content>
      </LayoutContext.Provider>
    </>
  )
}

function Content(props: {children: JSX.Element | JSX.Element[]}) {
  const {showFullScreen} = useLayout()

  if (showFullScreen) {
    return <>{props.children}</>
  }

  return (
    <>
      <AppBar mobileLeft={<MobileSidebarButton />} />
      <Bottom>
        <Sidebar />
        <MobileSidebar />
        <Main>{props.children}</Main>
      </Bottom>
    </>
  )
}

export function useLayout() {
  const context = React.useContext(LayoutContext)
  if (context === undefined) {
    throw new Error('useLayout must be used within Layout.')
  }

  return context
}

const Bottom = styled.div`
  display: flex;
  flex: 1;
`

export const Main = styled.div`
  flex: 1;
  background: #ffffff;

  position: relative;
  overflow: auto;
  scroll-behavior: smooth;

  > div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`

const FullPageStyles = createGlobalStyle`
  html, body, #root {
    height: 100%;
  }

  #root {
    display: flex;
    flex-direction: column;
  }
`

import TextField from '@material-ui/core/TextField'
import styled from 'styled-components'
import CloseIcon from '@material-ui/icons/Close'
import React from 'react'
import Grid from '@material-ui/core/Grid'
import IconButton from 'lib/ui/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'
import {HubspotGroup} from 'organization/Event/Services/Apps/Hubspot/index'
import PropertySelector from 'organization/Event/Services/Apps/Hubspot/Config/PropertySelector'
import {onChangeStringHandler} from 'lib/dom'
import {TargetProperty} from 'organization/Event/Services/Apps/Hubspot/Config'

export default function GroupInput(props: {
  group: HubspotGroup
  onChange: (group: HubspotGroup) => void
  onRemove: () => void
}) {
  const {onChange, group, onRemove} = props

  const update = <K extends keyof HubspotGroup>(key: K) => (
    value: HubspotGroup[K],
  ) => {
    const updated = {
      ...group,
      [key]: value,
    }

    onChange(updated)
  }

  const setField = (field: TargetProperty | null) => {
    if (!field) {
      return
    }

    const updated: HubspotGroup = {
      ...group,
      property_name: field.name,
      property_label: field.label,
      property_type: field.type,
    }

    onChange(updated)
  }

  return (
    <Grid container spacing={2}>
      <Grid item sm={6}>
        <TextField
          label="Group name"
          value={group.key}
          fullWidth
          variant="outlined"
          inputProps={{'aria-label': 'group name'}}
          onChange={onChangeStringHandler(update('key'))}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <RemoveButton aria-label="remove group" onClick={onRemove}>
                  <CloseIcon color="error" />
                </RemoveButton>
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item sm={6}>
        <PropertySelector
          label={'Property to Import'}
          value={{
            name: group.property_name || '',
            label: group.property_label || '',
            type: group.property_type,
          }}
          targetTypes={[
            'select',
            'text',
            'number',
            'date',
            'checkbox',
            'booleancheckbox',
            'radio',
          ]}
          onChange={setField}
        />
      </Grid>
    </Grid>
  )
}

const RemoveButton = styled(IconButton)`
  margin-left: ${(props) => props.theme.spacing[2]};
`

import React from 'react'
import {ThemeProvider as MuiThemeProvider} from '@material-ui/core/styles'
import {ThemeProvider as StyledThemeProvider} from 'styled-components'
import {
  spacing,
  breakpoints,
  colors,
  muiTheme,
  theme,
  darkTheme,
  muiDarkTheme,
} from 'lib/ui/theme'
import ColorProperties from 'color'

export default function ThemeProvider(props: {children: React.ReactNode}) {
  return (
    <StyledThemeProvider theme={theme}>
      <MuiThemeProvider theme={muiTheme}>{props.children}</MuiThemeProvider>
    </StyledThemeProvider>
  )
}

export function DarkThemeProvider(props: {
  children: JSX.Element | JSX.Element[]
}) {
  return (
    <StyledThemeProvider theme={darkTheme}>
      <MuiThemeProvider theme={muiDarkTheme}>{props.children}</MuiThemeProvider>
    </StyledThemeProvider>
  )
}

/**
 * Sets the theme depending on the background color.
 *
 * @param props
 * @returns
 */
export function DarkBackgroundTheme(props: {
  children: React.ReactElement | React.ReactElement[]
  color: string
  opacity: number
}) {
  const {color, opacity} = props

  if (hasDarkBackground({color, opacity})) {
    return <DarkThemeProvider>{props.children}</DarkThemeProvider>
  }

  return <>{props.children}</>
}

function hasDarkBackground(props: {color: string; opacity: number}) {
  const {color, opacity} = props
  const properties = ColorProperties(color)

  return properties.luminosity() < 0.05 && opacity > 0.8
}

declare module 'styled-components' {
  export interface DefaultTheme {
    name: string
    spacing: typeof spacing
    breakpoints: typeof breakpoints
    colors: typeof colors
  }
}

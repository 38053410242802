import Breadcrumbs from 'lib/ui/AppBar/Breadcrumbs'
import Logo from 'lib/ui/AppBar/Logo'
import React from 'react'
import styled from 'styled-components'

export type AppBarProps = {
  homeLink: string
  quickLinks?: JSX.Element
  mobileLeft?: JSX.Element
  desktopRight?: JSX.Element
  accountMenu?: JSX.Element
}

export default function AppBar(props: AppBarProps) {
  const {homeLink, mobileLeft, desktopRight, accountMenu} = props

  return (
    <Box>
      <MobileLeft>{mobileLeft}</MobileLeft>
      <StyledLogo homeLink={homeLink} />
      <StyledBreadcrumbs />
      <DesktopRight>{desktopRight}</DesktopRight>
      {accountMenu}
    </Box>
  )
}

const verticalPadding = 12

export const APPBAR_HEIGHT = 64

const Box = styled.div`
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.05);
  background: #ffffff;
  height: ${APPBAR_HEIGHT}px;
  display: flex;
  padding: ${verticalPadding}px 20px;
  justify-content: space-between;
  position: relative;
  z-index: 5;
  align-items: center;

  @media (min-width: ${(props) => props.theme.breakpoints.sm}) {
    padding: ${verticalPadding}px 32px;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    padding: ${verticalPadding}px 52px;

    position: sticky;
    top: 0;
  }
`

const StyledLogo = styled(Logo)`
  position: absolute;
  height: 100%;
  width: 100%;
  padding: ${verticalPadding}px 0;
  top: 0;
  left: 0;

  flex: 1;
  display: flex;
  justify-content: center;
  margin-right: 24px;

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    position: relative;
    padding: 0;
    flex: 0;
  }
`

const MobileLeft = styled.div`
  display: flex;
  align-items: center;

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    display: none;
  }
`

const StyledBreadcrumbs = styled(Breadcrumbs)`
  display: none;

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    display: flex;
    flex: 1;
  }
`

const DesktopRight = styled.div`
  display: none;
  margin-right: ${(props) => props.theme.spacing[2]};

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    display: block;
  }
`

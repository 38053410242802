import ButtonBase from 'lib/ui/Button'
import Tooltip from '@material-ui/core/Tooltip'
import {useHasWaiver} from 'Event/Step2/Waivers'
import {RelativeLink} from 'lib/ui/link/RelativeLink'
import {ENTERPRISE} from 'obvio/Billing/plans'
import IfOwnerHasPlan from 'organization/auth/IfOwnerPlan'
import {useEventRoutes} from 'organization/Event/EventRoutes'
import React from 'react'

export default function AdditionalWaiversButton() {
  const routes = useEventRoutes()
  const hasWaiver = useHasWaiver()

  return (
    <IfOwnerHasPlan plan={ENTERPRISE}>
      <RelativeLink
        disableStyles
        to={routes.waiver.additional_waivers}
        disabled={!hasWaiver}
      >
        <Content />
      </RelativeLink>
    </IfOwnerHasPlan>
  )
}

function Content() {
  const hasWaiver = useHasWaiver()
  if (!hasWaiver) {
    return (
      <Tooltip title="Default waiver required.">
        <div>
          <Button disabled />
        </div>
      </Tooltip>
    )
  }

  return <Button />
}

function Button(props: {disabled?: boolean}) {
  return (
    <ButtonBase
      variant="text"
      disabled={props.disabled}
      size="small"
      type="button"
    >
      Additional waivers
    </ButtonBase>
  )
}

import React from 'react'
import EmailField from 'Event/auth/Login/EmailField'
import Page, {
  StyledFormContainer,
  DescriptionText,
} from 'Event/template/Lefty/Login/Page'
import {SignUpProps} from 'Event/auth/SignUp'
import {useLeftyTemplate} from 'Event/template/Lefty'
import Description from 'Event/auth/SignUp/Description'
import SubmitButton from 'Event/auth/SignUp/SubmitButton'
import FirstNameField from 'Event/auth/SignUp/FirstNameField'
import LastNameField from 'Event/auth/SignUp/LastNameField'
import ErrorMessage from 'Event/auth/SignUp/ErrorMessage'
import {useGuestVariables} from 'Event'

export default function SignUp(props: SignUpProps) {
  return (
    <Page isPreview={false}>
      <StyledFormContainer>
        <Content {...props} />
      </StyledFormContainer>
    </Page>
  )
}

export function Content(props: SignUpProps) {
  const {signUp} = useLeftyTemplate()
  const v = useGuestVariables()

  return (
    <>
      <Description
        aria-label="event sign up description"
        Component={DescriptionText}
      >
        {v(signUp.description)}
      </Description>
      <form onSubmit={props.onSubmit}>
        <FirstNameField {...props} />
        <LastNameField {...props} />
        <EmailField {...props} />
        <ErrorMessage error={props.responseError?.message} />
        <SubmitButton {...props} />
      </form>
    </>
  )
}

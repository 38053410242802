import React from 'react'
import ColorPicker from 'lib/ui/ColorPicker'
import {Controller, UseFormMethods} from 'react-hook-form'
import {useNiftyFiftyTemplate} from 'Event/template/NiftyFifty'

export default function Styling(props: {control: UseFormMethods['control']}) {
  const {control} = props
  const template = useNiftyFiftyTemplate()
  const {resourceList: list} = template

  return (
    <>
      <Controller
        name="color"
        control={control}
        defaultValue={list.color}
        render={({value, onChange}) => (
          <ColorPicker
            label="Text Color"
            color={value}
            onPick={onChange}
            aria-label="resource card color"
          />
        )}
      />
      <Controller
        name="linkColor"
        control={control}
        defaultValue={list.linkColor}
        rules={{
          required: 'Link color is required',
        }}
        render={({value, onChange}) => (
          <ColorPicker
            label="Link Color"
            color={value}
            onPick={onChange}
            aria-label="resource card link color"
          />
        )}
      />
    </>
  )
}

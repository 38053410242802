import {useTownhallTemplate} from 'Event/template/Townhall'
import {useSaveTemplate} from 'Event/TemplateUpdateProvider'
import TextEditor, {TextEditorContainer} from 'lib/ui/form/TextEditor'
import InputLabel from 'lib/ui/InputLabel'
import TextField from 'lib/ui/TextField'
import {useConfig} from 'organization/Event/Configurable'
import Config from 'organization/Event/Configurable/Config'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import React from 'react'
import {Controller} from 'react-hook-form'

export default function BackgroundsHeaderConfig() {
  const save = useSaveTemplate()
  const {zoomBackgrounds} = useTownhallTemplate()

  const {
    form: {register, control},
  } = useConfig()

  return (
    <Config title="Header" onSave={save}>
      <SettingsPanel>
        <TextField
          name="zoomBackgrounds.title"
          defaultValue={zoomBackgrounds.title}
          label="Title"
          fullWidth
          inputProps={{
            'aria-label': 'zoomBackgrounds title',
            ref: register,
          }}
        />
        <TextField
          name="zoomBackgrounds.secondaryTitle"
          defaultValue={zoomBackgrounds.secondaryTitle}
          label="Secondary Title"
          fullWidth
          inputProps={{
            'aria-label': 'zoomBackgrounds secondary title',
            ref: register,
          }}
        />
        <InputLabel>Description</InputLabel>
        <TextEditorContainer>
          <Controller
            name="zoomBackgrounds.description"
            defaultValue={zoomBackgrounds.description}
            control={control}
            render={({value, onChange}) => (
              <TextEditor data={value} onChange={onChange} />
            )}
          />
        </TextEditorContainer>
      </SettingsPanel>
    </Config>
  )
}

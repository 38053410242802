import React from 'react'
import TicketSelector from 'Event/Marketplace/Block/TicketSelector'
import Image from 'Event/Marketplace/Block/Image'
import Button from 'Event/Marketplace/Block/Button'
import styled from 'styled-components'
import Title from 'Event/Marketplace/Block/Title'
import Video from 'Event/Marketplace/Block/Video'
import Text from 'Event/Marketplace/Block/Text'
import PurchaseForm from 'Event/Marketplace/Block/PurchaseForm'
import VisibleContent from 'Event/Marketplace/Block/VisibleContent'
import Separator from 'Event/Marketplace/Block/Separator'
import CountdownTimer from 'Event/Marketplace/Block/CountdownTimer'
import BulletedList from 'Event/Marketplace/Block/BulletedList'
import NumberedList from 'Event/Marketplace/Block/NumberedList'
import Icon from 'Event/Marketplace/Icon'
import FaqList from 'Event/Marketplace/Block/FaqList'
import Spacer from 'Event/Marketplace/Block/Spacer'
import {PurchasePageBlock, PurchasePageTemplate} from '../purchase-page'

export interface BlockProps extends BlockComponentProps {
  block: PurchasePageBlock
  style?: React.CSSProperties
  className?: string
  children?: React.ReactNode
  onMouseDown?: React.MouseEventHandler<HTMLDivElement>
  onMouseUp?: React.MouseEventHandler<HTMLDivElement>
  onTouchEnd?: React.TouchEventHandler<HTMLDivElement>
  isMobileMode?: boolean
}

export interface BlockComponentProps {
  id: string
  template: PurchasePageTemplate
}

const Block = React.forwardRef<HTMLDivElement, BlockProps>((props, ref) => {
  const {block, id, isMobileMode, template, ...gridLayoutProps} = props
  const {
    style,
    className,
    onMouseDown,
    onMouseUp,
    onTouchEnd,
    children,
  } = gridLayoutProps

  const Component = Components[block.type] ?? null

  if (!Component) {
    return null
  }

  return (
    <Box
      style={{...style, transitionProperty: 'none'}} // disable animations
      className={className}
      ref={ref}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      onTouchEnd={onTouchEnd}
      visibility={block.visibility}
      isMobileMode={isMobileMode}
    >
      {/*
       * Setting 'any' type for props is ok here, because we're
       * already verifying the correct component receives the
       * correct type in ComponentDefinitions.
       */}
      <Component {...(block as any)} id={id} template={template} />
      {children}
    </Box>
  )
})

export default Block

export type ComponentDefinitions = {
  [T in PurchasePageBlock as T['type']]: React.FC<
    T & {id: string; template: PurchasePageTemplate}
  >
}

export const Components: ComponentDefinitions = {
  Title,
  Text,
  Image,
  TicketSelector,
  Button,
  Video,
  PurchaseForm,
  Separator,
  BulletedList,
  CountdownTimer,
  NumberedList,
  Icon,
  FaqList,
  Spacer,
}

export const Box = styled(VisibleContent)`
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`

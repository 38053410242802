import MenuItem from '@material-ui/core/MenuItem'
import {onUnknownChangeHandler} from 'lib/dom'
import Select, {SelectProps} from 'lib/ui/Select'
import {useForms} from 'organization/Event/FormsProvider'
import React from 'react'

export default function FormSelect(
  props: Omit<SelectProps, 'onChange' | 'value'> & {
    onChange: (id: number | null) => void
    value?: number | null
    onCreateNewForm?: () => void
  },
) {
  const {onChange, onCreateNewForm, value} = props

  const {forms} = useForms()

  const selectForm = (id?: number) => {
    // If no value was provided, such as when clicking 'Create New Form, we'll
    // ignore the select instead of selecting 'null'.
    if (id === undefined) {
      return
    }

    const value = id || null
    onChange(value)
  }

  return (
    <>
      <Select
        {...props}
        value={value || ''}
        fullWidth
        aria-label="select form"
        onChange={onUnknownChangeHandler(selectForm)}
      >
        <MenuItem value={0}>None</MenuItem>
        {forms.map((form) => (
          <MenuItem
            key={form.id}
            value={form.id}
            aria-label={`pick ${form.name}`}
          >
            {form.name}
          </MenuItem>
        ))}
        <AddNewFormMenuItem onClick={onCreateNewForm} />
      </Select>
    </>
  )
}

function AddNewFormMenuItem(props: {onClick?: () => void}) {
  const {onClick} = props

  if (!onClick) {
    return null
  }

  return (
    <MenuItem aria-label="create a new form" onClick={onClick}>
      Create New Form
    </MenuItem>
  )
}

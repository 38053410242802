import {Rule} from 'Event/attendee-rules'
import styled from 'styled-components'
import React, {useState} from 'react'
import Button from 'lib/ui/Button'
import RuleForm from 'Event/attendee-rules/RuleConfig/RuleList/RuleForm'
import {TAGS} from 'Event/attendee-rules/RuleConfig/RuleList/SingleRule/TagsRule'
import {GROUP} from 'Event/attendee-rules/RuleConfig/RuleList/SingleRule/GroupRule'
import {RuleValue} from 'Event/attendee-rules/RuleConfig/RuleList/SingleRule'

type Filter = {
  index: number
  rule: Rule | null
}

export default function FilterList(props: {
  rules: Rule[]
  close?: () => void
  onChange: (rules: Rule[]) => void
  className?: string
  disabled?: boolean
}) {
  const [filters, setFilters] = useState<Filter[]>(
    props.rules.map((rule, index) => ({index, rule})),
  )
  const addNewFilter = () => {
    setFilters([
      ...filters,
      {
        index: filters.length,
        rule: null,
      },
    ])
  }

  const updateRules = (updates: Filter[]) => {
    const rules: Rule[] = []
    for (let i = 0; i < updates.length; i += 1) {
      const rule = updates[i].rule
      if (rule) {
        rules.push(rule)
      }
    }
    props.onChange(rules)
    setFilters(updates)
  }

  const saveFilter = (filter: Filter) => {
    const updated = filters.map((f, i) => (i === filter.index ? filter : f))
    updateRules(updated)
  }

  const deleteFilter = (filter: Filter) => {
    const removed = filters.filter((_, i) => i !== filter.index)
    updateRules(removed)
  }

  return (
    <div className={props.className}>
      <Filters
        filters={filters}
        saveFilter={saveFilter}
        deleteFilter={deleteFilter}
      />
      <Button
        variant="outlined"
        color="primary"
        fullWidth
        onClick={addNewFilter}
        aria-label="add rule"
        disabled={props.disabled}
      >
        Add Filter
      </Button>
    </div>
  )
}

function Filters(props: {
  className?: string
  filters: Filter[]
  saveFilter: (rule: Filter) => void
  deleteFilter: (rule: Filter) => void
}) {
  const hasFilters = props.filters.length > 0

  if (!hasFilters) {
    return <EmptyFiltersText>No filters have been added</EmptyFiltersText>
  }

  return (
    <FiltersContainer>
      {props.filters.map((filter) => (
        <FilterItem
          key={filter.index}
          filter={filter}
          save={props.saveFilter}
          delete={props.deleteFilter}
        />
      ))}
    </FiltersContainer>
  )
}

function FilterItem(props: {
  filter: Filter
  save: (filter: Filter) => void
  delete: (filter: Filter) => void
}) {
  const {filter} = props
  const [showingRuleForm, setShowingRuleForm] = useState(false)

  const onDelete = () => {
    props.delete(filter)
    setShowingRuleForm(false)
  }

  const onCreate = (rule: Rule) => {
    props.save({
      ...filter,
      rule,
    })
    setShowingRuleForm(false)
  }

  const onClose = () => {
    if (!filter.rule) {
      props.delete(filter)
    }

    setShowingRuleForm(false)
  }

  if (showingRuleForm || !filter.rule) {
    return (
      <RuleForm
        close={onClose}
        onDelete={onDelete}
        onCreate={onCreate}
        rule={filter.rule}
        sources={[TAGS, GROUP]}
        description="Rule"
      />
    )
  }

  return (
    <FilterContainer onClick={() => setShowingRuleForm(true)}>
      <RuleValue rule={filter.rule} />
    </FilterContainer>
  )
}

const FiltersContainer = styled.div`
  margin-bottom: ${(props) => props.theme.spacing[5]};
`

const EmptyFiltersText = styled.p`
  margin: ${(props) => props.theme.spacing[4]} 0;
  text-align: center;
`

const FilterContainer = styled.div`
  flex: 1;
  padding: ${(props) => `${props.theme.spacing[2]} ${props.theme.spacing[4]}`};
  border: 1px solid ${(props) => props.theme.colors.border};
  margin-bottom: ${(props) => props.theme.spacing[3]};
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background: ${(props) => props.theme.colors.menu.itemHoverBackground};
  }
`

import {
  NavButtonProps,
  NavButtonWithSize,
} from 'Event/Dashboard/components/NavButton'
import React, {useState, useCallback} from 'react'
import {ComponentConfigProps} from 'organization/Event/DashboardConfig/ComponentConfigPanel'
import {Panels, usePanelsUpdate} from 'Event/template/Panels'
import {useForm} from 'react-hook-form'
import {v4 as uuid} from 'uuid'
import {REMOVE, useRemoveIfEmpty} from 'Event/TemplateUpdateProvider'
import MainNavButtonConfigBase from 'Event/Dashboard/components/NavButton/NavButtonConfig/BaseConfig'
import Settings from 'Event/template/Panels/Dashboard/MainNav/MainNavButton/MainNavButtonConfig/Settings'
import Styling from 'Event/template/Panels/Dashboard/MainNav/MainNavButton/MainNavButtonConfig/Styling'
import {usePruneAssets} from 'lib/asset'
import {useTemplateEditor} from 'organization/Event/TemplateEditor'

export type ButtonConfigProps<K extends NavButtonProps> = {
  button: K
  update: <T extends keyof K>(key: T) => (value: K[T]) => void
}

export default function MainNavButtonConfig(
  props: ComponentConfigProps & {
    button: NavButtonWithSize
    id?: string
  },
) {
  const {button, id = uuid(), showing: isVisible, onClose} = props
  const updateTemplate = usePanelsUpdate()
  const pruneAssets = usePruneAssets()
  const {saved} = useTemplateEditor<Panels>()

  const [rules, setRules] = useState(button.rules)
  const {
    control,
    register,
    handleSubmit,
    watch,
    setValue,
    formState,
  } = useForm()

  const update = (id: string, updated: NavButtonWithSize) => {
    const image = updated.image
    if (saved.mainNav.buttons && id in saved.mainNav.buttons) {
      pruneAssets({image}, saved.mainNav.buttons[id])
    }

    updateTemplate({
      mainNav: {
        buttons: {
          [id]: updated,
        },
      },
    })
  }

  const removeButton = useCallback(() => {
    if (!id) {
      throw new Error('Missing button id')
    }

    onClose()
    updateTemplate({
      mainNav: {
        buttons: {
          [id]: REMOVE,
        },
      },
    })
  }, [id, onClose, updateTemplate])

  useRemoveIfEmpty(removeButton, button, {shouldSkip: !id})

  const save = (formData: NavButtonWithSize) => {
    const data: NavButtonWithSize = {
      ...formData,
      rules,
      // isImageUpload: isImageUploadButton,
    }

    update(id, data)
    onClose()
  }

  return (
    <MainNavButtonConfigBase
      button={button}
      id={id}
      control={control}
      register={register}
      watch={watch}
      setValue={setValue}
      rules={rules}
      setRules={setRules}
      removeButton={removeButton}
      onClose={onClose}
      formState={formState}
      handleSubmit={handleSubmit}
      save={save}
      isVisible={isVisible}
      showing={Boolean(id)}
      customSettings={
        <Settings
          button={button}
          control={control}
          watch={watch}
          register={register}
          setValue={setValue}
        />
      }
      customStyling={
        <Styling button={button} control={control} watch={watch} />
      }
    />
  )
}

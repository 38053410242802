import {DeepPartial} from './type-utils'

export function isObject(v: any): v is {} {
  return typeof v === 'object' && v !== null && !Array.isArray(v)
}

export function withDefaults<T extends Record<string, any>>(
  defaults: T,
  template: DeepPartial<T>,
) {
  const res: any = {}

  if (!template) {
    return defaults
  }

  for (const [key, defaultValue] of Object.entries(defaults)) {
    // is {} object
    if (isObject(defaultValue)) {
      const hasChildProps = Object.keys(defaultValue).length > 0
      if (!hasChildProps) {
        res[key] = template[key] || {}
        continue
      }

      res[key] = withDefaults(defaultValue, template[key])
      continue
    }

    const isUndefined = template[key] === undefined
    if (isUndefined) {
      res[key] = defaultValue
      continue
    }

    // alreay set
    res[key] = template[key]
  }

  return res as T
}

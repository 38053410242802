import React from 'react'
import {DndProvider} from 'react-dnd'
import {HTML5Backend} from 'react-dnd-html5-backend'

type DragHandler = (fromIndex: number, toIndex: number) => void

type DraggableGridContextProps = {
  onDrop: (sourceId: string, targetId: string) => void
}

const DraggableGridContext = React.createContext<
  undefined | DraggableGridContextProps
>(undefined)

export default function DraggableGrid(props: {
  children: JSX.Element
  onDrag: DragHandler
  ids: string[]
}) {
  const {children, onDrag, ids} = props

  const onDrop = (sourceId: string, targetId: string) => {
    const sourceIndex = ids.findIndex((i) => i === sourceId)
    const targetIndex = ids.findIndex((i) => i === targetId)

    if (sourceIndex < 0 || targetIndex < 0) {
      return
    }

    onDrag(sourceIndex, targetIndex)
  }

  return (
    <DraggableGridContext.Provider value={{onDrop}}>
      <DndProvider backend={HTML5Backend}>{children}</DndProvider>
    </DraggableGridContext.Provider>
  )
}

export function useDraggableGrid() {
  const context = React.useContext(DraggableGridContext)

  if (context === undefined) {
    throw new Error('useDraggableGrid must be used within a DraggableGrid')
  }

  return context
}

import CollapsibleSection from 'lib/ui/ConfigPanel/CollapsibleSection'
import TextField from 'lib/ui/TextField'
import {useConfig} from 'organization/Event/Configurable'
import Config from 'organization/Event/Configurable/Config'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import StylingPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/StylingPanel'
import {useUpdateTemplate} from 'organization/Marketplace/PurchasePageConfig/TemplateUpdateProvider'
import React, {useState} from 'react'
import {Controller} from 'react-hook-form'
import IconPicker from 'lib/ui/form/IconPicker'
import ImageAssetUploader from 'lib/asset/ImageAssetUploader'
import {EnabledSwitch} from 'lib/ui/form/Switch'
import Checkbox from '@material-ui/core/Checkbox'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import {onChangeCheckedHandler} from 'lib/dom'
import TypographySection from 'Event/Dashboard/components/NavButton/NavButtonConfig/BaseConfig/Styling/TypographySection'
import BackgroundSection from 'Event/Dashboard/components/NavButton/NavButtonConfig/BaseConfig/Styling/BackgroundSection'
import BorderSection from 'Event/Dashboard/components/NavButton/NavButtonConfig/BaseConfig/Styling/BorderSection'
import SizeSection from 'Event/Dashboard/components/NavButton/NavButtonConfig/BaseConfig/Styling/SizeSection'
import MarginPaddingInputs from 'lib/ui/MarginPaddingInputs'
import Select from 'lib/ui/Select'
import Option from 'lib/ui/Select/Option'
import {Label} from 'lib/ui/typography'
import {onUnknownChangeHandler} from 'lib/dom'
import {REMOVE} from 'Event/TemplateUpdateProvider'

import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'
import ToggleButton from '@material-ui/lab/ToggleButton'
import {UseFormMethods} from 'react-hook-form'
import VisibilitySetting from 'organization/Marketplace/PurchasePageConfig/BlockConfig/VisibilitySettings'
import AlignButtons from 'lib/ui/form/AlignButtons'
import {ButtonBlock} from 'Event/Marketplace/Block/Button'

interface ButtonConfigProps extends ButtonBlock {
  id: string
  sectionId: string
}

export default function ButtonConfig(props: ButtonConfigProps) {
  const {
    id,
    sectionId,
    isVisible,
    icon,
    iconAlign,
    text,
    textSecond,
    image,
    padding,
    alignment,
  } = props
  const updateTemplate = useUpdateTemplate()
  const {
    form: {register, control, watch},
  } = useConfig()
  const [visibility, setVisibility] = useState(props.visibility)

  const handleRemove = () => {
    updateTemplate({
      sections: {
        [sectionId]: {
          blocks: {
            [id]: REMOVE,
          },
        },
      },
    })
  }

  const save = (data: any) => {
    data.sections[sectionId].blocks[id].visibility = visibility
    updateTemplate(data)
  }

  return (
    <Config title="Button Block" onSave={save} onRemove={handleRemove}>
      <SettingsPanel>
        <Controller
          name={`sections.${sectionId}.blocks.${id}.isVisible`}
          control={control}
          defaultValue={isVisible}
          render={({value, onChange}) => (
            <EnabledSwitch
              checked={value}
              onChange={onChangeCheckedHandler(onChange)}
              arial-label="toggle visible"
            />
          )}
        />
        <Controller
          name={`sections.${sectionId}.blocks.${id}.icon`}
          defaultValue={icon || ''}
          control={control}
          render={({value, onChange}) => (
            <IconPicker value={value} onChange={onChange} />
          )}
        />

        <Controller
          name={`sections.${sectionId}.blocks.${id}.iconAlign`}
          defaultValue={iconAlign ?? 'left'}
          control={control}
          render={({value, onChange}) => (
            <AlignButtons value={value} onChange={onChange} />
          )}
        />

        <TextField
          name={`sections.${sectionId}.blocks.${id}.text`}
          defaultValue={text || ''}
          label="Text Line 1"
          inputProps={{
            ref: register,
            'aria-label': 'button text input',
          }}
        />

        <TextField
          name={`sections.${sectionId}.blocks.${id}.textSecond`}
          defaultValue={textSecond || ''}
          label="Text Line 2"
          inputProps={{
            ref: register,
            'aria-label': 'button text second input',
          }}
        />

        <Controller
          name={`sections.${sectionId}.blocks.${id}.image`}
          control={control}
          defaultValue={image || ''}
          render={({value, onChange}) => (
            <ImageAssetUploader
              onChange={onChange}
              value={value}
              alt="button image"
              uploadInputProps={{
                'aria-label': 'upload button image',
              }}
              removeButtonProps={{
                'aria-label': 'remove button image',
              }}
              canResize
              disableAutoRemove
            />
          )}
        />

        <TargetInput
          prefixName={`sections.${sectionId}.blocks.${id}`}
          control={control}
          register={register}
          watch={watch}
          buttonBlock={props}
        />
        <VisibilitySetting
          visibility={visibility}
          setVisibility={setVisibility}
        />
      </SettingsPanel>
      <StylingPanel>
        <TypographySection
          button={props}
          control={control}
          storageKey="shoppingCart.config.button.styling.typography"
          namePrefix={`sections.${sectionId}.blocks.${id}`}
          label="Typography - Line 1"
        />

        <TypographySection
          button={props.typographySecond ?? props}
          control={control}
          storageKey="shoppingCart.config.button.styling.typographySecond"
          namePrefix={`sections.${sectionId}.blocks.${id}.typographySecond`}
          label="Typography - Line 2"
        />

        <BackgroundSection
          button={props}
          control={control}
          watch={watch}
          storageKey="shoppingCart.config.button.styling.background"
          namePrefix={`sections.${sectionId}.blocks.${id}`}
        />
        <SizeSection
          button={props}
          control={control}
          storageKey="shoppingCart.config.button.styling.size"
          namePrefix={`sections.${sectionId}.blocks.${id}`}
          disableNewLine
        />
        <BorderSection
          button={props}
          control={control}
          storageKey="shoppingCart.config.button.styling.border"
          namePrefix={`sections.${sectionId}.blocks.${id}`}
        />
        <CollapsibleSection
          label="Alignment"
          storageKey={`blocks-${id}-alignment`}
        >
          <Controller
            name={`sections.${sectionId}.blocks.${id}.alignment.vertical`}
            defaultValue={alignment.vertical}
            control={control}
            render={({value, onChange}) => (
              <Select
                fullWidth
                label="Vertical"
                value={value}
                onChange={onUnknownChangeHandler(onChange)}
              >
                <Option value="start">Top</Option>
                <Option value="center">Center</Option>
                <Option value="end">Bottom</Option>
              </Select>
            )}
          />
          <Controller
            name={`sections.${sectionId}.blocks.${id}.alignment.horizontal`}
            defaultValue={alignment.horizontal}
            control={control}
            render={({value, onChange}) => (
              <Select
                fullWidth
                label="Horizontal"
                value={value}
                onChange={onUnknownChangeHandler(onChange)}
              >
                <Option value="left">Left</Option>
                <Option value="center">Center</Option>
                <Option value="right">Right</Option>
              </Select>
            )}
          />
        </CollapsibleSection>
        <CollapsibleSection
          label="Advanced Spacing"
          storageKey="shoppingCart.config.button.styling.spacing"
        >
          <Label>Padding</Label>
          <MarginPaddingInputs
            control={control}
            namePrefix={`sections.${sectionId}.blocks.${id}.padding`}
            values={padding}
          />
        </CollapsibleSection>
      </StylingPanel>
    </Config>
  )
}

export type TargetInputProps = {
  prefixName: string
  control: UseFormMethods['control']
  register: UseFormMethods['register']
  watch: UseFormMethods['watch']
  buttonBlock: ButtonConfigProps
}

function TargetInput(props: TargetInputProps) {
  const {prefixName, buttonBlock, control} = props

  return (
    <>
      <Controller
        name={`${prefixName}.destination`}
        control={control}
        defaultValue={buttonBlock.destination || 'link'}
        render={({value, onChange}) => (
          <FormControl>
            <ToggleButtonGroup exclusive onChange={(e, v) => onChange(v)}>
              <ToggleButton
                selected={'ticket_selector' === value}
                value="ticket_selector"
              >
                Ticket Registration
              </ToggleButton>
              <ToggleButton selected={'link' === value} value="link">
                Link
              </ToggleButton>
            </ToggleButtonGroup>
          </FormControl>
        )}
      />
      <LinkInput {...props} />
    </>
  )
}

function LinkInput(props: TargetInputProps) {
  const {register, buttonBlock, watch, control, prefixName} = props

  if (watch(`${prefixName}.destination`, buttonBlock.destination) !== 'link') {
    return null
  }
  return (
    <>
      <TextField
        name={`${prefixName}.link`}
        defaultValue={buttonBlock.link || ''}
        label="URL"
        inputProps={{
          'aria-label': 'button link input',
          ref: register,
        }}
        fullWidth
        helperText="Starting with https:// or http://"
      />
      <Controller
        name={`${prefixName}.newTab`}
        control={control}
        defaultValue={buttonBlock.newTab || false}
        render={({value, onChange}) => (
          <FormControl>
            <FormControlLabel
              label="New Tab"
              control={
                <Checkbox
                  checked={value}
                  onChange={onChangeCheckedHandler(onChange)}
                  inputProps={{
                    'aria-label': 'optional new tab',
                  }}
                />
              }
            />
          </FormControl>
        )}
      />
    </>
  )
}

import {PaymentMethod} from '@stripe/stripe-js'
import {useAsync} from 'lib/async'
import FullPageLoader from 'lib/ui/layout/FullPageLoader'
import {api} from 'lib/url'
import {teamMemberClient} from 'obvio/obvio-client'
import React, {useCallback, useEffect, useState} from 'react'

type DefaultPaymentMethodContextProps = {
  paymentMethod: null | PaymentMethod
  setPaymentMethod: (paymentMethod: PaymentMethod | null) => void
}

export const DefaultPaymentMethodContext = React.createContext<
  undefined | DefaultPaymentMethodContextProps
>(undefined)

export function DefaultPaymentMethodProvider(props: {
  children: React.ReactElement
}) {
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethod | null>(null)
  const {paymentMethod: savedPaymentMethod, loading} = useSavedPaymentMethod()

  useEffect(() => {
    if (!savedPaymentMethod) {
      return
    }

    setPaymentMethod(savedPaymentMethod)
  }, [savedPaymentMethod])

  if (loading) {
    return <FullPageLoader />
  }

  return (
    <DefaultPaymentMethodContext.Provider
      value={{
        paymentMethod,
        setPaymentMethod,
      }}
    >
      {props.children}
    </DefaultPaymentMethodContext.Provider>
  )
}

export function useDefaultPaymentMethod() {
  const context = React.useContext(DefaultPaymentMethodContext)
  if (context === undefined) {
    throw new Error(
      'useDefaultPaymentMethod must be used within a DefaultPaymentMethodProvider',
    )
  }

  return context
}

function useSavedPaymentMethod() {
  const request = useCallback(() => {
    const url = api('/stripe/default_payment_method')

    return teamMemberClient.get<PaymentMethod>(url)
  }, [])

  const {data: paymentMethod, ...res} = useAsync(request)

  return {paymentMethod, ...res}
}

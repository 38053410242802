import {PaymentMethod} from '@stripe/stripe-js'
import {api} from 'lib/url'
import {useOrganization} from 'organization/OrganizationProvider'
import {useQuery} from 'react-query'

export function usePaymentMethods() {
  const {organization, client} = useOrganization()

  return useQuery({
    queryKey: ['organizations', organization.id, 'payment_methods'],
    queryFn: () =>
      client.get<Array<PaymentMethod>>(
        api(`/organizations/${organization.id}/payment_methods`),
      ),
  })
}

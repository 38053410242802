import React, {useState} from 'react'

interface PurchasePageConfigProviderProps {
  children: JSX.Element
}

interface PurchasePageConfigContextProps {
  isMobileMode: boolean
  setMobileMode: (isMobileMode: boolean) => void
}

const PurchasePageConfigContext = React.createContext<
  PurchasePageConfigContextProps | undefined
>(undefined)

export default function PurchasePageConfigProvider(
  props: PurchasePageConfigProviderProps,
) {
  const {children} = props

  const [isMobileMode, setMobileMode] = useState(false)

  return (
    <PurchasePageConfigContext.Provider
      value={{
        isMobileMode,
        setMobileMode,
      }}
    >
      {children}
    </PurchasePageConfigContext.Provider>
  )
}

export function usePurchasePageConfig() {
  const context = React.useContext(PurchasePageConfigContext)
  if (context === undefined) {
    throw new Error(
      'usePurchasePageConfig must be used within a PurchasePageConfigProvider',
    )
  }

  return context
}

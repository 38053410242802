import React from 'react'
import SimpleBlogPage from 'Event/template/SimpleBlog/Page'
import Container from '@material-ui/core/Container'
import {TechCheckProps} from 'Event/Step3/TechCheck'
import ProgressBar from 'Event/template/SimpleBlog/check-in/ProgressBar'
import Body from 'Event/Step3/TechCheck/Body'
import Buttons from 'Event/Step3/TechCheck/Buttons'
import AdditionalContent from 'Event/Step3/TechCheck/AdditionalContent'
import ConfigFields from 'organization/Event/TechCheckConfig/ConfigFields'
import {useSimpleBlogTemplate} from 'Event/template/SimpleBlog'
import {DarkBackgroundTheme} from 'lib/ui/theme/ThemeProvider'

export default function TechCheck(props: TechCheckProps) {
  const template = useSimpleBlogTemplate()
  const {dashboardBackground} = template
  const {color, opacity} = dashboardBackground

  return (
    <SimpleBlogPage user={props.user}>
      <Container maxWidth="md">
        <DarkBackgroundTheme color={color} opacity={opacity}>
          <ProgressBar textKey="step3Text" percentKey="step3Percent" />
          <ConfigFields />
          <Body />
          <Buttons />
          <AdditionalContent />
        </DarkBackgroundTheme>
      </Container>
    </SimpleBlogPage>
  )
}

import Slider from 'lib/ui/Slider'
import {CountDownTimerSettings} from 'Event/Dashboard/components/CountDownTimer'
import ColorPicker from 'lib/ui/ColorPicker'
import React from 'react'
import {Controller} from 'react-hook-form'
import {useConfig} from 'organization/Event/Configurable'
import CollapsibleSection from 'lib/ui/ConfigPanel/CollapsibleSection'
import {NumberField} from 'lib/ui/TextField'

export default function Styling(props: {
  id: string
  sectionId: string
  countDownTimer: CountDownTimerSettings
}) {
  const {countDownTimer, sectionId, id} = props
  const {
    form: {control},
  } = useConfig()

  return (
    <>
      <CollapsibleSection
        label="Background"
        storageKey="dashboard.countdownTimer.styling.background"
      >
        <Controller
          name={`dashboardSections.${sectionId}.items.${id}.backgroundColor`}
          control={control}
          defaultValue={countDownTimer.backgroundColor}
          render={({value, onChange}) => (
            <ColorPicker label="Color" color={value} onPick={onChange} />
          )}
        />
        <Controller
          name={`dashboardSections.${sectionId}.items.${id}.backgroundOpacity"`}
          defaultValue={countDownTimer.backgroundOpacity}
          control={control}
          render={({value, onChange}) => (
            <Slider
              min={0}
              max={100}
              step={1}
              onChange={onChange}
              valueLabelDisplay="auto"
              value={value}
              label="Opacity"
              unit="%"
            />
          )}
        />
      </CollapsibleSection>
      <CollapsibleSection
        label="Number"
        storageKey="dashboard.countdownTimer.styling.number"
      >
        <Controller
          name={`dashboardSections.${sectionId}.items.${id}.numberColor`}
          control={control}
          defaultValue={countDownTimer.numberColor}
          render={({value, onChange}) => (
            <ColorPicker label="Color" color={value} onPick={onChange} />
          )}
        />
        <Controller
          name={`dashboardSections.${sectionId}.items.${id}.numberBackgroundColor`}
          control={control}
          defaultValue={countDownTimer.numberBackgroundColor}
          render={({value, onChange}) => (
            <ColorPicker
              label="Background Color"
              color={value}
              onPick={onChange}
            />
          )}
        />
        <Controller
          name={`dashboardSections.${sectionId}.items.${id}.numberBackgroundOpacity`}
          defaultValue={countDownTimer.numberBackgroundOpacity}
          control={control}
          render={({value, onChange}) => (
            <Slider
              min={0}
              max={100}
              step={1}
              onChange={onChange}
              valueLabelDisplay="auto"
              value={value}
              label="Background Opacity"
              unit="%"
            />
          )}
        />
        <Controller
          name={`dashboardSections.${sectionId}.items.${id}.numberBackgroundRadius`}
          defaultValue={countDownTimer.numberBackgroundRadius}
          control={control}
          render={({value, onChange}) => (
            <Slider
              min={0}
              max={1}
              step={0.1}
              onChange={onChange}
              valueLabelDisplay="auto"
              value={value}
              label="Background Radius"
            />
          )}
        />
        <Controller
          name={`dashboardSections.${sectionId}.items.${id}.numberBackgroundWidth`}
          control={control}
          defaultValue={countDownTimer.numberBackgroundWidth}
          render={({value, onChange}) => (
            <NumberField
              aria-label="number block width"
              fullWidth
              onChange={onChange}
              value={value}
              label="Width"
            />
          )}
        />
        <Controller
          name={`dashboardSections.${sectionId}.items.${id}.numberBackgroundHeight`}
          control={control}
          defaultValue={countDownTimer.numberBackgroundHeight}
          render={({value, onChange}) => (
            <NumberField
              aria-label="number block height"
              fullWidth
              onChange={onChange}
              value={value}
              label="Height"
            />
          )}
        />
      </CollapsibleSection>
      <CollapsibleSection
        label="Description"
        storageKey="dashboard.countdownTimer.styling.description"
      >
        <Controller
          name={`dashboardSections.${sectionId}.items.${id}.textColor`}
          control={control}
          defaultValue={countDownTimer.textColor}
          render={({value, onChange}) => (
            <ColorPicker label="Color" color={value} onPick={onChange} />
          )}
        />
      </CollapsibleSection>
    </>
  )
}

import TextField from 'lib/ui/TextField'
import {Label} from 'lib/ui/typography'
import React from 'react'
import Slider from 'lib/ui/Slider'
import {DEFAULT_FOOTER_IMAGE_SIZE} from 'Event/template/Lefty/Dashboard/Footer/FooterConfig'
import {Lefty} from 'Event/template/Lefty'
import {Controller, UseFormMethods} from 'react-hook-form'
import ImageAssetUploader from 'lib/asset/ImageAssetUploader'

const MIN_FOOTER_IMAGE_SIZE = 1
const MAX_FOOTER_IMAGE_SIZE = 550

export default function Settings(props: {
  footer: Lefty['footer']
  control: UseFormMethods['control']
}) {
  const {footer, control} = props

  return (
    <>
      <Controller
        name="image"
        control={control}
        defaultValue={footer.image}
        render={({onChange, value}) => (
          <ImageAssetUploader
            onChange={onChange}
            value={value}
            uploadLabel="Upload"
            canResize
            uploadInputProps={{
              'aria-label': 'footer image upload',
            }}
            disableAutoRemove
          />
        )}
      />
      <Controller
        name="imageSize"
        control={control}
        defaultValue={footer.imageSize || DEFAULT_FOOTER_IMAGE_SIZE}
        render={({value, onChange}) => (
          <Slider
            min={MIN_FOOTER_IMAGE_SIZE}
            max={MAX_FOOTER_IMAGE_SIZE}
            step={1}
            onChange={onChange}
            valueLabelDisplay="auto"
            value={value}
            aria-label="footer image size"
            label="Image Size"
          />
        )}
      />
      <Label>Terms Link</Label>
      <Controller
        name="termsLink"
        control={control}
        defaultValue={footer.termsLink || ''}
        render={({value, onChange}) => (
          <TextField
            value={value}
            aria-label="set footer terms link"
            fullWidth
            onChange={onChange}
          />
        )}
      />
      <Label>Privacy Link</Label>
      <Controller
        name="privacyLink"
        control={control}
        defaultValue={footer.privacyLink || ''}
        render={({value, onChange}) => (
          <TextField
            value={value}
            aria-label="set privacy terms link"
            fullWidth
            onChange={onChange}
          />
        )}
      />
      <Label>Copyright Text</Label>
      <Controller
        name="copyrightText"
        control={control}
        defaultValue={footer.copyrightText || ''}
        render={({value, onChange}) => (
          <TextField
            aria-label="set copyright text"
            fullWidth
            value={value}
            onChange={onChange}
          />
        )}
      />
    </>
  )
}

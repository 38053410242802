import styled from 'styled-components'
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react'
import {ColorChangeHandler, ChromePicker} from 'react-color'
import ReactDOM from 'react-dom'
import {isValidColor} from 'lib/color'
import {usePrevious} from 'lib/state'
import InputLabel from 'lib/ui/InputLabel'
import TextField, {TextFieldProps, useBackgroundColor} from 'lib/ui/TextField'

const DEFAULT_COLOR = '#FFFFFF'

export default function ColorPicker(
  props: {
    label?: string
    color?: string
    onPick: (color: string) => void
    'aria-label'?: string
    disabled?: boolean
  } & Pick<TextFieldProps, 'endAdornment' | 'variant'>,
) {
  const [showPicker, setShowPicker] = useState(false)
  const anchorRef = useRef<HTMLInputElement | null>(null)
  const [error, setError] = useState('')

  const inputBackground = useBackgroundColor(props)

  const {onPick, color} = props

  const [value, setValue] = useState(color || DEFAULT_COLOR)

  const prevValue = usePrevious(value)

  const toggleShowPicker = () => {
    setShowPicker(!showPicker)
  }

  useEffect(() => {
    setError('')

    if (value === color) {
      // no change
      return
    }

    if (!value) {
      onPick('')
      return
    }

    if (!isValidColor(value)) {
      setError('Invalid Color')
      return
    }

    if (prevValue === value) {
      // Was an update from parent
      return
    }

    onPick(value)
  }, [value, onPick, color, prevValue])

  // If parent color changes,
  useEffect(() => {
    setValue(color || DEFAULT_COLOR)
  }, [color])

  const handleColorChange: ColorChangeHandler = ({hex: newColor}) => {
    setValue(newColor)
  }

  return (
    <Box>
      <StyledInputLabel>{props.label}</StyledInputLabel>
      <Right>
        <SelectedColor
          value={value}
          inputBackground={inputBackground}
          onClick={toggleShowPicker}
        />
        <StyledTextField
          disabled={props.disabled}
          value={value}
          fullWidth
          onChange={setValue}
          inputProps={{
            'aria-label': props['aria-label'],
            ref: anchorRef,
          }}
          error={!!error}
          helperText={error}
          endAdornment={props.endAdornment}
        />
        <Picker
          visible={showPicker}
          color={value}
          onChangeColor={handleColorChange}
          toggle={toggleShowPicker}
          anchor={anchorRef.current}
        />
      </Right>
    </Box>
  )
}

export const COLOR_PICKER_POPOVER = 'color-picker-popover'

export function ColorPickerPopover() {
  return <div id={COLOR_PICKER_POPOVER}></div>
}

function Picker(props: {
  visible: boolean
  color?: string
  onChangeColor: ColorChangeHandler
  toggle: () => void
  anchor: HTMLDivElement | null
}) {
  const {anchor} = props

  const {
    left,
    top,
    calculating: calculatingPosition,
    containerRef,
  } = usePosition({
    anchor,
  })

  if (!props.visible) {
    return null
  }

  if (!props.anchor) {
    throw new Error('Missing color popover anchor')
  }

  // Render into element OUTSIDE of heirarchy. This allows the picker
  // to have its own z-index, even if rendered from inside
  // a dialog/modal.
  const popoverEl = document.getElementById(COLOR_PICKER_POPOVER)
  if (!popoverEl) {
    throw new Error('Missing color picker popover el')
  }

  return ReactDOM.createPortal(
    <Container
      left={left}
      top={top}
      ref={containerRef}
      showing={!calculatingPosition}
    >
      <HideOverlay onClick={props.toggle} />
      <ChromePicker
        color={props.color || undefined}
        onChange={props.onChangeColor}
        disableAlpha
      />
    </Container>,
    popoverEl,
  )
}

function usePosition(props: {anchor: HTMLDivElement | null}) {
  const {anchor} = props
  const [left, setLeft] = useState(0)
  const [top, setTop] = useState(0)

  const body = document.querySelector('body')
  const windowWidth = body?.clientWidth || 0
  const windowHeight = body?.clientHeight || 0

  const [containerWidth, setContainerWidth] = useState(0)
  const [containerHeight, setContainerHeight] = useState(0)

  const anchorRect = anchor?.getBoundingClientRect()

  const containerRef = useCallback((container: HTMLDivElement | null) => {
    if (!container) {
      return
    }

    setContainerWidth(container.getBoundingClientRect().width)
    setContainerHeight(container.getBoundingClientRect().height)
  }, [])

  useLayoutEffect(() => {
    if (!anchor || !containerWidth || !containerHeight) {
      return
    }

    const {
      left: inputLeft,
      top: anchorTop,
      height: anchorHeight,
      right: anchorRight,
    } = anchor?.getBoundingClientRect()

    // Left alignment - if the picker extends HORIZONTALLY past the
    // window, then we'll have it right aligne.d
    const isClippedHorizontally = inputLeft + containerWidth > windowWidth
    const inputRight = anchorRight - containerWidth
    const left = isClippedHorizontally ? inputRight : inputLeft
    setLeft(left)

    // Top alignment - if the picker extends VERTICALLY below the window
    // we'll move it up a bit, even if it covers the input.
    const topMargin = 8
    const topBelowInput = anchorTop + anchorHeight + topMargin + window.scrollY
    const topAboveInput =
      anchorTop - containerHeight - topMargin + window.scrollY
    const isClippedVertically = topBelowInput + containerHeight > windowHeight
    const top = isClippedVertically ? topAboveInput : topBelowInput
    setTop(top)
  }, [
    anchor,
    anchorRect,
    windowWidth,
    containerWidth,
    windowHeight,
    containerHeight,
  ])

  return {left, top, calculating: !left || !top, containerRef}
}

const Box = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${(props) => props.theme.spacing[6]};
`

const StyledInputLabel = styled(InputLabel)`
  flex: 10;
`

const Right = styled.div`
  display: flex;
  flex: 160px;
`

const Container = styled.div<{left: number; top: number; showing: boolean}>`
  position: absolute;
  visibility: ${(props) => (props.showing ? 'visible' : 'hidden')};
  z-index: 2000;
  left: ${(props) => `${props.left}px`};
  top: ${(props) => `${props.top}px`};

  // Hide color input on picker since we already have an input,
  // and also because React portal breaks mouse events. We
  // need the portal to render pickers inside modals.
  .chrome-picker {
    > div:nth-of-type(2) {
      padding: 16px !important;

      > div:nth-of-type(2) {
        display: none !important;
      }
    }
  }
`

const HideOverlay = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
`

const StyledTextField = styled(TextField)`
  margin-bottom: 0 !important;
  color: ${(props) => props.theme.colors.text.primary};
  font-weight: bold;
  text-transform: uppercase;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;

  > div {
    height: 100%;
    width: 100%;
    align-items: center;
  }

  input {
    height: 100%;
    width: 100%;
    padding: 8px 12px;
  }
`

const sizePx = 36

const SelectedColor = styled.div<{value?: string; inputBackground: string}>`
  width: ${sizePx}px;
  flex: 0 0 ${sizePx}px;
  height: ${sizePx}px;
  border-radius: 4px;
  background: ${(props) => props.value || props.inputBackground};

  margin-right: -4px;
  z-index: 1;
`

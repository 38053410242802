import styled from 'styled-components'
import React, {useEffect, useState} from 'react'
import TextField from '@material-ui/core/TextField'
import {handleAutocomplete, onChangeStringHandler} from 'lib/dom'
import {Autocomplete} from '@material-ui/lab'
import {useEvent} from 'Event/EventProvider'
import {api} from 'lib/url'
import {ajax, useObserve} from 'lib/rx'
import {debounceTime, filter, map, switchMap, tap} from 'rxjs/operators'
import {useAuthToken, useObvioUser} from 'obvio/auth'
import Button from '@material-ui/core/Button'
import {useToggle} from 'lib/toggle'
import Dialog from 'lib/ui/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import CreateNewFieldForm from 'organization/Event/Services/Apps/ActiveCampaign/Config/CreateNewFieldForm'
import Visible from 'lib/ui/layout/Visible'

export interface ActiveCampaignField {
  title: string
  id: string
  type: string
}

/**
 * Minimum amount of chars required to perform a search.
 */
const MIN_SEARCH_CHARS = 3

export default function FieldsAutocomplete(props: {
  value?: ActiveCampaignField | null
  onChange: (activeCampaignField: ActiveCampaignField | null) => void
  inputLabel?: string
  inputVariant?: 'filled' | 'standard' | 'outlined' | undefined
  saveButtonText?: string
  disabled?: boolean
  errorText?: string
  types?: string[]
}) {
  const {value, onChange, types} = props
  const [name, setName] = useState('')
  const {value$, onReady} = useObserve(name)
  const [options, setOptions] = React.useState<ActiveCampaignField[]>([])
  const [loading, setLoading] = React.useState(false)
  const {flag: showCreateForm, toggle: toggleShowCreateForm} = useToggle()
  const user = useObvioUser()

  const {
    event: {slug},
  } = useEvent()
  const token = useAuthToken()

  const handleOnCreate = (newField: ActiveCampaignField) => {
    toggleShowCreateForm()
    onChange(newField)
  }

  useEffect(() => {
    if (!onReady) {
      return
    }

    value$
      .pipe(
        filter((value) => {
          return value.length >= MIN_SEARCH_CHARS
        }),
        tap(() => {
          setLoading(true)
        }),
        debounceTime(500),
        switchMap((name) => {
          const url = api(
            `/events/${slug}/integrations/active_campaign/fields?search=${name}`,
          )

          return ajax.get(url, {
            accessToken: token,
          })
        }),
        map((res) => res.response),
        tap(() => {
          setLoading(false)
        }),
      )
      .subscribe({
        next: (options) => {
          const filteredOptions = options.filter(
            (option: ActiveCampaignField) => {
              if (types) {
                return types.includes(option.type)
              }

              return true
            },
          )

          setOptions(filteredOptions)
        },
      })

    onReady()
  }, [value$, token, slug, onReady, types])

  return (
    <Box>
      <Autocomplete
        disablePortal
        options={options}
        value={value || null}
        onChange={handleAutocomplete(onChange)}
        getOptionLabel={(option) => (option ? option.title : '')}
        loading={loading}
        closeIcon=""
        aria-label={'field id holder'}
        noOptionsText={'Type to search. Minimum length: 3 characters.'}
        getOptionSelected={(option, value) => option.id === value.id}
        renderInput={(params) => {
          return (
            <>
              <TextField
                {...params}
                onChange={onChangeStringHandler(setName)}
                variant={props.inputVariant ? props.inputVariant : 'standard'}
                fullWidth
                helperText={props.errorText ? props.errorText : ''}
                error={Boolean(props.errorText)}
                label={
                  props.inputLabel ? props.inputLabel : 'Active Campaign Field'
                }
                inputProps={{
                  ...params.inputProps,
                  'aria-label': 'field id',
                }}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <Visible when={user.is_admin}>
                      <Button
                        type="submit"
                        color="primary"
                        size="large"
                        aria-label="create field"
                        onClick={toggleShowCreateForm}
                      >
                        Create new field
                      </Button>
                    </Visible>
                  ),
                }}
              />
            </>
          )
        }}
      />
      <CreateNewFieldDialog
        visible={showCreateForm}
        onClose={toggleShowCreateForm}
        onCreate={handleOnCreate}
        types={types}
      />
    </Box>
  )
}

const CreateNewFieldDialog = (props: {
  visible: boolean
  onClose: () => void
  onCreate: (newTag: ActiveCampaignField) => void
  types?: string[]
}) => {
  const {visible, onCreate, onClose, types} = props

  return (
    <Dialog open={visible} onClose={onClose}>
      <DialogTitle>Create new field</DialogTitle>
      <DialogContent>
        <CreateNewFieldForm onCreate={onCreate} types={types} />
      </DialogContent>
    </Dialog>
  )
}

const Box = styled.div`
  position: relative;
  .MuiAutocomplete-popper {
    margin-top: -20px !important;
  }
`

import React from 'react'
import {Autocomplete, createFilterOptions} from '@material-ui/lab'
import {handleAutocomplete} from 'lib/dom'
import {TextField} from '@material-ui/core'
import styled from 'styled-components'

export type SearchableTextFieldProps = {
  label?: string | null
  options: string[]
  value: string | null
  onChange: (selected: string | null) => void
  disabled?: boolean
}

export default function SearchableTextField(props: SearchableTextFieldProps) {
  const {label, value, options, onChange} = props
  const filter = createFilterOptions<string>()

  return (
    <Autocomplete
      freeSolo
      options={options}
      value={value}
      aria-label="search autocomplete"
      onChange={handleAutocomplete(onChange)}
      disabled={props.disabled}
      closeIcon=""
      selectOnFocus
      clearOnBlur
      autoHighlight
      filterOptions={(options, params) => {
        // Update filter options to show current input text
        // as a selectable option.
        const filtered = filter(options, params)
        const {inputValue} = params
        if (!inputValue) {
          return filtered
        }

        const isExisting = options.includes(inputValue)
        if (isExisting) {
          return filtered
        }

        return [...filtered, inputValue]
      }}
      renderInput={(params) => (
        <StyledTextField
          {...params}
          placeholder={label || 'Field'}
          fullWidth
          variant="filled"
          inputProps={{
            ...params.inputProps,
            'aria-label': 'search field',
          }}
          InputProps={{
            ...params.InputProps,
          }}
        />
      )}
    />
  )
}

const StyledTextField = styled(TextField)`
  border-style: solid;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;

  > div {
    border-radius: 4px;
    padding: 12px !important;
  }

  > .Mui-disabled input {
    cursor: not-allowed;
  }

  > div > input {
    border-radius: 4px;
    padding: 0 !important;
  }

  > div:before {
    border: none;
    border-bottom-style: none !important;
  }

  > div:after {
    border: none;
  }

  > div:hover:before {
    border: none;
  }

  :focus {
    border: none;
  }
`

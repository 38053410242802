import {LoginProps} from 'Event/auth/Login'
import React from 'react'
import Page, {
  ErrorMessage,
  StyledFormContainer,
  DescriptionText,
} from 'Event/template/Townhall/Login/Page'
import EmailField from 'Event/auth/Login/EmailField'
import PasswordField from 'Event/auth/Login/PasswordField'
import SubmitButton from 'Event/auth/Login/SubmitButton'
import Description from 'Event/auth/Login/Description'
import Title from 'Event/template/Townhall/Login/Title'
import ForgotPasswordLink, {
  ForgotPasswordLinkBase,
} from 'Event/auth/Login/ForgotPasswordLink'
import styled from 'styled-components'
import SignUpLink, {SignUpLinkBase} from 'Event/auth/Login/SignUpLink'

export default function Login(props: LoginProps) {
  return (
    <Page isPreview={props.isPreview}>
      <StyledFormContainer>
        <Title />
        <Description Content={DescriptionText} />
        <form onSubmit={props.onSubmit}>
          <EmailField {...props} />
          <PasswordField {...props} />
          <ErrorMessage>{props.error}</ErrorMessage>
          <SubmitButton {...props} />
        </form>
        <ForgotPasswordLink Component={StyledForgotPasswordLink} />
        <SignUpLink Component={StyledSignUpLink} />
      </StyledFormContainer>
    </Page>
  )
}

export const StyledForgotPasswordLink = styled(ForgotPasswordLinkBase)<{
  color: string
}>`
  margin-top: ${(props) => props.theme.spacing[4]};
  display: block;
`

export const StyledSignUpLink = styled(SignUpLinkBase)<{
  color: string
}>`
  margin-top: ${(props) => props.theme.spacing[4]};
  display: block;
`

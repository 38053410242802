import React, {useCallback} from 'react'
import {v4 as uuid} from 'uuid'
import {useForm} from 'react-hook-form'
import {usePanelsUpdate} from 'Event/template/Panels'
import ComponentConfig, {
  ComponentConfigProps,
  RemoveButton,
  SaveButton,
  Footer,
} from 'organization/Event/DashboardConfig/ComponentConfigPanel'
import {CountDownTimerSettings} from 'Event/Dashboard/components/CountDownTimer'
import {REMOVE, useRemoveIfEmpty} from 'Event/TemplateUpdateProvider'
import Settings from 'Event/template/Panels/Dashboard/CountDownTimers/CountDownTimer/TimerConfig/Settings'
import Styling from 'Event/template/Panels/Dashboard/CountDownTimers/CountDownTimer/TimerConfig/Styling'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import StylingPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/StylingPanel'

export default function TimerConfig(
  props: ComponentConfigProps & {
    countDownTimer: CountDownTimerSettings
    id?: string
  },
) {
  const {showing: isVisible, onClose, id, countDownTimer} = props
  const updateTemplate = usePanelsUpdate()

  const {control, handleSubmit, formState} = useForm()

  const update = (id: string, updated: CountDownTimerSettings) => {
    updateTemplate({
      countDownTimers: {
        [id]: updated,
      },
    })
  }

  const insert = (countDownTimer: CountDownTimerSettings) => {
    const id = uuid()

    updateTemplate({
      countDownTimers: {
        [id]: countDownTimer,
      },
    })
  }

  const removeCountDownTimer = useCallback(() => {
    if (!id) {
      throw new Error('Missing count down timer id')
    }

    updateTemplate({
      countDownTimers: {
        [id]: REMOVE,
      },
    })
  }, [updateTemplate, id])

  useRemoveIfEmpty(removeCountDownTimer, countDownTimer, {
    shouldSkip: !id,
  })

  const save = (data: CountDownTimerSettings) => {
    if (id) {
      update(id, data)
    } else {
      insert(data)
    }

    onClose()
  }

  const showingRemoveButton = Boolean(id)

  return (
    <ComponentConfig
      showing={isVisible}
      onClose={onClose}
      title="Count Down Timer Config"
      onSubmit={handleSubmit(save)}
      hasChanges={formState.isDirty}
    >
      <SettingsPanel>
        <Settings control={control} countDownTimer={countDownTimer} />
      </SettingsPanel>
      <StylingPanel>
        <Styling control={control} countDownTimer={countDownTimer} />
      </StylingPanel>
      <Footer>
        <SaveButton type="submit" />
        <RemoveButton
          aria-label="remove countdown"
          onClick={removeCountDownTimer}
          showing={showingRemoveButton}
        />
      </Footer>
    </ComponentConfig>
  )
}

import styled from 'styled-components'
import React from 'react'
import {useAttendeeVariables} from 'Event'
import TextContent from 'lib/ui/form/TextEditor/Content'
import {
  BlogSectionProps,
  TownhallBlogPost,
} from 'Event/template/Townhall/Dashboard/Main/BlogSection'

export default function Content(props: {
  post: TownhallBlogPost
  postStyles?: BlogSectionProps['postStyles']
  textColor: string
}) {
  const {post, postStyles, textColor} = props
  const v = useAttendeeVariables()

  return (
    <StyledContent
      fontSize={postStyles?.contentFontSize}
      contentColor={postStyles?.contentTextColor}
      textColor={textColor}
    >
      {v(post.content)}
    </StyledContent>
  )
}

const StyledContent = styled(TextContent)<{
  fontSize?: number
  textColor: string
  contentColor?: string | null
}>`
  font-size: ${(props) => props.fontSize}px;
  color: ${(props) =>
    props.contentColor ? props.contentColor : props.textColor};
`

import {useCardsTemplate} from 'Event/template/Cards'
import React from 'react'
import styled from 'styled-components'

export default function BackgroundImage() {
  const {sidebar} = useCardsTemplate()

  if (!sidebar.backgroundImage) {
    return null
  }

  return (
    <StyledImage
      src={sidebar.backgroundImage}
      borderRadius={sidebar.borderRadius}
    />
  )
}

const StyledImage = styled.img<{borderRadius: number}>`
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: ${(props) => props.borderRadius}px;
`

import {PurchaseFormBlock} from 'Event/Marketplace/Block/PurchaseForm'

export const createPurchaseFormBlock = (): PurchaseFormBlock => ({
  type: 'PurchaseForm',
  visibility: 'all',
  errorMessage: 'Please Complete Required Fields',
  background: {
    image: '',
    color: '',
    opacity: 0,
  },
  isHidden: false,
  requireAddresses: false,
  border: {
    width: 0,
    radius: 0,
    color: '#000000',
  },
  padding: {
    left: 10,
    right: 10,
    top: 10,
    bottom: 10,
  },
  alignment: {
    vertical: 'center',
    horizontal: 'center',
  },
  input: {
    backgroundColor: '#FFFFFF',
    textColor: '#000000',
    fontSize: 20,
    spacing: 0,
    border: {
      width: 1,
      radius: 1,
      color: '#3490DC',
    },
    padding: {
      left: 8,
      right: 8,
      top: 4,
      bottom: 4,
    },
    placeholder: {
      firstName: 'First Name',
      lastName: 'Last Name',
      email: 'Email',
      phone: 'Phone',
      billingAddress1: 'Billing Address 1',
      billingAddress2: 'Billing Address 2',
      city: 'City',
      state: 'State',
      zipcode: 'Zip Code',
      country: 'Country',
      shippingAddress1: 'Shipping Address 1',
      shippingAddress2: 'Shipping Address 1',
      shippingcity: 'City',
      shippingstate: 'State',
      shippingzipcode: 'Zip Code',
      shippingcountry: 'Country',
    },
  },
  nextStepButton: {
    text: 'Next',
  },
  purchaseButton: {
    text: 'Confirm {{ticket_price}} Purchase',
  },
  buttonStyle: {
    textColor: '#3490DC',
    fontSize: 20,
    backgroundColor: 'transparent',
    borderWidth: 0,
    borderRadius: 0,
    size: 12,
    font: null,
  },
  phoneNumber: {
    enabled: false,
    required: false,
  },
  summary: {
    label: {
      attendeeDetailsLabel: 'Attendee Details',
      billingAddressLabel: 'Billing Address',
      shippingAddressLabel: 'Shipping Address',
      purchaseLabel: 'Purchase:',
      totalLabel: 'Total:',
      fontSize: 18,
      color: '#3490DC',
    },
    text: {
      fontSize: 18,
      color: '#3490DC',
    },
  },
  errors: {
    requiredField: 'This Field Is Required to Continue',
  },
  questions: {
    sameShippingAddress: 'Is your shipping address is the same as billing?',
  },
})

import React from 'react'
import {NavButtonWithSize} from 'Event/Dashboard/components/NavButton'
import TargetInput, {
  TargetInputProps,
} from 'Event/Dashboard/components/NavButton/NavButtonConfig/TargetInput'
import ContentInput from 'Event/Dashboard/components/NavButton/NavButtonConfig/ContentInput'
import {CardsNavButtonProps} from 'Event/template/Cards/Dashboard/CardsNavButton'
import VisibilityField from 'Event/Dashboard/components/NavButton/NavButtonConfig/BaseConfig/Settings/VisibilityField'
import ActionField from 'Event/Dashboard/components/NavButton/NavButtonConfig/BaseConfig/Settings/ActionField'
import TagFields from 'Event/Dashboard/components/NavButton/NavButtonConfig/BaseConfig/Settings/TagFields'
import WebhookFields from 'Event/Dashboard/components/NavButton/NavButtonConfig/BaseConfig/Settings/WebhookFields'

export default function BaseSettings(
  props: {
    button: NavButtonWithSize | CardsNavButtonProps
    id?: string | null
    customSettings?: JSX.Element | boolean
  } & Omit<TargetInputProps, 'button'>,
) {
  const {control, button, watch, register, setValue, customSettings} = props

  if (customSettings) {
    return <>{customSettings}</>
  }

  return (
    <>
      <VisibilityField button={button} control={control} />
      <ContentInput
        button={button}
        control={control}
        register={register}
        watch={watch}
      />
      <ActionField button={button} control={control} />
      <TargetInput
        button={button}
        control={control}
        register={register}
        watch={watch}
        setValue={setValue}
      />
      <TagFields button={button} control={control} />
      <WebhookFields
        button={button}
        control={control}
        register={register}
        watch={watch}
      />
    </>
  )
}

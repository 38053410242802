import {useEvent} from 'Event/EventProvider'
import {useAsync} from 'lib/async'
import {api} from 'lib/url'
import {useCallback, useEffect, useState} from 'react'

/**
 * A key/value store that is scoped an to the event. Useful for hardcoding
 * custom form values for attendees. ID(s) must be unique within an
 * event, or the value will be overwritten.
 */
export type Note = {
  id: string
  body: string
  files: NoteFile[]
}

type NoteFile = {
  hash: string
  url: string
  original_name: string
}

export type NoteContext = ReturnType<typeof useNote>

export function useNote(id: string) {
  const [note, setNote] = useState<Note | null>(null)
  const {event, client} = useEvent()
  const url = api(`/events/${event.id}/notes/${id}`)
  const request = useCallback(() => client.get<Note>(url), [url, client])
  const {data: saved, loading} = useAsync(request)

  useEffect(() => {
    if (!saved) {
      return
    }

    setNote(saved)
  }, [saved])

  const attachFile = (file: File) => {
    const data = new FormData()
    data.set('file', file)

    return client.post<Note>(`${url}/files`, data).then(setNote)
  }

  const save = (body: string) =>
    client
      .put<Note>(url, {body})
      .then(setNote)

  const removeFile = (file: string) =>
    client.delete<Note>(`${url}/files/${file}`).then(setNote)

  return {
    note,
    loading,
    attachFile,
    removeFile,
    save,
  }
}

import Page from 'Event/template/Panels/Page'
import React from 'react'
import styled from 'styled-components'
import LeftPanel from 'Event/template/Panels/check-in/LeftPanel'
import RightPanel from 'Event/template/Panels/check-in/RightPanel'
import MobilePanel from 'Event/template/Panels/check-in/MobilePanel'
import Waivers from 'Event/Step2/Waivers'
import {usePanelsTemplate} from 'Event/template/Panels'
import {DarkBackgroundTheme} from 'lib/ui/theme/ThemeProvider'
import {User} from 'auth/user'

export default function Step2(props: {user: User}) {
  return (
    <Page
      Left={<LeftPanel step={2} />}
      Right={
        <RightPanel>
          <Content user={props.user} />
        </RightPanel>
      }
      Mobile={
        <MobilePanel step={2}>
          <Content user={props.user} />
        </MobilePanel>
      }
    />
  )
}

function Content(props: {user: User}) {
  const template = usePanelsTemplate()
  const {checkInRightPanel} = template
  const {backgroundColor, backgroundOpacity} = checkInRightPanel

  return (
    <Container>
      <DarkBackgroundTheme color={backgroundColor} opacity={backgroundOpacity}>
        <Waivers user={props.user} />
      </DarkBackgroundTheme>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 400px;

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    align-items: flex-start;
    max-width: inherit;
  }
`

import CustomButtonLinkCommand from './custombuttonlinkcommand';
import Plugin from '@ckeditor/ckeditor5-core/src/plugin';

export default class CustomButtonLinkEditing extends Plugin {
	/**
      * @inheritDoc
      */
	static get pluginName() {
		return 'CustomButtonLinkEditing';
	}

	/**
      * @inheritDoc
      */
	init() {
		this.editor.commands.add( 'customButtonLink', new CustomButtonLinkCommand( this.editor ) );
	}
}


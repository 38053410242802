import React from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import {MoreActionsMenu} from 'lib/ui/Menu'
import {TableHeader} from 'lib/ui/typography'
import {useTemplate} from 'Event/TemplateProvider'
import EditMenuItem from 'organization/Event/Scripts/ScriptsTable/EditMenuItem'
import DeleteMenuItem from 'organization/Event/Scripts/ScriptsTable/DeleteMenuItem'
import {hasSelectedAll} from 'organization/Event/Scripts/ScriptForm/PagesSelector'

export default function ScriptsTable() {
  const {scripts} = useTemplate()

  const hasEmbedScripts = Object.keys(scripts).length > 0
  if (!hasEmbedScripts) {
    return <p>No scripts have been added.</p>
  }

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>
            <TableHeader>Name</TableHeader>
          </TableCell>
          <TableCell>
            <TableHeader>Pages</TableHeader>
          </TableCell>
          <TableCell />
        </TableRow>
      </TableHead>
      <TableBody>
        {Object.entries(scripts).map(([id, script]) => {
          return (
            <TableRow key={id}>
              <TableCell>{script.name}</TableCell>
              <TableCell>
                {hasSelectedAll(script.pages)
                  ? 'All Pages'
                  : script.pages.join(', ')}
              </TableCell>
              <TableCell>
                <MoreActionsMenu>
                  <EditMenuItem script={script} id={id} />
                  <DeleteMenuItem script={script} id={id} />
                </MoreActionsMenu>
              </TableCell>
            </TableRow>
          )
        })}
      </TableBody>
    </Table>
  )
}

import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import Button from 'lib/ui/Button'
import {useEvent} from 'Event/EventProvider'
import {api} from 'lib/url'
import {Room} from 'Event/room'
import {RoomDetails} from 'Event/Dashboard/TeamAccessControls'

export default function PauseButton(props: {room: RoomDetails}) {
  const {room} = props

  const pause = usePause()
  const [isProcessing, setIsProcessing] = useState<boolean>(false)
  const [selectedRoom, setSelectedRoom] = useState<Room | null>(null)

  useEffect(() => {
    setSelectedRoom(room)
  }, [room])

  if (!selectedRoom) {
    return null
  }

  const setPaused = (paused: boolean) => {
    if (isProcessing) {
      return
    }

    setIsProcessing(true)

    pause(selectedRoom, paused)
      .then((room) => {
        setSelectedRoom({
          ...selectedRoom,
          is_paused: room.is_paused,
        })
      })
      .finally(() => {
        setIsProcessing(false)
      })
  }

  if (selectedRoom.is_paused) {
    return (
      <StyledUnPauseButton
        variant="contained"
        color="warning"
        size="small"
        aria-label="unpause room"
        disabled={isProcessing}
        onClick={() => {
          setPaused(false)
        }}
      >
        Unpause Room
      </StyledUnPauseButton>
    )
  }

  return (
    <StyledPauseButton
      variant="outlined"
      size="small"
      color="warning"
      aria-label="pause room"
      disabled={isProcessing}
      onClick={() => {
        setPaused(true)
      }}
    >
      Pause Room
    </StyledPauseButton>
  )
}

export function usePause() {
  const {client} = useEvent()

  return (room: Room, pause: boolean) => {
    const url = pause
      ? api(`/rooms/${room.id}/pause`)
      : api(`/rooms/${room.id}/unpause`)
    return client.patch<Room>(url, {})
  }
}

const StyledPauseButton = styled(Button)`
  margin-left: ${(props) => props.theme.spacing[3]};
  display: inline;
`

const StyledUnPauseButton = styled(Button)`
  margin-left: ${(props) => props.theme.spacing[3]};
  display: inline;
`

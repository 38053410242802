import React from 'react'
import {Lefty, useLeftyUpdate} from 'Event/template/Lefty'
import {useForm} from 'react-hook-form'
import ComponentConfig, {
  ComponentConfigProps,
  SaveButton,
  Footer,
} from 'organization/Event/DashboardConfig/ComponentConfigPanel'
import {DeepPartial} from 'lib/type-utils'
import Settings from 'Event/template/Lefty/Dashboard/Header/HeaderConfig/Settings'
import Styling from 'Event/template/Lefty/Dashboard/Header/HeaderConfig/Styling'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import StylingPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/StylingPanel'
import {
  useAutoUpdate,
  useTemplateEditor,
} from 'organization/Event/TemplateEditor'
import {usePruneAssets} from 'lib/asset'

export default function HeaderConfig(props: ComponentConfigProps) {
  const {showing, onClose} = props
  const update = useLeftyUpdate()
  const {control, watch, handleSubmit, formState} = useForm()
  const pruneAssets = usePruneAssets()
  const {saved} = useTemplateEditor<Lefty>()

  const save = (data: DeepPartial<Lefty>) => {
    const logo = data.logo
    pruneAssets({logo}, saved)

    const headerBackground = data.header?.backgroundImage
    pruneAssets({background: headerBackground}, saved.header)

    const pageBackground = data.pageBackground?.image
    if (saved.pageBackground) {
      pruneAssets({image: pageBackground}, saved.pageBackground)
    }

    update(data)
    onClose()
  }

  useAutoUpdate({values: watch(), when: showing})

  return (
    <ComponentConfig
      showing={showing}
      onClose={onClose}
      title="Lefty"
      onSubmit={handleSubmit(save)}
      hasChanges={formState.isDirty}
    >
      <SettingsPanel>
        <Settings control={control} watch={watch} />
      </SettingsPanel>
      <StylingPanel>
        <Styling control={control} watch={watch} />
      </StylingPanel>
      <Footer>
        <SaveButton type="submit" />
      </Footer>
    </ComponentConfig>
  )
}

import {v4 as uuid} from 'uuid'
import {EMOJI_LIST} from 'Event/template/Panels/Dashboard/EmojiList'
import {SidebarItemProps} from 'Event/template/Lefty/Dashboard/Sidebar/SidebarItem'
import {
  AGENDA_LIST,
  createAgendaList,
} from 'Event/template/Lefty/Dashboard/Sidebar/SidebarItem/AgendaList'
import {
  createResourceList,
  RESOURCE_LIST,
} from 'Event/template/Lefty/Dashboard/Sidebar/SidebarItem/ResourceList'
import {createSidebarNav} from 'Event/template/Lefty/Dashboard/Sidebar/SidebarItem/SidebarNav'
import {
  createPointsSummary,
  POINTS_SUMMARY,
} from 'Event/template/Lefty/Dashboard/Sidebar/SidebarItem/PointsSummary'
import ComponentConfig, {
  SaveButton,
  Footer,
} from 'organization/Event/DashboardConfig/ComponentConfigPanel'
import React from 'react'
import {
  createTicketRibbonList,
  TICKET_RIBBON_LIST,
} from 'Event/template/Lefty/Dashboard/Sidebar/SidebarItem/TicketRibbonList'
import {
  createText,
  TEXT,
} from 'Event/template/Lefty/Dashboard/Sidebar/SidebarItem/Text'
import {useForm} from 'react-hook-form'
import {useLeftyUpdate} from 'Event/template/Lefty'
import {createEmojiList} from 'Event/template/Lefty/Dashboard/Sidebar/SidebarItem/EmojiList'
import Settings from 'Event/template/Lefty/Dashboard/Sidebar/AddSidebarItemButton/AddSidebarItemConfig/Settings'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import {SIDEBAR_NAV} from 'Event/template'

export default function AddSidebarItemConfig(props: {
  showing: boolean
  onClose: () => void
}) {
  const {showing, onClose} = props
  const {control, handleSubmit, formState} = useForm()
  const update = useLeftyUpdate()

  const add = (formData: {type: SidebarItemProps['type']}) => {
    if (!formData.type) {
      return
    }

    const id = uuid()
    const item = createItem(formData.type)

    update({
      sidebarItems: {
        [id]: item,
      },
    })

    onClose()
  }

  return (
    <ComponentConfig
      showing={showing}
      onClose={onClose}
      title="Add Sidebar Item"
      onSubmit={handleSubmit(add)}
      hasChanges={formState.isDirty}
    >
      <SettingsPanel>
        <Settings control={control} />
      </SettingsPanel>
      <Footer>
        <SaveButton type="submit" />
      </Footer>
    </ComponentConfig>
  )
}

function createItem(type: SidebarItemProps['type']): SidebarItemProps {
  switch (type) {
    case AGENDA_LIST:
      return createAgendaList()
    case EMOJI_LIST:
      return createEmojiList()
    case RESOURCE_LIST:
      return createResourceList()
    case POINTS_SUMMARY:
      return createPointsSummary()
    case SIDEBAR_NAV:
      return createSidebarNav()
    case TICKET_RIBBON_LIST:
      return createTicketRibbonList()
    case TEXT:
      return createText()
  }
}

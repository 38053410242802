import {Panels, usePanelsTemplate, usePanelsUpdate} from 'Event/template/Panels'
import IconSelect from 'lib/fontawesome/IconSelect'
import ColorPicker from 'lib/ui/ColorPicker'
import Title from 'lib/ui/ConfigPanel/Title'
import TextField from 'lib/ui/TextField'
import {useConfig} from 'organization/Event/Configurable'
import Config from 'organization/Event/Configurable/Config'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import StylingPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/StylingPanel'
import React from 'react'
import {Controller} from 'react-hook-form'

export default function StepConfig(props: {step: 1 | 2 | 3}) {
  const {step} = props
  const save = usePanelsUpdate()
  const title = `Step ${step}`
  const template = usePanelsTemplate()
  const {
    form: {register, control},
  } = useConfig()

  const nameKey: keyof Panels = `step${step}Label`
  const iconKey: keyof Panels = `step${step}Icon`

  return (
    <Config title={title} onSave={save}>
      <SettingsPanel>
        <Title>Label</Title>
        <TextField
          label="Text"
          fullWidth
          name={nameKey}
          defaultValue={template[nameKey]}
          inputProps={{
            'aria-label': `step ${step} label`,
            ref: register,
          }}
        />
        <Controller
          name={iconKey}
          defaultValue={template[iconKey]}
          control={control}
          render={({value, onChange}) => (
            <IconSelect value={value} onChange={onChange} />
          )}
        />
      </SettingsPanel>
      <StylingPanel>
        <Controller
          name="stepLabelColor"
          defaultValue={template.stepLabelColor}
          control={control}
          render={({value, onChange}) => (
            <ColorPicker
              label="Step Label Color"
              color={value}
              onPick={onChange}
              aria-label="step label color"
            />
          )}
        />
        <Controller
          name="stepIconColor"
          defaultValue={template.stepIconColor}
          control={control}
          render={({value, onChange}) => (
            <ColorPicker
              label="Step Icon Color"
              color={value}
              onPick={onChange}
              aria-label="step icon color"
            />
          )}
        />
        <Controller
          name="stepInactiveColor"
          defaultValue={template.stepInactiveColor}
          control={control}
          render={({value, onChange}) => (
            <ColorPicker
              label="Step Inactive Color"
              color={value}
              onPick={onChange}
              aria-label="step inactive color"
            />
          )}
        />
      </StylingPanel>
    </Config>
  )
}

import Command from "@ckeditor/ckeditor5-core/src/command";
import { ELEMENT_NAME } from "./Value";

export default class InsertVariableCommand extends Command {
	execute({ value }) {
		const editor = this.editor;
		const selection = editor.model.document.selection;

		editor.model.change((writer) => {
			// Create a <obvioVariable> elment with the "name" attribute (and all the selection attributes)...
			const obvioVariable = writer.createElement(ELEMENT_NAME, {
				...Object.fromEntries(selection.getAttributes()),
				name: value,
			});

			// ... and insert it into the document.
			editor.model.insertContent(obvioVariable);

			// Put the selection on the inserted element.
			writer.setSelection(obvioVariable, "on");
		});
	}

	refresh() {
		const model = this.editor.model;
		const selection = model.document.selection;

		const isAllowed = model.schema.checkChild(
			selection.focus.parent,
			ELEMENT_NAME
		);

		this.isEnabled = isAllowed;
	}
}

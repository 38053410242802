import ColorPicker from 'lib/ui/ColorPicker'
import {onUnknownChangeHandler} from 'lib/dom'
import Select from 'lib/ui/Select'
import Option from 'lib/ui/Select/Option'
import {useTownhallTemplate} from 'Event/template/Townhall'
import React from 'react'
import {Controller, UseFormMethods} from 'react-hook-form'

export default function Styling(props: {control: UseFormMethods['control']}) {
  const {control} = props
  const template = useTownhallTemplate()

  return (
    <>
      <Controller
        name="speakers.carouselArrowColor"
        defaultValue={template.speakers.carouselArrowColor}
        control={control}
        render={({value, onChange}) => (
          <ColorPicker
            label="Carousel Arrow Color"
            color={value}
            onPick={onChange}
            aria-label="carousel arrow color"
          />
        )}
      />
      <Controller
        name="speakers.alignment"
        defaultValue={template.speakers.alignment}
        control={control}
        render={({value, onChange}) => (
          <Select
            fullWidth
            label="Speakers Align"
            value={value}
            onChange={onUnknownChangeHandler(onChange)}
          >
            <Option value="left">Left</Option>
            <Option value="center">Center</Option>
            <Option value="right">Right</Option>
          </Select>
        )}
      />
    </>
  )
}

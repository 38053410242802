import Divider from '@material-ui/core/Divider'
import {useObvioUser} from 'obvio/auth'
import React from 'react'
import styled from 'styled-components'

export default React.forwardRef(() => {
  const user = useObvioUser()

  return (
    <>
      <UserEmail>
        <LoggedInAsLabel>Logged in as</LoggedInAsLabel>
        <br />
        {user.email}
      </UserEmail>
      <Divider />
    </>
  )
})

const UserEmail = styled.div`
  padding: 6px 16px;
`

const LoggedInAsLabel = styled.span`
  color: ${(props) => props.theme.colors.text.muted};
  font-size: 0.75rem;
`

import SignUp from 'Event/auth/SignUp'
import {DesignBreadcrumbs} from 'organization/Event/Page/PageBreadcrumbs'
import Page from 'organization/Event/TemplateEditor/Page'
import React from 'react'
import SignUpEnabledSwitch from 'Event/auth/SignUp/SignUpEnabledSwitch'
import {ConfigButton} from 'organization/Event/Configurable'
import Button from 'lib/ui/Button'
import {useEvent} from 'Event/EventProvider'
import RulesConfig from 'Event/auth/SignUp/RulesConfig'

export default function SignUpConfig() {
  return (
    <DesignBreadcrumbs page="Sign Up">
      <Page
        toolbarItems={
          <>
            <SignUpEnabledSwitch />
            <NewAttendeeRulesButton />
          </>
        }
      >
        <SignUp />
      </Page>
    </DesignBreadcrumbs>
  )
}

function NewAttendeeRulesButton() {
  const {event} = useEvent()

  const isSignUpEnabled = event.sign_up_enabled

  return (
    <ConfigButton config={<RulesConfig />}>
      {(onToggle) => (
        <Button
          size="small"
          type="button"
          variant="text"
          aria-label="new attendee rules"
          onClick={onToggle}
          disabled={!isSignUpEnabled}
        >
          New Attendee Rules
        </Button>
      )}
    </ConfigButton>
  )
}

import {EMOJI_LIST} from 'Event/template/Panels/Dashboard/EmojiList'
import {AGENDA_LIST} from 'Event/template/SimpleBlog/Dashboard/Sidebar/SidebarItem/AgendaList'
import {RESOURCE_LIST} from 'Event/template/SimpleBlog/Dashboard/Sidebar/SidebarItem/ResourceList'
import {POINTS_SUMMARY} from 'Event/template/SimpleBlog/Dashboard/Sidebar/SidebarItem/PointsSummary'
import {onUnknownChangeHandler} from 'lib/dom'
import {TICKET_RIBBON_LIST} from 'Event/template/SimpleBlog/Dashboard/Sidebar/SidebarItem/TicketRibbonList'
import {TEXT} from 'Event/template/SimpleBlog/Dashboard/Sidebar/SidebarItem/Text'
import Select from 'lib/ui/Select'
import Option from 'lib/ui/Select/Option'
import React from 'react'
import {Controller, UseFormMethods} from 'react-hook-form'
import {SIDEBAR_NAV} from 'Event/template'

export default function Settings(props: {control: UseFormMethods['control']}) {
  const {control} = props

  return (
    <Controller
      name="type"
      control={control}
      defaultValue=""
      render={({value, onChange}) => (
        <Select
          fullWidth
          label="Pick one"
          value={value}
          onChange={onUnknownChangeHandler(onChange)}
          aria-label="select sidebar item"
        >
          <Option value={AGENDA_LIST}>Agendas</Option>
          <Option value={EMOJI_LIST}>Emojis</Option>
          <Option value={RESOURCE_LIST}>Resources</Option>
          <Option value={POINTS_SUMMARY}>Points Summary</Option>
          <Option value={SIDEBAR_NAV}>Sidebar Buttons</Option>
          <Option value={TICKET_RIBBON_LIST}>Ticket Ribbons</Option>
          <Option value={TEXT}>Text</Option>
        </Select>
      )}
    />
  )
}

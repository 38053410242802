import {ImageBlock} from 'Event/Marketplace/Block/Image'

export const createImageBlock = (): ImageBlock => ({
  type: 'Image',
  visibility: 'all',
  background: {
    image: '',
    color: '',
    opacity: 0,
  },
  padding: {
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
  border: {
    width: 0,
    color: '#FFFFFF',
    radius: 0,
  },
  alignment: {
    vertical: 'center',
    horizontal: 'center',
  },
  image: null,
  size: 60,
  sizeMobile: 80,
  borderWidth: 0,
  borderRadius: 0,
  borderColor: '#FFFFFF',
})

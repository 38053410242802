import styled from 'styled-components'
import ArrowDownIcon from '@material-ui/icons/ArrowDropDown'
import React from 'react'
import Button, {ButtonProps} from 'lib/ui/Button'
import Menu, {MenuProps} from 'lib/ui/Menu'
import ButtonGroup from '@material-ui/core/ButtonGroup'

/**
 * A Button with an arrow that opens a Menu with additional options.
 *
 * @param props
 */
export default function MenuButton(
  props: Omit<ButtonProps, 'children'> & {
    children: JSX.Element | JSX.Element[]
    label: string
    MenuProps?: Omit<MenuProps, 'button'>
    ArrowButtonProps?: Partial<ButtonProps>
  },
) {
  const {
    children,
    label,
    MenuProps,
    type = 'button',
    ArrowButtonProps,
    ...buttonProps
  } = props

  const items = Array.isArray(children) ? children : [children]

  return (
    <Menu
      {...MenuProps}
      button={({open}) => (
        <ButtonGroup variant="contained" fullWidth>
          <StyledButton {...buttonProps} type={type}>
            {label}
          </StyledButton>
          <ArrowButton
            {...buttonProps}
            {...ArrowButtonProps}
            size="small"
            aria-haspopup="menu"
            onClick={open}
            type="button"
          >
            <ArrowDownIcon />
          </ArrowButton>
        </ButtonGroup>
      )}
      children={items}
    />
  )
}

const StyledButton = styled(Button)`
  &:disabled {
    cursor: not-allowed;
  }
`

const ArrowButton = styled(Button)`
  flex: 0;
  padding: 0;

  &:disabled {
    cursor: not-allowed;
  }
`

import React, {useState} from 'react'
import moment from 'moment'
import styled, {useTheme} from 'styled-components'
import {
  LineChart,
  XAxis,
  YAxis,
  Tooltip,
  TooltipProps,
  Legend,
  Line,
  CartesianGrid,
  ReferenceArea,
  ResponsiveContainer,
} from 'recharts'
import {useZoomAttendance} from 'organization/Event/ZoomAttendance/ZoomAttendanceProvider'
import {CategoricalChartState} from 'recharts/types/chart/generateCategoricalChart'
import {Payload} from 'recharts/types/component/DefaultLegendContent'
import {DataKey} from 'recharts/types/util/types'

interface Opacities {
  joined: number
  left: number
  inZoom: number
}

interface CustomTooltipProps extends TooltipProps<any, any> {
  opacities: Opacities
}

type LegendEvent = Payload & {
  dataKey?: DataKey<any> | undefined
}

export default function TimeSeries(props: {className?: string}) {
  const theme = useTheme()
  const {className} = props
  const [selectedStart, setSelectedStart] = useState<string>()
  const [selectedEnd, setSelectedEnd] = useState<string>()
  const [opacities, setOpacities] = useState<Opacities>({
    joined: 1.0,
    left: 1.0,
    inZoom: 1.0,
  })
  const [singledOut, setSingledOut] = useState<string | null>()
  const {area, zoomAttendance, fetchZoomAttendance} = useZoomAttendance()

  const CustomizedAxisTick = (props: {
    x?: number
    y?: number
    stroke?: string
    payload?: {
      value: string
    }
  }) => {
    const {x, y, payload} = props

    const values = (payload?.value || '').split(' ')
    const newY = (y || 0) + 20

    return (
      <>
        <g transform={`translate(${x},${y})`}>
          <text
            x={0}
            y={0}
            dy={16}
            textAnchor="end"
            fill="#666"
            transform="rotate(-35)"
          >
            {values[0]}
          </text>
        </g>
        <g transform={`translate(${x},${newY})`}>
          <text
            x={0}
            y={0}
            dy={16}
            textAnchor="end"
            fill="#666"
            transform="rotate(-35)"
          >
            {values[1]}
          </text>
        </g>
      </>
    )
  }

  const handleMouseUp = () => {
    let start = moment(selectedStart).toISOString()
    let end = moment(selectedEnd).toISOString()

    // When the start datetime is the same as the end, the query in the backend
    // won't give us any results, so we don't want to do anything here. Clear out
    // the selected datetimes and be done.
    if (start === end) {
      setSelectedStart('')
      setSelectedEnd('')

      return
    }

    // If the start datetime is greater than the end datetime, this is more than
    // likely a user clicking on the TimeSeries chart and draging backwards in
    // time. Need to flip these datetimes around so the query makes sense.
    if (start > end) {
      const temporaryEnd = start
      start = end
      end = temporaryEnd
    }

    setSelectedStart('')
    setSelectedEnd('')

    fetchZoomAttendance(start, end, area)
  }

  const adjustOpacities = (type: string, dataKey?: string) => {
    let otherLines = 0.0

    switch (type) {
      case 'highlight':
        otherLines = 0.2
        break
      case 'click':
        otherLines = singledOut === dataKey ? 1.0 : 0.2
        setSingledOut(singledOut === dataKey ? null : dataKey)
        break
      case 'reset':
        otherLines = 1.0
        break
    }

    setOpacities({
      joined: dataKey === 'Joined' ? 1.0 : otherLines,
      left: dataKey === 'Left' ? 1.0 : otherLines,
      inZoom: dataKey === 'In Zoom' ? 1.0 : otherLines,
    })
  }

  const handleLegendClick = (e: LegendEvent) => {
    adjustOpacities('click', e.dataKey as string)
  }

  const handleLegendMouseOver = (e: LegendEvent) => {
    if (!singledOut) {
      adjustOpacities('highlight', e.dataKey as string)
    }
  }

  const handleLegendMouseLeave = (_e: LegendEvent) => {
    if (!singledOut) {
      adjustOpacities('reset')
    }
  }

  const handleSelectedStart = (e?: CategoricalChartState) => {
    if (e?.activeLabel) {
      setSingledOut(null)
      setSelectedStart(e.activeLabel)
    }
  }

  const handleSelectedEnd = (e?: CategoricalChartState) => {
    if (e?.activeLabel && selectedStart) {
      setSingledOut(null)
      setSelectedEnd(e.activeLabel)
    }
  }

  if (!zoomAttendance) {
    return null
  }

  return (
    <ResponsiveContainer width="100%" height={500} className={className}>
      <LineChart
        width={500}
        height={500}
        data={zoomAttendance.attendee_events}
        onMouseDown={handleSelectedStart}
        onMouseMove={handleSelectedEnd}
        onMouseUp={handleMouseUp}
        margin={{
          top: 0,
          right: 10,
          left: 10,
          bottom: 70,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" tick={<CustomizedAxisTick />} />
        <YAxis type="number" padding={{top: 10}} />
        <Tooltip
          content={(props) => (
            <CustomTooltip {...props} opacities={opacities} />
          )}
        />
        <Legend
          margin={{bottom: 10}}
          onClick={handleLegendClick}
          onMouseLeave={handleLegendMouseLeave}
          onMouseOver={handleLegendMouseOver}
          verticalAlign="top"
        />
        <Line
          activeDot={{r: 4}}
          animationDuration={700}
          dataKey="In Zoom"
          dot={{r: 0}}
          stroke={
            opacities.inZoom === 1
              ? theme.colors.chart.zoomAttendance.inZoom
              : '#ccc'
          }
          strokeOpacity={opacities.inZoom}
          type="monotone"
        />
        <Line
          activeDot={{r: 4}}
          animationDuration={700}
          dataKey="Joined"
          dot={{r: 0}}
          stroke={
            opacities.joined === 1
              ? theme.colors.chart.zoomAttendance.join
              : '#ccc'
          }
          strokeOpacity={opacities.joined}
          type="monotone"
        />
        <Line
          activeDot={{r: 4}}
          animationDuration={700}
          dataKey="Left"
          dot={{r: 0}}
          stroke={
            opacities.left === 1
              ? theme.colors.chart.zoomAttendance.left
              : '#ccc'
          }
          strokeOpacity={opacities.left}
          type="monotone"
        />
        {selectedStart && selectedEnd ? (
          <ReferenceArea
            x1={selectedStart}
            x2={selectedEnd}
            stroke={theme.colors.primary}
            fill={theme.colors.primary}
            fillOpacity={0.1}
            strokeOpacity={0.3}
          />
        ) : null}
      </LineChart>
    </ResponsiveContainer>
  )
}

const CustomTooltip: React.FC<CustomTooltipProps> = ({
  active,
  payload,
  opacities,
}) => {
  if (!active || !payload || payload.length === 0) {
    return null
  }
  const date = payload[0].payload.name
  const activePayload = payload.filter((p) => {
    return (
      (p.name === 'In Zoom' && opacities.inZoom === 1) ||
      (p.name === 'Joined' && opacities.joined === 1) ||
      (p.name === 'Left' && opacities.left === 1)
    )
  })

  return (
    <TooltipContainer>
      <p>{date}</p>
      {activePayload.map((p, index) => (
        <p
          key={index}
          style={{color: (p as any).stroke}}
        >{`${p.name}: ${p.value}`}</p>
      ))}
    </TooltipContainer>
  )
}

const TooltipContainer = styled.div`
  padding: ${(props) => props.theme.spacing[2]};
  background: white;
  border: 1px solid rgba(0, 0, 0, 0.2);
`

import React from 'react'
import styled from 'styled-components'
import {Agenda} from 'Event/template/Townhall/Dashboard/Main/AgendaSection'
import {useTownhallTemplate} from 'Event/template/Townhall'

export default function SpeakerNames(props: {agenda: Agenda}) {
  const {agenda} = props

  const {speakers} = useTownhallTemplate()

  const names = agenda.speakers
    .map((id) => speakers.items[id]?.name)
    .filter((name) => Boolean(name)) // Handle missing speakers
    .join(', ')

  return <SpeakerNamesText>{names}</SpeakerNamesText>
}

const SpeakerNamesText = styled.div`
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 170%;
  letter-spacing: 0.16px;
`

import {ActiveCampaignTag} from 'Event/activeCampaign'
import {useEvent} from 'Event/EventProvider'
import ErrorAlert from 'lib/ui/alerts/ErrorAlert'

import Button from 'lib/ui/Button'
import TextField from 'lib/ui/TextField'
import {api} from 'lib/url'
import {useOrganization} from 'organization/OrganizationProvider'
import React, {useState} from 'react'
import {useForm} from 'react-hook-form'
import styled from 'styled-components'

export default function CreateNewTagForm(props: {
  onCreate: (newTag: ActiveCampaignTag) => void
}) {
  const {onCreate} = props
  const {register} = useForm()
  const {
    event: {id: eventId},
  } = useEvent()
  const {client} = useOrganization()
  const [processing, setProcessing] = useState<boolean>(false)
  const [error, setError] = useState<string | null>(null)
  const [newTagName, setNewTagName] = useState<string>('')
  const create = () => {
    setProcessing(true)

    const url = api(
      `/events/${eventId}/integrations/active_campaign/create_tag`,
    )

    client
      .post<ActiveCampaignTag>(url, {tag_name: newTagName})
      .then(onCreate)
      .catch((e) => setError(e.message))
      .finally(() => {
        setProcessing(false)
      })
  }

  return (
    <>
      <TextField
        fullWidth
        placeholder="Tag name"
        variant="outlined"
        inputProps={{
          'aria-label': 'tag name',
          ref: register,
        }}
        name="tag_name"
        onChange={setNewTagName}
      />
      <ErrorAlert>{error}</ErrorAlert>
      <CreateButton
        fullWidth
        variant="contained"
        color="primary"
        aria-label="create new tag"
        disabled={processing}
        onClick={create}
      >
        Create
      </CreateButton>
    </>
  )
}

const CreateButton = styled(Button)`
  margin-bottom: ${(props) => props.theme.spacing[4]}!important;
`

import {
  RIBBONS,
  TicketRibbon,
} from 'Event/template/Lefty/Dashboard/Sidebar/SidebarItem/TicketRibbonList/Ribbon'
import faker from 'faker'

export const fakeTicketRibbon = (
  overrides?: Partial<TicketRibbon>,
): TicketRibbon => ({
  name: faker.random.arrayElement(RIBBONS),
  text: faker.random.word(),
  color: '#FFFFFF',
  rules: [],
  ...overrides,
})

import React, {useEffect, useState} from 'react'
import ComponentConfig, {
  ComponentConfigProps,
  SaveButton,
  RemoveButton,
} from 'organization/Event/DashboardConfig/ComponentConfigPanel'
import {EmojiListProps} from 'Event/template/Lefty/Dashboard/Sidebar/SidebarItem/EmojiList'
import {useEditSidebarItem} from 'Event/template/Lefty/Dashboard/Sidebar/SidebarItem'
import Settings from 'Event/template/Lefty/Dashboard/Sidebar/SidebarItem/EmojiList/EmojiListConfig/Settings'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'

export function EmojiListConfig(
  props: ComponentConfigProps & {
    list: EmojiListProps
  },
) {
  const {showing, onClose} = props
  const {list} = props
  const [emojiWidth, setEmojiWidth] = useState(list.emojiWidth)
  const [emojis, setEmojis] = useState(list.emojis)
  const {remove: removeItem, update: updateItem} = useEditSidebarItem()

  useEffect(() => {
    if (showing) {
      return
    }

    setEmojiWidth(list.emojiWidth)
    setEmojis(list.emojis)
  }, [showing, list])

  const save = () => {
    const data = {
      emojiWidth,
      emojis,
    }

    updateItem(data)
    onClose()
  }

  return (
    <ComponentConfig title="Emojis" showing={showing} onClose={onClose}>
      <>
        <SettingsPanel>
          <Settings
            emojiWidth={emojiWidth}
            setEmojiWidth={setEmojiWidth}
            emojis={emojis}
            setEmojis={setEmojis}
          />
        </SettingsPanel>
        <SaveButton onClick={save} />
        <RemoveButton onClick={removeItem} />
      </>
    </ComponentConfig>
  )
}

import {useGuestVariables} from 'Event'
import TextField from 'Event/auth/ChangePassword/TextField'
import {useTemplate} from 'Event/TemplateProvider'
import Configurable from 'organization/Event/Configurable'
import React from 'react'
import {ChangePasswordRequest} from 'auth/password'
import ConfirmNewPasswordFieldConfig from 'Event/auth/ChangePassword/ConfirmNewPasswordField/ConfirmNewPasswordFieldConfig'

export default function ConfirmNewPasswordField(props: ChangePasswordRequest) {
  const v = useGuestVariables()
  const template = useTemplate()

  const confirmNewPasswordLabel = v(
    template.changePassword.confirmNewPasswordLabel,
  )

  return (
    <Configurable>
      <ConfirmNewPasswordFieldConfig />
      <TextField
        label={confirmNewPasswordLabel}
        type="password"
        fullWidth
        variant="outlined"
        name="password_confirmation"
        disabled={props.submitting}
        inputProps={{
          ref: props.register({
            required: `${confirmNewPasswordLabel} is required`,
          }),
          'aria-label': 'confirm new password',
        }}
        error={!!props.errors.password_confirmation}
        helperText={props.errors.password_confirmation}
      />
    </Configurable>
  )
}

import React from 'react'
import styled from 'styled-components'
import Content from 'lib/ui/form/TextEditor/Content'
import {Font, fontStyles} from 'lib/FontSelect'
import {Icon, IconProps} from 'lib/fontawesome/Icon'
import Collapse from '@material-ui/core/Collapse'
import Card from 'lib/ui/Card'
import MuiCardContent, {CardContentProps} from '@material-ui/core/CardContent'
import {makeStyles} from '@material-ui/styles'
import {spacing} from 'lib/ui/theme'
import {useAttendeeVariables} from 'Event'
import {rgba} from 'lib/color'
import {useToggleArray} from 'lib/toggle'
import {FaqItem, FaqListBlock} from './index'

interface ListItemProps extends FaqListBlock {
  item: FaqItem
}

export default function ListItem(props: ListItemProps) {
  const {item, icon} = props

  const [expanded, toggleExpand] = useToggleArray(item.showAnswerOnLoad)
  const v = useAttendeeVariables()

  const backgroundColor = rgba(item.background.color, item.background.opacity)

  return (
    <Box
      {...item}
      faqSpace={props.faqSpace}
      backgroundColor={backgroundColor}
      aria-label="faq list"
      onClick={toggleExpand}
    >
      <Card>
        <ListItemBox {...props}>
          <StyledIcon iconClass={icon} {...props} expanded={expanded} />
          <StyledContent iconSize={props.iconSize} fontSize={props.fontSize}>
            {v(item.question)}
          </StyledContent>
        </ListItemBox>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            <Content>{v(item.answer)}</Content>
          </CardContent>
        </Collapse>
      </Card>
    </Box>
  )
}

export const StyledIcon = styled(
  (
    props: IconProps & {
      iconColor: string
      iconSize: number
      textSpace: number
      expanded: boolean
    },
  ) => {
    const {iconSize: _, ...otherProps} = props
    return <Icon {...otherProps} />
  },
)`
  font-size: ${(props) => props.iconSize}px !important;
  width: ${(props) => props.iconSize}px;
  height: ${(props) => props.iconSize}px;
  margin-right: ${(props) => props.textSpace}px;
  color: ${(props) => props.iconColor};
  transform: ${(props) =>
    props.expanded ? 'rotate(-180deg) translateX(0.25em)' : 'unset'};
  transition: 0.3s ease;
`

const useStyles = makeStyles({
  root: {
    paddingTop: 0,
    paddingBottom: spacing[2],
    marginBottom: spacing[3],
  },
})

function CardContent(props: CardContentProps) {
  const classes = useStyles()

  return <MuiCardContent {...props} classes={classes} />
}

const ListItemBox = styled.div<{
  fontSize: number
  color: string
  font: Font | null
}>`
  display: flex;
  cursor: pointer;

  ${(props) => fontStyles(props.font)}
  font-size: ${(props) => props.fontSize}px;
  color: ${(props) => props.color};
`

const Box = styled.div<{
  faqSpace: number
  background: {
    image: string
  }
  border: {
    width: number
    color: string
    radius: number
  }
  backgroundColor: string
}>`
  padding: ${(props) => props.theme.spacing[2]};
  margin-top: ${(props) => props.faqSpace}px;
  ${(props) =>
    props.background.image
      ? `background-image: url(${props.background.image});`
      : null}
  background-color: ${(props) => props.backgroundColor};
  background-blend-mode: multiply;
  background-position: center;
  background-size: cover !important;
  background-repeat: no-repeat;
  border: ${(props) => props.border.width}px solid;
  border-color: ${(props) => props.border.color};
  border-radius: ${(props) => props.border.radius}px;
  width: 100%;
  height: 100%;
  &:last-child {
    margin-bottom: ${(props) => props.faqSpace}px;
  }
`

const StyledContent = styled(Content)<{
  fontSize: number
  iconSize: number
}>`
  margin-top: ${(props) => props.iconSize / 2 - (props.fontSize * 1.4) / 2}px;

  & > p {
    margin-bottom: 0 !important;
    padding-top: 0 !important;
  }
`

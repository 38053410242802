import {Font, fontStyles} from 'lib/FontSelect'
import React from 'react'
import styled from 'styled-components'
import Container from '@material-ui/core/Container'

export default function SectionContainer(props: {
  id?: string
  children: React.ReactElement | React.ReactElement[]
  background?: string
  color?: string | null
  font: Font | null
}) {
  const {id, children, color, font, background} = props

  return (
    <Box
      background={background}
      font={font}
      color={color ?? '#000000'}
      aria-label="section container"
      id={id}
    >
      <Container>{children}</Container>
    </Box>
  )
}

const Box = styled.div<{
  background?: string
  color: string | null
  font: Font | null
}>`
  padding: 30px 0px;
  background: ${(props) => props.background};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  ${(props) => fontStyles(props.font)}
  color: ${(props) => props.color};
  scroll-margin-top: 64px;
`

import {ObvioEvent} from 'Event'
import {Client} from 'lib/ui/api-client'
import {api} from 'lib/url'
import {useQuery} from 'react-query'

export type MailchimpField = {
  id: string
  name: string
}

export function useMailchimpFields(params: {
  client: Client
  event: ObvioEvent
}) {
  const {client, event} = params

  return useQuery({
    queryKey: ['events', event.id, 'integrations', 'mailchimp', 'fields'],
    queryFn: () =>
      client.get<Array<MailchimpField>>(
        api(`/events/${event.id}/integrations/mailchimp/fields`),
      ),
  })
}

import StepIndicator from 'Event/template/Panels/check-in/StepIndicator'
import styled from 'styled-components'
import React from 'react'
import Logo from 'Event/template/Panels/Logo'
import {Step} from 'Event/template/Panels/check-in/StepIndicator'
import {usePanelsTemplate} from 'Event/template/Panels'
import {rgba} from 'lib/color'
import Configurable, {Clickable} from 'organization/Event/Configurable'
import LeftPanelConfig from 'Event/template/Panels/check-in/LeftPanel/LeftPanelConfig'

export default function LeftPanel(props: {step: Step}) {
  const template = usePanelsTemplate()
  const {checkInLeftPanel} = template

  return (
    <FullHeightConfigurable>
      <LeftPanelConfig />
      <Box
        data-testid="left panel"
        backgroundColor={rgba(
          checkInLeftPanel.backgroundColor,
          checkInLeftPanel.backgroundOpacity / 100,
        )}
        textColor={checkInLeftPanel.textColor}
      >
        <BoxInner>
          <Menu />
          <StyledLogo />
          <StepIndicator step={props.step} />
        </BoxInner>
      </Box>
    </FullHeightConfigurable>
  )
}

function Menu() {
  return null
}

const Box = styled.div<{
  backgroundColor: string
  textColor: string | null
}>`
  display: flex;
  justify-content: center;
  margin: 24px;
  padding: 40px;
  background: ${(props) => props.backgroundColor};
  border-radius: 10px;
  overflow: auto;

  > * {
    color: ${(props) => props.textColor}!important;
  }
`

const BoxInner = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  flex: 1;
`

const StyledLogo = styled(Logo)`
  max-height: 40%;
`

export const FullHeightConfigurable = styled(Configurable)`
  > ${Clickable} {
    position: absolute;
    width: 100%;
    top: 0;
    height: 100%;
    display: flex;

    > div {
      flex: 1;
    }
  }
`

import React from 'react'
import styled from 'styled-components'
import Button from 'lib/ui/Button'
import {Label} from 'lib/ui/typography'
import {Rule} from 'Event/attendee-rules'

export interface AttendeeFilter {
  rules?: Rule[]
  isActive?: boolean
  isPasswordCreated?: boolean
  isWaiverSigned?: boolean
  isTechCheckCompleted?: boolean
  isCheckedIn?: boolean
}

export default function CheckedInFilter(props: {
  isEnabled: boolean
  status: boolean | null
  label: string
  'aria-label': string
  statusLabel: string
  onChange: (value: boolean | null) => void
}) {
  const {isEnabled, status, label, statusLabel, onChange} = props

  if (!isEnabled) {
    return null
  }

  return (
    <Container>
      <StyledLabel>{label}</StyledLabel>
      <ToggleBox>
        <FilterButtonGroup
          status={status}
          label={statusLabel}
          aria-label={props['aria-label']}
          onChange={onChange}
        />
      </ToggleBox>
    </Container>
  )
}

const FilterButtonGroup = (props: {
  status: boolean | null
  label: string
  'aria-label': string
  onChange: (value: boolean | null) => void
}) => {
  const {status, label, onChange} = props

  const change = (value: boolean) => {
    // Re-selecting the same value should unselect the filter.
    const val = status === value ? null : value
    onChange(val)
  }

  return (
    <>
      <StyledToggle
        aria-label={`${props['aria-label']} filter on`}
        color={status ? 'primary' : 'grey'}
        variant={status ? 'contained' : 'outlined'}
        onClick={() => change(true)}
      >
        {label}
      </StyledToggle>
      <StyledToggle
        aria-label={`${props['aria-label']} filter off`}
        color={status === false ? 'danger' : 'grey'}
        variant={status === false ? 'contained' : 'outlined'}
        onClick={() => change(false)}
      >
        Not {label}
      </StyledToggle>
    </>
  )
}

const Container = styled.div`
  margin-bottom: ${(props) => props.theme.spacing[5]};
`

const StyledLabel = styled(Label)`
  display: block;
  margin-bottom: ${(props) => props.theme.spacing[2]};
`

const ToggleBox = styled.div`
  display: flex;
  align-items: center;
  margin: ${(props) => `0 -${props.theme.spacing[2]}`};
`

const StyledToggle = styled(Button)`
  flex: 1;
  border-width: 1px;
  margin: ${(props) => `0 ${props.theme.spacing[2]}`};
`

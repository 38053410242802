import React from 'react'
import styled from 'styled-components'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import Dialog from 'lib/ui/Dialog'
import Button from 'lib/ui/Button'
import FilterList from 'organization/Event/AttendeeManagement/AttendeeFilterDialog/FilterList'
import {Rule} from 'Event/attendee-rules'
import {useTemplate} from 'Event/TemplateProvider'
import {Group, useAttendees} from 'organization/Event/AttendeesProvider'
import {
  createTagsRule,
  DOES_NOT_INCLUDE,
  INCLUDE,
  TAGS,
  TagsRule,
} from 'Event/attendee-rules/RuleConfig/RuleList/SingleRule/TagsRule'
import {
  createGroupRule,
  GROUP,
  GroupRule,
  IS,
  IS_BLANK,
  IS_FILLED,
  IS_NOT,
} from 'Event/attendee-rules/RuleConfig/RuleList/SingleRule/GroupRule'
import CheckedInFilter from 'organization/Event/AttendeeManagement/AttendeeFilterDialog/CheckedInFilter'
import {useSetBulkQueryParam} from 'lib/url'

export interface AttendeeFilter {
  rules?: Rule[]
  isActive?: boolean
  isPasswordCreated?: boolean
  isWaiverSigned?: boolean
  isTechCheckCompleted?: boolean
  isCheckedIn?: boolean
}

export default function AttendeeFilterDialog(props: {
  isVisible: boolean
  onClose: () => void
}) {
  const {onClose} = props
  const {filters, setFilters} = useAttendees()
  const setBulkQueryParam = useSetBulkQueryParam()

  const clearFilters = () => {
    const params = {
      is_active: '',
      password_created: '',
      waiver_signed: '',
      tech_check_completed: '',
      checked_in_status: '',
      tags_includes: '',
      tags_excludes: '',
      group_is: '',
      group_is_not: '',
      group_is_filled: '',
      group_is_blank: '',
      page: '1',
    }

    setBulkQueryParam(params)
    onClose()
  }

  return (
    <Dialog open={props.isVisible} onClose={onClose}>
      <DialogTitle>Add Filter</DialogTitle>
      <StyledDialogContent>
        <AttendeeFilterForm
          values={filters}
          onChange={setFilters}
          onClear={clearFilters}
        />
      </StyledDialogContent>
    </Dialog>
  )
}

type Values = {
  activeOnly: boolean | null
  passwordCreated: boolean | null
  waiverSigned: boolean | null
  techCheckCompleted: boolean | null
  checkedIn: boolean | null
  includedTags: string[]
  excludedTags: string[]
  correspondingGroups: Group[]
  oppositeGroups: Group[]
  filledGroups: Group[]
  blankGroups: Group[]
}

export function AttendeeFilterForm(props: {
  values: Values
  onChange: (values: Partial<Values>) => void
  onClear: () => void
}) {
  const template = useTemplate()

  const {
    values: {
      activeOnly,
      checkedIn,
      passwordCreated,
      waiverSigned,
      techCheckCompleted,
      includedTags,
      excludedTags,
      correspondingGroups,
      oppositeGroups,
      filledGroups,
      blankGroups,
    },
    onChange,
    onClear,
  } = props

  const includedTagRules = includedTags.map((tag) =>
    createTagsRule(INCLUDE, tag),
  )
  const excludedTagRules = excludedTags.map((tag) =>
    createTagsRule(DOES_NOT_INCLUDE, tag),
  )
  const correspondingGroupRules = correspondingGroups.map((group) =>
    createGroupRule(IS, group.key, group.value),
  )
  const oppositeGroupRules = oppositeGroups.map((group) =>
    createGroupRule(IS_NOT, group.key, group.value),
  )
  const filledGroupRules = filledGroups.map((group) =>
    createGroupRule(IS_FILLED, group.key, group.value),
  )
  const blankGroupRules = blankGroups.map((group) =>
    createGroupRule(IS_BLANK, group.key, group.value),
  )

  const rules = [
    ...includedTagRules,
    ...excludedTagRules,
    ...correspondingGroupRules,
    ...oppositeGroupRules,
    ...filledGroupRules,
    ...blankGroupRules,
  ]

  const handleChangedRules = (rules: Rule[]) => {
    const newIncludeTags = rules
      .filter((rule) => rule.source === TAGS && rule.type === INCLUDE)
      .map((rule) => (rule as TagsRule).target)

    const newExcludeTags = rules
      .filter((rule) => rule.source === TAGS && rule.type === DOES_NOT_INCLUDE)
      .map((rule) => (rule as TagsRule).target)

    const correspondingGroups = rules
      .filter((rule) => rule.source === GROUP && rule.type === IS)
      .map((rule) => ({
        key: (rule as GroupRule).key,
        value: (rule as GroupRule).target,
      }))

    const oppositeGroups = rules
      .filter((rule) => rule.source === GROUP && rule.type === IS_NOT)
      .map((rule) => ({
        key: (rule as GroupRule).key,
        value: (rule as GroupRule).target,
      }))

    const filledGroups = rules
      .filter((rule) => rule.source === GROUP && rule.type === IS_FILLED)
      .map((rule) => ({
        key: (rule as GroupRule).key,
        value: '',
      }))

    const blankGroups = rules
      .filter((rule) => rule.source === GROUP && rule.type === IS_BLANK)
      .map((rule) => ({
        key: (rule as GroupRule).key,
        value: '',
      }))

    onChange({
      includedTags: newIncludeTags,
      excludedTags: newExcludeTags,
      correspondingGroups,
      oppositeGroups,
      filledGroups,
      blankGroups,
    })
  }

  return (
    <>
      <StyledFilterList rules={rules} onChange={handleChangedRules} />
      <OptionBox>
        <CheckedInFilter
          isEnabled
          status={activeOnly}
          onChange={(val) => onChange({activeOnly: val})}
          label="Enabled status"
          aria-label="enabled"
          statusLabel="Enabled"
        />
        <CheckedInFilter
          isEnabled={template.login.requiresPassword}
          status={passwordCreated}
          onChange={(val) => onChange({passwordCreated: val})}
          label="Password"
          aria-label="password"
          statusLabel="Created"
        />
        <CheckedInFilter
          isEnabled={template.waiver.isEnabled}
          status={waiverSigned}
          onChange={(val) => onChange({waiverSigned: val})}
          label="Waiver"
          aria-label="waiver"
          statusLabel="Signed"
        />
        <CheckedInFilter
          isEnabled={template.techCheck.isEnabled}
          status={techCheckCompleted}
          onChange={(val) => onChange({techCheckCompleted: val})}
          label="Tech Check"
          aria-label="tech check"
          statusLabel="Completed"
        />
        <CheckedInFilter
          isEnabled
          status={checkedIn}
          onChange={(val) => onChange({checkedIn: val})}
          label="Check in status"
          aria-label="checked in"
          statusLabel="Checked In"
        />
      </OptionBox>
      <ClearFilterButton
        variant="outlined"
        color="danger"
        fullWidth
        aria-label="clear filter"
        onClick={onClear}
      >
        Clear Filter
      </ClearFilterButton>
    </>
  )
}

const StyledDialogContent = styled(DialogContent)`
  min-width: 480px;
  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    min-width: 320px;
  }
`

const StyledFilterList = styled(FilterList)`
  margin-bottom: ${(props) => props.theme.spacing[3]};
`

const OptionBox = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${(props) => `${props.theme.spacing[2]} 0`};
`

const ClearFilterButton = styled(Button)`
  margin-bottom: ${(props) => props.theme.spacing[3]};
`

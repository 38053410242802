import React from 'react'
import {onChangeCheckedHandler} from 'lib/dom'
import TextField from '@material-ui/core/TextField'
import Switch, {EnabledSwitch} from 'lib/ui/form/Switch'
import {MaterialUiPickersDate} from '@material-ui/pickers/typings/date'
import TextEditor, {TextEditorContainer} from 'lib/ui/form/TextEditor'
import Box from '@material-ui/core/Box'
import {BlogPost} from 'Event/Dashboard/components/BlogPosts'
import {useTemplate} from 'Event/TemplateProvider'
import LocalizedDateTimePicker from 'lib/LocalizedDateTimePicker'
import AttachmentConfig from 'Event/Dashboard/components/BlogPosts/BlogPostConfig/AttachmentConfig'
import {Controller, UseFormMethods} from 'react-hook-form'
import ConfirmDialog from 'lib/ui/ConfirmDialog'
import {
  EMAIL,
  EVENT_NAME,
  FIRST_NAME,
  LAST_NAME,
  TIME,
  TODAY,
} from 'lib/ui/form/TextEditor/variables'
import {defaultToolbar} from 'lib/ui/form/TextEditor/toolbar'

export default function Settings<T extends BlogPost>(props: {
  post: T
  id?: string
  control: UseFormMethods['control']
  register: UseFormMethods['register']
  watch: UseFormMethods['watch']
  update: (id: string, updated: Partial<T>) => void
  additionalInputs?: (params: {
    control: UseFormMethods['control']
  }) => JSX.Element
}) {
  const {post, update} = props
  const {blogPosts} = useTemplate()

  const {control, register, watch} = props

  const welcomePostId = Object.entries(blogPosts)
    .filter(([_, post]) => post.isWelcomePost)
    .map(([id]) => id)[0]

  // Called when we're setting this post as the welcome post, and
  // replacing another one
  const handleReplaceWelcomePost = (
    onChange: (...event: any[]) => void,
  ) => () => {
    // Unset other post
    update(welcomePostId, {
      isWelcomePost: false,
    } as Partial<T>)

    // Set this as welcome post
    onChange(true)
  }

  const attachment = (watch('attachment') || post.attachment) as NonNullable<
    BlogPost['attachment']
  >

  const handleDate = (set: (date: string | null) => void) => (
    date: MaterialUiPickersDate,
  ) => {
    const value = date ? date.toISOString() : null
    set(value)
  }

  const handleToggledIsWelcomePost = (
    confirm: (message: string) => void,
    onChange: (...event: any[]) => void,
  ) => (isWelcomePost: boolean) => {
    // If we're toggling off, there's nothing to check here, so we'll
    if (!isWelcomePost) {
      onChange(isWelcomePost)
      return
    }

    // No welcome post is set, so we're safe to set this one
    if (!welcomePostId) {
      onChange(isWelcomePost)
      return
    }

    // If this post is already the welcome post - ie. we've toggled
    // without saving, then we'll just go ahead and set it again.
    if (welcomePostId === props.id) {
      onChange(isWelcomePost)
      return
    }

    const otherPost = blogPosts[welcomePostId]
    const message = `There is already another blog post (${otherPost.title}) that is set as the welcome popup.  This will overwrite that setting, and make this blog post the welcome popup`

    confirm(message)
  }

  return (
    <>
      <Controller
        name="isVisible"
        defaultValue={post.isVisible}
        control={control}
        render={({value, onChange}) => (
          <EnabledSwitch
            checked={value}
            onChange={onChangeCheckedHandler(onChange)}
            arial-label="config visible switch"
          />
        )}
      />

      <Controller
        name="isWelcomePost"
        defaultValue={post.isWelcomePost ?? false}
        control={control}
        render={({value, onChange}) => (
          <ConfirmDialog onConfirm={handleReplaceWelcomePost(onChange)}>
            {(confirm) => (
              <Switch
                checked={value}
                onChange={onChangeCheckedHandler(
                  handleToggledIsWelcomePost(confirm, onChange),
                )}
                aria-label="toggle is welcome post"
                labelPlacement="end"
                label="Is Welcome Popup"
              />
            )}
          </ConfirmDialog>
        )}
      />
      <TextField
        name="title"
        defaultValue={post.title}
        inputProps={{
          'aria-label': 'blog post title',
          ref: register,
        }}
        label="Title"
        fullWidth
      />
      <Controller
        name="publishAt"
        defaultValue={post.publishAt}
        control={control}
        render={({value, onChange}) => (
          <LocalizedDateTimePicker
            clearable
            value={value}
            onChange={handleDate(onChange)}
            fullWidth
            label="Publish Date"
            inputProps={{
              'aria-label': 'post publish at',
            }}
          />
        )}
      />

      <Box mb={2}>
        <Controller
          name="hideDate"
          defaultValue={post.hideDate ?? false}
          control={control}
          render={({value, onChange}) => (
            <Switch
              checked={value}
              onChange={onChangeCheckedHandler(onChange)}
              arial-label="toggle hide date"
              labelPlacement="end"
              label="Hide Date"
            />
          )}
        />
      </Box>
      <Controller
        name="content"
        defaultValue={post.content}
        control={control}
        render={({value, onChange}) => (
          <TextEditorContainer>
            <TextEditor
              data={value}
              onChange={onChange}
              toolbar={[...defaultToolbar, 'obvioVariable']}
              variables={[
                FIRST_NAME,
                LAST_NAME,
                EMAIL,
                EVENT_NAME,
                TODAY,
                TIME,
              ]}
            />
          </TextEditorContainer>
        )}
      />
      {props.additionalInputs && props.additionalInputs({control})}
      <AttachmentConfig
        post={post}
        control={control}
        register={register}
        attachment={attachment}
        watch={watch}
      />
    </>
  )
}

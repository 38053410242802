import React from 'react'
import AreaSelect from 'organization/Event/Area/AreaSelect'
import {TargetInputProps} from 'Event/Dashboard/components/NavButton/NavButtonConfig/TargetInput'
import {Controller} from 'react-hook-form'

export default function AreaConfig(props: TargetInputProps) {
  const {control, watch, button} = props

  if (!watch('isAreaButton', button.isAreaButton)) {
    return null
  }

  return (
    <Controller
      name="areaId"
      defaultValue={button.areaId}
      control={control}
      render={({value, onChange}) => (
        <AreaSelect value={value} onPick={onChange} />
      )}
    />
  )
}

import {Meeting} from 'organization/Event/Room/meeting'
import styled from 'styled-components'
import React from 'react'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import {InfoSquareIcon} from 'lib/ui/Icon'
import {colors} from 'lib/ui/theme'
import {AbsoluteLink} from 'lib/ui/link/AbsoluteLink'
import TableCell from '@material-ui/core/TableCell'
import CopyIconButton from 'lib/ui/IconButton/CopyIconButton'
import If from 'lib/ui/layout/If'
import Table from '@material-ui/core/Table'

export default function MeetingDetails(props: {details: Meeting | null}) {
  const {details} = props

  if (!details) {
    return null
  }

  const {start_url: _, ...additionalDetails} = details
  const hasDetails = Object.keys(additionalDetails).length > 0
  if (!hasDetails) {
    return null
  }

  const copy = (string: string | undefined) => {
    if (!string) {
      return
    }

    navigator.clipboard.writeText(string)
  }

  return (
    <StyledTable aria-label="room info table">
      <TableBody>
        <TableRow>
          <TableCell colSpan={3}>
            <em>
              <InfoSquareIcon color={colors.primary} iconSize={16} /> The
              following zoom data is displayed to be used with ZoomOSC, ZoomISO,
              and other ZoomSDK based applications only. For information on
              ZoomOSC or ZoomISO, you can visit Liminal's website at{' '}
              <AbsoluteLink newTab={true} to={'http://www.liminalet.com/'}>
                www.liminalet.com
              </AbsoluteLink>
              .{' '}
              <strong>
                PLEASE DO NOT share this information with your attendees, as
                they will not be able to access the event using it.
              </strong>
            </em>
          </TableCell>
        </TableRow>
        <If hasValue={details.id}>
          {(id) => (
            <TableRow>
              <StyledTableCell>
                <b>Zoom Meeting ID:</b>
              </StyledTableCell>
              <TableCell>{details.id}</TableCell>
              <CopyCell>
                <CopyIconButton
                  aria-label="copy meeting id"
                  onClick={() => copy(id)}
                />
              </CopyCell>
            </TableRow>
          )}
        </If>

        <If hasValue={details.password}>
          {(password) => (
            <TableRow>
              <StyledTableCell>
                <b>Zoom Meeting Password:</b>
              </StyledTableCell>
              <TableCell>{password}</TableCell>
              <CopyCell>
                <CopyIconButton
                  aria-label="copy meeting password"
                  onClick={() => copy(password)}
                />
              </CopyCell>
            </TableRow>
          )}
        </If>

        <If hasValue={details.zak}>
          {(zak) => (
            <TableRow>
              <StyledTableCell>
                <b>Zoom Meeting ZAK:</b>
              </StyledTableCell>
              <StyledTableCellLongText>{zak}</StyledTableCellLongText>
              <CopyCell>
                <CopyIconButton
                  aria-label="copy meeting zak"
                  onClick={() => copy(zak)}
                />
              </CopyCell>
            </TableRow>
          )}
        </If>

        <If hasValue={details.host_email}>
          {(hostEmail) => (
            <TableRow>
              <StyledTableCell>
                <b>Host ID:</b>
              </StyledTableCell>
              <TableCell>{hostEmail}</TableCell>
              <CopyCell align="right">
                <CopyIconButton
                  aria-label="copy host email"
                  onClick={() => copy(hostEmail)}
                />
              </CopyCell>
            </TableRow>
          )}
        </If>
      </TableBody>
    </StyledTable>
  )
}

const StyledTable = styled(Table)`
  margin-top: ${(props) => props.theme.spacing[2]};

  .MuiTableCell-body {
    padding-left: 0;
  }
`

const StyledTableCell = styled(TableCell)`
  width: 185px;
`

const StyledTableCellLongText = styled(TableCell)`
  word-wrap: break-word;
  max-width: 0;
`

const CopyCell = styled(TableCell)`
  text-align: right;
  width: 50px;
`

import React from 'react'
import styled from 'styled-components'
import Grid, {GridSize} from '@material-ui/core/Grid'
import {
  STATISTIC_CHECK_IN_STEP_CHECKED_IN,
  STATISTIC_CHECK_IN_STEP_PASSWORD_CREATED,
  STATISTIC_CHECK_IN_STEP_WAIVER_SIGNED,
  useStatistics,
} from 'Event/Statistics'
import CheckedIn from 'Event/Statistics/Summary/CheckedInCard'
import TotalAttendees from 'Event/Statistics/Summary/TotalAttendeesCard'
import CheckInStep from 'Event/Statistics/Summary/CheckInStepCard'
import {useTemplate} from 'Event/TemplateProvider'

export default function Summary() {
  const {metrics} = useStatistics()

  const {attendees} = metrics
  const {total} = attendees

  const {waiver, login} = useTemplate()
  const requiresPassword = login.requiresPassword
  const waiverEnabled = waiver.isEnabled

  const gridSize = calculateGridSize(requiresPassword, waiverEnabled)

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <CheckedIn total={total} checked_in={attendees.num_checked_in} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TotalAttendees
              total={total}
              checked_in={attendees.num_checked_in}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <GridItem visible={requiresPassword} gridSize={gridSize}>
            <CheckInStep
              title="Password Created"
              completed={attendees.num_password_created}
              total={total}
              statisticType={STATISTIC_CHECK_IN_STEP_PASSWORD_CREATED}
            />
          </GridItem>
          <GridItem visible={waiverEnabled} gridSize={gridSize}>
            <CheckInStep
              title="Waiver Signed"
              completed={attendees.num_waiver_signed}
              total={total}
              statisticType={STATISTIC_CHECK_IN_STEP_WAIVER_SIGNED}
            />
          </GridItem>
          <GridItem visible gridSize={gridSize}>
            <CheckInStep
              title="Checked In"
              completed={attendees.num_checked_in}
              total={total}
              statisticType={STATISTIC_CHECK_IN_STEP_CHECKED_IN}
            />
          </GridItem>
        </Grid>
      </Grid>
    </Grid>
  )
}

function GridItem(props: {
  visible: boolean
  gridSize: GridSize
  children: React.ReactElement
}) {
  const {visible, gridSize, children} = props

  if (!visible) {
    return null
  }

  return (
    <Grid item xs={12} sm={gridSize}>
      {children}
    </Grid>
  )
}

function calculateGridSize(requiresPassword: boolean, waiverEnabled: boolean) {
  if (requiresPassword && waiverEnabled) {
    return 4
  }

  if (requiresPassword || waiverEnabled) {
    return 6
  }

  return 12
}

export const MainCard = styled.div`
  padding: ${(props) => `${props.theme.spacing[5]} ${props.theme.spacing[5]}`};
  background: ${(props) => props.theme.colors.sidebar.background};
  position: relative;
  width: 100%;
  padding-top: 100%;
  border-radius: ${(props) => props.theme.spacing[1]};
`

export const MainCardContent = styled.div`
  position: absolute;
  height: calc(100% - 40px);
  width: calc(100% - 40px);
  top: ${(props) => props.theme.spacing[5]};
  bottom: ${(props) => props.theme.spacing[5]};
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing[5]};
  justify-content: space-between;
`

export const CardHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${(props) => props.theme.spacing[2]};
  margin-bottom: ${(props) => props.theme.spacing[3]};
`

import Page from 'organization/Event/Page'
import React, {useState} from 'react'
import {NameAppendage} from 'organization/Event/NameAppendageConfig/NameAppendageProvider'
import NameAppendageAddDialog from 'organization/Event/NameAppendageConfig/AddDialog'
import NameAppendageUpdateDialog from 'organization/Event/NameAppendageConfig/UpdateDialog'
import NameAppendageListTable from 'organization/Event/NameAppendageConfig/ListTable'
import NameAppendageAddButton from 'organization/Event/NameAppendageConfig/AddDialog/button'
import {AttendeesBreadcrumbs} from 'organization/Event/Page/PageBreadcrumbs'

export default function NameAppendageConfig() {
  const [adding, setAdding] = useState<boolean>(false)
  const [editing, setEditing] = useState<NameAppendage | null>(null)
  const add = () => setAdding(true)
  const closeAddDialog = () => setAdding(false)
  const setEditDialog = (nameAppendage: NameAppendage) =>
    setEditing(nameAppendage)
  const closeEditDialog = () => setEditing(null)

  return (
    <AttendeesBreadcrumbs page="Labels">
      <Page>
        <NameAppendageAddButton openAdd={add} />

        <NameAppendageAddDialog isOpen={adding} onClose={closeAddDialog} />
        <NameAppendageUpdateDialog
          nameAppendage={editing}
          onClose={closeEditDialog}
        />

        <NameAppendageListTable setEditing={setEditDialog} />
      </Page>
    </AttendeesBreadcrumbs>
  )
}

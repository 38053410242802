import React from 'react'
import {Title as TitleText} from 'lib/ui/typography'
import {DesktopOnly, MobileOnly} from 'lib/ui/screen'
import Show from 'lib/Show'

export default function Title(props: {text: string; textCollapsed?: string}) {
  const {text, textCollapsed} = props

  if (!textCollapsed) {
    return <TitleText>{text}</TitleText>
  }

  return (
    <>
      <DesktopTitle>{text}</DesktopTitle>
      <Show when={Boolean(textCollapsed)}>
        <MobileTitle>{textCollapsed}</MobileTitle>
      </Show>
    </>
  )
}

const DesktopTitle = DesktopOnly(TitleText)

const MobileTitle = MobileOnly(TitleText)

import {ObvioEvent} from 'Event'
import {api} from 'lib/url'
import {useOrganization} from 'organization/OrganizationProvider'
import {useQuery} from 'react-query'

export function useMarketplaceSampleEvents() {
  const {client} = useOrganization()

  return useQuery({
    queryKey: ['marketplace_sample_events'],
    queryFn: () =>
      client.get<Array<ObvioEvent>>(api('/marketplace_sample_events')),
  })
}

import React from 'react'
import {NumberField} from 'lib/ui/TextField'
import {Label} from 'lib/ui/typography'

export default function Styling(props: {
  emojiWidth: number | null
  setEmojiWidth: (value: number | null) => void
}) {
  const {emojiWidth, setEmojiWidth} = props

  return (
    <>
      <Label>Emoji Size</Label>
      <NumberField
        fullWidth
        type="number"
        onChange={setEmojiWidth}
        value={emojiWidth || 20}
        inputProps={{
          min: 20,
          step: 5,
        }}
      />
    </>
  )
}

import React, {useState} from 'react'
import styled from 'styled-components'
import {api} from 'lib/url'
import {RedirectLink} from 'Event/Redirects/CustomRedirect'
import {useValidatedForm} from 'lib/form'
import {useOrganization} from 'organization/OrganizationProvider'
import {FormInputs} from 'organization/Event/Redirects/AddDialog/RedirectAddForm'
import Button from 'lib/ui/Button'

export interface UpdateRedirectData {
  description: string
  redirect_slug: string
  destination: string
}

export default function RedirectUpdateForm(props: {
  onClose: () => void
  redirect: RedirectLink | null
  onUpdate: (redirect: RedirectLink) => void
}) {
  const {redirect} = props
  const [submitting, setSubmitting] = useState(false)
  const {
    register,
    handleSubmit,
    errors,
    setResponseError,
    clearErrors,
  } = useValidatedForm()
  const {client} = useOrganization()

  if (!redirect) {
    return null
  }

  const submit = (data: UpdateRedirectData) => {
    clearErrors()

    setSubmitting(true)

    const url = api(`/redirects/${redirect.id}`)
    client
      .put<RedirectLink>(url, data)
      .then(props.onUpdate)
      .then(() => {
        setSubmitting(false)
        props.onClose()
      })
      .catch((e) => {
        setResponseError(e)
        setSubmitting(false)
      })
  }

  return (
    <>
      <form onSubmit={handleSubmit(submit)}>
        <FormInputs
          register={register}
          submitting={submitting}
          current={redirect}
          errors={errors}
          description={redirect.description}
          destination={redirect.destination}
          redirectSlug={redirect.redirect_slug}
        />
        <div>
          <SaveButton
            variant="contained"
            color="primary"
            fullWidth
            disabled={submitting}
            type="submit"
            aria-label="save updated redirect"
          >
            Update Redirect
          </SaveButton>
        </div>
      </form>
    </>
  )
}

const SaveButton = styled(Button)`
  margin-bottom: ${(props) => props.theme.spacing[4]}!important;
`

import {useTownhallTemplate} from 'Event/template/Townhall'
import AddSectionButton from 'Event/template/Townhall/Dashboard/Main/AddSectionButton'
import DashboardSection from 'Event/template/Townhall/Dashboard/Main/DashboardSection'
import {createPositions, orderedIdsByPosition} from 'lib/list'
import React from 'react'
import {Box as Page, Content, ColorOverlay} from 'Event/template/Townhall/Page'
import {useLoadFont} from 'lib/FontSelect'
import {rgba} from 'lib/color'
import LanguageSelector from 'Event/LanguageSelector'
import {User} from 'auth/user'
import Footer from 'Event/template/Townhall/Dashboard/Footer'
import {
  DragDropContext,
  Draggable,
  DropResult,
  Droppable,
} from 'react-beautiful-dnd'
import styled from 'styled-components'
import {useSaveTemplate} from 'Event/TemplateUpdateProvider'
import {useEditMode} from 'Event/EditModeProvider'
import {DragHandle, DraggableOverlay} from 'lib/ui/drag-and-drop'
import Container from '@material-ui/core/Container'

export default function Main(props: {user: User}) {
  const {
    pageBackground: page,
    textColor,
    linkUnderline,
    linkColor,
    font,
  } = useTownhallTemplate()

  const pageBackground = page.image ? `url(${page.image})` : 'transparent'

  const backgroundRGBColor = page
    ? rgba(page.color || '#FFFFFF', page.opacity || 0)
    : '#FFFFFF'

  useLoadFont(font)

  return (
    <>
      <Page
        background={pageBackground}
        color={textColor}
        linkColor={linkColor}
        linkUnderline={linkUnderline}
        font={font}
      >
        <ColorOverlay color={backgroundRGBColor}>
          <Content>
            <StyledContainer>
              <Sections />
              <Container>
                <AddSectionButton />
              </Container>
            </StyledContainer>
          </Content>
        </ColorOverlay>
        <Footer {...props} />
      </Page>
      <LanguageSelector />
    </>
  )
}

function Sections() {
  const {dashboardSections} = useTownhallTemplate()
  const isEditMode = useEditMode()

  if (isEditMode) {
    return <Editable />
  }

  const ids = orderedIdsByPosition(dashboardSections)

  return (
    <>
      {ids.map((id, index) => {
        const props = dashboardSections[id]

        return (
          <DashboardSection {...props} key={id} index={index} sectionId={id} />
        )
      })}
    </>
  )
}

function Editable() {
  const {dashboardSections} = useTownhallTemplate()
  const ids = orderedIdsByPosition(dashboardSections)
  const handleDrag = useHandleDrag()

  return (
    <DragDropContext onDragEnd={handleDrag}>
      <Droppable droppableId="drag-and-drop-sections">
        {(provided) => (
          <Box ref={provided.innerRef} {...provided.droppableProps}>
            <>
              {ids.map((id, index) => {
                const props = dashboardSections[id]

                return (
                  <Draggable draggableId={id} index={index} key={id}>
                    {(provided) => (
                      <div ref={provided.innerRef} {...provided.draggableProps}>
                        <DraggableOverlay draggableId={id}>
                          <DragHandle handleProps={provided.dragHandleProps} />
                          <DashboardSection
                            {...props}
                            index={index}
                            sectionId={id}
                          />
                        </DraggableOverlay>
                      </div>
                    )}
                  </Draggable>
                )
              })}
              {provided.placeholder}
            </>
          </Box>
        )}
      </Droppable>
    </DragDropContext>
  )
}

function useHandleDrag() {
  const update = useSaveTemplate()
  const {dashboardSections} = useTownhallTemplate()

  return (result: DropResult) => {
    const {destination, source} = result

    if (!destination) {
      return
    }

    const ids = orderedIdsByPosition(dashboardSections)
    const [removed] = ids.splice(source.index, 1)
    ids.splice(destination.index, 0, removed)
    update({
      dashboardSections: createPositions(ids),
    })
  }
}

const Box = styled.div`
  width: 100%;
`

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

import {useLeftyTemplate} from 'Event/template/Lefty'
import React from 'react'
import {Controller, UseFormMethods} from 'react-hook-form'
import ImageAssetUploader from 'lib/asset/ImageAssetUploader'
import Title from 'lib/ui/ConfigPanel/Title'

export default function Settings(props: {
  control: UseFormMethods['control']
  watch: UseFormMethods['watch']
}) {
  const {control} = props
  const template = useLeftyTemplate()

  return (
    <>
      <Title>Dashboard Logo</Title>
      <Controller
        name="logo"
        control={control}
        defaultValue={template.logo}
        render={({onChange, value}) => (
          <ImageAssetUploader
            onChange={onChange}
            value={value}
            uploadLabel="Upload"
            canResize
            removeButtonProps={{
              'aria-label': 'remove logo',
            }}
            uploadInputProps={{
              'aria-label': 'logo upload',
            }}
            disableAutoRemove
          />
        )}
      />
      <Title>Header Background</Title>
      <Controller
        name="header.backgroundImage"
        control={control}
        defaultValue={template.header.backgroundImage}
        render={({onChange, value}) => (
          <ImageAssetUploader
            onChange={onChange}
            value={value}
            uploadLabel="Upload"
            canResize
            uploadInputProps={{
              'aria-label': 'header background upload',
            }}
            disableAutoRemove
          />
        )}
      />
      <Title>Page Background</Title>
      <Controller
        name="pageBackground.image"
        control={control}
        defaultValue={template.pageBackground.image}
        render={({onChange, value}) => (
          <ImageAssetUploader
            onChange={onChange}
            value={value}
            uploadLabel="Upload"
            canResize
            uploadInputProps={{
              'aria-label': 'page background upload',
            }}
            disableAutoRemove
          />
        )}
      />
    </>
  )
}

import React from 'react'
import styled from 'styled-components'
import {useValidatedForm} from 'lib/form'
import {api} from 'lib/url'
import {ObvioEvent} from 'Event'
import {useOrganization} from 'organization/OrganizationProvider'
import {Client} from 'lib/ui/api-client'
import Dialog from 'lib/ui/Dialog'
import Button from 'lib/ui/Button'
import Typography from '@material-ui/core/Typography'
import Grid from 'lib/ui/Grid'
import {DialogContent, DialogTitle} from '@material-ui/core'
import TextField from 'lib/ui/TextField'
import {useSnackbar} from 'lib/ui/SnackbarProvider'
export interface DeleteEventData {
  password: string
}

export default function DeleteEventDialog(props: {
  onClose: () => void
  visible: boolean
  event: Pick<ObvioEvent, 'id' | 'slug' | 'name'>
}) {
  const [loading, setLoading] = React.useState(false)
  const {enqueueSnackbar} = useSnackbar()

  const {client} = useOrganization()
  const {
    register,
    errors,
    handleSubmit,
    setResponseError,
    responseError,
  } = useValidatedForm()
  const {visible, onClose} = props

  const onSubmit = (data: DeleteEventData) => {
    setLoading(true)
    deleteEvent(client, props.event.id, data)
      .then(() => {
        props.onClose()
        enqueueSnackbar('Successfully scheduled event to be deleted.', {
          variant: 'success',
        })
      })
      .catch((error) => {
        setResponseError(error)
        setLoading(false)
      })
  }

  return (
    <Dialog open={visible} onClose={onClose} disableEnforceFocus>
      <DialogTitle>Delete Event Confirmation</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={3}>
            <Grid item md={12} xs={12}>
              <Typography>{props.event.name}</Typography>
            </Grid>
            <Grid item md={12} xs={12}>
              <Typography color="textSecondary">
                This Event will permanently be deleted. There is no way of
                restoring an Event once deleted. Please confirm deletion by
                entering your accounts password below.
              </Typography>
            </Grid>
            <Grid item md={12} xs={12}>
              <TextField
                label="Please enter your password to continue"
                name="password"
                type="password"
                required
                variant="outlined"
                fullWidth
                inputProps={{
                  ref: register({
                    required: 'Password is required',
                  }),
                  'aria-label': 'password',
                }}
                error={!!errors.password}
                helperText={errors.password}
                disabled={loading}
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <Error>{responseError && responseError.message}</Error>
            </Grid>
            <Grid item md={12} xs={12}>
              <ActionButton
                variant="contained"
                color="primary"
                type="submit"
                disabled={loading}
                aria-label="ok"
              >
                OK
              </ActionButton>
              <ActionButton
                variant="outlined"
                color="primary"
                onClick={onClose}
                disabled={loading}
              >
                Cancel
              </ActionButton>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  )
}

function deleteEvent(client: Client, eventId: number, data: DeleteEventData) {
  const url = api(`/events/${eventId}/delete`)
  return client.post<ObvioEvent>(url, data)
}

function Error(props: {children: string | null}) {
  if (!props.children) {
    return null
  }

  return <Typography color="error">{props.children}</Typography>
}

const ActionButton = styled(Button)`
  margin: ${(props) => props.theme.spacing[2]};
`

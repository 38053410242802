import React from 'react'
import {useTemplate} from 'Event/TemplateProvider'
import {components} from 'Event/template'

export default function GlobalStylesConfig() {
  const {name} = useTemplate()

  const Component = components[name].GlobalStylesConfig.index

  return <Component />
}

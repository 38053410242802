import React from 'react'
import {DefaultPaymentMethodProvider} from 'obvio/Billing/DefaultPaymentMethodProvider'
import Billing from 'obvio/Billing'
import {Route, Switch} from 'react-router-dom'
import {obvioRoutes} from 'obvio/Routes'
import ChangePlanPage from 'obvio/Billing/ChangePlanPage/ChangePlanPage'
import CancelPlanPage from 'obvio/Billing/CancelPlanPage/CancelPlanPage'
import ResumePlanPage from 'obvio/Billing/ResumePlanPage/ResumePlanPage'
import PlanProvider from 'obvio/Billing/PlanProvider'

export default function BillingRoutes() {
  return (
    <DefaultPaymentMethodProvider>
      <PlanProvider>
        <Switch>
          <Route path={obvioRoutes.billing.change_plan}>
            <ChangePlanPage />
          </Route>
          <Route path={obvioRoutes.billing.cancel_plan}>
            <CancelPlanPage />
          </Route>
          <Route path={obvioRoutes.billing.resume_plan}>
            <ResumePlanPage />
          </Route>
          <Route path={obvioRoutes.billing.root}>
            <Billing />
          </Route>
        </Switch>
      </PlanProvider>
    </DefaultPaymentMethodProvider>
  )
}

import React from 'react'
import {Controller, UseFormMethods} from 'react-hook-form'
import TextField from '@material-ui/core/TextField'
import {Autocomplete} from '@material-ui/lab'
import {NavButtonWithSize} from 'Event/Dashboard/components/NavButton'
import {CardsNavButtonProps} from 'Event/template/Cards/Dashboard/CardsNavButton'
import {handleAutocomplete} from 'lib/dom'
import {Label} from 'lib/ui/typography'
import {ENTERPRISE} from 'obvio/Billing/plans'
import IfOwnerHasPlan from 'organization/auth/IfOwnerPlan'
import {useWebhooks} from 'organization/Event/WebhooksProvider'

export default function WebhookFields(props: {
  button: NavButtonWithSize | CardsNavButtonProps
  control: UseFormMethods['control']
  register: UseFormMethods['register']
  watch: UseFormMethods['watch']
}) {
  return (
    <IfOwnerHasPlan plan={ENTERPRISE}>
      <Content {...props} />
    </IfOwnerHasPlan>
  )
}

function Content(props: {
  button: NavButtonWithSize | CardsNavButtonProps
  control: UseFormMethods['control']
  register: UseFormMethods['register']
  watch: UseFormMethods['watch']
}) {
  const {control, button, register, watch} = props
  const {customWebhooks} = useWebhooks()

  // If there are no custom webhooks added to the Event, we don't want to show
  // the input fields.
  if (!customWebhooks.length) {
    return null
  }

  const webhookOptions: number[] = customWebhooks.map((webhook) => webhook.id)

  const getOptionLabel = (option: number): string => {
    const found = customWebhooks
      .filter((webhook) => webhook.id === option)
      .shift()

    return found?.custom_label || ''
  }

  const hasSelectedWebhook = !!watch('webhook.id') || !!button.webhook?.id

  return (
    <>
      <Label>Custom Webhook Label</Label>
      <Controller
        name="webhook.id"
        control={control}
        defaultValue={button.webhook?.id || null}
        render={({value, onChange}) => (
          <Autocomplete
            options={webhookOptions}
            value={value}
            onChange={handleAutocomplete(onChange)}
            aria-label="pick webhook"
            getOptionLabel={getOptionLabel}
            getOptionSelected={(option) => option === value?.id}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                inputProps={{
                  ...params.inputProps,
                  'aria-label': 'webhook search',
                }}
                InputProps={{
                  ...params.InputProps,
                }}
              />
            )}
          />
        )}
      />

      <WebhookDataInput
        button={button}
        hasSelectedWebhook={hasSelectedWebhook}
        register={register}
      />
    </>
  )
}

function WebhookDataInput(props: {
  button: NavButtonWithSize | CardsNavButtonProps
  hasSelectedWebhook: boolean
  register: UseFormMethods['register']
}) {
  const {button, hasSelectedWebhook, register} = props

  if (!hasSelectedWebhook) {
    return null
  }

  return (
    <TextField
      label="Addtional Webhook Data"
      name="webhook.data"
      defaultValue={button.webhook?.data || ''}
      inputProps={{
        'aria-label': 'webhook data input',
        ref: register,
      }}
      fullWidth
      helperText="Optional. Data will include the Custom Webhook Label in addition to this optional field. JSON strings accepted."
    />
  )
}

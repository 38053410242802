import React from 'react'
import {Cards, useCardsUpdate} from 'Event/template/Cards'
import ComponentConfig, {
  ComponentConfigProps,
  SaveButton,
  Footer,
} from 'organization/Event/DashboardConfig/ComponentConfigPanel'
import {useForm} from 'react-hook-form'
import Settings from 'Event/template/Cards/Dashboard/Hero/HeroConfig/Settings'
import Styling from 'Event/template/Cards/Dashboard/Hero/HeroConfig/Styling'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import StylingPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/StylingPanel'
import {usePruneAssets} from 'lib/asset'
import {
  useAutoUpdate,
  useTemplateEditor,
} from 'organization/Event/TemplateEditor'

export type Hero = {
  text?: string | null
  fontSize?: number
  textColor?: string | null
  image?: string | null
  imageSize?: number
}

export function HeroConfig(props: ComponentConfigProps) {
  const {showing, onClose} = props

  const update = useCardsUpdate()

  const {handleSubmit, control, watch, formState} = useForm()
  const pruneAssets = usePruneAssets()
  const {saved} = useTemplateEditor<Cards>()

  const save = (data: Hero) => {
    pruneAssets({image: data.image}, saved.hero)
    update({
      hero: data,
    })
    onClose()
  }

  useAutoUpdate({values: {hero: watch()}, when: showing})

  return (
    <ComponentConfig
      title="Hero"
      showing={showing}
      onClose={onClose}
      onSubmit={handleSubmit(save)}
      hasChanges={formState.isDirty}
    >
      <SettingsPanel>
        <Settings control={control} />
      </SettingsPanel>
      <StylingPanel>
        <Styling control={control} />
      </StylingPanel>
      <Footer>
        <SaveButton type="submit" />
      </Footer>
    </ComponentConfig>
  )
}

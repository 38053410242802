import React from 'react'
import {useAttendeeVariables} from 'Event'
import {PageTitle, PageDescription} from 'Event/template/Cards/Page'
import {useCardsTemplate} from 'Event/template/Cards'
import Content from 'lib/ui/form/TextEditor/Content'
import CardsPage from 'Event/template/Cards/Page'
import {User} from 'auth/user'
import Configurable from 'organization/Event/Configurable'
import HeaderConfig from 'Event/Backgrounds/HeaderConfig'
import BackgroundLayoutConfig from 'Event/Backgrounds/BackgroundLayoutConfig'
import UploadBackgroundButton from 'Event/Backgrounds/UploadBackgroundButton'
import BackgroundList from 'Event/Backgrounds/BackgroundList'

export default function Backgrounds(props: {user: User}) {
  const {
    zoomBackgrounds: settings,
    linkColor,
    linkUnderline,
  } = useCardsTemplate()
  const v = useAttendeeVariables()

  return (
    <CardsPage user={props.user}>
      <Configurable>
        <HeaderConfig />
        <PageTitle aria-label="backgrounds title">
          {v(settings.title)}
        </PageTitle>
      </Configurable>
      <PageDescription
        aria-label="backgrounds description"
        linkColor={linkColor}
        linkUnderline={linkUnderline}
      >
        <Content>{v(settings.description)}</Content>
      </PageDescription>
      <UploadBackgroundButton />
      <Configurable aria-label="background list config">
        <BackgroundLayoutConfig />
        <BackgroundList />
      </Configurable>
    </CardsPage>
  )
}

import {useEvent} from 'Event/EventProvider'
import {useAsync} from 'lib/async'
import {api} from 'lib/url'
import React from 'react'
import {useCallback, useState} from 'react'
import {Entry} from 'Event/Leaderboard'
import {useOrganization} from 'organization/OrganizationProvider'

const DEFAULT_LIMIT = 200

export interface LeaderboardContextProps {
  entries: Entry[]
  loading: boolean
  searchTerm: string
  search: (term: string) => void
  limit: number
  setLimit: (v: number) => void
}

export const LeaderboardContext = React.createContext<
  LeaderboardContextProps | undefined
>(undefined)

export default function LeaderboardProvider(props: {
  children: React.ReactElement
}) {
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [limit, setLimit] = useState(DEFAULT_LIMIT)
  const {entries, loading} = useFetchEntries({limit})

  const matching = entries.filter(match(searchTerm))

  return (
    <LeaderboardContext.Provider
      value={{
        entries: matching,
        loading,
        searchTerm,
        search: setSearchTerm,
        limit,
        setLimit,
      }}
    >
      {props.children}
    </LeaderboardContext.Provider>
  )
}

export function useLeaderboardEntries() {
  const context = React.useContext(LeaderboardContext)
  if (context === undefined) {
    throw new Error(
      'useLeaderboardEntries must be used within LeaderboardProvider',
    )
  }
  return context
}

type FetchEntriesParams = {
  limit?: number
}

function useFetchEntries(params: FetchEntriesParams) {
  const request = useFetchEntriesRequest(params)
  const {data, loading} = useAsync(request)

  return {
    entries: data || [],
    loading,
  }
}

function useFetchEntriesRequest({limit = DEFAULT_LIMIT}: FetchEntriesParams) {
  const {client} = useOrganization()
  const {event} = useEvent()

  return useCallback(() => {
    let url = api(`/events/${event.id}/leaderboard`)
    if (limit > 0) {
      url += `?limit=${limit}`
    }
    return client.get<Entry[]>(url)
  }, [limit, client, event.id])
}

function match(term: string) {
  return ({attendee}: Entry) => {
    const matchRegex = new RegExp(term, 'i')
    const searchFields = [
      attendee.email,
      attendee.first_name,
      attendee.last_name,
    ]

    return searchFields.filter(matchRegex.test.bind(matchRegex)).length > 0
  }
}

import React from 'react'
import MuiAccordion from '@material-ui/core/Accordion'
import styled from 'styled-components'

export type AccordionProps = {
  children: JSX.Element | JSX.Element[]
  disabled?: boolean
  expanded?: boolean
  isSquare?: boolean
  panelId?: string
  id: string
  onChange?: (panel?: string) => {}
  className?: string
}

export default function Accordion(props: AccordionProps) {
  const handleChange = (panelId?: string) => (
    _event: React.ChangeEvent<{}>,
    _newExpanded: boolean,
  ) => {
    if (props.onChange) {
      props.onChange(panelId)
    }
  }

  return (
    <StyledMuiAccordion
      className={props.className}
      square={props.isSquare}
      expanded={props.expanded}
      disabled={props.disabled}
      onChange={handleChange(props.panelId)}
    >
      {props.children}
    </StyledMuiAccordion>
  )
}

const StyledMuiAccordion = styled(MuiAccordion)`
  border: 1px solid #dfdfdf;
  border-bottom: none;
  box-shadow: none;

  &:last-child {
    border: 1px solid #dfdfdf;
  }

  &:before {
    display: none;
  }

  &.Mui-expanded {
    margin: auto;
  }
`

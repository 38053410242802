import ButtonConfig from 'organization/Marketplace/PurchasePageConfig/BlockConfig/ButtonConfig'
import ImageConfig from 'organization/Marketplace/PurchasePageConfig/BlockConfig/ImageConfig'
import TitleConfig from 'organization/Marketplace/PurchasePageConfig/BlockConfig/TItleConfig'
import TextConfig from 'organization/Marketplace/PurchasePageConfig/BlockConfig/TextConfig'
import TicketSelectorConfig from 'organization/Marketplace/PurchasePageConfig/BlockConfig/TicketSelectorConfig'
import React from 'react'
import VideoConfig from 'organization/Marketplace/PurchasePageConfig/BlockConfig/VideoConfig'
import PurchaseFormConfig from 'organization/Marketplace/PurchasePageConfig/BlockConfig/PurchaseFormConfig'
import SeparatorConfig from 'organization/Marketplace/PurchasePageConfig/BlockConfig/SeparatorConfig'
import CountdownTimerConfig from 'organization/Marketplace/PurchasePageConfig/BlockConfig/CountdownTimerConfig'
import BulletedListConfig from 'organization/Marketplace/PurchasePageConfig/BlockConfig/BulletedListConfig'
import NumberedListConfig from 'organization/Marketplace/PurchasePageConfig/BlockConfig/NumberedListConfig'
import IconConfig from 'organization/Marketplace/PurchasePageConfig/BlockConfig/IconConfig'
import FaqListConfig from 'organization/Marketplace/PurchasePageConfig/BlockConfig/FaqListConfig'
import SpacerConfig from 'organization/Marketplace/PurchasePageConfig/BlockConfig/SpacerConfig'
import {PurchasePageBlock} from 'Event/Marketplace/purchase-page'

interface BlockConfigProps {
  block: PurchasePageBlock
  id: string
  sectionId: string
}

export default function BlockConfig(props: BlockConfigProps) {
  const {block, id, sectionId} = props

  const ConfigComponent = ComponentConfigs[block.type] ?? null

  if (!ConfigComponent) {
    return null
  }

  return <ConfigComponent {...(block as any)} id={id} sectionId={sectionId} />
}

export type ConfigDefinitions = {
  [T in PurchasePageBlock as T['type']]: React.FC<
    T & {id: string; sectionId: string}
  >
}

export const ComponentConfigs: ConfigDefinitions = {
  Title: TitleConfig,
  Text: TextConfig,
  Image: ImageConfig,
  TicketSelector: TicketSelectorConfig,
  Button: ButtonConfig,
  Video: VideoConfig,
  PurchaseForm: PurchaseFormConfig,
  Separator: SeparatorConfig,
  BulletedList: BulletedListConfig,
  CountdownTimer: CountdownTimerConfig,
  NumberedList: NumberedListConfig,
  Icon: IconConfig,
  FaqList: FaqListConfig,
  Spacer: SpacerConfig,
}

import Typography from '@material-ui/core/Typography'
import styled from 'styled-components'
import Card from 'Event/template/NiftyFifty/Dashboard/Sponsors/SponsorList/Card'
import React from 'react'
import {useNiftyFiftyTemplate} from 'Event/template/NiftyFifty'
import {DragDropContext, Droppable} from 'react-beautiful-dnd'
import {orderedIdsByPosition} from 'lib/list'
import {useEditMode} from 'Event/EditModeProvider'
import {useHandleDragSponsors} from 'organization/Event/SponsorsConfig'
import {VisibleSponsor} from 'Event/Sponsors'

export default function SponsorList() {
  const {
    sponsors: {items},
    textColor,
  } = useNiftyFiftyTemplate()
  const isEditMode = useEditMode()

  const ids = orderedIdsByPosition(items)

  const isEmpty = ids.length === 0
  if (isEmpty) {
    return <Typography align="center">No sponsor have been added</Typography>
  }

  const sponsorsList = ids.map((id, index) => (
    <VisibleSponsor key={id} sponsor={items[id]}>
      <Card
        key={id}
        index={index}
        id={id}
        sponsor={items[id]}
        isLast={index === ids.length - 1}
      />
    </VisibleSponsor>
  ))

  if (!isEditMode) {
    return <Box color={textColor}>{sponsorsList}</Box>
  }

  return <DraggableList color={textColor}>{sponsorsList}</DraggableList>
}

function DraggableList(props: {
  children: React.ReactElement[]
  className?: string
  color: string
}) {
  const handleDrag = useHandleDragSponsors()

  return (
    <DragDropContext onDragEnd={handleDrag}>
      <Droppable droppableId="drag-and-drop-sponsor">
        {(provided) => (
          <Box
            ref={provided.innerRef}
            {...provided.droppableProps}
            className={props.className}
            color={props.color}
          >
            <>
              {props.children}
              {provided.placeholder}
            </>
          </Box>
        )}
      </Droppable>
    </DragDropContext>
  )
}

const Box = styled.div<{
  color: string
}>`
  width: 100%;
  color: ${(props) => props.color};
`

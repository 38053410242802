import {Template} from 'Event/template'
import {CARDS, Cards, DEFAULTS} from 'Event/template/Cards'
import {SIMPLE_BLOG} from 'Event/template/SimpleBlog'
import {PANELS} from 'Event/template/Panels'
import {LEFTY} from 'Event/template/Lefty'
import {createHashMap, HashMap} from 'lib/list'
import {NavButtonWithSize} from 'Event/Dashboard/components/NavButton'
import {CardsNavButtonProps} from 'Event/template/Cards/Dashboard/CardsNavButton'
import {
  TICKET_RIBBON_LIST,
  TicketRibbonListProps,
} from 'Event/template/Cards/Dashboard/Sidebar/SidebarItem/TicketRibbonList'
import {GREY_RIBBON} from 'Event/template/Cards/Dashboard/Sidebar/SidebarItem/TicketRibbonList/TicketRibbon'
import {
  RESOURCE_LIST,
  ResourceListProps,
} from 'Event/template/Cards/Dashboard/Sidebar/SidebarItem/ResourceList'
import {
  EMOJI_LIST,
  EmojiListProps,
} from 'Event/template/Cards/Dashboard/Sidebar/SidebarItem/EmojiList'
import {SidebarItemProps} from 'Event/template/Cards/Dashboard/Sidebar/SidebarItem'
import {baseProps} from 'Event/template/converter'

export function convert(template: Template): Cards {
  return {
    name: DEFAULTS.name,
    version: DEFAULTS.version,
    ...baseProps(template),
    imageWaterfall: imageWaterfall(template),
    hero: hero(template),
    login: login(template),
    title: title(template),
    footer: footer(template),
    header: header(template),
    checkIn: checkIn(template),
    contentPanel: contentPanel(template),
    mainNav: mainNav(template),
    sidebar: sidebar(template),
    pageLinks: pageLinks(template),
    textColor: textColor(template),
    background: background(template),
    postStyles: postStyles(template),
    accentColor: accentColor(template),
    offlinePage: offlinePage(template),
    sidebarItems: sidebarItems(template),
    homeMenuTitle: homeMenuTitle(template),
    welcomeMessage: welcomeMessage(template),
    backgroundImage1: backgroundImage1(template),
    backgroundImage2: backgroundImage2(template),
    backgroundImage3: backgroundImage3(template),
    backgroundImage4: backgroundImage4(template),
    backgroundImage5: backgroundImage5(template),
    backgroundImage1Position: backgroundImage1Position(template),
    backgroundImage2Position: backgroundImage2Position(template),
    backgroundImage3Position: backgroundImage3Position(template),
    backgroundImage4Position: backgroundImage4Position(template),
    backgroundImage5Position: backgroundImage5Position(template),
    backgroundPosition: backgroundPosition(template),
    dashboardBackground: dashboardBackground(template),
    zoomBackgrounds: zoomBackgrounds(template),
  }
}

function imageWaterfall(template: Template): Cards['imageWaterfall'] {
  if ('menuTitle' in template.imageWaterfall) {
    return template.imageWaterfall
  }

  return {
    ...DEFAULTS.imageWaterfall,
    ...template.imageWaterfall,
  }
}

function hero(template: Template): Cards['hero'] {
  if (template.name === SIMPLE_BLOG || template.name === LEFTY) {
    return {
      ...DEFAULTS.hero,
      image: template.heroImage,
      imageSize: template.heroImageSize,
    }
  }

  if ('hero' in template) {
    return template.hero
  }

  return DEFAULTS.hero
}

function login(template: Template): Cards['login'] {
  if (template.name === CARDS) {
    return template.login
  }

  return {
    ...DEFAULTS.login,
    ...template.login,
    passwordReset: {
      ...DEFAULTS.login.passwordReset,
      ...template.login.passwordReset,
    },
  }
}

function title(template: Template): Cards['title'] {
  if ('title' in template) {
    return template.title
  }

  return DEFAULTS.title
}

function footer(template: Template): Cards['footer'] {
  if ('footer' in template) {
    return template.footer
  }

  return DEFAULTS.footer
}

function header(template: Template): Cards['header'] {
  if ('header' in template) {
    return {
      ...DEFAULTS.header,
      ...template.header,
    }
  }

  return DEFAULTS.header
}

function contentPanel(template: Template): Cards['contentPanel'] {
  if ('contentPanel' in template) {
    return {
      ...DEFAULTS.contentPanel,
      ...template.contentPanel,
    }
  }

  return DEFAULTS.contentPanel
}

function checkIn(template: Template): Cards['checkIn'] {
  if ('progressBar' in template) {
    return {
      ...DEFAULTS.checkIn,
      step1Label: template.progressBar.step1Text,
      step2Label: template.progressBar.step2Text,
      step3Label: template.progressBar.step3Text,
    }
  }

  if (template.name === PANELS) {
    return {
      ...DEFAULTS.checkIn,
      step1Label: template.step1Label,
      step1Icon: template.step1Icon ?? DEFAULTS.checkIn.step1Icon,
      step2Label: template.step2Label,
      step2Icon: template.step2Icon ?? DEFAULTS.checkIn.step2Icon,
      step3Label: template.step3Label,
      step3Icon: template.step3Icon ?? DEFAULTS.checkIn.step3Icon,
    }
  }

  return template.checkIn
}

function mainNav(template: Template): Cards['mainNav'] {
  if (template.name === CARDS) {
    return template.mainNav
  }

  return {
    ...DEFAULTS.mainNav,
    buttons: convertToCardsNavButton(template.mainNav.buttons),
  }
}

function sidebar(template: Template): Cards['sidebar'] {
  if ('sidebar' in template) {
    return template.sidebar
  }

  return DEFAULTS.sidebar
}

function pageLinks(template: Template): Cards['pageLinks'] {
  if ('rightPanel' in template) {
    return {
      ...DEFAULTS.pageLinks,
      dividerColor: template.rightPanel.tabUnderlineColor,
    }
  }

  if ('pageLinks' in template) {
    return template.pageLinks
  }

  return DEFAULTS.pageLinks
}

function textColor(template: Template): Cards['textColor'] {
  return template.textColor
}

function background(template: Template): Cards['background'] {
  if ('background' in template) {
    return {
      ...DEFAULTS.background,
      ...template.background,
    }
  }

  return DEFAULTS.background
}

function postStyles(template: Template): Cards['postStyles'] {
  return {
    ...DEFAULTS.postStyles,
    ...template.postStyles,
  }
}

function accentColor(template: Template): Cards['accentColor'] {
  if ('header' in template) {
    return template.header.backgroundColor
  }

  return template.accentColor
}

function offlinePage(template: Template): Cards['offlinePage'] {
  return {
    ...DEFAULTS.offlinePage,
    ...template.offlinePage,
  }
}

function sidebarItems(template: Template): Cards['sidebarItems'] {
  if (template.name === CARDS) {
    return template.sidebarItems
  }

  if (template.name === SIMPLE_BLOG) {
    return createHashMap(
      Object.values(template.sidebarItems).map((i) => {
        // Cards Text items have a title
        if (i.type === 'Text') {
          return {...i, title: ''}
        }

        return i
      }),
    )
  }

  const sidebarItems: SidebarItemProps[] = []

  if ('resourceList' in template) {
    const resources: ResourceListProps = {
      ...template.resourceList,
      type: RESOURCE_LIST,
      isEnabled: true,
      description: '',
      offsetBottom: 0,
      offsetTop: 0,
      resources: createHashMap(
        template.resourceList.resources.map((item) => {
          return {
            ...item,
            icon: 'fas fa-link',
          }
        }),
      ),
    }

    sidebarItems.push(resources)
  }

  if ('emojiList' in template) {
    const emojis: EmojiListProps = {
      ...template.emojiList,
      type: EMOJI_LIST,
    }
    sidebarItems.push(emojis)
  }

  if ('ticketRibbons' in template) {
    const ribbons: TicketRibbonListProps = {
      type: TICKET_RIBBON_LIST,
      ribbons: createHashMap(
        template.ticketRibbons.map((item) => ({
          ...item,
          name: GREY_RIBBON,
          text: item.hoverText,
          color: item.backgroundColor,
        })),
      ),
    }

    sidebarItems.push(ribbons)
  }

  return createHashMap(sidebarItems)
}

function homeMenuTitle(template: Template): Cards['homeMenuTitle'] {
  if ('homeMenuTitle' in template) {
    return template.homeMenuTitle ?? DEFAULTS.homeMenuTitle
  }

  return DEFAULTS.homeMenuTitle
}

function welcomeMessage(template: Template): Cards['welcomeMessage'] {
  if ('welcomeText' in template) {
    return {
      ...DEFAULTS.welcomeMessage,
      text: template.welcomeText,
      textColor: template.textColor,
    }
  }

  if ('welcomeMessage' in template) {
    return template.welcomeMessage
  }

  return DEFAULTS.welcomeMessage
}

function backgroundImage1(template: Template): Cards['backgroundImage1'] {
  if ('backgroundImage1' in template) {
    return template.backgroundImage1
  }

  return DEFAULTS.backgroundImage1
}

function backgroundImage2(template: Template): Cards['backgroundImage2'] {
  if ('backgroundImage2' in template) {
    return template.backgroundImage2
  }

  return DEFAULTS.backgroundImage2
}

function backgroundImage3(template: Template): Cards['backgroundImage3'] {
  if ('backgroundImage3' in template) {
    return template.backgroundImage3
  }

  return DEFAULTS.backgroundImage3
}

function backgroundImage4(template: Template): Cards['backgroundImage4'] {
  if ('backgroundImage4' in template) {
    return template.backgroundImage4
  }

  return DEFAULTS.backgroundImage4
}

function backgroundImage5(template: Template): Cards['backgroundImage5'] {
  if ('backgroundImage5' in template) {
    return template.backgroundImage5
  }

  return DEFAULTS.backgroundImage5
}

function backgroundImage1Position(
  template: Template,
): Cards['backgroundImage1Position'] {
  if ('backgroundImage1Position' in template) {
    return template.backgroundImage1Position
  }

  return DEFAULTS.backgroundImage1Position
}

function backgroundImage2Position(
  template: Template,
): Cards['backgroundImage2Position'] {
  if ('backgroundImage2Position' in template) {
    return template.backgroundImage2Position
  }

  return DEFAULTS.backgroundImage2Position
}

function backgroundImage3Position(
  template: Template,
): Cards['backgroundImage3Position'] {
  if ('backgroundImage3Position' in template) {
    return template.backgroundImage3Position
  }

  return DEFAULTS.backgroundImage3Position
}

function backgroundImage4Position(
  template: Template,
): Cards['backgroundImage4Position'] {
  if ('backgroundImage4Position' in template) {
    return template.backgroundImage4Position
  }

  return DEFAULTS.backgroundImage4Position
}

function backgroundImage5Position(
  template: Template,
): Cards['backgroundImage5Position'] {
  if ('backgroundImage5Position' in template) {
    return template.backgroundImage5Position
  }

  return DEFAULTS.backgroundImage5Position
}

function backgroundPosition(template: Template): Cards['backgroundPosition'] {
  if ('pageBackgroundPosition' in template) {
    return template.pageBackgroundPosition ?? DEFAULTS.backgroundPosition
  }

  return template.backgroundPosition ?? DEFAULTS.backgroundPosition
}

function dashboardBackground(template: Template): Cards['dashboardBackground'] {
  if ('dashboardBackground' in template) {
    return {
      ...DEFAULTS.dashboardBackground,
      ...template.dashboardBackground,
    }
  }

  if ('pageBackground' in template) {
    return {
      ...DEFAULTS.dashboardBackground,
      ...template.pageBackground,
    }
  }

  return DEFAULTS.dashboardBackground
}

function zoomBackgrounds(template: Template): Cards['zoomBackgrounds'] {
  return template.zoomBackgrounds
}

function convertToCardsNavButton(
  buttons: HashMap<NavButtonWithSize>,
): HashMap<CardsNavButtonProps> {
  return Object.entries(buttons).reduce((acc, [id, button]) => {
    acc[id] = {...button, row: 1}
    return acc
  }, {} as HashMap<CardsNavButtonProps>)
}

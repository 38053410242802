import React from 'react'
import {UseFormMethods} from 'react-hook-form'
import {NavButtonWithSize} from 'Event/Dashboard/components/NavButton'
import BackgroundSection from 'Event/Dashboard/components/NavButton/NavButtonConfig/BaseConfig/Styling/BackgroundSection'
import TypographySection from 'Event/Dashboard/components/NavButton/NavButtonConfig/BaseConfig/Styling/TypographySection'
import SizeSection from 'Event/Dashboard/components/NavButton/NavButtonConfig/BaseConfig/Styling/SizeSection'
import BorderSection from 'Event/Dashboard/components/NavButton/NavButtonConfig/BaseConfig/Styling/BorderSection'

export default function Styling(props: {
  control: UseFormMethods['control']
  watch: UseFormMethods['watch']
  button: NavButtonWithSize
}) {
  const {control, button, watch} = props

  return (
    <>
      <TypographySection
        button={button}
        control={control}
        storageKey="techcheck.customButton.config.styling.typography"
      />
      <BackgroundSection
        watch={watch}
        control={control}
        button={button}
        storageKey="techcheck.customButton.config.styling.background"
      />
      <SizeSection
        button={button}
        control={control}
        storageKey="techcheck.customButton.config.styling.size"
      />
      <BorderSection
        button={button}
        control={control}
        storageKey="techcheck.customButton.config.styling.border"
      />
    </>
  )
}

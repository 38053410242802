import {VideoBlock} from 'Event/Marketplace/Block/Video'

export const createVideoBlock = (): VideoBlock => ({
  type: 'Video',
  visibility: 'all',
  url: '',
  html: null,
  background: {
    image: '',
    color: '',
    opacity: 0,
  },
  borderWidth: 0,
  borderRadius: 0,
  borderColor: '#FFFFFF',
  padding: {
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
  border: {
    width: 0,
    color: '#FFFFFF',
    radius: 0,
  },
  alignment: {
    vertical: 'center',
    horizontal: 'center',
  },
  aspectRatio: {
    value: '16:9',
    customWidthRatio: 16,
    customHeightRatio: 9,
  },
})

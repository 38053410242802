import {useEvent} from 'Event/EventProvider'
import ConfirmDialog from 'lib/ui/ConfirmDialog'
import {AbsoluteLink} from 'lib/ui/link/AbsoluteLink'
import {useRoom} from 'organization/Event/Room/RoomProvider'
import React from 'react'
import Button from 'lib/ui/Button'
import {useRoomRoutes} from 'organization/Event/Room/RoomRoutes'

const TEST_MODE_HELP_ARTICLE_URL = 'https://help.obv.io/test-mode/'

export default function StartButton(props: {
  disabled?: boolean
  children?: string
}) {
  const {children = 'Start', disabled} = props

  const {room} = useRoom()
  const {event} = useEvent()

  const {join} = useRoomRoutes()

  const canStart = room.is_online && !disabled

  return (
    <ConfirmDialog
      onConfirm={(event?: React.MouseEvent) => {
        const hostJoinUrl = event?.shiftKey ? `${join}?start_url=true` : join
        window.open(hostJoinUrl, '_blank')?.focus()
      }}
      title="Your Event Has Not Started"
      description={
        <div>
          Because your event has not yet started, this Room is being launched in
          TEST MODE. It will be automatically ended in 60 minutes. To learn more
          about TEST MODE, click{' '}
          <AbsoluteLink to={TEST_MODE_HELP_ARTICLE_URL} newTab>
            here
          </AbsoluteLink>
          .
        </div>
      }
      confirmLabel="Start"
      variant="info"
      // If an event is live we are NOT in test mode, so we'll
      // skip the confirmation
      skip={event.is_live}
    >
      {(confirm) => (
        <Button
          variant="contained"
          color="primary"
          aria-label="start room"
          disabled={!canStart}
          onClick={confirm}
        >
          {children}
        </Button>
      )}
    </ConfirmDialog>
  )
}

import React from 'react'
import NavButton, {
  NavButtonWithSize,
} from 'Event/Dashboard/components/NavButton'
import Container from 'Event/Marketplace/Block/Container'
import Grid from '@material-ui/core/Grid'
import {HashMap, orderedIdsByPosition} from 'lib/list'
import {BlockComponentProps} from 'Event/Marketplace/Block'
import {Layout} from 'react-grid-layout'
import {BlockBase} from '../base'
import {
  PurchasePageBlock,
  PurchasePageSection,
  PurchasePageTemplate,
} from '../../purchase-page'

export interface ButtonBlock
  extends BlockBase,
    Pick<
      NavButtonWithSize,
      | 'isVisible'
      | 'newTab'
      | 'icon'
      | 'text'
      | 'image'
      | 'height'
      | 'width'
      | 'size'
      | 'newLine'
      | 'link'
      | 'font'
      | 'fontSize'
      | 'textColor'
      | 'hoverTextColor'
      | 'backgroundColor'
      | 'hoverBackgroundColor'
      | 'disableHover'
      | 'borderColor'
      | 'hoverBorderColor'
      | 'borderWidth'
      | 'borderRadius'
      | 'typographySecond'
      | 'iconAlign'
    > {
  type: 'Button'
  destination: 'link' | 'ticket_selector'
  textSecond: string | null
}

export default function Button(props: ButtonBlock & BlockComponentProps) {
  const {
    padding: _padding,
    link,
    destination,
    font,
    fontSize,
    textColor,
    template,
    ...otherProps
  } = props

  const {typography} = template

  const targetBlockid = getTargetBlockId(template)

  const to = destination === 'ticket_selector' ? `#${targetBlockid}` : link

  return (
    <Container {...props}>
      <Grid item xs={12} md={props.size}>
        <NavButton
          isAreaButton={false}
          isFormButton={false}
          isImageUpload={false}
          areaId={null}
          formId={null}
          actionId={null}
          infusionsoftTag={null}
          mailchimpTag={null}
          zapierTag={null}
          highLevelTag={null}
          hubspotTag={null}
          activeCampaignTag={null}
          convertKitTag={null}
          webhook={null}
          ontraportTag={null}
          padding={0}
          link={to}
          font={font || typography.font}
          fontSize={fontSize || typography.fontSize}
          textColor={textColor || typography.textColor}
          {...otherProps}
        />
      </Grid>
    </Container>
  )
}

/**
 * Finds the block id that the button should scroll to when set to 'ticket_selector'.
 *
 * @returns string | null
 */
function getTargetBlockId(template: PurchasePageTemplate) {
  const {sections} = template

  // If there are no ticket selectors on the page, we'll just scroll
  // to the first purchase form
  return (
    getBlockOfType({
      sections,
      type: 'TicketSelector',
      position: 'last',
    }) ??
    getBlockOfType({
      sections,
      type: 'PurchaseForm',
      position: 'first',
    })
  )
}

function getBlockOfType(params: {
  sections: HashMap<PurchasePageSection>
  type: PurchasePageBlock['type']
  position: 'first' | 'last'
}) {
  const {sections, type: blockType, position} = params
  let currentBlockId: null | string = null
  let currentBlockLayout: Layout | null = null

  const sectionIds = orderedIdsByPosition(sections)

  for (const sectionId of sectionIds) {
    const blocks = sections[sectionId].blocks
    const layouts = sections[sectionId].layouts

    if (currentBlockId) {
      continue
    }

    for (const [id, block] of Object.entries(blocks)) {
      if (block.type !== blockType) {
        continue
      }

      const layout = layouts.desktop.find(({i}) => i === id)
      if (!layout) {
        continue
      }

      if (!currentBlockLayout) {
        currentBlockId = id
        currentBlockLayout = layout
        continue
      }

      const isAtPosition =
        position === 'first'
          ? layout.y < currentBlockLayout.y
          : layout.y > currentBlockLayout.y

      if (isAtPosition) {
        currentBlockId = id
        currentBlockLayout = layout
      }
    }
  }

  return currentBlockId
}

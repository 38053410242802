import styled from 'styled-components'
import React from 'react'
import Logo from 'Event/auth/Login/Logo'
import Background from 'Event/auth/Login/Background'

export default function LeftPanel() {
  return (
    <LeftPanelContainer>
      <Background>
        <Logo />
      </Background>
    </LeftPanelContainer>
  )
}

export const LeftPanelContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  overflow: auto;
  position: relative;
  border-radius: 0px;
  width: 100%;
  height: 100%;
`

import React from 'react'
import {useEditMode} from 'Event/EditModeProvider'
import PurchaseForm, {
  PurchaseFormBlock,
} from 'Event/Marketplace/Block/PurchaseForm'
import ConfigurablePurchaseFormTextField from 'organization/Marketplace/PurchasePageConfig/ConfigurablePurchaseForm/ConfigurablePurchaseFormTextField'
import ConfigurablePurchaseFormButton from 'organization/Marketplace/PurchasePageConfig/ConfigurablePurchaseForm/ConfigurablePurchaseFormButton'
import {BlockComponentProps} from 'Event/Marketplace/Block'

interface PurchaseFormProps extends PurchaseFormBlock, BlockComponentProps {}

export default function ConfigurablePurchaseForm(props: PurchaseFormProps) {
  const isEditMode = useEditMode()

  const paddingTop = isEditMode
    ? Math.max(props.padding.top, 24)
    : props.padding.top

  return (
    <PurchaseForm
      {...props}
      padding={{
        ...props.padding,
        top: paddingTop,
      }}
      TextFieldComponent={ConfigurablePurchaseFormTextField}
      ButtonComponent={ConfigurablePurchaseFormButton}
    />
  )
}

import React from 'react'
import Slider from 'lib/ui/Slider'
import {Controller} from 'react-hook-form'
import {DEFAULT_BUTTON_HEIGHT} from 'Event/Dashboard/components/NavButton'
import Checkbox from 'lib/ui/Checkbox'
import CollapsibleSection from 'lib/ui/ConfigPanel/CollapsibleSection'
import styled from 'styled-components'
import {Resource} from 'Event/template/Townhall/Dashboard/Main/ResourceSection/ResourceItem'
import {useConfig} from 'organization/Event/Configurable'

type SizeSectionProps = {
  resource: Resource
  storageKey: string
  disableNewLine?: boolean
  sectionId: string
  id: string
}

export default function SizeSection(props: SizeSectionProps) {
  const {resource, id, sectionId} = props

  const {
    form: {control},
  } = useConfig()

  return (
    <CollapsibleSection label="Size" storageKey={props.storageKey}>
      <Content>
        <Controller
          name={`dashboardSections.${sectionId}.items.${id}.height`}
          defaultValue={resource.height || DEFAULT_BUTTON_HEIGHT}
          control={control}
          render={({value, onChange}) => (
            <Slider
              min={1}
              max={400}
              onChange={onChange}
              valueLabelDisplay="off"
              value={value}
              label="Height"
            />
          )}
        />
        <Controller
          name={`dashboardSections.${sectionId}.items.${id}.size`}
          defaultValue={resource.size || 12}
          control={control}
          render={({value, onChange}) => (
            <Slider
              min={1}
              max={12}
              onChange={onChange}
              valueLabelDisplay="off"
              value={value}
              unit="grid"
              label="Width"
            />
          )}
        />

        <Controller
          name={`dashboardSections.${sectionId}.items.${id}.newLine`}
          defaultValue={resource.newLine || false}
          control={control}
          render={({value, onChange}) => (
            <Checkbox
              label="New Line"
              checked={value}
              aria-label="toggle new line"
              onChange={onChange}
            />
          )}
        />
      </Content>
    </CollapsibleSection>
  )
}

const Content = styled.div`
  margin-bottom: ${(props) => props.theme.spacing[4]};
`

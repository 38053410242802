import styled from 'styled-components'
import React, {FormEvent, useState} from 'react'
import {
  Stripe,
  StripeCardNumberElement,
  StripeElements,
} from '@stripe/stripe-js'
import {useStripe, useElements} from '@stripe/react-stripe-js'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import TextField from '@material-ui/core/TextField'
import {onChangeStringHandler} from 'lib/dom'
import ErrorAlert from 'lib/ui/alerts/ErrorAlert'
import {useToggle} from 'lib/toggle'
import StripeElementsProvider from 'obvio/Billing/StripeElementsProvider'
import CardFields from 'lib/stripe/CreditCardForm/CardFields'
import {colors} from 'lib/ui/theme'

export type CreditCardFormBaseProps = {
  submitLabel: string
  className?: string
}

export type CreditCardFormResult = {
  cardElement: StripeCardNumberElement
  name: string
  stripe: Stripe
  elements?: StripeElements
}

export type CreditCardFormProps = CreditCardFormBaseProps & {
  onSubmit: (result: CreditCardFormResult) => Promise<void>
  stripeAccount?: string
}

export default function CreditCardForm(props: CreditCardFormProps) {
  return (
    <StripeElementsProvider>
      <Content {...props} />
    </StripeElementsProvider>
  )
}

function Content(props: CreditCardFormProps) {
  const {onSubmit} = props
  const {flag: processing, toggle: toggleProcessing} = useToggle()
  const stripe = useStripe()
  const elements = useElements()
  const [name, setName] = useState('')
  const [error, setError] = useState<string | null>(null)
  const [
    cardElement,
    setCardElement,
  ] = useState<StripeCardNumberElement | null>(null)

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault()

    if (processing) {
      return
    }

    toggleProcessing()

    if (!stripe || !elements || !cardElement) {
      return
    }

    onSubmit({cardElement, stripe, name, elements}).catch((error) => {
      setError(error.message)
      toggleProcessing()
    })
  }

  const canSubmit = Boolean(stripe) && !processing

  return (
    <Box className={props.className}>
      <CardForm onSubmit={handleSubmit}>
        <ErrorAlert>{error}</ErrorAlert>
        <TextField
          variant="outlined"
          placeholder="Name"
          inputProps={{
            'aria-label': 'card name',
          }}
          fullWidth
          required
          value={name}
          onChange={onChangeStringHandler(setName)}
          disabled={!canSubmit}
        />
        <CardInput>
          <CardFields disabled={!canSubmit} onLoad={setCardElement} />
        </CardInput>
        <Box mt={2}>
          <Button
            type="submit"
            fullWidth
            disabled={!canSubmit}
            color="primary"
            variant="contained"
            aria-label="save card"
          >
            {props.submitLabel}
          </Button>
        </Box>
      </CardForm>
    </Box>
  )
}

const CardForm = styled.form`
  max-width: 400px;
`

const CardInput = styled.div`
  .StripeElement {
    width: 100%;
    padding: 18.5px 14px;
    border: ${(props) =>
      props.theme.name === 'dark'
        ? '1px solid rgba(255, 255, 255, 0.23)'
        : '1px solid rgba(0, 0, 0, 0.23)'};

    border-radius: 4px;
    margin-bottom: 16px;

    &.StripeElement--focus {
      border-color: ${(props) =>
        props.theme.name === 'dark' ? '#FFFFFF' : colors.primary};
      border-width: 2px;
      padding: 17.5px 13px;
    }

    &.StripeElement--invalid {
      border-color: ${colors.error};
      border-width: 2px;
      padding: 17.5px 13px;
    }
  }
`

import {useEvent} from 'Event/EventProvider'
import {useStaticDate} from 'lib/date-time/StaticDateProvider'
import {useEffect} from 'react'

/**
 * Shows all localized time in the Event's selected timezone if one
 * exists.
 *
 * @param props
 * @returns
 */
export default function EventTimezone(props: {children: JSX.Element}) {
  const {setTimezone} = useStaticDate()
  const {event} = useEvent()

  useEffect(() => {
    setTimezone(event.timezone)
  }, [event, setTimezone])

  return props.children
}

import {useWaiver} from 'Event/Step2/Waivers/Waiver'
import styled from 'styled-components'
import {useTemplate} from 'Event/TemplateProvider'
import React from 'react'
import Grid from '@material-ui/core/Grid'
import Configurable from 'organization/Event/Configurable'
import SubmitButtonConfig from 'Event/Step2/Waivers/Waiver/SubmitButton/SubmitButtonConfig'
import {Button} from 'Event/Dashboard/components/NavButton'

export default function SubmitButton() {
  const {canSubmit} = useWaiver()

  const {
    waiver: {button},
  } = useTemplate()

  return (
    <Box>
      <Grid item xs={button.size}>
        <Configurable>
          <SubmitButtonConfig />
          <Button {...button} type="submit" disabled={!canSubmit} />
        </Configurable>
      </Grid>
    </Box>
  )
}

export const Box = styled.div`
  display: flex;
  justify-content: center;
`

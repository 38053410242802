import React from 'react'
import {FAQ} from 'Event/FAQs'
import Body from 'Event/template/SimpleBlog/FAQs/FaqList/Card/Body'
import {Draggable} from 'react-beautiful-dnd'
import {useEditMode} from 'Event/EditModeProvider'

type FaqProps = {
  index: number
  id: string
  faq: FAQ
  className?: string
}

export default function Card(props: FaqProps) {
  const {id, index, faq} = props

  const isEditMode = useEditMode()

  if (!isEditMode) {
    return (
      <div aria-label="faq" className={props.className}>
        <Body id={id} faq={faq} />
      </div>
    )
  }

  return (
    <Draggable draggableId={id} index={index}>
      {(provided) => (
        <div
          ref={provided.innerRef}
          aria-label="faq"
          className={props.className}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <Body faq={faq} id={id} answerVisible />
        </div>
      )}
    </Draggable>
  )
}

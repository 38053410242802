import React from 'react'
import ComponentConfig, {
  ComponentConfigProps,
  SaveButton,
  Footer,
} from 'organization/Event/DashboardConfig/ComponentConfigPanel'
import {useForm} from 'react-hook-form'
import {useNiftyFiftyUpdate} from 'Event/template/NiftyFifty'
import Settings from 'Event/template/NiftyFifty/Dashboard/Resources/ResourceList/ResourceListConfig/Settings'
import Styling from 'Event/template/NiftyFifty/Dashboard/Resources/ResourceList/ResourceListConfig/Styling'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import StylingPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/StylingPanel'

export default function ResourceListConfig(props: ComponentConfigProps) {
  const {showing: isVisible, onClose} = props
  const {control, register, handleSubmit, formState} = useForm()
  const updateTemplate = useNiftyFiftyUpdate()

  const submit = (data: any) => {
    const {rowBackgroundPrimary, rowBackgroundSecondary, ...resource} = data
    updateTemplate({
      rowBackgroundPrimary,
      rowBackgroundSecondary,
      resourceList: {
        isVisible: resource.isVisible,
        title: resource.title,
        menuTitle: resource.menuTitle,
        color: resource.color,
        linkColor: resource.linkColor,
      },
    })

    onClose()
  }

  return (
    <ComponentConfig
      showing={isVisible}
      onClose={onClose}
      title="Resources"
      onSubmit={handleSubmit(submit)}
      hasChanges={formState.isDirty}
    >
      <SettingsPanel>
        <Settings control={control} register={register} />
      </SettingsPanel>
      <StylingPanel>
        <Styling control={control} />
      </StylingPanel>
      <Footer>
        <SaveButton type="submit" />
      </Footer>
    </ComponentConfig>
  )
}

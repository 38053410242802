import {useGuestVariables} from 'Event'
import {LoginProps} from 'Event/auth/Login'
import PasswordFieldConfig from 'Event/auth/Login/PasswordField/PasswordFieldConfig'
import TextField from 'Event/auth/Login/TextField'
import {useTemplate} from 'Event/TemplateProvider'
import Configurable from 'organization/Event/Configurable'
import React from 'react'

export default function PasswordField(props: LoginProps) {
  const v = useGuestVariables()
  const template = useTemplate()

  const passwordLabel = v(template.login.passwordLabel)

  return (
    <Configurable>
      <PasswordFieldConfig />
      <TextField
        label={passwordLabel}
        type="password"
        fullWidth
        variant="outlined"
        name="password"
        disabled={props.submitting}
        inputProps={{
          ref: props.register({
            required: `${passwordLabel} is required`,
          }),
          'aria-label': 'password',
        }}
        error={!!props.errors.password}
        helperText={props.errors.password && props.errors.password.message}
      />
    </Configurable>
  )
}

import {
  useNiftyFiftyTemplate,
  useNiftyFiftyUpdate,
  MIN_LOGO_SIZE_PERCENT,
  MAX_LOGO_SIZE_PERCENT,
} from 'Event/template/NiftyFifty'
import ColorPicker from 'lib/ui/ColorPicker'
import Divider from 'lib/ui/ConfigPanel/Divider'
import Title from 'lib/ui/ConfigPanel/Title'
import Slider from 'lib/ui/Slider'
import {useConfig} from 'organization/Event/Configurable'
import Config from 'organization/Event/Configurable/Config'
import StylingPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/StylingPanel'
import React from 'react'
import {Controller} from 'react-hook-form'
import ImageAssetUploader from 'lib/asset/ImageAssetUploader'
import {onChangeCheckedHandler} from 'lib/dom'
import Switch from 'lib/ui/form/Switch'
import {Grid, Box} from '@material-ui/core'

export default function LeftPanelConfig() {
  const save = useNiftyFiftyUpdate()
  const {
    form: {control},
  } = useConfig()

  const template = useNiftyFiftyTemplate()

  return (
    <Config title="Check In Left Panel" onSave={save}>
      <StylingPanel>
        <Grid container justify="center" spacing={2}>
          <Grid item xs={12} md={6}>
            <Box mb={2}>
              <Controller
                name="checkInLogo.image"
                defaultValue={template.checkInLogo.image}
                control={control}
                render={({value, onChange}) => (
                  <ImageAssetUploader
                    label="Logo"
                    onChange={onChange}
                    value={value}
                    canResize
                    width={1200}
                    height={1920}
                    uploadInputProps={{
                      'aria-label': 'step logo image',
                    }}
                  />
                )}
              />
            </Box>
            <Box mb={2}>
              <Controller
                name="checkInLogo.enabled"
                defaultValue={template.checkInLogo.enabled}
                control={control}
                render={({value, onChange}) => (
                  <Switch
                    checked={value}
                    onChange={onChangeCheckedHandler(onChange)}
                    arial-label="set logo mode"
                    labelPlacement="end"
                    label={value ? 'Logo Enabled' : 'Logo Disabled'}
                  />
                )}
              />
            </Box>
            <Box display="flex" flexDirection="column" flex="1" mb={2}>
              <Controller
                name="checkInLogo.size"
                defaultValue={template.checkInLogo.size}
                control={control}
                render={({value, onChange}) => (
                  <Slider
                    valueLabelDisplay="auto"
                    aria-label="logo weight"
                    value={value}
                    onChange={onChange}
                    step={1}
                    min={MIN_LOGO_SIZE_PERCENT}
                    max={MAX_LOGO_SIZE_PERCENT}
                    label="Logo Size"
                  />
                )}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name="checkInBackground.image"
              defaultValue={template.checkInBackground.image}
              control={control}
              render={({value, onChange}) => (
                <ImageAssetUploader
                  label="Background Image"
                  onChange={onChange}
                  value={value}
                  canResize
                  width={1200}
                  height={1920}
                />
              )}
            />
            <Controller
              name="checkInBackground.enabled"
              defaultValue={template.checkInBackground.enabled}
              control={control}
              render={({value, onChange}) => (
                <Switch
                  checked={value}
                  onChange={onChangeCheckedHandler(onChange)}
                  arial-label="set background mode"
                  labelPlacement="end"
                  label={
                    value
                      ? 'Background Image Enabled'
                      : 'Background Image Disabled'
                  }
                />
              )}
            />
          </Grid>
        </Grid>
        <Divider />
        <Title>Background</Title>
        <Controller
          name="checkInLeftPanel.backgroundColor"
          defaultValue={template.checkInLeftPanel.backgroundColor}
          control={control}
          render={({value, onChange}) => (
            <ColorPicker
              label="Color"
              color={value}
              onPick={onChange}
              aria-label="check in left panel background color"
            />
          )}
        />
        <Controller
          name="checkInLeftPanel.backgroundOpacity"
          defaultValue={template.checkInLeftPanel.backgroundOpacity}
          control={control}
          render={({value, onChange}) => (
            <Slider
              valueLabelDisplay="auto"
              min={0}
              max={100}
              step={1}
              onChange={onChange}
              value={value}
              label="Background Opacity"
              unit="%"
            />
          )}
        />
      </StylingPanel>
    </Config>
  )
}

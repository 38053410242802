import React from 'react'
import styled from 'styled-components'
import {Description as DescriptionText} from 'lib/ui/typography'
import TextField, {TextFieldProps} from 'lib/ui/TextField'
import InputLabel from 'lib/ui/TextField/InputLabel'

export type DescribedTextFieldProps = TextFieldProps & {
  className?: string
  title?: string
  description?: string
  textField?: JSX.Element
}

export default function DescribedTextField(props: DescribedTextFieldProps) {
  const {className, title, description, ...textFieldProps} = props

  const textField = props.textField || (
    <StyledTextField {...textFieldProps} variant="outlined" />
  )

  return (
    <>
      <FieldTitle>{title}</FieldTitle>
      <Box fullWidth={props.fullWidth} className={className}>
        <TopDescription>{description}</TopDescription>
        {textField}
        <RightDescription>{description}</RightDescription>
      </Box>
    </>
  )
}

export function TopDescription(props: {children?: string}) {
  if (!props.children) {
    return null
  }
  return (
    <TopDescriptionBox>
      <DescriptionText>{props.children}</DescriptionText>
    </TopDescriptionBox>
  )
}

export function RightDescription(props: {children?: string}) {
  if (!props.children) {
    return null
  }

  return (
    <RightDescriptionBox>
      <DescriptionText>{props.children}</DescriptionText>
    </RightDescriptionBox>
  )
}

const StyledTextField = styled(TextField)`
  max-width: 640px;
`

export const Box = styled.div<{fullWidth?: boolean}>`
  ${(props) => (props.fullWidth ? 'width: 100%;' : '')}
  margin-bottom: ${(props) => props.theme.spacing[10]};

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    display: flex;
    align-items: baseline;
    margin-bottom: ${(props) => props.theme.spacing[5]};
  }
`

const TopDescriptionBox = styled.div`
  padding: 0;
  border: none;
  margin-left: 0;
  margin-bottom: ${(props) => props.theme.spacing[4]} !important;

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    display: none;
  }
`

const RightDescriptionBox = styled(TopDescriptionBox)`
  display: none;
  flex: 1 0 40%;
  padding: ${(props) => `${props.theme.spacing[1]} ${props.theme.spacing[7]}`};
  border-left: 1px solid ${(props) => props.theme.colors.primary};
  margin-left: ${(props) => props.theme.spacing[27]};

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    display: inline;
  }
`

export function FieldTitle(props: {children?: string}) {
  if (!props.children) {
    return null
  }

  return <StyledLabel>{props.children}</StyledLabel>
}

const StyledLabel = styled(InputLabel)`
  margin-bottom: ${(props) => props.theme.spacing[4]} !important;

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    display: none;
  }
`

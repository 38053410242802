import React from 'react'
import styled from 'styled-components'

export default function Title(props: {
  children: string | JSX.Element | React.ReactNode
}) {
  return <Text>{props.children}</Text>
}

const Text = styled.h5`
  margin: 0 0 ${(props) => props.theme.spacing[5]};
  color: ${(props) => props.theme.colors.text.primary};
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
`

import React from 'react'
import {UseFormMethods} from 'react-hook-form'
import {ResourceListProps} from 'Event/template/Cards/Dashboard/Sidebar/SidebarItem/ResourceList'
import OffsetInputs from 'Event/template/Cards/Dashboard/Sidebar/SidebarItem/OffsetInputs'

export default function Styling(props: {
  control: UseFormMethods['control']
  list: ResourceListProps
}) {
  return (
    <OffsetInputs
      control={props.control}
      offsetTop={props.list.offsetTop}
      offsetBottom={props.list.offsetBottom}
    />
  )
}

import React from 'react'
import {PageConfigurable} from 'organization/Event/Configurable'
import RightPanelConfig from 'Event/template/NiftyFifty/EventOfflinePage/RightPanel/RightPanelConfig'
import styled from 'styled-components'
import {useNiftyFiftyTemplate} from 'Event/template/NiftyFifty'
import {rgba} from 'lib/color'
import {useGuestVariables} from 'Event'
import DescriptionText from 'Event/auth/Login/Description/Text'
import Configurable from 'organization/Event/Configurable'
import OfflineContentConfig from 'Event/EventOfflinePage/OfflineContentConfig'
import {OfflinePageProps} from 'Event/JoinArea/OfflinePage'
import {FormContainer} from 'Event/template/NiftyFifty/Login/RightPanel/index'

export default function RightPanel(props: OfflinePageProps) {
  const template = useNiftyFiftyTemplate()
  const {login, textColor} = template

  return (
    <PageConfigurable>
      <RightPanelConfig />
      <RightContainer
        backgroundColor={rgba(
          login.loginRightPanel.backgroundColor,
          login.loginRightPanel.backgroundOpacity,
        )}
      >
        <Configurable aria-label="event offline content config">
          <OfflineContentConfig />
          <FormContainer
            textColor={textColor}
            descriptionTextColor={login.description.color}
          >
            <OfflineText {...props} />
          </FormContainer>
        </Configurable>
      </RightContainer>
    </PageConfigurable>
  )
}

export function OfflineText(props: OfflinePageProps) {
  const v = useGuestVariables()

  return (
    <>
      <Content fontSize={24}>{v(props.title)}</Content>
      <Content>
        <div dangerouslySetInnerHTML={{__html: v(props.description)}} />
      </Content>
    </>
  )
}

function Content(props: {
  children: string | React.ReactElement
  'aria-label'?: string
  fontSize?: number
}) {
  const template = useNiftyFiftyTemplate()
  const {login, linkColor, linkUnderline} = template
  const color = login.description.color
  const fontSize = props.fontSize
    ? props.fontSize
    : template.login.description.fontSize
  if (!props.children) {
    return null
  }

  return (
    <StyledText
      color={color}
      linkColor={linkColor}
      linkUnderline={linkUnderline}
      fontSize={fontSize}
      aria-label={props['aria-label']}
    >
      {props.children}
    </StyledText>
  )
}

const StyledText = styled(DescriptionText)<{
  color: string | null
  fontSize: number
  linkColor: string
  linkUnderline: boolean
}>`
  color: ${(props) => props.color};
  font-size: ${(props) => props.fontSize}px;

  a {
    color: ${(props) => props.linkColor};
    text-decoration: none;

    &:hover {
      text-decoration: ${(props) =>
        props.linkUnderline ? 'underline' : 'none'};
    }
  }
`

const RightContainer = styled.div<{
  backgroundColor: string
}>`
  width: 100%;
  height: 100%;
  margin: 0 auto;

  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  background: ${(props) => props.backgroundColor};
`

import React, {useCallback, useEffect, useState} from 'react'
import {useEventRoutes} from 'organization/Event/EventRoutes'
import Page from 'organization/Event/Page'
import PageBreadcrumbs from 'organization/Event/Page/PageBreadcrumbs'
import RedirectTable from 'organization/Event/Redirects/RedirectsTable'
import {RedirectLink} from 'Event/Redirects/CustomRedirect'
import RedirectsUpdateDialog from 'organization/Event/Redirects/UpdateDialog'
import RedirectsAddDialog from 'organization/Event/Redirects/AddDialog'
import {useOrganization} from 'organization/OrganizationProvider'
import {useEvent} from 'Event/EventProvider'
import {api} from 'lib/url'
import {useAsync} from 'lib/async'
import FullPageLoader from 'lib/ui/layout/FullPageLoader'
import Button from 'lib/ui/Button'

export default function CustomRedirects() {
  const routes = useEventRoutes()
  const [editing, setEditing] = useState<RedirectLink | null>(null)
  const [adding, setAdding] = useState<boolean>(false)
  const add = () => setAdding(true)
  const closeAddDialog = () => setAdding(false)
  const edit = (redirect: RedirectLink) => setEditing(redirect)
  const closeEditDialog = () => setEditing(null)

  const [redirects, setRedirects] = useState<RedirectLink[]>([])
  const {data: saved, loading} = useFetchRedirects()

  const addRedirect = (redirect: RedirectLink) => {
    const appended = [...redirects, redirect]
    setRedirects(appended)
  }

  const updateRedirect = (redirect: RedirectLink) => {
    const updated = redirects.map((a) => {
      const isTarget = a.id === redirect.id
      if (isTarget) {
        return redirect
      }

      return a
    })

    setRedirects(updated)
  }

  const removeRedirect = (redirect: RedirectLink) => {
    const removed = redirects.filter((a) => a.id !== redirect.id)
    setRedirects(removed)
  }

  useEffect(() => {
    if (!saved) {
      return
    }

    setRedirects(saved)
  }, [saved])

  if (loading) {
    return <FullPageLoader />
  }

  return (
    <PageBreadcrumbs
      parent={{
        title: 'Production',
        url: routes.webhooks,
      }}
      page="Redirects"
    >
      <Page>
        <RedirectsAddDialog
          isOpen={adding}
          onClose={closeAddDialog}
          onAdd={addRedirect}
        />
        <RedirectsUpdateDialog
          redirect={editing}
          onClose={closeEditDialog}
          onUpdate={updateRedirect}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={add}
          aria-label="add redirect"
        >
          ADD REDIRECT
        </Button>
        <RedirectTable
          redirects={redirects}
          onSelect={edit}
          onRemove={removeRedirect}
        />
      </Page>
    </PageBreadcrumbs>
  )
}

export function useFetchRedirects() {
  const {client} = useOrganization()
  const {event} = useEvent()
  const url = api(`/redirects/${event.id}`)
  const request = useCallback(() => client.get<RedirectLink[]>(url), [
    url,
    client,
  ])

  return useAsync(request)
}

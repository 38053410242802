import TextField from '@material-ui/core/TextField'
import ComponentConfig, {
  SaveButton,
  Footer,
} from 'organization/Event/DashboardConfig/ComponentConfigPanel'
import StylingPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/StylingPanel'
import ColorPicker from 'lib/ui/ColorPicker'
import React from 'react'
import Slider from 'lib/ui/Slider'
import {useToggle} from 'lib/toggle'
import Button from '@material-ui/core/Button'
import styled from 'styled-components'
import {Controller, UseFormMethods, useForm} from 'react-hook-form'
import {useSaveTemplate} from 'Event/TemplateUpdateProvider'
import {Label} from 'lib/ui/typography'
import Title from 'lib/ui/ConfigPanel/Title'
import Divider from 'lib/ui/ConfigPanel/Divider'
import FontSelect from 'lib/FontSelect'
import {useHasUnsavedChanges} from 'organization/Event/TemplateEditor'
import {BlogSectionProps} from 'Event/template/Townhall/Dashboard/Main/BlogSection'
import {Townhall} from 'Event/template/Townhall'
import CollapsibleSection from 'lib/ui/ConfigPanel/CollapsibleSection'
import BackgroundPicker from 'lib/ui/form/BackgroundPicker'
import {useTownhallTemplate} from 'Event/template/Townhall'

const MAX_SPACING = 250
const MIN_SPACING = 1

export default function PostStylesConfig(props: {
  blogSection: BlogSectionProps
  sectionId: string
}) {
  const {flag: showingConfig, toggle: toggleConfig} = useToggle()
  const {register, control, handleSubmit, watch} = useForm()
  const update = useSaveTemplate()

  const save = (data: Townhall) => {
    update(data)

    toggleConfig()
  }

  const hasChanges = useHasUnsavedChanges(watch())

  return (
    <>
      <StyledEditPostStylesButton onClick={toggleConfig} />
      <ComponentConfig
        showing={showingConfig}
        onClose={toggleConfig}
        title="Post Styles"
        onSubmit={handleSubmit(save)}
        hasChanges={hasChanges}
      >
        <StylingPanel>
          <Styling control={control} register={register} {...props} />
        </StylingPanel>
        <Footer>
          <SaveButton type="submit" />
        </Footer>
      </ComponentConfig>
    </>
  )
}

function Styling(props: {
  control: UseFormMethods['control']
  register: UseFormMethods['register']
  blogSection: BlogSectionProps
  sectionId: string
}) {
  const {control, register, blogSection, sectionId} = props
  const {postStyles} = blogSection
  const template = useTownhallTemplate()
  const {textColor} = template
  const blogTitleTextColor = postStyles?.titleTextColor
    ? postStyles.titleTextColor
    : textColor
  const blogDateTextColor = postStyles?.dateTextColor
    ? postStyles.dateTextColor
    : textColor
  const blogContentTextColor = postStyles?.contentTextColor
    ? postStyles.contentTextColor
    : textColor

  return (
    <>
      <CollapsibleSection
        label="Background"
        storageKey="dashboard.postStyles.styling.background"
      >
        <Controller
          name={`dashboardSections.${sectionId}.postStyles.background`}
          control={control}
          defaultValue={postStyles?.background}
          render={({onChange, value}) => (
            <BackgroundPicker
              hasFreeSize
              label="Background Color"
              background={value}
              onChange={onChange}
            />
          )}
        />
      </CollapsibleSection>
      <CollapsibleSection
        label="Border"
        storageKey="dashboard.postStyles.styling.border"
      >
        <Controller
          name={`dashboardSections.${sectionId}.postStyles.borderColor`}
          control={control}
          defaultValue={postStyles?.borderColor || ''}
          render={({value, onChange}) => (
            <ColorPicker label="Color" color={value} onPick={onChange} />
          )}
        />
        <Controller
          name={`dashboardSections.${sectionId}.postStyles.borderWidth`}
          defaultValue={postStyles?.borderWidth || 0}
          control={control}
          render={({value, onChange}) => (
            <Slider
              min={0}
              max={100}
              onChange={onChange}
              valueLabelDisplay="off"
              value={value}
              label="Width"
            />
          )}
        />
        <Controller
          name={`dashboardSections.${sectionId}.postStyles.borderRadius`}
          defaultValue={postStyles?.borderRadius || 0}
          control={control}
          render={({value, onChange}) => (
            <Slider
              min={0}
              max={100}
              onChange={onChange}
              valueLabelDisplay="off"
              value={value}
              label="Radius"
            />
          )}
        />
      </CollapsibleSection>
      <Title>Blog Title</Title>
      <Label>Font</Label>
      <Controller
        control={control}
        name={`dashboardSections.${sectionId}.postStyles.titleFont`}
        defaultValue={postStyles?.titleFont}
        render={({value, onChange}) => (
          <FontSelect value={value} onChange={onChange} />
        )}
      />
      <TextField
        name={`dashboardSections.${sectionId}.postStyles.titleFontSize`}
        defaultValue={postStyles?.titleFontSize}
        label="Font Size"
        type="number"
        fullWidth
        inputProps={{
          min: 0,
          ref: register,
          'aria-label': 'title font size',
        }}
      />
      <Controller
        name={`dashboardSections.${sectionId}.postStyles.titleTextColor`}
        defaultValue={blogTitleTextColor}
        control={control}
        render={({value, onChange}) => (
          <ColorPicker
            label="Text Color"
            color={value}
            onPick={onChange}
            aria-label="title text color"
          />
        )}
      />
      <Divider />
      <Title>Blog Date</Title>
      <TextField
        name={`dashboardSections.${sectionId}.postStyles.dateFontSize`}
        defaultValue={postStyles?.dateFontSize}
        label="Font Size"
        type="number"
        fullWidth
        inputProps={{
          min: 0,
          ref: register,
          'aria-label': 'date font size',
        }}
      />
      <Controller
        name={`dashboardSections.${sectionId}.postStyles.dateTextColor`}
        defaultValue={blogDateTextColor}
        control={control}
        render={({value, onChange}) => (
          <ColorPicker
            label="Text Color"
            color={value}
            onPick={onChange}
            aria-label="date text color"
          />
        )}
      />
      <Divider />
      <Title>Blog Content</Title>
      <TextField
        name={`dashboardSections.${sectionId}.postStyles.contentFontSize`}
        defaultValue={postStyles?.contentFontSize}
        label="Font Size"
        type="number"
        fullWidth
        inputProps={{
          min: 0,
          ref: register,
          'aria-label': 'content font size',
        }}
      />
      <Controller
        name={`dashboardSections.${sectionId}.postStyles.contentTextColor`}
        defaultValue={blogContentTextColor}
        control={control}
        render={({value, onChange}) => (
          <ColorPicker
            label="Text Color"
            color={value}
            onPick={onChange}
            aria-label="content text color"
          />
        )}
      />
      <Divider />
      <Controller
        name={`dashboardSections.${sectionId}.postStyles.spacing`}
        defaultValue={postStyles?.spacing}
        control={control}
        render={({value, onChange}) => (
          <Slider
            min={MIN_SPACING}
            max={MAX_SPACING}
            step={1}
            onChange={onChange}
            valueLabelDisplay="auto"
            value={value}
            label="Space Between Posts"
          />
        )}
      />
    </>
  )
}

function EditPostStylesButton(props: {
  onClick: () => void
  className?: string
}) {
  return (
    <Button
      className={props.className}
      fullWidth
      size="large"
      variant="contained"
      color="primary"
      aria-label="style posts"
      onClick={props.onClick}
    >
      Edit Post Styles
    </Button>
  )
}

const StyledEditPostStylesButton = styled(EditPostStylesButton)`
  margin-bottom: ${(props) => props.theme.spacing[3]}!important;
`

import React from 'react'
import styled from 'styled-components'
import Typography from '@material-ui/core/Typography'
import TextContent from 'lib/ui/form/TextEditor/Content'
import {useAttendeeVariables} from 'Event'
import QuestionIcon from 'Event/Sponsors/QuestionIcon'
import {Sponsor} from 'Event/Sponsors'
import Buttons from 'Event/Sponsors/Buttons'
import {useTownhallTemplate} from 'Event/template/Townhall'
import {Townhall} from 'Event/template/Townhall'

type BodyProps = {
  sponsor: Sponsor
  toggleForm: () => void
  textColor?: string
  id: string
}

export default function Content(props: BodyProps) {
  const {sponsor, id} = props
  const v = useAttendeeVariables()
  const {sponsors, textColor} = useTownhallTemplate()
  return (
    <Box>
      <SponsorHeader separator={sponsors.separator}>
        <StyledTypography
          variant="h5"
          textColor={textColor}
          sponsorColor={sponsors.text.color}
        >
          {v(sponsor.name)}
        </StyledTypography>
      </SponsorHeader>
      <SponsorTextContent>
        <StyledTextContent
          textColor={textColor}
          sponsorColor={sponsors.text.color}
        >
          {v(sponsor.description)}
        </StyledTextContent>
        <StyledQuestionIcon>
          <QuestionIcon sponsor={sponsor} onClick={props.toggleForm} id={id} />
        </StyledQuestionIcon>
        <Buttons sponsorId={props.id} />
      </SponsorTextContent>
    </Box>
  )
}

const Box = styled.div`
  width: 100%;
`

const StyledQuestionIcon = styled.div`
  display: flex;
  justify-content: end;
`

const SponsorHeader = styled.div<{
  separator: Townhall['sponsors']['separator']
}>`
  display: flex;
  justify-content: space-between;
  border-bottom: ${(props) => props.separator.width}px solid
    ${(props) => props.separator.color};
  padding-left: ${(props) => props.theme.spacing[6]};
  padding-right: ${(props) => props.theme.spacing[6]};
  padding-top: ${(props) => props.theme.spacing[4]};
  padding-bottom: ${(props) => props.theme.spacing[4]};
`

const SponsorTextContent = styled.div`
  padding-left: ${(props) => props.theme.spacing[6]};
  padding-right: ${(props) => props.theme.spacing[6]};
  padding-bottom: ${(props) => props.theme.spacing[4]};
  padding-top: ${(props) => props.theme.spacing[4]};
`

const StyledTypography = styled(Typography)<{
  textColor: string
  sponsorColor: string | null
}>`
  color: ${(props) =>
    props.sponsorColor ? props.sponsorColor : props.textColor};
`

const StyledTextContent = styled(TextContent)<{
  textColor: string
  sponsorColor: string | null
}>`
  color: ${(props) =>
    props.sponsorColor ? props.sponsorColor : props.textColor};
`

import React, {useEffect, useState} from 'react'
import {onUnknownChangeHandler} from 'lib/dom'
import Select from 'lib/ui/Select'
import Option from 'lib/ui/Select/Option'
import AdvancedAttendeeFilter from 'organization/Event/ZoomAttendance/AdvancedAttendeeFilter'
import {FormProps} from 'organization/Event/ZoomAttendance/AttendeeActionForm'
import ButtonRow from 'organization/Event/ZoomAttendance/ButtonRow'
import {
  useZoomAttendanceAction,
  useZoomAttendance,
} from 'organization/Event/ZoomAttendance/ZoomAttendanceProvider'

export default function ActionFormExport(props: FormProps) {
  const {action, onClose, onSuccessMessage} = props
  const [error, setError] = useState<string>('')
  const [exportType, setExportType] = useState<string>('')
  const submitAction = useZoomAttendanceAction()
  const {
    area,
    attendeeFilterData,
    endDateTime,
    setScheduledActionData,
    startDateTime,
  } = useZoomAttendance()

  const validation = () => {
    setError('')

    if (!exportType) {
      setError('You must select an Export Type')
      return false
    }

    return true
  }

  const onClick = () => {
    if (validation() === false) {
      return
    }

    submitAction(action, startDateTime, endDateTime, area, {
      exportType: exportType,
      filter: attendeeFilterData,
    })
      .then((response) => {
        onSuccessMessage(response.message ?? null)
        onClose()
      })
      .catch((e) => {
        setError(e.message)
        return
      })
  }

  useEffect(() => {
    setScheduledActionData({
      exportType: exportType,
    })
  }, [exportType, setScheduledActionData])

  return (
    <>
      <AdvancedAttendeeFilter />

      <Select
        aria-label="pick export type"
        error={Boolean(error)}
        fullWidth
        helperText={error}
        label="Export Type"
        onChange={onUnknownChangeHandler(setExportType)}
        value={exportType}
      >
        <Option value="detail">Detailed</Option>
        <Option value="summary">Summary</Option>
      </Select>

      <ButtonRow
        action={action}
        onClick={onClick}
        onClose={onClose}
        validation={validation}
      />
    </>
  )
}

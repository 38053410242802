import React from 'react'

import {handleAutocomplete} from 'lib/dom'

import {Autocomplete} from '@material-ui/lab'
import TextField from '@material-ui/core/TextField'
import styled from 'styled-components'
import {useHubspotProperties} from 'organization/Event/Services/Apps/Hubspot/Config/HubspotPropertiesProvider'
import {TargetProperty} from 'organization/Event/Services/Apps/Hubspot/Config'

export default function PropertySelector(props: {
  onChange: (selected: TargetProperty | null) => void
  value?: TargetProperty | null
  targetTypes?: TargetProperty['type'][]
  readOnly?: boolean
  errorText?: string
  label?: string
  ['aria-label']?: string
}) {
  const {query: findProperties} = useHubspotProperties()

  const {
    value: selected,
    onChange,
    targetTypes: types,
    errorText,
    label,
    readOnly,
  } = props

  const optionLabel = (property: TargetProperty | null) => {
    if (!property || !property.label) {
      return ''
    }

    if (property.type) {
      return `${property.label} - (${property.type})`
    }

    return property.label
  }

  const options = findProperties({
    types: types || [],
    readOnly: readOnly || false,
  })

  return (
    <Box>
      <Autocomplete
        disablePortal
        options={options}
        value={selected || null}
        aria-label={props['aria-label'] || 'field autocomplete'}
        onChange={handleAutocomplete(onChange)}
        getOptionLabel={optionLabel}
        closeIcon=""
        noOptionsText="Could not find a field containing search word"
        getOptionSelected={(option, value) => option.name === value.name}
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              variant="outlined"
              label={label ?? 'Property'}
              fullWidth
              error={Boolean(errorText)}
              helperText={errorText || ''}
              inputProps={{
                ...params.inputProps,
                'aria-label': 'field search',
              }}
              InputProps={{
                ...params.InputProps,
              }}
            />
          )
        }}
      />
    </Box>
  )
}

const Box = styled.div`
  position: relative;
  .MuiAutocomplete-popper {
    margin-top: -20px !important;
  }
`

import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import Button from '@material-ui/core/Button'
import {ActiveCampaignTag} from 'Event/activeCampaign'
import {HighLevelTag} from 'Event/highlevel'
import {HubspotTag} from 'Event/hubspot'
import {InfusionsoftTag} from 'Event/infusionsoft'
import {MailchimpTag} from 'Event/mailchimp'
import {ZapierTag} from 'Event/zapier'
import {RelativeLink} from 'lib/ui/link/RelativeLink'
import HelperText from 'lib/ui/TextField/HelperText'
import ActiveCampaignTagInput from 'organization/Event/DashboardConfig/ActiveCampaignTagInput'
import ConvertKitTagInput from 'organization/Event/DashboardConfig/ConvertKitTagInput'
import HighLevelTagInput from 'organization/Event/DashboardConfig/HighLevelTagInput'
import HubspotTagInput from 'organization/Event/DashboardConfig/HubspotTagInput'
import InfusionsoftTagInput from 'organization/Event/DashboardConfig/InfusionsoftTagInput'
import MailchimpTagInput from 'organization/Event/DashboardConfig/MailchimpTagInput'
import OntraportTagInput from 'organization/Event/DashboardConfig/OntraportTagInput'
import ZapierTagInput from 'organization/Event/DashboardConfig/ZapierTagInput'
import {useEventRoutes} from 'organization/Event/EventRoutes'
import {ConvertKitTag} from 'organization/Event/Services/Apps/ConvertKit/Config/Tags'
import {OntraportTag} from 'organization/Event/Services/Apps/Ontraport/Config/Tags'
import ServicesProvider, {
  useServices,
} from 'organization/Event/Services/ServicesProvider'
import AdvancedAttendeeFilter from 'organization/Event/ZoomAttendance/AdvancedAttendeeFilter'
import {FormProps} from 'organization/Event/ZoomAttendance/AttendeeActionForm'
import ButtonRow, {
  ButtonRowProps,
} from 'organization/Event/ZoomAttendance/ButtonRow'
import {
  useZoomAttendanceAction,
  useZoomAttendance,
} from 'organization/Event/ZoomAttendance/ZoomAttendanceProvider'

export default function ActionFormExternalTag(props: FormProps) {
  return (
    <ServicesProvider noLoader>
      <Content {...props} />
    </ServicesProvider>
  )
}

const Content = (props: FormProps) => {
  const {action, onClose, onSuccessMessage} = props
  const [error, setError] = useState<string>('')
  const {integrations} = useServices()

  const [
    activeCampaignTag,
    setActiveCampaignTag,
  ] = useState<ActiveCampaignTag | null>(null)
  const [convertKitTag, setConvertKitTag] = useState<ConvertKitTag | null>(null)
  const [highLevelTag, setHighLevelTag] = useState<HighLevelTag | null>(null)
  const [hubspotTag, setHubspotTag] = useState<HubspotTag | null>(null)
  const [
    infusionsoftTag,
    setInfusionsoftTag,
  ] = useState<InfusionsoftTag | null>(null)
  const [mailchimpTag, setMailchimpTag] = useState<MailchimpTag | null>(null)
  const [ontraportTag, setOntraportTag] = useState<OntraportTag | null>(null)
  const [zapierTag, setZapierTag] = useState<ZapierTag | null>(null)

  const linkedIntegrations = integrations.filter(
    (integration) => integration.is_linked === true,
  )
  const hasIntegrations = Boolean(linkedIntegrations.length)

  const submitAction = useZoomAttendanceAction()
  const {
    area,
    attendeeFilterData,
    endDateTime,
    setScheduledActionData,
    startDateTime,
  } = useZoomAttendance()

  const validation = () => {
    setError('')

    if (
      !activeCampaignTag &&
      !convertKitTag &&
      !highLevelTag &&
      !hubspotTag &&
      !infusionsoftTag &&
      !mailchimpTag &&
      !ontraportTag &&
      !zapierTag
    ) {
      setError('You must provide at least one Service Inegration tag')
      return false
    }

    return true
  }

  const onClick = () => {
    if (validation() === false) {
      return
    }

    submitAction(action, startDateTime, endDateTime, area, {
      activeCampaignTag: activeCampaignTag,
      convertKitTag: convertKitTag,
      highLevelTag: highLevelTag,
      hubspotTag: hubspotTag,
      infusionsoftTag: infusionsoftTag,
      mailchimpTag: mailchimpTag,
      ontraportTag: ontraportTag,
      zapierTag: zapierTag,
      filter: attendeeFilterData,
    })
      .then((response) => {
        onSuccessMessage(response.message ?? null)
        onClose()
      })
      .catch((e) => {
        setError(e.message)
        return
      })
  }

  useEffect(() => {
    setScheduledActionData({
      activeCampaignTag: activeCampaignTag,
      convertKitTag: convertKitTag,
      highLevelTag: highLevelTag,
      hubspotTag: hubspotTag,
      infusionsoftTag: infusionsoftTag,
      mailchimpTag: mailchimpTag,
      ontraportTag: ontraportTag,
      zapierTag: zapierTag,
    })
  }, [
    activeCampaignTag,
    convertKitTag,
    highLevelTag,
    hubspotTag,
    infusionsoftTag,
    mailchimpTag,
    ontraportTag,
    zapierTag,
    setScheduledActionData,
  ])

  return (
    <>
      <AdvancedAttendeeFilter />

      <StyledIntegrationRow>
        <ActiveCampaignTagInput
          onChange={setActiveCampaignTag}
          value={activeCampaignTag}
        />
      </StyledIntegrationRow>
      <StyledIntegrationRow>
        <ConvertKitTagInput onChange={setConvertKitTag} value={convertKitTag} />
      </StyledIntegrationRow>
      <StyledIntegrationRow>
        <HighLevelTagInput onChange={setHighLevelTag} value={highLevelTag} />
      </StyledIntegrationRow>
      <StyledIntegrationRow>
        <HubspotTagInput onChange={setHubspotTag} value={hubspotTag} />
      </StyledIntegrationRow>
      <StyledIntegrationRow>
        <InfusionsoftTagInput
          autoSet
          onChange={setInfusionsoftTag}
          value={infusionsoftTag}
        />
      </StyledIntegrationRow>
      <StyledIntegrationRow>
        <MailchimpTagInput onChange={setMailchimpTag} value={mailchimpTag} />
      </StyledIntegrationRow>
      <StyledIntegrationRow>
        <OntraportTagInput onChange={setOntraportTag} value={ontraportTag} />
      </StyledIntegrationRow>
      <StyledIntegrationRow>
        <ZapierTagInput onChange={setZapierTag} value={zapierTag} />
      </StyledIntegrationRow>

      <StyledHelperText error={Boolean(error)}>{error}</StyledHelperText>

      <ButtonRowOrCancel
        action={action}
        hasIntegrations={hasIntegrations}
        onClick={onClick}
        onClose={onClose}
        validation={validation}
      />
    </>
  )
}

const ButtonRowOrCancel = (
  props: ButtonRowProps & {
    hasIntegrations?: boolean
  },
) => {
  const {onClose} = props
  const routes = useEventRoutes()

  if (props.hasIntegrations === false) {
    return (
      <>
        <div>
          Before you can Add and External Tag, you must integrate with at least
          one of the available{' '}
          <RelativeLink to={routes.services.root}>Services</RelativeLink> first.
        </div>

        <ButtonContainer>
          <Button color="primary" onClick={onClose} variant="outlined">
            OK
          </Button>
        </ButtonContainer>
      </>
    )
  }

  return <ButtonRow {...props} />
}

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: ${(props) => props.theme.spacing[4]} 0;

  & > button:first-child {
    margin-right: ${(props) => props.theme.spacing[4]};
  }
`

const StyledHelperText = styled(HelperText)`
  margin-bottom: ${(props) => props.theme.spacing[6]};
`
const StyledIntegrationRow = styled.div`
  margin-bottom: ${(props) => props.theme.spacing[1]};
`

import {Sidebar} from 'Event/template/SimpleBlog/Dashboard/Sidebar/SidebarContainer'
import {NavButtonWithSize} from 'Event/Dashboard/components/NavButton'
import {HashMap} from 'lib/list'
import {Column} from 'lib/ui/layout'
import {BaseTemplate, Details} from 'Event/template'
import {useTemplate} from 'Event/TemplateProvider'
import {DeepRequired} from 'lib/type-utils'
import {SidebarItemProps} from 'Event/template/SimpleBlog/Dashboard/Sidebar/SidebarItem'
import {CountDownTimerSettings} from 'Event/Dashboard/components/CountDownTimer'
import {useSaveTemplate} from 'Event/TemplateUpdateProvider'
import {InputStyleProps} from 'Event/auth/TextField'
import {createBaseTemplate} from 'Event/template/base'
import {Font} from 'lib/FontSelect'
import {createNavButtonWithSize} from 'Event/Dashboard/components/NavButton/create'
export {convert} from 'Event/template/SimpleBlog/converter'

/**
 * Template Name (Required)
 * --------------------------------------------------
 * Must be unique, and match the template directory
 * for dynamic imports to work.
 */
export const SIMPLE_BLOG = 'SimpleBlog'

/**
 * Template Details (Required)
 * --------------------------------------------------
 * App-level details shared by every template instance.
 */
export const details: Details = {
  label: 'Classic',
  previewUrl:
    'https://classic-demo.obv.io/login?token=938fc2d463f32a179961ca28a58274e3',
}

export function useSimpleBlogTemplate() {
  const template = useTemplate()

  if (template.name !== SIMPLE_BLOG) {
    throw new Error('useSimpleBlog called with wrong template')
  }

  return template
}

export function useSimpleBlogUpdate() {
  return useSaveTemplate<SimpleBlog>()
}

export type SimpleBlog = BaseTemplate & {
  name: typeof SIMPLE_BLOG
  title: string
  mainNav: {
    buttons: HashMap<NavButtonWithSize>
  }
  welcomeText?: string
  welcomeTextFont?: Font | null
  welcomeTextFontSize?: number
  welcomeTextColor?: string | null
  heroImageSize?: number
  heroImage: string | null
  sidebar: Sidebar
  sidebarItems: HashMap<SidebarItemProps>
  textColor?: string
  linkColor?: string
  linkUnderline?: boolean
  points_unit: string
  header: SimpleBlogHeader
  dashboardBackground?: {
    image: string | null
    color: string
    opacity: number
  }
  footer: {
    image: string | null
    background: string
    textColor: string | null
    termsLink: string | null
    privacyLink: string | null
    copyrightText: string | null
    imageSize?: number
  }
  progressBar: {
    barColor: string
    backgroundColor: string
    textColor: string
    thickness: number
    borderRadius: number
    showing: boolean
    step1Text: string
    step1Percent: number
    step2Text: string
    step2Percent: number
    step3Text: string
    step3Percent: number
  }
  backgroundPosition: 'fixed' | 'bottom'
  setPasswordForm?: {
    title?: string
    description?: string
    passwordLabel?: string
    confirmPasswordLabel?: string
    button?: NavButtonWithSize
    input?: InputStyleProps
  }
  leaderboard?: {
    title?: string
    description?: string
    backToDashboardText?: string
    backToDashboardTextColor?: string
    isVisible?: boolean
  }
  sponsors?: {
    imageSize?: Column
    backToDashboardText?: string
    backToDashboardTextColor?: string
    spacing?: number
    hasDivider?: boolean
    menuTitle?: string
  }
  speakers?: {
    backToDashboardText?: string
    backToDashboardTextColor?: string
    spacing?: number
    menuTitle?: string
  }
  faq?: {
    backToDashboardText?: string
    backToDashboardTextColor?: string
  }
  zoomBackgrounds?: {
    backToDashboardText?: string
    backToDashboardTextColor?: string
  }
  countDownTimers?: HashMap<CountDownTimerSettings>
}

export type SimpleBlogHeader = {
  backgroundColor: string
  backgroundOpacity: number
  height: number
  isEnabled?: boolean
  shadowEnabled?: boolean
  logoHeight: number
  background: string | null
}

/**
 * Create a Template instance with no preset data (required)
 */
export const createBlank = (): DeepRequired<SimpleBlog> => ({
  ...createBaseTemplate(),
  version: 1,
  name: SIMPLE_BLOG,
  title: '',
  mainNav: {
    buttons: {},
  },
  welcomeText: 'WELCOME TO YOUR DASHBOARD',
  welcomeTextFont: null,
  welcomeTextFontSize: 50,
  welcomeTextColor: null,
  sidebar: {
    backgroundImage: null,
    background: 'blue',
    textColor: null,
    borderRadius: 0,
    borderWidth: 0,
    borderColor: '#000000',
    paddingTop: 48,
    isVisible: true,
    separatorColor: '#FFFFFF',
    separatorStyle: 'solid',
    separatorThickness: 1,
    itemSpacing: 32,
  },
  sidebarItems: {},
  backgroundPosition: 'fixed',
  heroImageSize: 50,
  heroImage: null,
  textColor: '#000000',
  linkColor: '#000000',
  linkUnderline: true,
  header: {
    backgroundColor: '#FFFFFF',
    backgroundOpacity: 100,
    height: 150,
    isEnabled: true,
    shadowEnabled: true,
    logoHeight: 110,
    background: null,
  },
  footer: {
    image: null,
    background: '#00e0000',
    textColor: '#FFFFFF',
    termsLink: null,
    privacyLink: null,
    copyrightText: null,
    imageSize: 100,
  },
  progressBar: {
    barColor: '#0969d6',
    backgroundColor: '#b1d4f1',
    textColor: '#000000',
    thickness: 15,
    borderRadius: 50,
    showing: true,
    step1Text: 'Step 1',
    step1Percent: 33,
    step2Text: 'Step 2',
    step2Percent: 66,
    step3Text: 'Step 3',
    step3Percent: 100,
  },
  sponsors: {
    ...createBaseTemplate().sponsors,
    imageSize: 4,
    backToDashboardText: 'Back to Dashboard',
    backToDashboardTextColor: '#000000',
    spacing: 0,
    hasDivider: true,
    menuTitle: 'Our Sponsors',
  },
  speakers: {
    ...createBaseTemplate().speakers,
    backToDashboardText: 'Back to Dashboard',
    backToDashboardTextColor: '#000000',
    spacing: 0,
    menuTitle: 'Our Speakers',
  },
  zoomBackgrounds: {
    ...createBaseTemplate().zoomBackgrounds,
    backToDashboardText: 'Back to Dashboard',
    backToDashboardTextColor: '#000000',
  },
  dashboardBackground: {
    image: null,
    color: '#FFFFFF',
    opacity: 0,
  },
  setPasswordForm: {
    title: 'Please set a password to continue',
    description: '',
    passwordLabel: 'Password',
    confirmPasswordLabel: 'Confirm Password',
    button: createNavButtonWithSize({text: 'Submit'}),
    input: {
      labelColor: '#3490DC',
      textColor: '#000000',
      borderColor: '#3490DC',
      borderWidth: 2,
      backgroundColor: '#FFFFFF',
      borderRadius: 56,
    },
  },
  leaderboard: {
    title: 'Leaderboard',
    description:
      '<p>{{first name}}, you have earned {{leaderboard_points}} {{points_unit}}, and you are currently {{leaderboard_position}}. Great Job!</p><p><i>The list below is the top 200 point earners! If you don’t see your name listed, there’s still time!</i></p><p><br>&nbsp;</p>',
    backToDashboardText: 'Back to Dashboard',
    backToDashboardTextColor: '#000000',
    isVisible: true,
  },
  faq: {
    title: 'FAQ',
    description: '',
    backToDashboardText: 'Back to Dashboard',
    backToDashboardTextColor: '#000000',
    isEnabled: true,
    items: {},
  },
  countDownTimers: {},
})

export const DEFAULTS = createBlank()

export const sampleEventURL = 'https://v1.obv.io/events/classic-demo'

import {client} from 'lib/ui/api-client'
import {api} from 'lib/url'
import {useQuery} from 'react-query'

export interface State {
  id: number
  name: string
}

export function useStates(countryId: number | null) {
  return useQuery({
    queryKey: ['countries', countryId, 'states'],
    queryFn: () => client.get<State[]>(api(`/countries/${countryId}/states`)),
    enabled: !!countryId,
  })
}

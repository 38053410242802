import React from 'react'
import Slider from 'lib/ui/Slider'
import {Controller, UseFormMethods} from 'react-hook-form'
import {
  NavButtonWithSize,
  DEFAULT_FONT_SIZE,
} from 'Event/Dashboard/components/NavButton'
import {Label} from 'lib/ui/typography'
import ColorPicker from 'lib/ui/ColorPicker'
import FontSelect from 'lib/FontSelect'
import CollapsibleSection from 'lib/ui/ConfigPanel/CollapsibleSection'
import {DEFAULT_BUTTON_TEXT_COLOR} from 'lib/ui/Button/CustomButton'

export default function TypographySection(props: {
  control: UseFormMethods['control']
  button: Pick<
    NavButtonWithSize,
    'font' | 'fontSize' | 'textColor' | 'hoverTextColor'
  >
  storageKey: string
  namePrefix?: string
  label?: string
}) {
  const {control, button, namePrefix} = props

  return (
    <CollapsibleSection
      label={props.label ?? 'Typography'}
      storageKey={props.storageKey}
    >
      <Label>Font</Label>
      <Controller
        control={control}
        name={namePrefix ? `${namePrefix}.font` : 'font'}
        defaultValue={button.font || null}
        render={({value, onChange}) => (
          <FontSelect value={value} onChange={onChange} />
        )}
      />
      <Controller
        name={namePrefix ? `${namePrefix}.fontSize` : 'fontSize'}
        defaultValue={button.fontSize || DEFAULT_FONT_SIZE}
        control={control}
        render={({value, onChange}) => (
          <Slider
            min={1}
            max={100}
            onChange={onChange}
            valueLabelDisplay="off"
            value={value}
            label="Font Size"
          />
        )}
      />
      <Controller
        name={namePrefix ? `${namePrefix}.textColor` : 'textColor'}
        control={control}
        defaultValue={button.textColor || DEFAULT_BUTTON_TEXT_COLOR}
        render={({value, onChange}) => (
          <ColorPicker label="Text Color" color={value} onPick={onChange} />
        )}
      />
      <Controller
        name={namePrefix ? `${namePrefix}.hoverTextColor` : 'hoverTextColor'}
        control={control}
        defaultValue={button.hoverTextColor || DEFAULT_BUTTON_TEXT_COLOR}
        render={({value, onChange}) => (
          <ColorPicker
            label="Text Hover Color"
            color={value}
            onPick={onChange}
            aria-label="text hover color"
          />
        )}
      />
    </CollapsibleSection>
  )
}

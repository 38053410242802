import React, {useState} from 'react'
import {
  MailchimpIntegration,
  useMailchimp,
} from 'organization/Event/Services/Apps/Mailchimp/index'
import {api} from 'lib/url'
import {useOrganization} from 'organization/OrganizationProvider'
import {useEvent} from 'Event/EventProvider'
import {useServices} from 'organization/Event/Services/ServicesProvider'
import Select from 'lib/ui/Select'
import {onUnknownChangeHandler} from 'lib/dom'
import {useToggleArray} from 'lib/toggle'
import FormHelperText from '@material-ui/core/FormHelperText'
import {useAccessTokens} from 'organization/Event/Services/AccessTokens/AccessTokensProvider'
import Option from 'lib/ui/Select/Option'

export const AccessTokenSelect = (props: {disabled?: boolean}) => {
  const {tokens} = useAccessTokens()
  const {access_token_id} = useMailchimp()

  const [processing, toggleProcessing] = useToggleArray()
  const {setToken, error} = useSetAccessToken()

  const handleSetToken = (id: number) => {
    if (processing) {
      return
    }

    toggleProcessing()

    setToken(id).finally(toggleProcessing)
  }

  return (
    <>
      <Select
        disabled={processing || props.disabled}
        value={access_token_id || ''}
        onChange={onUnknownChangeHandler(handleSetToken)}
        fullWidth
        aria-label={'pick access token'}
        variant="outlined"
        error={Boolean(error)}
        label={'Access Token'}
      >
        {tokens.map((token) => (
          <Option
            key={token.id}
            value={token.id}
            aria-label={`pick ${token.value}`}
          >
            {token.value}
          </Option>
        ))}
      </Select>
      <FormHelperText error={Boolean(error)} hidden={!error}>
        {error}
      </FormHelperText>
    </>
  )
}

export function useSetAccessToken() {
  const {client} = useOrganization()
  const {event} = useEvent()
  const {update} = useServices()
  const [error, setError] = useState('')

  const setToken = (id: number) =>
    client
      .put<MailchimpIntegration>(
        api(`/events/${event.id}/integrations/mailchimp/access_token`),
        {
          id,
        },
      )
      .then(update)
      .catch((e) => {
        setError(e.message)
      })

  return {setToken, error}
}

import React from 'react'
import Slider from 'lib/ui/Slider'
import {Controller} from 'react-hook-form'
import {DEFAULT_FONT_SIZE} from 'Event/Dashboard/components/NavButton'
import {Label} from 'lib/ui/typography'
import ColorPicker from 'lib/ui/ColorPicker'
import FontSelect from 'lib/FontSelect'
import CollapsibleSection from 'lib/ui/ConfigPanel/CollapsibleSection'
import {DEFAULT_BUTTON_TEXT_COLOR} from 'lib/ui/Button/CustomButton'
import {Resource} from 'Event/template/Townhall/Dashboard/Main/ResourceSection/ResourceItem'
import {useConfig} from 'organization/Event/Configurable'

export default function TypographySection(props: {
  resource: Resource
  storageKey: string
  sectionId: string
  id: string
}) {
  const {resource, sectionId, id} = props
  const {
    form: {control},
  } = useConfig()

  return (
    <CollapsibleSection label="Typography" storageKey={props.storageKey}>
      <Label>Font</Label>
      <Controller
        control={control}
        name={`dashboardSections.${sectionId}.items.${id}.font`}
        defaultValue={resource.font || null}
        render={({value, onChange}) => (
          <FontSelect value={value} onChange={onChange} />
        )}
      />
      <Controller
        name={`dashboardSections.${sectionId}.items.${id}.fontSize`}
        defaultValue={resource.fontSize || DEFAULT_FONT_SIZE}
        control={control}
        render={({value, onChange}) => (
          <Slider
            min={1}
            max={100}
            onChange={onChange}
            valueLabelDisplay="off"
            value={value}
            label="Font Size"
          />
        )}
      />
      <Controller
        name={`dashboardSections.${sectionId}.items.${id}.textColor`}
        control={control}
        defaultValue={resource.textColor || DEFAULT_BUTTON_TEXT_COLOR}
        render={({value, onChange}) => (
          <ColorPicker label="Text Color" color={value} onPick={onChange} />
        )}
      />
      <Controller
        name={`dashboardSections.${sectionId}.items.${id}.hoverTextColor`}
        control={control}
        defaultValue={resource.hoverTextColor || DEFAULT_BUTTON_TEXT_COLOR}
        render={({value, onChange}) => (
          <ColorPicker
            label="Text Hover Color"
            color={value}
            onPick={onChange}
            aria-label="text hover color"
          />
        )}
      />
    </CollapsibleSection>
  )
}

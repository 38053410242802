import React from 'react'
import Button from '@material-ui/core/Button'
import {FAQ} from 'Event/FAQs'
import {useEditMode} from 'Event/EditModeProvider'
import styled from 'styled-components'
import {useSaveTemplate} from 'Event/TemplateUpdateProvider'
import {useTemplate} from 'Event/TemplateProvider'
import {uuid} from 'lib/uuid'

export default function AddFaqButton() {
  const isEditMode = useEditMode()

  if (!isEditMode) {
    return null
  }

  return <Content />
}

function Content() {
  const {faq} = useTemplate()
  const save = useSaveTemplate()

  const add = () => {
    const numFaqs = Object.keys(faq.items).length
    const position = numFaqs + 1
    const id = uuid()

    const newFaq: FAQ = {
      question: 'Question',
      answer: '',
      position,
    }

    save({
      faq: {
        items: {
          [id]: newFaq,
        },
      },
    })
  }

  return (
    <StyledAddButton
      fullWidth
      variant="contained"
      color="primary"
      aria-label="add faq"
      onClick={add}
    >
      Add Faq
    </StyledAddButton>
  )
}

const StyledAddButton = styled(Button)`
  margin-top: ${(props) => props.theme.spacing[8]}!important;
  margin-bottom: ${(props) => props.theme.spacing[8]}!important;
`

import moment from 'moment-timezone'

export const isPast = (target: string, start = now(), timezone = 'UTC') =>
  !date(target, timezone).isAfter(start)

export const now = () => new Date().toISOString()

/**
 * Date comparison utils
 *
 * @param target
 * @returns
 */
export const date = (target: string, timezone = 'UTC') => {
  return {
    isAfter: (value: string) => {
      /**
       * Handle bug where sometimes moment.isAfter would return true
       * for the same string
       */
      if (target === value) {
        return false
      }

      return moment.tz(target, timezone).isAfter(moment.tz(value, timezone))
    },
  }
}

export interface Duration {
  days?: string
  hours: string
  minutes: string
  seconds: string
}

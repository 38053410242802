import Button, {ButtonProps} from 'lib/ui/Button'
import Icon from 'lib/ui/Icon'
import React from 'react'
import styled from 'styled-components'

interface AddBlockButtonProps extends Omit<ButtonProps, 'children'> {}

export default function AddBlockButton(props: AddBlockButtonProps) {
  return (
    <Box>
      <StyledButton
        size="small"
        variant="contained"
        {...props}
        color="primary"
        fullWidth
        startIcon={<Icon className="fa-solid fa-circle-plus" />}
      >
        Add Content Block
      </StyledButton>
    </Box>
  )
}

const Box = styled.div`
  padding: 2rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid #c3ddf3;
  background-color: ${(props) => props.theme.colors.white};

  background-image: repeating-linear-gradient(
    145deg,
    #c3ddf3 10px,
    #c3ddf3 10px,
    transparent 12px,
    transparent 20px
  );
`

const StyledButton = styled(Button)`
  border: 10px solid #ffffff;
  border-radius: 15px;
  max-width: 300px;
  &:hover {
    opacity: 0.9;
  }
`

import React from 'react'
import Button from 'lib/ui/Button'
import Box from '@material-ui/core/Box'
import InputLabel from 'lib/ui/InputLabel'
import Switch from 'lib/ui/form/Switch'
import Slider from 'lib/ui/Slider'
import {RelativeLink} from 'lib/ui/link/RelativeLink'
import {useEventRoutes} from 'organization/Event/EventRoutes'
import {useTemplate} from 'Event/TemplateProvider'
import withStyles from '@material-ui/core/styles/withStyles'
import Page from 'organization/Event/Page'
import {onChangeCheckedHandler} from 'lib/dom'
import ColorPicker from 'lib/ui/ColorPicker'
import {Controller, useForm} from 'react-hook-form'
import {Template} from 'Event/template'
import {useSaveTemplate} from 'Event/TemplateUpdateProvider'
import ImageAssetUploader from 'lib/asset/ImageAssetUploader'
import {spacing} from 'lib/ui/theme'
import PageBreadcrumbs from 'organization/Event/Page/PageBreadcrumbs'

export default function EmojiPageSettings() {
  const {emojiPage} = useTemplate()
  const routes = useEventRoutes()
  const {control, handleSubmit} = useForm()
  const update = useSaveTemplate()

  const submit = (data: Template['emojiPage']) => {
    update({
      emojiPage: data,
    })
  }

  return (
    <PageBreadcrumbs
      parent={{
        title: 'Production',
        url: routes.webhooks,
      }}
      page="Emoji page"
    >
      <Page>
        <Box display="flex" justifyContent="flex-end" mb={2}>
          <RelativeLink
            to={routes.emoji.root}
            aria-label="view emoji page"
            newTab
            disableStyles
          >
            <Button variant="outlined">View Emojis</Button>
          </RelativeLink>
        </Box>
        <form onSubmit={handleSubmit(submit)}>
          <Box display="flex" flexDirection="column" flex="1" mb={2}>
            <Controller
              name="backgroundEnabled"
              defaultValue={emojiPage.backgroundEnabled}
              control={control}
              render={({value, onChange}) => (
                <Switch
                  checked={value}
                  onChange={onChangeCheckedHandler(onChange)}
                  aria-label="toggle background visible"
                  labelPlacement="end"
                  label={
                    value
                      ? 'Background Image Enabled'
                      : 'Background Image Disabled'
                  }
                />
              )}
            />
          </Box>
          <Box mb={2}>
            <Label>Background Image</Label>
            <Controller
              name="backgroundImage"
              control={control}
              defaultValue={emojiPage.backgroundImage}
              render={({onChange, value}) => (
                <ImageAssetUploader
                  onChange={onChange}
                  value={value}
                  uploadLabel="Upload"
                  canResize
                  removeButtonProps={{
                    'aria-label': 'remove emoji page background image',
                  }}
                  uploadInputProps={{
                    'aria-label': 'upload emoji page background image',
                  }}
                />
              )}
            />
          </Box>
          <Controller
            name="backgroundColor"
            defaultValue={emojiPage.backgroundColor}
            control={control}
            render={({value, onChange}) => (
              <ColorPicker
                label="Background Color"
                color={value}
                onPick={onChange}
                aria-label="emoji page background color"
              />
            )}
          />

          <Box mb={1}>
            <Controller
              name="backgroundOpacity"
              defaultValue={emojiPage.backgroundOpacity}
              control={control}
              render={({value, onChange}) => (
                <Slider
                  valueLabelDisplay="auto"
                  aria-label="emoji page background opacity"
                  value={value}
                  valueLabelFormat={() => <div>{value}</div>}
                  onChange={onChange}
                  step={1}
                  min={0}
                  max={100}
                  unit="%"
                  label="Background Opacity"
                />
              )}
            />
          </Box>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            hoverBackgroundColor="secondary"
            fullWidth
            aria-label="save"
            hasRipple
          >
            Save
          </Button>
        </form>
      </Page>
    </PageBreadcrumbs>
  )
}

const Label = withStyles({
  root: {
    marginBottom: spacing[3],
  },
})(InputLabel)

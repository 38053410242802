import DialogContent from '@material-ui/core/DialogContent'
import Typography from '@material-ui/core/Typography'
import Dialog from 'lib/ui/Dialog'
import {colors} from 'lib/ui/theme'
import Box from '@material-ui/core/Box'
import React from 'react'
import {withStyles} from '@material-ui/core/styles'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import {CheckIcon} from 'lib/ui/Icon'

export default function SuccessDialog(props: {
  showing: boolean
  onClose: () => void
  children: string | string[]
}) {
  return (
    <Dialog open={props.showing} onClose={props.onClose}>
      <DialogContent dividers>
        <Box py={4} px={9}>
          <Typography align="center" variant="h5">
            {props.children}
          </Typography>
        </Box>
        <Box textAlign="center">
          <CheckIcon color={colors.success} iconSize={38} />
        </Box>
      </DialogContent>
      <CenteredActions>
        <Button onClick={props.onClose} color="primary" aria-label="close">
          Close
        </Button>
      </CenteredActions>
    </Dialog>
  )
}

const CenteredActions = withStyles({
  root: {
    justifyContent: 'center',
  },
})(DialogActions)

import styled from 'styled-components'
import React, {useEffect, useRef, useState} from 'react'
import {useLayout} from 'organization/Event/Layout'
import {useHistory, useLocation} from 'react-router-dom'
import IfOwnerHasPlan from 'organization/auth/IfOwnerPlan'
import PageLink from 'organization/Event/Layout/Sidebar/PageLink'
import HasPermission from 'organization/HasPermission'
import PopoverLinks from 'organization/Event/Layout/Sidebar/Section/PopoverLinks'
import OrganizationFeatureToggle from 'organization/OrganizationFeatureToggle'
import FeatureToggle from 'lib/FeatureToggle'

type SectionContextProps = {
  expanded: boolean
  setExpandSection: (expanded: boolean) => void
}

const SectionContext = React.createContext<undefined | SectionContextProps>(
  undefined,
)

export default function Section(props: {
  title: string
  icon: JSX.Element
  children?: React.ReactNode
  route?: string
  newTab?: boolean
}) {
  const {title, icon, children = [], route} = props
  const [sectionExpanded, setExpandSection] = useState(false)
  const {
    sidebarExpanded,
    showingMobileSidebar,
    toggleMobileSidebar,
  } = useLayout()
  const history = useHistory()
  const {pathname} = useLocation()
  const [mouseOverSection, setMouseOverSection] = useState(false)
  const [mouseOverMenu, setMouseOverMenu] = useState(false)

  const containerRef = useRef<HTMLDivElement>(null)

  const links = Array.isArray(children) ? children : [children]
  const linkWithProps = links.filter(hasContent).map(addLinkProps)
  const hasLinks = links.length > 0

  const showingList = sidebarExpanded && sectionExpanded

  const firstLink = findFirstLinkRoute(links)

  // Fix bug where a page nav would prevent mouseLeave from ever being
  // fired, resulting in zombie menus staying open forever.
  useEffect(() => {
    setMouseOverSection(false)
    setMouseOverMenu(false)
  }, [pathname])

  const navToFirstLink = () => {
    if (!firstLink) {
      return
    }

    history.push(firstLink)
  }

  const handleClick = () => {
    if (!route) {
      navToFirstLink()
      return
    }

    // Hide mobile sidebar on nav
    if (showingMobileSidebar) {
      toggleMobileSidebar()
    }

    if (props.newTab) {
      window.open(route, '_blank')
      return
    }

    history.push(route)
  }

  // If a section has a route provided, it may not have any children to
  // set expanded status, so we have it handle it here.
  useEffect(() => {
    if (!route) {
      return
    }

    if (hasLinks) {
      return
    }
    const isactive = route === pathname
    setExpandSection(isactive)
  }, [pathname, route, hasLinks])

  // If the section's links are all hidden, ie no permissions, or wrong
  // plan, then we'll hide the section altogether.
  const isEmpty = links.length > 0 && links.filter(hasContent).length === 0
  if (isEmpty) {
    return null
  }

  const showingPopoverLinks = () => {
    if (!mouseOverSection && !mouseOverMenu) {
      return false
    }

    // Always show hover when sidebar is collapsed
    if (!sidebarExpanded) {
      return true
    }

    // Never show menu if section is already expanded since
    // we'd be showing duplicate links.
    return !sectionExpanded
  }

  // If sidebar is collapsed, but we're currently showing this section,
  // we want to highlight the icon .

  const isCollapsedAndExpanded = !sidebarExpanded && sectionExpanded

  // A section (ie. Zoom Areas) may not have any links, but is instead
  // a root-level link. In those cases we want to highlight the
  // header if we're on the target page.
  const isShowingSection = !hasLinks && sectionExpanded

  const highlight =
    isCollapsedAndExpanded || showingPopoverLinks() || isShowingSection

  return (
    <SectionContext.Provider
      value={{
        expanded: sectionExpanded,
        setExpandSection,
      }}
    >
      <>
        <Box
          showingTitle={sidebarExpanded}
          onMouseEnter={() => setMouseOverSection(true)}
          onMouseLeave={() => setMouseOverSection(false)}
          ref={containerRef}
        >
          <Header
            showingTitle={sidebarExpanded}
            highlight={highlight}
            bold={sectionExpanded}
            clickable={Boolean(route)}
            onClick={handleClick}
          >
            {icon} <span>{title}</span>
          </Header>
          <List showing={showingList}>{linkWithProps}</List>
        </Box>
        <PopoverLinks
          showing={showingPopoverLinks()}
          anchorEl={containerRef.current}
          onMouseEnter={() => setMouseOverMenu(true)}
          onMouseLeave={() => setMouseOverMenu(false)}
        >
          {linkWithProps}
        </PopoverLinks>
      </>
    </SectionContext.Provider>
  )
}

/**
 * Recursively finds the first link route from children components.
 *
 * @param components
 * @returns
 */
function findFirstLinkRoute(components: JSX.Element[]): string | null {
  const first = components.filter(hasContent)[0]
  if (!first) {
    return null
  }

  const {props} = first
  // A page link has the link text as children, so if children is not a string,
  // we'll keep looking inside the component.
  const hasChildren = typeof props.children !== 'string'

  const children = Array.isArray(props.children)
    ? props.children
    : [props.children]

  if (hasChildren) {
    return findFirstLinkRoute(children)
  }

  if (!props.route) {
    return null
  }

  return props.route
}

function addLinkProps(el: JSX.Element, index: number) {
  const props = {
    isFirst: index === 0,
    key: index,
  }

  return React.cloneElement(el, props)
}

/**
 * Checks whether a Section child component renders any content. Takes
 * into account permission, and owner plan checks.
 *
 * @param el
 * @returns
 */
function hasContent(el: JSX.Element | null): boolean {
  if (!el) {
    return false
  }

  const isLink = el.type === PageLink
  if (isLink) {
    return true
  }

  // If the element is a HOC check that could potentially return 'null',
  // we'll call the component's function manually and see what it
  // would return if it were rendered.
  const isPlanCheck = el.type === IfOwnerHasPlan
  const isPermissionsCheck = el.type === HasPermission
  const isOrganizationFeatureToggle = el.type === OrganizationFeatureToggle
  const isFeatureToggle = el.type === FeatureToggle
  if (
    isPlanCheck ||
    isPermissionsCheck ||
    isOrganizationFeatureToggle ||
    isFeatureToggle
  ) {
    const result = el.type(el.props)
    return hasContent(result)
  }

  // Children could also be a fragment
  if (el.type === React.Fragment) {
    return hasContent(el.props.children)
  }

  // If we received multiple elements, we only need one to have
  // content (not null).
  if (Array.isArray(el)) {
    return el.filter(hasContent).length > 0
  }

  throw new Error(
    `Unknown component '${el.type.name}' provided to Section. Allowed Components: IfOwnerHasPlan, HasPermission, PageLink, HasMarketplace, OrganizationFeatureToggle, isFeatureToggle.`,
  )
}

export function useSection() {
  const context = React.useContext(SectionContext)

  if (!context) {
    throw new Error('useSection must be used within Section')
  }

  return context
}

const Box = styled.div<{showingTitle: boolean}>`
  margin-bottom: ${(props) => props.theme.spacing[2]};
`

const Header = styled.div<{
  showingTitle: boolean
  highlight: boolean
  bold: boolean
  clickable: boolean
}>`
  display: flex;
  align-items: center;
  padding-top: ${(props) => props.theme.spacing[2]};
  padding-bottom: ${(props) => props.theme.spacing[2]};
  border-radius: 3px;
  padding-left: ${(props) => (props.showingTitle ? props.theme.spacing[8] : 0)};
  justify-content: ${(props) => (props.showingTitle ? 'start' : 'center')};
  cursor: pointer;

  &:hover {
    background: ${(props) =>
      props.highlight ? props.theme.colors.primary : 'transparent'};
  }

  background: ${(props) =>
    props.highlight ? props.theme.colors.primary : 'transparent'};

  i {
    font-size: 18px;
    width: 18px;
    text-align: center;
    margin-right: ${(props) =>
      props.showingTitle ? props.theme.spacing[3] : 0};
  }

  span {
    display: ${(props) => (props.showingTitle ? 'inline' : 'none')};
    ${(props) => (props.bold ? 'font-weight: bold;' : '')}
  }
`

const List = styled.div<{showing: boolean}>`
  list-style: none;
  display: ${(props) => (props.showing ? 'block' : 'none')};
`

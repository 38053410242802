import React from 'react'
import styled from 'styled-components'
import Grid from '@material-ui/core/Grid'
import Image from 'Event/Speakers/Image'
import {Speaker} from 'Event/Speakers'
import {Draggable} from 'react-beautiful-dnd'
import {useCardsTemplate} from 'Event/template/Cards'
import {useEditMode} from 'Event/EditModeProvider'
import SpeakerConfig, {SpeakerConfigurable} from 'Event/Speakers/SpeakerConfig'
import Typography from '@material-ui/core/Typography'
import {useAttendeeVariables} from 'Event'
import Content from 'lib/ui/form/TextEditor/Content'
import Visible from 'lib/ui/layout/Visible'
import {Column} from 'lib/ui/layout'

type SpeakerProps = {
  id: string
  index: number
  speaker: Speaker
}

export default function Card(props: SpeakerProps) {
  const isEditMode = useEditMode()

  if (!isEditMode) {
    return <SpeakerContent {...props} />
  }
  return (
    <Draggable draggableId={String(props.id)} index={props.index}>
      {(provided) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <SpeakerContent {...props} />
        </div>
      )}
    </Draggable>
  )
}

function SpeakerContent(props: SpeakerProps) {
  const {speaker} = props
  const template = useCardsTemplate()
  const {linkColor, linkUnderline} = template
  const v = useAttendeeVariables()
  const imageSize = template.speakers.imageSize

  const bodySize = (): Column => {
    if (!speaker.image) {
      return 12
    }
    return (12 - imageSize) as Column
  }

  return (
    <Container
      aria-label="speaker"
      linkColor={linkColor}
      linkUnderline={linkUnderline}
    >
      <SpeakerConfigurable>
        <SpeakerConfig speaker={props.speaker} id={props.id} />
        <Grid container spacing={2}>
          <Visible when={Boolean(speaker.image)}>
            <Grid item xs={imageSize}>
              <StyledImage speaker={speaker} />
            </Grid>
          </Visible>
          <GridItem item color={template.textColor} xs={bodySize()}>
            <Box>
              <Typography variant="h5">{v(speaker.name)}</Typography>
            </Box>
            <Content>{v(speaker.text)}</Content>
          </GridItem>
        </Grid>
      </SpeakerConfigurable>
    </Container>
  )
}

const Container = styled.div<{
  linkColor: string
  linkUnderline: boolean
}>`
  margin-bottom: ${(props) => props.theme.spacing[8]};
  a {
    color: ${(props) => props.linkColor};
    text-decoration: none;

    &:hover {
      text-decoration: ${(props) =>
        props.linkUnderline ? 'underline' : 'none'};
    }
  }
`
const GridItem = styled(Grid)<{color: string}>`
  color: ${(props) => props.color};
`

const Box = styled.div`
  position: relative;
`

const StyledImage = styled(Image)`
  margin-bottom: ${(props) => props.theme.spacing[4]};
  overflow: hidden;
  border-radius: 15px;
  img {
    width: 100%;
  }
`

import {useTemplate} from 'Event/TemplateProvider'
import {useSaveTemplate} from 'Event/TemplateUpdateProvider'
import TextField from 'lib/ui/TextField'
import {useConfig} from 'organization/Event/Configurable'
import Config from 'organization/Event/Configurable/Config'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import React from 'react'

export default function DescriptionConfig() {
  const save = useSaveTemplate()
  const {signUp} = useTemplate()

  const {
    form: {register},
  } = useConfig()

  return (
    <Config title="Description" onSave={save}>
      <SettingsPanel>
        <TextField
          name="signUp.description"
          defaultValue={signUp.description}
          label="Description"
          fullWidth
          inputProps={{
            'aria-label': 'set form description',
            ref: register,
          }}
        />
      </SettingsPanel>
    </Config>
  )
}

import {onChangeCheckedHandler} from 'lib/dom'
import Switch from 'lib/ui/form/Switch'
import React from 'react'
import {Controller, UseFormMethods} from 'react-hook-form'
import {Cards} from 'Event/template/Cards'
import Title from 'lib/ui/ConfigPanel/Title'
import ImageAssetUploader from 'lib/asset/ImageAssetUploader'

export default function Settings(props: {
  control: UseFormMethods['control']
  sidebar: Cards['sidebar']
}) {
  const {control, sidebar} = props

  return (
    <>
      <Controller
        name="isVisible"
        defaultValue={sidebar.isVisible || false}
        control={control}
        render={({value, onChange}) => (
          <Switch
            checked={value}
            onChange={onChangeCheckedHandler(onChange)}
            arial-label="config visible switch"
            labelPlacement="end"
            label={value ? 'Enabled' : 'Disabled'}
          />
        )}
      />
      <Title>Background Image</Title>
      <Controller
        name="backgroundImage"
        control={control}
        defaultValue={sidebar.backgroundImage}
        render={({onChange, value}) => (
          <ImageAssetUploader
            onChange={onChange}
            value={value}
            uploadLabel="Upload"
            canResize
            removeButtonProps={{
              'aria-label': 'remove sidebar background',
            }}
            uploadInputProps={{
              'aria-label': 'upload sidebar background',
            }}
            disableAutoRemove
          />
        )}
      />
    </>
  )
}

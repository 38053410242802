import Subheading from 'lib/ui/typography/Subheading'
import TransactionsTable from 'obvio/Billing/TransactionSection/TransactionsTable'
import {useTransactions} from 'obvio/Billing/TransactionSection/list-transactions'
import {Section} from 'organization/AddOns'
import {useOrganization} from 'organization/OrganizationProvider'
import React from 'react'

export default function TransactionSection() {
  const {organization} = useOrganization()
  const {transactions, goToPage, markComplete, paginated} = useTransactions(
    `/organizations/${organization.id}/transactions`,
  )

  return (
    <>
      <Subheading>Transactions</Subheading>
      <Section>
        <TransactionsTable
          transactions={transactions}
          goToPage={goToPage}
          markComplete={markComplete}
          paginated={paginated}
        />
      </Section>
    </>
  )
}

import React, {useCallback, useState} from 'react'
import grey from '@material-ui/core/colors/grey'
import styled from 'styled-components'
import SignaturePad from 'signature_pad'
import IconButton from 'lib/ui/IconButton'
import ClearIcon from '@material-ui/icons/Clear'
import TypeInput from 'lib/ui/form/Signature/Controls/TypeInput'

type ControlsProps = {
  value: null | string
  canvas: HTMLCanvasElement | null
  pad: SignaturePad | null
  onUpdate: (data: string | null) => void
  typeButtonText: string
  typeButtonColor: string
  typeInputLabel: string
  name: string
}

export default function Controls(props: ControlsProps) {
  if (!props.canvas || !props.pad) {
    return null
  }

  return <InputsContent {...props} canvas={props.canvas} pad={props.pad} />
}

function InputsContent(
  props: Omit<ControlsProps, 'canvas' | 'pad'> & {
    canvas: HTMLCanvasElement
    pad: SignaturePad
  },
) {
  const {canvas, pad, onUpdate, name} = props
  const [isTypeMode, setIsTypeMode] = useState(false)
  const canClear = Boolean(props.value)

  const clear = useCallback(() => {
    pad.clear()
    setIsTypeMode(false) // Default to click to sign
    onUpdate(null)
  }, [onUpdate, pad])

  const switchToTypeMode = () => setIsTypeMode(true)

  return (
    <>
      <ClearButton showing={canClear} clear={clear} />
      <TypeInput
        showing={isTypeMode}
        onClickToType={switchToTypeMode}
        buttonText={props.typeButtonText}
        buttonColor={props.typeButtonColor}
        label={props.typeInputLabel}
        canvas={canvas}
        pad={pad}
        name={name}
        onUpdate={onUpdate}
      />
    </>
  )
}

function ClearButton(props: {showing: boolean; clear: () => void}) {
  if (!props.showing) {
    return null
  }
  return (
    <StyledIconButton onClick={props.clear}>
      <ClearIcon htmlColor={grey[500]} />
    </StyledIconButton>
  )
}

const StyledIconButton = styled(IconButton)`
  position: absolute;
  top: 5px;
  right: 5px;
`

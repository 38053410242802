import React from 'react'
import FaqList from 'Event/template/NiftyFifty/Dashboard/Faqs/FaqList'
import {
  DEFAULT_DESCRIPTION,
  DEFAULT_TITLE,
} from 'Event/template/NiftyFifty/Dashboard/Faqs/FaqList/Card'
import {PageTitle, PageDescription} from 'Event/template/NiftyFifty/Page'
import {useAttendeeVariables} from 'Event'
import {useNiftyFiftyTemplate} from 'Event/template/NiftyFifty'
import Content from 'lib/ui/form/TextEditor/Content'
import Configurable from 'organization/Event/Configurable'
import AddFaqButton from 'Event/FAQs/AddFaqButton'
import TitleConfig from 'Event/FAQs/TitleConfig'
import DescriptionConfig from 'Event/FAQs/DescriptionConfig'

export default function NiftyFiftyFaqPage() {
  const template = useNiftyFiftyTemplate()
  const {faq: pageSettings} = template
  const v = useAttendeeVariables()

  return (
    <>
      <AddFaqButton />
      <Configurable>
        <TitleConfig />
        <PageTitle
          textColor={template.textColor}
          pageTitleColor={template.pageTitleColor}
          aria-label="faqs title"
          extraPadding
        >
          {v(pageSettings?.title || DEFAULT_TITLE)}
        </PageTitle>
      </Configurable>
      <Configurable>
        <DescriptionConfig />
        <PageDescription color={template.textColor} extraPadding>
          <Content aria-label="description">
            {v(pageSettings?.description || DEFAULT_DESCRIPTION)}
          </Content>
        </PageDescription>
      </Configurable>
      <FaqList />
    </>
  )
}

import React from 'react'
import Slider from '@material-ui/core/Slider'
import {handleChangeSlider} from 'lib/dom'
import {
  PlanAddOns,
  PlanInfo,
  ADDON_KEY_ZOOM_MEETINGS,
} from 'obvio/Billing/plans'

/**
 * Min/Max number of blocks a user can purchase at a single time. This is only a
 * frontend limit, and not a real limitation.
 */
export const MIN_NUM_BLOCKS = 1
export const MAX_NUM_BLOCKS = 20

export default function AddOnBlockSlider(props: {
  value: number
  addOnKey: string
  onChange: (blocks: number) => void
  plan: PlanInfo
}) {
  const {addOnKey, plan} = props

  const block = plan.addOns[addOnKey as keyof PlanAddOns].block

  const markOptions =
    addOnKey === ADDON_KEY_ZOOM_MEETINGS ? [1, 5, 10] : [1, 3, 6, 10]
  const markLabel = addOnKey === ADDON_KEY_ZOOM_MEETINGS ? 'meeting' : 'block'

  const marks = markOptions.map((size) => {
    const grammar = size > 1 ? 's' : ''

    const label =
      block === 1
        ? `${size} ${markLabel}${grammar}`
        : `${size} ${markLabel}${grammar} / ${block * size}`

    return {
      value: size,
      label: label,
    }
  })

  return (
    <Slider
      min={MIN_NUM_BLOCKS}
      max={MAX_NUM_BLOCKS}
      value={props.value}
      marks={marks}
      step={1}
      valueLabelDisplay="off"
      onChange={handleChangeSlider(props.onChange)}
    />
  )
}

import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import CustomThemeEditing from './customthemeediting';
import CustomThemeUI from './customthemeui';

export default class CustomTheme extends Plugin {
	static get requires() {
		return [ CustomThemeEditing, CustomThemeUI ];
	}

	static get pluginName() {
		return 'CustomTheme';
	}
}

import {BlogPost} from 'Event/Dashboard/components/BlogPosts'
import styled from 'styled-components'
import React from 'react'
import {useTemplate} from 'Event/TemplateProvider'
import {useAttendeeVariables} from 'Event'
import {Font} from 'lib/FontSelect'
import {BOLD, ITALIC, CAPS} from 'lib/ui/typography/FontStyleInput'

export default function Title(props: {post: BlogPost}) {
  const {post} = props
  const {postStyles: styles} = useTemplate()
  const v = useAttendeeVariables()

  return (
    <TitleText
      textColor={styles.titleTextColor}
      fontSize={styles.titleFontSize}
      font={styles.titleFont}
    >
      {v(post.title)}
    </TitleText>
  )
}

const TitleText = styled.h2<{
  textColor: string | null
  fontSize: number
  font: Font | null
}>`
  font-weight: ${(props) =>
    props.font?.styles?.includes(BOLD) ? 'bold' : 'normal'};
  font-family: ${(props) => (props.font ? props.font.family : 'inherit')};
  font-style: ${(props) =>
    props.font?.styles?.includes(ITALIC) ? 'italic' : 'normal'};
  text-transform: ${(props) =>
    props.font && !props.font?.styles?.includes(CAPS) ? 'none' : 'uppercase'};
  color: ${(props) =>
    props.textColor ? props.textColor : 'inherit'} !important;
  margin: 0;
  font-size: ${(props) => props.fontSize}px;
`

import {
  BaseIntegration,
  HUBSPOT,
  useServices,
} from 'organization/Event/Services/ServicesProvider'
import React from 'react'
import {useQueryParams} from 'lib/url'
import AuthPage from 'organization/Event/Services/Apps/Hubspot/AuthPage'
import Config from 'organization/Event/Services/Apps/Hubspot/Config'
import LinkPage from 'organization/Event/Services/Apps/Hubspot/LinkPage'
import {Property} from 'organization/Event/Services/Apps/Hubspot/Config/HubspotPropertiesProvider'

export type HubspotIntegration = BaseIntegration & {
  service: typeof HUBSPOT
  has_completed_setup: boolean
  sync_required: boolean
  login_property_name: string
  login_property_label: string
  login_property_type: Property['type'] | null

  import_list_id: number
  import_list_name: string

  tags_property_name: string
  tags_property_label: string
  tags_property_type: Property['type'] | null

  phone_property_name: string
  phone_property_label: string
  phone_property_type: Property['type'] | null

  portal_id: string
  tags: HubspotPlatformTag[]
  groups: HubspotGroup[]
}

export interface HubspotGroup {
  property_name: string
  property_label: string
  property_type: Property['type']
  key: string | null
}

export interface HubspotPlatformTag {
  id: number
  property_label: string | null
  property_name: string | null
  property_type: Property['type'] | null
  property_value: string | null
  type: TagType
}

/**
 * Tag types as defined in API's HubspotTagType class
 */

export const ATTENDEE_CREATED = 'attendee_created'
export const ATTENDEE_SIGNED_WAIVER = 'attendee_signed_waiver'
export const ATTENDEE_CHECKED_IN = 'attendee_checked_in'
export const FORM_SUBMITTED = 'form_submitted'

export type TagType =
  | typeof ATTENDEE_CREATED
  | typeof ATTENDEE_CHECKED_IN
  | typeof ATTENDEE_SIGNED_WAIVER
  | typeof FORM_SUBMITTED

export default function Hubspot() {
  const {isLinked} = useServices()
  const showConfig = isLinked(HUBSPOT)

  /**
   * HubSpot redirects back with an auth code
   * in a URL param named 'code'
   */
  const {code} = useQueryParams()

  if (showConfig) {
    return <Config />
  }

  if (code) {
    return <AuthPage authCode={code} />
  }

  return <LinkPage />
}

import React, {useState} from 'react'
import Button from 'lib/ui/Button'
import {api, routesWithValue} from 'lib/url'
import {useEvent} from 'Event/EventProvider'
import {useIsMounted} from 'lib/dom'
import {useRoomControls} from 'Event/Dashboard/TeamAccessControls/RoomControls'
import {eventRoutes} from 'Event/Routes'

export default function StartRoomButton(props: {children: string}) {
  const {room} = useRoomControls()
  const [isTogglingRoom, setIsTogglingRoom] = useState(false)
  const {client} = useEvent()
  const isMounted = useIsMounted()

  const roomRoutes = routesWithValue(
    ':room',
    String(room.id),
    eventRoutes.room[':room'],
  )

  const canStart = !isTogglingRoom

  const launchRoom = (event?: React.MouseEvent) => {
    if (isTogglingRoom) {
      return
    }

    const teamJoinUrl = event?.shiftKey
      ? `${roomRoutes.team_join}?start_url=true`
      : roomRoutes.team_join

    if (room.is_online) {
      window.open(teamJoinUrl, '_blank')?.focus()
      return
    }

    setIsTogglingRoom(true)

    const startRoomURL = api(`/rooms/${room.id}/start`)
    client
      .patch(startRoomURL)
      .then(() => {
        if (!isMounted.current) {
          return
        }

        setIsTogglingRoom(false)
        window.open(teamJoinUrl, '_blank')?.focus()
      })
      .catch(() => {
        setIsTogglingRoom(false)
      })
  }

  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        fullWidth={true}
        aria-label="start room"
        onClick={launchRoom}
        disabled={!canStart}
      >
        {props.children}
      </Button>
    </div>
  )
}

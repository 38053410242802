import React from 'react'
import Switch from 'lib/ui/form/Switch'
import {onChangeCheckedHandler} from 'lib/dom'
import {
  useHighLevel,
  useServices,
} from 'organization/Event/Services/ServicesProvider'
import {useEvent} from 'Event/EventProvider'
import {useOrganization} from 'organization/OrganizationProvider'
import {useToggle} from 'lib/toggle'
import {api} from 'lib/url'
import {HighLevelIntegration} from 'organization/Event/Services/Apps/HighLevel'

export default function SyncSwitch() {
  const {changeSync, processing} = useHighLevelSync()
  const highLevel = useHighLevel()
  const {event} = useEvent()
  return (
    <Switch
      checked={highLevel.sync_required}
      onChange={onChangeCheckedHandler(changeSync)}
      disabled={processing || event.has_ended}
      aria-label="toggle sync"
      labelPlacement="end"
      name="name"
      label="Sync attendees"
    />
  )
}

export function useHighLevelSync() {
  const service = useServices()
  const {event} = useEvent()
  const {client} = useOrganization()
  const {flag: processing, toggle: toggleProcessing} = useToggle()

  const changeSync = (sync: boolean) => {
    if (processing) {
      return
    }

    const request = sync ? client.put : client.delete

    toggleProcessing()
    const url = api(`/events/${event.id}/integrations/high_level/sync`)
    request<HighLevelIntegration>(url)
      .then(service.update)
      .finally(toggleProcessing)
  }

  return {
    changeSync,
    processing,
  }
}

import ColorPicker from 'lib/ui/ColorPicker'
import {Label} from 'lib/ui/typography'
import React from 'react'
import {Controller, UseFormMethods} from 'react-hook-form'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import {onUnknownChangeHandler} from 'lib/dom'
import {Cards} from 'Event/template/Cards'
import Title from 'lib/ui/ConfigPanel/Title'
import Divider from 'lib/ui/ConfigPanel/Divider'
import Slider from 'lib/ui/Slider'

const MIN_SIDEBAR_PADDING_TOP = 0
const MAX_SIDEBAR_PADDING_TOP = 720

const MIN_SIDEBAR_BORDER_WIDTH = 0
const MAX_SIDEBAR_BORDER_WIDTH = 50

const MIN_SIDEBAR_BORDER_RADIUS = 0
const MAX_SIDEBAR_BORDER_RADIUS = 25

const MIN_SIDEBAR_HEAD_BORDER_RADIUS = 0
const MAX_SIDEBAR_HEAD_BORDER_RADIUS = 25

const MIN_SIDEBAR_ITEM_SPACE = 0
const MAX_SIDEBAR_ITEM_SPACE = 200

export default function Styling(props: {
  control: UseFormMethods['control']
  sidebar: Cards['sidebar']
  textColor: string
}) {
  const {control, sidebar, textColor} = props
  const sidebarTextColor = sidebar.textColor ? sidebar.textColor : textColor

  return (
    <>
      <Controller
        name="paddingTop"
        defaultValue={sidebar.paddingTop || 48}
        control={control}
        render={({value, onChange}) => (
          <Slider
            min={MIN_SIDEBAR_PADDING_TOP}
            max={MAX_SIDEBAR_PADDING_TOP}
            step={4}
            onChange={onChange}
            valueLabelDisplay="auto"
            value={value}
            aria-label="padding top"
            label="Top Padding"
          />
        )}
      />
      <Divider />
      <Controller
        name="itemSpacing"
        defaultValue={sidebar.itemSpacing}
        control={control}
        render={({value, onChange}) => (
          <Slider
            min={MIN_SIDEBAR_ITEM_SPACE}
            max={MAX_SIDEBAR_ITEM_SPACE}
            step={1}
            onChange={onChange}
            valueLabelDisplay="auto"
            value={value}
            aria-label="item spacing"
            label="Space Between Items"
          />
        )}
      />
      <Divider />
      <Title>Seperator</Title>
      <Controller
        name="separatorColor"
        defaultValue={sidebar.separatorColor}
        control={control}
        render={({value, onChange}) => (
          <ColorPicker
            label="Color"
            color={value}
            onPick={onChange}
            aria-label="separator color"
          />
        )}
      />

      <Controller
        name="separatorThickness"
        defaultValue={sidebar.separatorThickness}
        control={control}
        render={({value, onChange}) => (
          <Slider
            min={1}
            max={10}
            step={1}
            onChange={onChange}
            valueLabelDisplay="auto"
            value={value}
            aria-label="separator width"
            label="Thickness"
          />
        )}
      />

      <FormControl fullWidth>
        <Label>Style</Label>
        <Controller
          name="separatorStyle"
          defaultValue={sidebar.separatorStyle}
          control={control}
          render={({value, onChange}) => (
            <Select
              value={value}
              onChange={onUnknownChangeHandler(onChange)}
              label="Separator Style"
            >
              <MenuItem value="solid">Solid</MenuItem>
              <MenuItem value="dashed">Dashed</MenuItem>
              <MenuItem value="dotted">Dotted</MenuItem>
              <MenuItem value="none">None</MenuItem>
            </Select>
          )}
        />
      </FormControl>
      <Divider />
      <Title>Background</Title>
      <Controller
        name="background"
        defaultValue={sidebar.background}
        control={control}
        render={({value, onChange}) => (
          <ColorPicker
            label="Color"
            color={value}
            onPick={onChange}
            aria-label="background color"
          />
        )}
      />
      <Divider />
      <Title>Typography</Title>
      <Controller
        name="textColor"
        defaultValue={sidebarTextColor}
        control={control}
        render={({value, onChange}) => (
          <ColorPicker
            label="Color"
            color={value}
            onPick={onChange}
            aria-label="color"
          />
        )}
      />
      <Divider />

      <Title>Head</Title>
      <Controller
        name="headTextColor"
        defaultValue={sidebar.headTextColor}
        control={control}
        render={({value, onChange}) => (
          <ColorPicker
            label="Text Color"
            color={value}
            onPick={onChange}
            aria-label="head text color"
          />
        )}
      />

      <Controller
        name="headBackgroundColor"
        defaultValue={sidebar.headBackgroundColor}
        control={control}
        render={({value, onChange}) => (
          <ColorPicker
            label="Background Color"
            color={value}
            onPick={onChange}
            aria-label="color"
          />
        )}
      />

      <Controller
        name="headBackgroundBorder"
        defaultValue={sidebar.headBackgroundBorder}
        control={control}
        render={({value, onChange}) => (
          <Slider
            min={MIN_SIDEBAR_HEAD_BORDER_RADIUS}
            max={MAX_SIDEBAR_HEAD_BORDER_RADIUS}
            step={1}
            onChange={onChange}
            valueLabelDisplay="auto"
            value={value}
            aria-label="header background border"
            label="Background Border"
          />
        )}
      />
      <Divider />

      <Title>Border</Title>
      <Controller
        name="borderWidth"
        defaultValue={sidebar.borderWidth}
        control={control}
        render={({value, onChange}) => (
          <Slider
            min={MIN_SIDEBAR_BORDER_WIDTH}
            max={MAX_SIDEBAR_BORDER_WIDTH}
            step={1}
            onChange={onChange}
            valueLabelDisplay="auto"
            value={value}
            aria-label="border thickness"
            label="Thickness"
          />
        )}
      />
      <Controller
        name="borderColor"
        defaultValue={sidebar.borderColor}
        control={control}
        render={({value, onChange}) => (
          <ColorPicker label="Color" color={value} onPick={onChange} />
        )}
      />
      <Controller
        name="borderRadius"
        defaultValue={sidebar.borderRadius}
        control={control}
        render={({value, onChange}) => (
          <Slider
            min={MIN_SIDEBAR_BORDER_RADIUS}
            max={MAX_SIDEBAR_BORDER_RADIUS}
            step={1}
            onChange={onChange}
            valueLabelDisplay="auto"
            value={value}
            aria-label="border radius"
            label="Radius"
          />
        )}
      />
    </>
  )
}

import {BlockBase} from './base'
import styled from 'styled-components'

const VisibleContent = styled.div<{
  visibility: BlockBase['visibility']
  isMobileMode?: boolean
}>`
  display: ${(props) =>
    props.visibility === 'desktop_only' ? 'none' : 'flex'};

  @media (min-width: ${(props) => props.theme.breakpoints.lg}) {
    display: ${(props) =>
      (props.visibility === 'mobile_only' && !props.isMobileMode) ||
      (props.visibility === 'desktop_only' && props.isMobileMode)
        ? 'none'
        : 'flex'};
  }
`

export default VisibleContent

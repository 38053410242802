import {TemplateStep2} from 'Event/Step2'
import styled from 'styled-components'
import {useAddWaiver} from 'Event/Step2/Waivers'
import {useTemplate} from 'Event/TemplateProvider'
import {useObvioUser} from 'obvio/auth'
import {ENTERPRISE} from 'obvio/Billing/plans'
import IfOwnerHasPlan from 'organization/auth/IfOwnerPlan'
import Page from 'organization/Event/TemplateEditor/Page'
import AdditionalWaiversButton from 'organization/Event/WaiverConfig/AdditionalWaiversButton'
import ToggleWaiverSwitch from 'organization/Event/WaiverConfig/ToggleWaiversSwitch'
import React, {useEffect} from 'react'
import {ConfigButton} from 'organization/Event/Configurable'
import Button from 'lib/ui/Button'
import RulesConfig from 'organization/Event/WaiverConfig/RulesConfig'
import {CheckInBreadcrumbs} from 'organization/Event/Page/PageBreadcrumbs'

export default function WaiverConfig() {
  const user = useObvioUser()

  useAutoCreateFirstWaiver()

  return (
    <CheckInBreadcrumbs page="Waiver">
      <Page
        toolbarItems={
          <>
            <ToggleWaiverSwitch />
            <SkipWaiverRulesButton />
            <IfOwnerHasPlan plan={ENTERPRISE}>
              <AdditionalWaiversButton />
            </IfOwnerHasPlan>
          </>
        }
      >
        <TemplateStep2 user={user} />
      </Page>
    </CheckInBreadcrumbs>
  )
}

/**
 * To avoid the user having to create their first waiver manually, we'll
 * create one here if one doesn't exist within the template.
 */
function useAutoCreateFirstWaiver() {
  const {
    waiver: {targets},
  } = useTemplate()
  const addWaiver = useAddWaiver()

  const hasWaivers = Object.keys(targets).length > 0

  useEffect(() => {
    if (hasWaivers) {
      return
    }

    addWaiver()
  }, [hasWaivers, addWaiver])
}

function SkipWaiverRulesButton() {
  const {waiver} = useTemplate()

  const isWaiverEnabled = waiver.isEnabled

  return (
    <ConfigButton config={<RulesConfig />}>
      {(onToggle) => (
        <StyledWaiverRulesButton
          size="small"
          type="button"
          variant="text"
          onClick={onToggle}
          disabled={!isWaiverEnabled}
        >
          Skip Waiver Rules
        </StyledWaiverRulesButton>
      )}
    </ConfigButton>
  )
}

const StyledWaiverRulesButton = styled(Button)`
  margin-right: ${(props) => props.theme.spacing[4]};
`

import { findOptimalInsertionPosition, isWidget, toWidget } from '@ckeditor/ckeditor5-widget/src/utils';
import BalloonPanelView from '@ckeditor/ckeditor5-ui/src/panel/balloon/balloonpanelview';

export const DEFAULT_FONT_SIZE = 23;
export const DEFAULT_BUTTON_COLOR = '#000000';
export const DEFAULT_BUTTON_BACKGROUND_COLOR = '#ffffff';
export const DEFAULT_BUTTON_ALIGNMENT = 'unset';
export const DEFAULT_BUTTON_TEXT = 'New Button';
export const FONT_SIZE_LIST = [ 9, 11, 13, 15, 17, 19, 21, 23, 25, 27, 29 ];

export function toCustomButtonWidget( viewElement, writer, label = 'obvio-custom-button' ) {
	writer.setCustomProperty( 'customButton', true, viewElement );

	return toWidget( viewElement, writer, { label } );
}

export function isCustomButtonWidget( viewElement ) {
	return !!viewElement && viewElement.getCustomProperty( 'customButton' ) && isWidget( viewElement );
}

export function getSelectedCustomButtonWidget( selection ) {
	const viewElement = selection.getSelectedElement();

	if ( viewElement && isCustomButtonWidget( viewElement ) ) {
		return viewElement;
	}

	return null;
}

export function isCustomButton( modelElement ) {
	return !!modelElement && modelElement.is( 'customButton' );
}

export function isCustomButtonElement( modelElement ) {
	return !!modelElement && modelElement.name === 'customButton';
}

export function insertCustomButton( writer, model, attributes = {} ) {
	const customButtonElement = writer.createElement( 'customButton', attributes );

	const insertAtSelection = findOptimalInsertionPosition( model.document.selection, model );

	model.insertContent( customButtonElement, insertAtSelection );

	if ( customButtonElement.parent ) {
		writer.setSelection( customButtonElement, 'on' );
	}
}

export function isCustomButtonAllowed( model ) {
	const schema = model.schema;
	const selection = model.document.selection;

	return isCustomButtonAllowedInParent( selection, schema, model ) &&
         !checkSelectionOnObject( selection, schema ) &&
         isInOtherCustomButton( selection );
}

// @returns {Boolean}
function isCustomButtonAllowedInParent( selection, schema, model ) {
	const parent = getInsertCustomButtonParent( selection, model );

	return schema.checkChild( parent, 'customButton' );
}

function checkSelectionOnObject( selection, schema ) {
	const selectedElement = selection.getSelectedElement();

	return selectedElement && schema.isObject( selectedElement );
}

function isInOtherCustomButton( selection ) {
	return [ ...selection.focus.getAncestors() ].every( ancestor => !ancestor.is( 'customButton' ) );
}

function getInsertCustomButtonParent( selection, model ) {
	const insertAt = findOptimalInsertionPosition( selection, model );

	const parent = insertAt.parent;

	if ( parent.isEmpty && !parent.is( '$root' ) ) {
		return parent.parent;
	}

	return parent;
}

export function repositionContextualBalloon( editor ) {
	const balloon = editor.plugins.get( 'ContextualBalloon' );

	if ( getSelectedCustomButtonWidget( editor.editing.view.document.selection ) ) {
		const position = getBalloonPositionData( editor );

		balloon.updatePosition( position );
	}
}

export function getBalloonPositionData( editor ) {
	const editingView = editor.editing.view;
	const defaultPositions = BalloonPanelView.defaultPositions;

	return {
		target: editingView.domConverter.viewToDom( editingView.document.selection.getSelectedElement() ),
		positions: [
			defaultPositions.northArrowSouth,
			defaultPositions.northArrowSouthWest,
			defaultPositions.northArrowSouthEast,
			defaultPositions.southArrowNorth,
			defaultPositions.southArrowNorthWest,
			defaultPositions.southArrowNorthEast
		]
	};
}

import {useEvent} from 'Event/EventProvider'
import styled from 'styled-components'
import React from 'react'
import {useTemplate} from 'Event/TemplateProvider'
import defaultLogo from 'assets/images/logo_vertical.png'
import Configurable from 'organization/Event/Configurable'
import LogoConfig from 'Event/auth/Login/Logo/LogoConfig'

export default function Logo(props: {className?: string}) {
  const {event} = useEvent()
  const {login} = useTemplate()

  const logo = login.logo || defaultLogo
  const size = login.logoSize

  return (
    <Container className={props.className}>
      <Box size={size}>
        <Configurable>
          <LogoConfig />
          <Image
            isEnabled={login.logoEnabled}
            src={logo}
            alt={event.name}
            aria-label="login logo"
          />
        </Configurable>
      </Box>
    </Container>
  )
}

const Box = styled.div<{size: number}>`
  display: block;
  margin: auto;
  margin-bottom: ${(props) => props.theme.spacing[6]};
  width: ${(props) => props.size}%;
  text-align: center;
  padding: ${(props) => props.theme.spacing[4]};
`

export const Image = styled.img<{isEnabled?: boolean}>`
  display: ${(props) => (props.isEnabled ? 'inline' : 'none')};
  max-width: 100%;
`

const Container = styled.div`
  width: 100%;
`

import React, {useState} from 'react'
import ComponentConfig, {
  ComponentConfigProps,
  SaveButton,
  Footer,
} from 'organization/Event/DashboardConfig/ComponentConfigPanel'
import {useForm} from 'react-hook-form'
import {PointsSummaryProps} from 'Event/template/Cards/Dashboard/Sidebar/SidebarItem/PointsSummary'
import {
  useEditSidebarItem,
  useAutoUpdateSidebarItem,
} from 'Event/template/Cards/Dashboard/Sidebar/SidebarItem'
import {Rule} from 'Event/attendee-rules'
import Settings from 'Event/template/Cards/Dashboard/Sidebar/SidebarItem/PointsSummary/PointsSummaryConfig/Settings'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import RulesPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/RulesPanel'
import Styling from 'Event/template/Cards/Dashboard/Sidebar/SidebarItem/PointsSummary/PointsSummaryConfig/Styling'
import StylingPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/StylingPanel'

export function PointsSummaryConfig(
  props: ComponentConfigProps & {
    points: PointsSummaryProps
  },
) {
  const {showing, onClose, points} = props
  const {handleSubmit, control, watch, formState} = useForm()
  const {update} = useEditSidebarItem()
  const [rules, setRules] = useState<Rule[]>(points.rules || [])

  const save = (data: PointsSummaryProps) => {
    update({...data, rules})
    onClose()
  }

  useAutoUpdateSidebarItem({
    item: watch(),
    when: showing,
  })

  return (
    <ComponentConfig
      title="Points"
      showing={showing}
      onClose={onClose}
      onSubmit={handleSubmit(save)}
      hasChanges={formState.isDirty}
    >
      <SettingsPanel>
        <Settings control={control} points={points} />
      </SettingsPanel>
      <StylingPanel>
        <Styling control={control} points={points} />
      </StylingPanel>
      <RulesPanel rules={rules} setRules={setRules} />
      <Footer>
        <SaveButton type="submit" />
      </Footer>
    </ComponentConfig>
  )
}

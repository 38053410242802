import React, {useState} from 'react'
import InputAdornment from '@material-ui/core/InputAdornment'
import TextField from '@material-ui/core/TextField'
import CopyIconButton from 'lib/ui/IconButton/CopyIconButton'
import styled from 'styled-components'
import IfOwnerHasPlan from 'organization/auth/IfOwnerPlan'
import {ENTERPRISE} from 'obvio/Billing/plans'
import {Tooltip} from '@material-ui/core'
import {LinkIcon} from 'lib/ui/Icon'
import RedirectsAddDialog from 'organization/Event/Redirects/AddDialog'
import {useToggleArray} from 'lib/toggle'
import IconButton from 'lib/ui/IconButton'
import grey from '@material-ui/core/colors/grey'
import InfoAlert from 'lib/ui/alerts/InfoAlert'
import AreaRegistrationConfig from 'organization/Event/Area/AreaRegistrationConfig'
import EditIconButton from 'lib/ui/IconButton/EditIconButton'
import {Area} from 'organization/Event/AreasProvider'

export default function AreaRegistrationURL(props: {area: Area}) {
  const [successMessage, setSuccessMessage] = useState<string | null>(null)
  const onCloseSuccess = () => setSuccessMessage(null)
  const {area} = props

  const registrationUrl = area.registration_url

  if (!registrationUrl) {
    return null
  }

  const copy = () => {
    navigator.clipboard.writeText(registrationUrl)
  }

  return (
    <>
      <Alert onClose={onCloseSuccess}>{successMessage}</Alert>
      <TextField
        value={registrationUrl}
        fullWidth
        variant="outlined"
        label="Registration URL"
        inputProps={{
          'aria-label': 'registration url',
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <CopyIconButton
                aria-label="copy registration url"
                onClick={copy}
              />
              <IfOwnerHasPlan plan={ENTERPRISE}>
                <AddRedirectLinkButton
                  setSuccessMessage={setSuccessMessage}
                  registrationUrl={registrationUrl}
                  areaName={area.name}
                />
              </IfOwnerHasPlan>
              <RegistrationConfig />
            </InputAdornment>
          ),
        }}
        helperText="Anyone with this URL will be able to join this room directly as an attendee."
      />
    </>
  )
}

function RegistrationConfig() {
  const [showing, toggleConfig] = useToggleArray()

  return (
    <>
      <AreaRegistrationConfig showing={showing} onClose={toggleConfig} />
      <Tooltip title={'Edit registration config'} placement="bottom">
        <EditIconButton
          aria-label="edit registration config"
          onClick={toggleConfig}
          color={grey[500]}
        />
      </Tooltip>
    </>
  )
}

function AddRedirectLinkButton(props: {
  setSuccessMessage: (message: string) => void
  registrationUrl: string
  areaName: string
}) {
  const [openRedirect, toggleOpenRedirect] = useToggleArray()
  const {areaName, setSuccessMessage, registrationUrl} = props

  const onRedirectAdded = () => {
    setSuccessMessage('The redirect URL has been copied to your clipboard.')
  }

  return (
    <>
      <RedirectsAddDialog
        isOpen={openRedirect}
        onClose={toggleOpenRedirect}
        onAdd={onRedirectAdded}
        description={`${areaName} Room Registration Link`}
        destination={registrationUrl}
        copyToClipboard
      />
      <Tooltip title={'Create Redirect Link'} placement="bottom">
        <StyledIconButton
          onClick={toggleOpenRedirect}
          aria-label="create redirect link"
        >
          <LinkIcon iconSize={20} />
        </StyledIconButton>
      </Tooltip>
    </>
  )
}

const StyledIconButton = styled(IconButton)`
  color: ${grey[500]};
  margin-left: ${(props) => props.theme.spacing[1]};
`

const Alert = styled(InfoAlert)`
  margin-bottom: ${(props) => props.theme.spacing[6]};
`

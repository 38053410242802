import React from 'react'
import MenuItem from '@material-ui/core/MenuItem'
import {EVENT_SETTINGS, usePermissions} from 'organization/PermissionsProvider'
import {MenuItemActionProps} from 'organization/Event/AttendeeManagement/AttendeeTools'
import {useAttendees} from 'organization/Event/AttendeesProvider'
import ConfirmDialog from 'lib/ui/ConfirmDialog'
import {useToggle} from 'lib/toggle'

export default function ClearAttendeesMenuItem(
  props: MenuItemActionProps & {onClick: () => void; disabled?: boolean},
) {
  const {can} = usePermissions()

  if (!can(EVENT_SETTINGS)) {
    return null
  }

  return (
    <ConfirmDialog
      description="This will irreversibly remove all attendees from your event. The process may take up to 20 minutes to complete."
      onConfirm={props.onClick}
    >
      {(confirm) => (
        <MenuItem onClick={confirm} disabled={props.disabled}>
          Clear Attendees
        </MenuItem>
      )}
    </ConfirmDialog>
  )
}

export function useClearAttendees(onSuccess: (message: string | null) => void) {
  const {flag: processing, toggle: toggleProcessing} = useToggle()
  const {clearAttendees, clearError} = useAttendees()

  const process = () => {
    if (processing) {
      return
    }

    toggleProcessing()
    onSuccess(null)
    clearError()

    clearAttendees()
      .then((res) => {
        onSuccess(res.message)
      })
      .finally(toggleProcessing)
  }

  return {
    processing,
    clearAttendees: process,
  }
}

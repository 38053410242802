import {PaymentMethod} from '@stripe/stripe-js'
import {api} from 'lib/url'
import {teamMemberClient} from 'obvio/obvio-client'
import {useMutation, useQueryClient} from 'react-query'

export function useRemovePaymentMethod() {
  const qc = useQueryClient()

  return useMutation({
    mutationFn: (paymentMethodId: string) => {
      const url = api(`/stripe/payment_methods/${paymentMethodId}`)
      return teamMemberClient.delete<PaymentMethod>(url)
    },
    onSuccess: () => {
      // refetch
      qc.invalidateQueries({
        queryKey: ['stripe', 'payment_methods'],
        exact: true,
      })
    },
  })
}

export interface OntraportField {
  field_id: string
  field_name: string
  field_type: string
  field_key: string
}

export const ontraportEmptyField = {
  field_id: '',
  field_name: '',
  field_type: '',
  field_key: '',
}

import Card from 'Event/template/Panels/Dashboard/Speakers/SpeakerList/Card'
import Grid from '@material-ui/core/Grid'
import React from 'react'
import Typography from '@material-ui/core/Typography'
import {DragDropContext, Droppable} from 'react-beautiful-dnd'
import {usePanelsTemplate} from 'Event/template/Panels'
import {orderedIdsByPosition} from 'lib/list'
import {useEditMode} from 'Event/EditModeProvider'
import {useHandleDragSpeakers} from 'organization/Event/SpeakersConfig'
import {VisibleSpeaker} from 'Event/Speakers'

export default function SpeakerList() {
  const template = usePanelsTemplate()
  const ids = orderedIdsByPosition(template.speakers.items)
  const isEditMode = useEditMode()

  const isEmpty = ids.length === 0
  if (isEmpty) {
    return <Typography align="center">No speakers have been added</Typography>
  }

  const cards = ids.map((id: string, index: number) => (
    <VisibleSpeaker key={id} speaker={template.speakers.items[id]}>
      <Grid item xs={12} key={id}>
        <Card id={id} speaker={template.speakers.items[id]} index={index} />
      </Grid>
    </VisibleSpeaker>
  ))

  if (!isEditMode) {
    return (
      <Grid container spacing={template.speakers.spacing}>
        {cards}
      </Grid>
    )
  }

  return <DraggableList>{cards}</DraggableList>
}

function DraggableList(props: {children: React.ReactElement[]}) {
  const handleDrag = useHandleDragSpeakers()
  const {speakers} = usePanelsTemplate()

  return (
    <DragDropContext onDragEnd={handleDrag}>
      <Droppable droppableId="drag-and-drop-speaker">
        {(provided) => (
          <Grid
            container
            spacing={speakers.spacing}
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            <>
              {props.children}
              {provided.placeholder}
            </>
          </Grid>
        )}
      </Droppable>
    </DragDropContext>
  )
}

import React from 'react'
import SpeakerList from 'Event/template/NiftyFifty/Dashboard/Speakers/SpeakerList'
import {PageDescription, PageTitle} from 'Event/template/NiftyFifty/Page'
import {useAttendeeVariables} from 'Event'
import {useNiftyFiftyTemplate} from 'Event/template/NiftyFifty'
import Content from 'lib/ui/form/TextEditor/Content'
import HeaderConfig from 'Event/Speakers/HeaderConfig'
import AddSpeakerButton from 'Event/Speakers/AddSpeakerButton'
import Configurable from 'organization/Event/Configurable'

export default function Speakers() {
  const template = useNiftyFiftyTemplate()
  const {speakers: speakerPageSettings} = template
  const v = useAttendeeVariables()

  return (
    <>
      <Configurable>
        <HeaderConfig />
        <PageTitle
          textColor={template.textColor}
          pageTitleColor={template.pageTitleColor}
          aria-label="speakers title"
          extraPadding
        >
          {v(speakerPageSettings.title)}
        </PageTitle>
      </Configurable>
      <PageDescription color={template.textColor} extraPadding>
        <Content>{v(speakerPageSettings.description)}</Content>
      </PageDescription>
      <SpeakerList />
      <AddSpeakerButton />
    </>
  )
}

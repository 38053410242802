import React from 'react'
import styled from 'styled-components'

export default function FormControl(props: {
  className?: string
  children: JSX.Element | JSX.Element[]
  horizontal?: boolean
  fullWidth?: boolean
}) {
  const Container = props.horizontal ? Horizontal : Vertical
  return (
    <Container className={props.className} fullWidth>
      {props.children}
    </Container>
  )
}

const Vertical = styled.div<{
  fullWidth?: boolean
}>`
  margin-bottom: ${(props) => props.theme.spacing[6]};
  ${(props) => (props.fullWidth ? 'width: 100%;' : '')}
`

const Horizontal = styled(Vertical)`
  display: flex;
  align-items: center;
  margin-top: ${(props) => props.theme.spacing[2]};
  margin-bottom: ${(props) => props.theme.spacing[6]};

  > span {
    margin-right: ${(props) => props.theme.spacing[4]};
  }

  > div {
    width: auto;
    flex: 1;
  }
`

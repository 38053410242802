import CustomButtons from 'Event/Step3/TechCheck/Buttons/CustomButtons'
import StartButton from 'Event/Step3/TechCheck/Buttons/StartButton'
import ToggleCustomButtonsSwitch from 'Event/Step3/TechCheck/Buttons/ToggleCustomButtonsSwitch'
import {useTemplate} from 'Event/TemplateProvider'
import React from 'react'

export default function Buttons() {
  return (
    <>
      <ToggleCustomButtonsSwitch />
      <Content />
    </>
  )
}

function Content() {
  const {
    techCheck: {hasCustomButtons},
  } = useTemplate()

  if (hasCustomButtons) {
    return <CustomButtons />
  }

  return <StartButton />
}

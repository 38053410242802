import React from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {useOrganization} from 'organization/OrganizationProvider'
import Tickets from 'organization/Marketplace/Tickets'
import {
  MANAGE_MARKETPLACE,
  usePermissions,
} from 'organization/PermissionsProvider'
import PurchasePageConfig from 'organization/Marketplace/PurchasePageConfig'
import StripeConnectedCallbackHandler from 'organization/Marketplace/StripeConnectedCallbackHandler'
import CreateTicketForm from 'organization/Marketplace/Tickets/TicketForm/CreateTicketForm'
import EditTicketForm from 'organization/Marketplace/Tickets/TicketForm/EditTicketForm'
import {useMarketplaceAccount} from 'lib/marketplace-api/accounts/use-marketplace-account'

export default function MarketplaceRoutes() {
  const {routes} = useOrganization()

  const {data: account} = useMarketplaceAccount()

  const {can} = usePermissions()
  if (!can(MANAGE_MARKETPLACE)) {
    return <Redirect to={routes.settings} />
  }

  if (account === undefined) {
    return null
  }

  // When isConnected is initialized and it happens to be false and we do not
  // have a redirection spot, short circuit.
  if (account?.completed_setup) {
    return <RequiresMarketplaceRoutes />
  }

  return (
    <Switch>
      <Route exact path={routes.marketplace.account.connected}>
        <StripeConnectedCallbackHandler />
      </Route>
      <Redirect to={routes.settings} />
    </Switch>
  )
}

function RequiresMarketplaceRoutes() {
  const {routes} = useOrganization()

  return (
    <Switch>
      <Route exact path={routes.events[':event'].marketplace.tickets.root}>
        <Tickets />
      </Route>
      <Route exact path={routes.events[':event'].marketplace.tickets.add}>
        <CreateTicketForm />
      </Route>
      <Route
        exact
        path={routes.events[':event'].marketplace.tickets[':ticket'].root}
      >
        <EditTicketForm />
      </Route>
      <Route exact path={routes.events[':event'].marketplace.purchase_page}>
        <PurchasePageConfig />
      </Route>
      <Redirect to={routes.settings} />
    </Switch>
  )
}

import {ObvioEvent} from 'Event'
import {PurchasePage} from 'Event/Marketplace/purchase-page'
import {marketplaceApi} from 'lib/marketplace-api/url'
import {Client} from 'lib/ui/api-client'
import {Organization} from 'organization'
import {useMutation, useQueryClient} from 'react-query'

export function useCreatePurchasePage(params: {
  event: ObvioEvent
  organization: Organization
}) {
  const {event} = params
  const qc = useQueryClient()

  return useMutation({
    mutationFn: async (args: {client: Client}) => {
      const {client} = args
      const url = marketplaceApi(`/events/${event.id}/purchase_page`)
      const data = await client.post<PurchasePage>(url)

      return data
    },
    onSuccess: (purchasePage) => {
      qc.setQueryData<PurchasePage>(
        ['events', event.id, 'purchase_page'],
        () => {
          return purchasePage
        },
      )
    },
  })
}

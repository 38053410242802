import {createMuiTheme, ThemeOptions} from '@material-ui/core/styles'

export const breakpoints = {
  sm: '600px',
  md: '960px',
  lg: '1280px',
  xl: '1920px',
}

const PRIMARY_COLOR = '#3490DC'

export const colors = {
  primary: PRIMARY_COLOR,
  secondary: '#2066a7',
  success: '#20A746',
  border: '#a0a9b0',
  info: '#51EA0A',
  error: '#f44336',
  warning: '#ffc107',
  text: {
    primary: '#000000',
    muted: '#999999',
    info: '#939393',
  },
  button: {
    background: 'transparent',
    hoverBackground: '#f9f9f9',
    danger: {
      default: '#f44336',
      hover: '#ff1100',
    },
    warning: {
      default: '#f3a136',
      hover: '#ff9000',
    },
  },
  accent: '#a72020',
  gray: '#f1f1f1',
  gray100: '#a7a7a7',
  gray200: '#838383',
  gray300: '#dfdfdf',
  gray400: '#4f4f4f',
  grey500: '#C4C4C4',
  gray600: '#dadada',
  gray700: '#eaeaea',
  blue: '#2794d2',
  disabled: '#c4c4c4',
  black: '#000000',
  white: '#ffffff',
  input: {
    label: '#000000',
    dark: '#353535',
    focus: '#1976D2',
    filled: '#DFDFDF',
    background: '#ffffff',
    border: '#838383',
  },
  divider: '#353535',
  switch: {
    checked: {
      track: PRIMARY_COLOR,
      thumb: '#FFFFFF',
    },
  },
  panel: {
    background: '#222222',
  },
  menu: {
    itemHoverBackground: '#F5F5F5',
  },
  chart: {
    background: '#464E5F',
    zoomAttendance: {
      join: '#167531',
      left: '#a91409',
      inZoom: '#333333',
    },
  },
  sidebar: {
    background: '#131D34',
  },
  table: {
    rowPrimary: '#f9f9f9',
    rowSecondary: '#F2F5F9',
  },
}

export const spacing = Array(80)
  .fill(4)
  .map((base, idx) => `${base * idx}px`)

export const theme = {
  name: 'light',
  spacing,
  breakpoints,
  colors,
}

export const darkTheme = {
  ...theme,
  name: 'dark',
  colors: {
    ...colors,
    text: {
      ...colors.text,
      primary: '#FFFFFF',
    },
    button: {
      ...colors.button,
      background: '#222222',
      hoverBackground: 'rgba(255, 255, 255, 0.08)',
    },
    input: {
      ...colors.input,
      label: '#FFFFFF',
      background: '#353535',
      border: '#353535',
    },
    switch: {
      ...colors.switch,
      checked: {
        track: '#353535',
        thumb: PRIMARY_COLOR,
      },
    },
    menu: {
      itemHoverBackground: 'rgba(255, 255, 255, 0.08)',
    },
    border: '#FFFFFF',
  },
}

const fontFamily = [
  'Rubik',
  '-apple-system',
  'BlinkMacSystemFont',
  '"Segoe UI"',
  '"Helvetica Neue"',
  'Arial',
  'sans-serif',
  '"Apple Color Emoji"',
  '"Segoe UI Emoji"',
  '"Segoe UI Symbol"',
].join(',')

const muiBaseTheme: ThemeOptions = {
  overrides: {
    MuiFormControl: {
      root: {
        marginBottom: spacing[4],
      },
    },
    MuiButton: {
      outlined: {
        border: '1px solid #000000',
      },
    },
  },
  typography: {
    body1: {
      fontFamily,
    },
    fontFamily,
  },
}

export const muiTheme = createMuiTheme(
  {
    palette: {
      primary: {
        main: colors.primary,
      },
      secondary: {
        main: colors.secondary,
      },
    },
  },
  muiBaseTheme,
)

export const muiDarkTheme = createMuiTheme(
  {
    palette: {
      type: 'dark',
      primary: {
        main: '#FFFFFF',
      },
      background: {
        paper: darkTheme.colors.panel.background,
      },
    },
    overrides: {
      MuiSwitch: {
        colorPrimary: {
          '&$checked': {
            color: colors.primary,
          },
          '&$checked + $track': {
            backgroundColor: colors.primary,
          },
        },
      },
    },
  },
  muiBaseTheme,
)

export type MuiThemeOptions = {
  secondaryColor: string
}

export const createMuiLightTheme = (options: MuiThemeOptions) =>
  createMuiTheme(
    {
      palette: {
        primary: {
          main: colors.primary,
        },
        secondary: {
          main: options.secondaryColor || colors.secondary,
        },
      },
    },
    muiBaseTheme,
  )

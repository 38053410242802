import Question from 'Event/Question'
import {Answer} from 'Event/SubmissionsProvider'
import {ValidationError} from 'lib/ui/api-client'
import {Form} from 'organization/Event/FormsProvider'
import React from 'react'
import {UseFormMethods} from 'react-hook-form'

// Hardcoded question ids to check
const HAS_BUSINESS_QUESTION_ID = '9251'
const DREAM_OF_OWNING_BUSINESS_QUESTION_ID = '9252'
const COMPANY_NAME_QUESTION_ID = '9253'
const COMPANY_INDUSTRY_QUESTION_ID = '9254'

/**
 * Custom Waiver form for Tony Robbin's UPW March 2023
 */
export default function UpwMarch23Form(props: {
  form: Form
  formErrors: UseFormMethods['errors']
  control: UseFormMethods['control']
  register: UseFormMethods['register']
  responseError: ValidationError<any>
  setValue: UseFormMethods['setValue']
  answers: Answer[]
  watch: UseFormMethods['watch']
}) {
  const {
    form,
    formErrors,
    control,
    register,
    responseError,
    setValue,
    answers,
    watch,
  } = props

  const currentAnswers: {question_id: string; value: string}[] =
    watch('answers') || []

  const hasBusinessQuestion = currentAnswers.find(
    (a) => a.question_id === HAS_BUSINESS_QUESTION_ID,
  )

  const hasBusinessAnswer = hasBusinessQuestion?.value

  const businessQuestionIds = [
    DREAM_OF_OWNING_BUSINESS_QUESTION_ID,
    COMPANY_NAME_QUESTION_ID,
    COMPANY_INDUSTRY_QUESTION_ID,
  ]

  const dreamOfOwningBusinessQuestion = form.questions.find(
    (q) => String(q.id) === DREAM_OF_OWNING_BUSINESS_QUESTION_ID,
  )
  if (!dreamOfOwningBusinessQuestion) {
    throw new Error(`Missing question for 'dream of owning own business'`)
  }

  const questions = form.questions.filter((q) => {
    // If user hasn't selected whether they own a business, we'll show
    // everything EXCEPT the conditional questions.
    if (!hasBusinessAnswer) {
      return !businessQuestionIds.includes(String(q.id))
    }

    // If they already own a business, we won't ask them if they
    // dream of owning one.
    if (hasBusinessAnswer === '{{survey_q4_option1}}') {
      return String(q.id) !== DREAM_OF_OWNING_BUSINESS_QUESTION_ID
    }

    // They do NOT own a business, so we'll skip any related to their
    // company which doesn't exist.
    return (
      String(q.id) !== COMPANY_NAME_QUESTION_ID &&
      String(q.id) !== COMPANY_INDUSTRY_QUESTION_ID
    )
  })

  return (
    <>
      {questions.map((question, index) => (
        <Question
          formErrors={formErrors}
          key={index}
          index={index}
          control={control}
          question={question}
          answers={answers}
          register={register}
          responseError={responseError}
          setValue={setValue}
          watch={watch}
        />
      ))}
    </>
  )
}

import Typography from '@material-ui/core/Typography'
import styled from 'styled-components'
import Card from 'Event/template/NiftyFifty/Dashboard/Faqs/FaqList/Card'
import React from 'react'
import VisibleOnMatch from 'Event/attendee-rules/VisibleOnMatch'
import {DragDropContext, Droppable} from 'react-beautiful-dnd'
import {useNiftyFiftyTemplate} from 'Event/template/NiftyFifty'
import {orderedIdsByPosition} from 'lib/list'
import {useEditMode} from 'Event/EditModeProvider'
import {useHandleDragFaqs} from 'organization/Event/FAQsConfig'
import Visible from 'lib/ui/layout/Visible'

export default function FaqList(props: {className?: string}) {
  const isEditMode = useEditMode()
  const {faq: faqPageSettings, textColor} = useNiftyFiftyTemplate()
  const ids = orderedIdsByPosition(faqPageSettings.items)

  const isEmpty = ids.length === 0

  if (isEmpty) {
    return <Typography align="center">No faq have been added</Typography>
  }

  const faqs = ids.map((id, index) => {
    const faq = faqPageSettings.items[id]

    // always show when it's undefined, to work FAQ's added before that change
    const shouldShow = faq.enabled === true || faq.enabled === undefined

    return (
      <VisibleOnMatch rules={faq.rules} key={id}>
        <Visible when={shouldShow} showInEditmode={true}>
          <Card index={index} id={id} faq={faqPageSettings.items[id]} />
        </Visible>
      </VisibleOnMatch>
    )
  })

  if (!isEditMode) {
    return (
      <Box color={textColor} className={props.className}>
        {faqs}
      </Box>
    )
  }

  return (
    <DraggableContent color={textColor} className={props.className}>
      {faqs}
    </DraggableContent>
  )
}

const DraggableContent = (props: {
  className?: string
  children: React.ReactElement[]
  color: string
}) => {
  const handleDrag = useHandleDragFaqs()

  return (
    <DragDropContext onDragEnd={handleDrag}>
      <Droppable droppableId="drag-and-drop-faq">
        {(provided) => (
          <Box
            ref={provided.innerRef}
            {...provided.droppableProps}
            className={props.className}
            color={props.color}
          >
            <>
              {props.children}
              {provided.placeholder}
            </>
          </Box>
        )}
      </Droppable>
    </DragDropContext>
  )
}

const Box = styled.div<{
  color: string
}>`
  width: 100%;
  color: ${(props) => props.color};
`

/**
 * Get the custom {{variables}} within a text
 *
 * @param text
 * @returns
 */

import {useCallback} from 'react'

export function parseVariables(text: string) {
  const matches = text.match(/{{([^}]+)}}/g)

  if (!matches) {
    return []
  }

  /**
   * Remove matched {{ brackes }}
   */
  return matches.map((key) => key.replace(/{/g, '').replace(/}/g, ''))
}

/**
 * Removes all {{variables}} from a given text.
 *
 * @returns
 */

export function useRemoveVariables() {
  return useCallback((text: string) => {
    const hasText = Boolean(text)
    if (!hasText) {
      return text
    }

    const variables = parseVariables(text)
    let result = text

    for (const key of variables) {
      result = replace(key, '', result)
    }

    return result
  }, [])
}

/**
 * Returns a given fallback text if the target text is empty, and a fallback text
 * was provided.
 */
export function useReturnFallback() {
  return useCallback(
    (fallback: string) => (text: string) => {
      const hasText = Boolean(text)
      if (hasText) {
        return text
      }

      if (fallback) {
        return fallback
      }

      if (!text) {
        return ''
      }

      // If we're here, text should be empty, and no fallback was provided
      // so let's just return the empty string.
      return text
    },
    [],
  )
}

export function replace(key: string, value: string, text: string) {
  // if no value was provided don't do a replace. Fixes an issu where users would
  // see `Welcome null` being replaced.
  if (value === null || value === undefined) {
    return text
  }

  const match = new RegExp(`{{${key}}}`, 'gi')
  return text.replace(match, value)
}

import React, {useEffect, useRef, useState} from 'react'
import styled from 'styled-components'
import SignaturePad from 'signature_pad'
import TextField from '@material-ui/core/TextField'
import {onChangeStringHandler} from 'lib/dom'

/**
 * Font used for signatures when the user chooses to type their
 * signature instead of signing via click + drag.
 */
export const SIGNATURE_FONT = 'Pacifico'
const SIGNATURE_FONT_SIZE = 40

type TypeInputProps = {
  name: string
  showing: boolean
  onClickToType: () => void
  buttonText: string
  buttonColor: string
  label: string
  pad: SignaturePad
  canvas: HTMLCanvasElement
  onUpdate: (data: string | null) => void
}

export default function TypeInput(props: TypeInputProps) {
  const {
    showing,
    onClickToType,
    name,
    buttonColor,
    buttonText,
    label,
    onUpdate,
    canvas,
    pad,
  } = props
  const [value, setValue] = useState('')

  // Toggle value everytime it's shown/hidden to start with
  // a signature
  useEffect(() => {
    if (showing) {
      pad.clear()
      setValue('')
      inputRef.current?.focus()
    }
  }, [showing, setValue, name, pad])

  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    pad.clear()

    const context = canvas.getContext('2d')
    const width = canvas.clientWidth
    const height = canvas.clientHeight

    if (!context) {
      throw new Error(
        'Missing context; was `getContext` called previously with a different type of context?',
      )
    }

    context.font = `${SIGNATURE_FONT_SIZE}px ${SIGNATURE_FONT}`
    context.textAlign = 'center'
    context.textBaseline = 'middle'
    context.fillText(value, width / 2, height / 2)
    const data = pad.toDataURL()

    // Can't update the signature with empty text.
    if (value) {
      onUpdate(data)
    }
  }, [value, onUpdate, canvas, pad])

  if (!showing) {
    return (
      <Box>
        <StyledButton
          color={buttonColor}
          aria-label="type signature"
          onClick={onClickToType}
        >
          {buttonText}
        </StyledButton>
      </Box>
    )
  }

  return (
    <Box>
      <StyledTextField
        inputProps={{
          'aria-label': 'signature name field',
        }}
        label={label}
        inputRef={inputRef}
        value={value}
        onChange={onChangeStringHandler(setValue)}
        size="small"
      />
    </Box>
  )
}

const Box = styled.div`
  display: flex;
  position: absolute;
  bottom: 20px;
  justify-content: center;
  width: 100%;
`

const StyledTextField = styled(TextField)`
  margin-bottom: 0;
`

const StyledButton = styled.a<{
  color?: string
}>`
  color: ${(props) => props.color || '#000000'} !important;
  cursor: pointer;
  letter-spacing: 0.5px;
  font-size: 12px;
`

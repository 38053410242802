import React, {useCallback} from 'react'
import styled from 'styled-components'
import TableRow from '@material-ui/core/TableRow'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableCell from '@material-ui/core/TableCell'
import TableBody from '@material-ui/core/TableBody'
import {
  OrganizationEmail,
  useEmails,
} from 'organization/Settings/CommunicationSettings/EmailsProvider'
import {useOrganization} from 'organization/OrganizationProvider'
import {api} from 'lib/url'
import {useDeferredAsync} from 'lib/async'
import {MoreActionsMenu} from 'lib/ui/Menu'
import MenuItem from 'lib/ui/Menu/MenuItem'
import ErrorAlert from 'lib/ui/alerts/ErrorAlert'
import InfoAlert from 'lib/ui/alerts/InfoAlert'

export default function EmailsTable() {
  const resendVerification = useResendVerification()
  const deleteEmail = useDeleteEmail()

  const processing = resendVerification.loading || deleteEmail.loading

  const verificationResentMessage = resendVerification.wasSuccessful
    ? 'Please click the link in the email to verify this email address.'
    : null

  return (
    <>
      <ErrorAlert onClose={resendVerification.reset}>
        {deleteEmail.error?.message}
      </ErrorAlert>
      <ErrorAlert onClose={deleteEmail.reset}>
        {resendVerification.error?.message}
      </ErrorAlert>
      <InfoAlert onClose={resendVerification.reset}>
        {verificationResentMessage}
      </InfoAlert>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>From Name</TableCell>
            <TableCell>From Email</TableCell>
            <StatusCell />
            <ActionCell />
          </TableRow>
        </TableHead>
        <TableBody>
          <Body
            onDelete={deleteEmail.send}
            onResendVerification={resendVerification.send}
            processing={processing}
          />
        </TableBody>
      </Table>
    </>
  )
}

function Body(props: {
  onDelete: (email: OrganizationEmail) => void
  onResendVerification: (email: OrganizationEmail) => void
  processing: boolean
}) {
  const {onDelete, onResendVerification, processing} = props

  const {emails} = useEmails()
  const hasEmails = emails.length > 0
  if (!hasEmails) {
    return (
      <TableRow>
        <TableCell colSpan={4}>No Emails have been added</TableCell>
      </TableRow>
    )
  }

  return (
    <>
      {emails.map((e) => (
        <EmailRow
          organizationEmail={e}
          key={e.id}
          onDelete={() => onDelete(e)}
          onResendVerification={() => onResendVerification(e)}
          processing={processing}
        />
      ))}
    </>
  )
}

function EmailRow(props: {
  organizationEmail: OrganizationEmail
  onDelete: () => void
  onResendVerification: () => void
  processing: boolean
}) {
  const {organizationEmail, processing} = props

  return (
    <TableRow>
      <TableCell>{organizationEmail.name}</TableCell>
      <TableCell>{organizationEmail.email}</TableCell>
      <TableCell>
        <StatusBadge organizationEmail={organizationEmail} />
      </TableCell>
      <TableCell>
        <MoreActionsMenu>
          <MenuItem onClick={props.onDelete} disabled={processing}>
            Delete
          </MenuItem>
          <ResendVerificationMenuItem
            organizationEmail={organizationEmail}
            onClick={props.onResendVerification}
            disabled={processing}
          />
        </MoreActionsMenu>
      </TableCell>
    </TableRow>
  )
}

function ResendVerificationMenuItem(props: {
  organizationEmail: OrganizationEmail
  onClick: () => void
  disabled: boolean
}) {
  if (props.organizationEmail.verified) {
    return null
  }

  return (
    <MenuItem onClick={props.onClick} disabled={props.disabled}>
      Resend Verification
    </MenuItem>
  )
}

function StatusBadge(props: {organizationEmail: OrganizationEmail}) {
  const {organizationEmail} = props

  if (organizationEmail.verified) {
    return null
  }

  return <StatusChip>Pending</StatusChip>
}

function useResendVerification() {
  const {client} = useOrganization()

  const request = useCallback(
    (email: OrganizationEmail) => {
      const url = api(`/organization_emails/${email.id}/resend_verification`)
      return client.post(url)
    },
    [client],
  )

  return useDeferredAsync(request)
}

function useDeleteEmail() {
  const {client} = useOrganization()
  const {remove} = useEmails()

  const request = useCallback(
    (email: OrganizationEmail) => {
      const url = api(`/organization_emails/${email.id}`)
      return client.delete(url).then(() => remove(email))
    },
    [client, remove],
  )

  return useDeferredAsync(request)
}

const StatusChip = styled.span`
  font-size: 10px;
  line-height: 12px;
  color: rgba(0, 0, 0, 0.5);
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 3px;
  padding: 7.5px 12px;
`

const StatusCell = styled(TableCell)`
  width: 65px;
`

const ActionCell = styled(TableCell)`
  width: 90px;
`

import React, {useState} from 'react'
import Lightbox from 'react-image-lightbox'
import 'react-image-lightbox/style.css'
import {Template} from 'Event/template'
import {getPreviews} from 'Event/template/screenshots'

export default function TemplatePreviewImages(props: {
  open: boolean
  template: Template['name']
  onClose: () => void
}) {
  const {open, template, onClose} = props

  const [index, setIndex] = useState(0)

  const previews = getPreviews(template)

  const lastIndex = previews.length - 1
  const nextIndex = index === lastIndex ? 0 : index + 1
  const previousIndex = index === 0 ? lastIndex : index - 1

  const onPrev = () => {
    setIndex(previousIndex)
  }

  const onNext = () => {
    setIndex(nextIndex)
  }

  if (!open) {
    return null
  }

  return (
    <Lightbox
      mainSrc={previews[index]}
      nextSrc={previews[nextIndex]}
      prevSrc={previews[previousIndex]}
      onCloseRequest={onClose}
      onMovePrevRequest={onPrev}
      onMoveNextRequest={onNext}
      enableZoom={false}
    />
  )
}

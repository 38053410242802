import React, {useState, useEffect} from 'react'
import styled, {useTheme} from 'styled-components'
import SliderComponent, {SliderProps} from '@material-ui/core/Slider'
import {withStyles} from '@material-ui/core/styles'
import {handleChangeSlider, onChangeNumberHandler} from 'lib/dom'
import {Label} from 'lib/ui/typography'

const CustomSlider = withStyles((theme) => ({
  root: {
    marginTop: '15px',
    color: theme.palette.primary.main,
  },
  rail: {
    color: theme.palette.grey[300],
    opacity: 1,
  },
}))(SliderComponent)

type SliderComponentProps = Omit<SliderProps, 'onChange'> & {
  label?: string
  unit?: string
  hideUnits?: boolean
  onChange: (value: number) => void
}

const DEFAULT_UNIT = 'PX'

export default function Slider(props: SliderComponentProps) {
  const {
    label,
    unit: unitLabel = DEFAULT_UNIT,
    hideUnits,
    onChange,
    ...sliderProps
  } = props

  const {min, max, value: initialValue} = sliderProps

  const [value, setValue] = useState(0)
  const [focused, setFocused] = useState(false)
  const toggleFocused = () => setFocused(!focused)

  const onChangeValue = (value: number) => {
    setValue(value)
    onChange(value)
  }

  useEffect(() => {
    if (Array.isArray(initialValue)) {
      setValue(initialValue[0])
    }
    if (initialValue && !Array.isArray(initialValue)) {
      setValue(initialValue)
    }
  }, [initialValue])

  return (
    <>
      <LabelContainer>
        <Label>{label}</Label>
        <OutputContent focused={focused}>
          <>
            <Input
              disabled={props.disabled}
              type="number"
              value={value}
              min={min}
              max={max}
              onChange={onChangeNumberHandler(onChangeValue, {isDecimal: true})}
              onFocus={toggleFocused}
              onBlur={toggleFocused}
              step={props.step || ''}
              aria-label={props['aria-label']}
              hasUnits={!hideUnits}
            />
            <SliderValueLabel hidden={hideUnits}>{unitLabel}</SliderValueLabel>
          </>
        </OutputContent>
      </LabelContainer>
      <CustomSlider
        {...sliderProps}
        value={value}
        onChange={handleChangeSlider(onChangeValue)}
      />
    </>
  )
}

const LabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

function OutputContent(props: {
  focused: boolean
  children: React.ReactElement
}) {
  const theme = useTheme()

  const getBorderColor = () => {
    if (props.focused) {
      return theme.colors.primary
    }

    if (theme.name === 'dark') {
      return 'transparent'
    }

    return theme.colors.gray
  }

  return (
    <OutputContentDiv borderColor={getBorderColor()}>
      {props.children}
    </OutputContentDiv>
  )
}

type OutputContentDivProps = {
  borderColor: string
}

const OutputContentDiv = styled.div<OutputContentDivProps>`
  height: 34px;
  background-color: ${(props) => props.theme.colors.input.background};
  padding: 11px 11px;
  border-radius: ${(props) => props.theme.spacing[1]};
  border: 1px solid ${(props) => props.borderColor};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 0;
`

const SliderValueLabel = styled.span<{hidden?: boolean}>`
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
  color: ${(props) => props.theme.colors.text.primary};
  display: ${(props) => (props.hidden ? 'none' : 'block')};
`

const Input = styled.input<{
  hasUnits: boolean
}>`
  color: ${(props) => props.theme.colors.text.primary};
  background-color: ${(props) => props.theme.colors.input.background};
  ${(props) => (props.hasUnits ? 'width: 35px;' : '')}
  ${(props) => (props.hasUnits ? '' : 'text-align: center;')}
  padding: 0;
  outline: none;
  font-size: 14px;
  line-height: 16px;
  font-weight: 900;
  border: none;
  -moz-appearance: textfield;
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
    width: 0;
  }
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    width: 0;
  }
`

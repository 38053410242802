import {useSaveTemplate} from 'Event/TemplateUpdateProvider'
import TextField from 'lib/ui/TextField'
import {useConfig} from 'organization/Event/Configurable'
import Config from 'organization/Event/Configurable/Config'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import React from 'react'

export default function TabLinkConfig(props: {name: string; value: string}) {
  const save = useSaveTemplate()

  const {
    form: {register},
  } = useConfig()

  return (
    <Config title="Tab Link" onSave={save}>
      <SettingsPanel>
        <TextField
          name={props.name}
          defaultValue={props.value}
          label="Text"
          fullWidth
          inputProps={{
            'aria-label': 'link text',
            ref: register,
          }}
        />
      </SettingsPanel>
    </Config>
  )
}

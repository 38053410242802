export default function If<T>(props: {
  hasValue?: T
  children: (exists: T) => JSX.Element
}) {
  if (!props.hasValue) {
    return null
  }

  return props.children(props.hasValue)
}

import React from 'react'
import {Draggable} from 'react-beautiful-dnd'
import {useEditMode} from 'Event/EditModeProvider'
import Configurable from 'organization/Event/Configurable'
import {Background} from 'organization/Event/BackgroundsConfig'
import BackgroundImageConfig from 'Event/Backgrounds/BackgroundImageConfig'
import BackgroundImage from 'Event/Backgrounds/BackgroundImage'
import {useTemplate} from 'Event/TemplateProvider'
import {downloadFile} from 'lib/http-client'
import {useAttendeeVariables} from 'Event'
import {createFileLocation} from 'lib/url'

type BackgroundProps = {
  index: number
  id: string
  background: Background
  className?: string
  isLast: boolean
}

export default function BackgroundCard(props: BackgroundProps) {
  const {index, id} = props

  const isEditMode = useEditMode()
  if (!isEditMode) {
    return <Content {...props} />
  }

  return (
    <Draggable draggableId={id} index={index}>
      {(provided) => (
        <div
          ref={provided.innerRef}
          className={props.className}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <Content {...props} />
        </div>
      )}
    </Draggable>
  )
}

function Content(props: BackgroundProps) {
  const {background, id} = props
  const {zoomBackgrounds: templateSettings} = useTemplate()
  const isEditMode = useEditMode()
  const width = isEditMode ? 200 : '100%'
  const v = useAttendeeVariables()
  const fileLocation = createFileLocation(background.image)

  if (!fileLocation) {
    return null
  }

  return (
    <Configurable>
      <BackgroundImageConfig id={id} background={background} />
      <BackgroundImage
        alt="background"
        borderRadius={templateSettings?.borderRadius}
        borderThickness={templateSettings?.borderThickness}
        borderColor={templateSettings?.borderColor}
        clickable={!isEditMode}
        src={background.image}
        aria-label="background image"
        width={width}
        onClick={() => downloadFile(v(fileLocation?.url), fileLocation?.name)}
      />
    </Configurable>
  )
}

import React, {useState} from 'react'
import {useTownhallTemplate, useTownhallUpdate} from 'Event/template/Townhall'
import Settings from 'Event/template/Townhall/Dashboard/NavBar/NavBarConfig/Settings'
import Styling from 'Event/template/Townhall/Dashboard/NavBar/NavBarConfig/Styling'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import StylingPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/StylingPanel'
import Config from 'organization/Event/Configurable/Config'
import {Emoji} from 'Event/Dashboard/components/EmojiList/emoji'

export function NavBarConfig() {
  const {emojiList} = useTownhallTemplate()
  const [emojis, setEmojis] = useState(emojiList.emojis)

  const update = useTownhallUpdate()

  const onSave = (data: any) => {
    const updated = {
      ...data,
      emojiList: {
        emojiWidth: data.emojiList.emojiWidth,
        emojis,
      },
      navBar: {
        ...data.navBar,
      },
    }
    update(updated)
  }

  const addNewEmoji = (emoji: Emoji['name']) => {
    const added = [...emojis, emoji]
    setEmojis(added)
  }

  const updateEmoji = (index: number) => (target: Emoji['name']) => {
    const updated = emojis.map((e, i) => {
      const isTarget = i === index
      if (isTarget) {
        return target
      }

      return e
    })

    setEmojis(updated)
  }

  return (
    <Config title="Townhall NavBar" onSave={onSave}>
      <SettingsPanel>
        <Settings
          addNewEmoji={addNewEmoji}
          emojis={emojis}
          setEmojis={setEmojis}
          updateEmoji={updateEmoji}
        />
      </SettingsPanel>
      <StylingPanel>
        <Styling />
      </StylingPanel>
    </Config>
  )
}

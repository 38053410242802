import React from 'react'
import TypographySection from 'Event/template/Townhall/Dashboard/Main/ResourceSection/ResourceItemConfig/TypographySection'
import BackgroundSection from 'Event/template/Townhall/Dashboard/Main/ResourceSection/ResourceItemConfig/BackgroundSection'
import SizeSection from 'Event/template/Townhall/Dashboard/Main/ResourceSection/ResourceItemConfig/SizeSection'
import {Resource} from 'Event/template/Townhall/Dashboard/Main/ResourceSection/ResourceItem'
import BorderSection from 'Event/template/Townhall/Dashboard/Main/ResourceSection/ResourceItemConfig/BorderSection'

export default function Styling(props: {
  id: string
  sectionId: string
  resource: Resource
}) {
  const {resource, id, sectionId} = props

  return (
    <>
      <TypographySection
        resource={resource}
        storageKey="resource.config.styling.typography"
        sectionId={sectionId}
        id={id}
      />
      <BackgroundSection
        resource={resource}
        storageKey="resource.config.styling.background"
        sectionId={sectionId}
        id={id}
      />
      <SizeSection
        resource={resource}
        storageKey="resource.config.styling.size"
        sectionId={sectionId}
        id={id}
      />
      <BorderSection
        resource={resource}
        storageKey="resource.config.styling.border"
        sectionId={sectionId}
        id={id}
      />
    </>
  )
}

import {useConfig} from 'organization/Event/Configurable'
import Config from 'organization/Event/Configurable/Config'
import StylingPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/StylingPanel'
import {useUpdateTemplate} from 'organization/Marketplace/PurchasePageConfig/TemplateUpdateProvider'
import React, {useState} from 'react'
import {Controller} from 'react-hook-form'
import CollapsibleSection from 'lib/ui/ConfigPanel/CollapsibleSection'
import Slider from 'lib/ui/Slider'
import FontSelect from 'lib/FontSelect'
import ColorPicker from 'lib/ui/ColorPicker'
import ImageAssetUploader from 'lib/asset/ImageAssetUploader'
import Select from 'lib/ui/Select'
import Option from 'lib/ui/Select/Option'
import {Label} from 'lib/ui/typography'
import MarginPaddingInputs from 'lib/ui/MarginPaddingInputs'
import {onUnknownChangeHandler} from 'lib/dom'
import {REMOVE} from 'Event/TemplateUpdateProvider'
import {EnabledSwitch} from 'lib/ui/form/Switch'
import {onChangeCheckedHandler} from 'lib/dom'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import VisibilitySetting from 'organization/Marketplace/PurchasePageConfig/BlockConfig/VisibilitySettings'
import {TicketSelectorBlock} from 'Event/Marketplace/Block/TicketSelector'

interface TicketSelectorConfigProps extends TicketSelectorBlock {
  id: string
  sectionId: string
}

export default function TicketSelectorConfig(props: TicketSelectorConfigProps) {
  const {
    id,
    sectionId,
    name,
    description,
    alignment,
    background,
    spacing,
    selectorIcon,
    padding,
    border,
  } = props
  const updateTemplate = useUpdateTemplate()
  const {
    form: {control},
  } = useConfig()
  const [visibility, setVisibility] = useState(props.visibility)

  const handleRemove = () => {
    updateTemplate({
      sections: {
        [sectionId]: {
          blocks: {
            [id]: REMOVE,
          },
        },
      },
    })
  }

  const save = (data: any) => {
    data.sections[sectionId].blocks[id].visibility = visibility
    updateTemplate(data)
  }

  return (
    <Config title="Ticket Selector Block" onSave={save} onRemove={handleRemove}>
      <SettingsPanel>
        <VisibilitySetting
          visibility={visibility}
          setVisibility={setVisibility}
        />
      </SettingsPanel>
      <StylingPanel>
        <CollapsibleSection
          label="Ticket Name"
          storageKey={`blocks-${id}-styling-name`}
        >
          <Controller
            name={`sections.${sectionId}.blocks.${id}.name.opacity`}
            defaultValue={name.opacity}
            control={control}
            render={({value, onChange}) => (
              <Slider
                label="Opacity"
                min={0}
                max={100}
                step={1}
                unit="%"
                onChange={onChange}
                value={value}
              />
            )}
          />
          <Controller
            name={`sections.${sectionId}.blocks.${id}.name.color`}
            defaultValue={name.color}
            control={control}
            render={({value, onChange}) => (
              <ColorPicker
                aria-label="ticket selector text color"
                label="Color"
                color={value}
                onPick={onChange}
              />
            )}
          />
          <Controller
            name={`sections.${sectionId}.blocks.${id}.name.font`}
            control={control}
            defaultValue={name.font || null}
            render={({value, onChange}) => (
              <FontSelect value={value} onChange={onChange} />
            )}
          />
          <Controller
            name={`sections.${sectionId}.blocks.${id}.name.fontSize`}
            defaultValue={name.fontSize}
            control={control}
            render={({value, onChange}) => (
              <Slider
                min={10}
                max={100}
                step={1}
                onChange={onChange}
                value={value}
                label="Size"
              />
            )}
          />
          <Controller
            name={`sections.${sectionId}.blocks.${id}.selectorIcon`}
            defaultValue={selectorIcon}
            control={control}
            render={({value, onChange}) => (
              <Select
                fullWidth
                label="Selector Icon Type"
                value={value}
                onChange={onUnknownChangeHandler(onChange)}
              >
                <Option value="circle">Circle Check</Option>
                <Option value="x">X Circle Check</Option>
                <Option value="hand">Hand Point</Option>
                <Option value="ticket">Ticket</Option>
              </Select>
            )}
          />
        </CollapsibleSection>
        <CollapsibleSection
          label="Ticket Description"
          storageKey={`blocks-${id}-styling-description`}
        >
          <Controller
            name={`sections.${sectionId}.blocks.${id}.description.enabled`}
            defaultValue={description.enabled}
            control={control}
            render={({value, onChange}) => (
              <EnabledSwitch
                checked={value}
                onChange={onChangeCheckedHandler(onChange)}
                arial-label="description visible switch"
              />
            )}
          />
          <Controller
            name={`sections.${sectionId}.blocks.${id}.description.opacity`}
            defaultValue={description.opacity}
            control={control}
            render={({value, onChange}) => (
              <Slider
                label="Opacity"
                min={0}
                max={100}
                step={1}
                unit="%"
                onChange={onChange}
                value={value}
              />
            )}
          />
          <Controller
            name={`sections.${sectionId}.blocks.${id}.description.color`}
            defaultValue={description.color}
            control={control}
            render={({value, onChange}) => (
              <ColorPicker
                aria-label="ticket selector text color"
                label="Color"
                color={value}
                onPick={onChange}
              />
            )}
          />
          <Controller
            name={`sections.${sectionId}.blocks.${id}.description.font`}
            control={control}
            defaultValue={description.font || null}
            render={({value, onChange}) => (
              <FontSelect value={value} onChange={onChange} />
            )}
          />
          <Controller
            name={`sections.${sectionId}.blocks.${id}.description.fontSize`}
            defaultValue={description.fontSize}
            control={control}
            render={({value, onChange}) => (
              <Slider
                min={10}
                max={100}
                step={1}
                onChange={onChange}
                value={value}
                label="Size"
              />
            )}
          />
        </CollapsibleSection>
        <CollapsibleSection
          label="Background"
          storageKey={`blocks-${id}-background`}
        >
          <Controller
            name={`sections.${sectionId}.blocks.${id}.background.image`}
            control={control}
            defaultValue={background.image}
            render={({onChange, value}) => (
              <ImageAssetUploader
                onChange={onChange}
                value={value}
                uploadLabel="Upload"
                uploadInputProps={{
                  'aria-label': 'block background image',
                }}
                width={1920}
                height={1200}
                canResize
                disableAutoRemove
              />
            )}
          />
          <Controller
            name={`sections.${sectionId}.blocks.${id}.background.color`}
            defaultValue={background.color}
            control={control}
            render={({value, onChange}) => (
              <ColorPicker
                label="Color"
                color={value}
                onPick={onChange}
                aria-label="block background color"
              />
            )}
          />
          <Controller
            name={`sections.${sectionId}.blocks.${id}.background.opacity`}
            defaultValue={background.opacity}
            control={control}
            render={({value, onChange}) => (
              <Slider
                label="Opacity"
                unit="%"
                valueLabelDisplay="auto"
                aria-label="block background opacity"
                value={value}
                onChange={onChange}
                step={1}
                min={0}
                max={100}
              />
            )}
          />
        </CollapsibleSection>
        <CollapsibleSection label="Border" storageKey={`blocks-${id}-border`}>
          <Controller
            name={`sections.${sectionId}.blocks.${id}.border.color`}
            control={control}
            defaultValue={border.color}
            render={({value, onChange}) => (
              <ColorPicker label="Color" color={value} onPick={onChange} />
            )}
          />
          <Controller
            name={`sections.${sectionId}.blocks.${id}.border.width`}
            defaultValue={border.width}
            control={control}
            render={({value, onChange}) => (
              <Slider
                label="Width"
                min={0}
                max={100}
                onChange={onChange}
                valueLabelDisplay="off"
                value={value}
              />
            )}
          />
          <Controller
            name={`sections.${sectionId}.blocks.${id}.border.radius`}
            defaultValue={border.radius || 0}
            control={control}
            render={({value, onChange}) => (
              <Slider
                label="Radius"
                min={0}
                max={100}
                onChange={onChange}
                valueLabelDisplay="off"
                value={value}
              />
            )}
          />
        </CollapsibleSection>
        <CollapsibleSection
          label="Alignment"
          storageKey={`blocks-${id}-alignment`}
        >
          <Controller
            name={`sections.${sectionId}.blocks.${id}.alignment.vertical`}
            defaultValue={alignment.vertical}
            control={control}
            render={({value, onChange}) => (
              <Select
                fullWidth
                label="Vertical"
                value={value}
                onChange={onUnknownChangeHandler(onChange)}
              >
                <Option value="start">Top</Option>
                <Option value="center">Center</Option>
                <Option value="end">Bottom</Option>
              </Select>
            )}
          />
          <Controller
            name={`sections.${sectionId}.blocks.${id}.alignment.horizontal`}
            defaultValue={alignment.horizontal}
            control={control}
            render={({value, onChange}) => (
              <Select
                fullWidth
                label="Horizontal"
                value={value}
                onChange={onUnknownChangeHandler(onChange)}
              >
                <Option value="left">Left</Option>
                <Option value="center">Center</Option>
                <Option value="right">Right</Option>
              </Select>
            )}
          />
        </CollapsibleSection>
        <CollapsibleSection
          label="Advanced Spacing"
          storageKey={`sections-${id}-spacing`}
        >
          <Label>Padding</Label>
          <MarginPaddingInputs
            control={control}
            namePrefix={`sections.${sectionId}.blocks.${id}.padding`}
            values={padding}
          />
          <Controller
            name={`sections.${sectionId}.blocks.${id}.spacing`}
            defaultValue={spacing}
            control={control}
            render={({value, onChange}) => (
              <Slider
                label="Space Between Tickets"
                min={0}
                max={100}
                step={1}
                unit="px"
                onChange={onChange}
                value={value}
              />
            )}
          />
        </CollapsibleSection>
      </StylingPanel>
    </Config>
  )
}

import React from 'react'
import Backdrop from '@material-ui/core/Backdrop'
import IconButton from '@material-ui/core/IconButton'
import styled from 'styled-components'
import PostForm from 'Event/Dashboard/components/BlogPosts/PostForm'
import Buttons from 'Event/Dashboard/components/BlogPosts/Buttons'
import Title from 'Event/Dashboard/components/BlogPosts/Title'
import Content from 'Event/Dashboard/components/BlogPosts/Content'
import CloseIcon from '@material-ui/icons/Close'
import {useEditMode} from 'Event/EditModeProvider'
import Date from 'Event/template/Townhall/Dashboard/Main/BlogSection/BlogPost/Date'
import {
  BlogSectionProps,
  TownhallBlogPost,
} from 'Event/template/Townhall/Dashboard/Main/BlogSection'

export default function ExpandedPostDialog(
  props: BlogSectionProps & {
    id: string | null
    onClose: () => void
  },
) {
  const {id, onClose} = props
  const isEditMode = useEditMode()

  if (!id || isEditMode) {
    return null
  }

  const blogPosts = props.items
  const post = blogPosts[id]
  if (!post) {
    return null
  }

  return (
    <StyledBackdrop open onClick={onClose}>
      <CloseButton onClick={onClose}>
        <CloseIcon />
      </CloseButton>
      <Container>
        <Image post={post} />
        <ContentBox>
          <Title post={post} />
          <Content post={post} />
          <Date post={post} />
          <PostForm post={post} />
          <Buttons post={post} postId={id} />
        </ContentBox>
      </Container>
    </StyledBackdrop>
  )
}

function Image(props: {post: TownhallBlogPost}) {
  const {post} = props

  if (!post.image) {
    return null
  }
  return (
    <ImageBox>
      <img src={post.image} alt="blog post" aria-label="blog post image" />
    </ImageBox>
  )
}

const CloseButton = styled(IconButton)`
  position: absolute;
  top: 8px;
  right: 8px;
  z-index: 99;
  svg {
    color: #ffffff;
  }
`

const StyledBackdrop = styled(Backdrop)`
  z-index: 99;
`

const Container = styled.div`
  box-shadow: 0px 0px 4px #edf1f7;
  border: solid 1px #edf1f7;
  border-radius: 4px;
  background-color: #ffffff;
  overflow: hidden;
`

const ContentBox = styled.div`
  padding: ${(props) => `${props.theme.spacing[4]} ${props.theme.spacing[4]}`};
`

const ImageBox = styled.div`
  display: flex;
  height: 100%;
  img {
    width: 100%;
    margin: auto;
  }
`

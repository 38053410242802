import {useTemplate} from 'Event/TemplateProvider'
import {useSaveTemplate} from 'Event/TemplateUpdateProvider'
import TextField from 'lib/ui/TextField'
import {useConfig} from 'organization/Event/Configurable'
import Config from 'organization/Event/Configurable/Config'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import React from 'react'

export default function TitleConfig() {
  const save = useSaveTemplate()
  const {setPasswordForm} = useTemplate()

  const {
    form: {register},
  } = useConfig()

  return (
    <Config title="Title" onSave={save}>
      <SettingsPanel>
        <TextField
          name="setPasswordForm.title"
          defaultValue={setPasswordForm.title}
          label="Text"
          fullWidth
          inputProps={{
            'aria-label': 'set password form title',
            ref: register,
          }}
        />
      </SettingsPanel>
    </Config>
  )
}

import React from 'react'
import styled from 'styled-components'
import {useTownhallTemplate} from 'Event/template/Townhall'

export default function Links() {
  const template = useTownhallTemplate()
  const {footer} = template

  return (
    <Container>
      <Link
        link={footer.termsLink}
        label="Terms of Service"
        aria-label="terms of service"
      />
      <Link
        link={footer.privacyLink}
        label="Privacy Policy"
        aria-label="privacy policy"
      />
    </Container>
  )
}

function Link(props: {
  label: string
  link: string | null
  'aria-label': string
}) {
  if (!props.link) {
    return null
  }

  return (
    <a href={props.link} aria-label={props['aria-label']}>
      {props.label}
    </a>
  )
}

const Container = styled.div`
  a {
    &:not(:last-child):after {
      content: ' • ';
    }
  }
`

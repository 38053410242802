import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import {PaymentMethod} from '@stripe/stripe-js'
import {useBroadcasts} from 'lib/comms-api/events/broadcasts/list'
import {usePaymentMethods} from 'lib/event-api/stripe/payment_methods/list'
import {useRemovePaymentMethod} from 'lib/event-api/stripe/payment_methods/remove'
import ConfirmDialog from 'lib/ui/ConfirmDialog'
import {CloseIcon} from 'lib/ui/Icon'
import IconButton from 'lib/ui/IconButton'
import {Loader} from 'lib/ui/Loader'
import ErrorAlert from 'lib/ui/alerts/ErrorAlert'
import {useDefaultPaymentMethod} from 'obvio/Billing/DefaultPaymentMethodProvider'
import React, {useEffect, useState} from 'react'
import styled from 'styled-components'

export default function OtherCardsTable() {
  const {data: paymentMethods} = usePaymentMethods()
  const {paymentMethod: defaultPaymentMethod} = useDefaultPaymentMethod()
  const [hasScheduledBroadcast, setHasScheduledBroadcast] = useState(false)
  const [responseError, setResponseError] = useState<string | null>(null)

  if (!paymentMethods) {
    return null
  }

  const otherPaymentMethods = paymentMethods.filter(
    (pm) => pm.id !== defaultPaymentMethod?.id,
  )

  if (otherPaymentMethods.length === 0) {
    return <p>No other credit cards have been added.</p>
  }

  return (
    <>
      <ErrorAlert>{responseError}</ErrorAlert>
      <ErrorAlert showing={hasScheduledBroadcast}>
        Could not remove card with an active broadcast scheduled.
      </ErrorAlert>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Brand</TableCell>
            <TableCell>Last 4 Digits</TableCell>
            <TableCell>{/* Action cell */}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {otherPaymentMethods.map((paymentMethod) => (
            <TableRow key={paymentMethod.id}>
              <TableCell>{paymentMethod.card?.brand}</TableCell>
              <TableCell>{paymentMethod.card?.last4}</TableCell>
              <ActionCell>
                <RemovePaymentMethodButton
                  paymentMethod={paymentMethod}
                  setHasScheduledBroadcast={setHasScheduledBroadcast}
                  setResponseError={setResponseError}
                />
              </ActionCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  )
}

function RemovePaymentMethodButton(props: {
  paymentMethod: PaymentMethod
  setHasScheduledBroadcast: (exists: boolean) => void
  setResponseError: (error: string | null) => void
}) {
  const {
    paymentMethod: {id: paymentMethodId, card},
    setHasScheduledBroadcast,
    setResponseError,
  } = props
  const [removing, setRemoving] = useState(false)

  const {mutate: remove} = useRemovePaymentMethod()

  const {data: broadcasts, isFetching} = useBroadcasts({
    status: 'in_future',
    paymentMethodId,
    enabled: removing,
  })

  const hasScheduledBroadcast = isFetching
    ? undefined
    : broadcasts && broadcasts.total > 0

  useEffect(() => {
    setResponseError(null)
    setHasScheduledBroadcast(false)

    // If we're still checking for scheduled broadcasts then we'll return
    // early here
    if (hasScheduledBroadcast === undefined) {
      return
    }

    if (hasScheduledBroadcast) {
      setHasScheduledBroadcast(true)
      setRemoving(false)
      return
    }

    remove(paymentMethodId, {
      onError: (e: any) => {
        setResponseError(e)
        setRemoving(false)
      },
    })
  }, [
    hasScheduledBroadcast,
    setHasScheduledBroadcast,
    paymentMethodId,
    remove,
    setResponseError,
    removing,
  ])

  if (removing) {
    return <Loader />
  }

  return (
    <ConfirmDialog
      description={`You are removing your ${card?.brand} card ending in ${card?.last4}.`}
      onConfirm={() => {
        setRemoving(true)
      }}
      confirmLabel="Remove"
    >
      {(confirm) => (
        <IconButton onClick={confirm} aria-label="remove payment method">
          <CloseIcon />
        </IconButton>
      )}
    </ConfirmDialog>
  )
}

const ActionCell = styled(TableCell)`
  width: 0;
  text-align: center;
`

import {useTemplate} from 'Event/TemplateProvider'
import React from 'react'
import styled from 'styled-components'

export default function Text(props: {
  children: string | JSX.Element
  'aria-label'?: string
  className?: string
}) {
  const template = useTemplate()
  const color = template.changePassword.description.color
  const fontSize = template.changePassword.description.fontSize

  if (!props.children) {
    return null
  }

  return (
    <StyledText
      color={color}
      fontSize={fontSize}
      aria-label={props['aria-label']}
      className={props.className}
    >
      {props.children}
    </StyledText>
  )
}

export const StyledText = styled.div<{
  color?: string | null
  fontSize: number
}>`
  color: ${(props) => props.color};
  font-size: ${(props) => props.fontSize}px;
  font-weight: 500;
  margin-bottom: ${(props) => props.theme.spacing[8]};
  text-align: center;
`

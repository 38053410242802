import React from 'react'
import styled from 'styled-components'
import Button from '@material-ui/core/Button'
import {api} from 'lib/url'
import {useOrganization} from 'organization/OrganizationProvider'
import {
  OrganizationEmail,
  useEmails,
} from 'organization/Settings/CommunicationSettings/EmailsProvider'
import {useValidatedForm} from 'lib/form'
import {useToggleArray} from 'lib/toggle'
import TextField from 'lib/ui/TextField'
import Grid from '@material-ui/core/Grid'

export interface AddEmailData {
  name: string
  email: string
}

export default function AddEmailForm(props: {onSuccess: () => void}) {
  const {
    register,
    handleSubmit,
    reset: resetForm,
    errors,
    setResponseError,
    clearErrors: clearFormErrors,
    hasErrors,
  } = useValidatedForm()
  const {onSuccess} = props

  const [submitting, toggleSubmitting] = useToggleArray()
  const {client, organization} = useOrganization()
  const {add} = useEmails()

  const submit = (data: AddEmailData) => {
    if (submitting) {
      return
    }

    toggleSubmitting()
    clearFormErrors()

    const url = api(`/organizations/${organization.id}/emails`)
    client
      .post<OrganizationEmail>(url, data)
      .then((email) => {
        add(email)
        resetForm()
        onSuccess()
      })
      .catch(setResponseError)
      .finally(toggleSubmitting)
  }

  return (
    <StyledForm onSubmit={handleSubmit(submit)} hasDesktopMargins={hasErrors}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={5}>
          <StyledTextField
            name="name"
            error={!!errors.name}
            helperText={errors.name}
            disabled={submitting}
            variant="outlined"
            label="From Name"
            fullWidth
            inputProps={{
              ref: register({
                required: true,
                validate: (val) => {
                  // We're using the pipe | as the delimiter in email
                  // fields, so we'll need to prevent that input.
                  if (/\|/.test(val)) {
                    return 'Invalid character in name.'
                  }

                  return true
                },
              }),
              'aria-label': 'name',
            }}
            disableMargin={hasErrors}
          />
        </Grid>
        <Grid item xs={12} sm={5}>
          <StyledTextField
            name="email"
            error={!!errors.email}
            helperText={errors.email}
            disabled={submitting}
            variant="outlined"
            label="From Email"
            fullWidth
            inputProps={{
              ref: register,
              'aria-label': 'email',
            }}
            disableMargin={hasErrors}
          />
        </Grid>
        <SaveButtonBox item>
          <SaveButton
            disabled={submitting}
            variant="contained"
            color="primary"
            type="submit"
          >
            Add
          </SaveButton>
        </SaveButtonBox>
      </Grid>
    </StyledForm>
  )
}

const StyledTextField = styled(TextField)<{disableMargin: boolean}>`
  margin-bottom: 0;

  input {
    margin-bottom: 0;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.sm}) {
    margin-bottom: ${(props) =>
      props.disableMargin ? 0 : props.theme.spacing[8]};
  }
`

const StyledForm = styled.form<{hasDesktopMargins: boolean}>`
  margin-bottom: ${(props) => props.theme.spacing[5]};

  @media (min-width: ${(props) => props.theme.breakpoints.sm}) {
    margin-bottom: ${(props) =>
      props.hasDesktopMargins ? props.theme.spacing[3] : 0};
  }
`

const SaveButtonBox = styled(Grid)`
  display: flex;
  align-items: center;
  width: 100%;

  @media (min-width: ${(props) => props.theme.breakpoints.sm}) {
    width: auto;
  }
`

const SaveButton = styled(Button)`
  width: 100%;

  @media (min-width: ${(props) => props.theme.breakpoints.sm}) {
    width: auto;
    margin-bottom: 2px;
  }
`

import {ObvioEvent} from 'Event'
import {api} from 'lib/url'
import {useOrganization} from 'organization/OrganizationProvider'
import {useQuery} from 'react-query'

export function useEvents() {
  const {client, organization} = useOrganization()

  return useQuery({
    queryKey: ['organizations', organization.id, 'events'],
    queryFn: () =>
      client.get<Array<ObvioEvent>>(
        api(`/organizations/${organization.id}/events`),
      ),
  })
}

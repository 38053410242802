import React from 'react'
import {Controller, UseFormMethods} from 'react-hook-form'
import {useLeftyTemplate} from 'Event/template/Lefty'
import Title from 'lib/ui/ConfigPanel/Title'
import {Label} from 'lib/ui/typography'
import Slider from 'lib/ui/Slider'
import FontSelect from 'lib/FontSelect'
import ColorPicker from 'lib/ui/ColorPicker'
import Select from 'lib/ui/Select'
import Option from 'lib/ui/Select/Option'
import {onUnknownChangeHandler} from 'lib/dom'

const DEFAULT_FONT_SIZE = 42
const MAX_FONT_SIZE = 50
const MIN_FONT_SIZE = 16

export const WELCOME_TEXT_ALIGN_LEFT = 'Left'
export const WELCOME_TEXT_ALIGN_CENTER = 'Center'
export const WELCOME_TEXT_ALIGN_RIGHT = 'Right'

export type WELCOME_TEXT_ALIGN =
  | typeof WELCOME_TEXT_ALIGN_LEFT
  | typeof WELCOME_TEXT_ALIGN_CENTER
  | typeof WELCOME_TEXT_ALIGN_RIGHT

const WELCOME_TEXT_ALIGNS: WELCOME_TEXT_ALIGN[] = [
  WELCOME_TEXT_ALIGN_LEFT,
  WELCOME_TEXT_ALIGN_CENTER,
  WELCOME_TEXT_ALIGN_RIGHT,
]

export default function Styling(props: {control: UseFormMethods['control']}) {
  const {control} = props
  const template = useLeftyTemplate()
  const welcomeTextColor = template.welcomeTextColor
    ? template.welcomeTextColor
    : template.textColor

  return (
    <>
      <Title>Typography</Title>
      <Label>Font</Label>
      <Controller
        control={control}
        name="welcomeTextFont"
        defaultValue={template.welcomeTextFont || null}
        render={({value, onChange}) => (
          <FontSelect value={value} onChange={onChange} />
        )}
      />
      <Controller
        name="welcomeTextColor"
        control={control}
        defaultValue={welcomeTextColor}
        render={({value, onChange}) => (
          <ColorPicker
            label="Font Color"
            aria-label="welcome text color"
            color={value}
            onPick={onChange}
          />
        )}
      />
      <Controller
        name="welcomeTextFontSize"
        defaultValue={template.welcomeTextFontSize || DEFAULT_FONT_SIZE}
        control={control}
        render={({value, onChange}) => (
          <Slider
            aria-label="welcome text font size"
            min={MIN_FONT_SIZE}
            max={MAX_FONT_SIZE}
            onChange={onChange}
            valueLabelDisplay="off"
            value={value}
            label="Font Size"
          />
        )}
      />
      <Controller
        name="welcomeTextAlign"
        defaultValue={template.welcomeTextAlign}
        control={control}
        render={({value, onChange}) => (
          <Select
            fullWidth
            label="Text Align"
            value={value}
            onChange={onUnknownChangeHandler(onChange)}
          >
            {WELCOME_TEXT_ALIGNS.map((align) => (
              <Option value={align}>{align}</Option>
            ))}
          </Select>
        )}
      />
    </>
  )
}

import React from 'react'
import {DesktopOnly, MobileOnly} from 'lib/ui/screen'
import ButtonBase, {ButtonProps} from 'lib/ui/Button'
import Show from 'lib/Show'

export default function Button(
  props: Partial<ButtonProps> & {
    text: string
    textCollapsed?: string
  },
) {
  const {text, textCollapsed, ...buttonOverrides} = props

  const buttonProps: Omit<ButtonProps, 'children'> = {
    variant: 'contained',
    color: 'success',
    ...buttonOverrides,
  }

  if (!textCollapsed) {
    return <ButtonBase {...buttonProps}>{text}</ButtonBase>
  }

  return (
    <>
      <DesktopButton {...buttonProps}>{text}</DesktopButton>
      <Show when={Boolean(textCollapsed)}>
        <MobileButton {...buttonProps}>{textCollapsed}</MobileButton>
      </Show>
    </>
  )
}

const DesktopButton = DesktopOnly(ButtonBase)

const MobileButton = MobileOnly(ButtonBase)

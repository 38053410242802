import React from 'react'
import styled from 'styled-components'
import {TownhallBlogPost} from 'Event/template/Townhall/Dashboard/Main/BlogSection'

export default function Image(props: {post: TownhallBlogPost}) {
  const {post} = props

  if (!post.image) {
    return null
  }
  return (
    <Box>
      <img src={post.image} alt="blog post" aria-label="blog post image" />
    </Box>
  )
}

const Box = styled.div`
  display: flex;
  height: 100%;
  img {
    width: 100%;
    margin: auto;
  }
`

import React, {useState, useEffect} from 'react'
import InputLabel from '@material-ui/core/InputLabel'
import {FieldProps, useSavedValue} from 'Event/Question'
import {useAttendeeVariables} from 'Event'
import styled from 'styled-components'
import {Controller} from 'react-hook-form'
import {DragDropContext, DropResult} from 'react-beautiful-dnd'
import {
  Question,
  QuestionBase,
  HasOptions,
  QuestionWithOptions,
} from 'organization/Event/QuestionsProvider'
import Answers from 'Event/Question/SelectPriority/Answers'
import Options from 'Event/Question/SelectPriority/Options'

export const SELECT_PRIORITY = 'select_priority'

export const DROPPABLE_ID_ANSWERS = 'answers'
export const DROPPABLE_ID_OPTIONS = 'options'

export type SelectPriorityQuestion = QuestionBase &
  HasOptions & {
    type: typeof SELECT_PRIORITY
    // Possible the styles have not been set yet, ie., when a question
    // has just been saved. So we'll mark as an optional here.
    styles: {
      optionBackgroundColor: string
      optionTextColor: string
      boxBackgroundColor: string
      boxTextColor: string
      borderRadius: number
    } | null
  }

export type SelectPriorityProps = FieldProps & {
  question: SelectPriorityQuestion
}

export default function SelectPriority(props: SelectPriorityProps) {
  useSavedValue(props)
  const v = useAttendeeVariables()
  const {inputStyles} = props

  return (
    <Container>
      <StyledInputLabel color={inputStyles?.labelColor}>
        {v(props.question.label)}
      </StyledInputLabel>
      <Controller
        name={props.name}
        control={props.control}
        defaultValue={props.answer}
        rules={{
          required: props.question.is_required && 'This is required',
        }}
        render={({value, onChange}) => (
          <Input onChange={onChange} value={value} {...props} />
        )}
      />
    </Container>
  )
}

function Input(
  props: SelectPriorityProps & {
    onChange: (...events: any[]) => void
    value: string | null
  },
) {
  const {question, value, onChange} = props

  const {
    answers,
    add: addAnswer,
    remove: removeAnswer,
    move: moveAnswer,
  } = useAnswers(value)
  const {options, add: addOption, remove: removeOption} = useOptions(
    question,
    answers,
  )

  const selectOption = (optionIndex: number, answerIndex?: number) => {
    const value = removeOption(optionIndex)
    const toIndex = answerIndex === undefined ? answers.length : answerIndex

    addAnswer(toIndex, value)
  }

  const unselectAnswer = (answerIndex: number, optionIndex?: number) => {
    const value = removeAnswer(answerIndex)
    const toIndex = optionIndex === undefined ? options.length : optionIndex
    addOption(toIndex, value)
  }

  const handleDrag = useHandleDrag(selectOption, unselectAnswer, moveAnswer)

  useEffect(() => {
    // No answer has been set yet
    const isBlank = !value && answers.length === 0
    if (isBlank) {
      return
    }

    // If unchanged value
    const updated = answers.join(', ')
    if (updated === value) {
      return
    }

    onChange(updated)
  }, [answers, onChange, value])

  return (
    <DragDropContext onDragEnd={handleDrag}>
      <InputBox>
        <Answers
          question={question}
          answers={answers}
          onRemove={unselectAnswer}
        />
        <Options
          options={options}
          onSelect={selectOption}
          question={question}
        />
      </InputBox>
    </DragDropContext>
  )
}

function useAnswers(value: string | null) {
  const [answers, setAnswers] = useState<string[]>([])

  //Load answers from question value which is a comma delimited string
  useEffect(() => {
    if (!value) {
      return
    }

    setAnswers(value.split(', '))
  }, [value])

  const remove = (index: number) => {
    const val = answers[index]
    if (!val) {
      throw new Error(`Invalid index for answers: ${index}`)
    }

    setAnswers((answers) => answers.filter((_, i) => i !== index))

    return val
  }

  const add = (index: number, value: string) => {
    const added = Array.from(answers)
    added.splice(index, 0, value)
    setAnswers(added)
  }

  const move = (fromIndex: number, toIndex: number) => {
    const moved = Array.from(answers)
    const [removed] = moved.splice(fromIndex, 1)
    moved.splice(toIndex, 0, removed)

    setAnswers(moved)
  }

  return {answers, remove, add, move}
}

function useOptions(question: QuestionWithOptions, answers: string[]) {
  const [options, setOptions] = useState<string[]>(
    question.options.map((o) => o.value),
  )

  useEffect(() => {
    const all = question.options.map((o) => o.value)
    const withoutSelected = all.filter((o) => !answers.includes(o))

    setOptions(withoutSelected)
  }, [question, answers])

  const remove = (index: number) => {
    const val = options[index]
    if (!val) {
      throw new Error(`Invalid index for options: ${index}`)
    }

    const removed = options.filter((_, i) => i !== index)
    setOptions(removed)

    return val
  }

  const add = (index: number, value: string) => {
    const added = Array.from(options)
    added.splice(index, 0, value)
    setOptions(added)
  }

  return {options, remove, add}
}

function useHandleDrag(
  selectOption: (optionIndex: number, answerIndex: number) => void,
  unselectAnswer: (answerIndex: number, optionIndex: number) => void,
  moveAnswer: (fromIndex: number, toIndex: number) => void,
) {
  return (result: DropResult) => {
    const {destination, source} = result

    if (!destination) {
      return
    }

    const selected =
      source.droppableId === DROPPABLE_ID_OPTIONS &&
      destination.droppableId === DROPPABLE_ID_ANSWERS

    if (selected) {
      selectOption(source.index, destination.index)
    }

    const unselected =
      source.droppableId === DROPPABLE_ID_ANSWERS &&
      destination.droppableId === DROPPABLE_ID_OPTIONS

    if (unselected) {
      unselectAnswer(source.index, destination.index)
    }

    const moved =
      source.droppableId === DROPPABLE_ID_ANSWERS &&
      destination.droppableId === DROPPABLE_ID_ANSWERS

    if (moved) {
      moveAnswer(source.index, destination.index)
    }
  }
}

const InputBox = styled.div`
  display: flex;
  margin: 0 -16px;

  > div {
    flex: 1;
    margin: 0 8px;
    padding: 8px 16px;
  }
`

const StyledInputLabel = styled((props) => {
  const {color: _, ...otherProps} = props
  return <InputLabel {...otherProps} />
})`
  color: ${(props) => (props.color ? `${props.color} !important;` : '')};
  margin-bottom: ${(props) => props.theme.spacing[2]};
  font-family: inherit;
`

export const Box = styled.div<{
  backgroundcolor?: string | null
  textColor?: string | null
  borderRadius?: number
}>`
  background-color: ${(props) => props.backgroundcolor};
  color: ${(props) => props.textColor};
  padding: ${(props) => `${props.theme.spacing[2]} ${props.theme.spacing[2]}`};
  border-radius: ${(props) => props.borderRadius}px;
`

export function getStyles(question?: Question) {
  const existingStyles = isSelectPriority(question) ? question.styles : {}

  return {
    optionBackgroundColor: '#FFFFFF',
    optionTextColor: '#000000',
    boxBackgroundColor: '#FFFFFF',
    boxTextColor: '#000000',
    borderRadius: 0,
    ...existingStyles,
  }
}

export function isSelectPriority(
  question?: Question,
): question is SelectPriorityQuestion {
  if (!question) {
    return false
  }

  return question.type === SELECT_PRIORITY
}

const Container = styled.div`
  margin-bottom: ${(props) => props.theme.spacing[2]};
`

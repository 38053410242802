import {useEffect, useRef} from 'react'

export function useDelay(callback: () => void, delayMs: number) {
  const isMountedRef = useRef(true)
  const timer = useRef(0)

  useEffect(() => {
    isMountedRef.current = true
    timer.current = window.setTimeout(callback, delayMs)

    return () => {
      isMountedRef.current = false
      window.clearTimeout(timer.current)
    }
  }, [callback, delayMs])
}

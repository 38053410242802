import Container from 'Event/Marketplace/Block/Container'
import React from 'react'
import styled from 'styled-components'
import {Icon as DefaultIcon, IconProps} from 'lib/fontawesome/Icon'
import {BlockComponentProps} from 'Event/Marketplace/Block'
import {BlockBase} from 'Event/Marketplace/Block/base'

export interface IconBlock extends BlockBase {
  icon: string | null
  color: string
  size: number
  opacity: number
  type: 'Icon'
}

export default function Icon(props: IconBlock & BlockComponentProps) {
  const {icon} = props

  return (
    <StyledContainer aria-label={`${icon} holder`} {...props}>
      <StyledIcon iconClass={icon} {...props} />
    </StyledContainer>
  )
}

export const StyledIcon = styled(
  (
    props: IconProps & {
      color: string
      size: number
      opacity: number
    },
  ) => {
    return <DefaultIcon {...props} />
  },
)`
  font-size: ${(props) => props.size}cqw;
  opacity: ${(props) => props.opacity}%;
  color: ${(props) => props.color};
`

const StyledContainer = styled(Container)`
  container-type: inline-size;
`

import React from 'react'
import {isAssetUrl, useCopyToEvent} from 'lib/asset'
import {isObject} from 'lib/object'
import Page from 'organization/Event/Page'
import Grid from '@material-ui/core/Grid'
import PageHeader from 'lib/ui/PageHeader'
import Title from 'lib/ui/PageHeader/Title'
import TemplateCard, {
  Action,
  BlankOption,
  CREATE_BLANK,
  SampleOption,
  useTemplateCard,
} from 'organization/Event/SelectTemplatePage/TemplateCard'
import {templates, Template, createBlank, sampleEventURL} from 'Event/template'
import {useEvent} from 'Event/EventProvider'
import {api} from 'lib/url'
import {useOrganization} from 'organization/OrganizationProvider'
import {ObvioEvent} from 'Event'
import {useToggle} from 'lib/toggle'
import Button from 'lib/ui/Button'
import FullPageLoader from 'lib/ui/layout/FullPageLoader'
import {useHasOrganizationFeatureToggle} from 'organization/OrganizationFeatureToggle'

export default function SelectTemplatePage() {
  const setTemplate = useSetTemplate()
  const {flag: processing, toggle: toggleProcessing} = useToggle()

  const createData = useCreateData()

  const handleSelect = (template: Template['name'], action: Action) => {
    if (processing) {
      return
    }
    toggleProcessing()

    createData(template, action).then(setTemplate).catch(toggleProcessing)
  }

  const hasTownhallFlag = useHasOrganizationFeatureToggle('townhall')
  const names = Object.keys(templates).filter((name) => {
    if (name === 'Townhall') {
      return hasTownhallFlag
    }

    return true
  }) as Template['name'][]

  if (processing) {
    return <FullPageLoader />
  }

  return (
    <Page>
      <PageHeader>
        <Title text="Choose Your Event's Template" />
      </PageHeader>
      <Grid container spacing={2}>
        {names.map((name) => {
          return (
            <Grid item xs={12} md={4} key={name}>
              <TemplateCard
                template={name}
                disabled={processing}
                defaultAction={CREATE_BLANK}
                options={
                  <>
                    <BlankOption />
                    <SampleOption />
                  </>
                }
                selectButton={
                  <SelectButton disabled={processing} onSelect={handleSelect} />
                }
              />
            </Grid>
          )
        })}
      </Grid>
    </Page>
  )
}

function SelectButton(props: {
  disabled: boolean
  onSelect: (template: Template['name'], action: Action) => void
}) {
  const {action, template} = useTemplateCard()
  const {onSelect} = props

  return (
    <Button
      variant="outlined"
      color="primary"
      fullWidth
      disabled={props.disabled}
      aria-label={template}
      onClick={() => onSelect(template, action)}
    >
      Select
    </Button>
  )
}

function useCreateData() {
  const withCopiedAssets = useDuplicateAssets<Template>()
  const fetchSample = useFetchSampleEvent()

  return async (template: Template['name'], action: Action) => {
    switch (action) {
      case 'create_blank':
        return Promise.resolve(createBlank(template))
      case 'create_sample':
        const event = await fetchSample(template)
        return withCopiedAssets(event.template)
      default:
        throw new Error(`Unhandled template action: ${action}`)
    }
  }
}

export function useSetTemplate() {
  const {event, set: setEvent} = useEvent()
  const url = api(`/events/${event.id}/template`)
  const {client} = useOrganization()

  return (template: Template) =>
    client
      .post<ObvioEvent>(url, {template})
      .then(setEvent)
}

export function useDuplicateAssets<T>(): (template: T) => Promise<T> {
  const {event} = useEvent()
  const copy = useCopyToEvent(event)

  const process = async (template: Record<string, any>) => {
    const assetUrls = getAssetUrls(template)
    const assetsLength = assetUrls.length
    const copiedAssets = await Promise.all(assetUrls.map(copy))
    let templateString = JSON.stringify(template)
    for (let i = 0; i < assetsLength; i++) {
      templateString = templateString.replace(
        assetUrls[i],
        copiedAssets[i].file.url,
      )
    }

    return JSON.parse(templateString)
  }

  return (template: T) => process(template as Record<string, any>) as Promise<T>
}

export function getAssetUrls(template: Record<string, any>) {
  const result: string[] = []

  for (const [_, val] of Object.entries(template)) {
    if (isObject(val)) {
      result.push(...getAssetUrls(val))
      continue
    }

    // Only care about strings as assets are just saved as urls
    if (typeof val !== 'string') {
      continue
    }

    // Is regular string
    if (!isAssetUrl(val)) {
      continue
    }

    result.push(val)
  }

  return result
}

export function useFetchSampleEvent() {
  const {client} = useOrganization()

  return async (template: Template['name']) => {
    const url = sampleEventURL(template)
    const event = await client.get<ObvioEvent>(url)
    if (!event.template) {
      throw new Error('Sample event missing template.')
    }

    // Not the prettiest cast, but we've already checked above that
    // the template exists.
    return event as ObvioEvent & {
      template: NonNullable<ObvioEvent['template']>
    }
  }
}

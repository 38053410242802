import styled from 'styled-components'

const Step = styled.div<{showing: boolean}>`
  opacity: ${(props) => (props.showing ? 1 : 0)};
  visibility: ${(props) => (props.showing ? 'visible' : 'hidden')};
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

export default Step

import {MarketplaceAccount} from 'lib/marketplace-api/accounts'
import {marketplaceApi} from 'lib/marketplace-api/url'
import {useOrgMarketplaceClient} from 'lib/marketplace-api/use-org-marketplace-client'
import {useOrganization} from 'organization/OrganizationProvider'
import {useQuery} from 'react-query'

export function useMarketplaceAccount() {
  const {client} = useOrgMarketplaceClient()
  const {organization} = useOrganization()

  return useQuery({
    queryKey: ['marketplace', 'account'],
    queryFn: () =>
      client!
        .get<{account: MarketplaceAccount | null}>(
          marketplaceApi(`/organizations/${organization.id}/account`),
        )
        .then((data) => data.account),
    enabled: client !== null,
    refetchInterval(data) {
      // No account setup is happening yet.
      if (!data) {
        return false
      }

      // If account has completed setup, we won't refetch.
      if (data?.completed_setup) {
        return false
      }

      // Re-fetch every 3s until setup is complete.
      return 3_000
    },
  })
}

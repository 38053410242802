import {Inputs as Checkbox, CHECKBOX} from 'Event/Question/Checkbox'
import {RadioInput as Radio, RADIO} from 'Event/Question/Radio'
import {SelectInput as Select, SELECT} from 'Event/Question/Select'
import {QuestionWithOptions} from 'organization/Event/QuestionsProvider'
import React from 'react'

export default function OptionSelect(props: {
  question: QuestionWithOptions | null
  value: string
  onChange: (value: string) => void
}) {
  const {question, value, onChange} = props
  if (!question) {
    return null
  }

  return <Input question={question} value={value} onChange={onChange} />
}

function Input(props: {
  question: QuestionWithOptions
  value: string
  onChange: (value: string) => void
}) {
  const {question, value, onChange} = props
  const {type} = question

  switch (question.type) {
    case RADIO:
      const otherOptionVisible =
        Boolean(value) &&
        !props.question.options.map(({value}) => value).includes(value)

      return (
        <Radio
          otherOptionVisible={otherOptionVisible}
          otherInputVisible={false} // Only allow user to select 'other' but not input text
          question={question}
          value={value}
          onChange={onChange}
        />
      )
    case SELECT:
      return <Select question={question} value={value} onChange={onChange} />
    case CHECKBOX:
      return <Checkbox question={question} value={value} onChange={onChange} />
    default:
      throw new Error(
        `Missing FormResponseRule/OptionSelect for question type: '${type}'.`,
      )
  }
}

import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import {RoomDetails} from 'Event/Dashboard/TeamAccessControls/index'
import TechCheckActions from 'Event/Dashboard/TeamAccessControls/RoomControls/TechCheckActions'
import MeetingButtons from 'Event/Dashboard/TeamAccessControls/RoomControls/MeetingButtons'
import Box from '@material-ui/core/Box'
import FiberManualRecordRoundedIcon from '@material-ui/icons/FiberManualRecordRounded'

type RoomControlsContextProps = {
  room: RoomDetails
  setRoom: (room: RoomDetails) => void
}

const RoomControlsContext = React.createContext<
  undefined | RoomControlsContextProps
>(undefined)

export default function RoomControls(props: {room: RoomDetails}) {
  const {room} = props
  const {area} = room

  const [current, setCurrent] = useState(room)

  // Always update current on refresh
  useEffect(() => {
    setCurrent(room)
  }, [room])

  return (
    <RoomControlsContext.Provider
      value={{
        room: current,
        setRoom: setCurrent,
      }}
    >
      <Box justifyContent="space-between" display="flex" paddingY={4}>
        <Box>
          <span>
            Area: <StatusIndicator isOnline={area.is_open} /> {room.area.name}
          </span>
          <br />
          <span>
            Room: <StatusIndicator isOnline={room.is_online} /> {room.number}{' '}
            {room.description}
          </span>
        </Box>
        <Box>
          <MeetingButtons />
        </Box>
      </Box>
      <TechCheckOnly room={room} />
    </RoomControlsContext.Provider>
  )
}

export function useRoomControls() {
  const context = React.useContext(RoomControlsContext)
  if (context === undefined) {
    throw new Error('useRoomControls must be used within RoomControls')
  }

  return context
}

function TechCheckOnly(props: {room: RoomDetails}) {
  const isTechCheck = props.room.area.is_tech_check
  if (!isTechCheck) {
    return null
  }

  return <TechCheckActions room={props.room} />
}

const StatusIndicator = styled(({isOnline: _, ...otherProps}) => (
  <FiberManualRecordRoundedIcon {...otherProps} fontSize="small" />
))<{
  isOnline: boolean
}>`
  color: ${(props) => (props.isOnline ? 'green' : 'red')};
  margin: -4px;
`

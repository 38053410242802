import React from 'react'
import {useGuestVariables} from 'Event'
import TextField from 'Event/auth/Login/TextField'
import {useTemplate} from 'Event/TemplateProvider'
import {UseFormMethods} from 'react-hook-form'
import Configurable from 'organization/Event/Configurable'
import FirstNameFieldConfig from './FirstNameFieldConfig'
import {useQueryParams} from 'lib/url'

export default function FirstNameField(props: {
  submitting: boolean
  errors: Record<string, any>
  register: UseFormMethods['register']
}) {
  const {signUp} = useTemplate()
  const v = useGuestVariables()
  const firstNameLabel = v(signUp.firstNameLabel)
  const {first_name} = useQueryParams()

  return (
    <Configurable>
      <FirstNameFieldConfig />
      <TextField
        label={firstNameLabel}
        type="text"
        fullWidth
        variant="outlined"
        name="first_name"
        disabled={props.submitting}
        defaultValue={first_name}
        inputProps={{
          ref: props.register({
            required: `First Name is required`,
          }),
          'aria-label': 'first name',
        }}
        error={!!props.errors.first_name}
        helperText={props.errors.first_name}
      />
    </Configurable>
  )
}

import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import CustomButtonFontSizeEditing from './custombuttonfontsizeediting';
import CustomButtonFontSizeUI from './custombuttonfontsizeui';

export default class CustomButtonFontSize extends Plugin {
	static get requires() {
		return [ CustomButtonFontSizeEditing, CustomButtonFontSizeUI ];
	}

	static get pluginName() {
		return 'CustomButtonFontSize';
	}
}

import React, {useEffect, useState} from 'react'
import styled from 'styled-components'

import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Typography from '@material-ui/core/Typography'
import Dialog from '@material-ui/core/Dialog'

import Button, {ButtonProps} from 'lib/ui/Button'
import Box from 'lib/ui/Box'
import {useToggleArray} from 'lib/toggle'

const DEFAULT_TITLE = 'Are you sure?'
const DEFAULT_CONFIRM_LABEL = 'Confirm'

type ConfirmationDialogVariant = 'info' | 'dangerous'

export default function ConfirmDialog<
  TEvent extends Record<string, any> | string | React.MouseEvent
>(props: {
  onConfirm: (event?: TEvent) => void
  children?: (confirm: (body?: TEvent) => void) => React.ReactElement
  description?: string | React.ReactElement
  showing?: boolean
  title?: string
  onCancel?: (toggleVisible: () => void) => void
  confirmLabel?: string
  variant?: ConfirmationDialogVariant
  skip?: boolean
  buttonsDisplay?: 'horizontally' | 'vertically'
}) {
  const {showing, onCancel} = props
  const [isVisible, toggleVisible] = useToggleArray()
  const [content, setContent] = useState(props.description)
  const [event, setEvent] = useState<TEvent | undefined>()
  const {description, skip, onConfirm} = props

  // In case the description was dynamically updated, we'll probably want to
  // update it here too.
  useEffect(() => {
    setContent(description)
  }, [description])

  const handleConfirm = () => {
    // Handling visibility internally
    if (showing === undefined) {
      toggleVisible()
    }

    onConfirm(event)
  }

  // Show the confirmation dialog. The children may optionally pass in
  // the description / content at this stage too.
  const showConfirm = (body?: string | TEvent) => {
    const incomingEvent = body && typeof body === 'object' ? body : null
    if (incomingEvent) {
      // Check if a 'persist' function is passed in. If it is, we want to freeze the click
      // event to prevent it being re-used, and all the values being set to `null`.
      // Reference: https://stackoverflow.com/questions/26317985/react-js-onclick-event-returning-all-null-values
      if ('persist' in incomingEvent) {
        incomingEvent.persist &&
          typeof incomingEvent.persist === 'function' &&
          incomingEvent.persist()
      }
      setEvent(incomingEvent)
    }

    if (skip) {
      onConfirm(incomingEvent || undefined)
      return
    }

    // Have to explicitly check if it's a string in case we've passed
    // it into an event handler, and an event gets passed in here.
    const content = body && typeof body === 'string' ? body : null
    if (content) {
      setContent(content)
    }

    toggleVisible()
  }

  /**
   * If visibility is specified by parent, we'll prefer that. Otherwise
   * manage it internally.
   */
  const open = showing === undefined ? isVisible : showing

  const handleCancel = () => {
    if (onCancel) {
      onCancel(toggleVisible)
      return
    }

    toggleVisible()
  }

  const title = props.title || DEFAULT_TITLE
  const confirmLabel = props.confirmLabel || DEFAULT_CONFIRM_LABEL

  const variant: ConfirmationDialogVariant = props.variant || 'dangerous'

  return (
    <>
      <Dialog open={open} onClose={handleCancel}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <Content>{content}</Content>
        </DialogContent>
        <StyledDialogAction disableSpacing>
          <Box
            display={props.buttonsDisplay === 'vertically' ? 'block' : 'flex'}
          >
            <ButtonContainer>
              <ConfirmButton
                dialogVariant={variant}
                variant="contained"
                color="primary"
                onClick={handleConfirm}
                aria-label="confirm"
              >
                {confirmLabel}
              </ConfirmButton>
            </ButtonContainer>
            <ButtonContainer>
              <CancelButton
                dialogVariant={variant}
                variant="contained"
                color="primary"
                onClick={handleCancel}
              >
                Cancel
              </CancelButton>
            </ButtonContainer>
          </Box>
        </StyledDialogAction>
      </Dialog>
      {props.children && props.children(showConfirm)}
    </>
  )
}

function CancelButton(
  props: ButtonProps & {dialogVariant: ConfirmationDialogVariant},
) {
  const {dialogVariant, ...buttonProps} = props
  const color: ButtonProps['color'] =
    dialogVariant === 'dangerous' ? 'primary' : 'grey'

  return (
    <Button {...buttonProps} color={color} fullWidth>
      Cancel
    </Button>
  )
}

function ConfirmButton(
  props: ButtonProps & {dialogVariant: ConfirmationDialogVariant},
) {
  const {dialogVariant, ...buttonProps} = props
  if (dialogVariant === 'dangerous') {
    return <Button {...buttonProps} color="danger" fullWidth />
  }

  return <Button {...buttonProps} color="primary" fullWidth />
}

function Content(props: {children?: string | React.ReactElement}) {
  if (!props.children) {
    return null
  }

  // If we're supplied a component for description, we'll
  // just render that as-is.
  if (typeof props.children === 'object') {
    return props.children
  }

  return <Typography>{props.children}</Typography>
}

const StyledDialogAction = styled(DialogActions)`
  justify-content: center !important;
`

const ButtonContainer = styled(Box)`
  width: 220px;
  padding: ${(props) => props.theme.spacing[1]};
`

import {obvioRoutes} from 'obvio/Routes'
import Organizations from 'obvio/Organizations'
import CreateOrganizationForm from 'obvio/Organizations/CreateOrganizationForm'
import React from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import AccountSettings from 'obvio/AccountSettingsPage'
import BillingRoutes from 'obvio/Billing/BillingRoutes'
import MailchimpAuthCallbackHandler from 'organization/Event/Services/Apps/Mailchimp/MailchimpAuthCallbackHandler'
import BillingStatusOverlay from 'obvio/BillingStatusOverlay'
import PromoterDashboard from 'obvio/PromoterDashboard'
import AuthCallbackHandler from 'organization/Event/Services/Apps/HighLevel/AuthCallbackHandler'
import HubspotAuthCallbackHandler from 'organization/Event/Services/Apps/Hubspot/HubspotAuthCallbackHandler'

export default function UserRoutes() {
  return (
    <Switch>
      <Route path={obvioRoutes.billing.root}>
        <BillingRoutes />
      </Route>
      <Route>
        <BillingStatusOverlay />
        <SubscribedUserRoutes />
      </Route>
    </Switch>
  )
}

function SubscribedUserRoutes() {
  return (
    <Switch>
      <Route path={obvioRoutes.organizations.create}>
        <CreateOrganizationForm />
      </Route>
      <Route path={obvioRoutes.organizations.root}>
        <Organizations />
      </Route>
      <Route path={obvioRoutes.account}>
        <AccountSettings />
      </Route>
      <Route path={obvioRoutes.mailchimp.auth}>
        <MailchimpAuthCallbackHandler />
      </Route>
      <Route path={obvioRoutes.hubspot.auth}>
        <HubspotAuthCallbackHandler />
      </Route>
      <Route path={obvioRoutes.highlevel.auth}>
        <AuthCallbackHandler />
      </Route>
      <Route path={obvioRoutes.promoter}>
        <PromoterDashboard />
      </Route>
      <Redirect to={obvioRoutes.organizations.root} />
    </Switch>
  )
}

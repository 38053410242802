import OntraportConfig from 'organization/Event/Services/Apps/Ontraport/Config'
import {OntraportField} from 'organization/Event/Services/Apps/Ontraport/Config/Fields'
import {OntraportGroup} from 'organization/Event/Services/Apps/Ontraport/Config/Groups'
import {Tag} from 'organization/Event/Services/Apps/Ontraport/Config/Tags'
import LinkPage from 'organization/Event/Services/Apps/Ontraport/LinkPage'
import {
  BaseIntegration,
  ONTRAPORT,
  useServices,
} from 'organization/Event/Services/ServicesProvider'
import React from 'react'

export type OntraportIntagration = BaseIntegration & {
  service: typeof ONTRAPORT
  api_id: string
  api_key: string
  tags: Tag[]
  groups: OntraportGroup[]
  login_field_id: string | null
  login_field_name: string | null
  login_field_type: string | null
  login_field_key: string | null
  imported_at: string | null
  has_set_import_tag: boolean
}

export type LoginField = OntraportField

export default function Ontraport() {
  const {isLinked} = useServices()
  const showConfig = isLinked(ONTRAPORT)

  if (showConfig) {
    return <OntraportConfig />
  }

  return <LinkPage />
}

import Typography from '@material-ui/core/Typography'
import Page from 'organization/Event/Page'
import React, {useState} from 'react'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import ImportAttendeesButton from 'organization/Event/Services/Apps/ActiveCampaign/Config/ImportAttendeesButton'
import {withStyles} from '@material-ui/core'
import {
  useActiveCampaign,
  useServices,
} from 'organization/Event/Services/ServicesProvider'
import {
  ActiveCampaignIntegration,
  ATTENDEE_CHECKED_IN,
  ATTENDEE_CREATED,
  ATTENDEE_SIGNED_WAIVER,
  IMPORT_TAG,
  Tag,
  Group,
  LoginField,
} from 'organization/Event/Services/Apps/ActiveCampaign'
import TagIdInput from 'organization/Event/Services/Apps/ActiveCampaign/Config/TagIdInput'
import ErrorAlert from 'lib/ui/alerts/ErrorAlert'
import {spacing} from 'lib/ui/theme'
import Button from '@material-ui/core/Button'
import {useOrganization} from 'organization/OrganizationProvider'
import {useEvent} from 'Event/EventProvider'
import {api} from 'lib/url'
import GroupsConfig from 'organization/Event/Services/Apps/ActiveCampaign/Config/GroupsConfig'
import FieldsAutocomplete, {
  ActiveCampaignField,
} from 'organization/Event/Services/Apps/ActiveCampaign/Config/FieldsAutocomplete'
import {useToggle} from 'lib/toggle'
import InfoAlert from 'lib/ui/alerts/InfoAlert'

export interface UpdateData {
  tags: Tag[]
  groups: Group[]
  login_field: LoginField
}

export default function Config() {
  const activeCampaign = useActiveCampaign()

  const [importError, setImportError] = useState('')
  const clearImportError = () => setImportError('')
  const {flag: showingImportSuccess, toggle: toggleImportSuccess} = useToggle()

  const [tags, setTags] = useState<Tag[]>(activeCampaign.tags)
  const [groups, setGroups] = useState<Group[]>(activeCampaign.groups)
  const [loginField, setLoginField] = useState<LoginField>({
    login_field_id: activeCampaign.login_field_id,
    login_field_title: activeCampaign.login_field_title,
    login_field_type: activeCampaign.login_field_type,
  })
  const {save, processing, error} = useSave()

  const changeLoginField = (field: ActiveCampaignField | null) => {
    const updated = {
      login_field_id: field?.id || null,
      login_field_title: field?.title || null,
      login_field_type: field?.type || null,
    }

    setLoginField(updated)
  }

  const handleSave = () => {
    const data: UpdateData = {
      tags,
      groups,
      login_field: loginField,
    }

    save(data)
  }

  const updateTag = (tag: Tag) => {
    const updated = tags.map((t) => {
      const isTarget = t.id === tag.id

      if (isTarget) {
        return tag
      }

      return t
    })

    setTags(updated)
  }

  const tagForType = (type: Tag['type']) => {
    const target = tags.find((t) => t.type === type)
    if (!target) {
      throw new Error(`Unknown tag type: ${type}`)
    }

    return target
  }

  return (
    <Page>
      <Box mb={3}>
        <Grid container spacing={2}>
          <Grid item md={6}>
            <Typography variant="h4">Active Campaign</Typography>
          </Grid>
          <StyledGrid item md={6}>
            <ImportAttendeesButton
              onSuccess={toggleImportSuccess}
              onError={setImportError}
            />
          </StyledGrid>
        </Grid>
      </Box>

      <ErrorAlert onClose={clearImportError}>{importError}</ErrorAlert>
      <InfoAlert showing={showingImportSuccess}>
        Import successfully started. Depending on the number of contacts, this
        process may take up to 30 minutes.
      </InfoAlert>

      <Box mb={3}>
        <Box mb={2}>
          <Typography variant="h5">
            Attendee Import or Delete via Tag
          </Typography>
        </Box>
        <TagIdInput tag={tagForType(IMPORT_TAG)} onChange={updateTag} />
        <em>
          If an attendee with the given email already exists, obv.io will update
          the other attributes.
        </em>
      </Box>

      <Box mb={2}>
        <Typography variant="h5">Login URL Field</Typography>
        <em>
          Select the Custom Field in ActiveCampaign to save your attendee's
          Login URL.
        </em>
      </Box>

      <FieldsAutocomplete
        onChange={changeLoginField}
        inputVariant={'outlined'}
        types={['text', 'textarea']}
        value={{
          type: loginField.login_field_type || '',
          title: loginField.login_field_title || '',
          id: loginField.login_field_id || '',
        }}
      />

      <Box mb={3}>
        <Typography variant="h5">Action Tags</Typography>
        <em>
          The following tags can be applied in ActiveCampaign when an attendee
          takes the specific action. (optional)
        </em>
      </Box>

      <TagIdInput tag={tagForType(ATTENDEE_CREATED)} onChange={updateTag} />
      <TagIdInput tag={tagForType(ATTENDEE_CHECKED_IN)} onChange={updateTag} />
      <TagIdInput
        tag={tagForType(ATTENDEE_SIGNED_WAIVER)}
        onChange={updateTag}
      />
      <Box mb={3}>
        <Typography variant="h5">Groups</Typography>
        <em>
          Select additional custom fields you wish to import into your Obvio
          event. (optional)
        </em>
      </Box>
      <Box mb={5}>
        <GroupsConfig onChange={setGroups} groups={groups} />
      </Box>
      <ErrorAlert>{error}</ErrorAlert>
      <SaveButton
        type="submit"
        variant="contained"
        color="primary"
        size="large"
        aria-label="save"
        onClick={handleSave}
        disabled={processing}
      >
        Save
      </SaveButton>
    </Page>
  )
}

function useSave() {
  const {client} = useOrganization()
  const {event} = useEvent()

  const [processing, setProcessing] = useState<boolean>(false)
  const {update: updateIntegration} = useServices()
  const [error, setError] = useState<string | null>(null)

  const save = (data: UpdateData) => {
    setProcessing(true)
    setError('')

    client
      .put<ActiveCampaignIntegration>(
        api(`/events/${event.id}/integrations/active_campaign`),
        data,
      )
      .then(updateIntegration)
      .catch((e) => setError(e.message))
      .finally(() => {
        setProcessing(false)
      })
  }

  return {
    processing,
    error,
    save,
  }
}

const StyledGrid = withStyles({
  root: {
    display: 'flex',
    justifyContent: 'end',
  },
})(Grid)

const SaveButton = withStyles({
  root: {
    marginBottom: spacing[5],
  },
})(Button)

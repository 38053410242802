import {useTemplate} from 'Event/TemplateProvider'
import {useSaveTemplate} from 'Event/TemplateUpdateProvider'
import styled from 'styled-components'
import {onChangeCheckedHandler} from 'lib/dom'
import React from 'react'
import {EnabledSwitch} from 'lib/ui/form/Switch'

export default function ToggleVisibleSwitch() {
  const {faq} = useTemplate()
  const save = useSaveTemplate()
  const handleToggle = (isEnabled: boolean) => {
    save({
      faq: {
        isEnabled,
      },
    })
  }

  return (
    <StyledEnabledSwitch
      checked={faq.isEnabled}
      onChange={onChangeCheckedHandler(handleToggle)}
      aria-label="toggle visible faq"
    />
  )
}

const StyledEnabledSwitch = styled(EnabledSwitch)`
  margin-bottom: 0;
`

import React from 'react'
import ReactPhoneNumberInput from 'react-phone-number-input/input'
import TextField, {TextFieldProps} from 'lib/ui/TextField'

export interface PhoneNumberFieldProps extends TextFieldProps {
  inputComponent?: React.FC<any>
}

export default function PhoneNumberField(props: PhoneNumberFieldProps) {
  const {
    onChange,
    disabled,
    value,
    label = 'Phone Number',
    inputComponent = TextFieldWithRef,
    ...textFieldProps
  } = props
  return (
    <ReactPhoneNumberInput
      defaultCountry="US" // Allow inputting US number without +1 country code
      international={false} // Allow US numbers to stay as region format (with brackets)
      value={value}
      onChange={(val) => {
        onChange && onChange(String(val))
      }}
      disabled={disabled}
      inputComponent={inputComponent as any}
      label={label}
      {...textFieldProps}
    />
  )
}

// TextField expects ref to be passed through inputProps.ref, so we'll
// wrap it, and forward it along correctly.
const TextFieldWithRef = React.forwardRef<HTMLInputElement, TextFieldProps>(
  (props, ref) => {
    const {inputProps, ...otherProps} = props
    return (
      <TextField
        inputProps={{
          ...inputProps,
          ref,
        }}
        {...otherProps}
      />
    )
  },
)

import React from 'react'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import ActionFormCustomWebhook from 'organization/Event/ZoomAttendance/ActionFormCustomWebhook'
import ActionFormExport from 'organization/Event/ZoomAttendance/ActionFormExport'
import ActionFormExternalTag from 'organization/Event/ZoomAttendance/ActionFormExternalTag'
import ActionFormTagGroup from 'organization/Event/ZoomAttendance/ActionFormTagGroup'
import {
  ACTION_CUSTOM_WEBHOOK,
  ACTION_EXPORT_ATTENDEES,
  ACTION_EXTERNAL_TAG,
  ACTION_OBVIO_TAG_GROUP,
} from 'organization/Event/ZoomAttendance/ZoomAttendanceProvider'

export interface FormProps {
  action: string
  onClose: () => void
  onSuccessMessage: (success: string | null) => void
}

export default function AttendeeActionForm(props: FormProps) {
  const {action} = props

  if (!action) {
    return null
  }

  let component = null

  switch (action) {
    case ACTION_EXPORT_ATTENDEES:
      component = <ActionFormExport {...props} />
      break
    case ACTION_OBVIO_TAG_GROUP:
      component = <ActionFormTagGroup {...props} />
      break
    case ACTION_EXTERNAL_TAG:
      component = <ActionFormExternalTag {...props} />
      break
    case ACTION_CUSTOM_WEBHOOK:
      component = <ActionFormCustomWebhook {...props} />
      break
  }

  return (
    <>
      <DialogTitle>{action}</DialogTitle>
      <DialogContent>{component}</DialogContent>
    </>
  )
}

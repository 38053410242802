import {useTemplate} from 'Event/TemplateProvider'
import {useSaveTemplate} from 'Event/TemplateUpdateProvider'
import Button from 'lib/ui/Button'
import GroupInput from 'lib/ui/form/GroupInput'
import TagsInput from 'lib/ui/form/TagsInput'
import {Group, Tag} from 'organization/Event/AttendeesProvider'
import Config from 'organization/Event/Configurable/Config'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import React, {useState} from 'react'

export default function RulesConfig() {
  const {signUp} = useTemplate()
  const save = useSaveTemplate()

  const [tags, setTags] = useState<Tag[]>(signUp.tags || [])
  const [groups, setGroups] = useState<Group[]>(signUp.groups || [])

  const addGroup = () => {
    const added = [...groups, {key: '', value: ''}]
    setGroups(added)
  }

  const updateGroup = (targetIndex: number) => (updated: Group) => {
    const updatedList = groups.map((g, i) => {
      const isTarget = i === targetIndex
      if (isTarget) {
        return updated
      }

      return g
    })

    setGroups(updatedList)
  }

  const removeGroup = (targetIndex: number) => () => {
    const updated = [...groups]
    updated.splice(targetIndex, 1)
    setGroups(updated)
  }

  const handleSave = () => {
    save({
      signUp: {
        tags,
        groups,
      },
    })
  }

  return (
    <Config title="New Attendee Rules" onSave={handleSave}>
      <SettingsPanel>
        <>
          <TagsInput
            value={tags}
            onChange={setTags}
            name="tags"
            aria-label="tags"
            label="Tags"
          />
          {groups.map((group, index) => (
            <GroupInput
              key={index}
              group={group}
              onChange={updateGroup(index)}
              remove={removeGroup(index)}
            />
          ))}
          <Button
            aria-label="add group"
            variant="outlined"
            color="primary"
            onClick={addGroup}
            fullWidth
            size="large"
          >
            Add Group
          </Button>
        </>
      </SettingsPanel>
    </Config>
  )
}

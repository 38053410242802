import {useDraggableGrid} from 'lib/ui/DraggableGrid'
import React, {useEffect, useRef} from 'react'

import {useDrag, useDrop} from 'react-dnd'

export default function DraggableGridItem(props: {
  children: JSX.Element
  id: string
  type: string
}) {
  const {id, type, children} = props

  const {onDrop} = useDraggableGrid()

  const ref = useRef<HTMLDivElement>(null)

  const [_1, connectDrag] = useDrag(() => ({
    type,
    item: {id},
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }))

  const [_2, connectDrop] = useDrop({
    accept: type,
    hover: (target: {id: string}) => {
      if (target.id === id) {
        return
      }

      onDrop(id, target.id)
    },
  })

  useEffect(() => {
    connectDrop(ref)
    connectDrag(ref)
  }, [connectDrag, connectDrop])

  return <div ref={ref}>{children}</div>
}

import React from 'react'
import {useNiftyFiftyTemplate} from 'Event/template/NiftyFifty'
import {rgba} from 'lib/color'
import {ForgotPasswordProps} from 'auth/password'
import {Content} from 'Event/template/NiftyFifty/ForgotPassword/RightPanel/index'
import {
  Box,
  LeftContainer,
  RightContainer,
  FormContainer,
  StyledBackground,
} from 'Event/template/NiftyFifty/Login/MobilePanel'
import Logo from 'Event/auth/Login/Logo'

export default function MobilePanel(props: ForgotPasswordProps) {
  const template = useNiftyFiftyTemplate()
  const {login} = template

  return (
    <Box>
      <LeftContainer>
        <StyledBackground>
          <Logo />
        </StyledBackground>
      </LeftContainer>
      <RightContainer
        backgroundColor={rgba(
          login.loginRightPanel.backgroundColor,
          login.loginRightPanel.backgroundOpacity,
        )}
      >
        <FormContainer>
          <Content {...props} />
        </FormContainer>
      </RightContainer>
    </Box>
  )
}

import Config from 'organization/Event/Services/Apps/ConvertKit/Config'
import {ConvertKitField} from 'organization/Event/Services/Apps/ConvertKit/Config/Fields/AutocompleteFields'
import {Group} from 'organization/Event/Services/Apps/ConvertKit/Config/Groups/convertKitGroup'
import {Tag} from 'organization/Event/Services/Apps/ConvertKit/Config/Tags'
import LinkPage from 'organization/Event/Services/Apps/ConvertKit/LinkPage'
import {
  BaseIntegration,
  CONVERT_KIT,
  useServices,
} from 'organization/Event/Services/ServicesProvider'
import React from 'react'

export type ConvertKitIntegration = BaseIntegration & {
  service: typeof CONVERT_KIT
  api_key: string
  api_secret: string
  tags: Tag[]
  groups: Group[]
  login_field_id: string | null
  login_field_name: string | null
  login_field_key: string | null
  login_field_label: string | null
  phone_field_id: string | null
  phone_field_name: string | null
  phone_field_key: string | null
  phone_field_label: string | null
  last_name_field_id: string | null
  last_name_field_key: string | null
  last_name_field_label: string | null
  last_name_field_name: string | null
  should_parse_last_name_from_first_name: boolean
  imported_at: string | null
  has_set_import_tag: boolean
}

export type LoginField = ConvertKitField

export type LastNameField = ConvertKitField

export type PhoneField = ConvertKitField

export default function ConvertKit() {
  const {isLinked} = useServices()
  const showConfig = isLinked(CONVERT_KIT)

  if (showConfig) {
    return <Config />
  }

  return <LinkPage />
}

import styled from 'styled-components'
import {useEvent} from 'Event/EventProvider'
import {useOrganization} from 'organization/OrganizationProvider'
import React, {useState, useEffect} from 'react'
import {useAddMailchimpField} from 'lib/event-api/integrations/mailchimp/fields/add'
import TextField from 'lib/ui/TextField'
import Button from 'lib/ui/Button'
import {MailchimpField} from 'lib/event-api/integrations/mailchimp/fields/list'

export default function CreateFieldInput(props: {
  showing: boolean
  onClose: () => void
  onAdd: (field: MailchimpField) => void
}) {
  const {onClose, showing, onAdd} = props
  const [name, setName] = useState('')
  const [error, setError] = useState('')

  const {event} = useEvent()
  const {client} = useOrganization()
  const {mutate: addField, isLoading} = useAddMailchimpField({event, client})

  useEffect(() => {
    if (showing) {
      return
    }

    // reset input
    setName('')
  }, [showing])

  const save = () => {
    if (isLoading) {
      return
    }

    setError('')

    addField(
      {name},
      {
        onError: (error: any) => {
          setError(error.message)
        },
        onSuccess: (field) => {
          onAdd(field)
          onClose()
        },
      },
    )
  }

  const canSave = !isLoading && Boolean(name)

  if (!showing) {
    return null
  }

  return (
    <>
      <TextField
        value={name}
        onChange={setName}
        label="New Login Field"
        variant="outlined"
        fullWidth
        inputProps={{
          'aria-label': 'new field name',
          maxLength: 100, // mailchimp limit
        }}
        disabled={isLoading}
        error={Boolean(error)}
        helperText={error}
      />
      <div>
        <SaveButton
          onClick={save}
          disabled={!canSave}
          color="primary"
          aria-label="save field"
          variant="contained"
        >
          Save
        </SaveButton>
        <Button
          onClick={onClose}
          disabled={isLoading}
          aria-label="cancel create feild"
        >
          Cancel
        </Button>
      </div>
    </>
  )
}

const SaveButton = styled(Button)`
  margin-right: ${(props) => props.theme.spacing[2]};
`

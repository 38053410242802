import FormControl from '@material-ui/core/FormControl'
import MuiCheckbox from '@material-ui/core/Checkbox'
import styled from 'styled-components'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import React from 'react'
import {useWaiver} from 'Event/Step2/Waivers/Waiver'
import {useAttendeeVariables} from 'Event'
import {onChangeCheckedHandler} from 'lib/dom'
import Configurable from 'organization/Event/Configurable'
import CheckboxConfig from 'Event/Step2/Waivers/Waiver/Checkbox/CheckboxConfig'
import {useTemplate} from 'Event/TemplateProvider'

export const DEFAULT_AGREE_STATEMENT = `I, {{first name}} {{last name}}, hereby certify that I have read the forgoing and fully understand the meaning effect thereof, and intending to be legally bound, have signed it.`
export default function Checkbox(props: {className?: string}) {
  const {className = ''} = props
  const {waiver, agree, setAgree} = useWaiver()
  const v = useAttendeeVariables()
  const agreeStatement = v(waiver.agreeStatement || DEFAULT_AGREE_STATEMENT)
  const {waiver: settings} = useTemplate()

  return (
    <Configurable>
      <CheckboxConfig />
      <Box className={className}>
        <FormControl required component="fieldset">
          <FormControlLabel
            control={
              <StyledCheckbox
                color={settings.checkboxColor}
                checked={agree}
                onChange={onChangeCheckedHandler(setAgree)}
                inputProps={{
                  'aria-label': 'agree to waiver checkbox',
                }}
              />
            }
            label={
              <span dangerouslySetInnerHTML={{__html: v(agreeStatement)}} />
            }
          />
        </FormControl>
      </Box>
    </Configurable>
  )
}

const Box = styled.div`
  margin-bottom: 12px;
`

export const StyledCheckbox = styled((props) => {
  const {color: _1, ...otherProps} = props
  return <MuiCheckbox {...otherProps} />
})`
  color: ${(props) => props.color} !important;
`

import Skeleton from '@material-ui/lab/Skeleton'
import {useEvent} from 'Event/EventProvider'
import {Attendee} from 'Event/attendee'
import {useAsync} from 'lib/async'
import {PaginatedCollection} from 'lib/ui/api-client'
import {api} from 'lib/url'
import {useBroadcastForm} from 'organization/Event/Broadcasts/BroadcastForm'
import {useOrganization} from 'organization/OrganizationProvider'
import React, {useCallback} from 'react'

export default function NumAttendeesSummary() {
  const {channel} = useBroadcastForm()

  // For SMS, we'll display a checkbox the user needs to agree to instead.
  if (channel === 'sms') {
    return null
  }

  return <Content />
}

function Content() {
  const {data: numAttendees} = useNumAttendees()

  if (numAttendees === null) {
    return <Skeleton />
  }

  const isPlural = numAttendees === 0 || numAttendees > 1

  const message = isPlural
    ? `${numAttendees} Attendees match the given criteria.`
    : `${numAttendees} Attendee matches the given criteria.`

  return <div>{message}</div>
}

function useNumAttendees() {
  const url = useQueryUrl()

  const {client} = useOrganization()

  const request = useCallback(() => {
    if (!url) {
      return Promise.resolve(null)
    }

    return client.get<PaginatedCollection<Attendee>>(url).then((p) => p.total)
  }, [client, url])

  return useAsync(request)
}

function useQueryUrl() {
  const {attendeeQuery} = useBroadcastForm()

  const {event} = useEvent()

  const baseUrl = api(`/events/${event.id}/attendees`)

  if (!attendeeQuery) {
    return baseUrl
  }

  return `${baseUrl}${attendeeQuery}`
}

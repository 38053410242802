import React from 'react'
import {useEditMode} from 'Event/EditModeProvider'
import {HashMap} from 'lib/list'
import {HasRules} from 'Event/attendee-rules'
import {HasSchedule} from 'lib/ui/layout/Scheduled'
import Configurable from 'organization/Event/Configurable'
import {BaseSectionProps} from 'Event/template/Townhall/Dashboard/Main/section'
import {CountdownTimerSectionConfig} from 'Event/template/Townhall/Dashboard/Main/CountdownTimerSection/CountdownTimerSectionConfig'
import {CountDownTimerSettings} from 'Event/Dashboard/components/CountDownTimer'
import CountDownTimers from 'Event/template/Townhall/Dashboard/Main/CountdownTimerSection/CountdownTimers'

export const COUNTDOWN_TIMER = 'countdown_timer'
export type CountdownTimerSectionProps = BaseSectionProps &
  HasRules &
  HasSchedule & {
    name: typeof COUNTDOWN_TIMER
    items: HashMap<CountDownTimerSettings>
  }

export const createCountdownTimerSection = (): CountdownTimerSectionProps => ({
  name: COUNTDOWN_TIMER,
  title: 'Countdown Timers',
  description: '',
  items: {},
  isEnabled: true,
  background: '#FFFFFF',
  color: '#000000',
  font: null,
})

export default function CountdownTimerSection(
  props: CountdownTimerSectionProps & {
    sectionId: string
  },
) {
  const {items, isEnabled, sectionId} = props
  const isEdit = useEditMode()
  const hasCountdown = Object.keys(items).length > 0

  const showing = hasCountdown && isEnabled

  if (!showing && !isEdit) {
    return null
  }

  return (
    <Configurable aria-label="countdown timer section config">
      <CountdownTimerSectionConfig section={props} id={sectionId} />
      <CountDownTimers {...props} />
    </Configurable>
  )
}

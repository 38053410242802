import React from 'react'
import Page from 'organization/Event/TemplateEditor/Page'
import Login from 'Event/auth/Login'
import {DesignBreadcrumbs} from 'organization/Event/Page/PageBreadcrumbs'

export default function LoginConfig() {
  return (
    <DesignBreadcrumbs page="Login">
      <Page>
        <Login isPreview />
      </Page>
    </DesignBreadcrumbs>
  )
}

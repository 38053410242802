import {ValidationError} from 'lib/ui/api-client'
import {useBreadcrumbs} from 'lib/ui/BreadcrumbProvider'
import {api} from 'lib/url'
import stripeLogo from 'assets/images/services/StripeWordmark-White.svg'
import {teamMemberClient} from 'obvio/obvio-client'
import Layout from 'organization/user/Layout'
import {Organization} from 'organization'
import React, {useState} from 'react'
import styled from 'styled-components'
import {useForm} from 'react-hook-form'
import {useOrganization} from 'organization/OrganizationProvider'
import {useIsOwner} from 'organization/OwnerProvider'
import {fieldError} from 'lib/form'
import Form from 'organization/Settings/Form'
import Page from 'lib/ui/layout/Page'
import Subheading from 'lib/ui/typography/Subheading'
import Divider from 'lib/ui/layout/Divider'
import Grid from 'lib/ui/Grid'
import PageHeader from 'lib/ui/PageHeader'
import Title from 'lib/ui/PageHeader/Title'
import CommunicationSettings from 'organization/Settings/CommunicationSettings'
import {useFileSelect} from 'lib/ui/form/file'
import {FileLocation} from 'lib/http-client'
import OrganizationFeatureToggle from 'organization/OrganizationFeatureToggle'
import {MARKETPLACE} from 'auth/user/flags'
import MarketplaceConnectForm from 'organization/Settings/MarketplaceConnectForm'

export interface Data {
  name: string
  slug: string
  logo: FileLocation | null
}

export default function Settings() {
  const {register, errors, handleSubmit} = useForm()
  const [serverError, setServerError] = useState<ValidationError<Data>>(null)
  const [submitting, setSubmitting] = useState(false)
  const {organization, routes: organizationRoutes, set} = useOrganization()
  const {name} = organization
  const isOwner = useIsOwner()
  const logo = useFileSelect(organization.logo)

  useBreadcrumbs(
    [
      {title: name, url: organizationRoutes.events.root},
      {title: 'Settings', url: organizationRoutes.settings},
    ],
    [name],
  )

  const data = (form: Data) => {
    if (logo.selected) {
      const formData = new FormData()
      for (const [key, value] of Object.entries(form)) {
        formData.set(key, String(value))
      }

      formData.set('logo', logo.selected)
      return formData
    }

    if (logo.wasRemoved) {
      return {
        ...form,
        logo: null,
      }
    }

    return form
  }

  const submit = (formData: Data) => {
    setSubmitting(true)
    sendRequest(data(formData), organization.id)
      .then(set)
      .catch((error) => {
        setServerError(error)
      })
      .finally(() => {
        setSubmitting(false)
      })
  }

  const nameError = fieldError('name', {
    form: errors,
    response: serverError,
  })

  return (
    <Layout>
      <Page>
        <PageHeader>
          <Title text="Settings" />
        </PageHeader>
        <OrganizationSettings showing={isOwner}>
          <Section>
            <Form
              onSubmit={handleSubmit(submit)}
              submitting={submitting}
              serverError={serverError}
              nameError={nameError}
              register={register}
              logo={logo}
            />
          </Section>
          <Divider />
        </OrganizationSettings>

        <FullGrid container spacing={2}>
          <Grid item xs={12} sm={8}>
            <Section>
              <Subheading>Communication settings</Subheading>
              <CommunicationSettings />
            </Section>
          </Grid>

          <OrganizationFeatureToggle flags={MARKETPLACE}>
            <Grid item xs={12} sm={4}>
              <Section>
                <StripeLogoBox>
                  <StripeLogo src={stripeLogo} alt="Stripe logo" />
                </StripeLogoBox>
                <MarketplaceConnectForm />
              </Section>
            </Grid>
          </OrganizationFeatureToggle>
        </FullGrid>
      </Page>
    </Layout>
  )
}

function sendRequest(data: Data | FormData, id: number) {
  const url = api(`/organizations/${id}`)
  return teamMemberClient.put<Organization>(url, data)
}

const Section = styled.div`
  margin-bottom: ${(props) => props.theme.spacing[16]};
`

const OrganizationSettings = styled.div<{showing: boolean}>`
  display: ${(props) => (props.showing ? 'block' : 'none')};
`

const FullGrid = styled(Grid)`
  margin: 0;
  width: 100%;
`

const StripeLogoBox = styled.div`
  align-items: center;
  background: #32325d;
  display: flex;
  justify-content: center;
  margin-bottom: ${(props) => props.theme.spacing[4]};
`

const StripeLogo = styled.img`
  margin: 30px 0;
  max-width: 100%;
`

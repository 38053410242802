import React, {useState, useEffect, useRef, useCallback} from 'react'
import styled, {useTheme} from 'styled-components'
import Box from '@material-ui/core/Box'
import {Header, Tiny} from 'lib/ui/typography'
import {
  CardHeader,
  MainCard,
  MainCardContent,
  Row,
} from 'Event/Statistics/Summary'
import PieChart from 'Event/Statistics/Summary/CheckedInCard/PieChart'
import {useOnResize} from 'lib/resize'
import {OpenStatisticWindowButton, STATISTIC_CHECKED_IN} from 'Event/Statistics'

export default function CheckedIn(props: {
  total: number
  checked_in: number
  className?: string
}) {
  const {total, checked_in: checkedIn, className} = props

  const [width, setWidth] = useState(0)

  const containerRef = useRef<HTMLDivElement>(null)
  const theme = useTheme()

  // Set the width to have the chart size dynamically update.
  const updateWidth = useCallback(() => {
    if (!containerRef.current) {
      return
    }

    const {width} = containerRef.current.getBoundingClientRect()
    setWidth(width)
  }, [])

  useEffect(updateWidth, [updateWidth]) // On load
  useOnResize(updateWidth)

  return (
    <MainCard className={className} ref={containerRef}>
      <MainCardContent>
        <CardHeader>
          <Header color="white">Checked in</Header>
          <OpenStatisticWindowButton statisticType={STATISTIC_CHECKED_IN} />
        </CardHeader>
        <ChartContainer>
          <PieChart total={total} checked_in={checkedIn} radius={width / 4} />
        </ChartContainer>
        <Box>
          <Row>
            <Circle backgroundColor={theme.colors.chart.background} />
            <Tiny color="white">{`${total - checkedIn} Not checked in`}</Tiny>
          </Row>
          <Row>
            <Circle backgroundColor={theme.colors.primary} />
            <Tiny color="white">{`${checkedIn} Checked in`}</Tiny>
          </Row>
        </Box>
      </MainCardContent>
    </MainCard>
  )
}

type CircleProps = {
  backgroundColor: string
}

const Circle = styled.div<CircleProps>`
  width: 15px;
  height: 15px;
  border-radius: 100%;
  background-color: ${(props) => props.backgroundColor};
`

const ChartContainer = styled.div`
  height: calc(100% - 122px);
  display: flex;
  align-items: center;
  justify-content: center;
`

import React, {useEffect} from 'react'
import {BlockProps, Box, ComponentDefinitions} from 'Event/Marketplace/Block'
import {useUpdateTemplate} from 'organization/Marketplace/PurchasePageConfig/TemplateUpdateProvider'
import {REMOVE} from 'lib/JsonUpdateProvider'
import {ConfigContext} from 'organization/Event/Configurable'
import BlockConfig from 'organization/Marketplace/PurchasePageConfig/BlockConfig'
import {useToggleArray} from 'lib/toggle'
import {useForm} from 'react-hook-form'
import {EditComponentOverlay} from 'Event/Dashboard/editor/views/EditComponent'
import styled from 'styled-components'
import {useEditMode} from 'Event/EditModeProvider'
import DragHandleIcon from '@material-ui/icons/DragHandle'
import {usePurchasePageConfig} from 'organization/Marketplace/PurchasePageConfig/PurchasePageConfigProvider'
import Title from 'Event/Marketplace/Block/Title'
import Text from 'Event/Marketplace/Block/Text'
import Image from 'Event/Marketplace/Block/Image'
import TicketSelector from 'Event/Marketplace/Block/TicketSelector'
import Button from 'Event/Marketplace/Block/Button'
import Video from 'Event/Marketplace/Block/Video'
import Separator from 'Event/Marketplace/Block/Separator'
import CountdownTimer from 'Event/Marketplace/Block/CountdownTimer'
import ConfigurableBulletedList from 'organization/Marketplace/PurchasePageConfig/ConfigurableBulletedList'
import ConfigurableNumberedList from 'organization/Marketplace/PurchasePageConfig/ConfigurableNumberedList'
import {uuid} from 'lib/uuid'
import Icon from 'Event/Marketplace/Icon'
import Spacer from 'Event/Marketplace/Block/Spacer'
import ConfigurablePurchaseForm from 'organization/Marketplace/PurchasePageConfig/ConfigurablePurchaseForm'
import ConfigurableFaqList from 'organization/Marketplace/PurchasePageConfig/ConfigurableFaqList'
import {useConfigurableSection} from 'organization/Marketplace/PurchasePageConfig/ConfigurableSection'

export const BLOCK_DRAG_HANDLE = 'block-drag-handle'

export const Blocks: ComponentDefinitions = {
  Title,
  Text,
  Image,
  TicketSelector,
  Button,
  Video,
  PurchaseForm: ConfigurablePurchaseForm,
  Separator,
  BulletedList: ConfigurableBulletedList,
  CountdownTimer,
  NumberedList: ConfigurableNumberedList,
  Icon,
  FaqList: ConfigurableFaqList,
  Spacer,
}

const ConfigurableBlock = React.forwardRef<HTMLDivElement, BlockProps>(
  (props, ref) => {
    const {block, id, template, ...gridLayoutProps} = props
    const {sectionId} = useConfigurableSection()

    const {
      style,
      className,
      onMouseDown,
      onMouseUp,
      onTouchEnd,
      children,
    } = gridLayoutProps
    const updateTemplate = useUpdateTemplate()
    const [showingConfig, toggleConfig] = useToggleArray()
    const form = useForm()
    const isEditMode = useEditMode()
    const {isMobileMode} = usePurchasePageConfig()
    const {sections} = template
    const blockType = block.type

    const Component = Blocks[blockType] ?? null

    // Remove blocks with missing components. ie. deprecated blocks.
    useEffect(() => {
      // If missing block type, we'll assumed its already being removed (set to `__REMOVE__`), so
      // we'll avoid deleting it again. Fixes an occasionally ocurring inf-loop bug.
      if (!blockType) {
        return
      }

      if (Component === null) {
        updateTemplate({
          sections: {
            [sectionId]: {
              blocks: {
                [id]: REMOVE,
              },
            },
          },
        })
      }
    }, [Component, id, updateTemplate, sectionId, blockType])

    const copy = () => {
      const layouts = sections[sectionId].layouts
      const desktopLayouts = layouts.desktop
      const mobileLayouts = layouts.mobile

      // need to get desktop/mobile layouts from block that is being copied
      const copiedDesktopLayout = desktopLayouts.find(
        (layout) => layout.i === id,
      )
      const copiedMobileLayout = mobileLayouts.find((layout) => layout.i === id)

      const newId = uuid()

      updateTemplate({
        sections: {
          [sectionId]: {
            blocks: {
              [newId]: sections[sectionId].blocks[id],
            },
            layouts: {
              mobile: [
                ...mobileLayouts,
                {...copiedMobileLayout, i: newId, x: 0, y: 1000000000000},
              ],
              desktop: [
                ...desktopLayouts,
                {...copiedDesktopLayout, i: newId, x: 0, y: 1000000000000},
              ],
            },
          },
        },
      })
    }

    if (!Component) {
      return null
    }

    // Need to use ConfigContext directly to separate out the config & editable component. If we used
    // Configurable, any clicks (ie. opening the config) would trigger a layout drag.
    return (
      <ConfigContext.Provider
        value={{showing: showingConfig, toggle: toggleConfig, form}}
      >
        <BlockConfig block={block} id={id} sectionId={sectionId} />
        <StyledBox
          style={style}
          className={className}
          ref={ref}
          hasBorder={isEditMode}
          onMouseDown={onMouseDown}
          onMouseUp={onMouseUp}
          onTouchEnd={onTouchEnd}
          visibility={block.visibility}
          isMobileMode={isMobileMode}
        >
          <StyledDraggableOverlay>
            <StyledEditComponentOverlay
              onClick={toggleConfig}
              aria-label={`edit ${block.type} block`}
              onCopy={copy}
            >
              {/*
               * Setting 'any' type for props is ok here, because we're
               * already verifying the correct component receives the
               * correct type in ComponentDefinitions.
               */}
              <>
                <DragHandle />
                <Component
                  {...(block as any)}
                  id={id}
                  sectionId={sectionId}
                  isMobileMode={isMobileMode}
                  template={template}
                />
              </>
            </StyledEditComponentOverlay>
          </StyledDraggableOverlay>
          {children}
        </StyledBox>
      </ConfigContext.Provider>
    )
  },
)

export default ConfigurableBlock

function DragHandle(props: {className?: string}) {
  return (
    <DragHandleBox
      aria-label="button drag handle"
      className={`${props.className || ''} ${BLOCK_DRAG_HANDLE}`}
    >
      <DragHandleIcon />
    </DragHandleBox>
  )
}

const DragHandleBox = styled.div`
  position: absolute;
  top: ${(props) => props.theme.spacing[1]};
  right: ${(props) => props.theme.spacing[10]};
  z-index: 2;
  display: none;
  border-radius: 4px;
  background: white;

  &:hover {
    opacity: 0.8;
  }

  svg {
    color: ${(props) => props.theme.colors.primary};
  }
`

export const DraggableOverlay = styled.div`
  position: relative;

  &:hover ${DragHandleBox} {
    display: inline-flex;
  }
`

const StyledDraggableOverlay = styled(DraggableOverlay)`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const StyledEditComponentOverlay = styled(EditComponentOverlay)`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const StyledBox = styled(Box)<{hasBorder: boolean}>`
  border: ${(props) =>
    props.hasBorder ? `1px dashed ${props.theme.colors.primary}` : 'none'};
`

import {useMarketplaceAccount} from 'lib/marketplace-api/accounts/use-marketplace-account'
import {marketplaceApi} from 'lib/marketplace-api/url'
import {useOrgMarketplaceClient} from 'lib/marketplace-api/use-org-marketplace-client'
import {useMutation, useQueryClient} from 'react-query'

export function useDisconnectMarketplaceAccount() {
  const {data: account} = useMarketplaceAccount()
  const {client} = useOrgMarketplaceClient()
  const qc = useQueryClient()

  return useMutation({
    mutationFn: () => {
      const url = marketplaceApi(`/accounts/${account!.id}`)
      return client!.delete(url)
    },
    onSuccess: () => {
      // refetch
      qc.invalidateQueries({
        queryKey: ['marketplace', 'account'],
      })
    },
  })
}

import React, {useState} from 'react'
import ComponentConfig, {
  ComponentConfigProps,
  SaveButton,
  RemoveButton,
  Footer,
} from 'organization/Event/DashboardConfig/ComponentConfigPanel'
import {useForm} from 'react-hook-form'
import Settings from 'Event/template/Panels/Dashboard/Resources/ResourceList/ResourceItemConfig/Settings'
import {usePanelsTemplate, usePanelsUpdate} from 'Event/template/Panels'
import {Resource} from 'Event/template/Panels/Dashboard/Resources/ResourceList'
import {useDeleteFile} from 'Event/template/Panels/Dashboard/Resources/ResourceList/ResourceUpload'
import RulesPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/RulesPanel'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'

export default function ResourceItemConfig(
  props: ComponentConfigProps & {
    resource: Resource
    targetIndex?: number
  },
) {
  const {resource, targetIndex, showing, onClose} = props
  const template = usePanelsTemplate()
  const updateTemplate = usePanelsUpdate()
  const {resourceList: list} = template
  const deleteFile = useDeleteFile()
  const {handleSubmit, control, register, formState} = useForm()

  const [rules, setRules] = useState(resource.rules)
  const [isUrl, setIsUrl] = useState(resource.isUrl)
  const [filePath, setFilePath] = useState(resource.filePath)
  const [actionId, setActionId] = useState(resource.actionId)
  const isEditing = targetIndex !== undefined

  const insert = (resource: Resource) => {
    return {
      resourceList: {
        resources: [...list.resources, resource],
      },
    }
  }

  const update = (resource: Resource) => {
    return {
      resourceList: {
        resources: list.resources.map((r, index) => {
          const isTarget = index === targetIndex
          if (isTarget) {
            return resource
          }

          return r
        }),
      },
    }
  }

  const save = (form: any) => {
    const data: Resource = {
      rules,
      isUrl,
      actionId,
      filePath,
      ...form,
    }

    const updated = targetIndex === undefined ? insert(data) : update(data)

    updateTemplate(updated)

    onClose()
  }

  const remove = () => {
    if (targetIndex === undefined) {
      throw new Error('Missing resource item index')
    }

    if (resource.filePath) {
      deleteFile(resource.filePath).catch((e) => {
        // Log error, but prevent it from crashing app
        console.error(e)
      })
    }
    onClose()

    updateTemplate({
      resourceList: {
        resources: list.resources.filter(
          (_, index) => index !== props.targetIndex,
        ),
      },
    })
  }

  return (
    <ComponentConfig
      title="Resource"
      onClose={onClose}
      showing={showing}
      onSubmit={handleSubmit(save)}
      hasChanges={formState.isDirty}
    >
      <SettingsPanel>
        <Settings
          control={control}
          register={register}
          resource={resource}
          isUrl={isUrl}
          setIsUrl={setIsUrl}
          setActionId={setActionId}
          actionId={actionId}
          filePath={filePath}
          setFilePath={setFilePath}
        />
      </SettingsPanel>
      <RulesPanel rules={rules} setRules={setRules} />
      <Footer>
        <SaveButton type="submit" />
        <RemoveButton
          aria-label="remove resource"
          onClick={remove}
          showing={isEditing}
        />
      </Footer>
    </ComponentConfig>
  )
}

import {QuestionTypeConfigProps} from 'organization/Event/Form/dialog/Form/QuestionTypeConfig'
import React from 'react'
import {isSelectQuestion} from 'Event/Question/Select'
import {Controller} from 'react-hook-form'
import Switch from 'lib/ui/form/Switch'

export function SelectConfig(props: QuestionTypeConfigProps) {
  const {question, control} = props

  return (
    <Controller
      name="allows_multiple_options"
      defaultValue={
        isSelectQuestion(question) ? question.allows_multiple_options : false
      }
      control={control}
      render={({onChange, value}) => (
        <Switch
          checked={value}
          onChange={(e) => onChange(e.target.checked)}
          labelPlacement="end"
          aria-label="allows multiple options"
          label={value ? 'Multi-Select Enabled' : 'Multi-Select Disabled'}
        />
      )}
    />
  )
}

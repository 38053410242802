import React from 'react'
import {Controller, UseFormMethods} from 'react-hook-form'
import ColorPicker from 'lib/ui/ColorPicker'
import Slider from 'lib/ui/Slider'
import {usePanelsTemplate} from 'Event/template/Panels'

export default function Styling(props: {control: UseFormMethods['control']}) {
  const {control} = props
  const template = usePanelsTemplate()
  const {imageWaterfall: current} = template

  return (
    <>
      <Controller
        name="uploadButtonFontSize"
        control={control}
        defaultValue={current.uploadButtonFontSize}
        render={({value, onChange}) => (
          <Slider
            valueLabelDisplay="auto"
            aria-label="uploadButtonFontSize"
            value={value ? value : 20}
            onChange={onChange}
            step={1}
            min={5}
            max={50}
            label="Upload Button Font Size"
          />
        )}
      />
      <Controller
        name="uploadButtonBorderRadius"
        control={control}
        defaultValue={current.uploadButtonBorderRadius}
        render={({value, onChange}) => (
          <Slider
            valueLabelDisplay="auto"
            aria-label="uploadButtonBorderRadius"
            value={value}
            onChange={onChange}
            step={1}
            min={0}
            max={60}
            label="Upload Button Border Radius"
          />
        )}
      />
      <Controller
        name="uploadButtonTextColor"
        control={control}
        defaultValue={current.uploadButtonTextColor}
        render={({value, onChange}) => (
          <ColorPicker
            label="Upload Button Text Color"
            color={value}
            onPick={onChange}
            aria-label="uploadButtonTextColor"
          />
        )}
      />
      <Controller
        name="uploadButtonBackgroundColor"
        control={control}
        defaultValue={current.uploadButtonBackgroundColor}
        render={({value, onChange}) => (
          <ColorPicker
            label="Upload Button Background Color"
            color={value}
            onPick={onChange}
            aria-label="uploadButtonBackgroundColor"
          />
        )}
      />
      <Controller
        name="uploadButtonBorderColor"
        control={control}
        defaultValue={current.uploadButtonBorderColor}
        render={({value, onChange}) => (
          <ColorPicker
            label="Upload Button Border Color"
            color={value}
            onPick={onChange}
            aria-label="uploadButtonBorderColor"
          />
        )}
      />
    </>
  )
}

import Grid from '@material-ui/core/Grid'
import {useTemplate} from 'Event/TemplateProvider'
import React from 'react'
import {RelativeLink} from 'lib/ui/link/RelativeLink'
import {areaRoutes} from 'Event/Routes'
import Configurable from 'organization/Event/Configurable'
import StartButtonConfig from 'Event/Step3/TechCheck/Buttons/StartButton/StartButtonConfig'
import {Button} from 'Event/Dashboard/components/NavButton'

export default function StartButton() {
  const {
    techCheck: {startButton, areaKey},
  } = useTemplate()

  const joinLink = areaKey ? areaRoutes(areaKey).root : '#'

  return (
    <Grid container justify="center">
      <Grid item xs={12} md={startButton.size}>
        <Configurable>
          <StartButtonConfig />
          <RelativeLink
            to={joinLink}
            newTab
            disableStyles
            aria-label="start tech check"
          >
            <Button {...startButton} />
          </RelativeLink>
        </Configurable>
      </Grid>
    </Grid>
  )
}

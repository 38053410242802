/**
 * Formats a number, and add thousandth's separator, i.e, 1,000,000
 *
 * @param number
 * @returns string
 */
export const formatInteger = (number: number) => {
  const formatter = new Intl.NumberFormat('en-us', {
    minimumFractionDigits: 0,
  })
  return formatter.format(number)
}

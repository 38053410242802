import MomentUtils from '@date-io/moment'
import {MuiPickersUtilsProvider} from '@material-ui/pickers'
import EditModeProvider from 'Event/EditModeProvider'
import QueryProvider from 'QueryProvider'
import LocalizationProvider from 'lib/LocalizationProvider'
import BreadcrumbProvider from 'lib/ui/BreadcrumbProvider'
import SnackbarProvider from 'lib/ui/SnackbarProvider'
import ThemeProvider from 'lib/ui/theme/ThemeProvider'
import React from 'react'
import {QueryClient} from 'react-query'

export default function Providers(props: {
  children: React.ReactNode
  // Allows passing in a mocked store provider for tests
  storeProvider: React.FunctionComponent<{children: React.ReactNode}>
  queryClient?: QueryClient
}) {
  const StoreProvider = props.storeProvider

  return (
    <QueryProvider queryClient={props.queryClient}>
      <StoreProvider>
        <EditModeProvider>
          <ThemeProvider>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <SnackbarProvider>
                <BreadcrumbProvider>
                  <LocalizationProvider>{props.children}</LocalizationProvider>
                </BreadcrumbProvider>
              </SnackbarProvider>
            </MuiPickersUtilsProvider>
          </ThemeProvider>
        </EditModeProvider>
      </StoreProvider>
    </QueryProvider>
  )
}

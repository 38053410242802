export const emojiesList: Array<string> = [
  '☺️',
  '☹️',
  '☠️',
  '✊',
  '✌️',
  '☝️',
  '✋',
  '✍️',
  '⛑',
  '☘️',
  '⭐️',
  '✨',
  '⚡️',
  '☄️',
  '☀️',
  '⛅️',
  '☁️',
  '⛈',
  '❄️',
  '☃️',
  '⛄️',
  '☔️',
  '☂️',
  '☕️',
  '⚽️',
  '⚾️',
  '⛳️',
  '⛸',
  '⛷',
  '♟',
  '✈️',
  '⛵️',
  '⛴',
  '⚓️',
  '⛽️',
  '⛲️',
  '⛱',
  '⛰',
  '⛺️',
  '⛪️',
  '⛩',
  '⌚️',
  '⌨️',
  '☎️',
  '⏱',
  '⏲',
  '⏰',
  '⌛️',
  '⏳',
  '⚖️',
  '⚒',
  '⛓',
  '⚙️',
  '⛏',
  '⚔️',
  '⚰️',
  '⚗️',
  '✉️',
  '✂️',
  '✒️',
  '✏️',
  '❤️',
  '❣️',
  '✝️',
  '☪️',
  '☸️',
  '♈️',
  '⛎',
  '☦️',
  '☯️',
  '✡️',
  '♉️',
  '♊️',
  '♋️',
  '♌️',
  '♍️',
  '♎️',
  '♏️',
  '♐️',
  '♑️',
  '♒️',
  '♓️',
  '⚛️',
  '☢️',
  '☣️',
  '✴️',
  '㊙️',
  '㊗️',
  '❌',
  '♨️',
  '⛔️',
  '⭕️',
  '❗️',
  '❕',
  '❓',
  '❔',
  '‼️',
  '⁉️',
  '〽️',
  '✅',
  '♻️',
  '⚜️',
  '⚠️',
  '❇️',
  '✳️',
  '❎',
  'Ⓜ️',
  '⚧',
  'ℹ️',
  '0️',
  '1️',
  '2️',
  '3️',
  '4️',
  '5️',
  '6️',
  '7️⃣',
  '8️',
  '9️',
  '▶️',
  '⏏️',
  '*️',
  '#️',
  '⏸',
  '⏯',
  '⏹',
  '⏺',
  '⏭',
  '⏮',
  '⏩',
  '⏪',
  '⬆️',
  '⬅️',
  '➡️',
  '◀️',
  '⏬',
  '⏫',
  '⬇️',
  '↗️',
  '↘️',
  '↙️',
  '↖️',
  '↕️',
  '↔️',
  '↪️',
  '↩️',
  '⤴️',
  '⤵️',
  '➕',
  '➖',
  '➗',
  '✖️',
  '♾',
  '®️',
  '©️',
  '™️',
  '〰️',
  '➰',
  '➿',
  '✔️',
  '☑️',
  '⚪️',
  '⚫️',
  '▪️',
  '▫️',
  '◾️',
  '◽️',
  '◼️',
  '◻️',
  '⬜️',
  '⬛️',
  '♦️',
  '♥️',
  '♣️',
  '♠️',
]

import React from 'react'
import styled from 'styled-components'
import Button from '@material-ui/core/Button'
import {WelcomeMessageConfig} from 'Event/template/Cards/Dashboard/WelcomeMessage/WelcomeMessageConfig'
import {useAttendeeVariables} from 'Event'
import {useCardsTemplate} from 'Event/template/Cards'
import {useToggle} from 'lib/toggle'
import EditModeOnly from 'Event/Dashboard/editor/views/EditModeOnly'
import {useEditMode} from 'Event/EditModeProvider'
import {Font, useLoadFont} from 'lib/FontSelect'
import {BOLD, ITALIC, CAPS} from 'lib/ui/typography/FontStyleInput'

export default function WelcomeMessage() {
  const template = useCardsTemplate()
  const isEdit = useEditMode()

  if (!isEdit && !template.header.isEnabled) {
    return null
  }

  return (
    <>
      <EditModeOnly>
        <EditButton />
      </EditModeOnly>
      <>
        <WelcomeMessageText />
      </>
    </>
  )
}

function EditButton() {
  const {flag: configVisible, toggle: toggleConfig} = useToggle()

  return (
    <>
      <WelcomeMessageConfig showing={configVisible} onClose={toggleConfig} />
      <StyledEditButton
        onClick={toggleConfig}
        fullWidth
        size="large"
        variant="outlined"
        color="primary"
        aria-label="edit welcome message"
      >
        Edit Welcome Message
      </StyledEditButton>
    </>
  )
}

function WelcomeMessageText() {
  const {
    welcomeMessage: {
      text: welcomeText,
      font: welcomeTextFont,
      fontSize: welcomeFontSize,
      textColor: welcomeTextColor,
    },
  } = useCardsTemplate()
  useLoadFont(welcomeTextFont)

  const v = useAttendeeVariables()

  if (!welcomeText) {
    return null
  }

  return (
    <Text
      aria-label="welcome message"
      font={welcomeTextFont}
      welcomeColor={welcomeTextColor}
      fontSize={welcomeFontSize}
    >
      {v(welcomeText)}
    </Text>
  )
}

const Text = styled.div<{
  font: Font | null
  welcomeColor: string | null
  fontSize: number
}>`
  font-weight: ${(props) =>
    props.font?.styles?.includes(BOLD) ? 'bold' : '500'};
  font-family: ${(props) => (props.font ? props.font.family : 'inherit')};
  font-style: ${(props) =>
    props.font?.styles?.includes(ITALIC) ? 'italic' : 'normal'};
  text-transform: ${(props) =>
    props.font && !props.font.styles?.includes(CAPS) ? 'none' : 'uppercase'};
  color: ${(props) => props.welcomeColor};
  font-size: ${(props) => props.fontSize}px;
  line-height: 1.5;
  text-align: center;
  &:empty {
    height: ${(props) => props.theme.spacing[5]};
    width: 100%;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    line-height: 2.5;
  }
`

const StyledEditButton = styled(Button)`
  margin-top: 20px;
`

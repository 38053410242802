import Question from 'Event/Question'
import {Answer} from 'Event/SubmissionsProvider'
import {ValidationError} from 'lib/ui/api-client'
import {Form} from 'organization/Event/FormsProvider'
import React from 'react'
import {UseFormMethods} from 'react-hook-form'

// Hardcoded question ids to check
const QUESTION_ONE_ID = '11060'
const QUESTION_TWO_ID = '11062'
const QUESTION_THREE_ID = '11063'

/**
 * Custom Waiver form for mundoparalelo event July, 2023.
 */

// TODO: Remove custom event code
export default function MundoParaleloForm(props: {
  form: Form
  formErrors: UseFormMethods['errors']
  control: UseFormMethods['control']
  register: UseFormMethods['register']
  responseError: ValidationError<any>
  setValue: UseFormMethods['setValue']
  answers: Answer[]
  watch: UseFormMethods['watch']
}) {
  const {
    form,
    formErrors,
    control,
    register,
    responseError,
    setValue,
    answers,
    watch,
  } = props

  const currentAnswers: {question_id: string; value: string}[] =
    watch('answers') || []

  const questionOneAnswer = currentAnswers.find((a) => {
    if (a) {
      return a.question_id === QUESTION_ONE_ID
    }
    return false
  })
  const questionTwoAnswer = currentAnswers.find((a) => {
    if (a) {
      return a.question_id === QUESTION_TWO_ID
    }

    return false
  })

  const questions = form.questions.filter((q) => {
    // Only show question 2 if they selected this option for question 1
    if (q.id.toString() === QUESTION_TWO_ID) {
      return questionOneAnswer?.value === 'Sim! Já fiz Lançamento Clássico'
    }

    // Only show question three if they select this option for question 2
    if (q.id.toString() === QUESTION_THREE_ID) {
      return questionTwoAnswer?.value === 'R$ 100.000 ou mais'
    }

    return true
  })

  return (
    <>
      {questions.map((question, index) => (
        <Question
          formErrors={formErrors}
          key={index}
          index={index}
          control={control}
          question={question}
          answers={answers}
          register={register}
          responseError={responseError}
          setValue={setValue}
          watch={watch}
        />
      ))}
    </>
  )
}

import React from 'react'
import LocalizedDateTimePicker from 'lib/LocalizedDateTimePicker'
import {useEvent, useUpdate} from 'Event/EventProvider'
import {onChangeDate} from 'lib/dom'
import {useToggleArray} from 'lib/toggle'

export default function TurnOffActionsDate(props: {
  onError: (error: string | null) => void
}) {
  const [loading, toggleLoading] = useToggleArray()
  const {event} = useEvent()

  const setTurnOffActionsDate = (date: string | null) => {
    if (loading) {
      return
    }
    toggleLoading()

    update({turn_off_actions_date: date})
      .catch(props.onError)
      .finally(toggleLoading)
  }

  const update = useUpdate()

  return (
    <div>
      <LocalizedDateTimePicker
        disabled={loading}
        label="Turn off actions at"
        value={event.turn_off_actions_date}
        onChange={onChangeDate(setTurnOffActionsDate)}
        fullWidth
        inputProps={{
          'aria-label': 'turn off actions date',
        }}
        onClear={() => setTurnOffActionsDate(null)}
      />
    </div>
  )
}

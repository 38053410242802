import GradientColorPicker from 'lib/ui/form/BackgroundPicker/GradientColorPicker'
import styled from 'styled-components'
import ImagePicker from 'lib/ui/form/BackgroundPicker/ImagePicker'
import SolidColorPicker from 'lib/ui/form/BackgroundPicker/SolidColorPicker'
import React, {useEffect, useState} from 'react'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import TabPanel from 'lib/ui/tabs/TabPanel'
import {isNull} from 'lodash'
import {usePrevious} from 'lib/state'

export type BackgroundPickerProps = {
  label: string
  background?: string
  onChange: (background: string) => void
  disabled?: boolean
  hasFreeSize?: boolean
  className?: string
}

const SOLID = 0
const GRADIENT = 1
const IMAGE = 2

type BackgroundType = typeof SOLID | typeof GRADIENT | typeof IMAGE

export default function BackgroundPicker(props: BackgroundPickerProps) {
  const {background, onChange} = props
  const [type, setType] = useState<BackgroundType | null>(null)

  const prevType = usePrevious(type)

  const isGradient = background?.startsWith('linear-gradient')
  const isImage = background?.startsWith('url')

  // Load initial type
  useEffect(() => {
    if (!isNull(type)) {
      return
    }

    if (isImage) {
      setType(IMAGE)
      return
    }

    if (isGradient) {
      setType(GRADIENT)
      return
    }

    setType(SOLID)
  }, [isImage, isGradient, type, setType])

  // Handle switching types by trying to parse a suitable initial
  // value. If we can't parse an initial value from the prev
  // type, we'll just clear the value.
  useEffect(() => {
    const didChangeType = !isNull(prevType) && prevType !== type
    if (!didChangeType) {
      return
    }

    const canSetGradient = prevType === SOLID && type === GRADIENT
    if (canSetGradient) {
      const color1 = background || '#FFFFFF'
      const gradientBg = `linear-gradient(180deg,${color1},#FFFFFF)`
      onChange(gradientBg)
      return
    }

    if (type === SOLID) {
      // Parsing values is handled inside SolidColorPicker
      // instead.
      return
    }

    onChange('')
  }, [prevType, type, background, onChange])

  if (isNull(type)) {
    return null
  }

  return (
    <Box className={props.className}>
      <StyledTabs
        value={type}
        onChange={(_, value) => {
          setType(parseInt(value) as BackgroundType)
        }}
        TabIndicatorProps={{
          style: {
            display: 'none',
          },
        }}
        scrollButtons="off"
      >
        <StyledTab
          aria-label="set solid background"
          label="Solid"
          disableRipple
          value={SOLID}
        />
        <StyledTab
          aria-label="set gradient background"
          label="Gradient"
          disableRipple
          value={GRADIENT}
        />
        <StyledTab
          aria-label="set image background"
          label="Image"
          disableRipple
          value={IMAGE}
        />
      </StyledTabs>

      <StyledTabPanel currentIndex={type} index={SOLID}>
        <SolidColorPicker {...props} />
      </StyledTabPanel>

      <StyledTabPanel currentIndex={type} index={GRADIENT}>
        <GradientColorPicker {...props} />
      </StyledTabPanel>

      <StyledTabPanel currentIndex={type} index={IMAGE}>
        <ImagePicker {...props} />
      </StyledTabPanel>
    </Box>
  )
}

// We're going to export the container here in case any parent needs to override
// the style via CSS. ie., instead a config panel where we would like to give
// the input negative margins.
export const Box = styled.div``

const StyledTabs = styled(Tabs)`
  background: ${(props) => props.theme.colors.input.background};
  padding: 2px;
  min-height: auto !important;

  // Container
  > div > div {
    justify-content: space-between;
    padding: 0 ${(props) => props.theme.spacing[4]};
  }
`

const StyledTab = styled(Tab)`
  background-color: ${(props) => props.theme.colors.input.dark};
  margin: 0px 1px !important;
  max-width: 100%;
  min-width: auto !important;
  min-height: auto !important;
  border-radius: 4px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px !important;
  line-height: 18px !important;
  padding: ${(props) =>
    `${props.theme.spacing[2]} ${props.theme.spacing[4]}`} !important;

  &.Mui-selected {
    background-color: ${(props) => props.theme.colors.panel.background};
    border-radius: 4px;
  }
`

const StyledTabPanel = styled(TabPanel)`
  padding: ${(props) => `${props.theme.spacing[6]} ${props.theme.spacing[5]}`} 0 !important;
`

import React from 'react'
import styled from 'styled-components'
import Box from '@material-ui/core/Box'
import {Header, Title} from 'lib/ui/typography'
import {UserFilledIcon} from 'lib/ui/Icon'
import {CardHeader, MainCard, MainCardContent} from 'Event/Statistics/Summary'
import {
  OpenStatisticWindowButton,
  STATISTIC_TOTAL_ATTENDEES,
} from 'Event/Statistics'

export default function TotalAttendees(props: {
  total: number
  checked_in: number
  className?: string
}) {
  const {total, checked_in: checkedIn, className} = props
  const percent = total === 0 ? 0 : Math.round((checkedIn / total) * 100)

  return (
    <MainCard className={className}>
      <MainCardContent>
        <CardHeader>
          <Header color="white">Total Attendees</Header>
          <OpenStatisticWindowButton
            statisticType={STATISTIC_TOTAL_ATTENDEES}
          />
        </CardHeader>
        <TotalAttendeesCenter>
          <UserFilledIcon color="white" iconSize={48} />
          <StyledTitle color="white">{total}</StyledTitle>
          <Header color="#3490DC">{`${percent}% Checked In`}</Header>
        </TotalAttendeesCenter>
        <Box />
      </MainCardContent>
    </MainCard>
  )
}

const TotalAttendeesCenter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const StyledTitle = styled(Title)`
  margin-top: ${(props) => props.theme.spacing[4]};
  margin-bottom: ${(props) => props.theme.spacing[6]};
`

import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import {StyledInput} from 'lib/ui/TextField'
import {Answer} from 'Event/SubmissionsProvider'
import FormSelect from 'organization/Event/FormsProvider/FormSelect'
import {QuestionSelect} from 'Event/attendee-rules/RuleConfig/RuleList/SingleRule/FormResponseRule/FormResponseRuleConfig'
import {useForms} from 'organization/Event/FormsProvider'
import If from 'lib/ui/layout/If'
import {Question} from 'organization/Event/QuestionsProvider'
import {usePreviewSubmissions} from 'Event/SubmissionsProvider/PreviewSubmissionsProvider'
import OptionSelect from 'Event/attendee-rules/RuleConfig/RuleList/SingleRule/FormResponseRule/OptionSelect'
import Visible from 'lib/ui/layout/Visible'
import Select from 'lib/ui/Select'
import FormControl from 'lib/ui/FormControl'
import RemoveButton from 'lib/ui/form/RemoveButton'

export function AnswerInput(props: {answer: Answer}) {
  const {answer} = props
  const [formId, setFormId] = useState(answer.form_id)
  const [questionId, setQuestionId] = useState<Question['id']>(
    answer.question_id,
  )
  const [value, setValue] = useState(answer.value)
  const {forms} = useForms()

  const submissions = usePreviewSubmissions()
  const {update} = submissions

  const form = forms.find((f) => f.id === formId)

  const question = form?.questions.find((q) => q.id === questionId) || null
  const selectedQuestion = question && 'options' in question ? question : null

  const handleFormSelect = (id: number | null) => {
    setFormId(id || 0)
  }

  // Update answer
  useEffect(() => {
    const hasChanges =
      formId !== answer.form_id ||
      questionId !== answer.question_id ||
      value !== answer.value

    if (!hasChanges) {
      return
    }

    update({
      id: answer.id,
      form_id: formId,
      question_id: typeof questionId === 'string' ? 0 : questionId,
      value,
    })
  }, [update, formId, questionId, value, answer])

  return (
    <Box>
      <RemoveButton
        onClick={() => submissions.remove(answer)}
        aria-label="remove form response"
      />
      <Row>
        <Input>
          <FormSelect
            value={formId}
            onChange={handleFormSelect}
            variant="outlined"
            label="Form"
          />
        </Input>
        <Input>
          <If hasValue={form}>
            {(f) => (
              <QuestionSelect
                variant="outlined"
                label="Question"
                form={f}
                question={selectedQuestion}
                setQuestion={(q) => setQuestionId(q.id)}
              />
            )}
          </If>
          {/* Placeholder QuestionSelect for before we select a Form */}
          <Visible when={!form}>
            <Select
              fullWidth
              variant="outlined"
              label="Question"
              onChange={() => {}}
              disabled
            />
          </Visible>
        </Input>
      </Row>
      <FormControl>
        <OptionSelect
          question={selectedQuestion}
          value={value}
          onChange={setValue}
        />
      </FormControl>
    </Box>
  )
}

const Box = styled.div`
  position: relative;
`

const Row = styled.div`
  display: flex;
`

const Input = styled.div`
  flex: 1;
  margin-right: ${(props) => props.theme.spacing[2]};
  overflow: hidden;

  ${StyledInput} {
    width: 100%;
  }
`

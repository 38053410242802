import {useRoom} from 'organization/Event/Room/RoomProvider'
import React from 'react'
import DangerButton from 'lib/ui/Button/DangerButton'
import ConfirmDialog from 'lib/ui/ConfirmDialog'
import {useAreaRoutes} from 'organization/Event/Area/AreaRoutes'
import {useHistory} from 'react-router-dom'
import {useRooms} from 'organization/Event/Area/RoomsProvider'
import Box from '@material-ui/core/Box'
import {EVENT_SETTINGS, usePermissions} from 'organization/PermissionsProvider'

export default function DeleteRoomButton() {
  const {processing} = useRoom()
  const deleteRoom = useDeleteRoom()

  const {can} = usePermissions()
  if (!can(EVENT_SETTINGS)) {
    return null
  }

  return (
    <ConfirmDialog
      onConfirm={deleteRoom}
      description="Deleting this Room will not only delete the Room, but also any statistics associated with this Room. This can NOT be undone. Are you sure you wish to delete it?"
    >
      {(confirm) => (
        <Box mb={2}>
          <DangerButton
            variant="outlined"
            disabled={processing}
            onClick={confirm}
            aria-label="delete room"
            size="small"
          >
            Delete
          </DangerButton>
        </Box>
      )}
    </ConfirmDialog>
  )
}

function useDeleteRoom() {
  const {deleteRoom, room} = useRoom()
  const areaRoutes = useAreaRoutes()
  const history = useHistory()
  const {remove} = useRooms()

  const goToBackToArea = () => {
    history.push(areaRoutes.root)
  }

  return () => {
    deleteRoom().then(() => {
      remove(room)
      goToBackToArea()
    })
  }
}

import React from 'react'
import styled from 'styled-components'
import Container from '@material-ui/core/Container'
import {MenuIconButton} from 'lib/ui/IconButton/MenuIconButton'
import {eventRoutes} from 'Event/Routes'
import {RelativeLink} from 'lib/ui/link/RelativeLink'
import {rgba} from 'lib/color'
import {useCardsTemplate} from 'Event/template/Cards'
import {useToggle} from 'lib/toggle'
import {Editable} from 'Event/Dashboard/editor/views/EditComponent'
import HeaderConfig from 'Event/template/Cards/Dashboard/Header/HeaderConfig'
import {useRandomBackground} from 'Event/template/Cards/Login/Page'
import EditModeOnly from 'Event/Dashboard/editor/views/EditModeOnly'
import WelcomeMessage from 'Event/template/Cards/Dashboard/WelcomeMessage'
import {BackgroundPosition} from 'lib/ui/BackgroundPositionSelector'

export default function Header(props: {
  toggleMenu: () => void
  menuVisible: boolean
  'aria-label'?: string
  hideLogo?: boolean
  isDashboardHeader: boolean
}) {
  const {flag: configVisible, toggle: toggleConfig} = useToggle()
  const {menu, logo} = useCardsTemplate()
  const {iconColor} = menu

  return (
    <>
      <EditModeOnly>
        <HeaderConfig showing={configVisible} onClose={toggleConfig} />
      </EditModeOnly>
      <Editable onEdit={toggleConfig}>
        <CollapsableBackground isDashboardHeader={props.isDashboardHeader}>
          <CollapsableColorOverlay>
            <LayoutContainer maxWidth="lg">
              <Side>
                <MenuIconButton
                  active={props.menuVisible}
                  onClick={props.toggleMenu}
                  aria-label="show side menu"
                  iconColor={iconColor}
                />
              </Side>
              <Middle>
                <StyledRelativeLink
                  to={eventRoutes.root}
                  logo={logo}
                  disableStyles
                >
                  <CollapsableLogo hideLogo={props.hideLogo} />
                </StyledRelativeLink>
                <WelcomeMessage />
              </Middle>
              <Side />
            </LayoutContainer>
          </CollapsableColorOverlay>
        </CollapsableBackground>
      </Editable>
    </>
  )
}

function CollapsableBackground(props: {
  children: React.ReactElement
  isDashboardHeader: boolean
}) {
  const headerBackground = useRandomBackground()

  const backgroundImage = props.isDashboardHeader
    ? ''
    : headerBackground?.image || null
  const backgroundPosition = headerBackground ? headerBackground.position : null

  return (
    <Box
      backgroundImage={backgroundImage}
      backgroundPosition={backgroundPosition}
      disableMB={!props.isDashboardHeader}
    >
      {props.children}
    </Box>
  )
}

function CollapsableLogo(props: {hideLogo?: boolean}) {
  const {title, header, logo} = useCardsTemplate()

  if (!header.isEnabled || props.hideLogo === true) {
    return null
  }
  return (
    <Logo
      src={logo || ''}
      alt={title}
      aria-label="logo"
      width={header.logoSize}
    />
  )
}

function CollapsableColorOverlay(props: {children: React.ReactElement}) {
  const {header} = useCardsTemplate()
  const backgroundColorRgb = rgba(
    header.backgroundColor,
    header.backgroundOpacity / 100,
  )

  if (!header.isEnabled) {
    return props.children
  }

  return (
    <ColorOverlay color={backgroundColorRgb} aria-label="header">
      {props.children}
    </ColorOverlay>
  )
}

const Side = styled.div`
  width: 42px;
  display: flex;
  align-items: center;
`

const Box = styled.div<{
  backgroundImage: string | null
  backgroundPosition: BackgroundPosition | null
  disableMB: boolean
}>`
  ${(props) =>
    props.backgroundImage
      ? `background-image: url(${props.backgroundImage});`
      : null}

  background-size: cover;
  background-position: ${(props) =>
    props.backgroundPosition ? props.backgroundPosition : 'center'};
  background-repeat: no-repeat;
  margin-bottom: ${(props) => (props.disableMB ? '0' : props.theme.spacing[8])};
`

const ColorOverlay = styled.div<{
  color: string
}>`
  background-color: ${(props) => props.color};
`

const Middle = styled.div`
  flex: 1;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
const Logo = styled.img<{width: number}>`
  margin: ${(props) => props.theme.spacing[3]} 0;
  width: ${(props) => props.width}%;
  max-height: 100%;
  max-width: 100%;
`

const LayoutContainer = styled(Container)`
  display: flex;
  flex-direction: row;
  width: 100%;
  min-height: 50px;
`

const StyledRelativeLink = styled(RelativeLink)<{logo: string | null}>`
  display: ${(props) => (props.logo ? 'flex;' : 'none;')}
  justify-content: center; 
  height: 100%; 
  width: 100%; 
`

import React from 'react'
import styled, {useTheme} from 'styled-components'
import {colors} from 'lib/ui/theme'
import MuiMenuItem from '@material-ui/core/MenuItem'
import {Text} from 'lib/ui/typography'

export type MenuItemProps = {
  icon?: string
  alt?: string
  color?: string
  className?: string
  disabled?: boolean
  children: string
  onClick?: () => void
  'aria-label'?: string
}

export default React.forwardRef<HTMLLIElement, MenuItemProps>((props, ref) => {
  const {icon, alt, className, children, onClick, disabled} = props

  return (
    <MuiMenuItem
      className={className}
      onClick={onClick}
      disabled={disabled}
      aria-label={props['aria-label']}
      ref={ref}
    >
      <Content>
        <MenuIcon icon={icon} alt={alt} />
        <StyledText color={useColor(props)}>{children}</StyledText>
      </Content>
    </MuiMenuItem>
  )
})

function MenuIcon(props: Pick<MenuItemProps, 'icon' | 'alt'>) {
  const {icon, alt} = props

  if (!icon) {
    return null
  }

  return <img src={icon} alt={alt} />
}

function useColor(props: MenuItemProps) {
  const theme = useTheme()

  if (props.color === 'danger') {
    return colors.error
  }
  if (props.color === 'primary') {
    return colors.primary
  }
  if (props.color === 'success') {
    return colors.success
  }
  if (props.color === 'info') {
    return colors.info
  }
  if (props.color === 'warning') {
    return colors.warning
  }
  if (props.color === 'secondary') {
    return colors.secondary
  }
  if (props.color === 'accent') {
    return colors.accent
  }
  if (props.color === 'light') {
    return '#FFFFFF'
  }
  if (props.color === 'dark') {
    return '#000000'
  }

  if (theme.name === 'dark') {
    return '#FFFFFF'
  }

  return colors.gray400
}

const Content = styled.div`
  display: flex;
  align-items: center;

  img {
    width: 15px;
    height: 15px;
    margin-right: 8px;
  }
`

const StyledText = styled(Text)<{color: string}>`
  font-weight: normal !important;
  color: ${(props) => props.color} !important;
`

import {useTemplate} from 'Event/TemplateProvider'
import {useSaveTemplate} from 'Event/TemplateUpdateProvider'
import Title from 'lib/ui/ConfigPanel/Title'
import TextField from 'lib/ui/TextField'
import Config from 'organization/Event/Configurable/Config'
import {useConfig} from 'organization/Event/Configurable'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import React from 'react'
import StylingPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/StylingPanel'
import {Controller} from 'react-hook-form'
import ColorPicker from 'lib/ui/ColorPicker'
import Divider from 'lib/ui/ConfigPanel/Divider'
import Slider from 'lib/ui/Slider'

export default function PasswordFieldConfig() {
  const saveTemplate = useSaveTemplate()
  const template = useTemplate()
  const {
    form: {register, control},
  } = useConfig()

  return (
    <Config title="Password Field" onSave={saveTemplate}>
      <SettingsPanel>
        <Title>Text</Title>
        <TextField
          label="Password Label"
          name="setPasswordForm.passwordLabel"
          defaultValue={template.setPasswordForm.passwordLabel}
          inputProps={{
            ref: register,
            'aria-label': 'password label',
          }}
        />
        <TextField
          label="Confirm Password Label"
          name="setPasswordForm.confirmPasswordLabel"
          defaultValue={template.setPasswordForm.confirmPasswordLabel}
          inputProps={{
            ref: register,
            'aria-label': 'confirm password label',
          }}
        />
      </SettingsPanel>
      <StylingPanel>
        <Title>Input Background</Title>
        <Controller
          name="setPasswordForm.input.backgroundColor"
          control={control}
          defaultValue={template.setPasswordForm.input.backgroundColor}
          render={({value, onChange}) => (
            <ColorPicker
              label="Color"
              color={value}
              onPick={onChange}
              aria-label="input background color"
            />
          )}
        />
        <Divider />
        <Title>Typography</Title>
        <Controller
          name="setPasswordForm.input.textColor"
          control={control}
          defaultValue={template.setPasswordForm.input.textColor}
          render={({value, onChange}) => (
            <ColorPicker label="Color" color={value} onPick={onChange} />
          )}
        />
        <Divider />
        <Title>Label</Title>
        <Controller
          name="setPasswordForm.input.labelColor"
          control={control}
          defaultValue={template.setPasswordForm.input.labelColor}
          render={({value, onChange}) => (
            <ColorPicker label="Color" color={value} onPick={onChange} />
          )}
        />
        <Divider />
        <Title>Border</Title>
        <Controller
          name="setPasswordForm.input.borderWidth"
          defaultValue={template.setPasswordForm.input.borderWidth}
          control={control}
          render={({value, onChange}) => (
            <Slider
              label="Thickness"
              value={value}
              onChange={onChange}
              aria-label="input border width"
            />
          )}
        />
        <Controller
          name="setPasswordForm.input.borderRadius"
          defaultValue={template.setPasswordForm.input.borderRadius}
          control={control}
          render={({value, onChange}) => (
            <Slider
              label="Radius"
              value={value}
              onChange={onChange}
              aria-label="input border radius"
            />
          )}
        />
        <Controller
          name="setPasswordForm.input.borderColor"
          control={control}
          defaultValue={template.setPasswordForm.input.borderColor}
          render={({value, onChange}) => (
            <ColorPicker label="Color" color={value} onPick={onChange} />
          )}
        />
      </StylingPanel>
    </Config>
  )
}

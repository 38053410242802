import React from 'react'
import styled from 'styled-components'
import Typography from '@material-ui/core/Typography'
import TextContent from 'lib/ui/form/TextEditor/Content'
import {useAttendeeVariables} from 'Event'
import QuestionIcon from 'Event/Sponsors/QuestionIcon'
import {Sponsor} from 'Event/Sponsors'
import Buttons from 'Event/Sponsors/Buttons'
import {useLeftyTemplate} from 'Event/template/Lefty'
import {Lefty} from 'Event/template/Lefty'

type BodyProps = {
  sponsor: Sponsor
  toggleForm: () => void
  textColor?: string
  id: string
}

export default function Content(props: BodyProps) {
  const {sponsor, id} = props
  const v = useAttendeeVariables()
  const {
    sponsors: {separator},
  } = useLeftyTemplate()
  return (
    <Box>
      <SponsorHeader separator={separator}>
        <Typography variant="h5">{v(sponsor.name)}</Typography>
      </SponsorHeader>
      <SponsorTextContent>
        <TextContent>{v(sponsor.description)}</TextContent>
        <StyledQuestionIcon>
          <QuestionIcon sponsor={sponsor} onClick={props.toggleForm} id={id} />
        </StyledQuestionIcon>
        <Buttons sponsorId={props.id} />
      </SponsorTextContent>
    </Box>
  )
}

const Box = styled.div`
  width: 100%;
`

const StyledQuestionIcon = styled.div`
  display: flex;
  justify-content: end;
`

const SponsorHeader = styled.div<{separator: Lefty['sponsors']['separator']}>`
  display: flex;
  justify-content: space-between;
  border-bottom: ${(props) => props.separator.width}px solid
    ${(props) => props.separator.color};
  padding-left: ${(props) => props.theme.spacing[6]};
  padding-right: ${(props) => props.theme.spacing[6]};
  padding-top: ${(props) => props.theme.spacing[4]};
  padding-bottom: ${(props) => props.theme.spacing[4]};
`

const SponsorTextContent = styled.div`
  padding-left: ${(props) => props.theme.spacing[6]};
  padding-right: ${(props) => props.theme.spacing[6]};
  padding-bottom: ${(props) => props.theme.spacing[4]};
  padding-top: ${(props) => props.theme.spacing[4]};
`

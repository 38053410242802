import {ObvioEvent} from 'Event'
import {useEvent} from 'Event/EventProvider'
import {useMutation} from 'react-query'
import {marketplaceApi} from 'lib/marketplace-api/url'
import {useEventMarketplaceClient} from 'lib/marketplace-api/use-event-marketplace-client'
import {PurchasePage} from 'Event/Marketplace/purchase-page'

export function useCopyTemplate(params: {event: ObvioEvent}) {
  const {event} = params

  const {event: target} = useEvent()
  const {client} = useEventMarketplaceClient()

  return useMutation({
    mutationFn: async () => {
      const url = marketplaceApi(`/events/${event.id}/purchase_page/copy`)
      return client!.post<PurchasePage>(url, {
        target_event_id: target.id,
      })
    },
  })
}

import React from 'react'
import {Speaker} from 'Event/Speakers'

type ImageProps = {
  speaker: Speaker
  className?: string
}

export default function Image(props: ImageProps) {
  const {speaker} = props
  const alt = speaker.name

  if (!speaker.image) {
    return null
  }

  return (
    <div className={props.className}>
      <img src={speaker.image} alt={alt} aria-label="speaker image" />
    </div>
  )
}

import React from 'react'
import {SignUpProps} from 'Event/auth/SignUp'
import LeftPanel from 'Event/template/NiftyFifty/Login/LeftPanel'
import Page from 'Event/template/NiftyFifty/Page'
import RightPanel from 'Event/template/NiftyFifty/SignUp/RightPanel'
import MobilePanel from 'Event/template/NiftyFifty/SignUp/MobilePanel'

export default function ForgotPassword(props: SignUpProps) {
  return (
    <Page
      Left={<LeftPanel />}
      Right={<RightPanel {...props} />}
      Mobile={<MobilePanel {...props} />}
    />
  )
}

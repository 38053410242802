import React from 'react'
import {ObvioEvent} from 'Event'
import styled from 'styled-components'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import {SubHead} from 'lib/ui/typography'
import Button from 'lib/ui/Button'
import defaultLogo from 'assets/images/logo_vertical.png'
import grey from '@material-ui/core/colors/grey'
import Icon from 'lib/ui/Icon'
import {getEventUrl} from 'lib/url'
import {useCopyTemplate} from 'lib/marketplace-api/use-copy-template'
import {useUpdateTemplate} from 'organization/Marketplace/PurchasePageConfig/TemplateUpdateProvider'
import {useDuplicateAssets} from 'organization/Event/SelectTemplatePage'
import {PurchasePage} from 'Event/Marketplace/purchase-page'

interface TemplateCardProps {
  event: ObvioEvent
}

export default function TemplateCard(props: TemplateCardProps) {
  const {event} = props

  const logo = event.logo?.url || defaultLogo

  const {mutate: copyTemplate, isLoading} = useCopyTemplate({event})
  const withCopiedAssets = useDuplicateAssets<PurchasePage['template']>()
  const updateTemplate = useUpdateTemplate()

  const onSelect = () => {
    copyTemplate(void 0, {
      onSuccess: (purchasePage) => {
        withCopiedAssets(purchasePage.template).then(updateTemplate)
      },
    })
  }

  const onViewPurchasePage = () => {
    const eventUrl = getEventUrl(event)
    window.open(`${eventUrl}/purchase`, '_blank')
  }

  return (
    <StyledCard>
      <Box>
        <Image src={logo} alt={event.name} aria-label="event logo" />
      </Box>
      <Container>
        <StyledCardContent>
          <SubHead>{event.name}</SubHead>
        </StyledCardContent>
        <StyledCardActions>
          <ActionsContainer>
            <Button
              variant="outlined"
              color="primary"
              fullWidth
              aria-label="select template"
              disabled={isLoading}
              onClick={onSelect}
            >
              Select
            </Button>
            <Button
              variant="text"
              color="primary"
              fullWidth
              aria-label="view sample dashboard"
              startIcon={<Icon className="fas fa-external-link" />}
              onClick={onViewPurchasePage}
            >
              View Purchase Page
            </Button>
          </ActionsContainer>
        </StyledCardActions>
      </Container>
    </StyledCard>
  )
}

const StyledCard = styled(Card)`
  display: flex;
  flex-direction: column;
  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    flex-direction: row;
    grid-gap: ${(props) => props.theme.spacing[6]};
  }
  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    flex-direction: column;
  }
`

const Container = styled.div`
  padding: ${(props) => props.theme.spacing[4]};
`

const StyledCardContent = styled(CardContent)`
  padding: ${(props) => props.theme.spacing[6]} 0;
`

const StyledCardActions = styled(CardActions)`
  display: block !important;
  padding: 0;
`

const ActionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: ${(props) => props.theme.spacing[2]};
`

const Box = styled.div`
  padding: ${(props) => props.theme.spacing[5]};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 180px;

  &:hover {
    background: ${grey[200]};
  }
`

const Image = styled.img`
  max-width: 100%;
  max-height: 152px;
`

import ColorPicker from 'lib/ui/ColorPicker'
import React from 'react'
import {Controller} from 'react-hook-form'
import {useConfig} from 'organization/Event/Configurable'
import {ResourceSectionProps} from 'Event/template/Townhall/Dashboard/Main/ResourceSection'
import CollapsibleSection from 'lib/ui/ConfigPanel/CollapsibleSection'
import Label from 'lib/ui/TextField/Label'
import FontSelect from 'lib/FontSelect'
import BackgroundPicker from 'lib/ui/form/BackgroundPicker'
import {useTownhallTemplate} from 'Event/template/Townhall'

export default function Styling(props: {
  section: ResourceSectionProps
  sectionId: string
}) {
  const {section, sectionId} = props
  const {
    form: {control},
  } = useConfig()
  const template = useTownhallTemplate()
  const {textColor} = template
  const resourceColor = section.color ? section.color : textColor

  return (
    <>
      <CollapsibleSection
        label="Background"
        storageKey="dashboard.resourceSection.background.styling"
      >
        <Controller
          name={`dashboardSections.${sectionId}.background`}
          control={control}
          defaultValue={section.background}
          render={({onChange, value}) => (
            <BackgroundPicker
              hasFreeSize
              label="Background Color"
              background={value}
              onChange={onChange}
            />
          )}
        />
      </CollapsibleSection>
      <CollapsibleSection
        label="Typography"
        storageKey="dashboard.resourceSection.text.styling"
      >
        <Label>Font</Label>
        <Controller
          control={control}
          name={`dashboardSections.${sectionId}.font`}
          defaultValue={section.font || null}
          render={({value, onChange}) => (
            <FontSelect value={value} onChange={onChange} />
          )}
        />
        <Controller
          name={`dashboardSections.${sectionId}.color`}
          control={control}
          defaultValue={resourceColor}
          render={({value, onChange}) => (
            <ColorPicker
              label="Font Color"
              aria-label="font color"
              color={value}
              onPick={onChange}
            />
          )}
        />
      </CollapsibleSection>
    </>
  )
}

import React from 'react'
import {useAttendeeVariables} from 'Event'
import {useTownhallTemplate} from 'Event/template/Townhall'
import styled from 'styled-components'
import Grid from '@material-ui/core/Grid'
import {Speaker, VisibleSpeaker} from 'Event/Speakers'
import {Title} from 'lib/ui/typography'
import Content from 'lib/ui/form/TextEditor/Content'
import Button from 'lib/ui/Button'
import SpeakerButton, {
  EditableButton,
} from 'Event/template/Townhall/Speakers/SpeakerButton'
import {useEditMode} from 'Event/EditModeProvider'
import {NavButtonProps} from 'Event/Dashboard/components/NavButton'
import {useSaveTemplate} from 'Event/TemplateUpdateProvider'
import {SpeakerConfigurable} from 'Event/Speakers/SpeakerConfig'
import FeaturedSpeakerConfig from 'Event/template/Townhall/Speakers/FeaturedSpeakerConfig'
import {orderedIdsByPosition, createPositions} from 'lib/list'
import AddFeaturedSpeakerButton from 'Event/template/Townhall/Speakers/FeaturedSpeakersList/AddFeaturedSpeakerButton'
import {TownhallSpeaker} from 'Event/template/Townhall/Speakers'
import {
  DragDropContext,
  Droppable,
  DropResult,
  Draggable,
} from 'react-beautiful-dnd'

const DEFAULT_BUTTON_WIDTH_PERCENT = 100

export default function FeaturedSpeakersList() {
  const {featuredSpeakers, textColor} = useTownhallTemplate()
  const ids = orderedIdsByPosition(featuredSpeakers.items)
  const isEditMode = useEditMode()

  const speakers = ids.map((id, index) => (
    <VisibleSpeaker key={id} speaker={featuredSpeakers.items[id]}>
      <FeaturedSpeaker
        id={id}
        speaker={featuredSpeakers.items[id]}
        index={index}
        color={textColor}
      />
    </VisibleSpeaker>
  ))

  if (isEditMode) {
    return (
      <Box>
        <DroppableList>{speakers}</DroppableList>
        <AddFeaturedSpeakerButton />
      </Box>
    )
  }

  return (
    <Box>
      {speakers}
      <AddFeaturedSpeakerButton />
    </Box>
  )
}

function DroppableList(props: {children: React.ReactNode[]}) {
  const handleDrag = useHandleDragSpeakers()

  return (
    <DragDropContext onDragEnd={handleDrag}>
      <Droppable droppableId="drag-and-drop-speaker">
        {(provided) => (
          <Grid container ref={provided.innerRef} {...provided.droppableProps}>
            <>
              {props.children}
              {provided.placeholder}
            </>
          </Grid>
        )}
      </Droppable>
    </DragDropContext>
  )
}

function FeaturedSpeaker(props: {
  id: string
  speaker: Speaker
  index: number
  color: string
}) {
  const isEditMode = useEditMode()

  if (!isEditMode) {
    return <SpeakerContent {...props} />
  }

  return (
    <Draggable draggableId={String(props.id)} index={props.index}>
      {(provided) => (
        <Box
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <SpeakerContent {...props} />
        </Box>
      )}
    </Draggable>
  )
}

function SpeakerContent(props: {
  id: string
  speaker: Speaker
  index: number
  color: string
}) {
  const {id, speaker, index, color} = props
  const v = useAttendeeVariables()
  const reverse = index % 2 === 0

  if (!speaker.image) {
    return (
      <SpeakerConfigurable>
        <FeaturedSpeakerConfig speaker={speaker} id={id} />
        <FeaturedItemContainer>
          <FeaturedTextContainer reverse={false} textColor={color}>
            <StyledTitle>{v(speaker.name)}</StyledTitle>
            <StyledContent>{v(speaker.text)}</StyledContent>
            <ButtonBox speaker={speaker} id={id} />
          </FeaturedTextContainer>
        </FeaturedItemContainer>
      </SpeakerConfigurable>
    )
  }

  return (
    <SpeakerConfigurable>
      <FeaturedSpeakerConfig speaker={speaker} id={id} />
      <FeaturedItemContainer reverse={reverse}>
        <FeaturedTextContainer reverse={reverse} textColor={color}>
          <StyledTitle>{v(speaker.name)}</StyledTitle>
          <StyledContent>{v(speaker.text)}</StyledContent>
          <ButtonBox speaker={speaker} id={id} />
        </FeaturedTextContainer>
        <FeaturedImageContainer reverse={reverse}>
          <StyledImage src={speaker.image} />
        </FeaturedImageContainer>
      </FeaturedItemContainer>
    </SpeakerConfigurable>
  )
}

function ButtonBox(props: {id: string; speaker: TownhallSpeaker}) {
  const {id, speaker} = props
  const isEditMode = useEditMode()

  if (!isEditMode) {
    return <NormalBox id={id} speaker={speaker} />
  }

  return <EditableBox id={id} speaker={speaker} />
}

function NormalBox(props: {id: string; speaker: TownhallSpeaker}) {
  const {id, speaker} = props

  if (!speaker.button) {
    return null
  }

  return <SpeakerButton button={speaker.button} speakerId={id} />
}

function EditableBox(props: {id: string; speaker: TownhallSpeaker}) {
  const {id, speaker} = props

  if (!speaker.button) {
    return <AddButton id={id} />
  }

  return <EditableButton button={speaker.button} speakerId={id} />
}

function AddButton(props: {id: string}) {
  const {id} = props
  const save = useSaveTemplate()
  const onAdd = () => {
    const button: NavButtonProps = {
      text: 'Button',
      link: '',
      rules: [],
      isAreaButton: false,
      isImageUpload: false,
      isFormButton: false,
      formId: null,
      areaId: null,
      actionId: null,
      isVisible: true,
      infusionsoftTag: null,
      mailchimpTag: null,
      hubspotTag: null,
      zapierTag: null,
      highLevelTag: null,
      activeCampaignTag: null,
      convertKitTag: null,
      ontraportTag: null,
      webhook: null,
      width: DEFAULT_BUTTON_WIDTH_PERCENT,
      fontSize: 16,
      padding: 8,
      height: 32,
    }
    save({
      featuredSpeakers: {
        items: {
          [id]: {
            button,
          },
        },
      },
    })
  }

  return (
    <Button
      variant="contained"
      fullWidth
      aria-label="add speaker button"
      color="primary"
      onClick={onAdd}
    >
      Add Button
    </Button>
  )
}

function useHandleDragSpeakers() {
  const update = useSaveTemplate()
  const {
    featuredSpeakers: {items},
  } = useTownhallTemplate()

  return (result: DropResult) => {
    const {destination, source} = result

    if (!destination) {
      return
    }

    const ids = orderedIdsByPosition(items)

    const [removed] = ids.splice(source.index, 1)
    ids.splice(destination.index, 0, removed)

    update({
      featuredSpeakers: {
        items: createPositions(ids),
      },
    })
  }
}

const FeaturedItemContainer = styled.div<{
  reverse?: boolean
}>`
  width: 100%;
  display: flex;
  flex-direction: ${(props) => (props.reverse ? 'row' : 'row-reverse')};
  margin-bottom: ${(props) => props.theme.spacing[20]};
  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    flex-direction: column-reverse;
    align-items: center;
  }
`

const StyledTitle = styled(Title)`
  font-style: normal;
  font-weight: 500;
  font-size: 34px;
  line-height: 40px;
  margin-bottom: ${(props) => props.theme.spacing[6]};
  color: inherit;
`

const StyledContent = styled(Content)`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: ${(props) => props.theme.spacing[9]};
  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    max-width: none;
  }
`

const FeaturedImageContainer = styled.div<{
  reverse: boolean
}>`
  width: 50%;
  display: flex;
  align-items: center;
  flex-direction: ${(props) => (props.reverse ? 'row' : 'row-reverse')};
  padding-right: ${(props) => (props.reverse ? 0 : 5)}%;
  padding-left: ${(props) => (props.reverse ? 5 : 0)}%;
  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    width: 100%;
    justify-content: center;
  }
`

const FeaturedTextContainer = styled.div<{
  reverse: boolean
  textColor: string
}>`
  color: ${(props) => props.textColor} !important;
  width: 50%;
  padding-right: ${(props) => (props.reverse ? 5 : 0)}%;
  padding-left: ${(props) => (props.reverse ? 0 : 5)}%;
  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    margin-right: 0;
    padding-right: 0;
    padding-left: 0;
    width: 100%;
  }
`

const Box = styled.div`
  width: 100%;
`

const StyledImage = styled.img`
  border-radius: 50%;
  object-fit: cover;
  width: 20rem;
  height: 20rem;
  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    width: 15rem;
    height: 15rem;
    margin-right: 0;
    margin-bottom: ${(props) => props.theme.spacing[10]};
  }
`

import Button from 'lib/ui/Button'
import TextField from 'lib/ui/TextField'
import React from 'react'
import {useForm} from 'react-hook-form'
import styled from 'styled-components'

export default function CreateNewTagForm(props: {
  onCreate: (tagName: string) => void
}) {
  const {handleSubmit, register} = useForm()

  const create = (data: {tag_name: string}) => {
    props.onCreate(data.tag_name)
  }

  return (
    <form onSubmit={handleSubmit(create)}>
      <TextField
        fullWidth
        placeholder="Tag name"
        variant="outlined"
        inputProps={{
          'aria-label': 'tag name',
          ref: register,
        }}
        name="tag_name"
      />

      <CreateButton
        fullWidth
        variant="contained"
        color="primary"
        type="submit"
        aria-label="create new tag"
      >
        Create
      </CreateButton>
    </form>
  )
}

const CreateButton = styled(Button)`
  margin-bottom: ${(props) => props.theme.spacing[4]}!important;
`

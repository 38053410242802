import React from 'react'
import styled from 'styled-components'
import TextField from '@material-ui/core/TextField'
import {handleAutocomplete} from 'lib/dom'
import {Autocomplete} from '@material-ui/lab'
import {Field} from 'organization/Event/Services/Apps/Infusionsoft/Config/FieldAutocomplete'

export default function PhoneSelector(props: {
  value: Field | null
  onChange: (field: Field | null) => void
  label?: string
  disabled?: boolean
}) {
  const {value: selected, onChange} = props

  const options: Field[] = [
    {
      name: 'PHONE1',
      label: 'Phone 1',
    },
    {
      name: 'PHONE2',
      label: 'Phone 2',
    },
    {
      name: 'PHONE3',
      label: 'Phone 3',
    },
    {
      name: 'PHONE4',
      label: 'Phone 4',
    },
    {
      name: 'PHONE5',
      label: 'Phone 5',
    },
  ]

  const optionLabel = (field: Field) => {
    return field.label + ' - ' + field.name
  }

  return (
    <Box>
      <Autocomplete
        disablePortal
        options={options}
        value={selected}
        aria-label={'phone autocomplete'}
        onChange={handleAutocomplete(onChange)}
        getOptionLabel={optionLabel}
        closeIcon=""
        getOptionSelected={(option, value) => option.name === value.name}
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              variant="outlined"
              label={props.label || 'Field'}
              fullWidth
              inputProps={{
                ...params.inputProps,
                'aria-label': 'phone field',
              }}
              InputProps={{
                ...params.InputProps,
              }}
            />
          )
        }}
      />
    </Box>
  )
}

const Box = styled.div`
  position: relative;
  .MuiAutocomplete-popper {
    margin-top: -20px !important;
  }
`
